import Button from "generalComponents/Button/Button";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onDeleteCategory, onDeleteProject, onMoveTrashTask } from "Store/actions/ProjectActions";
import { projectProps } from "types/Project";

import styles from "./DeleteConfirm.module.sass";

function DeleteConfirm({ params, onClose, type }) {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const project = useSelector((s) => s.Projects.project);

  const MSG = useProjectMsg();

  const deleteTask = () => {
    const messages = {
      error: MSG.ERROR,
      success: MSG[PROJECT_MODALS.DEL_TASK]
    };
    switch (type) {
      case PROJECT_MODALS.TASK_TO_TRASH: {
        const payload = {
          id_task: [params.id],
          id_project: project.id
        };
        return dispatch(onMoveTrashTask(payload, messages));
      }

      case PROJECT_MODALS.DEL_PROJECT: {
        return dispatch(onDeleteProject(params.id, MSG.ERROR));
      }

      case PROJECT_MODALS.DEL_CATEGORY: {
        return dispatch(onDeleteCategory(params, MSG.ERROR));
      }

      default:
        break;
    }
  };

  const getMessage = () => {
    switch (type) {
      case PROJECT_MODALS.TASK_TO_TRASH:
        return `${__("Задача")} "${params.name}" ${__("будет перемещена в корзину")}`;

      case PROJECT_MODALS.DEL_TASK:
        return params.id_task.length > 1
          ? `${__("Вы действительно хотите удалить выбранные задачи")}`
          : `${__("Вы действительно хотите удалить задачу")} "${params.name}"?`;

      case PROJECT_MODALS.DEL_PROJECT:
        return `${__("Вы действительно хотите удалить проект")} "${params.name}"?`;

      case PROJECT_MODALS.DEL_CATEGORY: {
        return params.id_tasks.length > 0
          ? `${__("В категории")} "${params.name}" ${__("есть задачи.")} ${__("Они будут перемещены в корзину")}`
          : `${__("Категория")} "${params.name}" ${__("будет удалена")}`;
      }

      case PROJECT_MODALS.CLEAR_TRASH: {
        return `${__("После того как Вы нажмете подтвердить все данные будут удалены без возможности востановления")}`;
      }
      default:
        break;
    }
  };

  return (
    <div className={styles.deleteWrap}>
      <div className={styles.deleteTaskText}>{getMessage()}</div>
      <div className={styles.btns}>
        <Button type="button" variant="cancel" text={__("Отмена")} onClick={onClose} />
        <Button type="button" variant="ok" text={__("Удалить")} onClick={deleteTask} />
      </div>
    </div>
  );
}

export default DeleteConfirm;

DeleteConfirm.defaultProps = {
  closeModal: () => {}
};

DeleteConfirm.propTypes = {
  onClose: PropTypes.func,
  type: PropTypes.string,
  params: PropTypes.oneOfType([
    projectProps,
    PropTypes.exact({
      id_task: PropTypes.string,
      id_category: PropTypes.string,
      name: PropTypes.string
    }),
    PropTypes.arrayOf(PropTypes.string)
  ])
};
