import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import classNames from "classnames";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import ElementsFilter from "generalComponents/ElementsFilter/ElementsFilter";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { ILinkServicePanelProps } from "models/project/linkServicePanel";
import React from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";

import styles from "./LinkServicePanel.module.sass";

const LinkServicePanel: React.FC<ILinkServicePanelProps> = ({
  isSelect,
  onDelete,
  handleSelect,
  filters,
  setFilters
}): JSX.Element => {
  const { __ } = useLocales();
  const dispatch = useDispatch();

  const onAddLink = (): void => {
    // TODO - mk - delete after all store is typed
    // @ts-ignore
    dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.ADD_LINK, params: {} }));
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.selectBtn} onClick={onAddLink}>
        {__("Добавить ссылку")}
      </div>
      <div className={styles.rightGroup}>
        <div className={classNames(styles.selectBtn, { [styles.active]: isSelect })} onClick={handleSelect}>
          {__("Выбрать")}
        </div>
        <div className={styles.icons}>
          <ElementsFilter filters={filters} setFilters={setFilters} />
          <ButtonIcon handleClick={onDelete} variant="grey" notHover disabled={!isSelect}>
            <TrashIcon width={15} height={15} className={styles.icon} />
          </ButtonIcon>
        </div>
      </div>
    </div>
  );
};

export default LinkServicePanel;
