import { ReactComponent as CollapsedIcon } from "assets/icons/collapsed.svg";
import { ReactComponent as PauseIcon } from "assets/icons/paused.svg";
import { ReactComponent as PlayIcon } from "assets/icons/play.svg";
import { ReactComponent as SpeakerIcon } from "assets/icons/speaker.svg";
import { ReactComponent as SpeakerOffIcon } from "assets/icons/speaker-off.svg";
import { ReactComponent as CloseIcon } from "assets/PrivateCabinet/closeModal.svg";
import classNames from "classnames";
import React, { RefObject, useCallback, useEffect, useRef, useState } from "react";

import styles from "./AudioPlayer.module.sass";

interface IAudioPlayerProps {
  collapse?: boolean;
  setCollapse?: () => void;
  onClose?: () => void;
  audio?: string;
}
const AudioPlayer: React.FC<IAudioPlayerProps> = ({ collapse, setCollapse, onClose, audio }) => {
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(60);
  const [lastVolume, setLastVolume] = useState<number>(60);
  const [muteVolume, setMuteVolume] = useState<boolean>(false);

  const audioRef = useRef<HTMLMediaElement>(null);
  const progressBarRef = useRef<HTMLInputElement>(null);
  const playAnimationRef = useRef(null);

  const onLoadedMetadata = () => {
    if (audioRef.current) {
      const seconds = isFinite(audioRef.current.duration) ? audioRef.current.duration : 0;
      setDuration(seconds);
      progressBarRef.current.max = String(seconds);
    }
  };

  const togglePlayPause = (): void => {
    setIsPlaying((prev) => !prev);
  };

  const repeat = useCallback(() => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      setTimeProgress(currentTime);
      progressBarRef.current.value = String(currentTime);
      playAnimationRef.current = requestAnimationFrame(repeat);
    }
  }, [audioRef, progressBarRef, setTimeProgress]);

  const getPercentTime = (): number => {
    if (progressBarRef.current) {
      return (Number(progressBarRef.current.value) / duration) * 100;
    }
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, audioRef, muteVolume]);

  const onChangeVolume = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setVolume(+e.currentTarget.value);
    setLastVolume(+e.currentTarget.value);
    if (+e.currentTarget.value === 0) {
      setMuteVolume(true);
    }
  };

  const onMuteValue = (): void => {
    if (muteVolume) {
      setMuteVolume(false);
      setVolume(lastVolume);
    } else {
      setMuteVolume(true);
      setVolume(0);
    }
  };

  // progress bar
  const handleProgressChange = () => {
    const cr = progressBarRef as RefObject<HTMLInputElement>;
    audioRef.current.currentTime = Number(cr?.current.value);
  };
  const formatTime = (time: number) => {
    if (time && !isNaN(time)) {
      const minutes = Math.floor(time / 60);
      const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const seconds = Math.floor(time % 60);
      const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${formatMinutes}:${formatSeconds}`;
    }
    return "00:00";
  };
  return (
    <div className={classNames(styles.container, { [styles.collapse]: collapse })}>
      <audio ref={audioRef} onLoadedMetadata={onLoadedMetadata}>
        <source src={`${audio}`} />
      </audio>

      <div className={styles.controls}>
        <div className={styles.volumeBlock}>
          <button onClick={onMuteValue}>{muteVolume ? <SpeakerOffIcon /> : <SpeakerIcon />}</button>
          <input
            type="range"
            min={0}
            max={100}
            value={volume}
            onChange={onChangeVolume}
            className={styles.volumeInput}
            style={{
              background: `linear-gradient(to right, #E7EBEA ${volume}%, rgba(231, 235, 234, 0.3) ${volume}%)`
            }}
          />
        </div>
        <button type="button" onClick={togglePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </button>
        {setCollapse ? (
          <div className={styles.services}>
            <button type="button" onClick={setCollapse} className={styles.close}>
              <CollapsedIcon />
            </button>
            {collapse && (
              <button type="button" onClick={onClose} className={styles.close}>
                <CloseIcon width={16} height={16} />
              </button>
            )}
          </div>
        ) : (
          <div className={styles.empty} />
        )}
      </div>
      <div className={styles.progressBar}>
        <span className={styles.time}>{formatTime(timeProgress)}</span>
        <input
          type="range"
          className={styles.progressInput}
          ref={progressBarRef}
          defaultValue="0"
          onChange={handleProgressChange}
          style={{
            background: `linear-gradient(to right, #E7EBEA ${getPercentTime()}%,rgba(231, 235, 234, 0.3) ${getPercentTime()}%)`
          }}
        />
        <span className={styles.time}>{formatTime(duration)}</span>
      </div>
    </div>
  );
};

export default AudioPlayer;
