import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import SharedFolderElementsList from "containers/sharedFolder/SharedFolderElementsList/SharedFolderElementsList";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { useGetFolderInfo } from "generalComponents/Services/folderServices/folderServicesApi";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { IFolder } from "models/store/folders/foldersStore";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./ShareFolderPage.module.sass";

const ShareFolderPage = () => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const { did, dirId } = useParams();
  const [dirInfo, setDirInfo] = useState<IFolder>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { onGetFolderInfo } = useGetFolderInfo();
  const { fetchFolderZipLink, onGetFilesSharedFolder, onResetFilesList, onSetFolderPassModal } = useActions();

  const getFolderInfo = () => {
    onGetFolderInfo({ did, id_dir: dirId }, setDirInfo, (value) => setLoading(value));
  };

  useEffect(() => getFolderInfo(), [dirId]); //eslint-disable-line

  const getFiles = () => {
    onResetFilesList();
    const params = {
      did,
      id_dir: dirInfo.id_dir
    };
    if (dirInfo.pass) {
      onSetFolderPassModal({
        open: true,
        type: "enterPassword",
        folder: dirInfo,
        callbackOk: (decryptedPass) => {
          onGetFilesSharedFolder({ ...params, pass: decryptedPass });
        },
        callbackCancel: () => {
          navigate(-1);
        }
      });
      return;
    }

    onGetFilesSharedFolder(params);
  };

  useEffect(() => {
    if (!dirInfo) return;
    getFiles();
  }, [dirInfo]); //eslint-disable-line

  const handleDownload = () => {
    const idDir: string = dirId ?? dirInfo.id_dir;
    fetchFolderZipLink(idDir, null, did);
  };

  return (
    <WorkAreaWrap>
      {loading && (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      )}
      {dirInfo && (
        <>
          <div className={styles.header}>
            <div className={styles.info}>
              <div className={styles.row}>
                {dirId && (
                  <Button
                    variant={ButtonVariantType.OPACITY}
                    size={ButtonSizeType.EXTRA_SMALL}
                    iconL={<ArrowIcon />}
                    isSquare
                    onClick={() => navigate(-1)}
                  />
                )}
                <p className={styles.name}>{dirInfo.name}</p>
              </div>
              <p className={styles.amount}>
                {__("Файлов")} - {dirInfo.col_files}
              </p>
            </div>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.LARGE}
              iconL={<UploadIcon />}
              text={__("Скачать все")}
              onClick={handleDownload}
            />
          </div>
          <SharedFolderElementsList />
        </>
      )}
    </WorkAreaWrap>
  );
};

export default ShareFolderPage;
