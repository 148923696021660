import { useGoogleLogin } from "@react-oauth/google";
import api from "api";
import { ReactComponent as GoogleIcon } from "assets/icons/sign_in_google.svg";
import Button from "containers/Authentication/Button/Button";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useActions } from "hooks/useActions";
import { STATUS_COLOR, USER_STATUS } from "models/store/user/user";
import { Dispatch, SetStateAction } from "react";
import { setUserToStorage } from "utils/signIn";

const GoogleButton = ({
  setError,
  rememberUser
}: {
  setError: Dispatch<SetStateAction<boolean>>;
  rememberUser: boolean;
}): JSX.Element => {
  const { onLog } = useActions();

  const onSuccessGoogleLogin = async (response: any) => {
    setError(false);
    const accessToken = response.access_token;
    try {
      const userInfoResponse = await fetch("https://www.googleapis.com/oauth2/v2/userinfo", {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (userInfoResponse.ok) {
        const userInfo = await userInfoResponse.json();
        const params = {
          google: userInfo
        };
        const { data } = await api.post(`/ajax/user_login.php`, null, { params });
        checkResponseStatus(data.ok);
        const paramsStatus = {
          uid: data.uid,
          status_color: STATUS_COLOR.ONLINE,
          status: USER_STATUS.ONLINE
        };
        const res = await api.get(`/ajax/user_edit2.php`, { params: paramsStatus });
        checkResponseStatus(res.data.ok);
        onLog(data.uid);
        if (rememberUser) {
          setUserToStorage(data.uid);
        }
      }
    } catch (error) {
      setError(true);
      console.error(error);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: onSuccessGoogleLogin
  });

  return (
    <Button onClick={() => googleLogin()}>
      <GoogleIcon />
    </Button>
  );
};

export default GoogleButton;
