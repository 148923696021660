import { ReactComponent as ImageIcon } from "assets/PrivateCabinet/image.svg";
import { FC } from "react";
import { useLocales } from "react-localized";

import styles from "./NoFormatFileView.module.sass";

export const NoFormatFileView: FC = () => {
  const { __ } = useLocales();

  return (
    <div className={styles.wrapp}>
      <ImageIcon width={293} fill="#9FAEAB" />
      <p className={styles.wrapp__text}>{__("Файл недоступен к просмотру")}</p>
    </div>
  );
};
