import TinyMCE, { TinyFile } from "containers/Postbox/TinyMCE/TinyMCE";
import PopUp from "generalComponents/PopUp/PopUp";
import { POSTBOX_MODALS } from "generalComponents/variables/mail";
import { useFindPostbox, usePostboxFolder } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { useContactsList } from "hooks/useContactsList";
import { useDateFormat } from "hooks/useDateFormat";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { initialMailNewMessageModalState } from "models/store/Cabinet/modals/postboxModal";
import { ILetterInfo, ILetterInfoAttachments, ILetterInfoTo, IPostbox } from "models/store/postbox/postbox";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { Editor as TinyMCEEditor } from "tinymce";

import HeaderModal from "../../HeaderModal/HeaderModal";
import InputMail from "./Input/InputMail";
import styles from "./MailNewMessage.module.sass";
import SelectMail from "./SelectMail/SelectMail";

const getSubject = (type: string, subject: string): string => {
  switch (type) {
    case "forward":
      return `Fwd: ${subject}`;
    case "answer":
      return `Re: ${subject}`;
    case "draft":
      return subject;

    default:
      return "";
  }
};
const getTo = (type: string, letter: ILetterInfo): string => {
  switch (type) {
    case "answer":
      return letter.from;
    case "draft":
      return letter.to?.[0]?.address;

    default:
      return "";
  }
};

const MailNewMessage = (): JSX.Element => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const { uid } = useUserSelectors();
  const { combineContacts } = useContactsList();
  const { postboxes } = usePostboxSelectors();
  const { postbox } = useFindPostbox();
  const { _isSchedule } = usePostboxFolder();
  const { onSendEmail, onSendEmailDelay, getContacts, onSetTopMessageModal } = useActions();
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const submitButton = useRef<HTMLButtonElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const filesRef = useRef<HTMLInputElement>(null);
  const { onMailNewMessageModal, onSetPostboxModal, onRemoveLetter } = useActions();
  const {
    mailNewMessage: { letter, to: recipient }
  } = useGlobalModalsSelectors();

  const _letter = letter?.letter;
  const _type = letter?.type;
  const [dateValue, setDateValue] = useState<string>("");
  const [from, setFrom] = useState<IPostbox>(letter ? postbox : postboxes.length === 1 ? postboxes[0] : null);
  const [to, setTo] = useState<string>(getTo(_type, _letter));
  const [subject, setSubject] = useState<string>(getSubject(_type, _letter?.subject));
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [forwardFiles, setForwardFiles] = useState((_letter?.attachments as ILetterInfoAttachments[]) || []);
  const _to = _letter?.to as ILetterInfoTo[];

  useEffect(() => {
    getContacts();
    document.addEventListener("keydown", onCloseEsc);
    return () => document.removeEventListener("keydown", onCloseEsc);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!recipient) return;
    setTo(recipient);
  }, [recipient]);

  const onCloseEsc = (e: KeyboardEvent) => {
    if (e.code === "Escape") closeModal();
  };

  const closeModal = (): void => {
    const content = editorRef.current.getContent();
    if (to || content || subject) {
      onSetPostboxModal({
        open: true,
        type: POSTBOX_MODALS.DRAFT,
        draft: {
          from,
          subject,
          to,
          html: content,
          files: uploadFiles,
          uid: _letter?.uid,
          prevFiles: _letter?.attachments as ILetterInfoAttachments[]
        }
      });
      return;
    }
    onMailNewMessageModal(initialMailNewMessageModalState());
  };

  const selectFrom = (el: IPostbox): void => {
    setFrom(el);
  };

  const renderInitialText = (): string => {
    const time = formatDate(_letter.time, `fullDay`);
    if (_type === "forward") {
      return `
        <br />
        <div dir="ltr">
          ---------- Forwarded message ---------<br/>
          Від: <span dir="auto">&lt;<a href="mailto:${_letter.from}">${_letter.from}</a>&gt;</span><br/>
          Date: ${time}<br/>
          Subject: ${_letter.subject}<br/>
          To: &lt;<a href="mailto:${_to[0].address}">${_to[0].address}</a>&gt;<br/><br/>
          <div>
          ${_letter.html}
          </ div>
        </div>`;
    }
    if (_type === "answer") {
      return `
        <br />
        <div dir="ltr">
          ${time}, &lt;<a href="mailto:${_letter.from}">${_letter.from}</a>&gt; ${__("Пишет")}:<br>           
          <blockquote class="gmail_quote" style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">
            ${_letter.html}
          </blockquote>
        </div>\r\n`;
    }

    if (_type === "draft") {
      return _letter.html;
    }
  };

  const onChangeFilesHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUploadFiles((prevState) =>
      prevState ? [...prevState, ...Array.from(e.target.files)] : [...Array.from(e.target.files)]
    );
  };

  const onRemove = () => {
    const payload = {
      user: postbox.email,
      pass: postbox.pass,
      server: postbox.server,
      data: JSON.stringify([{ folder: POSTBOX_SYSTEM_FOLDER.Drafts, message_nums: _letter.uid }])
    };

    const errorMsg = __("Что-то пошло не так. Попробуйте позже!");

    onRemoveLetter(payload, errorMsg);
  };

  const sendMail = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!to) {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR,
        message: __("Укажите хотя бы одного получателя")
      });
      return;
    }
    const formData = new FormData();
    if (_type === "forward" || _type === "draft") {
      forwardFiles.forEach((file) => formData.append("email_files[]", file.filename));
      formData.append("email_uid", String(_letter.uid));
      formData.append("email_folder", String(_letter.folder));
    }
    uploadFiles.forEach((file) => formData.append("files[]", file));
    formData.append("uid", uid);
    formData.append("user", from.email);
    formData.append("pass", from.pass);
    formData.append("server", from.server);
    formData.append("from_name", from.name || "");
    formData.append("from", from.email);
    formData.append("subject", subject);
    formData.append("to", to);
    formData.append("html", editorRef.current.getContent());

    if (dateValue) {
      formData.append("date", dateValue);
      onSendEmailDelay(formData, _isSchedule, {
        user: from.email,
        messages: {
          success: __("Вы запланировали отправку письма"),
          error: __("Ошибка планирования отправки письма")
        }
      });
    } else {
      onSendEmail(formData, {
        messages: {
          success: __("Письмо отправленно"),
          error: __("Ошибка отправки")
        },
        cb: _type === "draft" ? onRemove : null
      });
    }

    onMailNewMessageModal(initialMailNewMessageModalState());
  };

  const getUploadFiles = (): TinyFile[] => {
    const forward: TinyFile[] =
      _type === "forward" || _type === "draft"
        ? forwardFiles.map((file) => ({
            filename: file.filename,
            mime: file.mime,
            variant: "forward"
          }))
        : [];
    const upload: TinyFile[] = uploadFiles.map((file: File) => ({
      filename: file.name,
      mime: file.type,
      variant: "upload"
    }));
    return [...forward, ...upload];
  };

  const onRemoveFiles = (file: TinyFile): void => {
    if (file.variant === "forward") {
      setForwardFiles((prev) => prev.filter((item) => item.filename !== file.filename));
    }
    if (file.variant === "upload") {
      setUploadFiles((prev) => prev.filter((item) => item.name !== file.filename));
    }
  };
  return (
    <PopUp position="right-bottom">
      <div className={styles.root}>
        <HeaderModal title={__("Новое письмо")} onClose={closeModal} />
        <form
          className={styles.mainContent}
          ref={formRef}
          onSubmit={(e) => {
            sendMail(e);
          }}
        >
          <div className={styles.select}>
            <SelectMail value={from} onSelect={selectFrom} />
          </div>
          <InputMail placeholder={__("Кому")} name="to" value={to || ""} onChange={setTo} options={combineContacts} />
          <InputMail placeholder={__("Тема")} name="subject" value={subject} onChange={setSubject} />
          <div>
            <TinyMCE
              refFiles={filesRef}
              refEditor={editorRef}
              refSubmit={submitButton}
              setDateValue={setDateValue}
              dateValue={dateValue}
              files={getUploadFiles()}
              onRemoveFiles={onRemoveFiles}
              initialContent={_letter ? renderInitialText() : ""}
            />
          </div>
          <button ref={submitButton} type="submit" style={{ display: "none" }}></button>
          <input
            style={{ display: "none" }}
            name="files[]"
            type="file"
            multiple
            onChange={onChangeFilesHandler}
            ref={filesRef}
          />
        </form>
      </div>
    </PopUp>
  );
};

export default MailNewMessage;
