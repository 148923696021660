import { ReactComponent as CalendadIcon } from "assets/PrivateCabinet/calendar-5.svg";
import RobotIcon from "assets/PrivateCabinet/robot.png";
import classNames from "classnames";
import { format } from "date-fns";
import Button from "generalComponents/Button/Button";
import UserAvatar from "Pages/Cabinet/Components/Project/Components/UserAvatar/UserAvatar";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useLocales } from "react-localized";
import { useSelector } from "react-redux";
import { taskTypes } from "types/Project";

import styles from "./TaskHistory.module.sass";

const TaskHistory = ({ onClose, params }) => {
  const { __ } = useLocales();
  const project = useSelector((s) => s.Projects.project);
  const categoryTrash = useSelector((s) => s.Projects.categoryTrash);

  const getStatus = (id_category) => [...project.tasks_category, ...categoryTrash].find((el) => el.id === id_category);

  const findUser = (id) => project.users.find((u) => u.id_user === id);

  return (
    <div className={styles.wrap}>
      <p className={styles.subtitle}>
        {__("В данной вкладке отображенна вся история задачи, дата создания, а так же перемещение между категориями")}
      </p>
      <ul className={styles.list}>
        <div className={styles.create}>
          <CalendadIcon />
          <span>
            {__("Дата создания")}:&nbsp;{format(new Date(params.ut), "dd.MM.yyyy")}
          </span>
        </div>
        <div className={classNames(styles.create, styles.border)}>
          <UserAvatar icon={params.author_info?.icon} diameter={20} />
          <span>
            {__("Автор задачи")}:&nbsp;{params.author_info.name}&nbsp;{params.author_info?.sname}
          </span>
        </div>

        {params.move_history && (
          <>
            {params.move_history.map((item, i) => (
              <Fragment key={i}>
                {item.id_category_new && (
                  <li>
                    <div className={styles.create}>
                      <CalendadIcon />
                      <span>
                        {__("Дата смены статуса")}:&nbsp;{item.date.slice(0, 10)}
                      </span>
                    </div>
                    {item.id_user !== 0 ? (
                      <div className={classNames(styles.create, styles.border)}>
                        <UserAvatar icon={params.author_info?.icon} diameter={20} />
                        <span>
                          {findUser(item.id_user)?.name}&nbsp;{findUser(item.id_user)?.sname}&nbsp;
                          {__("перемещено в ")}
                          <span
                            className={styles.circle}
                            style={{ background: getStatus(item.id_category_new)?.color.dark }}
                          />
                          &quot;{getStatus(item.id_category_new)?.name}&quot;
                        </span>
                      </div>
                    ) : (
                      <div className={classNames(styles.create, styles.border)}>
                        <img src={RobotIcon} width={20} height={20} className={styles.robotIcon} alt={__("робот")} />
                        <span>
                          {__("Перемещено автоматически системой 4Hub в ")}
                          <span
                            className={styles.circle}
                            style={{ background: getStatus(item.id_category_new)?.color.dark }}
                          />
                          &quot;{getStatus(item.id_category_new)?.name}&quot;
                        </span>
                      </div>
                    )}
                  </li>
                )}
                {item.id_datelong_new && (
                  <li>
                    <div className={styles.create}>
                      <CalendadIcon />
                      <span>
                        {item.date.slice(0, 10)}&nbsp;
                        {__("продлено до")}&nbsp;{format(new Date(item.id_datelong_new), "dd.MM.yyyy")}
                      </span>
                    </div>
                    <div className={classNames(styles.create, styles.border)}>
                      <UserAvatar icon={params.author_info?.icon} diameter={20} />

                      <span>
                        {findUser(item.id_user)?.name}&nbsp;{findUser(item.id_user)?.sname}&nbsp;
                      </span>
                    </div>
                  </li>
                )}
              </Fragment>
            ))}
          </>
        )}
      </ul>

      <Button type="button" variant="ok" onClick={onClose} text={__("Закрыть")} />
    </div>
  );
};

export default TaskHistory;

TaskHistory.propTypes = {
  onClose: PropTypes.func,
  params: taskTypes
};
