import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { useSearchParams } from "react-router-dom";
import { useFilterTypeElementsSelector } from "Store/selectors/filterTypeElementsSelectors";
import { useSortByDateSelector } from "Store/selectors/sortByDateSelectors";

export const useIsFiltersJournal = (): boolean => {
  const [searchParams] = useSearchParams();
  const filter_user = searchParams.get(SEARCH_PARAMS.USER_ID);

  const {
    filterTypeElements: { table, id_type }
  } = useFilterTypeElementsSelector();

  const {
    sortByDate: { y, m, d }
  } = useSortByDateSelector();

  const isFiltersJournal =
    Boolean(table) || Boolean(id_type) || Boolean(filter_user) || (Boolean(y) && Boolean(m) && Boolean(d));
  return isFiltersJournal;
};
