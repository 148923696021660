import classNames from "classnames";
import { MAIL_PARAMS } from "generalComponents/variables/routing";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";
import { v4 as uuid } from "uuid";

import LetterItem from "../LetterItem/LetterItem";
import LettersObserver from "../LettersObserver/LettersObserver";
import styles from "./LetterList.module.sass";

const LetterList: React.FC = () => {
  const [value] = useSearchParams();
  const viewType = value.get(MAIL_PARAMS.SPLIT);
  const { letters } = usePostboxSelectors();

  return (
    <ul className={classNames(styles.list, "scrollbar-thin-blue", { [styles.short]: viewType })}>
      {letters.map((letter) => (
        <LetterItem key={uuid()} letter={letter} />
      ))}
      <LettersObserver />
    </ul>
  );
};

export default LetterList;
