import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import classNames from "classnames";
import { CKEDITOR_TOOLBAR_OPTIONS } from "generalComponents/variables/ckeditor";
import PropTypes from "prop-types";
import React from "react";

import styles from "./TextEditorComments.module.sass";

const hiddenEvent = () => null;

const TextEditorComments = ({ content, setContent, isAddFile = true }) => {
  const editor = null;
  const handlerChange = (event, editor) => {
    hiddenEvent(event);
    const text = editor.getData();
    setContent(text);
  };

  return (
    <div
      className={classNames("commentsWrap", styles.editorWrap, {
        [styles.editorWrapAddFile]: isAddFile
      })}
    >
      <CKEditor
        onReady={(editor) => {
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
        }}
        onError={({ willEditorRestart }) => {
          if (willEditorRestart) {
            editor.ui.view.toolbar.element.remove();
          }
        }}
        onChange={handlerChange}
        editor={DecoupledEditor}
        data={content}
        config={{
          toolbar: [...CKEDITOR_TOOLBAR_OPTIONS, "insertTable"],
          list: {
            properties: {
              styles: false,
              startIndex: true,
              reversed: true
            }
          }
        }}
      />
    </div>
  );
};

export default TextEditorComments;

TextEditorComments.propTypes = {
  content: PropTypes.string,
  setContent: PropTypes.func,
  isAddFile: PropTypes.bool
};
