import classNames from "classnames";
import React from "react";
import { useLocales } from "react-localized";
import { useSelector } from "react-redux";

import AddUser from "../Components/AddUser/AddUser";
import ProjectRoles from "../Components/ProjectRoles/ProjectRoles";
import styles from "./ProjectTeam.module.sass";
import TeamList from "./TeamList/TeamList";

const ProjectTeam = () => {
  const { __ } = useLocales();
  const project = useSelector((s) => s.Projects.project);

  return (
    <>
      <ProjectRoles />
      <div className={classNames(styles.addUser, { [styles.hide]: Number(project.is_read) === 0 })}>
        <AddUser />
        <p>{__("Добавить участника в команду")}</p>
      </div>
      <TeamList />
    </>
  );
};

export default ProjectTeam;
