import {
  ArchivedElementsArrayType,
  IArchivedFilesState,
  initialArchivedFilesState
} from "models/store/archive/archivedFiles";
import { ArchivedFilesActions } from "models/store/archive/archivedFilesActions";
import { ArchivedFilesTypes } from "Store/types/archivedFilesTypes";
import { removesElement } from "Store/utils/removesElement";

const INITIAL_STATE: IArchivedFilesState = initialArchivedFilesState();

export const ArchiveReducer = (state = INITIAL_STATE, action: ArchivedFilesActions): IArchivedFilesState => {
  switch (action.type) {
    case ArchivedFilesTypes.SET_ARCHIVED_FILES:
      if (state.elements) {
        return {
          ...state,
          elements: [...state.elements, ...action.payload]
        };
      } else {
        return {
          ...state,
          elements: action.payload
        };
      }

    case ArchivedFilesTypes.SET_TOTAL_ARCHIVED_ITEMS:
      return { ...state, total: action.payload };

    case ArchivedFilesTypes.SET_ARCHIVE_PAGE:
      return { ...state, page: action.payload };

    case ArchivedFilesTypes.RESET_ARCHIVED_FILES_LIST:
      return { ...state, page: 1, elements: null, total: 0 };

    case ArchivedFilesTypes.ARCHIVED_FILES_LOADER:
      return { ...state, loader: action.payload };

    case ArchivedFilesTypes.SET_DEL_ARCHIVED_ELEMENT: {
      const updateElements = removesElement(action.payload, state.elements) as ArchivedElementsArrayType;

      return {
        ...state,
        elements: updateElements,
        total: updateElements?.length
      };
    }

    case ArchivedFilesTypes.NULLIFY_ARCHIVED_FILES: {
      return {
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
};
