import booksImg from "assets/images/library/categories/books.png";
import booksImg2x from "assets/images/library/categories/books@2x.png";
import educationImg from "assets/images/library/categories/education.png";
import educationImg2x from "assets/images/library/categories/education@2x.png";
import photoImg from "assets/images/library/categories/photo.png";
import photoImg2x from "assets/images/library/categories/photo@2x.png";
import birthdaysImg from "assets/images/library/rubrics/birthdays.png";
import birthdaysImg2x from "assets/images/library/rubrics/birthdays@2x.png";
import designImg from "assets/images/library/rubrics/design.png";
import designImg2x from "assets/images/library/rubrics/design@2x.png";
import exhibitionsImg from "assets/images/library/rubrics/exhibitions.png";
import exhibitionsImg2x from "assets/images/library/rubrics/exhibitions@2x.png";
import financeImg from "assets/images/library/rubrics/finance.png";
import financeImg2x from "assets/images/library/rubrics/finance@2x.png";
import holidaysImg from "assets/images/library/rubrics/holidays.png";
import holidaysImg2x from "assets/images/library/rubrics/holidays@2x.png";
import managementImg from "assets/images/library/rubrics/management.png";
import managementImg2x from "assets/images/library/rubrics/management@2x.png";
import marketingImg from "assets/images/library/rubrics/marketing.png";
import marketingImg2x from "assets/images/library/rubrics/marketing@2x.png";
import presentationsImg from "assets/images/library/rubrics/presentations.png";
import presentationsImg2x from "assets/images/library/rubrics/presentations@2x.png";
import programmingLanguagesImg from "assets/images/library/rubrics/programming_languages.png";
import programmingLanguagesImg2x from "assets/images/library/rubrics/programming_languages@2x.png";
import technicalLiteratureImg from "assets/images/library/rubrics/technical_literature.png";
import technicalLiteratureImg2x from "assets/images/library/rubrics/technical_literature@2x.png";
import сonferencesImg from "assets/images/library/rubrics/сonferences.png";
import сonferencesImg2x from "assets/images/library/rubrics/сonferences@2x.png";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { DefaultLibraryCategories, DefaultLibraryRubrics, TABS } from "generalComponents/variables/library";
import { LibraryCategoryTypes } from "generalComponents/variables/library";
import { LibraryTypesRubrics } from "generalComponents/variables/library";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPath } from "hooks/useCurrentPath";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { ButtonVariantType } from "models/generalComponents/button";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { IFolder } from "models/store/folders/foldersStore";
import { ILibraryRubric } from "models/store/library/library";
import { useLocales } from "react-localized";
import { useParams } from "react-router";
import { useLibrarySelectors } from "Store/selectors/librarySelectors";

export interface ILibraryCategory {
  category: LibraryCategoryTypes;
  title: DefaultLibraryCategories;
  description: string;
  image: {
    src: string;
    srcSet: string;
    alt: string;
  };
  page: string;
}

export interface ILibraryCategories {
  [LibraryCategoryTypes.EDUCATION]: ILibraryCategory;
  [LibraryCategoryTypes.BOOKS]: ILibraryCategory;
  [LibraryCategoryTypes.PHOTO]: ILibraryCategory;
}

export const useContentRubrics = () => {
  const { __ } = useLocales();

  return {
    [LibraryTypesRubrics.MANAGEMENT_AND_LEADERSHIP]: {
      title: __(DefaultLibraryRubrics.MANAGEMENT_AND_LEADERSHIP),
      image: {
        src: managementImg,
        srcSet: `${managementImg2x} 2x`,
        alt: __(DefaultLibraryRubrics.MANAGEMENT_AND_LEADERSHIP)
      }
    },
    [LibraryTypesRubrics.FINANCE_AND_ACCOUNTING]: {
      title: __(DefaultLibraryRubrics.FINANCE_AND_ACCOUNTING),
      image: {
        src: financeImg,
        srcSet: `${financeImg2x} 2x`,
        alt: __(DefaultLibraryRubrics.FINANCE_AND_ACCOUNTING)
      }
    },
    [LibraryTypesRubrics.MARKETING_AND_ADVERTISING]: {
      title: __(DefaultLibraryRubrics.MARKETING_AND_ADVERTISING),
      image: {
        src: marketingImg,
        srcSet: `${marketingImg2x} 2x`,
        alt: __(DefaultLibraryRubrics.MARKETING_AND_ADVERTISING)
      }
    },
    [LibraryTypesRubrics.TECHNICAL_LITERATURE]: {
      title: __(DefaultLibraryRubrics.TECHNICAL_LITERATURE),
      image: {
        src: technicalLiteratureImg,
        srcSet: `${technicalLiteratureImg2x} 2x`,
        alt: __(DefaultLibraryRubrics.TECHNICAL_LITERATURE)
      }
    },
    [LibraryTypesRubrics.DESIGN_AND_ART]: {
      title: __(DefaultLibraryRubrics.DESIGN_AND_ART),
      image: {
        src: designImg,
        srcSet: `${designImg2x} 2x`,
        alt: __(DefaultLibraryRubrics.DESIGN_AND_ART)
      }
    },
    [LibraryTypesRubrics.PROGRAMMING_LANGUAGES]: {
      title: __(DefaultLibraryRubrics.PROGRAMMING_LANGUAGES),
      image: {
        src: programmingLanguagesImg,
        srcSet: `${programmingLanguagesImg2x} 2x`,
        alt: __(DefaultLibraryRubrics.PROGRAMMING_LANGUAGES)
      }
    },
    [LibraryTypesRubrics.CONFERENCES]: {
      title: __(DefaultLibraryRubrics.CONFERENCES),
      image: {
        src: сonferencesImg,
        srcSet: `${сonferencesImg2x} 2x`,
        alt: __(DefaultLibraryRubrics.CONFERENCES)
      }
    },
    [LibraryTypesRubrics.PRESENTATIONS]: {
      title: __(DefaultLibraryRubrics.PRESENTATIONS),
      image: {
        src: presentationsImg,
        srcSet: `${presentationsImg2x} 2x`,
        alt: __(DefaultLibraryRubrics.PRESENTATIONS)
      }
    },
    [LibraryTypesRubrics.EXHIBITIONS]: {
      title: __(DefaultLibraryRubrics.EXHIBITIONS),
      image: {
        src: exhibitionsImg,
        srcSet: `${exhibitionsImg2x} 2x`,
        alt: __(DefaultLibraryRubrics.EXHIBITIONS)
      }
    },
    [LibraryTypesRubrics.BIRTHDAYS]: {
      title: __(DefaultLibraryRubrics.BIRTHDAYS),
      image: {
        src: birthdaysImg,
        srcSet: `${birthdaysImg2x} 2x`,
        alt: __(DefaultLibraryRubrics.BIRTHDAYS)
      }
    },
    [LibraryTypesRubrics.HOLIDAYS]: {
      title: __(DefaultLibraryRubrics.HOLIDAYS),
      image: {
        src: holidaysImg,
        srcSet: `${holidaysImg2x} 2x`,
        alt: __(DefaultLibraryRubrics.HOLIDAYS)
      }
    }
  };
};

export const useContentCategories = () => {
  const { __ } = useLocales();
  const isLibrary = useCurrentPath(ROUTES.LIBRARY);

  const { id } = useParams();

  return {
    [LibraryCategoryTypes.EDUCATION]: {
      category: LibraryCategoryTypes.EDUCATION,
      title: __(DefaultLibraryCategories.EDUCATION),
      description: __("Видео курсы, лекции и статьи для образования и развития"),
      image: {
        src: educationImg,
        srcSet: `${educationImg2x} 2x`,
        alt: __(DefaultLibraryCategories.EDUCATION)
      },
      page: isLibrary ? ROUTES.LIBRARY : `${ROUTES.PROJECT}/${id}/${ROUTES.LIBRARY}`
    },
    [LibraryCategoryTypes.BOOKS]: {
      category: LibraryCategoryTypes.BOOKS,
      title: __(DefaultLibraryCategories.BOOKS),
      description: __("Специализированная и профильная литература"),
      image: {
        src: booksImg,
        srcSet: `${booksImg2x} 2x`,
        alt: __(DefaultLibraryCategories.BOOKS)
      },
      page: isLibrary ? ROUTES.LIBRARY : `${ROUTES.PROJECT}/${id}/${ROUTES.LIBRARY}`
    },
    [LibraryCategoryTypes.PHOTO]: {
      category: LibraryCategoryTypes.PHOTO,
      title: __(DefaultLibraryCategories.PHOTO),
      description: __("События и особые случаи"),
      image: {
        src: photoImg,
        srcSet: `${photoImg2x} 2x`,
        alt: __(DefaultLibraryCategories.PHOTO)
      },
      page: isLibrary ? ROUTES.LIBRARY : `${ROUTES.PROJECT}/${id}/${ROUTES.LIBRARY}`
    }
  };
};

export function useMenuFilterLibraryRubrics(): ISelectCustom[] {
  const { __ } = useLocales();

  const rubric = useContentRubrics();

  const { rubrics } = useLibrarySelectors();

  const { onSetObjSearchParams } = useSelectedObjSearchParams();

  const dataMenuRubrics = rubrics.map(({ name, id }) => ({
    name: rubric[name]?.title || name,
    value: id,
    callback: () =>
      onSetObjSearchParams({
        [SEARCH_PARAMS.ID_DEP]: id
      })
  }));

  return [
    {
      name: __(DefaultLibraryRubrics.ALL_RUBRICS),
      value: "",
      callback: () =>
        onSetObjSearchParams({
          [SEARCH_PARAMS.ID_DEP]: ""
        })
    },
    ...dataMenuRubrics
  ];
}

export function useMenuFilterLibraryCategories(): ISelectCustom[] {
  const { __ } = useLocales();

  const categories = useContentCategories();

  const { onSetObjSearchParams } = useSelectedObjSearchParams();

  const dataMenuCategories = Object.values(categories).map(({ category, title }) => ({
    name: title,
    value: category,
    callback: () =>
      onSetObjSearchParams({
        [SEARCH_PARAMS.CHAPTER]: category
      })
  }));

  return [
    {
      name: __(DefaultLibraryCategories.ALL_CATEGORIES),
      value: "",
      callback: () =>
        onSetObjSearchParams({
          [SEARCH_PARAMS.CHAPTER]: ""
        })
    },
    ...dataMenuCategories
  ];
}

export function useSelectLibraryCategories(): ISelectCustom[] {
  return Object.values(useContentCategories()).map(({ category, title }) => ({
    name: title,
    value: category
  }));
}

export function useSelectLibraryRubrics(): ISelectCustom[] {
  const rubric = useContentRubrics();

  const { rubrics } = useLibrarySelectors();

  return rubrics.map(({ name, id, is_system }) => ({
    name: is_system === "1" ? rubric[name]?.title : name,
    value: id
  }));
}

export function useMenuLibrarySettingsTabs(): ISelectCustom[] {
  const { __ } = useLocales();

  return [
    {
      label: __("Объекты"),
      name: SEARCH_PARAMS.TAB,
      value: TABS.OBJECTS
    },
    {
      label: __("Рубрики"),
      name: SEARCH_PARAMS.TAB,
      value: TABS.RUBRICS
    }
  ];
}

export const useContextLibraryFolder = () => {
  const { __ } = useLocales();
  const { onSetApproveModal, onTrashLibraryFolder, onSetCreateObjectLibraryModal } = useActions();

  const onTrashFolderCallback = (folder: IFolder) => {
    const params = {
      titleHead: __("Удалить навсегда"),
      title: __(`Вы действительно хотите удалить папку “${folder.name}”?`),
      text: __("Это действие необратимо"),
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => {
        onTrashLibraryFolder(folder);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  const onVisibleEditObjectLibraryModal = (folder: IFolder) => {
    onSetCreateObjectLibraryModal({
      open: true,
      titleHead: __("Изменить"),
      btnLabel: __("Сохранить"),
      folder,
      type: "editLibrary"
    });
  };

  return {
    onTrashFolderCallback,
    onVisibleEditObjectLibraryModal
  };
};

export const useContextLibraryRubric = () => {
  const { __ } = useLocales();
  const { onSetApproveModal, onTrashLibraryRubric, onSetCreateRubricLibraryModal } = useActions();

  const onTrashRubricModal = (rubric: ILibraryRubric) => {
    const payload = {
      id_item: rubric.id
    };

    const params = {
      titleHead: __("Удалить навсегда"),
      title: __(`Вы действительно хотите удалить рубрику “${rubric.name}”?`),
      text: __("Это действие необратимо"),
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => {
        onTrashLibraryRubric(payload);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  const onVisibleEditRubricLibraryModal = (rubric: ILibraryRubric) => {
    onSetCreateRubricLibraryModal({ open: true, titleHead: __("Изменить"), btnLabel: __("Изменить"), rubric });
  };

  return {
    onTrashRubricModal,
    onVisibleEditRubricLibraryModal
  };
};
