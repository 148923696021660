export interface ITariffModalState {
  open: boolean;
  isPeriodSelected?: boolean;
  price: number;
  discount?: number;
  title: string;
}

export function initialTariffModalState(): ITariffModalState {
  return { open: false, isPeriodSelected: false, price: 0, discount: undefined, title: "" };
}
