import classNames from "classnames";
import React, { useMemo } from "react";
import { useLocales } from "react-localized";
import { useSelector } from "react-redux";

import UserProject from "../../ProjectTasks/ProjectUsers/UserProject/UserProject";
import styles from "./TeamList.module.sass";

const TeamList = () => {
  const { __ } = useLocales();
  const project = useSelector((s) => s.Projects.project);
  const selectRole = useSelector((s) => s.Projects.selectRole);

  const filtredRoles = useMemo(() => {
    return project.roles.filter((r) => {
      if (selectRole !== "0") {
        return r.id === selectRole;
      }
      return r;
    });
  }, [project.roles, selectRole]);

  const filtredUsers = (id) => project.users.filter((u) => u.id_role === id);

  const renderUsers = (id) => {
    const userList = filtredUsers(id);
    return (
      <>
        {userList.length > 0 ? (
          userList.map((user, i) => <UserProject key={i} user={user} />)
        ) : (
          <div className={styles.noUser}>{__("Нет участников")}</div>
        )}
      </>
    );
  };

  const renderRole = (role) => (
    <div className={styles.roleBlock} key={role.id}>
      <p className={styles.roleName}>{role.name}</p>
      {renderUsers(role.id)}
    </div>
  );

  const otherUsers = useMemo(
    () => project.users.filter((u) => u.id_role === "0" || project.roles.findIndex((r) => r.id === u.id_role) < 0),
    [project.roles, project.users]
  );

  const renderOtherUser = () => {
    return (
      <div className={classNames(styles.roleBlock, styles.dark)}>
        <p className={styles.roleName}>{__("Не назначенные")}</p>

        <>
          {otherUsers.map((u, i) => (
            <UserProject key={i} user={u} />
          ))}
        </>
      </div>
    );
  };

  return (
    <div className={styles.wrap}>
      {filtredRoles.map((role) => renderRole(role))}
      {otherUsers.length > 0 && selectRole === "0" && renderOtherUser()}
    </div>
  );
};

export default TeamList;
