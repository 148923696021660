import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classNames from "classnames";
import { useFilteredTasks } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { TAB } from "Pages/JoinProjectPage/ProjectPage/ProjectGantPage/ProjectGantPage";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import { GantDay } from "../GantDay/GantDay";
import { GantMonth } from "../GantMonth/GantMonth";
import { GantTask } from "../GantTask/GantTask";
import { GantWeek } from "../GantWeek/GantWeek";
import { GantYear } from "../GantYear/GantYear";
import styles from "./GantSpace.module.sass";

interface IProps {
  tab: TAB;
}
export const H = 30; // высота линии
export const PT = 20; // верхний отступ
export const W = 30; // ширина колонки

export const GantSpace: FC<IProps> = ({ tab }) => {
  const { __ } = useLocales();
  const { onSetTasksProject, onFetchTaskGantProject } = useActions();
  const { project } = useJoinProjectsSelectors();

  const { filteredTasks } = useFilteredTasks(project.tasks);
  const [isShowTasks, setisShowTasks] = useState<boolean>(true);
  const [hoverRow, setHowerRow] = useState<string>("");
  const [scrollLeft, setScrollLeft] = useState(0);
  const [chenged, setChanged] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const controller = new AbortController();
    onFetchTaskGantProject(controller);
    return () => {
      onSetTasksProject([]);
      controller.abort();
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    if (ref?.current && scrollLeft !== 0 && !chenged) {
      ref.current.scrollTo({ left: scrollLeft - 200, behavior: "smooth" });
      setChanged(true);
    }
  }, [scrollLeft]); //eslint-disable-line

  const dates = useMemo(() => {
    const taskDates = filteredTasks.reduce(
      (acc, el) => {
        acc.min = Math.min(acc.min, new Date(el.date_start).getTime());
        acc.max = Math.max(acc.max, new Date(el.date_long || el.date_end).getTime());
        return acc;
      },
      { min: Infinity, max: -Infinity }
    );
    const startFirstSprint = new Date(project.sprints[0].date_start).getTime();
    const endLastSprint = new Date(project.sprints[project.sprints.length - 1].date_end).getTime();
    const minDate = taskDates.min <= startFirstSprint ? taskDates.min : startFirstSprint;
    const maxDate = taskDates.max >= endLastSprint ? taskDates.max : endLastSprint;
    return { minDate, maxDate };
  }, [filteredTasks, project.sprints]);

  return (
    <div className={classNames(styles.wrap, "scrollbar-medium-blue")} ref={ref}>
      {filteredTasks.length > 0 && (
        <div className={styles.content}>
          <div className={classNames(styles.tasks, { [styles.hidden]: !isShowTasks })}>
            <div className={styles.header}>
              <div className={styles.name}>{__("Номер")}</div>
              <div className={styles.author}> {__("И")}</div>
              <div className={styles.status}>{__("Статус")}</div>
              <div className={styles.toggle} onClick={() => setisShowTasks((prev) => !prev)}>
                <ArrowIcon width={7} hanging={12} fill="#fff" />
              </div>
            </div>
            <div className={styles.table}>
              {filteredTasks.map((task) => (
                <GantTask key={task.id} task={task} hoverRow={hoverRow} setHoverRow={setHowerRow} />
              ))}
            </div>
          </div>
          {tab === TAB.DAY && (
            <GantDay
              tasks={filteredTasks}
              hoverRow={hoverRow}
              setHoverRow={setHowerRow}
              setScrollLeft={setScrollLeft}
              minDate={dates.minDate}
              maxDate={dates.maxDate}
            />
          )}
          {tab === TAB.WEEK && (
            <GantWeek
              tasks={filteredTasks}
              hoverRow={hoverRow}
              setHoverRow={setHowerRow}
              setScrollLeft={setScrollLeft}
              minDate={dates.minDate}
              maxDate={dates.maxDate}
            />
          )}
          {tab === TAB.MONTH && (
            <GantMonth
              tasks={filteredTasks}
              hoverRow={hoverRow}
              setHoverRow={setHowerRow}
              setScrollLeft={setScrollLeft}
              minDate={dates.minDate}
              maxDate={dates.maxDate}
            />
          )}
          {tab === TAB.YEAR && (
            <GantYear
              tasks={filteredTasks}
              hoverRow={hoverRow}
              setHoverRow={setHowerRow}
              setScrollLeft={setScrollLeft}
              minDate={dates.minDate}
              maxDate={dates.maxDate}
            />
          )}
        </div>
      )}
    </div>
  );
};
