export const BUTTON_TYPES = {
  LIGHT_LONG: "light-long",
  SMALL: "small",
  LARGE: "large",
  FULL_WIDTH: "fullWidth",
  GREY: "grey",
  GREEN: "green",
  RED: "red",
  ICON: "icon",
  ROUND_GREY: "round-grey",
  ROUND_RED: "round-red",
  ROUND_GREEN: "round-green",
  ROUND_WHITE: "round-white",
  ICON_DISABLED: "icon-disabled",
  ICON_CHOSEN_DISABLED: "icon-chosen-disabled",
  TRANSPARENT: "transparent"
};
