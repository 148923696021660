import { LibraryElementsList } from "containers/Library/LibraryElementsList/LibraryElementsList";
import { useActions } from "hooks/useActions";
import { useDebounce } from "hooks/useDebounce";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";

const LibrarySearchPage = () => {
  const {
    objSearchParams: { chapter, id_dep, search_query, year, month }
  } = useSelectedObjSearchParams();

  const { onGetLibraryElements, onResetLibraryFolders } = useActions();

  const controllerSearch = new AbortController();

  const debounceCallback = useDebounce(() => onGetLibraryElements(controllerSearch), 300);

  useEffect(() => {
    onResetLibraryFolders();

    if (search_query) {
      debounceCallback();
    }

    return () => {
      controllerSearch.abort();
    };
  }, [chapter, id_dep, search_query, year, month]); //eslint-disable-line

  useEffect(() => {
    return () => {
      onResetLibraryFolders();
    };
  }, []); //eslint-disable-line

  return <LibraryElementsList />;
};

export default LibrarySearchPage;
