import { type Location, matchRoutes, useLocation } from "react-router-dom";

export const useCurrentPath = (path: string): boolean => {
  const location: Location = useLocation();
  const routes = matchRoutes([{ path }], location);
  if (routes && routes[0]?.route?.path === path) {
    return true;
  }

  return false;
};

export const useCurrentPathStarstWith = (path: string): boolean => {
  const { pathname } = useLocation();
  return pathname.startsWith(`/${path}`);
};
