import { ReactComponent as CheckIcon } from "assets/icons/checkmark.svg";
import classNames from "classnames";
import { useEffect, useState } from "react";

import styles from "./Button.module.sass";

const Button = ({
  children,
  className,
  isActive,
  disabled,
  onClick,
  animationClassName
}: {
  children: any;
  className?: string;
  disabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  animationClassName?: string;
}): JSX.Element => {
  const [position, setPosition] = useState({ left: "25%", top: "30%" });

  const getRandomCombination = () => {
    const combinations = [
      { left: "25%", top: "30%" },
      { left: "75%", top: "30%" },
      { left: "25%", top: "60%" },
      { left: "75%", top: "60%" }
    ];

    const randomIndex = Math.floor(Math.random() * combinations.length);
    return combinations[randomIndex];
  };

  useEffect(() => {
    const randomCombination = getRandomCombination();
    setPosition(randomCombination);
  }, []);

  return (
    <button
      disabled={disabled}
      className={classNames(styles.button, isActive && styles.active, className)}
      onClick={onClick}
    >
      <span className={classNames(styles.circle, animationClassName)} style={{ ...position }} />
      {isActive && <CheckIcon className={styles.icon} />}
      <div>{children}</div>
    </button>
  );
};

export default Button;
