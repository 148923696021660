import "bootstrap/dist/css/bootstrap.min.css";

import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow-2.svg";
import { ReactComponent as DotsHorizontalSquareIcon } from "assets/PrivateCabinet/dots-horizontal-square.svg";
import { ReactComponent as DotsVerticalRoundIcon } from "assets/PrivateCabinet/dots-vertical-round.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { DefaultButtonIcon } from "generalComponents/DefaultButtonIcon/DefaultButtonIcon";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IOverlayClasses, IPopoverData, OverlayPlacementType } from "models/generalComponents/popover/popoverOverlay";
import { FC, MouseEvent, ReactNode, useCallback, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { v4 as uuid } from "uuid";

import styles from "./PopoverContextMenu.module.sass";

interface IPopoverContextMenuProps {
  data: IPopoverData[] | IPopoverData[][];
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  classes?: IOverlayClasses;
  title?: string;
  placement?: OverlayPlacementType;
  iconVariant?: "vertical" | "horizontal" | "button" | "hidden";
  rootClose?: boolean;
  stopPropagation?: boolean;
  unzoomClickArea?: boolean;
  hoverClick?: boolean;
  children?: ReactNode;
  visible?: boolean;
  onEnter?: () => void;
  onExit?: () => void;
  btnSize?: ButtonSizeType;
}

export const PopoverContextMenu: FC<IPopoverContextMenuProps> = ({
  data,
  onClick,
  classes = {},
  title = "",
  placement = "auto",
  iconVariant = "vertical",
  rootClose = true,
  stopPropagation = true,
  unzoomClickArea = false,
  visible = false,
  children,
  onEnter,
  onExit,
  btnSize = ButtonSizeType.MEDIUM
}): JSX.Element => {
  const [show, setShow] = useState(false);
  const target = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClick(menuRef, () => setShow(false));

  const renderItem = (data: IPopoverData[]): JSX.Element => {
    return (
      <ul className={styles.popoverList} key={uuid()}>
        {data.map((item, i) =>
          item?.component ? (
            <li key={i}>{item.component}</li>
          ) : item?.next ? (
            <li key={i} className={classNames(styles.popoverItem, styles.nextItem)}>
              <div className={styles.popoverButton}>
                {item.img && <DefaultButtonIcon>{item.img}</DefaultButtonIcon>}
                <p className={styles.popoverButtonName}>{item.name}</p>
                <ArrowIcon className={styles.arrowIcon} width={8} height={12} />
              </div>
              <ul className={classNames(styles.popoverList, styles.nextList)}>
                {item.next.map((el, idx) => (
                  <li key={idx} className={styles.popoverItem}>
                    <button
                      className={styles.popoverButton}
                      onClick={(e) => {
                        e.preventDefault();
                        setShow(false);
                        el.callback(e);
                      }}
                      type="button"
                    >
                      <span className={styles.popoverButtonName}>{el.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </li>
          ) : (
            <li key={i} className={styles.popoverItem}>
              <button
                className={styles.popoverButton}
                onClick={(e) => {
                  e.preventDefault();
                  setShow(false);
                  item.callback(e);
                }}
                type="button"
              >
                {item.img && <DefaultButtonIcon>{item.img}</DefaultButtonIcon>}
                <span className={styles.popoverButtonName}>{item.name}</span>
              </button>
            </li>
          )
        )}
      </ul>
    );
  };

  const renderContent = () => {
    if (Array.isArray(data[0])) {
      return data.map((el) => renderItem(el as IPopoverData[]));
    }
    return renderItem(data as IPopoverData[]);
  };

  const renderIconVariant = useCallback(() => {
    if (children) return children;

    switch (iconVariant) {
      case "vertical":
        return <DotsVerticalRoundIcon className={styles.icon} />;

      case "horizontal":
        return <DotsHorizontalSquareIcon className={styles.icon} />;
    }
  }, [iconVariant, children]);

  const onButtonIconClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (stopPropagation) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (onClick) onClick(e);
      setShow((prev) => !prev);
    },
    [onClick, stopPropagation]
  );

  return (
    <>
      {iconVariant === "button" ? (
        <Button
          type="button"
          onClick={onButtonIconClick}
          ref={target}
          variant={ButtonVariantType.OPACITY}
          size={btnSize}
          iconL={<DotsVerticalRoundIcon fill="#274A42" />}
          className={classNames({ [styles.show]: show })}
          isSquare
          isActive={show}
        />
      ) : iconVariant === "hidden" ? (
        <button
          onClick={onButtonIconClick}
          className={classNames(styles.buttonIconHidden, {
            [styles.visible]: visible
          })}
          type="button"
          ref={target}
        >
          {renderIconVariant()}
        </button>
      ) : (
        <button
          onClick={onButtonIconClick}
          className={classNames(styles.buttonIcon, {
            [styles.hidden]: unzoomClickArea
          })}
          type="button"
          ref={target}
        >
          {renderIconVariant()}
        </button>
      )}
      <Overlay
        target={target.current}
        show={show}
        placement={placement}
        rootClose={rootClose}
        flip
        onEnter={onEnter}
        onExit={onExit}
      >
        <Popover
          onMouseLeave={(e) => e.stopPropagation()}
          onMouseEnter={(e) => e.stopPropagation()}
          className={classNames(classes?.popoverRoot, styles.popoverRoot)}
          style={{ maxWidth: 250 }}
          id="popover-context-menu"
        >
          <div ref={menuRef}>
            {title && (
              <Popover.Header className={classNames(classes?.popoverHeader, styles.popoverHeader)}>
                {title}
              </Popover.Header>
            )}
            <Popover.Body className={classNames(classes?.popoverBody, styles.popoverBody)}>
              {renderContent()}
            </Popover.Body>
          </div>
        </Popover>
      </Overlay>
    </>
  );
};
