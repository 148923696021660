import { CabinetActions, ICabinet, initialStateCabinet } from "models/store/Cabinet/Cabinet";
import { SearchTypes } from "Store/types/searchTypes";

import { ChatTypes } from "../types/chatTypes";
import { ModalTypes } from "../types/modalTypes";
import { PaintTypes } from "../types/paintTypes";

const INITIAL_STATE: ICabinet = initialStateCabinet();

export const CabinetReducer = (state = INITIAL_STATE, action: CabinetActions): ICabinet => {
  switch (action.type) {
    // SEARCH
    case SearchTypes.SEARCH: {
      return { ...state, search: action.payload };
    }

    // CHAT
    case ChatTypes.CHAT_GROUPS_LIST: {
      return { ...state, chat: { ...state.chat, groupsList: action.payload } };
    }
    case ChatTypes.RECENT_CHATS_LIST: {
      return {
        ...state,
        chat: { ...state.chat, recentChatsList: action.payload }
      };
    }
    case ChatTypes.SECRET_CHATS_LIST: {
      return {
        ...state,
        chat: { ...state.chat, secretChatsList: action.payload }
      };
    }
    case ChatTypes.CHAT_SELECTED_CONTACT: {
      return {
        ...state,
        chat: { ...state.chat, selectedContact: action.payload }
      };
    }
    case ChatTypes.CHAT_ID_USER: {
      return { ...state, chat: { ...state.chat, userId: action.payload } };
    }
    case ChatTypes.CHAT_GROUP_DELETE: {
      const groups = state.chat.groupsList.filter((gr) => gr.id !== action.payload.id);
      return { ...state, chat: { ...state.chat, groupsList: groups } };
    }
    case ChatTypes.SECRET_CHAT_DELETE: {
      const secretChats = state.chat.secretChatsList.filter((gr) => gr.id !== action.payload.id);
      return {
        ...state,
        chat: { ...state.chat, secretChatsList: secretChats }
      };
    }
    case ChatTypes.GET_MESSAGES: {
      return {
        ...state,
        chat: { ...state.chat, messages: { today: [], ...action.payload } }
      };
    }
    case ChatTypes.GET_CHAT_FILES: {
      return {
        ...state,
        chat: { ...state.chat, files: action.payload }
      };
    }
    case ChatTypes.GET_PREVIUS_MESSAGES: {
      let messages = { ...state.chat.messages };
      for (let key in action.payload) {
        messages[key] = messages[key]
          ? [
              ...messages[key].filter((oldMsg) => !action.payload[key].some((newMsg: any) => newMsg.id === oldMsg.id)),
              ...action.payload[key]
            ]
          : [...action.payload[key]];
      }
      return { ...state, chat: { ...state.chat, messages: messages } };
    }
    case ChatTypes.MESSAGE_DELETE: {
      return { ...state, chat: { ...state.chat, messages: action.payload } };
    }
    case ChatTypes.ADD_NEW_MESSAGE: {
      return {
        ...state,
        chat: {
          ...state.chat,
          messages: {
            ...state.chat.messages,
            today: state.chat.messages.today ? [action.payload, ...state.chat.messages.today] : [action.payload]
          }
        }
      };
    }
    case ChatTypes.NEW_LAST_GROUP_MESSAGE: {
      return {
        ...state,
        chat: {
          ...state.chat,
          recentGroupsMessages: {
            ...state.chat.recentGroupsMessages,
            [action.payload.id_group]: action.payload.text
          }
        }
      };
    }
    case ChatTypes.SET_NOTIFICATION_COUNTER: {
      return {
        ...state,
        chat: {
          ...state.chat,
          notificationsCounter: {
            ...state.chat.notificationsCounter,
            [action.payload.id]: action.payload.value
          }
        }
      };
    }
    case ChatTypes.INCREASE_NOTIFICATION_COUNTER: {
      return {
        ...state,
        chat: {
          ...state.chat,
          notificationsCounter: {
            ...state.chat.notificationsCounter,
            [action.payload]: (state.chat.notificationsCounter[action.payload] || 0) + 1
          }
        }
      };
    }
    case ChatTypes.SET_MESSAGE_LIFE_TIME: {
      return {
        ...state,
        chat: { ...state.chat, messageLifeTime: action.payload }
      };
    }
    case ChatTypes.INSERT_EMODJI: {
      return {
        ...state,
        chat: { ...state.chat, insertEmoji: action.payload }
      };
    }
    case ChatTypes.SET_CHAT_THEME: {
      return { ...state, chat: { ...state.chat, theme: action.payload } };
    }
    case ChatTypes.SET_MESSAGES_TO_READ: {
      let messages = { ...state.chat.messages };
      for (let [key, msgArr] of Object.entries(messages)) {
        msgArr.forEach((msg, i) => {
          if (action.payload.find((el: any) => el.id === msg.id)) {
            messages[key][i] = {
              ...msg,
              is_read: "1"
            };
          }
        });
      }
      return {
        ...state,
        chat: {
          ...state.chat,
          messages
        }
      };
    }
    case ChatTypes.SET_CHAT_CALLROOM: {
      return {
        ...state,
        chat: {
          ...state.chat,
          callRoom: { ...state.chat.callRoom, ...action.payload }
        }
      };
    }
    case ChatTypes.SET_CHAT_CALLROOM_STATE: {
      return {
        ...state,
        chat: {
          ...state.chat,
          callRoom: { ...state.chat.callRoom, state: action.payload }
        }
      };
    }
    case ChatTypes.SET_CHAT_CALLROOM_CONNECTED_USERS: {
      const connectedUsers = [...state.chat.callRoom.connectedUsers].filter(
        (it) => it.id_user !== action.payload.id_user
      );
      return {
        ...state,
        chat: {
          ...state.chat,
          callRoom: { ...state.chat.callRoom, connectedUsers: [...connectedUsers, action.payload] }
        }
      };
    }

    case ChatTypes.CHANGE_CHAT_CALLROOM_CONNECTED_USERS_MEDIA: {
      const connectedUsers = state.chat.callRoom.connectedUsers.map((it) => {
        if (it.id_user === action.payload.id_user) {
          return {
            ...it,
            media: action.payload.media
          };
        }
        return it;
      });
      return {
        ...state,
        chat: {
          ...state.chat,
          callRoom: { ...state.chat.callRoom, connectedUsers }
        }
      };
    }

    case ChatTypes.SET_CHAT_CALLROOM_CHANGE_CONNECTED_USER: {
      const connectedUsers = [...state.chat.callRoom.connectedUsers].map((it) =>
        it.id_user === action.payload.id_user ? action.payload : it
      );

      return {
        ...state,
        chat: {
          ...state.chat,
          callRoom: { ...state.chat.callRoom, connectedUsers }
        }
      };
    }

    // PAINT
    case PaintTypes.SET_PAINT: {
      return {
        ...state,
        paint: { ...state.paint, [action.payload.key]: action.payload.value }
      };
    }

    // GLOBAL MODAL
    case ModalTypes.SET_MODALS: {
      return {
        ...state,
        modals: { ...state.modals, [action.payload.key]: action.payload.value }
      };
    }

    case ModalTypes.SET_ERROR_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, error: action.payload }
      };
    }

    case ModalTypes.SET_SUCCESS_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, success: action.payload }
      };
    }

    case ModalTypes.SET_TOP_MESSAGE_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, topMessage: action.payload }
      };
    }

    case ModalTypes.SET_LOADER_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, loader: action.payload }
      };
    }

    case ModalTypes.SET_SHARE_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, share: action.payload }
      };
    }

    case ModalTypes.SET_PREVIEW_WITH_COMMENTS_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, previewWithComments: action.payload }
      };
    }

    case ModalTypes.SET_PRINT_SCREEN_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, printScreen: action.payload }
      };
    }

    case ModalTypes.SET_PREVIEW_FILE_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, previewFile: action.payload }
      };
    }

    case ModalTypes.SET_FILE_ACCESS_RIGHTS_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, fileAccessRights: action.payload }
      };
    }

    case ModalTypes.SET_CONTEXT_MENU_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, contextMenuModals: action.payload }
      };
    }

    case ModalTypes.SET_TASK_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, taskModals: action.payload }
      };
    }

    case ModalTypes.SET_LIBRARY_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, libraryModals: action.payload }
      };
    }

    case ModalTypes.SET_CALENDAR_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, calendarModals: action.payload }
      };
    }

    case ModalTypes.SET_PROJECT_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, projectModals: action.payload }
      };
    }

    case ModalTypes.SET_CREATE_FOLDER_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, createFolder: action.payload }
      };
    }

    case ModalTypes.SET_APPROVE_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, approveModal: action.payload }
      };
    }

    case ModalTypes.SET_ACCESS_IS_LIMITED_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, accessIsLimitedModal: action.payload }
      };
    }

    case ModalTypes.SET_PROPERTIES_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, propertiesModal: action.payload }
      };
    }

    case ModalTypes.SET_COPY_LINK_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, copyLinkModal: action.payload }
      };
    }

    case ModalTypes.SET_EDIT_ITEMS_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, editItemsModal: action.payload }
      };
    }

    case ModalTypes.SET_CUSTOMIZE_FILE_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, customizeFile: action.payload }
      };
    }

    case ModalTypes.SET_CREATE_ZIP_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, createZip: action.payload }
      };
    }

    case ModalTypes.DOWNLOAD_LINK: {
      return {
        ...state,
        modals: { ...state.modals, downloadLink: action.payload }
      };
    }

    case ModalTypes.FILE_VIEW: {
      return {
        ...state,
        modals: { ...state.modals, fileView: action.payload }
      };
    }

    case ModalTypes.FOLDER_NAVIGATION_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, folderNavigation: action.payload }
      };
    }

    case ModalTypes.FILE_PRINT: {
      return {
        ...state,
        modals: { ...state.modals, filePrint: action.payload }
      };
    }
    case ModalTypes.SET_NEXT_ACTION_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, nextAction: action.payload }
      };
    }

    case ModalTypes.SHARE_TO_MESSENGERS_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, shareToMessengers: action.payload }
      };
    }

    case ModalTypes.MAIL_NEW_MESSAGE_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, mailNewMessage: action.payload }
      };
    }
    case ModalTypes.SET_SAFE_MODALS: {
      return {
        ...state,
        modals: { ...state.modals, safeModals: action.payload }
      };
    }
    case ModalTypes.MOVE_TO_SAFE: {
      return {
        ...state,
        modals: { ...state.modals, moveToSafe: action.payload }
      };
    }
    case ModalTypes.SET_POSTBOX_MODALS: {
      return {
        ...state,
        modals: { ...state.modals, postboxModals: action.payload }
      };
    }

    case ModalTypes.SET_PROPERTIES_ELEMENT_MODALS: {
      return {
        ...state,
        modals: { ...state.modals, propertiesElementModals: action.payload }
      };
    }

    case ModalTypes.SET_RESTORE_FILE_MODALS: {
      return {
        ...state,
        modals: { ...state.modals, restoreFileModals: action.payload }
      };
    }

    case ModalTypes.SET_PROFILE_MODALS: {
      return {
        ...state,
        modals: { ...state.modals, profileModals: action.payload }
      };
    }
    case ModalTypes.SET_MOVE_ELEMENTS_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, moveElements: action.payload }
      };
    }

    case ModalTypes.SET_CREATE_OBJECT_LIBRARY_MODALS: {
      return {
        ...state,
        modals: { ...state.modals, createObjectLibraryModals: action.payload }
      };
    }

    case ModalTypes.ON_AVATAR_CROP: {
      return {
        ...state,
        modals: { ...state.modals, avatarCrop: action.payload }
      };
    }

    case ModalTypes.CONFIRM_CODE: {
      return {
        ...state,
        modals: { ...state.modals, confirmCode: action.payload }
      };
    }

    case ModalTypes.TARIFF_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, tariffs: action.payload }
      };
    }

    case ModalTypes.SET_ALL_FILE_VIEW_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, allFileViewModal: action.payload }
      };
    }

    case ModalTypes.CONTACTS_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, contactsModal: action.payload }
      };
    }

    case ModalTypes.SOCIAL_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, socialModal: action.payload }
      };
    }

    case ModalTypes.SET_CREATE_RUBRIC_LIBRARY_MODALS: {
      return {
        ...state,
        modals: { ...state.modals, createRubricLibraryModal: action.payload }
      };
    }

    case ModalTypes.SET_REMOVE_NON_EMPTY_RUBRIC_LIBRARY_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, removeNonEmptyRubricLibraryModal: action.payload }
      };
    }

    case ModalTypes.FOLDER_PASSWORD: {
      return {
        ...state,
        modals: { ...state.modals, folderPassModal: action.payload }
      };
    }
    case ModalTypes.MAIL_DASHBOARD_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, mailDashboardModal: action.payload }
      };
    }
    case ModalTypes.TRANSFER_DUTIES: {
      return {
        ...state,
        modals: { ...state.modals, transferDuties: action.payload }
      };
    }
    case ModalTypes.STATUS_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, statusModal: action.payload }
      };
    }

    case ModalTypes.CREATE_PROJECT: {
      return {
        ...state,
        modals: { ...state.modals, createProject: action.payload }
      };
    }

    case ModalTypes.CHANGE_STATUS_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, changeStatusModal: action.payload }
      };
    }

    case ModalTypes.ACCOUNT_DELETING_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, accountDeleting: action.payload }
      };
    }

    case ModalTypes.CREATE_PROJECT_ROLE: {
      return {
        ...state,
        modals: { ...state.modals, createProjectRole: action.payload }
      };
    }
    case ModalTypes.CREATE_PROJECT_TEAM: {
      return {
        ...state,
        modals: { ...state.modals, createProjectTeam: action.payload }
      };
    }
    case ModalTypes.CREATE_PROJECT_TASK: {
      return {
        ...state,
        modals: { ...state.modals, createProjectTask: action.payload }
      };
    }

    case ModalTypes.SET_CREATE_SPRINT_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, createSprintModal: action.payload }
      };
    }

    case ModalTypes.LINKS_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, linksModal: action.payload }
      };
    }

    case ModalTypes.LINKS_CATEGORY_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, linksCategoryModal: action.payload }
      };
    }

    case ModalTypes.SELECT_FILE: {
      return {
        ...state,
        modals: { ...state.modals, selectFile: action.payload }
      };
    }

    case ModalTypes.SET_CREATE_FILE_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, createFileModal: action.payload }
      };
    }

    case ModalTypes.TASKS_QUEUE_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, tasksQueue: action.payload }
      };
    }

    case ModalTypes.TASKS_CONNECTION_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, tasksConnection: action.payload }
      };
    }

    case ModalTypes.PROJECT_TASK_INFO:
      return {
        ...state,
        modals: { ...state.modals, taskProjectInfo: action.payload }
      };

    case ModalTypes.DASHBOARD_JOINT_PROJECT_STATISTIC: {
      return {
        ...state,
        modals: { ...state.modals, dashboardJointProjectStatistic: action.payload }
      };
    }

    case ModalTypes.DASHBOARD_JOINT_PROJECT_TEAM: {
      return {
        ...state,
        modals: { ...state.modals, dashboardJointProjectTeam: action.payload }
      };
    }

    case ModalTypes.TASK_OVERDUE: {
      return {
        ...state,
        modals: { ...state.modals, taskOverdue: action.payload }
      };
    }

    case ModalTypes.TAG_EDIT: {
      return {
        ...state,
        modals: { ...state.modals, tagEdit: action.payload }
      };
    }

    case ModalTypes.USER_DELETE: {
      return {
        ...state,
        modals: { ...state.modals, userDelete: action.payload }
      };
    }

    case ModalTypes.NOTE_EDIT: {
      return {
        ...state,
        modals: { ...state.modals, noteEdit: action.payload }
      };
    }

    case ModalTypes.RECORD_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, record: action.payload }
      };
    }

    case ModalTypes.NOTES_SIDEBAR: {
      return {
        ...state,
        modals: { ...state.modals, notesSidebar: action.payload }
      };
    }

    case ModalTypes.PASSWORD_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, passwordModal: action.payload }
      };
    }

    case ModalTypes.NOTES_CATEGORIES: {
      return {
        ...state,
        modals: { ...state.modals, notesCategories: action.payload }
      };
    }

    case ModalTypes.NOTES_FULL_CATEGORY: {
      return {
        ...state,
        modals: { ...state.modals, notesFullCategory: action.payload }
      };
    }

    default:
      return state;
  }
};
