import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

interface IProtectedRoutesProps {
  component: JSX.Element;
  redirectTo: string;
}

const PublicRoutes: FC<IProtectedRoutesProps> = ({ redirectTo, component }) => {
  const { uid } = useUserSelectors();
  return !uid ? component : <Navigate to={redirectTo} />;
};

export default PublicRoutes;
