import { ReactComponent as SearchIcon } from "assets/PrivateCabinet/search.svg";
import classNames from "classnames";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { NO_VALUE, projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { IContactShort } from "hooks/useContactsList";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IContact } from "models/store/user/user";
import { ChangeEvent, FC, useMemo, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { isEmail } from "utils/validation";

import styles from "./ContactsSelect.module.sass";

export interface IContactsSelectProps {
  contacts?: IContactShort[];
  addContact: (el: IContactShort) => void;
  selectedContacts?: IContact[];
  isSend?: boolean;
  item?: IFolder | IFile;
}

export const ContactsSelect: FC<IContactsSelectProps> = ({
  contacts,
  selectedContacts = [],
  addContact,
  isSend = false,
  item
}): JSX.Element => {
  const { __ } = useLocales();
  const { onSetShareToMessengersModal } = useActions();

  const { theme } = useUserSelectors();

  const [value, setValue] = useState<string>("");
  const [showDropDownMenu, setShowDropDownMenu] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useOutsideClick(selectRef, () => setShowDropDownMenu(false));

  const filtredContacts: IContactShort[] = useMemo(() => {
    return contacts
      ? contacts.filter((item: IContactShort) => {
          const includesName = item.name.toLowerCase().includes(value.toLowerCase());
          const includesSurname = item.sname.toLowerCase().includes(value.toLowerCase());
          const includesEmail = item.email.toLowerCase().includes(value.toLowerCase());

          return (
            (includesName || includesSurname || includesEmail) &&
            selectedContacts.findIndex((el) => el.id === item.id) < 0 &&
            item.email.length !== 0
          );
        })
      : [];
  }, [contacts, selectedContacts, value]);

  const onOpenContacts = (): void => {
    if (contacts && contacts.length > 0) {
      setShowDropDownMenu(true);
    }
  };

  const handleAddContact = (contact: IContactShort): void => {
    addContact(contact);
    setValue(NO_VALUE);
    setShowDropDownMenu(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    setShowDropDownMenu(true);
  };

  return (
    <div className={styles.root} ref={selectRef}>
      <div className={classNames(styles.selectedContactsWrapper, `scrollbar-thin-${theme}`)} onClick={onOpenContacts}>
        <input
          type="text"
          className={styles.searchInput}
          value={value}
          onChange={handleChange}
          placeholder={__("Имя или email")}
        />
        <SearchIcon className={styles.searchIcon} width={18} height={18} />
      </div>

      {showDropDownMenu && (
        <div className={classNames(styles.contactListWrapper, `scrollbar-thin-${theme}`)}>
          {filtredContacts.length > 0 || !value ? (
            <ul>
              {filtredContacts.map((contact) => (
                <li key={contact.id} className={styles.contactItem}>
                  <button onClick={() => handleAddContact(contact)} className={styles.contactButton} type="button">
                    <Avatar name={`${contact.name} ${contact.sname}`} imgSrc={projectSrc + contact.icon} />
                    <div className={styles.contactContentWrapper}>
                      <div className={styles.contactName}>{`${contact.name} ${contact.sname}`}</div>
                      <div className={styles.contactEmail}>{contact.email}</div>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            isSend && (
              <div className={styles.noUserBox}>
                <p className={styles.noUser}>{__("Пользователь не найден")}</p>
                {value.includes("@") && (
                  <>
                    <p className={styles.helperText}>
                      {__("Предоставить доступ для")} <span>{value}</span>?
                    </p>
                    <div className={styles.btns}>
                      <Button
                        type="button"
                        onClick={() =>
                          onSetShareToMessengersModal({
                            open: true,
                            params: item,
                            email: value
                          })
                        }
                        text={__("Отправить")}
                        variant={ButtonVariantType.BLUE}
                        size={ButtonSizeType.MEDIUM}
                        disabled={!isEmail(value)}
                      />
                    </div>
                  </>
                )}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};
