import { IContact } from "models/store/user/user";
import { ThunkAction } from "redux-thunk";

import api from "../../api";
import { CHAT_CALLROOM } from "../../generalComponents/variables/chat";
import { MODALS } from "../../generalComponents/variables/global";
import { IGenericWebRTCSocketSendMessage } from "../../models/generalComponents/hooks/WebRTC/useWebRTCSocketMessages";
import { CabinetActions } from "../../models/store/Cabinet/Cabinet";
import {
  IChatThemeState,
  IConnectedUser,
  IGroup,
  ISecretChat,
  MessageGroups,
  MessageTypes
} from "../../models/store/Cabinet/chat/chat";
import {
  ICallRoomPayload,
  IChatEditMessage,
  IMessageShortInfo
} from "../../models/store/Cabinet/chat/chatActionPayloads";
import { ChatActions } from "../../models/store/Cabinet/chat/chatActions";
import { ITopMessageTypes } from "../../models/store/Cabinet/modals/modals";
import { RootState } from "../reducers";
import { ChatTypes } from "../types/chatTypes";
import { ModalTypes } from "../types/modalTypes";

export const onGetChatGroups =
  (updateGroupUsersList: IGroup): ThunkAction<void, RootState, unknown, CabinetActions> =>
  async (dispatch, getState) => {
    const uid = getState().user.uid;

    api
      .get(`/ajax/chat_group_list.php`, {
        params: {
          uid
        }
      })
      .then((response) => {
        const data = response.data?.chat_groups;

        const newData = [];
        for (const key in data) {
          const group = data[key];
          newData.push({
            ...group,
            isGroup: true,
            users: Object.values(group.users)
          });
          if (updateGroupUsersList?.id_group === group.id_group)
            dispatch(
              onSetSelectedContact({
                ...updateGroupUsersList,
                users: Object.values(group.users)
              })
            );
        }

        dispatch({
          type: ChatTypes.CHAT_GROUPS_LIST,
          payload: newData
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
export const onGetRecentChatsList =
  (): ThunkAction<void, RootState, unknown, CabinetActions> => async (dispatch, getState) => {
    const uid = getState().user.uid;

    api
      .get(`/ajax/chat_list.php`, {
        params: {
          uid
        }
      })
      .then((response) => {
        if (response.data.ok) {
          dispatch({
            type: ChatTypes.CHAT_ID_USER,
            payload: response.data.id_user
          });

          const data = response.data?.data;
          const newData = [];
          for (const key in data) {
            newData.push({ ...data[key] });
          }
          dispatch({
            type: ChatTypes.RECENT_CHATS_LIST,
            payload: newData
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
export const onGetSecretChatsList =
  (): ThunkAction<void, RootState, unknown, CabinetActions> => async (dispatch, getState) => {
    const uid = getState().user.uid;

    api
      .get(`/ajax/chat_group_sec_list.php`, { params: { uid } })
      .then((response) => {
        if (response.data.ok) {
          const data: IGroup[] = response.data.chat_groups;
          const newData: ISecretChat[] = [];
          const userId = getState().Cabinet.chat.userId;
          for (const key in data) {
            const chat = Object.values(data[key].users).filter((item) => item.id !== userId)[0];
            if (chat)
              newData.push({
                ...chat,
                is_user: 1,
                real_user_date_last: chat.date_last,
                id: chat.id_group,
                is_secret_chat: true
              });
          }
          dispatch({
            type: ChatTypes.SECRET_CHATS_LIST,
            payload: newData
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
export const onDeleteChatGroup = (group: IGroup): ChatActions => {
  return {
    type: ChatTypes.CHAT_GROUP_DELETE,
    payload: group
  };
};
export const onDeleteSecretChat = (secretChat: ISecretChat): ChatActions => {
  return {
    type: ChatTypes.SECRET_CHAT_DELETE,
    payload: secretChat
  };
};
export const onGetChatMessages =
  (
    target: IContact | ISecretChat | IGroup,
    search: string,
    page: number,
    loadingMessages: (it: MessageGroups) => void
  ): ThunkAction<void, RootState, unknown, CabinetActions> =>
  async (dispatch, getState) => {
    const uid = getState().user.uid;
    const is_secret_chat = "is_secret_chat" in target ? target.is_secret_chat : undefined;
    const isGroup = "isGroup" in target ? target.isGroup : undefined;
    const id_real_user = "id_real_user" in target ? target.id_real_user : undefined;

    const url = `/ajax/chat${isGroup || is_secret_chat ? "_group" : ""}_message_get.php`;

    // TODO - delete url after testing messages
    // `/ajax/chat${isGroup || is_secret_chat ? "_group" : ""}_message_get.php?uid=${uid}&is_group=1${
    //           search ? `&search=${search}` : ""
    //         }${isGroup || is_secret_chat ? `&id_group=${target.id}` : `&id_user_to=${id_real_user}`}&page=${
    //           page || 1
    //         }&per_page=10`
    api
      .get(url, {
        params: {
          uid,
          is_group: 1,
          search,
          page: page || 1,
          per_page: 10,
          id_group: isGroup || is_secret_chat ? target.id : undefined,
          id_user_to: id_real_user
        }
      })
      .then((response) => {
        if (response.data.ok) {
          if (getState().Cabinet.chat.selectedContact?.id === target?.id) {
            const messages = response.data?.data ?? {};
            page > 1
              ? dispatch({
                  type: ChatTypes.GET_PREVIUS_MESSAGES,
                  payload: messages
                })
              : dispatch({
                  type: ChatTypes.GET_MESSAGES,
                  payload: messages
                });
            if (typeof loadingMessages === "function") loadingMessages(messages);
            if (page === 1)
              dispatch({
                type: ChatTypes.GET_CHAT_FILES,
                payload: { ...response.data?.attachments, links: response.data?.links }
              });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: ModalTypes.SET_MODALS,
          payload: {
            key: MODALS.TOP_MESSAGE,
            value: { open: true, type: ITopMessageTypes.ERROR, message: "Ошибка загрузки" }
          }
        });
        console.log(error);
      });
  };
export const addNewChatMessage =
  (msg: MessageTypes): ThunkAction<void, RootState, unknown, CabinetActions> =>
  (dispatch) => {
    dispatch({
      type: ChatTypes.ADD_NEW_MESSAGE,
      payload: msg
    });
  };
export const onSetSelectedContact = (contact: IContact | IGroup): ChatActions => {
  return {
    type: ChatTypes.CHAT_SELECTED_CONTACT,
    payload: contact
  };
};
export const onSetMessageLifeTime = (value: number): ChatActions => {
  return {
    type: ChatTypes.SET_MESSAGE_LIFE_TIME,
    payload: value
  };
};
export const onDeleteChatMessage =
  (message: IMessageShortInfo): ThunkAction<void, RootState, unknown, CabinetActions> =>
  (dispatch, getState) => {
    const oldMessages = getState().Cabinet.chat.messages;
    const messages = {
      ...oldMessages,
      [message.day]: oldMessages[message.day].filter((msg) => msg.id !== message.id)
    };
    dispatch({
      type: ChatTypes.MESSAGE_DELETE,
      payload: messages
    });
  };
export const onEditChatMessage =
  (
    editedData: IChatEditMessage,
    messageInfo: IMessageShortInfo
  ): ThunkAction<void, RootState, unknown, CabinetActions> =>
  (dispatch, getState) => {
    const oldMessages = getState().Cabinet.chat.messages;
    const messages = {
      ...oldMessages,
      [messageInfo.day]: oldMessages[messageInfo.day].map((msg) =>
        msg.id === messageInfo.id ? { ...msg, ...editedData } : msg
      )
    };
    dispatch({
      type: ChatTypes.MESSAGE_DELETE,
      payload: messages
    });
  };
export const changeChatTheme =
  (theme: IChatThemeState): ThunkAction<void, RootState, unknown, CabinetActions> =>
  async (dispatch) => {
    dispatch({
      type: ChatTypes.SET_CHAT_THEME,
      payload: theme
    });
  };
export const saveChatTheme =
  (themeName: string): ThunkAction<void, RootState, unknown, CabinetActions> =>
  async (dispatch, getState) => {
    const formData = new FormData();
    formData.set("uid", getState().user.uid);
    formData.set("chat_theme", themeName);

    api
      .post(`/ajax/user_edit2.php`, formData)
      .then((res) => {
        if (!res.data.ok) throw new Error();
      })
      .catch(() =>
        dispatch({
          type: ModalTypes.SET_MODALS,
          payload: {
            key: MODALS.TOP_MESSAGE,
            value: { open: true, type: ITopMessageTypes.ERROR, message: "Error" }
          }
        })
      );
  };
export const onReadMessages = (msgs: MessageTypes[]): ChatActions => {
  return {
    type: ChatTypes.SET_MESSAGES_TO_READ,
    payload: msgs
  };
};

export const setCallRoom = (payload: ICallRoomPayload): ChatActions => ({
  type: ChatTypes.SET_CHAT_CALLROOM,
  payload
});

export const setCallRoomState = (payload: CHAT_CALLROOM): ChatActions => ({
  type: ChatTypes.SET_CHAT_CALLROOM_STATE,
  payload
});

export const setCallRoomConnectedUsers = (payload: IConnectedUser): ChatActions => {
  return {
    type: ChatTypes.SET_CHAT_CALLROOM_CONNECTED_USERS,
    payload
  };
};

export const changeCallRoomConnectedUsers = (payload: IConnectedUser): ChatActions => ({
  type: ChatTypes.CHANGE_CHAT_CALLROOM_CONNECTED_USERS_MEDIA,
  payload
});

export const setCallRoomChangeConnectedUsers = (payload: IConnectedUser): ChatActions => ({
  type: ChatTypes.SET_CHAT_CALLROOM_CHANGE_CONNECTED_USER,
  payload
});

export const setCallRoomConnectedUsersHand =
  (payload: IGenericWebRTCSocketSendMessage): ThunkAction<void, RootState, unknown, ChatActions> =>
  (dispatch, getState) => {
    const user = getState().Cabinet.chat.callRoom.connectedUsers.find((it) => it.id_user === payload.from.id_user);
    dispatch(setCallRoomConnectedUsers({ ...user, handUp: payload.from.handUp }));
  };
