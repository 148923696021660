import { useActions } from "hooks/useActions";
import { initialFolderNavigationModalState } from "models/store/Cabinet/modals/modals";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

const FolderNavigation = (): JSX.Element => {
  const navigate = useNavigate();
  const { onSetFolderNavigationModal } = useActions();
  const {
    folderNavigation: {
      params: { url, options }
    }
  } = useGlobalModalsSelectors();

  useEffect(() => {
    navigate(url, options);
    onSetFolderNavigationModal(initialFolderNavigationModalState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default FolderNavigation;
