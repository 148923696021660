import { initialSharedState, ISharedState } from "models/store/shared/shared";
import { SharedActions } from "models/store/shared/sharedActions";
import { SharedTypes } from "Store/types/sharedTypes";

const INITIAL_STATE = initialSharedState();

export const SharedReducer = (state = INITIAL_STATE, action: SharedActions): ISharedState => {
  switch (action.type) {
    case SharedTypes.SET_SHARED:
      return { ...state, shared: action.payload };

    default:
      return state;
  }
};
