import EmployeesServicePanel from "containers/Profile/EmployeesServicePanel/EmployeesServicePanel";
import EmployeesTable from "containers/Profile/EmployeesTable/EmployeesTable";
import Clue from "generalComponents/Clue/Clue";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useActions } from "hooks/useActions";
import { IUserInfo } from "models/store/user/user";
import { useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

const ProfileEmployees = () => {
  const { __ } = useLocales();
  const { getEmployees } = useActions();
  const { employees } = useUserSelectors();
  const [search, setSearch] = useState<string>("");
  useEffect(() => {
    const controller = new AbortController();
    getEmployees();
    return () => {
      controller.abort();
    };
  }, []); // eslint-disable-line

  const filtredEmployees = useMemo(
    () =>
      employees?.filter((employee: IUserInfo) => {
        const nameMatches = employee.fname.toLowerCase().includes(search.toLowerCase());
        const snameMatches = employee.sname.toLowerCase().includes(search.toLowerCase());
        const pnameMatches = employee.pname.toLowerCase().includes(search.toLowerCase());
        return nameMatches || snameMatches || pnameMatches;
      }),
    [employees, search]
  );

  return (
    <WorkAreaWrap>
      <EmployeesServicePanel value={search} onChange={setSearch} />
      {filtredEmployees.length > 0 ? (
        <EmployeesTable employees={filtredEmployees} />
      ) : search.length > 0 ? (
        <Clue text={__("Нет сотрудников соответствующих параметрам поиска")} icon="sad" />
      ) : (
        <Clue text={__("Тут пока пусто")} icon="neutral" />
      )}
    </WorkAreaWrap>
  );
};

export default ProfileEmployees;
