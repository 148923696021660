import Invoices from "containers/Profile/Invoices/Invoices";
import Services from "containers/Profile/Services/Services";
import Tabs from "generalComponents/Tabs/Tabs";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./ProfileServices.module.sass";

const ProfileServices = () => {
  const { __ } = useLocales();
  const [active, setActive] = useState("services");

  const tabs = [
    { name: __("Услуги"), value: __("services"), onClick: () => setActive("services") },
    { name: __("Счета"), value: __("invoices"), onClick: () => setActive("invoices") },
    { name: __("Способы оплаты"), value: __("payments"), onClick: () => setActive("payments") }
  ];

  return (
    <WorkAreaWrap>
      <div className={styles.wrapper}>
        <Tabs tabs={tabs} value={active} size="large" variant="darkGreen" />
        <div className={styles.content}>
          {active === "services" && <Services />}
          {active === "invoices" && <Invoices />}
        </div>
      </div>
    </WorkAreaWrap>
  );
};

export default ProfileServices;
