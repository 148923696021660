import PopUp from "generalComponents/PopUp/PopUp";
import { POSTBOX_MODALS } from "generalComponents/variables/mail";
import { useActions } from "hooks/useActions";
import { initialPostboxModalState } from "models/store/Cabinet/modals/postboxModal";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import CheckPassPostbox from "./CheckPassPostbox/CheckPassPostbox";
import ConnectPostbox from "./ConnectPostbox/ConnectPostbox";
import CreatePostbox from "./CreatePostbox/CreatePostbox";
import Draft from "./Draft/Draft";
import EditPostboxFolder from "./EditPostboxFolder/EditPostboxFolder";
import MailFileView from "./MailFileView/MailFileView";
import MailPrint from "./MailPrint/MailPrint";
import NameChange from "./NameChange/NameChange";
import PasswordChange from "./PasswordChange/PasswordChange";
import PasswordRestore from "./PasswordRestore/PasswordRestore";
import PostboxSettings from "./PostboxSettings/PostboxSettings";
import SavePostboxFile from "./SavePostboxFile/SavePostboxFile";

const PostboxModals = () => {
  const { onSetPostboxModal } = useActions();
  const {
    postboxModals: { type, postbox, folder, letter, draft, preview, filename }
  } = useGlobalModalsSelectors();

  const closeModal = (): void => {
    onSetPostboxModal(initialPostboxModalState());
  };

  if (preview) {
    return <MailFileView closeModal={closeModal} preview={preview} />;
  }

  return (
    <PopUp set={closeModal}>
      {type === POSTBOX_MODALS.SETTINGS && <PostboxSettings closeModal={closeModal} />}
      {type === POSTBOX_MODALS.CREATE_ACCOUNT && <CreatePostbox closeModal={closeModal} />}
      {type === POSTBOX_MODALS.CONNECT_ACCOUNT && <ConnectPostbox closeModal={closeModal} />}
      {type === POSTBOX_MODALS.CHECK_PASS && <CheckPassPostbox closeModal={closeModal} postbox={postbox} />}
      {(type === POSTBOX_MODALS.ADD_FOLDER || type === POSTBOX_MODALS.EDIT_FOLDER) && (
        <EditPostboxFolder closeModal={closeModal} type={type} folder={folder} postbox={postbox} />
      )}
      {type === POSTBOX_MODALS.MAIL_PRINT && <MailPrint closeModal={closeModal} letter={letter} />}
      {type === POSTBOX_MODALS.DRAFT && <Draft closeModal={closeModal} draft={draft} />}
      {type === POSTBOX_MODALS.PASSWORD_RESTORE && <PasswordRestore closeModal={closeModal} />}
      {type === POSTBOX_MODALS.PASSWORD_CHANGE && <PasswordChange closeModal={closeModal} email={postbox.email} />}
      {type === POSTBOX_MODALS.NAME_CHANGE && <NameChange closeModal={closeModal} postbox={postbox} />}
      {type === POSTBOX_MODALS.SAVE_FILE && (
        <SavePostboxFile closeModal={closeModal} postbox={postbox} letter={letter} filename={filename} />
      )}
    </PopUp>
  );
};

export default PostboxModals;
