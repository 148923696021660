import { ProjectLibraryMainWorkSpace } from "containers/JoinProject/JoinProjectLibrary/components/Library/ProjectLibraryMainWorkSpace/ProjectLibraryMainWorkSpace";
import { ProjectLibraryServicePanel } from "containers/JoinProject/JoinProjectLibrary/components/Library/ProjectLibraryServicePanel/ProjectLibraryServicePanel";
import { ROUTES } from "generalComponents/variables/routing";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useCurrentPath } from "hooks/useCurrentPath";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

const ProjectLibraryPage = () => {
  const navigate = useNavigate();
  const { rights } = useUserSelectors();

  const { id } = useParams();

  const isProjectLibrary = useCurrentPath(`${ROUTES.PROJECT}/${id}/${ROUTES.LIBRARY}`);

  useEffect(() => {
    if (!rights.IS_LIBRARY) {
      navigate("/", { replace: true });
      return;
    }
  }, []); //eslint-disable-line

  return (
    <WorkAreaWrap>
      <ProjectLibraryServicePanel />
      {!isProjectLibrary ? <Outlet /> : <ProjectLibraryMainWorkSpace />}
    </WorkAreaWrap>
  );
};

export default ProjectLibraryPage;
