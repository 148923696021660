import api from "api";
import SearchItem from "containers/Search/SearchItem/SearchItem";
import Clue from "generalComponents/Clue/Clue";
import { Observer } from "generalComponents/Observer/Observer";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useLocation } from "react-router";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./SearchTable.module.sass";

const SearchTable = () => {
  const { __ } = useLocales();
  const { uid } = useUserSelectors();
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const containerRef = useRef(null);
  const controller = new AbortController();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const time_limit = searchParams.getAll("time_limit");
  const time = searchParams.getAll("time");
  const tools = searchParams.getAll("tools");
  const tagsArr = searchParams.getAll("tags");
  const colorsArr = searchParams.getAll("colors");
  const emoArr = searchParams.getAll("emo");
  const search = searchParams.get("query");

  const formatQueryParam = (values: string[]) => {
    return values.length > 0
      ? `${values.map((value) => (value.startsWith(",") ? value.slice(1) : value)).join(",")}`
      : "";
  };

  const fetchResults = async () => {
    try {
      const params = {
        uid,
        search,
        page,
        per_page: 30,
        time: formatQueryParam(time),
        time_limit: formatQueryParam(time_limit),
        tools: formatQueryParam(tools),
        tags: formatQueryParam(tagsArr),
        color: formatQueryParam(colorsArr),
        emo: formatQueryParam(emoArr)
      };
      const { data } = await api.get("/ajax/search.php", { params, signal: controller?.signal });
      setResult((prevResult) => [...prevResult, ...data.data]);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const debounceCallback = useDebounce(fetchResults, 300);

  useEffect(() => {
    setResult([]);
    setPage(1);
  }, [searchParams]);

  useEffect(() => {
    if (!search) return;
    setLoading(true);
    debounceCallback();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchParams]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>{__("Название")}</div>
        <div>{__("Последние изменения")}</div>
        <div>{__("Размер")}</div>
      </div>
      <div className={styles.content} ref={containerRef}>
        {!search?.length ? (
          <div className={styles.empty}>
            <Clue icon="happy" text={__("Введите поисковый запрос")} />
          </div>
        ) : result.length > 0 ? (
          result.map((item, index) => <SearchItem key={index} item={item} />)
        ) : (
          !loading && (
            <div className={styles.empty}>
              <Clue icon="sad" text={__("Здесь пока пусто")} />
            </div>
          )
        )}
        <Observer loader={loading} page={page} total={total} callBack={() => setPage(page + 1)} />
      </div>
    </div>
  );
};

export default SearchTable;
