import api from "api";
import Loader from "generalComponents/Loaders/4HUB";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useActions } from "hooks/useActions";
import { LoaderTypes } from "models/generalComponents/loader";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { getCurrentTimezoneOffset } from "utils/timezone";

const ProjectPage = () => {
  const { uid } = useUserSelectors();
  const { id } = useParams();
  const { onFetchCurrentProject, onUpdateProject } = useActions();
  const { project, projectList } = useJoinProjectsSelectors();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!id) return;
    const controller = new AbortController();
    const currentProject = projectList.find((item) => item.id === id);
    if (currentProject) {
      onFetchCurrentProject(id, controller, setLoading);
    }

    return () => {
      controller.abort();
    };
  }, [id]); // eslint-disable-line

  const updateProjectGmt = async () => {
    try {
      const params = new FormData();
      params.append("uid", uid);
      params.append("id_item", project.id);
      params.append("gmt", getCurrentTimezoneOffset());
      const { data } = await api.post("/ajax/project_edit.php", params);
      checkResponseStatus(data.ok);
      onUpdateProject(data.project);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!project) return;
    if (project.gmt !== getCurrentTimezoneOffset() && project.is_gmt_auto) {
      updateProjectGmt();
    }
  }, [project?.gmt]); // eslint-disable-line

  if (loading)
    return (
      <WorkAreaWrap>
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      </WorkAreaWrap>
    );

  if (project && id !== project.id) return;

  return <Outlet />;
};

export default ProjectPage;
