import { ReactComponent as AddIcon } from "assets/PrivateCabinet/add-2.svg";
import { ReactComponent as InfinitIcon } from "assets/PrivateCabinet/infin.svg";
import classNames from "classnames";
import { getTime, startOfDay } from "date-fns";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { STATUS_TYPES, TASK_MODALS, TASK_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { IMyTask, TASK_DEP } from "models/store/tasks/tasks";
import React from "react";
import { dateISO } from "utils/dateToISO";
import { INITIAL_ADD_TASK } from "utils/tasks";

import TaskLine from "../../../Tasks/Components/TaskLine/TaskLine";
import styles from "./TasksTimetableItem.module.sass";

interface ITasksTimetableItemProps {
  timeRows: Record<string, IMyTask[]>;
  timeToday: number;
  time: string;
  num: any;
  currentTask: IMyTask;
  setCurrentTask: (el: IMyTask) => void;
  currentTime: string;
  setCurrentTime: (el: string) => void;
}

const TasksTimetableItem: React.FC<ITasksTimetableItemProps> = ({
  timeRows,
  timeToday,
  time,
  num,
  currentTask,
  setCurrentTask,
  currentTime,
  setCurrentTime
}) => {
  const { onSetTaskModal, onEditTask, onUpdateTask } = useActions();
  const { formatDate } = useDateFormat();

  const onAddTask = (time: string): void => {
    const timeArr = time.split(":");
    const msTime = +timeArr[0] * 60 * 60 * 1000 + +timeArr[1] * 60 * 1000;

    onSetTaskModal({
      type: TASK_MODALS.ADD_TASK,
      params: {
        ...INITIAL_ADD_TASK,
        id_type: TASK_TYPES.TASK,
        date_start: dateISO(new Date(timeToday + msTime)),
        date_end: ``,
        id_dep: TASK_DEP.NO_DEP,
        id_status: STATUS_TYPES.QUEUE
      }
    });
  };
  const dragStart = (task: IMyTask) => {
    setCurrentTask(task);
  };
  const taskDragEnd = (): void => {
    setCurrentTime("");
    setCurrentTask(null);
  };

  const statusDragOver = (time: string): void => {
    setCurrentTime(time);
  };

  const statusDragLeave = (): void => {
    setCurrentTime("");
  };
  const statusOnDrop = (e: React.DragEvent<HTMLLIElement>, time: string): void => {
    e.preventDefault();
    if (formatDate(currentTask.date_start, "time") === time) {
      return;
    }

    const params = { id_task: currentTask.id, date_start: `${currentTask.date_start.slice(0, 11)}${time}` };
    onUpdateTask({ ...currentTask, date_start: `${currentTask.date_start.slice(0, 11)}${time}` });
    onEditTask(params, EVENT_TYPE.ADD, false, false);
    setCurrentTask(null);
    setCurrentTime("");
  };

  const renderTasks = (task: IMyTask): JSX.Element => {
    num.current += 1;
    return (
      <div
        key={task.id}
        draggable={timeToday === getTime(startOfDay(new Date(task.date_start)))}
        onDragStart={() => dragStart(task)}
        onDragEnd={taskDragEnd}
      >
        <TaskLine key={task.id} task={task} dayView num={num.current} isIcon />
      </div>
    );
  };

  return (
    <li
      className={classNames(styles.container, { [styles.stiky]: time === "0", [styles.isOver]: currentTime === time })}
      onDrop={(e) => statusOnDrop(e, time)}
      onDragOver={() => statusDragOver(time)}
      onDragLeave={statusDragLeave}
    >
      <div className={styles.time}>{time === "0" ? <InfinitIcon className={styles.infin} /> : time}</div>
      <div className={classNames(styles.tasks, { [styles.emptyTasks]: timeRows[time].length === 0 })}>
        {timeRows[time].length > 0 ? (
          timeRows[time].map((task) => renderTasks(task))
        ) : (
          <button className={styles.addBtn} onClick={() => onAddTask(time)}>
            <AddIcon />
          </button>
        )}
      </div>
    </li>
  );
};

export default TasksTimetableItem;
