export enum LibraryTypes {
  LIBRARY_PATH = "library",
  API_GET_FILES = "lsjson",
  API_GET_FOLDERS = "get_folders"
}

export enum DefaultLibraryRubrics {
  ALL_RUBRICS = "Все рубрики",
  MANAGEMENT_AND_LEADERSHIP = "Менеджмент и лидерство",
  FINANCE_AND_ACCOUNTING = "Финансы и бухгалтерия",
  MARKETING_AND_ADVERTISING = "Маркетинг и реклама",
  TECHNICAL_LITERATURE = "Техническая литература",
  DESIGN_AND_ART = "Дизайн и искусство",
  PROGRAMMING_LANGUAGES = "Языки программирования",
  CONFERENCES = "Конференции",
  PRESENTATIONS = "Презентации",
  EXHIBITIONS = "Выставки",
  BIRTHDAYS = "Дни рождения",
  HOLIDAYS = "Праздники"
}

export enum DefaultLibraryCategories {
  ALL_CATEGORIES = "Все категории",
  EDUCATION = "Обучение",
  BOOKS = "Книги",
  PHOTO = "Фото"
}

export enum LibraryTypesRubrics {
  ALL_RUBRICS = "",
  MANAGEMENT_AND_LEADERSHIP = "managment",
  FINANCE_AND_ACCOUNTING = "fin",
  MARKETING_AND_ADVERTISING = "marketing",
  TECHNICAL_LITERATURE = "tech",
  DESIGN_AND_ART = "design",
  PROGRAMMING_LANGUAGES = "prog",
  CONFERENCES = "conf",
  PRESENTATIONS = "present",
  EXHIBITIONS = "exhibitions",
  BIRTHDAYS = "birthday",
  HOLIDAYS = "holidays"
}

export enum LibraryCategoryTypes {
  EDUCATION = "education",
  BOOKS = "books",
  PHOTO = "photo"
}

export enum TABS {
  OBJECTS = "objects",
  RUBRICS = "rubrics"
}
