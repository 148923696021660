import { ReactComponent as InfoIcon } from "assets/icons/info_3.svg";
import { ReactComponent as ReturnIcon } from "assets/icons/restore_2.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash_2.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload_2.svg";
import { msToDateObject } from "generalComponents/Calendars/CalendarHelper";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { PROJECT_PATH, ROUTES, ROUTES_SEARCH_PARAMS } from "generalComponents/variables/routing";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { initialPropertiesElementModalState } from "models/store/Cabinet/modals/propertiesElementModals";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IProject } from "models/store/joinProjects/joinProgects";
import { ISafe } from "models/store/safe/safe";
import { IMyTask, TaskFilters } from "models/store/tasks/tasks";
import { TypeElements } from "models/typeElements/typeElements";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { getTheRestorePath } from "utils/getTheRestorePath";

export const useContextArchivedFile = () => {
  const { __ } = useLocales();
  const {
    onRestoreArchivedFiles,
    onTrashArchivedFiles,
    onSetPropertiesElementModal,
    onSetRestoreFileModal,
    fetchFileZipLink
  } = useActions();
  const navigate = useNavigate();
  const { archivedDirId } = useParams();

  const onRestoreFile = (file: IFile) => {
    if (archivedDirId) {
      onSetRestoreFileModal({
        open: true,
        element: file
      });
    } else {
      const callback = () => {
        if (TypeElements.FILES === file.table) {
          navigate(
            getTheRestorePath({
              path: ROUTES.FOLDERS,
              id_dir: file.id_dir,
              id_parent: undefined,
              search: `${ROUTES_SEARCH_PARAMS.byDateChanged}`
            })
          );
        }

        if (TypeElements.SAFE_FILES === file.table) {
          navigate(`/${ROUTES.SAFE}/${file.id_safe}${ROUTES_SEARCH_PARAMS.byDateChanged}`);
        }
      };

      onRestoreArchivedFiles(file, { id_safe: file.id_safe }, callback);
    }
  };

  const onTrashFile = (file: IFile) => {
    onTrashArchivedFiles(file);
  };

  return {
    contextMenuArchivedFileCallback: (file: IFile): IPopoverData[] => [
      {
        name: __("Восстановить"),
        img: <ReturnIcon />,
        callback: (): void => onRestoreFile(file)
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        callback: (): void => {
          onSetPropertiesElementModal({
            open: true,
            element: file,
            onRestore: () => {
              onRestoreFile(file);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            },
            onTrash: () => {
              onTrashFile(file);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            }
          });
        }
      },
      {
        name: __("Скачать"),
        img: <UploadIcon />,
        callback: (): void => {
          fetchFileZipLink(file.fid);
        }
      },
      {
        name: __("Удалить"),
        img: <DeleteIcon />,
        callback: (): void => onTrashFile(file)
      }
    ]
  };
};

export const useContextArchivedFolder = () => {
  const { __ } = useLocales();
  const {
    onRestoreArchivedFolder,
    onTrashArchivedFolder,
    onSetPropertiesElementModal,
    onSetFolderPassModal,
    fetchFolderZipLink,
    onSetRestoreFileModal
  } = useActions();
  const navigate = useNavigate();
  const { archivedDirId } = useParams();

  const onRestoreFolder = (folder: IFolder) => {
    if (archivedDirId) {
      onSetRestoreFileModal({
        open: true,
        element: folder
      });
    } else {
      const payload = {
        id_dirs: [folder.id_dir]
      };

      const callback = () => {
        navigate(
          getTheRestorePath({
            path: ROUTES.FOLDERS,
            id_dir: folder.id_dir,
            id_parent: folder.id_parent,
            search: `${ROUTES_SEARCH_PARAMS.byDateChanged}`
          })
        );
      };

      onRestoreArchivedFolder(folder, payload, callback);
    }
  };

  const onTrashFolder = (folder: IFolder) => {
    onTrashArchivedFolder(folder);
  };

  return {
    contextMenuArchivedFolderCallback: (folder: IFolder): IPopoverData[] => [
      {
        name: __("Восстановить"),
        img: <ReturnIcon />,
        callback: (): void => onRestoreFolder(folder)
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        callback: (): void => {
          if (folder.pass) {
            onSetFolderPassModal({
              open: true,
              type: "enterPassword",
              folder,
              callbackOk: () => {
                onSetPropertiesElementModal({
                  open: true,
                  element: folder,
                  onRestore: () => {
                    onRestoreFolder(folder);
                    onSetPropertiesElementModal(initialPropertiesElementModalState());
                  },
                  onTrash: () => {
                    onTrashFolder(folder);
                    onSetPropertiesElementModal(initialPropertiesElementModalState());
                  }
                });
              }
            });
          } else {
            onSetPropertiesElementModal({
              open: true,
              element: folder,
              onRestore: () => {
                onRestoreFolder(folder);
                onSetPropertiesElementModal(initialPropertiesElementModalState());
              },
              onTrash: () => {
                onTrashFolder(folder);
                onSetPropertiesElementModal(initialPropertiesElementModalState());
              }
            });
          }
        }
      },
      {
        name: __("Скачать"),
        img: <UploadIcon />,
        isSystem: false,
        callback: (): void => {
          if (folder.pass) {
            onSetFolderPassModal({
              open: true,
              type: "enterPassword",
              folder,
              callbackOk: () => fetchFolderZipLink(folder.id_dir, __("Не удалось скачать папку"))
            });
          } else {
            fetchFolderZipLink(folder.id_dir, __("Не удалось скачать папку"));
          }
        }
      },
      {
        name: __("Удалить"),
        img: <DeleteIcon />,
        callback: (): void => onTrashFolder(folder)
      }
    ]
  };
};

export const useContextArchivedTasks = () => {
  const { __ } = useLocales();
  const {
    onRestoreArchivedTasks,
    onTrashArchivedTasks,
    onSetPropertiesElementModal,
    onSelectFilterDate,
    onSelectFilterType
  } = useActions();
  const navigate = useNavigate();

  const onRestoreTasks = (task: IMyTask) => {
    const callback = () => {
      onSelectFilterDate(msToDateObject(task.date_start));
      onSelectFilterType(TaskFilters.BY_DAY);

      if (task.table === TypeElements.TASKS && task.id_type === TASK_TYPES.REMINDER) {
        navigate(`/${ROUTES.CALENDAR}`);
      } else {
        navigate(`/${ROUTES.TASKS}`);
      }
    };

    onRestoreArchivedTasks(task, callback);
  };

  const onTrashTasks = (task: IMyTask) => {
    onTrashArchivedTasks(task);
  };

  return {
    contextMenuArchivedTasksCallback: (task: IMyTask): IPopoverData[] => [
      {
        name: __("Восстановить"),
        img: <ReturnIcon />,
        callback: (): void => onRestoreTasks(task)
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        callback: (): void => {
          onSetPropertiesElementModal({
            open: true,
            element: task,
            onRestore: () => {
              onRestoreTasks(task);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            },
            onTrash: () => {
              onTrashTasks(task);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            }
          });
        }
      },
      {
        name: __("Удалить"),
        img: <DeleteIcon />,
        callback: (): void => onTrashTasks(task)
      }
    ]
  };
};

export const useContextArchivedSafe = () => {
  const { __ } = useLocales();
  const { onRestoreArchivedSafe, onTrashArchivedSafe, onSetPropertiesElementModal } = useActions();
  const navigate = useNavigate();

  const onRestoreSafe = (safe: ISafe) => {
    const callback = () => {
      navigate(`/${ROUTES.SAFE}/${safe.id}${ROUTES_SEARCH_PARAMS.byDateChanged}`);
    };

    onRestoreArchivedSafe(safe, callback);
  };

  const onTrashSafe = (safe: ISafe) => {
    onTrashArchivedSafe(safe);
  };

  return {
    contextMenuArchivedSafeCallback: (safe: ISafe): IPopoverData[] => [
      {
        name: __("Восстановить"),
        img: <ReturnIcon />,
        callback: (): void => onRestoreSafe(safe)
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        callback: (): void => {
          onSetPropertiesElementModal({
            open: true,
            element: safe,
            onRestore: () => {
              onRestoreSafe(safe);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            },
            onTrash: () => {
              onTrashSafe(safe);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            }
          });
        }
      },
      {
        name: __("Удалить"),
        img: <DeleteIcon />,
        callback: (): void => onTrashSafe(safe)
      }
    ]
  };
};

export const useContextArchivedProject = () => {
  const { __ } = useLocales();
  const { onSetPropertiesElementModal, onChangeArchivedProject } = useActions();

  const navigate = useNavigate();

  const onRestoreProjectCallback = (project: IProject) => {
    const callback = () => {
      navigate(`/${ROUTES.PROJECT}/${project.id}/${PROJECT_PATH.TASKS}`);
    };

    onChangeArchivedProject(EVENT_TYPE.EDIT, project, callback);
  };

  const onTrashProjectCallback = (project: IProject) => {
    onChangeArchivedProject(EVENT_TYPE.IS_DEL, project);
  };

  return {
    contextMenuArchivedProjectCallback: (project: IProject): IPopoverData[] => [
      {
        name: __("Восстановить"),
        img: <ReturnIcon />,
        callback: (): void => onRestoreProjectCallback(project)
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        callback: (): void => {
          onSetPropertiesElementModal({
            open: true,
            element: project,
            onRestore: () => {
              onRestoreProjectCallback(project);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            },
            onTrash: () => {
              onTrashProjectCallback(project);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            }
          });
        }
      },
      {
        name: __("Удалить"),
        img: <DeleteIcon />,
        callback: (): void => onTrashProjectCallback(project)
      }
    ]
  };
};
