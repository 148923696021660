import { ArchiveElementsList } from "containers/Archive/ArchiveElementsList/ArchiveElementsList";
import { ArchiveServicePanel } from "containers/Archive/ArchiveServicePanel/ArchiveServicePanel";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams, useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

export const ArchivePage = () => {
  const navigate = useNavigate();
  const { archivedDirId } = useParams();
  const {
    onGetAllArchivedFiles,
    onNullifyArchivedFiles,
    onResetArhivedFilesList,
    onNullifySortByDate,
    onNullifyFiltersTypeElements
  } = useActions();

  const {
    objSearchParams: { group, sort, sort_reverse, table, id_type, year, month, day }
  } = useSelectedObjSearchParams();

  const { color, emo, tags } = useAllSelectedSearchParams();

  const { rights } = useUserSelectors();

  useEffect(() => {
    const controllerFiles = new AbortController();

    if (!archivedDirId) {
      onResetArhivedFilesList();
      onGetAllArchivedFiles(controllerFiles);

      return () => {
        onNullifyArchivedFiles();
        controllerFiles.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    color.selectedValue,
    emo.selectedValue,
    tags.selectedValue?.length,
    group,
    sort,
    sort_reverse,
    year,
    month,
    day,
    table,
    id_type,
    archivedDirId
  ]);

  useEffect(() => {
    if (!rights.IS_ARCHIVE) {
      navigate("/", { replace: true });
      return;
    }

    return () => {
      onNullifyArchivedFiles();
      onNullifyFiltersTypeElements();
      onNullifySortByDate();
    };
  }, []); //eslint-disable-line

  return (
    <WorkAreaWrap>
      <ArchiveServicePanel />
      {archivedDirId ? <Outlet /> : <ArchiveElementsList />}
    </WorkAreaWrap>
  );
};
