import { FC } from "react";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";

import MailAccountItem from "../MailAccountItem/MailAccountItem";
import styles from "./MailAccountList.module.sass";

interface IMailAccountListProps {
  listCollapsed: boolean;
}
const MailAccountList: FC<IMailAccountListProps> = ({ listCollapsed }) => {
  const { postboxes } = usePostboxSelectors();

  return (
    <div className={styles.list}>
      {postboxes.length > 0 &&
        postboxes.map((postbox) => (
          <MailAccountItem
            key={postbox.id}
            postbox={postbox}
            folders={postbox.folders}
            listCollapsed={listCollapsed}
            isOpen={postboxes.length === 1}
          />
        ))}
    </div>
  );
};

export default MailAccountList;
