import classNames from "classnames";
import { messengerIcons, socialMediaIcons } from "collections/contactsIcons";
import ProfileControl from "containers/Profile/ContactSingle/ProfileControl/ProfileControl";
import Avatar from "generalComponents/Avatar/Avatar";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useDateFormat } from "hooks/useDateFormat";
import { IContact } from "models/store/user/user";
import { useLocales } from "react-localized";

import styles from "./ContactSingle.module.sass";

interface IContactSingle {
  data: IContact;
}

const ContactSingle = ({ data }: IContactSingle) => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Avatar name={data.name + " " + data.sname} size={48} imgSrc={projectSrc + data.icon} />
        <div className={styles.names}>
          <div className={styles.name}>{data.name + " " + data.sname}</div>
          <span className={styles.date}>
            {__("Добавлен")} &nbsp;
            {formatDate(data.ut, "date")}
          </span>
        </div>
        <ProfileControl data={data} />
      </div>
      <div className={styles.body}>
        <ul className={styles.list}>
          {data.tel.map((telephone, index) => (
            <li key={index}>
              <span className={styles.title}>
                {__("Телефон")} {index > 0 && index}
              </span>
              <a href={`tel:${telephone}`} className={styles.value}>
                {telephone}
              </a>
            </li>
          ))}
        </ul>

        <ul className={styles.list}>
          {data.email.map((email, index) => (
            <li key={index}>
              <span className={styles.title}>
                {__("Email")} {index > 0 && index}
              </span>
              <a href={`mailto:${email}`} className={styles.value}>
                {email}
              </a>
            </li>
          ))}
        </ul>
        {data.bdate && (
          <div className={classNames(styles.list, styles.listFlex)}>
            <span className={styles.title}>{__("День рождения")}</span>
            <div className={styles.value}>{formatDate(data.bdate, "date")}</div>
          </div>
        )}
        {data.soc.length > 0 && (
          <div className={classNames(styles.list, styles.listFlex)}>
            <span className={styles.title}>{__("Социальные сети")}</span>
            <ul className={styles.inlineList}>
              {data.soc.map((social, index) => (
                <li key={index} className={styles.inlineList}>
                  <a href={social.link} target="_blank" rel="noopener noreferrer">
                    {socialMediaIcons[social.type]}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {data.mes.length > 0 && (
          <div className={classNames(styles.list, styles.listFlex)}>
            <span className={styles.title}>{__("Мессенджеры")}</span>
            <ul className={styles.inlineList}>
              {data.mes.map((messenger, index) => (
                <li key={index} className={styles.inlineList}>
                  <a href={messenger.link} target="_blank" rel="noopener noreferrer">
                    {messengerIcons[messenger.type]}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {data.prim.length > 0 && (
          <div className={classNames(styles.list, styles.listFlex)}>
            <span className={styles.title}>{__("Заметка")}</span>
            <div className={styles.value}>{data.prim}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactSingle;
