import classNames from "classnames";
import { STYLED_CLASSES, TASKS_SCHEMA } from "generalComponents/variables/tasks";
import React, { createContext, useState } from "react";

import Calls from "../Calls/Calls";
import Mails from "../Mails/Mails";
import Meetings from "../Meetings/Meetings";
import MyTasks from "../MyTasks/MyTasks";
import styles from "./GridBoard.module.sass";

export interface IMyTasksProvider {
  schema: TASKS_SCHEMA;
  setSchema: (cb: (el: TASKS_SCHEMA) => TASKS_SCHEMA) => void;
}

export const MyTasksProvider = createContext<IMyTasksProvider>({
  schema: TASKS_SCHEMA.GRID_BAR,
  setSchema: () => {}
});

const GridBoard: React.FC = (): JSX.Element => {
  const [schema, setSchema] = useState<TASKS_SCHEMA>(TASKS_SCHEMA.GRID_BAR);

  return (
    <MyTasksProvider.Provider value={{ schema, setSchema }}>
      <div className={classNames(styles.girdBoardWrap, styles[schema])}>
        <div className={classNames(styles.taskBoardWrap, styles[STYLED_CLASSES[schema][0]])}>{<Meetings />}</div>
        <div className={classNames(styles.taskBoardWrap, styles[STYLED_CLASSES[schema][1]])}>{<Calls />}</div>
        <div className={classNames(styles.taskBoardWrap, styles[STYLED_CLASSES[schema][2]])}>{<Mails />}</div>
        <div className={classNames(styles.taskBoardWrap, styles[STYLED_CLASSES[schema][3]])}>{<MyTasks />}</div>
      </div>
    </MyTasksProvider.Provider>
  );
};

export default GridBoard;
