import classNames from "classnames";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { FC } from "react";

import styles from "./Options.module.sass";

interface OptionsProps {
  options?: ISelectCustom[];
  selected?: Omit<ISelectCustom, "callback">;
  handleClick: (el: ISelectCustom) => void;
}

export const Options: FC<OptionsProps> = ({ options, selected, handleClick }) => (
  <ul className={styles.list}>
    {options.map((el, i) => (
      <li key={i} className={styles.list__item}>
        {el.name && (
          <button
            type="button"
            onClick={() => {
              handleClick(el);
            }}
            className={classNames(styles.list__btn, { [styles.active]: selected.value === el.value })}
          >
            <span>{el.name}</span>
          </button>
        )}
      </li>
    ))}
  </ul>
);
