import ErrorModal from "generalComponents/Error/ErrorModal";
import Loader from "generalComponents/Loaders/4HUB";
import DashboardJointProjectStatisticModal from "generalComponents/Modals/Components/DashboardJointProjectStatisticModal/DashboardJointProjectStatisticModal";
import DashboardJointProjectTeamModal from "generalComponents/Modals/Components/DashboardJointProjectTeamModal/DashboardJointProjectTeamModal";
import ChangeStatusModal from "generalComponents/Modals/Components/JointProject/ChangeStatusModal/ChangeStatusModal";
import LinksCategoryModal from "generalComponents/Modals/Components/JointProject/LinksCategoryModal/LinksCategoryModal";
import LinksModal from "generalComponents/Modals/Components/JointProject/LinksModal/LinksModal";
import StatusModal from "generalComponents/Modals/Components/JointProject/StatusModal/StatusModal";
import MailDashboardModal from "generalComponents/Modals/Components/MailDashboardModal/MailDashboardModal";
import NoteEditModal from "generalComponents/Modals/Components/NoteEditModal/NoteEditModal";
import NotesCategoriesModal from "generalComponents/Modals/Components/NotesCategoriesModal/NotesCategoriesModal";
import NotesFullCategoryModal from "generalComponents/Modals/Components/NotesFullCategoryModal/NotesFullCategoryModal";
import PasswordModal from "generalComponents/Modals/Components/PasswordModal/PasswordModal";
import ContactsModal from "generalComponents/Modals/Components/ProfileModals/ContactsModal/ContactsModal";
import SocialModal from "generalComponents/Modals/Components/ProfileModals/SocialModal/SocialModal";
import RecordModal from "generalComponents/Modals/Components/RecordModal/RecordModal";
import TagEditModal from "generalComponents/Modals/Components/TagEditModal/TagEditModal";
import TariffModal from "generalComponents/Modals/Components/TariffModal/TariffModal";
import TasksConnectionModal from "generalComponents/Modals/Components/TasksConnectionModal/TasksConnectionModal";
import TasksQueueModal from "generalComponents/Modals/Components/TasksQueueModal/TasksQueueModal";
import UserDeleteModal from "generalComponents/Modals/Components/UserDeleteModal/UserDeleteModal";
import SuccessModal from "generalComponents/SuccessModal/SuccessModal";
import { LoaderTypes } from "models/generalComponents/loader";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { usePaintSelectors } from "Store/selectors/paintSelectors";

import AccessIsLimitedModal from "./Components/AccessIsLimitedModal/AccessIsLimitedModal";
import { AccountDeletingModal } from "./Components/AccountDeletingModal/AccountDeletingModal";
import { AllFileViewModal } from "./Components/AllFileViewModal/AllFileViewModal";
import ApproveModal from "./Components/ApproveModal/ApproveModal";
import AvatarCrop from "./Components/AvatarCrop/AvatarCrop";
import ChatModals from "./Components/ChatModals/ChatModals";
import ConfirmCodeModal from "./Components/ConfirmCodeModal/ConfirmCodeModal";
import { CopyLinkModal } from "./Components/CopyLinkModal/CopyLinkModal";
import CreateFile from "./Components/CreateFile/CreateFile";
import CreateFolder from "./Components/CreateFolder/CreateFolder";
import { CreateObjectLibraryModal } from "./Components/CreateObjectLibraryModal/CreateObjectLibraryModal";
import CreateProject from "./Components/CreateProject/CreateProject";
import CreateProjectRole from "./Components/CreateProjectRole/CreateProjectRole";
import CreateProjectTask from "./Components/CreateProjectTask/CreateProjectTask";
import CreateProjectTeam from "./Components/CreateProjectTeam/CreateProjectTeam";
import { CreateRubricLibraryModal } from "./Components/CreateRubricLibraryModal/CreateRubricLibraryModal";
import { CreateSprintModal } from "./Components/CreateSprintModal/CreateSprintModal";
import CreateZip from "./Components/CreateZip/CreateZip";
import DownloadOfLink from "./Components/DownloadOfLink/DownloadOfLink";
import EditItemsModal from "./Components/EditItemsModal/EditItemsModal";
import FilePrint from "./Components/FilePrint/FilePrint";
import FileView from "./Components/FileView/FileView";
import FolderNavigation from "./Components/FolderNavigation/FolderNavigation";
import FolderPassword from "./Components/FolderPassword/FolderPassword";
import MoveElements from "./Components/MoveElements/MoveElements";
import MoveToSafe from "./Components/MoveToSafe/MoveToSafe";
import MutualEdit from "./Components/MutualEdit/MutualEdit";
import NextActionModal from "./Components/NextActionModal/NextActionModal";
import MailNewMessage from "./Components/PostboxModals/MailNewMessage/MailNewMessage";
import PostboxModals from "./Components/PostboxModals/PostboxModals";
import PreviewFile from "./Components/PreviewFile/PreviewFile";
import PrintScreen from "./Components/PrintScreen/PrintScreen";
import ProfileModals from "./Components/ProfileModals/ProfileModals";
import ProjectModals from "./Components/ProjectModals/ProjectModals";
import Properties from "./Components/Properties/Properties";
import { PropertiesElementModals } from "./Components/PropertiesElementModals/PropertiesElementModals";
import { RemovingNonEmptyRubricLibraryModal } from "./Components/RemovingNonEmptyRubricLibraryModal/RemovingNonEmptyRubricLibraryModal";
import RenameFile from "./Components/RenameFile/RenameFile";
import { RestoreFileModal } from "./Components/RestoreFileModal/RestoreFileModal";
import SafeModals from "./Components/SafeModals/SafeModals";
import SelectFile from "./Components/SelectFile/SelectFile";
import Share from "./Components/Share/Share";
import ShareToMessengers from "./Components/ShareToMessengers/ShareToMessengers";
import TaskModals from "./Components/TaskModals/TaskModals";
import TaskOverdueModal from "./Components/TaskOverdueModal/TaskOverdueModal";
import TaskProjectInfoModal from "./Components/TaskProjectInfoModal/TaskProjectInfoModal";
import TopMessage from "./Components/TopMessage/TopMessage";
import TransferDuties from "./Components/TransferDuties/TransferDuties";
import UploadFiles from "./Components/UploadFiles/UploadFiles";

const Modals = (): JSX.Element => {
  const { mutualEdit } = usePaintSelectors();
  const { uploadFiles } = useFilesSelectors();
  const { project } = useJoinProjectsSelectors();

  const projectUploadFiles = project?.uploadFiles;

  const {
    error,
    success,
    loader,
    share,
    printScreen,
    previewFile,
    topMessage,
    taskModals,
    projectModals,
    createFolder,
    approveModal,
    accessIsLimitedModal,
    propertiesModal,
    copyLinkModal,
    editItemsModal,
    customizeFile,
    createZip,
    downloadLink,
    fileView,
    folderNavigation,
    filePrint,
    nextAction,
    shareToMessengers,
    mailNewMessage,
    safeModals,
    moveToSafe,
    postboxModals,
    propertiesElementModals,
    restoreFileModals,
    profileModals,
    createObjectLibraryModals,
    moveElements,
    avatarCrop,
    confirmCode,
    tariffs,
    allFileViewModal,
    contacts,
    socialModal,
    createRubricLibraryModal,
    removeNonEmptyRubricLibraryModal,
    folderPassModal,
    mailDashboardModal,
    transferDuties,
    statusModal,
    createProject,
    changeStatusModal,
    accountDeleting,
    createProjectRole,
    createProjectTeam,
    createProjectTask,
    createSprintModal,
    linksModal,
    linksCategoryModal,
    selectFile,
    createFileModal,
    tasksQueue,
    tasksConnection,
    taskProjectInfo,
    dashboardJointProjectStatistic,
    dashboardJointProjectTeam,
    taskOverdue,
    tagEdit,
    userDelete,
    noteEdit,
    record,
    passwordModal,
    notesCategories,
    notesFullCategory
  } = useGlobalModalsSelectors();

  return (
    <>
      {mutualEdit.open && <MutualEdit />}
      {(uploadFiles.selectedFiles.length > 0 || projectUploadFiles?.selectedFiles?.length > 0) &&
        !uploadFiles.replacementFile && <UploadFiles />}
      {createFileModal.open && <CreateFile />}
      {success.open && <SuccessModal />}
      {error.open && <ErrorModal />}
      {topMessage.open && <TopMessage />}
      {share.open && <Share />}
      {mailNewMessage.open && <MailNewMessage />}
      {loader && (
        <Loader
          position="absolute"
          zIndex={10000}
          containerType="bounceDots"
          type={LoaderTypes.BOUNCING_DOTS}
          background="rgba(256, 256, 256, 0.5)"
          animation={false}
        />
      )}
      {printScreen.open && <PrintScreen />}
      {previewFile.open && <PreviewFile />}
      {taskModals.type && <TaskModals />}
      {projectModals.type && <ProjectModals />}
      <ChatModals />
      {moveElements.open && <MoveElements />}
      {createFolder.open && <CreateFolder />}
      {accessIsLimitedModal.open && <AccessIsLimitedModal />}
      {propertiesModal.open && <Properties />}
      {copyLinkModal.open && <CopyLinkModal />}
      {folderPassModal.open && <FolderPassword />}
      {editItemsModal.open && <EditItemsModal />}
      {customizeFile.open && <RenameFile />}
      {createZip.open && <CreateZip />}
      {downloadLink && <DownloadOfLink />}
      {fileView.open && <FileView />}
      {folderNavigation.open && <FolderNavigation />}
      {filePrint.open && <FilePrint />}
      {nextAction.open && <NextActionModal />}
      {shareToMessengers.open && <ShareToMessengers />}
      {safeModals.open && <SafeModals />}
      {moveToSafe.open && <MoveToSafe />}
      {postboxModals.open && <PostboxModals />}
      {propertiesElementModals.open && <PropertiesElementModals />}
      {restoreFileModals.open && <RestoreFileModal />}
      {transferDuties.open && <TransferDuties />}
      {profileModals.open && <ProfileModals />}
      {createObjectLibraryModals.open && <CreateObjectLibraryModal />}
      {avatarCrop.open && <AvatarCrop />}
      {confirmCode.open && <ConfirmCodeModal />}
      {tariffs.open && <TariffModal />}
      {allFileViewModal.open && <AllFileViewModal />}
      {contacts.open && <ContactsModal />}
      {socialModal.open && <SocialModal />}
      {createRubricLibraryModal.open && <CreateRubricLibraryModal />}
      {removeNonEmptyRubricLibraryModal.open && <RemovingNonEmptyRubricLibraryModal />}
      {mailDashboardModal.open && <MailDashboardModal />}
      {statusModal.open && <StatusModal />}
      {createProject.open && <CreateProject />}
      {changeStatusModal.open && <ChangeStatusModal />}
      {createProjectRole.open && <CreateProjectRole />}
      {createProjectTeam.open && <CreateProjectTeam />}
      {createProjectTask.open && <CreateProjectTask />}
      {accountDeleting.open && <AccountDeletingModal />}
      {createSprintModal.open && <CreateSprintModal />}
      {linksModal.open && <LinksModal />}
      {linksCategoryModal.open && <LinksCategoryModal />}
      {selectFile.open && <SelectFile />}
      {taskProjectInfo.open && <TaskProjectInfoModal />}
      {tasksQueue.open && <TasksQueueModal />}
      {tasksConnection.open && <TasksConnectionModal />}
      {dashboardJointProjectStatistic.open && <DashboardJointProjectStatisticModal />}
      {dashboardJointProjectTeam.open && <DashboardJointProjectTeamModal />}
      {approveModal.open && <ApproveModal />}
      {taskOverdue.open && <TaskOverdueModal />}
      {tagEdit.open && <TagEditModal />}
      {userDelete.open && <UserDeleteModal />}
      {noteEdit.open && <NoteEditModal />}
      {record.open && <RecordModal />}
      {passwordModal.open && <PasswordModal />}
      {notesCategories.open && <NotesCategoriesModal />}
      {notesFullCategory.open && <NotesFullCategoryModal />}
    </>
  );
};

export default Modals;
