export interface IPaint {
  tool: any; // class
  color: string;
  size: number; // should find server response
  mutualEdit: IMutualEdit;
}

export type Paint = "tool" | "color" | "size" | "mutualEdit";
export type PaintValues = any;

export interface IMutualEdit {
  open: boolean;
  data: []; // should find server response
  destination: string; // path to save file
}

export function initialMutualEdit(): IMutualEdit {
  return {
    open: false,
    data: [],
    destination: ""
  };
}

export function initialPaintState(): IPaint {
  return {
    tool: { name: "" },
    color: "rgba(0,0,0,1)",
    size: 2,

    mutualEdit: initialMutualEdit()
  };
}
