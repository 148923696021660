import { useActions } from "hooks/useActions";
import { UseWebRTC } from "models/generalComponents/hooks/WebRTC/useWebRTC";
import { IOutgoingCallParams } from "models/generalComponents/hooks/WebRTC/useWebRTCSocketMessages";
import { useCallback, useEffect, useMemo } from "react";
import { useLocales } from "react-localized";

import { initialCallRoomState } from "../../../models/store/Cabinet/chat/chat";
import { useChatSelectors } from "../../../Store/selectors/chatSelectors";
import { useUserSelectors } from "../../../Store/selectors/userSelectors";
import { CHAT_CALLROOM, CHAT_CALLROOM_ACTIONS } from "../../variables/chat";
import { useWebRTCHelpers } from "./useWebRTCHelpers";
import { useWebRTCSetConnectionServices } from "./useWebRTCSetConnectionServices";
import { useWebRTCContext } from "./WebRTCProvider";

export function useWebRTC({ isScreen }: UseWebRTC) {
  const { __ } = useLocales();
  const { userInfo } = useUserSelectors();
  const icon = useMemo(() => userInfo.icon[0], [userInfo.icon]);
  const { callRoom } = useChatSelectors();
  const { setCallRoom, setCallRoomConnectedUsers, onSetErrorModal } = useActions();

  const { switchScreen } = useWebRTCHelpers();

  const {
    peerMediaElements,
    localMediaStream,
    peerScreenElements,
    minimizedMediaElement,
    sendSocket,
    clients,
    isLoadingUsers,
    nullifyData
  } = useWebRTCContext();

  const { startCall } = useWebRTCSetConnectionServices();

  // при первом рендере, если входящий звонок - рендерит окно входящего вызова с кнопками принять, отклонить
  useEffect(() => {
    if (
      !localMediaStream.current &&
      callRoom.state !== CHAT_CALLROOM_ACTIONS.OUTGOING_CALL &&
      callRoom.state !== CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL
    ) {
      startCall(callRoom.initialCallType === CHAT_CALLROOM.VIDEO_CALL);
    }
    //eslint-disable-next-line
  }, []);

  // если исходящий звонок - запрос на подключение отправлятеся пользователям каждые 1.5 секунды до момента выхода или подключения хотя бы отдного из пользователей для аудио/видео звонков
  useEffect(() => {
    if (callRoom.state === CHAT_CALLROOM_ACTIONS.OUTGOING_CALL) {
      startCall(false)
        .then(() => {
          // initializing call
          window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL] = setInterval(() => {
            sendSocket({
              action: CHAT_CALLROOM_ACTIONS.OUTGOING_CALL,
              users_to: callRoom.contacts.filter((it: string) => it !== callRoom.user_id),
              media: {
                audio: true,
                video: false
              },
              handUp: false
            });
          }, 1500);
          setCallRoomConnectedUsers({
            icon,
            id_user: userInfo.id,
            name: userInfo.name,
            sname: userInfo.sname,
            media: {
              audio: true,
              video: false
            },
            handUp: false
          });
        })
        .catch((err) => {
          onSetErrorModal({ open: true, message: __("Не удалось захватить аудио/видео контент") });
          console.log(err);
        });
    }

    if (callRoom.state === CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL) {
      setCallRoom({ ...callRoom, state: CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL });
      startCall(callRoom.initialCallType === CHAT_CALLROOM.VIDEO_CALL)
        .then(() => {
          // initializing video call
          window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL] = setInterval(() => {
            sendSocket({
              action: CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL,
              users_to: callRoom.contacts.filter((it: string) => it !== callRoom.user_id),
              media: {
                audio: true,
                video: callRoom.initialCallType === CHAT_CALLROOM.VIDEO_CALL
              },
              handUp: false,
              id_group: callRoom.id_group
            } as IOutgoingCallParams);
          }, 1500);
          setCallRoomConnectedUsers({
            icon,
            id_user: userInfo.id,
            name: userInfo.name,
            sname: userInfo.sname,
            media: {
              audio: true,
              video: callRoom.initialCallType === CHAT_CALLROOM.VIDEO_CALL
            },
            handUp: false
          });
        })
        .catch((err) => {
          onSetErrorModal({ open: true, message: __("Не удалось захватить аудио/видео контент") });
          console.log(err);
        });
    }
  }, []); //eslint-disable-line

  //Stop all media and setup default callRoom params
  // Останавливает все медиа и очищает данные при закрытии комнаты
  useEffect(() => {
    return () => {
      closeCallRoom();
      nullifyData();
      setCallRoom(initialCallRoomState());
    };
  }, []); //eslint-disable-line

  const closeCallRoom = async (): Promise<void> => {
    if (window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL]) {
      clearTimeout(window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL]);
    }
    if (localMediaStream.current) {
      await localMediaStream.current.getTracks().forEach((track) => track.stop());
    }
    sendSocket({ action: CHAT_CALLROOM_ACTIONS.LEAVE });
  };

  // добавляет src к video
  const provideMediaRef = useCallback((id: string, node: HTMLVideoElement): void => {
    peerMediaElements.current[id] = node;
    //eslint-disable-next-line
  }, []);

  // добавляет src к video
  const provideScreenRef = useCallback((id: string, node: HTMLVideoElement): void => {
    peerScreenElements.current[id] = node;
    //eslint-disable-next-line
  }, []);

  // добавляет src к video
  const provideMiniCallRoomRef = useCallback((node: HTMLVideoElement): void => {
    minimizedMediaElement.current = node;
    //eslint-disable-next-line
  }, []);

  // в случае минимизированного окна - добавляет src к видео элементу минифицированного обьекта
  useEffect(() => {
    if (isScreen && minimizedMediaElement.current) {
      minimizedMediaElement.current.volume = 0;
      minimizedMediaElement.current.srcObject = localMediaStream.current;
    }
    //eslint-disable-next-line
  }, [isScreen]);

  return {
    clients,
    closeCallRoom,
    switchScreen,
    provideMiniCallRoomRef,
    provideScreenRef,
    provideMediaRef,
    isLoadingUsers
  };
}
