import { ReactComponent as AttachIcon } from "assets/icons/attach.svg";
import { useSelectLibraryCategories, useSelectLibraryRubrics } from "collections/library";
import Input from "generalComponents/Input/Input";
import { InputUpload } from "generalComponents/InputUpload/InputUpload";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import { Textarea } from "generalComponents/Textarea/Textarea";
import { MAX_SIZE_FILE } from "generalComponents/variables/files";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { ChangeEvent, FC, MouseEvent } from "react";
import { useCallback } from "react";
import { Dispatch, SetStateAction } from "react";
import { useLocales } from "react-localized";
import { maxSizeFileRule, requiredRule } from "utils/validation";

import { PreviewUploadFiles } from "./components/PreviewUploadFilles/PreviewUploadFiles";
import styles from "./CreateDataObject.module.sass";
import { FieldsName } from "./lib/createDataObjectReducer";
import { FormCreateObjectLibraryTypes, FormDataCreateaObjectState } from "./lib/createDataObjectReducer";

interface IProps {
  data: FormDataCreateaObjectState;
  dispatchData: Function;
  folderFiles: IFile[];
  onSetFilesFolder: Dispatch<SetStateAction<IFile[]>>;
  onSetRemovedFilesFolder: Dispatch<SetStateAction<IFile[]>>;
}

export const CreateDataObject: FC<IProps> = ({
  data,
  dispatchData,
  folderFiles,
  onSetFilesFolder,
  onSetRemovedFilesFolder
}) => {
  const { __ } = useLocales();

  const errorMessageFiles = __("Превышен объем загружаемых файлов");

  const { onSetTopMessageModal } = useActions();

  const optionsCategories = useSelectLibraryCategories();
  const optionsRubrics = useSelectLibraryRubrics();

  const selectedDefaultRubric = optionsRubrics.find(({ value }) => value === data[FieldsName.RUBRIC]?.value);
  const selectedDefaultCategory = optionsCategories.find(({ value }) => value === data[FieldsName.CATEGORY]?.value);

  const dataFiles: File[] = data[FieldsName.FILES_UPLOAD].files;

  const onVisibleTopMessageModal = () => {
    onSetTopMessageModal({
      open: true,
      type: ITopMessageTypes.MESSAGE,
      message: errorMessageFiles
    });
  };

  const removeFile = useCallback(
    (id: number) => {
      const newDataFiles = dataFiles.filter((_, index) => index !== id);

      const error = maxSizeFileRule(newDataFiles, MAX_SIZE_FILE);

      if (error) {
        onVisibleTopMessageModal();
      }

      dispatchData({
        type: FormCreateObjectLibraryTypes.REMOVE_FILE,
        field: FieldsName.FILES_UPLOAD,
        files: newDataFiles,
        error,
        errorMessage: error ? errorMessageFiles : ""
      });
    },
    [dataFiles.length] //eslint-disable-line
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | MouseEvent<HTMLButtonElement>
  ): void => {
    const { value, name } = e.currentTarget;

    dispatchData({
      type: FormCreateObjectLibraryTypes.CHANGE,
      field: name,
      value,
      error: data[name as FieldsName]?.required ? requiredRule(value) : false,
      errorMessage: ""
    });
  };

  const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ?? null;

    const error = maxSizeFileRule(files, MAX_SIZE_FILE);

    if (error) {
      onVisibleTopMessageModal();
    }

    dispatchData({
      type: FormCreateObjectLibraryTypes.CHANGE_FILE,
      field: FieldsName.FILES_UPLOAD,
      files,
      error,
      errorMessage: error ? errorMessageFiles : ""
    });
  };

  return (
    <>
      <SelectCustom
        name={FieldsName.CATEGORY}
        width={320}
        variantHeight="medium"
        options={optionsCategories}
        label={__("Категория")}
        selectedDefault={{
          name: selectedDefaultCategory?.name,
          value: selectedDefaultCategory?.value ?? ""
        }}
        onSelected={handleChange}
        required={data[FieldsName.CATEGORY].required}
      />
      {data[FieldsName.RUBRIC] && (
        <SelectCustom
          name={FieldsName.RUBRIC}
          width={320}
          variantHeight="medium"
          options={optionsRubrics}
          label={__("Рубрика")}
          selectedDefault={{
            name: selectedDefaultRubric?.name,
            value: selectedDefaultRubric?.value ?? ""
          }}
          onSelected={handleChange}
          required={data[FieldsName.RUBRIC]?.required}
        />
      )}
      <Input
        value={data[FieldsName.NAME_FOLDER].value}
        onChange={handleChange}
        name={FieldsName.NAME_FOLDER}
        label={__("Название")}
        placeholder={__("Введите")}
        require={data[FieldsName.NAME_FOLDER].required}
        className={styles.inputName}
      />
      <Textarea
        value={data[FieldsName.DESCRIPTION_FOLDER].value}
        onChange={handleChange}
        name={FieldsName.DESCRIPTION_FOLDER}
        label={__("Описание")}
        placeholder={__("Введите")}
        className={styles.textarea}
      />
      <div>
        <InputUpload
          name={FieldsName.FILES_UPLOAD}
          textUpload={__("Прикрепить")}
          uploadIcon={<AttachIcon />}
          fileUpload={data[FieldsName.FILES_UPLOAD].files}
          variantBtnUpload={ButtonVariantType.EXRTA_LIGHT}
          sizeBtnUpload={ButtonSizeType.SMALL}
          uploadFile={uploadFile}
          multiple
        />
        <PreviewUploadFiles
          files={dataFiles}
          folderFiles={folderFiles}
          removeFile={removeFile}
          onSetFilesFolder={onSetFilesFolder}
          onSetRemovedFilesFolder={onSetRemovedFilesFolder}
        />
      </div>
    </>
  );
};
