import { EventHead } from "containers/Profile/Journal/EventHead/EventHead";
import { JournalEventsList } from "containers/Profile/Journal/JournalEventsList/JournalEventsList";
import { JournalServicePanel } from "containers/Profile/Journal/JournalServicePanel/JournalServicePanel";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useActions } from "hooks/useActions";
import { useEffect } from "react";
import { useLocales } from "react-localized";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { useFilterTypeElementsSelector } from "Store/selectors/filterTypeElementsSelectors";
import { useSortByDateSelector } from "Store/selectors/sortByDateSelectors";

export const JournalPage = () => {
  const [searchParams] = useSearchParams();

  const { actionLogId, actionLogGroup } = useParams();

  const filterUser = searchParams.get(SEARCH_PARAMS.USER_ID);

  const { __ } = useLocales();

  const { onGetJournalElements, onNullifyJournal, onNullifyFiltersTypeElements, onNullifySortByDate } = useActions();

  const { filterTypeElements } = useFilterTypeElementsSelector();
  const { sortByDate } = useSortByDateSelector();

  const message = {
    success: "",
    error: __("Не удалось получить список элементов, попробуйте еще раз")
  };

  useEffect(() => {
    if (!actionLogId && !actionLogGroup) {
      const controllerElements = new AbortController();

      onGetJournalElements(message, controllerElements);

      return () => {
        controllerElements?.abort();
        onNullifyJournal();
      };
    }
  }, [filterUser, filterTypeElements, sortByDate, actionLogId, actionLogGroup]); //eslint-disable-line

  useEffect(() => {
    return () => {
      onNullifyFiltersTypeElements();
      onNullifySortByDate();
    };
  }, []); //eslint-disable-line

  return (
    <WorkAreaWrap>
      <JournalServicePanel />
      <EventHead />
      {actionLogId ? <Outlet /> : <JournalEventsList />}
    </WorkAreaWrap>
  );
};
