import classNames from "classnames";
import {
  emptyMessengerIcons,
  emptySocialMediaIcons,
  messengerIcons,
  socialMediaIcons
} from "collections/contactsIcons";
import Button from "generalComponents/Button/Button";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import Input from "generalComponents/Input/Input";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import ContactsAvatar from "generalComponents/Modals/Components/ProfileModals/ContactsModal/ContactsAvatar/ContactsAvatar";
import EmailBlock from "generalComponents/Modals/Components/ProfileModals/ContactsModal/EmailBlock/EmailBlock";
import PhoneNumberBlock from "generalComponents/Modals/Components/ProfileModals/ContactsModal/PhoneNumberBlock/PhoneNumberBlock";
import SocialBlock from "generalComponents/Modals/Components/ProfileModals/ContactsModal/SocialBlock/SocialBlock";
import PopUp from "generalComponents/PopUp/PopUp";
import { Textarea } from "generalComponents/Textarea/Textarea";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialContactsModalState, initialSocialModalState } from "models/store/Cabinet/modals/contactsModal";
import { IContact } from "models/store/user/user";
import { IAddContactPayload } from "models/store/user/userPayloads";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { dateISO } from "utils/dateToISO";
import { isEmail, isPhoneNumber } from "utils/validation";

import styles from "./ContactsModal.module.sass";

const initialContactData: IAddContactPayload = {
  name: "",
  sname: "",
  company: "",
  bdate: "",
  icon: "",
  prim: "",
  tel: [""],
  email: [""],
  soc: [],
  mes: [],
  profileImage: null
};

const ContactsModal = () => {
  const { __ } = useLocales();
  const { onSetContactsModal, onSetSocialModal, addContact, updateContact } = useActions();

  const {
    contacts: { params }
  } = useGlobalModalsSelectors();
  const [avatarName, setAvatarName] = useState<string>(" ");
  const [telError, setTelError] = useState<string[]>([]);
  const [emailError, setEmailError] = useState<string[]>([]);

  const [contactData, setContactData] = useState<IAddContactPayload>(
    params
      ? {
          ...params,
          icon: params.icon ? projectSrc + params.icon : "",
          tel: params.tel.length ? params.tel : [""],
          email: params.email.length ? params.email : [""]
        }
      : initialContactData
  );

  const closeModal = (): void => {
    onSetContactsModal(initialContactsModalState());
    onSetSocialModal(initialSocialModalState());
  };

  const updateSocialNetworks = (updateData: IContact["soc"]) => {
    setContactData({ ...contactData, soc: updateData.filter((item) => item.link) });
  };

  const updateMessangers = (updateData: IContact["mes"]) => {
    setContactData({ ...contactData, mes: updateData.filter((item) => item.link) });
  };

  const handleTelChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    // Получаем введенное значение
    const inputValue = e.target.value;

    // Проверяем, содержит ли введенное значение буквы
    if (/[^0-9+]/.test(inputValue)) {
      // Если введено значение с буквами, установите ошибку
      setTelError((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = "Поле должно содержать только цифры";
        return updatedErrors;
      });
    } else {
      // Удаляем все нецифровые символы, кроме знака "+"
      const cleanedValue = inputValue.replace(/[^0-9+]/g, "");

      if (cleanedValue === "") {
        // Если введенное значение не содержит цифр, сбрасываем номер
        const newTel = [...contactData.tel];
        newTel[index] = "";
        setContactData({ ...contactData, tel: newTel });
      } else {
        // Проверяем, начинается ли введенное значение с "+"
        const newValue = cleanedValue[0] === "+" ? cleanedValue : `+${cleanedValue}`;
        // Обновляем состояние номера телефона
        const newTel = [...contactData.tel];
        newTel[index] = newValue;
        setContactData({ ...contactData, tel: newTel });
      }
      // Сбрасываем ошибку
      setTelError((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = "";
        return updatedErrors;
      });
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const newEmail = [...contactData.email];
    newEmail[index] = e.target.value;
    setContactData({ ...contactData, email: newEmail });
    setEmailError((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = "";
      return updatedErrors;
    });
  };

  const addTel = () => {
    const newTel = [...contactData.tel, ""];
    setContactData({ ...contactData, tel: newTel });
  };

  const removeTel = (index: number) => {
    const newTel = [...contactData.tel];
    newTel.splice(index, 1);
    setContactData({ ...contactData, tel: newTel });
  };

  const addEmail = () => {
    const newEmail = [...contactData.email, ""];
    setContactData({ ...contactData, email: newEmail });
  };

  const removeEmail = (index: number) => {
    const newEmail = [...contactData.email];
    newEmail.splice(index, 1);
    setContactData({ ...contactData, email: newEmail });
  };

  const handleTelBlur = (index: number) => {
    if (!isPhoneNumber(contactData.tel[index]) && contactData.tel[index].length > 0) {
      setTelError((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = __("Неверный формат номера телефона");
        return updatedErrors;
      });
    } else {
      setTelError((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = "";
        return updatedErrors;
      });
    }
  };

  const handleEmailBlur = (index: number) => {
    if (!isEmail(contactData.email[index]) && contactData.email[index].length > 0) {
      setEmailError((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = __("Неверный формат почты");
        return updatedErrors;
      });
    } else {
      setEmailError((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = "";
        return updatedErrors;
      });
    }
  };

  const onSubmit = () => {
    const telErrorsExist = telError.some((error) => !!error);
    const emailErrorsExist = emailError.some((error) => !!error);

    if (telErrorsExist || emailErrorsExist) {
      contactData.tel.forEach((_, index) => {
        if (telError[index]) {
          handleTelBlur(index);
        }
      });

      contactData.email.forEach((_, index) => {
        if (emailError[index]) {
          handleEmailBlur(index);
        }
      });
    } else {
      contactData.tel = contactData.tel.filter((tel) => tel.trim() !== "");
      contactData.email = contactData.email.filter((email) => email.trim() !== "");

      if (params?.id) {
        updateContact({ ...contactData, id: params.id });
      } else addContact(contactData);
      closeModal();
    }
  };

  const onBlurInput = () => {
    setAvatarName(`${contactData.name} ${contactData.sname}`);
  };

  useEffect(() => {
    if (contactData.sname || contactData.name) setAvatarName(`${contactData.name} ${contactData.sname}`);
  }, []); // eslint-disable-line

  return (
    <PopUp set={closeModal} position="top" top={50}>
      <div className={styles.wrap}>
        <HeaderModal title={__("Добавить")} onClose={closeModal} />
        <div className={styles.body}>
          <div className={styles.form}>
            <ContactsAvatar avatarName={avatarName} contactData={contactData} setContactData={setContactData} />
            <div className={styles.inputs}>
              <Input
                className={styles.input}
                value={contactData.name}
                onChange={(e) => setContactData({ ...contactData, name: e.target.value })}
                name="fname"
                placeholder={__("Введите")}
                label={__("Имя")}
                onBlur={onBlurInput}
              />
              <Input
                className={styles.input}
                value={contactData.sname}
                onChange={(e) => setContactData({ ...contactData, sname: e.target.value })}
                name="sname"
                placeholder={__("Введите")}
                label={__("Фамилия")}
                onBlur={onBlurInput}
              />
              <Input
                className={classNames(styles.input, styles.inputCompany)}
                value={contactData.company}
                onChange={(e) => setContactData({ ...contactData, company: e.target.value })}
                name="company"
                placeholder={__("Введите")}
                label={__("Компания")}
              />
            </div>
            <div className={styles.block}>
              {Object.entries(contactData.tel).map(([id, number], i) => (
                <PhoneNumberBlock
                  key={id}
                  number={number}
                  onTelChange={handleTelChange}
                  onTelBlur={handleTelBlur}
                  onAddTel={addTel}
                  onRemoveTel={removeTel}
                  index={i}
                  telError={telError}
                />
              ))}
              {Object.entries(contactData.email).map(([id, email], i) => (
                <EmailBlock
                  key={id}
                  email={email}
                  onEmailChange={handleEmailChange}
                  onEmailBlur={handleEmailBlur}
                  onAddEmail={addEmail}
                  onRemoveEmail={removeEmail}
                  index={i}
                  emailError={emailError}
                />
              ))}
            </div>
            <div className={styles.inputDate}>
              <span>{__("День рождения")}</span>
              <InputDate
                placeholder={__("Выберите")}
                dateFormat="dd MMM yyyy"
                startDate={
                  contactData.bdate
                    ? /^\d{2}.\d{2}.\d{4}$/.test(contactData.bdate)
                      ? new Date(contactData.bdate.replace(/(\d{2}).(\d{2}).(\d{4})/, "$3-$2-$1T00:00"))
                      : new Date(contactData.bdate)
                    : null
                }
                setStartDate={(date: Date) => setContactData({ ...contactData, bdate: dateISO(date) })}
              />
            </div>
            <div className={styles.block}>
              <SocialBlock
                title={__("Социальные сети")}
                data={contactData.soc}
                icons={socialMediaIcons}
                emptyIcons={emptySocialMediaIcons}
                updateData={updateSocialNetworks}
              />
            </div>
            <div className={styles.block}>
              <SocialBlock
                title={__("Мессенджеры")}
                data={contactData.mes}
                icons={messengerIcons}
                emptyIcons={emptyMessengerIcons}
                updateData={updateMessangers}
              />
            </div>
            <div className={styles.block}>
              <Textarea
                value={contactData.prim}
                onChange={(e) => setContactData({ ...contactData, prim: e.target.value })}
                name="note"
                placeholder={__("Введите")}
                label={__("Заметка")}
              />
            </div>
          </div>
          <div className={styles.btns}>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.MEDIUM}
              onClick={closeModal}
              text={__("Отменить")}
            />
            <Button
              disabled={!contactData.name}
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.MEDIUM}
              text={__("Сохранить")}
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default ContactsModal;
