export interface ICalendarMonthProps {
  onSelectDate: (el: any) => void;
  setShowCalendar: () => void;
  isWeekSelection: boolean;
}
export interface ICalendarDay {
  typeDay: TYPE_DAY;
  value: number;
}

export interface IDateObject {
  year: number;
  month: number;
  day?: number;
  time?: string;
}

export interface IExtend {
  start: IDateObject;
  end?: IDateObject;
}

export interface ICalendarProps {
  title?: string;
  subtitle?: string;
  setShowCalendar: (el: boolean) => void;
  setDateValue: (el: any) => void;
  selectRange?: boolean;
  extend?: IExtend;
  isStartTime?: boolean;
  isEndTime?: boolean;
  selectYear?: boolean;
}

export enum TYPE_DAY {
  PREV = "prev",
  CURRENT = "current",
  NEXT = "next"
}

export interface IMonth {
  id: number;
  name: string;
  declensionName: string;
  code: number;
  days: number;
}

export interface IDaysOfWeeks {
  short: string[];
  code: number[];
}
