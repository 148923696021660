import { useMemo } from "react";
import { useUserSelectors } from "Store/selectors/userSelectors";

export interface IContactShort {
  name: string;
  sname: string;
  email: string;
  icon: string;
  id: string;
}

export const useContactsList = (): {
  contactsList: IContactShort[];
  employeesList: IContactShort[];
  combineContacts: IContactShort[];
} => {
  const { contacts, employees } = useUserSelectors();
  const contactsList = useMemo(
    () =>
      contacts.reduce((acc, el) => {
        if (el.email.length > 0) {
          el.email.map((email) =>
            acc.push({ name: el.name, sname: el.sname, icon: el.icon, email: email, id: el.id_user })
          );
        }
        return acc;
      }, []),
    [contacts]
  );
  const employeesList = useMemo(
    () =>
      employees.reduce((acc, el) => {
        if (el.email.length > 0) {
          acc.push({ name: el.fname, sname: el.sname, icon: el.icon, email: el.email, id: el.id });
        }
        return acc;
      }, []),
    [employees]
  );

  const employeesContactsList = useMemo(
    () =>
      employees.reduce((acc, el) => {
        if (el.emails.length > 0) {
          el.emails.map((email) =>
            acc.push({ name: el.fname, sname: el.sname, icon: el.icon, email: email, id: el.id })
          );
        }
        return acc;
      }, []),
    [employees]
  );

  const combineContacts = useMemo(() => {
    const result: IContactShort[] = [];
    employeesList.forEach((c) => {
      if (result.findIndex((el) => el.email === c.email) < 0) {
        result.push(c);
      }
    });
    employeesContactsList.forEach((c) => {
      if (result.findIndex((el) => el.email === c.email) < 0) {
        result.push(c);
      }
    });
    contactsList.forEach((c) => {
      if (result.findIndex((el) => el.email === c.email) < 0) {
        result.push(c);
      }
    });
    return result.sort((a, b) => a.name?.localeCompare(b.name));
  }, [contactsList, employeesContactsList, employeesList]);

  console.log("combineContacts", combineContacts);

  return { contactsList, employeesList, combineContacts };
};
