import { ReactComponent as CloudIcon } from "assets/icons/cloud.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/upload.svg";
import { ReactComponent as ViewIcon } from "assets/icons/view.svg";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { POSTBOX_MODALS } from "generalComponents/variables/mail";
import { useFindPostbox } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { ILetterInfo, ITypeOfFile } from "models/store/postbox/postbox";
import { FC, MouseEvent, useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { typeOfAttachmentImage } from "utils/mail";

import styles from "./LetterAttachment.module.sass";

interface Props {
  name: string;
  type: ITypeOfFile | string;
  letter?: ILetterInfo;
}

const LetterAttachment: FC<Props> = ({ name, type, letter }) => {
  const { __ } = useLocales();
  const { onDownloadLink, onSetPostboxModal } = useActions();
  const { uid } = useUserSelectors();
  const { postbox } = useFindPostbox();
  const _type = typeof type !== "string" ? type.mimeType : type;
  const [show, setShow] = useState(false);
  const onDownload = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShow(false);
    const link = `ajax/email_attachment_download.php?uid=${uid}&user=${postbox.email}&pass=${postbox.pass}&server=${
      postbox.server || ""
    }&folder=${letter.folder}&email_uid=${letter.uid}&filename=${name}`;
    onDownloadLink(link);
  };

  const onSave = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShow(false);
    onSetPostboxModal({ open: true, type: POSTBOX_MODALS.SAVE_FILE, postbox, letter, filename: name });
  };

  const onPreview = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShow(false);
    const link = `/ajax/email_attachment_preview.php?uid=${uid}&user=${postbox.email}&pass=${postbox.pass}&server=${
      postbox.server || ""
    }&folder=${letter.folder}&email_uid=${letter.uid}&filename=${name}`;

    onSetPostboxModal({ open: true, type: POSTBOX_MODALS.PREVIEW_FILE, preview: { link, name, mime_type: _type } });
  };

  const overlayContent = () => {
    return (
      <>
        <button className={styles.btn} onClick={onPreview}>
          <span className={styles.icon}>
            <ViewIcon />
          </span>
          <span className={styles.name}>{__("Предпросмотр")}</span>
        </button>
        <button className={styles.btn} onClick={onSave}>
          <span className={styles.icon}>
            <CloudIcon />
          </span>
          <span className={styles.name}> {__("Сохранить в Файлы")}</span>
        </button>
        <button className={styles.btn} onClick={onDownload}>
          <span className={styles.icon}>
            <DownloadIcon width={14} height={17} />
          </span>
          <span className={styles.name}>{__("Сохранить локально")}</span>
        </button>
      </>
    );
  };

  return (
    <PopoverOverlay overlayContent={overlayContent()} placement="bottom" show={show} setShow={setShow}>
      <span className={styles.atachment}>
        {typeOfAttachmentImage(_type)}
        <span className={styles.fileName}>{name ?? "File"}</span>
      </span>
    </PopoverOverlay>
  );
};

export default LetterAttachment;
