import { getDay, getHours, setHours, setMinutes } from "date-fns";
import Avatar from "generalComponents/Avatar/Avatar";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useFindProjectUser } from "hooks/joinProjectHooks";
import { TASK_PRIORITY, TASK_TYPE } from "models/store/joinProjects/joinProgects";
import { FC, useMemo } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import TaskDuration from "./TaskDuration/TaskDuration";
import TaskExecutor from "./TaskExecutor/TaskExecutor";
import TaskPriority from "./TaskPriority/TaskPriority";
import styles from "./TaskSidebar.module.sass";
import TaskSprints from "./TaskSprints/TaskSprints";
import TaskStatus from "./TaskStatus/TaskStatus";
import TaskTeam from "./TaskTeam/TaskTeam";
import TaskType from "./TaskType/TaskType";

interface IProps {
  priority: TASK_PRIORITY;
  setPriority: (val: TASK_PRIORITY) => void;
  type: TASK_TYPE;
  setType: (val: TASK_TYPE) => void;
  status: string;
  setStatus: (val: string) => void;
  team: string;
  setTeam: (val: string) => void;
  author: string;
  executor: string;
  setExecutor: (val: string) => void;
  sprints: string[];
  setSprints: (val: string[]) => void;
  duration: number;
  setDuration: (val: number) => void;
  dateStart: Date;
  setDateStart: (val: Date) => void;
  dateEnd: Date;
  setDateEnd: (val: Date) => void;
  readOnly?: boolean;
}

const TaskSidebar: FC<IProps> = ({
  priority,
  setPriority,
  type,
  setType,
  status,
  setStatus,
  team,
  setTeam,
  author,
  executor,
  setExecutor,
  sprints,
  setSprints,
  duration,
  setDuration,
  dateStart,
  setDateStart,
  dateEnd,
  setDateEnd,
  readOnly = false
}) => {
  const { __ } = useLocales();
  const authorInfo = useFindProjectUser(author);
  const { project } = useJoinProjectsSelectors();

  const onChangeDateStart = (d: Date) => {
    const h = getHours(d);
    if (!project.work_hours.includes(h)) {
      d.setHours(project.work_hours[0]);
    }
    setDateStart(d);
  };

  const onChangeDateEnd = (d: Date) => {
    setDateEnd(d);
  };

  const isWeekend = (date: Date) => {
    const day = getDay(date);
    return project.work_days.includes(day);
  };
  const includeTimesStart = useMemo(() => {
    return [
      ...project.work_hours.map((el) => setHours(setMinutes(new Date(), 0), el)),
      ...project.work_hours.map((el) => setHours(setMinutes(new Date(), 30), el))
    ];
  }, [project.work_hours]);

  const includeTimesEnd = useMemo(() => {
    return [
      ...project.work_hours.map((el) => setHours(setMinutes(new Date(), 0), el + 1)),
      ...project.work_hours.map((el) => setHours(setMinutes(new Date(), 30), el))
    ].slice();
  }, [project.work_hours]);

  return (
    <div className={styles.sidebar}>
      <div className={styles.row}>
        <div className={styles.label}>{__("Приоритет")}</div>
        <div className={styles.select}>
          <TaskPriority priority={priority} setPriority={setPriority} readOnly={readOnly} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>{__("Тип")}</div>
        <div className={styles.select}>
          <TaskType type={type} setType={setType} readOnly={readOnly} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>{__("Состояние")}</div>
        <div className={styles.select}>
          <TaskStatus status={status} setStatus={setStatus} readOnly={readOnly} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>{__("Команда")}</div>
        <div className={styles.select}>
          <TaskTeam team={team} setTeam={setTeam} executor={executor} setExecutor={setExecutor} readOnly={readOnly} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>{__("Автор")}</div>
        <div className={styles.authorBlock}>
          <Avatar
            name={authorInfo.fname + " " + authorInfo.sname}
            imgSrc={projectSrc + authorInfo.icon}
            isRound
            size={24}
          />
          <span>{authorInfo.fname + " " + authorInfo.sname}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>{__("Исполнитель")}</div>
        <div className={styles.select}>
          <TaskExecutor
            executor={executor}
            setExecutor={setExecutor}
            team={team}
            setTeam={setTeam}
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>{__("Спринт")}</div>
        <div className={styles.select}>
          <TaskSprints sprints={sprints} setSprints={setSprints} readOnly={readOnly} />
        </div>
      </div>
      {type === TASK_TYPE.TASK && (
        <div className={styles.row}>
          <div className={styles.label}>{__("Длительность")}</div>{" "}
          <div className={styles.select}>
            <TaskDuration duration={duration} setDuration={setDuration} readOnly={readOnly} />
          </div>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.label}>{__("Начальная дата")}</div>
        <div className={styles.selectDate}>
          <InputDate
            startDate={dateStart}
            setStartDate={(v: Date) => onChangeDateStart(v)}
            placeholder={__("Нет даты")}
            readOnly={readOnly || sprints.length === 0}
            withTime
            filterDate={isWeekend}
            includeTimes={includeTimesStart}
            timeIntervals={30}
          />
        </div>
      </div>
      {type === TASK_TYPE.TASK && (
        <div className={styles.row}>
          <div className={styles.label}>{__("Конечная дата")}</div>
          <div className={styles.selectDate}>
            <InputDate
              startDate={dateEnd ? new Date(dateEnd) : null}
              setStartDate={(v: Date) => onChangeDateEnd(v)}
              placeholder={__("Нет даты")}
              readOnly={readOnly || sprints.length === 0}
              withTime
              filterDate={isWeekend}
              includeTimes={includeTimesEnd}
              timeIntervals={30}
              minDate={dateStart}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskSidebar;
