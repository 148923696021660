import api from "api";
import Button from "generalComponents/Button/Button";
import { ChooseFolder } from "generalComponents/ChooseFolder/ChooseFolder";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFolder, initialFolderState } from "models/store/folders/foldersStore";
import { ILetterInfo, IPostbox } from "models/store/postbox/postbox";
import { FC, useState } from "react";
import { useLocales } from "react-localized";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./SavePostboxFile.module.sass";

interface IProps {
  closeModal: () => void;
  postbox: IPostbox;
  letter: ILetterInfo;
  filename: string;
}

const SavePostboxFile: FC<IProps> = ({ closeModal, letter, postbox, filename }) => {
  const { __ } = useLocales();
  const { onSetTopMessageModal } = useActions();
  const { uid } = useUserSelectors();
  const [folder, setFolder] = useState<IFolder>({ ...initialFolderState(), id_parent: "0" });

  const { folders } = useFoldersSelectors();

  const onSelect = (folder: IFolder): void => setFolder(folder);

  const onSave = async () => {
    try {
      const { data } = await api.get(
        `/ajax/email_attachment_save.php?uid=${uid}&user=${postbox.email}&pass=${postbox.pass}&server=${
          postbox.server || ""
        }&folder=${letter.folder}&email_uid=${letter.uid}&filename=${filename}&id_dir=${folder.id_dir}`
      );
      checkResponseStatus(data.ok);
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: __("Файл сохранен!") });

      closeModal();
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR
      });
    }
  };

  return (
    <div className={styles.wrap}>
      <HeaderModal onClose={closeModal} title={__("Сохранить файл")} />
      <div className={styles.body}>
        <ChooseFolder onSelect={onSelect} folder={folder} folders={folders} />
        <div className={styles.btns}>
          <Button
            type="button"
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("Отменить")}
            onClick={closeModal}
          />
          <Button
            type="submit"
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={__("Сохранить")}
            onClick={onSave}
            disabled={!folder}
          />
        </div>
      </div>
    </div>
  );
};

export default SavePostboxFile;
