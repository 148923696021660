import SharedFolderElementsList from "containers/sharedFolder/SharedFolderElementsList/SharedFolderElementsList";
import ArchivePage from "Pages/ArchivePage";
import ArchivedFiles from "Pages/ArchivePage/components/ArchiveFiles/ArchiveFiles";
import Chat from "Pages/Cabinet/Components/Chat";
import Project from "Pages/Cabinet/Components/Project/Project";
import ProjectAnalytics from "Pages/Cabinet/Components/Project/ProjectAnalytics/ProjectAnalytics";
import ProjectBacklog from "Pages/Cabinet/Components/Project/ProjectBacklog/ProjectBacklog";
import ProjectDoc from "Pages/Cabinet/Components/Project/ProjectDoc/ProjectDoc";
import ProjectFiles from "Pages/Cabinet/Components/Project/ProjectFiles/ProjectFiles";
import ProjectLinks from "Pages/Cabinet/Components/Project/ProjectLinks/ProjectLinks";
import ProjectMyTasks from "Pages/Cabinet/Components/Project/ProjectMyTasks/ProjectMyTasks";
import ProjectSpace from "Pages/Cabinet/Components/Project/ProjectSpace/ProjectSpace";
import ProjectTasks from "Pages/Cabinet/Components/Project/ProjectTasks/ProjectTasks";
import ProjectTasksHistory from "Pages/Cabinet/Components/Project/ProjectTasksHistory/ProjectTasksHistory";
import ProjectTeam from "Pages/Cabinet/Components/Project/ProjectTeam/ProjectTeam";
import ProjectTrash from "Pages/Cabinet/Components/Project/ProjectTrash/ProjectTrash";
import CalendarPage from "Pages/CalendarPage/CalendarPage";
import DashboardPage from "Pages/DashboardPage/DashboardPage";
import EmployeePage from "Pages/EmployeePage/EmployeePage";
import FaqDetailsPage from "Pages/FaqDetailsPage/FaqDetailsPage";
import FaqPage from "Pages/FaqPage/FaqPage";
import FilePage from "Pages/FilePage/FilePage";
import FilesPage from "Pages/FilesPage/FilesPage";
import FoldersPage from "Pages/FoldersPage/FoldersPage";
import FolderWorkSpace from "Pages/FoldersPage/FolderWorkSpace/FolderWorkSpace";
import SharedList from "Pages/FoldersPage/SharedList/SharedList";
import ForgotPassword from "Pages/ForgotPassword/ForgotPassword";
import JoinProjectPage from "Pages/JoinProjectPage/JoinProjectPage";
import ProjectAnalyticsPage from "Pages/JoinProjectPage/ProjectPage/ProjectAnalyticsPage/ProjectAnalyticsPage";
import ProjectBacklogPage from "Pages/JoinProjectPage/ProjectPage/ProjectBacklogPage/ProjectBacklogPage";
import ProjectDocumentsPage from "Pages/JoinProjectPage/ProjectPage/ProjectDocumentsPage/ProjectDocumentsPage";
import ProjectFilesFolderPage from "Pages/JoinProjectPage/ProjectPage/ProjectFilesPage/components/ProjectFilesFolderPage/ProjectFilesFolderPage";
import ProjectFilesPage from "Pages/JoinProjectPage/ProjectPage/ProjectFilesPage/ProjectFilesPage";
import ProjectGantPage from "Pages/JoinProjectPage/ProjectPage/ProjectGantPage/ProjectGantPage";
import ProjectHistoryPage from "Pages/JoinProjectPage/ProjectPage/ProjectHistoryPage/ProjectHistoryPage";
import ProjectLibraryCategoryPage from "Pages/JoinProjectPage/ProjectPage/ProjectLibraryPage/components/ProjectLibraryCategoryPage/ProjectLibraryCategoryPage";
import ProjectLibrarySearchPage from "Pages/JoinProjectPage/ProjectPage/ProjectLibraryPage/components/ProjectLibrarySearchPage/ProjectSearchPage";
import ProjectLibrarySettingsPage from "Pages/JoinProjectPage/ProjectPage/ProjectLibraryPage/components/ProjectLibrarySettingsPage/ProjectLibrarySettingsPage";
import ProjectLibraryPage from "Pages/JoinProjectPage/ProjectPage/ProjectLibraryPage/ProjectLibraryPage";
import ProjectLinksPage from "Pages/JoinProjectPage/ProjectPage/ProjectLinksPage/ProjectLinksPage";
import ProjectPage from "Pages/JoinProjectPage/ProjectPage/ProjectPage";
import ProjectSettingsPage from "Pages/JoinProjectPage/ProjectPage/ProjectSettingsPage/ProjectSettingsPage";
import ProjectTaskPage from "Pages/JoinProjectPage/ProjectPage/ProjectTaskPage/ProjectTaskPage";
import ProjectTasksPage from "Pages/JoinProjectPage/ProjectPage/ProjectTasksPage/ProjectTasksPage";
import ProjectTeamPage from "Pages/JoinProjectPage/ProjectPage/ProjectTeamPage/ProjectTeamPage";
import ProjectTrashPage from "Pages/JoinProjectPage/ProjectPage/ProjectTrashPage/ProjectTrashPage";
import LibraryPage from "Pages/LibraryPage";
import LibraryCategoryPage from "Pages/LibraryPage/components/LibraryCategoryPage/LibraryCategoryPage";
import LibrarySearchPage from "Pages/LibraryPage/components/LibrarySearchPage/LibrarySearchPage";
import LibrarySettingsPage from "Pages/LibraryPage/components/LibrarySettingsPage/LibrarySettingsPage";
import MailPage from "Pages/MailPage/MailPage";
import PostboxFolder from "Pages/MailPage/PostboxFolder/PostboxFolder";
import PostboxWorkSpace from "Pages/MailPage/PostboxWorkSpace/PostboxWorkSpace";
import NewEmployeePage from "Pages/NewEmployeePage/NewEmployeePage";
import NotesPage from "Pages/NotesPage/NotesPage";
import AboutMe from "Pages/ProfilePage/AboutMe/AboutMe";
import Contacts from "Pages/ProfilePage/Contacts/Contacts";
import JournalPage from "Pages/ProfilePage/JournalPage";
import SingleObjJournalPage from "Pages/ProfilePage/JournalPage/components/SingleObjJournalPage/SingleObjJournalPage";
import MemoryPage from "Pages/ProfilePage/MemoryPage";
import ProfileEmployees from "Pages/ProfilePage/ProfileEmployees/ProfileEmployees";
import ProfilePage from "Pages/ProfilePage/ProfilePage";
import ProfileServices from "Pages/ProfilePage/ProfileServices/ProfileServices";
import ProfileSessions from "Pages/ProfilePage/ProfileSessions/ProfileSessions";
import Tariff from "Pages/ProfilePage/ProfileTariff/ProfileTariff";
import SettingsPage from "Pages/ProfilePage/SettingsPage";
import TagsPage from "Pages/ProfilePage/TagsPage/TagsPage";
import ProgramsPage from "Pages/ProgramsPage/ProgramsPage";
import ProgramsWorkSpace from "Pages/ProgramsPage/ProgramsWorkSpace/ProgramsWorkSpace";
import ProtectedRoutes from "Pages/ProtectedRoutes";
import PublicRoutes from "Pages/PublicRoutes";
import Questionnaire from "Pages/Questionnaire/Questionnaire";
import SafeFiles from "Pages/SafePage/SafeFiles/SafeFiles";
import SafePage from "Pages/SafePage/SafePage";
import SearchPage from "Pages/SearchPage/SearchPage";
import ShareFolderPage from "Pages/ShareFolderPage/ShareFolderPage";
import SignIn from "Pages/SignIn/SignIn";
import SignUp from "Pages/SignUp/SignUp";
import TasksPage from "Pages/TasksPage/TasksPage";
import TrashPage from "Pages/TrashPage";
import TrashFiles from "Pages/TrashPage/components/TrashFiles/TrashFiles";
import { FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Shell from "./Shell/Shell";
import {
  ACTIONS_LOG_ROUTES,
  ARCHIVE_ROUTES,
  FOLDER_ROUTES,
  LIBRARY_ROUTES,
  MAIL_ROUTS,
  PROFILE_ROUTES,
  PROGRAMS_ROUTES,
  PROJECT_FOLDER,
  PROJECT_PATH,
  ROUTES,
  SAFE_ROUTES,
  SHARE_FOLDER_ROUTES,
  TRASH_ROUTES
} from "./variables/routing";
import WebSocketsProvider from "./WebSocketsProvider/WebSocketsProvider";

const AppRouting: FC = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path={`${ROUTES.EMPLOYEE}/:uid`} element={<EmployeePage />} />
      <Route
        path="/"
        element={
          <WebSocketsProvider>
            <Shell />
          </WebSocketsProvider>
        }
      >
        <Route index element={<ProtectedRoutes component={<DashboardPage />} redirectTo={ROUTES.SIGN_IN} />} />

        <Route
          path={ROUTES.SIGN_UP}
          element={<PublicRoutes component={<SignUp />} redirectTo={location.state?.from || "/"} />}
        />
        <Route
          path={ROUTES.SIGN_IN}
          element={<PublicRoutes component={<SignIn />} redirectTo={location.state?.from || "/"} />}
        />
        <Route
          path={ROUTES.FORGOT_PASSWORD}
          element={<PublicRoutes component={<ForgotPassword />} redirectTo={location.state?.from || "/"} />}
        />
        <Route
          path={ROUTES.QUESTIONNAIRE}
          element={<PublicRoutes component={<Questionnaire />} redirectTo={location.state?.from || "/"} />}
        />

        <Route path={ROUTES.FAQ} element={<FaqPage />} />
        <Route path={ROUTES.FAQDETAILS} element={<FaqDetailsPage />}>
          <Route path={`${ROUTES.FAQCATEGORY}`} element={<FaqDetailsPage />} />
        </Route>
        <Route path={ROUTES.NEW_EMPLOYEE} element={<NewEmployeePage />} />
        <Route
          path={ROUTES.NOTES}
          element={<ProtectedRoutes component={<NotesPage />} redirectTo={ROUTES.SIGN_IN} />}
        />
        <Route
          path={ROUTES.FOLDERS}
          element={<ProtectedRoutes component={<FoldersPage />} redirectTo={ROUTES.SIGN_IN} />}
        >
          <Route path={FOLDER_ROUTES.SHARED} element={<SharedList />} />
          <Route path={FOLDER_ROUTES.FAVORITE} element={<FolderWorkSpace />} />
          <Route path={FOLDER_ROUTES.DIR_ID} element={<FolderWorkSpace />} />
        </Route>

        <Route
          path={ROUTES.FILES}
          element={<ProtectedRoutes component={<FilesPage />} redirectTo={ROUTES.SIGN_IN} />}
        />

        <Route path={ROUTES.SAFE} element={<ProtectedRoutes component={<SafePage />} redirectTo={ROUTES.SIGN_IN} />}>
          <Route path={SAFE_ROUTES.SAFE_ID} element={<SafeFiles />}>
            <Route path={SAFE_ROUTES.SAFE_DIR} element={<SafeFiles />} />
          </Route>
        </Route>

        <Route
          path={ROUTES.TASKS}
          element={<ProtectedRoutes component={<TasksPage />} redirectTo={ROUTES.SIGN_IN} />}
        />

        <Route
          path={ROUTES.CALENDAR}
          element={<ProtectedRoutes component={<CalendarPage />} redirectTo={ROUTES.SIGN_IN} />}
        />
        <Route
          path={ROUTES.PROGRAMS}
          element={<ProtectedRoutes component={<ProgramsPage />} redirectTo={ROUTES.SIGN_IN} />}
        >
          <Route path={PROGRAMS_ROUTES.FAVORITE} element={<ProgramsWorkSpace />} />
          <Route path={PROGRAMS_ROUTES.PROGRAM_ID} element={<ProgramsWorkSpace />} />
        </Route>

        <Route path={ROUTES.CHAT} element={<ProtectedRoutes component={<Chat />} redirectTo={ROUTES.SIGN_IN} />} />

        <Route path={ROUTES._PROJECT} element={<ProtectedRoutes component={<Project />} redirectTo={ROUTES.SIGN_IN} />}>
          <Route path=":id" element={<ProjectSpace />}>
            <Route path={PROJECT_PATH.TASKS} element={<ProjectTasks />} />
            <Route path={PROJECT_PATH.MY_TASKS} element={<ProjectMyTasks />} />
            <Route path={PROJECT_PATH.BACKLOG} element={<ProjectBacklog />} />
            <Route path={PROJECT_PATH.HISTORY} element={<ProjectTasksHistory />} />
            <Route path={PROJECT_PATH.FILES} element={<ProjectFiles />} />
            <Route path={PROJECT_PATH.ANALYTICS} element={<ProjectAnalytics />} />
            <Route path={PROJECT_PATH.TEAM} element={<ProjectTeam />} />
            <Route path={PROJECT_PATH.TRASH} element={<ProjectTrash />} />
            <Route path={PROJECT_PATH.DOC} element={<ProjectDoc />} />
            <Route path={PROJECT_PATH.LINKS} element={<ProjectLinks />} />
          </Route>
        </Route>

        <Route
          path={ROUTES.PROJECT}
          element={<ProtectedRoutes component={<JoinProjectPage />} redirectTo={ROUTES.SIGN_IN} />}
        >
          <Route path=":id" element={<ProjectPage />}>
            <Route path={PROJECT_PATH.TASKS} element={<ProjectTasksPage />} />

            <Route path={PROJECT_PATH.MY_TASKS} element={<ProjectTasksPage />} />
            <Route path={PROJECT_PATH.BACKLOG} element={<ProjectBacklogPage />} />
            <Route path={PROJECT_PATH.TEAM} element={<ProjectTeamPage />} />
            <Route path={PROJECT_PATH.HISTORY} element={<ProjectHistoryPage />} />
            <Route path={PROJECT_PATH.TRASH} element={<ProjectTrashPage />} />

            <Route path={PROJECT_PATH.ANALYTICS} element={<ProjectAnalyticsPage />} />
            <Route path={PROJECT_PATH.GANT} element={<ProjectGantPage />} />

            <Route path={PROJECT_PATH.LINKS} element={<ProjectLinksPage />} />
            <Route path={PROJECT_PATH.LIBRARY} element={<ProjectLibraryPage />}>
              <Route path={LIBRARY_ROUTES.CATEGORIES} element={<ProjectLibraryCategoryPage />} />
              <Route path={LIBRARY_ROUTES.DIR_ID} element={<ProjectLibraryCategoryPage />} />
              <Route path={LIBRARY_ROUTES.SEARCH} element={<ProjectLibrarySearchPage />} />
              <Route path={LIBRARY_ROUTES.SETTINGS} element={<ProjectLibrarySettingsPage />} />
            </Route>
            <Route path={PROJECT_PATH.FILES} element={<ProjectFilesPage />}>
              <Route path={PROJECT_FOLDER.DIR_ID} element={<ProjectFilesFolderPage />} />
            </Route>
            <Route path={PROJECT_PATH.DOC} element={<ProjectDocumentsPage />}>
              <Route path={PROJECT_FOLDER.DIR_ID} element={<ProjectDocumentsPage />} />
            </Route>
            <Route path={PROJECT_PATH.SETTINGS} element={<ProjectSettingsPage />} />
            <Route path={`${PROJECT_PATH.TASK}/:id_task`} element={<ProjectTaskPage />} />
          </Route>
        </Route>

        <Route path={ROUTES.MAIL} element={<ProtectedRoutes component={<MailPage />} redirectTo={ROUTES.SIGN_IN} />}>
          <Route path={MAIL_ROUTS.POSTBOX} element={<PostboxWorkSpace />}>
            <Route path={MAIL_ROUTS.FOLDER} element={<PostboxFolder />} />
          </Route>
        </Route>

        <Route
          path={ROUTES.ARCHIVE}
          element={<ProtectedRoutes component={<ArchivePage />} redirectTo={ROUTES.SIGN_IN} />}
        >
          <Route path={ARCHIVE_ROUTES.DIR_ID} element={<ArchivedFiles />} />
        </Route>

        <Route
          path={ROUTES.LIBRARY}
          element={<ProtectedRoutes component={<LibraryPage />} redirectTo={ROUTES.SIGN_IN} />}
        >
          <Route path={LIBRARY_ROUTES.CATEGORIES} element={<LibraryCategoryPage />} />
          <Route path={LIBRARY_ROUTES.RUBRICS} element={<LibraryCategoryPage />} />
          <Route path={LIBRARY_ROUTES.DIR_ID} element={<LibraryCategoryPage />} />
          <Route path={LIBRARY_ROUTES.SEARCH} element={<LibrarySearchPage />} />
          <Route path={LIBRARY_ROUTES.SETTINGS} element={<LibrarySettingsPage />} />
        </Route>

        <Route path={ROUTES.TRASH} element={<ProtectedRoutes component={<TrashPage />} redirectTo={ROUTES.SIGN_IN} />}>
          <Route path={TRASH_ROUTES.DIR_ID} element={<TrashFiles />} />
        </Route>

        <Route
          path={ROUTES.PROFILE}
          element={<ProtectedRoutes component={<ProfilePage />} redirectTo={ROUTES.SIGN_IN} />}
        >
          <Route path={PROFILE_ROUTES.ABOUT_ME} element={<AboutMe />} />
          <Route path={PROFILE_ROUTES.EMPLOYEES} element={<ProfileEmployees />} />
          <Route path={PROFILE_ROUTES.CONTACTS} element={<Contacts />} />
          <Route path={PROFILE_ROUTES.MY_SERVICES} element={<ProfileServices />} />
          <Route path={PROFILE_ROUTES.TARIFF} element={<Tariff />} />
          <Route path={PROFILE_ROUTES.INTEGRATIONS} element={<div>INTEGRATIONS</div>} />
          <Route path={PROFILE_ROUTES.MEMORY} element={<MemoryPage />} />
          <Route path={PROFILE_ROUTES.ACTIONS_LOG} element={<JournalPage />}>
            <Route path={`${ACTIONS_LOG_ROUTES.GROUP}/${ACTIONS_LOG_ROUTES.ID}`} element={<SingleObjJournalPage />} />
          </Route>
          <Route path={PROFILE_ROUTES.SESSEIONS} element={<ProfileSessions />} />
          <Route path={PROFILE_ROUTES.SETTINGS} element={<SettingsPage />} />
          <Route path={PROFILE_ROUTES.TAGS} element={<TagsPage />} />
        </Route>

        <Route path={ROUTES.FILE} element={<FilePage />} />
        <Route path={`${ROUTES.SHARE_FOLDER}/${SHARE_FOLDER_ROUTES.DID}`} element={<ShareFolderPage />}>
          <Route path={SHARE_FOLDER_ROUTES.DIR_ID} element={<SharedFolderElementsList />} />
        </Route>

        <Route path={ROUTES.SEARCH} element={<SearchPage />} />

        <Route path="*" element={<h1>Эта страница в разработке</h1>} />
      </Route>
    </Routes>
  );
};

export default AppRouting;
