import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { useSliderData } from "collections/questionnaire";
import Slider from "containers/Authentication/Slider/Slider";
import { ROUTES } from "generalComponents/variables/routing";
import { useLocales } from "react-localized";
import { Link, useLocation } from "react-router-dom";

import styles from "./Layout.module.sass";

const Layout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { pathname } = useLocation();
  const { __ } = useLocales();

  const data = useSliderData();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to="https://fslanding.mh.net.ua/">
          <Logo className={styles.logo} />
        </Link>
        <div className={styles.wrapper}>{children}</div>
        <span className={styles.info}>
          {pathname === `/${ROUTES.SIGN_UP}` ? (
            <>
              {__("Уже есть аккаунт?")} <Link to={`/${ROUTES.SIGN_IN}`}>{__("Вход")}</Link>
            </>
          ) : (
            <>
              {__("Еще нет аккаунта?")} <Link to={`/${ROUTES.SIGN_UP}`}>{__("Регистрация")}</Link>
            </>
          )}
        </span>
      </div>
      <div className={styles.slider}>
        <Slider data={data} />
      </div>
    </div>
  );
};

export default Layout;
