import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload_3.svg";
import classNames from "classnames";
import { useTimeZones } from "collections/profile";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import IconTooltip from "generalComponents/IconTooltip/IconTooltip";
import Input from "generalComponents/Input/Input";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import Switcher from "generalComponents/Switcher/Switcher";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ChangeEvent, FC, ReactNode, useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { getCurrentTimezoneOffset } from "utils/timezone";
import { isPhoneNumber } from "utils/validation";

import { DataFormTypes, DataState } from "../lib/stateReducer";
import styles from "./EmployeeData.module.sass";

interface IProps {
  data: DataState;
  dispatchData: Function;
  isEditEmail?: boolean;
  onChangeEmail?: () => void;
  isEditTel?: boolean;
  onChangeTel?: () => void;
  isEdit?: boolean;
  isNew?: boolean;
  isActiveTel?: boolean;
  repeateBlock?: ReactNode;
  onChangeTimeZone?: (value: string) => void;
  onChangeAutoTimeZone?: (value: boolean) => void;
}

const EmployeeData: FC<IProps> = ({
  data,
  dispatchData,
  isEditEmail,
  onChangeEmail,
  isEditTel,
  onChangeTel,
  isEdit,
  isNew,
  isActiveTel,
  repeateBlock,
  onChangeTimeZone,
  onChangeAutoTimeZone
}) => {
  const { __ } = useLocales();
  const { onSetAvatarCropModal } = useActions();
  const [avatarName, setAvatarName] = useState<string>(`${data.fname} ${data.sname}`);
  const [telErrors, setTelErrors] = useState<string[]>([]);
  const [mainTelError, setMainTelError] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>();
  const fileSelect = () => inputRef.current.click();
  const timeZoneOptions = useTimeZones();
  const onBlurInput = () => {
    setAvatarName(`${data.fname} ${data.sname}`);
  };
  const [autoTimezone, setAutoTimezone] = useState(data.is_gmt_auto);

  const uploadImage = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image")) {
      const imgSrc = URL.createObjectURL(file);
      onSetAvatarCropModal({
        open: true,
        imgSrc,
        callback: (icon) => dispatchData({ type: DataFormTypes.CHANGE, field: "icon", value: icon })
      });
    }
  };
  const removeIcon = () => dispatchData({ type: DataFormTypes.CHANGE, field: "icon", value: null });
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget;
    if (name === "tel") {
      if (/[^0-9+]/.test(value)) {
        setMainTelError("Поле должно содержать только цифры");
      } else {
        setMainTelError("");
      }
      if (value === "+") return dispatchData({ type: DataFormTypes.CHANGE, field: name, value });
      if (!value) return dispatchData({ type: DataFormTypes.CHANGE, field: name, value: "" });
      if (isNaN(+value.slice(1)) || isNaN(+value)) return;
      if (value.startsWith("+")) return dispatchData({ type: DataFormTypes.CHANGE, field: name, value });
      return dispatchData({ type: DataFormTypes.CHANGE, field: name, value: "+" + value });
    }
    dispatchData({ type: DataFormTypes.CHANGE, field: name, value });
  };
  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>, index: number): void => {
    const { value, name } = e.currentTarget;
    if (/[^0-9+]/.test(value)) {
      setTelErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = "Поле должно содержать только цифры";
        return updatedErrors;
      });
    } else if (!isPhoneNumber(value) && value.length > 0) {
      setTelErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = __("Неверный формат номера телефона");
        return updatedErrors;
      });
    } else {
      removeErrorOnBlur(index);
    }
    if (value === "+") return dispatchData({ type: DataFormTypes.CHANGE_PHONE, field: name, value });
    if (!value) return dispatchData({ type: DataFormTypes.CHANGE_PHONE, field: name, value: "" });
    if (isNaN(+value.slice(1)) || isNaN(+value)) return;
    if (value.startsWith("+")) return dispatchData({ type: DataFormTypes.CHANGE_PHONE, field: name, value });
    return dispatchData({ type: DataFormTypes.CHANGE_PHONE, field: name, value: "+" + value });
  };
  const addNumber = (): void => dispatchData({ type: DataFormTypes.ADD_PHONE });
  const removeNumber = (id: string): void => dispatchData({ type: DataFormTypes.REMOVE_PHONE, field: id });
  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget;
    dispatchData({ type: DataFormTypes.CHANGE_EMAIL, field: name, value });
  };
  const addEmail = (): void => dispatchData({ type: DataFormTypes.ADD_EMAIL });
  const removeEmail = (id: string): void => dispatchData({ type: DataFormTypes.REMOVE_EMAIL, field: id });
  const removeErrorOnBlur = (index: number): void =>
    setTelErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = "";
      return updatedErrors;
    });
  const placeholder = __("Введите");

  useEffect(() => {
    if (!onChangeTimeZone) return;
    if (autoTimezone) {
      onChangeTimeZone(getCurrentTimezoneOffset());
    }
    onChangeAutoTimeZone(autoTimezone);
  }, [autoTimezone]); //eslint-disable-line

  return (
    <>
      <div className={styles.avatarBlock}>
        <Avatar size={50} name={avatarName || `${data.fname} ${data.sname}`} imgSrc={data.icon} />
        <div className={styles.uploadBtns}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.EXTRA_SMALL}
            onClick={fileSelect}
            iconL={<UploadIcon />}
            text={__("Загрузить")}
          />
          {Boolean(data.icon) && (
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.EXTRA_SMALL}
              onClick={removeIcon}
              iconL={<TrashIcon width={9} height={13} />}
              text={__("Удалить")}
            />
          )}
        </div>
        <input
          type="file"
          ref={inputRef}
          className="hidden"
          onChange={uploadImage}
          style={{ display: "none" }}
          accept="image/png, image/gif, image/jpeg, image/jpg"
        />
      </div>
      <div className={styles.inputs}>
        <Input
          className={styles.input}
          value={data.fname}
          onChange={handleChange}
          name="fname"
          label={__("Имя")}
          placeholder={placeholder}
          onBlur={onBlurInput}
          require={isNew}
        />
        <Input
          className={styles.input}
          value={data.sname}
          onChange={handleChange}
          name="sname"
          label={__("Фамилия")}
          placeholder={placeholder}
          onBlur={onBlurInput}
        />
        <Input
          className={styles.input}
          value={data.pname}
          onChange={handleChange}
          name="pname"
          label={__("Отчество")}
          placeholder={placeholder}
        />
      </div>
      <div className={styles.contactBox}>
        <div className={styles.subtitleBlock} style={{ position: "static" }}>
          <span>{__("Контактная информация")}</span>
          <IconTooltip text={__("Контактная информация видна всем сотрудникам")} place="top" id="contact" />
        </div>
        {Object.entries(data.tels).map(([id, number], i) => (
          <div key={id} className={styles.phoneBlock}>
            <Input
              className={styles.input}
              value={number}
              onChange={(e) => handleChangePhone(e, i)}
              name={id}
              label={`${__("Телефон")} ${i + 1}`}
              type="text"
              placeholder={placeholder}
              error={telErrors[i] ? true : false}
              errorText={telErrors[i]}
              onBlur={() => removeErrorOnBlur(i)}
            />
            {i === 0 ? (
              <Button
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.MEDIUM}
                onClick={addNumber}
                iconR={<PlusIcon />}
                isSquare
                disabled={Object.keys(data.tels).length >= 3}
              />
            ) : (
              <Button
                variant={ButtonVariantType.OPACITY}
                size={ButtonSizeType.MEDIUM}
                onClick={() => removeNumber(id)}
                iconR={<TrashIcon />}
                isSquare
              />
            )}
          </div>
        ))}
        {Object.entries(data.emails).map(([id, email], i) => (
          <div key={id} className={styles.phoneBlock}>
            <Input
              className={styles.input}
              value={email}
              onChange={handleChangeEmail}
              name={id}
              label={`Email ${i + 1}`}
              placeholder={placeholder}
            />
            {i === 0 ? (
              <Button
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.MEDIUM}
                onClick={addEmail}
                iconR={<PlusIcon />}
                isSquare
                disabled={Object.keys(data.emails).length >= 3}
              />
            ) : (
              <Button
                variant={ButtonVariantType.OPACITY}
                size={ButtonSizeType.MEDIUM}
                onClick={() => removeEmail(id)}
                iconR={<TrashIcon />}
                isSquare
              />
            )}
          </div>
        ))}
      </div>
      {onChangeTimeZone && (
        <div className={styles.timezone}>
          <div className={classNames(styles.timezoneSelect, autoTimezone && styles.disabled)}>
            <SelectCustom
              disabled={autoTimezone}
              selectedDefault={timeZoneOptions.find((item) => item.value === data.gmt.toString())}
              value={timeZoneOptions.find((item) => item.value === data.gmt.toString())}
              name="timeZone"
              onSelected={(e) => onChangeTimeZone(e.currentTarget.value)}
              options={timeZoneOptions}
              width={384}
              variantHeight="medium"
            />
          </div>
          <div className={styles.autoTimezone}>
            <div>
              <Switcher checked={autoTimezone} onClickCheckBox={() => setAutoTimezone((prev) => !prev)} />
              {__("Автоопределение")}
            </div>
            <IconTooltip
              title={__("Автоопределение")}
              text={__(
                "<b>Вкл</b> — используется системный часовой пояс. <br/> <b>Выкл</b> — выбор часового пояса вручную, переход на зимнее/летнее время осуществляется вручную."
              )}
              id="tz"
            />
          </div>
        </div>
      )}
      <div className={styles.emailBox}>
        <div className={styles.subtitleBlock}>
          <span>{__("Данные авторизации")}</span>
          <IconTooltip text={__("Данные авторизации не видны другим сотрудникам")} place="top" id="data" />
        </div>
        <div className={styles.divFlex}>
          <Input
            className={styles.input}
            value={data.email}
            onChange={handleChange}
            name="email"
            label={__("Основной Email")}
            require
            error={Boolean(data.emailError)}
            errorText={data.emailError}
            placeholder={placeholder}
            disabled={isNew || false}
          />
          {isEdit && !isNew && (
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.MEDIUM}
              onClick={onChangeEmail}
              text={__("Изменить")}
              iconL={<EditIcon />}
              disabled={!isEditEmail}
            />
          )}
        </div>
        {repeateBlock && repeateBlock}
        <div className={styles.divFlex}>
          <Input
            className={styles.input}
            value={data.tel}
            onChange={handleChange}
            name="tel"
            label={__("Основной Телефон")}
            placeholder={placeholder}
            isActive={isActiveTel}
            error={mainTelError.length > 0 ? true : false}
            errorText={mainTelError}
            onBlur={() => setMainTelError("")}
          />
          {isEdit && (
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.MEDIUM}
              onClick={onChangeTel}
              text={__("Изменить")}
              iconL={<EditIcon />}
              disabled={!isEditTel}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeeData;
