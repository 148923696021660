import Chart from "chart.js/auto";
import { useDefaultProjectStatuses, useFilteredTasks } from "hooks/joinProjectHooks";
import { useEffect, useMemo, useRef } from "react";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectStatusStatistic.module.sass";

export const JointProjectStatusStatistic = () => {
  const { project } = useJoinProjectsSelectors();
  const { filteredTasks } = useFilteredTasks(project.tasks);
  const defaultStatuses = useDefaultProjectStatuses();
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const idStatusHashRef = useRef(null);

  const idStatusHash = useMemo(() => {
    return filteredTasks.map((task) => task.id_status).join(",");
  }, [filteredTasks]);

  useEffect(() => {
    const statuses = project.statuses;
    const visibleStatuses = statuses.filter((status) => status.is_visible !== false);
    if (idStatusHash !== idStatusHashRef.current) {
      idStatusHashRef.current = idStatusHash;
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const sortedData = visibleStatuses
        .sort((a, b) => a.y - b.y)
        .map((status) => ({
          ...status,
          id: status.id,
          color: status.color,
          count: filteredTasks?.filter((task) => task.id_status === status.id).length
        }));

      const data = {
        labels: sortedData.map((status) =>
          status.name ? `${status.name}: ${status.count}` : `${defaultStatuses[status.system_name]}: ${status.count}`
        ),
        datasets: [
          {
            data: sortedData.map((status) => status.count),
            backgroundColor: sortedData.map((status) => status.color),
            color: sortedData.map((status) => status.color),
            barThickness: 12,
            borderRadius: 2
          }
        ]
      };

      const maxCount = Math.max(...sortedData.map((status) => status.count));

      const grayBarsData = {
        labels: sortedData.map((status) =>
          status.name ? `${status.name}: ${status.count}` : `${defaultStatuses[status.system_name]}: ${status.count}`
        ),
        datasets: [
          {
            data: sortedData.map(() => (maxCount > 4 ? maxCount : 4)),
            backgroundColor: ["rgba(169, 169, 169, 0.5)"],
            color: sortedData.map((status) => status.color),
            barThickness: 12,
            borderRadius: 2,
            order: 0
          }
        ]
      };

      const containerWidth = sortedData.length * 17 + 20 + "px";

      const ctx = chartRef.current.getContext("2d");

      data.datasets.push(grayBarsData.datasets[0]);

      chartInstance.current = new Chart(ctx, {
        type: "bar",
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "x",
          scales: {
            x: { display: false, stacked: true },
            y: { display: false, max: maxCount + 1 }
          },
          layout: {
            padding: {
              top: 28,
              left: 10,
              right: 10
            }
          },
          plugins: {
            legend: { display: false },
            datalabels: { display: false },
            tooltip: {
              enabled: data.datasets[0].data.some((item) => item > 0) ? true : false,
              intersect: true,
              position: "nearest",
              callbacks: {
                label: function () {
                  return null;
                }
              },
              backgroundColor: function (tooltipItem) {
                const index = tooltipItem.tooltip.dataPoints[0].dataIndex;
                const dataset: any = tooltipItem.tooltip.dataPoints[0].dataset;
                const color = dataset.color[index] as string;
                return color;
              },
              displayColors: false,
              titleFont: {
                family: "Roboto",
                size: 12
              },
              caretSize: 0,
              titleMarginBottom: 0,
              padding: data.datasets[0].data.some((item) => item > 0) ? 6 : 0
            }
          }
        }
      });

      chartRef.current.parentNode.style.maxWidth = containerWidth;
    }
  }, [project.statuses, filteredTasks]); //eslint-disable-line

  return (
    <div className={styles.container}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default JointProjectStatusStatistic;
