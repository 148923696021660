import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ChangeEvent, FC, useRef } from "react";

import styles from "./InputUpload.module.sass";

interface InputUploadProps {
  name: string;
  className?: string;
  uploadFile: (event: ChangeEvent<HTMLInputElement>) => void;
  removeFile?: () => void;
  variantBtnTrash?: ButtonVariantType;
  sizeBtnTrash?: ButtonSizeType;
  variantBtnUpload?: ButtonVariantType;
  sizeBtnUpload?: ButtonSizeType;
  textUpload: string;
  textTrash?: string;
  uploadIcon: JSX.Element;
  trashIcon?: JSX.Element;
  fileUpload: File[] | File | string;
  accept?: string;
  multiple?: boolean;
  visibleTrashBtn?: boolean;
}

export const InputUpload: FC<InputUploadProps> = ({
  name,
  className,
  textUpload,
  textTrash,
  uploadIcon,
  trashIcon,
  variantBtnTrash = ButtonVariantType.EXRTA_LIGHT,
  sizeBtnTrash = ButtonSizeType.EXTRA_SMALL,
  variantBtnUpload = ButtonVariantType.EXRTA_LIGHT,
  sizeBtnUpload = ButtonSizeType.EXTRA_SMALL,
  accept,
  fileUpload,
  multiple = false,
  uploadFile,
  removeFile,
  visibleTrashBtn = false
}) => {
  const inputRef = useRef<HTMLInputElement>();

  const fileSelect = () => inputRef.current.click();

  if (fileUpload?.length === 0 && inputRef?.current) {
    inputRef.current.value = "";
  }

  return (
    <div className={classNames(styles.previewBlock, className)}>
      <div className={styles.uploadBtns}>
        <Button
          variant={variantBtnUpload}
          size={sizeBtnUpload}
          onClick={fileSelect}
          iconL={uploadIcon}
          text={textUpload}
        />
        {visibleTrashBtn && !!fileUpload?.length && (
          <Button
            variant={variantBtnTrash}
            size={sizeBtnTrash}
            onClick={removeFile}
            iconL={trashIcon}
            text={textTrash}
          />
        )}
      </div>
      <input
        name={name}
        type="file"
        ref={inputRef}
        className={styles.hidden}
        onChange={uploadFile}
        accept={accept}
        multiple={multiple}
      />
    </div>
  );
};
