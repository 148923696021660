import { IFavoriteItemsState, initialFavoriteItemsState } from "models/store/favoriteItems/favoriteItems";
import { FavoriteItemsActions } from "models/store/favoriteItems/favoriteItemsActions";
import { FavoriteItemsTypes } from "Store/types/favoriteItemsTypes";

const INITIAL_STATE = initialFavoriteItemsState();

export const FavoriteItemsReducer = (state = INITIAL_STATE, action: FavoriteItemsActions): IFavoriteItemsState => {
  switch (action.type) {
    case FavoriteItemsTypes.SET_FAVORITE_ITEMS:
      return { ...state, favoriteItems: action.payload };

    default:
      return state;
  }
};
