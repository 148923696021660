import { PAGE_DEPTH } from "generalComponents/variables/global";
import { ILoadingFiles } from "models/store/files/uploadFilesModals/uploadFiles";
import { IFolder } from "models/store/folders/foldersStore";
export interface ICreateFileModalState {
  open: boolean;
  ext: string;
  dep: PAGE_DEPTH;
  folders: IFolder[];
  onLoading: (payload: ILoadingFiles) => void;
}

export function initialCreateFileModalState(): ICreateFileModalState {
  return { open: false, dep: null, ext: null, folders: null, onLoading: null };
}
