// import Notifications from "../Notifications";
import { StorageSize } from "containers/Profile/Memory/StorageSize/StorageSize";
import HeaderSearch from "generalComponents/HeaderSearch/HeaderSearch";
import NotesButtons from "generalComponents/NotesButtons/NotesButtons";
import NotesSidebar from "generalComponents/NotesSidebar/NotesSidebar";
import Profile from "generalComponents/Profile/Profile";
import ScreenPaintingPanel from "generalComponents/ScreenPaintingPanel/ScreenPaintingPanel";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./Header.module.sass";

const Header = () => {
  const { userInfo } = useUserSelectors();

  return (
    <div className={styles.header}>
      {/* <SearchFieldDeprecated /> */}
      <HeaderSearch />
      <div className={styles.infoHeader}>
        {Boolean(userInfo?.is_admin) && <StorageSize />}
        {/* <Notifications /> */}
        <NotesButtons />
        <ScreenPaintingPanel />

        <Profile />
      </div>
      <NotesSidebar />
    </div>
  );
};

export default Header;
