import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import React, { Fragment } from "react";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./NextActionModal.module.sass";

const NextActionModal = () => {
  const { onNextAction } = useActions();

  const {
    nextAction: {
      params: { icon, title, text, buttons }
    }
  } = useGlobalModalsSelectors();

  const closeModal = () => onNextAction({ open: false, params: null });
  return (
    <PopUp set={closeModal} zIndex={10001}>
      <div className={styles.wrap}>
        <HeaderModal title={title} onClose={closeModal} />
        <div className={styles.container}>
          {icon && icon}
          <div className={styles.body}>
            {text && <p className={styles.text}>{text}</p>}
            {buttons?.length > 0 && (
              <div className={styles.buttonsWrap}>
                {buttons.map((el, i) => (
                  <Fragment key={i}>{el}</Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default NextActionModal;
