import classNames from "classnames";
import { noteTitles, useNotesCategoriesNames, useNotesTitles } from "collections/notes";
import NoteItem from "containers/Notes/NotesCategories/components/NoteItem/NoteItem";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialNotesCategoryFullState } from "models/store/Cabinet/modals/notesCategoryFull";
import { INote } from "models/store/notes/notes";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useNotesSelectors } from "Store/selectors/notesSelectors";
import { convertToRGBA } from "utils/convertToRGBA";

import styles from "./NotesFullCategoryModal.module.sass";

const NotesFullCategoryModal = () => {
  const { __ } = useLocales();
  const titles = useNotesTitles();
  const [sortedNotes, setSortedNotes] = useState<{ [date: string]: INote[] }>({});
  const { onSetNoteFullCategoryModal, onSetNoteEditModal, onSetActiveNote } = useActions();
  const {
    notesFullCategory: { category }
  } = useGlobalModalsSelectors();

  const defaultNames = useNotesCategoriesNames();

  const { notes } = useNotesSelectors();

  const closeModal = (): void => {
    onSetNoteFullCategoryModal(initialNotesCategoryFullState());
  };

  const sortNotesByDate = (notes: INote[]) => {
    const sorted: { [date: string]: INote[] } = {};
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    notes
      ?.filter((item) => item.id_dep === category.id)
      .forEach((note) => {
        const noteDate = new Date(note.ut);
        const todayMidnight = new Date(today);
        todayMidnight.setHours(0, 0, 0, 0);
        const yesterdayMidnight = new Date(yesterday);
        yesterdayMidnight.setHours(0, 0, 0, 0);

        let cat = "";
        if (noteDate >= todayMidnight) {
          cat = noteTitles.TODAY;
        } else if (noteDate >= yesterdayMidnight) {
          cat = noteTitles.YESTERDAY;
        } else if (noteDate >= lastWeek) {
          cat = noteTitles.LAST_7_DAYS;
        } else {
          cat = noteTitles.LAST_30_DAYS;
        }

        if (!sorted[cat]) {
          sorted[cat] = [];
        }
        sorted[cat].unshift(note);
      });

    for (const key in sorted) {
      sorted[key].sort((a, b) => {
        return new Date(b.ut).getTime() - new Date(a.ut).getTime();
      });
    }

    return sorted; // Возвращаем отсортированные заметки
  };

  useEffect(() => {
    const sorted = sortNotesByDate(notes);
    setSortedNotes(sorted);
  }, [notes]); //eslint-disable-line

  return (
    <PopUp set={closeModal}>
      <div
        className={classNames(styles.category, styles[`size_${category.size}`])}
        style={{
          background: category.color && convertToRGBA(category.color, 50)
        }}
      >
        <h6>
          {category.name || defaultNames[category.default_name]}{" "}
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("Создать")}
            onClick={() => {
              onSetActiveNote(null);
              onSetNoteEditModal({ open: true, category_id: category.id });
            }}
          />
        </h6>
        <div className={styles.notesList}>
          {Object.keys(sortedNotes).length > 0 ? (
            Object.keys(sortedNotes).map(
              (cat) =>
                sortedNotes[cat]?.length > 0 && (
                  <div key={cat}>
                    <div className={styles.header}>{titles[cat]}</div>
                    <div className={styles.notes}>
                      {sortedNotes[cat].map((item) => (
                        <NoteItem key={item.id} item={item} />
                      ))}
                    </div>
                  </div>
                )
            )
          ) : (
            <div className={styles.empty}>
              <Clue text={__("Тут пока пусто")} icon="folder" />
            </div>
          )}
        </div>
      </div>
    </PopUp>
  );
};

export default NotesFullCategoryModal;
