export enum ButtonVariantType {
  CANCEL = "cancel",
  OK = "ok",
  OK_RED = "okRed",
  ADD = "add",
  ADD_GREY = "addGrey",
  OPACITY = "opacity", // opacity
  EXRTA_LIGHT = "extraLight", // transparency
  LIGHT = "light", // light
  DARK = "dark", // Primary
  BLUE = "blue", // Secondary
  ACCORDION = "accordion",
  SPECIAL = "special",
  RED = "red",
  RED_RESERSE = "redReverse",
  DARK_GREEN = "darkGreen",
  GREEN = "green"
}
export enum ButtonSizeType {
  EXTRA_SMALL = "extraSmall", // height 24px
  SMALL = "small", // height 28px
  MEDIUM = "medium", // height 32px
  LARGE = "large" // height 40px
}

export interface IButtonProps {
  className?: string;
  onClick?: (el: React.MouseEvent<HTMLButtonElement>) => void;
  variant: ButtonVariantType;
  size?: ButtonSizeType;
  text?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  full?: boolean;
  isActive?: boolean;
  iconR?: JSX.Element;
  iconL?: JSX.Element;
  isSquare?: boolean;
  title?: string;
  ref?: any;
  onMouseDown?: (el: React.MouseEvent<HTMLButtonElement>) => void;
}
