import Loader from "generalComponents/Loaders/4HUB";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { LoaderTypes } from "models/generalComponents/loader";
import { FC, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import styles from "./Observer.module.sass";

interface IObserverProps {
  loader: boolean;
  page: number;
  total: number;
  callBack: () => void;
}

const Observer: FC<IObserverProps> = ({ loader, page, total, callBack }): JSX.Element => {
  const { ref, inView } = useInView({
    threshold: 0.5
  });

  useEffect(() => {
    if (Math.ceil(total / PER_PAGE) <= page) return;

    if (inView && page !== total) {
      callBack();
    }
  }, [inView]); //eslint-disable-line

  return loader ? (
    <div className={styles.loaderBox}>
      <Loader
        type={LoaderTypes.BOUNCING_DOTS}
        position="absolute"
        background="white"
        zIndex={5}
        width="100px"
        height="100px"
        containerType="bounceDots"
      />
    </div>
  ) : (
    <div ref={ref} className={styles.observer} />
  );
};

export { Observer };
