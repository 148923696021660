import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { nameToInitials } from "utils/nameToInitials";
import { stringToColor } from "utils/stringToColor";

import styles from "./Avatar.module.sass";

interface IAvatarProps {
  name?: string;
  imgSrc?: string;
  size?: number;
  status?: string;
  isRound?: boolean;
}

const Avatar: FC<IAvatarProps> = ({ name, imgSrc, size = 32, status, isRound = false }) => {
  const { color1, color2 } = stringToColor(name.replace(" ", "").trim());
  const [isLoadImage, setIsLoadImage] = useState<boolean>(imgSrc ? true : false);
  const handleImageLoad = () => setIsLoadImage(false);
  const handleImageError = () => setIsLoadImage(true);
  useEffect(() => {
    if (!imgSrc) {
      setIsLoadImage(true);
      return;
    }
    // Добавляем слушатели после первого рендера
    const imgElement = new Image();
    imgElement.src = imgSrc;
    imgElement.addEventListener("load", handleImageLoad);
    imgElement.addEventListener("error", handleImageError);

    // Очистка слушателей при размонтировании компонента
    return () => {
      imgElement.removeEventListener("load", handleImageLoad);
      imgElement.removeEventListener("error", handleImageError);
    };
  }, [imgSrc]);

  return (
    <span
      className={classNames(styles.avatar, { [styles[status]]: Boolean(status), [styles.round]: isRound })}
      style={{
        background: `linear-gradient(${color2}, ${color1})`,
        width: size,
        height: size,
        fontSize: size
      }}
      title={name}
    >
      {imgSrc && !isLoadImage && (
        <img className={classNames(styles.image, { [styles.hidden]: isLoadImage })} src={imgSrc} alt="" title={name} />
      )}
      {isLoadImage && <span className={styles.name}>{nameToInitials(name)}</span>}
    </span>
  );
};

export default Avatar;
