import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IProject } from "models/store/joinProjects/joinProgects";
import { ISafe } from "models/store/safe/safe";
import { IMyTask } from "models/store/tasks/tasks";
import { AllElementsArrayType, AllElementsType } from "models/store/typeElements";
import { IUserInfo } from "models/store/user/user";

export const removesElement = (obj: AllElementsType, arr: AllElementsArrayType) => {
  const asFilePayload = obj as IFile;
  const asFolderPayload = obj as IFolder;
  const asTaskPayload = obj as IMyTask;
  const asOtherPayload = obj as ISafe | IProject | IProject | IUserInfo;

  return arr?.filter((e) => {
    const asFile = e as IFile;
    const asFolder = e as IFolder;
    const asTask = e as IMyTask;
    const asOther = e as ISafe | IProject | IProject | IUserInfo;

    if (obj.table === e.table) {
      if (asFile?.fid) {
        return asFilePayload?.fid !== asFile?.fid;
      }

      if (asFile?.is_dir) {
        return asFolderPayload.id_dir !== asFolder?.id_dir;
      }

      if (asTask?.id_type) {
        return asTaskPayload.id_type !== asTask.id_type && asTaskPayload.id !== asTask.id;
      }

      if (!asTask?.id_type && asOther?.id) {
        return asOtherPayload.id !== asOther.id;
      }
    }

    return true;
  });
};
