// convert data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/....== to file.jpeg
export const dataURLToFile = (dataUrl: string): File => {
  const blobBin = window.atob(dataUrl.split(",")[1]);
  const array = [];
  for (let i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }

  const blob = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  const file = new File([blob], `${Date.now()}.jpeg`, {
    type: "image/jpeg"
  });

  return file;
};

export const dataURLToFileSize = async (
  dataUrl: string,
  width: number = 200,
  height: number = 200,
  type: string = "jpeg"
): Promise<File> => {
  return new Promise((resolve) => {
    const blobBin = window.atob(dataUrl.split(",")[1]);
    const array = [];
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }

    const blob = new Blob([new Uint8Array(array)], { type });
    const file = new File([blob], `${Date.now()}.${type.split("/")[1]}`, { type, lastModified: Date.now() });

    // Если заданы размеры, создайте объект URL для изображения и установите размеры
    if (width && height) {
      const imageUrl = URL.createObjectURL(file);
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((newBlob) => {
          // Замените оригинальный Blob новым Blob с измененными размерами
          const newFile = new File([newBlob], file.name, { type, lastModified: Date.now() });
          // Очистите объект URL после использования
          URL.revokeObjectURL(imageUrl);
          // Резольвим Promise с новым файлом
          resolve(newFile);
        }, type);
      };

      img.src = imageUrl;
    } else {
      // Если размеры не заданы, резольвим Promise с оригинальным файлом
      resolve(file);
    }
  });
};
