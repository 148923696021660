import api from "api";
import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import Button from "generalComponents/Button/Button";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { setStorageItem } from "generalComponents/Services/storageServices";
import { PROJECT_ADMIN_ROLES } from "generalComponents/variables/project";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onDeleteUser } from "Store/actions/ProjectActions";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";
import { ProjectTypes } from "Store/types/projectTypes";
import { projectUserProps } from "types/Project";

import styles from "../LeaveProject.module.sass";

const AddedAdmin = ({ params, onClose }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { project } = useProjectsSelectors();
  const user = useSelector((s) => s.user);

  const onEditProject = async () => {
    try {
      const payload = new FormData();
      payload.append("uid", user.uid);
      payload.append("id_project", project.id);
      payload.append("show_message", PROJECT_ADMIN_ROLES.ADMIN);
      const { data } = await api.post(`/ajax/project_edit.php`, payload);
      checkResponseStatus(data.ok);
      const params = {
        id_user: user.userInfo.id_user,
        id_project: project.id
      };
      dispatch(onDeleteUser(params));

      setStorageItem("projectId", "");
      history.push("/project");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onEditProject();
  }, []); //eslint-disable-line

  const onApprove = () => {
    dispatch({ type: ProjectTypes.DELETE_PROJECT, payload: project.id });
    onClose();
  };

  return (
    <div className={styles.wrap}>
      <CheckIcon className={styles.icon} />
      <p className={styles.text}>
        {__(`Вы больше не являетесь администратором проекта “${project.name}” `)}
        <br /> {__(`Права доступа переданны "${params[0].name} ${params[0]?.name ? params[0].sname : ""}”`)}
      </p>

      <Button type="button" variant="ok" onClick={onApprove} text={__("Готово")} />
    </div>
  );
};

export default AddedAdmin;
AddedAdmin.propTypes = {
  params: projectUserProps,
  onClose: PropTypes.func
};
