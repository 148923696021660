export enum TOOLBAR_BUTTONS {
  BOLD = "bold",
  ITALIC = "italic",
  UNDERLINE = "underline",
  STRIKETHROUGHT = "strikethrough",
  BULLETED_LIST = "bulletedList",
  NUMBERED_LIST = "numberedList",
  ALIGN_LEFT = "alignment left",
  ALIGN_CENTER = "alignment center",
  ALIGN_RIGHT = "alignment right",
  ALIGN_JUSTIFY = "alignment justify",
  OUTDENT_LIST = "outdentList"
}

export interface IPressedButton {
  change: boolean;
  type: string;
}

export interface ICustomEditorToolbarProps {
  onClick: (el: TOOLBAR_BUTTONS) => void;
  pushoutButton: IPressedButton;
  viewType?: string;
  currentEditor?: string;
}
