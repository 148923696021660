import api from "api";
import { ReactComponent as RestoreIcon } from "assets/icons/restore.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import DeadlineTimer from "generalComponents/DeadlineTimer/DeadlineTimer";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { ROUTES } from "generalComponents/variables/routing";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { ISafe } from "models/store/safe/safe";
import { ChangeEvent, FC, FormEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import AlertModal from "../../AlertModal/AlertModal";
import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./EnterSafeCode.module.sass";

interface IEnterSafeCodeProps {
  onClose: () => void;
  safe: ISafe;
  callbackType?: SAFE_CALLBACK_MODAL;
}

let currentActiveCode: number = 0;

const EnterSafeCode: FC<IEnterSafeCodeProps> = ({ onClose, safe }): JSX.Element => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { uid } = useUserSelectors();

  const { onAddAuthorized, onSetSafeModal, onSetLoaderModal, onSetTopMessageModal } = useActions();
  const [code, setCode] = useState<string[]>(new Array(4).fill(""));
  const [activeCode, setActiveCode] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [time, setTime] = useState<number>(Date.now() + 60000);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeCode]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    const val = value.substring(value.length - 1);
    if (error) setError(false);
    if (!val) setActiveCode(currentActiveCode - 1);
    else setActiveCode(currentActiveCode + 1);
    setCode((prev) => {
      const newCode: string[] = [...prev];
      newCode[currentActiveCode] = val;
      return newCode;
    });
  };

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>, idx: number): void => {
    currentActiveCode = idx;
    if (key === "Backspase") setActiveCode(currentActiveCode - 1);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    try {
      const params = {
        uid,
        id_safe: safe.id,
        code: code.join("")
      };
      const { data } = await api.get("/ajax/safe_code_check.php?", { params });
      if (data.ok) {
        onAddAuthorized({
          id_safe: safe.id,
          code: code.join(""),
          pass: safe.pass,
          deadlineCode: data.deadline_ms
        });
        navigate(`/${ROUTES.SAFE}/${safe.id}${search}`);
        onClose();
      } else {
        if (+data.pass_error >= 10) {
          onSetSafeModal({ open: true, type: SAFE_MODALS.SAFE_BLOKED, safe });
        }
        setCode(new Array(4).fill(""));
        setError(__("Неверный код"));
      }
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR
      });
    }
  };

  const handleCountdownComplete = (value: boolean) => {
    if (value) setDisabled(false);
  };

  const onRepeatCode = async () => {
    setDisabled(true);
    setTime(Date.now() + 60000);
    try {
      const params = {
        uid,
        id_safe: safe.id,
        pass: safe.pass,
        verify_only: 0
      };
      const { data } = await api.get("/ajax/safe_get_access.php?", { params });
      checkResponseStatus(data.ok);
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR
      });
    } finally {
      onSetLoaderModal(false);
    }
  };

  return (
    <div className={styles.wrap}>
      <HeaderModal title={__("Доступ Ограничен")} onClose={onClose} />
      <AlertModal text={__("Мы отправил вам смс с кодом подтверждения, введите его ")} variant="green" />
      <div className={styles.body}>
        <div className={styles.restoreBox}>
          <Button
            type="button"
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.EXTRA_SMALL}
            iconL={<RestoreIcon />}
            text={__("Выслать код повторно")}
            onClick={onRepeatCode}
            disabled={disabled}
          />
          {disabled && <DeadlineTimer deadline={time} onComplete={() => handleCountdownComplete(true)} />}
        </div>
        <form onSubmit={onSubmit}>
          <div className={styles.inputs}>
            {code.map((_, idx) => (
              <input
                ref={idx === activeCode ? inputRef : null}
                type="number"
                key={idx}
                onChange={handleChange}
                onKeyDown={(e) => handleKeyDown(e, idx)}
                value={code[idx]}
                className={classNames(styles.input, { [styles.error]: error })}
              />
            ))}
          </div>
          {error && <span className={styles.errorText}>{__("Неверный код")}</span>}
          <div className={styles.btns}>
            <Button
              onClick={onClose}
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.LARGE}
              text={__("Отменить")}
              type="button"
            />
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.LARGE}
              text={__("Открыть доступ ")}
              type="submit"
              disabled={code.some((el) => !el) || error}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnterSafeCode;
