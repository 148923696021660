import { useGoogleLogin } from "@react-oauth/google";
import api from "api";
import { ReactComponent as GoogleIcon } from "assets/icons/sign_in_google.svg";
import Button from "containers/Authentication/Button/Button";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useActions } from "hooks/useActions";
import { STATUS_COLOR, USER_STATUS } from "models/store/user/user";
import { Dispatch, SetStateAction } from "react";

import styles from "./Buttons.module.sass";

const GoogleButton = ({ setError }: { setError: Dispatch<SetStateAction<string>> }): JSX.Element => {
  const { onLog } = useActions();

  const onSuccessGoogleLogin = async (response: any) => {
    const accessToken = response.access_token;
    try {
      const userInfoResponse = await fetch("https://www.googleapis.com/oauth2/v2/userinfo", {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (userInfoResponse.ok) {
        const userInfo = await userInfoResponse.json();
        const params = {
          google: userInfo
        };
        const { data } = await api.post("/ajax/user_reg.php", null, { params });
        checkResponseStatus(data.google_ok);

        if (data.error) {
          if (data.error === "user exists") setError("Такой пользователь уже существует");
        } else {
          const params = {
            uid: data.uid,
            status_color: STATUS_COLOR.ONLINE,
            status: USER_STATUS.ONLINE
          };
          const res = await api.get(`/ajax/user_edit2.php`, { params });
          checkResponseStatus(res.data.ok);
          onLog(data.uid);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: onSuccessGoogleLogin
  });

  return (
    <Button
      onClick={() => {
        setError("");
        googleLogin();
      }}
    >
      <GoogleIcon className={styles.icon} />
      Google
    </Button>
  );
};

export default GoogleButton;
