import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { ERROR_SERVER, EVENT_TYPE, ViewType } from "generalComponents/variables/global";
import { ITopMessages } from "models/common/common";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import { initialCreateFolderState } from "models/store/Cabinet/modals/createFolder";
import { ModalActions } from "models/store/Cabinet/modals/modalActions";
import { initialCreateZipModalState, initialShareState, ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { initialMoveElementsModalState } from "models/store/Cabinet/modals/moveElements";
import { initialPickedItems } from "models/store/files/files";
import { FilesActions } from "models/store/files/filesActions";
import {
  FoldersAction,
  IFetchFolders,
  INullifyFolders,
  IOpenFolder,
  ISelectFolder,
  ISendFolderPath
} from "models/store/folders/foldersActions";
import {
  ICreateFolderPayload,
  ICreateZipFolderPayload,
  IEditFolderPayload,
  IHandleFolderPayload,
  IShareFoldersPayload
} from "models/store/folders/foldersPayloads";
import { IFolder } from "models/store/folders/foldersStore";
import { JoinProjectActions } from "models/store/joinProjects/joinProjectsActions";
import { IAuthorized } from "models/store/safe/safe";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { FoldersTypes } from "Store/types/foldersTypes";
import { getPageDep } from "utils/getPageDep";

import {
  onSetAddFiles,
  onSetAddNextFiles,
  onSetPickedItems,
  onSetUpdateFiles,
  onSetUpdateNextFiles
} from "./FilesActions";
import { onSetAddProjectDocFiles, onSetUpdateProjectDocDefaultFolders } from "./JoinProjectActions";
import {
  onDownloadLink,
  onSetCreateFolderModal,
  onSetCreateZipModal,
  onSetLoaderModal,
  onSetMoveElementsModal,
  onSetShareModal,
  onSetTopMessageModal
} from "./ModalActions";

export const onGetMyFolders =
  (controller?: AbortController): ThunkAction<void, RootState, unknown, FoldersAction | ModalActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        show_all: 1
      };

      const { data } = await api.get("/ajax/get_folders.php", { params, signal: controller?.signal });
      dispatch(onSetFolders(data.folders.global, data.folders.other));
    } catch {
      if (!controller?.signal.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    }
  };

export const onCreateFolder =
  (
    payload: ICreateFolderPayload
  ): ThunkAction<void, RootState, unknown, FilesActions | FoldersAction | CabinetActions> =>
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(onSetLoaderModal(true));

      const params = {
        ...payload,
        uid: getState().user.uid,
        tags: JSON.stringify(payload.tags)
      };
      const { data } = await api.get("/ajax/dir_add.php", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetFolders(data.folders.global, data.folders.other));
      dispatch(onSetCreateFolderModal(initialCreateFolderState()));
      if (getState().Files.view === ViewType.LINES_PREVIEW) {
        dispatch(onSetAddNextFiles(data.file_info));
      } else {
        if (payload.id_parent === getState().Folders.currentFolder.id_dir) {
          dispatch(onSetAddFiles(data.file_info));
        }
      }

      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.ADD }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onEditFolders =
  (
    payload: IEditFolderPayload,
    typeMessage: EVENT_TYPE,
    callback?: (folder?: IFolder) => void
  ): ThunkAction<void, RootState, unknown, FoldersAction | FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        ...payload
      };
      if (!payload?.is_favorite && payload?.is_favorite !== 0) {
        dispatch(onSetLoaderModal(true));
      }
      const { data } = await api.get(`/ajax/dir_edit.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetFolders(data.folders.global, data.folders.other));
      if (callback) callback(data.file_info);
      if (getState().Files.pickedItems.open) dispatch(onSetPickedItems(initialPickedItems()));
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: typeMessage
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      if (!payload?.is_favorite && payload?.is_favorite !== 0) {
        dispatch(onSetLoaderModal(false));
      }
    }
  };

export const onEditFoldersLocal =
  (payload: IEditFolderPayload): ThunkAction<void, RootState, unknown, FoldersAction | FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        ...payload
      };
      const { data } = await api.get(`/ajax/dir_edit.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetFolders(data.folders.global, data.folders.other));

      if (getState().Files.view === ViewType.LINES_PREVIEW) {
        dispatch(onSetUpdateNextFiles([data.file_info]));
      } else {
        dispatch(onSetUpdateFiles([data.file_info]));
      }

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.EDIT
        })
      );
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      // finaly
    }
  };

export const onCopyFolders =
  (
    payload: IHandleFolderPayload
  ): ThunkAction<void, RootState, unknown, FoldersAction | FilesActions | JoinProjectActions | CabinetActions> =>
  async (dispatch, getState): Promise<void> => {
    try {
      const { _FILES_, _PROJECT_DOC_ } = getPageDep(payload?.dep);

      const currentFolderState = getState().Folders.currentFolder.id_dir;

      dispatch(onSetLoaderModal(true));

      const params = {
        uid: getState().user.uid,
        id_project: getState()?.JoinProjects?.project?.id,
        ...payload
      };

      const { data } = await api.get(`/ajax/dir_copy.php?`, { params });

      if (_FILES_) {
        dispatch(onSetFolders(data.folders.global, data.folders.other));

        if (getState().Files.view === ViewType.LINES_PREVIEW) {
          dispatch(onSetAddNextFiles(data?.file_info));
        } else {
          if (payload?.id_parent === currentFolderState || data?.file_info?.id_parent === currentFolderState) {
            dispatch(onSetAddFiles(data?.file_info));
          }
        }
      }

      if (_PROJECT_DOC_) {
        dispatch(onSetUpdateProjectDocDefaultFolders(data.folders_doc));
        dispatch(onSetAddProjectDocFiles(data?.file_info));
      }

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.COPY
        })
      );
      dispatch(onSetMoveElementsModal(initialMoveElementsModalState()));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const fetchFolderZipLink =
  (
    id: string,
    safe?: IAuthorized,
    did?: string,
    message?: string
  ): ThunkAction<void, RootState, unknown, FoldersAction | CabinetActions> =>
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: did ? undefined : getState().user.uid,
        id_dir: id,
        id_safe: safe?.id_safe ? safe.id_safe : undefined,
        code: safe?.code ? safe.code : undefined,
        pass: safe?.pass ? safe.pass : undefined,
        did
      };
      const { data } = await api.get(`/ajax/${safe ? "safe_" : ""}dir_download.php?`, { params });
      if (data.error === ERROR_SERVER.NO_FILES) {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message }));
        return;
      }
      dispatch(onDownloadLink(data.zip));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onShareFolders =
  (payload: IShareFoldersPayload): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      users_to: payload.usersTo,
      id_dirs: payload.idDirs
    };
    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get("/ajax/dir_access_add.php", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetPickedItems(initialPickedItems()));
      dispatch(onSetShareModal(initialShareState()));
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.SHARED
        })
      );
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onSetNewFolderPass =
  (
    payload: { id_dir: string; pass: string; code: string },
    messages: ITopMessages,
    setSuccess?: (folder?: IFolder) => void
  ): ThunkAction<void, RootState, unknown, FoldersAction | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      ...payload
    };
    try {
      const { data } = await api.get("/ajax/dir_pass_set.php", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: messages.success }));
      setSuccess && setSuccess(data?.file_info);
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onCreateZipFolder =
  (
    payload: ICreateZipFolderPayload,
    setError: (el: boolean) => void
  ): ThunkAction<void, RootState, unknown, FoldersAction | FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_dir: payload.idDir,
      id_dirs: payload.idDirs,
      fids: payload.fids,
      zip_name: payload.zipName,
      zip_pass: payload.zipPass,
      tags: JSON.stringify(payload.tags),
      color: payload.color,
      emo: payload.emo
    };
    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get("/ajax/dir_zip.php", { params });
      dispatch(onSetPickedItems(initialPickedItems()));
      if (data.files.length === 0) {
        setError(true);
      } else {
        dispatch(onSetCreateZipModal(initialCreateZipModalState()));

        if (getState().Files.view === ViewType.LINES_PREVIEW) {
          dispatch(onSetAddNextFiles(data?.file_info));
        } else {
          dispatch(onSetAddFiles(data?.file_info));
        }

        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.ZIP }));
      }
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onSetFolders = (systemFolders: IFolder[], otherFolders: IFolder[]): IFetchFolders => ({
  type: FoldersTypes.SET_FOLDERS,
  payload: { systemFolders, otherFolders }
});

export const onSendOpenFolder = (path: string[]): IOpenFolder => ({
  type: FoldersTypes.OPEN_FOLDER,
  payload: path
});

export const onSelectFolder = (folder: IFolder): ISelectFolder => ({
  type: FoldersTypes.SELECT_FOLDER,
  payload: folder
});

export const onSendFolderPath = (path: IFolder[]): ISendFolderPath => ({
  type: FoldersTypes.FOLDER_PATH,
  payload: path
});

export const onNullifyFolders = (): INullifyFolders => ({
  type: FoldersTypes.NULLIFY_FOLDERS
});
