import { IMemoryTools } from "collections/profile";
import { FC } from "react";
import { v4 as uuid } from "uuid";

import { MemoryTool } from "./components/MemoryTool/MemoryTool";

interface IListMemoryToolsProps {
  tools: IMemoryTools[];
}

export const ListMemoryTools: FC<IListMemoryToolsProps> = ({ tools }): JSX.Element => (
  <ul>
    {tools.map((tool: IMemoryTools) => (
      <MemoryTool key={uuid()} tool={tool} />
    ))}
  </ul>
);
