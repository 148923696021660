import { PROJECT_PATH } from "generalComponents/variables/routing";
import { IFile } from "models/store/files/files";
import { initialUploadFiles } from "models/store/files/uploadFilesModals/uploadFiles";
import { IFolder } from "models/store/folders/foldersStore";
import {
  IJoinProjectsState,
  initialJoinProjectState,
  IProjectTask,
  IProjectTeam
} from "models/store/joinProjects/joinProgects";
import { JoinProjectActions } from "models/store/joinProjects/joinProjectsActions";
import { FoldersNextArrayType, FoldersNextObjectType } from "models/store/library/library";
import { initialUploadLibraryFiles } from "models/store/library/uploadFilesModals/uploadLibraryFiles";
import { TypeElements } from "models/typeElements/typeElements";
import { JoinProjectsTypes } from "Store/types/joinProjectsTypes";
import { filtersLibraryFolder } from "Store/utils/filtersLibraryFolder";
import { getLocationSearchParams } from "utils/getLocationSearchParams";
import { getObjDate } from "utils/getObjDate";

const INITIAL_STATE = initialJoinProjectState();
export const JoinProjectsReducer = (state = INITIAL_STATE, action: JoinProjectActions): IJoinProjectsState => {
  switch (action.type) {
    case JoinProjectsTypes.SET_PROJECT_LOADER:
      return { ...state, loader: action.payload };

    case JoinProjectsTypes.SET_PROJECT_LIST:
      return { ...state, projectList: action.payload };

    case JoinProjectsTypes.ADD_PROJECT:
      return { ...state, projectList: [...state.projectList, action.payload] };

    case JoinProjectsTypes.EDIT_PROJECT: {
      const { id, name, icon, date_start, date_end, work_days, work_hours } = action.payload;
      const projectList = state.projectList.map((el) => (el.id === id ? action.payload : el));
      const project = { ...state.project, name, icon, work_days, work_hours, date_start, date_end };
      return { ...state, projectList, project };
    }

    case JoinProjectsTypes.DEL_PROJECT:
      return {
        ...state,
        projectList: state.projectList.filter((project) => project.id !== action.payload),
        project: null
      };

    case JoinProjectsTypes.SET_CURRENT_PROJECT:
      return {
        ...state,
        project: {
          ...action.payload,
          library: {
            folders: null,
            folderInfo: null,
            uploadFiles: initialUploadLibraryFiles()
          },
          trash: null,
          documentation: {
            files: null,
            folderInfo: null
          },
          files: null,
          uploadFiles: initialUploadFiles(),
          tasks: [],
          allTasks: [],
          folders_doc: action.payload?.folders_doc?.filter(({ name }) => name !== "tmp"),
          tmp_copy: action.payload?.folders_doc?.filter(({ name }) => name === "tmp")
        }
      };

    case JoinProjectsTypes.SET_PROJECT_TASKS:
      return { ...state, project: { ...state.project, tasks: action.payload } };

    case JoinProjectsTypes.SET_ALL_PROJECT_TASKS:
      return { ...state, project: { ...state.project, allTasks: [...state.project.allTasks, ...action.payload] } };

    case JoinProjectsTypes.RESET_ALL_PROJECT_TASKS:
      return { ...state, project: { ...state.project, allTasks: [] }, page: 1, total: 0 };

    case JoinProjectsTypes.SET_PROJECT_BACKLOG:
      return { ...state, project: { ...state.project, backlog: action.payload } };

    case JoinProjectsTypes.SET_CURRENT_TASK:
      return { ...state, currentTask: action.payload };

    case JoinProjectsTypes.ADD_PROJECT_TASK: {
      const { searchParams } = new URL(window?.location?.href);
      const idSprint = searchParams.get("sprint");

      if (String(state.project.id) === String(action.payload.id_project)) {
        if (action?.payload?.id_sprints?.length === 0) {
          return { ...state, project: { ...state.project, backlog: [action.payload, ...state.project.backlog] } };
        }

        if (action?.payload?.id_sprints?.includes(idSprint)) {
          return { ...state, project: { ...state.project, tasks: [action.payload, ...state.project.tasks] } };
        }
      }
      return state;
    }

    case JoinProjectsTypes.EDIT_PROJECT_TASK: {
      const { searchParams, pathname } = new URL(window?.location?.href);
      const idSprint = searchParams.get("sprint");
      const isGantPage = pathname.includes(PROJECT_PATH.GANT);

      if (String(state.project.id) === String(action.payload.id_project)) {
        const _task = state.project.tasks.find((t) => t.id === action.payload.id);
        // если текущая страница диаграмма Ганта
        if (isGantPage) {
          // задача находится в сторе и не перемещена в беклог
          if (_task && action.payload.id_sprints.length > 0) {
            const tasks = state.project.tasks.map((item) => (item.id === action.payload.id ? action.payload : item));
            return { ...state, project: { ...state.project, tasks } };
          }
          // задача находится в сторе и перемещена в беклог
          if (_task && action.payload.id_sprints.length === 0) {
            const tasks = state.project.tasks.filter((item) => item.id !== action.payload.id);
            const backlog = [action.payload, ...state.project.backlog];
            return { ...state, project: { ...state.project, tasks, backlog } };
          }
        }

        // если есть задача в сторе и не относится к беклогу
        if (_task && action.payload.id_sprints.length > 0) {
          // проверяем относится ли задача к текущему спринту. Если да то изменяем ее иначе удаляем ее из спринта
          if (action.payload.id_sprints.includes(idSprint)) {
            const tasks = state.project.tasks.map((item) => (item.id === action.payload.id ? action.payload : item));
            return { ...state, project: { ...state.project, tasks } };
          } else {
            const tasks = state.project.tasks.filter((item) => item.id !== action.payload.id);
            return { ...state, project: { ...state.project, tasks } };
          }
        }

        // если нет задачи в сторе и не относится к беклогу но у нее есть текущий спринт то добавляем ее
        if (!_task && action.payload.id_sprints.length > 0) {
          if (action.payload.id_sprints.includes(idSprint)) {
            const tasks = [...state.project.tasks, action.payload];
            return { ...state, project: { ...state.project, tasks } };
          }
        }

        // если есть задача и относится к белогу
        if (_task && action.payload.id_sprints.length === 0) {
          const tasks = state.project.tasks.filter((item) => item.id !== action.payload.id);
          const backlog = [action.payload, ...state.project.backlog];
          return { ...state, project: { ...state.project, tasks, backlog } };
        }

        const _backlogTask = state.project.backlog.find((t) => t.id === action.payload.id);

        // если задача в беклоге и относится к беклогу
        if (_backlogTask && action.payload.id_sprints.length === 0) {
          const backlog = state.project.backlog.map((item) => (item.id === action.payload.id ? action.payload : item));
          return { ...state, project: { ...state.project, backlog } };
        }
        // если задачу нужно перенести из беклога и она относится к текущему спринту
        if (_backlogTask && action.payload.id_sprints.length > 0 && action.payload.id_sprints.includes(idSprint)) {
          const tasks = [action.payload, ...state.project.tasks];
          const backlog = state.project.backlog.filter((item) => item.id !== action.payload.id);
          return { ...state, project: { ...state.project, tasks, backlog } };
        }

        if (action.payload.id_sprints.length === 0) {
          const backlog = state.project.backlog.map((item) => (item.id === action.payload.id ? action.payload : item));
          return { ...state, project: { ...state.project, backlog } };
        }
        if (action.payload.id_sprints.includes(idSprint)) {
          const tasks = state.project.tasks.map((item) => (item.id === action.payload.id ? action.payload : item));
          return { ...state, project: { ...state.project, tasks } };
        }
      }
      return state;
    }

    case JoinProjectsTypes.DELETE_PROJECT_TASK: {
      const { searchParams } = new URL(window?.location?.href);
      const idSprint = searchParams.get("sprint");
      if (state.project.id === action.payload.id_project) {
        if (action.payload.id_sprints.length === 0) {
          const backlog = state.project.backlog.filter((item) => item.id !== action.payload.id);
          return { ...state, project: { ...state.project, backlog } };
        }
        if (action.payload.id_sprints.includes(idSprint)) {
          const tasks = state.project.tasks.filter((item) => item.id !== action.payload.id);
          return { ...state, project: { ...state.project, tasks } };
        }
      }
      return state;
    }

    case JoinProjectsTypes.MOVE_TASK_TO_BACKLOG: {
      if (state.project.id === action.payload.id_project) {
        const backlog = [action.payload, ...state.project.backlog];
        const tasks = state.project.tasks.filter((item) => item.id !== action.payload.id);
        return { ...state, project: { ...state.project, tasks, backlog } };
      }
      return state;
    }

    case JoinProjectsTypes.MOVE_TASK_FROM_BACKLOG: {
      const { searchParams } = new URL(window?.location?.href);
      const idSprint = searchParams.get("sprint");
      if (state.project.id === action.payload.tasks[0]?.id_project) {
        const tasks = idSprint === action.payload.idSprint ? action.payload.tasks : state.project.tasks;
        const backlog = state.project.backlog.filter((item) => item.id !== action.payload.idTask);
        return { ...state, project: { ...state.project, tasks, backlog } };
      }
      return state;
    }

    case JoinProjectsTypes.MOVED_TASKS: {
      const { searchParams } = new URL(window?.location?.href);
      const idSprint = searchParams.get("sprint");
      if (state.project.id === action.payload.tasks[0]?.id_project) {
        const tasks = idSprint === action.payload.idSprint ? action.payload.tasks : state.project.tasks;
        return { ...state, project: { ...state.project, tasks } };
      }
      return state;
    }

    case JoinProjectsTypes.ADD_ROLE:
      return { ...state, project: { ...state.project, roles: [...state.project.roles, action.payload] } };

    case JoinProjectsTypes.EDIT_ROLE: {
      const roles = state.project.roles.map((el) => (el.id === action.payload.id ? action.payload : el));
      return { ...state, project: { ...state.project, roles } };
    }

    case JoinProjectsTypes.EDIT_STATUSES: {
      return { ...state, project: { ...state.project, statuses: [...action.payload] } };
    }

    case JoinProjectsTypes.ADD_TEAM: {
      const { team, participants } = action.payload;

      return { ...state, project: { ...state.project, teams: [team, ...state.project.teams], participants } };
    }

    case JoinProjectsTypes.UPDATE_PROJECT_TEAMS: {
      const { teams, participants } = action.payload;

      return { ...state, project: { ...state.project, teams, participants } };
    }

    case JoinProjectsTypes.EDIT_TEAM: {
      const { teams, participants } = action.payload;
      return { ...state, project: { ...state.project, teams, participants } };
    }

    case JoinProjectsTypes.UPDATE_SPRINTS_PROJECT: {
      return { ...state, project: { ...state.project, sprints: action.payload } };
    }

    case JoinProjectsTypes.SET_PROJECT_LINKS: {
      return { ...state, project: { ...state.project, links: action.payload } };
    }

    case JoinProjectsTypes.SET_PROJECT_LINKS_CATEGORY: {
      return { ...state, project: { ...state.project, linksCategories: action.payload } };
    }

    case JoinProjectsTypes.ADD_PROJECT_LINKS: {
      return { ...state, project: { ...state.project, links: [...state.project.links, action.payload] } };
    }

    case JoinProjectsTypes.EDIT_PROJECT_LINKS: {
      const links = state.project.links.map((el) => (el.id === action.payload.id ? action.payload : el));
      return { ...state, project: { ...state.project, links } };
    }

    case JoinProjectsTypes.DEL_PROJECT_LINKS: {
      const links = state.project.links.filter((el) => el.id !== action.payload);
      return { ...state, project: { ...state.project, links } };
    }

    case JoinProjectsTypes.ADD_PROJECT_LINKS_CATEGORY: {
      return {
        ...state,
        project: { ...state.project, linksCategories: [...state.project.linksCategories, action.payload] }
      };
    }

    case JoinProjectsTypes.EDIT_PROJECT_LINKS_CATEGORY: {
      const linksCategories = state.project.linksCategories.map((el) =>
        el.id === action.payload.id ? action.payload : el
      );
      return { ...state, project: { ...state.project, linksCategories } };
    }

    case JoinProjectsTypes.DEL_PROJECT_LINKS_CATEGORY: {
      const linksCategories = state.project.linksCategories.filter((el) => el.id !== action.payload);
      return { ...state, project: { ...state.project, linksCategories } };
    }

    case JoinProjectsTypes.SET_PROJECT_LIBRARY_FOLDERS:
      if (state?.project?.library?.folders) {
        return {
          ...state,
          project: {
            ...state.project,
            library: {
              ...state.project.library,
              folders: Array.isArray(action.payload)
                ? [...(state.project.library.folders as FoldersNextArrayType), ...action.payload]
                : Object.keys(action.payload).reduce((acc: any, el: string) => {
                    return {
                      ...acc,
                      [el]: (state.project.library.folders as FoldersNextObjectType)[el]
                        ? [
                            ...(state.project.library.folders as FoldersNextObjectType)[el],
                            ...(action.payload as FoldersNextObjectType)[el]
                          ]
                        : (action.payload as FoldersNextObjectType)[el]
                    };
                  }, {})
            }
          }
        };
      } else {
        return {
          ...state,
          project: {
            ...state.project,
            library: {
              ...state.project.library,
              folders: action.payload
            }
          }
        };
      }

    case JoinProjectsTypes.SET_PROJECT_ADD_LIBRARY_FOLDER: {
      const { chapter } = getLocationSearchParams();

      const { y, m } = getObjDate(action.payload.cdate);

      const key = chapter ? `${y}.${m}` : action.payload.chapter;

      if (!Array.isArray(state?.project?.library?.folders)) {
        const updateFolders = state?.project?.library?.folders?.length
          ? Object.entries(state.project.library.folders || {}).reduce(
              (acc, [k, item]) => {
                if (k === key) {
                  const updateKey = [action.payload, ...item] as IFolder[];
                  const filterArr = filtersLibraryFolder(updateKey);

                  return {
                    ...acc,
                    [key]: filterArr
                  };
                }

                if (Object.keys(state.project.library.folders).every((v) => key !== v)) {
                  const updateKey = [action.payload] as IFolder[];
                  const filterArr = filtersLibraryFolder(updateKey);

                  return {
                    ...acc,
                    [key]: filterArr
                  };
                }

                return {
                  ...acc
                };
              },
              { ...state.project.library.folders }
            )
          : {
              [key]: filtersLibraryFolder([action.payload])
            };

        return {
          ...state,
          project: {
            ...state.project,
            library: {
              ...state.project.library,
              folders: updateFolders
            }
          },
          total: ++state.total
        };
      } else {
        return state;
      }
    }

    case JoinProjectsTypes.SET_PROJECT_UPDATE_LIBRARY_FOLDER: {
      const { chapter } = getLocationSearchParams();

      const { y, m } = getObjDate(action.payload.cdate);

      const key = chapter ? `${y}.${m}` : action.payload.chapter;

      if (!Array.isArray(state?.project?.library?.folders)) {
        const updateFolders = Object.entries(state.project.library.folders).reduce(
          (acc, [k, item]) => {
            // перемещаем папку в новый раздел, если он пустой

            if (Object.keys(state.project.library.folders).every((v) => key !== v)) {
              const updateKey = [action.payload] as IFolder[];

              const filterArr = filtersLibraryFolder(updateKey);

              if (filterArr.length) {
                acc[key] = filterArr;
              } else {
                delete acc[key];
              }
            }

            // удаляем папку, если папку переместили в другой раздел

            if (
              item.some(
                (folder) => folder.chapter !== action.payload.chapter && folder.id_dir === action.payload.id_dir
              )
            ) {
              const updateKey = item.filter((folder) => folder.id_dir !== action.payload.id_dir) as IFolder[];

              const filterArr = filtersLibraryFolder(updateKey);

              if (filterArr.length) {
                acc[k] = filterArr;
              } else {
                delete acc[k];
              }
            }

            // перемещаем папку в новый раздел, если он не пустой

            if (
              item.every(
                (folder) => folder.chapter === action.payload.chapter && folder.id_dir !== action.payload.id_dir
              )
            ) {
              const updateKey = [action.payload, ...item] as IFolder[];

              const filterArr = filtersLibraryFolder(updateKey);

              if (filterArr.length) {
                acc[k] = filterArr;
              } else {
                delete acc[k];
              }
            }

            // обновляем папку

            if (k === key && item.some(({ id_dir }) => id_dir === action.payload.id_dir)) {
              const updateKey = item?.map((folder) =>
                folder.id_dir === action.payload.id_dir ? { ...action.payload } : { ...folder }
              ) as IFolder[];

              const filterArr = filtersLibraryFolder(updateKey);

              if (filterArr.length) {
                acc[key] = filterArr;
              } else {
                delete acc[key];
              }
            }

            return acc;
          },
          { ...state.project.library.folders }
        );

        const isFilterEmpty = chapter && Object.keys(updateFolders).every((k) => k !== key);

        return {
          ...state,
          project: {
            ...state.project,
            library: {
              ...state.project.library,
              folders: isFilterEmpty ? {} : updateFolders
            }
          },
          total: isFilterEmpty || !Object.values(updateFolders).length ? 0 : state.total
        };
      } else {
        return state;
      }
    }

    case JoinProjectsTypes.SET_PROJECT_DEL_LIBRARY_FOLDER: {
      const { chapter } = getLocationSearchParams();

      const { y, m } = getObjDate(action.payload.cdate);

      const key = chapter ? `${y}.${m}` : action.payload.chapter;

      if (!Array.isArray(state.project.library.folders)) {
        const updateFolders = Object.entries(state.project.library.folders).reduce(
          (acc, [k, item]) => {
            if (k === key) {
              const updateKey = item.filter((folder) => folder.id_dir !== action.payload.id_dir);

              if (updateKey.length) {
                return {
                  ...acc,
                  [key]: updateKey
                };
              } else {
                delete acc[k];
              }
            }

            return {
              ...acc
            };
          },
          { ...state.project.library.folders }
        );

        return {
          ...state,
          project: {
            ...state.project,
            library: {
              ...state.project.library,
              folders: updateFolders
            }
          },
          total: --state.total
        };
      } else {
        return state;
      }
    }

    case JoinProjectsTypes.SET_PROJECT_DEL_LIBRARY_FILES: {
      const { chapter } = getLocationSearchParams();

      const updateFolders = Object.entries(state.project.library.folders).reduce(
        (acc, [k, item]) => {
          const currentFolder = item.find((folder: IFolder) => folder.id_dir === action.payload[0].id_dir);

          if (currentFolder) {
            const { y, m } = getObjDate(currentFolder.cdate);

            const key = chapter ? `${y}.${m}` : currentFolder.chapter;

            if (k === key) {
              return {
                ...acc,
                [key]: item.map((folder: IFolder) => {
                  if (folder.id_dir === currentFolder.id_dir) {
                    return {
                      ...currentFolder,
                      files: currentFolder.files.filter(
                        (file: IFile) => !action.payload.some(({ fid }) => fid === file.fid)
                      )
                    };
                  }

                  return {
                    ...folder
                  };
                })
              };
            }
          }

          return {
            ...acc
          };
        },
        { ...state.project.library.folders }
      );

      return {
        ...state,
        project: {
          ...state.project,
          library: {
            ...state.project.library,
            folders: updateFolders
          }
        }
      };
    }

    case JoinProjectsTypes.CHANGE_PROJECT_LIBRARY_LOADING_FILES:
      return {
        ...state,
        project: {
          ...state.project,
          library: {
            ...state.project.library,
            uploadFiles: {
              ...state.project.library.uploadFiles,
              loadingFiles: action.payload
            }
          }
        }
      };

    case JoinProjectsTypes.RESET_PROJECT_LIBRARY_LOADING_FILES:
      return {
        ...state,
        project: {
          ...state.project,
          library: {
            ...state.project.library,
            uploadFiles: initialUploadLibraryFiles()
          }
        }
      };

    case JoinProjectsTypes.RESET_PROJECT_LIBRARY_FOLDERS:
      return {
        ...state,
        project: {
          ...state.project,
          library: {
            ...state.project.library,
            folders: null,
            folderInfo: null
          }
        },
        page: 1,
        total: 0
      };

    case JoinProjectsTypes.SET_PROJECT_LIBRARY_FOLDER_INFO:
      return {
        ...state,
        project: {
          ...state.project,
          library: {
            ...state.project.library,
            folderInfo: action.payload
          }
        }
      };

    case JoinProjectsTypes.SET_PROJECT_PAGE:
      return { ...state, page: action.payload };

    case JoinProjectsTypes.SET_PROJECT_TOTAL_ITEMS:
      return { ...state, total: action.payload };

    case JoinProjectsTypes.UPDATE_PROJECT_DOC_DEFAULT_FOLDERS:
      return {
        ...state,
        project: {
          ...state.project,
          folders_doc: action.payload?.filter(({ name }) => name !== "tmp")
        }
      };

    case JoinProjectsTypes.SET_PROJECT_DOC_FILES:
      if (state.project.documentation.files) {
        return {
          ...state,
          project: {
            ...state.project,
            documentation: {
              ...state.project.documentation,
              files: [...state.project.documentation.files, ...action.payload]
            }
          }
        };
      } else {
        return {
          ...state,
          project: {
            ...state.project,
            documentation: {
              ...state.project.documentation,
              files: action.payload
            }
          }
        };
      }

    case JoinProjectsTypes.SET_PROJECT_SELECTED_FILES:
      return {
        ...state,
        project: {
          ...state.project,
          uploadFiles: { ...state.project.uploadFiles, selectedFiles: action.payload }
        }
      };

    case JoinProjectsTypes.RESET_PROJECT_DOC_FILES_LIST:
      return {
        ...state,
        project: {
          ...state.project,
          documentation: {
            ...state.project.documentation,
            files: null
          },
          uploadFiles: initialUploadFiles()
        },
        page: 1,
        total: 0
      };

    case JoinProjectsTypes.SET_PROJECT_LOADING_FILES:
      return {
        ...state,
        project: {
          ...state.project,
          uploadFiles: {
            ...state.project.uploadFiles,
            loadingFiles: [action.payload, ...state.project.uploadFiles.loadingFiles]
          }
        }
      };

    case JoinProjectsTypes.CHANGE_PROJECT_LOADING_FILES:
      return {
        ...state,
        project: {
          ...state.project,
          uploadFiles: {
            ...state.project.uploadFiles,
            loadingFiles: [
              ...state.project.uploadFiles.loadingFiles.map((el) => {
                return el.id === action.payload.id ? { ...el, ...action.payload } : { ...el };
              })
            ]
          }
        }
      };

    case JoinProjectsTypes.CANCEL_PROJECT_FILES_UPLOAD:
      return {
        ...state,
        project: {
          ...state.project,
          uploadFiles: {
            ...state.project.uploadFiles,
            loadingFiles: state.project.uploadFiles.loadingFiles.filter((e) => e.id !== action.payload)
          }
        }
      };

    case JoinProjectsTypes.CLOSE_PROJECT_LOADING_FILES:
      return {
        ...state,
        project: {
          ...state.project,
          uploadFiles: {
            ...state.project.uploadFiles,
            loadingFiles: []
          }
        }
      };

    case JoinProjectsTypes.ADD_PROJECT_DOC_FILES:
      return {
        ...state,
        project: {
          ...state.project,
          documentation: {
            ...state.project.documentation,
            files: [action.payload, ...state.project.documentation.files]
          }
        },
        total: ++state.total
      };

    case JoinProjectsTypes.UPDATE_PROJECT_DOC_FILES:
      return {
        ...state,
        project: {
          ...state.project,
          documentation: {
            ...state.project.documentation,
            files: [
              ...state.project.documentation.files.map((el) => {
                return ((el as IFile)?.fid === (action.payload as IFile)?.fid && !el?.is_dir) ||
                  ((el as IFolder)?.id_dir === (action.payload as IFolder)?.id_dir && el?.is_dir)
                  ? { ...action.payload }
                  : { ...el };
              })
            ]
          }
        }
      };

    case JoinProjectsTypes.SET_TRASH_PROJECT_DOC_FILE: {
      const updateFiles = state.project.documentation.files.filter(
        (e) =>
          ((e as IFile)?.fid !== action.payload.id && !e.is_dir) ||
          ((e as IFolder)?.id_dir !== action.payload.id && e.is_dir)
      );

      return {
        ...state,
        project: {
          ...state.project,
          documentation: {
            ...state.project.documentation,
            files: updateFiles
          }
        },
        total: updateFiles.length
      };
    }

    case JoinProjectsTypes.SET_PROJECT_FILES:
      if (state.project.files) {
        return {
          ...state,
          project: {
            ...state.project,
            files: [...state.project.files, ...action.payload]
          }
        };
      } else {
        return {
          ...state,
          project: {
            ...state.project,
            files: action.payload
          }
        };
      }

    case JoinProjectsTypes.ADD_PROJECT_FILES:
      return {
        ...state,
        project: {
          ...state.project,
          files: [action.payload, ...state.project.files]
        },
        total: ++state.total
      };

    case JoinProjectsTypes.UPDATE_PROJECT_FILES:
      return {
        ...state,
        project: {
          ...state.project,
          files: [
            ...state.project.files.map((el) => {
              return ((el as IFile)?.fid === (action.payload as IFile)?.fid && !el?.is_dir) ||
                ((el as IFolder)?.id_dir === (action.payload as IFolder)?.id_dir && el?.is_dir)
                ? { ...action.payload }
                : { ...el };
            })
          ]
        }
      };

    case JoinProjectsTypes.SET_TRASH_PROJECT_FILE: {
      const updateFiles = state.project.files.filter(
        (e) =>
          ((e as IFile)?.fid !== action.payload.id && !e.is_dir) ||
          ((e as IFolder)?.id_dir !== action.payload.id && e.is_dir)
      );

      return {
        ...state,
        project: {
          ...state.project,
          files: updateFiles
        },
        total: updateFiles.length
      };
    }

    case JoinProjectsTypes.RESET_PROJECT_FILES_LIST:
      return {
        ...state,
        project: {
          ...state.project,
          files: null,
          uploadFiles: initialUploadFiles()
        },
        page: 1,
        total: 0
      };

    case JoinProjectsTypes.UPDATE_PROJECT_FILES_DEFAULT_FOLDERS:
      return {
        ...state,
        project: {
          ...state.project,
          folders_file: action.payload
        }
      };

    case JoinProjectsTypes.SET_PROJECT_TRASH_FILES:
      if (state.project.trash) {
        return {
          ...state,
          project: {
            ...state.project,
            trash: [...state.project.trash, ...action.payload]
          }
        };
      } else {
        return {
          ...state,
          project: {
            ...state.project,
            trash: action.payload
          }
        };
      }

    case JoinProjectsTypes.SET_DEL_PROJECT_TRASH_ELEMENT: {
      const updateFiles = state.project.trash.filter(
        (e) =>
          ((e as IFile)?.fid !== action.payload.id && (e as IFile)?.table === TypeElements.FILES) ||
          ((e as IFolder)?.id_dir !== action.payload.id && (e as IFolder)?.table === TypeElements.FOLDERS) ||
          ((e as IProjectTask)?.id !== action.payload.id && (e as IProjectTask)?.table === TypeElements.TASKS) ||
          ((e as IProjectTeam)?.id !== action.payload.id && (e as IProjectTeam)?.table === TypeElements.TEAMS)
      );

      return {
        ...state,
        project: {
          ...state.project,
          trash: updateFiles
        },
        total: updateFiles.length
      };
    }

    case JoinProjectsTypes.SET_RESET_PROJECT_TRASH_ELEMENTS:
      return {
        ...state,
        project: {
          ...state.project,
          trash: null
        },
        page: 1,
        total: 0
      };

    default:
      return state;
  }
};
