import api from "api";
import { UserOption } from "containers/Search/SearchSidebar/UserSelect/UserSelect";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import PopUp from "generalComponents/PopUp/PopUp";
import Tabs from "generalComponents/Tabs/Tabs";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { initialUserDeleteState } from "models/store/Cabinet/modals/userDelete";
import { IProjectTask, IProjectTeam, STATUSES } from "models/store/joinProjects/joinProgects";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import Select, { components, OptionProps } from "react-select";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./UserDeleteModal.module.sass";

const UserDeleteModal = () => {
  const { __ } = useLocales();
  const { project } = useJoinProjectsSelectors();
  const [users, setUsers] = useState<UserOption[]>([]);
  const [newUserId, setNewUserId] = useState<string>();
  const { onSetUserDeleteModal } = useActions();
  const [isLoading, setIsLoading] = useState(false);
  const [withReplacement, setWithReplacement] = useState<boolean>(true);
  const closeModal = () => {
    onSetUserDeleteModal(initialUserDeleteState());
  };
  const { onEditTeam } = useActions();
  const { uid } = useUserSelectors();
  const {
    createProjectTeam: { team },
    userDelete: { user }
  } = useGlobalModalsSelectors();

  const tabs = [
    { name: __("Назначить замену"), value: "withReplacement", onClick: () => setWithReplacement(true) },
    { name: __("Не назначать замену"), value: "withoutReplacement", onClick: () => setWithReplacement(false) }
  ];

  useEffect(() => {
    if (!project.participants) return;
    const newOptions = project.participants.map((el) => ({
      value: el.id,
      label: el.fname + " " + el.sname,
      name: el.fname,
      fname: el.sname,
      icon: el.icon
    }));
    setUsers(newOptions);
  }, [project.participants]);

  const onUserChange = (newValue: UserOption) => {
    setNewUserId(newValue.value);
  };

  const Option = (props: OptionProps<UserOption>) => {
    const { name, fname, icon, label } = props.data;
    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <components.Option {...props}>
        <div className={styles.option}>
          <Avatar name={`${name} ${fname}`} size={24} imgSrc={`${projectSrc}${icon}`} />
          {label}
        </div>
      </components.Option>
    );
  };

  const onUserDelete = async (fromAllproject = false) => {
    // Установка флага загрузки в true перед началом выполнения операций
    setIsLoading(true);
    try {
      // Шаг 1: Получение всех задач пользователя
      const response = await api.get(`/ajax/task_get.php`, {
        params: { uid, search: "", id_executor: user.id, id_project: project.id }
      });

      // Извлечение всех задач пользователя из ответа
      const allUserTasks = response.data.tasks as IProjectTask[];

      // Находим статус, который не является завершенным
      const overdueStatus = project.statuses.find((status) => status.system_name !== STATUSES.DONE);

      // Фильтрация задач пользователя, исключая те, которые завершены
      const filteredTasks = allUserTasks.filter(
        (task) => task.id_status !== overdueStatus.id && task.id_team === team.id
      );

      // Получаем идентификаторы задач для редактирования
      const taskIds = filteredTasks.map((task) => task.id);

      // Шаг 2: Редактирование задач для переназначения пользователя
      if (taskIds) {
        await api.get(`/ajax/task_edit.php`, {
          params: {
            uid,
            id_task: taskIds,
            id_executor: newUserId && withReplacement ? newUserId : 0
          }
        });
      }

      // Определение команды или команд для редактирования
      const teams = fromAllproject ? project.teams : [team];

      // Формирование параметров для редактирования команды
      const editTeamParams = (currentTeam: IProjectTeam, usersArray: { id_user: string; id_role: string }[]) => {
        return {
          data: {
            id_project: project.id,
            id_item: currentTeam.id,
            users: JSON.stringify(usersArray)
          },
          message: withReplacement ? __("Пользователь заменен") : __("Пользователь удален")
        };
      };

      // Шаг 3: Определение пользователей для редактирования команды
      const editTeamRequests = teams.map(async (currentTeam) => {
        // Проверка, содержит ли текущая команда удаляемого пользователя
        const isUserInCurrentTeam = currentTeam.users.includes(user.id);
        // Проверка, содержит ли текущая команда нового пользователя (если выбрана опция с заменой)
        const isNewUserInCurrentTeam = !currentTeam.users.includes(newUserId);
        if (isUserInCurrentTeam) {
          try {
            const currentUsersArray = currentTeam.users
              .map((userId) => {
                const currentUserIndex = project.participants.findIndex((user) => user.id === userId);
                // Если пользователь найден и его id не совпадает с id текущего пользователя, добавляем его в массив
                if (currentUserIndex !== -1 && project.participants[currentUserIndex].id !== user.id) {
                  return {
                    id_user: project.participants[currentUserIndex].id,
                    id_role: project.participants[currentUserIndex].idRole
                  };
                }
                // Если выбрана опция с заменой и новый пользователь не является текущим пользователем, добавляем его в массив
                if (withReplacement && newUserId && newUserId !== user.id && isNewUserInCurrentTeam) {
                  return { id_user: newUserId, id_role: user.idRole }; // Здесь необходимо определить id роли для нового пользователя
                }
                return null;
              })
              .filter((user) => user !== null);

            // Шаг 4: Вызов onEditTeam с новыми параметрами команды
            onEditTeam(editTeamParams(currentTeam, currentUsersArray));
          } catch (error) {
            console.error("An error occurred while editing team:", error);
          }
        }
      });

      // Ожидание выполнения всех запросов на редактирование команд
      await Promise.all(editTeamRequests);
    } catch (error) {
      // Обработка ошибки
      console.error("An error occurred:", error);
    } finally {
      // Установка флага загрузки в false после выполнения операций
      setIsLoading(false);
      // Закрытие модального окна
      closeModal();
    }
  };

  return (
    <PopUp set={closeModal}>
      <HeaderModal title={__("Удалить участника")} onClose={closeModal} />
      <div className={styles.content}>
        {isLoading && (
          <Loader
            position="absolute"
            type={LoaderTypes.BOUNCING_DOTS}
            background="rgba(0, 0, 0, 0.35)"
            containerType="bounceDots"
          />
        )}
        <div className={styles.title}>
          {__("Вы действительно хотите удалить ")} <b>{user.sname + " " + user.fname}</b>
          <span>{__("Это действие необратимо")}</span>
        </div>
        <Tabs
          tabs={tabs}
          value={withReplacement ? "withReplacement" : "withoutReplacement"}
          size="medium"
          variant="darkGreen"
        />
        <div className={styles.select}>
          <span>
            {__("Выберите пользователя для замены, все задачи и привелегии будут переназначены на этого участника")}
          </span>
          <Select
            isDisabled={!withReplacement}
            closeMenuOnSelect={true}
            options={users}
            components={{ Option }}
            maxMenuHeight={250}
            placeholder={__("Выберите")}
            onChange={onUserChange as any}
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                width: "100%"
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "8px",
                minHeight: "32px"
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                zIndex: 10,
                fontSize: "14px"
              }),
              dropdownIndicator: (baseStyles) => ({
                ...baseStyles,
                display: "none"
              }),
              indicatorsContainer: (baseStyles) => ({
                ...baseStyles,
                display: "none"
              }),
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                maxHeight: "68px",
                overflow: "auto"
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                fontSize: "14px"
              })
            }}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={() => onUserDelete(true)}
            disabled={withReplacement && !newUserId}
            variant={ButtonVariantType.RED}
            size={ButtonSizeType.SMALL}
            text={"Удалить с проекта"}
          />
          <div className={styles.rightButtons}>
            <Button
              variant={ButtonVariantType.LIGHT}
              size={ButtonSizeType.SMALL}
              text={"Отменить"}
              onClick={closeModal}
            />
            <Button
              disabled={withReplacement && !newUserId}
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={"Удалить"}
              onClick={() => onUserDelete()}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default UserDeleteModal;
