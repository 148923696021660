import { ReactComponent as InfoIcon } from "assets/PrivateCabinet/info.svg";
import PropTypes from "prop-types";
import React from "react";

import styles from "./InfoBlock.module.sass";

const InfoBlock = ({ title, text }) => {
  return (
    <div className={styles.infoBlock}>
      <InfoIcon className={styles.infoIcon} />
      <p className={styles.title}>{title}</p>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default InfoBlock;

InfoBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};
