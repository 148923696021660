import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload_3.svg";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IAddContactPayload } from "models/store/user/userPayloads";
import { ChangeEvent, Dispatch, SetStateAction, useRef } from "react";
import { useLocales } from "react-localized";
import { dataURLToFile } from "utils/filesConvert";

import styles from "./ContactsAvatar.module.sass";

interface IContactsAvatar {
  contactData: IAddContactPayload;
  setContactData: Dispatch<SetStateAction<IAddContactPayload>>;
  avatarName: string;
}

const ContactsAvatar = ({ avatarName, contactData, setContactData }: IContactsAvatar) => {
  const { onSetAvatarCropModal } = useActions();
  const { __ } = useLocales();

  const inputRef = useRef<HTMLInputElement>();
  const fileSelect = () => inputRef.current.click();
  const uploadImage = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image")) {
      const imgSrc = URL.createObjectURL(file);

      onSetAvatarCropModal({
        open: true,
        imgSrc,
        callback: (icon) => {
          setContactData({
            ...contactData,
            icon: icon,
            profileImage: icon?.startsWith("data:image/jpeg;base64") ? dataURLToFile(icon) : null
          });
        }
      });
    }
  };

  const removeIcon = () => setContactData({ ...contactData, profileImage: null, icon: "" });

  return (
    <div className={styles.avatarBlock}>
      <Avatar size={50} name={avatarName || contactData.name + " " + contactData.sname} imgSrc={contactData.icon} />
      <div className={styles.uploadBtns}>
        <Button
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.EXTRA_SMALL}
          onClick={fileSelect}
          iconL={<UploadIcon />}
          text={__("Загрузить")}
        />
        {contactData.icon && (
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.EXTRA_SMALL}
            onClick={removeIcon}
            iconL={<TrashIcon width={9} height={13} />}
            text={__("Удалить")}
          />
        )}
      </div>
      <input
        type="file"
        ref={inputRef}
        className="hidden"
        onChange={uploadImage}
        style={{ display: "none" }}
        accept="image/png, image/gif, image/jpeg, image/jpg"
      />
    </div>
  );
};

export default ContactsAvatar;
