import Editor from "@draft-js-plugins/editor";
import { ReactComponent as FlagIcon } from "assets/icons/flag.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as DotsIcon } from "assets/PrivateCabinet/dots-vertical-round.svg";
import { colors } from "collections/markers";
import { useContextNotes } from "collections/notes";
import { convertFromRaw, EditorState, Modifier, SelectionState } from "draft-js";
import FileTags from "generalComponents/FileTags/FileTags";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useActions } from "hooks/useActions";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { INote } from "models/store/notes/notes";
import { useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { useLocales } from "react-localized";

import styles from "./NoteItem.module.sass";

// Функция-помощник для создания начального состояния редактора из JSON-строки
export const createEditorStateFromJson = (jsonString: string) => {
  if (!jsonString) {
    return EditorState.createEmpty(); // Если jsonString пустая, возвращаем пустое состояние редактора
  }
  try {
    const contentState = convertFromRaw(JSON.parse(jsonString));
    return EditorState.createWithContent(contentState);
  } catch (error) {
    console.error("Ошибка при разборе JSON:", error);
    return EditorState.createEmpty(); // Если произошла ошибка при разборе JSON, возвращаем пустое состояние редактора
  }
};

const NoteItem = ({ item }: { item: INote }) => {
  const { __ } = useLocales();
  const { menu } = useContextNotes();
  const { onSetNoteEditModal, onSetPasswordModal, onSetTopMessageModal, onSetActiveNote, onEditNote } = useActions();
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: "NOTE_ITEM",
      item: { ...item },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    [item]
  );

  const [showFlag, setShowFlag] = useState(false);
  const [showMenuButton, setShowMenuButton] = useState(false);
  const editorRef = useRef(null);

  const openModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (item.pass) {
      onSetPasswordModal({
        open: true,
        title: __("Заметка — защита паролем"),
        successButtonText: __("Открыть"),
        successCallback: (receivedPass) => {
          if (receivedPass === item.pass) {
            onSetActiveNote(item);
            onSetNoteEditModal({ open: true, isFull: true });
          } else {
            onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: "Неверный пароль" });
          }
        }
      });
    } else {
      onSetActiveNote(item);
      onSetNoteEditModal({ open: true, isFull: true });
    }
  };

  const customBlockStyleFn = () => {
    return styles.noMargin;
  };

  const renderMenu = (): JSX.Element => {
    return (
      <PopoverContextMenu data={menu(item)}>
        <div className={styles.dotsButton}>
          <DotsIcon />
        </div>
      </PopoverContextMenu>
    );
  };

  const renderEditorContent = () => {
    if (item.pass) {
      // Если у нас есть item.pass
      const text = item.prim ? JSON.parse(item.prim).blocks[0].text : ""; // Получаем текст из первого блока контента
      const truncatedText = text.slice(0, 3); // Берем первые три символа текста
      const stars = "*\u00A0".repeat(10); // Создаем строку из 20 звездочек
      return (
        <div>
          <div>{truncatedText + stars}</div>
          <div>{stars}</div>
        </div>
      ); // Возвращаем первые три символа и звездочки
    } else {
      const contentState = item.prim ? convertFromRaw(JSON.parse(item.prim)) : null; // Получаем контентное состояние из item.prim

      // Если контентное состояние не определено или пусто, возвращаем пустой контент
      if (!contentState || contentState.getBlockMap().isEmpty()) {
        return null;
      }

      // Получаем ключ первого блока
      const firstBlockKey = contentState.getBlockMap().first().getKey();

      // Применяем стиль "BOLD" к первому блоку контента
      const contentStateWithBoldFirstLine = Modifier.applyInlineStyle(
        contentState,
        new SelectionState({
          anchorKey: firstBlockKey, // Устанавливаем ключ первого блока в качестве начальной точки
          anchorOffset: 0,
          focusKey: firstBlockKey, // Устанавливаем ключ первого блока в качестве конечной точки
          focusOffset: contentState.getBlockForKey(firstBlockKey).getLength() // Устанавливаем конечный смещение в конец первого блока
        }),
        "BOLD"
      );

      // Создаем новый EditorState с обновленным содержимым
      const editorStateWithBoldFirstLine = EditorState.createWithContent(contentStateWithBoldFirstLine);

      return (
        <Editor
          blockStyleFn={customBlockStyleFn}
          editorState={editorStateWithBoldFirstLine}
          ref={editorRef}
          readOnly={true}
          onChange={() => {}}
        />
      );
    }
  };

  return (
    <div
      key={item.id}
      ref={dragRef}
      onClick={openModal}
      style={{ opacity }}
      onMouseEnter={() => setShowMenuButton(true)}
      onMouseLeave={() => setShowMenuButton(false)}
    >
      <div className={styles.container}>
        <div className={styles.header} onMouseLeave={() => setShowFlag(false)}>
          {showMenuButton ? (
            <div className={styles.flagWrapper}>
              <PopoverOverlay
                width={105}
                placement="bottom"
                overlayContent={
                  <div className={styles.flagList}>
                    {colors.slice(0, 8).map((el) => (
                      <FlagIcon
                        key={el}
                        fill={el || "#6E8680"}
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditNote({ id_item: item.id, flag: el });
                          setShowFlag(false);
                        }}
                      />
                    ))}
                  </div>
                }
                show={showFlag}
                setShow={setShowFlag}
              >
                <div className={styles.flagButton}>
                  <FlagIcon fill={item.flag || "#6E8680"} />
                </div>
              </PopoverOverlay>
            </div>
          ) : (
            item.flag && (
              <div className={styles.flag}>
                <FlagIcon fill={item.flag} />
              </div>
            )
          )}
          <div className={styles.right} onClick={(e) => e.stopPropagation()}>
            {item.color && <div className={styles.color} style={{ background: item.color }} />}
            {item.emo && <span dangerouslySetInnerHTML={{ __html: item.emo }} />}
            {item.pass && <LockIcon className={styles.lockIcon} />}
          </div>
        </div>
        {renderEditorContent()}
        {item?.tags?.length > 0 && (
          <div className={styles.footer}>
            <FileTags item={item} />
          </div>
        )}
        {showMenuButton && <div className={styles.menuWrapper}>{renderMenu()}</div>}
      </div>
    </div>
  );
};

export default NoteItem;
