import { ReactComponent as SadIcon } from "assets/PrivateCabinet/smile-sad.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./DeletedAccount.module.sass";

export const DeletedAccount: FC = () => {
  const { __ } = useLocales();

  const { theme } = useUserSelectors();

  const { onDeletesRestoresAdminAccount } = useActions();

  const payload = {
    is_del: 0
  };

  const messages = {
    success: "Аккаунт успешно, восстановлен",
    error: __("Не удалось восстановить аккаунт, попробуйте еще раз")
  };

  return (
    <div className={classNames(styles.root, `scrollbar-medium-${theme}`)}>
      <div className={styles.card}>
        <SadIcon className={styles.card__icon} />
        <div className={styles.card__content}>
          <h2 className={styles.card__title}>{__("Аккаунт удален")}</h2>
          <p className={styles.card__text}>{__("Вы можете восстановить аккаунт")}</p>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("Восстановить")}
            onClick={() => onDeletesRestoresAdminAccount(payload, messages)}
          />
        </div>
      </div>
    </div>
  );
};
