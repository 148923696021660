import { IFolder } from "models/store/folders/foldersStore";
import { getLocationSearchParams } from "utils/getLocationSearchParams";

export const filtersLibraryFolder = (arr: IFolder[]): IFolder[] => {
  const { chapter, id_dep } = getLocationSearchParams();

  let filtredArr = arr;

  if (id_dep) {
    filtredArr = filtredArr.filter((folder) => folder.id_dep === id_dep);
  }

  if (chapter) {
    filtredArr = filtredArr.filter((folder) => folder.chapter === chapter);
  }

  return filtredArr;
};
