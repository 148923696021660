import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Button from "generalComponents/Button/Button";
import SearchField from "generalComponents/SearchField/SearchField";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { PROFILE_MODALS } from "models/store/Cabinet/modals/profileModals";
import { Dispatch, FC, SetStateAction } from "react";
import { useLocales } from "react-localized";

import styles from "./EmployeesServicePanel.module.sass";

interface IProps {
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
}
const EmployeesServicePanel: FC<IProps> = ({ value, onChange }) => {
  const { __ } = useLocales();
  const { onSetProfileModal } = useActions();

  const onAddEmlpoee = () => onSetProfileModal({ open: true, type: PROFILE_MODALS.ADD_EMPLOYEE, employee: null });

  return (
    <ToolsPanel size="large">
      <div className={styles.wrap}>
        <SearchField variant="secondary" value={value} setValue={onChange} placeholder={__("Введите")}></SearchField>
        <Button
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.SMALL}
          onClick={onAddEmlpoee}
          text={__("Добавить")}
          iconL={<PlusIcon width={10} height={10} />}
        />
      </div>
    </ToolsPanel>
  );
};

export default EmployeesServicePanel;
