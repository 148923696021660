/* eslint-disable react/display-name */
import { ReactComponent as PhotoUploadIcon } from "assets/icons/photo-upload.svg";
import { Image } from "generalComponents/Image/Image";
import { memo } from "react";
import { useLocales } from "react-localized";

import styles from "./PreviewUploadIcon.module.sass";

interface IPreviewUploadIconProps {
  src: string;
  alt: string;
}

export const PreviewUploadIcon = memo<IPreviewUploadIconProps>(({ src, alt }: IPreviewUploadIconProps): JSX.Element => {
  const { __ } = useLocales();

  return (
    <div className={styles.previewIcon}>
      <div className={styles.previewIcon__box}>
        {!src && <PhotoUploadIcon />}
        {src && <Image src={src} alt={alt || __("Иконка")} />}
      </div>
    </div>
  );
});
