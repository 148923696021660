import api from "api";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { EVENT_TYPE, PAGE_DEPTH } from "generalComponents/variables/global";
import { SORTING_TYPE } from "generalComponents/variables/global";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import { initialCreateFolderState } from "models/store/Cabinet/modals/createFolder";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IEditFilesPayload } from "models/store/files/filesPayloads";
import { ILoadingFiles, ISelectedFiles } from "models/store/files/uploadFilesModals/uploadFiles";
import { ICreateFolderPayload, IEditFolderPayload } from "models/store/folders/foldersPayloads";
import { IFolder } from "models/store/folders/foldersStore";
import {
  IJoinProject,
  IJoinProjectLink,
  IJoinProjectLinkCategory,
  IJointProjectStatus,
  IParticipant,
  IProject,
  IProjectRole,
  IProjectTask,
  IProjectTeam,
  ISprint,
  TProjectElementsArray,
  TProjectFilesArray
} from "models/store/joinProjects/joinProgects";
import { JoinProjectActions } from "models/store/joinProjects/joinProjectsActions";
import {
  IAddJoinProjectLinkCategoryPayload,
  IAddJoinProjectLinkPayload,
  IAddProjectTeamPayload,
  IAddTaskDependencyPayload,
  ICreateProjectPayload,
  ICreateProjectRolePayload,
  IDeleteTaskDependencyPayload,
  IDelJoinProjectLinkCategoryPayload,
  IDelJoinProjectLinkPayload,
  IDelProjectTeamPayload,
  IEditJoinProjectLinkCategoryPayload,
  IEditProjectPayload,
  IEditStatusesPayload,
  IEditTaskDependencyPayload,
  IFetchAllTaskProject,
  IFetchCurrentTaskPayload,
  IFetchTaskProject,
  IMoveTrashArchivePayload,
  IUpdateTaskProject,
  TAddSprintProjectPayload,
  TChangesSprintProjectPayload
} from "models/store/joinProjects/joinProjectsPayloads";
import { FoldersNextArrayType } from "models/store/library/library";
import { ILoadingLibraryFiles } from "models/store/library/uploadFilesModals/uploadLibraryFiles";
import { IEditTaskPayload } from "models/store/tasks/tasksPayloads";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { JoinProjectsTypes } from "Store/types/joinProjectsTypes";
import { getLocationSearchParams } from "utils/getLocationSearchParams";
import { getPageDep } from "utils/getPageDep";

import {
  onFileView,
  onSetCreateFolderModal,
  onSetCreateProjectModal,
  onSetCreateProjectRoleModal,
  onSetCreateProjectTeamModal,
  onSetLoaderModal,
  onSetTopMessageModal
} from "./ModalActions";

export const onSetProjectList = (payload: IJoinProject[]): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_LIST,
  payload
});

export const onAddProject = (payload: IJoinProject): JoinProjectActions => ({
  type: JoinProjectsTypes.ADD_PROJECT,
  payload
});

export const onUpdateProject = (payload: IJoinProject): JoinProjectActions => ({
  type: JoinProjectsTypes.EDIT_PROJECT,
  payload
});

export const onDelProject = (payload: string): JoinProjectActions => ({
  type: JoinProjectsTypes.DEL_PROJECT,
  payload
});

export const onSetCurrentProject = (payload: IProject): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_CURRENT_PROJECT,
  payload
});

export const onAddProjectRole = (payload: IProjectRole): JoinProjectActions => ({
  type: JoinProjectsTypes.ADD_ROLE,
  payload
});

export const onEditProjectRole = (payload: IProjectRole): JoinProjectActions => ({
  type: JoinProjectsTypes.EDIT_ROLE,
  payload
});

export const onEditProjectStatuses = (payload: IJointProjectStatus[]): JoinProjectActions => ({
  type: JoinProjectsTypes.EDIT_STATUSES,
  payload
});

export const onAddProjectTeam = (payload: {
  team: IProjectTeam;
  participants: IParticipant[];
}): JoinProjectActions => ({
  type: JoinProjectsTypes.ADD_TEAM,
  payload
});

export const onEditProjectTeam = (payload: {
  teams: IProjectTeam[];
  participants: IParticipant[];
}): JoinProjectActions => ({
  type: JoinProjectsTypes.EDIT_TEAM,
  payload
});

export const onUpdateSprintsProject = (payload: ISprint[]): JoinProjectActions => ({
  type: JoinProjectsTypes.UPDATE_SPRINTS_PROJECT,
  payload
});

export const onSetProjectLinks = (payload: IJoinProjectLink[]): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_LINKS,
  payload
});

export const onSetProjectLinksCategory = (payload: IJoinProjectLinkCategory[]): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_LINKS_CATEGORY,
  payload
});

export const onAddProjectLink = (payload: IJoinProjectLink): JoinProjectActions => ({
  type: JoinProjectsTypes.ADD_PROJECT_LINKS,
  payload
});

export const onUpdateProjectLink = (payload: IJoinProjectLink): JoinProjectActions => ({
  type: JoinProjectsTypes.EDIT_PROJECT_LINKS,
  payload
});

export const onDelProjectLink = (payload: string): JoinProjectActions => ({
  type: JoinProjectsTypes.DEL_PROJECT_LINKS,
  payload
});

export const onAddProjectLinkCategory = (payload: IJoinProjectLinkCategory): JoinProjectActions => ({
  type: JoinProjectsTypes.ADD_PROJECT_LINKS_CATEGORY,
  payload
});

export const onUpdateProjectLinkCategory = (payload: IJoinProjectLinkCategory): JoinProjectActions => ({
  type: JoinProjectsTypes.EDIT_PROJECT_LINKS_CATEGORY,
  payload
});

export const onDelProjectLinkCategory = (payload: string): JoinProjectActions => ({
  type: JoinProjectsTypes.DEL_PROJECT_LINKS_CATEGORY,
  payload
});

export const onSetProjectLoader = (payload: boolean): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_LOADER,
  payload
});

export const onSetProjectPage = (payload: number): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_PAGE,
  payload
});

export const onSetProjectLibraryFolders = (payload: FoldersNextArrayType): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_LIBRARY_FOLDERS,
  payload
});

export const onSetProjectAddLibraryFolder = (payload: IFolder): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_ADD_LIBRARY_FOLDER,
  payload
});

export const onSetProjectUpdateLibraryFolder = (payload: IFolder): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_UPDATE_LIBRARY_FOLDER,
  payload
});

export const onSetProjectDelLibraryFolder = (payload: IFolder): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_DEL_LIBRARY_FOLDER,
  payload
});

export const onSetProjectDelLibraryFiles = (payload: IFile[]): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_DEL_LIBRARY_FILES,
  payload
});

export const onSetProjectLibraryFolderInfo = (payload: IFolder): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_LIBRARY_FOLDER_INFO,
  payload
});

export const onResetProjectLibraryFolders = (): JoinProjectActions => ({
  type: JoinProjectsTypes.RESET_PROJECT_LIBRARY_FOLDERS
});

export const onChangeProjectLibraryLoadingFiles = (payload: ILoadingLibraryFiles): JoinProjectActions => ({
  type: JoinProjectsTypes.CHANGE_PROJECT_LIBRARY_LOADING_FILES,
  payload
});

export const onResetProjectLibraryLoadingFiles = (): JoinProjectActions => ({
  type: JoinProjectsTypes.RESET_PROJECT_LIBRARY_LOADING_FILES
});

export const onSetProjectTotalItems = (payload: number): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_TOTAL_ITEMS,
  payload
});

export const onSetTasksProject = (payload: IProjectTask[]): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_TASKS,
  payload
});

export const onSetAllTasksProject = (payload: IProjectTask[]): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_ALL_PROJECT_TASKS,
  payload
});

export const onResetAllTasksProject = (): JoinProjectActions => ({
  type: JoinProjectsTypes.RESET_ALL_PROJECT_TASKS
});

export const onSetBackLogProject = (payload: IProjectTask[]): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_BACKLOG,
  payload
});

export const onAddTasksProject = (payload: IProjectTask): JoinProjectActions => ({
  type: JoinProjectsTypes.ADD_PROJECT_TASK,
  payload
});
export const onEditTasksProject = (payload: IProjectTask): JoinProjectActions => ({
  type: JoinProjectsTypes.EDIT_PROJECT_TASK,
  payload
});

export const onDeleteTasksProject = (payload: IProjectTask): JoinProjectActions => ({
  type: JoinProjectsTypes.DELETE_PROJECT_TASK,
  payload
});

export const onMoveTasksToBacklog = (payload: IProjectTask): JoinProjectActions => ({
  type: JoinProjectsTypes.MOVE_TASK_TO_BACKLOG,
  payload
});

export const onMoveTasksFromBacklog = (payload: {
  tasks: IProjectTask[];
  idTask: string;
  idSprint: string;
}): JoinProjectActions => ({
  type: JoinProjectsTypes.MOVE_TASK_FROM_BACKLOG,
  payload
});

export const onMovedTasks = (payload: { tasks: IProjectTask[]; idSprint: string }): JoinProjectActions => ({
  type: JoinProjectsTypes.MOVED_TASKS,
  payload
});

export const onSetProjectDocFiles = (payload: TProjectFilesArray): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_DOC_FILES,
  payload
});

export const onResetProjectDocFilesList = (): JoinProjectActions => ({
  type: JoinProjectsTypes.RESET_PROJECT_DOC_FILES_LIST
});

export const onNullifyProjectDoc = (): JoinProjectActions => ({
  type: JoinProjectsTypes.NULLIFY_PROJECT_DOC
});

export const onSetProjectSelectedFiles = (payload: ISelectedFiles[]): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_SELECTED_FILES,
  payload
});

export const onSetProjectLoadingDocFiles = (payload: ILoadingFiles): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_LOADING_FILES,
  payload
});

export const onChangeProjectLoadingFiles = (payload: ILoadingFiles): JoinProjectActions => ({
  type: JoinProjectsTypes.CHANGE_PROJECT_LOADING_FILES,
  payload
});

export const onCancelProjectFilesUpload = (payload: string): JoinProjectActions => ({
  type: JoinProjectsTypes.CANCEL_PROJECT_FILES_UPLOAD,
  payload
});

export const onCLoseProjectLoadingFiles = (): JoinProjectActions => ({
  type: JoinProjectsTypes.CLOSE_PROJECT_LOADING_FILES
});

export const onSetUpdateProjectDocDefaultFolders = (payload: IFolder[]): JoinProjectActions => ({
  type: JoinProjectsTypes.UPDATE_PROJECT_DOC_DEFAULT_FOLDERS,
  payload
});

export const onSetAddProjectDocFiles = (payload: IFile | IFolder): JoinProjectActions => ({
  type: JoinProjectsTypes.ADD_PROJECT_DOC_FILES,
  payload
});

export const onSetUpdateProjectDocFiles = (payload: IFile | IFolder): JoinProjectActions => ({
  type: JoinProjectsTypes.UPDATE_PROJECT_DOC_FILES,
  payload
});

export const onSetTrashProjectDocFile = (payload: { id: string }): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_TRASH_PROJECT_DOC_FILE,
  payload
});

export const onSetCopyProjectEditFile = (payload: IFile[]): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_COPY_PROJECT_EDIT_FILE,
  payload
});

export const onSetProjectFiles = (payload: TProjectFilesArray): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_FILES,
  payload
});

export const onSetAddProjectFiles = (payload: IFile | IFolder): JoinProjectActions => ({
  type: JoinProjectsTypes.ADD_PROJECT_FILES,
  payload
});

export const onSetUpdateProjectFiles = (payload: IFile | IFolder): JoinProjectActions => ({
  type: JoinProjectsTypes.UPDATE_PROJECT_FILES,
  payload
});

export const onSetTrashProjectFile = (payload: { id: string }): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_TRASH_PROJECT_FILE,
  payload
});

export const onResetProjectFilesList = (): JoinProjectActions => ({
  type: JoinProjectsTypes.RESET_PROJECT_FILES_LIST
});

export const onSetUpdateProjectFilesDefaultFolders = (payload: IFolder[]): JoinProjectActions => ({
  type: JoinProjectsTypes.UPDATE_PROJECT_FILES_DEFAULT_FOLDERS,
  payload
});

export const onSetProjectTrashFiles = (payload: TProjectElementsArray): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_PROJECT_TRASH_FILES,
  payload
});

export const onSetUpdateProjectTrashFiles = (payload: IFile | IFolder): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_UPDATE_PROJECT_TRASH_FILES,
  payload
});

export const onSetDelProjectTrashElement = (payload: { id: string }): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_DEL_PROJECT_TRASH_ELEMENT,
  payload
});

export const onSetResetProjectTrashElements = (): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_RESET_PROJECT_TRASH_ELEMENTS
});

export const onSetCurrentTask = (payload: IProjectTask): JoinProjectActions => ({
  type: JoinProjectsTypes.SET_CURRENT_TASK,
  payload
});

export const onSetUpdateProjectTeams = (payload: {
  teams: IProjectTeam[];
  participants: IParticipant[];
}): JoinProjectActions => ({
  type: JoinProjectsTypes.UPDATE_PROJECT_TEAMS,
  payload
});

export const onFetchProjectlist =
  (
    controller: AbortController,
    setLoader: (value: boolean) => void
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      setLoader(true);
      const params = {
        uid: getState().user.uid
      };
      const { data } = await api.get(`/ajax/project_list.php`, { params, signal: controller.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetProjectList(data.projects));
    } catch {
      if (!controller.signal.aborted) dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setLoader(false);
    }
  };

export const onFetchCurrentProject =
  (
    id: string,
    controller: AbortController,
    setLoader: (value: boolean) => void
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      setLoader(true);
      const params = {
        uid: getState().user.uid,
        id_item: id
      };
      const { data } = await api.get(`/ajax/project_get.php`, { params, signal: controller.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetCurrentProject(data.project));
    } catch {
      if (!controller.signal.aborted) dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setLoader(false);
    }
  };

export const onCreateProject =
  (params: ICreateProjectPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("name", params.data.name);
      payload.append("work_days", params.data.work_days);
      payload.append("work_hours", params.data.work_hours);
      payload.append("gmt", params.data.gmt);
      payload.append("is_gmt_auto", params.data.is_gmt_auto ? "true" : "false");
      if (params.data.icon) {
        payload.append("icon", params.data.icon);
      }
      if (params.data.date_start) {
        payload.append("date_start", params.data.date_start);
      }
      if (params.data.date_end) {
        payload.append("date_end", params.data.date_end);
      }

      const { data } = await api.post(`/ajax/project_add.php`, payload);
      if (data.error === "name exists") {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: params.messages.error }));
        return;
      }
      checkResponseStatus(data.ok);
      dispatch(onAddProject(data.project));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: params.messages.success }));
      dispatch(onSetCreateProjectModal({ open: false }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onEditProject =
  (params: IEditProjectPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("id_item", params.data.id);
      payload.append("work_days", params.data.work_days);
      payload.append("work_hours", params.data.work_hours);
      if (params.data.is_gmt_auto === true || params.data.is_gmt_auto === false) {
        payload.append("is_gmt_auto", params.data.is_gmt_auto ? "true" : "false");
      }
      if (params.data.gmt) {
        payload.append("gmt", params.data.gmt);
      }
      if (params.data.name) {
        payload.append("name", params.data.name);
      }
      if (params.data.icon) {
        payload.append("icon", params.data.icon);
        payload.append("icon_del", "1");
      }
      if (params.data.date_start) {
        payload.append("date_start", params.data.date_start);
      }
      if (params.data.date_end) {
        payload.append("date_end", params.data.date_end);
      }

      const { data } = await api.post(`/ajax/project_edit.php`, payload);
      if (data.error === "name exists") {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: params.messages.error }));
        return;
      }
      checkResponseStatus(data.ok);
      dispatch(onUpdateProject(data.project));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: params.messages.success }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onArchiveTrashProject =
  (payload: IMoveTrashArchivePayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        ...payload.data
      };
      const { data } = await api.get(`/ajax/project_edit.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onDelProject(data.project.id));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: payload.message }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      //
    }
  };

export const onFetchCurrentTaskProject =
  (payload: IFetchCurrentTaskPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetProjectLoader(true));
      const params = { uid: getState().user.uid, id_task: payload.id_task };
      const { data } = await api.get(`/ajax/task_info.php?`, { params, signal: payload.controller.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetCurrentTask(data.task));
    } catch {
      if (!payload.controller.signal.aborted)
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetProjectLoader(false));
    }
  };

export const onFetchTaskProject =
  (
    payload?: IFetchTaskProject,
    callBack?: () => void
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_project: getState().JoinProjects.project.id,
        id_sprint: payload.id_sprint ? payload.id_sprint : undefined,
        page: 1
      };
      const { data } = await api.get(`/ajax/task_get.php`, { params, signal: payload.controller.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetTasksProject(data.tasks));
    } catch {
      !payload.controller?.signal?.aborted &&
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      callBack && callBack();
    }
  };
export const onFetchTaskGantProject =
  (controller: AbortController): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_project: getState().JoinProjects.project.id,
        page: 1
      };
      const { data } = await api.get(`/ajax/task_get_gant.php`, { params, signal: controller.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetTasksProject(data.tasks));
    } catch {
      controller?.signal?.aborted && dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
    // finally {
    //   callBack && callBack();
    // }
  };

export const onFetchAllTaskProject =
  (payload: IFetchAllTaskProject): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const {
      per_page,
      search,
      selectedParticipants,
      team,
      id_sprint,
      emo,
      color,
      tags,
      priority,
      callBack,
      controller
    } = payload;

    try {
      const params = {
        uid: getState().user.uid,
        id_project: getState().JoinProjects.project.id,
        search,
        id_executor: selectedParticipants,
        id_team: team,
        emo: emo ? emo : undefined,
        color: color ? color : undefined,
        tags: tags?.length ? tags : undefined,
        priority,
        page: getState().JoinProjects.page,
        per_page,
        id_sprint: id_sprint
      };
      const { data } = await api.get(`/ajax/task_get.php`, { params, signal: controller.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetAllTasksProject(data.tasks));
      dispatch(onSetProjectTotalItems(data.total));
    } catch {
      !payload.controller?.signal?.aborted &&
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      callBack && callBack();
    }
  };

export const onUpdateTaskProject =
  (payload: IUpdateTaskProject): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      payload.isLoader && dispatch(onSetProjectLoader(true));
      payload.data.append("uid", getState().user.uid);
      const { data } = await api.post(`/ajax/task_edit.php`, payload.data);
      checkResponseStatus(data.ok);
      dispatch(onSetCurrentTask(data.task));
      dispatch(onEditTasksProject(data.task));
      payload.cb && payload.cb(data.task);
      payload.message &&
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: payload.message }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      payload.isLoader && dispatch(onSetProjectLoader(false));
    }
  };

export const onAddTaskDependency =
  (payload: IAddTaskDependencyPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const { id_task1, id_task2, type1, type2 } = payload;
      const params = { uid: getState().user.uid, id_task1, id_task2, type1, type2 };
      const { data } = await api.get("/ajax/task_links_add.php", { params });
      if (data?.error === "link exists") {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: payload.error }));
        return;
      }
      checkResponseStatus(data.ok);
      dispatch(onSetCurrentTask(data.task));
      dispatch(onEditTasksProject(data.task));
      payload.cb && payload.cb();
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onDeleteTaskDependency =
  (payload: IDeleteTaskDependencyPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const { id_task1, id_task2, type1, type2 } = payload;
      const params = { uid: getState().user.uid, id_task1, id_task2, type1, type2 };
      const { data } = await api.get("/ajax/task_links_del.php", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetCurrentTask(data.task));
      dispatch(onEditTasksProject(data.task));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onEditTaskDependency =
  (payload: IEditTaskDependencyPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const { id_task1, id_task2, type1, type2, type1_new, type2_new } = payload;
      const params = { uid: getState().user.uid, id_task1, id_task2, type1, type2, type1_new, type2_new };
      const { data } = await api.get("/ajax/task_links_edit.php", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetCurrentTask(data.task));
      dispatch(onEditTasksProject(data.task));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onCreateRole =
  (payload: ICreateProjectRolePayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        ...payload.data
      };
      const { data } = await api.get(`/ajax/project_roles_add.php`, { params });
      if (data.error === "name exists") {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: payload.messages.error }));
        return;
      }
      checkResponseStatus(data.ok);
      dispatch(onAddProjectRole(data.role));
      dispatch(onSetCreateProjectRoleModal({ open: false, type: "add", role: null }));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: payload.messages.success }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      //
    }
  };

export const onEditRole =
  (payload: ICreateProjectRolePayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        ...payload.data
      };
      const { data } = await api.get(`/ajax/project_roles_edit.php`, { params });
      if (data.error === "name exists") {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: payload.messages.error }));
        return;
      }
      checkResponseStatus(data.ok);
      dispatch(onEditProjectRole(data.role));
      dispatch(onSetCreateProjectRoleModal({ open: false, type: "add", role: null }));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: payload.messages.success }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      //
    }
  };

export const onEditStatuses =
  (payload: IEditStatusesPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_project", payload.id);
      formData.append("statuses", JSON.stringify(payload.statuses));

      const { data } = await api.post(`/ajax/project_status_edit.php`, formData);

      checkResponseStatus(data.ok);
      dispatch(onEditProjectStatuses(data.statuses));
      dispatch(onSetTasksProject(data.tasks));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onAddTeam =
  (payload: IAddProjectTeamPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("name", payload.data.name);
      formData.append("prim", payload.data.prim);
      formData.append("id_project", payload.data.id_project);
      formData.append("users", payload.data.users);
      formData.append("is_close", String(payload.data.is_close));

      const { data } = await api.post(`/ajax/project_teams_add.php`, formData);

      checkResponseStatus(data.ok);
      dispatch(onAddProjectTeam({ team: data.project_teams, participants: data.participants }));
      dispatch(onSetCreateProjectTeamModal({ open: false, type: "add", team: null, isAddParticipant: false }));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: payload.message }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      //
    }
  };

export const onEditTeam =
  (payload: IAddProjectTeamPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("name", payload.data.name);
      formData.append("prim", payload.data.prim);
      formData.append("id_project", payload.data.id_project);
      formData.append("id_item", payload.data.id_item);
      formData.append("users", payload.data.users);
      formData.append("is_close", String(payload.data.is_close));

      const { data } = await api.post(`/ajax/project_teams_edit.php`, formData);

      checkResponseStatus(data.ok);
      dispatch(onEditProjectTeam({ teams: data.project_teams, participants: data.participants }));
      dispatch(onSetCreateProjectTeamModal({ open: false, type: "add", team: null, isAddParticipant: false }));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: payload.message }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      //
    }
  };

export const onDelTeam =
  (payload: IDelProjectTeamPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        is_del: "1",
        ...payload.data
      };
      const { data } = await api.get(`/ajax/project_teams_edit.php`, { params });

      checkResponseStatus(data.ok);
      dispatch(onEditProjectTeam({ teams: data.project_teams, participants: data.participants }));

      // dispatch(onSetUpdateProjectTeams({ teams: data?.project_teams, participants: data?.participants }));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: payload.message }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      //
    }
  };

export const onSetChangesSprintProject =
  (
    type: EVENT_TYPE.ADD | EVENT_TYPE.EDIT | EVENT_TYPE.DEL,
    payload: TChangesSprintProjectPayload
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const payloadAdd = payload as TAddSprintProjectPayload;

    try {
      const params = {
        uid: getState()?.user?.uid,
        ...payload
      };
      const { data } = await api.get(`/ajax/project_sprints_${type}.php`, { params });

      checkResponseStatus(data.ok);
      dispatch(onUpdateSprintsProject(data?.project_sprints));
      if (data?.tasks && payloadAdd?.id_sprint_from) {
        dispatch(onMovedTasks({ tasks: data.tasks, idSprint: payloadAdd.id_sprint_from }));
      }
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: type }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onFetchJointProjectLinks =
  (payload: {
    id: string;
    setIsLoading: (value: boolean) => void;
  }): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { id, setIsLoading } = payload;
    try {
      setIsLoading(true);
      const params = {
        uid: getState().user.uid,
        id_project: id
      };
      const { data } = await api.get(`/ajax/project_links_list.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetProjectLinks(data.data.project_links));
      dispatch(onSetProjectLinksCategory(data.data.project_links_dep));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading(false);
    }
  };

export const onAddJointProjectLinksCategory =
  (
    payload: IAddJoinProjectLinkCategoryPayload
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { item, setIsLoading } = payload;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_project", item.id_project);
      formData.append("name", item.name);
      const { data } = await api.post(`/ajax/project_links_dep_add.php`, formData);
      checkResponseStatus(data.ok);
      dispatch(onSetProjectLinksCategory(data.project_links_dep));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading(false);
    }
  };

export const onEditJointProjectLinksCategory =
  (
    payload: IEditJoinProjectLinkCategoryPayload
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { item, setIsLoading } = payload;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_project", item.id_project);
      formData.append("id_item", item.id_item);
      formData.append("name", item.name);
      const { data } = await api.post(`/ajax/project_links_dep_edit.php`, formData);
      checkResponseStatus(data.ok);
      dispatch(onSetProjectLinksCategory(data.project_links_dep));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading(false);
    }
  };

export const onDelJointProjectLinksCategory =
  (
    payload: IDelJoinProjectLinkCategoryPayload
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { item, setIsLoading } = payload;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_project", item.id_project);
      formData.append("id_item", item.id_item);
      const { data } = await api.post(`/ajax/project_links_dep_del.php`, formData);
      checkResponseStatus(data.ok);
      dispatch(onSetProjectLinksCategory(data.project_links_dep));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading(false);
    }
  };

export const onAddJointProjectLink =
  (payload: IAddJoinProjectLinkPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { item, setIsLoading } = payload;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_project", item.id_project);
      formData.append("name", item.name);
      formData.append("emo", item.emo);
      formData.append("id_dep", item.id_dep);
      formData.append("link", item.link);
      const { data } = await api.post(`/ajax/project_links_add.php`, formData);
      checkResponseStatus(data.ok);
      dispatch(onSetProjectLinks(data.data.project_links));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading(false);
    }
  };

export const onEditJointProjectLink =
  (payload: IAddJoinProjectLinkPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { item, setIsLoading } = payload;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_item", item.id);
      formData.append("id_project", item.id_project);
      formData.append("name", item.name);
      formData.append("emo", item.emo);
      formData.append("id_dep", item.id_dep);
      formData.append("link", item.link);
      const { data } = await api.post(`/ajax/project_links_edit.php`, formData);
      checkResponseStatus(data.ok);
      dispatch(onSetProjectLinks(data.data.project_links));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading(false);
    }
  };

export const onDelJointProjectLink =
  (payload: IDelJoinProjectLinkPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { item, setIsLoading } = payload;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_project", item.id_project);
      formData.append("id_item", item.id_item);
      const { data } = await api.post(`/ajax/project_links_del.php`, formData);
      checkResponseStatus(data.ok);
      dispatch(onSetProjectLinks(data.data.project_links));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading(false);
    }
  };

export const onGetProjectLibraryElements =
  (
    controller?: AbortController,
    payload: { is_dir: number; id_dir?: string } = { is_dir: 1 }
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { chapter, search_query, year, month } = getLocationSearchParams();

    const groupByCategory = !chapter;
    const isDir = payload.is_dir === 1;

    const params = {
      uid: getState().user.uid,
      per_page: PER_PAGE,
      id_project: getState()?.JoinProjects?.project?.id,
      page: getState()?.JoinProjects?.page,
      chapter: chapter && !payload?.id_dir ? chapter : undefined,
      id_dir: payload?.id_dir ? payload?.id_dir : undefined,
      search: search_query || undefined,
      y: year || undefined,
      m: month || undefined,
      show_files: 1,
      group_month: chapter && isDir ? 1 : 0,
      group_chapter: groupByCategory && isDir ? 1 : 0,
      is_dir: groupByCategory && isDir ? 1 : 0,
      sort_reverse: chapter ? 1 : 0,
      dep: PAGE_DEPTH.PROJECT_LIBRARY
    };
    try {
      dispatch(onSetProjectLoader(true));
      const { data } = await api.get("/ajax/file_list.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetProjectLibraryFolders(data.files));
      dispatch(onSetProjectTotalItems(data.total));
      dispatch(onSetProjectLibraryFolderInfo(data.dir_info));
    } catch {
      if (!controller?.signal?.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onSetProjectLoader(false));
    }
  };

export const onAddProjectLibraryFiles =
  (payload: ILoadingLibraryFiles): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const formData = new FormData();

    formData.append("uid", getState().user.uid);
    formData.append("id_parent", "0");
    formData.append("chapter", payload.id_category);
    formData.append("dir_name", payload.dir_name);
    payload?.prim && formData.append("prim", payload.prim);
    formData.append("id_project", getState()?.JoinProjects?.project?.id);
    !!payload.files?.length && payload.files.forEach((file: File) => formData.append("myfile[]", file));
    formData.append("dep", PAGE_DEPTH.PROJECT_LIBRARY);

    try {
      const { data } = await api.post("/ajax/dir_add.php", formData, {
        onUploadProgress: (progressEvent: AxiosProgressEvent): void => {
          const time = Math.floor(
            progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
          );
          const percent = progressEvent.progress * 100;
          dispatch(onChangeProjectLibraryLoadingFiles({ ...payload, percent, time }));
        }
      });

      dispatch(onChangeProjectLibraryLoadingFiles({ ...payload, done: true, isOpen: false }));

      dispatch(onSetProjectAddLibraryFolder(data?.file_info));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.ADD
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    }
  };

let promisesEditFolder: Array<{ url: string; params: FormData }> = [];

export const onEditProjectLibraryFiles =
  (
    payload: ILoadingLibraryFiles & { deletedFiles?: IFile[]; id_dirs: string[] }
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const isEdits =
      payload.id_category ||
      payload.id_dep ||
      payload.dir_name ||
      payload.prim ||
      payload.prim === "" ||
      payload?.files?.length;

    if (isEdits) {
      const formData = new FormData();

      formData.append("uid", getState().user.uid);
      formData.append("id_project", getState()?.JoinProjects?.project?.id);
      payload.id_dirs?.forEach((id: string) => formData.append("id_dirs[]", id));
      payload.id_category && formData.append("chapter", payload.id_category);
      payload.id_dep && formData.append("id_dep", payload.id_dep);
      payload.dir_name && formData.append("dir_name_new", payload.dir_name);
      payload.prim !== undefined && formData.append("prim", payload.prim);
      !!payload?.files?.length && payload.files.forEach((file: File) => formData.append("myfile[]", file));
      formData.append("dep", PAGE_DEPTH.PROJECT_LIBRARY);

      promisesEditFolder.push({ url: "/ajax/dir_edit.php", params: formData });
    }

    if (payload?.deletedFiles?.length) {
      const formData = new FormData();

      formData.append("uid", getState().user.uid);
      payload.deletedFiles.forEach(({ fid }) => formData.append("fids[]", fid));
      formData.append("dep", PAGE_DEPTH.LIBRARY);

      promisesEditFolder.push({ url: "/ajax/file_del_force.php", params: formData });
    }

    try {
      const [dataFirst] = await axios.all(
        promisesEditFolder.map(({ url, params }) =>
          api.post(url, params, {
            onUploadProgress: (progressEvent: AxiosProgressEvent): void => {
              const time = Math.floor(
                progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
              );
              const percent = progressEvent.progress * 100;
              dispatch(onChangeProjectLibraryLoadingFiles({ ...payload, percent, time }));
            }
          })
        )
      );

      promisesEditFolder = [];

      dispatch(onChangeProjectLibraryLoadingFiles({ ...payload, done: true, isOpen: false }));

      dataFirst?.data?.file_info && dispatch(onSetProjectUpdateLibraryFolder(dataFirst?.data?.file_info));

      !dataFirst?.data?.file_info &&
        payload?.deletedFiles?.length &&
        dispatch(onSetProjectDelLibraryFiles(payload.deletedFiles));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.EDIT
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetProjectLoader(false));
    }
  };

export const onTrashProjectLibraryFolder =
  (payload: IFolder): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_dir: payload.id_dir,
      id_project: getState()?.JoinProjects?.project?.id,
      dep: PAGE_DEPTH.PROJECT_LIBRARY
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/dir_del.php`, { params });
      checkResponseStatus(data.ok);

      console.log(data);

      dispatch(onSetProjectDelLibraryFolder(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRemoveTaskFromBackLog =
  (payload: IProjectTask): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_task", payload.id);
      formData.append("id_sprints", JSON.stringify(payload.id_sprints));
      const { data } = await api.post(`/ajax/task_edit.php`, formData);
      checkResponseStatus(data.ok);
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      //
    }
  };

export const onEditJointProjectTasks =
  (
    payload: Record<string, Partial<IProjectTask>>,
    id_sprint: string,
    controller: AbortController,
    callBack: Function,
    shouldUpdateTasks = true
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_project", getState().JoinProjects.project.id);
      formData.append("data", JSON.stringify(payload));
      formData.append("id_sprint", id_sprint);

      const config: AxiosRequestConfig = {
        method: "post",
        url: "/ajax/task_edit_y.php",
        data: formData,
        signal: controller.signal
      };

      const { data } = await api(config);

      checkResponseStatus(data.ok);
      shouldUpdateTasks && dispatch(onSetTasksProject(data.tasks));
      callBack(data.tasks);
    } catch (error) {
      console.log(error);
    } finally {
      /* console.log("finally"); */
    }
  };

export const onGetProjectFiles =
  (
    payload: { id_dir?: string; dep: PAGE_DEPTH; callback: (files: TProjectFilesArray) => void },
    controller?: AbortController
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      per_page: PER_PAGE,
      page: getState().JoinProjects.page,
      id_dir: payload?.id_dir ? payload.id_dir : undefined,
      id_project: getState().JoinProjects.project.id,
      sort: SORTING_TYPE.DATE_CREATED,
      sort_reverse: 1,
      dep: payload.dep
    };
    try {
      dispatch(onSetProjectLoader(true));

      const { data } = await api.get("/ajax/file_list.php", { params, signal: controller?.signal });

      checkResponseStatus(data.ok);

      payload.callback(data.files);

      dispatch(onSetProjectTotalItems(data.total));
    } catch {
      if (!controller?.signal?.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onSetProjectLoader(false));
    }
  };

export const onUploadProjectFile =
  (
    file: ILoadingFiles,
    controller: AbortController
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { _PROJECT_DOC_, _PROJECT_FILES_ } = getPageDep(file.options.dep);

    try {
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("myfile", file.file);
      formData.append("id_dir", file.options.id_dir);
      formData.append("id_project", getState().JoinProjects.project.id);
      formData.append("fileName", file.options.name);
      formData.append("dep", file.options.dep);
      file?.options?.chapter && formData.append("chapter", file.options.chapter);

      const { data } = await api.post(`/ajax/file_add.php`, formData, {
        signal: controller.signal,
        onUploadProgress: (progressEvent: AxiosProgressEvent): void => {
          const time = Math.floor(
            progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
          );
          const percent = progressEvent.progress * 100;
          dispatch(onChangeProjectLoadingFiles({ ...file, percent, time }));
        }
      });

      dispatch(onChangeProjectLoadingFiles({ ...file, done: true }));

      if (file.options.id_dir === getState().Folders.currentFolder.id_dir) {
        if (_PROJECT_DOC_) {
          dispatch(onSetAddProjectDocFiles(data?.file_info));
        }

        if (_PROJECT_FILES_) {
          dispatch(onSetAddProjectFiles(data?.file_info));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const onCreateProjectDocFile =
  (
    file: ILoadingFiles,
    controller: AbortController
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_project: getState()?.JoinProjects?.project?.id,
        id_dir: file.options.id_dir,
        fileName: file.options.name,
        ext: file.options.ext,
        dep: file.options?.dep
      };

      const { data } = await api.get("/ajax/file_new.php", {
        params,
        signal: controller.signal,
        onUploadProgress: (progressEvent: AxiosProgressEvent): void => {
          const time = Math.floor(
            progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
          );
          const percent = progressEvent.progress * 100;
          dispatch(onChangeProjectLoadingFiles({ ...file, percent, time }));
        }
      });
      dispatch(onChangeProjectLoadingFiles({ ...file, done: true }));

      if (file.options.id_dir === getState().Folders.currentFolder.id_dir) {
        dispatch(onSetAddProjectDocFiles(data?.file));
      }
      if (file.isOpen) {
        dispatch(onFileView({ open: true, params: data.file }));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const onCreateProjectFolder =
  (payload: ICreateFolderPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState): Promise<void> => {
    const { _PROJECT_DOC_, _PROJECT_FILES_ } = getPageDep(payload.dep);

    const params = {
      uid: getState().user.uid,
      id_project: getState().JoinProjects.project.id,
      tags: JSON.stringify(payload.tags),
      ...payload
    };

    try {
      dispatch(onSetLoaderModal(true));

      const { data } = await api.get("/ajax/dir_add.php", { params });

      checkResponseStatus(data.ok);

      if (_PROJECT_DOC_) {
        dispatch(onSetUpdateProjectDocDefaultFolders(data.folders_doc));
      }

      if (_PROJECT_FILES_ && data?.folders_file) {
        dispatch(onSetUpdateProjectFilesDefaultFolders(data.folders_file));
      }

      if (payload.id_parent === getState().Folders.currentFolder.id_dir) {
        if (_PROJECT_DOC_) {
          dispatch(onSetAddProjectDocFiles(data?.file_info));
        }

        if (_PROJECT_FILES_) {
          dispatch(onSetAddProjectFiles(data?.file_info));
        }
      }

      dispatch(onSetCreateFolderModal(initialCreateFolderState()));

      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.ADD }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onEditProjectFolders =
  (
    payload: IEditFolderPayload,
    typeMessage: EVENT_TYPE
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { _PROJECT_DOC_, _PROJECT_FILES_ } = getPageDep(payload.dep);

    try {
      dispatch(onSetLoaderModal(true));

      const params = {
        uid: getState().user.uid,
        id_project: getState().JoinProjects.project.id,
        ...payload
      };

      const { data } = await api.get(`/ajax/dir_edit.php`, { params });
      checkResponseStatus(data.ok);

      if (_PROJECT_DOC_) {
        dispatch(onSetUpdateProjectDocDefaultFolders(data.folders_doc));
      }

      if (_PROJECT_FILES_ && data?.folders_file) {
        dispatch(onSetUpdateProjectFilesDefaultFolders(data.folders_file));
      }

      if (payload.id_parent === getState().Folders.currentFolder.id_dir) {
        if (_PROJECT_DOC_) {
          dispatch(onSetUpdateProjectDocFiles(data?.file_info));
        }

        if (_PROJECT_FILES_) {
          dispatch(onSetUpdateProjectFiles(data?.file_info));
        }
      }

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: typeMessage
        })
      );

      dispatch(onSetCreateFolderModal(initialCreateFolderState()));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onCompareProjectFile =
  (
    payload: {
      fid: string;
      fid2: string;
    },
    callback: () => void
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const params = {
        uid: getState().user.uid,
        id_project: getState().JoinProjects.project.id,
        fid: payload.fid,
        fid2: payload.fid2
      };

      const { data } = await api.get(`/ajax/file_compare.php`, { params });
      checkResponseStatus(data.ok);

      if (!data.compare) {
        callback();
      }
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onCopyProjectEditFile =
  (
    payload: IFile,
    callback: (copyFile: IFile) => void
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const params = {
        uid: getState().user.uid,
        id_project: getState().JoinProjects.project.id,
        id_dir: getState()?.JoinProjects?.project?.tmp_copy[0]?.id_dir,
        fids: [payload.fid],
        dep: payload.dep
      };

      const { data } = await api.get(`/ajax/file_copy.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(
        onFileView({
          open: true,
          params: data?.file_info,
          callback: () =>
            dispatch(
              onCompareProjectFile(
                {
                  fid: payload.fid,
                  fid2: data?.file_info?.fid
                },
                () => callback(data?.file_info)
              )
            )
        })
      );
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onDelCopyProjectFile =
  (payload: IEditFilesPayload): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_project: getState().JoinProjects.project.id,
        fids: payload.fids,
        dep: payload.dep
      };

      const { data } = await api.get(`/ajax/file_del_force.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetCopyProjectEditFile([]));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onRewriteProjecDocFile =
  (payload: {
    fid: string;
    fid_to: string;
    dep: PAGE_DEPTH;
  }): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_project: getState().JoinProjects.project.id,
      fid: payload.fid,
      fid_to: payload.fid_to,
      dep: payload.dep
    };

    try {
      dispatch(onSetLoaderModal(true));

      const { data } = await api.get(`/ajax/file_rewrite.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetUpdateProjectDocFiles(data?.file_info));

      dispatch(onDelCopyProjectFile({ fids: [payload.fid], dep: payload.dep }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onEditProjectElement =
  (
    payload: (IEditFolderPayload | IEditFilesPayload | IEditTaskPayload) & {
      id_item?: string;
      callback: (data?: any) => void;
    },
    type: "file" | "dir" | "task" | "project_teams",
    callback?: () => void
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      fids: (payload as IEditFilesPayload)?.fids,
      id_dirs: (payload as IEditFolderPayload)?.id_dirs,
      id_project: getState().JoinProjects.project.id,
      id_task: (payload as IEditTaskPayload)?.id_task,
      id_item: payload?.id_item,
      is_del: payload?.is_del,
      chapter: (payload as IEditFolderPayload | IEditFilesPayload)?.chapter
        ? (payload as IEditFolderPayload | IEditFilesPayload).chapter
        : undefined,
      dep: (payload as IEditFolderPayload | IEditFilesPayload)?.dep
    };

    try {
      dispatch(onSetLoaderModal(true));

      const { data } = await api.get(`/ajax/${type}_edit.php`, { params });

      checkResponseStatus(data.ok);

      payload?.callback(data);

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: payload?.is_del === 1 ? EVENT_TYPE.IS_DEL : EVENT_TYPE.RESTORED,
          callback
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onGetProjectTrashFiles =
  (
    payload: {
      id_team: string;
      id_executor: string;
      search: string;
    },
    controller?: AbortController
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      per_page: PER_PAGE,
      page: getState().JoinProjects.page,
      id_project: getState().JoinProjects.project.id,
      id_executor: payload?.id_executor ? payload.id_executor : undefined,
      id_team: payload?.id_team ? payload.id_team : undefined,
      search: payload?.search ? payload.search : undefined,
      sort: SORTING_TYPE.DATE_CREATED,
      sort_reverse: 1
    };
    try {
      dispatch(onSetProjectLoader(true));

      const { data } = await api.get("/ajax/trash_list.php", { params, signal: controller?.signal });

      checkResponseStatus(data.ok);

      dispatch(onSetProjectTrashFiles(data.files));

      dispatch(onSetProjectTotalItems(data.total));
    } catch {
      if (!controller?.signal?.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onSetProjectLoader(false));
    }
  };

export const onDelProjectElement =
  (
    payload: {
      id_dir?: string;
      id_item?: string;
      id_task?: string;
      fids?: string[];
      dep?: PAGE_DEPTH;
      callback: () => void;
    },
    type: "file_del_force" | "dir_del" | "task_del" | "project_teams_del"
  ): ThunkAction<void, RootState, unknown, JoinProjectActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_project: getState().JoinProjects.project.id,
      id_dir: payload?.id_dir,
      id_item: payload?.id_item,
      fids: payload?.fids?.length ? payload.fids : undefined,
      id_task: payload?.id_task,
      dep: payload?.dep
    };

    try {
      dispatch(onSetLoaderModal(true));

      const { data } = await api.get(`/ajax/${type}.php`, { params });

      checkResponseStatus(data.ok);

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.DEL
        })
      );

      payload.callback();
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };
