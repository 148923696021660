import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import Input from "generalComponents/Input/Input";
import PopUp from "generalComponents/PopUp/PopUp";
import Switcher from "generalComponents/Switcher/Switcher";
import Tabs from "generalComponents/Tabs/Tabs";
import { Textarea } from "generalComponents/Textarea/Textarea";
import { useFindParticipant } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialCreateProjectTeamState } from "models/store/Cabinet/modals/createProjectTeam";
import { IParticipant } from "models/store/joinProjects/joinProgects";
import { IAddProjectTeamPayload } from "models/store/joinProjects/joinProjectsPayloads";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { arraysAreEqual } from "utils/arraysEqual";

import HeaderModal from "../HeaderModal/HeaderModal";
import AddParticipant from "./components/AddParticipant/AddParticipant";
import Participant from "./components/Participant/Participant";
import styles from "./CreateProjectTeam.module.sass";

enum TEAM_TABS {
  COMMON = "COMMON",
  PARTICIPANTS = "PARTICIPANTS"
}

const CreateProjectTeam = () => {
  const { __ } = useLocales();
  const { onNextAction, onSetCreateProjectTeamModal, onAddTeam, onEditTeam } = useActions();
  const {
    createProjectTeam: { type, team, isAddParticipant: variant }
  } = useGlobalModalsSelectors();
  const { project } = useJoinProjectsSelectors();

  const _isAdd = type === "add";
  const [tabView, setTabView] = useState<TEAM_TABS>(variant ? TEAM_TABS.PARTICIPANTS : TEAM_TABS.COMMON);
  const [name, setName] = useState<string>(_isAdd ? "" : team.name);
  const [prim, setPrim] = useState<string>(_isAdd ? "" : team.prim);
  const [users, setUsers] = useState<Record<string, IParticipant>>({});
  const [isAddParticipants, setIsAddParticipants] = useState<boolean>(variant);
  const [isClose, setIsClose] = useState<boolean>(_isAdd ? false : Boolean(team.is_close));

  const findParticipant = useFindParticipant();

  useEffect(() => {
    if (team) {
      const users: Record<string, IParticipant> = team.users.reduce((acc, id) => {
        const user = findParticipant(id);
        return { ...acc, [id]: { ...user } };
      }, {});

      setUsers(users);
    }
  }, []); //eslint-disable-line

  const viewsTabs = [
    {
      name: __("Общее"),
      onClick: () => setTabView(TEAM_TABS.COMMON),
      value: TEAM_TABS.COMMON
    },
    {
      name: __("Участники"),
      onClick: () => setTabView(TEAM_TABS.PARTICIPANTS),
      value: TEAM_TABS.PARTICIPANTS
    }
  ];

  const closeModal = () => {
    if (!arraysAreEqual(team?.users || [], Object.keys(users))) {
      onNextAction({
        open: true,
        params: {
          text: __("Применить изменения перед выходом?"),
          title: __("Применить изменения?"),
          buttons: [
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.MEDIUM}
              text={__("Нет, выйти без применения")}
              onClick={() => {
                onSetCreateProjectTeamModal(initialCreateProjectTeamState());
                onNextAction({ open: false, params: null });
              }}
              key="1"
            />,
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.MEDIUM}
              text={__("Да, применить")}
              onClick={onSubmit}
              key="2"
            />
          ]
        }
      });
      return;
    }
    onSetCreateProjectTeamModal(initialCreateProjectTeamState());
  };

  const changeAdd = () => setIsAddParticipants((prev) => !prev);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);
  const onChangePrim = (e: ChangeEvent<HTMLTextAreaElement>) => setPrim(e.currentTarget.value);
  const onChangeClosed = () => setIsClose((prev) => !prev);

  const onChangeJobTitle = (jobTitle: string, id: string) =>
    setUsers((prev) => ({ ...prev, [id]: { ...prev[id], jobTitle } }));

  const onChangeRole = (value: string, id: string) => {
    setUsers((prev) => ({ ...prev, [id]: { ...prev[id], idRole: value } }));
  };

  const onAddUser = (user: IParticipant) => setUsers((prev) => ({ ...prev, [user.id]: user }));

  const onSubmit = () => {
    const _users = Object.keys(users).map((id) => ({
      id_user: users[id].id,
      id_role: users[id].idRole,
      job_title: users[id].jobTitle
    }));

    const params: IAddProjectTeamPayload = {
      data: {
        id_project: project.id,
        is_close: isClose ? 1 : 0,
        name,
        prim: String(prim),
        users: JSON.stringify(_users),
        id_item: _isAdd ? undefined : team.id
      },
      message: _isAdd ? __("Команда добавлена") : __("Команда изменена")
    };
    _isAdd ? onAddTeam(params) : onEditTeam(params);
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.wrap}>
        <HeaderModal
          onClose={closeModal}
          title={isAddParticipants ? __("Добавить участника") : __("Создать команду")}
        />
        <div className={styles.body}>
          {isAddParticipants ? (
            <AddParticipant userIds={Object.keys(users)} onAddUser={onAddUser} changeAdd={changeAdd} />
          ) : (
            <>
              <div className={styles.head}>
                <Tabs tabs={viewsTabs} value={tabView} size="large" variant="main" />
                {tabView === TEAM_TABS.PARTICIPANTS && (
                  <Button
                    variant={ButtonVariantType.EXRTA_LIGHT}
                    size={ButtonSizeType.MEDIUM}
                    text={__("Добавить участника")}
                    onClick={changeAdd}
                  />
                )}
              </div>
              {tabView === TEAM_TABS.COMMON && (
                <div className={styles.block}>
                  <Input value={name} onChange={onChange} require label={__("Название")} className={styles.input} />
                  <Textarea value={prim} onChange={onChangePrim} placeholder={__("Введите")} label={__("Описание")} />
                  <div className={styles.close}>
                    <Switcher checked={isClose} onClickCheckBox={onChangeClosed} />
                    <div>
                      <p className={styles.title}>{__("Закрытй доступ")}</p>
                      <p className={styles.text}>
                        {__("Доступ к задачам команды будет только для участников и Администрации")}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {tabView === TEAM_TABS.PARTICIPANTS && (
                <div className={styles.block}>
                  {Object.keys(users).length > 0 ? (
                    <ul className={styles.list}>
                      {Object.keys(users).map((id) => (
                        <Participant
                          key={id}
                          user={users[id]}
                          onChangeJobTitle={onChangeJobTitle}
                          onChangeRole={onChangeRole}
                        />
                      ))}
                    </ul>
                  ) : (
                    <Clue text={__("Тут пока пусто")} icon="neutral" />
                  )}
                </div>
              )}
            </>
          )}

          <div className={styles.footer}>
            {isAddParticipants ? (
              <Button
                variant={ButtonVariantType.BLUE}
                size={ButtonSizeType.MEDIUM}
                text={__("Готово")}
                onClick={changeAdd}
              />
            ) : (
              <>
                <Button
                  variant={ButtonVariantType.EXRTA_LIGHT}
                  size={ButtonSizeType.MEDIUM}
                  text={__("Отмена")}
                  onClick={closeModal}
                />
                <Button
                  variant={ButtonVariantType.BLUE}
                  size={ButtonSizeType.MEDIUM}
                  text={_isAdd ? __("Создать") : __("Сохранить")}
                  onClick={onSubmit}
                  disabled={Boolean(!name)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default CreateProjectTeam;
