import { ReactComponent as SafeIcon } from "assets/icons/safe.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share_2.svg";
import classNames from "classnames";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { useActions } from "hooks/useActions";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { ISafe } from "models/store/safe/safe";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router-dom";
import { useSafeSelectors } from "Store/selectors/safeSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { dateISO } from "utils/dateToISO";

import styles from "./SafeItem.module.sass";

const SafeItem = ({ item, type }: { item: ISafe; type: string }) => {
  const { __ } = useLocales();
  const { userInfo } = useUserSelectors();
  const { onSetSafeModal, onSetTopMessageModal } = useActions();

  const navigate = useNavigate();
  const { safeList, authorized } = useSafeSelectors();

  const handleClick = (): void => {
    if (!userInfo.tel) {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: __("Подтвердите основной телефон") });
      return;
    }
    const isAuthorized = authorized.find((el) => el.id_safe === item.id);
    if (isAuthorized) {
      navigate(item.id);
    } else {
      const safe = safeList.find((safe) => safe.id === item.id);
      if (safe.time_blocked && new Date(dateISO(safe.time_blocked)).getTime() > Date.now()) {
        onSetSafeModal({
          open: true,
          type: SAFE_MODALS.SAFE_BLOKED,
          safe
        });
        return;
      }
      onSetSafeModal({
        open: true,
        type: SAFE_MODALS.ENTER_SAFE_PASSWORD,
        safe,
        callbackType: SAFE_CALLBACK_MODAL.FETCH_FILES,
        alert: safe.is_system ? __("Введите пароль который используется для доступа к 4HUB") : undefined
      });
    }
  };

  return (
    <div className={classNames(styles.container, styles[`container_${type}`])} onClick={handleClick}>
      {!item.is_my && (
        <div className={styles.share}>
          <ShareIcon />
        </div>
      )}
      <SafeIcon
        fill={item.is_system ? "#DF9A14" : "#5379FF"}
        width={type === "normal" ? 24 : 54}
        height={type === "normal" ? 24 : 54}
      />
      <div className={styles.title}>{item.name}</div>
    </div>
  );
};

export default SafeItem;
