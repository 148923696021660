import { useDateFormat, VariantDate } from "hooks/useDateFormat";
import { FC } from "react";

import { ICommonData } from "../../models";
import styles from "./GantHeader.module.sass";

interface IProps {
  commonData: ICommonData;
  format: VariantDate;
}

export const GantHeader: FC<IProps> = ({ commonData, format }) => {
  const { formatDate } = useDateFormat();

  return (
    <div className={styles.header}>
      <svg width={commonData.gantWidth} height="38">
        {Object.entries(commonData.blocks).map(([key, values], idx) => (
          <g key={key}>
            <text
              x={commonData.blockWidth / 2 + idx * commonData.blockWidth}
              y="14"
              textAnchor="middle"
              fontSize="12"
              fill="#274A42"
            >
              {formatDate(+key, format)}
            </text>
            {values.map((item) => (
              <text key={item.value} x={item.x} y="30" textAnchor="middle" fontSize="12" fill={item.fill ?? "#274A42"}>
                {item.name}
              </text>
            ))}
            <path
              d={`M${(idx + 1) * commonData.blockWidth},0 L${(idx + 1) * commonData.blockWidth},38`}
              stroke="#CFD7D5"
              strokeWidth="1"
            />
          </g>
        ))}
      </svg>
    </div>
  );
};
