import { ReactComponent as Question } from "assets/icons/question.svg";
import CustomTooltip from "generalComponents/CustomTooltip/CustomTooltip";
import { PlacesType } from "react-tooltip";

import styles from "./IconTooltip.module.sass";

const IconTooltip = ({
  children,
  bgColor,
  textColor,
  title,
  text,
  onClick = false,
  place,
  id
}: {
  children?: React.ReactNode;
  bgColor?: "orange" | "grey";
  textColor?: string;
  title?: string;
  text?: string;
  onClick?: boolean;
  place?: PlacesType;
  id?: string;
}) => {
  return (
    <>
      <CustomTooltip
        id={id ? id : "tooltip"}
        textColor={textColor}
        bgColor={bgColor}
        title={title}
        text={text}
        onClick={onClick}
        place={place}
      />
      <span className={styles.icon} data-tooltip-id={id ? id : "tooltip"}>
        {children ? children : <Question />}
      </span>
    </>
  );
};

export default IconTooltip;
