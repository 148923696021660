export enum PROJECT_MODALS {
  NO_MODAL = "",
  PROJECT_SUCCESS = "projectSuccess",
  ADD_PROJECT = "addProject",
  EDIT_PROJECT = "editProject",
  DEL_PROJECT = "delProject",
  AVATAR_WAY = "avatarWay",
  CHOOSE_AVATAR = "chooseAvatar",
  AVATAR_CROP = "avatarCrop",
  ADD_ROLES = "addRoles",
  CREATE_ROLE = "createRole",
  EDIT_ROLE = "editRole",
  ADDED_ROLES = "addedRoles",
  ADD_USERS = "addUsers",
  APPOINT_ROLES = "appointRoles",
  ADDED_USERS = "addedUsers",
  EDIT_USER = "editUser",
  ADD_TASK = "addTask",
  EDIT_TASK = "editTask",
  TASK_TO_TRASH = "taskToTrash",
  DEL_TASK = "delTask",
  OPEN_TASK = "openTask",
  ADD_CATEGORY = "addCategory",
  EDIT_CATEGORY = "editCategory",
  DEL_CATEGORY = "delCategory",
  ADD_EXECUTORS = "addExecutors",
  LEAVE_PROJECT = "leaveProject",
  CHOOSE_ADMIN = "chooseAdmin",
  ADDED_ADMIN = "addedAdmin",
  APPOINT_SUBADMIN = "appointSubAdmin",
  CHOOSE_SUBADMIN = "chooseSubadmin",
  MESSAGE_ADMIN = "messageAdmin",
  CLEAR_TRASH = "clearTrash",
  EXTEND_PERIOD = "extendPeriod",
  TASK_HISTORY = "taskHistory",
  USER_INFO = "userInfo",
  ADD_LINK = "addLink",
  EDIT_LINK = "editLink",
  WARNING = "warning",
  ADD_SECTION = "addSection",
  EDIT_SECTION = "editSection"
}

export enum PROJECT_CONTEXT_MENU {
  DEL_PROJECT = "DEL_PROJECT",
  EDIT_PROJECT = "EDIT_PROJECT",
  DEL_ROLE = "delRole",
  EDIT_ROLE = "editRole",
  DEL_USER = "delUser",
  ADD_ROLE = "addRole",
  ADD_USER = "addUser",
  ADD_TASK = "addTask",
  USER_INFO = "userInfo",
  APPOINT_OF_ADMIN = "appointOfAdmin",
  EDIT_CATEGORY = "editCategory",
  OPEN_TASK = "openTask",
  EDIT_TASK = "editTask",
  COPY_LINK = "copyLink",
  DEL_TASK = "delTask",
  RESTORE_TASK = "restoreTask",
  TASK_TO_TRASH = "taskToTrash",
  ADD_URGENT = "addUrgent",
  DEL_URGENT = "delUrgent",
  LEAVE_PROJECT = "leaveProject",
  DEL_SUBADMIN = "delSubAdmin",
  APPOINT_SUBADMIN = "appointSubAdmin",
  DELETE_CATEGORY = "delCategory",
  TASK_HISTORY = "taskHistory",
  MOVE_TO_ARCHIVE = "moveToArchive",
  MOVE_TO_BACKLOG = "moveToBacklog",
  ADD_FOLDER = "addFolder",
  EDIT_FOLDER = "editFolder",
  DEL_FOLDER = "delFolder",
  EDIT_FILE = "editFile",
  DEL_FILE = "delFile"
}

export enum PROJECT_ADMIN_ROLES {
  ADMIN = "1",
  SUBADMIN = "2"
}

export enum GARBAGE_TABS {
  TASK = "TASK",
  FILES = "FILES"
}
/**
 * @deprecated use hook useDateFormat
 */
export enum PROJECT_PATH {
  TASKS = "tasks",
  MY_TASKS = "myTask",
  BACKLOG = "backlog",
  HISTORY = "history",
  TEAM = "team",
  TRASH = "trash",
  ANALYTICS = "analytics",
  GANT = "gant",
  LINKS = "links",
  LIBRARY = "library",
  FILES = "files",
  DOC = "doc",
  SETTINGS = "settings",
  TASK = "task"
}

export enum PROJECT_CATEGORIES {
  PENDING = "91",
  IN_WORK = "81",
  DONE = "80",
  NOT_IMPLEMENTED = "90"
}
