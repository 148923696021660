import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import { ReactComponent as CrossInCircle } from "assets/PrivateCabinet/mail/cross-in-circle.svg";
import { ReactComponent as UploadIcon } from "assets/PrivateCabinet/upload.svg";
import classNames from "classnames";
import File from "generalComponents/Files";
import { PropTypes } from "prop-types";
import React, { useState } from "react";

import styles from "./Carousel.module.sass";
import CarouselWrapper from "./CarouselWrapper";

function Carousel({ files, setFiles, downloadFile, deleteFile, noDownloadIcon, noDeleteIcon }) {
  const [showMenu, setShowMenu] = useState(false);

  const deleteUploadFile = (index, file) => {
    if (deleteFile) {
      return deleteFile(file, index);
    }

    const newArr = files.filter((_, i) => {
      return i !== index;
    });
    setFiles(newArr);
  };

  const downloadUploadFile = (file) => {
    if (!downloadFile) {
      return;
    }
    downloadFile(file);
  };

  if (!files?.length) return null;

  return (
    <CarouselWrapper>
      {files.map((file, i) => {
        const format = !file.name ? (file.filename ? file.filename.split(".")[1] : "") : file.name.split(".")[1];
        const filename = !file.name
          ? file.filename
            ? file.filename.split(".")[0]
            : undefined
          : file.name.split(".")[0];

        return (
          <div
            key={i}
            className={classNames(styles.fileIcon)}
            title={filename}
            onMouseEnter={() => setShowMenu(i)}
            onMouseLeave={() => setShowMenu(null)}
          >
            {setFiles && (
              <div className={styles.delFile} onClick={() => deleteUploadFile(i)}>
                <CrossInCircle />
              </div>
            )}
            <File format={format} alt="file" />
            {showMenu === i && (
              <div
                className={classNames(styles.menu, {
                  [styles.noActionIcon]: noDeleteIcon || noDownloadIcon
                })}
              >
                {!noDownloadIcon && (
                  <div onClick={() => downloadUploadFile(file)}>
                    <UploadIcon className={styles.icon} />
                  </div>
                )}
                {!noDeleteIcon && (
                  <div>
                    <TrashIcon
                      className={styles.icon}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteUploadFile(i, file);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </CarouselWrapper>
  );
}

export default Carousel;

Carousel.defaultProps = {
  noDownloadIcon: false,
  noDeleteIcon: false
};

Carousel.propTypes = {
  files: PropTypes.oneOfType([PropTypes.arrayOf, PropTypes.arrayOf]),
  setFiles: PropTypes.func,
  downloadFile: PropTypes.func,
  deleteFile: PropTypes.func,
  noDownloadIcon: PropTypes.bool,
  noDeleteIcon: PropTypes.bool
};
