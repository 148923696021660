import { ReactComponent as CheckMarkIcon } from "assets/PrivateCabinet/check-mark.svg";
import classNames from "classnames";
import React, { FC, MouseEvent } from "react";

import styles from "./CheckBoxNew.module.sass";

interface Props {
  isChecked?: boolean;
  setIsChecked?: (e: MouseEvent<HTMLDivElement>) => void;
  padding?: number | string;
}

const CheckBoxNew: FC<Props> = ({ isChecked, setIsChecked, padding }) => {
  return (
    <div className={styles.wrap} style={{ padding }} onClick={setIsChecked}>
      <div
        className={classNames(styles.checkBox, {
          [styles.checked]: isChecked
        })}
      >
        <CheckMarkIcon />
      </div>
    </div>
  );
};

export default CheckBoxNew;
