import { initialNotificationsState, INotificationsState } from "models/store/notifications/notifications";
import { NotificationsAction } from "models/store/notifications/notificationsActions";
import { NotificationsTypes } from "Store/types/notificationsTypes";

const INITIAL_STATE: INotificationsState = initialNotificationsState();

export const NotificationsReducer = (state = INITIAL_STATE, action: NotificationsAction): INotificationsState => {
  switch (action.type) {
    case NotificationsTypes.GET_ALL_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload]
      };
    }
    case NotificationsTypes.DELAY_NOTIFICATION:
    case NotificationsTypes.DELETE_NOTIFICATION: {
      const newNotifications = state.notifications.filter((item) => "id" in item && item.id !== action.payload.id);
      return {
        ...state,
        notifications: newNotifications
      };
    }
    case NotificationsTypes.HIDDEN_NOTIFICATION: {
      const newNotifications = [...state.notifications];
      const index = newNotifications.findIndex((item) => "id" in item && item.id === action.payload.id);
      newNotifications.splice(index, 1, action.payload);
      return {
        ...state,
        notifications: newNotifications
      };
    }
    case NotificationsTypes.LIST_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      };
    }
    case NotificationsTypes.EDIT_NOTIFICATION_CHAT: {
      return {
        ...state,
        notifications: action.payload
      };
    }
    case NotificationsTypes.GET_ALL_CALL_ROOM_INVITATIONS: {
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload]
      };
    }

    default:
      return state;
  }
};
