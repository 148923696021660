import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { useDateFormat } from "hooks/useDateFormat";
import { FC } from "react";

import styles from "./LibraryFolderInfo.module.sass";

interface ILibraryFolderInfo {
  prim: string;
  ctime: string;
}

export const LibraryFolderInfo: FC<ILibraryFolderInfo> = ({ prim, ctime }): JSX.Element => {
  const { formatDate } = useDateFormat();

  return (
    <div className={styles.content}>
      <p className={styles.content__text}>{prim}</p>
      <span className={styles.content__date}>
        <ClockIcon width={15} height={15} fill="#56716B" />
        <span>{formatDate(ctime, "full")}</span>
      </span>
    </div>
  );
};
