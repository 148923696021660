import classNames from "classnames";
import { isToday } from "date-fns";
import {
  getToday,
  useDaysOfWeeks,
  useGenerateCalendarWeeks,
  useMonths
} from "generalComponents/Calendars/CalendarHelper";
import { createTasksObj, useTasksCalendar } from "generalComponents/Services/calendarPageServices";
import { TaskFilters } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { ICalendarDay, TYPE_DAY } from "models/generalComponents/calendars";
import { IMyTask } from "models/store/tasks/tasks";
import React, { useMemo } from "react";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import styles from "./YearView.module.sass";

const YearView = () => {
  const months = useMonths();
  const daysOfWeeks = useDaysOfWeeks();
  const weeksOfMonth = useGenerateCalendarWeeks();
  const { tasks, calls, meetings, mails, reminder } = useTasksCalendar();
  const {
    filters: { date }
  } = useTasksSelectors();
  const { onSelectFilterType, onSelectFilterDate } = useActions();

  const objTasks: Record<string, IMyTask[]> = useMemo(() => createTasksObj(tasks), [tasks]);
  const objMeetings: Record<string, IMyTask[]> = useMemo(() => createTasksObj(meetings), [meetings]);
  const objCalls: Record<string, IMyTask[]> = useMemo(() => createTasksObj(calls), [calls]);
  const objMails: Record<string, IMyTask[]> = useMemo(() => createTasksObj(mails), [mails]);
  const objReminder: Record<string, IMyTask[]> = useMemo(() => createTasksObj(reminder), [reminder]);

  const isCurrentMonth = (month: number): boolean => {
    return getToday().year === date.year && getToday().month === month;
  };

  const isCurrentWeekDay = (i: number): boolean => {
    const current = new Date().getDay();
    if (i === 6) {
      return current === 0;
    }
    return i + 1 === current;
  };

  const selectMonth = (month: number): void => {
    onSelectFilterDate({ ...date, month });
    onSelectFilterType(TaskFilters.BY_MONTH);
  };
  const selectDay = (day: number, month: number): void => {
    onSelectFilterDate({ ...date, month, day });
    onSelectFilterType(TaskFilters.BY_DAY);
  };
  const tasksView = (day: number, month: number): JSX.Element => {
    const time = new Date(date.year, month, day).getTime();
    return (
      <div className={styles.tasksColor}>
        {objTasks[time]?.length && <div className={classNames(styles.color, styles.task)} />}
        {objMeetings[time]?.length && <div className={classNames(styles.color, styles.meeting)} />}
        {objCalls[time]?.length && <div className={classNames(styles.color, styles.call)} />}
        {objMails[time]?.length && <div className={classNames(styles.color, styles.mail)} />}
        {objReminder[time]?.length && <div className={classNames(styles.color, styles.reminder)} />}
      </div>
    );
  };

  const renderDay = (week: ICalendarDay[], month: number): JSX.Element[] => {
    return week.map((day, idx) => {
      return (
        <div
          key={idx}
          className={classNames(styles.dayBox, {
            [styles.hidden]: day.typeDay !== TYPE_DAY.CURRENT,
            [styles.light]: idx > 4
          })}
        >
          <div
            className={classNames(styles.numDay, { [styles.today]: isToday(new Date(date.year, month, day.value)) })}
            onClick={() => selectDay(day.value, month)}
          >
            {day.value}
          </div>
          {tasksView(day.value, month)}
        </div>
      );
    });
  };

  const renderWeek = (weeks: ICalendarDay[][], month: number): JSX.Element[] => {
    return weeks.map((week, i) => (
      <div key={i} className={classNames(styles.weekRow, styles.weekLine)}>
        {renderDay(week, month)}
      </div>
    ));
  };

  const renderMonth = (month: number): JSX.Element => {
    const monthDate = { year: date.year, month };
    const weeks = weeksOfMonth(monthDate);
    return <>{renderWeek(weeks, month)}</>;
  };

  return (
    <div className={styles.wrap}>
      {months(date.year).map((item) => {
        return (
          <div key={item.id} className={classNames(styles.month, { [styles.currentMonth]: isCurrentMonth(item.id) })}>
            <p className={styles.monthTitle} onClick={() => selectMonth(item.id)}>
              {item.name}
            </p>
            <div className={styles.calendarBox}>
              <div className={styles.weekRow}>
                {daysOfWeeks.short.map((el, i) => (
                  <div
                    key={el}
                    className={classNames(styles.weekDay, {
                      [styles.currentWeekDay]: isCurrentWeekDay(i)
                    })}
                  >
                    {el}
                  </div>
                ))}
              </div>
              <div className={styles.daysBlock}>{renderMonth(item.id)}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default YearView;
