import { ReactComponent as SendIcon } from "assets/icons/arrow-send.svg";
import { ReactComponent as ClipIcon } from "assets/icons/clip.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Button from "generalComponents/Button/Button";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IFile } from "models/store/files/files";
import { ChangeEvent, Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { Editor, EditorState as IEditorState } from "react-draft-wysiwyg";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { v4 as uuidv4 } from "uuid";

import styles from "./TaskCommentEditor.module.sass";

interface IProps {
  prim?: string;
  setPrim?: (val: string) => void;
  setUploadFiles: Dispatch<SetStateAction<(IFile | { file: File; fid: string })[]>>;
  onSend: (v: string) => void;
  setEditItem: (v: string) => void;
}

const TaskCommentEditor: FC<IProps> = ({ prim, setUploadFiles, onSend, setEditItem }) => {
  const { __ } = useLocales();
  const { onSetSelectFileModal } = useActions();
  const [show, setShow] = useState<boolean>(false);
  const { project } = useJoinProjectsSelectors();
  const [editorState, setEditorState] = useState<IEditorState>(
    prim
      ? () => {
          const blocksFromHTML = convertFromHTML(
            prim.replace(/<a\s+(?:[^>]*\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/gi, function (match, href, content) {
              return href.trim().toLowerCase() !== "#" ? content : match;
            })
          );
          const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );

          return EditorState.createWithContent(contentState);
        }
      : EditorState.createEmpty()
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const onEditorStateChange = (editorState: IEditorState) => {
    setEditorState(editorState);
  };

  const handleSend = () => {
    const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const processedHtml = text
      .replace(/(https?:\/\/[^\s<]+)/g, (match: string) => `<a href="${match}" target="_blank">${match}</a>`)
      .replace(/<a\s+href="#"/g, '<a href="#" onclick="event.preventDefault();"')
      .replace(
        /(<a[^>]*>.*?@.*?<\/a>)|((?<=\s|>)(?<!<\/\w*>)@(.*?)(?=\s|<|$))/g,
        (match, insideATag, _, notInsideATag) => {
          if (insideATag) {
            return match; // Не изменять, если @someText уже внутри тега <a></a>
          } else {
            return `<a href="#" onclick="event.preventDefault();">@${notInsideATag}</a>&nbsp;`;
          }
        }
      );
    onSend(processedHtml);
  };

  const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = [...Array.from(e.target.files)].map((file) => ({ file, fid: uuidv4() }));
    setUploadFiles((prev) => [...prev, ...files]);
  };

  const fileSelect = () => {
    inputRef.current.click();
    setShow(false);
  };

  const renderBtnFiles = () => {
    return (
      <PopoverOverlay
        show={show}
        setShow={setShow}
        overlayContent={
          <ul>
            <li className={styles.item} onClick={fileSelect}>
              {__("Локальные файлы")}
            </li>
            <li
              className={styles.item}
              onClick={() => {
                onSetSelectFileModal({
                  open: true,
                  callback: (files: IFile[]) => setUploadFiles((prev) => [...prev, ...files])
                });
                setShow(false);
              }}
            >
              {__("Файлы с 4HUB")}
            </li>
          </ul>
        }
        placement="top"
      >
        <span className={styles.clip}>
          <ClipIcon fill="var(--color-d800)" />
        </span>
      </PopoverOverlay>
    );
  };

  const renderBtnSend = () => {
    return (
      <div className={styles.sendBtns}>
        <Button
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.EXTRA_SMALL}
          iconL={<CrossIcon />}
          onClick={() => setEditItem("")}
          full
        />
        <Button
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.EXTRA_SMALL}
          iconL={<SendIcon />}
          onClick={handleSend}
          full
        />
      </div>
    );
  };

  const handleSuggestionsList = () => {
    const arr = project.participants.map((el) => ({
      text: `${el.fname} ${el.sname}`,
      value: el.fname && el.sname ? `${el.fname}_${el.sname}` : `${el.fname}` || `${el.sname}`,
      url: "#"
    }));
    return arr;
  };

  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName={styles.toolbar}
        wrapperClassName={styles.wrapper}
        editorClassName={styles.editor}
        onEditorStateChange={onEditorStateChange}
        toolbarCustomButtons={[renderBtnFiles(), renderBtnSend()]}
        // editorRef={setEditorReference}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: handleSuggestionsList()
        }}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "embedded",
            "emoji",
            "image",
            "remove",
            "history"
          ],
          image: {
            previewImage: true
          }
        }}
      />
      <input ref={inputRef} className={styles.hidden} type="file" multiple onChange={uploadImage} />
    </>
  );
};

export default TaskCommentEditor;
