//CALENDAR && TASKS Component
export const TASK = {
  API_GET_TASKS: "task_get",
  API_GET_TASKS_CALENDAR: "task_calendar"
};

export enum CALENDAR_MODALS {
  EDIT_EVENTS = "EditEvents",
  CHANGE_EVENTS = "ChangeEvents",
  SUCCESS_ADD = "SuccessAdd",
  CATEGORIES = "Сategories"
}

// MY TASKS
export enum TASK_TYPES {
  TASK = "task",
  ONLINE_MEETING = "onlineMeetings",
  OFFLINE_MEETING = "offlineMeetings",
  CALLS = "calls",
  MAILS = "mails",
  REMINDER = "reminder",
  MEETINGS = "meetings",
  NOTES = "notes"
}

export enum URGENCY_TYPES {
  URGENT = "urgent",
  PLANNED = "planned"
}

export enum STATUS_TYPES {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_DONE = "NOT_DONE",
  QUEUE = "QUEUE"
}

export enum BOARDS {
  MEETINGS = "MEETINGS",
  CALLS = "CALLS",
  MAILS = "MAILS",
  TASK = "TASK"
}

export enum TASKS_SCHEMA {
  GRID_BAR = "grid-bar",
  EXPANDED_MEETINGS_BOARD = "expanded-meetings-board",
  EXPANDED_CALLS_BOARD = "expanded-calls-board",
  EXPANDED_MAIL_BOARD = "expanded-mail-board",
  EXPANDED_TASKS_BOARD = "expanded-tasks-board"
}

export enum TASK_VIEW {
  LINE = "LINE",
  KANBAN = "KANBAN"
}

export const STYLED_CLASSES: Record<TASKS_SCHEMA, string[]> = {
  [TASKS_SCHEMA.GRID_BAR]: ["medium-item", "small-item-left", "small-item-right", "big-item"],
  [TASKS_SCHEMA.EXPANDED_MEETINGS_BOARD]: ["expanded-board", "hidden", "hidden", "hidden"],
  [TASKS_SCHEMA.EXPANDED_CALLS_BOARD]: ["hidden", "expanded-board", "hidden", "hidden"],
  [TASKS_SCHEMA.EXPANDED_MAIL_BOARD]: ["hidden", "hidden", "expanded-board", "hidden"],
  [TASKS_SCHEMA.EXPANDED_TASKS_BOARD]: ["hidden", "hidden", "hidden", "expanded-board"]
};

export enum LoadingState {
  LOADING = "LOADING",
  LOAD_NEXT_COLUMN = "next",
  IDLE = "IDLE"
}

export enum TASK_MODALS {
  NO_MODAL = "",

  ADD_NOTE = "AddNote",
  EDIT_NOTE = "EditNote",
  DEL_NOTE = "DeleteNote",
  OPEN_NOTE = "OpenNote",

  ADD_TASK = "AddTask",
  EDIT_TASK = "EditTask",

  ADD_MEETING = "AddMeeting",
  EDIT_MEETING = "EditMeeting",

  ADD_CALL = "AddCall",
  EDIT_CALL = "EditCall",

  ADD_LETTER = "AddLetter",
  EDIT_LETTER = "EditLetter",

  ADD_REMINDER = "AddReminder",
  EDIT_REMINDER = "EditReminder",

  ADD_COMMENT_TASK = "AddCommentTask",
  ADD_PASSWORD = "AddPassword",
  READ_PASSWORD = "ReadPassword",
  RECOVER_PASSWORD = "RecoverPassword",
  ENTER_CODE = "EnterCode",
  NEW_PASSWORD = "NewPassword",
  OPEN_TASK = "OpenTask"
}

export const contextMenuTask = {
  CUSTOMIZE: "customize",
  DELETE: "delete",
  ADD_COMMENT: "addComment",
  ADD_REMINDER: "reminder",
  RESCHEDULE_ONE: "rescheduleOne",
  RESCHEDULE_ALL: "rescheduleAll",
  ADD_MEETING_NOTE: "addMeetingNote",
  OPEN_TASK: "openTask"
};

export const taskDepartmentKey: string = "taskDepartment";

export enum TaskFilters {
  BY_DAY = "BY_DAY",
  BY_WEEK = "BY_WEEK",
  BY_MONTH = "BY_MONTH",
  BY_YEAR = "BY_YEAR"
}

export enum BTN_SWITCH {
  NEXT = "NEXT",
  PREV = "PREV"
}
