import Layout from "containers/Authentication/Layout/Layout";
import FirstStep from "containers/Authentication/SignUp/FirstStep/FirstStep";
import FourthStep from "containers/Authentication/SignUp/FourthStep/FourthStep";
import SecondStep from "containers/Authentication/SignUp/SecondStep/SecondStep";
import ThirdStep from "containers/Authentication/SignUp/ThirdStep/ThirdStep";
import { ROUTES } from "generalComponents/variables/routing";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

const SignUp = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const answers = location.state?.answers;
  const [step, setStep] = useState<number>(1);
  const [isSolo, seIsSolo] = useState<boolean>();
  const [login, setLogin] = useState("");
  const [uid, setUid] = useState("");

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    if (!answers) {
      navigate(`/${ROUTES.QUESTIONNAIRE}`);
    }
  }, [answers]); //eslint-disable-line

  return (
    <Layout>
      {step === 1 && <FirstStep isSolo={isSolo} setIsSolo={seIsSolo} handleNextStep={handleNextStep} />}
      {step === 2 && <SecondStep handleNextStep={handleNextStep} />}
      {step === 3 && (
        <ThirdStep handleNextStep={handleNextStep} login={login} setLogin={setLogin} isSolo={isSolo} setUid={setUid} />
      )}
      {step === 4 && <FourthStep login={login} uid={uid} answers={answers} />}
    </Layout>
  );
};

export default SignUp;
