import { ReactComponent as AddUserIcon } from "assets/PrivateCabinet/chat/addUser.svg";
import { ReactComponent as BrushIcon } from "assets/PrivateCabinet/chat/brush.svg";
import { ReactComponent as SwitchScreenIcon } from "assets/PrivateCabinet/chat/change-device.svg";
import { ReactComponent as CopyLinkIcon } from "assets/PrivateCabinet/chat/copy-link.svg";
import { ReactComponent as HandIcon } from "assets/PrivateCabinet/chat/hand.svg";
import { ReactComponent as HangUpIcon } from "assets/PrivateCabinet/chat/hangUp.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/chat/phone.svg";
import { ReactComponent as PrintScreenIcon } from "assets/PrivateCabinet/chat/print-screen.svg";
import { ReactComponent as ScreenSizeIcon } from "assets/PrivateCabinet/chat/screen-size.svg";
import { ReactComponent as VideoIcon } from "assets/PrivateCabinet/film.svg";
import { ReactComponent as ManUserIcon } from "assets/PrivateCabinet/manUser.svg";
import { ReactComponent as PhoneAcceptIcon } from "assets/PrivateCabinet/phone-5.svg";
import { ReactComponent as RadioIcon } from "assets/PrivateCabinet/radio-3.svg";
import OutgoingSound from "assets/sounds/chat/outgoing_call.mp3";
import IncomingSound from "assets/sounds/chat/ringtone.mp3";
import classNames from "classnames";
import ContextMenu from "generalComponents/ContextMenu";
import Button from "generalComponents/CustomableButton/CustomableButton";
import { useWebRTC } from "generalComponents/Hooks/WebRTC/useWebRTC";
import { useWebRTCSetConnectionServices } from "generalComponents/Hooks/WebRTC/useWebRTCSetConnectionServices";
import { useWebRTCSocketMessages } from "generalComponents/Hooks/WebRTC/useWebRTCSocketMessages";
import { useWebRTCContext } from "generalComponents/Hooks/WebRTC/WebRTCProvider";
import Loader from "generalComponents/Loaders/4HUB";
import PopUp from "generalComponents/PopUp/PopUp";
import { copyToClipboard } from "generalComponents/Services/browserServices";
import {
  CHAT_CALLROOM,
  CHAT_CALLROOM_ACTIONS,
  CHAT_MODALS,
  LOCAL_CLIENT,
  LOCAL_SCREEN_CLIENT,
  MEDIA_TRACK_KIND
} from "generalComponents/variables/chat";
import { BUTTON_TYPES } from "generalComponents/variables/customableButton";
import { MODALS } from "generalComponents/variables/global";
import { imageSrc } from "generalComponents/variables/globalVariables";
import { useWebSocketContext } from "generalComponents/WebSocketsProvider/WebSocketsProvider";
import { useActions } from "hooks/useActions";
import { LoaderTypes } from "models/generalComponents/loader";
import { initialCallRoomState } from "models/store/Cabinet/chat/chat";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import React, { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useChatSelectors } from "Store/selectors/chatSelectors";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import MiniCallRoom from "../MiniCallRoom/MiniCallRoom";
import styles from "./CallRoom.module.sass";
import { layout } from "./CallRoomService";

function CallRoom(): React.ReactElement {
  const { __ } = useLocales();
  const { callRoom, selectedContact } = useChatSelectors();
  const {
    uid,
    userInfo: { id, icon: iconLocalUser }
  } = useUserSelectors();
  const [fullScreen, setFullScreen] = useState<boolean>(true);
  const [isScreen, setIsScreen] = useState<boolean>(false);
  const {
    clients,
    provideMediaRef,
    closeCallRoom,
    switchScreen,
    provideScreenRef,
    provideMiniCallRoomRef,
    isLoadingUsers
  } = useWebRTC({ isScreen });
  const { localMediaStream, localScreenStream, peerScreenElements, sendSocket, hasCameraPermission } =
    useWebRTCContext();
  const { socket, sendMessage } = useWebSocketContext();
  const [mouseParams, setMouseParams] = useState(null);
  const { printScreen } = useGlobalModalsSelectors();
  const { setCallRoom, onSetPrintScreenModal, onSetTopMessageModal, onSetModals } = useActions();

  const { acceptCall, reconnectPeer, reloadMediaStream } = useWebRTCSetConnectionServices();

  useWebRTCSocketMessages(); // обработка всех входящих сокет сообщений

  // Запускает аудио в зависимости от типа исходящего звонка
  const setAudio = () => {
    switch (callRoom.state) {
      case CHAT_CALLROOM_ACTIONS.OUTGOING_CALL: {
        return OutgoingSound; // гудки
      }
      case CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL: {
        return OutgoingSound; // гудки
      }
      case CHAT_CALLROOM_ACTIONS.INCOMING_CALL: {
        return IncomingSound; // мелодия входящего звонка
      }
      default: {
        return null; // в противном случае ничего
      }
    }
  };
  const [audio] = useState(new Audio(setAudio())); // звук к воспроизведеню
  const videoLayout = layout(clients.length, isScreen); // разметка видео элементов в компоненте

  useEffect(() => {
    // измнение вида отображения в зависимости от того, приисходит в данный момент расшаривание или нет
    const screens = Object.values(callRoom.connectedUsers)
      .map((it) => it.media.screen)
      .filter((it) => it === true);
    screens.length > 0 ? setIsScreen(true) : setIsScreen(false);
  }, [callRoom.connectedUsers, isScreen]);

  // рассылка сообщения об отмене звонка и закрытие комнаты
  const cancelCall = (): void => {
    if (
      callRoom.state === CHAT_CALLROOM_ACTIONS.OUTGOING_CALL ||
      callRoom.state === CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL
    ) {
      if (window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL]) {
        // прекращение исходящего вызова
        clearTimeout(window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL]);
      }
      // рассылка сообщения об отмене звонка и закрытие комнаты
      sendMessage(
        JSON.stringify({
          action: callRoom.connectedUsers.length === 1 ? CHAT_CALLROOM_ACTIONS.STOP_CALL : CHAT_CALLROOM_ACTIONS.LEAVE,
          room_id: callRoom.room_id,
          from: id,
          users_to: callRoom.contacts.filter((it) => it !== callRoom.user_id)
        })
      );
      // закрытие комнаты
      setCallRoom(initialCallRoomState());
    } else {
      closeCallRoom();
    }
  };

  // отправка сообщение другим пользователям о состоянии руки
  const handleRiseHand = () => {
    const user = callRoom.connectedUsers.find((it) => it.id_user === id);
    sendMessage(
      JSON.stringify({
        action: CHAT_CALLROOM_ACTIONS.RISE_HAND,
        room_id: callRoom.room_id,
        uid,
        from: { ...user, media: { ...user.media }, handUp: !user.handUp }
      })
    );
  };

  // рендеринг вида длительного звонка
  const renderCallOngoing = () => (
    <>
      {callRoom.state !== CHAT_CALLROOM.JOINED ? (
        <div className={styles.receiver}>
          {__("Вызов")} {callRoom.contacts.length === 1 ? `${selectedContact.name}` : ""}
        </div>
      ) : null}
      {callRoom.state !== CHAT_CALLROOM.JOINED ? (
        <div className={classNames("flex-center", styles.avatarWrap)}>
          <img
            className={styles.avatar}
            src={callRoom.icon ?? `${imageSrc}/assets/PrivateCabinet/profile-noPhoto.svg`}
            alt="img"
          />
        </div>
      ) : null}
      <div
        className={classNames(styles.buttonsPanel, {
          [styles.centeredButton]: callRoom.state !== CHAT_CALLROOM.JOINED
        })}
      >
        {callRoom.state === CHAT_CALLROOM.JOINED ? (
          <Button style={BUTTON_TYPES.ROUND_WHITE} onClick={() => setFullScreen(false)} disabled={socket() === null}>
            <ScreenSizeIcon />
          </Button>
        ) : null}
        {callRoom.state === CHAT_CALLROOM.JOINED ? (
          <Button
            style={localScreenStream.current ? BUTTON_TYPES.ROUND_RED : BUTTON_TYPES.ROUND_GREY}
            disabled={socket() === null}
            onClick={switchScreen}
          >
            <SwitchScreenIcon />
            {localScreenStream.current ? <div className={styles.slash} /> : null}
          </Button>
        ) : null}

        {callRoom.state === CHAT_CALLROOM.JOINED ? (
          <Button
            style={
              callRoom.connectedUsers.find((it) => it.id_user === id)?.media.video
                ? BUTTON_TYPES.ROUND_GREY
                : BUTTON_TYPES.ROUND_RED
            }
            onClick={() => handleSwitchMedia(MEDIA_TRACK_KIND.VIDEO)}
            disabled={socket() === null}
          >
            <VideoIcon className={styles.cameraIcon} />
            {callRoom.connectedUsers.find((it) => it.id_user === id)?.media.video ? null : (
              <div className={styles.slash} />
            )}
          </Button>
        ) : null}
        {callRoom.state === CHAT_CALLROOM.JOINED ? (
          <Button
            style={
              callRoom.connectedUsers.find((it) => it.id_user === id)?.media.audio
                ? BUTTON_TYPES.ROUND_GREY
                : BUTTON_TYPES.ROUND_RED
            }
            onClick={() => handleSwitchMedia(MEDIA_TRACK_KIND.AUDIO)}
            disabled={socket() === null}
          >
            <RadioIcon />
            {callRoom.connectedUsers.find((it) => it.id_user === id)?.media.audio ? null : (
              <div className={styles.slash} />
            )}
          </Button>
        ) : null}
        {callRoom.state === CHAT_CALLROOM.JOINED ? (
          <Button
            style={
              callRoom.connectedUsers.find((it) => it.id_user === id)?.handUp
                ? BUTTON_TYPES.ROUND_GREEN
                : BUTTON_TYPES.ROUND_GREY
            }
            onClick={handleRiseHand}
          >
            <HandIcon className={styles.phoneIcon} />
          </Button>
        ) : null}
        {callRoom.state === CHAT_CALLROOM.JOINED ? (
          <Button
            style={BUTTON_TYPES.ROUND_GREY}
            onClick={(e) =>
              setMouseParams({
                x: e.clientX,
                y: e.clientY,
                width: 240,
                height: 25
              })
            }
          >
            <div className={styles.threeDots} />
            <div className={styles.threeDots} />
            <div className={styles.threeDots} />
          </Button>
        ) : null}
        <Button style={BUTTON_TYPES.ROUND_RED} onClick={cancelCall} disabled={socket() === null}>
          <HangUpIcon />
        </Button>
      </div>
      <div className={styles.paintButtons}>
        {callRoom.state === CHAT_CALLROOM.JOINED ? (
          <Button
            style={BUTTON_TYPES.TRANSPARENT}
            disabled={printScreen.open}
            onClick={() => {
              onSetPrintScreenModal({ ...printScreen, open: true });
            }}
          >
            <PrintScreenIcon />
          </Button>
        ) : null}
        {callRoom.state === CHAT_CALLROOM.JOINED ? (
          <Button
            style={BUTTON_TYPES.TRANSPARENT}
            disabled={false}
            onClick={() => {
              const user = callRoom.connectedUsers.find((it) => it.id_user === id);
              sendSocket({
                action: CHAT_CALLROOM_ACTIONS.OPEN_PAINT_ROOM,
                from: { ...user, media: { ...user.media } }
              });
            }}
          >
            <BrushIcon />
          </Button>
        ) : null}
      </div>
      {callRoom.state === CHAT_CALLROOM.VIDEO_CALL ? (
        <video ref={(instance) => provideMediaRef(LOCAL_SCREEN_CLIENT, instance)} autoPlay playsInline muted={true} />
      ) : null}
    </>
  );

  // рендеринг вида отображения входящего звонка
  const renderIncomingCall = (): JSX.Element => (
    <>
      <div className={styles.receiver}>{__("Входящий звонок")}</div>
      <div className={styles.avatarWrap}>
        <img
          className={styles.avatar}
          src={callRoom.icon ?? `${imageSrc}/assets/PrivateCabinet/profile-noPhoto.svg`}
          alt="img"
        />
      </div>
      <div
        className={classNames(styles.buttonsPanelIncomingCall, {
          "justify-content-center": !hasCameraPermission
        })}
      >
        {hasCameraPermission && (
          <Button style={BUTTON_TYPES.ROUND_GREEN} onClick={acceptCall} disabled={socket() === null}>
            <PhoneAcceptIcon className={styles.phoneAcceptIcon} />
          </Button>
        )}
        <Button style={BUTTON_TYPES.ROUND_RED} onClick={closeCallRoom} disabled={socket() === null}>
          <HangUpIcon />
        </Button>
      </div>
    </>
  );

  // фукция изменения медиа (аудио, видео, экран)
  const handleSwitchMedia = (mediaKind: MEDIA_TRACK_KIND): void => {
    const user = callRoom.connectedUsers.find((it) => it.id_user === id);
    localMediaStream.current.getTracks().forEach((it) => (it.kind === mediaKind ? (it.enabled = !it.enabled) : it));
    sendMessage(
      JSON.stringify({
        action: CHAT_CALLROOM_ACTIONS.SWITCH_VIDEO,
        room_id: callRoom.room_id,
        uid,
        from: { ...user, media: { ...user.media, [mediaKind]: !user.media[mediaKind] } }
      })
    );
  };

  // Проигрывание аудио дорожки при звонке
  useEffect(() => {
    if (
      callRoom.state === CHAT_CALLROOM_ACTIONS.OUTGOING_CALL ||
      callRoom.state === CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL ||
      callRoom.state === CHAT_CALLROOM_ACTIONS.INCOMING_CALL
    ) {
      audio.muted = true;
      audio.muted = false;
      audio.play();
    } else {
      audio.pause();
    }
    if (window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL] && callRoom.state !== CHAT_CALLROOM_ACTIONS.OUTGOING_CALL) {
      clearInterval(window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL]);
    }
    if (
      window[CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL] &&
      callRoom.state !== CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL
    ) {
      clearInterval(window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL]);
    }
  }, [callRoom.state, audio]);

  // остановка проигрывание музыки и начало звонка в случае ответа хотя бы одного пользователя
  useEffect(() => {
    return () => {
      audio.pause();
      if (window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL] || window[CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL]) {
        clearInterval(window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL]);
      }
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (callRoom.action === CHAT_CALLROOM_ACTIONS.ACCEPT_CALL && localMediaStream.current) {
      acceptCall();
    }
    //eslint-disable-next-line
  }, [localMediaStream.current]);

  // Переключение видео
  const handleVideoDisplay = (clientId: string, type: MEDIA_TRACK_KIND): "none" | "block" => {
    clientId = clientId === LOCAL_CLIENT ? id : clientId;
    const user = callRoom.connectedUsers.find((it) => it.id_user === clientId);
    if (user !== undefined) {
      return user?.media[type] ? "block" : "none";
    }
    return callRoom.state === CHAT_CALLROOM_ACTIONS.INCOMING_CALL || callRoom.callType === CHAT_CALLROOM.VOICE_CALL
      ? "none"
      : "block";
  };

  // возвращает путь к изображению пользователя
  const findIconSrc = (clientID: string): string => {
    if (clientID === LOCAL_CLIENT) {
      return iconLocalUser[0] ? `${imageSrc}${iconLocalUser[0]}` : "";
    }
    return callRoom.connectedUsers.find((it) => it.id_user === clientID)?.icon;
  };

  // скопировать ссылку на текущий звонок
  const copyLink = (): void => {
    const link = `${window.location.origin}/chat-page?type=CALLROOM&action=${CHAT_CALLROOM_ACTIONS.ACCEPT_CALL}${
      callRoom.id_group ? `&id_group=${callRoom.id_group}` : ""
    }&room_id=${callRoom.room_id}`;
    copyToClipboard(link);
    onSetTopMessageModal({
      open: true,
      type: ITopMessageTypes.MESSAGE,
      message: __("Ссылка скопирована")
    });
  };

  return (
    <>
      <PopUp set={closeCallRoom} display={fullScreen ? "" : "none"}>
        <div
          className={classNames(styles.callRoom, {
            [styles.videoCallRoom]: true
          })}
        >
          {callRoom.state === CHAT_CALLROOM_ACTIONS.INCOMING_CALL
            ? callRoom.action === CHAT_CALLROOM_ACTIONS.ACCEPT_CALL
              ? null
              : renderIncomingCall()
            : renderCallOngoing()}
          <div className={styles.videoWrap}>
            <div className={styles.videoInnerWrap}>
              <div
                className={classNames({
                  [styles.noScreen]: !isScreen,
                  [styles.isScreen]: isScreen
                })}
              >
                {/*редеринг экранов*/}
                {clients.map((clientID, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: peerScreenElements.current[clientID]?.srcObject ? "block" : "none"
                      }}
                      className={classNames(styles.video, {
                        [styles.background]: callRoom.state !== CHAT_CALLROOM_ACTIONS.INCOMING_CALL
                      })}
                    >
                      <video
                        ref={(instance) => provideScreenRef(clientID, instance)}
                        autoPlay
                        playsInline
                        muted
                        style={{
                          display: handleVideoDisplay(clientID, MEDIA_TRACK_KIND.SCREEN),
                          objectFit: "contain"
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div
                className={classNames({
                  [styles.videoLeftSide]: isScreen,
                  [styles.fullPage]: !isScreen
                })}
              >
                {/*рендеринг видеоизображений пользователей*/}
                {clients.map((clientID, i) => {
                  return (
                    <div
                      key={i}
                      style={videoLayout[i]}
                      className={classNames(styles.video, {
                        [styles.background]: callRoom.state !== CHAT_CALLROOM_ACTIONS.INCOMING_CALL
                      })}
                    >
                      {callRoom.state === CHAT_CALLROOM_ACTIONS.INCOMING_CALL ? null : findIconSrc(clientID) ? (
                        <img
                          src={findIconSrc(clientID)}
                          alt="avatar"
                          className={styles.image}
                          style={{
                            display: handleVideoDisplay(clientID, MEDIA_TRACK_KIND.VIDEO) !== "block" ? "block" : "none"
                          }}
                        />
                      ) : (
                        <ManUserIcon className={styles.noUserIcon} />
                      )}
                      <video
                        ref={(instance) => provideMediaRef(clientID, instance)}
                        autoPlay
                        playsInline
                        muted={clientID === LOCAL_CLIENT}
                        style={{
                          display: handleVideoDisplay(clientID, MEDIA_TRACK_KIND.VIDEO)
                        }}
                      />
                      <div className={styles.statusIcons}>
                        {callRoom.connectedUsers.find(
                          (it) => it.id_user === (clientID === LOCAL_CLIENT ? id : clientID)
                        )?.media.audio ? null : (
                          <div className={styles.muted}>
                            <RadioIcon />
                          </div>
                        )}
                        {callRoom.connectedUsers.find(
                          (it) => it.id_user === (clientID === LOCAL_CLIENT ? id : clientID)
                        )?.handUp ? (
                          <div className={styles.handUp}>
                            <HandIcon />
                          </div>
                        ) : null}
                        {clientID !== LOCAL_CLIENT && (
                          <div
                            style={{ cursor: "pointer", border: "1px solid black" }}
                            onClick={() => reconnectPeer(clientID)}
                          >
                            reconnectPeer
                          </div>
                        )}
                        {
                          <div
                            style={{ cursor: "pointer", border: "1px solid black" }}
                            onClick={() => reloadMediaStream(clientID)}
                          >
                            refreshMedia
                          </div>
                        }
                      </div>
                      <div className={styles.fullName}>
                        <span>
                          {
                            callRoom.connectedUsers.find(
                              (it) => it.id_user === (clientID === LOCAL_CLIENT ? id : clientID)
                            )?.name
                          }
                        </span>
                        <span>
                          {
                            callRoom.connectedUsers.find(
                              (it) => it.id_user === (clientID === LOCAL_CLIENT ? id : clientID)
                            )?.sname
                          }
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/*ЛОадер при переподключении*/}
        {isLoadingUsers && (
          <Loader
            position="absolute"
            zIndex={10000}
            containerType="bounceDots"
            type={LoaderTypes.BOUNCING_DOTS}
            background="rgba(0,0,0,0.5)"
            animation={false}
          />
        )}
      </PopUp>
      {/*Минифицированная версия комнаты*/}
      <MiniCallRoom
        setFullScreen={setFullScreen}
        handleSwitchMedia={handleSwitchMedia}
        cancelCall={cancelCall}
        fullScreen={fullScreen}
        provideMiniCallRoomRef={provideMiniCallRoomRef}
      />
      {mouseParams !== null && mouseParams?.width && mouseParams?.height ? (
        <ContextMenu params={mouseParams} setParams={setMouseParams}>
          <div
            className={styles.contextMenuItem}
            onClick={() => {
              if (socket() !== null) {
                onSetModals(MODALS.CHAT, { type: CHAT_MODALS.ADD_USER, params: null });
              }
            }}
          >
            <AddUserIcon className={styles.contextMenuIcon} />
            <div>{__("Добавление пользователя к конференции")}</div>
          </div>
          <div className={styles.contextMenuItem} onClick={copyLink}>
            <CopyLinkIcon className={styles.contextMenuIcon} />
            <div>{__("Копирование ссылки на конференцию")}</div>
          </div>
          <div className={styles.contextMenuItem}>
            <PhoneIcon className={styles.contextMenuIcon} />
            <div>{__("Подключение с другого устройства")}</div>
          </div>
        </ContextMenu>
      ) : null}
    </>
  );
}

export default CallRoom;
