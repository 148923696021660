import { useMenuFilterTypeElements } from "collections/menuFilterTypeElements";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import NavigationOption from "generalComponents/FilesServicePanel/NavigationOption/NavigationOption";
import TempSort from "generalComponents/FilesServicePanel/TempSort/TempSort";
import ViewTipeTabs from "generalComponents/FilesServicePanel/ViewTipeTabs/ViewTipeTabs";
import { FilterMarkers } from "generalComponents/FilterMarkers/FilterMarkers";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { IServePanel } from "models/servePanel";
import { TypeElements } from "models/typeElements/typeElements";
import { FC } from "react";
import { useParams } from "react-router-dom";

import styles from "./ArchiveServicePanel.module.sass";

export const ArchiveServicePanel: FC<IServePanel> = (): JSX.Element => {
  const {
    objSearchParams: { year, month, day, table, id_type },
    onSetObjSearchParams
  } = useSelectedObjSearchParams();

  const { archivedDirId } = useParams();

  const optionsTypeElements = useMenuFilterTypeElements().filter(
    ({ value }) => value !== TypeElements.USERS && value !== TASK_TYPES.NOTES
  );

  const defaultOptionTypeElement = optionsTypeElements.find(({ value }) => value === table || value === id_type);

  const onChangeDateFilter = (v: Date): void => {
    const y = v ? v.getFullYear().toString() : undefined;
    const m = v ? (v.getMonth() + 1).toString() : undefined;
    const d = v ? v.getDate().toString() : undefined;

    onSetObjSearchParams({
      [SEARCH_PARAMS.YEAR]: y ?? "",
      [SEARCH_PARAMS.MONTH]: m ?? "",
      [SEARCH_PARAMS.DAY]: d ?? ""
    });
  };

  const renderSelectedDate = (): Date => {
    if (year && month && day) {
      return new Date(Number(year), Number(month) - 1, Number(day));
    }
    return null;
  };

  return (
    <ToolsPanel size="large">
      <div className={styles.groupStart}>
        <NavigationOption />
        <ViewTipeTabs miniTipeTabs />
        <div className={styles.groupStart}>
          <TempSort />
          <FilterMarkers />
        </div>
        {!archivedDirId && (
          <div className={styles.groupStart}>
            <SelectCustom
              variantHeight="small"
              width={200}
              options={optionsTypeElements}
              selectedDefault={{
                name: defaultOptionTypeElement?.name || optionsTypeElements[0]?.name,
                value: defaultOptionTypeElement?.value || optionsTypeElements[0]?.value
              }}
            />
            <InputDate
              startDate={renderSelectedDate()}
              setStartDate={(v: Date) => onChangeDateFilter(v)}
              dateFormat="dd MMM yyyy"
              isClearable
              maxDate={new Date()}
            />
          </div>
        )}
      </div>
    </ToolsPanel>
  );
};
