import classNames from "classnames";
import { IMemoryTools } from "collections/profile";
import { calculateSize } from "generalComponents/Services/helpers";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import styles from "./MemoryTool.module.sass";

export interface IMemoryToolProps {
  tool: IMemoryTools;
}

export const MemoryTool: FC<IMemoryToolProps> = ({ tool }): JSX.Element => {
  const [chapterParams] = useSearchParams();
  const chapter = chapterParams.get(SEARCH_PARAMS.CHAPTER);

  const size = calculateSize(tool?.size ?? 0);

  return tool?.value ? (
    <li className={styles.item}>
      <button
        className={classNames(styles.item__btn, {
          [styles.active]: chapter === tool.value
        })}
        type="button"
        onClick={tool?.callback}
        aria-label={tool.name}
        value={tool.value}
      >
        <span className={styles.item__info}>
          <span className={styles.item__color} style={{ backgroundColor: tool?.color }} />
          <span className={styles.item__name}>{tool.name}</span>
        </span>
        <span className={styles.item__size}>{size}</span>
      </button>
    </li>
  ) : (
    <li className={classNames(styles.item, styles.item_noBtn)}>
      <span className={styles.item__info}>
        <span className={styles.item__color} style={{ backgroundColor: tool?.color }} />
        <span className={styles.item__name}>{tool.name}</span>
      </span>
      <span className={styles.item__size}>{size}</span>
    </li>
  );
};
