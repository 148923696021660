import { Times } from "generalComponents/Times/Times";
import { FC } from "react";

import styles from "./EditFileView.module.sass";

interface IEditFileViewProprs {
  name: string;
  src: string;
  onClose: () => void;
}

export const EditFileView: FC<IEditFileViewProprs> = ({ name, src, onClose }) => (
  <div className={styles.editFile}>
    <iframe title={name} src={src}></iframe>
    <Times handleClick={onClose} />
  </div>
);
