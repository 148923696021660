import api from "api";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { RightsState } from "generalComponents/Modals/Components/ProfileModals/AddEmployee/lib/stateReducer";
import SearchField from "generalComponents/SearchField/SearchField";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import Tabs from "generalComponents/Tabs/Tabs";
import { useAddEmployeeMail } from "hooks/employeesHooks";
import { useDefaultProjectRole, useFindParticipant } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IParticipant, SYSTEM_ROLE } from "models/store/joinProjects/joinProgects";
import { IUserInfo } from "models/store/user/user";
import { FC, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { isEmail } from "utils/validation";

import styles from "./AddParticipant.module.sass";

interface IProps {
  userIds: string[];
  onAddUser: (user: IParticipant) => void;
  changeAdd: () => void;
}
enum TEAM_TABS {
  SELECT = "SELECT",
  ADD = "ADD"
}

export const INITIAL_RIGHTS_STATE: RightsState = {
  IS_MGR_ORG: false,
  IS_MGR_EMPLOYEE: false,
  IS_MGR_FINANCE: false,
  IS_MGR_LIBRARY: false,
  IS_MGR_PROJECT: false,
  //
  IS_FILES: false,
  IS_SAFE: false,
  IS_MAIL: false,
  IS_PROJECT: true,
  IS_CHAT: false,
  IS_ARCHIVE: false,
  IS_LIBRARY: false,
  IS_JOURNAL: false
};

const AddParticipant: FC<IProps> = ({ onAddUser, userIds, changeAdd }) => {
  const { __ } = useLocales();
  const { employees, userInfo } = useUserSelectors();
  const { project } = useJoinProjectsSelectors();
  const defaultRoles = useDefaultProjectRole();
  const findParticipant = useFindParticipant();

  const emailHTML = useAddEmployeeMail();
  const { onSetTopMessageModal, setEmployees } = useActions();

  const [tabView, setTabView] = useState<TEAM_TABS>(TEAM_TABS.SELECT);
  const [search, setSearch] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [isFetch, setIsFetch] = useState<boolean>(false);

  const { value: fname, onChange: onChangeFname } = useInputValue();
  const { value: sname, onChange: onChangeSname } = useInputValue();
  const { value: jobTitle, onChange: onChangeJobTitle } = useInputValue();
  const { value: email, onChange: onChangeEmail, error, onError } = useInputValue();

  const viewsTabs = [
    {
      name: __("Сотрудники"),
      onClick: () => setTabView(TEAM_TABS.SELECT),
      value: TEAM_TABS.SELECT
    },
    {
      name: __("Пригласить участника"),
      onClick: () => setTabView(TEAM_TABS.ADD),
      value: TEAM_TABS.ADD
    }
  ];

  const filtredEmployees = useMemo(
    () =>
      [userInfo, ...employees]
        .filter((employee) => {
          const nameMatches = employee.fname.toLowerCase().includes(search.toLowerCase());
          const snameMatches = employee.sname.toLowerCase().includes(search.toLowerCase());
          const pnameMatches = employee.pname.toLowerCase().includes(search.toLowerCase());
          return nameMatches || snameMatches || pnameMatches;
        })
        .filter((employee) => !userIds.includes(employee.id)),
    [employees, search, userIds, userInfo]
  );

  const options = useMemo(
    () =>
      project.roles.map((role) => ({
        name: role.is_system ? defaultRoles[role.name as SYSTEM_ROLE].title : role.name,
        value: role.id,
        callback: () => setRole(role.id)
      })),
    [defaultRoles, project.roles]
  );

  const selectedDefault = useMemo(() => {
    const findRole = project.roles.find((role) => role.name === SYSTEM_ROLE.DEVELOPER);
    return {
      name: defaultRoles[findRole.name as SYSTEM_ROLE].title,
      value: findRole.id
    };
  }, [defaultRoles, project.roles]);

  const onInvite = async () => {
    if (!isEmail(email)) {
      onError(__("Не корректный email"));
      return;
    }
    try {
      setIsFetch(true);
      const payload = new FormData();
      payload.append("uid", userInfo.uid);
      payload.append("fname", fname);
      payload.append("sname", sname);
      payload.append("email", email);
      payload.append("rights", JSON.stringify(INITIAL_RIGHTS_STATE));
      payload.append("is_admin", "0");
      payload.append("id_company", userInfo.id_company);
      payload.append("html", emailHTML);

      const { data } = await api.post("/ajax/org_user_add.php", payload);

      if (data?.error === "user exists") {
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR,
          message: __("Пользователь с таким email уже зарегестрирован!")
        });
        return;
      }
      checkResponseStatus(data.ok);
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: __("Приглашение отправлено") });
      setEmployees(data.data.users);
      const newUser = data.data.users.find((u: IUserInfo) => u.id === data.id_user_new);

      setUser(newUser);
      changeAdd();
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    } finally {
      setIsFetch(false);
    }
  };

  const setUser = (user: IUserInfo) => {
    const { fname, sname, email, icon, id } = user;
    const defaultRoleId = project.roles.find((role) => role.name === SYSTEM_ROLE.DEVELOPER).id;
    const _user = findParticipant(id);
    const newUser: IParticipant = {
      fname,
      sname,
      email,
      icon,
      id,
      jobTitle: _user && _user.jobTitle ? _user.jobTitle : jobTitle,
      idRole: role ? role : defaultRoleId
    };
    onAddUser(newUser);
    onChangeEmail("");
    onChangeFname("");
    onChangeSname("");
    onChangeJobTitle("");
  };

  return (
    <>
      <div className={styles.head}>
        <Tabs tabs={viewsTabs} value={tabView} size="large" variant="main" />
      </div>
      {tabView === TEAM_TABS.SELECT && (
        <>
          <SearchField variant="primary" value={search} setValue={setSearch} placeholder={__("Введите")} />
          <ul className={styles.list}>
            {filtredEmployees.map((user) => (
              <li key={user.id} className={styles.item}>
                <div className={styles.info}>
                  <Avatar name={user.fname + " " + user.sname} imgSrc={user.icon} size={30} />
                  <div>
                    <p className={styles.name}>
                      {user.fname}&nbsp;{user.sname}
                    </p>
                    <p className={styles.email}>{user.email}</p>
                  </div>
                </div>

                <Button
                  className={styles.btn}
                  variant={ButtonVariantType.EXRTA_LIGHT}
                  size={ButtonSizeType.EXTRA_SMALL}
                  onClick={() => setUser(user)}
                  text={`+ ${__("Добавить")}`}
                />
              </li>
            ))}
          </ul>
        </>
      )}
      {tabView === TEAM_TABS.ADD && (
        <div className={styles.block}>
          <div className={styles.form}>
            <Input value={fname} onChange={onChangeFname} label={__("Имя")} />
            <Input value={sname} onChange={onChangeSname} label={__("Фамилия")} />
            <SelectCustom options={options} selectedDefault={selectedDefault} width={256} label={__("Роль")} />

            <Input value={jobTitle} onChange={onChangeJobTitle} label={__("Должность")} />
            <Input
              value={email}
              onChange={onChangeEmail}
              label={"Email"}
              require
              error={Boolean(error)}
              errorText={error}
            />
          </div>
          <Button
            className={styles.btn}
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.MEDIUM}
            onClick={onInvite}
            text={__("Отправить приглашение")}
            disabled={Boolean(!email) || isFetch}
          />
        </div>
      )}
    </>
  );
};

export default AddParticipant;
