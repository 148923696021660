import DownArrow from "generalComponents/DownArrow/DownArrow";
import { useRoleName } from "generalComponents/Services/projectServices";
import { ITrashRolesProps } from "models/project/projectTrash";
import { ITaskProject } from "models/store/projects/projectStore";
import React, { useState } from "react";
import { useLocales } from "react-localized";

import TrashTask from "../TrashTask/TrashTask";
import styles from "./TrashRoles.module.sass";

const TrashRoles: React.FC<ITrashRolesProps> = ({
  role,
  tasks,
  isSelect,
  toggleCurrentTask,
  chosenTasks
}): JSX.Element => {
  const { __ } = useLocales();
  const name = useRoleName();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleOpen = (): void => setIsOpen((open) => !open);

  return (
    <div className={styles.wrap}>
      <div className={styles.name}>
        <span>
          {name(role) ? name(role) : __("Без роли")}&nbsp;({tasks.length})
        </span>
        <DownArrow isOpen={isOpen} clickHandler={toggleOpen} />
      </div>
      {isOpen && (
        <>
          {tasks.map((task: ITaskProject) => (
            <TrashTask
              key={task.id}
              task={task}
              isSelect={isSelect}
              toggleCurrentTask={toggleCurrentTask}
              chosenTasks={chosenTasks}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default TrashRoles;
