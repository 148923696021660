import { ReactComponent as LockOpenIcon } from "assets/icons/lock.svg";
import { ReactComponent as SafeIcon } from "assets/icons/safe.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share_2.svg";
import classNames from "classnames";
import { useContextSafe } from "collections/safe";
import DeadlineTimer from "generalComponents/DeadlineTimer/DeadlineTimer";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { useActions } from "hooks/useActions";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { ISafe } from "models/store/safe/safe";
import React from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { useSafeSelectors } from "Store/selectors/safeSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { dateISO } from "utils/dateToISO";

import styles from "./SafeItem.module.sass";

interface ISafeItemProps {
  safe: ISafe;
  listCollapsed: boolean;
}

const SafeItem: React.FC<ISafeItemProps> = ({ safe, listCollapsed }): JSX.Element => {
  const { __ } = useLocales();
  const { safeId, safeDir } = useParams();
  const { userInfo } = useUserSelectors();
  const navigate = useNavigate();
  const { onRemoveAuthorized, onSetSafeModal, onResetFilesList, onSetTopMessageModal } = useActions();
  const { contextMenuSafeCallback, contextMenuSafeToMeCallback } = useContextSafe();

  const { safeList, authorized } = useSafeSelectors();
  const { contextCloseSafe } = useContextSafe();
  const authorizedSafe = authorized.find((el) => el.id_safe === safe.id);

  const getTime = (): void => {};

  const hideTime = () => {};

  const filtredContextMenu = (safe: ISafe): IPopoverData[] => {
    const authorizedSafe = authorized.find((el) => el.id_safe === safe.id);
    const menu = authorizedSafe ? contextCloseSafe(safe) : [];
    if (!safe.is_my) {
      return [...menu, ...contextMenuSafeToMeCallback(safe)];
    }
    if (!safe.is_system) {
      return [...menu, ...contextMenuSafeCallback(safe)];
    }

    return [...menu, ...contextMenuSafeCallback(safe).filter((el) => el.isSystem)];
  };

  const handleClick = (id: string): void => {
    if (!userInfo.tel) {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: __("Подтвердите основной телефон") });
      return;
    }
    if (id === safeId && !safeDir) return;
    const isAuthorized = authorized.find((el) => el.id_safe === id);
    if (isAuthorized) {
      navigate(id);
    } else {
      const safe = safeList.find((safe) => safe.id === id);
      if (safe.time_blocked && new Date(dateISO(safe.time_blocked)).getTime() > Date.now()) {
        onSetSafeModal({
          open: true,
          type: SAFE_MODALS.SAFE_BLOKED,
          safe
        });
        return;
      }
      onSetSafeModal({
        open: true,
        type: SAFE_MODALS.ENTER_SAFE_PASSWORD,
        safe,
        callbackType: SAFE_CALLBACK_MODAL.FETCH_FILES,
        alert: safe.is_system ? __("Введите пароль который используется для доступа к 4HUB") : undefined
      });
    }
  };

  const onClose = () => {
    if (safeId === safe.id) {
      onResetFilesList();
    }
    onRemoveAuthorized(safe.id);
  };

  return (
    <li
      title={listCollapsed ? safe.name : ""}
      className={classNames(styles.link, {
        [styles.active]: safeId === safe.id,
        [styles.collapsed]: listCollapsed
      })}
      onClick={() => handleClick(safe.id)}
    >
      <div className={styles.safeIconBlock}>
        <SafeIcon fill={safe.is_system ? "#DF9A14" : "#5379FF"} />
        <div className={styles.icons}>
          {Boolean(!safe.is_my || safe.is_share) && (
            <div className={classNames(styles.iconBox, styles.green)}>
              <ShareIcon widths={8} height={8} />
            </div>
          )}
          {authorizedSafe && (
            <div className={classNames(styles.iconBox, styles.yellow)} onMouseEnter={getTime} onMouseLeave={hideTime}>
              <LockOpenIcon widths={8} height={8} />
              <div className={styles.time}>
                <p>{__("Осталось")}:</p>
                <DeadlineTimer deadline={authorizedSafe.deadlineCode} onComplete={onClose} />
              </div>
            </div>
          )}
        </div>
      </div>
      <span className={styles.name}>{safe.is_system ? __("Мой Сейф") : safe.name}</span>
      <div className={styles.context}>
        <PopoverContextMenu data={filtredContextMenu(safe)} iconVariant="button" />
      </div>
    </li>
  );
};

export default SafeItem;
