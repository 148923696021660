import classNames from "classnames";
import { useCategoriesPropertiesList } from "collections/myFolders";
import { useSystemFolder } from "generalComponents/collections/folder";
import PopUp from "generalComponents/PopUp/PopUp";
import { useGetFileAccessInfo } from "generalComponents/Services/fileServices/fileServicesApi";
import { useGetFolderAccessInfo, useGetFolderInfo } from "generalComponents/Services/folderServices/folderServicesApi";
import { Times } from "generalComponents/Times/Times";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { DEFAULT_FOLDERS } from "models/generalComponents/folders";
import { ICategoryProperties } from "models/modals/propertiesModal/propertiesModal";
import { CategoriesPropertiesTypes } from "models/modals/propertiesModal/propertiesModal";
import { initialPropertiesModalState, PropertiesTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IAccessInfo } from "models/store/folders/foldersPayloads";
import { IFolder, initialFolderState } from "models/store/folders/foldersStore";
import { useCallback, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { AccessTabInfo } from "./Components/AccessTabInfo/AccessTabInfo";
import { GeneralTabInfo } from "./Components/GeneralTabInfo/GeneralTabInfo";
import { VersionsTabInfo } from "./Components/VersionsTabInfo/VersionsTabInfo";
import styles from "./Properties.module.sass";

const Properties = (): JSX.Element => {
  const { __ } = useLocales();
  const SYSTEM_FOLDERS = useSystemFolder();
  const { onSetPropertiesModal } = useActions();
  const { onGetFolderInfo } = useGetFolderInfo();
  const { onGetFileAccessInfo } = useGetFileAccessInfo();
  const { onGetFolderAccessInfo } = useGetFolderAccessInfo();
  const { categoriesProperties } = useCategoriesPropertiesList();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const { theme } = useUserSelectors();
  const {
    propertiesModal: { type, params }
  } = useGlobalModalsSelectors();

  const [_isFolder] = useState<boolean>(type === PropertiesTypes.FOLDER);
  const [usersFileAccess, setUsersFileAccess] = useState<IAccessInfo[]>([]);
  const [folderInfo, setFolderInfo] = useState<IFolder>(initialFolderState());
  const [usersFolderAccess, setUsersFolderAccess] = useState<IAccessInfo[]>([]);
  const [isFolderInfoLoading, setIsFolderInfoLoading] = useState<boolean>(true);
  const [category, setCategory] = useState<ICategoryProperties>(categoriesProperties[0]);
  const [isUsersFileAccessLoading, setIsUsersFileAccessLoading] = useState<boolean>(true);
  const [isUsersFolderAccessLoading, setIsUsersFolderAccessLoading] = useState<boolean>(true);

  const closeModal = (): void => {
    onSetPropertiesModal(initialPropertiesModalState());
  };

  const renderName = (): string => {
    if (_isFolder && (params as IFolder).is_system === "1") {
      return SYSTEM_FOLDERS[params.name as DEFAULT_FOLDERS].nameRu;
    }

    return params.name;
  };

  const renderTitleTextOption = (): string => {
    if (_isFolder) {
      return __(`Свойства папки`);
    }
    return __(`Свойства файла`);
  };

  const renderTabOption = (): JSX.Element => {
    switch (category.type) {
      case CategoriesPropertiesTypes.GENERAL:
        return (
          <GeneralTabInfo
            _isFolder={_isFolder}
            params={params}
            renderName={renderName}
            folderInfo={folderInfo}
            isFolderInfoLoading={isFolderInfoLoading}
          />
        );
      case CategoriesPropertiesTypes.ACCESSES:
        return (
          <AccessTabInfo
            _isFolder={_isFolder}
            usersFileAccess={usersFileAccess}
            folderAccessData={usersFolderAccess}
            isUsersFileAccessLoading={isUsersFileAccessLoading}
            isFolderAccessDataLoading={isUsersFolderAccessLoading}
          />
        );
      case CategoriesPropertiesTypes.VERSIONS:
        return <VersionsTabInfo _isFolder={_isFolder} params={params} />;
    }
  };

  const renderButtonTextOption = (el: ICategoryProperties): string => {
    if (el.type === CategoriesPropertiesTypes.ACCESSES) {
      if (_isFolder) return __(`Доступы к папке`);
      return __(`Доступы к файлу`);
    }
    return el.name;
  };

  const getProperties = useCallback((): void => {
    if (_isFolder) {
      const id_dir = (params as IFolder).id_dir;

      onGetFolderInfo({ id_dir }, setFolderInfo, setIsFolderInfoLoading);

      onGetFolderAccessInfo(id_dir, setUsersFolderAccess, setIsUsersFolderAccessLoading);
    } else {
      const fid = (params as IFile).fid;

      onGetFileAccessInfo(fid, setUsersFileAccess, setIsUsersFileAccessLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_isFolder, params]);

  useEffect(() => {
    getProperties();
  }, [getProperties]);

  const filtredTabs = (): ICategoryProperties[] => {
    if (isSafePage) {
      return [categoriesProperties[0], categoriesProperties[2]];
    }
    return categoriesProperties;
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.root}>
        <Times handleClick={closeModal} />
        <div className={styles.content}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{renderTitleTextOption()}</div>
          </div>
          <ul
            className={classNames(styles.categoryList, {
              [styles.addMarginBottom]: category.type !== CategoriesPropertiesTypes.GENERAL
            })}
          >
            {filtredTabs().map((item) => (
              <li key={item.type} className={styles.categoryItem}>
                <button
                  className={classNames(styles.categoryButton, {
                    [styles.selected]: item.type === category.type
                  })}
                  onClick={() => setCategory(item)}
                  type="button"
                >
                  {renderButtonTextOption(item)}
                </button>
              </li>
            ))}
          </ul>
          <div className={classNames(styles.categoriesWrapper, `scrollbar-thin-${theme}`)}>{renderTabOption()}</div>
        </div>
      </div>
    </PopUp>
  );
};

export default Properties;
