import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { createMarkup } from "generalComponents/Services/browserServices";
import { useProjectMsg } from "generalComponents/Services/projectServices";
import TextEditorArea from "generalComponents/TextEditorArea/TextEditorArea";
import { useActions } from "hooks/useActions";
import html2canvas from "html2canvas";
import { ButtonVariantType } from "models/generalComponents/button";
import { IDocsProps } from "models/project/docsWorkSpace";
import { IDocData, IDocsProject, INumberedData } from "models/store/projects/projectStore";
import { IEditDocs, ITopMessages } from "models/store/projects/projectThunk";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import InfoTooltip from "../../Components/InfoTooltip/InfoTooltip";
import styles from "./WorkSpace.module.sass";

const Docs: React.FC<IDocsProps> = ({
  currentDocs,
  selectDoc,
  isEdit,
  changeToolbar,
  pressedButton,
  handleEdit,
  currentEditor,
  setCurrentEditor,
  setCurrentDocs,
  isPrint,
  setIsPrint
}): JSX.Element => {
  const { __ } = useLocales();
  const { onEditDocs } = useActions();
  const { docs } = useProjectsSelectors();
  const MSG = useProjectMsg();
  const ref = useRef();
  const iframeRef = useRef(null);

  const [editableDocs, setEditableDocs] = useState<Record<string, IDocsProject>>({});

  useEffect(() => {
    const result = currentDocs.reduce(
      (acc, el) => ({
        ...acc,
        [el.id]: el
      }),
      {}
    );
    setEditableDocs(result);
  }, [currentDocs, isEdit]);

  useEffect(() => {
    if (isPrint) {
      getPrint();
    }
  }, [isPrint]); // eslint-disable-line

  const getPrint = async () => {
    try {
      let block = ref.current;
      const canvas = await html2canvas(block);
      const dataUrl = canvas.toDataURL("image/png");
      if (iframeRef.current) {
        let isPrinted = false;
        const img = new Image();
        img.width = 750;
        img.onload = () => {
          if (!isPrinted) {
            const iframeWindow = iframeRef.current.contentWindow || iframeRef.current;
            iframeWindow.document.body.appendChild(img);
            img.style.position = "absolute";
            img.style.top = "0";
            img.style.left = "0";
            iframeWindow.focus();
            iframeWindow.print();
            iframeWindow.document.body.removeChild(img);
            isPrinted = true;
          }
        };
        img.src = dataUrl;
        setIsPrint(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const documents: IDocsProject[] = useMemo(() => {
    return docs.filter((d) => d.is_template === 0) || [];
  }, [docs]);

  const isActive = (id: string): boolean => currentDocs.some((el) => el.id === id);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>, id: string): void => {
    setEditableDocs((prev) => ({ ...prev, [id]: { ...prev[id], name: e.target.value } }));
  };

  const handleChangeSection = (e: React.ChangeEvent<HTMLInputElement>, id: string, sectionId: string): void => {
    setEditableDocs((prev) => {
      const copy = [...prev[id].data];
      const section = copy.find((el) => el.id === sectionId);
      const index = copy.findIndex((el) => el.id === sectionId);
      if (section) {
        copy.splice(index, 1, { ...section, section: e.target.value });
      }
      return { ...prev, [id]: { ...prev[id], data: copy } };
    });
  };

  const setContent = (value: string, id: string, sectionId: string): void => {
    setEditableDocs((prev) => {
      const copy = [...prev[id].data];
      const section = copy.find((el) => el.id === sectionId);
      const index = copy.findIndex((el) => el.id === sectionId);
      if (section) {
        copy.splice(index, 1, { ...section, content: value });
      }
      return { ...prev, [id]: { ...prev[id], data: copy } };
    });
  };

  const onSave = (): void => {
    const documents: IDocsProject[] = Object.values(editableDocs);
    const payload: IEditDocs[] = documents.map((el) => ({
      ...el,
      data: JSON.stringify(el.data),
      numberedData: JSON.stringify(el.numberedData),
      inputs: JSON.stringify(el.inputs),
      footer: el.footer
    }));
    const messages: ITopMessages = {
      error: MSG.ERROR,
      success: MSG.EDIT_DOC
    };
    const cb = (): void => {
      handleEdit();
      setCurrentDocs(documents);
    };
    onEditDocs(payload, messages, cb);
  };

  const handleChangeInputs = (e: React.ChangeEvent<HTMLInputElement>, id: string, sectionId: string): void => {
    setEditableDocs((prev) => {
      const copy = [...prev[id].inputs];
      const section = copy.find((el) => el.id === sectionId);
      const index = copy.findIndex((el) => el.id === sectionId);
      if (section) {
        copy.splice(index, 1, { ...section, content: e.target.value });
      }
      return { ...prev, [id]: { ...prev[id], inputs: copy } };
    });
  };

  const setNumeredContent = (value: string, id: string, sectionId: string): void => {
    setEditableDocs((prev) => {
      const copy = [...prev[id].numberedData];
      const section = copy.find((el) => el.id === sectionId);
      const index = copy.findIndex((el) => el.id === sectionId);
      if (section) {
        copy.splice(index, 1, { ...section, content: value });
      }
      return { ...prev, [id]: { ...prev[id], numberedData: copy } };
    });
  };

  const setNestedContent = (value: string, id: string, sectionId: string, nestedId: string): void => {
    setEditableDocs((prev) => {
      const copy = [...prev[id].numberedData];
      const section = copy.find((el) => el.id === sectionId);
      const index = copy.findIndex((el) => el.id === sectionId);
      const copyNested = [...section.data];
      const sectionNested = copyNested.find((el) => el.id === nestedId);
      const indexNested = copyNested.findIndex((el) => el.id === nestedId);
      if (sectionNested && section) {
        copyNested.splice(indexNested, 1, { ...sectionNested, content: value });
        copy.splice(index, 1, { ...section, data: copyNested });
      }

      return { ...prev, [id]: { ...prev[id], numberedData: copy } };
    });
  };

  const renderData = (doc: IDocsProject, item: IDocData): JSX.Element => {
    return isEdit ? (
      <div key={item.id} className={styles.section}>
        <div className={styles.sectionHeader}>
          <input
            type="text"
            value={item.section}
            onChange={(e) => handleChangeSection(e, doc.id, item.id)}
            className={classNames(styles.inputSection)}
            placeholder={__("Название раздела")}
          />
          {item.tooltip && (
            <InfoTooltip
              text={<div className={classNames(styles.content)} dangerouslySetInnerHTML={createMarkup(item.tooltip)} />}
              title={__("Информация")}
            />
          )}
        </div>

        <div
          className={classNames(styles.editorBox, {
            [styles.editorBoxTable]: item.content.includes("<table")
          })}
          onClick={() => setCurrentEditor(item.id)}
        >
          {currentEditor === item.id ? (
            <TextEditorArea
              content={item.content}
              setContent={(value) => setContent(value, doc.id, item.id)}
              pressedButton={pressedButton}
              changeToolbar={changeToolbar}
            />
          ) : (
            <div
              className={classNames(styles.content)}
              dangerouslySetInnerHTML={createMarkup(item.content ? item.content : __("Введите текст"))}
            />
          )}
        </div>
      </div>
    ) : (
      <div key={item.id} className={styles.section}>
        <h3 className={styles.sectionName}>{item.section}</h3>
        <div className={classNames(styles.content)} dangerouslySetInnerHTML={createMarkup(item?.content)} />
      </div>
    );
  };

  const renderInputs = (doc: IDocsProject, item: IDocData): JSX.Element => {
    return (
      <div className={styles.item} key={item.id}>
        <p className={styles.lable}>{item.section}</p>
        {isEdit ? (
          <input
            type="text"
            value={item.content}
            onChange={(e) => handleChangeInputs(e, doc.id, item.id)}
            placeholder={item.section}
          />
        ) : (
          <p className={styles.value}> {item.content}</p>
        )}
      </div>
    );
  };

  const renderNestedList = (doc: IDocsProject, item: IDocData, el: any): JSX.Element => {
    return (
      <li key={el.id} className={styles.li}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionName}> {el.section}</h3>
          {isEdit && el.tooltip && (
            <InfoTooltip
              text={<div className={classNames(styles.content)} dangerouslySetInnerHTML={createMarkup(el.tooltip)} />}
              title={__("Информация")}
            />
          )}
        </div>
        {isEdit ? (
          <div
            className={classNames(styles.editorBox, {
              [styles.editorBoxTable]: el.content.includes("<table")
            })}
            onClick={() => setCurrentEditor(el.id)}
          >
            {currentEditor === el.id ? (
              <TextEditorArea
                content={el.content}
                setContent={(value) => setNestedContent(value, doc.id, item.id, el.id)}
                pressedButton={pressedButton}
                changeToolbar={changeToolbar}
              />
            ) : (
              <div
                className={classNames(styles.content)}
                dangerouslySetInnerHTML={createMarkup(el.content ? el.content : __("Введите текст"))}
              />
            )}
          </div>
        ) : (
          <div className={classNames(styles.content)} dangerouslySetInnerHTML={createMarkup(el.content)} />
        )}
      </li>
    );
  };

  const renderNumeredData = (doc: IDocsProject, item: INumberedData): JSX.Element => {
    return (
      <li key={item.id} className={styles.li}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionName}>{item.section}</h3>
          {isEdit && item.tooltip && (
            <InfoTooltip
              text={<div className={classNames(styles.content)} dangerouslySetInnerHTML={createMarkup(item.tooltip)} />}
              title={__("Информация")}
            />
          )}
        </div>
        {item.data ? (
          <ol className={classNames(styles.numeredList, styles.nestedList)}>
            {item.data.map((el) => renderNestedList(doc, item, el))}
          </ol>
        ) : (
          <>
            {isEdit ? (
              <div
                className={classNames(styles.editorBox, {
                  [styles.editorBoxTable]: item.content.includes("<table>")
                })}
                onClick={() => setCurrentEditor(item.id)}
              >
                {currentEditor === item.id ? (
                  <TextEditorArea
                    content={item.content}
                    setContent={(value) => setNumeredContent(value, doc.id, item.id)}
                    pressedButton={pressedButton}
                    changeToolbar={changeToolbar}
                    idx={item.id}
                  />
                ) : (
                  <div
                    className={classNames(styles.content)}
                    dangerouslySetInnerHTML={createMarkup(item.content ? item.content : __("Введите текст"))}
                  />
                )}
              </div>
            ) : (
              <div className={classNames(styles.content)} dangerouslySetInnerHTML={createMarkup(item.content)} />
            )}
          </>
        )}
      </li>
    );
  };

  return (
    <div className={styles.wrap}>
      <iframe ref={iframeRef} style={{ display: "none" }} title="docFrame" />

      <div className={styles.document}>
        {Object.values(editableDocs).length > 0 && (
          <>
            {Object.values(editableDocs).map((doc) => (
              <div key={doc.id} className={styles.sheet} ref={ref}>
                {isEdit ? (
                  <input
                    type="text"
                    value={doc.name}
                    onChange={(e) => handleChangeName(e, doc.id)}
                    className={classNames(styles.inputName, styles.inputFilled)}
                    placeholder={__("Имя документа")}
                  />
                ) : (
                  <h3 className={styles.docName}>{doc.name}</h3>
                )}

                <div className={styles.inputsBlock}> {doc.inputs.map((item) => renderInputs(doc, item))}</div>
                {doc.data?.length > 0 && doc.data.map((item) => renderData(doc, item))}
                {doc.numberedData.length > 0 && (
                  <ol className={styles.numeredList}>{doc.numberedData.map((item) => renderNumeredData(doc, item))}</ol>
                )}

                {doc.footer !== 0 && (
                  <div className={styles.footer}>
                    <p className={styles.text}>{__("Подписи об утверждении Устава проекта")}</p>
                    <p className={styles.text}>{__("Руководитель проекта")}</p>
                    <div className={styles.signatures}>
                      <p>{__("Подпись")}</p>
                      <p>{__("Дата")}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
        )}
        {isEdit && (
          <div className={styles.stickyBtn}>
            <Button text={__("Сохранить")} type="button" onClick={onSave} variant={ButtonVariantType.OK} />
          </div>
        )}
      </div>

      <div className={styles.sidePanel}>
        {documents.map((doc) => (
          <div
            key={doc.id}
            className={classNames(styles.sheet, { [styles.sheetActive]: isActive(doc.id) })}
            onClick={() => (!isEdit ? selectDoc(doc) : null)}
          >
            <h2 className={styles.docName}>{doc?.name}</h2>
            <div className={styles.inputsBlock}>
              {doc?.inputs?.length > 0 && doc.inputs?.map((el) => renderInputs(doc, el))}
            </div>

            {doc.data?.length > 0 &&
              doc.data.map((el) => (
                <div key={el.id} className={styles.section}>
                  {el.section && <h3 className={styles.sectionName}>{el.section}</h3>}
                  <div
                    className={classNames(styles.sideContent, {
                      [styles.empty]: !el.content
                    })}
                    dangerouslySetInnerHTML={createMarkup(el.content)}
                  />
                </div>
              ))}

            {doc.numberedData.length > 0 &&
              doc.numberedData.map((el) => (
                <div key={el.id}>
                  <h3 className={styles.sectionName}>{el.section}</h3>
                  {el.data ? (
                    el.data.map((el) => (
                      <div key={el.id}>
                        <h3 className={styles.sectionName}>{el.section}</h3>
                        <div
                          className={classNames(styles.sideContent, {
                            [styles.empty]: !el.content
                          })}
                          dangerouslySetInnerHTML={createMarkup(el.content)}
                        />
                      </div>
                    ))
                  ) : (
                    <div
                      className={classNames(styles.sideContent, {
                        [styles.empty]: !el.content
                      })}
                      dangerouslySetInnerHTML={createMarkup(el.content)}
                    />
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Docs;
