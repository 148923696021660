import InvoicesItem from "containers/Profile/Invoices/InvoicesItem/InvoicesItem";

import styles from "./Invoices.module.sass";

const Invoices = () => {
  const data = [
    {
      id: "4-999999",
      date: "12.09.2023",
      name: "Счет-фактура №1-6784158 от 28.07.2023",
      title: "Оплата тарифного плана Бизнес+ 12 мес",
      link: "/",
      price: "10000грн",
      isPaid: true
    },
    {
      id: "4-999999",
      date: "12.09.2023",
      name: "Счет-фактура №1-6784158 от 28.07.2023",
      title: "Оплата тарифного плана Бизнес+ 12 мес",
      link: "/",
      price: "10000грн",
      isPaid: false
    },
    {
      id: "4-999999",
      date: "12.09.2023",
      name: "Счет-фактура №1-6784158 от 28.07.2023",
      title: "Оплата тарифного плана Бизнес+ 12 мес",
      link: "/",
      price: "10000грн",
      isPaid: true
    },
    {
      id: "4-999999",
      date: "12.09.2023",
      name: "Счет-фактура №1-6784158 от 28.07.2023",
      title: "Оплата тарифного плана Бизнес+ 12 мес",
      link: "/",
      price: "10000грн",
      isPaid: false
    }
  ];

  return (
    <div className={styles.container}>
      {data.map((item, index) => (
        <InvoicesItem key={index} data={item} />
      ))}
    </div>
  );
};

export default Invoices;
