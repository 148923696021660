import { getTime, startOfDay } from "date-fns";
import { defaultRows, useTasksCalendar } from "generalComponents/Services/calendarPageServices";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { IMyTask } from "models/store/tasks/tasks";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import styles from "./TasksTimetable.module.sass";
import TasksTimetableItem from "./TasksTimetableItem/TasksTimetableItem";

const TasksTimetable: React.FC = (): JSX.Element => {
  const { tasks, reminder } = useTasksCalendar();
  const num = useRef<number>(0);
  const [currentTask, setCurrentTask] = useState<IMyTask>(null);
  const [currentTime, setCurrentTime] = useState<string>(null);

  useEffect(() => {
    if (num) num.current = 0;
  }, [tasks]);

  const {
    filters: { date }
  } = useTasksSelectors();

  const timeToday: number = getTime(startOfDay(new Date(date.year, date.month, date.day)));

  const taskTimes: Record<string, IMyTask[]> = useMemo(() => {
    return [...tasks, ...reminder].reduce((acc: Record<string, IMyTask[]>, t) => {
      const time: string = t.date_start.split("T")[1].slice(0, 5);
      const taskStart = new Date(t.date_start).getTime();

      if (timeToday >= taskStart && t.id_type === TASK_TYPES.TASK) {
        acc["0"] ? acc["0"].push(t) : (acc["0"] = [t]);
      } else {
        acc[time] ? acc[time].push(t) : (acc[time] = [t]);
      }
      return acc;
    }, {});
  }, [reminder, tasks, timeToday]);

  const timeRows: Record<string, IMyTask[]> = { ...defaultRows(), ...taskTimes };

  const timeArray: string[] = Object.keys(timeRows).sort();

  return (
    <ul className={styles.wrap}>
      {timeArray.map((time) => (
        <TasksTimetableItem
          key={time}
          timeRows={timeRows}
          timeToday={timeToday}
          time={time}
          num={num}
          currentTask={currentTask}
          setCurrentTask={(task) => setCurrentTask(task)}
          currentTime={currentTime}
          setCurrentTime={(time) => setCurrentTime(time)}
        />
      ))}
    </ul>
  );
};

export default TasksTimetable;
