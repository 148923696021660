import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classNames from "classnames";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useDefaultProjectStatuses, useFindStatus, useSortedStatuses } from "hooks/joinProjectHooks";
import { FC, MouseEvent, useState } from "react";

import styles from "./TaskStatus.module.sass";

interface IProps {
  status: string;
  setStatus: (val: string) => void;
  readOnly?: boolean;
}

const TaskStatus: FC<IProps> = ({ setStatus, status, readOnly = false }) => {
  const [show, setShow] = useState<boolean>(false);

  const defaultStatuses = useDefaultProjectStatuses();
  const { sortedStatuses } = useSortedStatuses();
  const findStatus = useFindStatus();

  const handleChange = (value: string): void => {
    setStatus(value);
    setShow(false);
  };

  const renderOverlay = () => {
    return (
      <ul>
        {sortedStatuses.map((item) => (
          <li key={item.id} className={styles.item} onClick={() => handleChange(item.id)}>
            <div className={styles.color} style={{ background: item.color }} />
            <span>{item.name ? item.name : defaultStatuses[item.system_name]}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <PopoverOverlay show={show} overlayContent={renderOverlay()} setShow={setShow} placement="bottom" btn="extra">
      <div
        className={classNames(styles.label, { [styles.active]: show })}
        onClick={(e: MouseEvent<HTMLParagraphElement>) => {
          e.stopPropagation();
          !readOnly && setShow(true);
        }}
      >
        <span>{findStatus(status).name || defaultStatuses[findStatus(status).system_name]}</span>
        <ArrowIcon className={classNames(styles.arrow, { [styles.arrowOpen]: show })} />
      </div>
    </PopoverOverlay>
  );
};

export default TaskStatus;
