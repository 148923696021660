import { EMOJIS, SIGNS } from "generalComponents/variables/elements";

export enum ELEMENTS_FILTER {
  TAG = "TAG",
  COLOR = "COLOR",
  EMO = "EMO",
  FIGURE = "FIGURE"
}

export interface IElementsFilter {
  [ELEMENTS_FILTER.TAG]: string;
  [ELEMENTS_FILTER.COLOR]: string;
  [ELEMENTS_FILTER.EMO]: EMOJIS;
  [ELEMENTS_FILTER.FIGURE]: SIGNS;
}

export interface IElementsFilterProps {
  filters: IElementsFilter;
  setFilters: (cb: (el: IElementsFilter) => IElementsFilter) => void;
}
