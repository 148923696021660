import classNames from "classnames";
import Clue from "generalComponents/Clue/Clue";
import FilesObserver from "generalComponents/FilesObserver/FilesObserver";
import BarsView from "generalComponents/ItemsViewTypeVariants/BarsView/BarsView";
import Loader from "generalComponents/Loaders/4HUB";
import { LoaderTypes } from "models/generalComponents/loader";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./SharedFolderElementsList.module.sass";

const SharedFolderElementsList: FC = () => {
  const { __ } = useLocales();
  const { theme } = useUserSelectors();
  const { loader, page, files } = useFilesSelectors();

  const renderFiles = () => {
    if (files && Array.isArray(files)) {
      if (files.length > 0) {
        return <BarsView items={files as (IFolder | IFile)[]} />;
      }
    }
    return <Clue text={__("Тут пока пусто")} icon="neutral" />;
  };

  return (
    <div className={classNames(styles.root, `scrollbar-medium-${theme}`)}>
      {loader && page === 1 ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <>
          {renderFiles()}
          {<FilesObserver />}
        </>
      )}
    </div>
  );
};

export default SharedFolderElementsList;
