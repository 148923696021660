import PropTypes from "prop-types";
import React from "react";
import { Bar } from "react-chartjs-2";
import { useLocales } from "react-localized";

const ChartAverageTasks = ({ usersData, average, users }) => {
  const { __ } = useLocales();
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: true,
        color: "#494949",
        align: "end"
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: __("дни")
        }
      }
    }
  };

  const averageData = users.map((_) => average);
  const data = {
    labels: users,
    datasets: [
      {
        type: "line",
        data: averageData,
        fill: false,
        borderColor: "rgb(54, 162, 235)",
        pointRadius: 0,
        borderDash: [10, 6],
        borderWidth: 1,
        datalabels: { display: false }
      },
      {
        data: usersData,
        type: "bar",
        backgroundColor: "#9ce7a8",
        borderRadius: "6"
      }
    ]
  };
  return <Bar data={data} options={options} />;
};

export default ChartAverageTasks;

ChartAverageTasks.propTypes = {
  average: PropTypes.number,
  usersData: PropTypes.arrayOf(PropTypes.number),
  users: PropTypes.arrayOf(PropTypes.string)
};
