import { IFile } from "models/store/files/files";
import { MutableRefObject } from "react";

import WebSocketBrush from "../../Pages/Cabinet/Components/WorkElements/MiniToolBar/Tools/WebSocketBrush";
import WebSocketCircle from "../../Pages/Cabinet/Components/WorkElements/MiniToolBar/Tools/WebSocketCircle";
import WebSocketEraser from "../../Pages/Cabinet/Components/WorkElements/MiniToolBar/Tools/WebSocketEraser";
import WebSocketMarker from "../../Pages/Cabinet/Components/WorkElements/MiniToolBar/Tools/WebSocketMarker";
import WebSocketPencil from "../../Pages/Cabinet/Components/WorkElements/MiniToolBar/Tools/WebSocketPencil";
import WebSocketSquare from "../../Pages/Cabinet/Components/WorkElements/MiniToolBar/Tools/WebSocketSquare";
import WebSocketTriangle from "../../Pages/Cabinet/Components/WorkElements/MiniToolBar/Tools/WebSocketTriangle";
import { ICallRoomConnectedUser } from "../store/chat/chat";

export interface IToolOptions {
  color: string;
  pushInDrawHistory: (it: string) => void;
  socket: WebSocket | null;
  uid: string;
  id_user: string;
  connectedUsers: ICallRoomConnectedUser[];
}

export type WebSocketToolTypes =
  | WebSocketBrush
  | WebSocketCircle
  | WebSocketPencil
  | WebSocketMarker
  | WebSocketEraser
  | WebSocketSquare
  | WebSocketTriangle;

export interface IDrawCircleParams {
  drawStartX: number;
  drawStartY: number;
  drawRadius: number;
}

export interface IDrawTriangleParams extends IDrawSquareParams {}

export interface IDrawSquareParams {
  drawStartX: number;
  drawStartY: number;
  drawWidth: number;
  drawHeight: number;
}

export type DrawParams = number[][] | IDrawCircleParams | IDrawSquareParams | IDrawTriangleParams;

export interface IColorPicker {
  colorPickerRef: React.RefObject<HTMLInputElement>;
}

export interface ISizePicker {
  isEdit: boolean;
}
export enum SAVE_TYPE {
  REPLACE = "REPLACE",
  COPY = "COPY",
  CREATE = "CREATE"
}
export interface IPaintToolbar {
  file?: IFile;
  canvasRef: MutableRefObject<HTMLCanvasElement>;
  onCancel?: () => void;
  naturalWidth?: number;
  naturalHeight?: number;
  setIsLoad?: (value: boolean) => void;
  saveType?: SAVE_TYPE;
}
