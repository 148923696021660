import { IFilterLibraryState } from "models/store/filterLibrary/filterLibrary";
import {
  IAddCategoryFilterLibrary,
  IAddRubricFilterLibrary,
  IAddSearchFilterLibrary,
  INullifyFilterLibrary
} from "models/store/filterLibrary/filterLibraryActions";
import { FilterLibraryTypes } from "Store/types/filterLibrary";

export const onAddCategoryFilterLibrary = (payload: IFilterLibraryState["category"]): IAddCategoryFilterLibrary => ({
  type: FilterLibraryTypes.ADD_CATEGORY_FILTER_LIBRARY,
  payload
});

export const onAddRubricFilterLibrary = (payload: IFilterLibraryState["idRubric"]): IAddRubricFilterLibrary => ({
  type: FilterLibraryTypes.ADD_RUBRIC_FILTER_LIBRARY,
  payload
});

export const onAddSearchFilterLibrary = (payload: IFilterLibraryState["search"]): IAddSearchFilterLibrary => ({
  type: FilterLibraryTypes.ADD_SEARCH_FILTER_LIBRARY,
  payload
});

export const onNullifyFilterLibrary = (): INullifyFilterLibrary => ({
  type: FilterLibraryTypes.NULLIFY_FILTER
});
