import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export function useProjectsSelectors() {
  const Projects = useTypeSelector((s) => s.Projects);
  const projects = Projects.projectsList;
  const project = Projects.project;
  const links = Projects.links;
  const selectRole = Projects.selectRole;
  const selectUser = Projects.selectUser;
  const docs = Projects.docs;
  const foldersTrash = Projects.foldersTrash;
  const selectUrgency = Projects.selectUrgency;
  const selectPriority = Projects.selectPriority;
  const tasksTrash = Projects.tasksTrash;
  const filesTrash = Projects.filesTrash;

  return {
    projects,
    project,
    links,
    selectRole,
    selectUser,
    docs,
    foldersTrash,
    filesTrash,
    selectPriority,
    selectUrgency,
    tasksTrash
  };
}
