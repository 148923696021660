import { useListMemoryTools } from "collections/profile";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./StorageDiagram.module.sass";

export const StorageDiagram = () => {
  const listMemoryTools = useListMemoryTools();

  const { userInfo } = useUserSelectors();

  return (
    <div className={styles.storageWrap}>
      {Object.values(listMemoryTools).map(({ name, color, size, value }) => {
        const width = size && userInfo?.total ? `${((size * 100) / userInfo.total).toFixed(1)}%` : "0%";

        return (
          Boolean(size) && (
            <div
              key={value}
              title={`${width} - ${name}`}
              className={styles.chapter}
              style={{ width, backgroundColor: color }}
            />
          )
        );
      })}
    </div>
  );
};
