export interface ISortByDateState {
  d?: number | undefined;
  m: number | undefined;
  y: number | undefined;
}

export function initialSortByDateState(): ISortByDateState {
  return {
    d: undefined,
    m: undefined,
    y: undefined
  };
}
