import { IColors } from "models/generalComponents/elements";
import { ICategoryColor } from "models/store/projects/projectStore";
import { useLocales } from "react-localized";

export const useTags = (): string[] => {
  const { __ } = useLocales();

  return [
    __("Фото"),
    __("Видео"),
    __("Музыка"),
    __("Работа"),
    __("Спорт"),
    __("Развлечения"),
    __("Игры"),
    __("Документы"),
    __("Другое")
  ];
};

export const colors: IColors[] = [
  {
    dark: "#a8a8a8",
    light: "#efeeee",
    color: "silver",
    name: "grey"
  },
  {
    dark: "#c4aa8f",
    light: "rgb(254, 220, 184)",
    color: "rgb(254, 220, 184)",
    name: "wheat"
  },
  {
    dark: "#326bc1",
    light: "rgb(64, 134, 242)",
    color: "rgb(64, 134, 242)",
    name: "blue"
  },
  {
    dark: "#27967a",
    light: "rgb(52, 198, 162)",
    color: "rgb(52, 198, 162)",
    name: "green"
  },
  {
    dark: "#cb8d40",
    light: "rgb(249, 173, 80)",
    color: "rgb(249, 173, 80)",
    name: "orange"
  },
  {
    dark: "#c15862",
    light: "rgb(244, 112, 125)",
    color: "rgb(244, 112, 125)",
    name: "pink"
  },
  {
    dark: "#792794",
    light: "rgb(160, 51, 195)",
    color: "rgb(160, 51, 195)",
    name: "purple"
  },
  {
    dark: "#992928",
    light: "rgb(194, 52, 51)",
    color: "rgb(194, 52, 51)",
    name: "red"
  },
  {
    dark: "#267d8d",
    light: "rgb(52, 174, 196)",
    color: "rgb(52, 174, 196)",
    name: "aqua"
  },
  {
    dark: "#981E76",
    light: "#C81E98",
    color: "#C81E98",
    name: "crimson"
  },
  {
    dark: "#40289b",
    light: "rgb(81, 52, 197)",
    color: "rgb(81, 52, 197)",
    name: "darkblue"
  },
  {
    dark: "#A46128",
    light: "#C67734",
    color: "#C67734",
    name: "darkbrown"
  },
  {
    dark: "#6A9E14",
    light: "#83BF20",
    color: "#83BF20",
    name: "darkgreen"
  }
];

export const colorsCategory: ICategoryColor[] = [
  {
    dark: "#08C326",
    light: "#EDF6EE",
    line: "#BCD6BF",
    secondary: "#DCF2DE",
    name: "lightGreen"
  },
  {
    dark: "#FFC77F",
    light: "#FFF7E8",
    line: "#ECDCC0",
    secondary: "#F6EBD4",
    name: "peach"
  },
  {
    dark: "#CA17E2",
    light: "#F8EDFA",
    line: "#F0D0F5",
    secondary: "#F0DBF3",
    name: "purple"
  },
  {
    dark: "#CC3F36",
    light: "#FFEBEA",
    line: "#EFC7C5",
    secondary: "#F4CFCD",
    name: "red"
  },
  {
    dark: "#FF910E",
    light: "#FFF7EE",
    line: "#F0DAC0",
    secondary: "#F2DECB",
    name: "orange"
  },
  {
    dark: "#74B6FF",
    light: "#EEF6FF",
    line: "#C9DDF2",
    secondary: "#D1E2F4",
    name: "lightBlue"
  },
  {
    dark: "#B6C2BF",
    light: "#F2F2F2",
    line: "#D8D8D8",
    secondary: "#E3E3E3",
    name: "lightGrey"
  },
  {
    dark: "#0100FF",
    light: "#EFF5FF",
    line: "#CFDDFA",
    secondary: "#D6E3F9",
    name: "blue"
  },
  {
    dark: "#FF00C7",
    light: "#FFF6FF",
    line: "#FAE1FA",
    secondary: "#F8D8F8",
    name: "pink"
  }
];

export const colorsNote: IColors[] = [
  {
    dark: "#387ADE",
    light: "#4086F1",
    color: "#4086F1",
    name: "lightBlue"
  },
  {
    dark: "#DF8C35",
    light: "#F19738",
    color: "#F19738",
    name: "orange"
  },
  {
    dark: "#2BB492",
    light: "#34C6A2",
    color: "#34C6A2",
    name: "green"
  },
  {
    dark: "#9E5700",
    light: "#B96600",
    color: "#B96600",
    name: "brown"
  },
  {
    dark: "#D75D69",
    light: "#F4707D",
    color: "#F4707D",
    name: "pink"
  },
  {
    dark: "#8829A7",
    light: "#A234C6",
    color: "#A234C6",
    name: "purple"
  },
  {
    dark: "#A32929",
    light: "#C63534",
    color: "#C63534",
    name: "red"
  },
  {
    dark: "#3A258F",
    light: "#5134C6",
    color: "#5134C6",
    name: "blue"
  }
];
export const emojis: string[] = [
  "&#128512;",
  "&#128513;",
  "&#128514;",
  "&#128515;",
  "&#128516;",
  "&#128517;",
  "&#128518;",
  "&#128519;",
  "&#128520;",
  "&#128521;",
  "&#128522;",
  "&#128523;",
  "&#128524;",
  "&#128525;",
  "&#128526;",
  "&#128527;",
  "&#128528;",
  "&#128529;",
  "&#128530;",
  "&#128531;",
  "&#128532;",
  "&#128533;",
  "&#128534;",
  "&#128535;",
  "&#128536;",
  "&#128537;",
  "&#128538;",
  "&#128539;"
];
