import {
  ICallRoomInvitations,
  IChatNotification,
  IReminderNotification
} from "models/store/notifications/notifications";

export enum NotificationsTypes {
  GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS",
  ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS",
  HIDDEN_NOTIFICATION = "HIDDEN_NOTIFICATION",
  DELAY_NOTIFICATION = "DELAY_NOTIFICATION",
  DELETE_NOTIFICATION = "DELETE_NOTIFICATION",
  LIST_NOTIFICATION = "LIST_NOTIFICATION",
  EDIT_NOTIFICATION_CHAT = "EDIT_NOTIFICATION_CHAT",
  GET_ALL_CALL_ROOM_INVITATIONS = "GET_ALL_CALL_ROOM_INVITATIONS"
}

export enum Notifications {
  REMINDER = "reminder",
  CHAT = "chat",
  INVITE_CALLROOM = "INVITE_CALLROOM"
}

export type NotificationTypes = IReminderNotification | IChatNotification | ICallRoomInvitations;
