import { ReactComponent as VideoIcon } from "assets/icons/camera.svg";
import { ReactComponent as ChatIcon } from "assets/icons/chat_2.svg";
import { ReactComponent as LetterIcon } from "assets/icons/mail_2.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone_2.svg";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IParticipant } from "models/store/joinProjects/joinProgects";
import { IUserInfo } from "models/store/user/user";
import { useState } from "react";

import styles from "./UserIcon.module.sass";

const UserIcon = ({ item }: { item: IUserInfo | IParticipant }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { onMailNewMessageModal } = useActions();

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handlePhoneClick = () => {};

  const handleMailClick = () => {
    onMailNewMessageModal({ open: true, to: item.email });
  };

  const handleChatClick = () => {};
  const handleVideoClick = () => {};

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <PopoverOverlay
        classes={{ popoverBody: styles.tooltip }}
        width={250}
        overlayContent={
          <div className={styles.tooltipContainer}>
            <Avatar
              name={"name" in item && item.name ? item.name + " " + item.sname : item.fname + " " + item.sname}
              size={60}
              imgSrc={item.icon ? `${projectSrc + item.icon}` : undefined}
              status={"status" in item && item.status}
            />
            <div className={styles.info}>
              <div className={styles.title}>
                {"name" in item && item.name ? item.name + " " + item.sname : item.fname + " " + item.sname}
              </div>
              <div className={styles.buttons}>
                <Button
                  iconL={<PhoneIcon />}
                  variant={ButtonVariantType.OPACITY}
                  size={ButtonSizeType.SMALL}
                  isSquare={true}
                  onClick={handlePhoneClick}
                  className={styles.button}
                />
                <Button
                  iconL={<ChatIcon />}
                  variant={ButtonVariantType.OPACITY}
                  size={ButtonSizeType.SMALL}
                  isSquare={true}
                  onClick={handleChatClick}
                  className={styles.button}
                />
                <Button
                  iconL={<LetterIcon />}
                  variant={ButtonVariantType.OPACITY}
                  size={ButtonSizeType.SMALL}
                  isSquare={true}
                  onClick={handleMailClick}
                  className={styles.button}
                />
                <Button
                  iconL={<VideoIcon />}
                  variant={ButtonVariantType.OPACITY}
                  size={ButtonSizeType.SMALL}
                  isSquare={true}
                  onClick={handleVideoClick}
                  className={styles.button}
                />
              </div>
            </div>
          </div>
        }
        show={isOpen}
        setShow={setIsOpen}
      >
        <div className={styles.container}>
          <div className={styles.iconWrapper}>
            {
              <Avatar
                name={"name" in item && item.name ? item.name + " " + item.sname : item.fname + " " + item.sname}
                size={30}
                imgSrc={item.icon ? `${projectSrc + item.icon}` : undefined}
                status={"status" in item && item.status}
              />
            }
          </div>
          <div className={styles.text}>
            <div>{"name" in item && item.name ? item.name + " " + item.sname : item.fname + " " + item.sname}</div>
            <div>{Array.isArray(item.email) ? item.email[0] : item.email}</div>
          </div>
        </div>
      </PopoverOverlay>
    </div>
  );
};

export default UserIcon;
