export enum ProjectTypes {
  NULLIFY_PROJECT = "NULLIFY_PROJECT",
  GET_PROJECTS = "GET_PROJECTS",
  ADD_PROJECT = "ADD_PROJECT",
  EDIT_PROJECT = "EDIT_PROJECT",
  DELETE_PROJECT = "DELETE_PROJECT",
  CURRENT_PROJECT = "CURRENT_PROJECT",

  ADD_USER = "ADD_USER",
  EDIT_USER = "EDIT_USER",
  DELETE_USER = "DELETE_USER",

  UPDATE_TASKS = "UPDATE_TASKS",
  DELETE_PROJECT_TASK = "DELETE_PROJECT_TASK",

  ADD_CATEGORY = "ADD_CATEGORY",
  EDIT_CATEGORY = "EDIT_CATEGORY",
  DELETE_CATEGORY = "DELETE_CATEGORY",
  GET_CATEGORIES = "GET_CATEGORIES",
  MOVE_CATEGORY = "MOVE_CATEGORY",

  SELECT_ROLE = "SELECT_ROLE",
  SELECT_USER = "SELECT_USER",

  FETCH_TASKS_TRASH = "FETCH_TASKS_TRASH",
  RESTORE_TASK = "RESTORE_TASK",
  CLEAR_TRASH = "CLEAR_TRASH",
  FETCH_FOLDERS_TRASH = "FETCH_FOLDERS_TRASH",
  FETCH_FILES_TRASH = "FETCH_FILEs_TRASH",

  SELECT_URGENCY = "SELECT_URGENCY",
  SELECT_PRIORITY = "SELECT_PRIORITY",

  GET_LINKS = "GET_LINKS",

  FETCH_DOCS = "FETCH_DOCS"
}
