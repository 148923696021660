import { ReactComponent as DotsIcon } from "assets/PrivateCabinet/dots-horizontal-square.svg";
import { ReactComponent as DotsIconVertical } from "assets/PrivateCabinet/dots-vertical-round.svg";
import classNames from "classnames";
import { useContextArchivedFile } from "collections/archive";
import { useContextFile } from "collections/myFiles";
import { useContextSafeFile } from "collections/safe";
import { useContextTrashFile } from "collections/trash";
import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
import FileTags from "generalComponents/FileTags/FileTags";
import { useGetItemSize } from "generalComponents/Hooks/Files/useGetItemSize";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { useIsFoldersPage, useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { calculateSize, splitFilename } from "generalComponents/Services/helpers";
import { HOT_KEYS } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useSafeFind } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { useDateFormat } from "hooks/useDateFormat";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { IFile } from "models/store/files/files";
import { useGetIconSize } from "Pages/Cabinet/Components/WorkElements/Hooks/useGetIconSize";
import { FC, MouseEvent, useState } from "react";
import { useLocation } from "react-router";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { getStartEndIndexsSelectedElements } from "utils/getStartEndIndexsSelectedElements";

import styles from "./FileBox.module.sass";

export interface IFileBox {
  file: IFile;
}

export const FileBox: FC<IFileBox> = ({ file }): JSX.Element => {
  const { pathname } = useLocation();
  const { isShared } = useIsShared();
  const { formatDate } = useDateFormat();
  const { isFoldersPage } = useIsFoldersPage();
  const currentSafe = useSafeFind();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const isArchive = useCurrentPathStarstWith(ROUTES.ARCHIVE);
  const isTrash = useCurrentPathStarstWith(ROUTES.TRASH);
  const isSharedFolderPage = useCurrentPathStarstWith(ROUTES.SHARE_FOLDER);

  const [isHover, setIsHover] = useState<boolean>(false);

  const onHover = (): void => setIsHover(true);
  const outHover = (): void => {
    setIsHover(false);
  };

  const { isBarsPreviewView, isLinesView } = useGetItemView();
  const { contextMenuFileCallback, contextMenuFileToMe, contextMenuFileLinkFolder } = useContextFile();
  const { contextMenuArchivedFileCallback } = useContextArchivedFile();
  const { contextMenuTrashFileCallback } = useContextTrashFile();
  const { contextMenuSafeFile, contextMenuSafeFileToMe } = useContextSafeFile();

  const { isMediumSize, isBigSize } = useGetItemSize();
  const { getFileIconWidth, getFileIconHeight } = useGetIconSize();
  const { onSetPickedItems, onSetCurrentItem, onFileView } = useActions();
  const { files, view } = useFilesSelectors();

  const {
    currentItem,
    pickedItems: { open, items, isLeftSide, isRightSide }
  } = useFilesSelectors();

  const setElementType = (): CheckBoxVariantTypes.NO_TYPE | CheckBoxVariantTypes.CHECKED => {
    return items?.some((item) => (item as IFile)?.fid === file.fid)
      ? CheckBoxVariantTypes.CHECKED
      : CheckBoxVariantTypes.NO_TYPE;
  };

  const [checkBoxOption, setCheckBoxOption] = useState<CheckBoxVariantTypes>(setElementType());
  const [_isCheckedOption] = useState<boolean>(checkBoxOption === CheckBoxVariantTypes.CHECKED);

  const onClickFileBoxItem = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (isBarsPreviewView) {
      switch (e.detail) {
        case 1: {
          onSetCurrentItem(file);
          return;
        }
        case 2: {
          onFileView({ open: true, params: file });
          return;
        }

        default:
          break;
      }
    }

    switch (e.detail) {
      case 2:
        onFileView({ open: true, params: file });
        break;
    }
  };

  const onClickCheckBox = (): void => {
    onSetPickedItems({
      open: true,
      items: items.find((item) => (item as IFile)?.fid === file.fid)
        ? items.filter((item) => (item as IFile)?.fid !== file.fid)
        : [...items, file],
      isLeftSide,
      isRightSide
    });
    onSetCurrentItem(file);
  };

  const onMouseEnterFile = (): void => {
    if (currentSafe && !currentSafe.is_my) return;
    if (_isCheckedOption || (isShared && !file.my)) return;
    setCheckBoxOption(CheckBoxVariantTypes.DEFAULT);
  };

  const onMouseLeaveFile = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.NO_TYPE);
  };

  const onMouseEnterCheckBox = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.HOVER);
  };

  const onMouseLeaveCheckBox = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.DEFAULT);
  };

  const renderContextMenu = (): IPopoverData[] => {
    if (isSafePage) {
      if (file) return currentSafe?.is_my ? contextMenuSafeFile(file) : contextMenuSafeFileToMe(file);
    }
    if (isShared) {
      if (file.my) {
        return contextMenuFileCallback(file);
      }
      if (!file.my) {
        return contextMenuFileToMe(file);
      }
    }
    if (isFoldersPage || pathname.startsWith(`/${ROUTES.FILES}`)) {
      return contextMenuFileCallback(file);
    }

    if (isArchive) {
      return contextMenuArchivedFileCallback(file);
    }

    if (isTrash) {
      return contextMenuTrashFileCallback(file);
    }

    if (isSharedFolderPage) {
      return contextMenuFileLinkFolder(file);
    }

    return [];
  };

  const isSelected = (): boolean => {
    if (open) {
      return items?.some((item) => (item as IFile)?.fid === file.fid);
    }

    return (currentItem as IFile)?.fid === file.fid;
  };

  const onSelectFilesRange = (event: MouseEvent<HTMLDivElement>) => {
    if (event[HOT_KEYS.SHIFT]) {
      if (!items.length) {
        onClickCheckBox();
      }

      if (items.length >= 1) {
        const [indexFirst, indexSecond] = getStartEndIndexsSelectedElements(files, items);

        const currentIndex = files.findIndex((el) => (el as IFile).fid === file.fid);
        const isSelectedItem = items.some((item) => (item as IFile).fid === file.fid);

        let startIndex = indexFirst;
        let endIndex = indexSecond;

        if (currentIndex > indexFirst && currentIndex > indexSecond && isLeftSide && !isRightSide) {
          startIndex = indexSecond;
        }

        if (currentIndex < indexFirst && currentIndex < indexSecond && !isLeftSide && isRightSide) {
          endIndex = indexFirst;
        }

        if (isSelectedItem && isLeftSide) {
          startIndex = currentIndex;
        }

        if (isSelectedItem && isRightSide) {
          endIndex = currentIndex;
        }

        const filesRange = files.slice(Math.min(startIndex, currentIndex), Math.max(endIndex + 1, currentIndex + 1));

        onSetPickedItems({
          open: true,
          items: filesRange,
          isRightSide:
            (currentIndex > indexFirst && currentIndex > indexSecond) || (isRightSide && isSelectedItem) ? true : false,
          isLeftSide:
            (currentIndex < indexFirst && currentIndex < indexSecond) || (isLeftSide && isSelectedItem) ? true : false
        });
      }
    }
  };

  const renderContextContent = (): JSX.Element => {
    return (
      <PopoverContextMenu data={renderContextMenu()} iconVariant={isLinesView ? "hidden" : undefined} visible={isHover}>
        {!isLinesView ? <DotsIcon className={styles.dots} /> : <DotsIconVertical fill="#E7EBEA" />}
      </PopoverContextMenu>
    );
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.selected]: isSelected(),
        [styles.isMediumSize]: isMediumSize,
        [styles.isBigSize]: isBigSize,
        [styles[view]]: view
      })}
      onMouseEnter={onHover}
      onMouseLeave={outHover}
      onClick={
        !isArchive && !isTrash
          ? (event: MouseEvent<HTMLDivElement>) => {
              if (event[HOT_KEYS.META] || event[HOT_KEYS.CTRL]) {
                onClickCheckBox();
              }

              onSelectFilesRange(event);
            }
          : undefined
      }
    >
      <div
        className={classNames(styles.body, {
          [styles[view]]: view
        })}
      >
        {!isLinesView && <div className={styles.context}>{renderContextContent()}</div>}
        {(file.emo || file.color) && !isLinesView && (
          <div className={styles.symbols}>
            <div className={styles.emoFigWrapper}>
              {file.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: file.emo }} />}
              {file.color && <div className={styles.color} style={{ background: file.color }} />}
            </div>
          </div>
        )}
        <div
          className={styles.bodyContent}
          onClick={onClickFileBoxItem}
          onMouseEnter={onMouseEnterFile}
          onMouseLeave={onMouseLeaveFile}
        >
          {!isArchive && !isTrash && !isSharedFolderPage && (
            <div className={styles.options}>
              <CheckBox
                variant={checkBoxOption}
                onClick={onClickCheckBox}
                onMouseEnter={onMouseEnterCheckBox}
                onMouseLeave={onMouseLeaveCheckBox}
                width={14}
                height={14}
                increaseArea
              />
            </div>
          )}
          <div
            className={classNames(styles.iconWrapperFlex, {
              [styles[view]]: view
            })}
          >
            <div
              className={classNames(styles.iconWrapper, {
                [styles[view]]: view
              })}
            >
              <ItemIcon
                isFolder={false}
                width={getFileIconWidth()}
                height={getFileIconHeight()}
                color={file.color}
                ext={file.ext}
                passIcon={file.is_dir && file.is_pass === 1}
              />
              {file.tags.length > 0 && (
                <div className={styles.tags}>
                  <FileTags item={file} />
                </div>
              )}
            </div>
            {!isLinesView && (
              <div
                className={classNames(styles.name, {
                  [styles[view]]: view
                })}
              >
                {splitFilename(file.name).name}
              </div>
            )}
            {isLinesView && (
              <div>
                <div
                  className={classNames(styles.name, {
                    [styles[view]]: view
                  })}
                >
                  {splitFilename(file.name).name}
                </div>
                <div
                  className={classNames(styles.bottomInfo, {
                    [styles[view]]: view
                  })}
                >
                  <div className={styles.size}>{calculateSize(file.size)}</div>
                  <div className={styles.dateCreation}>{formatDate(file.ctime)}</div>
                </div>
              </div>
            )}
          </div>
          {!isLinesView && (
            <div
              className={classNames(styles.bottomInfo, {
                [styles[view]]: view
              })}
            >
              <div className={styles.size}>{calculateSize(file.size)}</div>
              <div className={styles.dateCreation}>{formatDate(file.ctime)}</div>
            </div>
          )}
          {isLinesView && renderContextContent()}
        </div>
      </div>
    </div>
  );
};
