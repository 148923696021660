import { ReactComponent as GoogleIcon } from "assets/PrivateCabinet/google-color.svg";
import { ReactComponent as DownArrowIcon } from "assets/PrivateCabinet/sharp-down-arrow.svg";
import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import React, { useState } from "react";
import { useLocales } from "react-localized";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./ConnectPostbox.module.sass";

interface IConnectPostboxProps {
  closeModal: () => void;
}

const ConnectPostbox: React.FC<IConnectPostboxProps> = ({ closeModal }): JSX.Element => {
  const [buttonsIsHide, setButtonIsHide] = useState(true);
  const { value: name, onChange: onChangeName } = useInputValue();
  const { value: email, onChange: onChangeEmail } = useInputValue();
  const { value: pass, onChange: onChangePass } = useInputValue();

  const { __ } = useLocales();
  const { onEnterPostbox } = useActions();

  const createAccountHandler = () => {
    // onMailCreateAccountModal({ open: true });
  };

  const switchVisibilityButtonsHandler = () => {
    setButtonIsHide(!buttonsIsHide);
  };

  const onSubmit = (): void => {
    const payload = {
      email: email.trim(),
      pass: pass.trim(),
      name: name.trim(),
      messages: {
        succes: __("Почтовый ящик подключен"),
        error: __("Что-то пошло не так. Попробуйте еще раз"),
        errorLogin: __("Не верный пароль или логин"),
        errorBlocked: __("Почтовый ящик заблокирован"),
        errorExist: __("Вход в этот почтовый ящик уже уже выполнен")
      }
    };
    onEnterPostbox(payload);
  };

  return (
    <div className={styles.root}>
      <HeaderModal title={__("Подключить Аккаунт")} onClose={closeModal} />
      <div className={styles.mainContent}>
        <Input value={name} onChange={onChangeName} placeholder="Введите" label="Имя" require />
        <Input value={email} onChange={onChangeEmail} placeholder="Введите" label="Email" require />
        <Input value={pass} onChange={onChangePass} placeholder="Введите" label="Пароль" isPass require />
        <Button
          text={__("Детально")}
          onClick={switchVisibilityButtonsHandler}
          variant={ButtonVariantType.ACCORDION}
          size={ButtonSizeType.EXTRA_SMALL}
          iconR={<DownArrowIcon width={16} height={16} fill="#425c59" />}
        />
        {buttonsIsHide && (
          <div className={styles.buttonsWrapper}>
            <Button
              type="button"
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.MEDIUM}
              text={__("Отменить")}
              onClick={createAccountHandler}
            />
            <Button
              type="button"
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.MEDIUM}
              text={__("Подключить")}
              onClick={onSubmit}
            />
          </div>
        )}
        <div className={styles.divider}>
          <span>или</span>
        </div>
        <div className={styles.google}>
          <span>{__("Подключите аккаунт")}</span>
          <Button
            type="button"
            iconL={<GoogleIcon width={24} height={24} />}
            variant={ButtonVariantType.SPECIAL}
            size={ButtonSizeType.LARGE}
            text={__("Google")}
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default ConnectPostbox;
