import classNames from "classnames";
import { useContentCategories } from "collections/library";
import { PreviewFileLibrary } from "generalComponents/PreviewFileLibrary/PreviewFileLibrary";
import { SectionTitle } from "generalComponents/SectionTitle/SectionTitle";
import { LibraryCategoryTypes } from "generalComponents/variables/library";
import { useDateFormat } from "hooks/useDateFormat";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { FoldersNextType } from "models/store/library/library";
import { FC } from "react";
import { v4 as uuid } from "uuid";

import { PreviewFolderLibrary } from "./components/PreviewFolderLibrary/PreviewFolderLibrary";
import styles from "./FoldersLibrary.module.sass";

interface ILibraryCategoriesProps {
  folders: FoldersNextType;
  isLinePreview?: boolean;
}

const FoldersLibrary: FC<ILibraryCategoriesProps> = ({ folders, isLinePreview = false }): JSX.Element => {
  const { formatDate } = useDateFormat();

  const categories = useContentCategories();

  return Array.isArray(folders) ? (
    !!folders?.length && (
      <div
        className={classNames(styles.list, {
          [styles.isLinePreview]: isLinePreview
        })}
      >
        {folders.map((file) => (
          <PreviewFileLibrary key={uuid()} file={file as IFile} />
        ))}
      </div>
    )
  ) : (
    <div
      className={classNames(styles.listGroup, {
        [styles.isLinePreview]: isLinePreview
      })}
    >
      {folders &&
        Object.entries(folders).map(([key, items]): JSX.Element => {
          let date;
          let category;

          if (isNaN(Number(key))) {
            category = categories[key as LibraryCategoryTypes]?.title;
          } else {
            date = key;
          }

          return (
            !!items.length && (
              <div key={date || category}>
                <SectionTitle variant="center">
                  {date ? formatDate(new Date(`${date}.01`.replace(/[./]/g, "-")), "month") : category}
                </SectionTitle>
                <div
                  className={classNames(styles.list, {
                    [styles.isLinePreview]: isLinePreview
                  })}
                >
                  {items.map((folder) => (
                    <PreviewFolderLibrary
                      key={folder.id_dir}
                      folder={folder as IFolder}
                      isLinePreview={isLinePreview}
                    />
                  ))}
                </div>
              </div>
            )
          );
        })}
    </div>
  );
};

export { FoldersLibrary };
