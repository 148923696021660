import { CONTEXT_MENU_FILE } from "generalComponents/variables/files";
import { EVENT_TYPE, MODALS } from "generalComponents/variables/global";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { TASK_MODALS } from "generalComponents/variables/tasks";
import { IChangeStatusModalState, initialChangeStatusModalState } from "models/store/Cabinet/modals/changeStatusModal";
import {
  IContactsModalState,
  initialContactsModalState,
  initialSocialModalState,
  ISocialModalState
} from "models/store/Cabinet/modals/contactsModal";
import {
  IDashboardJointProjectStatistic,
  initialDashboardJointProjectStatisticState
} from "models/store/Cabinet/modals/dashboardJointProjectStatistic";
import {
  IDashboardJointProjectTeam,
  initialDashboardJointProjectTeamState
} from "models/store/Cabinet/modals/dashboardJointProjectTeams";
import {
  ILinksCategoryModalState,
  initialLinksCategoryModalState
} from "models/store/Cabinet/modals/linksCategoryModal";
import { ILinksModalState, initialLinksModalState } from "models/store/Cabinet/modals/linksModal";
import { initialNoteEditState, INoteEditState } from "models/store/Cabinet/modals/noteEdit";
import { initialNotesCategoryState, INotesCategoryState } from "models/store/Cabinet/modals/notesCategory";
import { initialNotesCategoryFullState, INotesCategoryFullState } from "models/store/Cabinet/modals/notesCategoryFull";
import { initialNotesSidebarState, INotesSidebarState } from "models/store/Cabinet/modals/notesSidebar";
import { initialPasswordModalState, IPasswordModalState } from "models/store/Cabinet/modals/passwordModal";
import { initialRecordModalState, IRecordModalState } from "models/store/Cabinet/modals/record";
import { initialStatusModalState, IStatusModalState } from "models/store/Cabinet/modals/statusModal";
import { initialTagEditState, ITagEditState } from "models/store/Cabinet/modals/tagEdit";
import { initialTariffModalState, ITariffModalState } from "models/store/Cabinet/modals/tariffModal";
import { initialTasksConnectionState, ITasksConnectionModalState } from "models/store/Cabinet/modals/tasksConnection";
import { initialTasksQueueState, ITasksQueueModalState } from "models/store/Cabinet/modals/tasksQueue";
import { initialUserDeleteState, IUserDeleteState } from "models/store/Cabinet/modals/userDelete";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { ISafe } from "models/store/safe/safe";
import { RelativeRoutingType } from "react-router";

import { IAccountDeletingModalState, initialAccountDeletingModalState } from "./accountDeleting";
import { IAllFileViewModalModalState } from "./allFileViewModal";
import { ApproveModal } from "./approveModal";
import { IAvatarCropModalState, initialAvatarCropModalState } from "./avatarCropModal";
import { CalendarModals } from "./calendarModals";
import { ChatModals } from "./chatModals";
import { IConfirmCodeModalState, initialConfirmCodeModalState } from "./confirmCodeModal";
import { ICreateFileModalState, initialCreateFileModalState } from "./createFileModal";
import { ICreateFolderModal, initialCreateFolderState } from "./createFolder";
import { ICreateObjectLibraryModalState, initialCreateObjectLibraryModalState } from "./createObjectLibraryModals";
import { ICreateProjectModal, initialCreateProjectState } from "./createProject";
import { ICreateProjectRoleModal, initialCreateProjectRoleState } from "./createProjectRole";
import { ICreateProjectTaskModal, initialCreateProjectTaskState } from "./createProjectTask";
import { ICreateProjectTeamModal, initialCreateProjectTeamState } from "./createProjectTeam";
import { ICreateRubricLibraryModalState, initialCreateRubricLibraryModalState } from "./createRubricLibraryModals";
import { ICreateSprintModalState, initialCreateSprintModalState } from "./createSprintModal";
import { IFolderPassModal, initialFolderPassState } from "./folderPass";
import { IMoveElementsModal, initialMoveElementsModalState } from "./moveElements";
import {
  IMailDashboardModal,
  IMailNewMessageModal,
  initialMailDashboardModalState,
  initialMailNewMessageModalState,
  initialPostboxModalState,
  IPostboxModalState
} from "./postboxModal";
import { initialProfileModalState, IProfileModalState } from "./profileModals";
import { ProjectModals } from "./projectModals";
import { initialProjectTasksInfoState, IProjectTasksInfoModalState } from "./projectTaskInfoModal";
import { initialPropertiesElementModalState, IPropertiesElementModalState } from "./propertiesElementModals";
import {
  initialRemoveNonEmptyRubricLibraryModalState,
  IRemoveNonEmptyRubricLibraryModalState
} from "./removeNonEmptyRubricLibraryModal";
import { initialRestoreFileModalState, IRestoreFileModalState } from "./restoreFileModals";
import { initialSelectFileModalState, ISelectFilesModalState } from "./selectFileModal";
import { TaskModals } from "./taskModals";
import { initialTasksOverdueState, ITasksOverdueModalState } from "./taskOverdue";
import { initialTransferDutiesModalState, ITransferDutiesModalState } from "./transferDuties";

export interface IModalsState {
  // TODO - mkortelov - each key of the object should be enum key
  error: IErrorModal;
  success: ISuccessModal;
  loader: boolean;
  share: IShareModal;
  previewWithComments: IPreviewWithCommentsModal;
  printScreen: IPrintScreenModal;
  previewFile: IPreviewFileModal;
  topMessage: ITopMessageModal;
  fileAccessRights: IFileAccessRightsModal;
  contextMenuModals: IContextMenuModals; //type name depends on modal to be opened e.g. Share opens Share comp. (see ContextModal comp.)
  taskModals: ITaskModals;
  libraryModals: ILibraryModals;
  calendarModals: ICalendarModals;
  projectModals: IProjectModals;
  chatModals: IChatModals;
  createFolder: ICreateFolderModal;
  createFileModal: ICreateFileModalState;
  approveModal: IApproveModal;
  accessIsLimitedModal: IAccessIsLimitedModal;
  propertiesModal: IPropertiesModal;
  copyLinkModal: ICopyLinkModal;
  folderPassModal: IFolderPassModal;
  editItemsModal: IEditItemsModal;
  customizeFile: ICustomizeFileModal;
  createZip: ICreateZipModal;
  downloadLink: string;
  fileView: IFileViewModal;
  folderNavigation: IFolderNavigationModal;
  filePrint: IFilePrintModal;
  nextAction: INextActionModal;
  shareToMessengers: IShareToMessengersModal;
  mailNewMessage: IMailNewMessageModal;
  safeModals: ISafeModalState;
  moveToSafe: IMoveToSafeModalState;
  postboxModals: IPostboxModalState;
  propertiesElementModals: IPropertiesElementModalState;
  restoreFileModals: IRestoreFileModalState;
  profileModals: IProfileModalState;
  createObjectLibraryModals: ICreateObjectLibraryModalState;
  moveElements: IMoveElementsModal;
  avatarCrop: IAvatarCropModalState;
  confirmCode: IConfirmCodeModalState;
  tariffs: ITariffModalState;
  allFileViewModal: IAllFileViewModalModalState;
  contactsModal: IContactsModalState;
  socialModal: ISocialModalState;
  createRubricLibraryModal: ICreateRubricLibraryModalState;
  removeNonEmptyRubricLibraryModal: IRemoveNonEmptyRubricLibraryModalState;
  mailDashboardModal: IMailDashboardModal;
  transferDuties: ITransferDutiesModalState;
  statusModal: IStatusModalState;
  createProject: ICreateProjectModal;
  changeStatusModal: IChangeStatusModalState;
  createProjectRole: ICreateProjectRoleModal;
  createProjectTeam: ICreateProjectTeamModal;
  createProjectTask: ICreateProjectTaskModal;
  accountDeleting: IAccountDeletingModalState;
  createSprintModal: ICreateSprintModalState;
  linksModal: ILinksModalState;
  linksCategoryModal: ILinksCategoryModalState;
  selectFile: ISelectFilesModalState;
  tasksQueue: ITasksQueueModalState;
  tasksConnection: ITasksConnectionModalState;
  taskProjectInfo: IProjectTasksInfoModalState;
  dashboardJointProjectStatistic: IDashboardJointProjectStatistic;
  dashboardJointProjectTeam: IDashboardJointProjectTeam;
  taskOverdue: ITasksOverdueModalState;
  tagEdit: ITagEditState;
  userDelete: IUserDeleteState;
  noteEdit: INoteEditState;
  record: IRecordModalState;
  notesSidebar: INotesSidebarState;
  passwordModal: IPasswordModalState;
  notesCategories: INotesCategoryState;
  notesFullCategory: INotesCategoryFullState;
}

export type ModalState =
  | IErrorModal
  | ISuccessModal
  | boolean
  | IShareModal
  | IPreviewWithCommentsModal
  | IPrintScreenModal
  | IPreviewFileModal
  | ITopMessageModal
  | IFileAccessRightsModal
  | IContextMenuModals
  | ITaskModals
  | ILibraryModals
  | ICalendarModals
  | IProjectModals
  | IChatModals
  | ICreateFolderModal
  | IApproveModal
  | ICopyLinkModal
  | IEditItemsModal
  | ICustomizeFileModal
  | ICreateZipModal
  | IFolderNavigationModal
  | IShareToMessengersModal
  | IMailSettingsModal
  | ISafeModalState
  | IMoveToSafeModalState;

export interface IErrorModal {
  open: boolean;
  message: string;
}

export interface ISuccessModal {
  open: boolean;
  message: React.ReactNode;
  title: string;
  icon?: JSX.Element;
}

export interface IShareModal {
  open: boolean;
  actionType: ShareActionTypes;
  folders: IFolder[];
  files: IFile[];
}

export interface IPreviewWithCommentsModal {
  open: boolean;
  files: [];
  chosenFile: null;
}

export interface IPrintScreenModal {
  open: boolean;
  result: string;
}

export interface IPreviewFileModal {
  open: boolean;
  file: {
    preview: string;
    mime_type: string;
    ext: string;
    is_preview: number;
    fid: string;
  };
  message: null;
}

export interface ITopMessageModal {
  open: boolean;
  type: ITopMessageTypes;
  variantMessage?: EVENT_TYPE;
  message?: string;
  callback?: () => void | undefined;
  newPath?: string;
}

export interface IFileAccessRightsModal {
  open: boolean;
  file: {};
}

export interface IContextMenuModals {
  type: CONTEXT_MENU_FILE;
  items: any[]; // TODO - androfficial - add structure
  title: string;
  action_type: string;
  filesPage: number;
  filePick: any; // TODO - androfficial - add structure
  menuItem: string;
  path: string;
  idTask: string;
}

export interface IAuthorizedSafe {
  id_safe: string;
  password: string;
  code: string;
}

export interface ITaskModals {
  type: TASK_MODALS;
  params: TaskModals;
}

export interface ILibraryModals {
  type: string;
  params: null;
}

export interface ICalendarModals {
  type: string;
  params: CalendarModals;
}

export interface IProjectModals {
  type: string;
  params: ProjectModals;
}

export interface IChatModals {
  type: string;
  params: ChatModals;
}

export interface IApproveModal {
  open: boolean;
  params: ApproveModal;
}

export interface IAccessIsLimitedModal {
  open: boolean;
  type: ShareContextTypes;
  params: null;
}

export interface IPropertiesModal {
  open: boolean;
  type: PropertiesTypes;
  params: IFolder | IFile;
}

export interface ICopyLinkModal {
  open: boolean;
  params: string;
}

export interface IEditItemsModal {
  open: boolean;
  type: EditItemsTypes;
  params: {
    callbackCancel?: () => void;
    items: (IFolder | IFile)[];
  };
}

export interface ICustomizeFileModal {
  open: boolean;
  params: IFile;
}

export interface ICreateZipModal {
  open: boolean;
  params: (IFolder | IFile)[];
}

export interface IFileViewModal {
  open: boolean;
  params: IFile;
  callback?: () => void;
}

export interface IFolderNavigationModal {
  open: boolean;
  params: {
    url: string;
    options?: {
      replace?: boolean;
      state?: any;
      preventScrollReset?: boolean;
      relative?: RelativeRoutingType;
    };
  };
}

export interface IFilePrintModal {
  open: boolean;
  url: string;
  fid: string;
}

export interface INextActionModal {
  open: boolean;
  params: {
    icon?: JSX.Element;
    title?: string;
    text?: string;
    buttons?: JSX.Element[];
  };
}

export interface IShareToMessengersModal {
  open: boolean;
  params: IFolder | IFile;
  email?: string;
}

export interface IMoveToSafeModalState {
  open: boolean;
  file?: IFile;
  safe?: ISafe;
}

export interface IMailSettingsModal {
  open: boolean;
}

export interface ISafeModalState {
  open: boolean;
  type: SAFE_MODALS;
  safe?: ISafe;
  callbackType?: SAFE_CALLBACK_MODAL;
  alert?: string;
  folder?: IFolder;
}

export enum ITopMessageTypes {
  MESSAGE = "message",
  ERROR = "error",
  SUCCESS = "success"
}

export enum ShareContextTypes {
  NO_TYPE = "",
  EXPAND_WITHOUT_FILES = "EXPAND_WITHOUT_FILES",
  ACCESS_IS_LIMITED = "ACCESS_IS_LIMITED"
}

export enum ShareActionTypes {
  NO_TYPE = "",
  DIR_ACCESS_ADD = "dir_access_add",
  FILE_SHARE = "file_share",
  MIXED = "mixed"
}

export enum PropertiesTypes {
  NO_TYPE = "",
  FOLDER = "folder",
  FILE = "file"
}
export enum EditItemsTypes {
  NO_TYPE = "",
  EDIT_FOLDERS = "editFolders",
  EDIT_FILES = "editFiles"
}

export function initialModalsState(): IModalsState {
  return {
    error: initialErrorState(),
    success: initialSuccessState(),
    loader: false,
    share: initialShareState(),
    previewWithComments: initialPreviewWithCommentsState(),
    printScreen: initialPrintScreenState(),
    previewFile: initialPreviewFileState(),
    topMessage: initialTopMessageState(),
    fileAccessRights: initialFileAccessRightsState(),
    contextMenuModals: initialContextMenuState(),
    taskModals: initialTaskModalState(),
    libraryModals: initialLibraryModalsState(),
    calendarModals: initialCalendarModalsState(),
    projectModals: initialProjectModalsState(),
    chatModals: initialChatModalsState(),
    createFolder: initialCreateFolderState(),
    createFileModal: initialCreateFileModalState(),
    approveModal: initialApproveModalState(),
    accessIsLimitedModal: initialAccessIsLimitedModalState(),
    propertiesModal: initialPropertiesModalState(),
    copyLinkModal: initialCopyLinkModalState(),
    folderPassModal: initialFolderPassState(),
    editItemsModal: initialEditItemsModalState(),
    customizeFile: initialCustomizeFileModalState(),
    createZip: initialCreateZipModalState(),
    downloadLink: "",
    fileView: initialFileViewModalState(),
    folderNavigation: initialFolderNavigationModalState(),
    filePrint: initialFilePrintModalState(),
    nextAction: initialNextActionState(),
    shareToMessengers: initialShareToMessengersModalState(),
    mailNewMessage: initialMailNewMessageModalState(),
    safeModals: initialSafeModalState(),
    moveToSafe: initialMoveToSafeModalState(),
    postboxModals: initialPostboxModalState(),
    propertiesElementModals: initialPropertiesElementModalState(),
    restoreFileModals: initialRestoreFileModalState(),
    profileModals: initialProfileModalState(),
    createObjectLibraryModals: initialCreateObjectLibraryModalState(),
    moveElements: initialMoveElementsModalState(),
    avatarCrop: initialAvatarCropModalState(),
    confirmCode: initialConfirmCodeModalState(),
    tariffs: initialTariffModalState(),
    allFileViewModal: initialFileViewModalState(),
    contactsModal: initialContactsModalState(),
    socialModal: initialSocialModalState(),
    createRubricLibraryModal: initialCreateRubricLibraryModalState(),
    removeNonEmptyRubricLibraryModal: initialRemoveNonEmptyRubricLibraryModalState(),
    mailDashboardModal: initialMailDashboardModalState(),
    transferDuties: initialTransferDutiesModalState(),
    statusModal: initialStatusModalState(),
    createProject: initialCreateProjectState(),
    changeStatusModal: initialChangeStatusModalState(),
    createProjectRole: initialCreateProjectRoleState(),
    createProjectTeam: initialCreateProjectTeamState(),
    createProjectTask: initialCreateProjectTaskState(),
    accountDeleting: initialAccountDeletingModalState(),
    createSprintModal: initialCreateSprintModalState(),
    linksModal: initialLinksModalState(),
    linksCategoryModal: initialLinksCategoryModalState(),
    selectFile: initialSelectFileModalState(),
    tasksQueue: initialTasksQueueState(),
    tasksConnection: initialTasksConnectionState(),
    taskProjectInfo: initialProjectTasksInfoState(),
    dashboardJointProjectStatistic: initialDashboardJointProjectStatisticState(),
    dashboardJointProjectTeam: initialDashboardJointProjectTeamState(),
    taskOverdue: initialTasksOverdueState(),
    tagEdit: initialTagEditState(),
    userDelete: initialUserDeleteState(),
    noteEdit: initialNoteEditState(),
    record: initialRecordModalState(),
    notesSidebar: initialNotesSidebarState(),
    passwordModal: initialPasswordModalState(),
    notesCategories: initialNotesCategoryState(),
    notesFullCategory: initialNotesCategoryFullState()
  };
}

export function initialErrorState(): IErrorModal {
  return { open: false, message: "" };
}

export function initialSuccessState(): ISuccessModal {
  return { open: false, message: "", title: "", icon: null };
}

export function initialShareState(): IShareModal {
  return { open: false, actionType: ShareActionTypes.NO_TYPE, folders: [], files: [] };
}

export function initialPreviewWithCommentsState(): IPreviewWithCommentsModal {
  return { open: false, files: [], chosenFile: null };
}

export function initialPrintScreenState(): IPrintScreenModal {
  return { open: false, result: "" };
}

export function initialPreviewFileState(): IPreviewFileModal {
  return { open: false, file: null, message: null };
}

export function initialTopMessageState(): ITopMessageModal {
  return { open: false, type: ITopMessageTypes.MESSAGE, message: "", callback: undefined };
}

export function initialFileAccessRightsState(): IFileAccessRightsModal {
  return { open: false, file: {} };
}

export function initialContextMenuState(): IContextMenuModals {
  return {
    type: CONTEXT_MENU_FILE.NO_MODAL,
    items: [],
    title: "",
    action_type: "",
    filesPage: 0,
    filePick: null,
    menuItem: "",
    path: "",
    idTask: ""
  };
}

export function initialTaskModalState(): ITaskModals {
  return { type: TASK_MODALS.NO_MODAL, params: null };
}

export function initialLibraryModalsState(): ILibraryModals {
  return { type: MODALS.NO_MODAL, params: null };
}

export function initialCalendarModalsState(): ICalendarModals {
  return { type: MODALS.NO_MODAL, params: null };
}

export function initialProjectModalsState(): IProjectModals {
  return { type: MODALS.NO_MODAL, params: null };
}

export function initialChatModalsState(): IChatModals {
  return { type: MODALS.NO_MODAL, params: null };
}

export function initialApproveModalState(): IApproveModal {
  return { open: false, params: null };
}

export function initialAccessIsLimitedModalState(): IAccessIsLimitedModal {
  return { open: false, type: ShareContextTypes.NO_TYPE, params: null };
}

export function initialPropertiesModalState(): IPropertiesModal {
  return { open: false, type: PropertiesTypes.NO_TYPE, params: null };
}

export function initialCopyLinkModalState(): ICopyLinkModal {
  return { open: false, params: null };
}

export function initialEditItemsModalState(): IEditItemsModal {
  return { open: false, type: EditItemsTypes.NO_TYPE, params: null };
}

export function initialCustomizeFileModalState(): ICustomizeFileModal {
  return { open: false, params: null };
}

export function initialCreateZipModalState(): ICreateZipModal {
  return { open: false, params: null };
}

export function initialFileViewModalState(): IFileViewModal {
  return { open: false, params: null };
}

export function initialFolderNavigationModalState(): IFolderNavigationModal {
  return { open: false, params: null };
}

export function initialFilePrintModalState(): IFilePrintModal {
  return { open: false, url: "", fid: "" };
}

export function initialNextActionState(): INextActionModal {
  return { open: false, params: null };
}

export function initialShareToMessengersModalState(): IShareToMessengersModal {
  return { open: false, params: null, email: "" };
}

export function initialMailSettingsModalState(): IMailSettingsModal {
  return { open: false };
}

export const initialSafeModalState = (): ISafeModalState => {
  return { open: false, type: SAFE_MODALS.NO_MODAL, safe: null, alert: "", folder: null };
};

export const initialMoveToSafeModalState = (): IMoveToSafeModalState => {
  return { open: false, file: null, safe: null };
};
