import { ReactComponent as Card } from "assets/icons/card.svg";
import { ReactComponent as Trash } from "assets/icons/trash_2.svg";
import Button from "generalComponents/Button/Button";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useLocales } from "react-localized";

import styles from "./ServicesItem.module.sass";

interface IServiceItem {
  title: string;
  isPaid: boolean;
  paidUntil: number;
}

const ServicesItem = ({ data }: { data: IServiceItem }) => {
  const { title, paidUntil } = data;
  const { __ } = useLocales();

  const getDateString = (timestamp: number): JSX.Element => {
    const date = new Date(timestamp * 1000);
    const currentDate = new Date();
    const timeDiff = date.getTime() - currentDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (daysDiff > 7) {
      const formattedDate = date.toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit"
      });
      return (
        <span className={styles.success}>
          {__("Уплачено до ")} {formattedDate}
        </span>
      );
    } else if (daysDiff >= 0) {
      return (
        <span className={styles.warning}>
          {__("Заканчивается через")} {daysDiff}
          {__(" дней")}{" "}
        </span>
      );
    } else {
      return <span className={styles.danger}>{__("Ожидает оплаты")}</span>;
    }
  };

  return (
    <div className={styles.item}>
      <h6>{title}</h6>
      <div className={styles.date}> {getDateString(paidUntil)}</div>

      <div className={styles.buttons}>
        <Button
          className={styles.button}
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.EXTRA_SMALL}
          iconL={<Card />}
          text={__("Продлить")}
          onClick={() => {}}
        />
        <Button
          className={styles.button}
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.EXTRA_SMALL}
          iconL={<Trash />}
          onClick={() => {}}
          isSquare
        />
      </div>
    </div>
  );
};

export default ServicesItem;
