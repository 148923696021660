import classNames from "classnames";
import React, { FC, ReactNode } from "react";
import { v4 as uuid } from "uuid";

import styles from "./Tabs.module.sass";

export type TabsType = {
  name?: string;
  icon?: ReactNode;
  onClick: () => void;
  value?: string;
  tip?: string;
};

interface Props {
  tabs: TabsType[];
  value: string;
  variant?: "main" | "secondary" | "dark" | "darkGreen";
  size?: "small" | "medium" | "large"; // 24px | 28px | 32px
  full?: boolean;
}

const Tabs: FC<Props> = ({ tabs, value, variant = "main", size = "medium", full = false }) => {
  const buttonClickHandler = (callback: () => void) => {
    callback();
  };

  return (
    <div className={classNames(styles.tabsWrapper, styles[variant], styles[size], { [styles.full]: full })}>
      {tabs.map((tab) => (
        <button
          className={classNames({ [styles.active]: value === tab.value })}
          onClick={() => buttonClickHandler(tab.onClick)}
          key={uuid()}
        >
          {tab.tip && <span className={styles.tip}>{tab.tip}</span>}
          {Boolean(tab.name) && tab.name}
          {Boolean(tab?.icon) && tab.icon}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
