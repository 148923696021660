import { ReactComponent as NoteIcon } from "assets/icons/note.svg";
import { ReactComponent as PositionIcon } from "assets/icons/position.svg";
import { ReactComponent as SafeIcon } from "assets/icons/safe.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as BellIcon } from "assets/PrivateCabinet/bell.svg";
import { ReactComponent as TaskIcon } from "assets/PrivateCabinet/calendar-4.svg";
import { ReactComponent as FolderIcon } from "assets/PrivateCabinet/folder.svg";
import { ReactComponent as MailIcon } from "assets/PrivateCabinet/mail/unread.svg";
import { ReactComponent as MeetingIcon } from "assets/PrivateCabinet/meeting.svg";
import { ReactComponent as GrantedAccessIcon } from "assets/PrivateCabinet/myFolders/contextMenu/granted-access.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-5.svg";
import { ReactComponent as ArchiveIcon } from "assets/PrivateCabinet/sideMenu/archive.svg";
import { ReactComponent as LibraryIcon } from "assets/PrivateCabinet/sideMenu/library.svg";
import { ReactComponent as ProgramsIcon } from "assets/PrivateCabinet/sideMenu/programs.svg";
import { ReactComponent as GroupProjectIcon } from "assets/PrivateCabinet/sideMenu/project.svg";
import { EVENT_TYPE, TYPE_ELEMENTS } from "generalComponents/variables/global";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { TypeElements } from "models/typeElements/typeElements";
import { useLocales } from "react-localized";

export const useMenuFilterJournalTypeElements = (): ISelectCustom[] => {
  const { __ } = useLocales();
  const { onAddFilterTypeElements } = useActions();

  return [
    {
      name: __(`Все объекты`),
      value: "",
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Все объекты`),
          table: null,
          id_type: null,
          value: ""
        });
      }
    },
    {
      name: __(`Файлы и Папки`),
      value: `${TYPE_ELEMENTS.FILES}, ${TYPE_ELEMENTS.FOLDERS}`,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Файлы и Папки`),
          table: [TYPE_ELEMENTS.FILES, TYPE_ELEMENTS.FOLDERS],
          id_type: null,
          event_type: null,
          value: `${TYPE_ELEMENTS.FILES}, ${TYPE_ELEMENTS.FOLDERS}`
        });
      },
      icon: <FolderIcon width={16} height={16} />
    },
    {
      name: __(`Сейф`),
      value: TYPE_ELEMENTS.SAFES,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Сейф`),
          table: [TYPE_ELEMENTS.SAFES],
          id_type: null,
          event_type: null,
          value: TYPE_ELEMENTS.SAFES
        });
      },
      icon: <SafeIcon width={16} height={16} fill="#1565C0" />
    },
    {
      name: __(`Совместный проект`),
      value: TypeElements.PROJECT,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Совместный проект`),
          table: [TypeElements.PROJECT],
          id_type: null,
          event_type: null,
          value: TypeElements.PROJECT
        });
      },
      icon: <GroupProjectIcon width={16} height={16} fill="#2FB292" />
    },
    {
      name: __(`Почта`),
      value: TYPE_ELEMENTS.EMAILS,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Почта`),
          table: [TYPE_ELEMENTS.EMAILS],
          id_type: null,
          event_type: null,
          value: TYPE_ELEMENTS.EMAILS
        });
      },
      icon: <MailIcon width={16} height={16} fill="#1565C0" />
    },
    {
      name: __(`Архив`),
      value: `${EVENT_TYPE.ARCHIVE}, ${EVENT_TYPE.UNARCHIVE}`,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Архив`),
          table: null,
          id_type: null,
          event_type: [EVENT_TYPE.ARCHIVE, EVENT_TYPE.UNARCHIVE],
          value: `${EVENT_TYPE.ARCHIVE}, ${EVENT_TYPE.UNARCHIVE}`
        });
      },
      icon: <ArchiveIcon width={16} height={16} fill="#E4C500" />
    },
    {
      name: __(`Программы`),
      value: TYPE_ELEMENTS.SOFT,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Программы`),
          table: [TYPE_ELEMENTS.SOFT],
          id_type: null,
          event_type: null,
          value: TYPE_ELEMENTS.SOFT
        });
      },
      icon: <ProgramsIcon width={16} height={16} fill="#4086F1" />
    },
    {
      name: __("Библиотека"),
      value: `${TYPE_ELEMENTS.LIBRARY}, ${TYPE_ELEMENTS.DEPS}`,
      callback: () => {
        onAddFilterTypeElements({
          name: __("Библиотека"),
          table: [TYPE_ELEMENTS.LIBRARY, TYPE_ELEMENTS.DEPS],
          id_type: null,
          event_type: null,
          value: `${TYPE_ELEMENTS.LIBRARY}, ${TYPE_ELEMENTS.DEPS}`
        });
      },
      icon: <LibraryIcon width={16} height={16} fill="#4D35BE" />
    },
    {
      name: __(`Предоставленный доступ`),
      value: `${EVENT_TYPE.SHARE}, ${EVENT_TYPE.SHARE_ME}`,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Предоставленный доступ`),
          table: null,
          id_type: null,
          event_type: [EVENT_TYPE.SHARE, EVENT_TYPE.SHARE_ME],
          value: `${EVENT_TYPE.SHARE}, ${EVENT_TYPE.SHARE_ME}`
        });
      },
      icon: <GrantedAccessIcon width={16} height={16} fill="#274A42" />
    },
    {
      name: __(`Корзина`),
      value: `${EVENT_TYPE.IS_DEL}, ${EVENT_TYPE.UN_IS_DEL}, ${EVENT_TYPE.DEL}`,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Корзина`),
          table: null,
          id_type: null,
          event_type: [EVENT_TYPE.IS_DEL, EVENT_TYPE.UN_IS_DEL, EVENT_TYPE.DEL],
          value: `${EVENT_TYPE.IS_DEL}, ${EVENT_TYPE.UN_IS_DEL}, ${EVENT_TYPE.DEL}`
        });
      },
      icon: <TrashIcon width={16} height={16} fill="#274A42" />
    },
    {
      name: __(`Должности`),
      value: "5",
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Должности`),
          table: [TypeElements.TASKS],
          id_type: [],
          event_type: null,
          value: "5"
        });
      },
      icon: <PositionIcon width={16} height={16} fill="#274A42" />
    },
    {
      name: "",
      label: __(`Задачи`)
    },
    {
      name: __(`Задача`),
      value: TASK_TYPES.TASK,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Задача`),
          table: [TypeElements.TASKS],
          id_type: [TASK_TYPES.TASK],
          event_type: null,
          value: TASK_TYPES.TASK
        });
      },
      icon: <TaskIcon width={16} height={16} fill="#4086F1" />
    },
    {
      name: __(`Встречи`),
      value: `${TASK_TYPES.ONLINE_MEETING}, ${TASK_TYPES.OFFLINE_MEETING}`,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Встречи`),
          table: [TYPE_ELEMENTS.TASKS],
          id_type: [TASK_TYPES.ONLINE_MEETING, TASK_TYPES.OFFLINE_MEETING],
          event_type: null,
          value: `${TASK_TYPES.ONLINE_MEETING}, ${TASK_TYPES.OFFLINE_MEETING}`
        });
      },
      icon: <MeetingIcon width={16} height={16} />
    },
    {
      name: __(`Звонки`),
      value: TASK_TYPES.CALLS,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Встречи`),
          table: [TYPE_ELEMENTS.TASKS],
          id_type: [TASK_TYPES.CALLS],
          event_type: null,
          value: TASK_TYPES.CALLS
        });
      },
      icon: <PhoneIcon width={16} height={16} fill="#2ABD27" />
    },
    {
      name: __(`Письма`),
      value: TASK_TYPES.MAILS,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Письма`),
          table: [TYPE_ELEMENTS.TASKS],
          id_type: [TASK_TYPES.MAILS],
          event_type: null,
          value: TASK_TYPES.MAILS
        });
      },
      icon: <MailIcon width={16} height={16} fill="#F0BA2E" />
    },
    {
      name: __(`Напоминание`),
      value: TASK_TYPES.REMINDER,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Напоминание`),
          table: [TYPE_ELEMENTS.TASKS],
          id_type: [TASK_TYPES.REMINDER],
          event_type: null,
          value: TASK_TYPES.REMINDER
        });
      },
      icon: <BellIcon width={16} height={16} fill="#26c0ca" />
    },
    {
      name: __(`Заметка`),
      value: TASK_TYPES.NOTES,
      callback: () => {
        onAddFilterTypeElements({
          name: __(`Заметка`),
          table: [TYPE_ELEMENTS.TASKS],
          id_type: [TASK_TYPES.NOTES],
          value: TASK_TYPES.NOTES
        });
      },
      icon: <NoteIcon />
    }
  ];
};

export const useActionsJournal = (): Record<EVENT_TYPE, string> => {
  const { __ } = useLocales();

  return {
    [EVENT_TYPE.ADD]: __("Создано"),
    [EVENT_TYPE.LIST]: __("Открыто"),
    [EVENT_TYPE.EDIT]: __("Изменено"),
    [EVENT_TYPE.SHARE]: __("Предоставленный доступ (мной)"),
    [EVENT_TYPE.SHARE_ME]: __("Предоставленный доступ (мне)"),
    [EVENT_TYPE.SEND]: __("Поделится"),
    [EVENT_TYPE.ADD_FAVORITE]: __("Добавлено в Избранное"),
    [EVENT_TYPE.UNFAVORITE]: __("Удалено из Избранного"),
    [EVENT_TYPE.EDIT_NAME]: __("Переименовано"),
    [EVENT_TYPE.MOVE]: __("Перемещено"),
    [EVENT_TYPE.COPY]: __("Скопировано"),
    [EVENT_TYPE.SAFE]: __("Перемещено в Сейф"),
    [EVENT_TYPE.ARCHIVE]: __("Перемещено в архив"),
    [EVENT_TYPE.UNARCHIVE]: __("Восстановлено из архива"),
    [EVENT_TYPE.IS_DEL]: __("Перемещено в корзину"),
    [EVENT_TYPE.UN_IS_DEL]: __("Восстановлено из корзины"),
    [EVENT_TYPE.DEL]: __("Удалено"),
    [EVENT_TYPE.ZIP]: __("Запаковано в zip"),
    [EVENT_TYPE.DOWNLOAD]: __("Скачано"),
    [EVENT_TYPE.GET_ACCES]: __("Открыто"),
    [EVENT_TYPE.GET_NO_ACCES]: __("Попытка открыть"),
    [EVENT_TYPE.OPEN_BLOCKED]: __("Попытка открыть (заблокировано)"),
    [EVENT_TYPE.PASS_CHANGE]: __("Изменен пароль"),
    [EVENT_TYPE.PASS_ADD]: __("Установлен пароль"),
    [EVENT_TYPE.PASS_DEL]: __("Пароль удален"),
    [EVENT_TYPE.PASS_RESTORE]: __("Попытка восстановить пароль"),
    [EVENT_TYPE.PASS_WRONG]: __("Неверный пароль"),
    [EVENT_TYPE.EDIT_STATUS]: __("Изменен статус"),
    [EVENT_TYPE.REG]: __("Новый аккаунт"),
    [EVENT_TYPE.ENTER]: __("Аккаунт подключен"),
    [EVENT_TYPE.EMPLOYEE_ENTER]: __("Вход под сотрудником"),
    [EVENT_TYPE.MESSAGE_DEL]: __("Письмо удалено"),
    [EVENT_TYPE.BLOCK]: __("Заблокировано"),
    [EVENT_TYPE.UNBLOCK]: __("Разблокировано"),
    [EVENT_TYPE.LOGGOF]: __("Завершить"),
    [EVENT_TYPE.USER_COPY]: __("Передача обязанностей"),
    [EVENT_TYPE.USER_DEL]: __("Удалено"),
    [EVENT_TYPE.USER_UNDEL]: __("Восстановлено"),
    [EVENT_TYPE.PRINTED]: __("Отправлено на печать"),
    [EVENT_TYPE.SHARED]: __("Параметры доступа обновлены"),
    [EVENT_TYPE.RESTORED]: __("Восстановлено")
  };
};

export const useEventGroupElement = () => {
  const { __ } = useLocales();

  return {
    [TYPE_ELEMENTS.FOLDERS]: __("Папки"),
    [TYPE_ELEMENTS.FILES]: __("Файлы"),
    [TYPE_ELEMENTS.LIBRARY]: __("Библиотека"),
    [TYPE_ELEMENTS.SAFES]: __("Сейф"),
    [TYPE_ELEMENTS.TASKS]: __("Задачи"),
    [TYPE_ELEMENTS.EMAILS]: __("Почта"),
    [TYPE_ELEMENTS.SOFT]: __("Программы"),
    [TYPE_ELEMENTS.USERS]: __("Мои данные"),
    [TYPE_ELEMENTS.ORG_USERS]: __("Сотрудники"),
    [TYPE_ELEMENTS.SESSION_USERS]: __("Активные сессии"),
    [TYPE_ELEMENTS.DEPS]: __("Библиотека (рубрика)")
  };
};
