import { ReactComponent as ExclamationIcon } from "assets/icons/exclamation_mark.svg";
import Button from "generalComponents/Button/Button";
import Tabs from "generalComponents/Tabs/Tabs";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { useAddEmployeeMail } from "hooks/employeesHooks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ITopMessages } from "models/common/common";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { PROFILE_MODALS } from "models/store/Cabinet/modals/profileModals";
import { IUserInfo } from "models/store/user/user";
import { IAddEmployeePayload } from "models/store/user/userPayloads";
import { FC, ReactNode, useEffect, useReducer, useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { dataURLToFileSize } from "utils/filesConvert";
import { isEmail } from "utils/validation";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./AddEmployee.module.sass";
import EmployeeActions from "./EmployeeActions/EmployeeActions";
import EmployeeData from "./EmployeeData/EmployeeData";
import EmployeeRights from "./EmployeeRights/EmployeeRights";
import {
  DataFormTypes,
  editData,
  INITIAL_DATA_STATE,
  INITIAL_RIGHTS_STATE,
  reducerData,
  reducerRights
} from "./lib/stateReducer";

interface IProps {
  closeModal: () => void;
  type: PROFILE_MODALS;
  employee: IUserInfo;
}

const AddEmployee: FC<IProps> = ({ closeModal, type, employee }) => {
  const { __ } = useLocales();
  const { userInfo } = useUserSelectors();
  const { addEmployee, editEmployee, onSetApproveModal, retryEmployee } = useActions();
  const _isEdit = type === PROFILE_MODALS.EDIT_EMPLOYEE;
  const [tabValue, setTabValue] = useState<string>("1");
  const [isEditEmail, setIsEditEmail] = useState<boolean>(false);
  const [isEditTel, setIsEditTel] = useState<boolean>(false);
  const emailHTML = useAddEmployeeMail();
  const { formatDate } = useDateFormat();

  const [data, dispatchData] = useReducer(reducerData, _isEdit ? editData(employee) : INITIAL_DATA_STATE);
  const [rights, dispatchRights] = useReducer(reducerRights, _isEdit ? employee.rights : INITIAL_RIGHTS_STATE);

  useEffect(() => {
    if (!_isEdit) return;
    if (employee.email.trim() !== data.email.trim()) {
      setIsEditEmail(true);
    } else {
      setIsEditEmail(false);
    }
    if (
      (employee.tel && "+" + employee.tel?.trim() !== data.tel.trim()) ||
      (!employee.tel && employee.tel?.trim() !== data.tel.trim())
    ) {
      setIsEditTel(true);
    } else {
      setIsEditTel(false);
    }
  }, [_isEdit, data?.email, data?.tel, employee?.email, employee?.tel]);

  const onChangeEmail = () => {
    const params = {
      titleHead: __("Изменить"),
      text: (
        <p style={{ color: "#274A42" }}>
          <b>{__("Основной Email")}</b>
          {__(
            " необходим для авторизации на платформе, поьзователь больше не сможет авторизироваться используя старый email."
          )}
          <br />
          <span style={{ color: "#56716B" }}>{__("Убедитесь в правильности ввода")}</span>
        </p>
      ),
      approveBtn: __("Изменить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => setIsEditEmail(false)
    };

    onSetApproveModal({ open: true, params });
  };

  const onChangeTel = () => {
    const params = {
      titleHead: __("Изменить"),
      text: (
        <p style={{ color: "#274A42" }}>
          <b>{__("Основной Телефон ")}</b>
          {__("необходим для авторизации на платформе и доступу к сейфам")}
          <br />
          <span style={{ color: "#56716B" }}>{__("Убедитесь в правильности ввода")}</span>
        </p>
      ),
      approveBtn: __("Изменить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => setIsEditTel(false)
    };

    onSetApproveModal({ open: true, params });
  };

  const viewsTabs = [
    {
      name: __("Данные"),
      onClick: () => setTabValue("1"),
      value: "1"
    },
    {
      name: __("Привилегии"),
      onClick: () => setTabValue("2"),
      value: "2"
    }
  ];

  const onAddEmployee = async () => {
    if (!isEmail(data.email)) {
      dispatchData({ type: DataFormTypes.CHANGE, field: "emailError", value: __("не корректный email") });
      return;
    }
    const tels = Object.values(data.tels)
      .map((t) => t.replace("+", ""))
      .filter((v) => v);
    const emails = Object.values(data.emails).filter((v) => v);
    let icon = null;
    if (data.icon?.startsWith("data:image/jpeg;base64")) {
      icon = await dataURLToFileSize(data.icon);
    }
    const params: IAddEmployeePayload = {
      data: {
        fname: data.fname,
        sname: data.sname,
        pname: data.pname,
        email: data.email.trim(),
        tel: data.tel.replace("+", ""),
        tels: JSON.stringify(tels),
        emails: JSON.stringify(emails),
        id_company: userInfo.id_company,
        is_admin: "0",
        rights: JSON.stringify(rights),
        icon: icon,
        id_item: employee?.id ? employee.id : undefined,
        html: type === PROFILE_MODALS.ADD_EMPLOYEE ? emailHTML : undefined,
        icon_del: Boolean(!data.icon)
      },
      message: { succes: EVENT_TYPE.ADD, error: __("Пользователь с таким email уже зарегестрирован!") }
    };

    const message: ITopMessages = {
      success: __("Данные обновлены"),
      error: ""
    };

    _isEdit ? editEmployee(params, message) : addEmployee(params);
  };

  const onRepeateInvite = async () => {
    const payload = {
      data: {
        id_item: employee.id,
        id_company: employee.id_company
      },
      message: { succes: __("Приглашение отправлено!") }
    };
    retryEmployee(payload);
  };

  const renderRepeateBlock = (): ReactNode => {
    if (employee.pass) return;

    return (
      <div className={styles.repeateBlock}>
        <div className={styles.svgBlock}>
          <ExclamationIcon />
        </div>
        <div>
          <p className={styles.text}>
            {__(
              `${formatDate(employee.date_send, "full")} на ${employee.email} отправлена инструкция настройке акаунта`
            )}
          </p>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.MEDIUM}
            onClick={onRepeateInvite}
            text={__("Отправить повторно")}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.wrap}>
      <HeaderModal title={_isEdit ? __("Изменить") : __("Добавить")} onClose={closeModal} />
      {_isEdit && !employee?.is_admin && <EmployeeActions employee={employee} closeModal={closeModal} />}
      <div className={styles.body}>
        {!employee?.is_admin && (
          <div className={styles.tabBlock}>
            <Tabs tabs={viewsTabs} value={tabValue} size="medium" variant="secondary" />
          </div>
        )}
        <div className={styles.form}>
          {tabValue === "1" && (
            <EmployeeData
              data={data}
              dispatchData={dispatchData}
              isEditEmail={isEditEmail}
              isEditTel={isEditTel}
              onChangeEmail={onChangeEmail}
              onChangeTel={onChangeTel}
              isEdit={_isEdit}
              repeateBlock={_isEdit ? renderRepeateBlock() : undefined}
            />
          )}
          {tabValue === "2" && <EmployeeRights rights={rights} dispatchRights={dispatchRights} />}
        </div>

        <div className={styles.btns}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.MEDIUM}
            onClick={closeModal}
            text={__("Отменить")}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.MEDIUM}
            onClick={onAddEmployee}
            text={__("Сохранить")}
            disabled={!data.email || isEditEmail || isEditTel}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
