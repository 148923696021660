import { ReactComponent as AddIcon } from "assets/PrivateCabinet/add-2.svg";
import { ReactComponent as CollapseIcon } from "assets/PrivateCabinet/collapse.svg";
import { ReactComponent as ExpandIcon } from "assets/PrivateCabinet/frame.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-5.svg";
import { useTaskStatuses } from "collections/tasks";
import { TASK_MODALS, TASK_TYPES, TaskFilters, TASKS_SCHEMA } from "generalComponents/variables/tasks";
import { useTasks } from "hooks/tasksHooks";
import { useActions } from "hooks/useActions";
import { ITaskStatus } from "models/generalComponents/collections/tasks";
import { IMyTask, TASK_DEP } from "models/store/tasks/tasks";
import React, { useContext } from "react";
import { useLocales } from "react-localized";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";
import { groupByDay, groupByMonth, groupByWeek, groupByYear, INITIAL_ADD_TASK, taskOfStatus } from "utils/tasks";

import CallItem from "../../../Pages/Cabinet/Components/Tasks/Components/CallItem/CallItem";
import StatusLine from "../../../Pages/Cabinet/Components/Tasks/Components/StatusLine/StatusLine";
import TaskDate from "../../../Pages/Cabinet/Components/Tasks/Components/TaskDate/TaskDate";
import { MyTasksProvider } from "../GridBoard/GridBoard";
import styles from "./Calls.module.sass";

const Calls: React.FC = (): JSX.Element => {
  const { __ } = useLocales();
  const { schema, setSchema } = useContext(MyTasksProvider);

  const { onSetTaskModal } = useActions();
  const { otherTasks } = useTaskStatuses();

  const { calls } = useTasks();
  const { filters } = useTasksSelectors();

  const onExpand = (): void => {
    setSchema((prev) =>
      prev === TASKS_SCHEMA.EXPANDED_CALLS_BOARD ? TASKS_SCHEMA.GRID_BAR : TASKS_SCHEMA.EXPANDED_CALLS_BOARD
    );
  };

  const getGroup = (): Record<string, IMyTask[]> => {
    switch (filters.type) {
      case TaskFilters.BY_DAY:
        return groupByDay(calls, filters.date);
      case TaskFilters.BY_WEEK:
        return groupByWeek(calls, filters.date);
      case TaskFilters.BY_MONTH:
        return groupByMonth(calls, filters.date);
      case TaskFilters.BY_YEAR:
        return groupByYear(calls, filters.date);

      default:
        break;
    }
  };
  const onAddTask = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.ADD_CALL,
      params: { ...INITIAL_ADD_TASK, id_type: TASK_TYPES.CALLS, id_dep: TASK_DEP.NO_DEP }
    });
  };

  const renderStatus = (status: ITaskStatus, values: IMyTask[]) => {
    const tasks = taskOfStatus(status.status, values);
    return (
      tasks.length > 0 && (
        <StatusLine title={status.nameCall}>
          {tasks.map((t) => (
            <CallItem key={t.id} task={t} />
          ))}
        </StatusLine>
      )
    );
  };

  return (
    <div className={styles.meetingWrap}>
      <div className={styles.panel}>
        <div className={styles.block}>
          <AddIcon onClick={onAddTask} className={styles.addIcon} />
          <span>{__("Звонки")}</span>
          <PhoneIcon fill="#08C326" width={13} height={13} />
        </div>
        <button className={styles.expandIcon} onClick={onExpand}>
          {schema === TASKS_SCHEMA.EXPANDED_CALLS_BOARD ? <CollapseIcon /> : <ExpandIcon />}
        </button>
      </div>
      <div className={styles.wrap}>
        {Object.entries(getGroup()).map(([key, values], i) => (
          <div key={key}>
            <TaskDate amountWeeks={Object.keys(getGroup()).length} idx={i + 1} time={key} values={values} />
            {otherTasks.map((el, i) => (
              <React.Fragment key={i}>{renderStatus(el, values)}</React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calls;
