import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload_3.svg";
import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow_down.svg";
import Button from "generalComponents/Button/Button";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { ShellContext } from "generalComponents/Shell/Shell";
import Tabs from "generalComponents/Tabs/Tabs";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { useProjectMainAccess } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useContext, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectDocumentationPanel.module.sass";
import { JointProjectsCreateMenu } from "./JointProjectsCreateMenu/JointProjectsCreateMenu";

export const JointProjectDocumentationPanel = ({ tabs, activeTab }: { tabs: any; activeTab: any }) => {
  const [showCreateMenu, setShowCreateMenu] = useState(false);
  const { __ } = useLocales();

  const { onSetSelectedUploadReplacementFile } = useActions();

  const { fileSelect } = useContext(ShellContext);

  const navigate = useNavigate();

  const toggleShowCreateMenu = () => {
    setShowCreateMenu((prev) => !prev);
  };

  const isMainAccess = useProjectMainAccess();

  const { project } = useJoinProjectsSelectors();

  return (
    <ToolsPanel size="large">
      <div className={styles.buttons}>
        <Button
          variant={ButtonVariantType.EXRTA_LIGHT}
          onClick={() => navigate(-1)}
          iconL={<ArrowLeftIcon />}
          size={ButtonSizeType.SMALL}
        />
        <Tabs tabs={tabs} value={activeTab} size="medium" variant="main" />
      </div>
      {(isMainAccess || project.accesses.MGR_DOCS) && (
        <div className={styles.buttons}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            onClick={() => {
              onSetSelectedUploadReplacementFile(null);
              fileSelect();
            }}
            iconL={<UploadIcon />}
            size={ButtonSizeType.SMALL}
            text={__("Загрузить")}
          />
          <PopoverOverlay
            overlayContent={<JointProjectsCreateMenu setShowCreateMenu={() => setShowCreateMenu(false)} />}
            placement="bottom"
            classes={{ popoverBody: styles.nopadding }}
            show={showCreateMenu}
            setShow={toggleShowCreateMenu}
            btn="custom"
            variantBtn={ButtonVariantType.BLUE}
            sizeBtn={ButtonSizeType.SMALL}
            iconRBtn={<ArrowIcon />}
            title={__("Создать")}
            visibleHeader={false}
          />
        </div>
      )}
    </ToolsPanel>
  );
};
