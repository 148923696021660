import { useFindPostbox } from "hooks/postboxHooks";
import { useDateFormat } from "hooks/useDateFormat";
import { ILetterInfo } from "models/store/postbox/postbox";
import React, { useCallback, useEffect } from "react";
import { useLocales } from "react-localized";
import { renderHeader, renderSubject } from "utils/mail";

interface IMailPrintProps {
  closeModal: () => void;
  letter: ILetterInfo;
}

const MailPrint: React.FC<IMailPrintProps> = ({ letter, closeModal }) => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const { postbox } = useFindPostbox();
  useEffect(() => {
    printFile();
  }, []); // eslint-disable-line

  const printFile = useCallback((): void => {
    // @ts-ignore
    const iframe = document.frames ? document.frames["printLetter"] : document.getElementById("printLetter");
    const iframeWindow = iframe.contentWindow || iframe;
    setTimeout(() => {
      iframe.focus(iframe);
      iframeWindow.print();

      closeModal();
    }, 1000);
    return;
  }, [closeModal]);

  const renderLetterInfo = (): string => {
    const time = formatDate(letter.time, `fullDay`);

    return `
        <div style="margin-bottom: 16px; padding: 0 16px">
          <p style="margin: 0 0 4px">
            <span style="font-style: italic">${__("Дата:")}</span>&nbsp;
            ${time}
          </p>
          <p style="margin: 0 0 4px">
            <span style="font-style: italic">${__("Від:")}</span>&nbsp;
            ${letter.from_name || ""}&lt;${letter.from}&gt;
          </p>
          <p style="margin: 0 0 4px">
            <span style="font-style: italic">${__("Кому:")}</span>&nbsp;
            ${postbox.name || ""}&lt;${postbox.email}&gt;
          </p>
        </div>
        `;
  };

  const getPrint = () => {
    return `
    <div style="font-size: 16px; font-family: sans-serif; max-width: 750px">   
        ${renderHeader(postbox)}
        ${renderSubject(letter.subject || __("без темы"))} 
        ${renderLetterInfo()}
        ${letter.html}
     </div>`;
  };

  return <iframe srcDoc={getPrint()} style={{ display: "none" }} id="printLetter" title="mail" />;
};

export default MailPrint;
