import { ReactComponent as ScreenIcon } from "assets/icons/screen.svg";
import VideoPreview from "containers/VideoRecord/VidePreview";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useReactMediaRecorder } from "react-media-recorder";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./VideoRecord.module.sass";

const VideoRecorder = ({
  fromScreen,
  setFromScreen
}: {
  fromScreen: boolean;
  setFromScreen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { __ } = useLocales();
  const [isLoading, setIsLoading] = useState(false);
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } = useReactMediaRecorder({
    video: true,
    audio: true,
    askPermissionOnMount: true,
    screen: fromScreen,
    blobPropertyBag: { type: "video/mp4" }
  });
  const {
    record: { callBack }
  } = useGlobalModalsSelectors();

  const onStop = async (mediaBlobUrl: any) => {
    try {
      setIsLoading(true);
      // Получаем blob из mediaBlobUrl
      const response = await fetch(mediaBlobUrl);
      const blob = await response.blob();
      // Вызываем callBack с полученным blob
      callBack(blob);
    } catch (error) {
      console.error("Error saving media blob:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!mediaBlobUrl) return;
    onStop(mediaBlobUrl);
  }, [mediaBlobUrl]); //eslint-disable-line

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loader}>
          <Loader
            position="relative"
            width="100px"
            height="100px"
            type={LoaderTypes.BOUNCING_DOTS}
            containerType="bounceDots"
          />
        </div>
      ) : (
        <>
          <div className={styles.content}>
            {previewStream ? (
              <VideoPreview stream={previewStream} />
            ) : (
              mediaBlobUrl && <video src={mediaBlobUrl} controls autoPlay />
            )}
          </div>
          <div className={styles.buttons}>
            {status !== "recording" && (
              <Button
                variant={ButtonVariantType.LIGHT}
                size={ButtonSizeType.SMALL}
                onClick={() => {
                  if (!fromScreen) {
                    setFromScreen(true);
                  } else {
                    setFromScreen(false);
                  }
                }}
                iconL={!fromScreen ? <ScreenIcon /> : null}
                text={fromScreen ? __("Запись с камеры") : __("Запись экрана")}
              />
            )}
            <Button
              variant={status === "recording" ? ButtonVariantType.RED : ButtonVariantType.DARK_GREEN}
              size={ButtonSizeType.SMALL}
              onClick={status === "recording" ? stopRecording : startRecording}
              text={status === "recording" ? __("Стоп") : __("Старт")}
            />
          </div>
        </>
      )}
    </div>
  );
};

const VideoRecord = () => {
  const [fromScreen, setFromScreen] = useState(false);
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    setShowComponent(false);
    const timeout = setTimeout(() => {
      setShowComponent(true);
    }, 100);
    return () => clearTimeout(timeout);
  }, [fromScreen]);

  if (!showComponent) return null;

  return <VideoRecorder fromScreen={fromScreen} setFromScreen={setFromScreen} />;
};

export default VideoRecord;
