import { JointProjectSearch } from "containers/JoinProject/JointProjectSearch/JointProjectSearch";
import ParticipantsButtons from "containers/JoinProject/JointProjectsTasks/components/ParticipantsButtons/ParticipantsButtons";
import TeamButtons from "containers/JoinProject/JointProjectsTasks/components/TeamButtons/TeamButtons";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";

import styles from "./JointProjectTrashPanel.module.sass";

export const JointProjectTrashPanel = () => {
  const { search, team, participants } = useAllSelectedSearchParams();

  return (
    <ToolsPanel size="extraLarge">
      <div className={styles.wrapp}>
        <div className={styles.tools}>
          <div className={styles.buttons}>
            <TeamButtons selectedTeam={team.selectedValue} onSelectTeam={team.onSelectValue} />
          </div>
          <JointProjectSearch searchQuery={search.selectedValue} setSearchQuery={search.onSelectValue} />
        </div>
        <ParticipantsButtons
          onSelectParticipiant={participants.onSelectMultipleValue}
          teamId={team.selectedValue}
          selectedParticipants={participants.selectedValue}
        />
      </div>
    </ToolsPanel>
  );
};
