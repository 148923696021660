import { FC } from "react";
import { useLocales } from "react-localized";

import styles from "./EventHead.module.sass";

export const EventHead: FC = () => {
  const { __ } = useLocales();

  return (
    <div className={styles.wrapp}>
      <div>{__("Инструмент")}</div>
      <div>{__("Объект")}</div>
      <div>{__("Дата")}</div>
      <div>{__("Действие")}</div>
    </div>
  );
};
