import { IEvent, IJournalState, initialJournalState } from "models/store/journal/journal";
import { JournalActions } from "models/store/journal/journalActions";
import { JournalEventsTypes } from "Store/types/journalEventsTypes";

const INITIAL_STATE: IJournalState = initialJournalState();

export const JournalReducer = (state = INITIAL_STATE, action: JournalActions): IJournalState => {
  switch (action.type) {
    case JournalEventsTypes.SET_JOURNAL_LOADER:
      return { ...state, loader: action.payload };

    case JournalEventsTypes.SET_JOURNAL_ELEMENTS:
      if (state.events) {
        return {
          ...state,
          events: [...(state.events as IEvent[]), ...action.payload]
        };
      } else {
        return {
          ...state,
          events: action.payload
        };
      }

    case JournalEventsTypes.SET_JOURNAL_PAGE:
      return { ...state, page: action.payload };

    case JournalEventsTypes.SET_TOTAL_JOURNAL_ELEMENTS:
      return { ...state, total: action.payload };

    case JournalEventsTypes.RESET_JOURNAL_ELEMENTS:
      return { ...state, events: null, page: 1, total: 0 };

    case JournalEventsTypes.NULLIFY_JOURNAL:
      return INITIAL_STATE;

    default:
      return state;
  }
};
