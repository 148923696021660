import { EVENT_TYPE } from "generalComponents/variables/global";
import { IFile } from "models/store/files/files";
import { FilesActions } from "models/store/files/filesActions";
import { IEditFilesPayload, IEditSafePayload } from "models/store/files/filesPayloads";
import { FoldersAction } from "models/store/folders/foldersActions";
import { ICreateFolderPayload, IEditFolderPayload, IHandleFolderPayload } from "models/store/folders/foldersPayloads";
import { IFolder } from "models/store/folders/foldersStore";
import { JoinProjectActions } from "models/store/joinProjects/joinProjectsActions";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";

import { CabinetActions } from "../Cabinet";

export enum MOVE_ELEMENTS_TYPE {
  MOVE_FOLDER = "MOVE_FOLDER",
  MOVE_FILE = "MOVE_FILE",
  COPY_FOLDER = "COPY_FOLDER",
  COPY_FILE = "COPY_FILE"
}

type TThunkAction = ThunkAction<
  void,
  RootState,
  unknown,
  FoldersAction | FilesActions | JoinProjectActions | CabinetActions
>;

export interface IMoveElementsModal {
  open: boolean;
  type: string;
  elements: IFolder | IFile;
  folders: IFolder[];
  actionsCallback: {
    onCreateFolder?: (payload: ICreateFolderPayload) => TThunkAction;
    onEditFolders?: (
      payload: IEditFolderPayload,
      typeMessage: EVENT_TYPE,
      callback: (data?: any) => void
    ) => TThunkAction;
    onCopyFolders?: (payload: IHandleFolderPayload) => TThunkAction;
    onEditFiles?: (
      payload: IEditFilesPayload,
      typeMessage: EVENT_TYPE,
      safe?: IEditSafePayload,
      callback?: (file: IFile) => void
    ) => TThunkAction;
    onDelCopyFile?: () => void;
    onCopyFiles?: (payload: IEditFilesPayload, callback: () => void) => TThunkAction;
    onSetUpdate?: (data?: any) => void;
  };
}

export const initialMoveElementsModalState = (): IMoveElementsModal => {
  return {
    open: false,
    type: "",
    elements: null,
    folders: null,
    actionsCallback: {
      onCreateFolder: null,
      onEditFolders: null,
      onCopyFolders: null,
      onEditFiles: null,
      onCopyFiles: null
    }
  };
};
