import api from "api";
import { ReactComponent as AppleIcon } from "assets/icons/apple.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as LetterdIcon } from "assets/icons/letter.svg";
import { ReactComponent as LockIcon } from "assets/icons/sign_in_lock.svg";
import Button from "containers/Authentication/Button/Button";
import Input from "containers/Authentication/Input/Input";
import Layout from "containers/Authentication/Layout/Layout";
import GoogleButton from "containers/Authentication/SignIn/Buttons/GoogleButton";
import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useActions } from "hooks/useActions";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { STATUS_COLOR, USER_STATUS } from "models/store/user/user";
import { useState } from "react";
import { useLocales } from "react-localized";
import { Link } from "react-router-dom";
import { setUserToStorage } from "utils/signIn";
import { isEmail, isPhoneNumber } from "utils/validation";

import styles from "./SignIn.module.sass";

const SignIn = (): JSX.Element => {
  const { __ } = useLocales();
  const { onLog, onSetTopMessageModal } = useActions();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [fetchErrorText, setFetchErrorText] = useState("");
  const [fetchError, setFetchError] = useState(false);
  const [rememberUser, setRememberUser] = useState(true);

  const [errors, setErrors] = useState({
    login: "",
    password: ""
  });

  const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLogin(value);
    setErrors({ ...errors, login: "" });
    setFetchError(false);
    setFetchErrorText("");
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
    setErrors({ ...errors, password: "" });
    setFetchError(false);
    setFetchErrorText("");
  };

  const validateLogin = (login: string): boolean => {
    return isEmail(login) || isPhoneNumber(login);
  };

  const validatePassword = (password: string): boolean => {
    return password.length > 3;
  };

  const toggleRememberUser = () => {
    setRememberUser((prev) => !prev);
  };

  const signIn = async () => {
    try {
      if (login && password) {
        if (!validateLogin(login)) {
          setErrors({
            ...errors,
            login: __("Неверный логин. Введите действительный адрес электронной почты или номер телефона.")
          });
          return;
        }
        if (!validatePassword(password)) {
          setErrors({ ...errors, password: __("Пароль должен быть длиной не менее 4 символов.") });
          return;
        }
        const { data } = await api.post(`/ajax/user_login.php?name=${login}&pass=${password}`);
        checkResponseStatus(data.ok);

        if (data.col_pass || data.col_users) {
          setFetchErrorText(__("Такая комбинация логина и пароля не найдена"));
        } else {
          setFetchErrorText("");
        }

        const params = {
          uid: data.uid,
          status_color: STATUS_COLOR.ONLINE,
          status: USER_STATUS.ONLINE
        };
        const res = await api.get(`/ajax/user_edit2.php`, { params });
        checkResponseStatus(res.data.ok);
        onLog(data.uid);
        if (rememberUser) {
          setUserToStorage(data.uid);
        }
      }
    } catch {
      setFetchError(true);
    }
  };

  if (fetchError) {
    onSetTopMessageModal({
      open: true,
      type: ITopMessageTypes.ERROR
    });
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h4>{__("Войти в 4HUB")}</h4>
          <p>{__("Продолжайте работать в интеллектуальных рабочих пространствах.")}</p>
          <div className={styles.buttons_wrapper}>
            <GoogleButton setError={setFetchError} rememberUser={rememberUser} />
            <Button>
              <FacebookIcon />
            </Button>
            <Button>
              <AppleIcon />{" "}
            </Button>
          </div>
          <div className={styles.or}>{__("или")}</div>
          <div className={styles.inputs_wrapper}>
            <Input
              value={login}
              onChange={handleLoginChange}
              type="text"
              placeholder={__("Введите адрес электронной почты/телефон")}
              icon={<LetterdIcon />}
              error={errors.login}
            />
            <Input
              value={password}
              onChange={handlePasswordChange}
              placeholder={__("Введите пароль")}
              icon={<LockIcon />}
              type="password"
              error={errors.password}
            />
            <div className={styles.checkboxWrap} onClick={toggleRememberUser}>
              <CheckBox
                className={styles.checkbox}
                variant={rememberUser ? CheckBoxVariantTypes.SIGNIN_CHECKED : CheckBoxVariantTypes.SIGNIN_DEFAULT}
              />
              {__("Запомнить меня")}
            </div>

            {fetchErrorText && <span className={styles.error}>{fetchErrorText}</span>}
          </div>

          <span className={styles.info}>
            <Link to="/forgot_password"> {__("Забыли пароль?")}</Link>
          </span>
        </div>
        <Button
          disabled={!login || !password}
          className={styles.confirm_button}
          onClick={signIn}
          animationClassName={styles.animation}
        >
          {__("Вход")}
        </Button>
      </div>
    </Layout>
  );
};

export default SignIn;
