import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow_down.svg";
import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import { ReactComponent as CrossIcon } from "assets/PrivateCabinet/closeModal.svg";
import { ReactComponent as UploadIcon } from "assets/PrivateCabinet/uploadArrow.svg";
import classNames from "classnames";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./FileLoader.module.sass";
import LoadItem from "./LoadItem/LoadItem";
import LoadItemFolder from "./LoadItemFolder/LoadItemFolder";
import LoadTime from "./LoadTime/LoadTime";

const FileLoader = (): JSX.Element => {
  const { __ } = useLocales();
  const { theme } = useUserSelectors();
  const {
    uploadFiles: { loadingFiles, loadingFolders }
  } = useFilesSelectors();

  const { project } = useJoinProjectsSelectors();

  const projectLoadingFiles = project?.uploadFiles?.loadingFiles;

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  const {
    onSetApproveModal,
    onCloseLoadingFiles,
    onCancelFilesUpload,
    onCLoseProjectLoadingFiles,
    onCancelProjectFilesUpload,
    onCancelFoldersUpload
  } = useActions();

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const getAmount = (): number =>
    projectLoadingFiles?.length
      ? projectLoadingFiles.filter((el) => !el.done).length
      : loadingFiles.filter((el) => !el.done).length +
        loadingFolders?.reduce((acc, item) => acc + item?.files?.filter((el) => !el.done).length, 0);

  const getPercent = (): number =>
    projectLoadingFiles?.length
      ? projectLoadingFiles.reduce((acc, el) => acc + el.percent, 0) / getAmount()
      : loadingFiles.reduce((acc, el) => acc + el.percent, 0) / getAmount() +
        loadingFolders.reduce(
          (accFolders, item) => accFolders + item.files.reduce((accFiles, el) => accFiles + el.percent, 0),
          0
        ) /
          getAmount();

  const collapseToggle = (): void => setIsCollapsed((prev) => !prev);

  const onClose = (): void => {
    if (projectLoadingFiles?.length) {
      if (projectLoadingFiles.find((el) => !el.done)) {
        const params = {
          titleHead: __("Закрыть загрузки"),
          title: __("Закрыть загрузки"),
          text: __("Все незавершенные загрузки будут отменены. Закрыть?"),
          approveBtn: __("Закрыть"),
          approveBtnVariant: ButtonVariantType.OK_RED,
          callback: (): void => {
            onCLoseProjectLoadingFiles();
          }
        };
        onSetApproveModal({ open: true, params });
        return;
      }
      onCLoseProjectLoadingFiles();
    } else {
      if (
        loadingFiles?.find((el) => !el.done) ||
        loadingFolders?.some((folder) => folder?.files.some(({ done }) => !done))
      ) {
        const params = {
          titleHead: __("Закрыть загрузки"),
          title: __("Закрыть загрузки"),
          text: __("Все незавершенные загрузки будут отменены. Закрыть?"),
          approveBtn: __("Закрыть"),
          approveBtnVariant: ButtonVariantType.OK_RED,
          callback: (): void => {
            onCloseLoadingFiles();
          }
        };
        onSetApproveModal({ open: true, params });
        return;
      }
      onCloseLoadingFiles();
    }
  };

  const onCancel = (): void => {
    !!loadingFiles?.length &&
      loadingFiles.forEach((el) => {
        if (!el.done) {
          onCancelFilesUpload(el.id);
        }
      });

    !!projectLoadingFiles?.length &&
      projectLoadingFiles.forEach((el) => {
        if (!el.done) {
          onCancelProjectFilesUpload(el.id);
        }
      });

    !!loadingFolders?.length &&
      loadingFolders.forEach((el) => {
        if (el.files.some((item) => !item.done)) {
          onCancelFoldersUpload(el.id);
        }
      });
  };

  useEffect(() => {
    if (!getAmount()) {
      timeoutRef.current = setTimeout(() => {
        onClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [getAmount()]); // eslint-disable-line

  return (
    <div className={classNames(styles.wrap, { [styles.collapsed]: isCollapsed })}>
      <div className={styles.header}>
        <span className={styles.text}>
          {getAmount() > 0 ? `${__("Загрузка")} ${getAmount()} ${__("файла")}` : __("Загрузка завершена")}
        </span>
        {isCollapsed && (
          <div className={styles.container}>
            {getAmount() > 0 ? (
              <>
                <div
                  className={styles.progress}
                  style={{
                    background: `conic-gradient(#4086F1 ${getPercent()}%,#D2D2D2  ${getPercent()}%)`
                  }}
                >
                  <div className={styles.upload}>
                    <UploadIcon />
                  </div>
                </div>
                <span>{getAmount()}</span>
              </>
            ) : (
              <CheckIcon width={22} />
            )}
          </div>
        )}

        <button className={styles.btn} onClick={collapseToggle}>
          <ArrowIcon width={12} height={7} />
        </button>
        <button className={classNames(styles.btn)} onClick={onClose}>
          <CrossIcon width={12} />
        </button>
      </div>
      <LoadTime onCancel={onCancel} />
      <ul className={classNames(styles.fileList, `scrollbar-thin-${theme}`)}>
        {!!loadingFiles?.length && loadingFiles.map((el) => <LoadItem key={el.id} file={el} />)}
        {!!projectLoadingFiles?.length && projectLoadingFiles.map((el) => <LoadItem key={el.id} file={el} />)}
        {!!loadingFolders?.length && loadingFolders.map((folder) => <LoadItemFolder key={folder.id} folder={folder} />)}
      </ul>
    </div>
  );
};

export default FileLoader;
