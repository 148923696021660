import { useActions } from "hooks/useActions";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";

export const useSetOpenFolders = () => {
  const { onSendOpenFolder } = useActions();
  const { openFolder } = useFoldersSelectors();

  const onSetOpenFolders = (idParent: string) => {
    const filteredArray = Array.from(new Set([...openFolder, idParent]));
    onSendOpenFolder(filteredArray);
  };

  return {
    onSetOpenFolders
  };
};
