// Programs
import { useLocales } from "react-localized";

export function useContextProgramFolder() {
  const { __ } = useLocales();
  return {
    main: [
      { name: __("Расшарить"), img: "shareFile" },
      { name: __("Скопировать ссылку"), img: "link-4" },
      { name: __("Редактировать"), img: "edit" },
      { name: __("Переместить в архив"), img: "archive" },
      { name: __("Свойства"), img: "info" }
    ],
    additional: [{ name: __("Удалить"), img: "garbage" }]
  };
}

export function useContextProgram() {
  const { __ } = useLocales();
  return {
    main: [
      { name: __("Открыть"), img: "open-file-button" },
      { name: __("Переслать"), img: "resend" },
      { name: __("Скопировать ссылку"), img: "link-4" }
    ],
    additional: [{ name: __("Удалить"), img: "garbage" }]
  };
}

export const dotsPaint = [
  { id: 1, width: 16 },
  { id: 2, width: 14 },
  { id: 3, width: 12 },
  { id: 4, width: 10 },
  { id: 5, width: 8 },
  { id: 6, width: 6 },
  { id: 7, width: 5 },
  { id: 8, width: 2 }
];
