import { ReactComponent as UserIcon } from "assets/PrivateCabinet/user-setting.svg";
import Button from "generalComponents/Button/Button";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import React from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";

import styles from "./AppointAdmin.module.sass";

const AppointAdmin = () => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const handleClick = () =>
    dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.CHOOSE_SUBADMIN, params: { isSeveral: true } }));

  return (
    <div className={styles.wrap}>
      <UserIcon className={styles.icon} />
      <div>
        <p className={styles.text}>
          -{__(" зам администратора не имеет право удалять главного администратора из проекта")};
        </p>
        <p className={styles.text}>-{__(" зам администратора имеет право добавлять новых участников в проект")};</p>
        <p className={styles.text}>
          -{__(" зам администратора не имеет право назначать других сотрудников администраторами")};
        </p>
        <p className={styles.text}>
          -{__(" после выхода из проекта главного администратора, он передаёт свой статус заму")}.
        </p>
      </div>
      <Button text="Выбрать заместителя Администратора" type="button" variant="ok" onClick={handleClick} />
    </div>
  );
};

export default AppointAdmin;
