import { ReactComponent as FireIcon } from "assets/icons/fire_2.svg";
import classNames from "classnames";
import { useTaskPriority } from "hooks/joinProjectHooks";
import { TASK_PRIORITY } from "models/store/joinProjects/joinProgects";
import { FC } from "react";
import { useLocales } from "react-localized";

import styles from "./FilterTasksPriority.module.sass";

interface IFilterTasksPriorityProps {
  priority: string;
  onSetPriority: (val: string) => void;
  onSetShow: (show: boolean) => void;
}

export const FilterTasksPriority: FC<IFilterTasksPriorityProps> = ({ priority, onSetPriority, onSetShow }) => {
  const { taskPriority } = useTaskPriority();

  const { __ } = useLocales();

  const handleChange = (value: string): void => {
    onSetPriority(value);
    onSetShow(false);
  };

  return (
    <ul className={styles.list}>
      <li className={styles.list__item}>
        <button
          type="button"
          onClick={() => handleChange("")}
          className={classNames(styles.list__btn, {
            [styles.active]: !priority
          })}
        >
          {__("Все")}
        </button>
      </li>
      {Object.keys(taskPriority).map((key) => (
        <li key={key} className={styles.list__item}>
          <button
            type="button"
            onClick={() => handleChange(key)}
            className={classNames(styles.list__btn, {
              [styles.active]: priority === key
            })}
          >
            {key === TASK_PRIORITY.URGENT && (
              <>
                <FireIcon />
                &nbsp;
              </>
            )}
            {taskPriority[key as TASK_PRIORITY]}
          </button>
        </li>
      ))}
    </ul>
  );
};
