import { AllElementsArrayType } from "../typeElements";

export interface ITrashElementsState {
  elements: AllElementsArrayType;
  total: number;
  page: number;
  loader: boolean;
}

export function initialTrashElementsState(): ITrashElementsState {
  return {
    elements: null,
    total: 0,
    page: 1,
    loader: false
  };
}
