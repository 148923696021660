import { ReactComponent as DevicesIcon } from "assets/icons/devices.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import classNames from "classnames";
import { useEmployeeContext } from "collections/profile";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { PROFILE_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { PROFILE_MODALS } from "models/store/Cabinet/modals/profileModals";
import { IUserInfo } from "models/store/user/user";
import { FC, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { v4 as uuid } from "uuid";

import styles from "./EmployeeItem.module.sass";

interface IProps {
  employee: IUserInfo;
}
const EmployeeItem: FC<IProps> = ({ employee }) => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);
  const { onSetProfileModal } = useActions();
  const { main, own } = useEmployeeContext();
  const { userInfo } = useUserSelectors();

  const onEdit = () => onSetProfileModal({ open: true, type: PROFILE_MODALS.EDIT_EMPLOYEE, employee: employee });
  const renderName = `${employee.fname} ${employee.pname} ${employee.sname}`;
  return (
    <li className={classNames(styles.item, { [styles.isActive]: isActive })}>
      <div className={styles.nameBox}>
        <Avatar
          name={`${employee.fname} ${employee.sname}`}
          size={24}
          imgSrc={employee.icon ? `${projectSrc + employee.icon}` : undefined}
          status={employee.status}
        />
        <span className={styles.name}>{renderName || employee.name || <>&mdash;</>}</span>
        {Boolean(employee.is_block) && <span className={styles.isBlock}>{__("Заблокирован")}</span>}
      </div>
      <div className={styles.emails}>
        {employee.emails.length > 0 ? employee.emails.map((email) => <span key={uuid()}>{email}</span>) : <>&mdash;</>}
      </div>
      <div className={styles.tels}>
        {employee.tels.length > 0 ? employee.tels.map((tel) => <span key={uuid()}>{"+" + tel}</span>) : <>&mdash;</>}
      </div>
      {employee.is_admin === 0 && (Boolean(userInfo.is_admin) || userInfo.rights.IS_MGR_EMPLOYEE) && (
        <div className={styles.actions}>
          <Button
            onClick={onEdit}
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.MEDIUM}
            iconL={<EditIcon width={15} height={15} />}
            isSquare
          />
          <Button
            onClick={() => navigate(`/${ROUTES.PROFILE}/${PROFILE_ROUTES.SESSEIONS}?user_id=${employee.id}`)}
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.MEDIUM}
            iconL={<DevicesIcon width={18.33} height={16} />}
            isSquare
          />
          <PopoverContextMenu
            data={employee.is_admin ? own(employee) : main(employee)}
            iconVariant="button"
            onEnter={() => setIsActive(true)}
            onExit={() => setIsActive(false)}
          />
        </div>
      )}
    </li>
  );
};

export default EmployeeItem;
