import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./DownArrow.module.sass";

const DownArrow = ({ isOpen, clickHandler }) => {
  return <div className={classNames(styles.open, { [styles.isOpen]: isOpen })} onClick={clickHandler} />;
};

export default DownArrow;

DownArrow.propTypes = {
  isOpen: PropTypes.bool,
  clickHandler: PropTypes.func
};
