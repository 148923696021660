import React from "react";

import { IToolOptions } from "../../../../../../models/workElements/minitoolbar";
import { PaintTools } from "../../../../../../models/workElements/previews";
import Tool from "./Tool";

class Pencil extends Tool {
  constructor(canvas: HTMLCanvasElement, options: IToolOptions) {
    super(canvas, options);
    setTimeout(() => this.listen(), 0);
  }

  name = PaintTools.PENCIL;
  mouseDown = false;

  listen(): void {
    this.canvas.onmousemove = (e) => this.mouseMoveHandler.apply(this, [e as unknown as React.MouseEvent]);
    this.canvas.onmousedown = (e) => this.mouseDownHandler.apply(this, [e as unknown as React.MouseEvent]);
    this.canvas.onmouseup = this.mouseUpHandler.bind(this);
  }

  mouseUpHandler() {
    this.mouseDown = false;
    if (this.socket && this.drawCoordinates.length > 0) {
      this.sendSocketPaint(this.drawCoordinates, this.name, String(this.ctx.strokeStyle));
    }
    this.drawCoordinates = [];
  }

  mouseDownHandler(e: React.MouseEvent): void {
    const node = e.target as HTMLElement;
    this.pushInDrawHistory(this.canvas.toDataURL());
    if (this.ctx) {
      this.mouseDown = true;
      this.ctx.beginPath();
      this.ctx.moveTo(e.pageX - node.getBoundingClientRect().x, e.pageY - node.getBoundingClientRect().y);
    }
  }

  drawCoordinates: number[][] = [];

  mouseMoveHandler(e: React.MouseEvent): any {
    if (this.mouseDown) {
      const node = e.target as HTMLElement;
      this.drawCoordinates.push([e.pageX - node.getBoundingClientRect().x, e.pageY - node.getBoundingClientRect().y]);
      this.draw(e.pageX - node.getBoundingClientRect().x, e.pageY - node.getBoundingClientRect().y);
    }
  }

  draw(x: number, y: number): void {
    if (this.ctx) {
      this.ctx.lineTo(x, y);
      this.ctx.stroke();
    }
  }
}

export default Pencil;
