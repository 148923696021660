import { IFile } from "models/store/files/files";

export interface IAllFileViewModalModalState {
  open: boolean;
  params: IFile | null;
  titleHead?: string;
  btnLabel?: string;
  callbak?: () => void | undefined;
}

export const initialAllFileViewModalState = (): IAllFileViewModalModalState => {
  return { open: false, params: null };
};
