import classNames from "classnames";
import Clue from "generalComponents/Clue/Clue";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { ChangeEvent, FC, MouseEvent, useEffect, useState } from "react";
import { useLocales } from "react-localized";

import { Options } from "./Options/Options";
import styles from "./SelectCustom.module.sass";

interface ISelectCustomProps {
  name?: string;
  label?: string;
  options: ISelectCustom[];
  selectedDefault: ISelectCustom;
  variantHeight?: "small" | "medium"; // 28 | 32
  onSelected?: (e: MouseEvent<HTMLButtonElement>) => void;
  required?: boolean;
  search?: boolean;
  placeholder?: string;
  value?: ISelectCustom;
  width: number;
  disabled?: boolean;
}

const SelectCustom: FC<ISelectCustomProps> = ({
  name,
  label,
  options,
  selectedDefault,
  variantHeight = "small",
  onSelected,
  required = false,
  search = false,
  value,
  width,
  disabled
}): JSX.Element => {
  const { __ } = useLocales();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<ISelectCustom>({
    name: selectedDefault?.name?.trim() || __("Выберите"),
    value: selectedDefault?.value || ""
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchResults, setSearchResults] = useState<ISelectCustom[]>([]);
  useEffect(() => setSearchResults(options), [options]);
  const isSelected = selected.value !== selectedDefault?.value || selectedDefault?.value !== "";

  const handleClick = (el: ISelectCustom): void => {
    setSelected({
      name: el?.name,
      value: el?.value
    });

    if (search) {
      if (el?.value) {
        setSearchValue(el?.name);
      } else {
        setSearchValue("");
      }
    }

    setIsOpen(false);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setSearchValue(value);

    setSelected({
      name: __("Выберите"),
      value: ""
    });

    const newResult = options.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase()));

    if (value) {
      setSearchResults(newResult);
    } else {
      setSearchResults(options);
    }
  };

  useEffect(() => {
    if (search && selected.value) {
      setSearchValue(selected.name);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!value || value.value === selected.value) return;
    setSelected(value);
  }, [value]); //eslint-disable-line

  return (
    <PopoverOverlay
      width={width}
      placement="bottom"
      disabled={disabled}
      overlayContent={
        searchResults?.length ? (
          <Options
            name={name}
            options={searchResults}
            onSelected={onSelected}
            handleClick={handleClick}
            selected={selected}
          />
        ) : (
          <div className={styles.empty}>
            <Clue icon="sad" text={__("Ничего не найдено")} variant="column" />
          </div>
        )
      }
      show={isOpen}
      setShow={setIsOpen}
      search={search}
      value={searchValue}
      placeholder={selected.name}
      onChange={onChange}
    >
      {!search && label && (
        <span className={styles.labelWrapp}>
          <span
            className={classNames(styles.label, {
              [styles.required]: required
            })}
          >
            {label}
          </span>
          <span
            className={classNames(styles.btnSelected, styles[variantHeight], {
              [styles.open]: isOpen,
              [styles.selected]: isSelected
            })}
            style={{ width }}
          >
            {selected?.name || selectedDefault?.name}
          </span>
        </span>
      )}
      {!search && !label && (
        <span
          className={classNames(styles.btnSelected, styles[variantHeight], {
            [styles.open]: isOpen,
            [styles.selected]: isSelected
          })}
          style={{ width }}
        >
          {selected?.name || selectedDefault?.name}
        </span>
      )}
    </PopoverOverlay>
  );
};

export { SelectCustom };
