import { ReactComponent as ArrowDownIcon } from "assets/PrivateCabinet/arrow-down.svg";
import classNames from "classnames";
import { useCreateTask } from "collections/tasks";
import Button from "generalComponents/Button/Button";
import CalendarTabs from "generalComponents/CalendarTabs/CalendarTabs";
import FilterElements from "generalComponents/FilterElements/FilterElements";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import Tabs, { TabsType } from "generalComponents/Tabs/Tabs";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { ROUTES, TASK_PARAMS } from "generalComponents/variables/routing";
import { TASK_MODALS, TASK_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ICreateTask } from "models/generalComponents/collections/tasks";
import { TASK_DEP } from "models/store/tasks/tasks";
import React, { useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { useLocales } from "react-localized";
import { useMatch, useSearchParams } from "react-router-dom";
import { INITIAL_ADD_TASK } from "utils/tasks";

import styles from "./TaskServicePanel.module.sass";

interface ITaskServicePanelProps {
  departmentTabs: TabsType[];
}

const TaskServicePanel: React.FC<ITaskServicePanelProps> = ({ departmentTabs }): JSX.Element => {
  const { __ } = useLocales();
  const [serchParams] = useSearchParams();
  const dep = serchParams.get(TASK_PARAMS.DEP);
  const isCalendarPage = useMatch(`${ROUTES.CALENDAR}`);

  const createList = useCreateTask();
  const { onSetTaskModal } = useActions();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  useOutsideClick(target, () => setTimeout(() => setShow(false), 100));

  const onAddTask = (typeTask: TASK_TYPES, typeModal: TASK_MODALS): void => {
    onSetTaskModal({
      type: typeModal,
      params: { ...INITIAL_ADD_TASK, id_type: typeTask, id_dep: TASK_DEP.NO_DEP }
    });
  };

  const getCreateList = (): ICreateTask[] => {
    return isCalendarPage ? createList : createList.slice(0, 4);
  };

  const toogleShow = (): void => setShow((show) => !show);

  return (
    <ToolsPanel size="small">
      <div className={styles.block}>
        <CalendarTabs />
        <FilterElements />
        <div>
          <Tabs tabs={departmentTabs} value={dep ?? ""} size="small" />
        </div>
      </div>

      <Button
        ref={target}
        onClick={toogleShow}
        type="button"
        variant={ButtonVariantType.BLUE}
        size={ButtonSizeType.EXTRA_SMALL}
        text={__("Создать")}
        iconR={<ArrowDownIcon className={classNames(styles.icon, { [styles.open]: show })} />}
        isActive={show}
      />
      <Overlay placement="bottom-end" rootClose show={show} flip target={target.current}>
        <Popover className={classNames(styles.popoverRoot)} id="popover-menu">
          <Popover.Body className={classNames(styles.popoverBody)}>
            <ul>
              {getCreateList().map((item, i) => (
                <li key={i} className={styles.createItem}>
                  <button type="button" onClick={() => onAddTask(item.typeTask, item.typeModal)}>
                    <div className={styles.iconItem}>{item.icon}</div>
                    <span>{item.text}</span>
                  </button>
                </li>
              ))}
            </ul>
          </Popover.Body>
        </Popover>
      </Overlay>
    </ToolsPanel>
  );
};

export default TaskServicePanel;
