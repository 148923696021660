import { ISprintRect } from "../models";

export const SprintsRect = ({ sprint }: { sprint: ISprintRect }) => {
  return (
    <g>
      <rect
        x={sprint.x}
        y="2"
        width={sprint.width}
        height="16"
        rx="4"
        ry="4"
        fill={sprint.isCurrent ? "rgba(100, 181, 246, 0.5)" : "rgba(207, 215, 213, 0.5"}
      />
      {sprint.isText && (
        <text x={sprint.x + sprint.width / 2} y={14} textAnchor="middle" fontSize={12}>
          {sprint.name}
        </text>
      )}
    </g>
  );
};
