import api from "api";
import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import SearchField from "generalComponents/SearchField/SearchField";
import { MAIL_PARAMS } from "generalComponents/variables/routing";
import { useLetterTime } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { useDebounce } from "hooks/useDebounce";
import { LoaderTypes } from "models/generalComponents/loader";
import { ILetterInfo } from "models/store/postbox/postbox";
import React, { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { v4 as uuid } from "uuid";

import styles from "./MailSearch.module.sass";

interface ISerchedLetter {
  answered: number;
  att_col: number;
  attachments: boolean;
  cc: string;
  deleted: number;
  draft: number;
  flagged: number;
  folder: string;
  from: string;
  from_name: string;
  html: string;
  email: string;
  message_id: string;
  preview: string;
  recent: number;
  seen: number;
  size: number;
  subject: string;
  text: string;
  time: string;
  to: any;
  uid: number;
  search: string;
}

const MailSearch = () => {
  const { __ } = useLocales();
  const [searchParams, setSearchParams] = useSearchParams();
  const { onSetSearchLetter } = useActions();
  const { letterTime } = useLetterTime();

  const { uid } = useUserSelectors();
  const [search, setSearch] = useState("");
  const [result, setResult] = useState<ISerchedLetter[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [finish, setFinish] = useState<boolean>(false);

  const controller = new AbortController();
  const searchLetters = async () => {
    try {
      setLoader(true);

      const params = {
        uid,
        search
      };
      const { data } = await api.get("/ajax/email_messages_search_all.php", { params, signal: controller.signal });
      setResult(data.data.messages || []);
      setFinish(true);
    } catch {
      //
    } finally {
      setLoader(false);
    }
  };

  const debounceCallback = useDebounce(searchLetters, 300);

  useEffect(() => {
    setResult([]);
    if (search.length >= 3) {
      setFinish(false);
      setResult([]);
      debounceCallback();
    }
    return () => {
      controller.abort();
    };
  }, [search]); //eslint-disable-line

  const handleClick = (el: ISerchedLetter): void => {
    const letter: ILetterInfo = {
      answered: el.answered,
      att_col: el.att_col,
      attachments: el.attachments,
      cc: el.cc,
      deleted: el.deleted,
      draft: el.draft,
      flagged: el.flagged,
      folder: el.folder,
      from: el.from,
      from_name: el.from_name,
      html: el.html,
      id: "",
      is_favorite: 0,
      is_liked: 0,
      message_id: el.message_id,
      preview: el.preview,
      recent: el.recent,
      seen: el.seen,
      size: el.size,
      subject: el.subject,
      text: el.text,
      time: el.time,
      to: el.to,
      uid: +el.uid
    };
    searchParams.set(`${MAIL_PARAMS.SPLIT}`, "true");
    setSearchParams(searchParams);
    onSetSearchLetter(letter);
  };
  const getCutSearch = (string: string): string => {
    const idx = string.indexOf(search);
    const result = string.slice(idx - 10);
    console.log("result", result);
    return string;
  };
  const renderResult = (el: ISerchedLetter): string => {
    const string = `    
      <p class=${styles.from}><span>${el.from}</span>, <span>${el.from_name}</span> </p>
      <p class=${styles.subject}>${el.subject}</p>
      <p class=${styles.text}>${getCutSearch(el.search) ?? ""}</p>
      
    `;
    const regexExpression = new RegExp(search, "gi");
    const highlightedText = string.replace(regexExpression, (match: any): any => {
      return `<span class=${styles.highlight}>${match}</span>`;
    });

    return highlightedText;
  };

  return (
    <SearchField variant="secondary" placeholder={__("Введите от 3 символов")} value={search} setValue={setSearch}>
      {loader ? (
        <div className={styles.loaderBox}>
          <Loader
            type={LoaderTypes.BOUNCING_DOTS}
            position="absolute"
            background="white"
            zIndex={5}
            width="100px"
            height="100px"
            containerType="bounceDots"
          />
        </div>
      ) : result.length > 0 ? (
        <ul className={styles.list}>
          {result.map((el) => (
            <li key={uuid()}>
              <div className={styles.letter} onClick={() => handleClick(el)}>
                <div className={styles.left} dangerouslySetInnerHTML={{ __html: renderResult(el) }} />
                <p className={styles.time}>{letterTime(el.time)}</p>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        finish && (
          <div className={styles.loaderBox}>
            <Clue text={__("Нет совпадений")} icon="sad" />
          </div>
        )
      )}
    </SearchField>
  );
};

export default MailSearch;
