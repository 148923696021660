import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { TASK_MODALS } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import md5 from "md5";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IReadPasswordProps } from "models/store/Cabinet/modals/taskModals";
import React from "react";
import { useLocales } from "react-localized";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import styles from "./ReadPassword.module.sass";

const ReadPassword: React.FC<IReadPasswordProps> = ({ type, params, closeModal }): JSX.Element => {
  const { __ } = useLocales();
  const { myTasks } = useTasksSelectors();

  const { onSetTaskModal, onDeleteTask, onSetApproveModal } = useActions();
  const { value, onChange, error, onError } = useInputValue();

  const onDelete = (): void => {
    closeModal();
    const payload = {
      title: __("Удалить заметку"),
      text: __("Вы действительно хотите удалить выбранную заметку?"),
      callback: () => onDeleteTask(params.note.id),
      approveBtn: __("Удалить")
    };
    onSetApproveModal({ open: true, params: payload });
  };

  const onSubmit = (): void => {
    if (md5(value) === params.note.pass) {
      if (params.callbackType === TASK_MODALS.EDIT_NOTE || params.callbackType === TASK_MODALS.OPEN_NOTE) {
        onSetTaskModal({ type: params.callbackType, params: params.note });
      }
      if (params.callbackType === TASK_MODALS.DEL_NOTE) {
        onDelete();
        closeModal();
      }
    } else {
      onError(__("неверный пароль"));
    }
  };

  const onNext = (): void => {
    const task = myTasks.find((t) => t.id === params.note.id);
    if (md5(value) === task.pass) {
      if (params.callbackType === TASK_MODALS.DEL_NOTE) {
        onSetTaskModal({ type: TASK_MODALS.NO_MODAL, params: null });
        onDelete();
      } else {
        onSetTaskModal({ type: TASK_MODALS.NEW_PASSWORD, params: params });
      }
    } else {
      onError(__("неверный пароль"));
    }
  };

  const handleForgotPass = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.RECOVER_PASSWORD,
      params: params
    });
  };

  return (
    <div className={styles.passWrap}>
      <Input
        value={value}
        onChange={onChange}
        isPass
        error={Boolean(error)}
        errorText={error}
        label={__("Введите пароль")}
      />

      <div className={styles.btns}>
        {type === TASK_MODALS.READ_PASSWORD && (
          <div>
            <Button
              type="button"
              onClick={handleForgotPass}
              variant={ButtonVariantType.OPACITY}
              size={ButtonSizeType.EXTRA_SMALL}
              text={__("Забыли пароль?")}
            />
          </div>
        )}
        <div className={styles.btns}>
          {type === TASK_MODALS.READ_PASSWORD && (
            <>
              <Button type="button" variant={ButtonVariantType.CANCEL} text={__("Отмена")} onClick={closeModal} />
              <Button
                type="button"
                variant={ButtonVariantType.OK}
                text={__("Открыть")}
                onClick={onSubmit}
                disabled={!value}
              />
            </>
          )}
          {type === TASK_MODALS.ENTER_CODE && (
            <Button type="button" variant={ButtonVariantType.OK} text={__("Далее")} onClick={onNext} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReadPassword;
