import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow-down.svg";
import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import classNames from "classnames";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useRef } from "react";

import styles from "./MultiSelect.module.sass";

const MultiSelect = ({ values, data, placeholder, onSelectItem }) => {
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setShow(false));
  const getValues = () => {
    if (values.length === 0) {
      return <span className={styles.placeholder}>{placeholder}</span>;
    }
    return values.map((item) => (
      <div className={styles.item} key={item.id}>
        {item.img && item.img}
        {item.text}
      </div>
    ));
  };

  const isChecked = (id) => values.find((el) => el.id === id);

  const onChoose = (item) => {
    isChecked(item.id) ? onSelectItem(item, "remove") : onSelectItem(item, "add");
  };

  return (
    <div className={styles.wrap} ref={ref}>
      <ArrowIcon className={classNames(styles.arrowIcon, { [styles.arrowActive]: show })} />
      <div
        className={classNames(styles.values, { [styles.listShow]: show })}
        onClick={() => setShow((state) => !state)}
      >
        {getValues()}
      </div>
      {show && (
        <div className={styles.list}>
          {data.map((item, i) => (
            <div key={i} className={styles.itemBlock} onClick={() => onChoose(item)}>
              {isChecked(item.id) ? <CheckIcon className={styles.check} /> : <div className={styles.noCheck} />}
              {item.img && item.img}
              {item.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;

MultiSelect.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      img: PropTypes.node
    })
  ),
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      img: PropTypes.node
    })
  ),
  onSelectItem: PropTypes.func
};
