import { useContentCategories } from "collections/library";
import { useMenuFilterLibraryRubrics } from "collections/library";
import { NavPrevPage } from "generalComponents/NavPrevPage/NavPrevPage";
import { LibraryCategoryTypes } from "generalComponents/variables/library";
import { ROUTES } from "generalComponents/variables/routing";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useLibrarySelectors } from "Store/selectors/librarySelectors";

interface INavigationLibraryOption {
  isLibrary?: boolean;
  isLibrarySearch?: boolean;
  isLibrarySetttings?: boolean;
  libraryCategory: string;
  libraryRubricId: string;
  libraryDirId: string;
}

export const NavigationLibraryOption: FC<INavigationLibraryOption> = ({
  isLibrary,
  isLibrarySearch,
  isLibrarySetttings,
  libraryCategory,
  libraryRubricId,
  libraryDirId
}): JSX.Element => {
  const { __ } = useLocales();

  const { folderInfo } = useLibrarySelectors();

  const contentCategory = useContentCategories();
  const contentRubrics = useMenuFilterLibraryRubrics();

  const renderPageName = () => {
    if (isLibrary) {
      return __("Библиотека");
    }

    if (isLibrarySearch) {
      return __("Поиск");
    }

    if (isLibrarySetttings) {
      return __("Настройки");
    }

    if (libraryCategory) {
      return Object.values(contentCategory).find(
        ({ category }) => category === (libraryCategory as LibraryCategoryTypes)
      )?.title;
    }

    if (libraryRubricId) {
      return contentRubrics.find(({ value }) => value === libraryRubricId)?.name;
    }

    if (libraryDirId === folderInfo?.id_dir) {
      return folderInfo?.name;
    }
  };

  return <NavPrevPage title={renderPageName()} defaultPage={ROUTES.LIBRARY} />;
};
