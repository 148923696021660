import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { IGetUserFilters, INullifyFilters, UserFiltersActions } from "models/store/userFilters/userFiltersActions";
import { IDetchUserFiltersDTO, IUserFiltersPayload } from "models/store/userFilters/userFiltersPayload";
import { IUserFiltersState } from "models/store/userFilters/usersFilters";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { UserFilters } from "Store/types/usersFilters";

import api from "../../api";

export const fetchUserFilters =
  (payload: IUserFiltersPayload): ThunkAction<void, RootState, unknown, UserFiltersActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        sources: payload.sources,
        fields: payload.fields
      };
      const { data } = await api.get<IDetchUserFiltersDTO>("/ajax/user_icons_get.php?", { params });
      checkResponseStatus(data.ok);
      dispatch(
        getUserFilters({
          emojis: data.data.id_emo,
          colors: data.data.id_color,
          tags: data.data.tags,
          figures: data.data.id_fig
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

export const getUserFilters = (payload: IUserFiltersState): IGetUserFilters => ({
  type: UserFilters.GET_FILTERS,
  payload
});

export const onNullifyUserFilters = (): INullifyFilters => ({ type: UserFilters.NULLIFY_FILTERS });
