import ProfileMenu from "containers/Profile/ProfileMenu/ProfileMenu";
import { PROFILE_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useCurrentPath } from "hooks/useCurrentPath";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const navigate = useNavigate();
  const isProfile = useCurrentPath(ROUTES.PROFILE);

  useEffect(() => {
    if (isProfile) {
      navigate(`${PROFILE_ROUTES.ABOUT_ME}`, { replace: true });
    }
  }, []); // eslint-disable-line

  return (
    <WorkAreaWrap variant="row">
      <ProfileMenu />
      <Outlet />
    </WorkAreaWrap>
  );
};

export default ProfilePage;
