import api from "api";
import { ReactComponent as EditIcon } from "assets/PrivateCabinet/edit.svg";
import { ReactComponent as FileIcon } from "assets/PrivateCabinet/file-5.svg";
import { ReactComponent as FireIcon } from "assets/PrivateCabinet/fire.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import { ReactComponent as Clip } from "assets/PrivateCabinet/mail/clip.svg";
import { ReactComponent as DownloadArrow } from "assets/PrivateCabinet/mail/download-arrow.svg";
import { ReactComponent as PCIcon } from "assets/PrivateCabinet/pc.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/plus-3.svg";
import { ReactComponent as SettingsIcon } from "assets/PrivateCabinet/settings-work-tool.svg";
import classNames from "classnames";
import Comments from "containers/Tasks/Comments/Comments";
import Button from "generalComponents/Button/Button";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import Carousel from "generalComponents/Carousel/Carousel";
import DownArrow from "generalComponents/DownArrow/DownArrow";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import Loader from "generalComponents/Loaders/4HUB";
import PopUp from "generalComponents/PopUp/PopUp";
import Select from "generalComponents/Select/Select";
import { createMarkup } from "generalComponents/Services/browserServices";
import { getFormatDate } from "generalComponents/Services/dateServices";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import Executors from "Pages/Cabinet/Components/Project/Components/Executors/Executors";
import ProjectTask from "Pages/Cabinet/Components/Project/Components/ProjectTask/ProjectTask";
import UserBlock from "Pages/Cabinet/Components/Project/Components/UserBlock/UserBlock";
import { getChildrenTask, getParentTask, useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { useResolvedPath } from "react-router-dom";
import { onSetModals } from "Store/actions/ModalActions";
import { handleTaskComment, onChangeStatusTask } from "Store/actions/ProjectActions";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";
import { ProjectTypes } from "Store/types/projectTypes";
import { eventProps } from "types/CalendarPage";
import { taskTypes } from "types/Tasks";

import ChooseFile from "../ChooseFile/ChooseFile";
import styles from "./OpenTask.module.sass";

function OpenTask({ params }) {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const uid = useSelector((s) => s.user.uid);
  const { project } = useProjectsSelectors();
  const MSG = useProjectMsg();
  const [comment, setComment] = useState("");
  const [idEditComment, setIdEditComment] = useState("");
  const [isOpenChildren, setIsOpenChildren] = useState(false);
  const [files, setFiles] = useState([]);
  const [files4Hub, setFiles4hub] = useState([]);
  const [openFile, setOpenFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const task = useSelector((s) => s.Projects.project).tasks.find((t) => t.id === params.id);
  const inputRef = useRef(null);
  const isBacklog = useResolvedPath("/project/:id/backlog");
  const isTrash = useResolvedPath("/project/:id/trash");

  useEffect(() => {
    if (params.files_col > 0) {
      fetchFiles();
    }
  }, []); //eslint-disable-line

  const fetchFiles = async () => {
    try {
      setLoading(true);

      const { data } = await api.get("/ajax/task_file_list.php", { params: { uid, id_task: params.id } });
      setFiles([...data.files, ...data.files_4hub]);
      setFiles4hub(data.files_4hub);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const childrenRef = useRef(null);

  useOutsideClick(childrenRef, () => setIsOpenChildren(false));

  const status = project.tasks_category.map((item) => ({ id: item.id, text: item.name }));

  const handleSubmit = (type) => {
    if (params.isLink) {
      return;
    }
    if (comment.length === 0) {
      return;
    }
    const payload = {
      id_task: params.id,
      text: comment,
      id: idEditComment
    };
    dispatch(handleTaskComment(type, payload, MSG.ERROR));
    setComment("");
  };

  const handleEditComment = (com) => {
    setIdEditComment(com.id);
    setComment(com.text);
  };

  const handleCommentDel = (id) => {
    if (params.isLink) {
      return;
    }
    const payload = {
      id: id,
      id_task: params.id
    };
    dispatch(handleTaskComment("del", payload, MSG.ERROR));
  };

  const renderComments = () => {
    const task = project.tasks.find((task) => task.id === params.id);
    return (
      task?.comments?.length > 0 && (
        <>
          <h3 className={styles.subTitle}>{__("Комментарии:")}</h3>
          <ul className={styles.list}>
            {task.comments.map((item) => (
              <div key={item.id} className={classNames(styles.comment)}>
                <div className="htmlContent" dangerouslySetInnerHTML={createMarkup(item.text)} />
                <div className={styles.btns}>
                  <ButtonIcon variant="grey" handleClick={() => handleEditComment(item)} icon={<EditIcon />} />
                  <ButtonIcon variant="grey" handleClick={() => handleCommentDel(item.id)} notHover>
                    <TrashIcon width={15} height={15} className={styles.icon} />
                  </ButtonIcon>
                </div>
              </div>
            ))}
          </ul>
        </>
      )
    );
  };

  const onChangeStatus = (type) => {
    if (!params.isLink) {
      const payload = {
        sort: params.sort,
        id: params.id,
        name: params.name,
        id_category: type,
        id_act: params.id_act
      };
      dispatch(onChangeStatusTask(payload, MSG.ERROR));
    }
  };

  const onAddTask = () => {
    if (!params.isLink) {
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.ADD_TASK,
          params: { id_parent: params.id, id_epic: params.id_epic }
        })
      );
    }
  };

  const onEditTask = () => {
    if (!params.isLink) {
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.EDIT_TASK,
          params: params
        })
      );
    }
  };

  const onAddExecutor = () => {
    if (!params.isLink) {
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.ADD_EXECUTORS,
          callback: PROJECT_MODALS.OPEN_TASK,
          params: params
        })
      );
    }
  };

  const renderStatus = () => {
    if (isBacklog) {
      return <div className={classNames(styles.select, styles.selectDiv)}>Backlog</div>;
    }
    if (isTrash) {
      return <div className={classNames(styles.select, styles.selectDiv)}>{__("В корзине")}</div>;
    }
    return <Select data={status} initValue={params.id_category} className={styles.select} onChange={onChangeStatus} />;
  };

  const onOpenChildrenTask = () => {
    setIsOpenChildren((state) => !state);
  };

  const fileSelect = () => inputRef.current.click();

  const uploadImage = async (event) => {
    try {
      setLoading(true);
      await Promise.all(
        [...event.target.files].map(async (file) => {
          const payload = new FormData();
          payload.append("uid", uid);
          payload.append("myfile", file);
          payload.append("id_task", params.id);
          payload.append("fileName", `${file.name.slice(0, file.name.lastIndexOf("."))}`);

          const response = await api.post(`/ajax/task_file_add.php`, payload);
          setFiles((prev) => [...prev, { ...response.data.files.myfile, fid: response.data.fid }]);
          checkResponseStatus(response.data.ok);
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onAddFile = async (file) => {
    const fids = task.fids ?? [];
    try {
      dispatch(onSetModals(MODALS.LOADER, true));
      setOpenFile(false);
      const payload = new FormData();
      payload.append("uid", uid);
      payload.append("id_task", params.id);
      payload.append("name", params.name);
      payload.append("fids", JSON.stringify([...fids, file.fid]));
      const { data } = await api.post(`/ajax/task_edit.php`, payload);
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: data.project_tasks });
      setFiles((prev) => [...prev, file]);
      setFiles4hub((prev) => [...prev, file]);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(onSetModals(MODALS.LOADER, false));
    }
  };

  const downloadFile = (file) => {
    const path = files4Hub.some((f) => f.fid === file.fid) ? "download" : "task_file_download";
    dispatch(
      onSetModals("contextMenuModals", {
        type: "DownloadFile",
        items: [file],
        idTask: params.id,
        authorizedSafe: null,
        path: path
      })
    );
  };

  const downloadAll = () => {
    dispatch(
      onSetModals("contextMenuModals", {
        type: "DownloadFile",
        idTask: params.id,
        authorizedSafe: null,
        path: "task_file_download_zip"
      })
    );
  };

  const deleteFile = async (file) => {
    if (task.fids.includes(file.fid)) {
      try {
        dispatch(onSetModals(MODALS.LOADER, true));
        const fids = params.fids.filter((fid) => fid !== file.fid);
        const payload = new FormData();
        payload.append("uid", uid);
        payload.append("id_task", params.id);
        payload.append("name", params.name);
        payload.append("fids", JSON.stringify([...fids]));
        const { data } = await api.post(`/ajax/task_edit.php`, payload);
        checkResponseStatus(data.ok);
        dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: data.project_tasks });
        setFiles((files) => files.filter((f) => f.fid !== file.fid));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(onSetModals(MODALS.LOADER, false));
      }
    } else {
      try {
        dispatch(onSetModals(MODALS.LOADER, true));
        const payload = new FormData();
        payload.append("uid", uid);
        payload.append("id_task", params.id);
        payload.append("fid", file.fid);
        const { data } = await api.post(`/ajax/task_file_del.php`, payload);
        checkResponseStatus(data.ok);
        setFiles((files) => files.filter((f) => f.fid !== file.fid));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(onSetModals(MODALS.LOADER, false));
      }
    }
  };

  return (
    <div className={styles.taskWrap}>
      <div className={styles.header}>
        {project.icon && <img src={project.icon} alt="project logo" width={31} height={31} className={styles.logo} />}
        <span className={styles.name}>{project.name}</span>
      </div>
      <div className={styles.container}>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <h2 className={styles.taskName}>{params.name}</h2>
            <div className={styles.attributes}>
              {params.tags?.trim() !== "" && <span className={styles.tag}>{params.tags}</span>}
              {params.id_act === "urgent" && <FireIcon />}
              {params.id_parent && (
                <div>
                  <span className={styles.textBlue}>{__("Родительская задача: ")}</span>
                  <span className={styles.text}>{getParentTask(project.tasks, params.id_parent)?.name}</span>
                </div>
              )}
              {getChildrenTask(project.tasks, params.id).length > 0 && (
                <div className={styles.childrenBox} ref={childrenRef}>
                  <span className={styles.textBlue}>{__("Дочерняя задача: ")}</span>
                  <span className={styles.text}>
                    {
                      getChildrenTask(project.tasks, params.id)[getChildrenTask(project.tasks, params.id).length - 1]
                        .name
                    }
                  </span>
                  <DownArrow isOpen={isOpenChildren} clickHandler={onOpenChildrenTask} />

                  {isOpenChildren && (
                    <div className={styles.children}>
                      <div className={styles.childrenScroll}>
                        {getChildrenTask(project.tasks, params.id).map((item, i) => (
                          <div key={i}>
                            <ProjectTask task={item} accordion variant="column" />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className={styles.files}>
              {files.length > 0 && (
                <>
                  <Carousel files={files} downloadFile={(file) => downloadFile(file)} deleteFile={deleteFile} />
                  <div>
                    <span>
                      <Clip />
                      &nbsp;{files.length}
                      {__(` вложенных файла`)}
                    </span>
                    <div className={styles.downloadAll} onClick={downloadAll}>
                      <DownloadArrow />
                      <p>&nbsp;{__("Скачать все файлы")}</p>
                    </div>
                  </div>
                </>
              )}
              {loading && (
                <Loader
                  type="bounceDots"
                  position="relative"
                  background="transparent"
                  zIndex={5}
                  width="100px"
                  height="57px"
                  containerType="bounceDots"
                />
              )}
            </div>

            {params.prim && (
              <>
                <h3 className={styles.subTitle}>{__("Описание задачи:")}</h3>
                <div
                  className={classNames("htmlContent", styles.text)}
                  dangerouslySetInnerHTML={createMarkup(params.prim)}
                />
              </>
            )}
            {renderComments()}
          </div>
          <Comments
            handleSubmit={() => handleSubmit(idEditComment ? "edit" : "add")}
            setComment={setComment}
            comment={comment}
          />
        </div>
        <div className={styles.info}>
          <div>
            {renderStatus()}
            <h3 className={styles.subTitle}>{__("Исполнитель(и):")}</h3>
            <Executors executors={params.id_executor} />
            <div className={styles.btnBox}>
              <Button
                type="button"
                variant="addGrey"
                text={__("Добавить исполнителя")}
                icon={<PlusIcon className={styles.plusIcon} />}
                full={true}
                onClick={onAddExecutor}
              />
            </div>
            {params.date_start && params.date_end && (
              <>
                <h3 className={styles.subTitle}>{__("Спринт:")}</h3>
                <div className={styles.sprint}>
                  <div className={styles.date}>{getFormatDate(params.date_start)}</div>
                  <div className={styles.date}>{getFormatDate(params.date_end)}</div>
                </div>
              </>
            )}
            <h3 className={styles.subTitle}>{__("Автор задачи:")}</h3>
            <UserBlock user={params.author_info} variant="light" />
          </div>
          {!isBacklog && (
            <div className={styles.settings}>
              <div className={styles.addTask}>
                <p onClick={onAddTask}>{__("Добавить подзадачу")}</p>
              </div>
              <div className={styles.settingsEdit} onClick={onEditTask}>
                <SettingsIcon className={styles.settingsIcon} />
                <span>{__("Редактировать")}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.addFileBlock}>
        <PlusIcon className={styles.addFileIcon} />
        <div className={styles.addFileMenu}>
          <div className={styles.item} onClick={() => setOpenFile(true)}>
            <FileIcon className={styles.fileIcon} /> <span>{__("Загрузить файл с системы 4Hub")}</span>
          </div>
          <div className={styles.item} onClick={fileSelect}>
            <PCIcon /> <span>{__("Загрузить файл с локального компьютера")}</span>
          </div>
        </div>
      </div>
      <input ref={inputRef} className={styles.hidden} type="file" multiple="multiple" onChange={uploadImage} />
      {openFile && (
        <PopUp set={() => setOpenFile(false)} zIndex={102}>
          <ChooseFile onClose={() => setOpenFile(false)} onAddFile={onAddFile} />
        </PopUp>
      )}
    </div>
  );
}

export default OpenTask;

OpenTask.propTypes = {
  params: PropTypes.oneOfType([taskTypes, eventProps])
};
