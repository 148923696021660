import { Paint, PaintValues } from "../../models/store/Cabinet/paint/paint";
import { PaintActions } from "../../models/store/Cabinet/paint/paintActions";
import { PaintTypes } from "../types/paintTypes";

export const onSetPaint = (key: Paint, value: PaintValues): PaintActions => {
  return {
    type: PaintTypes.SET_PAINT,
    payload: { key, value }
  };
};
