import { ReactComponent as ArrowLeft } from "assets/PrivateCabinet/mail/arrow-right.svg";
import { ILibraryCategory } from "collections/library";
import { Image } from "generalComponents/Image/Image";
import { FC } from "react";
import { useLocales } from "react-localized";
import { Link } from "react-router-dom";

import styles from "./LibraryCategory.module.sass";

interface ILibraryCategoryProps {
  category: ILibraryCategory;
}

const LibraryCategory: FC<ILibraryCategoryProps> = ({
  category: { category, title, description, image, page }
}): JSX.Element => {
  const { __ } = useLocales();

  return (
    <Link to={`/${page}/categories?chapter=${category}`} className={styles.item}>
      <h3 className={styles.item__title}>{title}</h3>
      <p className={styles.item__desc}>{description}</p>
      <Image src={image?.src} srcSet={image?.srcSet} alt={image?.alt} className={styles.item__img} />
      <span className={`${styles.item__btn} flex-center`}>
        <span>{__("Смотреть")}</span> <ArrowLeft width={16} height={16} fill="#274A42" />
      </span>
    </Link>
  );
};

export { LibraryCategory };
