import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import Button from "containers/Authentication/Button/Button";
import { useLogin } from "react-facebook";

import styles from "./Buttons.module.sass";

const FacebookButton = (): JSX.Element => {
  const { login, isLoading } = useLogin();

  async function handleLogin() {
    try {
      const response = await login({
        scope: "email"
      });
      console.log(response);
    } catch (error: any) {
      console.log(error.message);
    }
  }

  return (
    <Button onClick={handleLogin} disabled={isLoading}>
      <FacebookIcon className={styles.icon} />
      Facebook
    </Button>
  );
};

export default FacebookButton;
