import classNames from "classnames";
import { useFiltredUsers } from "generalComponents/Services/projectServices";
import React from "react";
import { useUserSelectors } from "Store/selectors/userSelectors";

import AddUser from "../../Components/AddUser/AddUser";
import styles from "./ProjectUsers.module.sass";
import UserProject from "./UserProject/UserProject";

const ProjectUsers = () => {
  const { userInfo } = useUserSelectors();
  const filtredUsers = useFiltredUsers();

  return (
    <div className={styles.usersWrap}>
      <AddUser />
      <div className={classNames(styles.users, `scrollbar-thin-${userInfo.theme}`)}>
        {filtredUsers.map((user) => (
          <UserProject key={user.id_user} user={user} />
        ))}
      </div>
    </div>
  );
};

export default ProjectUsers;
