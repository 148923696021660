import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialPasswordModalState } from "models/store/Cabinet/modals/passwordModal";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./PasswordModal.module.sass";

const PasswordModal = () => {
  const { __ } = useLocales();

  const { onSetPasswordModal } = useActions();

  const {
    passwordModal: { currentValue, title, successButtonText, successCallback }
  } = useGlobalModalsSelectors();

  const { value, onChange } = useInputValue(currentValue);

  const onSubmit = (): void => {
    successCallback(value);
    closeModal();
  };

  const closeModal = (): void => {
    onSetPasswordModal(initialPasswordModalState());
  };

  return (
    <PopUp>
      <HeaderModal title={title} />
      <div className={styles.passWrap}>
        <Input value={value} onChange={onChange} isPass label={__("Пароль")} />
        <div className={styles.btns}>
          <Button
            variant={ButtonVariantType.CANCEL}
            size={ButtonSizeType.SMALL}
            text={__("Отмена")}
            onClick={closeModal}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={successButtonText}
            onClick={onSubmit}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default PasswordModal;
