import { ReactComponent as EditIcon } from "assets/PrivateCabinet/edit.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/Path.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { useProjectMsg } from "generalComponents/Services/projectServices";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { ICreateTemplateProps } from "models/project/docsWorkSpace";
import { PROJECT_DOCS_VIEW } from "models/project/projectDocs";
import { IDocData } from "models/store/projects/projectStore";
import { IAddDocs, ITopMessages } from "models/store/projects/projectThunk";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { v4 as uuid } from "uuid";

import styles from "./WorkSpace.module.sass";

const CreateTemplate: React.FC<ICreateTemplateProps> = ({ setTypes }): JSX.Element => {
  const { __ } = useLocales();
  const { onAddDocs, onSetProjectModal } = useActions();
  const MSG = useProjectMsg();

  const [name, setName] = useState<string>(NO_VALUE);
  const [sections, setSections] = useState<IDocData[]>([]);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => setName(e.target.value);

  const addSection = (): void => {
    const params = {
      callback: (value: string) => {
        setSections((prev) => [...prev, { id: uuid(), section: value, content: "" }]);
      }
    };
    onSetProjectModal({ type: PROJECT_MODALS.ADD_SECTION, params });
  };

  const editSection = (el: string, id: string): void => {
    const params = {
      callback: (value: string) => {
        setSections((prev) => {
          const copy = [...prev];
          const idx = copy.findIndex((item) => item.id === id);
          copy.splice(idx, 1, { id, section: value, content: "" });
          return copy;
        });
      },
      name: el
    };
    onSetProjectModal({ type: PROJECT_MODALS.EDIT_SECTION, params });
  };

  const deleteSection = (id: string): void => setSections((prev) => prev.filter((el) => el.id !== id));

  const onSave = (): void => {
    const payload: IAddDocs[] = [
      {
        is_template: 1,
        name,
        data: JSON.stringify(sections),
        numberedData: JSON.stringify([]),
        inputs: JSON.stringify([]),
        footer: 0
      }
    ];
    const messages: ITopMessages = {
      error: MSG.ERROR,
      success: MSG.ADD_DOCS
    };
    const cb = (): void => setTypes(PROJECT_DOCS_VIEW.TEMPLATE);
    onAddDocs(payload, messages, cb);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.document}>
        <div className={styles.sheet}>
          <input
            type="text"
            value={name}
            onChange={handleChangeName}
            className={classNames(styles.inputName, { [styles.inputFilled]: name.length > 0 })}
            placeholder={__("Имя документа")}
          />
          <div className={styles.editorWrap}>
            {sections.map((item, i) => (
              <div key={i} className={styles.section}>
                <div className={styles.sectionHeader}>
                  <h3 className={styles.sectionName}>{item.section}</h3>
                  <div className={styles.btns}>
                    <ButtonIcon
                      handleClick={() => editSection(item.section, item.id)}
                      icon={<EditIcon />}
                      variant="white"
                    />
                    <ButtonIcon
                      handleClick={() => {
                        deleteSection(item.id);
                      }}
                      variant="white"
                      notHover
                    >
                      <TrashIcon className={styles.trashIcon} />
                    </ButtonIcon>
                  </div>
                </div>
                <div className={styles.empty} />
              </div>
            ))}
            <span>{__("Добавить раздел")}</span>
            <div className={styles.add} onClick={addSection}>
              <PlusIcon />
            </div>
          </div>
        </div>
        <div className={styles.stickyBtn}>
          <Button
            text={__("Сохранить")}
            type="button"
            onClick={onSave}
            variant={ButtonVariantType.OK}
            disabled={!name}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateTemplate;
