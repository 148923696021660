import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { HOT_KEYS } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useGetIconSize } from "Pages/Cabinet/Components/WorkElements/Hooks/useGetIconSize";
import { FC, MouseEvent, useState } from "react";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { getStartEndIndexsSelectedElements } from "utils/getStartEndIndexsSelectedElements";

interface ILinesIconProps {
  item: IFile | IFolder;
  checkBoxOption: CheckBoxVariantTypes;
  setCheckBoxOption: (type: CheckBoxVariantTypes) => void;
}

const LinesIcon: FC<ILinesIconProps> = ({ item, checkBoxOption, setCheckBoxOption }) => {
  const { onSetPickedItems, onSetCurrentItem } = useActions();
  const {
    files,
    pickedItems: { items, isLeftSide, isRightSide }
  } = useFilesSelectors();
  const { getFolderIconWidth, getFolderIconHeight, getFileIconWidth, getFileIconHeight } = useGetIconSize();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);

  const [_isCheckedOption] = useState<boolean>(checkBoxOption === CheckBoxVariantTypes.CHECKED);

  const { isLinesPreviewView } = useGetItemView();

  const onClickCheckBox = (): void => {
    if (!isLinesPreviewView) {
      onSetPickedItems({
        open: true,
        items: items.some((element) =>
          element.is_dir === 1 ? element.id_dir === item.id_dir : (element as IFile).fid === (item as IFile).fid
        )
          ? items.filter((element) =>
              element.is_dir === 1 ? element.id_dir !== item.id_dir : (element as IFile).fid !== (item as IFile).fid
            )
          : [...items, item],
        isLeftSide,
        isRightSide
      });
      onSetCurrentItem(item);
    } else {
      onSetPickedItems({
        open: true,
        items: items.some((element) =>
          element.is_dir === 1 ? element.id_dir === item.id_dir : (element as IFile).fid === (item as IFile).fid
        )
          ? items.filter((element) =>
              element.is_dir === 1 ? element.id_dir !== item.id_dir : (element as IFile).fid !== (item as IFile).fid
            )
          : (item.is_dir === 1 &&
              items.some(
                (el) =>
                  (el.is_dir === 1 && (el as IFolder).id_parent !== (item as IFolder).id_parent) ||
                  (el.is_dir === 0 && el.id_dir !== (item as IFolder).id_parent)
              )) ||
            (item.is_dir === 0 &&
              items.some(
                (el) =>
                  (el.is_dir === 1 && (el as IFolder).id_parent !== (item as IFile).id_dir) ||
                  (el.is_dir === 0 && el.id_dir !== item.id_dir)
              ))
          ? [item]
          : [...items, item],
        isLeftSide,
        isRightSide
      });

      onSetCurrentItem(item);
    }
  };

  const onSelectFilesRange = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (e[HOT_KEYS.SHIFT]) {
      if (!items.length) {
        onClickCheckBox();
      }

      if (items.length >= 1) {
        const file = item;
        const isFolder: boolean = item.is_dir === 1;

        const [indexFirst, indexSecond] = getStartEndIndexsSelectedElements(files, items);

        const currentIndex = files.findIndex((el) =>
          isFolder ? (el as IFolder).id_dir === (file as IFolder).id_dir : (el as IFile).fid === (file as IFile).fid
        );
        const isSelectedItem = items.some((item) =>
          isFolder ? (item as IFolder).id_dir === (file as IFolder).id_dir : (item as IFile).fid === (file as IFile).fid
        );

        let startIndex = indexFirst;
        let endIndex = indexSecond;

        if (currentIndex > indexFirst && currentIndex > indexSecond && isLeftSide && !isRightSide) {
          startIndex = indexSecond;
        }

        if (currentIndex < indexFirst && currentIndex < indexSecond && !isLeftSide && isRightSide) {
          endIndex = indexFirst;
        }

        if (isSelectedItem && isLeftSide) {
          startIndex = currentIndex;
        }

        if (isSelectedItem && isRightSide) {
          endIndex = currentIndex;
        }

        const filesRange = files.slice(Math.min(startIndex, currentIndex), Math.max(endIndex + 1, currentIndex + 1));

        onSetPickedItems({
          open: true,
          items: filesRange,
          isRightSide:
            (currentIndex > indexFirst && currentIndex > indexSecond) || (isRightSide && isSelectedItem) ? true : false,
          isLeftSide:
            (currentIndex < indexFirst && currentIndex < indexSecond) || (isLeftSide && isSelectedItem) ? true : false
        });
      }
    }
  };

  const onMouseEnterCheckBox = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.HOVER);
  };

  const onMouseLeaveCheckBox = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.DEFAULT);
  };

  if (checkBoxOption !== CheckBoxVariantTypes.NO_TYPE) {
    return (
      <CheckBox
        variant={checkBoxOption}
        onClick={(e) => {
          if (isLinesPreviewView) {
            onClickCheckBox();
          } else {
            if (!e[HOT_KEYS.META] && !e[HOT_KEYS.CTRL] && !e[HOT_KEYS.SHIFT]) {
              onClickCheckBox();
            }

            if ((e[HOT_KEYS.META] || e[HOT_KEYS.CTRL]) && !e[HOT_KEYS.SHIFT]) {
              onClickCheckBox();
            }

            onSelectFilesRange(e);
          }
        }}
        onMouseEnter={onMouseEnterCheckBox}
        onMouseLeave={onMouseLeaveCheckBox}
        width={16}
        height={16}
        increaseArea
      />
    );
  }

  return (
    <ItemIcon
      isFolder={item.is_dir === 1}
      width={item.is_dir === 1 ? getFolderIconWidth() : getFileIconWidth()}
      height={item.is_dir === 1 ? getFolderIconHeight() : getFileIconHeight()}
      color={item.color}
      passIcon={!isSafePage && item.is_dir && item.is_pass === 1}
      ext={(item as IFile).ext}
    />
  );
};

export default LinesIcon;
