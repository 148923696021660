import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";
import { IFavoriteItemsState } from "models/store/favoriteItems/favoriteItems";

export const useFavoriteItemsSelectors = (): IFavoriteItemsState => {
  const favoriteItems = useTypeSelector((s) => s.FavoriteItems.favoriteItems);

  return {
    favoriteItems
  };
};
