import PropTypes from "prop-types";
import React from "react";

const Lines = ({ color, id, first }) => {
  return (
    <svg width="100%" height="100%">
      <defs>
        <marker id={`arrowhead${id}`} markerWidth="8" markerHeight="6" refX="2" refY="3" orient="auto">
          <polygon points="0 0, 4 3, 0 6" fill={color} />
        </marker>
      </defs>
      {first && <line x1="50%" y1="0" x2="100%" y2="0" stroke={color} strokeWidth={1} />}
      <line x1="100%" y1="0" x2="100%" y2="90%" stroke={color} strokeWidth={1} />
      <line x1="100%" y1="90%" x2="50%" y2="90%" stroke={color} strokeWidth={1} />
      <line
        x1="50%"
        y1="90%"
        x2="50%"
        y2="98%"
        markerEnd={`url(#arrowhead${id})`}
        stroke={color}
        fill="transparent"
        strokeWidth={1}
      />
    </svg>
  );
};

export default Lines;

Lines.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string,
  first: PropTypes.bool
};
