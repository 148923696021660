import { useLocales } from "react-localized";

type Tariff = {
  name: string;
  price: {
    value: string | number;
    discount: string | number | null;
  };
  employees: number;
  corporateMail: number;
  diskSpace: number;
  safes: number;
  provided: number | null;
  projects: number;
  library: number;
  journal: number | null;
  sharedTable: number;
};

type TariffData = {
  base: Tariff;
  standart: Tariff;
  business: Tariff;
  businessTop: Tariff;
};

export const useTariffData = (selectedDuration: string) => {
  const { __ } = useLocales();

  const durations = ["1", "3", "6", "12"];

  if (!durations.includes(selectedDuration)) {
    throw new Error("Недопустимый срок для тарифа");
  }

  const createTariff = (duration: string) => {
    const baseDiscount = null as null;
    const standartDiscount = duration === "6" ? 19 : duration === "12" ? 19 : null;
    const businessDiscount = duration === "6" ? 32 : duration === "12" ? 32 : null;
    const businessTopDiscount = duration === "6" ? 56 : duration === "12" ? 56 : null;

    return {
      base: {
        name: __("Базовый"),
        price: { value: "Бесплатно", discount: baseDiscount },
        employees: 0,
        corporateMail: 0,
        diskSpace: 10,
        safes: 1,
        provided: null as null,
        projects: 1,
        library: Infinity,
        journal: null as null,
        sharedTable: 1
      },
      standart: {
        name: __("Стандарт"),
        price: { value: 25, discount: standartDiscount },
        employees: 0,
        corporateMail: 5,
        diskSpace: 10,
        safes: 50,
        provided: 10,
        projects: 1,
        library: Infinity,
        journal: Infinity,
        sharedTable: 2
      },
      business: {
        name: __("Бизнес"),
        price: { value: 40, discount: businessDiscount },
        employees: 25,
        corporateMail: Infinity,
        diskSpace: 1000,
        safes: 25,
        provided: 50,
        projects: 1,
        library: Infinity,
        journal: Infinity,
        sharedTable: 10
      },
      businessTop: {
        name: __("Бизнес+"),
        price: { value: 70, discount: businessTopDiscount },
        employees: 100,
        corporateMail: Infinity,
        diskSpace: 5000,
        safes: Infinity,
        provided: Infinity,
        projects: 1,
        library: Infinity,
        journal: Infinity,
        sharedTable: Infinity
      }
    };
  };

  const tariffData: TariffData = createTariff(selectedDuration);

  return tariffData;
};
