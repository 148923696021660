import React, { useEffect, useState } from "react";

import styles from "./CountDown.module.sass";

interface ICountDownProps {
  time: number; // seconds
  onComplete: () => void;
}

export const CountDown: React.FC<ICountDownProps> = ({ time, onComplete }): JSX.Element => {
  const [timeLeft, setTimeLeft] = useState<number>(time);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  useEffect(() => {
    if (timeLeft <= 0) {
      onComplete();
    } else {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    }
  }, [timeLeft, onComplete]);

  return (
    <p className={styles.time}>
      {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
    </p>
  );
};
