import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { NotesReducer } from "Store/reducers/notesReducer";

import { ArchiveReducer } from "./archiveReducer";
import { CabinetReducer } from "./cabinetReducer";
import { FavoriteItemsReducer } from "./favoriteItemsReducer";
import { FilesReducer } from "./filesReducer";
import { FilterCriteriaReducer } from "./filterCriteriaReducer";
import { FilterLibraryReducer } from "./filterLibraryReducer";
import { FilterTypeElementsReducer } from "./filterTypeElementsReducer";
import { FoldersReducer } from "./foldersReducer";
import { JoinProjectsReducer } from "./joinProjectsReducer";
import { JournalReducer } from "./journalReducer";
import { LibraryReducer } from "./libraryReducer";
import { MemoryReducer } from "./memoryReducer";
import { NotificationsReducer } from "./notificationsReducer";
import { PostboxReducer } from "./postboxReducer";
import { ProgramsReducer } from "./programsReducer";
import { ProjectReducer } from "./projectReducer";
import { RecentFilesReducer } from "./recentFilesReducer";
import { SafeReducer } from "./safeReducer";
import { SharedReducer } from "./sharedReducer";
import { SortByDateReducer } from "./sorByDateReducer";
import { SortCriteriaReducer } from "./sortCriteriaReducer";
import { TasksReducer } from "./tasksReducer";
import { TrashReducer } from "./trashReducer";
import { UserFiltersReducer } from "./userFiltersReducer";
import { UserReducer } from "./userReducer";

const persistUid = {
  key: "uid",
  storage: storageSession,
  whitelist: ["uid"]
};

export const rootReducer = combineReducers({
  user: persistReducer(persistUid, UserReducer),
  Cabinet: CabinetReducer,
  Memory: MemoryReducer,
  Postbox: PostboxReducer,
  Programs: ProgramsReducer,
  Tasks: TasksReducer,
  Projects: ProjectReducer,
  Notifications: NotificationsReducer,
  Folders: FoldersReducer,
  Files: FilesReducer,
  Archive: ArchiveReducer,
  Library: LibraryReducer,
  Journal: JournalReducer,
  Trash: TrashReducer,
  UserFilters: UserFiltersReducer,
  SortCriteria: SortCriteriaReducer,
  FilterCriteria: FilterCriteriaReducer,
  FilterTypeElements: FilterTypeElementsReducer,
  FilterLibrary: FilterLibraryReducer,
  SortByDate: SortByDateReducer,
  RecentFiles: RecentFilesReducer,
  Safe: SafeReducer,
  FavoriteItems: FavoriteItemsReducer,
  Shared: SharedReducer,
  JoinProjects: JoinProjectsReducer,
  Notes: NotesReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export type getRootState = () => RootState;
