import { ReactComponent as BacklogIcon } from "assets/icons/backlog.svg";
import { ReactComponent as TeamIcon } from "assets/icons/person.svg";
import { useContextProjecTrashElement } from "collections/joinProject";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { useIsSystemWatherRole } from "hooks/joinProjectHooks";
import { ButtonSizeType } from "models/generalComponents/button";
import { IProjectTeam } from "models/store/joinProjects/joinProgects";

import styles from "./JointProjectTrashTeam.module.sass";

export const JointProjectTrashTeam = ({ item }: { item: IProjectTeam }) => {
  const { contextMenuTrashTeam } = useContextProjecTrashElement();

  const isSystemWatherRole = useIsSystemWatherRole();

  return (
    <div className={styles.card}>
      <div className={styles.card__top}>
        <h3 className={styles.card__title}>{item.name}</h3>
        <p className={styles.card__prim}>{item.prim}</p>
      </div>
      <div className={styles.card__bottom}>
        <div className={styles.card__info}>
          <TeamIcon width={16} height={16} />
          <span>{item?.users?.length}</span>
        </div>
        <div className={styles.card__info}>
          <BacklogIcon width={16} height={16} />
          <span>{item?.tasks?.length ?? 0}</span>
        </div>
      </div>
      {!isSystemWatherRole && (
        <div className={styles.menuButton}>
          <PopoverContextMenu
            data={contextMenuTrashTeam(item) || []}
            iconVariant="button"
            btnSize={ButtonSizeType.SMALL}
          />
        </div>
      )}
    </div>
  );
};
