import { useTaskStatuses } from "collections/tasks";
import { ITaskStatus } from "models/generalComponents/collections/tasks";
import { IMyTask } from "models/store/tasks/tasks";
import { ITasksLineProps } from "models/tasks/myTasks";
import StatusKanban from "Pages/Cabinet/Components/Tasks/Components/StatusKanban/StatusKanban";
import React, { useState } from "react";

import styles from "./TasksKanban.module.sass";

const TasksKanban: React.FC<ITasksLineProps> = ({ tasks }): JSX.Element => {
  const { myTasks } = useTaskStatuses();
  const [currentTask, setCurrentTask] = useState<IMyTask>(null);
  const [currentStatus, setCurrentStatus] = useState<ITaskStatus>(null);

  return (
    <div className={styles.wrap}>
      {myTasks.map((el, i) => (
        <StatusKanban
          key={i}
          status={el}
          tasks={tasks}
          currentTask={currentTask}
          setCurrentTask={setCurrentTask}
          currentStatus={currentStatus}
          setCurrentStatus={setCurrentStatus}
        />
      ))}
    </div>
  );
};

export default TasksKanban;
