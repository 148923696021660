import { SORTING_TYPE } from "generalComponents/variables/global";
import { useFilterCriteriaSelector } from "Store/selectors/filterCriteriaSelectors";
import { useFilterTypeElementsSelector } from "Store/selectors/filterTypeElementsSelectors";
import { useSortByDateSelector } from "Store/selectors/sortByDateSelectors";
import { useSortCriteriaSelector } from "Store/selectors/sortCriteriaSelectors";

import { useAllSelectedSearchParams, useSelectedObjSearchParams } from "./useSelectedSearchParams";

export const useIsFilterDate = (): boolean => {
  const {
    sortByDate: { y, m, d }
  } = useSortByDateSelector();

  const isFilterDate: boolean = Boolean(y) && Boolean(m) && Boolean(d);

  return isFilterDate;
};

export const useIsFilterByDate = (): boolean => {
  const {
    objSearchParams: { year, month, day }
  } = useSelectedObjSearchParams();

  const isFilterDate: boolean = Boolean(year) && Boolean(month) && Boolean(day);

  return isFilterDate;
};

export const useIsFilterTypeElements = (): boolean => {
  const {
    filterTypeElements: { table, id_type, event_type }
  } = useFilterTypeElementsSelector();

  const isFilterTypeElements = Boolean(table) || Boolean(id_type) || Boolean(event_type);

  return isFilterTypeElements;
};

export const useIsFiltersLibrary = (): boolean => {
  const {
    objSearchParams: { chapter, id_dep, search_query }
  } = useSelectedObjSearchParams();

  const isFilterDate = useIsFilterByDate();

  const isFiltersLibrary = Boolean(chapter) || Boolean(id_dep) || Boolean(search_query) || isFilterDate;
  return isFiltersLibrary;
};

export const useIsFilterSort = (): boolean => {
  const {
    objSearchParams: { sort }
  } = useSelectedObjSearchParams();
  const isFiltersSort = Boolean(sort);

  return isFiltersSort;
};

export const useIsFiltersMarkers = (): boolean => {
  const { color, tags, emo } = useAllSelectedSearchParams();

  const isFiltersMarkers: boolean =
    Boolean(color.selectedValue) || Boolean(tags.selectedValue) || Boolean(emo.selectedValue);

  return isFiltersMarkers;
};

export const useIsLocalSortCriteria = (): boolean => {
  const { sortCriteria } = useSortCriteriaSelector();

  return Boolean(sortCriteria.group) || Boolean(sortCriteria.sort) || Boolean(sortCriteria.sort_reverse);
};

export const useIsLocalFiltersMarkers = (): boolean => {
  const { filterCriteria } = useFilterCriteriaSelector();

  return Boolean(filterCriteria.color) || Boolean(filterCriteria.emo) || Boolean(filterCriteria.tags);
};

export const useIsFiltersTeams = (): boolean => {
  const { team, participants } = useAllSelectedSearchParams();

  const isFiltersTeams: boolean = Boolean(team.selectedValue) || Boolean(participants.selectedValue);

  return isFiltersTeams;
};

export const useIsFiltersJoinProject = (): boolean => {
  const { priority, search } = useAllSelectedSearchParams();

  const isFiltersTeams = useIsFiltersTeams();
  const isFiltersMarkers = useIsFiltersMarkers();

  const isFiltersJoinProject =
    Boolean(priority.selectedValue) || Boolean(search.selectedValue) || isFiltersMarkers || isFiltersTeams;

  return isFiltersJoinProject;
};

export const useIsFilterByDateCreated = () => {
  const {
    objSearchParams: { sort, group, sort_reverse }
  } = useSelectedObjSearchParams();

  const isFilterByDateCreated: boolean = sort === SORTING_TYPE.DATE_CREATED && group === "1" && sort_reverse === "1";

  return isFilterByDateCreated;
};
