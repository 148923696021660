import { ReactComponent as ArrowLeft } from "assets/PrivateCabinet/mail/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/PrivateCabinet/mail/arrow-right.svg";
import Button from "generalComponents/Button/Button";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { FC } from "react";

import classes from "./SliderNav.module.sass";

interface ISliderNavProps {
  activeIndex: number;
  total: number;
  slidePrev: () => void;
  slideNext: () => void;
}

export const SliderNav: FC<ISliderNavProps> = ({ activeIndex, total, slidePrev, slideNext }) => (
  <div className={classes.sliderNav}>
    <Button
      onClick={slidePrev}
      size={ButtonSizeType.MEDIUM}
      iconL={<ArrowLeft width={20} height={20} fill="#274A42" />}
      variant={ButtonVariantType.EXRTA_LIGHT}
      className={classes.sliderNav__btn}
      disabled={activeIndex === 1}
    />
    <Button
      onClick={slideNext}
      size={ButtonSizeType.MEDIUM}
      iconL={<ArrowRight width={20} height={20} fill="#274A42" />}
      variant={ButtonVariantType.EXRTA_LIGHT}
      className={classes.sliderNav__btn}
      disabled={activeIndex === total}
    />
  </div>
);
