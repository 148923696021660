import { EVENT_TYPE } from "generalComponents/variables/global";

export const getEventType = (eventType: EVENT_TYPE) => {
  const isCopy: boolean = eventType === EVENT_TYPE.COPY;
  const isEditName: boolean = eventType === EVENT_TYPE.EDIT_NAME;
  const isEdit: boolean = eventType === EVENT_TYPE.EDIT;
  const isMove: boolean = eventType === EVENT_TYPE.MOVE;
  const isShare: boolean = eventType === EVENT_TYPE.SHARE;
  const isShareMe: boolean = eventType === EVENT_TYPE.SHARE_ME;
  const isSend: boolean = eventType === EVENT_TYPE.SEND;
  const isEditStatus: boolean = eventType === EVENT_TYPE.EDIT_STATUS;
  const isEmailsReg: boolean = eventType === EVENT_TYPE.REG;
  const isArchive: boolean = eventType === EVENT_TYPE.ARCHIVE;
  const isUnArchive: boolean = eventType === EVENT_TYPE.UNARCHIVE;
  const isIsDel: boolean = eventType === EVENT_TYPE.IS_DEL;
  const isUnDel: boolean = eventType === EVENT_TYPE.UN_IS_DEL;
  const isDel: boolean = eventType === EVENT_TYPE.DEL;
  const isEmployeeEnter: boolean = eventType === EVENT_TYPE.EMPLOYEE_ENTER;
  const isUserCopy: boolean = eventType === EVENT_TYPE.USER_COPY;

  return {
    isCopy,
    isEditName,
    isEdit,
    isMove,
    isShare,
    isShareMe,
    isSend,
    isEditStatus,
    isEmailsReg,
    isArchive,
    isUnArchive,
    isIsDel,
    isUnDel,
    isDel,
    isEmployeeEnter,
    isUserCopy
  };
};
