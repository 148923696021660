import Button from "generalComponents/Button/Button";
import CombinedElements from "generalComponents/Elements/CombinedElements";
import Input from "generalComponents/Input/Input";
import { ItemInfoDynamic } from "generalComponents/ItemInfoDynamic/ItemInfoDynamic";
import { EMOJIS } from "generalComponents/variables/elements";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { SAFE_MODALS } from "generalComponents/variables/safe";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { IFolder } from "models/store/folders/foldersStore";
import { IEditSafeFolder } from "models/store/safe/safePayloads";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./CreateSafeFolder.module.sass";

interface ICreateSafeFolderProps {
  onClose: () => void;
  type: SAFE_MODALS;
  folder?: IFolder;
}

const CreateSafeFolder: React.FC<ICreateSafeFolderProps> = ({ onClose, type, folder }) => {
  const { __ } = useLocales();
  const { safeDir } = useParams();
  const { onCreateSafeFolder, onEditSafeFolder } = useActions();
  const safeAuthorized = useAuthorizedSafe();
  const _isAddType = type === SAFE_MODALS.CREATE_FOLDER;

  const [name, setName] = useState<string>(_isAddType ? NO_VALUE : folder.name);
  const [emo, setEmo] = useState<string>(_isAddType ? EMOJIS.NO_EMOJI : folder?.emo);
  const [tagOption, setTagOption] = useState<string[]>(_isAddType ? [] : folder.tags);
  const [color, setColor] = useState<string>(_isAddType ? "" : folder.color);

  const onAddName = (e: React.ChangeEvent<HTMLInputElement>): void => setName(e.target.value);
  const onSubmit = (): void => {
    const payload = {
      ...safeAuthorized,
      name,
      idParent: _isAddType ? folder?.id_dir || safeDir || "0" : folder.id_parent
    };
    const payloadAdd = {
      ...payload,
      id_dir: safeDir,
      tags: tagOption || [],
      emo: emo || "",
      color
    };
    const payloadEdit: IEditSafeFolder = {
      ...payload,
      id_dirs: [folder?.id_dir],
      dir_name_new: name
    };
    _isAddType ? onCreateSafeFolder(payloadAdd) : onEditSafeFolder(payloadEdit, EVENT_TYPE.EDIT);
  };
  return (
    <div className={styles.wrap}>
      <HeaderModal title={_isAddType ? __("Создать папку") : __("Переименовать")} onClose={onClose} />
      <div className={styles.body}>
        {_isAddType && (
          <ItemInfoDynamic
            setColor={setColor}
            setEmo={setEmo}
            color={color}
            passIcon={_isAddType ? false : folder.is_pass === 1}
            name={name}
            tags={tagOption}
            emo={emo}
            isFolder
          />
        )}
        <Input value={name} onChange={onAddName} label={__("Имя папки")} placeholder={__("Введите имя папки")} />
        {_isAddType && (
          <CombinedElements
            tags={tagOption}
            onSelectTags={setTagOption}
            color={color}
            setColor={setColor}
            emo={emo}
            setEmo={setEmo}
          />
        )}
      </div>
      <div className={styles.btns}>
        <Button type="button" variant={ButtonVariantType.CANCEL} text={__("Отмена")} onClick={onClose} />
        <Button
          type="button"
          variant={ButtonVariantType.BLUE}
          text={_isAddType ? __("Добавить") : __("Сохранить")}
          onClick={onSubmit}
          disabled={!name}
        />
      </div>
    </div>
  );
};

export default CreateSafeFolder;
