import { useLocales } from "react-localized";

export function useContextMenuChat() {
  const { __ } = useLocales();
  return {
    group: [
      { name: __("Редактировать"), img: "edit", type: "editChatGroup" },
      { name: __("Удалить"), img: "garbage", type: "deleteChatGroup" },
      {
        name: __("Покинуть группу"),
        img: "garbage",
        type: "leaveFromChatGroup"
      }
    ],
    secretChat: [{ name: __("Удалить"), img: "garbage", type: "deleteSecretChat" }],
    userInGroup: [
      { name: __("Очистить историю у меня"), img: "brush-2", type: "clearMessages" },
      { name: __("Очистить историю у всех"), img: "brush-2", type: "clearAllMessages" },
      { name: __("Заблокировать"), img: "block-user", type: "blockUser" },
      {
        name: __("Удалить из группы"),
        img: "garbage",
        type: "deleteUserFromGroup"
      }
    ],
    recentChat: [
      { name: __("Очистить историю у меня"), img: "brush-2", type: "clearMessages" },
      { name: __("Очистить историю у всех"), img: "brush-2", type: "clearAllMessages" },
      { name: __("Заблокировать"), img: "block-user", type: "blockUser" },
      {
        name: __("Отметить непрочитанным"),
        img: "mark-unread",
        type: "markAsUnread"
      }
    ],
    contact: [
      { name: __("Очистить историю у меня"), img: "brush-2", type: "clearMessages" },
      { name: __("Очистить историю у всех"), img: "brush-2", type: "clearAllMessages" },
      { name: __("Заблокировать"), img: "block-user", type: "blockUser" },
      {
        name: __("Отметить непрочитанным"),
        img: "mark-unread",
        type: "markAsUnread"
      },
      { name: __("Удалить"), img: "garbage", type: "deleteContact" }
    ],
    timer: [
      { name: __("1 час") },
      { name: __("45 мин.") },
      { name: __("30 мин.") },
      { name: __("15 мин.") },
      { name: __("10 мин.") },
      { name: __("5 мин.") },
      { name: __("1 мин.") },
      { name: __("30 сек.") },
      { name: __("20 сек.") }
    ],
    message: [
      { name: __("Редактировать сообщение"), img: "edit", type: "editMessage" },
      { name: __("Скачать"), img: "download-blue", type: "download" },
      { name: __("Удалить сообщение"), img: "garbage", type: "deleteMessage" }
    ],
    uploadFile: [
      { name: __("Камера"), img: "camera", type: "createMediaFromCamera" },
      { name: __("Файлы с системы 4Hub"), img: "files", type: "add4hubFile" },
      { name: __("Файлы с компьютера"), img: "files", type: "addPcFile" },
      { name: __("Создать задачу"), img: "task", type: "addTask" },
      { name: __("Создать быструю задачу"), img: "task", type: "addQuickTask" }
    ]
  };
}
