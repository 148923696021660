import Button from "generalComponents/Button/Button";
import { ChooseFolder } from "generalComponents/ChooseFolder/ChooseFolder";
import CombinedElements from "generalComponents/Elements/CombinedElements";
import Input from "generalComponents/Input/Input";
import { ItemInfoDynamic } from "generalComponents/ItemInfoDynamic/ItemInfoDynamic";
import PopUp from "generalComponents/PopUp/PopUp";
import { useCurrentFolder } from "generalComponents/Services/folderServices/folderServices";
import { Times } from "generalComponents/Times/Times";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ButtonVariantType } from "models/generalComponents/button";
import { DEFAULT_FOLDERS } from "models/generalComponents/folders";
import { initialCreateFileModalState } from "models/store/Cabinet/modals/createFileModal";
import { IFolder } from "models/store/folders/foldersStore";
import { useState } from "react";
import { type ChangeEvent } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { v4 as uuid } from "uuid";

import styles from "./CreateFile.module.sass";

const CreateFile: React.FC = (): JSX.Element => {
  const { __ } = useLocales();
  const { safeDir } = useParams();

  const {
    createFileModal: { dep, ext, folders, onLoading }
  } = useGlobalModalsSelectors();

  const currentFolder = useCurrentFolder();
  const { onSetCreateFileModal } = useActions();
  const defaultFolder = folders.find((el) => el.name === DEFAULT_FOLDERS.ALL);

  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const isProject = useCurrentPathStarstWith(ROUTES.PROJECT);

  const [name, setName] = useState<string>(NO_VALUE);
  const [tags, setTags] = useState<string[]>([]);
  const [emo, setEmo] = useState<string>("");
  const [color, setColor] = useState<string>();
  const [folder, setFolder] = useState<IFolder>(currentFolder ?? defaultFolder);

  const onCloseTab = (): void => {
    onSetCreateFileModal(initialCreateFileModalState());
  };
  const onChangeName = (e: ChangeEvent<HTMLInputElement>): void => setName(e.target.value);

  const onAddFile = (isOpen: boolean = false): void => {
    const safe_dir = safeDir ?? "0";
    const payload = {
      options: {
        name,
        tags,
        emo,
        color: color,
        id_dir: isSafePage ? safe_dir : folder.id_dir,
        ext,
        dep
      },
      time: 0,
      percent: 0,
      id: uuid(),
      done: false,
      create: true,
      replace: false,
      isOpen
    };
    onLoading(payload);
    onCloseTab();
  };

  return (
    <PopUp set={onCloseTab}>
      <div className={styles.createFolderWrap}>
        <Times handleClick={onCloseTab} />
        <span className={styles.title}>{__("Создать файл")}</span>
        <ItemInfoDynamic
          setColor={setColor}
          setEmo={setEmo}
          color={color}
          passIcon={false}
          ext={ext}
          name={name}
          tags={tags}
          emo={emo}
          isFolder={false}
        />

        <div className={styles.inputFieldsWrap}>
          <Input type="text" placeholder={__("Имя файла")} value={name} onChange={onChangeName} />
          {!isSafePage && <ChooseFolder onSelect={(folder) => setFolder(folder)} folder={folder} folders={folders} />}
          {!isProject && (
            <CombinedElements
              color={color}
              setColor={setColor}
              emo={emo}
              setEmo={setEmo}
              tags={tags}
              onSelectTags={setTags}
            />
          )}
        </div>
        <div className={styles.buttonsWrap}>
          <Button type="button" variant={ButtonVariantType.CANCEL} text={__("Отмена")} onClick={onCloseTab} />
          <Button
            type="button"
            variant={ButtonVariantType.OK}
            text={__("Создать")}
            onClick={() => onAddFile()}
            disabled={Boolean(!name)}
          />
          <Button
            type="button"
            variant={ButtonVariantType.OK}
            text={__("Создать и открыть в редакторе")}
            onClick={() => onAddFile(true)}
            full
            disabled={Boolean(!name)}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default CreateFile;
