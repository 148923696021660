import { ReactComponent as BarsIcon } from "assets/PrivateCabinet/bars.svg";
import { ReactComponent as LinesIcon } from "assets/PrivateCabinet/lines.svg";
import { ReactComponent as PreviewIcon } from "assets/PrivateCabinet/preview.svg";
import { ReactComponent as VerticalLinesIcon } from "assets/PrivateCabinet/vertical-lines.svg";
import classNames from "classnames";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { useIsFilesPage } from "generalComponents/Services/fileServices/fileServices";
import { useIsFavorite, useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { ViewType } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import styles from "./ViewTipeTabs.module.sass";

interface ViewTipeTabsProps {
  miniTipeTabs?: boolean;
}

const ViewTipeTabs: FC<ViewTipeTabsProps> = ({ miniTipeTabs = false }): JSX.Element => {
  const { __ } = useLocales();
  const { isBarsView, isLinesView, isBarsPreviewView, isLinesPreviewView } = useGetItemView();
  const { isFavorite } = useIsFavorite();
  const { isShared } = useIsShared();
  const { isFilesPage } = useIsFilesPage();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const { safeId } = useParams();
  const { search } = useLocation();
  const naigate = useNavigate();

  const { onGetMyFiles, onSetNextFiles, onResetFilesList, onSetPickedItems, onSetCurrentItem, onSetWorkElementsView } =
    useActions();

  const onChangeView = (type: ViewType) => {
    if (isLinesPreviewView && !isFavorite && !isShared && !isSafePage && !isFilesPage) {
      onSetNextFiles(null);
      onGetMyFiles({
        isReset: true
      });
    }
    if (isLinesPreviewView && isSafePage) {
      naigate(`/${ROUTES.SAFE}/${safeId}${search}`);
    }

    onSetCurrentItem(null);
    onSetWorkElementsView(type);
  };

  const onCheangeToLinePreview = (): void => {
    if (!isFavorite && !isShared && !isFilesPage && !isSafePage) {
      onResetFilesList();
    }
    if (isSafePage) {
      naigate(`/${ROUTES.SAFE}/${safeId}${search}`);
    }
    onSetPickedItems({ items: [], open: false });
    onSetWorkElementsView(ViewType.LINES_PREVIEW);
  };

  return (
    <div
      className={classNames(styles.root, {
        [styles.isLinesViewSelected]: isLinesView,
        [styles.isBarsPreviewViewSelected]: isBarsPreviewView,
        [styles.isLinesPreviewViewSelected]: isLinesPreviewView
      })}
    >
      <button
        onClick={() => onChangeView(ViewType.BARS)}
        className={classNames(styles.button, {
          [styles.selected]: isBarsView
        })}
        type="button"
        title={__("Сетка")}
      >
        <BarsIcon />
      </button>
      <button
        onClick={() => onChangeView(ViewType.LINES)}
        className={classNames(styles.button, {
          [styles.selected]: isLinesView
        })}
        type="button"
        title={__("Список")}
      >
        <LinesIcon />
      </button>
      {!miniTipeTabs && (
        <>
          <button
            onClick={() => onChangeView(ViewType.BARS_PREVIEW)}
            className={classNames(styles.button, {
              [styles.selected]: isBarsPreviewView
            })}
            type="button"
            title={__("Галерея")}
          >
            <PreviewIcon />
          </button>
          <button
            onClick={onCheangeToLinePreview}
            className={classNames(styles.button, {
              [styles.selected]: isLinesPreviewView
            })}
            type="button"
            title={__("Колонки")}
          >
            <VerticalLinesIcon />
          </button>
        </>
      )}
    </div>
  );
};

export default ViewTipeTabs;
