import api from "api";
import { ReactComponent as SmileIcon } from "assets/icons/smile.svg";
import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import EmployeeData from "generalComponents/Modals/Components/ProfileModals/AddEmployee/EmployeeData/EmployeeData";
import {
  DataFormTypes,
  editData,
  INITIAL_DATA_STATE,
  reducerData
} from "generalComponents/Modals/Components/ProfileModals/AddEmployee/lib/stateReducer";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { useEffect, useReducer, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { dataURLToFileSize } from "utils/filesConvert";

import styles from "./NewEmployeePage.module.sass";

const NewEmployeePage = () => {
  const { __ } = useLocales();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { onSetTopMessageModal, onSetConfirmCodeModal } = useActions();
  const uid = searchParams.get("uid");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isEditTel, setIsEditTel] = useState<boolean>(false);

  const [data, dispatchData] = useReducer(reducerData, INITIAL_DATA_STATE);
  const { value: password, onChange: setPassword, error: passwordError, onError: onPasswordError } = useInputValue();
  const {
    value: passwordRepeat,
    onChange: setPasswordRepeat,
    error: repeatError,
    onError: onRepeatError
  } = useInputValue();
  const _tel = useRef<string>("");

  useEffect(() => {
    onGetUserInfo();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!data.tel) return setIsEditTel(false);
    if (data.tel !== `+${_tel.current}`) setIsEditTel(true);
    else setIsEditTel(false);
  }, [data.tel, _tel]);

  const onGetUserInfo = async () => {
    try {
      const { data } = await api.get("/ajax/user_get.php?", { params: { uid } });
      if (data.data.pass) {
        navigate(`/${ROUTES.SIGN_IN}`, { replace: true });
      }
      const employee = editData(data.data);
      dispatchData({ type: DataFormTypes.NEW_EMPLOYEE, employee });
      _tel.current = data.data.tel;
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  const onChangeTel = async () => {
    try {
      const params = {
        uid: uid,
        tel: _tel.current ? _tel.current : data.tel.replace("+", ""),
        action: "send"
      };
      await api.get("/ajax/user_confirm.php?", { params });
      onSetConfirmCodeModal({
        open: true,
        type: _tel.current ? "CHANGE_PHONE" : "CONFIRM_PHONE",
        callbackSuccess: () => {
          setIsEditTel(false);
          _tel.current = data.tel;
        },
        info: {
          uid,
          newValue: data.tel,
          prevValue: `+${_tel.current}`,
          step: _tel.current ? 1 : 0
        },
        callbackRepeat: () => onChangeTel()
      });
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  const onPasswordBlur = (): void => {
    if (password.length >= 4) return;
    onPasswordError(__("Пароль должен быть не менее 4 символов"));
  };

  const onRepeatBlur = (): void => {
    if (password !== passwordRepeat) {
      onRepeatError(__("Пароли не совпадают"));
      return;
    }
  };

  const onSave = async () => {
    onRepeatBlur();
    try {
      const tels = Object.values(data.tels)
        .map((t) => t.replace("+", ""))
        .filter((v) => v);
      const emails = Object.values(data.emails).filter((v) => v);

      const payload = new FormData();
      let icon = null;
      if (data.icon?.startsWith("data:image/jpeg;base64")) {
        icon = await dataURLToFileSize(data.icon);
      }
      payload.append("uid", uid);
      payload.append("fname", data.fname);
      payload.append("sname", data.sname);
      payload.append("pname", data.pname);
      payload.append("email", data.email);
      payload.append("tel", data.tel);
      payload.append("emails", JSON.stringify(emails));
      payload.append("tels", JSON.stringify(tels));
      payload.append("pass", password);
      payload.append("icon", icon);
      payload.append("icon_del", !data.icon ? "1" : "0");
      const response = await api.post(`/ajax/user_edit2.php`, payload);
      if (response.data.ok) setIsSuccess(true);
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  if (!data.email) return;

  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        {isSuccess ? (
          <div className={styles.successBlock}>
            <SmileIcon fill="#46CC6B" width={48} height={48} />
            <div>
              <h5>{__("Готово")}</h5>
              <p>{__("Аккаунт настроен, можно начинать работу")}</p>
              <Link to={`/${ROUTES.SIGN_IN}`} className={styles.link}>
                {__("Вход")}
              </Link>
            </div>
          </div>
        ) : (
          <>
            <HeaderModal title={__("Настройка аккаунта")} />
            <div className={styles.form}>
              <EmployeeData
                data={data}
                dispatchData={dispatchData}
                isNew={true}
                isEditTel={isEditTel}
                onChangeTel={onChangeTel}
                isEdit
              />
              <div style={{ height: 12 }} />
              <Input
                className={styles.input}
                value={password}
                onChange={setPassword}
                label={__("Пароль")}
                placeholder={__("Введите")}
                isPass
                error={Boolean(passwordError)}
                errorText={passwordError}
                onBlur={onPasswordBlur}
                require
              />
              <div style={{ height: 8 }} />
              <Input
                className={styles.input}
                value={passwordRepeat}
                onChange={setPasswordRepeat}
                onBlur={onRepeatBlur}
                label={__("Повторите пароль")}
                placeholder={__("Введите")}
                error={Boolean(repeatError)}
                errorText={repeatError}
                isPass
                require
              />
            </div>
            <div className={styles.footer}>
              <Button
                variant={ButtonVariantType.BLUE}
                size={ButtonSizeType.MEDIUM}
                text={__("Сохранить")}
                onClick={onSave}
                disabled={!password || Boolean(passwordError) || Boolean(repeatError) || !data.fname || isEditTel}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewEmployeePage;
