import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import { Observer } from "generalComponents/Observer/Observer";
import { WorkSpaceWrap } from "generalComponents/WorkSpaceWrap/WorkSpaceWrap";
import { useActions } from "hooks/useActions";
import { useIsFiltersJoinProject } from "hooks/useIsFilters";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { LoaderTypes } from "models/generalComponents/loader";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import { JointProjectsHistoryGrid } from "./components/JointProjectsHistoryGrid/JointProjectsHistoryGrid";

export const JointProjectsHistoryContent: FC = (): JSX.Element => {
  const { __ } = useLocales();

  const { loader, project, page, total } = useJoinProjectsSelectors();

  const { onSetProjectLoader, onSetProjectPage, onFetchAllTaskProject } = useActions();

  const { color, emo, tags, priority, search, team, participants } = useAllSelectedSearchParams();

  const projectAllTasks = project?.allTasks;

  const isFilters = useIsFiltersJoinProject();

  const loadMore = (): void => {
    const controller = new AbortController();
    onSetProjectPage(page + 1);
    onSetProjectLoader(true);

    const payload = {
      team: team.selectedValue,
      selectedParticipants: participants.selectedValue?.join(","),
      per_page: 500,
      color: color.selectedValue,
      emo: emo.selectedValue,
      tags: tags.selectedValue,
      priority: priority.selectedValue,
      search: search.selectedValue,
      callBack: () => onSetProjectLoader(false),
      controller
    };

    onFetchAllTaskProject(payload);
  };

  const renderMyFiles = (): JSX.Element => {
    if (projectAllTasks !== null) {
      if (total === 0) {
        if (isFilters) {
          return <Clue icon="neutral" text={__("Нет задач соответствующих параметрам фильтрации")} />;
        }
        return <Clue icon="sad" text={__("Тут пока пусто")} />;
      }

      return <JointProjectsHistoryGrid tasks={projectAllTasks} />;
    }
  };

  return (
    <WorkSpaceWrap>
      {loader && page === 1 ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <>
          {renderMyFiles()}
          {<Observer loader={loader} page={page} total={total} callBack={loadMore} />}
        </>
      )}
    </WorkSpaceWrap>
  );
};
