import classNames from "classnames";
import { colors } from "collections/markers";
import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialNoteEditState } from "models/store/Cabinet/modals/noteEdit";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./NotesCategoriesModal.module.sass";

const NotesCategoriesModal = () => {
  const { __ } = useLocales();

  const { onSetNotesCategoriesModal, onAddNoteCategory, onEditNoteCategory, onSetActiveNote } = useActions();
  const {
    notesCategories: { category }
  } = useGlobalModalsSelectors();

  const [name, setName] = useState<string>(category?.name ?? "");
  const [color, setColor] = useState<string>();

  const onSubmit = (): void => {
    if (category) {
      onEditNoteCategory({ ...category, name });
    } else {
      onAddNoteCategory({ name, color });
    }
    closeModal();
  };

  const closeModal = (): void => {
    onSetActiveNote(null);
    onSetNotesCategoriesModal(initialNoteEditState());
  };

  return (
    <PopUp>
      <HeaderModal title={__("Имя папки")} />
      <div className={styles.passWrap}>
        <Input value={name} onChange={(e) => setName(e.currentTarget.value)} placeholder={__("Введите")} />

        {!category && (
          <div className={styles.list}>
            {colors.slice(0, 8).map((el, i) => (
              <div
                key={i}
                className={classNames(styles.element, { [styles.active]: el === color })}
                onClick={() => setColor(el)}
              >
                <div className={styles.color} style={{ background: el }} />
              </div>
            ))}
          </div>
        )}

        <div className={styles.btns}>
          <Button
            variant={ButtonVariantType.CANCEL}
            size={ButtonSizeType.SMALL}
            text={__("Отмена")}
            onClick={closeModal}
          />
          <Button
            disabled={!name}
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={__("Сохранить")}
            onClick={onSubmit}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default NotesCategoriesModal;
