import { Notifications } from "Store/types/notificationsTypes";

export interface IReminderNotification {
  id: string;
  text: string;
  date: string;
  period_d: string;
  period_w: string;
  period_m: string;
  period_y: string;
  date2: string;
  is_read: string;
  date_view: string;
  type: Notifications.REMINDER;
}

export interface IChatNotification {
  attachment: {
    preview_small: string;
  };
  day: string;
  deadline: string;
  id: string;
  id_group: string;
  id_user: string;
  id_user_to: string;
  is_del: string;
  is_read: string;
  text: string;
  ut: string;
  type: Notifications.CHAT;
}

export interface ICallRoomInvitations {
  uid: string;
  room_id: string;
  from: {
    icon: string[];
    id_user: string;
    name: string;
  };
  users_to: string[];
  userId: number;
  userName: string;
  rooms: Record<string, string[]>;
  connected_users: string[];
  url: string;
  type: Notifications.INVITE_CALLROOM;
}

export interface INotificationsState {
  notifications: (IReminderNotification | IChatNotification | ICallRoomInvitations)[];
}

export function initialNotificationsState(): INotificationsState {
  return {
    notifications: []
  };
}
