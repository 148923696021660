import { FC, ReactNode } from "react";

import styles from "./WrapperPage.module.sass";

interface IWrapperPageProps {
  children: ReactNode;
}

export const WrapperPage: FC<IWrapperPageProps> = ({ children }): JSX.Element => (
  <div className={styles.box}>{children}</div>
);
