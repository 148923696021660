import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as ArchiveIcon } from "assets/PrivateCabinet/mail/archive.svg";
import { ReactComponent as DraftsIcon } from "assets/PrivateCabinet/mail/drafts.svg";
import { ReactComponent as FavouriteIcon } from "assets/PrivateCabinet/mail/favourite.svg";
import { ReactComponent as ImportantIcon } from "assets/PrivateCabinet/mail/important.svg";
import { ReactComponent as InboxIcon } from "assets/PrivateCabinet/mail/incoming.svg";
import { ReactComponent as ScheduledIcon } from "assets/PrivateCabinet/mail/scheduled.svg";
import { ReactComponent as SentIcon } from "assets/PrivateCabinet/mail/sent.svg";
import { ReactComponent as SpamIcon } from "assets/PrivateCabinet/mail/spam.svg";
import { getTime, startOfToday } from "date-fns";
import { POSTBOX_SYSTEM_FOLDER, SystemPostboxFolders } from "models/postbox";
import { IPostbox } from "models/store/postbox/postbox";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";
import { dateISO } from "utils/dateToISO";

import { useDateFormat } from "./useDateFormat";

export const useValidPass = () => {
  const { __ } = useLocales();

  return (pass: string, cb: (val: string) => void): void => {
    if (pass.length < 8) cb(__("Пароль должен быть не менее 8-ми символов"));
    return;
  };
};

export const useValidCheckPass = () => {
  const { __ } = useLocales();

  return (pass: string, checkPass: string, cb: (val: string) => void): void => {
    if (pass !== checkPass) cb(__("Пароли не совпадают"));
    return;
  };
};

export const useSystemPostboxFolder = (): SystemPostboxFolders => {
  const { __ } = useLocales();
  return {
    [POSTBOX_SYSTEM_FOLDER.INBOX]: {
      name: __("Входящие"),
      img: <InboxIcon />
    },
    [POSTBOX_SYSTEM_FOLDER.Sent]: {
      name: __("Исходящие"),
      img: <SentIcon />
    },
    [POSTBOX_SYSTEM_FOLDER.ARCHIVE]: {
      name: __("Архив"),
      img: <ArchiveIcon />
    },
    [POSTBOX_SYSTEM_FOLDER.Drafts]: {
      name: __("Черновик"),
      img: <DraftsIcon />
    },
    [POSTBOX_SYSTEM_FOLDER.LIKED]: {
      name: __("Избранное"),
      img: <FavouriteIcon />
    },
    [POSTBOX_SYSTEM_FOLDER.IMPORTANT]: {
      name: __("Важные"),
      img: <ImportantIcon />
    },

    [POSTBOX_SYSTEM_FOLDER.SCHEDULED]: {
      name: __("Запланированные"),
      img: <ScheduledIcon />
    },

    [POSTBOX_SYSTEM_FOLDER.Spam]: {
      name: __("Спам"),
      img: <SpamIcon />
    },

    [POSTBOX_SYSTEM_FOLDER.Trash]: {
      name: __("Корзина"),
      img: <TrashIcon />
    }
  };
};

export const useFindPostbox = (): { postbox: IPostbox } => {
  const { postboxId } = useParams();
  const { postboxes } = usePostboxSelectors();
  const postbox = postboxes.find((el) => el.id === postboxId);
  return { postbox };
};

export const useIsPostboxFolder = (): boolean => {
  const { folderName } = useParams();
  const { postbox } = useFindPostbox();
  const systemFolders = postbox.folders.system.map((f) => f.orig);
  const customFolders = postbox.folders.custom.map((f) => f.orig);
  const allFolders = [...systemFolders, ...customFolders];
  return allFolders.some((el) => el === folderName);
};

export const usePostboxFolder = () => {
  const { folderName } = useParams();

  const _isInbox = folderName === POSTBOX_SYSTEM_FOLDER.INBOX;
  const _isSent = folderName === POSTBOX_SYSTEM_FOLDER.Sent;
  const _isDraft = folderName === POSTBOX_SYSTEM_FOLDER.Drafts;
  const _isFlag = folderName === POSTBOX_SYSTEM_FOLDER.LIKED || folderName === POSTBOX_SYSTEM_FOLDER.IMPORTANT;
  const _isSpam = folderName === POSTBOX_SYSTEM_FOLDER.Spam;
  const _isTrash = folderName === POSTBOX_SYSTEM_FOLDER.Trash;
  const _isArchive = folderName === POSTBOX_SYSTEM_FOLDER.ARCHIVE;
  const _isSchedule = folderName === POSTBOX_SYSTEM_FOLDER.SCHEDULED;
  return {
    _isInbox,
    _isSent,
    _isDraft,
    _isFlag,
    _isSpam,
    _isTrash,
    _isArchive,
    _isSchedule
  };
};

export const useLetterTime = (): { letterTime: (val: string) => string } => {
  const { formatDate } = useDateFormat();
  const startToday = getTime(startOfToday());

  const getLetterTime = (date: string): string => {
    const timeLetter = new Date(dateISO(date)).getTime();
    if (!timeLetter) {
      return "DateError";
    }
    return startToday > timeLetter ? formatDate(date) : formatDate(date, "time");
  };

  return { letterTime: (date: string) => getLetterTime(date) };
};
