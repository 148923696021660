import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { taskTypes } from "types/Project";

import { useProjectTasks } from "../../../helpers";
import styles from "./AnaliticsPanel.module.sass";

const AnaliticsPanel = ({ filtredTasks }) => {
  const project = useSelector((s) => s.Projects.project);
  const { currentTasks } = useProjectTasks();
  const allTasks = currentTasks.length;

  const renderItem = (category) => {
    const amount = filtredTasks[category.id].length;
    const width = amount ? `${(amount / allTasks) * 100}%` : "20px";
    return (
      <div className={styles.category} key={category.id} style={{ width: width, color: category.color.dark }}>
        <span>{amount}</span>
        <div
          className={styles.line}
          style={{ background: `repeating-linear-gradient(-60deg, #fff 0 0.5px, ${category.color.dark} 0 5px)` }}
        />
      </div>
    );
  };
  return <div className={styles.wrap}>{project.tasks_category.map((category) => renderItem(category))}</div>;
};

export default AnaliticsPanel;

AnaliticsPanel.propTypes = {
  filtredTasks: PropTypes.objectOf(PropTypes.arrayOf(taskTypes))
};
