export enum IRecordType {
  VIDEO = "video",
  AUDIO = "audio"
}

export interface IRecordModalState {
  open: boolean;
  type: IRecordType;
  callBack: (el: Blob) => void;
}

export function initialRecordModalState(): IRecordModalState {
  return { open: false, type: undefined, callBack: undefined };
}
