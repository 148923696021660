import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share_2.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useFindUserById } from "hooks/employeesHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITag } from "models/store/user/user";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./TagsItem.module.sass";

const TagsItem = ({ tag }: { tag: ITag }) => {
  const { onSetTagEditModal, deleteTag } = useActions();
  const { userInfo } = useUserSelectors();
  const user = useFindUserById(tag.id_user);

  if (!user) return;
  return (
    <div className={styles.container}>
      <div className={styles.name}>{tag.name}</div>
      <div className={styles.user}>
        <Avatar name={`${user.fname} ${user.sname}`} size={24} imgSrc={`${projectSrc}${user.icon}`} /> {user.name}{" "}
        {user.fname}
      </div>
      <div className={styles.settings}>
        {tag.id_company && <ShareIcon width={14} />}
        {tag.id_user === userInfo.id && (
          <div className={styles.buttons}>
            <Button
              size={ButtonSizeType.EXTRA_SMALL}
              variant={ButtonVariantType.OPACITY}
              isSquare
              iconL={<EditIcon />}
              onClick={() => onSetTagEditModal({ open: true, tag })}
            />
            <Button
              size={ButtonSizeType.EXTRA_SMALL}
              variant={ButtonVariantType.OPACITY}
              isSquare
              iconL={<TrashIcon />}
              onClick={() => deleteTag(tag.id)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TagsItem;
