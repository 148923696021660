import { ReactComponent as BacklogIcon } from "assets/PrivateCabinet/backlog.svg";
import { ReactComponent as EditIcon } from "assets/PrivateCabinet/edit.svg";
import { ReactComponent as FireIcon } from "assets/PrivateCabinet/fire.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import { ReactComponent as InfoIcon } from "assets/PrivateCabinet/info-2.svg";
import { ReactComponent as CopyIcon } from "assets/PrivateCabinet/link-2.svg";
import { ReactComponent as LogoutIcon } from "assets/PrivateCabinet/logout.svg";
import { ReactComponent as OpenIcon } from "assets/PrivateCabinet/open.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/plus-3.svg";
import { ReactComponent as SafeIcon } from "assets/PrivateCabinet/safe-2.svg";
import { ReactComponent as UserAddIcon } from "assets/PrivateCabinet/user-add.svg";
import { ReactComponent as UserRemoveIcon } from "assets/PrivateCabinet/user-remove.svg";
import { ReactComponent as UserSettinghIcon } from "assets/PrivateCabinet/user-setting.svg";
import { getTime, startOfDay } from "date-fns";
import { useFiltredRole, useFiltredTask } from "generalComponents/Services/projectServices";
import { PROJECT_CONTEXT_MENU, PROJECT_MODALS } from "generalComponents/variables/project";
import React from "react";
import { useLocales } from "react-localized";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import { typeCheck } from "../../../../generalComponents/Services/browserServices";
import { TYPES } from "../../../../generalComponents/variables/global";

/**
 * @deprecated use useProjectMsg from Services project
 */
export const useProjectMsg = () => {
  const { __ } = useLocales();
  return {
    ERROR_PROJECT: __("Ошибка при получении проектов"),
    ERROR: __("Что-то пошло не так, попробуйте еще раз"),
    [PROJECT_MODALS.ADD_PROJECT]: __("Проект успешно добавлен"),
    [PROJECT_MODALS.EDIT_PROJECT]: __("Ваш проект 4Hub успешно изменен"),
    [PROJECT_MODALS.ADD_ROLES]: {
      title: __("Роли успешно добавлены"),
      text: __(
        "Выбранные Вами роли успешно добавленны, в дольнейшем вы сможите добавить участиков ролей или сделайте это сейчас нажав на кнопку добавить участников проекта"
      )
    },
    [PROJECT_MODALS.EDIT_ROLE]: {
      title: __("Роли изменена"),
      text: __("Выбранная Вами роли успешно изменена")
    },
    DEL_ROLE: {
      title: __("Роль удалена"),
      text: __("Выбранная Вами роль удалена")
    },
    [PROJECT_MODALS.ADD_CATEGORY]: __("Категория успешно добавлена"),
    [PROJECT_MODALS.EDIT_CATEGORY]: __("Категория успешно изменена"),
    DELETE_CATEGORY: {
      title: __("Категория удалена"),
      text: __("Выбранная Вами Категория удалена")
    },
    [PROJECT_MODALS.ADD_TASK]: __("Задача успешно добавлена"),
    [PROJECT_MODALS.EDIT_TASK]: __("Задача изменена"),
    [PROJECT_MODALS.DEL_TASK]: __("Задача удалена"),
    ADD_FILE: {
      title: __("Файл добавлен"),
      text: __("Файл добавлен в файлы проекта")
    },
    MOVE_TO_BACKLOG: {
      title: __("Задача изменена"),
      text: __("Задача перемещена в backlog")
    },
    DEL_USER: __("Участник удален из проєкта"),
    ADD_LINK: __("Ссылка добавлена"),
    EDIT_LINK: __("Ссылка изменена"),
    DEL_LINK: __("Ссылка удалена"),
    DEL_FILE: __("Файл удален"),
    ADD_DOCS: __("Документ добавлен"),
    ADD_TEMPLATE: __("Шаблон добавлен"),
    DEL_DOC: __("Документ удален"),
    EDIT_DOC: __("Документ изменен")
  };
};

export const dateToMs = (d) => new Date(d).getTime();
/**
 * @deprecated use useParentTask from Services project
 */
export const getParentTask = (tasks, id) => tasks.find((el) => el.id === id);
/**
 * @deprecated use useChildrenTask from Services project
 */
export const getChildrenTask = (tasks, id) => tasks.filter((el) => el.id_parent === id);

export const getUser = (users, id) => users.find((user) => user.id_user === id);

export const getInitials = (users, id) => {
  return (
    <>
      {getUser(users, id).name.substring(0, 1).toUpperCase()}
      {getUser(users, id).sname && getUser(users, id).sname.substring(0, 1).toUpperCase()}
    </>
  );
};

export const getNames = (users) => {
  const names = users.map((u) => {
    const name = u.name;
    const sname = u?.sname ? u.sname : "";
    return '"' + name + sname + '"';
  });
  return names.length > 1 ? names.join(", ") : names.join(" ");
};

export const getDateColor = (task) => {
  const endDay = getTime(new Date(task.date_end));
  const startDay = getTime(startOfDay(new Date(task.date_end)));
  const now = getTime(new Date());
  if (now > endDay) {
    return { color: "#D13F1F" };
  }
  if (now < endDay && now > startDay) {
    return { color: "#FFA92B" };
  }
  return { color: "#3AA552" };
};

export function useContextProjectTask() {
  const { __ } = useLocales();
  return [
    {
      name: __("Открыть задачу"),
      img: <OpenIcon className="contextIcon" viewBox="0 0 20 20" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.OPEN_TASK
    },
    {
      name: __("Редактировать задачу"),
      img: <EditIcon className="contextIcon" viewBox="0 0 20 20" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.EDIT_TASK
    },
    {
      name: __("Скопировать ссылку"),
      img: <CopyIcon className="contextIcon" viewBox="0 0 20 20" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.COPY_LINK
    },
    {
      name: __("Срочная задача"),
      img: <FireIcon viewBox="0 -3 20 20" width={21} height={20} />,
      type: PROJECT_CONTEXT_MENU.ADD_URGENT
    },
    {
      name: __("Не срочная задача"),
      img: <FireIcon viewBox="0 -3 20 20" width={21} height={20} />,
      type: PROJECT_CONTEXT_MENU.DEL_URGENT
    },
    {
      name: __("Информация о задаче"),
      img: <InfoIcon width={15} height={15} viewBox="0 -1 20 20" />,
      type: PROJECT_CONTEXT_MENU.TASK_HISTORY
    },
    {
      name: __("Преместить в историю задач"),
      img: <SafeIcon width={20} height={20} viewBox="-2 -2 20 20" />,
      type: PROJECT_CONTEXT_MENU.MOVE_TO_ARCHIVE
    },
    {
      name: `${__("Преместить в ")}backlog`,
      img: <BacklogIcon width={20} height={20} viewBox="-2 -2 20 20" />,
      type: PROJECT_CONTEXT_MENU.MOVE_TO_BACKLOG
    },
    {
      name: __("Удалить задачу"),
      img: <TrashIcon className="contextIcon" viewBox="0 0 22 22" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.TASK_TO_TRASH
    }
  ];
}

export function useContextProjectUser() {
  const { __ } = useLocales();
  return [
    {
      name: __("Изменить роль"),
      img: <EditIcon viewBox="-3 -3 20 20" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.ADD_ROLE
    },
    {
      name: __("Информация о сотруднике"),
      img: <InfoIcon width={20} height={16} viewBox="-1 -1 20 20" />,
      type: PROJECT_CONTEXT_MENU.USER_INFO
    },
    {
      name: __("Добавить участника в проект"),
      img: <UserAddIcon viewBox="0 1 20 20" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.ADD_USER
    },
    {
      name: __("Назначить заместителем администратора"),
      img: <UserSettinghIcon viewBox="-2 0 20 20" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.APPOINT_SUBADMIN
    },

    {
      name: __("Назначить заместителя администратора"),
      img: <UserSettinghIcon viewBox="-2 0 20 20" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.APPOINT_OF_ADMIN
    },
    {
      name: __("Удалить из заместителя администратора"),
      img: <UserSettinghIcon viewBox="-2 0 20 20" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.DEL_SUBADMIN
    },
    {
      name: __("Создать задачу"),
      img: <PlusIcon viewBox="-6 -6 46 46" width={18} height={18} />,
      type: PROJECT_CONTEXT_MENU.ADD_TASK
    },
    {
      name: __("Удалить сотрудника из проекта"),
      img: <UserRemoveIcon />,
      type: PROJECT_CONTEXT_MENU.DEL_USER
    },
    {
      name: __("Покинуть проект"),
      img: <LogoutIcon viewBox="-2 -2 20 20" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.LEAVE_PROJECT
    }
  ];
}

export const useContextFolder = () => {
  const { __ } = useLocales();
  return [
    {
      name: __("Добавить папку"),
      img: <PlusIcon viewBox="-6 -6 46 46" width={18} height={18} />,
      type: PROJECT_CONTEXT_MENU.ADD_FOLDER
    },
    {
      name: __("Редактировать"),
      img: <EditIcon viewBox="-3 -3 20 20" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.EDIT_FOLDER
    },
    {
      name: __("Удалить "),
      img: <TrashIcon className="contextIcon" viewBox="0 0 22 22" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.DEL_FOLDER
    }
  ];
};

export const useContextFile = () => {
  const { __ } = useLocales();
  return [
    {
      name: __("Редактировать"),
      img: <EditIcon viewBox="-3 -3 20 20" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.EDIT_FILE
    },
    {
      name: __("Удалить "),
      img: <TrashIcon className="contextIcon" viewBox="0 0 22 22" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.DEL_FILE
    }
  ];
};

export const useProjectTasks = () => {
  const { project, selectRole } = useProjectsSelectors();
  const projectTask = project.tasks ?? [];

  const allTasks = useFiltredTask();
  const currentTasks = allTasks.filter((t) => t.is_archive === "0" && !t.is_epic && t.is_backlog < 0);
  const epicTasks = projectTask?.filter((t) => t.is_epic).sort((a, b) => dateToMs(a.ut) - dateToMs(b.ut));
  const filtredRoles = useFiltredRole();

  const tasksCategory = (id, tasks) => {
    return tasks
      ?.filter((t) => t.id_category === id)
      .sort((a, b) => +a.sort - +b.sort)
      .map((t, i) => ({ ...t, sort: (i + 1) * 10 }));
  };
  const tasksGroupByCategory = project.tasks_category.reduce((acc, c) => {
    acc[c.id] = tasksCategory(c.id, currentTasks);

    return acc;
  }, {});

  const tasksGroupByCategoryAll = project.tasks_category.reduce((acc, c) => {
    acc[c.id] = tasksCategory(
      c.id,
      allTasks?.filter((t) => !t.is_epic && t.is_backlog < 0)
    );
    return acc;
  }, {});

  const getGroupUser = (tasks, users) => {
    const group = users.reduce((acc, u) => {
      const fTasks = tasks.filter(
        (t) => typeCheck(t.id_executor) === TYPES.ARRAY && t.id_executor.some((i) => i === u.id_user)
      );
      if (fTasks.length > 0) acc[u.id_user] = fTasks;
      return acc;
    }, {});
    return Object.keys(group).length > 0 ? group : null;
  };

  const tasksGroupByRoles = (id) => {
    const tasks = currentTasks.filter((t) => t.id_category === id);
    const usersWithoutRoles = project.users.filter((u) => u.id_role === "0");
    const withoutUser = tasks.filter((t) => t.id_executor?.length === 0);

    const withoutRole = tasks.filter(
      (t) =>
        typeCheck(t.id_executor) === TYPES.ARRAY &&
        t.id_executor?.some((i) => usersWithoutRoles.some((u) => u.id_user === i))
    );

    return filtredRoles.reduce((acc, item) => {
      const users = project.users.filter((u) => u.id_role === item.id);
      acc[item.name] = getGroupUser(tasks, users);
      if (selectRole === "0") {
        if (withoutUser.length > 0) {
          acc["0"] = acc["0"] ? { ...acc["0"], 0: [...withoutUser] } : { 0: [...withoutUser] };
        }
        if (withoutRole.length > 0) {
          acc["0"] = acc["0"]
            ? { ...acc["0"], ...getGroupUser(withoutRole, usersWithoutRoles) }
            : { ...getGroupUser(withoutRole, usersWithoutRoles) };
        }
      }
      return acc;
    }, {});
  };

  const tasksGroupByCategoryRoles = project.tasks_category.reduce((acc, c) => {
    acc[c.id] = tasksGroupByRoles(c.id);
    return acc;
  }, {});

  return {
    allTasks,
    currentTasks,
    tasksGroupByCategory,
    tasksGroupByCategoryAll,
    epicTasks,
    tasksGroupByCategoryRoles
  };
};
export const minmaxDate = (tasks) => {
  let min = 0;
  let max = 0;
  let maxLong = 0;

  for (let i = 0; i < tasks.length; i++) {
    if (tasks[i].date_start) {
      if (min === 0) {
        min = dateToMs(tasks[i].date_start);
      }
      if (dateToMs(tasks[i].date_start) < min) {
        min = dateToMs(tasks[i].date_start);
      }
    }
    if (tasks[i].date_end) {
      if (dateToMs(tasks[i].date_end) > max) {
        max = dateToMs(tasks[i].date_end);
      }
    }
    if (tasks[i].date_long) {
      if (dateToMs(tasks[i].date_long) > maxLong) {
        maxLong = dateToMs(tasks[i].date_long);
      }
    }
  }
  return { min, max, maxLong: maxLong > max ? maxLong : max };
};
