import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export const useGlobalModalsSelectors = () => {
  const modals = useTypeSelector((s) => s.Cabinet.modals);
  const error = modals.error;
  const success = modals.success;
  const loader = modals.loader;
  const share = modals.share;
  const previewWithComments = modals.previewWithComments;
  const printScreen = modals.printScreen;
  const previewFile = modals.previewFile;
  const topMessage = modals.topMessage;
  const fileAccessRights = modals.fileAccessRights;
  const contextMenuModals = modals.contextMenuModals;
  const taskModals = modals.taskModals;
  const mailNewMessage = modals.mailNewMessage;
  const calendarModals = modals.calendarModals;
  const projectModals = modals.projectModals;
  const chatModals = modals.chatModals;
  const createFolder = modals.createFolder;
  const approveModal = modals.approveModal;
  const accessIsLimitedModal = modals.accessIsLimitedModal;
  const propertiesModal = modals.propertiesModal;
  const copyLinkModal = modals.copyLinkModal;
  const editItemsModal = modals.editItemsModal;
  const customizeFile = modals.customizeFile;
  const createZip = modals.createZip;
  const downloadLink = modals.downloadLink;
  const fileView = modals.fileView;
  const folderNavigation = modals.folderNavigation;
  const filePrint = modals.filePrint;
  const nextAction = modals.nextAction;
  const shareToMessengers = modals.shareToMessengers;
  const safeModals = modals.safeModals;
  const moveToSafe = modals.moveToSafe;
  const postboxModals = modals.postboxModals;
  const propertiesElementModals = modals.propertiesElementModals;
  const restoreFileModals = modals.restoreFileModals;
  const profileModals = modals.profileModals;
  const createObjectLibraryModals = modals.createObjectLibraryModals;
  const moveElements = modals.moveElements;
  const avatarCrop = modals.avatarCrop;
  const confirmCode = modals.confirmCode;
  const tariffs = modals.tariffs;
  const allFileViewModal = modals.allFileViewModal;
  const contacts = modals.contactsModal;
  const socialModal = modals.socialModal;
  const createRubricLibraryModal = modals.createRubricLibraryModal;
  const removeNonEmptyRubricLibraryModal = modals.removeNonEmptyRubricLibraryModal;
  const folderPassModal = modals.folderPassModal;
  const mailDashboardModal = modals.mailDashboardModal;
  const transferDuties = modals.transferDuties;
  const statusModal = modals.statusModal;
  const createProject = modals.createProject;
  const changeStatusModal = modals.changeStatusModal;
  const createProjectRole = modals.createProjectRole;
  const createProjectTeam = modals.createProjectTeam;
  const createProjectTask = modals.createProjectTask;
  const accountDeleting = modals.accountDeleting;
  const createSprintModal = modals.createSprintModal;
  const linksModal = modals.linksModal;
  const linksCategoryModal = modals.linksCategoryModal;
  const selectFile = modals.selectFile;
  const createFileModal = modals.createFileModal;
  const tasksQueue = modals.tasksQueue;
  const tasksConnection = modals.tasksConnection;
  const taskProjectInfo = modals.taskProjectInfo;
  const dashboardJointProjectStatistic = modals.dashboardJointProjectStatistic;
  const dashboardJointProjectTeam = modals.dashboardJointProjectTeam;
  const taskOverdue = modals.taskOverdue;
  const tagEdit = modals.tagEdit;
  const userDelete = modals.userDelete;
  const noteEdit = modals.noteEdit;
  const record = modals.record;
  const notesSidebar = modals.notesSidebar;
  const passwordModal = modals.passwordModal;
  const notesCategories = modals.notesCategories;
  const notesFullCategory = modals.notesFullCategory;

  return {
    error,
    success,
    loader,
    share,
    previewWithComments,
    printScreen,
    previewFile,
    topMessage,
    fileAccessRights,
    contextMenuModals,
    taskModals,
    calendarModals,
    projectModals,
    chatModals,
    createFolder,
    approveModal,
    accessIsLimitedModal,
    propertiesModal,
    copyLinkModal,
    editItemsModal,
    customizeFile,
    createZip,
    downloadLink,
    fileView,
    folderNavigation,
    filePrint,
    nextAction,
    shareToMessengers,
    mailNewMessage,
    safeModals,
    moveToSafe,
    postboxModals,
    propertiesElementModals,
    restoreFileModals,
    profileModals,
    createObjectLibraryModals,
    moveElements,
    avatarCrop,
    confirmCode,
    tariffs,
    allFileViewModal,
    contacts,
    socialModal,
    createRubricLibraryModal,
    removeNonEmptyRubricLibraryModal,
    folderPassModal,
    mailDashboardModal,
    transferDuties,
    statusModal,
    createProject,
    changeStatusModal,
    accountDeleting,
    createProjectRole,
    createProjectTeam,
    createProjectTask,
    createSprintModal,
    linksModal,
    linksCategoryModal,
    selectFile,
    createFileModal,
    tasksQueue,
    tasksConnection,
    taskProjectInfo,
    dashboardJointProjectStatistic,
    dashboardJointProjectTeam,
    taskOverdue,
    tagEdit,
    userDelete,
    noteEdit,
    record,
    notesSidebar,
    passwordModal,
    notesCategories,
    notesFullCategory
  };
};
