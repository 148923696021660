import api from "api";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { ContactsSelect } from "generalComponents/ContactsSelect/ContactsSelect";
import Loader from "generalComponents/Loaders/4HUB";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { IContactShort, useContactsList } from "hooks/useContactsList";
import { AccessTypes } from "models/contextMenuComponents/generalContextMenuComponents/share";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { ISharedUser } from "models/modals/sharedUser";
import { initialSafeModalState, ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { ISafe } from "models/store/safe/safe";
import React, { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import AlertModal from "../../AlertModal/AlertModal";
import HeaderModal from "../../HeaderModal/HeaderModal";
import SharedUser from "../../SharedUser/SharedUser";
import styles from "./SharedSafe.module.sass";

interface ISharedSafeProps {
  onClose: () => void;
  safe: ISafe;
}

const SharedSafe: React.FC<ISharedSafeProps> = ({ onClose, safe }) => {
  const { __ } = useLocales();
  const { onSetSafeModal, onSetTopMessageModal, onSetSafeList } = useActions();
  const { theme, uid } = useUserSelectors();
  const { employeesList } = useContactsList();

  const [loading, setLoading] = useState<boolean>(false);
  const [sharedUsers, setSharedUsers] = useState<ISharedUser[]>([]);

  const onAddRecipientContact = (contact: IContactShort): void => {
    setSharedUsers((prev) => [...prev, { ...contact, deadline: "", accessType: AccessTypes.VIEWING }]);
  };
  const onChangeUserAccess = (id: string, type: AccessTypes): void => {
    setSharedUsers((prev) => {
      return prev.map((u) => (u.id === id ? { ...u, accessType: type } : u));
    });
  };
  const onChangeUserTime = (id: string, time: string): void => {
    setSharedUsers((prev) => {
      return prev.map((u) => (u.id === id ? { ...u, deadline: time } : u));
    });
  };
  const onRemoveUser = (id: string): void => {
    setSharedUsers((prev) => prev.filter((u) => u.id !== id));
  };

  const getSafeShare = async (): Promise<void> => {
    try {
      setLoading(true);
      const params = {
        uid,
        id_safe: safe.id
      };
      const { data } = await api.get("/ajax/safe_access_info.php?", { params });
      setSharedUsers(
        data.access.map((i: any) => ({
          ...i,
          name: i.user_name,
          email: [i.email],
          accessType: i.is_read ? AccessTypes.VIEWING : AccessTypes.EDITING,
          id_real_user: i.id_user_to,
          id: i.id_user_to,
          deadline: i.deadline
        }))
      );
    } catch {
      //
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSafeShare();
  }, []); //eslint-disable-line

  const onClickSendButton = async (): Promise<void> => {
    try {
      const _users = sharedUsers.map((u) => ({
        id_user: u.id,
        deadline: u.deadline,
        is_write: u.accessType === AccessTypes.EDITING ? 1 : 0,
        is_download: u.accessType === AccessTypes.EDITING ? 1 : 0
      }));
      const params = {
        uid,
        users_to: JSON.stringify(_users),
        pass: safe.pass,
        id_safe: safe.id
      };
      const { data } = await api.get("/ajax/safe_share.php?", { params });
      checkResponseStatus(data.ok);
      onSetSafeList(data.safes);
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.SUCCESS,
        variantMessage: EVENT_TYPE.SHARED
      });
      onSetSafeModal(initialSafeModalState());
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  return (
    <div className={styles.wrap}>
      <HeaderModal title={__("Предоставленный доступ")} onClose={onClose} />
      <AlertModal
        text={__(
          "Для получения доступа к сейфу требуется пароль. Передайте пароль от сейфа пользователям любым безопасным способом"
        )}
        variant="red"
      />
      <div className={styles.body}>
        <ContactsSelect addContact={onAddRecipientContact} contacts={employeesList} />
        <div className={classNames(styles.users, `scrollbar-thin-${theme}`)}>
          {loading ? (
            <Loader
              type={LoaderTypes.BOUNCING_DOTS}
              position="absolute"
              background="white"
              zIndex={5}
              width="100px"
              height="100px"
              containerType="bounceDots"
            />
          ) : (
            <ul>
              {sharedUsers.map((user) => (
                <SharedUser
                  key={user.id}
                  user={user as ISharedUser}
                  onChangeUserAccess={onChangeUserAccess}
                  onChangeUserTime={onChangeUserTime}
                  onRemoveUser={onRemoveUser}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className={styles.btns}>
        <Button
          type="button"
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.LARGE}
          text={__("Отмена")}
          onClick={onClose}
        />
        <Button
          type="button"
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.LARGE}
          text={__("Применить")}
          onClick={onClickSendButton}
        />
      </div>
    </div>
  );
};

export default SharedSafe;
