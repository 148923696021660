import api from "api";
import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useValidCheckPass, useValidPass } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { FC, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import AlertModal from "../../AlertModal/AlertModal";
import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./PasswordChange.module.sass";

interface Props {
  closeModal: () => void;
  email: string;
}

const PasswordChange: FC<Props> = ({ closeModal, email }) => {
  const { __ } = useLocales();
  const { uid } = useUserSelectors();
  const { postboxes } = usePostboxSelectors();
  const { onSetTopMessageModal, onChangePasswordPostbox } = useActions();
  const { value: pass, onChange: onChangePass, error: passError, onError: onPassError } = useInputValue();
  const { value: newPass, onChange: onChangeNewPass, error: newPassError, onError: onNewPassError } = useInputValue();
  const { value: checkPass, onChange: onChangeCheckPass, error: checkError, onError: onCheckError } = useInputValue();
  const [error, setError] = useState<string>(passError || newPassError || checkError || "");

  const onValidPass = useValidPass();
  const onValidCheckPass = useValidCheckPass();

  useEffect(() => {
    if (passError) {
      setError(passError);
      return;
    }
    if (newPassError) {
      setError(newPassError);
      return;
    }
    if (checkError) {
      setError(checkError);
      return;
    }
    setError("");
  }, [newPassError, passError, checkError]);

  const onRestore = async (): Promise<void> => {
    try {
      const params = {
        uid,
        email,
        pass: newPass,
        pass_old: pass
      };
      const { data } = await api.get("/ajax/email_pass_set.php", { params });
      if (data.error) {
        return onPassError(__("Неверный пароль"));
      }
      checkResponseStatus(data.ok);
      if (postboxes.some((el) => el.email === email)) {
        onChangePasswordPostbox(newPass, email);
      }
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.SUCCESS,
        message: __("Пароль был изменен")
      });
      closeModal();
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR,
        message: __("Что-то пошло не так. Попробуйте позже")
      });
    }
  };
  return (
    <div className={styles.wrap}>
      <HeaderModal onClose={closeModal} title={__("Восстановление пароля ")} />
      {error && <AlertModal variant={"red"} text={error} />}
      <div className={styles.body}>
        <Input onChange={onChangePass} value={pass} placeholder="Введите" label="Пароль" require isPass />
        <Input
          onChange={onChangeNewPass}
          value={newPass}
          placeholder="Введите"
          label="Новый пароль"
          require
          isPass
          onBlur={() => onValidPass(newPass, onNewPassError)}
        />
        <Input
          onChange={onChangeCheckPass}
          value={checkPass}
          placeholder="Введите"
          label="Новый пароль еще раз"
          isPass
          require
          onBlur={() => onValidCheckPass(newPass, checkPass, onCheckError)}
        />
      </div>
      <div className={styles.btns}>
        <Button
          type="button"
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.SMALL}
          text={__("Отменить")}
          onClick={closeModal}
        />
        <Button
          type="submit"
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.SMALL}
          text={__("Сменить пароль")}
          onClick={onRestore}
          disabled={!pass || !checkPass}
        />
      </div>
    </div>
  );
};

export default PasswordChange;
