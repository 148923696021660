import classNames from "classnames";
import Avatar from "generalComponents/Avatar/Avatar";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { FC } from "react";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./ParticipantsButtons.module.sass";

interface IProps {
  selectedParticipants?: string[];
  onSelectParticipiant: (id: string) => void;
  teamId?: string;
  activeParticipiants?: string[] | string;
}

const ParticipantsButtons: FC<IProps> = ({
  selectedParticipants,
  onSelectParticipiant,
  teamId,
  activeParticipiants
}) => {
  const { project } = useJoinProjectsSelectors();

  const getTeamParticipants = () => {
    if (teamId) {
      const selectedTeam = project.teams.find((team) => team.id === teamId);
      if (selectedTeam) {
        return project.participants.filter((participant) => selectedTeam.users.includes(participant.id));
      }
    }
    return project.participants;
  };

  const teamParticipants = getTeamParticipants();

  const isSelected = (participantId: string) => {
    if (activeParticipiants?.includes(participantId)) return styles.activeWhite;
    else if (activeParticipiants?.includes(participantId) || selectedParticipants?.includes(participantId))
      return styles.active;
    else return undefined;
  };

  return (
    <div className={classNames(styles.wrapper, "scrollbar-thin-blue")}>
      <ul className={styles.list}>
        {teamParticipants.map((participant) => (
          <li
            key={participant.id}
            className={isSelected(participant.id)}
            onClick={() => onSelectParticipiant(participant.id)}
          >
            <Avatar
              name={`${participant.fname} ${participant.sname}`}
              size={28}
              imgSrc={participant.icon ? `${projectSrc + participant.icon}` : undefined}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ParticipantsButtons;
