import { ReactComponent as AddIcon } from "assets/PrivateCabinet/minitoolbar/add.svg";
import { ReactComponent as BrushIcon } from "assets/PrivateCabinet/minitoolbar/brush.svg";
import { ReactComponent as CameraIcon } from "assets/PrivateCabinet/minitoolbar/camera.svg";
import { ReactComponent as DashedBorderIcon } from "assets/PrivateCabinet/minitoolbar/dashedBorder.svg";
import { ReactComponent as EraserIcon } from "assets/PrivateCabinet/minitoolbar/eraser.svg";
import { ReactComponent as InfoIcon } from "assets/PrivateCabinet/minitoolbar/info.svg";
import { ReactComponent as LineIcon } from "assets/PrivateCabinet/minitoolbar/line.svg";
import { ReactComponent as MarkerIcon } from "assets/PrivateCabinet/minitoolbar/marker.svg";
import { ReactComponent as MessageIcon } from "assets/PrivateCabinet/minitoolbar/messages.svg";
import { ReactComponent as PencilIcon } from "assets/PrivateCabinet/minitoolbar/pencil.svg";
import { ReactComponent as PhotoIcon } from "assets/PrivateCabinet/minitoolbar/photo.svg";
import { ReactComponent as SquareIcon } from "assets/PrivateCabinet/minitoolbar/rectangle1.svg";
import { ReactComponent as Square1Icon } from "assets/PrivateCabinet/minitoolbar/rectangle2.svg";
import { ReactComponent as Square3Icon } from "assets/PrivateCabinet/minitoolbar/rectangle3.svg";
import { ReactComponent as SearchIcon } from "assets/PrivateCabinet/minitoolbar/search.svg";
import { ReactComponent as TextIcon } from "assets/PrivateCabinet/minitoolbar/text.svg";
//TODO - Need to switch to real users
import BlackMan from "assets/PrivateCabinet/minitoolbar/users/photo0.png";
import WhiteMan from "assets/PrivateCabinet/minitoolbar/users/photo1.png";
import Woman from "assets/PrivateCabinet/minitoolbar/users/photo2.png";
import { ReactComponent as VectorIcon } from "assets/PrivateCabinet/minitoolbar/vector-1.svg";
import classNames from "classnames";
import { drawCanvas } from "generalComponents/Modals/Components/PreviewFile/paintHelpers";
// import { dateURLToFile } from "generalComponents/Services/fileService";
import { replaceChatMessage, replaceFile } from "generalComponents/Services/requestServices";
import { MODALS } from "generalComponents/variables/global";
import { imageSrc } from "generalComponents/variables/globalVariables";
import { useWebSocketContext } from "generalComponents/WebSocketsProvider/WebSocketsProvider";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { onSetPaint } from "Store/actions/PaintActions";
import { onSendFile } from "Store/actions/ProjectActions";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { fileChatProps, fileProps, fileSharedProps } from "types/File";
import { v4 as uuidv4 } from "uuid";

import { useProjectMsg } from "../../Project/helpers";
import styles from "./MiniToolBar.module.sass";
import Brush from "./Tools/Brush";
import Circle from "./Tools/Circle";
import ColorPicker from "./Tools/ColorPicker";
import Eraser from "./Tools/Eraser";
import LineDraw from "./Tools/LineDraw/LineDraw";
import Magnifier from "./Tools/Magnifier";
import Marker from "./Tools/Marker";
import Pencil from "./Tools/Pencil";
import SizePicker from "./Tools/SizePicker";
import Square from "./Tools/Square";
import TextDraw from "./Tools/TextDraw";
import Triangle from "./Tools/Triangle";
// import { useSocketPaint } from "./useSocketPaint";

const MiniToolBar = ({
  file,
  toolBarType,
  width,
  canvasRef,
  share,
  canvasWrapRef,
  title,
  images,
  saveImageToPanel,
  isLoading,
  isPreview,
  isComment,
  toggleComment,
  buttonsStyle,
  from
}) => {
  const { __ } = useLocales();
  const [params, setParams] = useState({
    edit: false,
    history: { next: [], previous: [] },
    showAdditionalTools: false,
    drawTool: ""
  });
  const [isOpenSave, setIsOpenSave] = useState(false);
  const paint = useSelector((state) => state.Cabinet.paint);
  const previewWithComments = useSelector((state) => state.Cabinet.modals.previewWithComments);
  const project = useSelector((state) => state.Projects.project);
  const { uid, userInfo } = useUserSelectors();
  const { connectedUsers } = useSelector((state) => state.Cabinet.chat.callRoom);
  const printScreen = useSelector((s) => s.Cabinet.modals.printScreen);
  const shareStore = useSelector((s) => s.Cabinet.modals.share);
  const previewFile = useSelector((s) => s.Cabinet.modals.previewFile);
  const dispatch = useDispatch();
  const colorPickerRef = useRef();
  const PROJECT_MSG = useProjectMsg();
  const {
    socket
    //  lastMessage
  } = useWebSocketContext();

  const onFinishDraw = (image) =>
    setParams((s) => ({
      ...s,
      history: {
        ...s.history,
        previous: [...s.history.previous, image],
        next: []
      }
    }));
  const imageNavigation = (next, previous) => setParams((s) => ({ ...s, history: { previous, next } }));

  // useSocketPaint(canvasRef.current, lastMessage, onFinishDraw);

  const previousImage = () => {
    if (params.history.previous.length > 0) {
      let previous = [...params.history.previous];
      const image = previous.pop();
      const next = [...params.history.next, canvasRef.current.toDataURL()];
      drawCanvas(canvasRef.current, image, imageNavigation, next, previous);
    }
  };

  const nextImage = () => {
    if (params.history.next.length > 0) {
      let next = [...params.history.next];
      const image = next.pop();
      const previous = [...params.history.previous, canvasRef.current.toDataURL()];
      drawCanvas(canvasRef.current, image, imageNavigation, next, previous);
    }
  };

  const chooseAdditionalTool = (name) => {
    if (canvasRef?.current) {
      canvasRef.current.onmousemove = null;
      canvasRef.current.onmousedown = null;
      canvasRef.current.onmouseup = null;
    }
    dispatch(onSetPaint("tool", { name }));
  };

  const toggleContextMenu = () => setParams((s) => ({ ...s, showAdditionalTools: !s.showAdditionalTools }));

  const onAddMyFiles = () => {
    // const dataUrl = canvasRef?.current?.toDataURL("image/jpeg");

    // setAwaitingFiles([
    //   {
    //     file: dateURLToFile(dataUrl),
    //     options: {
    //       destination: "myFolders",
    //       dir: "global/all",
    //       filePath: "global/all"
    //     }
    //   }
    // ]);

    dispatch(
      onSetModals(MODALS.FILE_PREVIEW, {
        ...previewFile,
        open: false,
        file: null,
        from: ""
      })
    );
  };

  const renderAdditionalTools = () => (
    <>
      {params.showAdditionalTools ? (
        <div className={styles.additionalTools}>
          <div
            onClick={() => chooseAdditionalTool("text")}
            className={`${styles.line} ${"text" === paint.tool?.name && styles.chosen}`}
          >
            <TextIcon className={styles.iconTool} />
            Текст
          </div>
          <div
            onClick={() => chooseAdditionalTool("magnifier")}
            className={`${styles.line} ${"magnifier" === paint.tool?.name && styles.chosen}`}
          >
            <SearchIcon className={styles.iconTool} />
            Лупа
          </div>
          <div className={`${styles.line} ${styles.lineIcons}`}>
            <div
              onClick={() => addTool(Circle)}
              className={`${styles.toolWrap} ${"circle" === paint.tool?.name && styles.chosen}`}
            >
              <Square1Icon />
            </div>
            <div
              onClick={() => addTool(Triangle)}
              className={`${styles.toolWrap} ${"triangle" === paint.tool?.name && styles.chosen}`}
            >
              <SquareIcon />
            </div>
            <div
              onClick={() => addTool(Square)}
              className={`${styles.toolWrap} ${"square" === paint.tool?.name && styles.chosen}`}
            >
              <Square3Icon />{" "}
            </div>
            <div
              onClick={() => chooseAdditionalTool("arrow")}
              className={`${styles.toolWrap} ${"arrow" === paint.tool?.name && styles.chosen}`}
            >
              <VectorIcon />
            </div>
            <div
              onClick={() => chooseAdditionalTool("line")}
              className={`${styles.toolWrap} ${"line" === paint.tool?.name && styles.chosen}`}
            >
              <LineIcon />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );

  const addTool = (toolName) => {
    dispatch(
      onSetPaint(
        "tool",
        new toolName(canvasRef?.current, {
          color: paint.color,
          pushInDrawHistory: onFinishDraw,
          socket,
          uid,
          id_user: userInfo.id_user,
          connectedUsers
        })
      )
    );
  };

  const addButton = (icon, name = "", options = null, callback = null, itemClass) => (
    <div
      style={buttonsStyle}
      className={`${styles.buttonWrap} ${!params.edit && styles.buttonWrapInactive} ${
        name === paint.tool?.name && styles.chosen
      } ${styles[itemClass]}`}
      onClick={
        options && callback && params.edit
          ? () => {
              callback(options);
            }
          : null
      }
    >
      {icon}
    </div>
  );

  const chooseColor = () => {
    colorPickerRef.current.click();
  };

  const handleSaveImage = () => {
    if (params.edit) {
      canvasRef.current.onmousemove = null;
      canvasRef.current.onmousedown = null;
      canvasRef.current.onmouseup = null;
      dispatch(onSetPaint("tool", undefined));
      const preview = canvasRef.current.toDataURL("image/png");
      if (previewFile.message) {
        replaceChatMessage(previewFile.message, uid, file);
      } else {
        if (file?.fid && file?.fid !== "printScreen") replaceFile(uid, file, preview);
      }
    } else {
      addTool(Pencil);
    }
    setParams((state) => ({ ...state, edit: !state.edit }));
  };

  const standardEditToolBar = () => (
    <div
      className={classNames({
        [styles.standardToolBarWrap]: true,
        [styles.bigSize]: toolBarType === "toolsOnly"
      })}
    >
      <div className={styles.customWrap}>
        {addButton(
          <div
            className={`${styles.arrow} ${!params.edit || params.history.previous.length === 0 ? styles.inActive : ""}`}
          >
            &larr;
          </div>,
          "previous",
          true,
          previousImage
        )}
      </div>
      <div className={styles.customWrap}>
        {addButton(
          <div className={`${styles.arrow} ${!params.edit || params.history.next.length === 0 ? styles.inActive : ""}`}>
            &rarr;
          </div>,
          "next",
          true,
          nextImage
        )}
      </div>
      <div className={styles.customWrap}>
        {addButton(<PencilIcon className={`${!params.edit && styles.inActive}`} />, "pencil", Pencil, addTool)}
      </div>
      <div className={styles.customWrap}>
        {addButton(<MarkerIcon className={`${!params.edit && styles.inActive}`} />, "marker", Marker, addTool)}
      </div>
      <div className={styles.customWrap}>
        {addButton(<BrushIcon className={`${!params.edit && styles.inActive}`} />, "brush", Brush, addTool)}
      </div>
      <div className={styles.customWrap}>
        {addButton(<EraserIcon className={`${!params.edit && styles.inActive}`} />, "eraser", Eraser, addTool)}
      </div>
      <div className={styles.customWrap}>{addButton(<SizePicker isEdit={params.edit} />, "colorPicker")}</div>
      <div className={styles.customWrap}>
        {addButton(
          !params.edit ? (
            <div className={styles.inactiveColor} />
          ) : (
            <div style={{ position: "relative" }}>
              <img src={`${imageSrc}assets/PrivateCabinet/Oval.png`} alt="palette" onClick={chooseColor} />
              <ColorPicker colorPickerRef={colorPickerRef} />
            </div>
          ),
          "colorPicker"
        )}
      </div>
      <div className={styles.customWrap}>
        {addButton(
          <div className={styles.additionalToolsWrap}>
            <AddIcon className={`${!params.edit && styles.inActive}`} />
            {renderAdditionalTools()}
          </div>,
          "add",
          true,
          toggleContextMenu
        )}
      </div>
    </div>
  );

  const renderPhotos = (photos) => (
    <div className={styles.photoWrap}>
      {photos.map((photo, i) => (
        <div key={i} className={styles.photoUser} style={{ transform: `translateX(${12 * i}px)` }}>
          <img src={photo} alt="img" />
        </div>
      ))}
    </div>
  );

  const setPreviewFileOrder = () => (
    <div
      className={styles.previewFileToolbar}
      style={{
        width
      }}
    >
      <div className={styles.leftPart}>
        {file?.preview ? (
          <div className={styles.imgTitle}>
            {isPreview ? (
              <img
                src={`${file?.preview}${file?.fid === "printScreen" ? "" : `?${new Date()}`}`}
                style={{ maxWidth: 100, maxHeight: 45 }}
                alt="img"
              />
            ) : null}
            <span className={styles.name}>{file?.name}</span>
          </div>
        ) : null}
        {standardEditToolBar()}
        {isComment ? (
          <div className={styles.customWrap} onClick={toggleComment}>
            {addButton(<MessageIcon />)}
            <div className={styles.unread} />
          </div>
        ) : null}
      </div>
      <div className={styles.rightPart}>
        <div
          className={styles.customWrap}
          onClick={() => {
            if (params.edit) {
              dispatch(
                onSetPaint("mutualEdit", {
                  ...paint.mutualEdit,
                  open: true,
                  data: [canvasRef.current.toDataURL("image/png")],
                  destination: file?.gdir || "global/all"
                })
              );
              dispatch(
                onSetModals(MODALS.FILE_PREVIEW, {
                  ...previewFile,
                  open: false,
                  file: null
                })
              );
            }
          }}
        >
          {addButton(
            <div className={styles.compareWrap}>
              <PhotoIcon className={`${!params.edit && styles.inActive}`} />
              <PhotoIcon className={`${!params.edit && styles.inActive}`} />
            </div>
          )}
        </div>
        <div className={styles.manageButtons}>
          <span
            className={`${styles.button} ${styles.cancel}`}
            onClick={() => {
              dispatch(
                onSetModals(MODALS.FILE_PREVIEW, {
                  ...previewFile,
                  open: false,
                  file: null,
                  from: ""
                })
              );
              dispatch(
                onSetModals("previewWithComments", {
                  ...previewFile,
                  open: false,
                  files: [],
                  chosenFile: null,
                  from: ""
                })
              );
            }}
          >
            {__("Отменить")}
          </span>

          {from === "project" || from === "mail" ? (
            <div className={styles.btnsBox}>
              <span
                className={`${styles.button} ${styles.save}`}
                onClick={() => setParams((state) => ({ ...state, edit: !state.edit }))}
              >
                {__("Редактировать")}
              </span>
              <span
                className={`${styles.button} ${styles.save}`}
                onMouseEnter={() => setIsOpenSave(true)}
                onMouseLeave={() => setIsOpenSave(false)}
              >
                {__("Сохранить")}
                <ul className={classNames(styles.saveSelect, { [styles.hidden]: !isOpenSave })}>
                  <li onClick={onAddMyFiles}>{__('В "Моих файлах"')}</li>
                  <li>
                    <a href={canvasRef?.current?.toDataURL()} download={uuidv4().slice(0, 5)}>
                      {__("На устройстве")}
                    </a>
                  </li>
                  {from === "project" && (
                    <li onClick={() => dispatch(onSendFile(file, PROJECT_MSG.ADD_FILE))}>{__("В файлах проекта")}</li>
                  )}
                </ul>
              </span>
            </div>
          ) : (
            <span className={`${styles.button} ${styles.save}`} onClick={handleSaveImage}>
              {params.edit ? __("Сохранить") : __("Редактировать")}
            </span>
          )}
          {share !== null ? (
            <span
              className={`${styles.button} ${styles.send}`}
              onClick={() => {
                dispatch(
                  onSetModals("share", {
                    ...shareStore,
                    open: true,
                    fids: [file.fid],
                    action_type: "file_share"
                  })
                );
                dispatch(
                  onSetModals(MODALS.FILE_PREVIEW, {
                    ...previewFile,
                    open: false,
                    file: null
                  })
                );
              }}
            >
              {__("Отправить")}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );

  const setPreviewFileProject = () => (
    <div
      className={styles.previewFileToolbar}
      style={{
        width
      }}
    >
      <div className={styles.leftPart} />
      <div className={styles.rightPart}>
        <div
          className={styles.customWrap}
          onClick={() =>
            dispatch(
              onSetModals("previewWithComments", {
                ...previewWithComments,
                open: true,
                files: project.files,
                chosenFile: file
              })
            )
          }
        >
          {addButton(<MessageIcon />)}
          <div className={styles.unread} />
        </div>
        <div
          className={`${styles.customWrap} ${printScreen.open && styles.inActive}`}
          onClick={() =>
            printScreen.open ? null : dispatch(onSetModals(MODALS.PRINT_SCREEN, { ...printScreen, open: true }))
          }
        >
          {addButton(<CameraIcon />)}
        </div>
        <div className={styles.rightPart}>
          <div
            className={`${styles.customWrap}`}
            onClick={() =>
              dispatch(
                onSetPaint("mutualEdit", {
                  ...paint.mutualEdit,
                  open: true,
                  data: [file.preview],
                  destination: file?.gdir || "global/all"
                })
              )
            }
          >
            {addButton(
              <div className={styles.compareWrap}>
                <PhotoIcon />
                <PhotoIcon />
              </div>
            )}
          </div>
        </div>
        <div
          className={styles.customWrap}
          onClick={() => dispatch(onSetModals(MODALS.FILE_PREVIEW, { ...previewFile, open: true, file }))}
        >
          {addButton(<DashedBorderIcon />)}
        </div>
        <div className={styles.customWrap}>{addButton(<div className={styles.menuDots} />)}</div>
        <div className={styles.customWrap}>{addButton(<InfoIcon />)}</div>
        {renderPhotos([BlackMan, WhiteMan, Woman])}
      </div>
    </div>
  );

  const setPreviewMutualEdit = () => (
    <div
      className={`${styles.previewFileToolbar} ${styles.mutualEditWrap}`}
      style={{
        width
      }}
    >
      <div className={styles.leftPart}>
        <div className={styles.title}>{title}</div>
        {standardEditToolBar()}
      </div>
      <div className={styles.rightPart}>
        <div className={styles.customWrap}>{addButton(<CameraIcon />)}</div>
        <div className={`${styles.customWrap}`}>
          {addButton(
            <div className={`${styles.compareWrap}`}>
              <PhotoIcon className={`${!params.edit && styles.inActive}`} />
              <PhotoIcon className={`${!params.edit && styles.inActive}`} />
            </div>,
            "",
            null,
            null,
            "compareWrapChosen"
          )}
        </div>
        <div className={styles.customWrap}>{addButton(<div className={styles.menuDots} />)}</div>
        <div className={styles.customWrap}>{addButton(<InfoIcon />)}</div>
        {renderPhotos([BlackMan, WhiteMan, Woman])}
        <div className={styles.manageButtons}>
          <span
            className={`${styles.button} ${styles.cancel}`}
            onClick={() => {
              dispatch(onSetPaint("mutualEdit", { ...paint.mutualEdit, open: false }));
            }}
          >
            Закрыть
          </span>
          <span
            className={`${styles.button} ${images?.length > 0 && !isLoading ? styles.save : styles.disabled}`}
            onClick={() => (!isLoading ? saveImageToPanel(canvasRef.current.toDataURL()) : null)}
          >
            Сохранить
          </span>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    return () => {
      chooseAdditionalTool({});
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    setParams((s) => ({
      ...s,
      history: { ...s.history, previous: [], next: [] }
    }));
    if (images?.length > 0) {
      setParams((s) => ({ ...s, edit: true }));
    } else {
      setParams((s) => ({ ...s, edit: false }));
      chooseAdditionalTool("none");
    }
  }, [images]); //eslint-disable-line

  return (
    <>
      {toolBarType === "general" ? setPreviewFileOrder() : null}
      {toolBarType === "previewFile" ? setPreviewFileProject() : null}
      {toolBarType === "mutualEdit" ? setPreviewMutualEdit() : null}
      {toolBarType === "toolsOnly" ? standardEditToolBar() : null}

      {params.edit && paint.tool?.name === "text" ? (
        <TextDraw canvas={canvasRef?.current} onFinishDraw={onFinishDraw} addTool={addTool} />
      ) : null}
      {params.edit && (paint.tool?.name === "arrow" || paint.tool?.name === "line") ? (
        <LineDraw
          canvas={canvasRef?.current}
          onFinishDraw={onFinishDraw}
          addTool={addTool}
          canvasWrapRef={canvasWrapRef}
          isArrow={paint.tool?.name === "arrow"}
        />
      ) : null}
      {paint.tool?.name === "magnifier" ? <Magnifier canvas={canvasRef?.current} /> : null}
    </>
  );
};

export default MiniToolBar;

MiniToolBar.propTypes = {
  file: PropTypes.oneOfType([fileChatProps, fileSharedProps, fileProps]),
  toolBarType: PropTypes.string,
  width: PropTypes.string,
  canvasRef: PropTypes.exact({ current: PropTypes.instanceOf(Element) }),
  share: PropTypes.bool,
  canvasWrapRef: PropTypes.exact({ current: PropTypes.instanceOf(Element) }),
  title: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  saveImageToPanel: PropTypes.func,
  isLoading: PropTypes.bool,
  isPreview: PropTypes.bool,
  isComment: PropTypes.bool,
  toggleComment: PropTypes.func,
  buttonsStyle: PropTypes.exact({
    boxShadow: PropTypes.string
  }),
  from: PropTypes.string
};

MiniToolBar.defaultProps = {
  toolBarType: "general",
  width: "100%",
  canvasRef: null,
  share: null,
  title: "",
  isLoading: false,
  isPreview: true,
  isComment: false,
  toggleComment: () => {},
  buttonsStyle: {},
  from: ""
};
