import { getDay, getHours, setHours, setMinutes } from "date-fns";
import Button from "generalComponents/Button/Button";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import PopUp from "generalComponents/PopUp/PopUp";
import { useGetDuration } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IUpdateTaskProject } from "models/store/joinProjects/joinProjectsPayloads";
import { useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { dateISO } from "utils/dateToISO";

import styles from "./TaskOverdueModal.module.sass";

const TaskOverdueModal = () => {
  const { __ } = useLocales();
  const { id } = useParams();
  const { project } = useJoinProjectsSelectors();
  const getDuration = useGetDuration();
  const { onSetTaskOverdueModal, onUpdateTaskProject } = useActions();
  const {
    taskOverdue: { task, callbackCancel, callbackOk }
  } = useGlobalModalsSelectors();
  const [dateLong, setDateLong] = useState<Date>(null);
  const [isClose, setIsClose] = useState<boolean>(false);

  const closeModal = () => {
    if (!isClose) {
      onSetTaskOverdueModal({ open: false, task: null });
      callbackCancel();
    }
  };

  const onChangeDate = (d: Date) => {
    if (d) {
      const h = getHours(d);
      if (!project.work_hours.includes(h - 1)) {
        d.setHours(project.work_hours[0]);
      }
      setDateLong(d);
      return;
    }
    return setDateLong(null);
  };
  const handleSuccess = () => {
    callbackOk();
    onSetTaskOverdueModal({ open: false, task: null });
  };
  const onSubmit = () => {
    const _time = getDuration(new Date(task.date_end), dateLong);

    const formData = new FormData();
    formData.append("time_long", String(_time));
    formData.append("date_long", dateISO(dateLong));
    formData.append("id_project", id);
    formData.append("id_task", task.id);
    const payload: IUpdateTaskProject = {
      data: formData,
      cb: () => handleSuccess(),
      isLoader: false
    };
    onUpdateTaskProject(payload);
  };

  const isWeekend = (date: Date) => {
    const day = getDay(date);
    return project.work_days.includes(day);
  };

  const includeTimesEnd = useMemo(() => {
    return [
      ...project.work_hours.map((el) => setHours(setMinutes(new Date(), 0), el + 1)),
      ...project.work_hours.map((el) => setHours(setMinutes(new Date(), 30), el))
    ].slice();
  }, [project.work_hours]);

  return (
    <PopUp set={closeModal}>
      <div className={styles.wrap}>
        <div className={styles.selectDate}>
          <InputDate
            startDate={dateLong ? new Date(dateLong) : null}
            setStartDate={(v: Date) => onChangeDate(v)}
            withTime
            minDate={new Date(task.date_long || task.date_end)}
            setIsClose={setIsClose}
            filterDate={isWeekend}
            includeTimes={includeTimesEnd}
            timeIntervals={30}
          />
          <div className={styles.label}>{__("Новая конечная дата")}</div>
        </div>
        <div className={styles.btns}>
          <Button
            type="button"
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("Отмена")}
            onClick={closeModal}
          />
          <Button
            type="button"
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={__("Применить")}
            onClick={onSubmit}
            disabled={!dateLong}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default TaskOverdueModal;
