import { applyMiddleware, compose, legacy_createStore as createStore } from "redux";
import { persistStore } from "redux-persist";
import reduxThunk from "redux-thunk";

import { rootReducer } from "./reducers";

const composeEnhancers =
  process.env.NODE_ENV === "development" && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(reduxThunk)));

export const persistor = persistStore(store);
