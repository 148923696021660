import api from "api";
import Select from "generalComponents/Select/Select";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import TextButton from "generalComponents/TextButton";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { TASK_MODALS } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { IReadPasswordProps } from "models/store/Cabinet/modals/taskModals";
import React, { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./RecoverPass.module.sass";

const RecoverPass: React.FC<IReadPasswordProps> = ({ params }) => {
  const { __ } = useLocales();
  const { uid, userInfo } = useUserSelectors();
  const { onSetLoaderModal, onGetAllTasks, onSetTaskModal, onSetErrorModal } = useActions();
  const [selectData, setSelectData] = useState([]);
  const [sentCodeto, setSentCodeto] = useState<string>(NO_VALUE);

  useEffect(() => {
    if (userInfo.email) setSelectData((data) => [...data, { text: __("Email"), id: "email", activ: false }]);
    if (userInfo.tel) setSelectData((data) => [...data, { text: __("Телефон"), id: "tel", activ: false }]);
  }, [userInfo]); //eslint-disable-line

  useEffect(() => {
    setSentCodeto(selectData[0]?.id);
  }, [selectData]);

  const onSubmit = async (): Promise<void> => {
    try {
      onSetLoaderModal(true);

      const { data } = await api.get(
        `ajax/task_pass_restore.php?uid=${uid}&send_to=${sentCodeto}&id_task=${params.note.id}`
      );
      checkResponseStatus(data.ok);
      onGetAllTasks(__("Что-то пошло не так, попробуйте еще раз"));

      onSetTaskModal({
        type: TASK_MODALS.ENTER_CODE,
        params: { ...params, callbackType: params.callbackType }
      });
    } catch {
      onSetErrorModal({ open: true, message: __("Что-то пошло не так, попробуйте еще раз") });
    } finally {
      onSetLoaderModal(false);
    }
  };

  return (
    <div className={styles.passWrap}>
      <p className={styles.text}>
        {__("Введите Email / Телефон указанный при регистрации, Вам будет направлен код для восстановления пароля")}
      </p>

      <div className={styles.formItem}>
        <p className={styles.text}>{__("Выберите способ восстановления")}</p>
        <div className={styles.inputWrap}>
          <Select
            setSelectData={setSelectData}
            data={selectData}
            initValue={sentCodeto}
            onChange={(value: string) => setSentCodeto(value)}
            placeholder={selectData[0]?.text}
            cleareFilter={() => setSentCodeto(selectData[0]?.id)}
            chatTheme={NO_VALUE}
          />
        </div>
      </div>
      <TextButton text={__("Отправить")} type="ok" callback={onSubmit} />
    </div>
  );
};

export default RecoverPass;
