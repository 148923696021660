import UserIcon from "assets/PrivateCabinet/minitoolbar/users/photo0.png";
import classNames from "classnames";
import PopUp from "generalComponents/PopUp/PopUp";
import { CHAT_CALLROOM_ACTIONS } from "generalComponents/variables/chat";
import { MODALS } from "generalComponents/variables/global";
import { useWebSocketContext } from "generalComponents/WebSocketsProvider/WebSocketsProvider";
import React, { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { getContacts } from "Store/actions/userAction";

import styles from "./AddUser.module.sass";

function AddUser() {
  const { __ } = useLocales();
  const { socket } = useWebSocketContext();
  const dispatch = useDispatch();
  const contactList = useSelector((state) => state.Cabinet.contactList) ?? [];
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");
  const { uid, userInfo } = useSelector((s) => s.user);
  const { callRoom } = useSelector((s) => s.Cabinet.chat);

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  const switchSelectedUsers = (user) =>
    selectedUsers.find((it) => it.id === user.id)
      ? setSelectedUsers((s) => s.filter((it) => it.id !== user.id))
      : setSelectedUsers((s) => [...s, user]);

  const filterUser = (user) =>
    user.name.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
    user.sname.toLowerCase().trim().includes(search.toLowerCase().trim());

  const renderContactList = () =>
    contactList.filter(filterUser).map((user, i) => (
      <div key={i} className={styles.user} onClick={() => switchSelectedUsers(user)}>
        <div className={styles.userData}>
          <img src={user.icon?.[0] ?? UserIcon} alt="user" />
          <span>
            {user.name} {user.sname}
          </span>
        </div>
        <div
          className={classNames(styles.selected, {
            [styles.isSelected]: selectedUsers.find((it) => it.id === user.id)
          })}
        >
          &#10003;
        </div>
      </div>
    ));

  const renderSelectedUsers = () =>
    selectedUsers.map((user, i) => (
      <div key={i} className={styles.selectedUser}>
        <img src={UserIcon} alt="user" />
        <span>
          {user.name} {user.sname}
        </span>
      </div>
    ));

  const closePopUp = () => dispatch(onSetModals(MODALS.CHAT, { type: MODALS.NO_MODAL, params: null }));

  const inviteSelectedUsers = () => {
    socket.send(
      JSON.stringify({
        action: CHAT_CALLROOM_ACTIONS.INVITE_CALLROOM,
        uid,
        room_id: callRoom.room_id,
        from: {
          icon: userInfo.icon,
          id_user: userInfo.id_user,
          name: userInfo.name
        },
        id_group: callRoom.id_group,
        users_to: selectedUsers.map((it) => it.id_real_user)
      })
    );
    closePopUp();
  };

  return (
    <PopUp set={closePopUp}>
      <div className={styles.addUserChat}>
        <div className={classNames(styles.addUserTitle)}>{__("Добавить участника к звонку")}</div>
        <div className={styles.addUserSearch}>
          <label htmlFor="search">{__("Введите имя для поиска из списка")}</label>
          <input type="text" id="search" value={search} onChange={({ target }) => setSearch(target.value)} />
        </div>
        <div className={classNames(`scrollbar-thin-${userInfo?.theme}`, styles.selectedUsers)}>
          {selectedUsers.length > 0 ? renderSelectedUsers() : <span>{__("Список выбранных пользователей")}</span>}
        </div>
        <div className={classNames(styles.addUserTitle)}>{__("Выберите из списка")}</div>
        <div className={styles.userList}>{renderContactList()}</div>
        <div className={styles.info}>
          {__("Пригласить нововго пользователя в проект, для отправки приглошение нажмите на кнопку пригласить")}
        </div>
        <button onClick={closePopUp}>отменить</button>

        <button onClick={inviteSelectedUsers}>пригласить</button>
      </div>
    </PopUp>
  );
}

export default AddUser;
