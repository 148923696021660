import { ReactComponent as ArchiveIcon } from "assets/icons/archive_to.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as BellIcon } from "assets/PrivateCabinet/bell.svg";
import { ReactComponent as TaskIcon } from "assets/PrivateCabinet/calendar-4.svg";
import { ReactComponent as DoneIcon } from "assets/PrivateCabinet/check.svg";
import { ReactComponent as EditIcon } from "assets/PrivateCabinet/edit.svg";
import { ReactComponent as LinesIcon } from "assets/PrivateCabinet/lines.svg";
import { ReactComponent as MailIcon } from "assets/PrivateCabinet/mail/unread.svg";
import { ReactComponent as MeetingIcon } from "assets/PrivateCabinet/meeting.svg";
import { ReactComponent as OpenIcon } from "assets/PrivateCabinet/open.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-5.svg";
import { ReactComponent as NoDoneIcon } from "assets/PrivateCabinet/stop.svg";
import { ReactComponent as ProgressIcon } from "assets/PrivateCabinet/stop-2.svg";
import { ReactComponent as BarsIcon } from "assets/PrivateCabinet/tasks/bars.svg";
import { TabsType } from "generalComponents/Tabs/Tabs";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { TASK_PARAMS } from "generalComponents/variables/routing";
import { STATUS_TYPES, TASK_MODALS, TASK_TYPES, TASK_VIEW, URGENCY_TYPES } from "generalComponents/variables/tasks";
import { useDepCalendarName, useDepName } from "hooks/tasksHooks";
import { useActions } from "hooks/useActions";
import {
  ICreateTask,
  IMeetingType,
  ITaskFilterType,
  ITaskModalTitles,
  ITaskUrgensy,
  TContextTask
} from "models/generalComponents/collections/tasks";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { IMyTask, TASK_DEP, TaskFilters } from "models/store/tasks/tasks";
import React from "react";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";

export const useContextMenuTask = (): TContextTask => {
  const { __ } = useLocales();
  const { onSetTaskModal, onSetApproveModal, onDeleteTask, onEditTask } = useActions();

  return (task: IMyTask): IPopoverData[] => [
    {
      name: __("Открыть задачу"),
      img: <OpenIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onSetTaskModal({
          type: TASK_MODALS.OPEN_TASK,
          params: task
        });
      }
    },
    {
      name: __("Редактировать задачу"),
      img: <EditIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onSetTaskModal({
          type: TASK_MODALS.EDIT_TASK,
          params: task
        });
      }
    },
    {
      name: __("Переместить в архив"),
      img: <ArchiveIcon fill="#b1b1b1" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          is_archive: "1"
        };
        onEditTask(params, EVENT_TYPE.ARCHIVE);
      }
    },

    {
      name: __("Удалить задачу"),
      img: <TrashIcon fill="#b1b1b1" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          title: __("Удалить задачу"),
          text: __("Вы действительно хотите удалить выбранную задачу?"),
          callback: () => onDeleteTask(task.id),
          approveBtn: __("Удалить")
        };
        onSetApproveModal({ open: true, params });
      }
    }
  ];
};

export const useContextMenuMeeting = (): TContextTask => {
  const { __ } = useLocales();
  const { onSetTaskModal, onSetApproveModal, onDeleteTask, onEditTask } = useActions();

  return (task: IMyTask): IPopoverData[] => [
    {
      name: __("В ожидании"),
      img: <ProgressIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          id_status: STATUS_TYPES.IN_PROGRESS
        };
        onEditTask(params, EVENT_TYPE.EDIT_STATUS);
      }
    },
    {
      name: __("Состоялась"),
      img: <DoneIcon width={10} height={10} />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          id_status: STATUS_TYPES.DONE
        };
        onEditTask(params, EVENT_TYPE.EDIT_STATUS);
      }
    },
    {
      name: __("Не состоялась"),
      img: <NoDoneIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          id_status: STATUS_TYPES.NOT_DONE
        };
        onEditTask(params, EVENT_TYPE.EDIT_STATUS);
      }
    },
    {
      name: __("Открыть встречу"),
      img: <OpenIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onSetTaskModal({
          type: TASK_MODALS.OPEN_TASK,
          params: task
        });
      }
    },
    {
      name: __("Редактировать встречу"),
      img: <EditIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onSetTaskModal({
          type: TASK_MODALS.EDIT_MEETING,
          params: task
        });
      }
    },
    {
      name: __("Переместить в архив"),
      img: <ArchiveIcon fill="#b1b1b1" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          is_archive: "1"
        };
        onEditTask(params, EVENT_TYPE.ARCHIVE);
      }
    },

    {
      name: __("Удалить встречу"),
      img: <TrashIcon fill="#b1b1b1" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          title: __("Удалить встречу"),
          text: __("Вы действительно хотите удалить выбранную встречу?"),
          callback: () => onDeleteTask(task.id),
          approveBtn: __("Удалить")
        };
        onSetApproveModal({ open: true, params });
      }
    }
  ];
};

export const useContextMenuCall = (): TContextTask => {
  const { __ } = useLocales();
  const { onSetTaskModal, onSetApproveModal, onDeleteTask, onEditTask } = useActions();

  return (task: IMyTask): IPopoverData[] => [
    {
      name: __("В ожидании"),
      img: <ProgressIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          id_status: STATUS_TYPES.IN_PROGRESS
        };
        onEditTask(params, EVENT_TYPE.EDIT_STATUS);
      }
    },
    {
      name: __("Состоялся"),
      img: <DoneIcon width={10} height={10} />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          id_status: STATUS_TYPES.DONE
        };
        onEditTask(params, EVENT_TYPE.EDIT_STATUS);
      }
    },
    {
      name: __("Не состоялся"),
      img: <NoDoneIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          id_status: STATUS_TYPES.NOT_DONE
        };
        onEditTask(params, EVENT_TYPE.EDIT_STATUS);
      }
    },
    {
      name: __("Открыть звонок"),
      img: <OpenIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onSetTaskModal({
          type: TASK_MODALS.OPEN_TASK,
          params: task
        });
      }
    },
    {
      name: __("Редактировать звонок"),
      img: <EditIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onSetTaskModal({
          type: TASK_MODALS.EDIT_CALL,
          params: task
        });
      }
    },
    {
      name: __("Переместить в архив"),
      img: <ArchiveIcon fill="#b1b1b1" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          is_archive: "1"
        };
        onEditTask(params, EVENT_TYPE.ARCHIVE);
      }
    },

    {
      name: __("Удалить звонок"),
      img: <TrashIcon fill="#b1b1b1" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          title: __("Удалить звонок"),
          text: __("Вы действительно хотите удалить выбранный звонок?"),
          callback: () => onDeleteTask(task.id),
          approveBtn: __("Удалить")
        };
        onSetApproveModal({ open: true, params });
      }
    }
  ];
};

export const useContextMenuMail = (): TContextTask => {
  const { __ } = useLocales();
  const { onSetTaskModal, onSetApproveModal, onDeleteTask, onEditTask } = useActions();

  return (task: IMyTask): IPopoverData[] => [
    {
      name: __("В ожидании"),
      img: <ProgressIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          id_status: STATUS_TYPES.IN_PROGRESS
        };
        onEditTask(params, EVENT_TYPE.EDIT);
      }
    },
    {
      name: __("Отправлено"),
      img: <DoneIcon width={10} height={10} />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          id_status: STATUS_TYPES.DONE
        };
        onEditTask(params, EVENT_TYPE.EDIT_STATUS);
      }
    },
    {
      name: __("Не отправлено"),
      img: <NoDoneIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          id_status: STATUS_TYPES.NOT_DONE
        };
        onEditTask(params, EVENT_TYPE.EDIT_STATUS);
      }
    },
    {
      name: __("Открыть письмо"),
      img: <OpenIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onSetTaskModal({
          type: TASK_MODALS.OPEN_TASK,
          params: task
        });
      }
    },
    {
      name: __("Редактировать письмо"),
      img: <EditIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onSetTaskModal({
          type: TASK_MODALS.EDIT_CALL,
          params: task
        });
      }
    },
    {
      name: __("Переместить в архив"),
      img: <ArchiveIcon fill="#b1b1b1" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          is_archive: "1"
        };
        onEditTask(params, EVENT_TYPE.ARCHIVE);
      }
    },

    {
      name: __("Удалить "),
      img: <TrashIcon fill="#b1b1b1" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          title: __("Удалить письмо"),
          text: __("Вы действительно хотите удалить выбранное письмо?"),
          callback: () => onDeleteTask(task.id),
          approveBtn: __("Удалить")
        };
        onSetApproveModal({ open: true, params });
      }
    }
  ];
};

export const useContextMenuReminder = (): TContextTask => {
  const { __ } = useLocales();
  const { onSetTaskModal, onSetApproveModal, onDeleteTask, onEditTask } = useActions();
  return (task: IMyTask): IPopoverData[] => [
    {
      name: __("Открыть напоминание"),
      img: <OpenIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onSetTaskModal({
          type: TASK_MODALS.OPEN_TASK,
          params: task
        });
      }
    },
    {
      name: __("Редактировать напоминание"),
      img: <EditIcon />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onSetTaskModal({
          type: TASK_MODALS.EDIT_TASK,
          params: task
        });
      }
    },
    {
      name: __("Переместить в архив"),
      img: <ArchiveIcon fill="#b1b1b1" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          id_task: task.id,
          is_archive: "1"
        };
        onEditTask(params, EVENT_TYPE.ARCHIVE);
      }
    },

    {
      name: __("Удалить напоминание"),
      img: <TrashIcon fill="#b1b1b1" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const params = {
          title: __("Удалить напоминание"),
          text: __("Вы действительно хотите удалить выбранное напоминание?"),
          callback: () => onDeleteTask(task.id),
          approveBtn: __("Удалить")
        };
        onSetApproveModal({ open: true, params });
      }
    }
  ];
};

export const useContextMenuNote = (): TContextTask => {
  const { __ } = useLocales();
  const { onSetTaskModal, onSetApproveModal, onDeleteTask } = useActions();

  return (note: IMyTask): IPopoverData[] => [
    {
      name: __("Редактировать заметку"),
      img: <EditIcon fill="#6E8680" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (note.pass) {
          return onSetTaskModal({
            type: TASK_MODALS.READ_PASSWORD,
            params: { note: note, callbackType: TASK_MODALS.EDIT_NOTE }
          });
        }
        onSetTaskModal({
          type: TASK_MODALS.EDIT_NOTE,
          params: { ...note }
        });
      }
    },
    {
      name: __("Удалить заметку "),
      img: <TrashIcon fill="#b1b1b1" />,
      callback: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (note.pass && !note?.showNote) {
          return onSetTaskModal({
            type: TASK_MODALS.READ_PASSWORD,
            params: { note: note, callbackType: TASK_MODALS.DEL_NOTE }
          });
        }
        const params = {
          title: __("Удалить заметку"),
          text: __("Вы действительно хотите удалить выбранную заметку?"),
          callback: () => onDeleteTask(note.id),
          approveBtn: __("Удалить")
        };
        onSetApproveModal({ open: true, params });
      }
    }
  ];
};

export const useTasksFiltersType = (): ITaskFilterType[] => {
  const { __ } = useLocales();
  return [
    { type: TaskFilters.BY_YEAR, name: __("Год") },
    { type: TaskFilters.BY_MONTH, name: __("Месяц") },
    { type: TaskFilters.BY_WEEK, name: __("Неделя") },
    { type: TaskFilters.BY_DAY, name: __("День") }
  ];
};

export const ELEMENTS = [
  { img: BarsIcon, id: TASK_VIEW.KANBAN },
  { img: LinesIcon, id: TASK_VIEW.LINE }
];

export const useTaskStatuses = () => {
  const { __ } = useLocales();
  const queue = {
    status: STATUS_TYPES.QUEUE,
    nameTask: __("Задачи в очереди"),
    shortNameTask: __("В очереди"),
    nameMeeting: "",
    shortNameMeeting: "",
    nameCall: "",
    shortNameCall: "",
    nameMail: "",
    shortNameMail: "",
    img: <NoDoneIcon fill="#B6C2BF" />,
    color: "lightGrey"
  };
  const notDone = {
    status: STATUS_TYPES.NOT_DONE,
    nameTask: "",
    shortNameTask: "",
    nameMeeting: __("Не состоявшиеся встречи"),
    shortNameMeeting: __("Не состоялась"),
    nameCall: __("Не состоявшиеся"),
    shortNameCall: __("Не состоялся"),
    nameMail: __("Не отправленные"),
    shortNameMail: __("Не отправлено"),
    img: <NoDoneIcon />,
    color: "red"
  };
  const done = {
    status: STATUS_TYPES.DONE,
    nameTask: __("Готовые задачи"),
    shortNameTask: __("Готово"),
    nameMeeting: __("Состоявшиеся встречи"),
    shortNameMeeting: __("Состоялась"),
    nameCall: __("Состоявшиеся"),
    shortNameCall: __("Состоялся"),
    nameMail: __("Отпрвленные"),
    shortNameMail: __("Отправлено"),
    img: <DoneIcon width="10px" height="10px" />,
    color: "lightGreen"
  };

  const inProgress = {
    status: STATUS_TYPES.IN_PROGRESS,
    nameTask: __("Задачи в работе"),
    shortNameTask: __("В работе"),
    nameMeeting: __("В ожидании"),
    shortNameMeeting: __("В ожидании"),
    nameCall: __("В ожидании"),
    shortNameCall: __("В ожидании"),
    nameMail: __("В ожидании"),
    shortNameMail: __("В ожидании"),
    img: <ProgressIcon />,
    color: "orange"
  };
  const myTasks = [queue, inProgress, done];
  const otherTasks = [notDone, inProgress, done];
  return { myTasks, otherTasks };
};

export const useUrgencyTask = (): ITaskUrgensy[] => {
  const { __ } = useLocales();
  return [
    { name: __("Срочные задачи"), id: URGENCY_TYPES.URGENT },
    { name: __("Плановые задачи"), id: URGENCY_TYPES.PLANNED }
  ];
};

export const useTypesMeeting = (): IMeetingType[] => {
  const { __ } = useLocales();
  return [
    { name: __("Онлайн"), id: TASK_TYPES.ONLINE_MEETING },
    { name: __("Офлайн"), id: TASK_TYPES.OFFLINE_MEETING }
  ];
};

export const useTaskModalTitles = (): ITaskModalTitles => {
  const { __ } = useLocales();
  return {
    [TASK_MODALS.ADD_NOTE]: __("Создать заметку"),
    [TASK_MODALS.EDIT_NOTE]: __("Редактировать"),

    [TASK_MODALS.ADD_TASK]: __("Добавление задачи"),
    [TASK_MODALS.EDIT_TASK]: __("Редактировать"),

    [TASK_MODALS.ADD_MEETING]: __("Создать встречу"),
    [TASK_MODALS.EDIT_MEETING]: __("Редактировать"),

    [TASK_MODALS.ADD_CALL]: __("Создать звонок"),
    [TASK_MODALS.EDIT_CALL]: __("Редактировать"),

    [TASK_MODALS.ADD_LETTER]: __("Создать письмо"),
    [TASK_MODALS.EDIT_LETTER]: __("Редактировать"),

    [TASK_MODALS.ADD_REMINDER]: __("Создать напоминание"),
    [TASK_MODALS.EDIT_REMINDER]: __("Редактировать"),

    [TASK_MODALS.ADD_COMMENT_TASK]: __("Комментарий к задаче"),
    [TASK_MODALS.ADD_PASSWORD]: __("Добавить пароль к заметке"),
    [TASK_MODALS.READ_PASSWORD]: __("Введите пароль к заметке"),
    [TASK_MODALS.RECOVER_PASSWORD]: __("Восстановление пароля"),
    [TASK_MODALS.ENTER_CODE]: __("Введите код"),
    [TASK_MODALS.NEW_PASSWORD]: __("Введите новый пароль"),
    [TASK_MODALS.OPEN_TASK]: NO_VALUE,
    [TASK_MODALS.NO_MODAL]: NO_VALUE,
    [TASK_MODALS.DEL_NOTE]: NO_VALUE
  };
};

export const useTaskDepartment = (): TabsType[] => {
  const [searchParams, setSerchParams] = useSearchParams();
  const departmentName = useDepName();
  return [
    {
      name: departmentName(TASK_DEP.NO_DEP),
      onClick: () => {
        searchParams.delete(`${TASK_PARAMS.DEP}`);
        setSerchParams(searchParams);
      },
      value: TASK_DEP.NO_DEP
    },
    {
      name: departmentName(TASK_DEP.WORK),
      onClick: () => {
        searchParams.set(`${[TASK_PARAMS.DEP]}`, TASK_DEP.WORK);
        setSerchParams(searchParams);
      },
      value: TASK_DEP.WORK
    },
    {
      name: departmentName(TASK_DEP.PERSONAL),
      onClick: () => {
        searchParams.set(`${[TASK_PARAMS.DEP]}`, TASK_DEP.PERSONAL);
        setSerchParams(searchParams);
      },
      value: TASK_DEP.PERSONAL
    }
  ];
};
export const useCalendarDepartment = (): TabsType[] => {
  const [_, setSerchParams] = useSearchParams();
  const departmentName = useDepCalendarName();
  return [
    {
      name: departmentName(TASK_DEP.NO_DEP),
      onClick: () => setSerchParams(),
      value: TASK_DEP.NO_DEP
    },
    {
      name: departmentName(TASK_DEP.WORK),
      onClick: () => setSerchParams({ [TASK_PARAMS.DEP]: TASK_DEP.WORK }),
      value: TASK_DEP.WORK
    },
    {
      name: departmentName(TASK_DEP.PERSONAL),
      onClick: () => setSerchParams({ [TASK_PARAMS.DEP]: TASK_DEP.PERSONAL }),
      value: TASK_DEP.PERSONAL
    }
  ];
};

export const useCreateTask = (): ICreateTask[] => {
  const { __ } = useLocales();
  return [
    { icon: <TaskIcon />, typeTask: TASK_TYPES.TASK, typeModal: TASK_MODALS.ADD_TASK, text: __("Задачу") },
    {
      icon: <MeetingIcon />,
      typeTask: TASK_TYPES.OFFLINE_MEETING,
      typeModal: TASK_MODALS.ADD_MEETING,
      text: __("Встречу")
    },
    { icon: <PhoneIcon />, typeTask: TASK_TYPES.CALLS, typeModal: TASK_MODALS.ADD_CALL, text: __("Звонок") },
    { icon: <MailIcon />, typeTask: TASK_TYPES.MAILS, typeModal: TASK_MODALS.ADD_LETTER, text: __("Письмо") },
    {
      icon: <BellIcon fill="#26c0ca" width="12px" height="15px" />,
      typeTask: TASK_TYPES.REMINDER,
      typeModal: TASK_MODALS.ADD_REMINDER,
      text: __("Напоминание")
    }
  ];
};
