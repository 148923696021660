import ElementsList from "generalComponents/ElementsList/ElementsList";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { useSetOpenFolders } from "generalComponents/Hooks/Folders/useSetOpenFolders";
import { useSetFolderData } from "generalComponents/Hooks/useSetSelectFolder";
import { useAllFolders, useIsFavorite } from "generalComponents/Services/folderServices/folderServices";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams, useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { initialFolderState } from "models/store/folders/foldersStore";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const FolderWorkSpace = (): JSX.Element => {
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const { dirId } = useParams();
  const { isFavorite } = useIsFavorite();
  const { onGetMyFiles, onSetFolderPassModal, onSendFolderPath } = useActions();
  const {
    objSearchParams: { group, sort, sort_reverse }
  } = useSelectedObjSearchParams();

  const { color, emo, tags } = useAllSelectedSearchParams();

  const { isLinesPreviewView } = useGetItemView();
  const folders = useAllFolders();
  const { onSetOpenFolders } = useSetOpenFolders();
  const { setFolderData, setSelectFolder } = useSetFolderData();

  useEffect(() => {
    if (isFavorite) {
      onSendFolderPath([]);
      onGetMyFiles({
        isReset: true
      });
      return;
    }
  }, [isFavorite, sort, group, sort_reverse, color.selectedValue, emo.selectedValue, tags.selectedValue?.length]); //eslint-disable-line

  useEffect(() => {
    const controller = new AbortController();
    if (!dirId) return;
    onSendFolderPath([]);
    setSelectFolder(initialFolderState());
    const folder = folders.find((el) => el.id_dir === dirId);
    if (!folder) return;
    if (folder.pass !== NO_VALUE) {
      onSetFolderPassModal({
        open: true,
        type: "enterPassword",
        folder,
        callbackOk: (decryptedPass) => {
          setFolderData(folder);
          onSetOpenFolders(folder.id_parent);
          onGetMyFiles({
            payload: { pass: decryptedPass },
            isReset: true,
            controller
          });
        },
        callbackCancel: () => {
          const path = state?.from || `/${ROUTES.FOLDERS}`;
          navigate(`${path}${search}`);
        }
      });
      return;
    }
    setFolderData(folder);
    onSetOpenFolders(folder.id_parent);
    if (isLinesPreviewView) return;
    onGetMyFiles({
      isReset: true,
      controller
    });
    return () => controller.abort();
  }, [dirId, color.selectedValue, emo.selectedValue, tags.selectedValue?.length, sort, group, sort_reverse]); //eslint-disable-line

  return <ElementsList />;
};

export default FolderWorkSpace;
