import { IProjectTeam } from "models/store/joinProjects/joinProgects";

export interface ICreateProjectTeamModal {
  open: boolean;
  type: "add" | "edit";
  team: IProjectTeam;
  isAddParticipant?: boolean;
}

export function initialCreateProjectTeamState(): ICreateProjectTeamModal {
  return { open: false, type: "add", team: null, isAddParticipant: false };
}
