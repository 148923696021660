import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import classNames from "classnames";
import { FC } from "react";

import styles from "./CircleProgress.module.sass";

interface ICircleProgressProps {
  done: boolean;
  percent: number;
  className?: string;
}

export const CircleProgress: FC<ICircleProgressProps> = ({ done, percent, className }) =>
  done ? (
    <CheckIcon width={22} className={classNames(styles.checkIcon, className)} />
  ) : (
    <div
      className={classNames(styles.progress, className)}
      style={{
        background: `conic-gradient(#4086F1 ${percent}%,#D2D2D2 ${percent}%)`
      }}
    />
  );
