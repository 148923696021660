import { type DragEvent, type FC } from "react";

import styles from "./DropArea.module.sass";

type TDragEvent = (e: DragEvent<HTMLDivElement>) => void;

interface IDropAreaProps {
  onDragStart: TDragEvent;
  onDragLeave: TDragEvent;
  onDragOver: TDragEvent;
  onDrop: TDragEvent;
}

export const DropArea: FC<IDropAreaProps> = ({ onDragStart, onDragLeave, onDragOver, onDrop }): JSX.Element => (
  <div
    className={styles.drop}
    onDragStart={onDragStart}
    onDragLeave={onDragLeave}
    onDragOver={onDragOver}
    onDrop={onDrop}
  />
);
