import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import Button from "generalComponents/Button/Button";
import Role from "Pages/Cabinet/Components/Project/Components/Role/Role";
import UserBlock from "Pages/Cabinet/Components/Project/Components/UserBlock/UserBlock";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useLocales } from "react-localized";
import { useSelector } from "react-redux";
import { projectUserProps } from "types/Project";

import styles from "./AddedUsers.module.sass";

const AddedUsers = ({ onClose, params }) => {
  const { __ } = useLocales();
  const project = useSelector((s) => s.Projects.project);
  const getRoles = (id) => project.roles.find((el) => el.id === id);

  return (
    <div className={styles.wrap}>
      <CheckIcon className={styles.checkIcon} />
      <p className={styles.text}>{__("В будущем вы можете редактировать роли, добовлять и удалять участников")}</p>
      <div className={styles.list}>
        {params.map((item, i) => (
          <Fragment key={i}>
            <UserBlock user={item} />
            {item.id_role !== "0" ? <Role role={getRoles(item.id_role)} /> : <div />}
          </Fragment>
        ))}
      </div>
      <div className={styles.btns}>
        <Button type="button" variant="ok" text={__("Готово")} onClick={onClose} />
      </div>
    </div>
  );
};

export default AddedUsers;
AddedUsers.propTypes = {
  onClose: PropTypes.func,
  params: PropTypes.arrayOf(projectUserProps)
};
