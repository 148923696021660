// /* eslint-disable react-hooks/exhaustive-deps */
import "./TinyMCE.sass";

import { Editor } from "@tinymce/tinymce-react";
import { ReactComponent as CloseIcon } from "assets/icons/cross.svg";
import AddedAttachment from "containers/Postbox/AddedAttachment/AddedAttachment";
import EmojiPicker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";
import Button from "generalComponents/Button/Button";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useLocales } from "react-localized";
import { Editor as TinyMCEEditor } from "tinymce";
import { dateISO } from "utils/dateToISO";
import { v4 as uuid } from "uuid";

import styles from "./TinyMCE.module.sass";

export interface TinyFile {
  filename: string;
  mime: string;
  variant: "forward" | "upload";
}

interface Props {
  refEditor: MutableRefObject<TinyMCEEditor>;
  refSubmit: MutableRefObject<HTMLButtonElement>;
  refFiles: MutableRefObject<HTMLInputElement>;
  setDateValue: Dispatch<SetStateAction<string>>;
  dateValue?: string;
  files?: TinyFile[];
  onRemoveFiles?: (file: TinyFile) => void;
  submitText?: string;
  isScheduleButton?: boolean;
  initialContent?: string;
  btnText?: string;
}

const TinyMCE: FC<Props> = ({
  refEditor,
  setDateValue,
  dateValue,
  refSubmit,
  refFiles,
  files = [],
  onRemoveFiles,
  isScheduleButton = true,
  initialContent,
  btnText
}) => {
  const { __ } = useLocales();
  const [isEmoji, setIsEmoji] = useState<boolean>(false);
  const [isClose, _] = useState<boolean>(false);
  const [scheduleTime, setSceduleTime] = useState<string>(dateValue);

  const tinyRef = useRef<Editor>(null);
  const emojiRef = useRef<HTMLDivElement>(null);
  const scheduleRef = useRef<HTMLDivElement>(null);
  const [isSchedule, setIsSchedule] = useState<boolean>(false);
  useOutsideClick(scheduleRef, () => {
    if (isClose) return;
    setIsSchedule(false);
  });
  useOutsideClick(emojiRef, () => {
    setIsEmoji(false);
  });

  const setIsEmojiHandler = (e: MouseEvent): void => {
    e.stopPropagation();
    setIsEmoji(true);
  };

  const onClickClockButton = (e: MouseEvent): void => {
    e.stopPropagation();
    setIsSchedule(true);
  };

  const clickOnEmojiHandler = (emoji: EmojiClickData) => {
    refEditor.current.insertContent(emoji.emoji);
  };

  const onScheduleTime = () => {
    setDateValue(scheduleTime);
    setIsSchedule(false);
  };

  const onChangeDate = (v: Date): void => {
    setSceduleTime(dateISO(v));
  };

  useEffect(() => {
    if (refEditor.current) {
      const editorHeader = window.document.querySelector(".tox-editor-header");
      const atachmentsWrapper = window.document.createElement("div");
      const IsAttachemtWrapper = window.document.querySelector(".atachments-tinymce__list");
      atachmentsWrapper.classList.add("atachments-tinymce__list");

      if (IsAttachemtWrapper) {
        IsAttachemtWrapper.remove();
        editorHeader.parentNode.insertBefore(atachmentsWrapper, editorHeader);
      } else {
        editorHeader?.parentNode.insertBefore(atachmentsWrapper, editorHeader);
      }
      const root = createRoot(atachmentsWrapper);

      root.render(
        files.map((file) => (
          <li key={uuid()}>
            <AddedAttachment remove={() => onRemoveFiles(file)} name={file.filename} type={file.mime} />
          </li>
        ))
      );
    }
  }, [files, refEditor]); // eslint-disable-line

  useEffect(() => {
    if (refEditor.current) {
      const scheduleBtn = window.document.querySelector("#schedule");
      const sendBtn = window.document.querySelector("#send");
      if (!sendBtn || btnText) return;
      if (dateValue && sendBtn) {
        scheduleBtn.classList.add(styles.isChedule);
        sendBtn.textContent = __("Запланировать");
      } else {
        scheduleBtn.classList?.remove(styles.isChedule);
        sendBtn.textContent = __("Отправить");
      }
    }
  }, [dateValue]); // eslint-disable-line

  return (
    <div style={{ position: "relative" }}>
      <Editor
        apiKey="8hbvavpgna2fjjp1cbeg151o2ifncw8luk0d3svbor4ho4s2"
        ref={tinyRef}
        initialValue={initialContent}
        onInit={(_, editor) => {
          refEditor.current = editor;
          if (files.length > 0) {
            const editorHeader = window.document.querySelector(".tox-editor-header");
            const atachmentsList = window.document.createElement("ul");
            editorHeader.parentNode.insertBefore(atachmentsList, editorHeader);
            atachmentsList.classList.add("atachments-tinymce__list");

            const root = createRoot(atachmentsList);
            root.render(
              <>
                {files.map((file) => (
                  <li key={uuid()}>
                    <AddedAttachment remove={() => onRemoveFiles(file)} name={file.filename} type={file.mime} />
                  </li>
                ))}
              </>
            );
          }

          const buttons = document.querySelectorAll(".tox-tbtn");
          buttons.forEach((button) => {
            if (button.textContent === __("Отправить") || button.textContent === __("Сохранить")) {
              button.classList.add(styles.customSend);
              button.id = "send";
              button.setAttribute("type", "submit");
            }
            if (button.textContent === "emoji") {
              button.classList.add(styles.customEmoji);
              button.innerHTML =
                '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.99102 0.666748C4.39102 0.666748 0.666016 4.40008 0.666016 9.00008C0.666016 13.6001 4.39102 17.3334 8.99102 17.3334C13.5993 17.3334 17.3327 13.6001 17.3327 9.00008C17.3327 4.40008 13.5993 0.666748 8.99102 0.666748ZM8.99935 15.6667C5.31602 15.6667 2.33268 12.6834 2.33268 9.00008C2.33268 5.31675 5.31602 2.33341 8.99935 2.33341C12.6827 2.33341 15.666 5.31675 15.666 9.00008C15.666 12.6834 12.6827 15.6667 8.99935 15.6667ZM13.166 6.91675C13.166 7.60842 12.6077 8.16675 11.916 8.16675C11.2243 8.16675 10.666 7.60842 10.666 6.91675C10.666 6.22508 11.2243 5.66675 11.916 5.66675C12.6077 5.66675 13.166 6.22508 13.166 6.91675ZM6.08268 8.16675C6.77435 8.16675 7.33268 7.60842 7.33268 6.91675C7.33268 6.22508 6.77435 5.66675 6.08268 5.66675C5.39102 5.66675 4.83268 6.22508 4.83268 6.91675C4.83268 7.60842 5.39102 8.16675 6.08268 8.16675ZM13.2577 10.6667C12.591 12.3667 10.941 13.5834 8.99935 13.5834C7.05768 13.5834 5.40768 12.3667 4.74102 10.6667H13.2577Z" fill="#274A42"/></svg>';
              button.addEventListener("click", (e): void => {
                setIsEmojiHandler(e as unknown as MouseEvent);
              });
            }
            if (button.textContent === "clip") {
              button.classList.add(styles.customClip);
              button.innerHTML =
                '<svg width="10" height="20" viewBox="0 0 10 20" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path d="M8.33268 5.00016V14.5835C8.33268 16.4252 6.84101 17.9168 4.99935 17.9168C3.15768 17.9168 1.66602 16.4252 1.66602 14.5835V4.16683C1.66602 3.01683 2.59935 2.0835 3.74935 2.0835C4.89935 2.0835 5.83268 3.01683 5.83268 4.16683V12.9168C5.83268 13.3752 5.45768 13.7502 4.99935 13.7502C4.54102 13.7502 4.16602 13.3752 4.16602 12.9168V5.00016H2.91602V12.9168C2.91602 14.0668 3.84935 15.0002 4.99935 15.0002C6.14935 15.0002 7.08268 14.0668 7.08268 12.9168V4.16683C7.08268 2.32516 5.59102 0.833496 3.74935 0.833496C1.90768 0.833496 0.416016 2.32516 0.416016 4.16683V14.5835C0.416016 17.1168 2.46602 19.1668 4.99935 19.1668C7.53268 19.1668 9.58268 17.1168 9.58268 14.5835V5.00016H8.33268Z"/></svg>';
            }
            if (button.textContent === "schedule") {
              button.addEventListener("click", (e): void => {
                onClickClockButton(e as unknown as MouseEvent);
              });
              button.classList.add(styles.customSchedule);

              button.id = "schedule";
              button.innerHTML =
                '<svg width="18" height="17" viewBox="0 0 18 17" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path d="M2.77528 3.72438C4.27391 1.51884 6.803 0.0698242 9.67008 0.0698242C14.27 0.0698242 18 3.79978 18 8.39975C18 12.9997 14.27 16.7297 9.67008 16.7297C7.61629 16.7297 5.73891 15.9891 4.2885 14.761L4.6334 14.7563C5.2894 14.7473 5.88626 14.418 6.24946 13.9049C7.24179 14.5222 8.41381 14.8786 9.67008 14.8786C13.2519 14.8786 16.1489 11.9816 16.1489 8.39975C16.1489 4.81788 13.2519 1.92092 9.67008 1.92092C7.66012 1.92092 5.86583 2.83313 4.67808 4.2667L4.63482 4.22533L4.62747 4.21838C4.24841 3.85981 3.74324 3.66155 3.2184 3.66872C3.068 3.67077 2.91944 3.68967 2.77528 3.72438Z"/><path d="M10.1327 3.77201L8.74453 3.77201L8.74434 9.3253L13.1407 11.9631L13.835 10.8247L10.1328 8.63113L10.1327 3.77201Z"/><path d="M6.42969 8.87116C6.49308 8.87029 6.54969 8.83189 6.57339 8.77389C6.59708 8.71589 6.58268 8.64979 6.53719 8.60628L3.35517 5.56317C3.32528 5.53489 3.28518 5.51909 3.24344 5.51966C3.20171 5.52023 3.16206 5.53712 3.13296 5.5665L0.0443533 8.69529C6.85207e-05 8.74 -0.0122076 8.80648 0.0127525 8.86381C0.0380176 8.92083 0.0956576 8.95767 0.159053 8.95681L1.88528 8.93322L1.93794 12.7877C1.9391 12.8728 2.01036 12.9409 2.09698 12.9397L4.60787 12.9054C4.6945 12.9042 4.76387 12.8342 4.7627 12.7491L4.71005 8.89465L6.42969 8.87116Z"/></svg>';
            }
          });
        }}
        initial-value="Once upon a time..."
        init={{
          setup: (editor) => {
            editor.ui.registry.addGroupToolbarButton("alignment", {
              icon: "align-left",
              tooltip: "Alignment",
              items: "alignleft aligncenter alignright | alignjustify"
            });
            editor.ui.registry.addButton("customClip", {
              text: "clip",
              onAction: () => {
                refFiles.current.click();
              }
            });
            editor.ui.registry.addButton("customEmoji", {
              text: "emoji",
              onAction: () => {}
            });
            isScheduleButton &&
              editor.ui.registry.addButton("customSchedule", {
                text: "schedule",
                onAction: () => {}
              });
            editor.ui.registry.addButton("sendCustom", {
              text: btnText || __("Отправить"),
              onAction: () => {
                refSubmit.current.click();
              }
            });
          },
          height: 200,
          menubar: false,
          toolbar_location: "bottom",
          toolbar:
            "customClip bold italic alignment strikethrough underline " +
            "bullist numlist customEmoji | customSchedule sendCustom",
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          preview_styles: false,
          statusbar: false
        }}
      />
      {isSchedule && (
        <div className={styles.scheduleMessageModal} ref={scheduleRef}>
          <div className={styles.scheduleMessageHeader}>
            <div className={styles.scheduleMessageTitle}>{__("Запланированная отправка")}</div>
            <button className={styles.crossBtn} onClick={() => setIsSchedule(false)}>
              <CloseIcon />
            </button>
          </div>
          <div className={styles.scheduleMessageContent}>
            <InputDate
              startDate={scheduleTime ? new Date(scheduleTime) : null}
              setStartDate={(v: Date) => onChangeDate(v)}
              withTime
              isClearable
              isBtnTooday
            />
          </div>
          <div className={styles.scheduleActions}>
            <Button
              key={uuid()}
              type="button"
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.MEDIUM}
              text={__("Отменить")}
              onClick={() => {
                setDateValue("");
                setSceduleTime("");
                setIsSchedule(false);
              }}
            />
            <Button
              key={uuid()}
              type="button"
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.MEDIUM}
              text={__("Готово")}
              onClick={onScheduleTime}
            />
          </div>
        </div>
      )}
      {isEmoji && (
        <div className={styles.emojiBox} ref={emojiRef}>
          <EmojiPicker
            previewConfig={{ showPreview: false }}
            width={268}
            height={300}
            searchDisabled
            emojiStyle={EmojiStyle.APPLE}
            onEmojiClick={clickOnEmojiHandler}
          />
        </div>
      )}
    </div>
  );
};

export default TinyMCE;
