import classNames from "classnames";
import { FC, ReactNode } from "react";

import styles from "./WorkAreaWrap.module.sass";

interface IWorkAreaWrapProps {
  children?: ReactNode;
  variant?: "column" | "row";
  className?: string;
}

export const WorkAreaWrap: FC<IWorkAreaWrapProps> = ({ children, variant = "column", className }) => (
  <div className={classNames(styles.workAreaWrap, styles[variant], className)}>{children}</div>
);
