import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "Store/actions";

// import Actions from "/Store/actions";

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};
