import classNames from "classnames";
import AudioPlayer from "generalComponents/AudioPlayer/AudioPlayer";
import Clue from "generalComponents/Clue/Clue";
import SignItem from "generalComponents/Elements/SignItem";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { PdfView } from "generalComponents/Modals/Components/PdfView/PdfView";
import { isSafari } from "generalComponents/Services/browserServices";
import { getMedia } from "generalComponents/Services/mediaServices";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { ROUTES } from "generalComponents/variables/routing";
import VideoPlayer from "generalComponents/VideoPlayer/VideoPlayer";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { useDateFormat } from "hooks/useDateFormat";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { FC, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./PreviewItem.module.sass";

const PreviewItem: FC = (): JSX.Element => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const { uid, tags } = useUserSelectors();
  const { currentItem } = useFilesSelectors();
  const { isLinesPreviewView } = useGetItemView();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const safe = useAuthorizedSafe();
  const file = currentItem as IFile;
  const [audio, setAudio] = useState<string>(NO_VALUE);
  const [video, setVideo] = useState<string>(NO_VALUE);

  const getTagById = (tagId: string) => {
    return tags.find((tag) => tag.id === tagId);
  };

  useEffect(() => {
    if (file?.mime_type) {
      if (file.mime_type.includes("audio") && file.is_preview) {
        isSafari() ? getMedia(`${FILE_PREVIEW}`, file.mime_type, setAudio) : setAudio(FILE_PREVIEW);
      }
      if (file.mime_type.includes("video") && file.is_preview) {
        isSafari() ? getMedia(`${FILE_PREVIEW}`, file.mime_type, setVideo) : setVideo(FILE_PREVIEW);
      }
    }
  }, [file]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentItem) {
    return (
      <div className={styles.emptyBox}>
        <Clue icon="happy" text={__("Выберите файл для просмотра")} />
        <div style={{ height: 100 }} />
      </div>
    );
  }
  const FILE_PREVIEW = isSafePage
    ? `https://fs2.mh.net.ua/ajax/safe_file_preview.php?uid=${uid}&fid=${file.fid}&code=${safe.code}&pass=${
        safe.pass
      }&id_safe=${safe.id_safe}&${new Date()}`
    : `${file?.preview}?${new Date()}`;

  if (!file.is_dir && file.mime_type && file.mime_type.startsWith("image")) {
    return (
      <div className={classNames(styles.imgContainer, { [styles.line]: isLinesPreviewView })}>
        <img src={FILE_PREVIEW} alt="filePrieview" className={styles.image} />
      </div>
    );
  }

  if (file.mime_type && file.mime_type.startsWith("audio")) {
    return (
      <div className={classNames(styles.wrapMedia, { [styles.line]: isLinesPreviewView })}>
        {audio && <AudioPlayer audio={audio} />}
      </div>
    );
  }

  if (file.mime_type && file.mime_type.startsWith("video")) {
    return (
      <div className={classNames(styles.wrapMedia, { [styles.line]: isLinesPreviewView })}>
        {video && <VideoPlayer video={video} />}
      </div>
    );
  }

  if (file.mime_type && file.mime_type.startsWith("application/pdf")) {
    return (
      <div className={styles.pdfContainer}>
        <PdfView src={FILE_PREVIEW} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.folderBox}>
        <div className={styles.options}>
          {currentItem.tags.length > 0 && (
            <div className={styles.tags}>
              {currentItem.tags.map((id) => (
                <div key={id} className={styles.tag}>
                  {getTagById(id)?.name}
                </div>
              ))}
            </div>
          )}
          {(currentItem as IFolder).emo && (
            <span className={styles.emo} dangerouslySetInnerHTML={{ __html: (currentItem as IFolder).emo }} />
          )}
          {(currentItem as IFolder).fig && <SignItem sign={(currentItem as IFolder).fig} size={22} />}
        </div>
        <ItemIcon
          color={currentItem.color}
          isFolder={Boolean(currentItem.is_dir)}
          width={currentItem.is_dir ? 150 : 112}
          height={currentItem.is_dir ? 112 : 150}
          passIcon={!isSafePage && Boolean(currentItem.is_pass)}
          ext={(currentItem as IFile).ext}
        />
        <p className={styles.text}>
          {__("Название: ")}
          <strong>{currentItem.name}</strong>
        </p>
        <p className={styles.text}>
          {__("Дата создания/добавления: ")}
          <strong>
            {currentItem.is_dir ? formatDate((currentItem as IFolder).cdate) : formatDate((currentItem as IFile).ctime)}
          </strong>
        </p>
        {Boolean(!currentItem.is_dir) && (
          <p className={styles.text}>
            {__("Размер файла: ")}
            <strong>{(currentItem as IFile).size_now}</strong>
          </p>
        )}
      </div>
    </div>
  );
};

export default PreviewItem;
