import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CKEDITOR_TOOLBAR_OPTIONS } from "generalComponents/variables/ckeditor";
import { TOOLBAR_BUTTONS } from "models/generalComponents/customEditorToolbar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocales } from "react-localized";

import styles from "./TextEditorArea.module.sass";

const TextEditorArea = ({ content, setContent, pressedButton, changeToolbar }) => {
  const { __ } = useLocales();
  const [editorState, setEditorState] = useState();

  useEffect(() => {
    if (editorState) {
      if (pressedButton.type.startsWith("alignment")) {
        const arr = pressedButton.type.split(" ");
        editorState.execute("alignment", { value: arr[1] });
        editorState.ui.getEditableElement().focus();
        return;
      }
      editorState.execute(pressedButton.type);
      editorState.ui.getEditableElement().focus();
    }
  }, [pressedButton]); // eslint-disable-line

  const editor = null;
  const handlerChange = (_, editor) => {
    const text = editor.getData();
    setContent(text);
  };

  return (
    <div className={styles.wrapEditor}>
      <CKEditor
        onReady={(editor) => {
          setEditorState(editor);
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());

          const outdentList = editor.commands.get(TOOLBAR_BUTTONS.OUTDENT_LIST);
          outdentList.on("execute", () => changeToolbar(TOOLBAR_BUTTONS.OUTDENT_LIST));
          const bold = editor.commands.get(TOOLBAR_BUTTONS.BOLD);
          bold.on("execute", () => changeToolbar(TOOLBAR_BUTTONS.BOLD));
          const italic = editor.commands.get(TOOLBAR_BUTTONS.ITALIC);
          italic.on("execute", () => changeToolbar(TOOLBAR_BUTTONS.ITALIC));
          const underline = editor.commands.get(TOOLBAR_BUTTONS.UNDERLINE);
          underline.on("execute", () => changeToolbar(TOOLBAR_BUTTONS.UNDERLINE));
          const strikethought = editor.commands.get(TOOLBAR_BUTTONS.STRIKETHROUGHT);
          strikethought.on("execute", () => changeToolbar(TOOLBAR_BUTTONS.STRIKETHROUGHT));
        }}
        onError={({ willEditorRestart }) => {
          if (willEditorRestart) {
            editor.ui.view.toolbar.element.remove();
          }
        }}
        onChange={handlerChange}
        editor={DecoupledEditor}
        data={content}
        config={{
          toolbar: CKEDITOR_TOOLBAR_OPTIONS,
          list: {
            properties: {
              styles: false,
              startIndex: true,
              reversed: true
            }
          },
          placeholder: __("Введите текст")
        }}
      />
    </div>
  );
};

export default TextEditorArea;

TextEditorArea.propTypes = {
  content: PropTypes.string,
  setContent: PropTypes.func,
  pressedButton: PropTypes.exact({
    type: PropTypes.string,
    change: PropTypes.bool
  }),
  changeToolbar: PropTypes.func,
  idx: PropTypes.string,
  blurIdx: PropTypes.number,
  setBlurIdx: PropTypes.func
};
