import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import { ReactComponent as Info } from "assets/icons/info_2.svg";
import { ReactComponent as Upload } from "assets/icons/upload.svg";
import Bank from "assets/images/Profile/payment/bank.png";
import Card from "assets/images/Profile/payment/card.png";
import Stripe from "assets/images/Profile/payment/stripe.png";
import { ReactComponent as Tariff } from "assets/images/Profile/payment/tariff.svg";
import Button from "generalComponents/Button/Button";
import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
import PayVariant from "generalComponents/Modals/Components/TariffModal/PayVariant/PayVariant";
import Price from "generalComponents/Modals/Components/TariffModal/Prise/Price";
import PopUp from "generalComponents/PopUp/PopUp";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { initialTariffModalState } from "models/store/Cabinet/modals/tariffModal";
import { ChangeEvent, MouseEvent, useState } from "react";
import { useLocales } from "react-localized";
import { Link } from "react-router-dom";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./TariffModal.module.sass";

const TariffModal = () => {
  const { __ } = useLocales();
  const { onSetTariffModal } = useActions();
  const [checkedAgreement, setCheckedAgreement] = useState(false);
  const [activePeriod, setActivePeriod] = useState<string>("year");
  const [paymentMethod, setPaymentMethod] = useState<string | undefined>();
  const [showDetails, setShowDetails] = useState(false);
  const {
    tariffs: { price, discount, isPeriodSelected, title }
  } = useGlobalModalsSelectors();

  const payVariants = [
    {
      title: __("Безналичный расчет"),
      subtitle: __("Банковский перевод"),
      img: Bank,
      onClick: () => {
        setPaymentMethod(undefined);
        setShowDetails(true);
      }
    },
    {
      title: __("Stripe"),
      subtitle: __("Visa, Mastercard, Apple Pay"),
      img: Stripe,
      onClick: () => setPaymentMethod("Stripe")
    },
    {
      title: __("Картой"),
      subtitle: __("Visa, Mastercard"),
      img: Card,
      onClick: () => setPaymentMethod("Картой")
    }
  ];

  const period = [
    {
      name: __("12 месяцев, по предоплате"),
      value: "year"
    },
    {
      name: __("12 месяцев, ежемесячная оплата"),
      value: "month"
    }
  ];

  const closeModal = () => {
    onSetTariffModal(initialTariffModalState());
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    setActivePeriod(value);
  };

  const handleSubmit = () => {
    console.log("test");
  };

  const sum = activePeriod === "year" ? price * 12 : price;

  return (
    <PopUp set={closeModal} position="top">
      <div className={styles.container}>
        <div className={styles.block}>
          <div className={styles.head}>
            <Tariff />
            <div className={styles.title}>
              <h6>{title}</h6>
              <span>{__("12 мес")}</span>
            </div>
            <div className={styles.prices}>
              <Price
                value={activePeriod === "year" ? price * 12 : price}
                discount={activePeriod === "year" ? discount * 12 : discount}
              />
            </div>
          </div>
          {isPeriodSelected && (
            <div className={styles.select}>
              <SelectCustom
                options={period}
                onSelected={handleChange}
                selectedDefault={period[0].name}
                width={250}
                variantHeight="small"
              />
              <span className={styles.tip}>{__("Подписка")}</span>
            </div>
          )}
          <div className={styles.sum}>
            <div>
              {__("Подытог")}
              <span>
                {sum}${activePeriod === "year" ? __("в год") : __("в мес")}
              </span>
            </div>
            <div>
              {__("НДС 20 %")}
              <span>
                {(sum * 20) / 100}${activePeriod === "year" ? __("в год") : __("в мес")}
              </span>
            </div>
            <div>
              {__("ВСЕГО")}
              <span>
                {sum}${activePeriod === "year" ? __("в год") : __("в мес")}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.block}>
          {showDetails ? (
            <>
              <span className={styles.goBack} onClick={() => setShowDetails(false)}>
                <Arrow /> {__("Выбор способа оплаты")}
              </span>
              <div className={styles.info}>
                <Info />
                <span>{__("Тариф будет активироапн сразу после поступления средств ")}</span>
              </div>
              <div className={styles.info}>
                <span>
                  <strong>{__("Получатель:")}</strong>
                  {__("ООО 'ГЕТ ВИН', код ЕГРПОУ: 3161592")}
                </span>
                <span>
                  <strong>{__("IBAN:")}</strong>
                  {__("UA843052990000026004234234423")}
                </span>
                <span>
                  <strong>{__("Назначение платежа:")}</strong>
                  {__("Предоплата услуг сервиса 4HUB согласно счета 4-123456")}
                </span>
                <span>
                  <strong>{__("Сумма:")}</strong>
                  {__("9999 %valut%")}
                </span>
              </div>
              <Button
                className={styles.button}
                variant={ButtonVariantType.BLUE}
                onClick={handleSubmit}
                text={__("Скачать счет")}
                iconL={<Upload />}
              />
            </>
          ) : (
            <>
              <span className={styles.rightTitle}>{__("Выбор способа оплаты")}</span>
              <div className={styles.payment}>
                {payVariants.map((item, index) => (
                  <PayVariant
                    key={index}
                    title={item.title}
                    img={item.img}
                    subtitle={item.subtitle}
                    active={item.title === paymentMethod}
                    onClick={item.onClick}
                  />
                ))}
              </div>
              <div className={styles.agreement}>
                <CheckBox
                  className={styles.checkbox}
                  variant={checkedAgreement ? CheckBoxVariantTypes.CHECKED : CheckBoxVariantTypes.DEFAULT}
                  onClick={() => setCheckedAgreement(!checkedAgreement)}
                />
                {__("Я принимаю ")}
                <Link to="/">{__("Соглашения")}</Link>
              </div>
              <Button
                className={styles.button}
                disabled={!checkedAgreement || !paymentMethod}
                variant={ButtonVariantType.BLUE}
                onClick={handleSubmit}
                text={__("Оформить и оплатить")}
              />
            </>
          )}
        </div>
      </div>
    </PopUp>
  );
};

export default TariffModal;
