import api from "api";
import CryptoJS from "crypto-js";
import Loader from "generalComponents/Loaders/4HUB";
import { useActions } from "hooks/useActions";
import { LoaderTypes } from "models/generalComponents/loader";
import { STATUS_COLOR, USER_STATUS } from "models/store/user/user";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EmployeePage = () => {
  const { uid } = useParams();
  const { onLog } = useActions();
  const navigate = useNavigate();
  const getUser = async () => {
    const decryptedBase64Uid = CryptoJS.enc.Base64.parse(uid).toString(CryptoJS.enc.Utf8);
    const decriptedUid = CryptoJS.AES.decrypt(decryptedBase64Uid, process.env.REACT_APP_CRYPTO).toString(
      CryptoJS.enc.Utf8
    );
    const params = {
      uid: decriptedUid,
      status_color: STATUS_COLOR.ONLINE,
      status: USER_STATUS.ONLINE
    };
    await api.get(`/ajax/user_edit2.php`, { params });
    onLog(decriptedUid);
    navigate(`/`, { replace: true });
  };

  useEffect(() => {
    if (uid) {
      getUser();
    }
  }, []); // eslint-disable-line
  return (
    <Loader
      position="absolute"
      zIndex={10000}
      containerType="bounceDots"
      type={LoaderTypes.BOUNCING_DOTS}
      background="white"
      animation={false}
    />
  );
};

export default EmployeePage;
