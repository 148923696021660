import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import { Observer } from "generalComponents/Observer/Observer";
import { useIsFiltersJournal } from "generalComponents/Services/journalSercives";
import { WorkSpaceWrap } from "generalComponents/WorkSpaceWrap/WorkSpaceWrap";
import { useActions } from "hooks/useActions";
import { LoaderTypes } from "models/generalComponents/loader";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useJournalSelectors } from "Store/selectors/journalSelectors";

import { JournalEventsItems } from "./components/JournalEvents/JournalEventsItems";
import styles from "./JournalEventsList.module.sass";

export const JournalEventsList: FC = (): JSX.Element => {
  const { __ } = useLocales();

  const { actionLogId, actionLogGroup } = useParams();

  const { onGetJournalElements, onSetJournalPage } = useActions();

  const { loader, events, page, total } = useJournalSelectors();

  const message = {
    success: "",
    error: __("Не удалось получить список элементов, попробуйте еще раз")
  };

  const isFiltersJournal = useIsFiltersJournal();

  const onLoadMore = (): void => {
    const controller = new AbortController();

    onSetJournalPage(page + 1);

    if (actionLogId && actionLogGroup) {
      onGetJournalElements(message, controller, {
        filter_object: actionLogId,
        filter_group: [actionLogGroup.toLocaleUpperCase()]
      });
    } else {
      onGetJournalElements(message, controller);
    }
  };

  const renderMyEvents = (): JSX.Element => {
    if (events !== null) {
      if (total === 0) {
        if (isFiltersJournal) {
          return <Clue icon="neutral" text={__("Нет файлов соответствующих параметрам фильтрации")} />;
        }

        return <Clue icon="sad" text={__("Здесь пока пусто")} />;
      }

      return <JournalEventsItems events={events} />;
    }
  };

  return (
    <WorkSpaceWrap className={styles.root}>
      {loader && page === 1 ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <>
          {renderMyEvents()}
          {<Observer loader={loader} page={page} total={total} callBack={onLoadMore} />}
        </>
      )}
    </WorkSpaceWrap>
  );
};
