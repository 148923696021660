import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";
import { IMailsState } from "models/store/postbox/postbox";

export const usePostboxSelectors = (): IMailsState => {
  const mails = useTypeSelector((s) => s.Postbox);
  const postboxes = mails.postboxes;
  const loader = mails.loader;
  const letters = mails.letters;
  const isObserver = mails.isObserver;
  const pickedLetters = mails.pickedLetters;
  const searchLetter = mails.searchLetter;

  return {
    postboxes,
    loader,
    letters,
    isObserver,
    pickedLetters,
    searchLetter
  };
};
