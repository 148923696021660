export enum FieldsName {
  CATEGORY = "cfolder",
  RUBRIC = "rfolder",
  NAME_FOLDER = "nfolder",
  DESCRIPTION_FOLDER = "dfolder",
  FILES_UPLOAD = "filesUpload"
}

export interface IDataField {
  value?: string;
  required?: boolean;
  files?: File[];
  error?: boolean;
  errorMessage?: string;
}

export interface FormDataCreateaObjectState {
  [FieldsName.CATEGORY]: IDataField;
  [FieldsName.RUBRIC]?: IDataField;
  [FieldsName.NAME_FOLDER]: IDataField;
  [FieldsName.DESCRIPTION_FOLDER]: IDataField;
  [FieldsName.FILES_UPLOAD]: IDataField;
}

export enum FormCreateObjectLibraryTypes {
  SET_VALUES = "SET_VALUES",
  CHANGE = "CHANGE",
  CHANGE_FILE = "CHANGE_FILE",
  REMOVE_FILE = "REMOVE_FILE",
  NULLIFY = "NULLIFY"
}

export type FormAction = {
  type: FormCreateObjectLibraryTypes;
  field?: FieldsName;
  value?: string;
  files?: File[];
  error?: boolean;
  errorMessage?: string;
  payload?: FormDataCreateaObjectState;
};

export const initialState: FormDataCreateaObjectState = {
  [FieldsName.CATEGORY]: {
    value: "",
    required: true,
    error: true,
    errorMessage: ""
  },
  [FieldsName.RUBRIC]: {
    value: "",
    required: true,
    error: true,
    errorMessage: ""
  },
  [FieldsName.NAME_FOLDER]: {
    value: "",
    required: true,
    error: true,
    errorMessage: ""
  },
  [FieldsName.DESCRIPTION_FOLDER]: {
    value: "",
    required: false,
    error: false,
    errorMessage: ""
  },
  [FieldsName.FILES_UPLOAD]: {
    files: [],
    required: false,
    error: false,
    errorMessage: ""
  }
};

export const reducerData = (state: FormDataCreateaObjectState, action: FormAction): FormDataCreateaObjectState => {
  switch (action.type) {
    case FormCreateObjectLibraryTypes.SET_VALUES:
      return {
        ...state,
        ...action.payload
      };
    case FormCreateObjectLibraryTypes.CHANGE:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          value: action.value,
          error: action.error,
          errorMessage: action.errorMessage
        }
      };

    case FormCreateObjectLibraryTypes.CHANGE_FILE:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          files: [...state[action.field].files, ...action.files],
          error: action.error,
          errorMessage: action.errorMessage
        }
      };

    case FormCreateObjectLibraryTypes.REMOVE_FILE:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          files: action.files,
          error: action.error,
          errorMessage: action.errorMessage
        }
      };

    case FormCreateObjectLibraryTypes.NULLIFY:
      return initialState;

    default:
      return state;
  }
};
