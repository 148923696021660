import SearchField from "generalComponents/SearchField/SearchField";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";

const LibrarySearch = () => {
  const { __ } = useLocales();

  const {
    objSearchParams: { search_query },
    onSetObjSearchParams
  } = useSelectedObjSearchParams();

  const [searchValue, setSearchValue] = useState<string>(search_query ?? NO_VALUE);

  useEffect(() => {
    onSetObjSearchParams({ [SEARCH_PARAMS.SEARCH_QUERY]: searchValue });
  }, [searchValue]); //eslint-disable-line

  return (
    <SearchField
      variant="secondary"
      placeholder={__("Введите")}
      value={searchValue}
      setValue={setSearchValue}
      autoFocus
    />
  );
};

export { LibrarySearch };
