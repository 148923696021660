import { useMonths } from "generalComponents/Calendars/CalendarHelper";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useLocales } from "react-localized";

const ChartExtendPeriod = ({ average, extendData }) => {
  const { __ } = useLocales();
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,

    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: true,
        color: "#494949",
        align: "top"
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: __("дни")
        }
      }
    }
  };

  const months = useMonths();

  const averadeData = useMemo(() => months().map((_) => average), [average, months]);

  const labels = months().map((m) => m.name);

  const data = {
    labels: labels,
    datasets: [
      {
        type: "line",
        data: averadeData,
        fill: false,
        borderColor: "rgb(54, 162, 235)",
        pointRadius: 0,
        borderDash: [10, 6],
        borderWidth: 1,
        datalabels: { display: false }
      },
      {
        data: extendData,
        type: "bar",
        backgroundColor: "#ff9999",
        borderRadius: "6"
      }
    ]
  };
  return <Bar data={data} options={options} />;
};

export default ChartExtendPeriod;

ChartExtendPeriod.propTypes = {
  average: PropTypes.number,
  extendData: PropTypes.arrayOf(PropTypes.number)
};
