import "react-datepicker/dist/react-datepicker.css";
import "./theme/index.sass";
import "./styles/colors.sass";
import "react-tooltip/dist/react-tooltip.css";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { FacebookProvider } from "react-facebook";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "Store/store";

import App from "./App";

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <FacebookProvider appId="732497768687937">
      <GoogleOAuthProvider clientId="824792284729-d3mkpobr143jr8s76qjh8a3ueav95pfq.apps.googleusercontent.com">
        <PersistGate loading={null} persistor={persistor}>
          <DndProvider backend={HTML5Backend}>
            <Router>
              <App />
            </Router>
          </DndProvider>
        </PersistGate>
      </GoogleOAuthProvider>
    </FacebookProvider>
  </Provider>
);
