import { AxiosProgressEvent } from "axios";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { ITopMessages } from "models/common/common";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import { ModalActions } from "models/store/Cabinet/modals/modalActions";
import {
  initialEditItemsModalState,
  initialPropertiesModalState,
  initialSafeModalState,
  ITopMessageTypes
} from "models/store/Cabinet/modals/modals";
import { FilesNextArrayType, initialPickedItems } from "models/store/files/files";
import { FilesActions } from "models/store/files/filesActions";
import { ILoadingFiles } from "models/store/files/uploadFilesModals/uploadFiles";
import { IFolder } from "models/store/folders/foldersStore";
import { IAuthorized, ISafe } from "models/store/safe/safe";
import {
  IAddAuthorized,
  IAddNewSafe,
  IClearAuthorized,
  IFetchSafeList,
  IRemoveAuthorized,
  ISafeFoldersList,
  SafeActions
} from "models/store/safe/safeActions";
import {
  IChengeSafePassword,
  ICreateSafe,
  ICreateSafeFile,
  ICreateSafeFolder,
  IDelSafeFolder,
  IEditSafe,
  IEditSafeFolder,
  IGetSafeFiles
} from "models/store/safe/safePayloads";
import { ThunkAction } from "redux-thunk";
import { getLocationSearchParams } from "utils/getLocationSearchParams";

import api from "../../api";
import { RootState } from "../reducers";
import { SafeTypes } from "../types/safeTypes";
import {
  onChangeLoadingFiles,
  onFileLoader,
  onResetFilesList,
  onSetAddFiles,
  onSetMyFiles,
  onSetNextFiles,
  onSetPickedItems,
  onSetTotalItems
} from "./FilesActions";
import {
  onSetEditItemsModal,
  onSetLoaderModal,
  onSetPropertiesModal,
  onSetSafeModal,
  onSetTopMessageModal
} from "./ModalActions";

export const onFetchSafes =
  (
    isLoader?: boolean,
    controller?: AbortController
  ): ThunkAction<void, RootState, unknown, SafeActions | ModalActions> =>
  async (dispatch, getState) => {
    try {
      if (isLoader) {
        dispatch(onSetLoaderModal(true));
      }
      const params = {
        uid: getState().user.uid
      };
      const { data } = await api.get(`/ajax/safe_list.php`, { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetSafeList(data.safes));
    } catch {
      if (!controller?.signal?.aborted) {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
      }
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onCreateSafe =
  (payload: ICreateSafe, cb?: (el: ISafe) => void): ThunkAction<void, RootState, unknown, SafeActions | ModalActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        name: payload.name,
        pass: payload.pass,
        prim: payload.prim,
        is_system: payload.is_system
      };
      const { data } = await api.get("/ajax/safe_add.php?", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetSafeList(data.safes));
      dispatch(onSetSafeModal(initialSafeModalState()));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.ADD }));
      if (cb) {
        const mySafes = data.safes.filter((el: ISafe) => el.is_my === 1);
        cb(mySafes[mySafes.length - 1]);
      }
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onEditSafe =
  (payload: IEditSafe, typeMessage: EVENT_TYPE): ThunkAction<void, RootState, unknown, SafeActions | ModalActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        id_safe: payload.id,
        pass: payload.pass,
        name: payload?.name,
        is_del: payload?.is_del,
        is_archive: payload?.is_archive
      };
      const { data } = await api.get("/ajax/safe_edit.php?", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetSafeList(data.safes));
      dispatch(onSetSafeModal(initialSafeModalState()));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: typeMessage }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onChangeSafePassword =
  (
    payload: IChengeSafePassword,
    message: ITopMessages
  ): ThunkAction<void, RootState, unknown, SafeActions | ModalActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        id_safe: payload.id,
        pass: payload.pass,
        pass_new: payload.pass_new
      };
      const { data } = await api.get("/ajax/safe_pass_change.php?", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetSafeList(data.safes));
      dispatch(onSetSafeModal(initialSafeModalState()));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: message.success }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: message.error }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onCloseSafe =
  (safeId: string, messages?: ITopMessages): ThunkAction<void, RootState, unknown, SafeActions | ModalActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_safe: safeId
      };
      const { data } = await api.get("/ajax/safe_close.php?", { params });
      if (data.ok) {
        dispatch(onRemoveAuthorized(safeId));
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.SUCCESS,
            message: messages.success
          })
        );
      } else {
        throw new Error();
      }
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR,
          message: messages.error
        })
      );
    }
  };

export const onDeleteSahereSafe =
  (safeId: string): ThunkAction<void, RootState, unknown, SafeActions | ModalActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_safe: safeId
      };
      const { data } = await api.get("/ajax/safe_share_delme.php?", { params });
      if (data.ok) {
        dispatch(onRemoveAuthorized(safeId));
        dispatch(onFetchSafes());
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.SUCCESS,
            variantMessage: EVENT_TYPE.IS_DEL
          })
        );
      } else {
        throw new Error();
      }
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    }
  };

export const onGetSafeFiles =
  (payload?: IGetSafeFiles): ThunkAction<void, RootState, unknown, FilesActions | ModalActions | SafeActions> =>
  async (dispatch, getState) => {
    const { sort, sort_reverse, color, emo, tags } = getLocationSearchParams();

    const params = {
      uid: getState().user.uid,
      id_safe: payload.id_safe,
      id_dir: payload.id_dir,
      code: payload.code,
      search: payload?.search,
      sort,
      sort_reverse,
      emo,
      color,
      tags: tags ? tags.split(",") : undefined,
      per_page: PER_PAGE,
      page: getState().Files.page,
      group: 0
    };
    try {
      dispatch(onFileLoader(true));
      const { data } = await api.get("/ajax/safe_file_list.php?", { params });
      if (data.ok === 1) {
        dispatch(onResetFilesList());
        dispatch(onSetMyFiles(data.files));
        dispatch(onSetTotalItems(data.total));
      }
      if (data.ok === 0) {
        dispatch(onRemoveAuthorized(payload.id_safe));
        const codeDeadLine = new Date(data.code_info.deadline).getTime();
        if (!data.code_info.code) {
          const safe = getState().Safe.safeList.find((s) => s.id === payload.id_safe);
          dispatch(
            onSetSafeModal({
              open: true,
              type: SAFE_MODALS.ENTER_SAFE_CODE,
              safe,
              callbackType: SAFE_CALLBACK_MODAL.FETCH_FILES,
              alert: "Срок действия кода закончился. Нужно получить новый код"
            })
          );
          return;
        }
        if (codeDeadLine < Date.now()) {
          const safe = getState().Safe.safeList.find((s) => s.id === payload.id_safe);
          dispatch(
            onSetSafeModal({
              open: true,
              type: SAFE_MODALS.ENTER_SAFE_PASSWORD,
              safe,
              callbackType: SAFE_CALLBACK_MODAL.FETCH_FILES
            })
          );
        }
      }
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    } finally {
      dispatch(onFileLoader(false));
    }
  };

export const onGetSafeFilesLinePreview =
  (
    payload?: IGetSafeFiles,
    messages?: string,
    cb?: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { sort, sort_reverse, color, emo, tags } = getLocationSearchParams();

    const params = {
      uid: getState().user.uid,
      id_safe: payload.id_safe,
      code: payload.code,
      search: payload?.search,
      per_page: PER_PAGE,
      page: payload?.page ?? 1,
      id_dir: payload.id_dir,
      emo,
      color,
      tags: tags ? tags.split(",") : undefined,
      sort_reverse,
      sort,
      group: 0
    };
    try {
      const { data } = await api.get("/ajax/safe_file_list.php", {
        params
      });
      const idDir = payload.id_dir;
      const state = getState().Files.filesNext;
      const result: { files: FilesNextArrayType; total: number } = { files: null, total: data.total };
      if (payload.page === 1) {
        result.files = data.files;
      } else {
        result.files = [...(state[idDir].files as FilesNextArrayType), ...data.files];
      }

      dispatch(onSetNextFiles({ [idDir]: result }));
    } catch {
      if (messages) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR,
            message: messages
          })
        );
      }
    } finally {
      if (cb) cb();
    }
  };

export const onUploadSafeFile =
  (
    file: ILoadingFiles,
    safeId: string,
    controller: AbortController
  ): ThunkAction<void, RootState, unknown, FilesActions | SafeActions> =>
  async (dispatch, getState) => {
    try {
      const safe = getState().Safe.authorized.find((el) => el.id_safe === safeId);
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_safe", safe?.id_safe);
      formData.append("pass", safe?.pass);
      formData.append("code", safe?.code);
      formData.append("myfile", file.file);
      formData.append("id_dir", file.options.id_dir);
      formData.append("fileName", file.options.name);
      formData.append("tags", JSON.stringify(file.options.tags));
      formData.append("color", file.options.color);
      formData.append("emo", file.options.emo);

      const { data } = await api.post(`/ajax/safe_file_add.php`, formData, {
        signal: controller.signal,
        onUploadProgress: (progressEvent: AxiosProgressEvent): void => {
          const time = Math.floor(
            progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
          );
          const percent = progressEvent.progress * 100;
          dispatch(onChangeLoadingFiles({ ...file, percent, time }));
        }
      });
      dispatch(onChangeLoadingFiles({ ...file, done: true }));
      dispatch(onSetAddFiles(data?.file_info));
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

export const onGetSafeFolders =
  (idSafe: string): ThunkAction<void, RootState, unknown, FilesActions | SafeActions | ModalActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_safe: idSafe
      };

      const { data } = await api.get("/ajax/safe_folders_list.php?", { params });
      checkResponseStatus(data.ok);

      const foldersAll = (f: any): IFolder[] => {
        const result: IFolder[] = [];
        const allFolders = (folders: IFolder[]): void => {
          for (let i = 0; i <= folders.length - 1; i++) {
            result.push(folders[i]);
            if (folders[i].folders?.length > 0) {
              allFolders(folders[i].folders);
            }
          }
        };
        allFolders(f);
        return result;
      };

      dispatch(onSafeFolders(foldersAll(data.folders)));
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

export const onCreateSafeFolder =
  (payload: ICreateSafeFolder): ThunkAction<void, RootState, unknown, FilesActions | SafeActions | ModalActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        id_safe: payload.id_safe,
        id_parent: payload.idParent,
        dir_name: payload.name,
        tags: payload?.tags?.length ? JSON.stringify(payload.tags) : undefined,
        emo: payload?.emo ? payload.emo : undefined,
        color: payload?.color ? payload.color : undefined
      };

      const { data } = await api.get("/ajax/safe_folders_add.php?", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetSafeModal(initialSafeModalState()));
      dispatch(onSetAddFiles(data?.file_info));

      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.ADD }));
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };
export const onEditSafeFolder =
  (
    payload: IEditSafeFolder,
    typeMessage: EVENT_TYPE
  ): ThunkAction<void, RootState, unknown, FilesActions | SafeActions | ModalActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        id_safe: payload.id_safe,
        pass: payload.pass,
        id_parent: payload.idParent,
        dir_name: payload.name,
        id_dirs: payload.id_dirs,
        dir_name_new: payload.dir_name_new,
        is_archive: payload.is_archive,
        is_del: payload.is_del,
        tags: JSON.stringify(payload.tags),
        emo: payload.emo,
        color: payload.color,
        symbol: payload.symbol
      };

      const { data } = await api.get("/ajax/safe_folders_edit.php?", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetSafeModal({ open: false, type: SAFE_MODALS.NO_MODAL }));
      dispatch(onSetPickedItems(initialPickedItems()));
      dispatch(onSetEditItemsModal(initialEditItemsModalState()));
      dispatch(onResetFilesList());
      dispatch(onGetSafeFiles({ code: payload.code, id_safe: payload.id_safe, id_dir: payload.idParent }));
      dispatch(onSetPropertiesModal(initialPropertiesModalState()));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: typeMessage }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onDelSafeFolder =
  (payload: IDelSafeFolder): ThunkAction<void, RootState, unknown, FilesActions | SafeActions | ModalActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_safe: payload.id_safe,
        id_dirs: payload.id_dirs
      };
      const { data } = await api.get("/ajax/safe_folders_del.php", { params });
      checkResponseStatus(data.ok);
      dispatch(onResetFilesList());
      dispatch(onSetPickedItems(initialPickedItems()));
      dispatch(onGetSafeFiles({ code: payload.code, id_safe: payload.id_safe, id_dir: payload.id_parent }));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.IS_DEL }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onCreateSafeFile =
  (
    safe: ICreateSafeFile,
    file: ILoadingFiles,
    controller: AbortController
  ): ThunkAction<void, RootState, unknown, FilesActions | SafeActions | ModalActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_safe: safe.safe.id,
        pass: safe.safe.pass,
        code: safe.code,
        id_dir: file.options.id_dir,
        ext: file.options.ext,
        fileName: file.options.name,
        tags: JSON.stringify(file.options.tags),
        emo: file.options.emo,
        color: file.options.color
      };

      const { data } = await api.get("/ajax/safe_file_new.php?", {
        params,
        signal: controller.signal,
        onUploadProgress: (progressEvent: AxiosProgressEvent): void => {
          const time = Math.floor(
            progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
          );
          const percent = progressEvent.progress * 100;
          dispatch(onChangeLoadingFiles({ ...file, percent, time }));
        }
      });
      dispatch(onChangeLoadingFiles({ ...file, done: true }));
      checkResponseStatus(data.ok);
      dispatch(onSetAddFiles(data?.file_info));
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

export const onSetSafeList = (payload: ISafe[]): IFetchSafeList => ({ type: SafeTypes.GET_SAFES, payload });
export const onAddNewSafe = (payload: ISafe): IAddNewSafe => ({ type: SafeTypes.ADD_SAFE, payload });
export const onAddAuthorized = (payload: IAuthorized): IAddAuthorized => ({ type: SafeTypes.ADD_AUTHORIZED, payload });
export const onRemoveAuthorized = (payload: string): IRemoveAuthorized => ({
  type: SafeTypes.REMOVE_AUTHORIZED,
  payload
});

export const onClearAuthorized = (): IClearAuthorized => ({ type: SafeTypes.CLEAR_AUTHORIZED });
export const onSafeFolders = (payload: IFolder[]): ISafeFoldersList => ({ type: SafeTypes.SAFE_FOLDERS, payload });
