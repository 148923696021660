import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow-down.svg";
import classNames from "classnames";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { IPostbox } from "models/store/postbox/postbox";
import React, { useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";

import styles from "./SelectMail.module.sass";

interface ISelectMailProps {
  onSelect: (el: IPostbox) => void;
  value: IPostbox;
}

const SelectMail: React.FC<ISelectMailProps> = ({ value, onSelect }) => {
  const { __ } = useLocales();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setIsOpen(false));
  const { postboxes } = usePostboxSelectors();
  const {
    mailNewMessage: { letter }
  } = useGlobalModalsSelectors();
  const isSelect = postboxes.length <= 1 || letter;

  const selectItem = (el: IPostbox): void => {
    onSelect(el);
    setIsOpen(false);
  };
  const handleOpen = () => {
    if (isSelect) return;
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={styles.wrap} ref={ref}>
      <div className={styles.select} onClick={handleOpen}>
        <span className={styles.lable}>{__("От")}</span>
        <span className={styles.name}>{value?.email || ""}</span>
        {!isSelect && (
          <div className={classNames(styles.icon, { [styles.isOpen]: isOpen })}>
            <ArrowIcon />
          </div>
        )}
      </div>
      {isOpen && (
        <ul className={styles.list}>
          {postboxes.map((el) => (
            <li key={el.id} onClick={() => selectItem(el)} className={styles.item}>
              {el.email}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectMail;
