import api from "api";
import Button from "generalComponents/Button/Button";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onEditProject } from "Store/actions/JoinProjectActions";
import { onSetModals } from "Store/actions/ModalActions";
import { projectProps, projectRoleProps } from "types/Project";

import styles from "./CreateRole.module.sass";

const CreateRole = ({ params, type }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const uid = useSelector((s) => s.user.uid);
  const project = useSelector((s) => s.Projects.project);
  const MSG = useProjectMsg();
  const [value, setValue] = useState(type === PROJECT_MODALS.EDIT_ROLE ? params.name : "");
  const [error, setError] = useState("");

  const handleChange = ({ target }) => {
    setError("");
    setValue(target.value);
  };

  const onCreate = async () => {
    try {
      dispatch(onSetModals(MODALS.LOADER, true));

      const payload = new FormData();
      payload.append("uid", uid);
      payload.append("name", value);

      const { data } = await api.post(`/ajax/project_roles_add.php`, payload);
      if (data.error === "name exists") {
        setError(__(" (данная роль уже существет в списке)"));
        return;
      }
      checkResponseStatus(data.ok);
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.PROJECT_SUCCESS,
          callback: PROJECT_MODALS.ADD_ROLES,
          params: {
            title: __("Роль добавленна"),
            text: __(
              "Новая роль успешно добавленна в общий список ролей в будущем она будет отоброжаться вместе со всеми"
            )
          }
        })
      );
    } catch (error) {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: MSG.ERROR
        })
      );
      console.log(error);
    } finally {
      dispatch(onSetModals(MODALS.LOADER, false));
    }
  };

  const onEdit = async () => {
    try {
      dispatch(onSetModals(MODALS.LOADER, true));

      const payload = new FormData();
      payload.append("uid", uid);
      payload.append("name", value);
      payload.append("id_role", params.id);

      const { data } = await api.post(`/ajax/project_roles_edit.php`, payload);
      checkResponseStatus(data.ok);

      const newRoles = [...project.roles];
      const index = newRoles.findIndex((item) => item.id === params.id);
      newRoles.splice(index, 1, { ...params, name: value });
      const paramsData = { ...project, roles: newRoles };
      const messages = { error: MSG.ERROR, success: MSG[PROJECT_MODALS.EDIT_ROLE] };
      dispatch(onEditProject(paramsData, messages, PROJECT_MODALS.PROJECT_SUCCESS));
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    type === PROJECT_MODALS.CREATE_ROLE ? onCreate() : onEdit();
  };

  return (
    <div className={styles.wrap}>
      <span className={styles.subTitle} htmlFor="search">
        {__("Введите имя роли")}
        {error && <span className={styles.error}>{error}</span>}
      </span>
      <input className={styles.input} value={value} onChange={handleChange} />
      <p className={styles.text}>
        {__("Созданная Вами роль добавить в общий список ролей после того как Вы нажмете на кнопку создать")}
      </p>
      <Button type="button" variant="ok" onClick={onSubmit} text={__("Готово")} disabled={!value || error} />
    </div>
  );
};

export default CreateRole;

CreateRole.propTypes = {
  onClose: PropTypes.func,
  params: PropTypes.oneOfType([projectRoleProps, projectProps]),
  type: PropTypes.string
};
