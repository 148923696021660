import api from "api";
import { ReactComponent as PrintIcon } from "assets/icons/print.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { ReactComponent as ImageIcon } from "assets/PrivateCabinet/image.svg";
import classNames from "classnames";
import AudioPlayer from "generalComponents/AudioPlayer/AudioPlayer";
import Button from "generalComponents/Button/Button";
import { previewFormats } from "generalComponents/collections/folders";
import { PdfView } from "generalComponents/Modals/Components/PdfView/PdfView";
import { isSafari } from "generalComponents/Services/browserServices";
import { getMedia } from "generalComponents/Services/mediaServices";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { NO_VALUE, projectSrc } from "generalComponents/variables/globalVariables";
import VideoPlayer from "generalComponents/VideoPlayer/VideoPlayer";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IFile } from "models/store/files/files";
import { useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";

import styles from "./FilePage.module.sass";

const FilePage = (): JSX.Element => {
  const { __ } = useLocales();
  const { fid } = useParams();
  const [file, setFile] = useState<IFile>(null);
  const [audio, setAudio] = useState<string>(NO_VALUE);
  const [video, setVideo] = useState<string>(NO_VALUE);
  const { onSetErrorModal, fetchFileZipLink, fetchPrintFileUrl } = useActions();
  const isPrint: boolean = useMemo(() => {
    if (!file) return false;
    const mType = file.mime_type;
    const isFormat = previewFormats.find((format) => file.ext.toLowerCase().includes(format));
    return !!isFormat || mType === "application/pdf" || mType?.includes("image");
  }, [file]);

  useEffect(() => {
    const controller = new AbortController();
    getFile(controller);

    return () => controller.abort(controller);
  }, []); //eslint-disable-line

  useEffect(() => {
    if (file) {
      if (file?.mime_type && file.mime_type.includes("audio") && file.is_preview) {
        isSafari() ? getMedia(`${projectSrc}${file.preview}`, file.mime_type, setAudio) : setAudio(file.preview);
      }
      if (file?.mime_type && file.mime_type.includes("video") && file.is_preview) {
        isSafari() ? getMedia(`${projectSrc}${file.preview}`, file.mime_type, setVideo) : setVideo(file.preview);
      }
    }
  }, [file]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFile = async (controller: AbortController): Promise<void> => {
    try {
      const params = { fid };
      const { data } = await api.get("/ajax/file_info.php", {
        params,
        signal: controller.signal
      });
      checkResponseStatus(data.ok);
      setFile(data.info);
      setAudio(data.info.preview);
    } catch {
      onSetErrorModal({ open: true, message: __("Не удалось получить информацию о файле") });
    }
  };

  const onDownload = () => {
    fetchFileZipLink(file.fid);
  };

  const onPrint = () => {
    const messages = {
      error: __("Не удалось напечатать файл. Попробуйте еще раз!")
    };
    fetchPrintFileUrl(file, messages);
  };

  const renderView = (): JSX.Element => {
    if (file.mime_type && file.mime_type.startsWith("audio")) {
      return <div className={styles.wrapAudio}>{audio && <AudioPlayer audio={audio} />}</div>;
    }
    if (file.mime_type && file.mime_type.startsWith("video")) {
      return <div className={classNames(styles.wrapVideo)}>{video && <VideoPlayer video={video} />}</div>;
    }
    if (file.mime_type && file.mime_type.startsWith("image")) {
      return (
        <div className={styles.imgContainer}>
          <img src={file.preview} alt="filePrieview" className={styles.image} />
        </div>
      );
    }
    if (file.mime_type && file.mime_type.startsWith("application/pdf")) {
      return (
        <div className={styles.pdfContainer}>
          <PdfView src={`${projectSrc}${file.preview}`} />
        </div>
      );
    }
    return (
      <div className={styles.default}>
        <div>
          <ImageIcon width={293} height={213} fill="#9FAEAB" />
        </div>
        <p className={styles.text}>{__("Файл недоступен к просмотру")}</p>
      </div>
    );
  };

  return (
    file && (
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.info}>
            <span className={styles.name}>{file.name}</span>
            <span className={styles.size}>{file.size_now}</span>
          </div>
          <div className={styles.buttons}>
            {isPrint && (
              <Button
                onClick={onPrint}
                text={__("Печать")}
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.LARGE}
                iconL={<PrintIcon />}
              />
            )}
            <Button
              onClick={onDownload}
              text={__("Скачать")}
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.LARGE}
              iconL={<UploadIcon />}
            />
          </div>
        </div>
        <>{renderView()}</>
      </div>
    )
  );
};

export default FilePage;
