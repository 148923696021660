import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/plus.svg";
import Empty from "containers/Dashboard/Empty/Empty";
import TaskItem from "containers/Dashboard/Tasks/TasksItem/TaskItem";
import Button from "generalComponents/Button/Button";
import { MS_OF_DAY } from "generalComponents/Calendars/CalendarHelper";
import Input from "generalComponents/Input/Input";
import OverdueTasks from "generalComponents/OverdueTasks/OverdueTasks";
import Tabs from "generalComponents/Tabs/Tabs";
import { ROUTES } from "generalComponents/variables/routing";
import { VariantSize } from "generalComponents/variables/size";
import { STATUS_TYPES, TASK_TYPES } from "generalComponents/variables/tasks";
import { useTasks } from "hooks/tasksHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { TASK_DEP } from "models/store/tasks/tasks";
import { IAddTaskPayload } from "models/store/tasks/tasksPayloads";
import { useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { Link } from "react-router-dom";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { dateISO } from "utils/dateToISO";

import styles from "./Tasks.module.sass";

const Tasks = ({ type }: { type: string }) => {
  const { __ } = useLocales();

  const {
    taskModals: { params }
  } = useGlobalModalsSelectors();
  const [active, setActive] = useState<TASK_DEP>(TASK_DEP.WORK);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const { tasks } = useTasks();
  const { onAddNewTask } = useActions();

  const tabs = [
    { name: __("Рабочие"), value: "work", onClick: () => setActive(TASK_DEP.WORK) },
    { name: __("Личные"), value: "personal", onClick: () => setActive(TASK_DEP.PERSONAL) }
  ];
  const filteredTasks = useMemo(
    () =>
      tasks
        .filter((task) => task.id_dep === active && task.id_status !== STATUS_TYPES.DONE)
        .sort((a, b) => {
          const dateA = a.date_end ? new Date(a.date_end) : new Date(a.date_start);
          const dateB = b.date_end ? new Date(b.date_end) : new Date(b.date_start);

          if (!dateA.getTime()) return 1;
          if (!dateB.getTime()) return -1;

          return dateA.getTime() - dateB.getTime();
        }),
    [active, tasks]
  );

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setMessage(value);
  };

  const onSubmit = (): void => {
    const currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + MS_OF_DAY);
    console.log({
      ...params,
      name: message,
      id_dep: active,
      id_type: TASK_TYPES.TASK,
      date_start: dateISO(newDate),
      id_status: STATUS_TYPES.IN_PROGRESS
    });
    onAddNewTask({
      ...params,
      name: message,
      id_dep: active,
      id_type: TASK_TYPES.TASK,
      date_start: dateISO(newDate),
      id_status: STATUS_TYPES.IN_PROGRESS
    } as IAddTaskPayload);
    setMessage("");
    setShowInput(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Link to={ROUTES.TASKS} className={styles.title}>
          {__("Задачи")}
        </Link>
        {filteredTasks && (
          <div className={styles.settings}>
            {type !== "normal" && <OverdueTasks />}
            <Tabs tabs={tabs} value={active} size="small" variant="darkGreen" />
          </div>
        )}
      </div>
      {showInput ? (
        <div className={styles.inputWrapper}>
          <Input
            onChange={handleMessageChange}
            placeholder={__("Имя задачи...")}
            value={message}
            size={VariantSize.LARGE}
          />
          <Button
            onClick={onSubmit}
            disabled={message.length === 0}
            variant={ButtonVariantType.DARK_GREEN}
            size={ButtonSizeType.SMALL}
            text={`${__("Добавить")}`}
          />
        </div>
      ) : (
        <Button
          className={styles.newButton}
          iconL={<PlusIcon />}
          variant={ButtonVariantType.OPACITY}
          size={ButtonSizeType.SMALL}
          text={`${__("Новая задача")}`}
          onClick={() => setShowInput((prev) => !prev)}
        />
      )}
      {filteredTasks.length === 0 ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={styles.scrollWrapper}>
          <div className={styles.list}>
            {filteredTasks.map((item, i) => (
              <TaskItem task={item} key={i} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tasks;
