import { ReactComponent as CommentsIcon } from "assets/icons/comments.svg";
import { ReactComponent as FireIcon } from "assets/icons/fire.svg";
import { ReactComponent as OpenIcon } from "assets/icons/open.svg";
import { ReactComponent as RelatedIcon } from "assets/icons/related.svg";
import classNames from "classnames";
import { useProjectTaskContext } from "collections/joinProject";
import { useContextProjecTrashElement } from "collections/joinProject";
import Avatar from "generalComponents/Avatar/Avatar";
import FileTags from "generalComponents/FileTags/FileTags";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { PROJECT_PATH, ROUTES } from "generalComponents/variables/routing";
import { useFindProjectUser, useTaskPriority } from "hooks/joinProjectHooks";
import { useIsSystemWatherRole } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType } from "models/generalComponents/button";
import { IProjectTask, TASK_PRIORITY } from "models/store/joinProjects/joinProgects";
import React, { FC, useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectsTaskCard.module.sass";

interface IProps {
  item: IProjectTask;
  onHeightChange?: (height: number) => void;
}

const JointProjectsTaskCard: FC<IProps> = ({ item, onHeightChange }) => {
  const { project } = useJoinProjectsSelectors();
  const { taskPriority } = useTaskPriority();
  const { menu, menuBacklog } = useProjectTaskContext();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const executor = useFindProjectUser(item.id_executor);
  const { onSetProjectTaskInfoModal } = useActions();

  const { contextMenuTrashTask } = useContextProjecTrashElement();

  const isSystemWatherRole = useIsSystemWatherRole();

  const cardRef = useRef(null);
  const [searchParams] = useSearchParams();
  const sprint = searchParams.get("sprint");
  useEffect(() => {
    const handleResize = () => {
      if (cardRef.current && onHeightChange) {
        onHeightChange(cardRef.current.clientHeight);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(cardRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []); //eslint-disable-line

  const renderPriority = (priority: IProjectTask["priority"]) => {
    switch (priority) {
      case TASK_PRIORITY.MINOR:
        return taskPriority[TASK_PRIORITY.MINOR];
      case TASK_PRIORITY.NORMAL:
        return taskPriority[TASK_PRIORITY.NORMAL];
      case TASK_PRIORITY.URGENT:
        return (
          <div className={classNames(styles.priority, styles.urgent)}>
            <FireIcon />
            {taskPriority[TASK_PRIORITY.URGENT]}
          </div>
        );
      default:
        return "";
    }
  };

  const renderContextMenu = () => {
    if (!item.is_del) {
      if (item.is_backlog) {
        return menuBacklog(item);
      } else {
        return menu(item);
      }
    } else {
      return contextMenuTrashTask(item);
    }
  };

  const onOpenTask = (e: React.MouseEvent<HTMLDivElement>) => {
    e.detail === 2 && onSetProjectTaskInfoModal({ open: true, task: item });
  };

  const getSprint = (task: IProjectTask): string => {
    if (sprint) return `?sprint=${sprint}`;
    if (task.id_sprints.length === 0) return "";
    return `?sprint=${task.id_sprints[0]}`;
  };

  return (
    <div className={classNames(styles.item, { [styles.active]: showTooltip })} ref={cardRef} onMouseUp={onOpenTask}>
      {item.orders?.[sprint] && item.is_del !== 1 && <div className={styles.number}>{item.orders[sprint]}</div>}
      <div
        className={classNames(styles.descriptions, {
          [styles.margin]: item.orders?.[sprint] && item.is_del !== 1
        })}
      >
        <span className={styles.num}>{item.num}</span>
        <span className={styles.text}>{item.name}</span>
        {(item.tags.length > 0 || item.color || item.emo) && (
          <div className={styles.tags}>
            {item.tags.length > 0 && <FileTags item={item} />}
            {item.color && <div className={styles.color} style={{ background: item.color }} />}
            {item.emo && <span dangerouslySetInnerHTML={{ __html: item.emo }} />}
          </div>
        )}
      </div>

      <div className={styles.information}>
        <div className={styles.leftBlock}>
          {executor && (
            <Avatar
              name={`${executor.fname} ${executor.sname}`}
              imgSrc={`${projectSrc}${executor.icon}`}
              size={24}
              isRound={true}
            />
          )}
          <div className={styles.priority}>{renderPriority(item.priority)}</div>
        </div>
        <div className={styles.icons}>
          {item?.col_links > 0 && (
            <div className={styles.related}>
              <RelatedIcon /> <span>{item?.col_links}</span>
            </div>
          )}
          {item?.col_com > 0 && (
            <div className={styles.comments}>
              <CommentsIcon /> <span>{item.col_com}</span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.hoverIcons} onMouseDown={(e) => e.stopPropagation()}>
        {item.is_del !== 1 && (
          <Link
            to={`/${ROUTES.PROJECT}/${project.id}/${PROJECT_PATH.TASK}/${item.id}${getSprint(item)}`}
            className={styles.openTask}
          >
            <OpenIcon />
          </Link>
        )}
        {!isSystemWatherRole && (
          <PopoverContextMenu
            data={renderContextMenu()}
            iconVariant="button"
            onEnter={() => setShowTooltip(true)}
            onExit={() => setShowTooltip(false)}
            btnSize={ButtonSizeType.SMALL}
          />
        )}
      </div>
    </div>
  );
};

export default JointProjectsTaskCard;
