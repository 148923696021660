/* eslint-disable react/display-name */
import { ReactComponent as InvisibleIcon } from "assets/PrivateCabinet/hiden-eye.svg";
import { ReactComponent as EyeIcon } from "assets/PrivateCabinet/visibility-eye.svg";
import classNames from "classnames";
import { VariantSize } from "generalComponents/variables/size";
import React, {
  Dispatch,
  forwardRef,
  HTMLInputTypeAttribute,
  LegacyRef,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from "react";
import { v4 as uuid } from "uuid";

import styles from "./Input.module.sass";

export enum InputVariantType {
  SELECTED = "selected"
}
interface IInputProps {
  className?: string;
  require?: boolean;
  style?: React.CSSProperties;
  value: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  setValue?: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  label?: string;
  type?: HTMLInputTypeAttribute;
  error?: boolean;
  isPass?: boolean;
  rightIcon?: JSX.Element;
  rightText?: string;
  rightIconClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  errorText?: string;
  name?: string;
  isActive?: boolean;
  size?: VariantSize;
  variant?: InputVariantType;
  open?: boolean;
  autoFocus?: boolean;
}

const Input = forwardRef(
  (
    {
      className,
      require,
      style,
      value,
      onClick,
      onFocus,
      onChange,
      onBlur,
      onKeyUp,
      disabled,
      readOnly,
      placeholder,
      label,
      type = "text",
      error,
      isPass,
      rightIcon,
      rightText,
      rightIconClick,
      errorText,
      name,
      isActive,
      variant,
      size,
      open,
      autoFocus
    }: IInputProps,
    ref?: LegacyRef<HTMLDivElement>
  ): JSX.Element => {
    const id = uuid();
    const [onInputBlur, setOnInputBlur] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const refInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (refInput.current && isActive) refInput.current.focus();
    }, [isActive]);

    const onClickButtonIcon = (): void => setShowPassword((prev) => !prev);
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
      setOnInputBlur(true);
      if (onBlur) onBlur(e);
    };

    const renderIcons = (): JSX.Element => {
      if (isPass) {
        return (
          <button onClick={onClickButtonIcon} className={styles.buttonIcon} type="button">
            {showPassword ? <EyeIcon width={20} height={20} /> : <InvisibleIcon width={20} height={20} />}
          </button>
        );
      }

      if (rightIcon) {
        return (
          <span onClick={rightIconClick} className={styles.buttonIcon}>
            {rightIcon}
          </span>
        );
      }
    };

    return (
      <div
        ref={ref}
        className={classNames(styles.wrapper, {
          [styles.isLabel]: label,
          [styles[variant]]: variant,
          [styles[size]]: size,
          [styles.open]: variant === InputVariantType.SELECTED && open
        })}
      >
        <div className={styles.inputBlock}>
          <input
            ref={refInput}
            className={classNames(className, styles.input, {
              [styles.error]: onInputBlur && error,
              [styles.withIcon]: isPass || rightIcon,
              [styles.isNumberType]: type === "number",
              [styles.password]: !showPassword && isPass,
              [styles[size]]: size
            })}
            style={style}
            value={value}
            onClick={onClick}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={handleInputBlur}
            onKeyUp={onKeyUp}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            type={type}
            id={id}
            name={name}
            autoFocus={autoFocus}
          />
          {renderIcons()}
        </div>
        {label && (
          <label className={styles.label} htmlFor={id}>
            {require && <span className={styles.require}>*</span>} {label}
          </label>
        )}
        {Boolean(rightText) && (
          <div className={classNames(styles.rightText, { [styles.isLabel]: label })}>{rightText}</div>
        )}
        {error && errorText && <p className={styles.errortext}>{errorText}</p>}
      </div>
    );
  }
);

export default Input;
