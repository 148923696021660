import classNames from "classnames";
import { FC, ReactNode } from "react";

import styles from "./ToolsPanel.module.sass";

interface Props {
  size?: "small" | "large" | "extraLarge"; // 48px | 60px | 98px
  children?: ReactNode;
}

const ToolsPanel: FC<Props> = ({ size = "large", children }) => {
  return <div className={classNames(styles.toolsPanel, styles[size])}>{children}</div>;
};

export default ToolsPanel;
