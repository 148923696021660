import PopUp from "generalComponents/PopUp/PopUp";
import { SAFE_MODALS } from "generalComponents/variables/safe";
import { useActions } from "hooks/useActions";
import { initialSafeModalState } from "models/store/Cabinet/modals/modals";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import AddSafe from "./AddSafe/AddSafe";
import ChangeSafePassword from "./ChangeSafePassword/ChangeSafePassword";
import CreateSafeFolder from "./CreateSafeFolder/CreateSafeFolder";
import EnterSafeCode from "./EnterSafeCode/EnterSafeCode";
import EnterSafePassword from "./EnterSafePassword/EnterSafePassword";
import PropertiesSafe from "./PropertiesSafe/PropertiesSafe";
import RenameSafe from "./RenameSafe/RenameSafe";
import SafeBlocked from "./SafeBlocked/SafeBlocked";
import SharedSafe from "./SharedSafe/SharedSafe";

const SafeModals = () => {
  const { onSetSafeModal } = useActions();
  const {
    safeModals: { type, safe, callbackType, alert, folder }
  } = useGlobalModalsSelectors();

  const closeModal = (): void => {
    onSetSafeModal(initialSafeModalState());
  };
  return (
    <PopUp set={closeModal}>
      {(type === SAFE_MODALS.ADD_SAFE || type === SAFE_MODALS.ADD_FIRST_SAFE) && (
        <AddSafe onClose={closeModal} type={type} />
      )}
      {type === SAFE_MODALS.RENAME_SAFE && <RenameSafe onClose={closeModal} safe={safe} />}
      {type === SAFE_MODALS.SHARED_SAFE && <SharedSafe onClose={closeModal} safe={safe} />}

      {type === SAFE_MODALS.ENTER_SAFE_PASSWORD && (
        <EnterSafePassword onClose={closeModal} safe={safe} callbackType={callbackType} alert={alert} />
      )}
      {type === SAFE_MODALS.ENTER_SAFE_CODE && (
        <EnterSafeCode onClose={closeModal} safe={safe} callbackType={callbackType} />
      )}
      {type === SAFE_MODALS.SAFE_BLOKED && <SafeBlocked safe={safe} />}
      {type === SAFE_MODALS.CHANGE_PASSWORD && <ChangeSafePassword onClose={closeModal} safe={safe} />}
      {type === SAFE_MODALS.PROPERTIES && <PropertiesSafe onClose={closeModal} safe={safe} />}
      {(type === SAFE_MODALS.CREATE_FOLDER || type === SAFE_MODALS.RENAME_FOLDER) && (
        <CreateSafeFolder onClose={closeModal} folder={folder} type={type} />
      )}
    </PopUp>
  );
};

export default SafeModals;
