import { ReactComponent as ReminderIcon } from "assets/PrivateCabinet/bell.svg";
import { ReactComponent as TaskIcon } from "assets/PrivateCabinet/calendar-4.svg";
import { ReactComponent as MailIcon } from "assets/PrivateCabinet/mail/unread.svg";
import { ReactComponent as MeetingIcon } from "assets/PrivateCabinet/meeting.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-5.svg";
import classNames from "classnames";
import { TASK_MODALS, TASK_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { IMyTask } from "models/store/tasks/tasks";
import { useState } from "react";
import { convertToRGBA } from "utils/convertToRGBA";

import styles from "./CalenderItem.module.sass";

const CalenderItem = ({ task }: { task: IMyTask }) => {
  const { formatDate } = useDateFormat();
  const { onSetTaskModal } = useActions();
  const [isHovered, setIsHovered] = useState(false);

  const iconColorStyle = task.color ? { fill: task.color } : {};
  const textColorStyle = task.color ? { color: task.color } : {};
  const containerStyle = task.color ? { background: convertToRGBA(task.color, isHovered ? 20 : 10) } : {};
  const borderStyle = task.color ? { background: task.color } : {};

  const renderIcon = () => {
    switch (task.id_type) {
      case TASK_TYPES.MAILS:
        return <MailIcon style={iconColorStyle} />;
      case TASK_TYPES.CALLS:
        return <PhoneIcon style={iconColorStyle} />;
      case TASK_TYPES.ONLINE_MEETING:
      case TASK_TYPES.OFFLINE_MEETING:
      case TASK_TYPES.MEETINGS:
        return <MeetingIcon style={iconColorStyle} />;
      case TASK_TYPES.REMINDER:
        return <ReminderIcon width={12} style={iconColorStyle} />;
      default:
        return <TaskIcon style={iconColorStyle} />;
    }
  };

  return (
    <div
      className={classNames(styles.container, styles[`container_${task.id_type}`])}
      style={containerStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        onSetTaskModal({
          type: TASK_MODALS.OPEN_TASK,
          params: task
        });
      }}
    >
      <div className={styles.border} style={borderStyle} />
      <div className={styles.info} style={textColorStyle}>
        <span className={styles.title}>
          {renderIcon()}
          {task.name}
        </span>
        <span className={styles.time}>{formatDate(task.date_start, "time")}</span>
      </div>
      <div className={styles.info} style={textColorStyle}>
        <span className={styles.text} dangerouslySetInnerHTML={{ __html: task.prim }} />
        {task.date_end && <span className={styles.time}>{formatDate(task.date_end, "time")}</span>}
      </div>
    </div>
  );
};

export default CalenderItem;
