import { endOfDay, endOfMonth, endOfWeek, getTime, startOfDay, startOfMonth, startOfWeek } from "date-fns";
import { MS_OF_DAY } from "generalComponents/Calendars/CalendarHelper";
import { EMOJIS } from "generalComponents/variables/elements";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { STATUS_TYPES, TASK_TYPES, URGENCY_TYPES } from "generalComponents/variables/tasks";
import { IDateObject } from "models/generalComponents/calendars";
import { IMyTask, TASK_DEP } from "models/store/tasks/tasks";
import { IAddTaskPayload } from "models/store/tasks/tasksPayloads";
import { dateISO } from "utils/dateToISO";

export const taskOfPeriod = (tasks: IMyTask[], periodStart: number, periodEnd: number): IMyTask[] => {
  return tasks.filter((t) => {
    const taskStart = getTime(startOfDay(new Date(t.date_start)));
    const taskEnd = getTime(endOfDay(new Date(t.date_end)));

    return (
      (taskStart < periodEnd && taskStart >= periodStart) ||
      (taskEnd <= periodEnd && taskEnd > periodStart) ||
      (taskEnd >= periodEnd && taskStart <= periodStart)
    );
  });
};

export const groupByDay = (tasks: IMyTask[], date: IDateObject): Record<number, IMyTask[]> => {
  const time = getTime(new Date(date.year, date.month, date.day));
  const periodStart = getTime(startOfDay(new Date(time)));
  const periodEnd = getTime(endOfDay(new Date(time)));
  return { [time]: taskOfPeriod(tasks, periodStart, periodEnd) };
};

export const groupByWeek = (tasks: IMyTask[], date: IDateObject): Record<number, IMyTask[]> => {
  const obj: Record<number, IMyTask[]> = {};
  const time = getTime(new Date(date.year, date.month, date.day));
  const periodStart = getTime(startOfWeek(new Date(time), { weekStartsOn: 1 }));
  const periodEnd = getTime(endOfWeek(new Date(time), { weekStartsOn: 1 }));
  obj[time] = taskOfPeriod(tasks, periodStart, periodEnd);
  return obj;
};

export const groupByMonth = (tasks: IMyTask[], date: IDateObject): Record<number, IMyTask[]> => {
  const startMonth = getTime(startOfMonth(new Date(date.year, date.month, date.day)));
  const endMonth = getTime(endOfMonth(new Date(date.year, date.month, date.day)));
  const obj: Record<number, IMyTask[]> = {};
  for (let i = startMonth; i < endMonth + MS_OF_DAY * 5; i += MS_OF_DAY * 7) {
    if (i > endMonth) {
      const startLastWeek = getTime(startOfWeek(new Date(i), { weekStartsOn: 1 }));
      if (startLastWeek < endMonth) {
        obj[endMonth] = taskOfPeriod(tasks, startLastWeek, endMonth);
      }
    } else {
      const endtWeek = getTime(endOfWeek(new Date(i), { weekStartsOn: 1 }));
      const startWeek = getTime(startOfWeek(new Date(i), { weekStartsOn: 1 }));
      if (endtWeek >= endMonth) {
        obj[endMonth] = taskOfPeriod(tasks, startWeek, endMonth);
      } else if (startWeek < startMonth) {
        obj[i] = taskOfPeriod(tasks, startMonth, endtWeek);
      } else {
        obj[i] = taskOfPeriod(tasks, startWeek, endtWeek);
      }
    }
  }
  return obj;
};

export const groupByYear = (tasks: IMyTask[], date: IDateObject): Record<number, IMyTask[]> => {
  const obj: Record<number, IMyTask[]> = {};
  for (let i = 0; i < 12; i++) {
    const startMonth = getTime(startOfMonth(new Date(date.year, i)));
    const endMonth = getTime(endOfMonth(new Date(date.year, i)));
    obj[startMonth] = taskOfPeriod(tasks, startMonth, endMonth);
  }
  return obj;
};

export const INITIAL_ADD_TASK: IAddTaskPayload = {
  name: NO_VALUE,
  id_type: TASK_TYPES.NOTES,
  id_dep: TASK_DEP.NO_DEP,
  prim: NO_VALUE,
  date_start: NO_VALUE,
  date_end: NO_VALUE,
  time_start: NO_VALUE,
  time_end: NO_VALUE,
  color: NO_VALUE,
  emo: EMOJIS.NO_EMOJI,
  id_act: URGENCY_TYPES.PLANNED,
  emails: NO_VALUE,
  tags: [],
  pass: NO_VALUE,
  id_status: STATUS_TYPES.IN_PROGRESS
};

export const taskOfStatus = (status: STATUS_TYPES, tasks: IMyTask[]): IMyTask[] => {
  return tasks.filter((t) => t.id_status === status);
};

export const groupAllTasksByDate = (tasks: IMyTask[]): Record<string, IMyTask[]> => {
  const groupedTasks: Record<string, IMyTask[]> = {};

  tasks.forEach((task) => {
    const taskDate = new Date(task.date_end || task.date_start);
    const taskDateWithoutTime = new Date(taskDate.getFullYear(), taskDate.getMonth(), taskDate.getDate());

    const key = dateISO(taskDateWithoutTime);
    if (!groupedTasks[key]) {
      groupedTasks[key] = [];
    }

    groupedTasks[key].push(task);
  });

  return groupedTasks;
};
