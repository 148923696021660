import { ReactComponent as FilterIcon } from "assets/PrivateCabinet/filter.svg";
import classNames from "classnames";
import { MarksContent } from "generalComponents/MarksContent/MarksContent";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useActions } from "hooks/useActions";
import { useIsFiltersMarkers } from "hooks/useIsFilters";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { ButtonSizeType } from "models/generalComponents/button";
import { FC, ReactNode, useEffect, useState } from "react";
import { useFilterCriteriaSelector } from "Store/selectors/filterCriteriaSelectors";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./FilterMarkers.module.sass";

interface IFilterMarkersProps {
  hide?: boolean;
  showColors?: boolean;
  showEmojis?: boolean;
  showTags?: boolean;
  depFilter?: string;
  children?: ReactNode;
}

export const FilterMarkers: FC<IFilterMarkersProps> = ({
  depFilter,
  children,
  hide,
  showColors,
  showEmojis,
  showTags
}) => {
  const [show, setShow] = useState<boolean>();

  const { onAddFilterCriteria } = useActions();

  const { filterCriteria } = useFilterCriteriaSelector();

  const { selectFile } = useGlobalModalsSelectors();

  const { color, tags, emo } = useAllSelectedSearchParams();

  const onSelectTag = (el: string): void => {
    onAddFilterCriteria({
      ...filterCriteria,
      tags: filterCriteria.tags.includes(el)
        ? filterCriteria.tags.filter((tag) => tag !== el)
        : [...filterCriteria.tags, el]
    });
  };

  const onSelectColor = (el: string): void => {
    onAddFilterCriteria({
      ...filterCriteria,
      color: filterCriteria.color === el ? "" : el
    });
  };

  const onSelectEmo = (el: string): void => {
    onAddFilterCriteria({
      ...filterCriteria,
      emo: filterCriteria.emo === el ? "" : el
    });
  };

  const isFiltersMarkers = useIsFiltersMarkers();

  const isSelectedFilter: boolean = !selectFile.open
    ? isFiltersMarkers || Boolean(depFilter)
    : Object.values(filterCriteria).some((filter) => filter.length);

  useEffect(() => {
    if (hide) {
      setShow(false);
    }
  }, [hide]);

  return (
    <div
      className={classNames(styles.btnWrapp, {
        [styles.isSelected]: isSelectedFilter
      })}
    >
      <PopoverOverlay
        overlayContent={
          <MarksContent
            tags={!selectFile.open ? tags.selectedValue : filterCriteria.tags}
            onSetTag={!selectFile.open ? tags.onSelectMultipleValue : onSelectTag}
            color={!selectFile.open ? color.selectedValue : filterCriteria.color}
            onSetColor={!selectFile.open ? color.onSelectValue : onSelectColor}
            emoji={!selectFile.open ? emo.selectedValue : filterCriteria.emo}
            onSetEmoji={!selectFile.open ? emo.onSelectValue : onSelectEmo}
            onSetShow={setShow}
            showColors={showColors}
            showEmojis={showEmojis}
            showTags={showTags}
          >
            {children}
          </MarksContent>
        }
        classes={{ popoverBody: styles.nopadding }}
        show={show}
        setShow={setShow}
        btn="custom"
        iconLBtn={<FilterIcon fill="#274A42" />}
        sizeBtn={ButtonSizeType.SMALL}
        visibleHeader={false}
        width={442}
      />
    </div>
  );
};
