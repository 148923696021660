import { ReactComponent as ContactsIcon } from "assets/icons/contacts_2.svg";
import { ReactComponent as TeamIcon } from "assets/icons/group-team.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail_2.svg";
import { ReactComponent as NoteIcon } from "assets/icons/note.svg";
import { ReactComponent as UsersIcon } from "assets/icons/person_2.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as BookIcon } from "assets/PrivateCabinet/book.svg";
import { ReactComponent as CalendarIcon } from "assets/PrivateCabinet/calendar-5.svg";
import { ReactComponent as ToolIcon } from "assets/PrivateCabinet/folder.svg";
import { ReactComponent as RocketIcon } from "assets/PrivateCabinet/rocket.svg";
import { ReactComponent as ZipIcon } from "assets/PrivateCabinet/safe.svg";
import { ReactComponent as SafeIcon } from "assets/PrivateCabinet/safeColored.svg";
import { useLocales } from "react-localized";

export const useSearchData = () => {
  const { __ } = useLocales();

  const times = [
    {
      title: __("Последние 24ч"),
      value: "1"
    },
    {
      title: __("На этой неделе"),
      value: "7"
    },
    {
      title: __("В этом месяце"),
      value: "30"
    },
    {
      title: __("В этом году"),
      value: "365"
    }
  ];

  const tools = [
    {
      title: __("Папки"),
      icon: <ToolIcon />,
      value: "folders"
    },
    {
      title: __("Сейф"),
      icon: <SafeIcon />,
      value: "safe"
    },
    {
      title: __("Заметки"),
      icon: <NoteIcon />,
      value: "notes"
    },
    {
      title: __("Совместный проект"),
      icon: <TeamIcon fill="#2FB292" />,
      value: "project"
    },
    {
      title: __("Календарь"),
      icon: <CalendarIcon />,
      value: "calendar"
    },
    {
      title: __("Почта"),
      icon: <MailIcon fill="#1565C0" />,
      value: "email"
    },
    {
      title: __("Программы"),
      icon: <RocketIcon />,
      value: "soft"
    },
    {
      title: __("Библиотека"),
      icon: <BookIcon />,
      value: "library"
    },
    {
      title: __("Пользователи"),
      icon: <UsersIcon />,
      value: "users"
    },
    {
      title: __("Контакты"),
      icon: <ContactsIcon />,
      value: "contacts"
    },
    {
      title: __("Архив"),
      icon: <ZipIcon fill="#E4C500" />,
      value: "is_archive"
    },
    {
      title: __("Корзина"),
      icon: <TrashIcon fill="#BB7B42" />,
      value: "is_del"
    }
  ];

  return { times, tools };
};
