import classNames from "classnames";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./DashboardItem.module.sass";

type DashboardItemProps = {
  item: IFile | IFolder;
  type?: string;
  isClickable?: boolean;
};

export const isIFile = (item: IFile | IFolder): item is IFile => {
  return (item as IFile)?.mime_type !== undefined;
};

const DashboardItem = ({ item, type, isClickable = true }: DashboardItemProps) => {
  const { pathname } = useLocation();
  const { onFileView } = useActions();
  const navigate = useNavigate();

  const onOpenFile = (): void => {
    if (isIFile(item)) {
      onFileView({ open: true, params: item });
    }
  };

  const onClickFolder = (): void => {
    navigate(`/${ROUTES.FOLDERS}/${item.id_dir}`, { state: { from: pathname } });
  };

  return (
    <div
      className={classNames(styles.container, styles[`container_${type}`])}
      onDoubleClick={isClickable ? (isIFile(item) ? onOpenFile : onClickFolder) : undefined}
    >
      {isIFile(item) && item.mime_type && item.mime_type.startsWith("image") ? (
        <img src={item.preview} alt={item.name} className={styles.image} />
      ) : (
        <ItemIcon
          width={type === "normal" ? 24 : isIFile(item) ? 44 : 54}
          height={type === "normal" ? 24 : 54}
          color={item.color}
          ext={isIFile(item) ? item.ext : undefined}
          passIcon={isIFile(item) && item.is_pass === 1}
          isFolder={!isIFile(item)}
        />
      )}
      <div className={styles.title}>{item.name}</div>
    </div>
  );
};

export default DashboardItem;
