import { ReactComponent as AddFolderNewIcon } from "assets/PrivateCabinet/add_folder-new.svg";
import { ReactComponent as FolderIcon } from "assets/PrivateCabinet/folder.svg";
import { colors } from "generalComponents/collections/elements";
import { useSystemFolder } from "generalComponents/collections/folder";
import ContextMenu from "generalComponents/ContextMenu";
import ContextMenuItem from "generalComponents/ContextMenu/ContextMenuItem";
import FileServicePanel from "generalComponents/FilesServicePanel/FilesServicePanel";
import FolderItem from "generalComponents/FolderItem/FolderItem";
import List from "generalComponents/List/List";
import { useFolderPath } from "generalComponents/Services/folderServices/folderServices";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_CONTEXT_MENU } from "generalComponents/variables/project";
import { useActions } from "hooks/useActions";
import React, { useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";

import { useContextFolder, useProjectMsg } from "../helpers";
import styles from "./ProjectFiles.module.sass";

const ProjectFiles = () => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const contextMenu = useContextFolder();
  const { systemFolders, otherFolders, currentFolder, folderPath } = useFoldersSelectors();
  const FOLDERS = useSystemFolder();
  const getFolderPath = useFolderPath();

  const { onSendFolderPath, onSelectFolder } = useActions();

  const MSG = useProjectMsg();

  const [listCollapsed, setListCollapsed] = useState(false);
  const [mouseParams, setMouseParams] = useState(null);
  const [filePick, setFilePick] = useState({
    show: false,
    files: []
  });

  const { getProjectFolders, onNullifyFolders } = useActions();

  useEffect(() => {
    getProjectFolders(MSG.ERROR);
    return () => {
      onNullifyFolders();
    };
  }, []); //eslint-disable-line

  const folders = useMemo(() => {
    return [...systemFolders, ...otherFolders];
  }, [otherFolders, systemFolders]);

  const onCreateFolder = () =>
    dispatch(
      onSetModals(MODALS.CREATE_FOLDER, {
        open: true,
        folder: null,
        type: "add"
      })
    );
  const closeContextMenu = () => setMouseParams(null);

  const filteredContextMenu = useMemo(() => {
    if (currentFolder?.is_system === "1") {
      return contextMenu.filter((item) => {
        return item.type === PROJECT_CONTEXT_MENU.ADD_FOLDER;
      });
    }
    return contextMenu;
  }, [currentFolder?.is_system]); //eslint-disable-line

  const callbacks = {
    [PROJECT_CONTEXT_MENU.ADD_FOLDER]: () =>
      dispatch(
        onSetModals(MODALS.CREATE_FOLDER, {
          open: true,
          folder: currentFolder,
          type: "add"
        })
      ),

    [PROJECT_CONTEXT_MENU.EDIT_FOLDER]: () =>
      dispatch(
        onSetModals(MODALS.CREATE_FOLDER, {
          open: true,
          folder: currentFolder,
          type: "edit"
        })
      ),

    [PROJECT_CONTEXT_MENU.DEL_FOLDER]: () => {
      const params = {
        id_dir: currentFolder.id_dir,
        is_del: "1"
      };

      const messages = {
        error: "error",
        success: "success"
      };
      dispatch(
        onSetModals(MODALS.APPROVE, {
          open: true,
          params: {
            title: __("Удаление папки"),
            text: __("Вы действительно хотите удалить выбранную папку?"),
            children: <FolderIcon className="grey" width={"100px"} height="100px" />,
            approveBtn: __("Удалить"),
            callback: () => console.log(params, messages)
          }
        })
      );
    }
  };

  const chooseSeveral = () => {
    setFilePick((prev) => {
      return prev.show ? { show: false, files: [] } : { show: true, files: [] };
    });
  };

  const handleSelectFolder = (e, folder) => {
    e.stopPropagation();
    onSelectFolder(folder);
    onSendFolderPath(getFolderPath(folder));
  };

  const renderFolderPath = () => {
    return (
      <>
        {folderPath.map((f, i) => (
          <div key={f.id_dir} className={styles.pathItem}>
            {i > 0 && <span>/</span>}
            <div className={styles.pathInfo} onClick={(e) => handleSelectFolder(e, f)}>
              {f.is_system === "1" ? (
                FOLDERS[f.name]?.icon
              ) : (
                <FolderIcon
                  className={`${styles.innerFolderIcon} ${colors.find((el) => el.color === f.color)?.name}`}
                />
              )}
              <span className={styles.name}>{f.is_system === "1" ? FOLDERS[f.name]?.nameRu ?? f.name : f.name}</span>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {folders.length > 0 && (
        <div className={styles.listWrap}>
          <FileServicePanel chooseSeveral={chooseSeveral} filePick={filePick} />
          <div className={styles.path}>{renderFolderPath()}</div>

          <div className={styles.workSpace}>
            <List
              title={__("Папки")}
              icon={<AddFolderNewIcon />}
              listCollapsed={listCollapsed}
              setListCollapsed={setListCollapsed}
              onCreate={onCreateFolder}
            >
              <ul className={styles.foldersList}>
                {folders.map((folder) => (
                  <FolderItem
                    key={folder.id_dir}
                    folder={folder}
                    setMouseParams={setMouseParams}
                    listCollapsed={listCollapsed}
                    handleSelectFolder={handleSelectFolder}
                    // TODO - mk - add context menu
                    filteredContextMenu={() => {}}
                    contextMenu={[]}
                  />
                ))}
              </ul>
            </List>
            {/* TODO add files list of project */}
          </div>

          {mouseParams && (
            <ContextMenu params={mouseParams} setParams={closeContextMenu} tooltip={true}>
              <div>
                {filteredContextMenu.map((item, i) => (
                  <ContextMenuItem
                    key={i}
                    width={mouseParams.width}
                    height={mouseParams.height}
                    text={item.name}
                    callback={callbacks[item.type]}
                    icon={item.img}
                  />
                ))}
              </div>
            </ContextMenu>
          )}
        </div>
      )}
    </>
  );
};

export default ProjectFiles;
