// FileLoader
import { useLocales } from "react-localized";

export const loadDest = {
  myFolders: "",
  safe: "safe_",
  myFiles: "",
  project: "project_"
};

export function usePeriods() {
  const { __ } = useLocales();
  return {
    1: __("Сегодня"),
    7: __("На этой неделе"),
    14: __("Более недели"),
    30: __("В этом месяце"),
    60: __("В прошлом месяце"),
    365: __("В этом году"),
    999: __("Папки")
  };
}
