import DownArrow from "generalComponents/DownArrow/DownArrow";
import ProjectTask from "Pages/Cabinet/Components/Project/Components/ProjectTask/ProjectTask";
import UserBlock from "Pages/Cabinet/Components/Project/Components/UserBlock/UserBlock";
import { getUser } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useSelector } from "react-redux";
import { colorCategoryProps, taskTypes } from "types/Project";

import { TasksProvider } from "../Tasks";
import styles from "./UserTaskList.module.sass";

const UserTaskList = ({ tasks, userId, color, variant }) => {
  const { __ } = useLocales();
  const project = useSelector((s) => s.Projects.project);
  const [isOpenTasks, setIsOpenTasks] = useState(false);
  const [isOpenUser, setIsOpenUser] = useState(false);
  const { isOpenAll } = useContext(TasksProvider);

  useEffect(() => {
    setIsOpenUser(isOpenAll);
    setIsOpenTasks(isOpenAll);
  }, [isOpenAll]);

  const toggleOpenTasks = () => setIsOpenTasks((open) => !open);
  const toggleOpenUser = () => setIsOpenUser((open) => !open);

  const getStyleTaskCard = {
    backgroundColor: color.secondary,
    borderColor: color.dark
  };
  return (
    <div className={styles.block}>
      <div className={styles.userBox}>
        {userId !== "0" ? <UserBlock user={getUser(project.users, userId)} variant="light" /> : __("Без исполнителя")}
        <DownArrow clickHandler={toggleOpenUser} isOpen={isOpenUser} />
      </div>
      {isOpenUser && (
        <>
          {isOpenTasks ? (
            <>
              {tasks.length > 1 && (
                <div className={styles.arrowBoxTop} style={getStyleTaskCard}>
                  <DownArrow clickHandler={toggleOpenTasks} isOpen={isOpenTasks} />
                </div>
              )}
              <div className={styles.blocks}>
                {tasks.map((t, i) => (
                  <div key={i} className={styles.taskBox}>
                    <ProjectTask task={t} color={color} variant={variant} />
                  </div>
                ))}
              </div>
              {tasks.length > 1 && (
                <div className={styles.arrowBoxBottom} style={getStyleTaskCard}>
                  <DownArrow clickHandler={toggleOpenTasks} isOpen={isOpenTasks} />
                </div>
              )}
            </>
          ) : (
            <>
              <ProjectTask task={tasks[0]} color={color} variant={variant} />
              {tasks.length > 1 && (
                <div className={styles.arrowBoxBottom} style={getStyleTaskCard} onClick={toggleOpenTasks}>
                  +&nbsp;{tasks.length - 1}&nbsp;{__("Задач")}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UserTaskList;

UserTaskList.propTypes = {
  tasks: PropTypes.arrayOf(taskTypes),
  userId: PropTypes.string,
  color: colorCategoryProps,
  variant: PropTypes.string
};
