import Loader from "generalComponents/Loaders/4HUB";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { ROUTES } from "generalComponents/variables/routing";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { LoaderTypes } from "models/generalComponents/loader";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useFilterCriteriaSelector } from "Store/selectors/filterCriteriaSelectors";

import RenderColumns from "../RenderColumns/RenderColumns";
import styles from "./LinesPreviewColumn.module.sass";

export interface ILinesPreviewColumnProps {
  idDir: string;
}

const LinesPreviewColumn: React.FC<ILinesPreviewColumnProps> = ({ idDir }): JSX.Element => {
  const { __ } = useLocales();
  const { filesNext } = useFilesSelectors();
  const {
    objSearchParams: { group, sort, sort_reverse }
  } = useSelectedObjSearchParams();

  const {
    filterCriteria: { color, emo, figure, tags }
  } = useFilterCriteriaSelector();

  const { onGetMyFilesLinePreview, onGetSafeFilesLinePreview } = useActions();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const authorizedSafe = useAuthorizedSafe();

  const { ref, inView } = useInView({
    threshold: 0.5
  });

  const stopLoader = (): void => setLoading(false);

  useEffect(() => {
    if (page > 1) {
      setLoading(true);
      if (isSafePage) {
        onGetSafeFilesLinePreview(
          { code: authorizedSafe.code, id_safe: authorizedSafe.id_safe, id_dir: idDir, page },
          __("Не удалось получить список файлов, попробуйте еще раз"),
          stopLoader
        );
        return;
      }
      onGetMyFilesLinePreview(
        { id_dir: idDir, page },
        __("Не удалось получить список файлов, попробуйте еще раз"),
        stopLoader
      );
    }
  }, [page]); // eslint-disable-line

  useEffect(() => {
    setLoading(true);
    setPage(1);
    if (isSafePage) {
      onGetSafeFilesLinePreview(
        { code: authorizedSafe.code, id_safe: authorizedSafe.id_safe, id_dir: idDir, page: 1 },
        __("Не удалось получить список файлов, попробуйте еще раз"),
        stopLoader
      );
      return;
    }
    onGetMyFilesLinePreview(
      { id_dir: idDir, page: 1 },
      __("Не удалось получить список файлов, попробуйте еще раз"),
      stopLoader
    );
  }, [group, sort, sort_reverse, color, emo, figure, tags]); // eslint-disable-line

  useEffect(() => {
    if (!filesNext?.[idDir] || Math.ceil(filesNext?.[idDir].total / PER_PAGE) <= page) return;
    if (inView) {
      setPage((prev) => prev + 1);
    }
  }, [inView]); // eslint-disable-line

  return (
    <>
      <RenderColumns items={filesNext?.[idDir]?.files} />
      {!loading ? (
        <div ref={ref} className={styles.observer} />
      ) : (
        <div className={styles.loaderBox} style={{ position: "relative" }}>
          <Loader
            type={LoaderTypes.BOUNCING_DOTS}
            position="absolute"
            background="white"
            zIndex={5}
            width="100px"
            height="100px"
            containerType="bounceDots"
          />
        </div>
      )}
    </>
  );
};

export default LinesPreviewColumn;
