import { IProjectTask, TASK_DEPENDENCIES } from "models/store/joinProjects/joinProgects";

export interface ITasksConnectionModalState {
  open: boolean;
  task: IProjectTask;
  type1: TASK_DEPENDENCIES;
  type2: TASK_DEPENDENCIES;
}

export function initialTasksConnectionState(): ITasksConnectionModalState {
  return { open: false, task: null, type1: undefined, type2: undefined };
}
