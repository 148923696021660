import { IProjectRole } from "models/store/joinProjects/joinProgects";

export interface ICreateProjectRoleModal {
  open: boolean;
  type: "add" | "edit";
  role: IProjectRole;
}

export function initialCreateProjectRoleState(): ICreateProjectRoleModal {
  return { open: false, type: "add", role: null };
}
