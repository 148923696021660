import { ReactComponent as OpenIcon } from "assets/PrivateCabinet/open-all.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/plus-3.svg";
import classNames from "classnames";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import React, { useContext, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onEditProject } from "Store/actions/JoinProjectActions";
import { ProjectTypes } from "Store/types/projectTypes";

import { onSetModals } from "../../../../../../../Store/actions/ModalActions";
import TaskFilter from "../../../Components/TaskFilter/TaskFilter";
import { useProjectMsg } from "../../../helpers";
import Category from "../Category/Category";
import { TasksProvider } from "../Tasks";
import styles from "./TasksKanban.module.sass";

const TasksKanban = () => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const MSG = useProjectMsg();

  const project = useSelector((s) => s.Projects.project);
  const [currentTask, setCurrentTask] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [dragEnterId, setDragEnterId] = useState(0);

  const { toggleOpen, isOpenAll } = useContext(TasksProvider);

  const onAddCategory = () =>
    dispatch(
      onSetModals(MODALS.PROJECT, {
        type: PROJECT_MODALS.ADD_CATEGORY,
        params: {}
      })
    );

  const dragStart = (item) => {
    setCurrentCategory(item);
  };

  const dragEnd = () => {
    setCurrentCategory(null);
    setDragEnterId(0);
  };

  const dragEnter = (item) => {
    const targetIndex = project.tasks_category.indexOf(item);
    const currentIndex = project.tasks_category.indexOf(currentCategory);
    if (targetIndex + 1 === currentIndex) {
      return;
    }

    if (currentTask === null) {
      setDragEnterId(item.id);
    }
  };

  const dropHandler = (item) => {
    setDragEnterId(0);
    setCurrentCategory(null);
    if (item.id === currentCategory.id || currentTask) {
      return;
    }

    const list = [...project.tasks_category];
    const indexTarget = list.indexOf(item);
    if (item.id === currentCategory.id || currentTask) {
      return;
    }
    const indexCurrent = list.indexOf(currentCategory);
    list.splice(indexCurrent, 1);
    indexTarget < indexCurrent
      ? list.splice(indexTarget + 1, 0, currentCategory)
      : list.splice(indexTarget, 0, currentCategory);
    dispatch({ type: ProjectTypes.MOVE_CATEGORY, payload: list });
    const sortCategories = list.map((el) => el.id);

    const params = {
      ...project,
      tasks_category: list,
      tasks_category_sort: sortCategories
    };
    const messages = {
      error: MSG.ERROR,
      success: __("Порядок категорий изменен")
    };
    dispatch(onEditProject(params, messages));
  };

  return (
    <div className={classNames(styles.wrap)}>
      {project.tasks_category.map((item, index) => (
        <div
          key={item.id}
          className={classNames(styles.container, {
            [styles.opacity]: currentCategory?.id === item.id && !currentTask,
            [styles.start]: currentCategory && !currentTask,
            [styles.dragEnter]: dragEnterId === item.id && !currentTask && currentCategory?.id !== item.id
          })}
          draggable={index > 0 && !currentTask}
          onDragStart={(e) => dragStart(item, e)}
          onDragEnd={dragEnd}
          onDragOver={() => dragEnter(item)}
          onDrop={(e) => dropHandler(item, e)}
        >
          <Category
            category={item}
            currentTask={currentTask}
            setCurrentTask={setCurrentTask}
            setCurrentCategory={setCurrentCategory}
            variant="column"
          />
        </div>
      ))}
      <div className={styles.stikyBox}>
        {Number(project.is_read) > 0 && (
          <ButtonIcon handleClick={onAddCategory} icon={<PlusIcon width={16} />} variant="grey" />
        )}
        <TaskFilter />
        <ButtonIcon handleClick={toggleOpen} icon={<OpenIcon />} isActive={isOpenAll} variant="grey" />
      </div>
    </div>
  );
};

export default TasksKanban;
