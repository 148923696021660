export interface IProgramsCategory {
  id: string;
  id_user: string;
  name: string;
  date: string;
  pass: string;
  tags: any;
  id_emo: any;
  id_color: any;
  id_fig: any;
  is_del: string;
  id_parent: string;
  count: number;
  icon: string;
}

export interface IProgram {
  id: string;
  id_user: string;
  name: string;
  icon: string;
  date: any;
  pass: any;
  tags: any;
  id_emo: any;
  id_color: any;
  id_fig: any;
  is_del: string;
  id_dep: string;
  link: string;
  linkLabel: string;
  is_favorite: number;
}

export interface IProgramsState {
  isSearchProgramsLoading: boolean;
  isGetProgramsByCatLoading: boolean;
  searchedPrograms: IProgram[];
  categories: IProgramsCategory[]; // should find server response
  programs: IProgram[];
}

export function initialProgramsState(): IProgramsState {
  return {
    isSearchProgramsLoading: false,
    isGetProgramsByCatLoading: false,
    searchedPrograms: [],
    categories: [],
    programs: []
  };
}
