import { ReactComponent as DotsIcon } from "assets/PrivateCabinet/dots-vertical-round.svg";
import classNames from "classnames";
import { useContextFile } from "collections/myFiles";
import { DefaultButtonIcon } from "generalComponents/DefaultButtonIcon/DefaultButtonIcon";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { useActions } from "hooks/useActions";
import { IRecentFileProps } from "models/workElements/recentFile";
import React, { useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";

import styles from "./RecentFile.module.sass";

const RecentFile: React.FC<IRecentFileProps> = ({ file }): JSX.Element => {
  const { onFileView } = useActions();
  const { contextMenuRecentFile } = useContextFile();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const target = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClick(menuRef, () => {
    setIsHover(false);
    setShow(false);
  });

  const onHover = (): void => setIsHover(true);
  const outHover = (): void => {
    if (show) return;
    setIsHover(false);
  };

  const onOpenFile = (): void => {
    onFileView({ open: true, params: file });
  };

  return (
    <div
      className={classNames(styles.file, {
        // TODO - androfficial - add logic for highlighting and opening a selected file
        [styles.hover]: isHover
      })}
      onDoubleClick={onOpenFile}
      onMouseEnter={onHover}
      onMouseLeave={outHover}
    >
      {file.mime_type && file?.mime_type?.startsWith("image") ? (
        <img src={file.preview} alt={file.name} className={styles.image} width={32} height={32} />
      ) : (
        <ItemIcon
          isFolder={false}
          width={28}
          height={38}
          color={file.color}
          passIcon={file.is_pass === 1}
          ext={file.ext}
        />
      )}
      <div className={styles.name}>{file.name}</div>
      <button
        className={classNames(styles.contextBtn, { [styles.visible]: show })}
        onClick={() => setShow((prev) => !prev)}
        ref={target}
      >
        <DotsIcon />
      </button>
      <Overlay placement={"auto"} rootClose show={show} flip target={target.current}>
        <Popover id="popover-context-menu">
          <div ref={menuRef}>
            <Popover.Body className={styles.popoverBody}>
              <ul className={styles.popoverList}>
                {contextMenuRecentFile(file).map((item, i) => (
                  <li key={i} className={styles.popoverItem}>
                    <button
                      className={styles.popoverButton}
                      onClick={(e) => {
                        setShow(false);
                        setIsHover(false);
                        item.callback(e);
                      }}
                      type="button"
                    >
                      <DefaultButtonIcon>{item.img}</DefaultButtonIcon>
                      <span className={styles.popoverButtonName}>{item.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </Popover.Body>
          </div>
        </Popover>
      </Overlay>
    </div>
  );
};

export default RecentFile;
