import React, { MutableRefObject, RefObject } from "react";

import { SendSocketMessages } from "./useWebRTCSocketMessages";

export interface IWebRTCProvider {
  children: React.ReactNode;
}

export interface ISenderTracks {
  audio: MediaStreamTrack;
  video: MediaStreamTrack;
}

export interface IMediaState {
  audio: boolean;
  video: boolean;
  screen: boolean;
}

export interface IWebRTCContext {
  peerConnections: RefObject<Record<string, RTCPeerConnection>>;
  localMediaStream: MutableRefObject<MediaStream>;
  localScreenStream: MutableRefObject<MediaStream>;
  peerMediaElements: RefObject<Record<string, HTMLVideoElement>>;
  peerScreenElements: RefObject<Record<string, HTMLVideoElement>>;
  minimizedMediaElement: MutableRefObject<HTMLVideoElement>;
  politeRef: RefObject<Record<string, boolean>>;
  sender: RefObject<Record<string, ISenderTracks>>;
  remoteStreams: MutableRefObject<Record<string, MediaStream>>;
  screenStreams: RefObject<Record<string, MediaStream>>;
  media: IMediaState;
  setMedia: (it: IMediaState) => void;
  makingOffer: boolean;
  setMakingOffer: (it: boolean) => void;
  clients: string[];
  updateClients: (newState: string[] | ((state: string[]) => string[]), cb?: (state: string[]) => void) => void;
  isLoadingUsers: boolean;
  setIsLoadingUsers: (it: boolean) => void;
  hasCameraPermission: boolean;
  setHasCameraPermission: (it: boolean) => void;
  fixedReconnectionUsers: { isFixed: boolean; checkCounts: number };
  setFixedReconnectionUsers: React.Dispatch<React.SetStateAction<{ isFixed: boolean; checkCounts: number }>>;

  sendSocket: (message: SendSocketMessages) => void;
  nullifyData: () => void;
}

export function initialMediaState(): IMediaState {
  return {
    audio: false,
    video: false,
    screen: false
  };
}

export enum MediKinds {
  Audio = "audio",
  Video = "video"
}
