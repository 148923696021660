import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow_down.svg";
import classNames from "classnames";
import LetterAttachment from "containers/Postbox/LetterAttachment/LetterAttachment";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import { useLetterTime } from "hooks/postboxHooks";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ILetterInfo, IPostbox } from "models/store/postbox/postbox";
import React, { useState } from "react";
import { getLetterText } from "utils/mail";
import { v4 as uuid } from "uuid";

import LetterActions from "../LetterActions/LetterActions";
import styles from "./LetterViewItem.module.sass";

interface ILetterViewItemProps {
  letter: ILetterInfo;
  postbox: IPostbox;
  onHideLetter: (el: ILetterInfo) => void;
}

const LetterViewItem: React.FC<ILetterViewItemProps> = ({ letter, postbox, onHideLetter }) => {
  const { letterTime } = useLetterTime();

  const [show, setShow] = useState<boolean>(false);
  const toggleShow = (): void => setShow((prev) => !prev);

  return (
    <li
      className={classNames(styles.letterItem, {
        [styles.isMe]: letter.from.toLocaleLowerCase() === postbox.email.toLocaleLowerCase()
      })}
    >
      <div className={styles.messageHeader}>
        <div className={styles.messageHeaderLeft}>
          <Avatar name={letter.from_name || letter.from} size={30} />
          <div className={styles.messageName}>
            <div className={styles.name}>{letter.from_name}</div>
            <div className={styles.email}>{letter.from}</div>
          </div>
        </div>
        <div className={styles.time}>{letterTime(letter.time)}</div>
        <div className={styles.menu}>
          <LetterActions letter={letter} postbox={postbox} onHideLetter={onHideLetter} />
        </div>
      </div>
      <div className={styles.letterItem_body}>
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: getLetterText(letter?.html).visible }} />

        {getLetterText(letter.html).unvisible.length > 0 && (
          <div className={classNames(styles.more, { [styles.show]: show })}>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.EXTRA_SMALL}
              onClick={toggleShow}
              iconL={<ArrowIcon />}
            />
          </div>
        )}
        {show && (
          <div className={styles.text} dangerouslySetInnerHTML={{ __html: getLetterText(letter.html).unvisible }} />
        )}
        {Array.isArray(letter.attachments) && (
          <div className={styles.attachments}>
            {letter.attachments.map((atachment) => (
              <LetterAttachment key={uuid()} name={atachment.filename} type={atachment.type} letter={letter} />
            ))}
          </div>
        )}
      </div>
    </li>
  );
};

export default LetterViewItem;
