import { IUseWebRTCCheckServices } from "../../../models/generalComponents/hooks/WebRTC/useWebRTCCheckServices";
import { IGenericWebRTCSocketReceiveMessage } from "../../../models/generalComponents/hooks/WebRTC/useWebRTCSocketMessages";
import { useUserSelectors } from "../../../Store/selectors/userSelectors";
import { typeCheck } from "../../Services/browserServices";
import { createUniqueArrayOfValues } from "../../Services/chatServices";
import { CHAT_CALLROOM_ACTIONS } from "../../variables/chat";
import { TYPES } from "../../variables/global";
import { useWebRTCContext } from "./WebRTCProvider";

// Хук, ответственный за проверку подключения всех пользователей
export function useWebRTCCheckServices(): IUseWebRTCCheckServices {
  const { userInfo } = useUserSelectors();
  const { peerConnections, setIsLoadingUsers, clients, peerMediaElements } = useWebRTCContext();
  const { sendSocket } = useWebRTCContext();

  // ПРоверка разрешений камеры в браузере
  const checkCameraPermissions = (): Promise<void> => {
    const camera = "camera" as PermissionName;
    return navigator.permissions
      .query({ name: camera })
      .then((res) => {
        if (res.state !== "granted") {
          throw new Error("Permissions not granted.");
        }
      })
      .catch((err) => console.log(err));
  };

  // Проверка состояния соединения PeerConnection
  const checkConnectionStatus = (message: IGenericWebRTCSocketReceiveMessage): void => {
    if ("connected_users" in message && typeCheck(message.connected_users) === TYPES.ARRAY) {
      const users = message.connected_users.filter((it) => it !== userInfo.id);
      const peers = Object.keys(peerConnections.current);
      const notConnectedUsers = createUniqueArrayOfValues([
        ...checkPeerConnection(users, peers),
        ...checkWebRTCConnection(peers),
        ...checkPeerMediaElements(users),
        ...checkClients(users)
      ]);
      if (message.connected_users.includes(userInfo.id)) {
        if (notConnectedUsers.length > 0) {
          console.log("!!!!!!!!!!!notConnectedUsers!!");
          console.log("notConnectedUsers", notConnectedUsers);
          setTimeout(() => {
            askForConnectedUsers();
          }, 1500);
        } else {
          setIsLoadingUsers(false);
        }
      }
    }
  };

  // Проверка соответствия подключенных клиентов локально на соответствие подключенных пользователей в PeerConnections и MediaElements
  const checkClients = (users: string[]): string[] => {
    return users.filter((it) => !(clients as string[]).includes(it));
  };

  // проверка наличия медиаэлементов у всех пользователей
  const checkPeerMediaElements = (users: string[]) => {
    const mediaElements = Object.keys(peerMediaElements.current);
    return users.filter((it) => !mediaElements.includes(it));
  };

  // проверка статуса RTC соединенеия "connected"
  const checkWebRTCConnection = (peers: string[]): string[] => {
    const notConnectedPeers = peers
      .map((it) => {
        const pc = peerConnections.current[it];
        if (pc) {
          return pc.connectionState === "connected" && pc.localDescription?.type && pc.remoteDescription?.type
            ? null
            : it;
        } else {
          return null;
        }
      })
      .filter((it) => it !== null);
    return notConnectedPeers;
  };

  // Проверка соответствия подключенных клиентов локально на соответствие подключенных пользователей на сервере
  const checkPeerConnection = (users: string[], peers: string[]): string[] => {
    return users.filter((it) => !peers.includes(it));
  };

  // запрос на переподключения пользователя
  const askForConnectedUsers = (): void => {
    const message = {
      action: CHAT_CALLROOM_ACTIONS.CHECK_CONNECTED_USERS
    };
    sendSocket(message);
  };

  return {
    checkCameraPermissions,
    checkConnectionStatus,
    checkWebRTCConnection,
    checkClients,
    checkPeerConnection,
    checkPeerMediaElements
  };
}
