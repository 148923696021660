import classNames from "classnames";
import DashboardItem from "containers/Dashboard/DashboardItem/DashboardItem";
import Empty from "containers/Dashboard/Empty/Empty";
import { useLocales } from "react-localized";
import { useFavoriteItemsSelectors } from "Store/selectors/favoriteItemsSelectors";

import styles from "./Favorites.module.sass";

const Favorites = ({ type }: { type: string }) => {
  const { __ } = useLocales();
  const { favoriteItems } = useFavoriteItemsSelectors();

  return (
    <div className={classNames(styles.container, styles[`container_${type}`])}>
      <div className={styles.title}>{__("Избранное")}</div>
      {favoriteItems.length === 0 ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={classNames(styles.scrollWrapper, "scrollbar-thin-blue")}>
          <div className={styles.list}>
            {favoriteItems.map((item, i) => (
              <DashboardItem item={item} key={i} type={type} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Favorites;
