import { ISortCriteriaState } from "models/store/sortCriteria/sortCriteria";
import { IAddSortCriteria, INullifySortCriteria } from "models/store/sortCriteria/sortCriteriaActions";
import { SortCriteriaTypes } from "Store/types/sortCriteria";

export const onAddSortCriteria = (payload: ISortCriteriaState): IAddSortCriteria => ({
  type: SortCriteriaTypes.ADD_SORT,
  payload
});

export const onNullifySortCriteria = (): INullifySortCriteria => ({
  type: SortCriteriaTypes.NULLIFY_SORTS_CRITERIA
});
