import { format } from "date-fns";
import React, { useMemo, useState } from "react";

import ProjectUsers from "../Components/ProjectUsers/ProjectUsers";
import Roles from "../Components/Roles/Roles";
import { useProjectTasks } from "../helpers";
import HistoryServicePanel from "./HistoryServicePanel/HistoryServicePanel";
import HistoryTasks from "./HistoryTasks/HistoryTasks";
import styles from "./ProjectTasksHistory.module.sass";

const ProjectTasksHistory = () => {
  const { allTasks } = useProjectTasks();
  const [searchValue, setSearchValue] = useState("");
  const [dateValue, setDateValue] = useState("");

  const archiveTasks = useMemo(
    () =>
      [...allTasks.filter((t) => t.is_archive === "1" && !t.is_epic)].sort(
        (a, b) => new Date(b.date_start).getTime() - new Date(a.date_start).getTime()
      ),
    [allTasks]
  );

  const handleChange = (value) => setSearchValue(value);

  const filtredValue = useMemo(
    () => archiveTasks.filter((t) => t.name.toLowerCase().includes(searchValue.toLowerCase())),
    [archiveTasks, searchValue]
  );

  const filtredDate = useMemo(
    () =>
      filtredValue.filter((t) => {
        const time = new Date(dateValue.slice(6), dateValue.slice(3, 5) - 1, dateValue.slice(0, 2)).getTime();
        const timeStart = new Date(t.date_start).getTime();
        const timeEnd = new Date(t.date_end).getTime();
        if (dateValue) {
          return timeStart <= time && timeEnd >= time;
        }
        return t;
      }),
    [dateValue, filtredValue]
  );

  const historyTasks = () => {
    const ht = filtredDate.reduce((acc, item) => {
      if (item.date_start && item.date_end) {
        const sprintStart = format(new Date(item.date_start), "yyyy.MM.dd");
        const sprintEnd = format(new Date(item.date_end), "yyyy.MM.dd");

        const key = `${sprintStart} - ${sprintEnd}`;
        acc[key] = acc[key] ? [...acc[key], item] : [item];
      }
      return acc;
    }, {});
    return ht;
  };

  return (
    <div className={styles.wrap}>
      <Roles />
      <ProjectUsers />
      <HistoryServicePanel
        searchValue={searchValue}
        handleChange={handleChange}
        setDateValue={setDateValue}
        dateValue={dateValue}
      />
      {<HistoryTasks tasks={historyTasks()} />}
    </div>
  );
};

export default ProjectTasksHistory;
