import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import classNames from "classnames";
import ParticipantsButtons from "containers/JoinProject/JointProjectsTasks/components/ParticipantsButtons/ParticipantsButtons";
import TeamButtons from "containers/JoinProject/JointProjectsTasks/components/TeamButtons/TeamButtons";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import Input from "generalComponents/Input/Input";
import Loader from "generalComponents/Loaders/4HUB";
import { Observer } from "generalComponents/Observer/Observer";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { useDebounce } from "hooks/useDebounce";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { initialTasksConnectionState } from "models/store/Cabinet/modals/tasksConnection";
import { IProjectTask } from "models/store/joinProjects/joinProgects";
import { useEffect } from "react";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./TasksConnectionModal.module.sass";

const TasksConnectionModal = () => {
  const { __ } = useLocales();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [activeTeam, setActiveTeam] = useState<string>("");
  const [activeParticipiants, setActiveParticipiants] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchingData, setFetchingData] = useState<boolean>(false);

  const {
    onSetTasksConnectionModal,
    onAddTaskDependency,
    onSetProjectPage,
    onFetchAllTaskProject,
    onResetAllTasksProject
  } = useActions();

  const { project, page, total } = useJoinProjectsSelectors();
  const { uid } = useUserSelectors();
  const { tasksConnection } = useGlobalModalsSelectors();

  const closeModal = () => {
    onSetTasksConnectionModal(initialTasksConnectionState());
  };

  const onSelectParticipants = (id: string) => {
    const participantsArray = activeParticipiants ? activeParticipiants.split(",") : [];

    if (participantsArray.includes(id)) {
      const updatedParticipants = participantsArray.filter((participantId) => participantId !== id);
      setActiveParticipiants(updatedParticipants.join(","));
    } else {
      participantsArray.push(id);
      setActiveParticipiants(participantsArray.join(","));
    }
  };

  const toggleActiveTeam = (id: string) => {
    setActiveTeam((prev) => (prev === id ? "" : id));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const controller = new AbortController();

  const payload = {
    per_page: 500,
    search: searchQuery,
    selectedParticipants: activeParticipiants,
    team: activeTeam,
    callBack: () => setFetchingData(false),
    controller
  };

  const projectAllTasks = project?.allTasks;

  const loadMore = (): void => {
    onSetProjectPage(page + 1);
    setFetchingData(true);
    onFetchAllTaskProject(payload);
  };

  const debouncedSearchQuery = useDebounce(
    () => {
      setFetchingData(true);

      onFetchAllTaskProject(payload);
    },
    searchQuery?.length > 0 ? 300 : 0
  );

  useEffect(() => {
    onResetAllTasksProject();

    debouncedSearchQuery();

    return () => {
      onResetAllTasksProject();
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeam, activeParticipiants, searchQuery]);

  const renderTasks = (): JSX.Element => {
    if (projectAllTasks !== null) {
      if (total === 0) {
        if (activeTeam || activeParticipiants || searchQuery) {
          return <Clue icon="neutral" text={__("Нет задач соответствующих параметрам фильтрации")} />;
        }
        return <Clue icon="sad" text={__("Тут пока пусто")} />;
      }

      return (
        <>
          {projectAllTasks
            .filter((item) => item.id !== tasksConnection.task.id)
            .map((item) => (
              <div className={styles.item} key={item.id}>
                <div>
                  <div className={styles.num}>{item.num}</div>
                  <div className={styles.name}>{item.name}</div>
                </div>
                <Button
                  variant={ButtonVariantType.ADD_GREY}
                  size={ButtonSizeType.EXTRA_SMALL}
                  text={__("Связать")}
                  onClick={() => onAddConnection(item)}
                />
              </div>
            ))}
        </>
      );
    }
  };

  const onAddConnection = (_task: IProjectTask) => {
    const params = {
      uid,
      id_task1: tasksConnection.task.id,
      id_task2: _task.id,
      type1: tasksConnection.type1,
      type2: tasksConnection.type2,
      cb: () => {
        setLoading(false);
        closeModal();
      },
      error: __("Связь с данной задачей уже установленна")
    };
    onAddTaskDependency(params);
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.container}>
        <Input value={searchQuery} onChange={handleSearchChange} rightIcon={<SearchIcon fill="var(--color-d100)" />} />
        <div className={styles.header}>
          <TeamButtons selectedTeam={activeTeam} onSelectTeam={toggleActiveTeam} />
          <ParticipantsButtons
            onSelectParticipiant={onSelectParticipants}
            teamId={activeTeam}
            activeParticipiants={activeParticipiants}
          />
        </div>
        <div className={classNames(styles.content, "scrollbar-thin-blue")}>
          {fetchingData && page === 1 ? (
            <div className={styles.loader}>
              <Loader
                position="absolute"
                height={"100px"}
                width={"100px"}
                type={LoaderTypes.BOUNCING_DOTS}
                containerType="bounceDots"
              />
            </div>
          ) : (
            <>
              {renderTasks()}
              {<Observer loader={fetchingData} page={page} total={total} callBack={loadMore} />}
            </>
          )}
        </div>
        {loading && (
          <div className={styles.loaderBox}>
            <Loader
              type={LoaderTypes.BOUNCING_DOTS}
              position="absolute"
              background="transparent"
              zIndex={5}
              width="200px"
              height="200px"
              containerType="bounceDots"
            />
          </div>
        )}
      </div>
    </PopUp>
  );
};

export default TasksConnectionModal;
