import api from "api";
import { type AxiosResponse } from "axios";
import { EVENT_TYPE, TYPE_ELEMENTS } from "generalComponents/variables/global";

export interface IEventAddAPI {
  uid: string;
  type?: string;
  text?: string;
  id_user_event?: string;
  event_type?: EVENT_TYPE;
  event_group?: TYPE_ELEMENTS;
  event_object?: string;
  date?: string;
  period_d?: number;
  period_w?: number;
  period_m?: number;
  period_y?: number;
  data?: {
    email?: string;
  }[];
}

export const EventAPI = {
  async add(params: IEventAddAPI): Promise<AxiosResponse> {
    return await api.get("/ajax/event_add.php", { params });
  }
};
