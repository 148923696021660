import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/plus-3.svg";
import Button from "generalComponents/Button/Button";
import { ButtonVariantType } from "models/generalComponents/button";
import { ITabsPanelProps } from "models/project/docsTabsPanel";
import { PROJECT_DOCS_VIEW } from "models/project/projectDocs";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./TabsPanel.module.sass";

const TabsPanel: React.FC<ITabsPanelProps> = ({ viewType, setTypes }): JSX.Element => {
  const { __ } = useLocales();

  const chengeTab = (viewType: PROJECT_DOCS_VIEW): void => {
    setTypes(viewType);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.btns}>
        <Button
          type="button"
          text={__("Документация проекта")}
          variant={ButtonVariantType.ADD_GREY}
          onClick={() => chengeTab(PROJECT_DOCS_VIEW.DOCS)}
          isActive={viewType === PROJECT_DOCS_VIEW.DOCS}
        />
        <Button
          type="button"
          text={__("Шаблоны документов")}
          variant={ButtonVariantType.ADD_GREY}
          onClick={() => chengeTab(PROJECT_DOCS_VIEW.TEMPLATE)}
          isActive={viewType === PROJECT_DOCS_VIEW.TEMPLATE}
        />
      </div>
      <div className={styles.btns}>
        <Button
          type="button"
          text={__("Создать документ")}
          variant={ButtonVariantType.ADD_GREY}
          onClick={() => chengeTab(PROJECT_DOCS_VIEW.CREATE_DOC)}
          iconR={<PlusIcon width={16} height={16} />}
          isActive={viewType === PROJECT_DOCS_VIEW.CREATE_DOC}
        />
        <Button
          type="button"
          text={__("Создать шаблон")}
          variant={ButtonVariantType.ADD_GREY}
          iconR={<PlusIcon width={16} height={16} />}
          onClick={() => chengeTab(PROJECT_DOCS_VIEW.CREATE_TEMPLATE)}
          isActive={viewType === PROJECT_DOCS_VIEW.CREATE_TEMPLATE}
        />
      </div>
    </div>
  );
};

export default TabsPanel;
