import classNames from "classnames";
import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { FC, useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { SelectPeriod } from "./components/SelectPeriod";
import styles from "./ContentСleaning.module.sass";

export const ContentСleaning: FC = () => {
  const { __ } = useLocales();

  const { trash_minutes_del } = useUserSelectors();

  const [checked, setChecked] = useState<boolean>(Boolean(trash_minutes_del));

  const onClickCheckBox = (): void => {
    setChecked((prev: boolean) => !prev);
  };

  return (
    <div className={styles.box}>
      <div className={styles.box__head}>
        <div role="button" tabIndex={0} onClick={onClickCheckBox} className={styles.box__switcher}>
          <CheckBox
            variant={checked ? CheckBoxVariantTypes.CHECKED : CheckBoxVariantTypes.DEFAULT}
            width={20}
            height={20}
            increaseArea
            className={classNames(styles.box__switcherCheck, {
              [styles.checked]: checked
            })}
          />
          <span
            className={classNames(styles.box__switcherLabel, {
              [styles.checked]: checked
            })}
          >
            {checked ? __("Вкл") : __("Выкл")}
          </span>
        </div>
        <p className={styles.box__title}>{__("Автоматическая очистка корзины")}</p>
      </div>
      <div className={styles.box__body}>
        <p className={styles.box__desc}>{__("Файлы будут автоматически удалены с корзины через")}</p>
        <SelectPeriod checked={checked} />
      </div>
    </div>
  );
};
