import { ReactComponent as CalendarIcon } from "assets/icons/calendar_4.svg";
import { ReactComponent as CardIcon } from "assets/icons/card.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder_2.svg";
import { ReactComponent as TeamIcon } from "assets/icons/group-team.svg";
import { ReactComponent as LibraryIcon } from "assets/icons/library.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as Person2Icon } from "assets/icons/person_4.svg";
import { ReactComponent as ChatIcon } from "assets/PrivateCabinet/chat/sms.svg";
import { useLocales } from "react-localized";

export interface IFaqCategory {
  title: string;
  content: (() => React.ReactNode) | IFaqCategory[];
  route: string;
}

export interface IFaqItem {
  title: string;
  icon: React.ReactNode;
  describe: string;
  content: IFaqCategory[];
  route: string;
}

export const useFaq = (): Array<IFaqItem> => {
  const { __ } = useLocales();

  return [
    {
      title: __("Учетная запись"),
      route: "account",
      icon: <PersonIcon height={81} width={81} />,
      describe: __("Регистрация вход и восстановление доступа"),
      content: [
        {
          title: __("Как создать аккаунт"),
          route: "create-account",
          content: [
            {
              title: __("Как изменить пароль"),
              route: "change-password",
              content: [
                {
                  title: __("Как восстановить доступ к аккаунту"),
                  route: "recover-account",
                  content: [
                    {
                      title: __("Как настроить двухфакторную аутентификацию"),
                      route: "two-factor-setup",
                      content: () => <p>{__("Ответ на ваш вопрос")}</p>
                    },
                    {
                      title: __("Что делать, если забыл пароль"),
                      route: "forgot-password",
                      content: [
                        {
                          title: __("Как сменить адрес электронной почты"),
                          route: "change-email",
                          content: () => <p>{__("Ответ на ваш вопрос")}</p>
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              title: __("Что делать при проблемах с входом"),
              route: "login-troubleshoot",
              content: () => <p>{__("Ответ на ваш вопрос")}</p>
            }
          ]
        },
        {
          title: __("Как удалить аккаунт"),
          route: "delete-account",
          content: () => (
            <>
              <h1 id="Создать папку">Создать папку с паролем</h1>
              <h2>Создать папку с паролем</h2>
              <h3>Создать папку с паролем</h3>
              <h4>Создать папку с паролем</h4>
              <h5>Создать папку с паролем</h5>
              <h6>Создать папку с паролем</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Risus pretium quam vulputate dignissim suspendisse in est ante in. Laoreet id donec
                ultrices tincidunt arcu non sodales neque. Lorem mollis aliquam ut porttitor. Dolor purus non enim
                praesent elementum facilisis leo. Massa vitae tortor condimentum lacinia quis vel eros. Nisl rhoncus
                mattis rhoncus urna neque. Pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat
                vivamus. Aliquam etiam erat velit scelerisque in dictum non consectetur. Ultricies leo integer malesuada
                nunc.
                <strong id="one more link">
                  Arcu non odio euismod lacinia at quis risus. Nibh tellus molestie nunc non blandit massa enim nec.
                </strong>
              </p>

              <p>
                Leo urna molestie at elementum eu facilisis sed odio morbi. Consequat semper viverra nam libero justo
                laoreet sit. Quis blandit turpis cursus in hac habitasse platea dictumst quisque. Viverra orci sagittis
                eu volutpat odio facilisis mauris sit. Eget dolor morbi non arcu risus quis varius quam quisque. Nunc
                scelerisque viverra mauris in aliquam. Diam vulputate ut pharetra sit amet aliquam id diam. Tincidunt id
                aliquet risus feugiat in ante metus dictum at. Tristique sollicitudin nibh sit amet commodo nulla.
                Commodo sed egestas egestas fringilla phasellus faucibus. Sed egestas egestas fringilla phasellus
                faucibus scelerisque. Cursus euismod quis viverra nibh cras pulvinar mattis nunc. Ut sem nulla pharetra
                diam. Auctor urna nunc id cursus metus aliquam. Lectus arcu bibendum at varius vel pharetra vel turpis
                nunc. Elit ut aliquam purus sit amet luctus.
              </p>

              <p>
                <b>
                  Nibh sed pulvinar proin gravida. Convallis convallis tellus id interdum. Ipsum a arcu cursus vitae.
                  Volutpat consequat mauris nunc congue nisi vitae. Leo duis ut diam quam nulla porttitor massa id. Cras
                  ornare arcu dui vivamus arcu. Amet consectetur adipiscing elit duis tristique sollicitudin nibh sit
                  amet. Vulputate sapien nec sagittis aliquam malesuada.
                </b>{" "}
                Pellentesque elit eget gravida cum sociis. Turpis egestas maecenas pharetra convallis posuere morbi leo
                urna molestie. Donec massa sapien faucibus et molestie ac. Consectetur adipiscing elit ut aliquam purus
                sit amet luctus venenatis. Sed ullamcorper morbi tincidunt ornare. A erat nam at lectus urna duis
                convallis convallis. Quis auctor elit sed vulputate mi sit. In egestas erat imperdiet sed euismod nisi
                porta lorem mollis. Ut ornare lectus sit amet est placerat in egestas. Aliquet sagittis id consectetur
                purus ut. Sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.
              </p>

              <p>
                Massa tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada proin. Nec ullamcorper sit amet
                risus nullam. Lacus luctus accumsan tortor posuere. Arcu non sodales neque sodales. In nibh mauris
                cursus mattis molestie a iaculis. Blandit aliquam etiam erat velit scelerisque in dictum. Dictumst
                vestibulum rhoncus est pellentesque elit. Dictum varius duis at consectetur lorem donec massa sapien.
                Cras sed felis eget velit aliquet. Penatibus et magnis dis parturient montes nascetur ridiculus. Commodo
                quis imperdiet massa tincidunt nunc pulvinar. Scelerisque fermentum dui faucibus in ornare quam viverra.
              </p>
              <h4 id="Таблица">Создать папку с паролем</h4>
              <table>
                <tbody>
                  <tr>
                    <td>
                      Откройте выбранное программное обеспечение и создайте новый защищенный контейнер или архив. В
                      процессе создания вас попросят установить пароль. Придумайте надежный пароль, состоящий из букв,
                      цифр и символов.
                    </td>
                    <td>
                      После создания защищенного контейнера или архива, перетащите или добавьте файлы и документы,
                      которые вы хотите сохранить в этой папке.
                    </td>
                    <td>Закройте защищенный контейнер или архив и убедитесь, что он защищен паролем.</td>
                  </tr>
                </tbody>
              </table>
            </>
          )
        }
      ]
    },
    {
      title: __("Тарифные планы и оплата"),
      route: "tariff",
      icon: <CardIcon height={81} width={81} />,
      describe: __("Регистрация вход и восстановление доступа к аккаунту"),
      content: [
        {
          title: __("Как выбрать подходящий тариф"),
          route: "choose-plan",
          content: () => <p>{__("Ответ на ваш вопрос")}</p>
        },
        {
          title: __("Как обновить тарифный план"),
          route: "update-plan",
          content: () => <p>{__("Ответ на ваш вопрос")}</p>
        },
        {
          title: __("Как происходит оплата"),
          route: "payment-process",
          content: [
            {
              title: __("Как использовать промокод"),
              route: "use-promocode",
              content: () => <p>{__("Ответ на ваш вопрос")}</p>
            },
            {
              title: __("Что делать, если платеж не прошел"),
              route: "payment-issue",
              content: () => <p>{__("Ответ на ваш вопрос")}</p>
            }
          ]
        },
        {
          title: __("Как отменить подписку"),
          route: "cancel-subscription",
          content: () => <p>{__("Ответ на ваш вопрос")}</p>
        }
      ]
    },
    {
      title: __("Сотрудники"),
      route: "employees",
      icon: <Person2Icon height={81} width={81} />,
      describe: __("Управление сотрудниками"),
      content: [
        {
          title: __("вопрос 1"),
          route: "employees",
          content: () => <p>{__("ответ 1")}</p>
        }
      ]
    },
    {
      title: __("Безопасность"),
      route: "safety",
      icon: <LockIcon height={81} width={81} />,
      describe: __("Активные сессии и устройства, Журнал и Сейф"),
      content: [{ title: __("вопрос 1"), route: "safetyww", content: () => <p>{__("ответ 1")}</p> }]
    },
    {
      title: __("Работа с файлами"),
      route: "files",
      icon: <FolderIcon height={81} width={81} />,
      describe: __("Файлы, Папки и Корзина"),
      content: [{ title: __("вопрос 1"), route: "safetyww", content: () => <p>{__("ответ 1")}</p> }]
    },
    {
      title: __("Календарь и планирование"),
      route: "planning",
      icon: <CalendarIcon height={81} width={81} />,
      describe: __("Задачи и Календарь"),
      content: [{ title: __("вопрос 1"), route: "planningqq", content: () => <p>{__("ответ 1")}</p> }]
    },
    {
      title: __("Коммуникация"),
      route: "communication",
      icon: <ChatIcon height={81} width={81} />,
      describe: __("Чат, звонки, Email и общий стол"),
      content: [{ title: __("вопрос 1"), route: "communicationww", content: () => <p>{__("ответ 1")}</p> }]
    },
    {
      title: __("Совместный проект"),
      route: "jointProject",
      icon: <TeamIcon height={81} width={81} />,
      describe: __("Управление и планирование рабочих процессов"),
      content: [{ title: __("вопрос 1"), route: "jointProjecwt", content: () => <p>{__("ответ 1")}</p> }]
    },
    {
      title: __("Библиотека"),
      route: "library",
      icon: <LibraryIcon height={81} width={81} />,
      describe: __("Хранение и управление общедоступными ресурсами"),
      content: [{ title: __("вопрос 1"), route: "libraryaa", content: () => <p>{__("ответ 1")}</p> }]
    }
  ];
};
