import classNames from "classnames";
import { getTagById } from "generalComponents/FileTags/FileTags";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { IItemInfoDynamicProps } from "models/generalComponents/itemInfoDynamic";
import React from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./ItemInfoDynamic.module.sass";

export const ItemInfoDynamic: React.FC<IItemInfoDynamicProps> = ({
  isFolder,
  setColor,
  setEmo,
  passIcon,
  color,
  ext = "FILES",
  name,
  size,
  tags,
  emo,
  itemsLength
}): JSX.Element => {
  const { __ } = useLocales();
  const { tags: userTags } = useUserSelectors();
  const onSetColor = (): void => setColor(undefined);
  const onSetEmo = (): void => setEmo("");

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <ItemIcon
          isFolder={isFolder}
          width={isFolder ? 58 : 42}
          height={isFolder ? 48 : 56}
          color={color}
          passIcon={passIcon}
          ext={ext}
        />
      </div>
      <div className={styles.picPreview}>
        <div className={styles.topInfo}>
          <div className={styles.name}>
            {itemsLength > 1 ? `${__("Выбранно")} ${itemsLength} ${__("элементa")}` : name}
          </div>
          {size && <div className={styles.fileSize}>{size}</div>}
        </div>
        {(tags?.length > 0 || color || emo) && (
          <div className={styles.options}>
            {tags?.length > 0 && (
              <div className={styles.tags}>
                {tags.map((id) => (
                  <div key={id} className={styles.tag}>
                    {getTagById(id, userTags).name}
                  </div>
                ))}
              </div>
            )}
            {color && (
              <button className={classNames(styles.colorWrap, styles.redCross)} onClick={onSetColor} type="button">
                <div
                  className={styles.circle}
                  style={{
                    background: color,
                    border: `1px solid ${color}`
                  }}
                />
              </button>
            )}
            {emo && (
              <button onClick={onSetEmo} className={classNames(styles.emojiButton, styles.redCross)} type="button">
                <span className={styles.emo} dangerouslySetInnerHTML={{ __html: emo }} />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
