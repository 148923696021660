import { ReactComponent as AddictionIcon } from "assets/checkboxIcons/checkbox-addiction.svg";
import { ReactComponent as CheckedIcon } from "assets/checkboxIcons/checkbox-checked.svg";
import { ReactComponent as DefaultIcon } from "assets/checkboxIcons/checkbox-default.svg";
import { ReactComponent as HoverIcon } from "assets/checkboxIcons/checkbox-hover.svg";
import { ReactComponent as SigninCheckedIcon } from "assets/checkboxIcons/signin_checked.svg";
import { ReactComponent as SigninDefaultIcon } from "assets/checkboxIcons/signin_default.svg";
import classNames from "classnames";
import { CheckBoxVariantTypes, ICheckBoxProps } from "models/generalComponents/checkBox";
import React from "react";

import styles from "./CheckBox.module.sass";

export const CheckBox: React.FC<ICheckBoxProps> = ({
  variant,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  width,
  height,
  tooltip,
  increaseArea
}): JSX.Element => {
  const renderCheckboxOption = (): JSX.Element => {
    switch (variant) {
      case CheckBoxVariantTypes.DEFAULT:
        return <DefaultIcon />;

      case CheckBoxVariantTypes.HOVER:
        return <HoverIcon />;

      case CheckBoxVariantTypes.CHECKED:
        return <CheckedIcon />;

      case CheckBoxVariantTypes.ADDICTION:
        return <AddictionIcon />;

      case CheckBoxVariantTypes.SIGNIN_DEFAULT:
        return <SigninDefaultIcon />;

      case CheckBoxVariantTypes.SIGNIN_CHECKED:
        return <SigninCheckedIcon />;
    }
  };

  if (variant === CheckBoxVariantTypes.NO_TYPE) return;

  return (
    <div
      className={classNames(className, styles.root, {
        [styles.increaseArea]: increaseArea
      })}
      style={{ flex: `0 0 ${width}px`, width, height }}
      title={tooltip}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {renderCheckboxOption()}
    </div>
  );
};
