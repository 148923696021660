import { IContact } from "models/store/user/user";

import { CHAT_CALLROOM, CHAT_CALLROOM_ACTIONS } from "../../../../generalComponents/variables/chat";

export interface IChatState {
  groupsList: IGroup[];
  recentGroupsMessages: {}; // Always empty???
  notificationsCounter: Record<string, number>;
  recentChatsList: IContact[];
  secretChatsList: ISecretChat[];
  selectedContact: IContact | IGroup;
  userId: string;
  files: IChatFiles;
  messages: MessageGroups;
  messageLifeTime: number;
  insertEmoji: string;
  theme: IChatThemeState;
  callRoom: ICallRoomState;
}

export interface IChatFiles {
  links: any[];
}

export interface IGroupUser {
  id: string;
  id_group: string;
  id_user: string;
  unread: string;
  time_read: string;
  name: string;
  tel: string;
  email: string;
  api_key: string;
  confirm: string;
  confirm_pass: string;
  fname: string;
  sname: string;
  uid: string;
  udir: string;
  prim: string;
  theme: string;
  r: string;
  code: string;
  safe_code: string;
  safe_code_ut: string;
  id_company: string;
  is_admin: number;
  pname: null;
  name1: null;
  date_last: string;
  date_created: string; // Date
  lang: string;
  notify: string;
  chat_theme: string;
  email2: string;
  icon: string[];
  is_online: number;
}

export interface IGroup {
  id: string;
  id_group: string;
  id_user: string;
  unread: string;
  time_read: string;
  name: string;
  ut: string;
  deadline: string;
  room_col: string;
  room_id: string;
  room_users: any[];
  icon: string[];
  isGroup: boolean;
  users: IGroupUser[];
  messages: []; // array of hardcoded message that comes from BE - is it needed???
}

export interface ISecretChat {
  id: string;
  id_group: string;
  id_user: string;
  unread: string;
  time_read: string; // Date
  name: string;
  tel: string;
  email: string;
  api_key: string;
  confirm: string;
  confirm_pass: string;
  fname: string;
  sname: string;
  uid: string;
  udir: string;
  prim: string;
  theme: string; // TODO - list all themes in enum
  r: string;
  code: string;
  safe_code: string;
  safe_code_ut: string;
  id_company: string;
  is_admin: number;
  pname: string;
  name1: string;
  date_last: string; // Date
  date_created: string; // Date
  lang: string; // TODO - crete list of languages enum
  notify: string;
  chat_theme: string;
  email2: string;
  is_online: number;
  is_user: number;
  real_user_date_last: string; // Date
  is_secret_chat: boolean;
  icon: string[];
}

export type MessageGroups = Record<string, MessageTypes[]>;

export type MessageTypes = ITextMessage | ITaskMessage;

export interface IMessageAttachment {
  preview_small: string;
}

export interface ITextMessage {
  id: string;
  id_user: string;
  id_user_to: string;
  text: string;
  ut: string;
  is_read: string;
  is_del: string;
  deadline: string;
  is_hide: string;
  is_hide_to: string;
  id_task: string;
  task_data: string;
  type: string;
  id_project: string;
  attachment: IMessageAttachment;
}

export interface ITaskMessage {
  id: string;
  id_user: string;
  id_user_to: string;
  text: string;
  ut: string;
  is_read: string;
  is_del: string;
  deadline: string;
  is_hide: string;
  is_hide_to: string;
  id_task: string;
  task_data: string;
  type: string;
  id_project: string;
  attachment: IMessageAttachment;
}

export interface IChatThemeState {
  name: string;
  background: string;
  textColor: string;
  iconColor: string;
  inputBgColor: string;
  inputColor: string;
  accentColor: string;
}

// ----- Start CallRoom

export interface ICallRoomState {
  state: CHAT_CALLROOM | CHAT_CALLROOM_ACTIONS;
  action: CHAT_CALLROOM_ACTIONS;
  callType: CHAT_CALLROOM;
  contacts: string[];
  user_id: string;
  icon: string;
  connectedUsers: IConnectedUser[];
  initialCallType: CHAT_CALLROOM;
  socket: WebSocket | null;
  room_id: string;
  id_group: string;
}

export interface IConnectedUser {
  icon: string;
  id_user: string;
  name: string;
  sname: string;
  media: {
    audio: boolean;
    video: boolean;
    screen?: boolean;
  };
  handUp: boolean;
}

// ------ End CallRoom ------

// ----- InitialState ----------

export function initialChatThemeState(): IChatThemeState {
  return {
    name: "white",
    background: "#fff",
    textColor: "#49494B",
    iconColor: "#B8B8B8",
    inputBgColor: "#F7F7F7",
    inputColor: "#AEAEAE",
    accentColor: ""
  };
}
export function initialCallRoomState(): ICallRoomState {
  return {
    state: CHAT_CALLROOM.NO_CALL,
    action: CHAT_CALLROOM_ACTIONS.NO_ACTION,
    callType: CHAT_CALLROOM.VOICE_CALL,
    contacts: null,
    user_id: null,
    icon: null,
    connectedUsers: [],
    initialCallType: CHAT_CALLROOM.NO_CALL,
    socket: null,
    room_id: "",
    id_group: ""
  };
}

export function initialChatState(): IChatState {
  return {
    groupsList: [],
    recentGroupsMessages: {},
    notificationsCounter: {},
    recentChatsList: [],
    secretChatsList: [],
    selectedContact: null,
    userId: null,
    files: null,
    messages: null,
    messageLifeTime: 3600,
    insertEmoji: "",
    theme: initialChatThemeState(),
    callRoom: initialCallRoomState()
  };
}
