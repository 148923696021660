import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { CircleProgress } from "generalComponents/CircleProgress/CircleProgress";
import PopUp from "generalComponents/PopUp/PopUp";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { LIBRARY_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialCreateObjectLibraryModalState } from "models/store/Cabinet/modals/createObjectLibraryModals";
import { IFile } from "models/store/files/files";
import { useEffect, useReducer, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useLibrarySelectors } from "Store/selectors/librarySelectors";
import { requiredRule } from "utils/validation";

import HeaderModal from "../HeaderModal/HeaderModal";
import { CreateDataObject } from "./components/CreateDataObject/CreateDataObject";
import { FormDataCreateaObjectState } from "./components/CreateDataObject/lib/createDataObjectReducer";
import {
  FieldsName,
  FormCreateObjectLibraryTypes,
  initialState,
  reducerData
} from "./components/CreateDataObject/lib/createDataObjectReducer";
import styles from "./CreateObjectLibraryModal.module.sass";

export const CreateObjectLibraryModal = (): JSX.Element => {
  const { __ } = useLocales();

  const navigate = useNavigate();

  const { id } = useParams();

  const {
    onSetCreateObjectLibraryModal,
    onAddLibraryFiles,
    onEditLibraryFiles,
    onResetLibraryLoadingFiles,
    onAddProjectLibraryFiles,
    onEditProjectLibraryFiles,
    onResetProjectLibraryLoadingFiles
  } = useActions();

  const {
    createObjectLibraryModals: { titleHead, btnLabel, folder, type }
  } = useGlobalModalsSelectors();

  const libraryUpload = useLibrarySelectors();
  const projecLibrarytUpload = useJoinProjectsSelectors();

  const libraryLoadingFiles = libraryUpload?.uploadFiles?.loadingFiles;
  const projectLibraryLoadingFiles = projecLibrarytUpload?.project?.library?.uploadFiles?.loadingFiles;

  const [filesFolder, setFilesFolder] = useState<IFile[]>(folder?.files);
  const [removedFilesFolder, setRemovedFilesFolder] = useState<IFile[]>([]);

  const isLibrarySettings = useCurrentPathStarstWith(`${ROUTES.LIBRARY}/${LIBRARY_ROUTES.SETTINGS}`);
  const isProjectLibrarySettings = useCurrentPathStarstWith(
    `${ROUTES.PROJECT}/${id}/${ROUTES.LIBRARY}/${LIBRARY_ROUTES.SETTINGS}`
  );

  const closeModal = (): void => {
    onSetCreateObjectLibraryModal(initialCreateObjectLibraryModalState());
  };

  const initialStateFolder: FormDataCreateaObjectState = {
    [FieldsName.CATEGORY]: {
      value: folder?.chapter ?? "",
      required: true,
      error: requiredRule(folder?.chapter),
      errorMessage: ""
    },
    [FieldsName.RUBRIC]: {
      value: folder?.id_dep ?? "",
      required: true,
      error: requiredRule(folder?.id_dep),
      errorMessage: ""
    },
    [FieldsName.NAME_FOLDER]: {
      value: folder?.name ?? "",
      required: true,
      error: requiredRule(folder?.name),
      errorMessage: ""
    },
    [FieldsName.DESCRIPTION_FOLDER]: {
      value: folder?.prim ?? "",
      required: false,
      error: false,
      errorMessage: ""
    },
    [FieldsName.FILES_UPLOAD]: {
      files: [],
      required: false,
      error: false,
      errorMessage: ""
    }
  };

  const renderDataStateDefault = (): FormDataCreateaObjectState => {
    switch (type) {
      case "addLibrary":
        return initialState;

      case "editLibrary":
        return initialStateFolder;

      case "addProjectLibrary":
        return Object.fromEntries(
          Object.entries(initialState).filter(([key]) => key !== FieldsName.RUBRIC)
        ) as FormDataCreateaObjectState;

      case "editProjectLibrary":
        return Object.fromEntries(
          Object.entries(initialStateFolder).filter(([key]) => key !== FieldsName.RUBRIC)
        ) as FormDataCreateaObjectState;
    }
  };

  const [data, dispatchData] = useReducer(reducerData, renderDataStateDefault());
  const dataCategory = data[FieldsName.CATEGORY].value;
  const dataRubric = data[FieldsName.RUBRIC] ? data[FieldsName.RUBRIC].value : "";
  const dataNameFolder = data[FieldsName.NAME_FOLDER].value;
  const dataDescriptionFolder = data[FieldsName.DESCRIPTION_FOLDER].value;
  const dataFiles = data[FieldsName.FILES_UPLOAD].files;

  const notChangedCategory = folder?.chapter && folder.chapter === dataCategory;
  const notChangedRubric = dataRubric ? folder?.id_dep && folder.id_dep === dataRubric : true;
  const notChangedNameFolder = folder?.name && folder.name === dataNameFolder;
  const notChangedDescriptionFolder = folder?.prim === dataDescriptionFolder;
  const notChangedFiles = !dataFiles?.length;

  const getErrorForm = (): boolean => Object.values(data).some(({ error, required }) => (error && required) || error);
  const getErrorEdit = (): boolean =>
    notChangedCategory &&
    notChangedRubric &&
    notChangedNameFolder &&
    notChangedDescriptionFolder &&
    notChangedFiles &&
    !removedFilesFolder?.length;

  const onSubmitDataObj = () => {
    const params = {
      id_category: !notChangedCategory ? dataCategory : undefined,
      deletedFiles: removedFilesFolder?.length ? removedFilesFolder : undefined,
      id_dep: !notChangedRubric ? dataRubric : undefined,
      id_dirs: folder?.id_dir ? [folder.id_dir] : undefined,
      dir_name: !notChangedNameFolder ? dataNameFolder : undefined,
      prim: !notChangedDescriptionFolder ? dataDescriptionFolder : undefined,
      files: !notChangedFiles ? dataFiles : undefined,
      time: libraryLoadingFiles?.time || projectLibraryLoadingFiles?.time,
      percent: libraryLoadingFiles?.percent || projectLibraryLoadingFiles?.percent,
      done: libraryLoadingFiles?.done || projectLibraryLoadingFiles?.done,
      isOpen: true
    };

    switch (type) {
      case "addLibrary":
        return onAddLibraryFiles(params);

      case "editLibrary":
        return onEditLibraryFiles(params);

      case "addProjectLibrary":
        return onAddProjectLibraryFiles(params);

      case "editProjectLibrary":
        return onEditProjectLibraryFiles(params);
    }
  };

  useEffect(() => {
    if (libraryLoadingFiles?.done) {
      if (!folder && !isLibrarySettings) {
        navigate(
          `${ROUTES.LIBRARY}/${LIBRARY_ROUTES.CATEGORIES}?${SEARCH_PARAMS.CHAPTER}=${libraryLoadingFiles?.id_category}`
        );
      }

      onResetLibraryLoadingFiles();
      onSetCreateObjectLibraryModal(initialCreateObjectLibraryModalState());
    }

    if (projectLibraryLoadingFiles?.done) {
      if (!folder && !isProjectLibrarySettings) {
        navigate(
          `${ROUTES.PROJECT}/${id}/${ROUTES.LIBRARY}/${LIBRARY_ROUTES.CATEGORIES}?${SEARCH_PARAMS.CHAPTER}=${projectLibraryLoadingFiles?.id_category}`
        );
      }

      onResetProjectLibraryLoadingFiles();
      onSetCreateObjectLibraryModal(initialCreateObjectLibraryModalState());
    }

    if ((libraryLoadingFiles?.isOpen && !folder) || (projectLibraryLoadingFiles?.isOpen && !folder)) {
      dispatchData({ type: FormCreateObjectLibraryTypes.NULLIFY });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    libraryLoadingFiles?.done,
    libraryLoadingFiles?.isOpen,
    projectLibraryLoadingFiles?.isOpen,
    projectLibraryLoadingFiles?.done
  ]);

  return (
    <PopUp set={closeModal} disableCloseModal={libraryLoadingFiles?.isOpen || projectLibraryLoadingFiles?.isOpen}>
      <HeaderModal onClose={closeModal} title={titleHead || __("Создать")} />
      <div
        className={classNames(styles.wrap, {
          [styles.loading]: libraryLoadingFiles?.isOpen || projectLibraryLoadingFiles?.isOpen
        })}
      >
        <form className={styles.contentWrap}>
          <div className={styles.topWrap}>
            <CreateDataObject
              data={data}
              dispatchData={dispatchData}
              folderFiles={filesFolder}
              onSetFilesFolder={setFilesFolder}
              onSetRemovedFilesFolder={setRemovedFilesFolder}
            />
          </div>
          <div className={styles.buttonsWrap}>
            <Button
              type="button"
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Отменить")}
              onClick={closeModal}
            />
            <Button
              type="button"
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={btnLabel || __("Создать")}
              onClick={onSubmitDataObj}
              disabled={getErrorForm() || getErrorEdit()}
            />
          </div>
        </form>
      </div>
      {(libraryLoadingFiles?.isOpen || projectLibraryLoadingFiles?.isOpen) && (
        <CircleProgress
          done={libraryLoadingFiles?.done || projectLibraryLoadingFiles?.done}
          percent={libraryLoadingFiles?.percent || projectLibraryLoadingFiles?.percent}
        />
      )}
    </PopUp>
  );
};
