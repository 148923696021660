import { IProjectTask } from "models/store/joinProjects/joinProgects";

export interface ITasksOverdueModalState {
  open: boolean;
  task: IProjectTask;
  callbackOk?: () => void;
  callbackCancel?: () => void;
}

export function initialTasksOverdueState(): ITasksOverdueModalState {
  return { open: false, task: null, callbackOk: () => {}, callbackCancel: () => {} };
}
