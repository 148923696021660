import { ILibraryRubric } from "models/store/library/library";

export interface IRemoveNonEmptyRubricLibraryModalState {
  open: boolean;
  id_dep_del?: string;
  rubric?: ILibraryRubric;
}

export const initialRemoveNonEmptyRubricLibraryModalState = (): IRemoveNonEmptyRubricLibraryModalState => {
  return { open: false };
};
