import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow-down.svg";
import classNames from "classnames";
import { useSystemFoldersDoc } from "collections/joinProject";
import { useSystemFolder } from "generalComponents/collections/folder";
import FileTags from "generalComponents/FileTags/FileTags";
import { useSetOpenFolders } from "generalComponents/Hooks/Folders/useSetOpenFolders";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { PAGE_DEPTH } from "generalComponents/variables/global";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { IFolderInfoProps } from "models/generalComponents/folderInfo";
import { DEFAULT_PROJECT_DOC_FOLDERS, IDefaultFolders } from "models/generalComponents/folders";
import React, { useMemo } from "react";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";

import styles from "./FolderInfo.module.sass";

const FolderInfo: React.FC<IFolderInfoProps> = ({
  className,
  folder,
  indent,
  listCollapsed,
  isChosen,
  contextMenu
}): JSX.Element => {
  const { openFolder } = useFoldersSelectors();
  const { onSetOpenFolders } = useSetOpenFolders();
  const { onSendOpenFolder, onSetFolderPassModal } = useActions();
  const SYSTEM_FOLDERS: Record<string, IDefaultFolders> = useSystemFolder();
  const PROJECT_SYSTEM_DOC_FOLDERS = useSystemFoldersDoc();

  const handleOpenFolder = (): void => {
    if (openFolder.includes(folder.id_dir)) {
      onSendOpenFolder(openFolder.filter((id) => id !== folder.id_dir));
      return;
    }

    onSetOpenFolders(folder.id_dir);
  };

  const toggleOpen = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();

    if (!isOpen && folder?.pass && folder.pass !== NO_VALUE) {
      onSetFolderPassModal({
        open: true,
        type: "enterPassword",
        folder,
        callbackOk: () => handleOpenFolder()
      });
      return;
    }

    handleOpenFolder();
  };

  const renderFolderIcon = (): JSX.Element => {
    if (folder.dep === PAGE_DEPTH.FILES && folder.is_system === "1") return SYSTEM_FOLDERS[folder.name]?.icon;

    return <ItemIcon color={folder.color} passIcon={folder.is_pass === 1} isFolder />;
  };

  const renderFolderName = (): string => {
    if (folder.dep !== PAGE_DEPTH.FILES && folder.is_system === "1") {
      return PROJECT_SYSTEM_DOC_FOLDERS[folder.name as DEFAULT_PROJECT_DOC_FOLDERS]?.name;
    }

    return folder.is_system === "1" ? SYSTEM_FOLDERS[folder.name].nameRu : folder.name;
  };

  const isOpen: boolean = useMemo((): boolean => openFolder.includes(folder.id_dir), [folder.id_dir, openFolder]);

  return (
    <div
      className={classNames(styles.folderBox, styles[className], {
        [styles.chosenFolder]: isChosen
      })}
      style={{ paddingLeft: indent }}
    >
      <div className={styles.iconWrapper}>{renderFolderIcon()}</div>
      <div
        className={classNames(styles.folderInfoWrapper, {
          [styles.collapsed]: listCollapsed
        })}
      >
        <div className={styles.folderInfo}>
          <div className={styles.nameWrapper}>
            <span className={styles.folderText}>{renderFolderName()}</span>
            {folder?.tags.length > 0 && <FileTags item={folder} />}
          </div>
          {(folder.emo || folder.fig) && (
            <div className={styles.rowStart}>
              {folder?.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: folder.emo }} />}
            </div>
          )}
        </div>
        <div className={styles.rowStart}>
          {folder?.folders?.length > 0 && (
            <button onClick={toggleOpen} className={styles.buttonArrow} type="button">
              <ArrowIcon
                className={classNames(styles.arrowIcon, {
                  [styles.active]: isOpen
                })}
              />
            </button>
          )}
          {contextMenu && contextMenu?.length !== 0 && <PopoverContextMenu data={contextMenu} />}
        </div>
      </div>
    </div>
  );
};

export default FolderInfo;
