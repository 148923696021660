import { useListWarningsAccountDeleting } from "collections/profile";
import Button from "generalComponents/Button/Button";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialApproveModalState } from "models/store/Cabinet/modals/modals";
import { FC } from "react";
import { useLocales } from "react-localized";

import styles from "./AccountDeleting.module.sass";

export const AccountDeleting: FC = (): JSX.Element => {
  const { __ } = useLocales();

  const { onSetApproveModal, onSetAccountDeletingModal } = useActions();
  const listWarningsAccountDeleting = useListWarningsAccountDeleting();

  const onApproveAccountDeletion = () => {
    const params = {
      titleHead: __("Удалить аккаунт"),
      title: __("Вы действительно хотите удалить аккаунт?"),
      approveBtn: __("Удалить аккаунт"),
      list: listWarningsAccountDeleting,
      approveBtnVariant: ButtonVariantType.RED,
      callback: (): void => {
        onSetAccountDeletingModal({ open: true });
        onSetApproveModal(initialApproveModalState());
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  return (
    <div className={styles.box}>
      <Button
        variant={ButtonVariantType.RED}
        onClick={onApproveAccountDeletion}
        size={ButtonSizeType.MEDIUM}
        text={__("Удалить аккаунт")}
      />
    </div>
  );
};
