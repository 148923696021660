import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import Button from "generalComponents/Button/Button";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import Role from "Pages/Cabinet/Components/Project/Components/Role/Role";
import PropTypes from "prop-types";
import React from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { projectRoleProps } from "types/Project";

import styles from "./AddedRoles.module.sass";

const AddedRoles = ({ onClose, params }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const onAddUsers = () => dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.ADD_USERS, params }));

  return (
    <div className={styles.wrap}>
      <CheckIcon className={styles.checkIcon} />
      <div className={styles.roles}>
        {params.map((role) => (
          <Role key={role.id} role={role} />
        ))}
      </div>
      <p className={styles.text}>
        {__(
          "Выбранные Вами роли успешно добавленны, в дольнейшем вы сможите добавить участиков ролей или сделайте это сейчас нажав на кнопку добавить участников проекта"
        )}
      </p>
      <div className={styles.btns}>
        <Button type="button" variant="ok" text={__("Готово")} onClick={onClose} />
        <Button type="button" variant="ok" text={__("Добавить участников проекта")} onClick={onAddUsers} />
      </div>
    </div>
  );
};

export default AddedRoles;
AddedRoles.propTypes = {
  onClose: PropTypes.func,
  params: PropTypes.arrayOf(projectRoleProps)
};
