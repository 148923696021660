import Button from "generalComponents/Button/Button";
import { useTags } from "generalComponents/collections/elements";
import Colors from "generalComponents/Elements/Colors";
import Emoji from "generalComponents/Elements/Emoji";
import SelectChosen from "generalComponents/SelectChosen/SelectChosen";
import { useProjectMsg } from "generalComponents/Services/projectServices";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { IAddLinkProps } from "models/project/addLink";
import { ILinkProject } from "models/store/projects/projectStore";
import { ITopMessages } from "models/store/projects/projectThunk";
import React, { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./AddLink.module.sass";

const AddLink: React.FC<IAddLinkProps> = ({ onClose, type, params }): JSX.Element => {
  const { __ } = useLocales();
  const tags = useTags();
  const MSG = useProjectMsg();
  const { handlerLinks } = useActions();

  const [name, setName] = useState<string>(params.name ?? "");
  const [link, setLink] = useState<string>(params.link ?? "");

  const [color, setColor] = useState<string>(params.color ?? "");
  const [emo, setEmo] = useState<string>(params.emo ?? "");
  const [tag, setTag] = useState<string>(params.tags ?? "");

  const onSubmit = (): void => {
    const payload: ILinkProject = {
      name,
      link,
      color,
      emo,
      tags: tag,
      id: params.id
    };
    const messages: ITopMessages = {
      error: MSG.ERROR,
      success: type === PROJECT_MODALS.ADD_LINK ? MSG.ADD_LINK : MSG.EDIT_LINK
    };
    type === PROJECT_MODALS.ADD_LINK ? handlerLinks(payload, "add", messages) : handlerLinks(payload, "edit", messages);
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => setName(e.target.value);
  const onChangeUrl = (e: React.ChangeEvent<HTMLInputElement>): void => setLink(e.target.value);

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        type="text"
        name="taskName"
        value={name}
        onChange={onChangeName}
        placeholder={__("Имя ссылки")}
      />
      <input
        className={styles.input}
        type="text"
        name="taskName"
        value={link}
        onChange={onChangeUrl}
        placeholder={__("Вставьте ссылку в поле")}
      />
      <div className={styles.tagWrap}>
        <SelectChosen
          value={tag}
          handleChange={(value) => setTag(value)}
          readOnly={false}
          placeholder={__("Выбирите тег из списка или введите вручную")}
          variant="low"
          onClear={() => setTag("")}
        >
          <div className={styles.tags}>
            {tags.map((tag, i) => (
              <p key={i} onClick={() => setTag(tag)}>
                {tag}
              </p>
            ))}
          </div>
        </SelectChosen>
      </div>
      <Colors color={color} setColor={setColor} />
      <Emoji emo={emo} setEmo={setEmo} />
      <div className={styles.btns}>
        <Button type="button" variant={ButtonVariantType.CANCEL} text={__("Отмена")} onClick={onClose} />
        <Button
          type="button"
          variant={ButtonVariantType.OK}
          text={type === PROJECT_MODALS.ADD_LINK ? __("Добавить") : __("Сохранить")}
          onClick={onSubmit}
          disabled={!name && !link}
        />
      </div>
    </div>
  );
};

export default AddLink;
