import { CHAT_CALLROOM_ACTIONS } from "generalComponents/variables/chat";
import { Component } from "react";

import { connectedUsersToProfileAndOthers } from "../../../../../../generalComponents/Services/chatServices";
import { ICallRoomConnectedUser } from "../../../../../../models/store/chat/chat";
import {
  DrawParams,
  IDrawCircleParams,
  IDrawSquareParams,
  IToolOptions,
  WebSocketToolTypes
} from "../../../../../../models/workElements/minitoolbar";
import { ISocketPaintTransferData, PaintTools } from "../../../../../../models/workElements/previews";
import WebSocketBrush from "./WebSocketBrush";
import WebSocketCircle from "./WebSocketCircle";
import WebSocketEraser from "./WebSocketEraser";
import WebSocketMarker from "./WebSocketMarker";
import WebSocketPencil from "./WebSocketPencil";
import WebSocketSquare from "./WebSocketSquare";
import WebSocketTriangle from "./WebSocketTriangle";

class Tool extends Component {
  color: string;
  pushInDrawHistory: (it: string) => void;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D | null;
  socket: WebSocket | null;
  uid: string;
  connectedUsers: ICallRoomConnectedUser[];
  id_user: string;

  constructor(canvas: HTMLCanvasElement, options: IToolOptions) {
    super(canvas, options);
    this.color = options.color;
    this.pushInDrawHistory = options.pushInDrawHistory;
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");
    this.socket = options.socket;
    this.uid = options.uid;
    this.connectedUsers = options.connectedUsers;
    this.id_user = options.id_user;
    this.destroyEvents();
  }

  //TODO - check and delete
  width = {
    pencil: 1,
    penThin: 1,
    penThick: 2.5,
    eraser: 10,
    marker: 7,
    brush: 10
  };

  set strokeStyle(color: string) {
    if (this.ctx) {
      this.ctx.strokeStyle = color;
    }
  }

  set lineWidth(width: number) {
    if (this.ctx) {
      this.ctx.lineWidth = width;
    }
  }

  destroyEvents(): void {
    this.canvas.onmousemove = null;
    this.canvas.onmousedown = null;
    this.canvas.onmouseup = null;
    if (this.ctx) {
      this.ctx.shadowBlur = 0;
      this.ctx.globalAlpha = 1;
      this.ctx.strokeStyle = this.color;
    }
  }

  socketTransferData(drawCoordinates: DrawParams, tool: PaintTools, color: string): string {
    const message: ISocketPaintTransferData = {
      action: CHAT_CALLROOM_ACTIONS.DRAW_CANVAS,
      uid: this.uid,
      tool,
      drawCoordinates,
      color: color,
      lineWidth: this.ctx ? this.ctx.lineWidth : 1,
      ...connectedUsersToProfileAndOthers(this.connectedUsers, this.id_user)
    };
    return JSON.stringify(message);
  }

  sendSocketPaint(drawCoordinates: DrawParams, tool: PaintTools, color: string): void {
    this.socket && this.socket.send(this.socketTransferData(drawCoordinates, tool, color));
  }

  drawLine(
    drawCoordinates: number[][],
    tool: WebSocketBrush | WebSocketPencil | WebSocketMarker | WebSocketEraser
  ): void {
    if (this.ctx) {
      this.ctx.beginPath();
      const [initialX, initialY] = drawCoordinates.splice(0, 1)[0];
      this.ctx.moveTo(initialX, initialY);
      drawCoordinates.forEach(([x, y]) => {
        tool.draw(x, y);
      });
    }
  }

  drawWebSocketPaint(drawParams: DrawParams, tool: WebSocketToolTypes): void {
    switch (tool.name) {
      case PaintTools.BRUSH:
      case PaintTools.PENCIL:
      case PaintTools.MARKER:
      case PaintTools.ERASER: {
        this.drawLine(
          drawParams as number[][],
          tool as WebSocketBrush | WebSocketPencil | WebSocketMarker | WebSocketEraser
        );
        break;
      }
      case PaintTools.CIRCLE: {
        const { drawStartX, drawStartY, drawRadius } = drawParams as IDrawCircleParams;
        (tool as WebSocketCircle).draw(drawStartX, drawStartY, drawRadius);
        break;
      }
      case PaintTools.TRIANGLE: {
        const { drawStartX, drawStartY, drawWidth, drawHeight } = drawParams as IDrawSquareParams;
        (tool as WebSocketTriangle).draw(drawStartX, drawStartY, drawWidth, drawHeight);
        break;
      }
      case PaintTools.SQUARE: {
        const { drawStartX, drawStartY, drawWidth, drawHeight } = drawParams as IDrawSquareParams;
        (tool as WebSocketSquare).draw(drawStartX, drawStartY, drawWidth, drawHeight);
        break;
      }
      default: {
        break;
      }
    }
  }
}

export default Tool;
