import classNames from "classnames";
import Button from "containers/Authentication/Button/Button";

import styles from "./Question.module.sass";

const Question = ({
  length,
  question,
  answers,
  activeAnswers,
  onSelectAnswer
}: {
  length: string;
  question: string;
  answers: string[];
  activeAnswers?: string[];
  onSelectAnswer: (answer: string) => void;
}) => {
  return (
    <div className={styles.wrapper}>
      <span>{length}</span>
      <h4>{question}</h4>
      <div className={styles.answers}>
        {answers.map((item, index) => (
          <Button
            key={index}
            isActive={activeAnswers && activeAnswers.includes(item)}
            className={classNames(styles.answer, activeAnswers && activeAnswers.includes(item) && styles.active)}
            onClick={() => onSelectAnswer(item)}
          >
            {item}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Question;
