import { AllElementsType } from "../typeElements";
import { IUserInfo } from "../user/user";

export type ArchivedElementsType = Exclude<AllElementsType, IUserInfo>;

export type ArchivedElementsArrayType = ArchivedElementsType[];

export type AllElementsNextObjectType = Record<number, ArchivedElementsArrayType>;

export interface IArchivedFilesState {
  elements: ArchivedElementsArrayType;
  total: number;
  page: number;
  loader: boolean;
}

export function initialArchivedFilesState(): IArchivedFilesState {
  return {
    elements: null,
    total: 0,
    page: 1,
    loader: false
  };
}
