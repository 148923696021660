import { ReactComponent as CloseIcon } from "assets/PrivateCabinet/closeModal.svg";
import { ReactComponent as ImageIcon } from "assets/PrivateCabinet/image.svg";
import classNames from "classnames";
import AudioPlayer from "generalComponents/AudioPlayer/AudioPlayer";
import PopUp from "generalComponents/PopUp/PopUp";
import { isSafari } from "generalComponents/Services/browserServices";
import { getMedia } from "generalComponents/Services/mediaServices";
import { projectSrc } from "generalComponents/variables/globalVariables";
import VideoPlayer from "generalComponents/VideoPlayer/VideoPlayer";
import { ILetterPreview } from "models/store/Cabinet/modals/postboxModal";
import { FC, useEffect, useState } from "react";
import { useLocales } from "react-localized";

import HeaderModal from "../../HeaderModal/HeaderModal";
import { PdfView } from "../../PdfView/PdfView";
import styles from "./MailFileView.module.sass";

interface Props {
  closeModal: () => void;
  preview: ILetterPreview;
}
const MailFileView: FC<Props> = ({ closeModal, preview }): JSX.Element => {
  const { __ } = useLocales();

  const [audio, setAudio] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [collapse, setCollapse] = useState<boolean>(false);
  const onClose = (): void => {
    closeModal();
  };
  const { link, mime_type, name } = preview;
  const FILE_PREVIEW = link;

  const renderFile = (): JSX.Element => {
    if (mime_type.startsWith("application/pdf")) {
      return (
        <div className={styles.pdfContainer}>
          <PdfView src={`https://fs2.mh.net.ua${link}`} />
        </div>
      );
    }

    if (mime_type.startsWith("image")) {
      return (
        <>
          <HeaderModal title={name} onClose={onClose} />
          <div className={styles.imageBlock}>
            <img src={`${projectSrc}${link}`} alt="filePrieview" className={styles.image} />
          </div>
        </>
      );
    }

    return (
      <>
        <HeaderModal title={name} onClose={onClose} />
        <div className={styles.noformat}>
          <div>
            <ImageIcon width={293} height={213} fill="#9FAEAB" />
          </div>
          <p className={styles.text}>{__("Файл недоступен к просмотру")}</p>
        </div>
      </>
    );
  };
  const collapseToggle = (): void => setCollapse((prev) => !prev);

  useEffect(() => {
    if (mime_type && mime_type.includes("audio")) {
      isSafari() ? getMedia(FILE_PREVIEW, mime_type, setAudio) : setAudio(FILE_PREVIEW);
    }
    if (mime_type.includes("video")) {
      isSafari() ? getMedia(FILE_PREVIEW, mime_type, setVideo) : setVideo(FILE_PREVIEW);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (mime_type.startsWith("audio")) {
    return (
      <div className={classNames(styles.audiopopup, { [styles.collapse]: collapse })}>
        <div className={styles.wrap}>
          {!collapse && (
            <div className={styles.header}>
              <span>{name}</span>
              <button type="button" onClick={onClose} className={styles.close}>
                <CloseIcon width={18} height={18} />
              </button>
            </div>
          )}
          {audio && <AudioPlayer collapse={collapse} setCollapse={collapseToggle} onClose={onClose} audio={audio} />}
        </div>
      </div>
    );
  }

  if (mime_type.startsWith("video")) {
    return (
      <div className={classNames(styles.videopopup, { [styles.collapse]: collapse })}>
        <div className={styles.wrap}>
          {!collapse && (
            <div className={styles.header}>
              <span>{name}</span>
              <button type="button" onClick={onClose} className={styles.close}>
                <CloseIcon width={18} height={18} />
              </button>
            </div>
          )}
          {video && <VideoPlayer collapse={collapse} setCollapse={collapseToggle} onClose={onClose} video={video} />}
        </div>
      </div>
    );
  }

  return (
    <PopUp set={onClose} background={"rgba(0,0,0, 0.7"}>
      {renderFile()}
    </PopUp>
  );
};

export default MailFileView;
