import { useTaskModalTitles } from "collections/tasks";
import PopUp from "generalComponents/PopUp/PopUp";
import { TASK_MODALS } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { IAddPassword, IReadPassword } from "models/store/Cabinet/modals/taskModals";
import { IMyTask } from "models/store/tasks/tasks";
import { IAddTaskPayload } from "models/store/tasks/tasksPayloads";
import { FC, useState } from "react";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import AddComments from "./AddComments/AddComments";
import AddNote from "./AddNote/AddNote";
import AddPassword from "./AddPassword/AddPassword";
import EditTask from "./EditTask/EditTask";
import OpenNote from "./OpenNote/OpenNote";
import OpenTask from "./OpenTask/OpenTask";
import ReadPassword from "./ReadPassword/ReadPassword";
import RecoverPass from "./RecoverPass/RecoverPass";
import styles from "./TaskModals.module.sass";

const TaskModals: FC = (): JSX.Element => {
  const { onSetTaskModal } = useActions();
  const closeModal = (): void => {
    onSetTaskModal({ type: TASK_MODALS.NO_MODAL, params: null });
  };
  const [isClose, setIsClose] = useState<boolean>(false);

  const {
    taskModals: { type, params }
  } = useGlobalModalsSelectors();

  const TITLES = useTaskModalTitles();

  const onChangeField = (name: string, value: string): void => {
    onSetTaskModal({ type, params: { ...params, [name]: value } });
  };

  return (
    <PopUp set={closeModal} disableCloseModal={isClose}>
      <form className={styles.taskModalsWrap}>
        {type !== TASK_MODALS.OPEN_NOTE && <HeaderModal title={TITLES[type]} onClose={closeModal} />}
        <div className={styles.body}>
          {(type === TASK_MODALS.ADD_NOTE || type === TASK_MODALS.EDIT_NOTE) && (
            <AddNote type={type} params={params as IMyTask} closeModal={closeModal} onChangeField={onChangeField} />
          )}
          {type === TASK_MODALS.OPEN_NOTE && <OpenNote params={params as IMyTask} closeModal={closeModal} />}
          {(type === TASK_MODALS.ADD_TASK ||
            type === TASK_MODALS.EDIT_TASK ||
            type === TASK_MODALS.ADD_MEETING ||
            type === TASK_MODALS.EDIT_MEETING ||
            type === TASK_MODALS.ADD_CALL ||
            type === TASK_MODALS.EDIT_CALL ||
            type === TASK_MODALS.ADD_LETTER ||
            type === TASK_MODALS.EDIT_LETTER ||
            type === TASK_MODALS.EDIT_REMINDER ||
            type === TASK_MODALS.ADD_REMINDER) && (
            <EditTask
              type={type}
              params={params as IMyTask}
              closeModal={closeModal}
              onChangeField={onChangeField}
              setIsClose={setIsClose}
            />
          )}

          {type === TASK_MODALS.OPEN_TASK && <OpenTask params={params as IMyTask} />}

          {type === TASK_MODALS.ADD_COMMENT_TASK && (
            <AddComments type={type} closeModal={closeModal} params={params as IAddTaskPayload} />
          )}
          {(type === TASK_MODALS.ADD_PASSWORD || type === TASK_MODALS.NEW_PASSWORD) && (
            <AddPassword type={type} params={params as IAddPassword} />
          )}
          {(type === TASK_MODALS.READ_PASSWORD || type === TASK_MODALS.ENTER_CODE) && (
            <ReadPassword type={type} closeModal={closeModal} params={params as IReadPassword} />
          )}
          {type === TASK_MODALS.RECOVER_PASSWORD && (
            <RecoverPass type={type} closeModal={closeModal} params={params as IReadPassword} />
          )}
        </div>
      </form>
    </PopUp>
  );
};

export default TaskModals;
