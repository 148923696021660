import api from "api";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile, initialPickedItems } from "models/store/files/files";
import { FolderUrlTypes } from "models/store/folders/foldersPayloads";
import { IFolder } from "models/store/folders/foldersStore";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

const DeleteBtn: FC = () => {
  const { __ } = useLocales();
  const { safeDir } = useParams();
  const safe = useAuthorizedSafe();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const {
    onDeleteFiles,
    onGetSafeFiles,
    onEditFolders,
    onDelSafeFolder,
    onSetFolders,
    onSetPickedItems,
    onSetLoaderModal,
    onSetApproveModal,
    onDeleteHardFiles,
    onSetTopMessageModal,
    onSetDelFiles,
    onSetDelNextFiles
  } = useActions();
  const {
    pickedItems: { items }
  } = useFilesSelectors();
  const { uid } = useUserSelectors();

  const { currentFolder } = useFoldersSelectors();

  const { isLinesPreviewView } = useGetItemView();

  const onClickTrashButton = (): void => {
    const getTrashTitle = (): string => {
      if (items.every((item) => item.is_dir === 1)) {
        if (items.length > 1) {
          return __("Удаление папок");
        } else {
          return __("Удаление папки");
        }
      }

      if (items.every((item) => item.is_dir === 0)) {
        if (items.length > 1) {
          return __("Удаление файлов");
        } else {
          return __("Удаление файла");
        }
      }

      return __("Удаление файлов и папок");
    };

    const getTrashText = (): string => {
      if (items.every((item) => item.is_dir === 1)) {
        if (items.length > 1) {
          return __("Вы действительно хотите удалить выбранные папки?");
        } else {
          return __("Вы действительно хотите удалить выбранную папку?");
        }
      }

      if (items.every((item) => item.is_dir === 0)) {
        if (items.length > 1) {
          return __("Вы действительно хотите удалить выбранные файлы?");
        } else {
          return __("Вы действительно хотите удалить выбранный файл?");
        }
      }

      return __("Вы действительно хотите удалить выбранные файлы и папки?");
    };

    const params = {
      titleHead: getTrashTitle(),
      text: getTrashText(),
      approveBtn: __("Удалить"),
      callback: async (): Promise<void> => {
        // deleting folders
        if (items.every((item) => item.is_dir === 1)) {
          const id_dirs = (items as IFolder[]).map((folder) => folder.id_dir);

          const payload = {
            id_dirs,
            is_del: 1
          };
          const payloadSafe = {
            ...safe,
            ...payload,
            id_parent: safeDir || "0"
          };

          isSafePage
            ? onDelSafeFolder(payloadSafe)
            : onEditFolders(payload, EVENT_TYPE.IS_DEL, () => {
                if (isLinesPreviewView) {
                  onSetDelNextFiles(items);
                } else {
                  if (items.some((item) => (item as IFolder).id_parent === currentFolder?.id_dir)) {
                    onSetDelFiles({ ids: id_dirs });
                  }
                }
              });
          return;
        }

        // deleting files
        if (items.every((item) => item.is_dir === 0)) {
          const payload = {
            fids: (items as IFile[]).map((file) => file.fid),
            id_dir: isSafePage ? safeDir || "0" : undefined,
            files: (items as IFile[]).filter((el) => el.is_dir === 0) || undefined
          };

          isSafePage ? onDeleteHardFiles(payload, safe) : onDeleteFiles(payload);
          return;
        }
        // deleting files and folders
        const id_dirs = (items as IFolder[]).filter((folder) => folder.is_dir === 1).map((folder) => folder.id_dir);
        const fids = (items as IFile[]).filter((file) => file.is_dir === 0).map((file) => file.fid);

        const ids = [...id_dirs, ...fids];

        const foldersParams = {
          uid,
          id_dirs,
          is_del: 1
        };

        const filesParams = {
          uid,
          fids,
          is_del: 1
        };

        const safeFilesParams = {
          uid,
          fids: (items as IFile[]).filter((file) => file.is_dir === 0).map((file) => file.fid),
          code: safe?.code,
          pass: safe?.pass,
          id_safe: safe?.id_safe
        };

        const safeFolderParams = {
          ...safe,
          id_parent: safeDir || "0",
          id_dirs: (items as IFolder[]).filter((folder) => folder.is_dir === 1).map((folder) => folder.id_dir)
        };

        try {
          onSetLoaderModal(true);
          const [foldersResponse, filesResponse] = await Promise.all(
            isSafePage
              ? [
                  api.get(`/ajax/safe_file_del.php`, { params: safeFilesParams }),
                  api.get("/ajax/safe_folders_del.php", { params: safeFolderParams })
                ]
              : [
                  api.get(`/ajax/${FolderUrlTypes.DIR_EDIT}`, { params: foldersParams }),
                  api.get("/ajax/file_edit.php", { params: filesParams })
                ]
          );

          if (foldersResponse.data.ok === 1 && filesResponse.data.ok === 1) {
            onSetPickedItems(initialPickedItems());

            console.log(items);

            if (isLinesPreviewView) {
              onSetDelNextFiles(items);
            } else {
              if (items.some((item) => (item as IFolder).id_parent === currentFolder?.id_dir)) {
                onSetDelFiles({ ids });
              }
            }

            onSetTopMessageModal({
              open: true,
              type: ITopMessageTypes.SUCCESS,
              message: __("Файлы и папки удалены!")
            });
            if (isSafePage) {
              onGetSafeFiles({ code: safe.code, id_safe: safe.id_safe, id_dir: safeDir || "0" });
              return;
            }
            onSetFolders(foldersResponse.data.folders.global, foldersResponse.data.folders.other);
            // onGetMyFiles();
          } else {
            throw new Error();
          }
        } catch {
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          });
        } finally {
          onSetLoaderModal(false);
        }
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  return (
    <button onClick={onClickTrashButton} type="button">
      {__("Удалить")}
    </button>
  );
};

export default DeleteBtn;
