import { ReactComponent as CleaningIcon } from "assets/icons/cleaning.svg";
import classNames from "classnames";
import { useMenuFilterTypeElements } from "collections/menuFilterTypeElements";
import Button from "generalComponents/Button/Button";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import NavigationOption from "generalComponents/FilesServicePanel/NavigationOption/NavigationOption";
import TempSort from "generalComponents/FilesServicePanel/TempSort/TempSort";
import ViewTipeTabs from "generalComponents/FilesServicePanel/ViewTipeTabs/ViewTipeTabs";
import { FilterMarkers } from "generalComponents/FilterMarkers/FilterMarkers";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { ITopMessages } from "models/common/common";
import { ButtonVariantType } from "models/generalComponents/button";
import { ButtonSizeType } from "models/generalComponents/button";
import { IServePanel } from "models/servePanel";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router";

import { AutomaticСleaning } from "./components/AutomaticСleaning";
import styles from "./TrashServicePanel.module.sass";

export const TrashServicePanel: FC<IServePanel> = (): JSX.Element => {
  const { __ } = useLocales();
  const { onSetApproveModal, onFullCleaningTrash } = useActions();

  const {
    objSearchParams: { year, month, day, table, id_type },
    onSetObjSearchParams
  } = useSelectedObjSearchParams();

  const { trashDirId } = useParams();

  const optionsTypeElements = useMenuFilterTypeElements();

  const defaultOptionTypeElement = optionsTypeElements.find(({ value }) => value === table || value === id_type);

  const onChangeDateFilter = (v: Date): void => {
    const y = v ? v.getFullYear().toString() : undefined;
    const m = v ? (v.getMonth() + 1).toString() : undefined;
    const d = v ? v.getDate().toString() : undefined;

    onSetObjSearchParams({
      [SEARCH_PARAMS.YEAR]: y ?? "",
      [SEARCH_PARAMS.MONTH]: m ?? "",
      [SEARCH_PARAMS.DAY]: d ?? ""
    });
  };

  const renderSelectedDate = (): Date => {
    if (year && month && day) {
      return new Date(Number(year), Number(month) - 1, Number(day));
    }
    return null;
  };

  const onСleansСompletely = () => {
    const params = {
      titleHead: __("Очистить"),
      title: __("Вы действительно хотите очистить корзину?"),
      text: __("Это действие необратимо"),
      approveBtn: __("Очистить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => {
        const messages: ITopMessages = {
          success: __("Корзина очищена"),
          error: __("Не удалось очистить корзину")
        };
        onFullCleaningTrash(messages);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  return (
    <ToolsPanel size="large">
      <div className={styles.groupStart}>
        <NavigationOption />
        <ViewTipeTabs miniTipeTabs />
        <div className={styles.groupStart}>
          <TempSort />
          <FilterMarkers />
        </div>
        {!trashDirId && (
          <div className={styles.groupStart}>
            <SelectCustom
              width={200}
              variantHeight="small"
              options={optionsTypeElements}
              selectedDefault={{
                name: defaultOptionTypeElement?.name || optionsTypeElements[0]?.name,
                value: defaultOptionTypeElement?.value || optionsTypeElements[0]?.value
              }}
            />
            <InputDate
              startDate={renderSelectedDate()}
              setStartDate={(v: Date) => onChangeDateFilter(v)}
              dateFormat="dd MMM yyyy"
              isClearable
              maxDate={new Date()}
            />
          </div>
        )}
      </div>
      <div className={classNames(styles.groupStart, styles.groupStart_right)}>
        <AutomaticСleaning />
        <Button
          variant={ButtonVariantType.BLUE}
          onClick={onСleansСompletely}
          size={ButtonSizeType.SMALL}
          iconL={<CleaningIcon />}
          text={__("Очистить")}
          disabled={false}
        />
      </div>
    </ToolsPanel>
  );
};
