import { ReactComponent as DefaultSign } from "assets/figures/heart-13.svg";
import { ReactComponent as CloseModal } from "assets/PrivateCabinet/closeModal.svg";
import classNames from "classnames";
import PopUp from "generalComponents/PopUp/PopUp";
import { switchSign } from "generalComponents/Services/elementsServices";
import { SIGNS } from "generalComponents/variables/elements";
import { ISignsProps } from "models/generalComponents/elements";
import React, { useState } from "react";

import SignItem from "./SignItem";
import styles from "./Signs.module.sass";

const Signs: React.FC<ISignsProps> = ({ sign, setSign, disabled = false }): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const setElement = (el: SIGNS): void => {
    sign === el ? setSign(SIGNS.NO_SIGN) : setSign(el);
    toggleOpen();
  };
  const signs: SIGNS[] = Object.values(SIGNS).filter((el) => el !== SIGNS.NO_SIGN);
  const toggleOpen = (): void => setIsOpen((open) => !open);

  return (
    <>
      <button type="button" className={styles.button} onClick={toggleOpen} disabled={disabled}>
        {sign ? <SignItem sign={sign} size={15} /> : <DefaultSign className={styles.default} />}
      </button>

      {isOpen && (
        <PopUp set={toggleOpen}>
          <ul className={styles.wrap}>
            {signs.map((el, i) => (
              <li key={i} className={classNames({ [styles.marginRight]: i === 5 })}>
                <button
                  className={classNames(styles.emojiItem, { [styles.selected]: sign === el })}
                  onClick={() => setElement(el)}
                  type="button"
                >
                  {switchSign(el)}
                </button>
              </li>
            ))}
            <button className={styles.closeBtn} onClick={toggleOpen}>
              <CloseModal width={15} height={15} />
            </button>
          </ul>
        </PopUp>
      )}
    </>
  );
};

export default Signs;
