import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classNames from "classnames";
import TeamButtons from "containers/JoinProject/JointProjectsTasks/components/TeamButtons/TeamButtons";
import Avatar from "generalComponents/Avatar/Avatar";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import SearchField from "generalComponents/SearchField/SearchField";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useFindProjectUser, useFindTeam, useProjectParticipants } from "hooks/joinProjectHooks";
import { FC, MouseEvent, useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./TaskExecutor.module.sass";

interface IProps {
  executor: string;
  setExecutor: (val: string) => void;
  team: string;
  setTeam: (val: string) => void;
  readOnly?: boolean;
}

const TaskExecutor: FC<IProps> = ({ executor, setExecutor, team, setTeam, readOnly = false }) => {
  const { __ } = useLocales();
  const findUser = useFindProjectUser(executor);
  const { project } = useJoinProjectsSelectors();
  const findTeam = useFindTeam();
  const { projectParticipant } = useProjectParticipants();
  const [show, setShow] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [idTeam, setIdTeam] = useState<string>(team);

  useEffect(() => setIdTeam(team), [team]);

  const onSelectTeam = (id: string) => setIdTeam((prev) => (prev === id ? "" : id));

  const filtredParticipant = useMemo(
    () =>
      projectParticipant
        .filter((item) => {
          const _team = findTeam(idTeam);
          if (_team) {
            return findTeam(idTeam).users.includes(item.id);
          }
          return item;
        })
        .filter((item) => {
          const nameMatches = item.fname.toLowerCase().includes(value.toLowerCase());
          const snameMatches = item.sname.toLowerCase().includes(value.toLowerCase());
          return nameMatches || snameMatches;
        }),
    [findTeam, idTeam, projectParticipant, value]
  );

  const handleChange = (value: string): void => {
    setExecutor(value);
    setShow(false);

    const teams = project.teams.filter((t) => t.users.includes(value));
    if (teams.length === 1) {
      setTeam(teams[0].id);
    } else if (!findTeam(team).users.includes(value)) {
      setTeam("");
    }
  };

  const renderOverlay = () => {
    return (
      <div>
        <SearchField value={value} setValue={setValue} placeholder={__("Ведите")} />
        <div className={styles.teams}>
          <TeamButtons onSelectTeam={onSelectTeam} selectedTeam={idTeam} />
        </div>
        <ul className={styles.participants}>
          <li className={styles.item} onClick={() => handleChange("")}>
            <span>{__("Нет исполнителя")}</span>
          </li>
          {filtredParticipant.map((item) => (
            <li key={item.id} className={styles.item} onClick={() => handleChange(item.id)}>
              <Avatar name={item.fname + " " + item.sname} imgSrc={projectSrc + item.icon} size={24} />
              <span>{item.fname + " " + item.sname}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <PopoverOverlay
      show={show}
      overlayContent={renderOverlay()}
      setShow={setShow}
      placement="bottom"
      btn="extra"
      width={440}
    >
      <div
        className={classNames(styles.label, { [styles.active]: show })}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          !readOnly && setShow(true);
        }}
      >
        {executor ? (
          <>
            <Avatar
              name={findUser.fname + " " + findUser.sname}
              imgSrc={projectSrc + findUser.icon}
              size={24}
              isRound
            />
            <span>{findUser.fname + " " + findUser.sname}</span>
          </>
        ) : (
          <span>{__("Нет исполнителя")}</span>
        )}
        <ArrowIcon className={classNames(styles.arrow, { [styles.arrowOpen]: show })} />
      </div>
    </PopoverOverlay>
  );
};

export default TaskExecutor;
