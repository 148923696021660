import { ReactComponent as NoteIcon } from "assets/icons/note.svg";
import { ReactComponent as BellIcon } from "assets/PrivateCabinet/bell.svg";
import { ReactComponent as DotsIcon } from "assets/PrivateCabinet/dots-vertical-round.svg";
import { ReactComponent as MailIcon } from "assets/PrivateCabinet/mail/unread.svg";
import { ReactComponent as MeetingIcon } from "assets/PrivateCabinet/meeting.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-5.svg";
import { ReactComponent as TaskIcon } from "assets/PrivateCabinet/sideMenu/tasks.svg";
import classNames from "classnames";
import { useContextArchivedTasks } from "collections/archive";
import { useContextTrashTasks } from "collections/trash";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { ROUTES } from "generalComponents/variables/routing";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { useDateFormat } from "hooks/useDateFormat";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { IMyTask } from "models/store/tasks/tasks";
import { FC, useState } from "react";
import { useLocation } from "react-router";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

import styles from "./TaskBox.module.sass";

export interface ITaskBox {
  task: IMyTask;
}

export const TaskBox: FC<ITaskBox> = ({ task }): JSX.Element => {
  const { pathname } = useLocation();

  const { formatDate } = useDateFormat();

  const { view } = useFilesSelectors();

  const [isHover, setIsHover] = useState<boolean>(false);

  const taskType = task.id_type;

  const onHover = (): void => setIsHover(true);
  const outHover = (): void => {
    setIsHover(false);
  };

  const { contextMenuArchivedTasksCallback } = useContextArchivedTasks();
  const { contextMenuTrashTasksCallback } = useContextTrashTasks();

  const isArchive = pathname.startsWith(`/${ROUTES.ARCHIVE}`);
  const isTrash = pathname.startsWith(`/${ROUTES.TRASH}`);

  const renderIcon = (): JSX.Element => {
    switch (taskType) {
      case TASK_TYPES.MAILS:
        return <MailIcon width={30} height={30} fill="#F0BA2E" />;

      case TASK_TYPES.CALLS:
        return <PhoneIcon width={30} height={30} fill="#2ABD27" />;

      case TASK_TYPES.TASK:
        return <TaskIcon width={30} height={30} fill="#4086F1" />;

      case TASK_TYPES.OFFLINE_MEETING:
      case TASK_TYPES.ONLINE_MEETING:
        return <MeetingIcon width={30} height={30} />;

      case TASK_TYPES.REMINDER:
        return <BellIcon width={30} height={30} fill="#26c0ca" />;

      case TASK_TYPES.NOTES:
        return <NoteIcon width={30} height={30} />;
    }
  };

  const renderContextMenu = (): IPopoverData[] => {
    if (isArchive) {
      return contextMenuArchivedTasksCallback(task);
    }

    if (isTrash) {
      return contextMenuTrashTasksCallback(task);
    }

    return [];
  };

  return (
    <div
      className={classNames(styles.card, {
        [styles.hover]: isHover,
        [styles[view]]: view
      })}
      onMouseEnter={onHover}
      onMouseLeave={outHover}
    >
      <div
        className={classNames(styles.card__wrapp, {
          [styles[view]]: view
        })}
      >
        <div
          className={classNames(styles.card__head, {
            [styles[view]]: view
          })}
        >
          {renderIcon()}
          <p className={styles.card__name}>{task.name}</p>
        </div>
        <div
          className={classNames(styles.card__body, {
            [styles[view]]: view
          })}
        >
          {task?.date_start && task?.date_end && (
            <span
              className={classNames(styles.card__date, {
                [styles[view]]: view
              })}
            >
              {formatDate(task.date_start)} {task.date_end && `- ${formatDate(task.date_end)}`}
            </span>
          )}
          {task?.prim && (
            <div
              className={classNames(styles.card__info, {
                [styles[view]]: view
              })}
              dangerouslySetInnerHTML={{ __html: task.prim }}
            />
          )}
        </div>
        <PopoverContextMenu data={renderContextMenu()} iconVariant="hidden" visible={isHover}>
          <DotsIcon fill="#E7EBEA" />
        </PopoverContextMenu>
      </div>
    </div>
  );
};
