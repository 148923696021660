import { useContentCategories } from "collections/library";
import { NavPrevPage } from "generalComponents/NavPrevPage/NavPrevPage";
import { LibraryCategoryTypes } from "generalComponents/variables/library";
import { ROUTES } from "generalComponents/variables/routing";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

interface INavigationLibraryOption {
  isLibrarySearch?: boolean;
  isLibrarySetttings?: boolean;
  libraryCategory: string;
  libraryDirId: string;
}

export const NavigationLibraryOption: FC<INavigationLibraryOption> = ({
  isLibrarySearch,
  isLibrarySetttings,
  libraryCategory,
  libraryDirId
}): JSX.Element => {
  const { __ } = useLocales();

  const { project } = useJoinProjectsSelectors();

  const contentCategory = useContentCategories();

  const folderInfo = project?.library?.folderInfo;

  const { id } = useParams();

  const renderPageName = () => {
    if (isLibrarySearch) {
      return __("Поиск");
    }

    if (isLibrarySetttings) {
      return __("Настройки");
    }

    if (libraryCategory) {
      return Object.values(contentCategory).find(
        ({ category }) => category === (libraryCategory as LibraryCategoryTypes)
      )?.title;
    }

    if (libraryDirId === folderInfo?.id_dir) {
      return folderInfo?.name;
    }
  };

  return <NavPrevPage title={renderPageName()} defaultPage={`${ROUTES.PROJECT}/${id}/${ROUTES.LIBRARY}`} />;
};
