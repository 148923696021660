import { ReactComponent as BellIcon } from "assets/PrivateCabinet/bell.svg";
import { ReactComponent as TaskIcon } from "assets/PrivateCabinet/calendar-4.svg";
import { ReactComponent as MailIcon } from "assets/PrivateCabinet/mail/unread.svg";
import { ReactComponent as MeetingIcon } from "assets/PrivateCabinet/meeting.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-5.svg";
import classNames from "classnames";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { ITasksPopover } from "models/calendarPage/tasksPopover";
import { FC, useState } from "react";
import { useLocales } from "react-localized";

import TaskPopoverItem from "../TaskPopoverItem/TaskPopoverItem";
import styles from "./TasksPopover.module.sass";

const TasksPopover: FC<ITasksPopover> = ({ tasks, type }): JSX.Element => {
  const { __ } = useLocales();
  const [show, setShow] = useState(false);

  const getIcon = (): JSX.Element => {
    switch (type) {
      case TASK_TYPES.TASK:
        return <TaskIcon width={12} height={14} />;
      case TASK_TYPES.MEETINGS:
        return <MeetingIcon />;
      case TASK_TYPES.CALLS:
        return <PhoneIcon />;
      case TASK_TYPES.MAILS:
        return <MailIcon />;
      case TASK_TYPES.REMINDER:
        return <BellIcon />;
      default:
        return;
    }
  };

  const getTitle = (): string => {
    switch (type) {
      case TASK_TYPES.TASK:
        return __("Задачи");
      case TASK_TYPES.MEETINGS:
        return __("Встречи");
      case TASK_TYPES.CALLS:
        return __("Звонки");
      case TASK_TYPES.MAILS:
        return __("Письма");
      case TASK_TYPES.REMINDER:
        return __("Напоминания");
      default:
        return;
    }
  };

  const renderTasks = (): JSX.Element => {
    return (
      <ul className={styles.taskList}>
        {tasks.map((task, i) => (
          <TaskPopoverItem key={task.id} task={task} type={type} num={i + 1} />
        ))}
      </ul>
    );
  };

  return (
    <PopoverOverlay
      overlayContent={renderTasks()}
      title={getTitle()}
      width={285}
      show={show}
      setShow={setShow}
      isCloseDelay
    >
      <span className={classNames(styles.color, styles[type])}>{getIcon()}</span>
    </PopoverOverlay>
  );
};

export default TasksPopover;
