import "theme/theme.sass";

import { ReactComponent as BurgerIcon } from "assets/PrivateCabinet/sideMenu/burger.svg";
import { ReactComponent as LogoIcon } from "assets/PrivateCabinet/sideMenu/logo.svg";
import { ReactComponent as LogoTextIcon } from "assets/PrivateCabinet/sideMenu/logo-text.svg";
import classNames from "classnames";
import { FC, useMemo, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { useMenu } from "./listHelper";
import styles from "./SideMenu.module.sass";

const SideMenu: FC = (): JSX.Element => {
  const { theme, rights } = useUserSelectors();
  const { menu } = useMenu();

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onClickBurgerButton = (): void => setCollapsed(!collapsed);

  const filtredMenu = useMemo(() => {
    if (!rights) return [];
    return menu.filter((el) => (el.access ? rights[el.access] : el));
  }, [menu, rights]);

  const renderMenuItems = (): JSX.Element[] => {
    return filtredMenu.map((item) => (
      <li key={item.path} className={styles.menuItem}>
        <NavLink
          to={`/${item.path}`}
          className={({ isActive }) => {
            return classNames(styles.menuLink, `menuItem-${theme}`, {
              [`menuItemChosen-${theme}`]: isActive,
              [styles.menuItemCollapsed]: collapsed
            });
          }}
          title={collapsed ? item.name : undefined}
        >
          <div
            className={classNames(styles.imageWrap, `background-${theme} border-inner-side-menu-${theme}`, {
              [styles.imageMargin]: !collapsed
            })}
          >
            {item.icon}
          </div>
          <div
            className={classNames(styles.menuName, {
              [styles.collapsed]: collapsed
            })}
          >
            {item.name}
          </div>
        </NavLink>
      </li>
    ));
  };

  return (
    <aside
      className={classNames(styles.asideWrap, `linear-${theme}`, {
        [styles.collapsed]: collapsed
      })}
    >
      <Link to="/" className={styles.logoWrapper}>
        <LogoIcon className={styles.logoIcon} />
        <LogoTextIcon
          className={classNames(styles.logoIconText, {
            [styles.collapsed]: collapsed
          })}
        />
      </Link>
      <div className={styles.burgerWrapper}>
        <button onClick={onClickBurgerButton} className={styles.burgerButton} type="button">
          <BurgerIcon />
        </button>
      </div>
      <nav className={styles.menuListWrapper}>
        <ul className={styles.menuList}>{renderMenuItems()}</ul>
      </nav>
    </aside>
  );
};

export default SideMenu;
