import { Image } from "generalComponents/Image/Image";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { IFile } from "models/store/files/files";
import { FC } from "react";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./PreviewFileLibrary.module.sass";

export interface IPreviewFileLibraryProps {
  file: IFile;
  clickable?: boolean;
}

export const PreviewFileLibrary: FC<IPreviewFileLibraryProps> = ({ file, clickable = true }): JSX.Element => {
  const { onSetAllFileViewModal } = useActions();
  const { user } = useUserSelectors();

  const previewImage = `${projectSrc}ajax/file_preview_image.php?uid=${user.uid}&fid=${file.fid}&type=png&w=200`;

  const onVisibleAllFileFiew = () => {
    onSetAllFileViewModal({
      open: true,
      params: file
    });
  };

  const folderName = file?.name;

  const isImage = (file.mime_type && file.mime_type.startsWith("image")) || file.preview_image;

  return (
    <div
      onClick={clickable ? onVisibleAllFileFiew : undefined}
      tabIndex={clickable ? 0 : undefined}
      role={clickable ? "button" : undefined}
      className={styles.card}
    >
      <div className={styles.card__head}>
        <div className={`${styles.card__imgBox} flex-center`}>
          {isImage && <Image src={previewImage} alt={folderName} />}
          {!isImage && <ItemIcon isFolder={false} width={33} height={44} color={file.color} ext={file.ext} />}
        </div>
      </div>
      <div className={styles.card__body}>
        <p className={styles.card__name}>{folderName}</p>
      </div>
    </div>
  );
};
