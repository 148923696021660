import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import UserBlock from "Pages/Cabinet/Components/Project/Components/UserBlock/UserBlock";
import { useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { onAppointRoles } from "Store/actions/ProjectActions";
import { getContacts } from "Store/actions/userAction";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import styles from "../AddUsers.module.sass";

const AddUsers = ({ onClose }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const MSG = useProjectMsg();
  const theme = useSelector((s) => s.user.userInfo.theme);
  const { project } = useProjectsSelectors();
  const contactList = useSelector((s) => s.Cabinet.contactList);
  const [contacts, setContacts] = useState([]);
  const [value, setValue] = useState("");
  const [chosenUsers, setChosenUsers] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    contactList ? onFiltredContacts(contactList) : dispatch(getContacts());
  }, [contactList]); // eslint-disable-line

  const onFiltredContacts = (contacts) => {
    const usersList = contacts.filter(
      (item) => project.users.findIndex((el) => el.id_user === item.id_real_user) < 0 && item.id_real_user !== "0"
    );
    setContacts(usersList);
    setloading(false);
  };

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  const filtredRoles = useMemo(() => {
    return contacts.filter((el) => el.name.toLowerCase().includes(value.toLowerCase()));
  }, [contacts, value]);

  const isChosen = (item) => {
    return chosenUsers.find((el) => el.id === item.id) ? true : false;
  };

  const onSelectRole = (item) => {
    if (isChosen(item)) {
      setChosenUsers((prev) => prev.filter((el) => el.id !== item.id));
    } else {
      setChosenUsers((prev) => [...prev, item]);
    }
  };

  const onSubmit = async () => {
    const users = chosenUsers.map((user) => ({
      id_user: user.id_real_user,
      name: user.name,
      sname: user.sname,
      icon: user.icon,
      id_role: "0",
      is_read: "0"
    }));

    project.roles.length > 0
      ? dispatch(
          onSetModals(MODALS.PROJECT, {
            type: PROJECT_MODALS.APPOINT_ROLES,
            callback: PROJECT_MODALS.ADD_USERS,
            params: users
          })
        )
      : dispatch(onAppointRoles(users, MSG.ERROR));
  };

  const toInviteUser = () => {};

  return (
    <div className={styles.wrap}>
      <span className={styles.subTitle}>{__("Введите имя для посика из списка")}</span>
      <input className={styles.input} value={value} onChange={handleChange} />
      <div className={styles.chosenUsers}>
        {chosenUsers.map((el) => (
          <UserBlock key={el.id} user={el} />
        ))}
      </div>
      <span className={styles.subTitle}>{__("Выберите из списка")}</span>
      <div className={classNames(styles.usersList, `scrollbar-medium-${theme}`)}>
        {loading ? (
          <Loader
            type="bounceDots"
            position={"relative"}
            background="transparent"
            zIndex={5}
            width="200px"
            height="200px"
            containerType="bounceDots"
          />
        ) : (
          <>
            {filtredRoles.map((el) => (
              <div key={el.id} onClick={() => onSelectRole(el)}>
                <UserBlock user={el} isChosen={isChosen(el)} />
              </div>
            ))}
          </>
        )}
      </div>
      <div className={styles.btns}>
        <Button type="button" variant="cancel" text={__("Отмена")} onClick={onClose} />
        <Button
          type="button"
          variant="ok"
          text={project.roles.length > 0 ? __("Назначить роль") : __("Добвить пользователей")}
          onClick={onSubmit}
          disabled={chosenUsers.length === 0}
        />
      </div>
      <p className={styles.text}>
        {__("Пригласить нововго пользователя в проект, для отправки приглошение нажмите на кнопку пригласить")}
      </p>
      <div className={styles.btns}>
        <Button type="button" variant="ok" text={__("Пригласить")} onClick={toInviteUser} />
      </div>
    </div>
  );
};

export default AddUsers;

AddUsers.propTypes = {
  onClose: PropTypes.func
};
