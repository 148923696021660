import api from "api";
import { ReactComponent as OpenIcon } from "assets/icons/open.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import Input from "generalComponents/Input/Input";
import Loader from "generalComponents/Loaders/4HUB";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useActions } from "hooks/useActions";
import { useDebounce } from "hooks/useDebounce";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { IProjectTask } from "models/store/joinProjects/joinProgects";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./JoinProjectSearchTasks.module.sass";

export const JoinProjectSearchTasks = () => {
  const { __ } = useLocales();

  const [showSearch, setShowSearch] = useState(false);

  const { project } = useJoinProjectsSelectors();

  const { uid } = useUserSelectors();

  const { onSetProjectTaskInfoModal } = useActions();

  const [search, setSearch] = useState("");
  const [result, setResult] = useState<IProjectTask[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [finish, setFinish] = useState<boolean>(false);

  const controller = new AbortController();

  const searchTasks = async () => {
    try {
      setLoader(true);

      const params = {
        uid,
        search,
        id_project: project.id
      };

      const { data } = await api.get("/ajax/task_get.php", { params, signal: controller.signal });

      setResult(data.tasks || []);

      setFinish(true);
    } catch {
      //
    } finally {
      setLoader(false);
    }
  };

  const debounceCallback = useDebounce(searchTasks, 300);

  useEffect(() => {
    setResult([]);
    if (search.length) {
      setFinish(false);

      setResult([]);

      debounceCallback();
    }
    return () => {
      controller.abort();
    };
  }, [search]); //eslint-disable-line

  const handleClick = (task: IProjectTask): void => {
    onSetProjectTaskInfoModal({ open: true, task });
  };

  return (
    <PopoverOverlay
      overlayContent={
        <div className={styles.content}>
          <Input
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder={__("Введите")}
            rightIcon={<SearchIcon fill="var(--color-d100)" />}
          />
          {loader ? (
            <div className={styles.loaderBox}>
              <Loader
                type={LoaderTypes.BOUNCING_DOTS}
                position="absolute"
                background="white"
                zIndex={5}
                width="100px"
                height="100px"
                containerType="bounceDots"
              />
            </div>
          ) : result.length > 0 ? (
            <ul className={styles.list}>
              {result.map((task) => (
                <li key={task.id} className={styles.list__item}>
                  <span className={styles.list__num}>{task.num}</span>
                  <p className={styles.list__name}>{task.name}</p>
                  <Button
                    variant={ButtonVariantType.EXRTA_LIGHT}
                    onClick={() => handleClick(task)}
                    size={ButtonSizeType.EXTRA_SMALL}
                    className={styles.list__btn}
                    iconL={<OpenIcon />}
                    isSquare
                  />
                </li>
              ))}
            </ul>
          ) : (
            finish && (
              <div className={styles.loaderBox}>
                <Clue text={__("Нет совпадений")} icon="sad" />
              </div>
            )
          )}
        </div>
      }
      placement="bottom"
      classes={{ popoverBody: styles.popup }}
      show={showSearch}
      setShow={setShowSearch}
      variantBtn={ButtonVariantType.EXRTA_LIGHT}
      sizeBtn={ButtonSizeType.SMALL}
      iconLBtn={<SearchIcon fill="#274A42" />}
      btn={"custom"}
      width={360}
    />
  );
};
