import { useTaskDepartment } from "collections/tasks";
import Notes from "containers/Tasks/Notes/Notes";
import TaskServicePanel from "generalComponents/TaskServicePanel/TaskServicePanel";
import { useActions } from "hooks/useActions";
import { FiltersFields, FiltersSources } from "models/store/userFilters/userFiltersPayload";
import React, { useEffect } from "react";
import { useLocales } from "react-localized";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import GridBoard from "../../containers/Tasks/GridBoard/GridBoard";
import styles from "./TasksPage.module.sass";

const TasksPage: React.FC = () => {
  const { __ } = useLocales();
  const { onGetAllTasks, fetchUserFilters } = useActions();
  const departmentTabs = useTaskDepartment();
  const { myTasks } = useTasksSelectors();

  useEffect(() => {
    const isLoader = myTasks.length === 0;
    onGetAllTasks(__("Ошибка при получении задач"), isLoader);
    fetchUserFilters({
      sources: [FiltersSources.TASKS],
      fields: [FiltersFields.COLORS, FiltersFields.EMOJIS, FiltersFields.SIGNS, FiltersFields.TAGS]
    });
  }, []); //eslint-disable-line

  return (
    <div className={styles.taskWrap}>
      <Notes />
      <TaskServicePanel departmentTabs={departmentTabs} />
      <GridBoard />
    </div>
  );
};

export default TasksPage;
