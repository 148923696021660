import PropTypes from "prop-types";
import { colorType } from "./Color";

export const colorCategoryProps = PropTypes.exact({
  dark: PropTypes.string,
  light: PropTypes.string,
  line: PropTypes.string,
  secondary: PropTypes.string,
  name: PropTypes.string
});

export const projectCategoryProps = PropTypes.exact({
  id: PropTypes.string,
  id_user: PropTypes.string,
  name: PropTypes.string,
  projects_hide: PropTypes.string,
  color: colorCategoryProps,
  is_del: PropTypes.string,
  del_date: PropTypes.string,
  id_project: PropTypes.string
});

export const projectRoleProps = PropTypes.exact({
  id: PropTypes.string,
  id_user: PropTypes.string,
  name: PropTypes.string
});
export const taskCommentTypes = PropTypes.exact({
  id: PropTypes.string,
  id_task: PropTypes.string,
  id_user: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string
});

export const projectUserProps = PropTypes.exact({
  id_user: PropTypes.string,
  id_role: PropTypes.string,
  sname: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  email: PropTypes.string,
  is_read: PropTypes.string,
  date_add: PropTypes.string,
  icon: PropTypes.arrayOf(PropTypes.string),
  id_project: PropTypes.string
});

export const taskHistoryProps = PropTypes.exact({
  date: PropTypes.string,
  id_category_new: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id_category_old: PropTypes.string,
  id_datelong_old: PropTypes.string,
  id_datelong_new: PropTypes.string,
  id_user: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ut: PropTypes.number
});

export const taskTypes = PropTypes.exact({
  id: PropTypes.string,
  name: PropTypes.string,
  id_user: PropTypes.string,
  ut: PropTypes.string,
  date_start: PropTypes.string,
  date_long: PropTypes.string,
  date_end: PropTypes.string,
  emails: PropTypes.string,
  tags: PropTypes.string,
  prim: PropTypes.string,
  id_emo: PropTypes.string,
  id_fig: PropTypes.string,
  id_color: PropTypes.oneOfType([colorType, PropTypes.string]),
  is_del: PropTypes.string,
  id_type: PropTypes.string,
  id_dep: PropTypes.string,
  id_act: PropTypes.string,
  id_status: PropTypes.string,
  id_project: PropTypes.string,
  id_executor: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  id_category: PropTypes.string,
  id_parent: PropTypes.string,
  time_start: PropTypes.string,
  comments: PropTypes.arrayOf(taskCommentTypes),
  pass: PropTypes.string,
  callbackType: PropTypes.string,
  showNote: PropTypes.bool,
  num: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  author_info: projectUserProps,
  author: PropTypes.string,
  sort: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  link: PropTypes.string,
  is_del: PropTypes.string,
  del_date: PropTypes.string,
  fids: PropTypes.arrayOf(PropTypes.string),
  is_archive: PropTypes.string,
  is_epic: PropTypes.number,
  id_epic: PropTypes.string,
  num_epic: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id_user_move: PropTypes.string,
  move_history: PropTypes.arrayOf(taskHistoryProps),
  files_col: PropTypes.number,
  files_col_uploaded: PropTypes.number,
  files_col_4hub: PropTypes.number,
  is_backlog: PropTypes.number
});

export const projectProps = PropTypes.exact({
  id: PropTypes.string,
  id_user: PropTypes.string,
  name: PropTypes.string,
  tags: PropTypes.string,
  id_emo: PropTypes.string,
  id_fig: PropTypes.string,
  icon: PropTypes.string,
  project_icon: PropTypes.string,
  link: PropTypes.string,
  is_my: PropTypes.number,
  roles: PropTypes.arrayOf(projectRoleProps),
  users: PropTypes.arrayOf(projectUserProps),
  tasks: PropTypes.objectOf(PropTypes.arrayOf(taskTypes)),
  is_read: PropTypes.string,
  tasks_category: PropTypes.arrayOf(projectCategoryProps),
  show_message: PropTypes.string,
  tasks_category_sort: PropTypes.arrayOf(PropTypes.string)
});
