import React, { useContext, useRef, useState } from "react";

import { SendSocketMessages } from "../../../models/generalComponents/hooks/WebRTC/useWebRTCSocketMessages";
import {
  IMediaState,
  initialMediaState,
  ISenderTracks,
  IWebRTCContext,
  IWebRTCProvider
} from "../../../models/generalComponents/hooks/WebRTC/WebRTCProvider";
import { useChatSelectors } from "../../../Store/selectors/chatSelectors";
import { useUserSelectors } from "../../../Store/selectors/userSelectors";
import { CHAT_CALLROOM_ACTIONS } from "../../variables/chat";
import { useWebSocketContext } from "../../WebSocketsProvider/WebSocketsProvider";
import { useStateWithCallback } from "../useStateWithCallback";

const WebRTCContext = React.createContext<IWebRTCContext>({
  peerConnections: null,
  localMediaStream: null,
  localScreenStream: null,
  peerMediaElements: null,
  peerScreenElements: null,
  minimizedMediaElement: null,
  politeRef: null,
  sender: null,
  remoteStreams: null,
  screenStreams: null,
  media: initialMediaState(),
  setMedia: (it: IMediaState) => {
    console.log("setMedia offer is not activated");
  },
  makingOffer: false,
  setMakingOffer: (it: boolean) => {
    console.log("setMaking offer is not activated");
  },
  clients: [],
  updateClients: (it) => {
    console.log("updateClients is not activated");
    return [];
  },
  isLoadingUsers: false,
  setIsLoadingUsers: (it: boolean) => {
    console.log("setIsLoadingUsers is not activated");
  },
  hasCameraPermission: false,
  setHasCameraPermission: (it: boolean) => {
    console.log("setHasCameraPermission is not activated");
  },
  fixedReconnectionUsers: { isFixed: true, checkCounts: 0 },
  setFixedReconnectionUsers: (it) => {
    console.log("setFixedReconnectionUsers is not activated");
  },

  sendSocket: (_) => {
    console.log("sendSocket is not activated");
  },
  nullifyData: () => {
    console.log("nullifyData is not activated");
  }
});

// состояние и все данные о соединениях в комнате пользователей
function WebRTCProvider({ children }: IWebRTCProvider): JSX.Element {
  const { sendMessage } = useWebSocketContext();
  const { uid, userInfo } = useUserSelectors();
  const { callRoom } = useChatSelectors();

  const peerConnections = useRef<Record<string, RTCPeerConnection>>({}); // соединение клиентов
  const localMediaStream = useRef<MediaStream | null>(null); // локальные медиаданные
  const localScreenStream = useRef<MediaStream>(null); // локальные медиаданные экрана
  const peerMediaElements = useRef<Record<string, HTMLVideoElement>>({}); // video теги
  const peerScreenElements = useRef<Record<string, HTMLVideoElement>>({}); // video теги
  const minimizedMediaElement = useRef<HTMLVideoElement>(null); // video теги MiniCallRoom
  const politeRef = useRef<Record<string, boolean>>({}); // Информация о конфликтах при подключении
  const sender = useRef<Record<string, ISenderTracks>>({}); // Информация о Sender из пир соединений
  const remoteStreams = useRef<Record<string, MediaStream>>({}); // обьект удаленных MediaStreams
  const screenStreams = useRef<Record<string, MediaStream>>({}); // обьект удаленных MediaStreams экранов

  const [makingOffer, setMakingOffer] = useState<boolean>(false);
  const [media, setMedia] = useState(initialMediaState()); // Информация о медиа локального пользователя
  const { state: clients, updateState: updateClients } = useStateWithCallback<string[]>([]); // Модифицированных хук состояния, который принимаетв себя колбэк после изменения состояния
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false); // Активно, если идет переподключение
  const [hasCameraPermission, setHasCameraPermission] = useState<boolean>(false); // Проверка на разрешений камеры
  const [fixedReconnectionUsers, setFixedReconnectionUsers] = useState<{ isFixed: boolean; checkCounts: number }>({
    isFixed: callRoom.state === CHAT_CALLROOM_ACTIONS.INCOMING_CALL,
    checkCounts: 0
  }); // Подсчет переподключений и проверка установки корректного подклчюения

  // Стандартная форма отправка сокет сообщения - используется в только CallRoom
  const sendSocket = (message: SendSocketMessages) => {
    sendMessage(
      JSON.stringify({
        uid,
        room_id: callRoom.room_id,
        ...message,
        from: {
          icon: userInfo.icon[0] || "",
          id_user: userInfo.id,
          name: userInfo.name,
          sname: userInfo.sname,
          handUp: false,
          media: { audio: false, video: false, screen: false },
          ...message?.from
        },
        id_group: callRoom.id_group
      })
    );
  };

  const nullifyData = () => {
    peerConnections.current = {};
    localMediaStream.current = null;
    localScreenStream.current = null;
    peerMediaElements.current = {};
    peerScreenElements.current = {};
    minimizedMediaElement.current = null;
    politeRef.current = {};
    screenStreams.current = {};
    setMakingOffer(false);
    setMedia(initialMediaState());
    updateClients([], () => {
      console.log("nullified client list");
    });
    setIsLoadingUsers(false);
    setHasCameraPermission(false);
    console.log("DATA was nullified");
  };

  return (
    <WebRTCContext.Provider
      value={{
        peerConnections,
        localMediaStream,
        localScreenStream,
        peerMediaElements,
        peerScreenElements,
        minimizedMediaElement,
        politeRef,
        sender,
        screenStreams,
        sendSocket,
        media,
        setMedia,
        makingOffer,
        setMakingOffer,
        clients,
        updateClients,
        isLoadingUsers,
        setIsLoadingUsers,
        hasCameraPermission,
        setHasCameraPermission,
        nullifyData,
        remoteStreams,
        fixedReconnectionUsers,
        setFixedReconnectionUsers
      }}
    >
      {children}
    </WebRTCContext.Provider>
  );
}

export default WebRTCProvider;
export const useWebRTCContext = () => useContext(WebRTCContext);
