// import api from "api";
// import { EventAPI } from "api/EventAddAPI";
import { EventAPI } from "api/EventAddAPI";
import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import PopUp from "generalComponents/PopUp/PopUp";
import { EVENT_TYPE, TYPE_ELEMENTS } from "generalComponents/variables/global";
import { useAccountDeletingMail } from "hooks/settingsHooks";
import { useActions } from "hooks/useActions";
import md5 from "md5";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialAccountDeletingModalState } from "models/store/Cabinet/modals/accountDeleting";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./AccountDeletingModal.module.sass";

export const AccountDeletingModal = () => {
  const { __ } = useLocales();

  const { uid, userInfo } = useUserSelectors();
  const { onSetAccountDeletingModal, onDeletesRestoresAdminAccount } = useActions();
  const {
    value: password,
    onChange: onChangePassword,
    error: errorPassword,
    onError: onErrorPassword
  } = useInputValue();

  const closeModal = () => {
    onSetAccountDeletingModal(initialAccountDeletingModalState());
  };

  const htmlAccountDeletingMail = useAccountDeletingMail();

  const onDeletesAccount = async () => {
    if (md5(password) !== userInfo.pass) {
      const params = {
        uid,
        event_object: userInfo.id,
        event_type: EVENT_TYPE.PASS_WRONG,
        event_group: TYPE_ELEMENTS.USERS
      };

      await EventAPI.add(params);

      return onErrorPassword(__("Неверный пароль"));
    }

    const payload = {
      is_del: 1,
      html: htmlAccountDeletingMail
    };

    const messages = {
      success: "",
      error: __("Не удалось удалить аккаунт, попробуйте еще раз")
    };

    onDeletesRestoresAdminAccount(payload, messages);

    closeModal();
  };

  return (
    <PopUp set={closeModal} position="center">
      <div className={styles.wrap}>
        <HeaderModal title={__("Пароль")} onClose={closeModal} />
        <div className={styles.body}>
          <Input
            value={password}
            onChange={onChangePassword}
            error={Boolean(errorPassword)}
            errorText={errorPassword}
            isPass
            label={__("Пароль")}
          />
          <div className={styles.btns}>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Отмена")}
              onClick={closeModal}
            />
            <Button
              variant={ButtonVariantType.RED}
              size={ButtonSizeType.SMALL}
              text={__("Удалить аккаунт")}
              onClick={onDeletesAccount}
              disabled={password.length === 0 || Boolean(errorPassword)}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};
