import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as LabelIcon } from "assets/icons/label.svg";
import { ReactComponent as PaintIcon } from "assets/icons/paint.svg";
import { ReactComponent as SmileIcon } from "assets/icons/smile.svg";
import { ReactComponent as ToolIcon } from "assets/icons/tool.svg";
import classNames from "classnames";
import { colors, emojis } from "collections/markers";
import { useSearchData } from "collections/search";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./SearchSidebar.module.sass";

const SearchSidebar = () => {
  const { __ } = useLocales();
  const { times, tools } = useSearchData();
  const { tags } = useUserSelectors();
  const navigate = useNavigate();
  const location = useLocation();
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  const onChangeDate = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const updateURL = (param: string, value: string | string[]) => {
    console.log("2");
    const searchParams = new URLSearchParams(location.search);
    const paramValue = Array.isArray(value) ? value.join(",") : value;
    searchParams.set(param, paramValue);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const toggleFilter = (param: string, item: string) => {
    const currentValues = new Set(
      (location.search && new URLSearchParams(location.search).get(param)?.split(",")) || []
    );
    if (currentValues.has(item)) {
      currentValues.delete(item);
    } else {
      currentValues.add(item);
    }

    updateURL(param, Array.from(currentValues));
  };

  const isActiveFilter = (param: string, item: string) => {
    const currentValues = new Set(
      (location.search && new URLSearchParams(location.search).get(param)?.split(",")) || []
    );

    return currentValues.has(item);
  };

  const toggleSingleFilter = (param: string, item: string) => {
    const currentValue = new URLSearchParams(location.search).get(param);
    if (param === "time_limit" && startDate && endDate) {
      setStartDate(null);
      setEndDate(null);
      updateURL("time", "");
    }
    if (currentValue === item) {
      updateURL(param, "");
    } else {
      updateURL(param, item);
    }
  };

  const isActiveSingleFilter = (param: string, item: string) => {
    const currentValue = new URLSearchParams(location.search).get(param);
    return currentValue === item;
  };

  useEffect(() => {
    if (!startDate || !endDate) return;
    updateURL("time", startDate.toISOString() + " " + endDate.toISOString());
    updateURL("time_limit", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);

  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <div className={styles.title}>
          <ClockIcon />
          {__("Время")}
        </div>
        <div className={styles.content}>
          {times.map((item, i) => (
            <div
              key={i}
              className={classNames(styles.button, isActiveSingleFilter("time_limit", item.value) && styles.active)}
              onClick={() => toggleSingleFilter("time_limit", item.value)}
            >
              {item.title}
            </div>
          ))}
          <InputDate
            className={classNames(styles.inputDate, startDate && endDate && styles.active)}
            startDate={startDate}
            endDate={endDate}
            setStartDate={onChangeDate}
            dateFormat="dd MMM yyyy"
            selectsRange
            isBtnClear={true}
            isClearable
          />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.title}>
          <ToolIcon />
          {__("Инструменты")}
        </div>
        <div className={styles.content}>
          {tools.map((item, i) => (
            <div
              key={i}
              className={classNames(styles.button, isActiveSingleFilter("tools", item.value) && styles.active)}
              onClick={() => toggleSingleFilter("tools", item.value)}
            >
              {item.icon}
              {item.title}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.title}>
          <LabelIcon />
          {__("Теги")}
        </div>
        <div className={styles.content}>
          {tags.map((item) => (
            <div
              key={item.id}
              className={classNames(styles.button, isActiveFilter("tags", item.id) && styles.active)}
              onClick={() => toggleFilter("tags", item.id)}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.title}>
          <PaintIcon height={18} />
          {__("Цвет")}
        </div>
        <div className={styles.content}>
          {colors.map((item) => (
            <div
              key={item}
              className={classNames(styles.color, isActiveFilter("colors", item) && styles.active)}
              style={{ background: item }}
              onClick={() => toggleFilter("colors", item)}
            />
          ))}
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.title}>
          <SmileIcon />
          {__("Эмодзи")}
        </div>
        <div className={styles.content}>
          {emojis.map((item) => (
            <div
              key={item}
              className={classNames(styles.color, isActiveFilter("emo", item) && styles.active)}
              dangerouslySetInnerHTML={{ __html: item }}
              onClick={() => toggleFilter("emo", item)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchSidebar;
