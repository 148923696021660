import { ReactComponent as CloseIcon } from "assets/icons/cross.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import SearchSidebar from "containers/Search/SearchSidebar/SearchSidebar";
import SearchTable from "containers/Search/SearchTable/SearchTable";
import Input from "generalComponents/Input/Input";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useLocation, useNavigate } from "react-router";

import styles from "./SearchPage.module.sass";

const SearchPage = () => {
  const { __ } = useLocales();
  const { search: searchParam } = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;
    const newSearchParams = new URLSearchParams(searchParam);
    newSearchParams.set("query", newSearchValue);
    navigate(`?${newSearchParams.toString()}`);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(searchParam);
    const query = queryParams.get("query") || "";
    setSearch(query);
  }, [searchParam]);

  return (
    <>
      <div className={styles.title}>{__("Поиск")}</div>
      <div className={styles.container}>
        <div className={styles.content}>
          <Input
            value={search}
            onChange={handleInputChange}
            placeholder={__("Название, тег или Люди")}
            rightIcon={search.length > 0 ? <CloseIcon fill="#274A42" /> : <SearchIcon fill="#274A42" />}
            rightIconClick={() => setSearch("")}
          />
          <SearchTable />
        </div>
        <SearchSidebar />
      </div>
    </>
  );
};

export default SearchPage;
