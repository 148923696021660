import classNames from "classnames";
import { useIsFilesPage } from "generalComponents/Services/fileServices/fileServices";
import { useIsFavorite, useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { FilesNextType } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useSafeSelectors } from "Store/selectors/safeSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import PreviewItem from "../BarsPreviewView/PreviewItem/PreviewItem";
import LinesPreviewColumn from "./LinesPreviewColumn/LinesPreviewColumn";
import styles from "./LinesPreviewView.module.sass";
import RenderColumns from "./RenderColumns/RenderColumns";

const LinesPreviewView = (): JSX.Element => {
  const { safeDir } = useParams();
  const {
    userInfo: { theme }
  } = useUserSelectors();
  const { folderPath } = useFoldersSelectors();
  const { safeFolders } = useSafeSelectors();
  const { filesNext, files } = useFilesSelectors();
  const { isFavorite } = useIsFavorite();
  const { isShared, isSharedByMe, isSharedToMe } = useIsShared();
  const { isFilesPage } = useIsFilesPage();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);

  const { onChangeNextFiles } = useActions();
  const refScroll = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refScroll.current) {
      refScroll.current.scrollTo({ left: 600, behavior: "smooth" });
    }
    let obj = { ...filesNext };
    for (let key in obj) {
      if (folderPath.findIndex((el) => el.id_dir === key) === -1) {
        delete obj[key];
      }
    }
    onChangeNextFiles(obj);
  }, [folderPath?.length]); //eslint-disable-line

  const filtredItems: FilesNextType = useMemo(() => {
    return files?.filter((f) => {
      if (isSharedByMe) {
        return f.my === 1;
      }
      if (isSharedToMe) {
        return f.my === 0;
      }
      return f;
    });
    // else {
    //   const obj = { ...files };
    //   for (let k in obj)
    //     obj[k] = obj[k].filter((f) => {
    //       if (isSharedToMe) {
    //         return f.my === 0;
    //       }
    //       if (isSharedByMe) {
    //         return f.my === 1;
    //       }
    //       return f;
    //     });
    //   return obj;
    // }
  }, [files, isSharedByMe, isSharedToMe]);

  const getFolderPath = () => {
    if (isSafePage) {
      const path: IFolder[] = [];
      const folder = safeFolders.find((el) => el.id_dir === safeDir);
      const getPath = (folder: IFolder) => {
        path.unshift(folder);
        if (folder.id_parent === "0" || !folder.id_parent) {
          return;
        } else {
          const f = safeFolders.find((f) => f.id_dir === folder.id_parent);
          getPath(f);
        }
      };
      if (!folder) {
        return [];
      }
      getPath(folder);
      return path;
    }
    return folderPath;
  };

  return (
    <div className={classNames(styles.wrapper, `scrollbar-medium-${theme}`)} ref={refScroll}>
      <ul className={styles.listPath}>
        {isFavorite || isShared || isFilesPage ? (
          <li className={classNames(styles.pathItem, `scrollbar-thin-${theme}`)}>
            <RenderColumns items={isShared ? filtredItems : files} />
          </li>
        ) : (
          <>
            {isSafePage && (
              <li className={classNames(styles.pathItem, `scrollbar-thin-${theme}`)}>
                <RenderColumns items={files} />
              </li>
            )}
            {getFolderPath().map((el) => (
              <li key={el.id_dir} className={classNames(styles.pathItem, `scrollbar-thin-${theme}`)}>
                <LinesPreviewColumn idDir={el.id_dir} />
              </li>
            ))}
          </>
        )}
      </ul>
      <div className={styles.preview}>
        <PreviewItem />
      </div>
    </div>
  );
};

export default LinesPreviewView;
