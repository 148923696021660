import api from "api";
import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IPostbox } from "models/store/postbox/postbox";
import { FC, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./NameChange.module.sass";

interface Props {
  closeModal: () => void;
  postbox: IPostbox;
}

const NameChange: FC<Props> = ({ closeModal, postbox }) => {
  const { __ } = useLocales();
  const { uid } = useUserSelectors();
  const { postboxes } = usePostboxSelectors();
  const { onSetTopMessageModal, onChangeNamePostbox } = useActions();
  const [value, onChange] = useState<string>(postbox.name);

  useEffect(() => {}, []);

  const onRestore = async (): Promise<void> => {
    try {
      const params = {
        uid,
        id_item: postbox.id,
        name: value.trim()
      };
      const { data } = await api.get("/ajax/email_edit.php", { params });

      checkResponseStatus(data.ok);
      if (postboxes.some((el) => el.email === postbox.email)) {
        onChangeNamePostbox(value, postbox.email);
      }
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.SUCCESS,
        message: __("Имя было изменено")
      });
      closeModal();
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR,
        message: __("Что-то пошло не так. Попробуйте позже")
      });
    }
  };
  return (
    <div className={styles.wrap}>
      <HeaderModal onClose={closeModal} title={__("Редактировать")} />
      <div className={styles.body}>
        <Input
          onChange={(e) => onChange(e.currentTarget.value)}
          value={value}
          placeholder="Введите"
          label={__("Имя")}
        />
      </div>
      <div className={styles.btns}>
        <Button
          type="button"
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.SMALL}
          text={__("Отменить")}
          onClick={closeModal}
        />
        <Button
          type="submit"
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.SMALL}
          text={__("Применить")}
          onClick={onRestore}
          disabled={!value}
        />
      </div>
    </div>
  );
};

export default NameChange;
