import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Editor from "@draft-js-plugins/editor";
import createVideoPlugin from "@draft-js-plugins/video";
import api from "api";
import { ReactComponent as CameraIcon } from "assets/icons/camera.svg";
import { ReactComponent as LabelIcon } from "assets/icons/label.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as MicrophoneIcon } from "assets/icons/microphone.svg";
import { ReactComponent as OlIcon } from "assets/icons/ol.svg";
import { ReactComponent as UlIcon } from "assets/icons/ul.svg";
import { RichUtils } from "draft-js";
import Button from "generalComponents/Button/Button";
import { MarksContent } from "generalComponents/MarksContent/MarksContent";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialRecordModalState, IRecordType } from "models/store/Cabinet/modals/record";
import { useEffect, useRef, useState } from "react";
import { EditorState as IEditorState } from "react-draft-wysiwyg";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./NoteEditor.module.sass";
const videoPlugin = createVideoPlugin();

interface NoteEditorProps {
  editorState: IEditorState;
  setEditorState: React.Dispatch<React.SetStateAction<IEditorState>>;
  height?: string;
  password?: string;
  setPassword?: React.Dispatch<React.SetStateAction<string>>;
  color?: string;
  setColor?: React.Dispatch<React.SetStateAction<string>>;
  tags?: string[];
  setTags?: React.Dispatch<React.SetStateAction<string[]>>;
  emo?: string;
  setEmo?: React.Dispatch<React.SetStateAction<string>>;
}

const NoteEditor = ({
  editorState,
  setEditorState,
  height,
  password,
  setPassword,
  color,
  setColor,
  tags,
  setTags,
  emo,
  setEmo
}: NoteEditorProps) => {
  const { __ } = useLocales();
  const { uid } = useUserSelectors();
  const { onSetRecordModal, onSetPasswordModal } = useActions();
  const editorRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleKeyCommand = (
    command: string,
    editorState: IEditorState,
    setEditorState: React.Dispatch<React.SetStateAction<IEditorState>>
  ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  useEffect(() => {
    const videoElements = document.querySelectorAll("video");
    videoElements.forEach((videoElement) => {
      // Загружаем метаданные видео, чтобы определить, есть ли в файле видео
      videoElement.addEventListener("loadedmetadata", () => {
        if (videoElement.videoWidth > 0 && videoElement.videoHeight > 0) {
          // Если видео имеет ненулевые ширину и высоту, значит это видео
          videoElement.style.height = "350px"; // Видео
          videoElement.style.width = "700px";
        } else {
          // Иначе это, вероятно, только аудио
          videoElement.style.height = "50px"; // Аудио
          videoElement.style.width = "300px";
        }
      });
    });
  }, [editorState]);

  const onDownload = async (blob: Blob) => {
    const file = new File([blob], `${Date.now()}`, { type: "video/mp4" });
    try {
      const formData = new FormData();
      formData.append("uid", uid);
      formData.append("myfile", file);
      formData.append("dep", "_NOTES_");
      const { data } = await api.post(`/ajax/file_add.php`, formData);
      checkResponseStatus(data.ok);
      setEditorState(
        videoPlugin.addVideo(editorState, {
          src: data.preview
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      onSetRecordModal(initialRecordModalState());
    }
  };

  const customBlockStyleFn = () => {
    return styles.noMargin;
  };

  const openPasswordModal = () => {
    onSetPasswordModal({
      open: true,
      title: __("Заметка — защита паролем"),
      successButtonText: __("Установить пароль"),
      successCallback: (val) => setPassword(val),
      currentValue: password
    });
  };

  const onSetTags = (val: string) => {
    const tagIndex = tags.indexOf(val);
    if (tagIndex !== -1) {
      const updatedTags = [...tags];
      updatedTags.splice(tagIndex, 1);
      setTags(updatedTags);
    } else {
      setTags((prevTags) => [...prevTags, val]);
    }
  };

  const handleListButtonClick = (listType: string) => {
    const newState = RichUtils.toggleBlockType(editorState, listType);
    setEditorState(newState);
  };

  const isListActive = (editorState: IEditorState, listType: string) => {
    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();
    const block = content.getBlockForKey(selection.getStartKey());
    return block && block.getType() === listType;
  };

  return (
    <div className={styles.content} style={{ minHeight: height ?? undefined, maxHeight: height ?? undefined }}>
      <Editor
        blockStyleFn={customBlockStyleFn}
        editorState={editorState}
        onChange={setEditorState}
        plugins={[videoPlugin]}
        ref={editorRef}
        handleKeyCommand={(command) => handleKeyCommand(command, editorState, setEditorState)}
        placeholder={__("Введите текст заметки")}
      />
      <div className={styles.buttons}>
        <div className={styles.left}>
          <Button
            isSquare
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.SMALL}
            iconL={<MicrophoneIcon />}
            onClick={() => onSetRecordModal({ open: true, type: IRecordType.AUDIO, callBack: onDownload })}
          />
          <Button
            isSquare
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.SMALL}
            iconL={<CameraIcon />}
            onClick={() => onSetRecordModal({ open: true, type: IRecordType.VIDEO, callBack: onDownload })}
          />
          <Button
            isSquare
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.SMALL}
            iconL={<LockIcon />}
            onClick={openPasswordModal}
          />
        </div>
        <div className={styles.middle}>
          <Button
            isSquare
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.SMALL}
            iconL={<OlIcon />}
            className={isListActive(editorState, "ordered-list-item") ? styles.active : ""}
            onClick={() => handleListButtonClick("ordered-list-item")}
          />
          <Button
            isSquare
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.SMALL}
            iconL={<UlIcon />}
            className={isListActive(editorState, "unordered-list-item") ? styles.active : ""}
            onClick={() => handleListButtonClick("unordered-list-item")}
          />
        </div>
        <div className={styles.right}>
          <PopoverOverlay
            overlayContent={
              <MarksContent
                tags={tags}
                onSetTag={onSetTags}
                color={color}
                onSetColor={setColor}
                emoji={emo}
                onSetEmoji={setEmo}
                onSetShow={() => {}}
              />
            }
            classes={{ popoverBody: styles.nopadding }}
            show={showOverlay}
            setShow={setShowOverlay}
            width={442}
          >
            <div className={styles.editorIcon}>
              <LabelIcon />
            </div>
          </PopoverOverlay>
        </div>
      </div>
    </div>
  );
};

export default NoteEditor;
