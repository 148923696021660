export enum FieldsName {
  CATEGORY = "crubric",
  NAME_RUBRIC = "nrubric",
  ICON = "icon"
}

export interface IDataField {
  value?: string;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
}

export interface FormDataCreateaRubricState {
  [FieldsName.CATEGORY]?: IDataField;
  [FieldsName.NAME_RUBRIC]: IDataField;
  [FieldsName.ICON]: IDataField;
}

export enum FormCreateRubricLibraryTypes {
  CHANGE = "CHANGE",
  NULLIFY = "NULLIFY"
}

export type FormAction = {
  type: FormCreateRubricLibraryTypes;
  field?: FieldsName;
  value?: string;
  error?: boolean;
  errorMessage?: string;
};

export const initialState: FormDataCreateaRubricState = {
  [FieldsName.CATEGORY]: {
    value: "",
    required: true,
    error: true,
    errorMessage: ""
  },
  [FieldsName.NAME_RUBRIC]: {
    value: "",
    required: true,
    error: true,
    errorMessage: ""
  },
  [FieldsName.ICON]: {
    value: "",
    required: false,
    error: false,
    errorMessage: ""
  }
};

export const reducerData = (state: FormDataCreateaRubricState, action: FormAction): FormDataCreateaRubricState => {
  switch (action.type) {
    case FormCreateRubricLibraryTypes.CHANGE:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          value: action.value,
          error: action.error,
          errorMessage: action.errorMessage
        }
      };

    case FormCreateRubricLibraryTypes.NULLIFY:
      return initialState;

    default:
      return state;
  }
};
