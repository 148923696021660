import { ReactComponent as FireIcon } from "assets/PrivateCabinet/fire.svg";
import classNames from "classnames";
import { dateToMs } from "generalComponents/Calendars/CalendarHelper";
import DownArrow from "generalComponents/DownArrow/DownArrow";
import { typeCheck } from "generalComponents/Services/browserServices";
import { useFiltredTask, useGetStatus } from "generalComponents/Services/projectServices";
import ThreeDots from "generalComponents/ThreeDots/ThreeDots";
import { TYPES } from "generalComponents/variables/global";
import PropTypes from "prop-types";
import React, { Fragment, useMemo, useState } from "react";
import { taskTypes } from "types/Project";

import TaskNum from "../../../Components/TaskNum/TaskNum";
import UserInitials from "../../../Components/UserInitials/UserInitials";
import styles from "./TasksGant.module.sass";

const TaskRow = ({ task, collapsed, isEpic, setShowChildren, setMouseParams, setchosenTask }) => {
  const filtredTask = useFiltredTask()
    .sort((a, b) => dateToMs(a.ut) - dateToMs(b.ut))
    .filter((t) => t.is_backlog < 0);
  const [openChildren, setOpenChildren] = useState(false);
  const status = useGetStatus();

  const toggleOpenChildren = () => {
    setOpenChildren((open) => !open);
    setShowChildren((prev) => {
      if (openChildren) {
        return prev.filter((item) => item !== task.id);
      } else {
        return [...prev, task.id];
      }
    });
  };

  const children = useMemo(() => {
    return filtredTask.filter((t) => t.id_epic === task.id);
  }, [filtredTask, task.id]);

  return (
    <>
      <div
        style={{
          background: isEpic ? "#fff" : status(task)?.color.light
        }}
        className={styles.row}
      >
        <div className={classNames(styles.nameCell, { [styles.nameChild]: task.id_epic })}>
          <TaskNum num={task.num_epic} color={!isEpic ? status(task)?.color.dark : null} />
          {task.id_act === "urgent" && <FireIcon className={styles.fireIcon} />}
          <span className={classNames(styles.name, { [styles.bold]: isEpic })}>{task.name}</span>
          {isEpic && <DownArrow clickHandler={() => toggleOpenChildren()} isOpen={openChildren} />}
        </div>
        <div className={classNames(styles.visible, styles.executors, { [styles.hidden]: collapsed })}>
          {typeCheck(task.id_executor) === TYPES.ARRAY &&
            task.id_executor.map((item) => <UserInitials key={item} id={item} />)}
        </div>
        <div className={classNames(styles.visible, { [styles.hidden]: collapsed })}>
          {collapsed ? (
            ""
          ) : (
            <>
              {!isEpic && (
                <p className={styles.status}>
                  <span className={styles.circle} style={{ background: status(task)?.color.dark }} />
                  &nbsp;
                  <span>{status(task)?.name}</span>
                </p>
              )}
            </>
          )}
        </div>
        <div className={classNames(styles.visible, { [styles.hidden]: collapsed })}>
          {collapsed ? "" : <span className={classNames({ [styles.role]: task.tags?.trim() })}>{task.tags}</span>}
        </div>
        <div className={styles.plusBox}>
          <ThreeDots
            onClick={(e) => {
              setMouseParams({
                x: e.clientX,
                y: e.clientY,
                width: 240,
                height: 25,
                task: task
              });
              setchosenTask(task);
            }}
          />
        </div>
      </div>
      {openChildren &&
        children?.map((task, i) => (
          <TaskRow
            key={i}
            task={task}
            collapsed={collapsed}
            setMouseParams={setMouseParams}
            setchosenTask={setchosenTask}
          />
        ))}
    </>
  );
};

export default TaskRow;

TaskRow.propTypes = {
  task: taskTypes,
  collapsed: PropTypes.bool,
  isEpic: PropTypes.bool,
  setShowChildren: PropTypes.func,
  setMouseParams: PropTypes.func,
  setchosenTask: PropTypes.func
};
