import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IUserInfo } from "models/store/user/user";
import { FC, useState } from "react";
import { useLocales } from "react-localized";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./ChangeStatus.module.sass";

interface IProps {
  closeModal: () => void;
  employee: IUserInfo;
}

const ChangeStatus: FC<IProps> = ({ closeModal, employee }) => {
  const { __ } = useLocales();
  const { onChangeUserSettings } = useActions();

  const [value, onChange] = useState<string>(employee?.prim || "");

  const onSubmit = (): void => {
    onChangeUserSettings({ prim: value });
    closeModal();
  };

  const onClear = (): void => {
    onChangeUserSettings({ prim: "" });
    closeModal();
  };

  return (
    <div className={styles.wrap}>
      <HeaderModal title={__("Свой статус...")} onClose={closeModal} />
      <div className={styles.body}>
        <Input value={value} onChange={(e) => onChange(e.currentTarget.value)} placeholder={__("Введите")} />
        <div className={styles.footer}>
          {employee.prim && (
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.MEDIUM}
              onClick={onClear}
              text={__("Очистить")}
            />
          )}

          <div className={styles.btns}>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.MEDIUM}
              onClick={closeModal}
              text={__("Отменить")}
            />
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.MEDIUM}
              onClick={onSubmit}
              text={__("Применить")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeStatus;
