import { ReactComponent as SmileIcon } from "assets/icons/smile.svg";
import { ReactComponent as CloseModal } from "assets/PrivateCabinet/closeModal.svg";
import classNames from "classnames";
import { emojis } from "collections/markers";
import Button from "generalComponents/Button/Button";
import PopUp from "generalComponents/PopUp/PopUp";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import styles from "./Emoji.module.sass";

export interface IColorsProps {
  emo: string;
  setEmo: Function;
  disabled?: boolean;
}

const Emoji: React.FC<IColorsProps> = ({ emo, setEmo, disabled = false }): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = (): void => setIsOpen((open) => !open);

  const renderEmojis = (): JSX.Element[] => {
    return emojis.map((current) => (
      <li
        key={uuid()}
        className={classNames(styles.emoji, emo === current && styles.active)}
        onClick={() => setEmo((prev: string) => (prev === current ? undefined : current))}
      >
        <div className={styles.wrapper}>
          <span dangerouslySetInnerHTML={{ __html: current }} />
        </div>
      </li>
    ));
  };

  return (
    <>
      <Button
        disabled={disabled}
        isSquare
        size={ButtonSizeType.LARGE}
        variant={ButtonVariantType.ADD_GREY}
        onClick={toggleOpen}
        iconL={!emo ? <SmileIcon /> : <span dangerouslySetInnerHTML={{ __html: emo }} />}
      />

      {isOpen && (
        <PopUp set={toggleOpen}>
          <ul className={styles.wrap}>
            {renderEmojis()}
            <button className={styles.closeBtn} onClick={toggleOpen}>
              <CloseModal width={15} height={15} />
            </button>
          </ul>
        </PopUp>
      )}
    </>
  );
};

export default Emoji;
