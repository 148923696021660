import PropTypes from "prop-types";
import React from "react";
import { Doughnut } from "react-chartjs-2";

import styles from "./ChartCategories.module.sass";

const options = {
  plugins: {
    tooltip: {
      enabled: false
    },
    legend: {
      display: false
    },
    datalabels: {
      display: false
    }
  }
};

const ChartCategories = ({ items, total, color }) => {
  const data = {
    datasets: [
      {
        data: items.amount,
        borderColor: items.borders,
        borderWidth: items.borderWidth,
        cutout: 100
      }
    ]
  };
  return (
    <div className={styles.container}>
      <div className={styles.text} style={{ color }}>
        {total}
        {total > 0 && "%"}
      </div>
      <Doughnut data={data} options={options} height={50} width={50} />
    </div>
  );
};
export default ChartCategories;

ChartCategories.propTypes = {
  items: PropTypes.exact({
    amount: PropTypes.arrayOf(PropTypes.number),
    borderWidth: PropTypes.arrayOf(PropTypes.number),
    borders: PropTypes.arrayOf(PropTypes.string)
  }),
  total: PropTypes.number,
  color: PropTypes.string
};
