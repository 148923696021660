import { POSTBOX_MODALS } from "generalComponents/variables/mail";
import { ILetterInfo, ILetterInfoAttachments, IMailFolder, IPostbox } from "models/store/postbox/postbox";

export interface ILetterDraft {
  from: IPostbox;
  to: string;
  subject: string;
  html: string;
  files?: File[];
  uid?: number;
  prevFiles?: ILetterInfoAttachments[];
}

export interface ILetterPreview {
  link: string;
  mime_type: string;
  name: string;
}

export interface IPostboxModalState {
  open: boolean;
  type: POSTBOX_MODALS;
  postbox?: IPostbox;
  folder?: IMailFolder;
  letter?: ILetterInfo;
  draft?: ILetterDraft;
  preview?: ILetterPreview;
  filename?: string;
}

export const initialPostboxModalState = (): IPostboxModalState => {
  return {
    open: false,
    type: POSTBOX_MODALS.NO_MODAL,
    postbox: null,
    folder: null,
    letter: null,
    draft: null,
    preview: null,
    filename: ""
  };
};

export interface IMailNewMessageModal {
  open: boolean;
  letter?: { letter: ILetterInfo; type: "forward" | "answer" | "draft" };
  to?: string;
}

export function initialMailNewMessageModalState(): IMailNewMessageModal {
  return { open: false, letter: null };
}

export interface IMailDashboardModal {
  open: boolean;
  letter: ILetterInfo;
}

export function initialMailDashboardModalState(): IMailDashboardModal {
  return { open: false, letter: null };
}
