import { ChartData, ChartOptions, ScriptableContext } from "chart.js";
import { FC } from "react";
import { Bar, Line } from "react-chartjs-2";
import { useLocales } from "react-localized";

interface IProps {
  dataChart: Record<string, { all: number; overdue: number }>;
  tab: "line" | "bar";
}
const TasksAmountChart: FC<IProps> = ({ dataChart, tab }) => {
  const { __ } = useLocales();

  const options: ChartOptions<"line" | "bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true
      },
      datalabels: {
        display: tab === "line" ? false : true,
        color: "#494949",
        align: "end"
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: __("задачи")
        },
        ticks: {
          stepSize: 1
        }
      }
    }
  };
  const dataLine: ChartData<"line", number[], string> = {
    labels: Object.keys(dataChart),
    datasets: [
      {
        data: Object.values(dataChart).map((el) => el.all),
        type: "line",
        fill: true,
        label: __("Всего задач"),
        borderColor: "#4086F1",
        borderJoinStyle: "bevel",
        borderWidth: 1,
        pointRadius: 2,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 150);
          gradient.addColorStop(0, "rgba(64, 134, 241, 0.7)");
          gradient.addColorStop(1, "rgba(64, 134, 241, 0)");
          return gradient;
        }
      },
      {
        data: Object.values(dataChart).map((el) => el.overdue),
        type: "line",
        fill: true,
        label: __("Просроченных задач"),
        borderColor: "#CC4036",
        borderJoinStyle: "bevel",
        borderWidth: 1,
        pointRadius: 2,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 150);
          gradient.addColorStop(0, "rgba(204, 64, 54, 0.7)");
          gradient.addColorStop(1, "rgba(204, 64, 54, 0)");
          return gradient;
        }
      }
    ]
  };
  const dataBar: ChartData<"bar", number[], string> = {
    labels: Object.keys(dataChart),
    datasets: [
      {
        data: Object.values(dataChart).map((el) => el.all),
        type: "bar",
        label: __("Всего задач"),
        borderRadius: 4,
        backgroundColor: "#4086F1"
      },
      {
        data: Object.values(dataChart).map((el) => el.overdue),
        type: "bar",
        label: __("Просроченных задач"),
        backgroundColor: "#CC4036",
        borderRadius: 4
      }
    ]
  };

  return tab === "line" ? <Line data={dataLine} options={options} /> : <Bar data={dataBar} options={options} />;
};

export default TasksAmountChart;
