import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import Button from "generalComponents/Button/Button";
import PropTypes from "prop-types";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./ProjectSuccess.module.sass";

const ProjectSuccess = ({ onClose, params }) => {
  const { __ } = useLocales();

  return (
    <div className={styles.wrap}>
      <p className={styles.title}>{params.title}</p>
      <CheckIcon className={styles.icon} />
      <p className={styles.text}>{params.text}</p>
      <Button type="button" variant="ok" onClick={onClose} text={__("Готово")} />
    </div>
  );
};

export default ProjectSuccess;

ProjectSuccess.propTypes = {
  onClose: PropTypes.func,
  params: PropTypes.exact({ title: PropTypes.string, text: PropTypes.string })
};
