import classNames from "classnames";
import { useActions } from "hooks/useActions";
import { ShareActionTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { FC, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

import styles from "./BottomMenuOption.module.sass";
interface ICopy extends IFolder {
  stop: boolean;
}

const ShareBtn: FC = () => {
  const { __ } = useLocales();
  const { onSetShareModal, onSetFolderPassModal } = useActions();
  const {
    pickedItems: { items }
  } = useFilesSelectors();
  const [copyItems, setCopyItems] = useState<IFolder[]>([]);
  const [update, setUpdate] = useState<boolean>(false);

  useEffect(() => {
    const copy: IFolder[] = [];
    items.forEach((item) => {
      if (item.is_dir && (item as IFolder).pass) {
        copy.push(item as IFolder);
      }
    });
    setCopyItems(copy);
  }, [items]);

  useEffect(() => {
    if (!update) return;
    onShare();
    setUpdate(false);
  }, [update]); //eslint-disable-line

  const onShare = (): void => {
    let actionType = ShareActionTypes.MIXED;
    if (items.every((item) => item.is_dir === 1)) actionType = ShareActionTypes.DIR_ACCESS_ADD;
    if (items.every((item) => item.is_dir === 0)) actionType = ShareActionTypes.FILE_SHARE;

    const element = copyItems.find((el) => (el as ICopy).pass !== "");
    if (element) {
      onSetFolderPassModal({
        open: true,
        type: "enterPassword",
        folder: element,
        callbackOk: () => {
          setCopyItems((prev) => prev.filter((i) => i.id_dir !== element.id_dir));
          setUpdate(true);
        }
      });
      return;
    }

    onSetShareModal({
      open: true,
      actionType,
      folders: items.filter((item) => item.is_dir === 1) as IFolder[],
      files: items.filter((item) => item.is_dir === 0) as IFile[]
    });
  };

  return (
    <button onClick={onShare} className={classNames({ [styles.disabled]: items?.length === 0 })} type="button">
      {__("Расшарить")}
    </button>
  );
};

export default ShareBtn;
