import { ReactComponent as BulletIcon } from "assets/PrivateCabinet/editorIcons/bullet.svg";
import { ReactComponent as CenterIcon } from "assets/PrivateCabinet/editorIcons/center.svg";
import { ReactComponent as JustifyIcon } from "assets/PrivateCabinet/editorIcons/justify.svg";
import { ReactComponent as LeftIcon } from "assets/PrivateCabinet/editorIcons/left.svg";
import { ReactComponent as NumberIcon } from "assets/PrivateCabinet/editorIcons/numbers.svg";
import { ReactComponent as RightIcon } from "assets/PrivateCabinet/editorIcons/right.svg";
import classNames from "classnames";
import { ICustomEditorToolbarProps, TOOLBAR_BUTTONS } from "models/generalComponents/customEditorToolbar";
import React, { useEffect, useState } from "react";

import styles from "./CustomEditorToolbar.module.sass";

const CustomEditorToolbar: React.FC<ICustomEditorToolbarProps> = ({
  onClick,
  pushoutButton,
  viewType,
  currentEditor
}): JSX.Element => {
  const [textIcons, setTextIcons] = useState<string[]>([]);
  const [alignIcon, setAlignIcon] = useState<TOOLBAR_BUTTONS>(TOOLBAR_BUTTONS.ALIGN_LEFT);
  const [listIcon, setListIcon] = useState<TOOLBAR_BUTTONS>(TOOLBAR_BUTTONS.OUTDENT_LIST);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setTextIcons([]);
    setAlignIcon(TOOLBAR_BUTTONS.ALIGN_LEFT);
    setListIcon(TOOLBAR_BUTTONS.OUTDENT_LIST);
  }, [viewType, currentEditor]);

  useEffect(() => {
    if (pushoutButton.type) {
      if (pushoutButton.type === TOOLBAR_BUTTONS.OUTDENT_LIST) {
        setListIcon(TOOLBAR_BUTTONS.OUTDENT_LIST);
        return;
      }

      setTextIcons((prev) =>
        prev.some((el) => el === pushoutButton.type)
          ? prev.filter((el) => el !== pushoutButton.type)
          : [...prev, pushoutButton.type]
      );
    }
  }, [pushoutButton]);

  const toolbarClick = (value: TOOLBAR_BUTTONS): void => {
    onClick(value);
    if (value.startsWith("alignment")) {
      if (value === alignIcon || value === TOOLBAR_BUTTONS.ALIGN_LEFT) {
        setAlignIcon(TOOLBAR_BUTTONS.ALIGN_LEFT);
      } else {
        setAlignIcon(value);
      }
      return;
    }
    if (value === TOOLBAR_BUTTONS.NUMBERED_LIST || value === TOOLBAR_BUTTONS.BULLETED_LIST) {
      setListIcon((prev) => (prev === value ? TOOLBAR_BUTTONS.OUTDENT_LIST : value));
      return;
    }
  };

  const isActive = (value: TOOLBAR_BUTTONS): boolean => {
    return textIcons.some((el) => el === value);
  };

  const getCurrentAlignIcon = (): JSX.Element => {
    switch (alignIcon) {
      case TOOLBAR_BUTTONS.ALIGN_LEFT:
        return <LeftIcon />;
      case TOOLBAR_BUTTONS.ALIGN_RIGHT:
        return <RightIcon />;
      case TOOLBAR_BUTTONS.ALIGN_CENTER:
        return <CenterIcon />;
      case TOOLBAR_BUTTONS.ALIGN_JUSTIFY:
        return <JustifyIcon />;

      default:
        return <LeftIcon />;
    }
  };

  const toggleShow = (): void => setShow((prev) => !prev);

  return (
    <div className={styles.box}>
      <button
        onClick={() => toolbarClick(TOOLBAR_BUTTONS.BOLD)}
        className={classNames(styles.btn, styles.bold, { [styles.active]: isActive(TOOLBAR_BUTTONS.BOLD) })}
        type="button"
      >
        B
      </button>
      <button
        onClick={() => toolbarClick(TOOLBAR_BUTTONS.ITALIC)}
        className={classNames(styles.btn, styles.italic, { [styles.active]: isActive(TOOLBAR_BUTTONS.ITALIC) })}
        type="button"
      >
        I
      </button>
      <button
        onClick={() => toolbarClick(TOOLBAR_BUTTONS.UNDERLINE)}
        className={classNames(styles.btn, styles.underline, { [styles.active]: isActive(TOOLBAR_BUTTONS.UNDERLINE) })}
        type="button"
      >
        U
      </button>
      <button
        onClick={() => toolbarClick(TOOLBAR_BUTTONS.STRIKETHROUGHT)}
        className={classNames(styles.btn, styles.strikethrought, {
          [styles.active]: isActive(TOOLBAR_BUTTONS.STRIKETHROUGHT)
        })}
        type="button"
      >
        S
      </button>
      <button
        onClick={() => toolbarClick(TOOLBAR_BUTTONS.BULLETED_LIST)}
        className={classNames(styles.btn, { [styles.active]: listIcon === TOOLBAR_BUTTONS.BULLETED_LIST })}
        type="button"
      >
        <BulletIcon />
      </button>
      <button
        onClick={() => toolbarClick(TOOLBAR_BUTTONS.NUMBERED_LIST)}
        className={classNames(styles.btn, { [styles.active]: listIcon === TOOLBAR_BUTTONS.NUMBERED_LIST })}
        type="button"
      >
        <NumberIcon />
      </button>
      <div className={styles.align} onMouseEnter={toggleShow} onMouseLeave={toggleShow}>
        <button
          className={classNames(styles.btn, {
            [styles.active]: alignIcon !== TOOLBAR_BUTTONS.ALIGN_LEFT
          })}
        >
          {getCurrentAlignIcon()}
        </button>
        {show && (
          <div className={styles.group}>
            <button
              onClick={() => toolbarClick(TOOLBAR_BUTTONS.ALIGN_LEFT)}
              className={classNames(styles.btn, { [styles.active]: alignIcon === TOOLBAR_BUTTONS.ALIGN_LEFT })}
              type="button"
            >
              <LeftIcon />
            </button>
            <button
              onClick={() => toolbarClick(TOOLBAR_BUTTONS.ALIGN_CENTER)}
              className={classNames(styles.btn, { [styles.active]: alignIcon === TOOLBAR_BUTTONS.ALIGN_CENTER })}
              type="button"
            >
              <CenterIcon />
            </button>
            <button
              onClick={() => toolbarClick(TOOLBAR_BUTTONS.ALIGN_RIGHT)}
              className={classNames(styles.btn, { [styles.active]: alignIcon === TOOLBAR_BUTTONS.ALIGN_RIGHT })}
              type="button"
            >
              <RightIcon />
            </button>
            <button
              onClick={() => toolbarClick(TOOLBAR_BUTTONS.ALIGN_JUSTIFY)}
              className={classNames(styles.btn, { [styles.active]: alignIcon === TOOLBAR_BUTTONS.ALIGN_JUSTIFY })}
              type="button"
            >
              <JustifyIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomEditorToolbar;
