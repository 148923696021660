import { EVENT_TYPE, TYPE_ELEMENTS } from "generalComponents/variables/global";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { TypeElements } from "models/typeElements/typeElements";

export interface IFilterTypeElementsState {
  name: string;
  value: string;
  table?: TypeElements[] | TYPE_ELEMENTS[];
  id_type?: TASK_TYPES[];
  event_type?: EVENT_TYPE[];
}

export function initialFilterTypeElementsState(): IFilterTypeElementsState {
  return {
    name: "",
    value: "",
    table: null,
    id_type: null,
    event_type: null
  };
}
