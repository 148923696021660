import { ReactComponent as CommentIcon } from "assets/PrivateCabinet/comm.svg";
import classNames from "classnames";
import { useContextMenuMeeting } from "collections/tasks";
import FileTags from "generalComponents/FileTags/FileTags";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { STATUS_TYPES, TASK_MODALS, TASK_TYPES, TASKS_SCHEMA } from "generalComponents/variables/tasks";
import { useTaskSatus } from "hooks/tasksHooks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { IMeetingItemProps } from "models/tasks/myTasks";
import React, { useContext, useEffect, useRef } from "react";
import { useLocales } from "react-localized";

import { MyTasksProvider } from "../../../../../../containers/Tasks/GridBoard/GridBoard";
import ContextButtons from "../ContextButtons/ContextButtons";
import StatusesButtons from "../StatusesButtons/StatusesButtons";
import TaskStatusSelect from "../TaskStatusSelect/TaskStatusSelect";
import styles from "./MeetingItem.module.sass";

const MeetingItem: React.FC<IMeetingItemProps> = ({ task, isFull = false }) => {
  const { __ } = useLocales();
  const status = useTaskSatus(TASK_TYPES.MEETINGS);
  const contextMenu = useContextMenuMeeting();
  const { onSetTaskModal } = useActions();
  const { formatDate } = useDateFormat();

  const { schema } = useContext(MyTasksProvider);
  const isLarge: boolean = schema === TASKS_SCHEMA.EXPANDED_MEETINGS_BOARD || isFull;

  const refContainer = useRef<HTMLDivElement>(null);
  const refStatuses = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refContainer && refStatuses) {
      const toLeft = refStatuses.current.clientWidth + 5;
      refContainer.current.scrollLeft = toLeft;
    }
  }, []);

  const onAddComment = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.ADD_COMMENT_TASK,
      params: {
        ...task
      }
    });
  };
  const onOpen = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.OPEN_TASK,
      params: task
    });
  };

  return (
    <div className={classNames(styles.container)} ref={refContainer}>
      <div className={styles.additions} ref={refStatuses}>
        <StatusesButtons task={task} />
      </div>
      <div className={classNames(styles.taskBox, { [styles.full]: isLarge })} onClick={onOpen}>
        <div className={classNames(styles.time, { [styles.through]: task.id_status === STATUS_TYPES.DONE })}>
          {formatDate(task.date_start, "time")}
          {task.date_end && <>&nbsp;-&nbsp;{formatDate(task.date_end, "time")}</>}
        </div>
        <div className={classNames(styles.name, { [styles.through]: task.id_status === STATUS_TYPES.DONE })}>
          {task.name}
        </div>
        {isLarge && task.tags.length > 0 && <FileTags item={task} />}
        {task.color && <div className={styles.color} style={{ background: task.color }} />}
        {task.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: task.emo }} />}

        <div
          className={classNames(styles.commentBox, { [styles.isComment]: task.comments?.length > 0 })}
          onClick={onAddComment}
        >
          <CommentIcon />
        </div>

        {isLarge && <TaskStatusSelect task={task} />}
        <PopoverContextMenu data={contextMenu(task)} title={__("Действия / Статус")} />
        <div className={styles.iconStatus}>{status(task.id_status)?.img}</div>
      </div>

      <div className={styles.additions}>
        <ContextButtons task={task} />
      </div>
    </div>
  );
};

export default MeetingItem;
