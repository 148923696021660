import { ReactComponent as AddFolderNewIcon } from "assets/PrivateCabinet/add_folder-new.svg";
import { ReactComponent as FavoriteIcon } from "assets/PrivateCabinet/folderFavorite.svg";
import { ReactComponent as SharedIcon } from "assets/PrivateCabinet/folderShared.svg";
import classNames from "classnames";
import { useContextFolder } from "collections/myFolders";
import BottomMenuOption from "generalComponents/BottomMenuOption/BottomMenuOption";
import Clue from "generalComponents/Clue/Clue";
import FileServicePanel from "generalComponents/FilesServicePanel/FilesServicePanel";
import FolderBreadcrumbs from "generalComponents/FolderBreadcrumbs/FolderBreadcrumbs";
import FolderItem from "generalComponents/FolderItem/FolderItem";
import List from "generalComponents/List/List";
import RecentFiles from "generalComponents/RecentFiles/RecentFiles";
import { useIsFavorite, useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { FOLDER_ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { IFolder, initialFolderState } from "models/store/folders/foldersStore";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { NavLink, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useRecentFilesSelectors } from "Store/selectors/recentFilesSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./FoldersPage.module.sass";

const FoldersPage = (): JSX.Element => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const fakeScrollRef = useRef<HTMLDivElement>(null);
  const folderListWrapRef = useRef<HTMLDivElement>(null);
  const { contextMenuFolderCallback, consextMenuSystemFolderCallbak } = useContextFolder();
  const {
    onGetMyFolders,
    onNullifyFiles,
    onSelectFolder,
    onSendFolderPath,
    onSetCurrentItem,
    fetchRecentFiles,
    onNullifyUserFilters,
    onNullifyFilterCriteria,
    onSetCreateFolderModal,
    onCreateFolder
  } = useActions();

  const { currentFolder, folders } = useFoldersSelectors();
  const { recentFiles } = useRecentFilesSelectors();
  const { pickedItems } = useFilesSelectors();
  const { dirId } = useParams();
  const { search } = useLocation();
  const { isShared } = useIsShared();
  const { isFavorite } = useIsFavorite();

  const {
    userInfo: { theme, rights }
  } = useUserSelectors();

  const [listCollapsed, setListCollapsed] = useState<boolean>(false);

  const onCreateFolderCallback = () =>
    onSetCreateFolderModal({ open: true, type: "add", folder: null, folders, onCreateFolder });

  const filteredContextMenu = (folder: IFolder): IPopoverData[] => {
    if (folder.is_system === "1") {
      return consextMenuSystemFolderCallbak(folder);
    }
    return contextMenuFolderCallback(folder);
  };

  const handleSelectFolder = (e: MouseEvent<HTMLLIElement>, folder: IFolder): void => {
    e.stopPropagation();
    if (folder.id_dir !== currentFolder.id_dir) {
      onSetCurrentItem(null);
      navigate(`${folder.id_dir}${search}`);
    }
  };

  const onScroll = (): void => {
    if (folderListWrapRef.current && fakeScrollRef.current) {
      fakeScrollRef.current.scrollLeft = folderListWrapRef.current.scrollLeft;
    }
  };

  useEffect(() => {
    if (!rights.IS_FILES) {
      navigate(`/`, { replace: true });
      return;
    }

    const controllerFolders = new AbortController();
    onGetMyFolders(controllerFolders);
    fetchRecentFiles();

    return () => {
      onNullifyFiles();
      onNullifyUserFilters();
      onNullifyFilterCriteria();
      onSendFolderPath([]);
      onSelectFolder(initialFolderState());
      controllerFolders.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    folders.length > 0 && (
      <div className={styles.workAreaWrap}>
        <List
          title={__("Папки")}
          tooltipBtn={__("Новая папка")}
          icon={<AddFolderNewIcon />}
          setListCollapsed={setListCollapsed}
          listCollapsed={listCollapsed}
          onCreate={onCreateFolderCallback}
        >
          <div
            className={classNames(styles.folderListWrap, `scrollbar-thin-${theme}`)}
            ref={folderListWrapRef}
            onScroll={onScroll}
          >
            <ul className={styles.foldersList}>
              <li className={styles.listItem} title={listCollapsed ? __("Предоставленный доступ") : undefined}>
                <NavLink
                  to={`${FOLDER_ROUTES.SHARED}${search}`}
                  className={({ isActive }) => {
                    return classNames(styles.link, {
                      [styles.active]: isActive
                    });
                  }}
                >
                  <SharedIcon className={styles.sharedIcon} />
                  <span
                    className={classNames(styles.name, {
                      [styles.collapsed]: listCollapsed
                    })}
                  >
                    {__("Предоставленный доступ")}
                  </span>
                </NavLink>
              </li>
              <li className={styles.listItem} title={listCollapsed ? __("Избранное") : undefined}>
                <NavLink
                  to={`${FOLDER_ROUTES.FAVORITE}${search}`}
                  className={({ isActive }) => {
                    return classNames(styles.link, {
                      [styles.active]: isActive
                    });
                  }}
                >
                  <FavoriteIcon />
                  <span
                    className={classNames(styles.name, {
                      [styles.collapsed]: listCollapsed
                    })}
                  >
                    {__("Избранное")}
                  </span>
                </NavLink>
              </li>
              {folders.map((folder) => (
                <FolderItem
                  key={folder.id_dir}
                  folder={folder}
                  listCollapsed={listCollapsed}
                  handleSelectFolder={handleSelectFolder}
                  filteredContextMenu={filteredContextMenu}
                  contextMenu={filteredContextMenu(folder)}
                />
              ))}
            </ul>
          </div>
        </List>
        <div className={styles.workSpaceWrap}>
          {recentFiles.length > 0 && <RecentFiles />}
          <FileServicePanel />
          {dirId || isShared || isFavorite ? (
            <>
              <FolderBreadcrumbs />
              <Outlet />
            </>
          ) : (
            <Clue icon="happy" text={__("Выберите папку для начала работы ")} />
          )}
          {pickedItems.open && <BottomMenuOption />}
        </div>
      </div>
    )
  );
};

export default FoldersPage;
