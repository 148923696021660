import "chartjs-plugin-datalabels";

import { ReactComponent as ClearIcon } from "assets/PrivateCabinet/close.svg";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import classNames from "classnames";
import { endOfDay, endOfMonth, endOfYear, format, getTime, startOfDay, startOfMonth, startOfYear } from "date-fns";
import { MS_OF_DAY, useMonths } from "generalComponents/Calendars/CalendarHelper";
import { PROJECT_CATEGORIES } from "generalComponents/collections/project";
import SelectChosen from "generalComponents/SelectChosen/SelectChosen";
import { useFiltredTask, useFiltredUsers } from "generalComponents/Services/projectServices";
import { ANALITIC_INFO } from "models/project/helperContent";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import InfoTooltip from "../../Components/InfoTooltip/InfoTooltip";
import { dateToMs, minmaxDate } from "../../helpers";
import ChartAverageTasks from "../ChartAverageTasks/ChartAverageTasks";
import ChartCategories from "../ChartCategories/ChartCategories";
import ChartExtendPeriod from "../ChartExtendPeriod/ChartExtendPeriod";
import ChartTasks from "../ChartTasks/ChartTasks";
import HelperContent from "../HelperContent/HelperContent";
import styles from "./AnaliticCharts.module.sass";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Filler,
  ArcElement,
  LineElement,
  Tooltip,
  ChartDataLabels
);

const AnaliticCharts = ({ date, setDate }) => {
  const { __ } = useLocales();
  const tasks = useFiltredTask();
  const { project, selectUser } = useProjectsSelectors();
  const months = useMonths();
  const { userInfo } = useUserSelectors();
  const users = useFiltredUsers();
  const [year, setYear] = useState(new Date().getFullYear());

  const dates = useMemo(() => minmaxDate(tasks), [tasks]);
  const { max, maxLong, min } = dates;

  const getPeriodDates = useMemo(() => {
    const start = date?.start || format(new Date(min), "dd.MM.yyyy");
    const end = date?.end || format(new Date(maxLong), "dd.MM.yyyy");
    return (
      <>
        {start} - {end}
      </>
    );
  }, [date?.end, date?.start, maxLong, min]);

  const analiticsTasks = useMemo(() => {
    const tasksCharts = tasks.filter((t) => !t.is_epic && t.is_backlog < 0);
    return tasksCharts;
  }, [tasks]);

  const getFormatingDate = (dateValue) => {
    const dateArr = dateValue.split(".");
    const year = Number(dateArr[2]);
    const month = Number(dateArr[1]) - 1;
    const day = Number(dateArr[0]);
    return { year, month, day };
  };

  const filtredDateTask = useMemo(() => {
    if (date) {
      const dateStart = getFormatingDate(date.start);
      const filterStart = dateToMs(new Date(dateStart.year, dateStart.month, dateStart.day));

      const dateEnd = getFormatingDate(date.end);
      const filterEnd = dateToMs(endOfDay(new Date(dateEnd.year, dateEnd.month, dateEnd.day)));

      return analiticsTasks.filter((t) => {
        const taskStart = dateToMs(t.date_start);
        const taskEnd = dateToMs(t.date_long || t.date_end);
        return (
          (taskStart < filterEnd && taskStart >= filterStart) ||
          (taskEnd < filterEnd && taskEnd > filterStart) ||
          (taskEnd > filterEnd && taskStart < filterStart)
        );
      });
    }

    return analiticsTasks;
  }, [date, analiticsTasks]);

  // ==== AnaliticsCategories ====

  const getAmountTasks = (item) => filtredDateTask.filter((t) => t.id_category === item.id).length;

  const renderAnaliticsCategories = (item) => {
    const amountCurrent = getAmountTasks(item);
    const amountOther = amountCurrent ? analiticsTasks.length - amountCurrent : 1;
    const data = {
      amount: [amountCurrent, amountOther],
      borders: [item.color.dark, "#e5e5e5"],
      borderWidth: [5, 3]
    };
    const perstange = filtredDateTask.length > 0 ? Math.round((amountCurrent / filtredDateTask.length) * 100) : 0;

    return (
      <div className={styles.chartItem}>
        <div className={styles.legend}>
          <span className={styles.circle} style={{ backgroundColor: item.color.dark }} />
          <span>
            {amountCurrent} {__("задач")}
          </span>
        </div>
        <ChartCategories items={data} total={perstange} color={item.color.dark} />
        <span>{item.name}</span>
      </div>
    );
  };

  // ==== Progres Project ====

  const progress = useMemo(() => {
    const allDays = Math.round((maxLong - min) / MS_OF_DAY);
    const today = Math.round((new Date().getTime() - min) / MS_OF_DAY);
    const currentLeft = Math.round((today * 100) / allDays);
    const end = Math.round((max - min) / MS_OF_DAY);
    const endProgress = maxLong > max ? Math.round((end * 100) / allDays) : 100;

    const current = () => {
      if (currentLeft > 100) {
        return 100;
      }
      if (currentLeft < 0) {
        return 0;
      }
      return currentLeft;
    };
    return { currentProgress: current(), endProgress };
  }, [max, maxLong, min]);

  const renderProgress = () => {
    return (
      <>
        <div className={styles.row}>
          <div className={styles.legend}>
            <span className={classNames(styles.circle, styles.blue)} />
            <span className={styles.column}>
              {__("Старт")}
              <span>{format(new Date(min), "dd.MM.yy")}</span>
            </span>
          </div>
          <div className={styles.legend}>
            <span className={classNames(styles.circle, styles.orange)} />
            <span className={styles.column}>
              {__("Факт")}
              <span>{format(new Date(), "dd.MM.yy")}</span>
            </span>
          </div>
          <div className={styles.legend}>
            <span className={classNames(styles.circle, styles.green)} />
            <span className={styles.column}>
              {__("План")}
              <span>{format(new Date(max), "dd.MM.yy")}</span>
            </span>
          </div>
          {maxLong > max && (
            <div className={styles.legend}>
              <span className={classNames(styles.circle, styles.red)} />
              <span className={styles.column}>
                {__("Прогноз ")}
                <span>{format(new Date(maxLong), "dd.MM.yy")}</span>
              </span>
            </div>
          )}
        </div>
        <div className={styles.progressBlock}>
          <div className={classNames(styles.item, styles.start)} style={{ width: `${progress.currentProgress}%` }} />
          <div className={classNames(styles.item, styles.current)} style={{ left: `${progress.currentProgress}%` }} />
          <div className={classNames(styles.item, styles.end)} style={{ left: `${progress.endProgress}%` }} />
          {maxLong > max && (
            <div
              className={classNames(styles.item, styles.extend)}
              style={{ width: `${100 - progress.endProgress}%` }}
            />
          )}
        </div>
      </>
    );
  };

  // ==== Extension Chart ====

  const years = useMemo(() => {
    const minYear = new Date(min).getFullYear();
    const nowYear = new Date().getFullYear();
    const yearArray = [];
    for (let i = minYear; i <= nowYear; i++) {
      yearArray.push(i);
    }
    return yearArray;
  }, [min]);

  const monthTasks = useCallback(
    (m) => {
      const dateYear = year === null ? new Date().getFullYear() : year;
      const start = getTime(startOfMonth(new Date(dateYear, m)));
      const end = getTime(endOfMonth(new Date(dateYear, m)));

      return tasks.filter((t) => {
        const taskEnd = t.date_long || t.date_end;
        return (
          t.date_long &&
          ((dateToMs(t.date_start) < end && dateToMs(t.date_start) >= start) ||
            (dateToMs(taskEnd) < end && dateToMs(taskEnd) > start) ||
            (dateToMs(taskEnd) > end && dateToMs(t.date_start) < start))
        );
      });
    },
    [year, tasks]
  );

  const extensiondData = useMemo(
    () =>
      months().map((m) => {
        const amount = monthTasks(m.id).reduce((summ, t) => {
          const endMonth = getTime(endOfMonth(new Date(year, m.id)));
          const startMonth = getTime(startOfMonth(new Date(year, m.id)));
          const extraEnd = dateToMs(t.date_long) > endMonth ? endMonth : dateToMs(t.date_long);
          const end = dateToMs(t.date_end) > startMonth ? dateToMs(t.date_end) : startMonth;
          const delta = Math.round((extraEnd - end) / MS_OF_DAY) > 0 ? Math.round((extraEnd - end) / MS_OF_DAY) : 0;
          return summ + delta;
        }, 0);
        return amount;
      }),
    [year, monthTasks, months]
  );

  const extensionAmount = useMemo(() => {
    const startYear = getTime(startOfYear(new Date(year, 0)));
    const endYear = getTime(endOfYear(new Date(year, 0)));
    return tasks.filter((t) => {
      const extStart = dateToMs(t.date_end);
      const extEnd = dateToMs(t.date_long);

      return (
        t.date_long &&
        ((extStart < endYear && extStart >= startYear) ||
          (extEnd < endYear && extEnd > startYear) ||
          (extEnd > endYear && extStart < startYear))
      );
    }).length;
  }, [tasks, year]);

  const totalExtensionDays = useMemo(() => extensiondData.reduce((summ, el) => summ + el, 0), [extensiondData]);

  const averageExtension = useMemo(
    () => Math.floor((totalExtensionDays / extensionAmount) * 10) / 10 || 0,
    [extensionAmount, totalExtensionDays]
  );

  // ==== Users done Tasks ====
  const filtredUsers = useMemo(
    () =>
      users.filter((u) => {
        if (selectUser !== "0") {
          return u.id_user === selectUser;
        }
        return u;
      }),
    [selectUser, users]
  );

  const usersTasks = useMemo(
    () =>
      filtredUsers.map((u) =>
        analiticsTasks
          .filter((t) => t.id_category === PROJECT_CATEGORIES.DONE && t.id_executor.includes(u.id_user))
          .filter((t) => {
            if (date && t.move_history) {
              const dateStart = getFormatingDate(date.start);
              const filterStart = dateToMs(new Date(dateStart.year, dateStart.month, dateStart.day));

              const dateEnd = getFormatingDate(date.end);
              const filterEnd = dateToMs(endOfDay(new Date(dateEnd.year, dateEnd.month, dateEnd.day)));

              const dateToDoneTasks = t.move_history.filter((i) => i.id_category_new === PROJECT_CATEGORIES.DONE);
              const dateToDone = dateToDoneTasks[dateToDoneTasks.length - 1]?.date;
              const doneDate = dateToDone ? getFormatingDate(dateToDone.slice(0, 10)) : null;
              const timeDone = doneDate ? getTime(new Date(doneDate.year, doneDate.month, doneDate.day)) : null;

              return timeDone <= filterEnd && timeDone >= filterStart;
            }
            return t;
          })
      ),
    [filtredUsers, analiticsTasks, date]
  );

  const averageTaskDone = useMemo(() => {
    const totalDays = usersTasks.map((userArr) => {
      const t = userArr.reduce((acc, t) => {
        if (t.move_history) {
          const dateToDoneTasks = t.move_history.filter((i) => i.id_category_new === PROJECT_CATEGORIES.DONE);
          const dateToDone = dateToDoneTasks[dateToDoneTasks.length - 1]?.date;
          const doneDate = dateToDone ? getFormatingDate(dateToDone.slice(0, 10)) : null;
          const timeDone = doneDate ? getTime(endOfDay(new Date(doneDate.year, doneDate.month, doneDate.day))) : null;

          const dateToStart = t.move_history.find((i) => i.id_category_new === PROJECT_CATEGORIES.IN_WORK)?.date;
          const startDate = dateToStart ? getFormatingDate(dateToStart.slice(0, 10)) : null;
          const timeStart = startDate
            ? getTime(startOfDay(new Date(startDate.year, startDate.month, startDate.day)))
            : null;

          const dateToWait = t.move_history.find((i) => i.id_category_new === PROJECT_CATEGORIES.PENDING)?.date;
          const waitDate = dateToWait ? getFormatingDate(dateToWait.slice(0, 10)) : null;
          const timeWait = waitDate ? getTime(startOfDay(new Date(waitDate))) : null;
          const timeCreate = getTime(startOfDay(new Date(t.date_start || t.ut)));
          const start = timeStart || timeWait || timeCreate;

          const delta = timeDone ? Math.round((timeDone - start) / MS_OF_DAY) : 0;

          return acc + delta;
        }
        return acc;
      }, 0);
      return Math.floor((t / userArr.length) * 10) / 10 || 0;
    });
    const average = Math.floor((totalDays.reduce((summ, el) => summ + el, 0) / totalDays.length) * 10) / 10;
    return { totalDays, average };
  }, [usersTasks]);

  // ===== Dinamics Tasks ====

  const dinamicsTask = useMemo(() => {
    const dates = minmaxDate(filtredDateTask);
    const getDate = (value) => {
      const d = getFormatingDate(value);
      return getTime(new Date(d.year, d.month, d.day));
    };
    const startPeriod = date ? getDate(date.start) : dates.min;
    const endPeriod = date ? getDate(date.end) : dates.maxLong;

    const daysArray = [];
    const amountTasks = [];
    const amountExtension = [];
    for (let i = startPeriod; i <= endPeriod; i += MS_OF_DAY) {
      daysArray.push(format(new Date(i), "dd.MM.yy"));
      const filterEnd = i + MS_OF_DAY - 1;
      const filterStart = i;
      const total = filtredDateTask.filter((t) => {
        const taskStart = dateToMs(t.date_start);
        const taskEnd = dateToMs(t.date_long || t.date_end);

        return (
          (taskStart < filterEnd && taskStart >= filterStart) ||
          (taskEnd < filterEnd && taskEnd > filterStart) ||
          (taskEnd > filterEnd && taskStart < filterStart)
        );
      });
      const totalExt = filtredDateTask.filter((t) => {
        const taskStart = dateToMs(t.date_end);
        const taskEnd = dateToMs(t.date_long);

        return (
          t.date_long &&
          ((taskStart < filterEnd && taskStart > filterStart) ||
            (taskEnd < filterEnd && taskEnd > filterStart) ||
            (taskEnd > filterEnd && taskStart < filterStart))
        );
      });
      amountTasks.push(total.length);
      amountExtension.push(totalExt.length);
    }

    return { daysArray, amountTasks, amountExtension };
  }, [date, filtredDateTask]);

  return (
    <div className={styles.wrap}>
      <p className={styles.text}>
        {__("Аналитика за период")}&nbsp;{getPeriodDates}&nbsp;
        {date ? <ClearIcon onClick={() => setDate(null)} width={12} height={12} /> : <span>({__("весь период")})</span>}
      </p>
      <div className={styles.row}>
        <div className={classNames(styles.block, styles.half)}>
          <div className={styles.row}>
            <h2 className={styles.title}>{__("Статистика задач")}</h2>
            <InfoTooltip title={__("Информация")} text={<HelperContent block={ANALITIC_INFO.CATEGORIES} />} />
          </div>
          <div className={classNames(styles.charts, `scrollbar-thin-${userInfo.theme}`)} style={{}}>
            {project.tasks_category.map((item) => renderAnaliticsCategories(item))}
          </div>
        </div>
        <div className={classNames(styles.block, styles.half)}>
          <div className={styles.row}>
            <h2 className={styles.title}>{__("Прогресс создания проекта")}</h2>
            <div className={styles.rowStart}>
              <div className={styles.textInfoBlue}>
                {__("Выполнено")}:&nbsp;<span>{progress.currentProgress}%</span>
              </div>
              <InfoTooltip title={__("Информация")} text={<HelperContent block={ANALITIC_INFO.PROGRESS} />} />
            </div>
          </div>
          {renderProgress()}
        </div>
      </div>
      <div className={classNames(styles.block, styles.blockTasks)}>
        <div className={styles.row}>
          <div className={styles.rowStart}>
            <h2 className={styles.title}>{__("Статистика просроченых задач")}</h2>
            <div className={styles.selectBox}>
              <SelectChosen variant="low" value={year} placeholder={__("Год")}>
                <ul className={styles.selectList}>
                  {years.map((y) => (
                    <li key={y} onClick={() => setYear(y)}>
                      {y}
                    </li>
                  ))}
                </ul>
              </SelectChosen>
            </div>
          </div>
          <div className={styles.rowStart}>
            <div className={styles.textInfoBlue}>
              {__("Среднее время отклонения")}:&nbsp;
              <span>
                {averageExtension}&nbsp;{__("дней")}
              </span>
            </div>
            <div className={classNames(styles.textInfoRed)}>
              {__("Продление окончания проекта")}:&nbsp;
              <span>
                {totalExtensionDays}&nbsp;{__("дней")}
              </span>
            </div>
            <InfoTooltip title={__("Информация")} text={<HelperContent block={ANALITIC_INFO.EXTEND} />} />
          </div>
        </div>
        <div style={{ height: 200 }}>
          <ChartExtendPeriod year={year} average={averageExtension} extendData={extensiondData} />
        </div>
      </div>
      <div className={classNames(styles.block, styles.blockTasks)}>
        <div className={styles.row}>
          <h2 className={styles.title}>{__("Среднее время выполнения задач")}</h2>

          <div className={styles.rowStart}>
            <div className={styles.textInfoBlue}>
              {__("Среднее время выполнения задач")}:&nbsp;
              <span>
                {averageTaskDone.average}&nbsp;{__("дней")}
              </span>
            </div>
            <InfoTooltip title={__("Информация")} text={<HelperContent block={ANALITIC_INFO.AVERANGE_DONE} />} />
          </div>
        </div>
        <div style={{ height: 200 }}>
          <ChartAverageTasks
            usersData={averageTaskDone.totalDays}
            average={averageTaskDone.average}
            users={filtredUsers.map((u) => u.name)}
          />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.row}>
          <h2 className={styles.title}>{__("Динамика кол-ва задач")}</h2>
          <div className={styles.rowStart}>
            <div className={styles.textInfoBlue}>
              <span className={styles.circle} />
              &nbsp;
              {__("Кол-во задач")}
            </div>
            <div className={styles.textInfoRed}>
              <span className={styles.circle} />
              &nbsp;
              {__("Кол-во просроченных задач")}
            </div>

            <InfoTooltip title={__("Информация")} text={<HelperContent block={ANALITIC_INFO.TASKS} />} />
          </div>
        </div>
        <div style={{ height: 200 }}>
          <ChartTasks datas={dinamicsTask} />
        </div>
      </div>
    </div>
  );
};

export default AnaliticCharts;

AnaliticCharts.propTypes = {
  date: PropTypes.exact({ start: PropTypes.string, end: PropTypes.string }),
  setDate: PropTypes.func
};
