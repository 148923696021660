import classNames from "classnames";
import Empty from "containers/Dashboard/Empty/Empty";
import ShareItem from "containers/Dashboard/Share/ShareItem/ShareItem";
import { FOLDER_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { useLocales } from "react-localized";
import { Link } from "react-router-dom";
import { useSharedSelectors } from "Store/selectors/sharedSelector";

import styles from "./Share.module.sass";

const Share = ({ type }: { type: string }) => {
  const { __ } = useLocales();
  const { shared } = useSharedSelectors();

  return (
    <div className={classNames(styles.container, styles[type])}>
      <Link className={styles.title} to={`/${ROUTES.FOLDERS}/${FOLDER_ROUTES.SHARED}`}>
        {__("Доступы")}
      </Link>
      {shared.length === 0 ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={classNames(styles.scrollWrapper, "scrollbar-thin-blue")}>
          <div className={styles.list}>
            {shared.map((item, i) => (
              <ShareItem key={i} item={item} type={type} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Share;
