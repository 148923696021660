import { IFolder } from "models/store/folders/foldersStore";

export interface ICreateObjectLibraryModalState {
  open: boolean;
  titleHead?: string;
  btnLabel?: string;
  callbak?: () => void | undefined;
  folder?: IFolder;
  type: "addLibrary" | "editLibrary" | "addProjectLibrary" | "editProjectLibrary" | "";
}

export const initialCreateObjectLibraryModalState = (): ICreateObjectLibraryModalState => {
  return { open: false, type: "" };
};
