import { ITooltipeText } from "../models";

export const TooltipeText = ({ tooltipe }: { tooltipe: ITooltipeText }) => {
  return (
    <g>
      <text x={tooltipe.x} y={tooltipe.y} textAnchor="start" fontSize={12}>
        {tooltipe.text}
      </text>
    </g>
  );
};
