import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classNames from "classnames";
import CheckBoxNew from "generalComponents/CheckBoxNew/CheckBoxNew";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import SearchField from "generalComponents/SearchField/SearchField";
import { ISprint } from "models/store/joinProjects/joinProgects";
import { FC, MouseEvent, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { getConvertFormatDateTime } from "utils/getConvertDateTime";

import styles from "./TaskSprints.module.sass";

interface IProps {
  sprints: string[];
  setSprints: (val: string[]) => void;
  readOnly?: boolean;
}

const TaskSprints: FC<IProps> = ({ sprints, setSprints, readOnly = false }) => {
  const { __ } = useLocales();
  const {
    user: {
      userInfo: { lang }
    }
  } = useUserSelectors();
  const { project } = useJoinProjectsSelectors();

  const [show, setShow] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [_sprints, set_Sprints] = useState<ISprint[]>(project.sprints.filter((el) => sprints.includes(el.id)));

  const handleChange = (sprint: ISprint) => {
    if (!sprint) {
      set_Sprints([]);
      return;
    }
    if (_sprints.findIndex((s) => s.id === sprint.id) >= 0) {
      set_Sprints((prev) => prev.filter((s) => s.id !== sprint.id));
      return;
    }
    set_Sprints([..._sprints, sprint]);
  };

  const sendSprints = () => setSprints(_sprints.map((el) => el.id));

  const renderOverlay = () => {
    return (
      <div>
        <SearchField value={value} setValue={setValue} placeholder={__("Ведите")} />

        <ul className={styles.participants}>
          <li className={styles.item} onClick={() => handleChange(null)}>
            <CheckBoxNew isChecked={_sprints.length === 0} />
            <span>{__("Без спринта(backlog)")}</span>
          </li>
          {project.sprints.map((item) => {
            const { date } = getConvertFormatDateTime({
              dateStart: item?.date_start,
              dateEnd: item?.date_end,
              locale: lang
            });
            return (
              <li key={item.id} className={styles.item} onClick={() => handleChange(item)}>
                <CheckBoxNew isChecked={_sprints.findIndex((s) => s.id === item.id) >= 0} />
                <span>{item.name}</span>
                <span className={styles.date}>{date}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <PopoverOverlay
      show={show}
      overlayContent={renderOverlay()}
      setShow={setShow}
      placement="bottom"
      btn="extra"
      onExit={sendSprints}
    >
      <div
        className={classNames(styles.label, { [styles.active]: show })}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          !readOnly && setShow(true);
        }}
      >
        {_sprints.length ? <p>{_sprints.map((s) => s?.name).join(", ")}</p> : <span>{__("Без спринта(backlog)")}</span>}
        <ArrowIcon className={classNames(styles.arrow, { [styles.arrowOpen]: show })} />
      </div>
    </PopoverOverlay>
  );
};

export default TaskSprints;
