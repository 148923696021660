import { FileBox } from "generalComponents/FileBox/FileBox";
import { FolderBox } from "generalComponents/FolderBox/FolderBox";
import { TypeElementBox } from "generalComponents/TypeElementBox/TypeElementBox";
import { IElementsItemProps } from "models/myFolders/workElements";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { AllElementsArrayType, AllElementsType } from "models/store/typeElements";
import { FC } from "react";
import { v4 as uuid } from "uuid";

import styles from "./LinesViewElements.module.sass";

const LinesViewElements: FC<IElementsItemProps> = ({ items }): JSX.Element => {
  const renderItem = (item: AllElementsType): JSX.Element => {
    if (Boolean(item.is_del) || Boolean(item.is_archive)) {
      return <TypeElementBox element={item} />;
    } else {
      if ((item as IFolder).is_dir) {
        return <FolderBox folder={item as IFolder} />;
      }

      return <FileBox file={item as IFile} />;
    }
  };

  return (
    <ul className={styles.list}>
      {(items as AllElementsArrayType).map((item: AllElementsType) => (
        <li key={uuid()} className={styles.item}>
          {renderItem(item)}
        </li>
      ))}
    </ul>
  );
};

export { LinesViewElements };
