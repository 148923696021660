import { useMenuFilterJournalTypeElements } from "collections/journal";
import { useListEmployees } from "collections/profile";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { IServePanel } from "models/servePanel";
import { FC, useEffect } from "react";
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFilterTypeElementsSelector } from "Store/selectors/filterTypeElementsSelectors";
import { useSortByDateSelector } from "Store/selectors/sortByDateSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { NavigationJournalOption } from "./components/NavigationJournalOption/NavigationJournalOption";
import styles from "./JournalServicePanel.module.sass";

export const JournalServicePanel: FC<IServePanel> = (): JSX.Element => {
  const { onAddSortByDate } = useActions();

  const { userInfo } = useUserSelectors();
  const { sortByDate } = useSortByDateSelector();
  const { filterTypeElements } = useFilterTypeElementsSelector();

  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [searchParams] = useSearchParams();
  const { actionLogId } = useParams();

  const idEmployee = Boolean(userInfo?.is_admin) && searchParams.get(SEARCH_PARAMS.USER_ID);

  const optionsEmployees = useListEmployees();
  const optionsTypeElements = useMenuFilterJournalTypeElements();

  const selectedDefaultEmployee = optionsEmployees.find(({ value }) =>
    idEmployee ? value === idEmployee : value === userInfo.id
  );

  useEffect(() => {
    if (selectedDefaultEmployee?.value !== userInfo?.id) {
      const path = {
        pathname,
        search: createSearchParams({
          [SEARCH_PARAMS.USER_ID]: selectedDefaultEmployee.value
        }).toString(),
        replace: true
      };

      navigate(path, { state });
    }
  }, []); //eslint-disable-line

  const onChangeDateFilter = (v: Date): void => {
    const y = v ? v.getFullYear() : undefined;
    const m = v ? v.getMonth() + 1 : undefined;
    const d = v ? v.getDate() : undefined;
    onAddSortByDate({ y, m, d });
  };

  const renderSelectedDate = (): Date => {
    if (sortByDate.y && sortByDate.m && sortByDate.d) {
      return new Date(sortByDate.y, sortByDate.m - 1, sortByDate.d);
    }
    return null;
  };

  return (
    <ToolsPanel size="large">
      <div className={styles.toolsLeft}>
        {actionLogId && <NavigationJournalOption actionLogId={actionLogId} />}
        {!actionLogId && (
          <>
            {Boolean(userInfo?.is_admin) && (
              <SelectCustom
                selectedDefault={{ name: selectedDefaultEmployee?.name, value: selectedDefaultEmployee?.value }}
                name="searchEmployees"
                options={optionsEmployees}
                width={176}
                variantHeight="small"
                search
              />
            )}
            <SelectCustom
              width={176}
              variantHeight="small"
              options={optionsTypeElements}
              selectedDefault={{
                name: filterTypeElements?.name || optionsTypeElements[0]?.name,
                value: filterTypeElements?.value || optionsTypeElements[0]?.value
              }}
            />
            <InputDate
              startDate={renderSelectedDate()}
              setStartDate={(v: Date) => onChangeDateFilter(v)}
              dateFormat="dd MMM yyyy"
              isClearable
              maxDate={new Date()}
            />
          </>
        )}
      </div>
    </ToolsPanel>
  );
};
