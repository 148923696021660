import { ReactComponent as PhotoIcon } from "assets/PrivateCabinet/camera.svg";
import { ReactComponent as PaintIcon } from "assets/PrivateCabinet/paint-brush.svg";
import Sound from "assets/sounds/printScreen.mp3";
import classNames from "classnames";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { imageSrc, NO_VALUE } from "generalComponents/variables/globalVariables";
import html2canvas from "html2canvas";
import { SAVE_TYPE } from "models/workElements/minitoolbar";
import PaintToolbar from "Pages/Cabinet/Components/WorkElements/MiniToolBar/PaintToolbar";
import React, { useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";

import { createWhiteSheetDataUrl } from "../Services/mediaServices";
import styles from "./ScreenPaintingPanel.module.sass";

const clientWidth = window.innerWidth;
const clientHeight = window.innerHeight;
const w = clientWidth * 0.8;
const h = clientHeight * 0.8;

const ScreenPaintingPanel = (): JSX.Element => {
  const { __ } = useLocales();
  const audioRef = useRef<HTMLAudioElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [type, setType] = useState<string>(NO_VALUE);
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    if (type) {
      getUrlData();
    }
  }, [type]); //eslint-disable-line

  const onClose = (): void => {
    setType(NO_VALUE);
    setIsReady(false);
  };

  const getPhoto = (data: string): void => {
    const img = new Image();
    img.src = data as string;
    img.onload = () => {
      const cr = canvasRef;
      const canvas = cr.current.getContext("2d");
      cr.current.width = w;
      cr.current.height = h;
      cr.current.style.width = w + "px";
      cr.current.style.height = h + "px";
      canvas.clearRect(0, 0, w, h);
      canvas.drawImage(img, 0, 0, w, h);
      setIsReady(true);
    };
  };

  const getUrlData = async (): Promise<void> => {
    try {
      if (type === "cleanSheet") {
        const result = await createWhiteSheetDataUrl(clientWidth, clientHeight);
        if (typeof result === "string") {
          getPhoto(result);
        } else {
          throw new Error(__("Упс!!! Попробуйте еще раз!"));
        }
      }
      if (type === "printScreen") {
        const result = await html2canvas(document.getElementById("root"), { allowTaint: true, proxy: imageSrc });
        getPhoto(result.toDataURL("image/png"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.screenPaintingPanel}>
      <ButtonIcon
        handleClick={() => setType("cleanSheet")}
        icon={<PaintIcon width={12} height={12} />}
        variant="grey"
        height={28}
        tooltip={__("Общий холст")}
      />
      <ButtonIcon
        handleClick={() => {
          audioRef.current.play();
          setType("printScreen");
        }}
        icon={<PhotoIcon width={13} height={11} />}
        variant="grey"
        height={28}
        tooltip={__("Скриншот")}
      />
      <audio src={Sound} ref={audioRef} />
      {type && (
        <div className={classNames(styles.popup, { [styles.opacity]: !isReady })}>
          <div className={styles.container}>
            {isReady && (
              <PaintToolbar
                canvasRef={canvasRef}
                onCancel={onClose}
                naturalWidth={clientWidth}
                naturalHeight={clientHeight}
                saveType={SAVE_TYPE.CREATE}
              />
            )}

            <canvas ref={canvasRef} className={styles.canvas} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ScreenPaintingPanel;
