import classNames from "classnames";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import styles from "./SelectChosen.module.sass";

const SelectChosen = ({ className, value, variant, handleChange, placeholder, readOnly, children, onClear }) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);

  useOutsideClick(ref, () => setOpen(false));

  const onChange = (e) => {
    setOpen(false);
    handleChange(e.target.value);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    onClear();
  };

  const toggleOpen = (e) => {
    e.stopPropagation();

    setOpen((open) => !open);
  };

  useEffect(() => {
    setOpen(false);
  }, [value]);

  return (
    <div
      ref={ref}
      className={classNames(className, styles.selectWrap, styles[variant], {
        [styles.active]: !!open
      })}
      onClick={() => setOpen(true)}
    >
      {typeof value === "string" || !value ? (
        <input
          className={styles.input}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      ) : (
        <>{value}</>
      )}
      {onClear && value && (
        <button className={styles.clear} type="button" onClick={handleClear}>
          <span className={styles.span} />
        </button>
      )}

      <span
        className={classNames({
          [styles.arrow]: true,
          [styles.active]: !!open
        })}
        onClick={toggleOpen}
      />
      <div
        className={classNames(styles.contentWrap, {
          [styles.active]: !!open
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default SelectChosen;

SelectChosen.defaultProps = {
  readOnly: true
};

SelectChosen.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  children: PropTypes.element,
  variant: PropTypes.string,
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool,
  onClear: PropTypes.func
};
