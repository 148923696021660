import { ProjectsTasksServicePanel } from "containers/JoinProject/JointProjectsTasks/components/ProjectsTasksServicePanel/ProjectsTasksServicePanel";
import JointProjectsTasks from "containers/JoinProject/JointProjectsTasks/JointProjectsTasks";
import { WrapperPage } from "containers/JoinProject/WrapperPage/WrapperPage";
import { ViewType } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

const ProjectTasksPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { onFetchTaskProject, onSetTasksProject } = useActions();
  const { project } = useJoinProjectsSelectors();
  const [showBacklog, setShowBacklog] = useState(false);
  const [viewTab, setViewTab] = useState(ViewType.BARS);
  const id_sprint = searchParams.get("sprint");

  const toggleBacklog = () => {
    setShowBacklog((prev) => !prev);
  };

  useEffect(() => {
    if (project?.sprints.some((sprint) => sprint.id === id_sprint)) return;

    const currentSprint = project.sprints.find(
      ({ date_start, date_end }) => new Date() >= new Date(date_start) && new Date() <= new Date(date_end)
    );

    if (currentSprint) {
      searchParams.set("sprint", currentSprint.id);
      setSearchParams(searchParams);
      return;
    }
    const lastSprints = project.sprints.filter(({ date_end }) => new Date(date_end) < new Date());
    const previusSprint = lastSprints[lastSprints.length - 1];
    if (previusSprint) {
      searchParams.set("sprint", previusSprint.id);
      setSearchParams(searchParams);
      return;
    }
    if (project.sprints[0]) {
      searchParams.set("sprint", project.sprints[0].id);
      setSearchParams(searchParams);
    }
  }, [project.sprints]); //eslint-disable-line

  useEffect(() => {
    const controller = new AbortController();
    if (id_sprint) {
      onFetchTaskProject({ id_sprint, controller });
    }
    return () => {
      onSetTasksProject([]);
      controller.abort();
    };
  }, [id_sprint]); //eslint-disable-line

  return (
    <WrapperPage>
      <ProjectsTasksServicePanel viewTab={viewTab} setViewTab={setViewTab} toggleBacklog={toggleBacklog} />
      <JointProjectsTasks showBacklog={showBacklog} viewTab={viewTab} />
    </WrapperPage>
  );
};

export default ProjectTasksPage;
