import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { TASK_MODALS } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { IAddPasswordProps } from "models/store/Cabinet/modals/taskModals";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./AddPassword.module.sass";

const AddPassword: React.FC<IAddPasswordProps> = ({ type, params }): JSX.Element => {
  const { __ } = useLocales();
  const { value, onChange } = useInputValue();
  const { onSetTaskModal, onEditTask } = useActions();

  const onSubmit = (): void => {
    if (type === TASK_MODALS.ADD_PASSWORD) {
      onSetTaskModal({ type: params.callbackType, params: { ...params.note, pass: value } });
    }
    if (type === TASK_MODALS.NEW_PASSWORD) {
      console.log("params", params);
      onEditTask({ id_task: params.note.id, pass: value }, EVENT_TYPE.PASS_CHANGE);
    }
  };

  const closeModal = (): void => {
    onSetTaskModal({ type: params.callbackType, params: { ...params.note } });
  };

  return (
    <div className={styles.passWrap}>
      <Input value={value} onChange={onChange} isPass label={__("Введите пароль")} />
      <div className={styles.btns}>
        <Button type="button" variant={ButtonVariantType.CANCEL} text={__("Отмена")} onClick={closeModal} />
        <Button
          type="button"
          variant={ButtonVariantType.OK}
          text={__("Добавить")}
          onClick={onSubmit}
          disabled={!value}
        />
      </div>
    </div>
  );
};

export default AddPassword;
