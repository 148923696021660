import { getTime, startOfDay, startOfQuarter, startOfYear } from "date-fns";
import { MS_OF_DAY } from "generalComponents/Calendars/CalendarHelper";
import { useDurationToString, useFindStatus, useGetDuration, useGetTaskEnd } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { TASK_DEPENDENCIES, TASK_TYPE } from "models/store/joinProjects/joinProgects";
import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { dateISO } from "utils/dateToISO";

import { DependencyArrow } from "../components/DependencyArrow";
import { DrawLine } from "../components/DrawLine";
import { GantHeader } from "../components/GantHeader/GantHeader";
import { RowTask } from "../components/RowTask";
import { SprintsRect } from "../components/SprintsRect";
import TaskGant from "../components/TaskGant/TaskGant";
import { TodayLine } from "../components/TodayLine";
import { TooltipeText } from "../components/TooltipeText";
import { H, PT, W } from "../GantSpace/GantSpace";
import { IBlockItem, ICommonData, IGantTypeProps, ITaskData, ITooltipeText } from "../models";

export const GantYear: FC<IGantTypeProps> = ({ tasks, hoverRow, setHoverRow, setScrollLeft, minDate, maxDate }) => {
  const { __ } = useLocales();
  const { onUpdateTaskProject, onAddTaskDependency } = useActions();
  const { project } = useJoinProjectsSelectors();
  const findStatus = useFindStatus();
  const durationToString = useDurationToString();
  const getDateEnd = useGetTaskEnd();
  const getDuration = useGetDuration();

  const [commonData, setCommonData] = useState<ICommonData>(null);
  const [tasksData, setTasksData] = useState<ITaskData[]>([]);
  const [arrowsData, setArrowsData] = useState<string[]>([]);
  const [tooltipe, setTooltipe] = useState<ITooltipeText>(null);

  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [activeTask, setActiveTask] = useState<ITaskData>(null);
  const [mouseOffset, setMouseOffset] = useState<number>(0);

  const [isResizingLeft, setIsResizingLeft] = useState<boolean>(false);
  const [isResizingRight, setIsResizingRight] = useState<boolean>(false);

  const [isDrawLine, setIsDrawLine] = useState<boolean>(false);
  const [startPosition, setStartPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [endPosition, setEndPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [activeParentTask, setActiveParentTask] = useState<ITaskData>(null);
  const [activeChildTask, setActiveChaildTask] = useState<ITaskData>(null);
  const [parentHover, setParentHover] = useState<ITaskData>(null);
  const [childHover, setChildHover] = useState<ITaskData>(null);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tasks.length < 0 || !ref?.current) return;
    setCommonData(getCommonData());
  }, [tasks]); // eslint-disable-line

  useEffect(() => {
    if (!commonData) return;
    setTasksData(getTasksData());
    setScrollLeft(commonData.todayX);
  }, [commonData]); // eslint-disable-line

  useEffect(() => {
    if (tasksData.length <= 0) return;
    setArrowsData(getArrowsData());
  }, [tasksData]); // eslint-disable-line

  const getCommonData = () => {
    const dateStart = new Date(minDate).getFullYear();
    const dateEnd = new Date(maxDate).getFullYear();
    console.log(document.body.clientWidth);

    let years: number[] = [];
    const blockWidth = W * 4;

    for (let i = dateStart - 1; i <= dateEnd + 12; i++) {
      years.push(getTime(startOfYear(new Date(i, 0))));
    }
    const getQuarter = (y: number, idx: number) => {
      const querter = [];
      const getName = (n: number) => {
        switch (n) {
          case 0:
            return "I";
          case 3:
            return "II";
          case 6:
            return "III";
          case 9:
            return "IV";
          default:
            break;
        }
      };
      let counter = 0;
      for (let j = 0; j <= 11; j += 3) {
        const d = new Date(y);
        d.setMonth(j);
        const value = getTime(d);
        querter.push({ value, name: getName(j), x: W / 2 + W * counter + idx * blockWidth });
        counter += 1;
      }
      return querter;
    };

    const blocks: Record<number, IBlockItem[]> = years.reduce((acc, year, idx) => {
      return { ...acc, [year]: getQuarter(year, idx) };
    }, {});

    const blockItems = Object.values(blocks).flat(1);
    const gantWidth = blockItems.length * W;
    const taskHeight = tasks.length * H + PT;
    const gantHeight = taskHeight > ref.current.clientHeight - 38 ? taskHeight : ref.current.clientHeight - 38;

    const verticalLines = Array.from({ length: blockItems.length }, (_, index) => index + 1)
      .map((el) => `M${el * W},0 L${el * W},${gantHeight}`)
      .join(" ");
    const sprints = project.sprints.map((s) => {
      const idxStart = blockItems.findIndex((el) => {
        return el.value === getTime(startOfQuarter(new Date(s.date_start)));
      });
      const deltaDays = (getTime(startOfDay(new Date(s.date_start))) - blockItems[idxStart].value) / MS_OF_DAY;
      const widthDay = W / 91;
      const x = idxStart * W + widthDay * deltaDays;

      const idxEnd = blockItems.findIndex((el) => el.value === getTime(startOfQuarter(new Date(s.date_end))));
      const deltaDaysEnd = (getTime(startOfDay(new Date(s.date_end))) - blockItems[idxEnd].value) / MS_OF_DAY;
      const xEnd = idxEnd * W + widthDay * deltaDaysEnd + widthDay;
      const width = xEnd - x;
      const isText = s.name.length * 7 < width;
      const isCurrent = new Date(s.date_start) < new Date() && new Date() < new Date(s.date_end);
      return { id: s.id, name: s.name, width, x, isCurrent, isText };
    });

    const getToday = (): number => {
      const today = new Date();
      const idxToday = blockItems.findIndex((el) => el.value === getTime(startOfQuarter(today)));
      const deltaDays = (getTime(today) - blockItems[idxToday].value) / MS_OF_DAY;
      const widthDay = W / 91;

      return idxToday * W + widthDay * deltaDays;
    };

    const todayX = getToday();
    return { blocks, blockItems, gantWidth, verticalLines, blockWidth, gantHeight, sprints, todayX };
  };

  const getTasksData = () => {
    const _tasks = tasks.map((t, i) => {
      const idxStart = commonData.blockItems.findIndex(
        (el) => el.value === getTime(startOfQuarter(new Date(t.date_start)))
      );

      const deltaDaysStart =
        (getTime(startOfDay(new Date(t.date_start))) - commonData.blockItems[idxStart].value) / MS_OF_DAY;
      const widthDay = W / 91;
      const x = idxStart * W + widthDay * deltaDaysStart;

      const idxEnd = t.date_end
        ? commonData.blockItems.findIndex((el) => el.value === getTime(startOfQuarter(new Date(t.date_end))))
        : -1;
      let deltaDaysEnd = 0;
      let widthDayEnd = 0;
      if (idxEnd >= 0) {
        deltaDaysEnd = (getTime(startOfDay(new Date(t.date_end))) - commonData.blockItems[idxEnd].value) / MS_OF_DAY;
        widthDayEnd = W / 91;
      }
      const endTask = idxEnd * W + widthDayEnd * deltaDaysEnd;

      const idxOver = t.date_long
        ? commonData.blockItems.findIndex((el) => el.value === getTime(startOfQuarter(new Date(t.date_long))))
        : -1;
      let deltaDaysOver = 0;
      let widthDayOver = 0;
      if (idxOver >= 0) {
        deltaDaysOver = (getTime(startOfDay(new Date(t.date_long))) - commonData.blockItems[idxOver].value) / MS_OF_DAY;
        widthDayOver = W / 91;
      }
      const xOver = idxOver * W + widthDayOver * deltaDaysOver;

      const y = i * H + PT + 3;
      const isTask = t.id_type === TASK_TYPE.TASK;

      const isOverdue = t.time_long > 0;

      const widthPrev = endTask - x || 1;
      const widthOverdue = t.date_long ? xOver - endTask || 1 : 0;
      const widthTotal = widthPrev + widthOverdue;
      const xEnd = x + widthTotal;

      const xGradient = widthTotal ? 100 - (widthOverdue * 100) / widthTotal : 0;
      const textOverdue = isOverdue ? __(`(просроченно на ${durationToString(t.time_long)})`) : "";
      const title = `${t.num} ${t.name} ${textOverdue}`;
      const textLength = title.length;

      const isText = textLength * 6 < widthTotal;
      const statusColor = findStatus(t.id_status).color;
      return {
        ...t,
        title,
        x,
        y,
        isText,
        statusColor,
        isTask,
        widthPrev,
        widthOverdue,
        widthTotal,
        isOverdue,
        xGradient,
        xEnd
      };
    });
    return _tasks;
  };

  const getArrowsData = () => {
    const _tasks = tasksData.filter((el) => el.links?.REQUIRED_FOR);
    const arrows = _tasks.map((el) => {
      const arrow = el.links.REQUIRED_FOR.map((t) => {
        const tChild = tasksData.find((item) => item.id === t.id);
        if (!tChild) return "";
        const xStart = el.isTask ? el.x + el.widthTotal : el.x + 14;
        const yStart = el.y + 12;
        const xEnd = tChild?.x - 5;
        const yEnd = tChild.y + 12;
        let diff = "";
        if (el.y < tChild.y) {
          if (xStart + 10 < xEnd - 10) {
            diff = ` V${yEnd}`;
          }
          if (xStart + 10 >= xEnd - 10) {
            diff = `V${yStart + H / 2} H${xEnd - 10} V${yEnd}`;
          }
        } else {
          if (xStart + 10 < xEnd - 10) {
            diff = `V${yEnd}`;
          }
          if (xStart + 10 >= xEnd - 10) {
            diff = `V${yStart - H / 2} H${xEnd - 10} V${yEnd}`;
          }
        }

        const path = `M${xStart},${yStart} H${xStart + 10} ${diff} H${xEnd}`;
        return path;
      });
      return arrow;
    });
    return arrows.flat();
  };

  const handleMouseMove = (e: MouseEvent<SVGSVGElement>) => {
    if (isMoving && activeTask) {
      const { offsetX } = e.nativeEvent;
      const x = offsetX - mouseOffset;
      const xEnd = x + activeTask.widthTotal;
      const updTask = { ...activeTask, x, xEnd };
      setTasksData((prev) => prev.map((t) => (t.id === updTask.id ? updTask : t)));
    }
    if (isResizingLeft && activeTask) {
      const { offsetX } = e.nativeEvent;
      const x = offsetX - mouseOffset;
      const xDelta = x - activeTask.x;
      const widthPrev = activeTask.widthPrev - xDelta;
      const widthTotal = activeTask.widthOverdue + widthPrev;
      const xGradient = 100 - (activeTask.widthOverdue * 100) / widthTotal;
      const isText = activeTask.title.length * 6 + 10 < widthTotal;
      if (widthPrev < 30) return;
      const updTask = { ...activeTask, x, widthPrev, isText, xGradient, widthTotal };
      setTasksData((prev) => prev.map((t) => (t.id === updTask.id ? updTask : t)));
    }
    if (isResizingRight && activeTask) {
      const { offsetX } = e.nativeEvent;
      const xEnd = offsetX + mouseOffset;
      const xDelta = xEnd - activeTask.xEnd;
      let widthOverdue = activeTask.widthOverdue;
      let widthPrev = activeTask.widthPrev;
      if (widthOverdue > 0) {
        widthOverdue = widthOverdue + xDelta;
      } else {
        widthPrev = widthPrev + xDelta;
      }

      const widthTotal = widthOverdue + widthPrev;
      const xGradient = 100 - (widthOverdue * 100) / widthTotal;
      const isText = activeTask.title.length * 6 + 10 < widthTotal;
      if (widthTotal < 29) return;
      const updTask = { ...activeTask, widthPrev, widthOverdue, widthTotal, xGradient, isText, xEnd };
      setTasksData((prev) => prev.map((t) => (t.id === updTask.id ? updTask : t)));
    }
    if (isDrawLine) {
      const { offsetX, offsetY } = e.nativeEvent;
      if (childHover || parentHover) return;
      setEndPosition({ x: offsetX, y: offsetY });
    }
  };

  const getDateFromX = (x: number) => {
    const idxDay = Math.floor(x / W); // определяем индекс в массиве дат
    let date = new Date(commonData.blockItems[idxDay].value); // определяем новый квартал начала задачи
    const remainderMonth = x - idxDay * W; // расчитываем разницу от начала месяца
    const _day = Math.ceil((remainderMonth * 91) / W); // определяем день
    date.setDate(_day);
    date.setHours(project.work_hours[0]); // записываем часы к дате на начало рабочего дня
    date.setMinutes(0); // записываем минуты к дате
    while (!project.work_days.includes(date.getDay())) {
      date = new Date(date.getTime() + MS_OF_DAY);
    }
    return date;
  };

  const clearDrawing = () => {
    setActiveChaildTask(null);
    setActiveParentTask(null);
    setIsDrawLine(false);
    setStartPosition({ x: 0, y: 0 });
    setEndPosition({ x: 0, y: 0 });
    setChildHover(null);
    setParentHover(null);
  };

  const handleMouseUp = () => {
    if (isMoving && activeTask) {
      const _task = tasksData.find((el) => el.id === activeTask.id);
      let dateStart = getDateFromX(_task.x);
      const dateEnd = getDateEnd(dateStart, _task.duration); // расчитываем новую дату окончания задачи
      const dateLong = _task.date_long ? getDateEnd(dateEnd, _task.time_long) : "";
      const updTask = {
        ..._task,
        date_start: dateISO(dateStart),
        date_end: dateISO(dateEnd),
        date_long: dateISO(dateLong)
      };
      setTasksData((prev) => prev.map((el) => (el.id === updTask.id ? updTask : el)));

      const data = new FormData();
      data.append("id_project", project.id);
      data.append("id_task", _task.id);
      data.append("date_start", dateISO(dateStart));
      data.append("date_end", dateISO(dateEnd));
      dateLong && data.append("date_long", dateISO(dateLong));
      onUpdateTaskProject({ data });

      setIsMoving(false);
      setActiveTask(null);
      setMouseOffset(0);
      return;
    }
    if (isResizingLeft && activeTask) {
      const _task = tasksData.find((el) => el.id === activeTask.id);
      const dateStart = getDateFromX(_task.x);
      const duration = getDuration(dateStart, new Date(_task.date_end)); // расчитываем новую дату окончания задачи

      const data = new FormData();
      data.append("id_project", project.id);
      data.append("id_task", _task.id);
      data.append("date_start", dateISO(dateStart));
      data.append("duration", String(duration));
      onUpdateTaskProject({ data });

      setIsResizingLeft(false);
      setActiveTask(null);
      setMouseOffset(0);

      return;
    }
    if (isResizingRight && activeTask) {
      const _task = tasksData.find((el) => el.id === activeTask.id);

      const dateEnd = getDateFromX(_task.xEnd);
      let date_end = _task.date_end;
      let duration = _task.duration;
      let timeLong = _task.time_long;
      let dateLong = _task.date_long;
      if (_task.date_long && dateEnd > new Date(_task.date_end)) {
        timeLong = getDuration(new Date(_task.date_end), dateEnd);
        dateLong = dateISO(dateEnd);
      } else {
        timeLong = 0;
        dateLong = "";
        date_end = dateISO(dateEnd);
        duration = getDuration(new Date(_task.date_start), dateEnd);
      }
      const updTask = { ..._task, date_end: date_end, duration, time_long: timeLong, date_long: dateLong };
      setTasksData((prev) => prev.map((el) => (el.id === updTask.id ? updTask : el)));

      const data = new FormData();
      data.append("id_project", project.id);
      data.append("id_task", _task.id);
      data.append("date_end", dateISO(date_end));
      data.append("duration", String(duration));
      data.append("date_long", dateISO(dateLong));
      data.append("time_long", String(timeLong));
      onUpdateTaskProject({ data });

      setIsResizingRight(false);
      setActiveTask(null);
      setMouseOffset(0);

      return;
    }

    if (isDrawLine) {
      if (activeParentTask && childHover) {
        if (activeParentTask?.links?.DEPENDS_ON?.some((el) => el.id === childHover.id)) {
          clearDrawing();
          return;
        }
        const links =
          activeParentTask.links && activeParentTask.links.REQUIRED_FOR
            ? { ...activeParentTask.links, REQUIRED_FOR: [...activeParentTask.links.REQUIRED_FOR, childHover] }
            : { REQUIRED_FOR: [childHover] };
        setTasksData((prev) => prev.map((el) => (el.id === activeParentTask.id ? { ...el, links } : el)));
        const payload = {
          id_task1: activeParentTask.id,
          id_task2: childHover.id,
          type1: TASK_DEPENDENCIES.REQUIRED_FOR,
          type2: TASK_DEPENDENCIES.DEPENDS_ON
        };
        onAddTaskDependency(payload);
      }
      if (activeChildTask && parentHover) {
        if (parentHover?.links?.DEPENDS_ON?.some((el) => el.id === activeChildTask.id)) {
          clearDrawing();
          return;
        }
        const links =
          parentHover.links && parentHover.links.REQUIRED_FOR
            ? { ...parentHover.links, REQUIRED_FOR: [...parentHover.links.REQUIRED_FOR, activeChildTask] }
            : { REQUIRED_FOR: [activeChildTask] };
        setTasksData((prev) => prev.map((el) => (el.id === parentHover.id ? { ...el, links } : el)));
        const payload = {
          id_task1: parentHover.id,
          id_task2: activeChildTask.id,
          type1: TASK_DEPENDENCIES.REQUIRED_FOR,
          type2: TASK_DEPENDENCIES.DEPENDS_ON
        };
        onAddTaskDependency(payload);
      }
      clearDrawing();
    }
  };

  return (
    <div ref={ref}>
      {commonData && (
        <div style={{ width: commonData.gantWidth }}>
          <GantHeader commonData={commonData} format="year" />
          <svg
            width={commonData.gantWidth}
            height={commonData.gantHeight}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={() => setHoverRow("")}
          >
            <path d={commonData.verticalLines} stroke="#E7EBEA" strokeWidth="1" />
            {commonData?.sprints?.map((s) => (
              <SprintsRect key={s.id} sprint={s} />
            ))}
            {commonData.todayX > 0 && <TodayLine x={commonData.todayX} />}
            {arrowsData.length > 0 && arrowsData.map((path) => <DependencyArrow key={path} path={path} />)}
            {tasksData.map((t, i) => (
              <g key={t.id}>
                <RowTask id={t.id} y={i * H + PT} isHover={t.id === hoverRow} setHoverRow={setHoverRow} />
                <TaskGant
                  t={t}
                  activeChildTask={activeChildTask}
                  activeParentTask={activeParentTask}
                  hoverRow={hoverRow}
                  isDrawLine={isDrawLine}
                  isMoving={isMoving}
                  setChildHover={setChildHover}
                  setParentHover={setParentHover}
                  setTooltipe={setTooltipe}
                  setActiveTask={setActiveTask}
                  setIsMoving={setIsMoving}
                  setMouseOffset={setMouseOffset}
                  setIsResizingLeft={setIsResizingLeft}
                  setIsResizingRight={setIsResizingRight}
                  setIsDrawLine={setIsDrawLine}
                  setStartPosition={setStartPosition}
                  setEndPosition={setEndPosition}
                  setActiveChaildTask={setActiveChaildTask}
                  setActiveParentTask={setActiveParentTask}
                />
              </g>
            ))}
            {tooltipe && <TooltipeText tooltipe={tooltipe} />}
            {isDrawLine && <DrawLine startPosition={startPosition} endPosition={endPosition} />}
          </svg>
        </div>
      )}
    </div>
  );
};
