import { JoinProjectElementsList } from "containers/JoinProject/JointProjectFiles/components/JoinProjectElementsList/JoinProjectElementsList";
import { JointProjectFilesPanel } from "containers/JoinProject/JointProjectFiles/components/JointProjectFilesPanel/JointProjectFilesPanel";
import { WrapperPage } from "containers/JoinProject/WrapperPage/WrapperPage";
import { useAllFolders } from "generalComponents/Services/folderServices/folderServices";
import { PAGE_DEPTH } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { IFolder, initialFolderState } from "models/store/folders/foldersStore";
import { TProjectFilesArray } from "models/store/joinProjects/joinProgects";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

const ProjectFilesPage = () => {
  const { onGetProjectFiles, onResetProjectFilesList, onSelectFolder, onSetProjectFiles } = useActions();

  const { project } = useJoinProjectsSelectors();

  const foldersAll = useAllFolders();

  const { dirId } = useParams();

  useEffect(() => {
    const controller = new AbortController();

    if (!dirId && project?.folders_file?.length) {
      const id_dir = project.folders_file[0].id_dir;

      const folder: IFolder = foldersAll.find((el) => el.id_dir === id_dir);

      if (!folder) return;

      onSelectFolder(folder);

      const payload = {
        id_dir,
        dep: PAGE_DEPTH.PROJECT_FILES,
        callback: (files: TProjectFilesArray) => onSetProjectFiles(files)
      };

      onGetProjectFiles(payload, controller);
    }

    return () => {
      onSelectFolder(initialFolderState());
      onResetProjectFilesList();
      controller.abort();
    };
  }, [dirId]); // eslint-disable-line

  return (
    <WrapperPage>
      <JointProjectFilesPanel />
      {dirId ? <Outlet /> : <JoinProjectElementsList />}
    </WrapperPage>
  );
};

export default ProjectFilesPage;
