import Button from "generalComponents/Button/Button";
import CropImage from "generalComponents/CropImage/CropImage";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialAvatarCropModalState } from "models/store/Cabinet/modals/avatarCropModal";
import { useRef } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./AvatarCrop.module.sass";

const AvatarCrop = () => {
  const { __ } = useLocales();
  const { onSetAvatarCropModal } = useActions();
  const {
    avatarCrop: { imgSrc, callback }
  } = useGlobalModalsSelectors();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const closeModal = (): void => {
    onSetAvatarCropModal(initialAvatarCropModalState());
  };

  const onSave = () => {
    const image64 = canvasRef.current.toDataURL("image/jpeg");
    callback(image64);
    closeModal();
  };

  return (
    <PopUp set={closeModal} zIndex={105}>
      <div className={styles.wrap}>
        <HeaderModal onClose={closeModal} title={__("Отредактировать изображение")} />
        <div className={styles.body}>
          <CropImage imgSrc={imgSrc} canvasRef={canvasRef} />
          <canvas ref={canvasRef} style={{ width: "50px" }} />
        </div>
        <div className={styles.footer}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.MEDIUM}
            text={__("Отмена")}
            onClick={closeModal}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.MEDIUM}
            text={__("Сохранить")}
            onClick={onSave}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default AvatarCrop;
