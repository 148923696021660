import api from "api";
import Empty from "containers/Dashboard/Empty/Empty";
import MailItem from "containers/Dashboard/Mail/MailItem/MailItem";
import Loader from "generalComponents/Loaders/4HUB";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { ROUTES } from "generalComponents/variables/routing";
import { LoaderTypes } from "models/generalComponents/loader";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import { ILetterInfo, IPostbox } from "models/store/postbox/postbox";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { Link } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./Mail.module.sass";

const Mail = () => {
  const { __ } = useLocales();
  const { postboxes } = usePostboxSelectors();
  const [allLetters, setAllLetters] = useState<ILetterInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { uid } = useUserSelectors();

  useEffect(() => {
    const fetchAllLetters = async () => {
      setIsLoading(true);
      const allLetters = [];
      for (const postbox of postboxes) {
        try {
          const letters = await fetchLettersByPostbox(postbox);
          const lettersWithPostbox = letters.map((letter: ILetterInfo) => ({ ...letter, postbox }));
          allLetters.push(...lettersWithPostbox);
        } catch (error) {
          console.error(error);
        }
      }
      const sortedLetters = allLetters.sort((a, b) => {
        return new Date(b.time).getTime() - new Date(a.time).getTime();
      });

      setAllLetters(sortedLetters);
      setIsLoading(false);
    };

    fetchAllLetters();
  }, [postboxes]); //eslint-disable-line

  const fetchLettersByPostbox = async (postbox: IPostbox) => {
    const params = {
      uid,
      user: postbox.email,
      pass: postbox.pass,
      folder: POSTBOX_SYSTEM_FOLDER.INBOX,
      limit: 20,
      start: 0
    };
    const { data } = await api.get(`/ajax/email_messages_get.php`, { params });
    checkResponseStatus(data.ok);
    return data.data.messages;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Link to={ROUTES.MAIL} className={styles.title}>
          {__("Почта")}
        </Link>
      </div>
      {isLoading ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="relative"
          width="100px"
          height="100px"
          containerType="bounceDots"
          zIndex={1}
        />
      ) : postboxes?.length < 1 || allLetters.length < 1 ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={styles.scrollWrapper}>
          <div className={styles.list}>
            {allLetters.map((item) => (
              <MailItem item={item} key={item.message_id} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Mail;
