import Editor from "@draft-js-plugins/editor";
import { ReactComponent as IconNote } from "assets/icons/note.svg";
import { createEditorStateFromJson } from "containers/Notes/NotesCategories/components/NoteItem/NoteItem";
import { useActions } from "hooks/useActions";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { INote } from "models/store/notes/notes";
import { useLocales } from "react-localized";

import styles from "./NoteIcon.module.sass";

const NoteIcon = ({ item }: { item: INote }) => {
  const { __ } = useLocales();
  const { onSetPasswordModal, onSetNoteEditModal, onSetActiveNote, onSetTopMessageModal } = useActions();

  const customBlockStyleFn = () => {
    return styles.noMargin;
  };

  const renderEditorContent = () => {
    if (item.pass) {
      // Если у нас есть item.pass
      const text = item.prim ? JSON.parse(item.prim).blocks[0].text : ""; // Получаем текст из первого блока контента
      const truncatedText = text.slice(0, 3); // Берем первые три символа текста
      const stars = "*\u00A0".repeat(10); // Создаем строку из 20 звездочек
      return (
        <div>
          <div>{truncatedText + stars}</div>
          <div>{stars}</div>
        </div>
      ); // Возвращаем первые три символа и звездочки
    } else {
      return (
        <Editor
          blockStyleFn={customBlockStyleFn}
          editorState={createEditorStateFromJson(item.prim)}
          readOnly={true}
          onChange={() => {}}
        />
      );
    }
  };

  const openModal = () => {
    if (item.pass) {
      onSetPasswordModal({
        open: true,
        title: __("Заметка — защита паролем"),
        successButtonText: __("Открыть"),
        successCallback: (receivedPass) => {
          if (receivedPass === item.pass) {
            onSetNoteEditModal({ open: true, isFull: true });
            onSetActiveNote(item);
          } else {
            onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: "Неверный пароль" });
          }
        }
      });
    } else {
      onSetNoteEditModal({ open: true, isFull: true });
      onSetActiveNote(item);
    }
  };

  return (
    <div className={styles.container} onClick={openModal}>
      <div className={styles.iconWrapper}>
        <IconNote width={23} height={23} />
      </div>
      <div className={styles.text}>
        <div>{renderEditorContent()}</div>
      </div>
    </div>
  );
};

export default NoteIcon;
