import { IStatusLineProps } from "models/tasks/myTasks";
import React from "react";

import styles from "./StatusLine.module.sass";

const StatusLine: React.FC<IStatusLineProps> = ({ title, children }): JSX.Element => {
  return (
    <div className={styles.wrap}>
      <p className={styles.title}>{title}</p>
      {children}
    </div>
  );
};

export default StatusLine;
