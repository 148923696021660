import { ReactComponent as EditIcon } from "assets/PrivateCabinet/edit.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import { ReactComponent as CopyIcon } from "assets/PrivateCabinet/link-2.svg";
import classNames from "classnames";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { useCopyLink } from "generalComponents/Services/browserServices";
import { MODALS } from "generalComponents/variables/global";
import { imageSrc } from "generalComponents/variables/globalVariables";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { ILinkItemProps } from "models/project/linkItem";
import React, { useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";

import styles from "./LinkItem.module.sass";

const LinkItem: React.FC<ILinkItemProps> = ({ link, handleDelete, selectedLinks, handleSelected }): JSX.Element => {
  const dispatch = useDispatch();
  const linkRef = useRef<HTMLInputElement | null>(null);
  const copyLink = useCopyLink();

  const getImg = (): string => {
    const path = link.link.split("/");
    const src = `${path[0]}//${path[2]}/favicon.ico`;
    return src;
  };

  const handleEdit = (): void => {
    dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.EDIT_LINK, params: link }));
  };

  const handleCopyLink = (): void => {
    copyLink(linkRef, link.link);
  };

  const isActive: boolean = useMemo(() => {
    return selectedLinks.some((id) => id === link.id);
  }, [link.id, selectedLinks]);

  return (
    <div className={classNames(styles.wrap, { [styles.active]: isActive })} onClick={() => handleSelected(link.id)}>
      <img src={link.icon ? link.icon : getImg()} alt="icon" className={styles.img} width={32} height={32} />
      <div className={styles.name}>
        <span>{link.name}</span>
      </div>
      {link.tags && <div className={styles.tag}>#{link.tags}</div>}
      {link.emo && <img src={`${imageSrc}assets/PrivateCabinet/smiles/${link.emo}.svg`} alt="emoji" />}
      {link.color && <div className={styles.color} style={{ backgroundColor: link.color }} />}
      <a
        href={link.link}
        target="_blank"
        rel="noreferrer"
        className={classNames(styles.link, { [styles.activeLink]: isActive })}
      >
        {link.link}
      </a>
      <ButtonIcon handleClick={handleEdit} icon={<EditIcon />} variant="white" />
      <ButtonIcon handleClick={handleCopyLink} icon={<CopyIcon />} variant="white" />
      <ButtonIcon handleClick={() => handleDelete([link.id])} variant="white" notHover>
        <TrashIcon className={styles.icon} />
      </ButtonIcon>
      <input value={link.link} readOnly className={styles.hiddenInput} ref={linkRef} />
    </div>
  );
};

export default LinkItem;
