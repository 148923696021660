import { useGetItemSize } from "generalComponents/Hooks/Files/useGetItemSize";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";

export const useGetIconSize = () => {
  const { isSmallSize, isMediumSize, isBigSize } = useGetItemSize();
  const { isBarsView, isLinesView, isLinesPreviewView, isBarsPreviewView } = useGetItemView();

  const getFolderIconWidth = (): number => {
    if (isBarsView || isBarsPreviewView) {
      if (isSmallSize) return 34;
      if (isMediumSize) return 45;
      if (isBigSize) return 55;
    }

    if (isLinesView || isLinesPreviewView) {
      if (isSmallSize) return 25;
      if (isMediumSize) return 32;
      if (isBigSize) return 40;
    }
  };

  const getFolderIconHeight = (): number => {
    if (isBarsView || isBarsPreviewView) {
      if (isSmallSize) return 28;
      if (isMediumSize) return 45;
      if (isBigSize) return 55;
    }

    if (isLinesView || isLinesPreviewView) {
      if (isSmallSize) return 21;
      if (isMediumSize) return 27;
      if (isBigSize) return 34;
    }
  };

  const getFileIconWidth = (): number => {
    if (isBarsView || isBarsPreviewView) {
      if (isSmallSize) return 27;
      if (isMediumSize) return 33;
      if (isBigSize) return 40;
    }

    if (isLinesView || isLinesPreviewView) {
      if (isSmallSize) return 18;
      if (isMediumSize) return 28;
      if (isBigSize) return 33;
    }
  };

  const getFileIconHeight = (): number => {
    if (isBarsView || isBarsPreviewView) {
      if (isSmallSize) return 37;
      if (isMediumSize) return 44;
      if (isBigSize) return 54;
    }

    if (isLinesView || isLinesPreviewView) {
      if (isSmallSize) return 24;
      if (isMediumSize) return 37;
      if (isBigSize) return 44;
    }
  };

  return {
    getFolderIconWidth,
    getFolderIconHeight,
    getFileIconWidth,
    getFileIconHeight
  };
};
