import api from "api";
import axios, { AxiosProgressEvent } from "axios";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { EVENT_TYPE, PAGE_DEPTH } from "generalComponents/variables/global";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { TABS } from "generalComponents/variables/library";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { FoldersNextArrayType, ILibraryRubric } from "models/store/library/library";
import { LibraryActions } from "models/store/library/libraryActions";
import { ILoadingLibraryFiles } from "models/store/library/uploadFilesModals/uploadLibraryFiles";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { LibraryTypes } from "Store/types/libraryTypes";
import { getLocationSearchParams } from "utils/getLocationSearchParams";

import { onSetLoaderModal } from "./ModalActions";
import { onSetTopMessageModal } from "./ModalActions";
import { onSetRemoveNonEmptyRubricLibraryModal } from "./ModalActions";

export const onSetLibraryLoader = (payload: boolean): LibraryActions => ({
  type: LibraryTypes.SET_LIBRARY_LOADER,
  payload
});

export const onSetLibraryRubrics = (payload: ILibraryRubric[]): LibraryActions => ({
  type: LibraryTypes.SET_LIBRARY_RUBRICS,
  payload
});

export const onSetAddLibraryRubric = (payload: ILibraryRubric): LibraryActions => ({
  type: LibraryTypes.SET_ADD_LIBRARY_RUBRIC,
  payload
});

export const onSetUpdateLibraryRubric = (payload: ILibraryRubric): LibraryActions => ({
  type: LibraryTypes.SET_UPDATE_LIBRARY_RUBRIC,
  payload
});

export const onSetDelLibraryRubric = (payload: { id: string }): LibraryActions => ({
  type: LibraryTypes.SET_DEL_LIBRARY_RUBRIC,
  payload
});

export const onSetLibraryPage = (payload: number): LibraryActions => ({
  type: LibraryTypes.SET_LIBRARY_PAGE,
  payload
});

export const onSetLibraryFolders = (payload: FoldersNextArrayType): LibraryActions => ({
  type: LibraryTypes.SET_LIBRARY_FOLDERS,
  payload
});

export const onSetAddLibraryFolder = (payload: IFolder): LibraryActions => ({
  type: LibraryTypes.SET_ADD_LIBRARY_FOLDER,
  payload
});

export const onSetUpdateLibraryFolder = (payload: IFolder): LibraryActions => ({
  type: LibraryTypes.SET_UPDATE_LIBRARY_FOLDER,
  payload
});

export const onSetDelLibraryFolder = (payload: IFolder): LibraryActions => ({
  type: LibraryTypes.SET_DEL_LIBRARY_FOLDER,
  payload
});

export const onSetLibraryFolderInfo = (payload: IFolder): LibraryActions => ({
  type: LibraryTypes.SET_LIBRARY_FOLDER_INFO,
  payload
});

export const onResetLibraryFolders = (): LibraryActions => ({
  type: LibraryTypes.RESET_LIBRARY_FOLDERS
});

export const onChangeLibraryLoadingFiles = (payload: ILoadingLibraryFiles): LibraryActions => ({
  type: LibraryTypes.CHANGE_LIBRARY_LOADING_FILES,
  payload
});

export const onResetLibraryLoadingFiles = (): LibraryActions => ({
  type: LibraryTypes.RESET_LIBRARY_LOADING_FILES
});

export const onSetLibraryTotalItems = (payload: number): LibraryActions => ({
  type: LibraryTypes.SET_LIBRARY_TOTAL_ITEMS,
  payload
});

export const onSetDelLibraryFiles = (payload: IFile[]): LibraryActions => ({
  type: LibraryTypes.SET_DEL_LIBRARY_FILES,
  payload
});

export const onGetAllLibraryRubrics =
  (controller?: AbortController): ThunkAction<void, RootState, unknown, CabinetActions | LibraryActions> =>
  async (dispatch, getState) => {
    const { year, month } = getLocationSearchParams();
    const params = {
      uid: getState().user.uid,
      y: year || undefined,
      m: month || undefined,
      d: 1,
      dep: PAGE_DEPTH.LIBRARY
    };

    try {
      dispatch(onSetLibraryLoader(true));
      const { data } = await api.get("/ajax/dir_dep_list.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetLibraryRubrics(data.deps));
    } catch {
      if (!controller?.signal.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onSetLibraryLoader(false));
    }
  };

export const onAddLibraryFiles =
  (payload: ILoadingLibraryFiles): ThunkAction<void, RootState, unknown, LibraryActions | CabinetActions> =>
  async (dispatch, getState) => {
    const formData = new FormData();

    formData.append("uid", getState().user.uid);
    formData.append("id_parent", "0");
    formData.append("chapter", payload.id_category);
    payload?.id_dep && formData.append("id_dep", payload.id_dep);
    formData.append("dir_name", payload.dir_name);
    formData.append("prim", payload.prim);
    payload?.files?.forEach((file: File) => formData.append("myfile[]", file));
    formData.append("dep", PAGE_DEPTH.LIBRARY);

    try {
      const { data } = await api.post("/ajax/dir_add.php", formData, {
        onUploadProgress: (progressEvent: AxiosProgressEvent): void => {
          const time = Math.floor(
            progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
          );
          const percent = progressEvent.progress * 100;
          dispatch(onChangeLibraryLoadingFiles({ ...payload, percent, time }));
        }
      });

      dispatch(onChangeLibraryLoadingFiles({ ...payload, done: true, isOpen: false }));

      dispatch(onSetAddLibraryFolder(data?.file_info));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.ADD
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    }
  };

let promisesEditFolder: Array<{ url: string; params: FormData }> = [];

export const onEditLibraryFiles =
  (
    payload: ILoadingLibraryFiles & { deletedFiles?: IFile[]; id_dirs: string[] }
  ): ThunkAction<void, RootState, unknown, LibraryActions | CabinetActions> =>
  async (dispatch, getState) => {
    const isEdits =
      payload.id_category ||
      payload.id_dep ||
      payload.dir_name ||
      payload.prim ||
      payload.prim === "" ||
      payload?.files?.length;

    if (isEdits) {
      const formData = new FormData();

      formData.append("uid", getState().user.uid);
      payload.id_dirs?.forEach((id: string) => formData.append("id_dirs[]", id));
      payload.id_category && formData.append("chapter", payload.id_category);
      payload.id_dep && formData.append("id_dep", payload.id_dep);
      payload.dir_name && formData.append("dir_name_new", payload.dir_name);
      payload.prim !== undefined && formData.append("prim", payload.prim);
      !!payload?.files?.length && payload.files.forEach((file: File) => formData.append("myfile[]", file));
      formData.append("dep", PAGE_DEPTH.LIBRARY);

      promisesEditFolder.push({ url: "/ajax/dir_edit.php", params: formData });
    }

    if (payload?.deletedFiles?.length) {
      const formData = new FormData();

      formData.append("uid", getState().user.uid);
      payload?.deletedFiles?.forEach(({ fid }) => formData.append("fids[]", fid));
      formData.append("dep", PAGE_DEPTH.LIBRARY);

      promisesEditFolder.push({ url: "/ajax/file_del_force.php", params: formData });
    }

    try {
      const [dataFirst] = await axios.all(
        promisesEditFolder.map(({ url, params }) =>
          api.post(url, params, {
            onUploadProgress: (progressEvent: AxiosProgressEvent): void => {
              const time = Math.floor(
                progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
              );
              const percent = progressEvent.progress * 100;
              dispatch(onChangeLibraryLoadingFiles({ ...payload, percent, time }));
            }
          })
        )
      );

      promisesEditFolder = [];

      dispatch(onChangeLibraryLoadingFiles({ ...payload, done: true, isOpen: false }));

      dataFirst?.data?.file_info && dispatch(onSetUpdateLibraryFolder(dataFirst?.data?.file_info));

      !dataFirst?.data?.file_info &&
        payload?.deletedFiles?.length &&
        dispatch(onSetDelLibraryFiles(payload.deletedFiles));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.EDIT
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLibraryLoader(false));
    }
  };

export const onGetLibraryElements =
  (
    controller?: AbortController,
    payload?: { id_dir?: string }
  ): ThunkAction<void, RootState, unknown, LibraryActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { tab, id_dep, chapter, search_query, year, month } = getLocationSearchParams();

    const groupByCategory = (id_dep && !chapter) || (!chapter && !id_dep);
    const isDir = chapter || id_dep || tab === TABS.OBJECTS || search_query;

    const params = {
      uid: getState().user.uid,
      per_page: PER_PAGE,
      page: getState()?.Library?.page,
      chapter: chapter && !payload?.id_dir ? chapter : undefined,
      id_dir: payload?.id_dir ? payload?.id_dir : undefined,
      id_dep: isDir && id_dep ? id_dep : undefined,
      search: search_query || undefined,
      y: year || undefined,
      m: month || undefined,
      show_files: 1,
      group_month: chapter && isDir ? 1 : 0,
      group_chapter: groupByCategory && isDir ? 1 : 0,
      is_dir: isDir ? 1 : 0,
      sort_reverse: chapter ? 1 : 0,
      dep: PAGE_DEPTH.LIBRARY
    };
    try {
      dispatch(onSetLibraryLoader(true));
      const { data } = await api.get("/ajax/file_list.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetLibraryFolders(data.files));
      dispatch(onSetLibraryTotalItems(data.total));
      dispatch(onSetLibraryFolderInfo(data.dir_info));
    } catch {
      if (!controller?.signal?.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onSetLibraryLoader(false));
    }
  };

export const onTrashLibraryFolder =
  (payload: IFolder): ThunkAction<void, RootState, unknown, LibraryActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_dir: payload.id_dir,
      dep: PAGE_DEPTH.LIBRARY
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/dir_del.php`, { params });

      checkResponseStatus(data.ok);

      dispatch(onSetDelLibraryFolder(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onAddLibraryRubric =
  (payload: {
    id_category: string;
    id_item?: string;
    name: string;
    icon: File | string;
  }): ThunkAction<void, RootState, unknown, LibraryActions | CabinetActions> =>
  async (dispatch, getState) => {
    const formData = new FormData();

    formData.append("uid", getState().user.uid);
    payload?.id_category && formData.append("chapter", payload.id_category);
    payload?.id_item && formData.append("id_item", payload.id_item);
    payload?.name && formData.append("name", payload.name);
    payload?.icon && formData.append("icon", payload.icon);
    payload?.icon === "" && formData.append("icon_del", "1");
    formData.append("dep", PAGE_DEPTH.LIBRARY);

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.post(`/ajax/${payload.id_item ? "dir_dep_edit" : "dir_dep_add"}.php`, formData);

      if (payload?.id_item) {
        dispatch(onSetUpdateLibraryRubric(data?.deps));
      } else {
        dispatch(onSetAddLibraryRubric(data?.deps));
      }

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: payload?.id_item ? EVENT_TYPE.EDIT : EVENT_TYPE.ADD
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onTrashLibraryRubric =
  (payload: {
    id_item: string;
    id_item_new?: string;
  }): ThunkAction<void, RootState, unknown, LibraryActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_item: payload.id_item,
      id_item_new: payload?.id_item_new ? payload.id_item_new : undefined,
      dep: PAGE_DEPTH.LIBRARY
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/dir_deps_del.php`, { params });

      if (data?.error === "folders exists in this dep") {
        dispatch(onSetRemoveNonEmptyRubricLibraryModal({ open: true, id_dep_del: payload.id_item }));
      } else {
        checkResponseStatus(data.ok);

        dispatch(onSetDelLibraryRubric({ id: payload.id_item }));

        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.SUCCESS,
            variantMessage: EVENT_TYPE.IS_DEL
          })
        );
      }
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };
