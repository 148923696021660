import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import Button from "generalComponents/Button/Button";
import { FilterMarkers } from "generalComponents/FilterMarkers/FilterMarkers";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { useProjectMainAccess } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectLinksPanel.module.sass";

export const JointProjectLinksPanel = () => {
  const { onSetLinksCategoryModal, onSetLinksModal } = useActions();

  const { __ } = useLocales();

  const isMainAccess = useProjectMainAccess();

  const { project } = useJoinProjectsSelectors();

  return (
    <ToolsPanel size="large">
      <div className={styles.wrapper}>
        <div className={styles.toolsRight}>
          <FilterMarkers showColors={false} showTags={false} />
        </div>
        {(isMainAccess || project.accesses.MGR_LINKS) && (
          <div className={styles.buttons}>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              onClick={() => onSetLinksCategoryModal({ open: true })}
              size={ButtonSizeType.SMALL}
              iconL={<SettingsIcon fill="#274A42" />}
            />
            <Button
              variant={ButtonVariantType.BLUE}
              onClick={() => onSetLinksModal({ open: true })}
              size={ButtonSizeType.SMALL}
              text={__("Добавить ссылку")}
            />
          </div>
        )}
      </div>
    </ToolsPanel>
  );
};
