import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";
import { IJoinProjectsState } from "models/store/joinProjects/joinProgects";

export const useJoinProjectsSelectors = (): IJoinProjectsState => {
  const joinProjects = useTypeSelector((s) => s.JoinProjects);
  const projectList = joinProjects.projectList;
  const project = joinProjects.project;
  const currentTask = joinProjects.currentTask;
  const loader = joinProjects.loader;
  const page = joinProjects.page;
  const total = joinProjects.total;
  return { projectList, project, currentTask, loader, page, total };
};
