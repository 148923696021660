import TextEditorComments from "generalComponents/TextEditorComment/TextEditorComments";
import { ICommentsProps } from "models/tasks/comments";
import React from "react";

import CommentHandler from "../CommentHandler/TaskComments";
import styles from "./Comments.module.sass";

const Comments: React.FC<ICommentsProps> = ({ handleSubmit, setComment, comment, isAddFile = true }): JSX.Element => {
  return (
    <div className={styles.textareaWrap}>
      <TextEditorComments setContent={setComment} content={comment} isAddFile={isAddFile} />
      <CommentHandler handleSubmit={handleSubmit} isComment={comment.length > 0} />
    </div>
  );
};

export default Comments;
