import classNames from "classnames";
import { isIFile } from "containers/Dashboard/DashboardItem/DashboardItem";
import Avatar from "generalComponents/Avatar/Avatar";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./ShareItem.module.sass";

const ShareItem = ({ item, type }: { item: IFile | IFolder; type: string }) => {
  const { pathname } = useLocation();
  const { onFileView } = useActions();
  const navigate = useNavigate();

  const onOpenFile = (): void => {
    if (isIFile(item)) {
      onFileView({ open: true, params: item });
    }
  };

  const onClickFolder = (): void => {
    navigate(`/${ROUTES.FOLDERS}/${item.id_dir}`, { state: { from: pathname } });
  };

  return (
    <div
      className={classNames(styles.container, styles[`container_${type}`])}
      onDoubleClick={isIFile(item) ? onOpenFile : onClickFolder}
    >
      <Avatar name={`Test Test`} size={type === "normal" ? 24 : 34} imgSrc={undefined} />
      {type === "big" && (
        <div className={styles.name}>
          {isIFile(item) && item.user_name} {isIFile(item) && item.user_sname}
        </div>
      )}
      <div className={styles.info}>
        <div className={styles.title}>Новый доступ</div>
        <div className={styles.file}>
          <ItemIcon
            width={isIFile(item) ? 12 : 15}
            height={15}
            color={item.color}
            ext={isIFile(item) ? item.ext : undefined}
            passIcon={isIFile(item) && item.is_pass === 1}
            isFolder={!isIFile(item)}
          />
          <span>{item.name}</span>
        </div>
      </div>
    </div>
  );
};

export default ShareItem;
