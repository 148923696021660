export enum TasksTypes {
  FETCH_TASKS = "FETCH_TASKS",
  ADD_TASK = "ADD_TASK",
  DELETE_TASK = "DELETE_TASK",
  EDIT_TASK = "EDIT_TASK",
  SELECT_TASK = "SELECT_TASK",
  ADD_TASK_COMMENT = "ADD_TASK_COMMENT",
  SELECT_DATE_FILTER = "SELECT_DATE_FILTER",
  SELECT_TYPE_FILTER = "SELECT_TYPE_FILTER"
}
