export enum SizeTypes {
  SMALL = "small",
  MEDIUM = "medium",
  BIG = "big"
}

export interface ISortFilesPayload {
  sort: string;
  group: string;
  sort_reverse: number;
}
