import api from "api";
import { ReactComponent as ClockIcon } from "assets/icons/clock_1.svg";
import { ReactComponent as CloseIcon } from "assets/icons/cross.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import { useSearchData } from "collections/search";
import SearchItem from "containers/Search/SearchItem/SearchItem";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import Input from "generalComponents/Input/Input";
import Loader from "generalComponents/Loaders/4HUB";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import RecentFile from "generalComponents/RecentFiles/RecentFile/RecentFile";
import { ROUTES } from "generalComponents/variables/routing";
import { useDebounce } from "hooks/useDebounce";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router";
import { useRecentFilesSelectors } from "Store/selectors/recentFilesSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./HeaderSearch.module.sass";

const HeaderSearch = () => {
  const { __ } = useLocales();
  const { uid } = useUserSelectors();
  const { recentFiles } = useRecentFilesSelectors();
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [selectedTool, setSelectedTool] = useState<string | undefined>();
  const [result, setResult] = useState([]);
  const { tools } = useSearchData();
  const [loading, setLoading] = useState(false);
  const controller = new AbortController();
  const navigate = useNavigate();

  const handleToolClick = (tool: string) => {
    setSelectedTool((prev) => (prev === tool ? undefined : tool)); // Set selected tool directly as a string
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;
    setSearch(newSearchValue);
  };

  const fetchResults = async () => {
    setLoading(true);
    try {
      const params = {
        uid,
        search,
        page: 1,
        per_page: 100,
        tools: selectedTool
      };
      const { data } = await api.get("/ajax/search.php", { params, signal: controller?.signal });
      setResult(data.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const debounceCallback = useDebounce(fetchResults, 300);

  useEffect(() => {
    if (!search) return;
    debounceCallback();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedTool]);

  return (
    <PopoverOverlay
      width={600}
      overlayContent={
        <div className={styles.content}>
          {search.length > 0 ? (
            <>
              <div className={styles.filter}>
                {tools.map((item, i) => (
                  <div
                    key={i}
                    className={`${styles.button} ${selectedTool === item.value ? styles.active : ""}`}
                    onClick={() => handleToolClick(item.value)}
                  >
                    {item.icon}
                    {item.title}
                  </div>
                ))}
              </div>
              <div className={styles.results}>
                {loading ? (
                  <div className={styles.empty}>
                    <Loader
                      type={LoaderTypes.BOUNCING_DOTS}
                      position="relative"
                      background="transparent"
                      zIndex={5}
                      width="200px"
                      height="200px"
                      containerType="bounceDots"
                    />
                  </div>
                ) : result.length > 0 ? (
                  result.map((item, index) => <SearchItem key={index} item={item} />)
                ) : (
                  <div className={styles.empty}>
                    <Clue icon="sad" text={__("Здесь пока пусто")} />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <span className={styles.title}>
                <ClockIcon />
                {__("Недавние объекты")}
              </span>
              <ul className={styles.list}>
                {recentFiles.map((file) => (
                  <li key={file.fid} className={styles.item}>
                    <RecentFile file={file} />
                  </li>
                ))}
              </ul>
            </>
          )}
          <Button
            className={styles.moreButton}
            variant={ButtonVariantType.LIGHT}
            size={ButtonSizeType.MEDIUM}
            text={__("Расширенный поиск")}
            onClick={() => {
              setSearch("");
              setShow(false);
              navigate(ROUTES.SEARCH);
            }}
          />
        </div>
      }
      show={show}
      setShow={setShow}
      placement="bottom"
    >
      <Input
        className={styles.input}
        value={search}
        onBlur={() => setShow(true)}
        onChange={handleInputChange}
        placeholder={__("Название, тег или Люди")}
        rightIcon={search.length > 0 ? <CloseIcon fill="#274A42" /> : <SearchIcon fill="#274A42" />}
        rightIconClick={() => setSearch("")}
      />
    </PopoverOverlay>
  );
};

export default HeaderSearch;
