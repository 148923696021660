import { ReactComponent as EmptyFacebookIcon } from "assets/icons/empty-facebook.svg";
import { ReactComponent as EmptyInstagramIcon } from "assets/icons/empty-instagram.svg";
import { ReactComponent as EmptyLinkedinIcon } from "assets/icons/empty-linkedin.svg";
import { ReactComponent as EmptySkypeIcon } from "assets/icons/empty-skype.svg";
import { ReactComponent as EmptySlackIcon } from "assets/icons/empty-slack.svg";
import { ReactComponent as EmptyTelegramIcon } from "assets/icons/empty-telegram.svg";
import { ReactComponent as EmptyTwitterIcon } from "assets/icons/empty-twitter.svg";
import { ReactComponent as EmptyViberIcon } from "assets/icons/empty-viber.svg";
import { ReactComponent as EmptyVk } from "assets/icons/empty-vk.svg";
import { ReactComponent as EmptyWhatsappIcon } from "assets/icons/empty-whatsapp.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook-2.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as LinkedinIcon } from "assets/icons/linkedin-2.svg";
import { ReactComponent as SkypeIcon } from "assets/icons/skype.svg";
import { ReactComponent as SlackIcon } from "assets/icons/slack.svg";
import { ReactComponent as TelegramIcon } from "assets/icons/telegram.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter-2.svg";
import { ReactComponent as ViberIcon } from "assets/icons/Viber.svg";
import { ReactComponent as Vk } from "assets/icons/vk-2.svg";
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp.svg";

export const socialMediaIcons: Record<string, JSX.Element> = {
  twitter: <TwitterIcon />,
  linkedin: <LinkedinIcon />,
  facebook: <FacebookIcon />,
  skype: <SkypeIcon />,
  instagram: <InstagramIcon />,
  vk: <Vk />
};

export const emptySocialMediaIcons: Record<string, JSX.Element> = {
  twitter: <EmptyTwitterIcon />,
  linkedin: <EmptyLinkedinIcon />,
  facebook: <EmptyFacebookIcon />,
  skype: <EmptySkypeIcon />,
  instagram: <EmptyInstagramIcon />,
  vk: <EmptyVk />
};

export const messengerIcons: Record<string, JSX.Element> = {
  skype: <SkypeIcon />,
  viber: <ViberIcon />,
  whatsapp: <WhatsappIcon />,
  telegram: <TelegramIcon />,
  slack: <SlackIcon />
};

export const emptyMessengerIcons: Record<string, JSX.Element> = {
  skype: <EmptySkypeIcon />,
  viber: <EmptyViberIcon />,
  whatsapp: <EmptyWhatsappIcon />,
  telegram: <EmptyTelegramIcon />,
  slack: <EmptySlackIcon />
};
