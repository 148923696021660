import { ISprint } from "models/store/joinProjects/joinProgects";

export interface ICreateSprintModalState {
  open: boolean;
  titleHead?: string;
  btnLabel?: string;
  callbak?: () => void | undefined;
  sprint?: ISprint;
}

export const initialCreateSprintModalState = (): ICreateSprintModalState => {
  return { open: false };
};
