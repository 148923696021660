import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export function useTasksSelectors() {
  const Tasks = useTypeSelector((s) => s.Tasks);
  const myTasks = Tasks.myTasks;
  const chosenTask = Tasks.chosenTask;
  const filters = Tasks.filters;

  return {
    myTasks,
    chosenTask,
    filters
  };
}
