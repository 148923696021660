import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import classNames from "classnames";
import { useProjectMsg } from "generalComponents/Services/projectServices";
import { useActions } from "hooks/useActions";
import { ITrashServicePanelTasksProps } from "models/project/projectTrash";
import React from "react";
import { useLocales } from "react-localized";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import styles from "./TrashServicePanel.module.sass";

const TrashServicePanelTasks: React.FC<ITrashServicePanelTasksProps> = ({ chosenTasks, toggleSelect, isSelect }) => {
  const { __ } = useLocales();
  const MSG = useProjectMsg();

  const { project, tasksTrash } = useProjectsSelectors();
  const { onRestoreTask, onSetApproveModal, onDeleteProjectTask, onMoveToBacklog, onClearTrash } = useActions();

  const onDelete = (): void => {
    if (tasksTrash.length === 0) {
      return;
    }
    const clearAll = chosenTasks.length === 0;
    const tasksId = chosenTasks.map((t) => t.id);
    const messages = {
      error: MSG.ERROR,
      success: MSG.DEL_TASKS
    };

    onSetApproveModal({
      open: true,
      params: {
        title: __("Удаление задач"),
        text: clearAll
          ? __("Вы действительно хотите удалить все задачи?")
          : __("Вы действительно хотите удалить выбранные задачи?"),
        approveBtn: __("Удалить"),
        callback: clearAll ? () => onClearTrash(MSG.ERROR) : () => onDeleteProjectTask(tasksId, messages)
      }
    });
    if (!clearAll) {
      toggleSelect();
    }
  };

  const onRestore = (): void => {
    onRestoreTask(
      chosenTasks.map((t) => t.id),
      MSG.ERROR
    );
    toggleSelect();
  };

  const moveToBacklog = (): void => {
    const messages = {
      error: MSG.ERROR,
      success: MSG.MOVE_TO_BACKLOG.text
    };
    onMoveToBacklog(chosenTasks, messages);
    toggleSelect();
  };

  return (
    <div className={styles.wrap}>
      {project.is_read === "1" && (
        <div className={classNames(styles.selectBtn, { [styles.active]: isSelect })} onClick={toggleSelect}>
          {__("Выбрать")}
        </div>
      )}

      <div className={styles.btns}>
        {project.is_read === "1" && (
          <>
            <div
              className={classNames(styles.selectBtn, { [styles.disabled]: chosenTasks.length === 0 })}
              onClick={moveToBacklog}
            >
              {__("Переместить в Becklog")}
            </div>
            <div
              className={classNames(styles.selectBtn, { [styles.disabled]: chosenTasks.length === 0 })}
              onClick={onRestore}
            >
              {__("Восстановить")}
            </div>
            <div
              className={classNames(styles.selectBtn, { [styles.disabled]: chosenTasks.length === 0 })}
              onClick={onDelete}
            >
              {__("Удалить")}
            </div>
            <div className={styles.selectBtn} onClick={onDelete}>
              {__("Очистить все")}
              <TrashIcon width={12} height={13} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TrashServicePanelTasks;
