import { SEARCH_PARAMS, SORTING_TYPE } from "./global";

export enum ROUTES {
  INFO = "info",
  SIGN_UP = "signup",
  SIGN_IN = "signin",
  FORGOT_PASSWORD = "forgot_password",
  QUESTIONNAIRE = "questionnaire",
  TEST = "test",
  FAQ = "faq",
  FAQDETAILS = "faqdetails",
  FAQCATEGORY = ":category/*",
  SEARCH = "search",
  NOTES = "notes",

  // cabinet side menu
  FOLDERS = "folders",
  FILES = "files",
  SAFE = "safe",
  TASKS = "tasks",
  _PROJECT = "_project",
  PROJECT = "project",

  CALENDAR = "calendar",
  CHAT = "chat",
  MAIL = "mail",
  PROGRAMS = "programs",
  ARCHIVE = "archive",
  LIBRARY = "library",
  JOURNAL = "activity-log",
  TRASH = "trash",
  SUPPORT = "support",
  PROFILE = "profile",
  // public
  FILE = "file/:fid",
  NEW_EMPLOYEE = "org_new_user",
  EMPLOYEE = "employee",
  SHARE_FOLDER = "share_folders"
}

export enum FOLDER_ROUTES {
  SHARED = "shared",
  FAVORITE = "favorite",
  DIR_ID = ":dirId"
}

export enum ARCHIVE_ROUTES {
  DIR_ID = ":archivedDirId"
}

export enum LIBRARY_ROUTES {
  CATEGORIES = "categories",
  RUBRICS = "rubrics",
  DIR_ID = "dir/:libraryDirId",
  SEARCH = "search",
  SETTINGS = "settings"
}

export enum TRASH_ROUTES {
  DIR_ID = ":trashDirId"
}
export enum SHARE_FOLDER_ROUTES {
  DIR_ID = ":dirId",
  DID = ":did"
}

export enum SAFE_ROUTES {
  SAFE_ID = ":safeId",
  SAFE_DIR = ":safeDir"
}
export enum MAIL_ROUTS {
  POSTBOX = ":postboxId",
  FOLDER = ":folderName"
}

export enum PROGRAMS_ROUTES {
  PROGRAM_ID = ":programId",
  FAVORITE = "favorite"
}

export enum PROFILE_ROUTES {
  ABOUT_ME = "about-me",
  EMPLOYEES = "employees",
  CONTACTS = "contacts",
  MY_SERVICES = "my-services",
  TARIFF = "tariff",
  INTEGRATIONS = "integrations",
  MEMORY = "memory",
  ACTIONS_LOG = "action-log",
  SESSEIONS = "sessions",
  SETTINGS = "settings",
  TAGS = "tags",
  FAQ = "faq"
}

export enum ACTIONS_LOG_ROUTES {
  GROUP = ":actionLogGroup",
  ID = ":actionLogId"
}

export enum SHARED_TYPES {
  TO_ME = "toMe",
  BY_ME = "byMe"
}

export enum SHARED_PARAMS {
  DIR = "dir",
  TYPE = "type"
}

export enum TASK_PARAMS {
  DEP = "dep",
  OVERDUE = "overdue"
}

export enum MAIL_PARAMS {
  SPLIT = "split",
  UNREAD = "unread",
  FAVORITE = "favorite"
}

export enum PROJECT_PATH {
  TASKS = "tasks",
  MY_TASKS = "myTask",
  BACKLOG = "backlog",
  HISTORY = "history",
  TEAM = "team",
  TRASH = "trash",
  ANALYTICS = "analytics",
  GANT = "gant",
  LINKS = "links",
  LIBRARY = "library",
  FILES = "files",
  DOC = "doc",
  SETTINGS = "settings",
  TASK = "task"
}

export enum PROJECT_FOLDER {
  DIR_ID = ":dirId"
}

export const ROUTES_SEARCH_PARAMS: Record<string, string> = {
  [SORTING_TYPE.DATE_CREATED]: `?${SEARCH_PARAMS.SORT}=${SORTING_TYPE.DATE_CREATED}&${SEARCH_PARAMS.GROUP}=1&${SEARCH_PARAMS.SORT_REVERS}=1`,
  [SORTING_TYPE.DATE_CHANGED]: `?${SEARCH_PARAMS.SORT}=${SORTING_TYPE.DATE_CHANGED}&${SEARCH_PARAMS.GROUP}=0&${SEARCH_PARAMS.SORT_REVERS}=1`
};
