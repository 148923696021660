import { EMOJIS, SIGNS } from "generalComponents/variables/elements";
import { PAGE_DEPTH } from "generalComponents/variables/global";
import { LibraryCategoryTypes } from "generalComponents/variables/library";
import { TypeElements } from "models/typeElements/typeElements";

import { IFile } from "../files/files";

export interface IFolder {
  is_dir: number;
  is_del: string;
  id_dir: string;
  id_parent: string;
  id_dep?: string;
  is_archive?: number;
  name: string | LibraryCategoryTypes;
  tags: string[];
  pass?: string;
  is_pass: number;
  is_lock: number;
  deadline: number;
  color: string;
  sort?: string;
  fig: SIGNS;
  emo: EMOJIS;
  id_fig?: SIGNS;
  id_emo?: EMOJIS;
  is_system: string;
  dir: string;
  cdate?: string;
  mdate?: string;
  atime?: string;
  size?: number;
  ctime?: string;
  mtime?: string;
  dtime?: string;
  link_share_to_user?: string;
  folders: IFolder[];
  col_folders?: number;
  col_files?: number;
  col_total?: number;
  is_favorite: number;
  my?: number;
  table?: "" | TypeElements;
  files_preview?: Record<string, string>;
  prim?: string;
  files?: IFile[];
  chapter?: string;
  dep?: PAGE_DEPTH;
  id_project?: string;
}

export interface IFoldersState {
  folders: IFolder[];
  currentFolder: IFolder;
  openFolder: string[];
  folderPath: IFolder[];
}

export function initialFolderState(): IFolder {
  return {
    is_dir: 1,
    is_del: "",
    id_dir: "",
    id_parent: "0",
    is_archive: 0,
    name: "",
    tags: [],
    pass: "",
    is_pass: 0,
    is_lock: 0,
    deadline: 0,
    color: "",
    sort: "",
    fig: SIGNS.NO_SIGN,
    emo: EMOJIS.NO_EMOJI,
    is_system: "",
    dir: "",
    cdate: "",
    mdate: "",
    size: 0,
    ctime: "",
    mtime: "",
    link_share_to_user: "",
    folders: [],
    col_folders: 0,
    col_files: 0,
    col_total: 0,
    is_favorite: 0
  };
}

export function initialFoldersState(): IFoldersState {
  return {
    folders: [],
    currentFolder: initialFolderState(),
    openFolder: [],
    folderPath: []
  };
}
