import classNames from "classnames";
import Empty from "containers/Dashboard/Empty/Empty";
import SafeItem from "containers/Dashboard/Safe/SafeItem/SafeItem";
import { ROUTES } from "generalComponents/variables/routing";
import { useLocales } from "react-localized";
import { Link } from "react-router-dom";
import { useSafeSelectors } from "Store/selectors/safeSelectors";

import styles from "./Safe.module.sass";

const Safe = ({ type }: { type: string }) => {
  const { __ } = useLocales();
  const { safeList } = useSafeSelectors();

  return (
    <div className={classNames(styles.container, styles[`container_${type}`])}>
      <Link className={styles.title} to={`/${ROUTES.SAFE}`}>
        {__("Сейф")}
      </Link>
      {safeList.length === 0 ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={classNames(styles.scrollWrapper, "scrollbar-thin-blue")}>
          <div className={styles.list}>
            {safeList.map((item, i) => (
              <SafeItem key={i} item={item} type={type} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Safe;
