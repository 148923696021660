import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Button from "generalComponents/Button/Button";
import { Option } from "generalComponents/TagSelect/TagSelect";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITagPickerProps } from "models/generalComponents/elements";
import React, { Fragment, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import Select, { components, MenuProps, OnChangeValue } from "react-select";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./TagPicker.module.sass";

export const TagPicker: React.FC<ITagPickerProps> = ({ tags, onSelectTags, disabled = false }): JSX.Element => {
  const { __ } = useLocales();
  const { tags: userTags } = useUserSelectors();
  const [options, setOptions] = useState<Option[]>([]);
  const [active, setActive] = useState<Option[]>([]);
  const { onSetTagEditModal } = useActions();

  useEffect(() => {
    if (!userTags) return;

    const newOptions = userTags.map((tag) => ({
      value: tag.id,
      label: tag.name
    }));
    setOptions(newOptions);

    const defaultValuesFromItem = tags?.map((tagId) => {
      const correspondingTag = userTags?.find((tag) => tag.id === tagId);
      return correspondingTag ? { value: correspondingTag.id, label: correspondingTag.name } : null;
    });

    setActive(defaultValuesFromItem?.filter((value) => value !== null));
  }, [userTags, tags]);

  const onTagChange = (newValue: OnChangeValue<Option, true>) => {
    setActive(newValue as Option[]);
    onSelectTags(newValue.map((item) => item.value));
  };

  const Menu = (props: MenuProps<Option, true>) => {
    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <Fragment>
        <components.Menu<Option, true, any> {...props}>
          {props.children}
          <Button
            className={styles.button}
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={__("Добавить")}
            onClick={() => onSetTagEditModal({ open: true })}
            iconL={<PlusIcon width={10} height={10} />}
          />
        </components.Menu>
      </Fragment>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Select
        isDisabled={disabled}
        value={active}
        closeMenuOnSelect={false}
        isMulti={true}
        options={options}
        maxMenuHeight={200}
        onChange={onTagChange}
        placeholder={"#" + __("Тег")}
        components={{ Menu }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            isDisabled: true,
            borderRadius: "8px",
            minHeight: "40px"
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            display: "none"
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            display: "none"
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 10,
            fontSize: "14px"
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            maxHeight: "68px",
            overflow: "auto"
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: "14px"
          })
        }}
      />
    </div>
  );
};
