import { IEvent } from "models/store/journal/journal";
import { FC } from "react";
import { v4 as uuid } from "uuid";

import { JournalEvent } from "./components/JournalEvent/JournalEvent";
import styles from "./JournalEventsItems.module.sass";

interface IJournalEventsItemsProps {
  events: IEvent[];
}

export const JournalEventsItems: FC<IJournalEventsItemsProps> = ({ events }): JSX.Element => (
  <div className={styles.list}>
    {events.map((event: IEvent) => (
      <JournalEvent key={uuid()} event={event} />
    ))}
  </div>
);
