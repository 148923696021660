import { useLocales } from "react-localized";

export interface IWorkDay {
  numDay: number;
  name: string;
}
export const useWorkDays = (): { workDays: IWorkDay[] } => {
  const { __ } = useLocales();
  const workDays = [
    { numDay: 1, name: __("ПН") },
    { numDay: 2, name: __("ВТ") },
    { numDay: 3, name: __("СР") },
    { numDay: 4, name: __("ЧТ") },
    { numDay: 5, name: __("ПТ") },
    { numDay: 6, name: __("СБ") },
    { numDay: 0, name: __("ВС") }
  ];
  return { workDays };
};

export interface IWorkHour {
  id: number;
  value: string;
}
export const workHours: IWorkHour[] = [
  { id: 0, value: "0:00" },
  { id: 1, value: "1:00" },
  { id: 2, value: "2:00" },
  { id: 3, value: "3:00" },
  { id: 4, value: "4:00" },
  { id: 5, value: "5:00" },
  { id: 6, value: "6:00" },
  { id: 7, value: "7:00" },
  { id: 8, value: "8:00" },
  { id: 9, value: "9:00" },
  { id: 10, value: "10:00" },
  { id: 11, value: "11:00" },
  { id: 12, value: "12:00" },
  { id: 13, value: "13:00" },
  { id: 14, value: "14:00" },
  { id: 15, value: "15:00" },
  { id: 16, value: "16:00" },
  { id: 17, value: "17:00" },
  { id: 18, value: "18:00" },
  { id: 19, value: "19:00" },
  { id: 20, value: "20:00" },
  { id: 21, value: "21:00" },
  { id: 22, value: "22:00" },
  { id: 23, value: "23:00" }
];
