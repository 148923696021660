import classNames from "classnames";
import { useTaskStatuses } from "collections/tasks";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { STATUS_TYPES, TASK_TYPES } from "generalComponents/variables/tasks";
import { useTaskSatus } from "hooks/tasksHooks";
import { useActions } from "hooks/useActions";
import { IStatusesButtonsProps } from "models/tasks/myTasks";
import React from "react";

import styles from "./StatusesButtons.module.sass";

const StatusesButtons: React.FC<IStatusesButtonsProps> = ({ task }): JSX.Element => {
  const { myTasks, otherTasks } = useTaskStatuses();
  const status = useTaskSatus(task.id_type);
  const statuses = task.id_type === TASK_TYPES.TASK ? myTasks : otherTasks;

  const { onEditTask } = useActions();

  const contextStatuses = statuses.map((el) => ({ ...el, isCurrent: el.status === status(task.id_status)?.status }));

  const onChangeStatus = (status: STATUS_TYPES): void => {
    const params = {
      id_task: task.id,
      id_status: status
    };
    onEditTask(params, EVENT_TYPE.EDIT_STATUS);
  };

  return (
    <>
      {contextStatuses.map((el, i) => (
        <button
          key={i}
          className={classNames(styles.statusBtn, { [styles.activeStatus]: el.isCurrent })}
          onClick={() => onChangeStatus(el.status)}
        >
          {el.img}
        </button>
      ))}
    </>
  );
};

export default StatusesButtons;
