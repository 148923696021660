import { ReactComponent as EditIcon } from "assets/PrivateCabinet/edit.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import { ReactComponent as PrintIcon } from "assets/PrivateCabinet/print.svg";
import classNames from "classnames";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import CustomEditorToolbar from "generalComponents/CustomEditorToolbar/CustomEditorToolbar";
import { useProjectMsg } from "generalComponents/Services/projectServices";
import { useActions } from "hooks/useActions";
import { IServicePanelProps } from "models/project/docsServicePanel";
import { PROJECT_DOCS_VIEW } from "models/project/projectDocs";
import { IDelDocs, ITopMessages } from "models/store/projects/projectThunk";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./ServicePanel.module.sass";

const ServicePanel: React.FC<IServicePanelProps> = ({
  toolbarClick,
  pushoutButton,
  viewType,
  currentDocs,
  clearCurrentDocs,
  handleSelect,
  isSelect,
  handleEdit,
  isEdit,
  currentEditor,
  onPrint
}): JSX.Element => {
  const { __ } = useLocales();
  const MSG = useProjectMsg();
  const { onDelDocs, onSetApproveModal } = useActions();

  const onDeleteDoc = (): void => {
    const payload: IDelDocs[] = currentDocs.map((currentDoc) => ({ id_doc: currentDoc.id, name: currentDoc.name }));

    const messages: ITopMessages = {
      error: MSG.ERROR,
      success: MSG.DEL_DOC
    };

    const cb = (): void => clearCurrentDocs();

    onSetApproveModal({
      open: true,
      params: {
        title: __("Удаление документа"),
        text: `${__("Вы действительно хотите удалить выбранные документы?")} `,
        approveBtn: __("Удалить"),
        callback: () => onDelDocs(payload, messages, cb)
      }
    });
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.mainPanel}>
        {(viewType === PROJECT_DOCS_VIEW.CREATE_DOC ||
          (viewType === PROJECT_DOCS_VIEW.DOCS && isEdit) ||
          (viewType === PROJECT_DOCS_VIEW.TEMPLATE && !isEdit)) && (
          <div className={classNames(styles.toolbar, { [styles.left]: viewType === PROJECT_DOCS_VIEW.CREATE_DOC })}>
            <CustomEditorToolbar
              onClick={toolbarClick}
              pushoutButton={pushoutButton}
              viewType={viewType}
              currentEditor={currentEditor}
            />
          </div>
        )}
      </div>
      {(viewType === PROJECT_DOCS_VIEW.DOCS || viewType === PROJECT_DOCS_VIEW.TEMPLATE) && (
        <div className={classNames(styles.selectBtn, { [styles.active]: isSelect })} onClick={handleSelect}>
          {__("Выбрать")}
        </div>
      )}

      <div className={styles.btnsPanel}>
        <ButtonIcon handleClick={onPrint} icon={<PrintIcon />} variant="white" disabled={currentDocs.length !== 1} />
        <ButtonIcon
          handleClick={handleEdit}
          icon={<EditIcon />}
          variant="white"
          disabled={currentDocs.length === 0}
          isActive={isEdit}
        />
        <ButtonIcon handleClick={onDeleteDoc} variant="white" notHover disabled={currentDocs.length === 0}>
          <TrashIcon className={styles.trashIcon} />
        </ButtonIcon>
      </div>
    </div>
  );
};

export default ServicePanel;
