import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";

export const getStartEndIndexsSelectedElements = (
  files: (IFile | IFolder)[],
  pickedItems: (IFile | IFolder)[]
): number[] => {
  const isFolderFirstElement: boolean = pickedItems[0].is_dir === 1;
  const isFolderLastElement: boolean = pickedItems[pickedItems.length - 1].is_dir === 1;

  const indexFirst = files.findIndex((el) =>
    isFolderFirstElement
      ? (el as IFolder)?.id_dir === (pickedItems[0] as IFolder)?.id_dir
      : (el as IFile)?.fid === (pickedItems[0] as IFile)?.fid
  );
  const indexSecond = files.findIndex((el) =>
    isFolderLastElement
      ? (el as IFolder).id_dir === (pickedItems[pickedItems.length - 1] as IFolder).id_dir
      : (el as IFile).fid === (pickedItems[pickedItems.length - 1] as IFile).fid
  );

  return [indexFirst, indexSecond];
};
