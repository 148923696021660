export const calculateSize = (value: number): string => {
  if (value < 0) return "";

  if (value / 1000000000 > 1) {
    return `${(value / 1000000000).toFixed()} GB`;
  }

  if (value / 1000000 > 1) {
    return `${(value / 1000000).toFixed(2)} MB`;
  }

  if (value / 1000 > 1) {
    return `${(value / 1000).toFixed(2)} KB`;
  }

  return `${value} B`;
};

export const splitFilename = (val: string): { name: string; format: string } => {
  const i = val.lastIndexOf(".");

  return {
    name: val.substring(0, i),
    format: val.substring(i + 1)
  };
};
