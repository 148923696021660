import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Button from "generalComponents/Button/Button";
import SearchField from "generalComponents/SearchField/SearchField";
import Tabs from "generalComponents/Tabs/Tabs";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { Dispatch, FC, SetStateAction } from "react";
import { useLocales } from "react-localized";

import styles from "./TagsServicePanel.module.sass";

export enum ITagTab {
  All = "all",
  My = "my",
  Аnother = "another"
}

interface IProps {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  active: ITagTab;
  setActive: Dispatch<SetStateAction<ITagTab>>;
}

const TagsServicePanel: FC<IProps> = ({ active, setActive, search, setSearch }) => {
  const { __ } = useLocales();
  const { onSetTagEditModal } = useActions();

  const tabs = [
    { name: __("Все"), value: ITagTab.All, onClick: () => setActive(ITagTab.All) },
    { name: __("Мои"), value: ITagTab.My, onClick: () => setActive(ITagTab.My) },
    { name: __("Чужие"), value: ITagTab.Аnother, onClick: () => setActive(ITagTab.Аnother) }
  ];

  return (
    <ToolsPanel size="large">
      <div className={styles.wrap}>
        <Tabs tabs={tabs} value={active} size="large" />
        <div className={styles.search}>
          <SearchField variant="secondary" value={search} setValue={setSearch} placeholder={__("Введите")} />
          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={__("Добавить")}
            onClick={() => onSetTagEditModal({ open: true })}
            iconL={<PlusIcon width={10} height={10} />}
          />
        </div>
      </div>
    </ToolsPanel>
  );
};

export default TagsServicePanel;
