import { getStorageItem } from "generalComponents/Services/storageServices";
import { PROJECT_PATH } from "generalComponents/variables/project";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { ProjectTypes } from "Store/types/projectTypes";

import { useProjectMsg } from "./helpers";
import styles from "./Project.module.sass";
import ProjectSideMenu from "./ProjectSideMenu/ProjectSideMenu.tsx";

const Project = () => {
  const navigate = useNavigate();
  const { projects } = useProjectsSelectors();
  // const isLink = useMatch("/project/:id_project/task/:id_task");
  const dispatch = useDispatch();
  const MSG = useProjectMsg();
  const { onFetchProjectsList } = useActions();
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { rights } = useUserSelectors();

  useEffect(() => {
    if (!rights.IS_PROJECT) {
      navigate("/", { replace: true });
      return;
    }

    const isLoader = projects.length === 0;
    onFetchProjectsList(MSG.ERROR_PROJECT, isLoader);
  }, []); //eslint-disable-line

  const findProject = (id) => projects.find((item) => item.id === id);

  useEffect(() => {
    if (projects.length === 0) return;
    const projectId = getStorageItem("projectId");
    // TODO -mk- fixed task to link
    // if (isLink) {
    //   if (findProject(isLink.params.id_project)) {
    //     setStorageItem("projectId", isLink.params.id_project);
    //     dispatch({ type: ProjectTypes.CURRENT_PROJECT, payload: findProject(isLink.params.id_project) });
    //     navigate({
    //       pathname: `/project/${isLink.params.id_project}/${PROJECT_PATH.TASKS}`,
    //       state: { openModal: true, idTask: isLink.params.id_task }
    //     });
    //   } else {
    //     navigate({
    //       pathname: `/project/${isLink.params.id_project}/${PROJECT_PATH.TASK}/${isLink.params.id_task}`
    //     });
    //   }
    // } else {
    if (projectId && findProject(projectId)) {
      const project = findProject(projectId);
      dispatch({ type: ProjectTypes.CURRENT_PROJECT, payload: project });
      navigate(`${projectId}/${PROJECT_PATH.TASKS}`, { replace: true });
    } else {
      navigate(ROUTES.PROJECT);
    }
    // }
  }, [projects]); //eslint-disable-line

  return (
    <>
      <div className={styles.projectWrap}>
        <ProjectSideMenu menuCollapsed={menuCollapsed} setMenuCollapsed={setMenuCollapsed} />
        {/* <Route
          path=":id_project/task/:id_task"
          exact
          element={<ProjectTaskModal idProject={isLink.params?.id_project} idTask={isLink.params?.id_task} />}
        /> */}
        <Outlet />
      </div>
    </>
  );
};

export default Project;

Project.propTypes = {
  setLoadingType: PropTypes.func
};
