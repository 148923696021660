import api from "api";
import { useMailSettingTabs } from "collections/mail/mail";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import Tabs from "generalComponents/Tabs/Tabs";
import { MailSettingsTabs } from "generalComponents/variables/mail";
import React, { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import HeaderModal from "../../HeaderModal/HeaderModal";
import Accounts from "./Accounts/Accounts";
import BlockedUsers from "./BlockedUsers/BlockedUsers";
import styles from "./PostboxSettings.module.sass";
import Sessions from "./Sessions/Sessions";

interface IPostboxSettingsProps {
  closeModal: () => void;
}

export interface IAccount {
  agent: string;
  date: string;
  email: string;
  id: string;
  id_user: string;
  info: { browser: string; device_type: string; platform: string };
  ip: string;
  is_block: number;
  is_my: number;
  ip_info: { adr: string };
  geo_info: { city: string; country: string };
}
export type Agents = Record<string, IAccount[]>;

const PostboxSettings: React.FC<IPostboxSettingsProps> = ({ closeModal }) => {
  const { __ } = useLocales();

  const { postboxes } = usePostboxSelectors();
  const { uid } = useUserSelectors();
  const { tabItem, tabs } = useMailSettingTabs();
  const [agents, setAgents] = useState<Agents>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const controller = new AbortController();
    getSessions(controller);
    return () => controller.abort();
  }, []); //eslint-disable-line

  const getSessions = async (controller: AbortController): Promise<void> => {
    try {
      setLoading(true);
      await Promise.all(
        postboxes.map(async (item) => {
          const params = {
            uid,
            email: item.email
          };

          const { data } = await api.get("/ajax/email_agents_list.php", { params, signal: controller.signal });
          checkResponseStatus(data.ok);
          setAgents((prev: any) => ({ ...prev, [item.email]: data.emails_agents }));
        })
      );
    } catch {
      setError(__("Не удалось получить список почтовых ящиков. Попробуйте позже"));
    } finally {
      setLoading(false);
    }
  };

  const onRemoveItem = (email: string, id: string, val: number): void => {
    setAgents((prev) => {
      const arr = [...prev[email]];
      const idx = arr.findIndex((el) => el.id === id);
      const agent = { ...arr.find((el) => el.id === id), is_block: val };
      arr.splice(idx, 1, agent);
      return { ...prev, [email]: arr };
    });
  };

  return (
    <div className={styles.root}>
      <HeaderModal title={__("Настройки")} onClose={closeModal} />
      <div className={styles.mainContent}>
        <div className={styles.tabsBox}>
          <Tabs tabs={tabs} variant="secondary" size="large" value={tabItem} />
        </div>
        {tabItem === MailSettingsTabs.ACCOUNTS && <Accounts agents={agents} />}
        {tabItem === MailSettingsTabs.SESSIONS && (
          <Sessions agents={agents} loading={loading} onRemoveItem={onRemoveItem} blocked={false} error={error} />
        )}
        {tabItem === MailSettingsTabs.BLOCKED_DEVICE && (
          <Sessions agents={agents} loading={loading} onRemoveItem={onRemoveItem} blocked={true} error={error} />
        )}
        {tabItem === MailSettingsTabs.BLOCKED_USER && <BlockedUsers />}
      </div>
    </div>
  );
};

export default PostboxSettings;
