import { IUserInfo } from "models/store/user/user";
import { FC } from "react";
import { useLocales } from "react-localized";

import EmployeeItem from "../EmployeeItem/EmployeeItem";
import styles from "./EmployeesTable.module.sass";

interface Props {
  employees: IUserInfo[];
}

const EmployeesTable: FC<Props> = ({ employees }) => {
  const { __ } = useLocales();
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.name}>{__("ФИО")}</div>
        <div className={styles.email}>Email</div>
        <div className={styles.tel}>{__("Телефон")}</div>
      </div>
      <ul>
        {employees.map((employee) => (
          <EmployeeItem key={employee.id} employee={employee} />
        ))}
      </ul>
    </div>
  );
};

export default EmployeesTable;
