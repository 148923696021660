import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import api from "api";
import { ReactComponent as CloseIcon } from "assets/icons/cross.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import classNames from "classnames";
import { IWidget, useDashboardSettings, WidgetCategory } from "collections/dashboard";
import Calendar from "containers/Dashboard/Calendar/Calendar";
import Employees from "containers/Dashboard/Employees/Employees";
import Favorites from "containers/Dashboard/Favorites/Favorites";
import JointProjectStatistic from "containers/Dashboard/JointProjectStatistic/JointProjectStatistic";
import JointProjectStatusStatistic from "containers/Dashboard/JointProjectStatusStatistic/JointProjectStatusStatistic";
import JointProjectTeams from "containers/Dashboard/JointProjectTeams/JointProjectTeams";
import LastFiles from "containers/Dashboard/LastFiles/LastFiles";
import Mail from "containers/Dashboard/Mail/Mail";
import Safe from "containers/Dashboard/Safe/Safe";
import Share from "containers/Dashboard/Share/Share";
import Tasks from "containers/Dashboard/Tasks/Tasks";
import Trash from "containers/Dashboard/Trash/Trash";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import React, { useEffect, useState } from "react";
import RGL, { Responsive as ResponsiveGridLayout, WidthProvider } from "react-grid-layout";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onUserInfo } from "Store/actions/userAction";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { generateUniqueId } from "utils/generateUniqueId";

import styles from "./DashboardPage.module.sass";

const ReactGridLayout = WidthProvider(ResponsiveGridLayout);
const GRID_BREAKPOINT = 919;
export interface ILayuotWidget extends IWidget, RGL.Layout {}

const elements: {
  [key in WidgetCategory]: (
    type?: string,
    teamId?: string,
    projectId?: string,
    setBoard?: React.Dispatch<React.SetStateAction<ILayuotWidget[]>>,
    i?: string,
    handleConfirm?: () => void
  ) => React.ReactElement;
} = {
  [WidgetCategory.Calendar]: (type) => <Calendar type={type} />,
  [WidgetCategory.Mail]: () => <Mail />,
  [WidgetCategory.Favorites]: (type) => <Favorites type={type} />,
  [WidgetCategory.Employees]: (type) => <Employees type={type} />,
  [WidgetCategory.LastFiles]: (type) => <LastFiles type={type} />,
  [WidgetCategory.Share]: (type) => <Share type={type} />,
  [WidgetCategory.Basket]: () => <Trash />,
  [WidgetCategory.Safe]: (type) => <Safe type={type} />,
  [WidgetCategory.Tasks]: (type) => <Tasks type={type} />,
  [WidgetCategory.JointProjectStatistic]: () => <JointProjectStatistic />,
  [WidgetCategory.JointProjectTeam]: (type, projectId, teamId, setBoard, i, handleConfirm) => (
    <JointProjectTeams
      type={type}
      projectId={projectId}
      teamId={teamId}
      setBoard={setBoard}
      i={i}
      handleConfirm={handleConfirm}
    />
  ),
  [WidgetCategory.JointProjectStatusStatistic]: (type, projectId, teamId, setBoard, i, handleConfirm) => (
    <JointProjectStatusStatistic
      type={type}
      projectId={projectId}
      teamId={teamId}
      setBoard={setBoard}
      i={i}
      handleConfirm={handleConfirm}
    />
  )
};

const DashboardPage: React.FC = (): JSX.Element => {
  const { __ } = useLocales();
  const { userInfo } = useUserSelectors();
  const [mainBoard, setMainBoard] = useState<ILayuotWidget[]>([]);
  const [tabletMainBoard, setTabletMainBoard] = useState<ILayuotWidget[]>([]);
  const [widgetInteraction, setWidgetInteraction] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState("md");
  const [showSidebar, setShowSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // виджет который в данный момент перетаскивается
  const [draggedWidget, setDraggedWidget] = useState<IWidget | null>(null);
  // активный виджет, добавляется если нажанть на +
  const [activeWidget, setActiveWidget] = useState<IWidget | null>(null);

  const dispatch = useDispatch();
  const dashboardSettings = useDashboardSettings();

  useEffect(() => {
    if (!userInfo) return;

    const parsedMainBoard = JSON.parse(userInfo.dashboardSettings);
    setMainBoard(Array.isArray(parsedMainBoard) ? parsedMainBoard : []);

    const parsedTabletMainBoard = JSON.parse(userInfo.tabletDashboardSettings);
    setTabletMainBoard(Array.isArray(parsedTabletMainBoard) ? parsedTabletMainBoard : []);
  }, [userInfo]);

  // перетаскивании виджета из левой колнки
  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, item: IWidget) => {
    // для Faerfox
    e.dataTransfer.setData("text/plain", "");
    // сбрасываем активный виджет для кнопки +
    setActiveWidget(null);
    // добавляем активный виджет для перетаскивания
    setDraggedWidget(item);
  };

  // сброс виджета на доску
  const dropHandler = (
    layout: RGL.Layout[],
    layoutItem: RGL.Layout,
    setBoard: React.Dispatch<React.SetStateAction<ILayuotWidget[]>>
  ) => {
    // находим координаты всех елементов на доске и перезаписываем их с учетом нового виджета, добавляем новый виджет
    setBoard((prev) => [
      ...prev.map((item) => {
        const layoutItem = layout.find((lItem: RGL.Layout) => lItem.i === item.i);
        if (layoutItem) {
          return { ...item, x: layoutItem.x, y: layoutItem.y };
        }
        return item;
      }),
      { ...draggedWidget, x: layoutItem.x, y: layoutItem.y, i: generateUniqueId(), isResizable: false }
    ]);
    // сбрасываем активный виджет для перетаскивания
    setDraggedWidget(null);
    setWidgetInteraction(false);
  };

  // Функция для обработки изменений раскладки
  const onLayoutChange = (layout: RGL.Layout[], setBoard: React.Dispatch<React.SetStateAction<ILayuotWidget[]>>) => {
    if (!widgetInteraction && !activeWidget) return;
    setBoard((prev) => {
      return prev.map((item) => {
        const layoutItem = layout.find((lItem: RGL.Layout) => lItem.i === item.i);
        if (layoutItem) {
          return { ...item, x: layoutItem.x, y: layoutItem.y };
        }
        return item;
      });
    });
  };

  // удаляем елемент с доски
  const removeItem = (item: ILayuotWidget, setBoard: React.Dispatch<React.SetStateAction<ILayuotWidget[]>>) => {
    // удаляем елемент
    setBoard((prev) => prev.filter((boardItem) => boardItem.i !== item.i));
  };

  // добавляем активный виджет в состояние при нажатии +
  const handleActiveWidget = (item: IWidget) => {
    setActiveWidget(item);
  };

  // добавляем активный виджет на доску при клике на блок с подсветкой
  const addItem = (setBoard: React.Dispatch<React.SetStateAction<ILayuotWidget[]>>) => {
    // добавляем активный виджет в состояние
    setBoard((prev: ILayuotWidget[]) => {
      return [...prev, { ...activeWidget, x: 0, y: 0, i: generateUniqueId(), isResizable: false }];
    });
    // сбрасываем активный виджет для кнопки +
    setActiveWidget(null);
  };

  const toggleSidebar = async () => {
    setShowSidebar((prev) => !prev);
  };

  const handleConfirm = async () => {
    const serializedMainBoard = JSON.stringify(mainBoard);
    const serializedTabletMainBoard = JSON.stringify(tabletMainBoard);
    const formData = new FormData();

    formData.append("uid", userInfo.uid);
    formData.append("dashboardSettings", serializedMainBoard);
    formData.append("tabletDashboardSettings", serializedTabletMainBoard);

    try {
      setIsLoading(true);
      const res = await api.post(`/ajax/user_edit2.php`, formData);
      checkResponseStatus(res.data.ok);
      dispatch(onUserInfo(res.data.data));
      setShowSidebar(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderGridItems = (board: ILayuotWidget[], setBoard: React.Dispatch<React.SetStateAction<ILayuotWidget[]>>) => {
    return board.map((item) => (
      <div
        key={item.i}
        className={classNames(
          styles.item,
          widgetInteraction && styles.widgetInteraction,
          draggedWidget && styles.dragFromOutside
        )}
        data-grid={item}
      >
        {showSidebar && (
          <Button
            isSquare
            className={styles.removeButton}
            size={ButtonSizeType.SMALL}
            variant={ButtonVariantType.DARK_GREEN}
            iconL={<CloseIcon />}
            onClick={() => removeItem(item, setBoard)}
            onMouseDown={(e) => e.stopPropagation()}
          />
        )}
        {elements[item.category](item.type, item.projectId, item.teamId, setBoard, item.i, handleConfirm)}
      </div>
    ));
  };

  return (
    <>
      <div className={classNames(styles.leftSidebar, !showSidebar && styles.hiddenSidebar)}>
        <div className={styles.sidebarTitle}>
          {__("Настройка виджетов")}
          <Button
            iconL={<CloseIcon />}
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.SMALL}
            onClick={toggleSidebar}
            className={styles.closeButton}
            isSquare
          />
        </div>
        <div className={styles.contentWrapper}>
          {dashboardSettings.map((category) => (
            <div key={category.name}>
              <div className={styles.categoryTitle}>
                {category.icon}
                {category.name}
              </div>
              <div className={styles.categoryGrid}>
                {category.content.map((item: IWidget, i: number) => (
                  <div
                    className={styles.leftItem}
                    style={{
                      gridColumn: `span ${item.placeholder.w}`,
                      gridRow: `span ${item.placeholder.h}`,
                      backgroundImage: `url(${item.placeholder.img})`
                    }}
                    key={i}
                    draggable={true}
                    onDragStart={(e) => dragStartHandler(e, item)}
                  >
                    <div className={styles.shadow}>
                      <Button
                        variant={ButtonVariantType.BLUE}
                        size={ButtonSizeType.MEDIUM}
                        isSquare
                        onClick={() => handleActiveWidget(item)}
                        text="+"
                      />
                    </div>
                    <div className={styles.title}>{item.name}</div>
                  </div>
                ))}
              </div>
              <div className={styles.line} />
            </div>
          ))}
        </div>
      </div>
      <div className={classNames(styles.mainWrapper, showSidebar && styles.openSidebar)}>
        {isLoading && (
          <Loader type={LoaderTypes.BOUNCING_DOTS} background="rgba(0, 0, 0, 0.65)" containerType="bounceDots" />
        )}
        {showSidebar && (
          <div className={styles.alert}>
            {currentBreakpoint === "md"
              ? __("Настройте расположение виджетов для десктопной версии.")
              : __("Настройте расположение виджетов для планшетной версии.")}
          </div>
        )}
        <ReactGridLayout
          isDraggable={showSidebar}
          className={classNames(styles.content, showSidebar && styles.contentOpen)}
          breakpoints={{ md: GRID_BREAKPOINT, sm: 0 }}
          cols={{ md: 8, sm: 6 }}
          rowHeight={102}
          onDragStart={() => setWidgetInteraction(true)}
          onDragStop={() => setWidgetInteraction(false)}
          onLayoutChange={(layout) =>
            onLayoutChange(layout, currentBreakpoint === "sm" ? setTabletMainBoard : setMainBoard)
          }
          onDrop={(layout, LayoutItem) =>
            dropHandler(layout, LayoutItem, currentBreakpoint === "sm" ? setTabletMainBoard : setMainBoard)
          }
          isDroppable={true}
          droppingItem={draggedWidget && { i: "__dropping-elem__", h: draggedWidget?.h, w: draggedWidget?.w }}
          onBreakpointChange={(newBreakpoint) => setCurrentBreakpoint(newBreakpoint)}
        >
          {/* активный виджет для кнопки +, key не удалять, без него не работает */}
          {activeWidget && (
            <div
              key={"add"}
              data-grid={{ i: "add", x: 0, y: 0, w: activeWidget.w, h: activeWidget.h, static: true }}
              onClick={() => addItem(currentBreakpoint === "sm" ? setTabletMainBoard : setMainBoard)}
              className={classNames(styles.item, styles.blur)}
            />
          )}
          {currentBreakpoint === "sm"
            ? renderGridItems(tabletMainBoard, setTabletMainBoard)
            : renderGridItems(mainBoard, setMainBoard)}
        </ReactGridLayout>
        {showSidebar ? (
          <div className={styles.settingsButtonWrapper}>
            <Button
              onClick={handleConfirm}
              className={styles.addButton}
              variant={ButtonVariantType.DARK_GREEN}
              size={ButtonSizeType.MEDIUM}
              text={"Применить"}
            />
          </div>
        ) : (
          <Button
            onClick={toggleSidebar}
            className={styles.addButton}
            variant={ButtonVariantType.DARK_GREEN}
            size={ButtonSizeType.MEDIUM}
            text={"Настройка виджетов"}
            iconL={<SettingsIcon />}
          />
        )}
      </div>
    </>
  );
};

export default DashboardPage;
