import api from "api";
import pallete from "assets/icons/palette.jpg";
import { ReactComponent as BrushIcon } from "assets/PrivateCabinet/minitoolbar/brush.svg";
import { ReactComponent as EraserIcon } from "assets/PrivateCabinet/minitoolbar/eraser.svg";
import { ReactComponent as MarkerIcon } from "assets/PrivateCabinet/minitoolbar/marker.svg";
import { ReactComponent as PencilIcon } from "assets/PrivateCabinet/minitoolbar/pencil.svg";
import { ReactComponent as TriangleIcon } from "assets/PrivateCabinet/minitoolbar/rectangle1.svg";
import { ReactComponent as CircleIcon } from "assets/PrivateCabinet/minitoolbar/rectangle2.svg";
import { ReactComponent as Square3Icon } from "assets/PrivateCabinet/minitoolbar/rectangle3.svg";
import classNames from "classnames";
import { format } from "date-fns";
import Button from "generalComponents/Button/Button";
import { dotsPaint } from "generalComponents/collections/programs";
import { splitFilename } from "generalComponents/Services/helpers";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { IPaintToolbar, SAVE_TYPE } from "models/workElements/minitoolbar";
import { PaintTools } from "models/workElements/previews";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { usePaintSelectors } from "Store/selectors/paintSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { dataURLToFile } from "utils/filesConvert";
import { v4 as uuid } from "uuid";

import styles from "./PaintToolbar.module.sass";
import Brush from "./Tools/Brush";
import Circle from "./Tools/Circle";
import ColorPicker from "./Tools/ColorPicker";
import Eraser from "./Tools/Eraser";
import Marker from "./Tools/Marker";
import Pencil from "./Tools/Pencil";
import Square from "./Tools/Square";
import Triangle from "./Tools/Triangle";

const PaintToolbar: React.FC<IPaintToolbar> = ({
  file,
  canvasRef,
  onCancel,
  naturalHeight,
  naturalWidth,
  setIsLoad,
  saveType
}) => {
  const { __ } = useLocales();
  const { onSetPaint, onSelectedFiles, onNextAction, onLoadingFiles, onFileView } = useActions();
  const { paint, size, tool, color } = usePaintSelectors();
  const { uid } = useUserSelectors();
  const [isOpenSize, setIsOpenSize] = useState<boolean>(false);

  const colorPickerRef = useRef(null);

  const addTool = (toolName: any) => {
    onSetPaint(
      "tool",
      new toolName(canvasRef?.current, {
        color: paint.color,
        pushInDrawHistory: () => {}
      })
    );
  };

  const chooseColor = () => {
    colorPickerRef.current.click();
  };

  const setOriginalCanvas = (): string => {
    const originalCanvas = document.createElement("canvas");
    originalCanvas.height = naturalHeight;
    originalCanvas.width = naturalWidth;
    const resizedContext = originalCanvas.getContext("2d");
    resizedContext.drawImage(canvasRef.current, 0, 0, naturalWidth, naturalHeight);
    const original = originalCanvas.toDataURL("image/png");
    return original;
  };

  const onReplaceImg = async (): Promise<void> => {
    try {
      onNextAction({
        open: false,
        params: null
      });
      setIsLoad(true);
      const newFile = setOriginalCanvas();
      const base = newFile.replace("image/png", "image/octet-stream");
      const blob = new Blob([base], { type: "image/png" });

      const payload = new FormData();
      payload.append("uid", uid);
      payload.append("myfile", blob);
      payload.append("fid", file.fid);
      payload.append("id_dir", file.id_dir);
      const { data } = await api.post(`/ajax/file_replace.php`, payload);
      checkResponseStatus(data.ok);
      setIsLoad(false);
      onCancel();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoad(false);
    }
  };

  const onCopyImage = (): void => {
    onNextAction({
      open: false,
      params: null
    });
    onFileView({ open: false, params: null });
    const newFile = setOriginalCanvas();
    const base = newFile.replace("image/png", "image/octet-stream");
    const File = dataURLToFile(base);
    onLoadingFiles({
      ...{ file: File },
      options: {
        name: `${splitFilename(file.name).name}_copy`,
        tags: file.tags,
        emo: file.emo,
        color: file.color,
        id_dir: file.id_dir,
        ext: file.ext
      },
      time: 0,
      percent: 0,
      id: uuid(),
      done: false,
      create: false,
      replace: false
    });
  };

  const onDownload = (): void => {
    const downloadLink = document.createElement("a");
    downloadLink.href = setOriginalCanvas();
    downloadLink.download = `${file?.name ?? format(new Date(), "yyyy-MM-dd_hh:mm:ss")}`;
    downloadLink.focus();
    downloadLink.click();
  };

  const handleSaveImage = (): void => {
    if (saveType === SAVE_TYPE.CREATE) {
      const base = canvasRef.current.toDataURL("image/png");
      const file = dataURLToFile(base);
      onSelectedFiles([{ file: file }]);
      onCancel();
      return;
    }
    onNextAction({
      open: true,
      params: {
        title: `${__("Сохранить")}`,
        text: `${__("Выберете вариант сохранения редактируемого изображения!")}`,
        buttons: [
          <Button
            key="1"
            onClick={() =>
              onNextAction({
                open: false,
                params: null
              })
            }
            text={__("Отмена")}
            variant={ButtonVariantType.CANCEL}
            type="button"
            className={styles.actionBtn}
          />,
          <Button
            key="2"
            onClick={onReplaceImg}
            text={__("Сохранить")}
            variant={ButtonVariantType.OK}
            type="button"
            className={styles.actionBtn}
          />,
          <Button
            key="3"
            onClick={onCopyImage}
            text={__("Сохранить как копию")}
            variant={ButtonVariantType.OK}
            type="button"
            className={styles.actionBtn}
          />
        ]
      }
    });
  };

  useLayoutEffect(() => {
    addTool(Pencil);
  }, []); //eslint-disable-line

  return (
    <div className={styles.wrap}>
      <div className={styles.tools}>
        <button
          className={classNames(styles.toolBtn, { [styles.active]: tool.name === PaintTools.PENCIL })}
          onClick={() => addTool(Pencil)}
        >
          <PencilIcon width={26} height={26} />
        </button>
        <button
          className={classNames(styles.toolBtn, { [styles.active]: tool.name === PaintTools.MARKER })}
          onClick={() => addTool(Marker)}
        >
          <MarkerIcon width={26} height={26} />
        </button>
        <button
          className={classNames(styles.toolBtn, { [styles.active]: tool.name === PaintTools.BRUSH })}
          onClick={() => addTool(Brush)}
        >
          <BrushIcon width={26} height={26} />
        </button>
        <button
          className={classNames(styles.toolBtn, { [styles.active]: tool.name === PaintTools.ERASER })}
          onClick={() => addTool(Eraser)}
        >
          <EraserIcon width={26} height={26} />
        </button>
        <button
          className={classNames(styles.toolBtn, { [styles.active]: tool.name === PaintTools.SQUARE })}
          onClick={() => addTool(Square)}
        >
          <Square3Icon />
        </button>
        <button
          className={classNames(styles.toolBtn, { [styles.active]: tool.name === PaintTools.CIRCLE })}
          onClick={() => addTool(Circle)}
        >
          <CircleIcon />
        </button>
        <button
          className={classNames(styles.toolBtn, { [styles.active]: tool.name === PaintTools.TRIANGLE })}
          onClick={() => addTool(Triangle)}
        >
          <TriangleIcon />
        </button>
        <div
          className={styles.toolBtn}
          onMouseEnter={() => setIsOpenSize(true)}
          onMouseLeave={() => setIsOpenSize(false)}
        >
          <span
            style={{
              background: color,
              borderRadius: "50%",
              width: size,
              height: size
            }}
          />
          {isOpenSize && (
            <div className={styles.sizes}>
              {dotsPaint.map((item, index) => (
                <button
                  key={index}
                  className={styles.sizeBtn}
                  onClick={() => {
                    tool.lineWidth = item.width;
                    onSetPaint("size", item.width);
                    setIsOpenSize(false);
                  }}
                >
                  <span
                    style={{
                      width: item.width,
                      height: item.width,
                      background: color
                    }}
                  />
                </button>
              ))}
            </div>
          )}
        </div>
        <div className={styles.toolBtn} onClick={chooseColor}>
          <img src={pallete} alt="pallete" />
          <ColorPicker colorPickerRef={colorPickerRef} />
        </div>
      </div>

      <div className={styles.manageButtons}>
        <Button
          onClick={onCancel}
          text={__("Отмена")}
          variant={ButtonVariantType.CANCEL}
          type="button"
          className={styles.btn}
        />
        <Button
          onClick={onDownload}
          text={__("Скачать")}
          variant={ButtonVariantType.OK}
          type="button"
          className={styles.btn}
        />
        <Button
          onClick={handleSaveImage}
          text={__("Сохранить")}
          variant={ButtonVariantType.OK}
          type="button"
          className={styles.btn}
        />
      </div>
    </div>
  );
};

export default PaintToolbar;
