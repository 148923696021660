import { ILibraryRubric } from "models/store/library/library";
import { FC } from "react";
import { v4 as uuid } from "uuid";

import { PreviewRubricLibrary } from "./components/PreviewRubricLibrary/PreviewRubricLibrary";
import styles from "./RubricsLibrary.module.sass";

interface IRubricsLibraryProps {
  rubrics: ILibraryRubric[];
}

export const RubricsLibrary: FC<IRubricsLibraryProps> = ({ rubrics }): JSX.Element =>
  !!rubrics?.length && (
    <div className={styles.list}>
      {rubrics.map((rubric) => (
        <PreviewRubricLibrary key={uuid()} rubric={rubric} />
      ))}
    </div>
  );
