export enum UserTypes {
  LOG_DATA = "LOG_DATA",
  UPDATE_SETTINGS = "UPDATE_SETTINGS",
  USER_INFO = "USER_INFO",
  SET_EMPLOYEES = "SET_EMPLOYEES",
  SET_USERS_STORAGE = "SET_USERS_STORAGE",
  CONTACT_LIST = "CONTACT_LIST",
  UPDATE_DASHBOARDS = "UPDATE_DASHBOARDS",
  SET_TAG_LIST = "SET_TAG_LIST",
  GET_TAG_LIST = "GET_TAG_LIST",
  ADD_TAG = "ADD_TAG",
  UPDATE_TAG = "UPDATE_TAG",
  DELETE_TAG = "DELETE_TAG"
}
