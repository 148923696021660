import PropTypes from "prop-types";
import { colorType } from "./Color";
import { projectUserProps } from "./Project";

export const editMeetingParams = PropTypes.exact({
  width: PropTypes.number,
  date: PropTypes.string,
  time: PropTypes.string,
  name: PropTypes.string
});

export const editSectionParams = PropTypes.exact({
  width: PropTypes.number,
  title: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string
});

export const taskCommentTypes = PropTypes.exact({
  id: PropTypes.string,
  id_task: PropTypes.string,
  id_user: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string
});

export const taskTypes = PropTypes.exact({
  id: PropTypes.string,
  name: PropTypes.string,
  id_user: PropTypes.string,
  ut: PropTypes.string,
  date_start: PropTypes.string,
  date_long: PropTypes.string,
  date_end: PropTypes.string,
  emails: PropTypes.string,
  tags: PropTypes.string,
  prim: PropTypes.string,
  id_emo: PropTypes.string,
  id_fig: PropTypes.string,
  id_color: PropTypes.oneOfType([colorType, PropTypes.string]),
  is_del: PropTypes.string,
  id_type: PropTypes.string,
  id_dep: PropTypes.string,
  id_act: PropTypes.string,
  id_status: PropTypes.string,
  id_project: PropTypes.string,
  id_executor: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  id_category: PropTypes.string,
  id_parent: PropTypes.string,
  time_start: PropTypes.string,
  comments: PropTypes.arrayOf(taskCommentTypes),
  pass: PropTypes.string,
  callbackType: PropTypes.string,
  showNote: PropTypes.bool,
  num: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  author_info: projectUserProps,
  author: PropTypes.string,
  sort: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLink: PropTypes.bool,
  link: PropTypes.string,
  del_date: PropTypes.string
});

export const taskDepartmentTypes = PropTypes.exact({
  name: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  id_parent: PropTypes.string,
  id_user: PropTypes.string,
  is_system: PropTypes.string
});

export const reminderTypes = PropTypes.exact({
  text: PropTypes.string,
  id: PropTypes.string,
  date: PropTypes.string,
  type: PropTypes.string,
  period_d: PropTypes.string,
  period_w: PropTypes.string,
  period_m: PropTypes.string,
  period_y: PropTypes.string,
  date2: PropTypes.string,
  is_read: PropTypes.string,
  date_view: PropTypes.string
});
