import { ReactComponent as Canvas } from "assets/icons/canvas.svg";
import { ReactComponent as Clock } from "assets/icons/clock_1.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as Group } from "assets/icons/group-team.svg";
import { ReactComponent as Inf } from "assets/icons/infinity.svg";
import { ReactComponent as Library } from "assets/icons/library.svg";
import { ReactComponent as Person } from "assets/icons/person_2.svg";
import { ReactComponent as PersonMail } from "assets/icons/person_mail.svg";
import { ReactComponent as Safe } from "assets/icons/safe_1.svg";
import { ReactComponent as Segment } from "assets/icons/segment.svg";
import { ReactComponent as Shared } from "assets/icons/shared.svg";
import { ReactComponent as Usd } from "assets/icons/usd.svg";
import { useTariffData } from "collections/tariff";
import TariffPrice from "containers/Profile/TarrifTable/TariffPrice.tsx/TariffPrice";
import Button from "generalComponents/Button/Button";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useLocales } from "react-localized";

import styles from "./TarrifTable.module.sass";

type TariffType = "base" | "standart" | "business" | "businessTop";
const tariffTypes: TariffType[] = ["base", "standart", "business", "businessTop"];

const getInformation = (value: number | string | null | typeof Infinity): string | JSX.Element => {
  if (value === null) {
    return <Close />;
  } else if (value === Infinity) {
    return <Inf />;
  } else {
    return value.toString();
  }
};

const TarrifTable = ({ active }: { active: string }) => {
  const { onSetTariffModal } = useActions();
  const { __ } = useLocales();
  const data = useTariffData(active);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerCell}></div>
        {tariffTypes.map((type) => (
          <div key={type} className={styles.headerCell}>
            {data[type].name}
          </div>
        ))}
      </div>
      <div className={styles.title}>
        <div className={styles.titleCell}>
          <Usd />
          {__("Стоимость/месяц")}
        </div>
        {tariffTypes.map((type) => (
          <TariffPrice key={type} value={data[type].price.value} discount={data[type].price.discount} />
        ))}
      </div>
      <div className={styles.row} style={{ height: "56px" }}>
        <div className={styles.cell} style={{ paddingTop: "28px" }}>
          <Person />
          {__("Сотрудники")}
        </div>
        {tariffTypes.map((type, index) => (
          <div key={index} className={styles.cell} style={{ paddingTop: "16px" }}>
            {getInformation(data[type].employees)}
          </div>
        ))}
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>
          <PersonMail />
          {__("Корпоративная почта")}
        </div>
        {tariffTypes.map((type, index) => (
          <div key={index} className={styles.cell}>
            {getInformation(data[type].corporateMail)}
          </div>
        ))}
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>
          <Segment />
          {__("Место на диске")}
        </div>
        {tariffTypes.map((type, index) => (
          <div key={index} className={styles.cell}>
            {getInformation(data[type].diskSpace)} {__("ГБ")}
          </div>
        ))}
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>
          <Safe />
          {__("Сейфы")}
        </div>
        {tariffTypes.map((type, index) => (
          <div key={index} className={styles.cell}>
            {getInformation(data[type].safes)}
          </div>
        ))}
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>
          <Shared />
          {__("Предоставленный доступ")}
        </div>
        {tariffTypes.map((type, index) => (
          <div key={index} className={styles.cell}>
            {getInformation(data[type].provided)}
          </div>
        ))}
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>
          <Group />
          {__("Проекти")}
        </div>
        {tariffTypes.map((type, index) => (
          <div key={index} className={styles.cell}>
            {getInformation(data[type].projects)}
          </div>
        ))}
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>
          <Library />
          {__("Библиотека")}
        </div>
        {tariffTypes.map((type, index) => (
          <div key={index} className={styles.cell}>
            {getInformation(data[type].library)}
          </div>
        ))}
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>
          <Clock />
          {__("Журнал")}
        </div>
        {tariffTypes.map((type, index) => (
          <div key={index} className={styles.cell}>
            {getInformation(data[type].journal)}
          </div>
        ))}
      </div>
      <div className={styles.row} style={{ height: "56px" }}>
        <div className={styles.cell} style={{ paddingBottom: "28px" }}>
          <Canvas /> {__("Совместный стол")}
        </div>
        {tariffTypes.map((type, index) => (
          <div key={index} className={styles.cell} style={{ paddingBottom: "16px" }}>
            {getInformation(data[type].sharedTable)}
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <div className={styles.cell} />
        <div className={styles.cell} />
        <div className={styles.cell}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            className={styles.button}
            text={__("Подключить")}
            onClick={() => onSetTariffModal({ open: true, price: 25, title: __("Стандарт") })}
          />
        </div>
        <div className={styles.cell}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            className={styles.button}
            text={__("Подключить")}
            onClick={() =>
              onSetTariffModal({ open: true, price: 45, discount: 19, title: __("Бизнесс"), isPeriodSelected: true })
            }
          />
        </div>
        <div className={styles.cell}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            className={styles.button}
            text={__("Подключить")}
            onClick={() =>
              onSetTariffModal({ open: true, price: 45, discount: 19, title: __("Бизнесс +"), isPeriodSelected: true })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default TarrifTable;
