import { useTaskStatuses } from "collections/tasks";
import { TASK_PARAMS } from "generalComponents/variables/routing";
import { STATUS_TYPES, TASK_TYPES } from "generalComponents/variables/tasks";
import { ITaskStatus } from "models/generalComponents/collections/tasks";
import { ITasksTypes } from "models/generalComponents/services/tasksServices";
import { IMyTask, TASK_DEP } from "models/store/tasks/tasks";
import { useMemo } from "react";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";
import { useFilterCriteriaSelector } from "Store/selectors/filterCriteriaSelectors";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";
import { dateISO } from "utils/dateToISO";

export const useTasks = (): ITasksTypes => {
  const { myTasks } = useTasksSelectors();
  const [searchParams] = useSearchParams();
  const dep = searchParams.get(TASK_PARAMS.DEP);
  const {
    filterCriteria: { color, emo, figure, tags }
  } = useFilterCriteriaSelector();

  const filtredDepartment: IMyTask[] = useMemo(() => {
    if (dep) {
      return myTasks.filter((task) => task.id_dep === dep);
    }
    return myTasks;
  }, [dep, myTasks]);

  const filtredTasks: IMyTask[] = useMemo(() => {
    if (!color && !emo && !figure && !tags) {
      return filtredDepartment;
    }
    return filtredDepartment
      .filter((task) => {
        if (color) {
          return task.color === color;
        }
        return task;
      })
      .filter((task) => {
        if (emo) {
          return task.emo === emo;
        }
        return task;
      })
      .filter((task) => {
        if (tags) {
          return task.tags.some((tag) => tags.includes(tag));
        }
        return task;
      });
  }, [color, emo, figure, filtredDepartment, tags.length]); // eslint-disable-line

  const tasks = filtredTasks.filter((t) => t.id_type === TASK_TYPES.TASK);
  const meetings = filtredTasks.filter(
    (t) => t.id_type === TASK_TYPES.OFFLINE_MEETING || t.id_type === TASK_TYPES.ONLINE_MEETING
  );
  const calls = filtredTasks.filter((t) => t.id_type === TASK_TYPES.CALLS);
  const mails = filtredTasks.filter((t) => t.id_type === TASK_TYPES.MAILS);

  return {
    tasks,
    meetings,
    calls,
    mails
  };
};

export const useTaskSatus = (type: TASK_TYPES): ((it: STATUS_TYPES) => ITaskStatus) => {
  const { myTasks, otherTasks } = useTaskStatuses();
  if (type === TASK_TYPES.TASK) {
    return (it: STATUS_TYPES): ITaskStatus => myTasks.find((el) => el.status === it);
  }
  return (it: STATUS_TYPES): ITaskStatus => otherTasks.find((el) => el.status === it);
};

export const useOverdueTasks = (): { overdueTasks: IMyTask[] } => {
  const { myTasks } = useTasksSelectors();

  const [searchParams] = useSearchParams();
  const dep = searchParams.get(TASK_PARAMS.DEP);
  const overdueTasks = useMemo(() => {
    const now = new Date();
    const overdue = myTasks
      .filter(
        (t) => t.id_type === TASK_TYPES.TASK && t.id_status !== STATUS_TYPES.DONE && (dep ? t.id_dep === dep : true)
      )
      .filter((task) => {
        if (task.date_end) {
          const endDate = new Date(dateISO(task.date_end));
          return endDate < now;
        } else if (task.date_start) {
          const startDate = new Date(dateISO(task.date_start));
          return startDate < now;
        }
        return false;
      });
    return overdue;
  }, [myTasks, dep]);

  return { overdueTasks };
};

export const useDepName = () => {
  const { __ } = useLocales();
  return (value: TASK_DEP): string => {
    switch (value) {
      case TASK_DEP.WORK:
        return __("Рабочие задачи");
      case TASK_DEP.PERSONAL:
        return __("Личные задачи");
      case TASK_DEP.NO_DEP:
        return __("Все");

      default:
        break;
    }
  };
};

export const useDepCalendarName = () => {
  const { __ } = useLocales();
  return (value: TASK_DEP): string => {
    switch (value) {
      case TASK_DEP.WORK:
        return __("Рабочий");
      case TASK_DEP.PERSONAL:
        return __("Личный");
      case TASK_DEP.NO_DEP:
        return __("Все календари");

      default:
        break;
    }
  };
};
