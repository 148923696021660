import { de, enGB, fr, pl, ru, uk } from "date-fns/locale";
import { FC } from "react";
import DatePicker from "react-datepicker";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./InputDate.module.sass";

interface IProps {
  startDate: Date;
  endDate?: Date;
  setStartDate: Function;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  timeFormat?: string;
  isClearable?: boolean;
  isBtnTooday?: boolean;
  isBtnClear?: boolean;
  withTime?: boolean;
  showMonthYearPicker?: boolean; // для выбора месяца
  setIsClose?: (v: boolean) => void;
  setWithTime?: (v: boolean) => void;
  selectsRange?: boolean;
  excludeDateIntervals?: Array<{ start: Date; end: Date }>;
  filterDate?(date: Date): boolean;
  includeTimes?: Date[];
  timeIntervals?: number;
  readOnly?: boolean;
  className?: string;
}
const getLocale = (lang: string): Locale => {
  switch (lang) {
    case "uk":
      return uk;
    case "ru":
      return ru;
    case "en":
      return enGB;
    case "pl":
      return pl;
    case "de":
      return de;
    case "fr":
      return fr;

    default:
      return enGB;
  }
};

const InputDate: FC<IProps> = (props) => {
  const { __ } = useLocales();
  const {
    startDate,
    endDate,
    setStartDate,
    placeholder,
    minDate,
    maxDate,
    dateFormat,
    timeFormat,
    isClearable,
    withTime,
    showMonthYearPicker,
    setIsClose,
    filterDate,
    timeIntervals = 15,
    includeTimes,
    selectsRange,
    excludeDateIntervals,
    readOnly = false,
    className
  } = props;
  const {
    userInfo: { lang }
  } = useUserSelectors();

  return (
    <DatePicker
      customInput={<input className={className ? className : styles.input} />}
      locale={getLocale(lang)}
      wrapperClassName="DatePickerWrapper"
      calendarClassName="DatePickerCalendar"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      placeholderText={placeholder || __("Выберите дату")}
      minDate={minDate}
      maxDate={maxDate}
      startDate={startDate}
      endDate={endDate}
      timeFormat={timeFormat || "p"}
      dateFormat={dateFormat || "dd MMM yyyy HH:mm"}
      showTimeSelect={withTime}
      timeInputLabel=""
      timeIntervals={timeIntervals}
      isClearable={isClearable}
      onCalendarClose={() => setIsClose && setTimeout(() => setIsClose(false), 500)}
      onCalendarOpen={() => setIsClose && setIsClose(true)}
      shouldCloseOnSelect
      showMonthYearPicker={showMonthYearPicker}
      selectsRange={selectsRange}
      excludeDateIntervals={excludeDateIntervals}
      filterDate={filterDate}
      readOnly={readOnly}
      includeTimes={includeTimes}
    />
  );
};

export default InputDate;
