import { ReactComponent as UserIcon } from "assets/PrivateCabinet/user-setting.svg";
import classNames from "classnames";
import { PROJECT_ADMIN_ROLES } from "generalComponents/variables/project";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProjectTypes } from "Store/types/projectTypes";

import UserBlock from "../UserBlock/UserBlock";
import styles from "./ProjectUsers.module.sass";

const ProjectUsers = () => {
  const dispatch = useDispatch();
  const theme = useSelector((s) => s.user.userInfo.theme);
  const project = useSelector((s) => s.Projects.project);
  const selectUser = useSelector((s) => s.Projects.selectUser);
  const selectRole = useSelector((s) => s.Projects.selectRole);

  const handleClick = (user) => {
    const payload = selectUser === user.id_user ? "0" : user.id_user;
    dispatch({ type: ProjectTypes.SELECT_USER, payload });
  };

  const filtredUsers = useMemo(() => {
    return project.users.filter((user) => {
      if (selectRole !== "0") {
        return user.id_role === selectRole;
      }
      return user;
    });
  }, [project.users, selectRole]);

  return (
    <div className={classNames(styles.users, `scrollbar-thin-${theme}`)}>
      {filtredUsers.map((user) => (
        <div
          key={user.id_user}
          className={classNames(styles.user, { [styles.active]: selectUser === user.id_user || selectUser === "0" })}
          onClick={() => handleClick(user)}
        >
          <UserBlock user={user} variant="light" />
          {Number(user.is_read) > 0 && (
            <UserIcon
              className={classNames(styles.icon, { [styles.admin]: user.is_read === PROJECT_ADMIN_ROLES.ADMIN })}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProjectUsers;
