import JoinProjectTeams from "containers/JoinProject/JoinProjectTeams/JoinProjectTeams";
import Button from "generalComponents/Button/Button";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useIsSystemWatherRole } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useLocales } from "react-localized";

import styles from "./ProjectTeamPage.module.sass";

const ProjectTeamPage = () => {
  const { __ } = useLocales();
  const { onSetCreateProjectTeamModal } = useActions();
  const createTeam = () => onSetCreateProjectTeamModal({ open: true, type: "add", team: null });

  const isSystemWatherRole = useIsSystemWatherRole();

  return (
    <WorkAreaWrap>
      <ToolsPanel size="large">
        {!isSystemWatherRole && (
          <div className={styles.tools}>
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={__("Создать команду")}
              onClick={createTeam}
            />
          </div>
        )}
      </ToolsPanel>
      <div className={styles.container}>
        <JoinProjectTeams />
      </div>
    </WorkAreaWrap>
  );
};

export default ProjectTeamPage;
