import Button from "generalComponents/Button/Button";
import { ROUTES, TASK_PARAMS } from "generalComponents/variables/routing";
import { useOverdueTasks } from "hooks/tasksHooks";
import { useCurrentPath } from "hooks/useCurrentPath";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useLocales } from "react-localized";
import { useNavigate, useSearchParams } from "react-router-dom";

const OverdueTasks = () => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const { overdueTasks } = useOverdueTasks();
  const [searchParams, setSearchParams] = useSearchParams();
  const isOverdue = Boolean(searchParams.get(`${TASK_PARAMS.OVERDUE}`));
  const isTaskPage = useCurrentPath(ROUTES.TASKS);

  const toggleOverdue = () => {
    if (isTaskPage) {
      isOverdue ? searchParams.delete(`${TASK_PARAMS.OVERDUE}`) : searchParams.set(`${TASK_PARAMS.OVERDUE}`, "true");
      setSearchParams(searchParams);
      return;
    }
    navigate(`/${ROUTES.TASKS}?${TASK_PARAMS.OVERDUE}=true`);
  };

  return (
    (overdueTasks.length > 0 || isOverdue) && (
      <Button
        onClick={toggleOverdue}
        variant={isOverdue ? ButtonVariantType.RED : ButtonVariantType.RED_RESERSE}
        size={ButtonSizeType.EXTRA_SMALL}
        text={`${__("Просроченных")} - ${overdueTasks.length}`}
      />
    )
  );
};

export default OverdueTasks;
