import classNames from "classnames";
import React from "react";
import { useRecentFilesSelectors } from "Store/selectors/recentFilesSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import RecentFile from "./RecentFile/RecentFile";
import styles from "./RecentFiles.module.sass";

const RecentFiles = (): JSX.Element => {
  const { recentFiles } = useRecentFilesSelectors();
  const {
    userInfo: { theme }
  } = useUserSelectors();

  return (
    <div className={styles.root}>
      <ul className={classNames(styles.list, `scrollbar-thin-${theme}`)}>
        {recentFiles.map((file) => (
          <li key={file.fid} className={styles.item}>
            <RecentFile file={file} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentFiles;
