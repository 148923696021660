import { ReactComponent as SwitchScreenIcon } from "assets/PrivateCabinet/chat/change-device.svg";
import { ReactComponent as FullScreenIcon } from "assets/PrivateCabinet/chat/full-screen.svg";
import { ReactComponent as HangUpIcon } from "assets/PrivateCabinet/chat/hangUp.svg";
import { ReactComponent as VideoIcon } from "assets/PrivateCabinet/film.svg";
import { ReactComponent as RadioIcon } from "assets/PrivateCabinet/radio-3.svg";
import classNames from "classnames";
import Button from "generalComponents/CustomableButton/CustomableButton";
import { useWebRTCContext } from "generalComponents/Hooks/WebRTC/WebRTCProvider";
import { CHAT_CALLROOM, LOCAL_CLIENT, MEDIA_TRACK_KIND } from "generalComponents/variables/chat";
import { BUTTON_TYPES } from "generalComponents/variables/customableButton";
import { useWebSocketContext } from "generalComponents/WebSocketsProvider/WebSocketsProvider";
import { IMiniCallRoomProps } from "models/chat/callRoom";
import { IConnectedUser } from "models/store/Cabinet/chat/chat";
import React from "react";
import { useChatSelectors } from "Store/selectors/chatSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./MiniCallRoom.module.sass";

function MiniCallRoom({
  setFullScreen,
  handleSwitchMedia,
  cancelCall,
  fullScreen,
  provideMiniCallRoomRef
}: IMiniCallRoomProps) {
  const {
    userInfo: { id }
  } = useUserSelectors();
  const { callRoom } = useChatSelectors();

  const { socket } = useWebSocketContext();
  const { clients, localScreenStream } = useWebRTCContext();

  const currentID = clients[0] === LOCAL_CLIENT ? id : clients[0];
  const connectedUser: IConnectedUser = callRoom.connectedUsers?.find((it) => it.id_user === currentID);

  // отображение копок звонка, когда соединение установлено
  const renderButtons = (): JSX.Element => (
    <div className={classNames(styles.buttons, styles.hiddenButtons)}>
      {callRoom.state === CHAT_CALLROOM.JOINED ? (
        <Button style={BUTTON_TYPES.ROUND_GREY} onClick={() => setFullScreen(true)} disabled={socket === null}>
          <FullScreenIcon />
        </Button>
      ) : null}
      {callRoom.state === CHAT_CALLROOM.JOINED ? (
        <Button style={BUTTON_TYPES.ROUND_GREY} disabled={socket === null}>
          <SwitchScreenIcon />
        </Button>
      ) : null}
      {callRoom.state === CHAT_CALLROOM.JOINED ? (
        <Button
          style={
            callRoom.connectedUsers.find((it) => it.id_user === id)?.media.video
              ? BUTTON_TYPES.ROUND_GREY
              : BUTTON_TYPES.ROUND_RED
          }
          onClick={() => handleSwitchMedia(MEDIA_TRACK_KIND.VIDEO)}
          disabled={socket === null}
        >
          <VideoIcon className={styles.cameraIcon} />
          {callRoom.connectedUsers.find((it) => it.id_user === id)?.media.video ? null : (
            <div className={styles.slash} />
          )}
        </Button>
      ) : null}
      {callRoom.state === CHAT_CALLROOM.JOINED ? (
        <Button
          style={
            callRoom.connectedUsers.find((it) => it.id_user === id)?.media.audio
              ? BUTTON_TYPES.ROUND_GREY
              : BUTTON_TYPES.ROUND_RED
          }
          onClick={() => handleSwitchMedia(MEDIA_TRACK_KIND.AUDIO)}
          disabled={socket === null}
        >
          <RadioIcon />
          {callRoom.connectedUsers.find((it) => it.id_user === id)?.media.audio ? null : (
            <div className={styles.slash} />
          )}
        </Button>
      ) : null}
      <Button style={BUTTON_TYPES.ROUND_RED} onClick={cancelCall} disabled={socket === null}>
        <HangUpIcon />
      </Button>
    </div>
  );

  return (
    <div
      className={classNames(styles.miniCallRoom, {
        "d-none": fullScreen
      })}
    >
      <div className={styles.innerMiniCallRoom}>
        <video
          ref={(instance) => provideMiniCallRoomRef(instance)}
          autoPlay
          playsInline
          muted={clients[0] === LOCAL_CLIENT}
        />
        {!connectedUser?.media?.video && !localScreenStream.current ? (
          <img src={connectedUser?.icon} alt="icon" className={styles.avatarImage} />
        ) : null}
        {renderButtons()}
      </div>
    </div>
  );
}

export default MiniCallRoom;
