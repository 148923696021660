export interface IPostbox {
  agent: string;
  date: string | null;
  email: string;
  id: string;
  id_user: string;
  isInternalDomain: number;
  pass: string;
  server: string | null;
  name: string;
  folders: IMailFolders;
}
export interface IFolderInfo {
  Date: string;
  Deleted: number;
  Driver: string;
  Mailbox: string;
  Nmsgs: number;
  Recent: number;
  Size: number;
  Unread: number;
}

export interface IMailFolder {
  count?: number;
  full: string;
  info: IFolderInfo;
  name: string;
  orig: string;
  id: string;
}

export interface IMailFolders {
  system: IMailFolder[];
  custom: IMailFolder[];
}

export interface ITypeOfFile {
  mimeType: string;
  encoding: number;
}

export interface ILetterInfoAttachments {
  filename: string;
  mime: string;
  size: number;
  structure: object;
  type: ITypeOfFile;
}

export interface ILetterInfoTo {
  address: string;
}
export interface ITypeOfFile {
  mimeType: string;
  encoding: number;
}

export interface ILetterInfo {
  [key: string]: any;
  answered: number;
  att_col: number;
  attachments: boolean | ILetterInfoAttachments[];
  date_scheduled?: string;
  cc: string;
  deleted: number;
  draft: number;
  flagged: number;
  from: string;
  from_name: string;
  html: string;
  is_liked: number;
  is_favorite: number;
  message_id: string;
  preview: string;
  recent: number;
  seen: number;
  size: number;
  subject: string;
  text: string | boolean;
  time: string;
  to: ILetterInfoTo[];
  uid: number;
  id: string;
  folder: string;
}

export interface IMailsState {
  postboxes: IPostbox[];
  loader: boolean;
  letters: ILetterInfo[];
  isObserver: boolean;
  pickedLetters: ILetterInfo[];
  searchLetter: ILetterInfo;
}

export function initialMailsState(): IMailsState {
  return {
    postboxes: [],
    loader: false,
    letters: [],
    isObserver: false,
    pickedLetters: [],
    searchLetter: null
  };
}
