import { TASK_TYPES } from "generalComponents/variables/tasks";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IMyTask } from "models/store/tasks/tasks";
import { AllElementsType } from "models/store/typeElements";
import { TypeElements } from "models/typeElements/typeElements";

export const renderType = (element: AllElementsType): string => {
  const task = element as IMyTask;

  if (
    element.table === TypeElements.SAFE_FILES ||
    element.table === TypeElements.FILES ||
    (element as IFile).is_dir === 0
  ) {
    return "Файл";
  }

  if ((element as IFolder).is_dir === 1) {
    return "Папка";
  }

  if (element.table === TypeElements.SAFE) {
    return "Сейф";
  }

  if (element.table === TypeElements.PROJECT) {
    return "Совместный проект";
  }

  if (element.table === TypeElements.USERS) {
    return "Сотрудник";
  }

  if (task.id_type === TASK_TYPES.OFFLINE_MEETING) {
    return "Оффлайн встреча";
  }

  if (task.id_type === TASK_TYPES.ONLINE_MEETING) {
    return "Онлайн встреча";
  }

  if (task.id_type === TASK_TYPES.CALLS) {
    return "Звонок";
  }

  if (task.id_type === TASK_TYPES.TASK) {
    return "Задача";
  }

  if (task.id_type === TASK_TYPES.MAILS) {
    return "Письмо";
  }

  if (task.id_type === TASK_TYPES.REMINDER) {
    return "Напоминание";
  }

  if (task.id_type === TASK_TYPES.NOTES) {
    return "Заметки";
  }
};
