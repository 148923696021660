import api from "api";
import classNames from "classnames";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile, initialPickedItems } from "models/store/files/files";
import { FolderUrlTypes } from "models/store/folders/foldersPayloads";
import { IFolder } from "models/store/folders/foldersStore";
import React from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./BottomMenuOption.module.sass";

const MoveToArchiveBtn: React.FC = () => {
  const { __ } = useLocales();
  const {
    onEditFolders,
    onSetPickedItems,
    onSetLoaderModal,
    onEditSafeFolder,
    onSetApproveModal,
    onMoveFileToArchive,
    onSetTopMessageModal,
    onSetDelFiles,
    onSetDelNextFiles
  } = useActions();
  const {
    pickedItems: { items }
  } = useFilesSelectors();
  const { uid } = useUserSelectors();

  const { currentFolder } = useFoldersSelectors();

  const { isLinesPreviewView } = useGetItemView();

  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const { safeDir } = useParams();
  const safe = useAuthorizedSafe();
  const onMoveToArchive = async (): Promise<void> => {
    // only folders
    if (items.every((item) => item.is_dir === 1)) {
      const id_dirs = items.map((item) => (item as IFolder).id_dir);

      const payload = {
        id_dirs,
        is_archive: 1
      };
      const payloadSafe = {
        ...safe,
        idParent: (items[0] as IFolder).id_parent,
        id_dirs,
        is_archive: "1"
        // name: (items[0] as IFolder).name
      };

      isSafePage
        ? onEditSafeFolder(payloadSafe, EVENT_TYPE.ARCHIVE)
        : onEditFolders(payload, EVENT_TYPE.MOVE, () => {
            if (isLinesPreviewView) {
              onSetDelNextFiles(items);
            } else {
              if (items.some((item) => (item as IFolder).id_parent === currentFolder?.id_dir)) {
                onSetDelFiles({ ids: id_dirs });
              }
            }
          });
      return;
    }

    // only files
    if (items.every((item) => item.is_dir === 0)) {
      const payload = {
        fids: items.map((item) => (item as IFile).fid),
        id_dir: safeDir ? safeDir : undefined,
        files: (items as IFile[]).filter((el) => el.is_dir === 0) || undefined
      };

      const params = {
        titleHead: __("Переместить в архив"),
        title: `${__("Вы действительно хотите переместить")} выбранные файлы в архив?`,
        text: `${__(`Вы сможете восстановить файлы с архива`)}`,
        approveBtn: __("Переместить"),
        callback: (): void => {
          onMoveFileToArchive(payload, EVENT_TYPE.ARCHIVE, safe);
        }
      };

      onSetApproveModal({
        open: true,
        params
      });
      return;
    }

    // files and folders
    const params = {
      titleHead: __("Переместить в архив"),
      title: `${__("Вы действительно хотите переместить")} выбранные файлы и папки в архив?`,
      text: `${__(`Вы сможете восстановить файлы и папки с архива`)}`,
      approveBtn: __("Переместить"),
      callback: async (): Promise<void> => {
        const id_dirs = items.filter((item) => item.is_dir === 1).map((item) => (item as IFolder).id_dir);
        const fids = items.filter((item) => item.is_dir === 0).map((item) => (item as IFile).fid);

        const ids = [...id_dirs, ...fids];

        const foldersParams = {
          uid,
          id_dirs,
          is_archive: 1
        };

        const filesParams = {
          uid,
          fids,
          is_archive: 1
        };

        try {
          onSetLoaderModal(true);
          const [foldersResponse, filesResponse] = await Promise.all([
            api.get(`/ajax/${FolderUrlTypes.DIR_EDIT}`, { params: foldersParams }),
            api.get("/ajax/file_edit.php", { params: filesParams })
          ]);

          if (foldersResponse.data.ok === 1 && filesResponse.data.ok === 1) {
            onSetPickedItems(initialPickedItems());

            if (isLinesPreviewView) {
              onSetDelNextFiles(items);
            } else {
              if (items.some((item) => (item as IFolder).id_parent === currentFolder?.id_dir)) {
                onSetDelFiles({ ids });
              }
            }

            onSetTopMessageModal({
              open: true,
              type: ITopMessageTypes.SUCCESS,
              variantMessage: EVENT_TYPE.ARCHIVE
            });
          } else {
            throw new Error();
          }
        } catch (error) {
          console.log(error);
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          });
        } finally {
          onSetLoaderModal(false);
        }
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  return (
    <button
      onClick={onMoveToArchive}
      className={classNames({
        [styles.disabled]: items.length === 0
      })}
      type="button"
    >
      {__("Пер. в архив")}
    </button>
  );
};

export default MoveToArchiveBtn;
