import { useSystemFoldersDoc } from "collections/joinProject";
import { JoinProjectDocElementsList } from "containers/JoinProject/JointProjectDocumentation/components/JoinProjectDocElementsList/JoinProjectDocElementsList";
import { JointProjectDocumentationPanel } from "containers/JoinProject/JointProjectDocumentation/components/JointProjectDocumentationPanel/JointProjectDocumentationPanel";
import { WrapperPage } from "containers/JoinProject/WrapperPage/WrapperPage";
import { useAllFolders } from "generalComponents/Services/folderServices/folderServices";
import { PAGE_DEPTH } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { IFolder, initialFolderState } from "models/store/folders/foldersStore";
import { TProjectFilesArray } from "models/store/joinProjects/joinProgects";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

const ProjectDocumentsPage = () => {
  const { onGetProjectFiles, onResetProjectDocFilesList, onSetProjectDocFiles, onSelectFolder } = useActions();

  const { dirId } = useParams();

  const navigate = useNavigate();

  const systemFoldersDoc = useSystemFoldersDoc();

  const { project } = useJoinProjectsSelectors();

  const [activeTab, setActiveTab] = useState<string>("");

  const foldersAll = useAllFolders();

  useEffect(() => {
    const controller = new AbortController();

    if (dirId) {
      const folder: IFolder = foldersAll.find((el) => el.id_dir === dirId);

      if (!folder) return;

      setActiveTab(folder.chapter);

      onSelectFolder(folder);

      const payload = {
        id_dir: dirId,
        dep: PAGE_DEPTH.PROJECT_DOC,
        callback: (files: TProjectFilesArray) => onSetProjectDocFiles(files)
      };

      onGetProjectFiles(payload, controller);
    }

    return () => {
      onSelectFolder(initialFolderState());
      onResetProjectDocFilesList();
      controller.abort();
    };
  }, [dirId]); // eslint-disable-line

  useEffect(() => {
    if (!dirId && !!project?.folders_doc?.length) {
      navigate(`${project.folders_doc[0].id_dir}`, { replace: true });
      return;
    }

    return () => {
      onResetProjectDocFilesList();
    };
  }, []); //eslint-disable-line

  return (
    <WrapperPage>
      <JointProjectDocumentationPanel tabs={Object.values(systemFoldersDoc)} activeTab={activeTab} />
      <JoinProjectDocElementsList />
    </WrapperPage>
  );
};

export default ProjectDocumentsPage;
