import { EVENT_TYPE, TYPE_ELEMENTS, TYPE_TABLE_ACCESS } from "generalComponents/variables/global";
import { IDeviceInfo, IIP_Info } from "models/deviceInfo/deviceInfo";

import { IFile } from "../files/files";
import { IFolder } from "../folders/foldersStore";
import { IPostbox } from "../postbox/postbox";
import { IMyTask } from "../tasks/tasks";
import { IUserInfo } from "../user/user";

export type TEventGroup =
  | TYPE_ELEMENTS.FOLDERS
  | TYPE_ELEMENTS.FILES
  | TYPE_ELEMENTS.LIBRARY
  | TYPE_ELEMENTS.SAFES
  | TYPE_ELEMENTS.TASKS
  | TYPE_ELEMENTS.EMAILS
  | TYPE_ELEMENTS.SOFT
  | TYPE_ELEMENTS.USERS
  | TYPE_ELEMENTS.ORG_USERS
  | TYPE_ELEMENTS.SESSION_USERS
  | TYPE_ELEMENTS.DEPS;

export type TEventType =
  | EVENT_TYPE.ADD
  | EVENT_TYPE.ARCHIVE
  | EVENT_TYPE.COPY
  | EVENT_TYPE.EDIT
  | EVENT_TYPE.EDIT_NAME
  | EVENT_TYPE.ADD_FAVORITE
  | EVENT_TYPE.UNFAVORITE
  | EVENT_TYPE.IS_DEL
  | EVENT_TYPE.UN_IS_DEL
  | EVENT_TYPE.DEL
  | EVENT_TYPE.LIST
  | EVENT_TYPE.MOVE
  | EVENT_TYPE.SEND
  | EVENT_TYPE.SHARE
  | EVENT_TYPE.SHARE_ME
  | EVENT_TYPE.EDIT_STATUS
  | EVENT_TYPE.ZIP
  | EVENT_TYPE.DOWNLOAD
  | EVENT_TYPE.GET_ACCES
  | EVENT_TYPE.GET_NO_ACCES
  | EVENT_TYPE.PASS_CHANGE
  | EVENT_TYPE.PASS_ADD
  | EVENT_TYPE.PASS_DEL
  | EVENT_TYPE.PASS_RESTORE
  | EVENT_TYPE.OPEN_BLOCKED
  | EVENT_TYPE.REG
  | EVENT_TYPE.ENTER
  | EVENT_TYPE.MESSAGE_DEL
  | EVENT_TYPE.USER_COPY;

type TAllObj = IFile | IFolder | IMyTask | IPostbox | IUserInfo; //IUserShare удалить

export interface IEvent {
  data: {
    new: TAllObj & { share?: IUserInfo[]; send?: string };
    old: TAllObj & { share?: IUserInfo[]; send?: string };
    admin?: IUserInfo;
    from?: string;
    to?: string;
    table?: TYPE_TABLE_ACCESS;
  };
  date: string;
  date2: string;
  date_view: string;
  event_group: TEventGroup;
  event_object: string;
  event_type: TEventType;
  id: string;
  id_user: string;
  is_exists?: number;
  is_read: "0" | "1";
  period_d: string;
  period_m: string;
  period_w: string;
  period_y: string;
  object: TAllObj & { id?: string; id_type?: string; email?: string };
  text: string;
  device_info: IDeviceInfo;
  ip_info: IIP_Info;
}

export interface IJournalState {
  events: IEvent[];
  total: number;
  page: number;
  loader: boolean;
}

export function initialJournalState(): IJournalState {
  return {
    events: null,
    total: 0,
    page: 1,
    loader: false
  };
}
