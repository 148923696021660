import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IProgram, IProgramsCategory } from "models/store/programs/programs";
import {
  IChangeFavoriteProgram,
  IClearSearchPrograms,
  IGetProgramsByCategory,
  IGetProgramsByCategoryLoading,
  ISearchIsLoading,
  ISetCategories,
  ISetSearchPrograms,
  ProgramsActions
} from "models/store/programs/programsActions";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { ProgramsTypes } from "Store/types/programsTypes";

import { onSetLoaderModal, onSetTopMessageModal } from "./ModalActions";

export const onGetCategories =
  (message?: string, isLoading?: boolean): ThunkAction<void, RootState, unknown, ProgramsActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      isLoading && dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid
      };
      const { data } = await api.get(`/ajax/soft_dep_list.php`, { params });
      dispatch(setProgramCategories(data.data.soft_dep));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: message }));
    } finally {
      isLoading && dispatch(onSetLoaderModal(false));
    }
  };

export const onGetProgramsByCategory =
  (
    payload: { depId: string; isFavorite: string },
    message: string,
    controller: AbortController
  ): ThunkAction<void, RootState, unknown, ProgramsActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(isLoadingGetProgramsByCat(true));
      const params = {
        uid: getState().user.uid,
        id_dep: payload.depId,
        is_favorite: payload.isFavorite
      };
      const { data } = await api.get("/ajax/soft_list.php?", { params, signal: controller.signal });
      checkResponseStatus(data.ok);
      dispatch(setProgramsByCategory(data.data.soft));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: message }));
    } finally {
      dispatch(isLoadingGetProgramsByCat(false));
    }
  };

export const setFaforiteProgram =
  (
    payload: { program: IProgram; isFavorite: string },
    message: string
  ): ThunkAction<void, RootState, unknown, ProgramsActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_item: payload.program.id,
        is_favorite: payload.isFavorite
      };
      const { data } = await api.get(`/ajax/soft_edit.php`, { params });
      checkResponseStatus(data.ok);
    } catch {
      dispatch(onChangeFavoriteProgram(payload.program));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: message }));
    }
  };

export const searchPrograms = (searchQuery: string): ThunkAction<void, RootState, unknown, ProgramsActions> => {
  return async (dispatch) => {
    try {
      const params = {
        search: searchQuery
      };
      dispatch(isLoadingSearchPrograms(true));
      const { data } = await api.get(`/ajax/soft_list.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(setSerchPrograms(data.data.soft));
    } catch {
      dispatch(isLoadingSearchPrograms(false));
    } finally {
      dispatch(isLoadingSearchPrograms(false));
    }
  };
};

export const clearSearchPrograms = (): IClearSearchPrograms => ({
  type: ProgramsTypes.CLEAR_SEARCH_PROGRAMS,
  payload: []
});

export const setProgramCategories = (payload: IProgramsCategory[]): ISetCategories => ({
  type: ProgramsTypes.GET_CATEGORIES,
  payload
});

export const setProgramsByCategory = (payload: IProgram[]): IGetProgramsByCategory => ({
  type: ProgramsTypes.GET_PROGRAMS_BY_CAT,
  payload
});

export const isLoadingSearchPrograms = (payload: boolean): ISearchIsLoading => ({
  type: ProgramsTypes.IS_LOADING_SEARCH_PROGRAMS,
  payload
});

const isLoadingGetProgramsByCat = (value: boolean): IGetProgramsByCategoryLoading => ({
  type: ProgramsTypes.IS_LOADING_GET_PROGRAMS_BY_CAT,
  payload: value
});

const setSerchPrograms = (payload: IProgram[]): ISetSearchPrograms => ({
  type: ProgramsTypes.SET_SEARCH_PROGRAMS,
  payload
});

export const onChangeFavoriteProgram = (payload: IProgram): IChangeFavoriteProgram => ({
  type: ProgramsTypes.CHANGE_FAVORITE,
  payload
});
