import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { initialPropertiesElementModalState } from "models/store/Cabinet/modals/propertiesElementModals";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import { PropertiesElemenModalBody } from "./components/PropertiesElemenModalBody/PropertiesElemenModalBody";

export const PropertiesElementModals: FC = () => {
  const { __ } = useLocales();

  const { onSetPropertiesElementModal } = useActions();

  const {
    propertiesElementModals: { element, onRestore, onTrash }
  } = useGlobalModalsSelectors();

  const closeModal = (): void => {
    onSetPropertiesElementModal(initialPropertiesElementModalState());
  };

  return (
    <PopUp set={closeModal}>
      <HeaderModal onClose={closeModal} title={__("Свойства")} />
      <PropertiesElemenModalBody element={element} onRestore={onRestore} onTrash={onTrash} />
    </PopUp>
  );
};
