import Button from "generalComponents/Button/Button";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useLocales } from "react-localized";

import styles from "./TariffServices.module.sass";

interface ITariffServices {
  title: string;
  icon: JSX.Element;
  data: { title: string; subTitle: string; price: number }[];
}

const TariffServices = ({ title, data, icon }: ITariffServices) => {
  const { __ } = useLocales();
  const { onSetTariffModal } = useActions();

  return (
    <div className={styles.tariffService}>
      <h4>{title}</h4>
      <div className={styles.wrapper}>
        {data.map((item, index) => (
          <div key={index} className={styles.item}>
            <div>
              <h5>
                {icon}
                {item.title}
              </h5>
              <span>{item.subTitle}</span>
            </div>

            <div className={styles.price}>
              {item.price}
              <sup>$</sup>
            </div>
            <Button
              className={styles.button}
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Подключить")}
              onClick={() =>
                onSetTariffModal({
                  open: true,
                  price: item.price,
                  title: item.title
                })
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TariffServices;
