import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ChangeEvent } from "react";
import { useLocales } from "react-localized";

import styles from "./PhoneNumberBlock.module.sass";

interface IPhoneNumberBlock {
  number: string;
  onTelChange: (e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onTelBlur: (index: number) => void;
  onAddTel: () => void;
  onRemoveTel: (index: number) => void;
  index: number;
  telError: string[];
}

const PhoneNumberBlock = ({
  number,
  onTelChange,
  onTelBlur,
  onAddTel,
  onRemoveTel,
  index,
  telError
}: IPhoneNumberBlock) => {
  const { __ } = useLocales();

  return (
    <div className={styles.phoneBlock}>
      <Input
        className={styles.input}
        value={number}
        placeholder={__("Введите")}
        onChange={(e) => onTelChange(e, index)}
        label={`${__("Телефон")} ${index + 1}`}
        type="text"
        error={telError[index] ? true : false}
        errorText={telError[index]}
        onBlur={() => onTelBlur(index)}
      />
      {index === 0 ? (
        <Button
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.MEDIUM}
          onClick={onAddTel}
          iconR={<PlusIcon />}
          isSquare
        />
      ) : (
        <Button
          variant={ButtonVariantType.OPACITY}
          size={ButtonSizeType.MEDIUM}
          onClick={() => onRemoveTel(index)}
          iconR={<TrashIcon />}
          isSquare
        />
      )}
    </div>
  );
};

export default PhoneNumberBlock;
