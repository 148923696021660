import WebRTCProvider from "generalComponents/Hooks/WebRTC/WebRTCProvider";
import { CHAT_CALLROOM, CHAT_MODALS } from "generalComponents/variables/chat";
import { MODALS } from "generalComponents/variables/global";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";

import AddQuickTask from "./AddQuickTask/AddQuickTask";
import AddTask from "./AddTask/AddTask";
import AddUser from "./AddUser/AddUser";
import CallRoom from "./CallRoom/CallRoom";

function ChatModals() {
  const callRoom = useSelector((s) => s.Cabinet.chat.callRoom);
  const chatModals = useSelector((s) => s.Cabinet.modals.chatModals);
  const { type, callback, params } = useSelector((s) => s.Cabinet.modals.chatModals);
  const dispatch = useDispatch();

  const closeModal = () => {
    callback
      ? dispatch(onSetModals(MODALS.CHAT, { type: callback, params }))
      : dispatch(onSetModals(MODALS.CHAT, { type: MODALS.NO_MODAL, params: null }));
  };

  return (
    <>
      {callRoom.state !== CHAT_CALLROOM.NO_CALL ? (
        <WebRTCProvider>
          <CallRoom />
        </WebRTCProvider>
      ) : null}
      {chatModals.type === CHAT_MODALS.ADD_USER ? <AddUser /> : null}
      {chatModals.type === CHAT_MODALS.ADD_TASK ? <AddTask onClose={closeModal} params={params} type={type} /> : null}
      {chatModals.type === CHAT_MODALS.ADD_QUICK_TASK ? (
        <AddQuickTask onClose={closeModal} params={params} type={type} />
      ) : null}
    </>
  );
}

export default ChatModals;
