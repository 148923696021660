interface IConvertDateOption {
  day: "2-digit" | "numeric" | "";
  month: "2-digit" | "numeric" | "long" | "short" | "narrow";
  year: "2-digit" | "numeric";
}

interface IConvertDate {
  dateStart: string;
  dateEnd?: string;
  locale: string;
  option?: IConvertDateOption;
}

export const getConvertFormatDateTime = ({
  dateStart,
  dateEnd = "",
  locale = "en-US",
  option = { day: "2-digit", month: "short", year: "numeric" }
}: IConvertDate): { date: string; time: string } => {
  if (dateStart) {
    let date = "";
    let time = "";

    const dateS = new Date(dateStart);
    const dayS = option.day ? dateS.toLocaleString(locale, { day: option.day }) : "";
    const monthS = dateS.toLocaleString(locale, { month: option.month });
    const yearS = dateS.toLocaleString(locale, { year: option.year });
    const timeS = dateS.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

    time = timeS;
    date = `${dayS} ${monthS} ${yearS}`;

    if (dateEnd) {
      const dateE = new Date(dateEnd);
      const dayE = option.day ? dateE.toLocaleString(locale, { day: option.day }) : "";
      const monthE = dateE.toLocaleString(locale, { month: option.month });
      const yearE = dateE.toLocaleString(locale, { year: option.year });
      const timeE = dateE.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

      if (!dayS && !dayE && monthS === monthE && yearS === yearE) {
        date = `${monthS} ${yearS}`;
      }

      if (!dayS && !dayE && monthS !== monthE && yearS === yearE) {
        date = `${monthS} - ${monthE} ${yearS}`;
      }

      if (!dayS && !dayE && yearS !== yearE) {
        date = `${monthS} ${yearS} - ${monthE} ${yearE}`;
      }

      if (dayS !== dayE && monthS === monthE && yearS === yearE) {
        date = `${dayS} - ${dayE} ${monthS} ${yearS}`;
      }

      if (monthS !== monthE && yearS === yearE) {
        date = `${dayS} ${monthS} - ${dayE} ${monthE} ${yearS}`;
      }

      if (yearS !== yearE) {
        date = `${dayS} ${monthS} ${yearS} - ${dayE} ${monthE} ${yearE}`;
      }

      const timeSArr = timeS.split(":");
      const timeEArr = timeE.split(":");

      timeSArr.forEach((item: string, index: number) => {
        if (
          Number(item) !== Number(timeEArr[index]) &&
          Number(timeEArr[0]) !== 23 &&
          Number(timeEArr[timeEArr.length - 1]) !== 59
        ) {
          time = `${timeS} - ${timeE}`;
        }
      });
    }

    return {
      date,
      time
    };
  }
};
