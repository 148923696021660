import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { ROUTES, ROUTES_SEARCH_PARAMS } from "generalComponents/variables/routing";
import { IRestoreInFolder } from "models/restoreInFolder/restoreInFolder";
import { ArchivedElementsArrayType, ArchivedElementsType } from "models/store/archive/archivedFiles";
import { ArchivedFilesActions } from "models/store/archive/archivedFilesActions";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { FilesActions } from "models/store/files/filesActions";
import { FoldersAction } from "models/store/folders/foldersActions";
import { IFolder } from "models/store/folders/foldersStore";
import { IProject } from "models/store/joinProjects/joinProgects";
import { ISafe } from "models/store/safe/safe";
import { IMyTask } from "models/store/tasks/tasks";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { ArchivedFilesTypes } from "Store/types/archivedFilesTypes";
import { getLocationSearchParams } from "utils/getLocationSearchParams";
import { getTheRestorePath } from "utils/getTheRestorePath";

import { onSetFolders } from "./FoldersActions";
import { onSetLoaderModal, onSetRestoreFileModal } from "./ModalActions";
import { onSetTopMessageModal } from "./ModalActions";

export const onArhivedFileLoader = (payload: boolean): ArchivedFilesActions => ({
  type: ArchivedFilesTypes.ARCHIVED_FILES_LOADER,
  payload
});

export const onSetMyArchivedFiles = (payload: ArchivedElementsArrayType): ArchivedFilesActions => ({
  type: ArchivedFilesTypes.SET_ARCHIVED_FILES,
  payload
});

export const onSetTotalArhivedItems = (payload: number): ArchivedFilesActions => ({
  type: ArchivedFilesTypes.SET_TOTAL_ARCHIVED_ITEMS,
  payload
});

export const onResetArhivedFilesList = (): ArchivedFilesActions => ({
  type: ArchivedFilesTypes.RESET_ARCHIVED_FILES_LIST
});

export const onNullifyArchivedFiles = (): ArchivedFilesActions => ({
  type: ArchivedFilesTypes.NULLIFY_ARCHIVED_FILES
});

export const onSetArchivePage = (payload: number): ArchivedFilesActions => ({
  type: ArchivedFilesTypes.SET_ARCHIVE_PAGE,
  payload
});

export const onSetDelArchivedElement = (payload: ArchivedElementsType): ArchivedFilesActions => ({
  type: ArchivedFilesTypes.SET_DEL_ARCHIVED_ELEMENT,
  payload
});

export const onGetAllArchivedFiles =
  (controller?: AbortController): ThunkAction<void, RootState, unknown, ArchivedFilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { sort, sort_reverse, color, emo, tags, table, id_type, year, month, day } = getLocationSearchParams();

    const params = {
      uid: getState().user.uid,
      per_page: PER_PAGE,
      page: getState().Archive.page,
      emo,
      color,
      tags: tags ? tags.split(",") : undefined,
      sort_reverse,
      sort,
      group: 0,
      table: table ? table.split(",") : undefined,
      id_type: id_type ? id_type.split(",") : undefined,
      y: year || undefined,
      m: month || undefined,
      d: day || undefined
    };
    try {
      dispatch(onArhivedFileLoader(true));
      const { data } = await api.get("/ajax/archive_list.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetMyArchivedFiles(data.files));
      dispatch(onSetTotalArhivedItems(data.total));
    } catch {
      if (!controller?.signal.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onArhivedFileLoader(false));
    }
  };

export const onGetArchivedFolderFiles =
  (
    payload: { id_dir: string; pass?: string },
    controller?: AbortController
  ): ThunkAction<void, RootState, unknown, ArchivedFilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { sort, sort_reverse, color, emo, tags } = getLocationSearchParams();

    const params = {
      uid: getState().user.uid,
      per_page: PER_PAGE,
      id_dir: payload.id_dir,
      pass: payload.pass,
      page: getState().Archive.page,
      emo,
      color,
      tags: tags ? tags.split(",") : undefined,
      sort_reverse,
      sort,
      group: 0
    };
    try {
      dispatch(onArhivedFileLoader(true));
      const { data } = await api.get("/ajax/file_list.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetMyArchivedFiles(data.files));
      dispatch(onSetTotalArhivedItems(data.total));
    } catch {
      if (!controller?.signal.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onArhivedFileLoader(false));
    }
  };

export const onRestoreArchivedFiles =
  (
    element: IFile,
    payload: { id_dir?: string; id_safe?: string } & IRestoreInFolder,
    callback?: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | FoldersAction | ArchivedFilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_dir: payload.id_dir ? payload.id_dir : undefined,
      fids: [element.fid],
      add_dir_name: payload.add_dir_name,
      add_pass: payload.add_pass,
      add_id_parent: payload.add_id_parent,
      add_tags: payload.add_tags ? JSON.stringify(payload.add_tags) : undefined,
      add_color: payload.add_color,
      add_emo: payload.add_emo ? payload.add_emo : undefined,
      is_archive: 0,
      id_safe: payload.id_safe ? payload.id_safe : undefined
    };

    let isNotDirError: boolean = false;

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/${payload.id_safe ? "safe_file_archive" : "file_edit"}.php`, { params });

      isNotDirError = data.dir_ok === 0;

      if (isNotDirError) {
        dispatch(
          onSetRestoreFileModal({
            open: true,
            element
          })
        );
      }
      checkResponseStatus(data.ok);

      dispatch(onSetDelArchivedElement(element));

      data?.folders && dispatch(onSetFolders(data.folders.global, data.folders.other));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.RESTORED,
          callback,
          newPath:
            data.add_id_dir || data.add_id_parent
              ? getTheRestorePath({
                  path: ROUTES.FOLDERS,
                  id_dir: data.add_id_dir,
                  id_parent: data.add_id_parent,
                  search: `${ROUTES_SEARCH_PARAMS.byDateChanged}`
                })
              : undefined
        })
      );
    } catch {
      if (!isNotDirError) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRestoreArchivedTasks =
  (
    payload: IMyTask,
    callback: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | ArchivedFilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_task: payload.id,
      is_archive: 0
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/task_edit.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelArchivedElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.RESTORED,
          callback
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRestoreArchivedFolder =
  (
    element: IFolder,
    payload: { id_dirs?: string[]; id_parent?: string; current_id_parent?: string } & IRestoreInFolder,
    callback: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | FoldersAction | ArchivedFilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_dirs: payload.id_dirs,
      id_parent: payload.id_parent,
      add_dir_name: payload.add_dir_name,
      add_id_parent: payload.add_id_parent,
      add_tags: JSON.stringify(payload.add_tags),
      add_color: payload.add_color,
      add_emo: payload.add_emo,
      add_pass: payload.add_pass,
      is_archive: 0
    };

    let isNotDirError: boolean = false;

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/dir_edit.php`, { params });

      isNotDirError = data.dir_ok === 0;

      if (isNotDirError) {
        dispatch(
          onSetRestoreFileModal({
            open: true,
            element
          })
        );
      }
      checkResponseStatus(data.ok);

      data?.folders && dispatch(onSetFolders(data.folders.global, data.folders.other));

      dispatch(onSetDelArchivedElement(element));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.RESTORED,
          callback,
          newPath:
            data.add_id_dir || data.add_id_parent
              ? getTheRestorePath({
                  path: ROUTES.FOLDERS,
                  id_dir: data.add_id_dir,
                  id_parent: data.add_id_parent,
                  search: `${ROUTES_SEARCH_PARAMS.byDateChanged}`
                })
              : undefined
        })
      );
    } catch {
      if (!isNotDirError) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRestoreArchivedSafe =
  (
    payload: ISafe,
    callback: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | ArchivedFilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_safe: [payload.id],
      is_archive: 0
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/safe_edit.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelArchivedElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.RESTORED,
          callback
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onTrashArchivedFiles =
  (
    payload: IFile
  ): ThunkAction<void, RootState, unknown, FilesActions | FoldersAction | ArchivedFilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      fids: [payload.fid],
      is_del: 1,
      id_safe: payload.id_safe ? [payload.id_safe] : undefined
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/${payload.id_safe ? "safe_file_edit" : "file_edit"}.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelArchivedElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.IS_DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onTrashArchivedTasks =
  (payload: IMyTask): ThunkAction<void, RootState, unknown, FilesActions | ArchivedFilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_task: payload.id,
      is_del: 1
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/task_edit.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelArchivedElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.IS_DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onTrashArchivedFolder =
  (payload: IFolder): ThunkAction<void, RootState, unknown, FilesActions | ArchivedFilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_dirs: [payload.id_dir],
      is_del: 1
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/dir_edit.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelArchivedElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.IS_DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onTrashArchivedSafe =
  (payload: ISafe): ThunkAction<void, RootState, unknown, FilesActions | ArchivedFilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_safe: payload.id,
      is_del: 1
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/safe_edit.php`, { params });

      checkResponseStatus(data.ok);

      dispatch(onSetDelArchivedElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.IS_DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onChangeArchivedProject =
  (
    type: EVENT_TYPE.EDIT | EVENT_TYPE.IS_DEL,
    payload: IProject,
    callback?: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | ArchivedFilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_item: payload.id,
      is_archive: type === EVENT_TYPE.EDIT ? 0 : undefined,
      is_del: type === EVENT_TYPE.IS_DEL ? 1 : undefined
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/project_edit.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelArchivedElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: type,
          callback
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };
