import { ReactComponent as ArchiveIcon } from "assets/icons/archive_to.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder_in.svg";
import { ReactComponent as UnreadIcon } from "assets/icons/mail_unread.svg";
import { ReactComponent as PrintIcon } from "assets/icons/print.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import { ReactComponent as HeartIcon } from "assets/PrivateCabinet/greyHeart.svg";
import { ReactComponent as FlagIcon } from "assets/PrivateCabinet/mail/important.svg";
import { ReactComponent as ViewType1Icon } from "assets/PrivateCabinet/view-type1.svg";
import { ReactComponent as ViewType2Icon } from "assets/PrivateCabinet/view-type2.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { TabsType } from "generalComponents/Tabs/Tabs";
import { MailSettingsTabs, POSTBOX_MODALS } from "generalComponents/variables/mail";
import { MAIL_PARAMS } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import { ILetterInfo, IMailFolder, IPostbox } from "models/store/postbox/postbox";
import { IMoveLetter } from "models/store/postbox/postboxPayloads";
import { useState } from "react";
import React from "react";
import { useLocales } from "react-localized";
import { useParams, useSearchParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";

import styles from "./mail.module.sass";

export const useMailsTabs = () => {
  const { __ } = useLocales();
  const [params, setParams] = useSearchParams();
  const isReadFilter = params.get(`${[MAIL_PARAMS.UNREAD]}`);

  const mailTabs: TabsType[] = [
    {
      name: __("Все"),
      onClick: () => {
        params.delete(`${[MAIL_PARAMS.UNREAD]}`);
        setParams(params);
      },
      value: null
    },
    {
      name: __("Непрочитанные"),
      onClick: () => {
        params.set(`${[MAIL_PARAMS.UNREAD]}`, "true");
        setParams(params);
      },
      value: "true"
    }
  ];
  return {
    isReadFilter,
    mailTabs
  };
};

export const useMailsViewTabs = () => {
  const [params, setParams] = useSearchParams();
  const mailView = params.get(`${[MAIL_PARAMS.SPLIT]}`);

  const viewsTabs = [
    {
      icon: <ViewType1Icon fill="#3E5D56" />,
      onClick: () => {
        params.set(`${[MAIL_PARAMS.SPLIT]}`, "true");
        setParams(params);
      },
      value: "true"
    },
    {
      icon: <ViewType2Icon fill="#3E5D56" />,
      onClick: () => {
        params.delete(`${[MAIL_PARAMS.SPLIT]}`);
        setParams(params);
      },
      value: null
    }
  ];
  return {
    viewsTabs,
    mailView
  };
};

export const useMailSettingTabs = () => {
  const { __ } = useLocales();
  const [tabItem, setTabItem] = useState<string>(MailSettingsTabs.ACCOUNTS);
  const tabs = [
    {
      name: __("Аккаунты"),
      onClick: () => setTabItem(MailSettingsTabs.ACCOUNTS),
      value: MailSettingsTabs.ACCOUNTS
    },
    {
      name: __("Активные сессии"),
      onClick: () => setTabItem(MailSettingsTabs.SESSIONS),
      value: MailSettingsTabs.SESSIONS
    },
    {
      name: __("Заблокированные устройства"),
      onClick: () => setTabItem(MailSettingsTabs.BLOCKED_DEVICE),
      value: MailSettingsTabs.BLOCKED_DEVICE
    },
    {
      name: __("Заблокированные пользователи"),
      onClick: () => setTabItem(MailSettingsTabs.BLOCKED_USER),
      value: MailSettingsTabs.BLOCKED_USER
    }
  ];

  return {
    tabItem,
    tabs
  };
};

export const useContextPostboxFolder = () => {
  const { __ } = useLocales();
  const { onSetPostboxModal, onSetApproveModal, onRemoveFolderPostbox } = useActions();

  return {
    contextPostboxFolder: (folder: IMailFolder, postbox: IPostbox): IPopoverData[] => [
      {
        name: __("Редактировать"),
        img: <EditIcon />,
        isSystem: true,
        callback: (e): void => {
          e.stopPropagation();
          onSetPostboxModal({ open: true, type: POSTBOX_MODALS.EDIT_FOLDER, folder, postbox });
        }
      },
      {
        name: __("Удалить"),
        img: <TrashIcon />,
        isSystem: true,
        callback: (e): void => {
          e.stopPropagation();
          const messages = {
            success: __("Папка удалена из Вашего списка"),
            error: __("Что-то пошло не так! Попробуйте еще раз")
          };
          const payload = { email: postbox.email, pass: postbox.pass, folder: folder.orig };
          const params = {
            title: __("Удалить папку"),
            text: __("Вы действительно хотите удалить папку из своего списка?"),
            approveBtn: __("Удалить"),
            callback: (): void => {
              onRemoveFolderPostbox(payload, messages);
            }
          };
          onSetApproveModal({ open: true, params });
        }
      }
    ]
  };
};

export const useContextLetter = () => {
  const { __ } = useLocales();
  const {
    onChangeStandartFlagMail,
    onChangeCustomFlagMail,
    onMoveLetter,
    onMoveToSpan,
    onNextAction,
    onSetPostboxModal,
    onSetSearchLetter
  } = useActions();
  const { searchLetter } = usePostboxSelectors();
  const { folderName } = useParams();
  const errorMsg = __("Что-то пошло не так. Попробуйте позже!");

  const clearSearch = (uids: number[]) => {
    if (searchLetter && !uids.includes(searchLetter.uid)) return;
    onSetSearchLetter(null);
  };

  const unread = (mail: ILetterInfo, postbox: IPostbox): IPopoverData => ({
    name: __("Отметить как непрочитанное"),
    img: <UnreadIcon />,
    isSystem: true,
    callback: (e): void => {
      e.stopPropagation();
      const val = mail.seen ? "0" : "1";
      const payload = {
        data: {
          user: postbox.email,
          pass: postbox.pass,
          server: postbox.server,
          data: JSON.stringify([
            {
              folder: mail.folder,
              message_nums: mail.uid,
              flag: "seen",
              flag_value: val,
              message_id: mail.message_id
            }
          ])
        },
        value: { flag: "seen", val, folder: mail.folder }
      };
      onChangeStandartFlagMail(payload, errorMsg);
    }
  });
  const archive = (mails: ILetterInfo[], postbox: IPostbox): IPopoverData => ({
    name: __("В Архив"),
    img: <ArchiveIcon />,
    isSystem: true,
    callback: (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      clearSearch(mails.map((l) => l.uid));
      const payload = {
        user: postbox.email,
        pass: postbox.pass,
        server: postbox.server,
        data: JSON.stringify(
          mails.map((mail) => ({
            folder: mail.folder,
            folder_new: POSTBOX_SYSTEM_FOLDER.ARCHIVE,
            message_nums: mail.uid
          }))
        )
      };
      onMoveLetter(payload, errorMsg);
    }
  });
  const folderIn = (mails: ILetterInfo[], postbox: IPostbox, folders: IMailFolder[]): IPopoverData => ({
    name: __("В Папку"),
    img: <FolderIcon />,
    isSystem: true,
    next: folders.map((el) => ({
      name: el.name,
      callback: (e): void => {
        e.stopPropagation();
        clearSearch(mails.map((l) => l.uid));

        const payload = {
          user: postbox.email,
          pass: postbox.pass,
          server: postbox.server,
          data: JSON.stringify(
            mails.map((mail) => ({ folder: mail.folder, folder_new: el.orig, message_nums: mail.uid }))
          )
        };
        onMoveLetter(payload, errorMsg);
      }
    })),
    callback: (): void => {}
  });

  const favorite = (mails: ILetterInfo[], postbox: IPostbox): IPopoverData => ({
    name: __("В Избранное"),
    img: (
      <HeartIcon
        className={classNames(styles.flag, { [styles.active]: mails.every((mail) => mail.is_liked) })}
        width={16}
        height={16}
      />
    ),
    isSystem: true,
    callback: (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      const val = mails.every((mail) => mail.is_liked === 1) ? "0" : "1";
      const data = mails.map((mail) => ({
        folder: mail.folder,
        message_nums: mail.uid,
        is_liked: val,
        message_id: mail.message_id
      }));
      const payload = {
        data: {
          user: postbox.email,
          pass: postbox.pass,
          server: postbox.server,
          data: JSON.stringify(data)
        },
        value: { flag: "is_liked", val }
      };
      onChangeCustomFlagMail(payload, errorMsg);
    }
  });

  const important = (mails: ILetterInfo[], postbox: IPostbox): IPopoverData => ({
    name: __("В Важное"),
    img: <FlagIcon className={classNames(styles.flag, { [styles.active]: mails.every((el) => el.flagged) })} />,
    isSystem: true,
    callback: (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      const val = mails.every((el) => el.flagged) ? "0" : "1";
      const payload = {
        data: {
          user: postbox.email,
          pass: postbox.pass,
          server: postbox.server,
          data: JSON.stringify(
            mails.map((mail) => ({
              folder: mail.folder,
              message_nums: mail.uid,
              flag: "flagged",
              flag_value: val,
              message_id: mail.message_id
            }))
          )
        },
        value: { flag: "flagged", val }
      };
      onChangeStandartFlagMail(payload, errorMsg);
    }
  });

  const print = (mail: ILetterInfo): IPopoverData => ({
    name: __("Печать"),
    img: <PrintIcon />,
    isSystem: true,
    callback: (e): void => {
      e.stopPropagation();
      onSetPostboxModal({ open: true, type: POSTBOX_MODALS.MAIL_PRINT, letter: mail });
    }
  });

  const spam = (mail: ILetterInfo, postbox: IPostbox): IPopoverData => ({
    name: __("В Спам"),
    img: <WarningIcon />,
    isSystem: true,
    callback: (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      clearSearch([mail.uid]);

      const params = {
        title: __("В Спам"),
        text: `${__("Сообщение будет помечено как спам. Хотите заблокировать отправителя ")} ${mail.from}?`,
        buttons: [
          <Button
            key="1"
            onClick={() => onNextAction({ open: false, params: null })}
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.MEDIUM}
            text={__("Отменить")}
          />,
          <Button
            key="2"
            onClick={() => {
              const payload = {
                user: postbox.email,
                pass: postbox.pass,
                server: postbox.server,
                data: JSON.stringify([
                  { folder: mail.folder, folder_new: POSTBOX_SYSTEM_FOLDER.Spam, message_nums: mail.uid }
                ])
              };
              onMoveLetter(payload, errorMsg);
              onNextAction({ open: false, params: null });
            }}
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.MEDIUM}
            text={__("В спам")}
          />,
          <Button
            key="3"
            onClick={() => {
              onMoveToSpan(mail.from, postbox, errorMsg);
              onNextAction({ open: false, params: null });
            }}
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.MEDIUM}
            text={__("В спам и заблокировать")}
          />
        ]
      };
      onNextAction({ open: true, params });
    }
  });
  const onDelete = (mails: ILetterInfo[], postbox: IPostbox): IPopoverData => ({
    name: __("В Спам"),
    img: <TrashIcon />,
    isSystem: true,
    callback: (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      clearSearch(mails.map((l) => l.uid));

      const payload: IMoveLetter = {
        user: postbox.email,
        pass: postbox.pass,
        server: postbox.server,
        data: JSON.stringify(
          mails.map((mail) => ({
            folder: mail.folder,
            folder_new: POSTBOX_SYSTEM_FOLDER.Trash,
            message_nums: mail.uid
          }))
        )
      };
      const errorMsg = __("Что-то пошло не так. Попробуйте позже!");

      onMoveLetter(payload, errorMsg);
    }
  });

  const getList = (mail: ILetterInfo, postbox: IPostbox): IPopoverData[] => {
    const other = postbox.folders.custom.filter((el) => el.orig !== folderName);
    const arr = [];
    if (mail.folder !== POSTBOX_SYSTEM_FOLDER.Sent && mail.seen) arr.push(unread(mail, postbox));
    if (folderName !== POSTBOX_SYSTEM_FOLDER.ARCHIVE) arr.push(archive([mail], postbox));
    if (other?.length > 0) arr.push(folderIn([mail], postbox, other));
    arr.push(favorite([mail], postbox));
    arr.push(important([mail], postbox));
    arr.push(print(mail));
    if (mail.folder === POSTBOX_SYSTEM_FOLDER.INBOX) arr.push(spam(mail, postbox));
    return arr;
  };

  const getActions = (mails: ILetterInfo[], postbox: IPostbox): IPopoverData[] => {
    const other = postbox.folders.custom.filter((el) => el.orig !== folderName);
    const arr = [];
    if (folderName !== POSTBOX_SYSTEM_FOLDER.ARCHIVE) arr.push(archive(mails, postbox));
    if (other?.length > 0) arr.push(folderIn(mails, postbox, other));
    arr.push(favorite(mails, postbox));
    arr.push(important(mails, postbox));
    arr.push(onDelete(mails, postbox));
    return arr;
  };

  return {
    contextLetter: (mail: ILetterInfo, postbox: IPostbox): IPopoverData[] => getList(mail, postbox),
    actionsLetter: (mails: ILetterInfo[], postbox: IPostbox): IPopoverData[] => getActions(mails, postbox)
  };
};
