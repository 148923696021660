import { ProjectLibraryElementsList } from "containers/JoinProject/JoinProjectLibrary/components/Library/ProjectLibraryElementsList/ProjectLibraryElementsList";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { LibraryCategoryTypes } from "generalComponents/variables/library";
import { LIBRARY_ROUTES, PROJECT_PATH, ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPath } from "hooks/useCurrentPath";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ProjectLibraryCategoryPage = () => {
  const { id, libraryDirId } = useParams();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const isLibraryCategoriesPage = useCurrentPath(
    `${ROUTES.PROJECT}/${id}/${PROJECT_PATH.LIBRARY}/${LIBRARY_ROUTES.CATEGORIES}`
  );

  const { onGetProjectLibraryElements, onResetProjectLibraryFolders } = useActions();

  const {
    objSearchParams: { chapter, search_query, year, month }
  } = useSelectedObjSearchParams();

  useEffect(() => {
    const controllerFolders = new AbortController();

    onResetProjectLibraryFolders();

    if (chapter && !search_query) {
      onGetProjectLibraryElements(controllerFolders);
    }

    if (!isNaN(Number(libraryDirId))) {
      onGetProjectLibraryElements(controllerFolders, { is_dir: 0, id_dir: libraryDirId });
    }

    return () => {
      controllerFolders.abort();
    };
  }, [chapter, search_query, year, month, libraryDirId]); //eslint-disable-line

  useEffect(() => {
    if (isLibraryCategoriesPage && !chapter) {
      const path = `${pathname}?${[SEARCH_PARAMS.CHAPTER]}=${LibraryCategoryTypes.EDUCATION}`;

      navigate(path, { state: { from: `${ROUTES.PROJECT}/${id}/${PROJECT_PATH.LIBRARY}` } });
    }

    return () => {
      onResetProjectLibraryFolders();
    };
  }, []); //eslint-disable-line

  return <ProjectLibraryElementsList />;
};

export default ProjectLibraryCategoryPage;
