import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";
import { ISharedState } from "models/store/shared/shared";

export const useSharedSelectors = (): ISharedState => {
  const shared = useTypeSelector((s) => s.Shared.shared);

  return {
    shared
  };
};
