import { ReactComponent as AddIcon } from "assets/PrivateCabinet/add-2.svg";
import classNames from "classnames";
import Note from "generalComponents/Note/Note";
import { TASK_MODALS, TASK_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { IMyTask } from "models/store/tasks/tasks";
import React, { useMemo } from "react";
import { useLocales } from "react-localized";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { INITIAL_ADD_TASK } from "utils/tasks";

import styles from "./Notes.module.sass";

const Notes: React.FC = (): JSX.Element => {
  const { __ } = useLocales();
  const { myTasks } = useTasksSelectors();
  const {
    userInfo: { theme }
  } = useUserSelectors();
  const { onSetTaskModal } = useActions();

  const notes: IMyTask[] = useMemo(() => myTasks.filter((item) => item.id_type === TASK_TYPES.NOTES), [myTasks]);

  const onAddNote = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.ADD_NOTE,
      params: INITIAL_ADD_TASK
    });
  };

  return (
    <div className={classNames(styles.notesWrap, `scrollbar-thin-${theme}`)}>
      <button type="button" className={styles.emptyNote} onClick={onAddNote}>
        <AddIcon />
        <span className={styles.text}>{__("Создать заметку")}</span>
        <div className={styles.corner} />
      </button>
      {myTasks.length > 0 && notes.map((note) => <Note key={note.id} note={note} />)}
    </div>
  );
};

export default Notes;
