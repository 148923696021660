import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { setStorageItem } from "generalComponents/Services/storageServices";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { FoldersAction } from "models/store/folders/foldersActions";
import { IGetProjectFoldersDTO } from "models/store/projects/projectPayloads";
import {
  ICategoryProject,
  ICommentTask,
  ILinkProject,
  IProject,
  ITaskProject,
  IUserProject,
  ProjectAction
} from "models/store/projects/projectStore";
import {
  IAddDocs,
  IDelDocs,
  IDeleteCategory,
  IEditDocs,
  IMoveTaskTrash,
  IPopupMessages,
  ITopMessages
} from "models/store/projects/projectThunk";
import { ThunkAction } from "redux-thunk";
import { ProjectTypes } from "Store/types/projectTypes";

import { CabinetActions } from "../../models/store/Cabinet/Cabinet";
import { RootState } from "../reducers";
import { onSetErrorModal, onSetLoaderModal, onSetModals, onSetTopMessageModal } from "./ModalActions";

export const onFetchProjectsList =
  (message?: string, isLoader?: boolean): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      isLoader && dispatch(onSetLoaderModal(true));

      const { data } = await api.get(`/ajax/project_list.php`, { params: { uid: getState().user.uid } });
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.GET_PROJECTS, payload: data.projects ?? [] });
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: message
        })
      );
    } finally {
      isLoader && dispatch(onSetLoaderModal(false));
    }
  };

export const onAddProject =
  (params: IProject, messages: ITopMessages): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      let payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("name", params.name);
      payload.append("tag", params.tags);
      payload.append("symbol", params.id_fig);
      payload.append("emoji", params.id_emo);
      payload.append("icon", params.icon);
      payload.append("show_message", "0");

      const { data } = await api.post(`/ajax/project_add.php`, payload);
      checkResponseStatus(data.ok);
      setStorageItem("projectId", data.project.id);
      dispatch({ type: ProjectTypes.ADD_PROJECT, payload: data.project });
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.NO_MODAL,
          params: null
        })
      );
    }
  };

// export const onEditProject =
//   (
//     params: IProject,
//     messages: ITopMessages,
//     callback: string
//   ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
//   async (dispatch, getState) => {
//     try {
//       dispatch(onSetLoaderModal(true));

//       const payload = new FormData();
//       payload.append("uid", getState().user.uid);
//       payload.append("name", params.name);
//       payload.append("tag", params.tags);
//       payload.append("symbol", params.id_fig);
//       payload.append("emoji", params.id_emo);
//       payload.append("id_project", params.id);
//       payload.append("roles", JSON.stringify(params.roles));
//       payload.append("icon", params.icon);
//       payload.append("show_message", params.show_message);
//       payload.append("tasks_category_sort", JSON.stringify(params.tasks_category_sort));

//       const { data } = await api.post(`/ajax/project_edit.php`, payload);
//       checkResponseStatus(data.ok);
//       const project_roles = getState().Projects.project.roles;

//       const newRoles = data.project.roles.filter(
//         (item: IRoleProject) => project_roles.findIndex((el: IRoleProject) => el.id === item.id) === -1
//       );
//       dispatch({ type: ProjectTypes.EDIT_PROJECT, payload: data.project });
//       if (callback === PROJECT_MODALS.ADDED_ROLES) {
//         dispatch(
//           onSetModals(MODALS.PROJECT, {
//             type: callback,
//             params: newRoles
//           })
//         );
//       } else if (messages.success) {
//         dispatch(
//           onSetModals(MODALS.TOP_MESSAGE, {
//             open: true,
//             type: "success",
//             message: messages.success
//           })
//         );
//       }
//     } catch {
//       dispatch(
//         onSetModals(MODALS.ERROR, {
//           open: true,
//           message: messages?.error
//         })
//       );
//     } finally {
//       dispatch(onSetLoaderModal(false));
//       dispatch(
//         onSetModals(MODALS.PROJECT, {
//           type: PROJECT_MODALS.NO_MODAL,
//           params: null
//         })
//       );
//     }
//   };

export const onDeleteProject =
  (id_project: string, message: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const uid = getState().user.uid;
      const { data } = await api.delete(`/ajax/project_del.php`, { params: { uid, id_project } });
      checkResponseStatus(data.ok);
      setStorageItem("projectId", "");
      dispatch({ type: ProjectTypes.DELETE_PROJECT, payload: data.project.id });

      dispatch(onSetModals(MODALS.PROJECT, { type: MODALS.NO_MODAL, params: null }));
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: message
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onAppointRoles =
  (users: IUserProject[], messages: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const usersResult = await Promise.all(
        users.map(async (user) => {
          const payload = new FormData();
          payload.append("uid", getState().user.uid);
          payload.append("id_project", getState().Projects.project.id);
          payload.append("id_user", user.id_user);
          payload.append("is_read", user.is_read);
          payload.append("id_role", user.id_role);

          const { data } = await api.post(`/ajax/project_user_add.php`, payload);

          return data.user_add;
        })
      );

      dispatch({ type: ProjectTypes.ADD_USER, payload: usersResult });

      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.ADDED_USERS, params: usersResult }));
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onEditUser =
  (
    users: IUserProject[],
    messages: IPopupMessages,
    callback: string
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const result = await Promise.all(
        users.map(async (user) => {
          const payload = new FormData();
          payload.append("uid", getState().user.uid);
          payload.append("id_project", getState().Projects.project.id);
          payload.append("id_user", user.id_user);
          payload.append("is_read", user.is_read);
          payload.append("id_role", user.id_role);
          const { data } = await api.post(`/ajax/project_user_edit.php`, payload);
          return data.user_add;
        })
      );
      dispatch({ type: ProjectTypes.EDIT_USER, payload: result });
      (callback === PROJECT_MODALS.ADDED_USERS || callback === PROJECT_MODALS.ADDED_ADMIN) &&
        dispatch(onSetModals(MODALS.PROJECT, { type: callback, params: result }));

      callback === PROJECT_MODALS.PROJECT_SUCCESS &&
        dispatch(onSetModals(MODALS.PROJECT, { type: callback, params: messages.success }));
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onDeleteUser =
  (
    params: IUserProject,
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      messages && dispatch(onSetLoaderModal(true));

      const uid = getState().user.uid;
      const { data } = await api.delete(`/ajax/project_user_del.php`, {
        params: { uid, id_project: getState().Projects.project.id, id_user: params.id_user }
      });
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.DELETE_USER, payload: params.id_user });
      messages &&
        dispatch(
          onSetModals(MODALS.TOP_MESSAGE, {
            open: true,
            type: "success",
            message: messages.success
          })
        );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages?.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
      messages &&
        dispatch(
          onSetModals(MODALS.PROJECT, {
            type: PROJECT_MODALS.NO_MODAL,
            params: null
          })
        );
    }
  };

export const onAddProjectTask =
  (
    params: ITaskProject,
    messages: ITopMessages,
    tasksChildren: string[],
    uploadFiles: File[]
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("name", params.name);
      payload.append("tag", params.tags);
      payload.append("prim", params.prim);
      payload.append("date_start", params.date_start);
      payload.append("date_end", params.date_end);
      payload.append("id_act", params.id_act);
      payload.append("id_executor", JSON.stringify(params.id_executor));
      payload.append("id_parent", params.id_parent);
      payload.append("id_project", params.id_project);
      payload.append("author", params.author);
      payload.append("fids", JSON.stringify(params.fids));
      payload.append("is_epic", String(params.is_epic));
      payload.append("id_epic", params.id_epic);
      payload.append("is_backlog", String(params.is_backlog));

      const { data } = await api.post(`/ajax/task_add.php`, payload);
      checkResponseStatus(data.ok);

      if (tasksChildren?.length > 0) {
        let editTasks: ITaskProject[] = [];
        await Promise.all(
          tasksChildren.map(async (id) => {
            const task = getState().Projects.project.tasks.find((t: ITaskProject) => t.id === id);
            const payload = new FormData();
            payload.append("uid", getState().user.uid);
            payload.append("name", task ? task.name : "");
            payload.append("id_task", id);
            payload.append("id_epic", data.task.id);
            const response = await api.post(`/ajax/task_edit.php`, payload);
            return (editTasks = response.data.project_tasks);
          })
        );
        dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: editTasks });
      } else {
        dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: data.project_tasks });
      }

      if (uploadFiles?.length > 0) {
        await Promise.all(
          uploadFiles.map(async (file) => {
            const payload = new FormData();
            payload.append("uid", getState().user.uid);
            payload.append("myfile", file);
            payload.append("id_task", data.task.id);
            payload.append("fileName", `${file.name.slice(0, file.name.lastIndexOf("."))}`);

            const response = await api.post(`/ajax/task_file_add.php`, payload);
            checkResponseStatus(response.data.ok);
          })
        );
      }
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.NO_MODAL,
          params: null
        })
      );
    }
  };

export const onEditProjectTask =
  (
    params: ITaskProject,
    messages: ITopMessages,
    uploadFiles: File[]
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("id_task", params.id);
      payload.append("sort", params.sort);
      payload.append("name", params.name);
      payload.append("tag", params.tags);
      payload.append("prim", params.prim);
      payload.append("date_start", params.date_start);
      payload.append("date_end", params.date_end);
      payload.append("id_act", params.id_act);
      payload.append("id_executor", JSON.stringify(params.id_executor));
      payload.append("id_parent", params.id_parent);
      payload.append("id_project", params.id_project);
      payload.append("author", params.author);
      payload.append("date_long", params.date_long);
      payload.append("fids", JSON.stringify(params.fids));
      payload.append("id_epic", params.id_epic);
      payload.append("id_category", params.id_category);

      if (uploadFiles?.length > 0) {
        await Promise.all(
          uploadFiles.map(async (file) => {
            const payload = new FormData();
            payload.append("uid", getState().user.uid);
            payload.append("myfile", file);
            payload.append("id_task", params.id);
            payload.append("fileName", `${file.name.slice(0, file.name.lastIndexOf("."))}`);

            const response = await api.post(`/ajax/task_file_add.php`, payload);
            checkResponseStatus(response.data.ok);
          })
        );
      }

      const { data } = await api.post(`/ajax/task_edit.php`, payload);
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: data.project_tasks });
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.NO_MODAL,
          params: null
        })
      );
    }
  };

export const onMoveToArchiv =
  (params: ITaskProject, message: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("id_task", params.id);
      payload.append("name", params.name);
      payload.append("is_archive", String(1));

      const { data } = await api.post(`/ajax/task_edit.php`, payload);
      dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: data.project_tasks });
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: message
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onDeleteProjectTask =
  (payload: string[], messages: ITopMessages): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.delete(`/ajax/task_del.php`, {
        params: {
          uid: getState().user.uid,
          id_project: getState().Projects.project.id,
          id_task: payload
        }
      });

      dispatch({ type: ProjectTypes.DELETE_PROJECT_TASK, payload: data.id_task });
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onMoveTrashTask =
  (
    params: IMoveTaskTrash,
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("id_task", JSON.stringify(params.id_task));

      const { data } = await api.post(`/ajax/task_moveto_trash.php`, payload);
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: data.project_tasks });
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(onSetModals(MODALS.ERROR, { open: true, message: messages.error }));
    } finally {
      dispatch(onSetLoaderModal(false));
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.NO_MODAL,
          params: null
        })
      );
    }
  };

export const onMoveToBacklog =
  (
    tasks: ITaskProject[],
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      await Promise.all(
        tasks.map(async (task) => {
          const params = {
            uid: getState().user.uid,
            id_task: task.id,
            name: task.name,
            is_backlog: "0",
            is_del: "0"
          };

          const { data } = await api.get(`/ajax/task_edit.php`, { params });
          dispatch({
            type: ProjectTypes.RESTORE_TASK,
            payload: { project_tasks: data.project_tasks, restore_tasks: [task.id] }
          });
        })
      );

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onChangeStatusTask =
  (params: ITaskProject, message: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("id_task", params.id);
      payload.append("name", params.name);
      payload.append("sort", params.sort);
      payload.append("id_category", params.id_category);
      payload.append("id_act", params.id_act);

      const { data } = await api.post(`/ajax/task_edit.php`, payload);
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: data.project_tasks });
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: message
        })
      );
    }
  };

export const onAddCategory =
  (
    params: ICategoryProject,
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("name", params.name);
      payload.append("color", JSON.stringify(params.color));
      payload.append("id_project", getState().Projects.project.id);

      const { data } = await api.post(`/ajax/task_category_add.php`, payload);

      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.ADD_CATEGORY, payload: data.tasks_category });
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.NO_MODAL,
          params: null
        })
      );
    }
  };

export const onEditCategory =
  (
    params: ICategoryProject,
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("name", params.name);
      payload.append("id_category", params.id);
      payload.append("color", JSON.stringify(params.color));

      const { data } = await api.post(`/ajax/task_category_edit.php`, payload);
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.EDIT_CATEGORY, payload: data.tasks_category });
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.NO_MODAL,
          params: null
        })
      );
    }
  };

export const onDeleteCategory =
  (params: IDeleteCategory, message: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const uid = getState().user.uid;

      if (params.id_tasks.length > 0) {
        const payloadTask = new FormData();
        payloadTask.append("uid", getState().user.uid);
        payloadTask.append("id_task", JSON.stringify(params.id_tasks));
        const responseTask = await api.post(`/ajax/task_moveto_trash.php`, payloadTask);
        checkResponseStatus(responseTask.data.ok);
        dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: responseTask.data.project_tasks });
      }

      const { data } = await api.delete(`/ajax/task_category_del.php`, {
        params: { uid, id_category: params.id_category }
      });

      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.DELETE_CATEGORY, payload: params.id_category });

      dispatch(onSetModals(MODALS.PROJECT, { type: MODALS.NO_MODAL, params: null }));
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: message
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const handleTaskComment =
  (
    type: string,
    payload: ICommentTask,
    message: string
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      let params = new FormData();
      params.append("uid", getState().user.uid);
      params.append("text", payload.text);
      params.append("id_task", payload.id_task);
      params.append("id_com", payload.id);
      params.append("id_project", getState().Projects.project.id);
      const { data } = await api.post(`/ajax/task_com_${type}.php`, params);
      checkResponseStatus(data.ok);
      dispatch({
        type: ProjectTypes.UPDATE_TASKS,
        payload: data.project_tasks
      });
    } catch {
      dispatch(onSetModals(MODALS.ERROR, { open: true, message: message }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onFetchTrashTasks =
  (message: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = { uid: getState().user.uid, id_project: getState().Projects.project.id };
      const { data } = await api.get(`/ajax/trash_task_list.php`, { params });
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.FETCH_TASKS_TRASH, payload: data.tasks ? data.tasks : [] });
    } catch {
      dispatch(onSetErrorModal({ open: true, message: message }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRestoreTask =
  (params: string[], message: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("id_task", JSON.stringify(params));
      const { data } = await api.post(`/ajax/task_movefrom_trash.php`, payload);
      checkResponseStatus(data.ok);
      dispatch({
        type: ProjectTypes.RESTORE_TASK,
        payload: { project_tasks: data.project_tasks, restore_tasks: params }
      });
    } catch {
      dispatch(onSetErrorModal({ open: true, message: message }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onClearTrash =
  (message: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = { uid: getState().user.uid, id_project: getState().Projects.project.id };

      const { data } = await api.get(`/ajax/project_clear_trash.php`, { params });
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.CLEAR_TRASH });

      dispatch(onSetModals(MODALS.PROJECT, { type: MODALS.NO_MODAL, params: null }));
    } catch {
      dispatch(onSetModals(MODALS.ERROR, { open: true, message: message }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onEditBacklogTask =
  (
    params: ITaskProject,
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("id_task", params.id);
      payload.append("name", params.name);
      payload.append("is_backlog", String(params.is_backlog));
      payload.append("is_del", "0");

      const { data } = await api.post(`/ajax/task_edit.php`, payload);
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: data.project_tasks });
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.NO_MODAL,
          params: null
        })
      );
    }
  };

export const onMoveToTasks =
  (
    tasks: string[],
    role: string,
    message: string
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = new FormData();
      params.append("uid", getState().user.uid);
      params.append("id_tasks", JSON.stringify(tasks));
      params.append("is_backlog", role);

      const { data } = await api.post("/ajax/task_set_backlog.php", params);
      dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: data.project_tasks });
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: message
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

// TODO - mk - fixed when adding files to project
export const onSendFile =
  (file: any, msg: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const uid = getState().user.uid;
      const project = getState().Projects.project;

      dispatch(onSetLoaderModal(true));
      const newFIle = file.preview.replace("image/png", "image/octet-stream");
      const blob = new Blob([newFIle], { type: "image/png" });
      let data = new FormData();
      data.append("uid", uid);
      data.append("myfile", blob);
      data.append("fileName", `${project.name}_`);
      data.append("tag", "");
      data.append("pass", "");
      data.append("color", "");
      data.append("symbol", "");
      data.append("emoji", "");
      data.append("id_project", project.id);
      data.append("dir", "");
      const response = await api.post(`/ajax/project_file_add.php`, data);
      checkResponseStatus(response.data.ok);

      dispatch(onSetModals(MODALS.FILE_PREVIEW, { open: false, file: null, message: null }));

      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.PROJECT_SUCCESS, params: msg }));
    } catch (e) {
      console.log(e);
    } finally {
      //
      //
      //   dispatch(onSetLoaderModal(false));
    }
  };

export const onFetchLinks =
  (message: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        id_project: getState().Projects.project.id
      };
      const response = await api.get("/ajax/project_links_list.php", { params });
      checkResponseStatus(response.data.ok);
      dispatch({ type: ProjectTypes.GET_LINKS, payload: response.data.data.project_links });
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: message
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const handlerLinks =
  (
    payload: ILinkProject,
    endpoint: string,
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        ...payload,
        uid: getState().user.uid,
        id_project: getState().Projects.project.id,
        id_link: payload.id
      };
      const { data } = await api.get(`/ajax/project_links_${endpoint}.php`, { params });
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.GET_LINKS, payload: data.data.project_links });
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.NO_MODAL,
          params: null
        })
      );
    }
  };

export const onDeleteLinks =
  (payload: string[], messages: ITopMessages): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        ...payload,
        uid: getState().user.uid,
        id_project: getState().Projects.project.id,
        id_link: payload.join(",")
      };
      const { data } = await api.get(`/ajax/project_links_del.php`, { params });
      checkResponseStatus(data.ok);
      dispatch({ type: ProjectTypes.GET_LINKS, payload: data.data.project_links });
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };
export const fetchProjectFiles =
  (idDir: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        id_project: getState().Projects.project.id,
        id_dir: idDir,
        sort: "byName"
      };
      const { data } = await api.get("/ajax/project_file_list.php", { params });
      checkResponseStatus(data.ok);
    } catch {
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "error",
          message: "Error"
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const deleteProjectFiles =
  (
    path: string,
    fids: string[],
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_project: getState().Projects.project.id,
        dir: path,
        fids
      };

      const { data } = await api.get(`/ajax/project_file_del.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
      dispatch(
        onSetModals(MODALS.APPROVE, {
          open: false,
          params: null
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "error",
          message: "Error"
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onFetchDocs =
  (message: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        id_project: getState().Projects.project.id
      };
      const response = await api.get("/ajax/project_docs_list.php", { params });
      checkResponseStatus(response.data.ok);
      dispatch({ type: ProjectTypes.FETCH_DOCS, payload: response.data.project_docs });
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: message
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onAddDocs =
  (
    payload: IAddDocs[],
    messages: ITopMessages,
    cb: () => void
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const addResult = await Promise.all(
        payload.map(async (doc) => {
          const params = new FormData();
          params.append("uid", getState().user.uid);
          params.append("id_project", getState().Projects.project.id);
          params.append("is_template", String(doc.is_template));
          params.append("name", doc.name);
          params.append("data", doc.data);
          params.append("numberedData", doc.numberedData);
          params.append("inputs", doc.inputs);
          params.append("footer", String(doc.footer));

          const { data } = await api.post(`/ajax/project_docs_add.php`, params);
          checkResponseStatus(data.ok);
          return data.project_docs;
        })
      );

      dispatch({ type: ProjectTypes.FETCH_DOCS, payload: addResult[addResult.length - 1] });
      cb();
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onDelDocs =
  (
    payload: IDelDocs[],
    messages: ITopMessages,
    cb: () => void
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const delResult = await Promise.all(
        payload.map(async (el) => {
          const params = {
            uid: getState().user.uid,
            id_project: getState().Projects.project.id,
            id_doc: el.id_doc
          };
          const { data } = await api.get(`/ajax/project_docs_del.php`, { params });

          return data.project_docs;
        })
      );
      dispatch({ type: ProjectTypes.FETCH_DOCS, payload: delResult[delResult.length - 1] });
      cb();
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onEditDocs =
  (
    payload: IEditDocs[],
    messages: ITopMessages,
    cb: () => void
  ): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const editResult = await Promise.all(
        payload.map(async (doc) => {
          const params = new FormData();
          params.append("uid", getState().user.uid);
          params.append("id_project", getState().Projects.project.id);
          params.append("id_doc", doc.id);
          params.append("data", doc.data);
          params.append("name", doc.name);
          const { data } = await api.post(`/ajax/project_docs_edit.php`, params);

          return data.project_docs;
        })
      );
      dispatch({ type: ProjectTypes.FETCH_DOCS, payload: editResult[editResult.length - 1] });
      cb();
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: "success",
          message: messages.success
        })
      );
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onFetchTrashFiles =
  (message: string): ThunkAction<void, RootState, unknown, ProjectAction | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const params = {
        uid: getState().user.uid,
        id_project: getState().Projects.project.id
      };
      const responseFolder = await api.get(`/ajax/project_folders_trash.php`, { params });
      const responseFile = await api.get(`/ajax/project_file_trash.php`, { params });
      checkResponseStatus(responseFolder.data.ok);
      dispatch({ type: ProjectTypes.FETCH_FOLDERS_TRASH, payload: responseFolder.data.folders });
      dispatch({ type: ProjectTypes.FETCH_FILES_TRASH, payload: responseFile.data });
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR,
          message: message
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };
export const getProjectFolders =
  (message: string): ThunkAction<void, RootState, unknown, FoldersAction | CabinetActions> =>
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        id_project: getState().Projects.project.id
      };
      const { data } = await api.get<IGetProjectFoldersDTO>("/ajax/project_folders_list.php", { params });
      checkResponseStatus(data.ok);
    } catch {
      dispatch(
        onSetErrorModal({
          open: true,
          message: message
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };
