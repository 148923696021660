import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classNames from "classnames";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useFindTeam } from "hooks/joinProjectHooks";
import { FC, MouseEvent, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./TaskTeam.module.sass";

interface IProps {
  team: string;
  setTeam: (val: string) => void;
  executor: string;
  setExecutor: (val: string) => void;
  readOnly?: boolean;
}

const TaskTeam: FC<IProps> = ({ team, setTeam, executor, setExecutor, readOnly = false }) => {
  const { __ } = useLocales();
  const [show, setShow] = useState<boolean>(false);
  const { project } = useJoinProjectsSelectors();

  const findTeam = useFindTeam();

  const handleChange = (value: string): void => {
    const _team = findTeam(value);
    if (_team && !_team.users.includes(executor)) {
      setExecutor("");
    }

    setTeam(value);
    setShow(false);
  };

  const renderOverlay = () => {
    return (
      <ul>
        <li className={styles.item} onClick={() => handleChange("")}>
          <span>{__("Не выбрано")}</span>
        </li>
        {project.teams.map((item) => (
          <li key={item.id} className={styles.item} onClick={() => handleChange(item.id)}>
            <span>{item.name}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <PopoverOverlay show={show} overlayContent={renderOverlay()} setShow={setShow} placement="bottom" btn="extra">
      <div
        className={classNames(styles.label, { [styles.active]: show })}
        onClick={(e: MouseEvent<HTMLParagraphElement>) => {
          e.stopPropagation();
          !readOnly && setShow(true);
        }}
      >
        <span>{findTeam(team)?.name ?? __("Не выбрано")}</span>
        <ArrowIcon className={classNames(styles.arrow, { [styles.arrowOpen]: show })} />
      </div>
    </PopoverOverlay>
  );
};

export default TaskTeam;
