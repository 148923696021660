import { ReactComponent as FolderIcon } from "assets/icons/folder_2.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import { ReactComponent as SmileIcon } from "assets/PrivateCabinet/smile-happy.svg";
import { ReactComponent as NeutralIcon } from "assets/PrivateCabinet/smile-neutral.svg";
import { ReactComponent as SadIcon } from "assets/PrivateCabinet/smile-sad.svg";
import classNames from "classnames";
import React from "react";

import styles from "./Clue.module.sass";

type IconType = "happy" | "neutral" | "sad" | "search" | "folder";

interface IClueProps {
  icon?: IconType;
  text: string;
  variant?: "column";
}
const Clue: React.FC<IClueProps> = ({ icon, text, variant }) => {
  const renderIcon = (): React.ReactNode => {
    switch (icon) {
      case "happy":
        return <SmileIcon />;
      case "neutral":
        return <NeutralIcon width={34} height={34} />;
      case "sad":
        return <SadIcon />;

      case "search":
        return <SearchIcon width={25} height={25} fill="#757575" />;
      case "folder":
        return <FolderIcon width={25} height={25} fill="#757575" />;
      default:
        break;
    }
  };
  return (
    <div
      className={classNames(styles.container, {
        [styles[variant]]: variant
      })}
    >
      {renderIcon()}
      <p className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default Clue;
