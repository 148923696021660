import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { useMenuFilterLibraryCategories } from "collections/library";
import { LibrarySearch } from "containers/Library/LibraryServicePanel/components/LibrarySearch/LibrarySearch";
import Button from "generalComponents/Button/Button";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { LIBRARY_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { useProjectMainAccess } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPath } from "hooks/useCurrentPath";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IServePanel } from "models/servePanel";
import { useCallback } from "react";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import { NavigationLibraryOption } from "./components/NavigationLibraryOption/NavigationLibraryOption";
import styles from "./ProjectLibraryServicePanel.module.sass";

export const ProjectLibraryServicePanel: FC<IServePanel> = (): JSX.Element => {
  const { libraryDirId } = useParams();

  const { onSetCreateObjectLibraryModal, fetchFolderZipLink } = useActions();

  const {
    objSearchParams: { chapter, year, month },
    onSetObjSearchParams
  } = useSelectedObjSearchParams();

  const { project } = useJoinProjectsSelectors();

  const isMainAccess = useProjectMainAccess();

  const isAccessLibrary = project?.accesses?.MGR_LIBRARY;

  const folderInfo = project?.library?.folderInfo;

  const projectLibraryPage = `${ROUTES.PROJECT}/${project?.id}/${ROUTES.LIBRARY}`;

  const isLibrary = useCurrentPath(projectLibraryPage);
  const isLibrarySearch = useCurrentPath(`/${projectLibraryPage}/${LIBRARY_ROUTES.SEARCH}`);
  const isLibrarySetttings = useCurrentPath(`/${projectLibraryPage}/${LIBRARY_ROUTES.SETTINGS}`);

  const navigate = useNavigate();

  const { __ } = useLocales();

  const onChangeDateFilter = (v: Date): void => {
    const y = v ? v.getFullYear().toString() : undefined;
    const m = v ? (v.getMonth() + 1).toString() : undefined;
    onSetObjSearchParams({
      [SEARCH_PARAMS.YEAR]: y ?? "",
      [SEARCH_PARAMS.MONTH]: m ?? ""
    });
  };

  const renderSelectedDate = (): Date => {
    if (year && month) {
      return new Date(Number(year), Number(month) - 1, 1);
    }
    return null;
  };

  const optionsFilterLibraryCategories = useMenuFilterLibraryCategories();

  const selectedDefaultCategory = optionsFilterLibraryCategories.find(({ value }) => value === chapter);

  const onVisibleCreateObjectLibraryModal = () => {
    onSetCreateObjectLibraryModal({ open: true, type: "addProjectLibrary" });
  };

  const downloadFolder = useCallback(() => {
    fetchFolderZipLink(folderInfo?.id_dir, __("Не удалось скачать папку. Попробуйте еще раз"));
  }, [folderInfo?.id_dir]); //eslint-disable-line

  return (
    <ToolsPanel size="large">
      <div className={styles.toolsLeft}>
        {!isLibrary && (
          <NavigationLibraryOption
            isLibrarySearch={isLibrarySearch}
            isLibrarySetttings={isLibrarySetttings}
            libraryCategory={chapter}
            libraryDirId={libraryDirId}
          />
        )}
        {!isLibrary && (
          <>
            {(isLibrarySearch || isLibrarySetttings) && (
              <SelectCustom
                width={200}
                variantHeight="small"
                options={optionsFilterLibraryCategories}
                selectedDefault={{
                  name: selectedDefaultCategory?.name || optionsFilterLibraryCategories[0]?.name,
                  value: selectedDefaultCategory?.value || optionsFilterLibraryCategories[0]?.value
                }}
              />
            )}
            {!libraryDirId && (
              <InputDate
                startDate={renderSelectedDate()}
                setStartDate={(v: Date) => onChangeDateFilter(v)}
                dateFormat="MMM yy"
                isClearable
                maxDate={new Date()}
                showMonthYearPicker
              />
            )}
          </>
        )}
      </div>
      <div className={styles.toolsRight}>
        {!isLibrarySearch && !libraryDirId && !isLibrarySetttings && (
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            onClick={() => navigate(`/${projectLibraryPage}/${LIBRARY_ROUTES.SEARCH}`)}
            size={ButtonSizeType.SMALL}
            iconL={<SearchIcon fill="#274A42" />}
          />
        )}
        {isLibrarySearch && <LibrarySearch />}
        {isLibrary && (isMainAccess || isAccessLibrary) && (
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            onClick={() => navigate(`/${projectLibraryPage}/${LIBRARY_ROUTES.SETTINGS}`)}
            size={ButtonSizeType.SMALL}
            iconL={<SettingsIcon fill="#274A42" />}
          />
        )}
        {(isLibrary || isLibrarySetttings) && (isMainAccess || isAccessLibrary) && (
          <Button
            variant={ButtonVariantType.BLUE}
            onClick={onVisibleCreateObjectLibraryModal}
            size={ButtonSizeType.SMALL}
            text={`+ ${__("Создать")}`}
          />
        )}
        {libraryDirId && libraryDirId === folderInfo?.id_dir && (
          <Button
            variant={ButtonVariantType.BLUE}
            onClick={downloadFolder}
            size={ButtonSizeType.SMALL}
            text={`${__("Скачать все")}`}
            iconL={<UploadIcon width={16} height={16} />}
          />
        )}
      </div>
    </ToolsPanel>
  );
};
