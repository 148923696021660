import { FC } from "react";

import { StorageDiagram } from "../../../StorageDiagram/StorageDiagram";
import { StorageHead } from "../../../StorageHead/StorageHead";
import { PanelBox } from "../PanelBox/PanelBox";
import styles from "./PanelStorageDiagram.module.sass";

export const PanelStorageDiagram: FC = (): JSX.Element => {
  return (
    <PanelBox>
      <div className={styles.box}>
        <StorageHead />
        <StorageDiagram />
      </div>
    </PanelBox>
  );
};
