import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Button from "generalComponents/Button/Button";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import ProjectItem from "../ProjectItem/ProjectItem";
import styles from "./JoinProjectMainMenu.module.sass";

interface IProps {
  collapsed: boolean;
}

const JoinProjectMainMenu: FC<IProps> = ({ collapsed }) => {
  const { __ } = useLocales();
  const { onSetCreateProjectModal } = useActions();

  const { userInfo } = useUserSelectors();
  const { projectList } = useJoinProjectsSelectors();

  const addProject = () => onSetCreateProjectModal({ open: true });
  return (
    <div className={styles.wrap}>
      <ul>
        {projectList.length > 0 &&
          projectList.map((project) => <ProjectItem key={project.id} project={project} collapsed={collapsed} />)}
      </ul>
      {userInfo.rights.IS_MGR_PROJECT && (
        <div className={styles.btnBox}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            iconL={<PlusIcon />}
            text={!collapsed ? __("Создать проект") : ""}
            isSquare={collapsed ? true : false}
            title={collapsed ? __("Создать проект") : ""}
            onClick={addProject}
          />
        </div>
      )}
    </div>
  );
};

export default JoinProjectMainMenu;
