import Loader from "generalComponents/Loaders/4HUB";
import { LETTERS_LIMIT } from "generalComponents/variables/globalVariables";
import { MAIL_PARAMS } from "generalComponents/variables/routing";
import { useFindPostbox } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { LoaderTypes } from "models/generalComponents/loader";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import { IGetPostboxMailsPayload } from "models/store/postbox/postboxPayloads";
import React, { memo, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";

import styles from "./LettersObserver.module.sass";

const LettersObserver: React.FC = memo(function LettersObserver() {
  const { folderName } = useParams();
  const [params] = useSearchParams();
  const { fetchPostboxMails } = useActions();
  const { postbox } = useFindPostbox();
  const { isObserver, letters, loader } = usePostboxSelectors();
  const unseen = params.get(MAIL_PARAMS.UNREAD);
  const favorite = params.get(MAIL_PARAMS.FAVORITE);

  const { ref, inView } = useInView({
    threshold: 0.9
  });

  useEffect(() => {
    const controller = new AbortController();
    if (inView) {
      const payload: IGetPostboxMailsPayload = {
        user: postbox.email,
        pass: postbox.pass,
        folder: folderName,
        limit: LETTERS_LIMIT,
        start: letters.length,
        isSchedule: folderName === POSTBOX_SYSTEM_FOLDER.SCHEDULED,
        is_favorite: favorite ? "1" : undefined,
        is_unseen: unseen ? "1" : undefined
      };
      fetchPostboxMails(payload, controller);
    }
    return () => controller.abort();
  }, [inView, unseen, favorite]); //eslint-disable-line

  return loader ? (
    <div className={styles.loaderBox}>
      <Loader
        type={LoaderTypes.BOUNCING_DOTS}
        position="absolute"
        background="white"
        zIndex={5}
        width="100px"
        height="100px"
        containerType="bounceDots"
      />
    </div>
  ) : (
    isObserver && <div ref={ref} className={styles.observer} />
  );
});

export default LettersObserver;
