import { ReactComponent as SafetIcon } from "assets/icons/safe.svg";
import { ReactComponent as DotsIcon } from "assets/PrivateCabinet/dots-vertical-round.svg";
import classNames from "classnames";
import { useContextArchivedProject, useContextArchivedSafe } from "collections/archive";
import { useContextTrashEmployee, useContextTrashProject, useContextTrashSafe } from "collections/trash";
import Avatar from "generalComponents/Avatar/Avatar";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { ROUTES } from "generalComponents/variables/routing";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { IProject } from "models/store/joinProjects/joinProgects";
import { ISafe } from "models/store/safe/safe";
import { IUserInfo } from "models/store/user/user";
import { TypeElements } from "models/typeElements/typeElements";
import { FC, useState } from "react";
import { useLocation } from "react-router";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

import styles from "./ObjectElementsBox.module.sass";

export interface IObjectElementsBoxProps {
  element: IProject | IUserInfo | ISafe;
}

export const ObjectElementsBox: FC<IObjectElementsBoxProps> = ({ element }): JSX.Element => {
  const { view } = useFilesSelectors();
  const { pathname } = useLocation();
  const { isLinesView } = useGetItemView();

  const [isHover, setIsHover] = useState<boolean>(false);

  const onHover = (): void => setIsHover(true);
  const outHover = (): void => {
    setIsHover(false);
  };

  const { contextMenuArchivedProjectCallback } = useContextArchivedProject();
  const { contextMenuArchivedSafeCallback } = useContextArchivedSafe();
  const { contextMenuTrashProjectCallback } = useContextTrashProject();
  const { contextMenuTrashSafeCallback } = useContextTrashSafe();
  const { contextMenuTrashEmloyeeCallback } = useContextTrashEmployee();

  const asProject = element as IProject;
  const asUser = element as IUserInfo;
  const asSafe = element as ISafe;

  const isArchive = pathname.startsWith(`/${ROUTES.ARCHIVE}`);
  const isTrash = pathname.startsWith(`/${ROUTES.TRASH}`);

  const elementType = element?.table;

  const renderIcon = (): JSX.Element => {
    if (elementType === TypeElements.PROJECT) {
      return (
        <Avatar
          size={!isLinesView ? 54 : 30}
          name={asProject.name}
          imgSrc={asProject?.icon && `${projectSrc}${asProject.icon}`}
        />
      );
    }

    if (elementType === TypeElements.SAFE) {
      return <SafetIcon width={!isLinesView ? 54 : 30} height={!isLinesView ? 54 : 30} />;
    }

    if (elementType === TypeElements.USERS) {
      return (
        <Avatar
          name={`${asUser.fname} ${asUser.sname}`}
          size={!isLinesView ? 40 : 30}
          imgSrc={`${projectSrc}${asUser.icon}`}
        />
      );
    }
  };

  const renderContextMenu = (): IPopoverData[] => {
    if (isArchive && elementType === TypeElements.PROJECT) {
      return contextMenuArchivedProjectCallback(asProject);
    }

    if (isArchive && elementType === TypeElements.SAFE) {
      return contextMenuArchivedSafeCallback(asSafe);
    }

    if (isTrash && elementType === TypeElements.PROJECT) {
      return contextMenuTrashProjectCallback(asProject);
    }

    if (isTrash && elementType === TypeElements.SAFE) {
      return contextMenuTrashSafeCallback(asSafe);
    }

    if (isTrash && elementType === TypeElements.USERS) {
      return contextMenuTrashEmloyeeCallback(asUser);
    }

    return [];
  };

  return (
    <div
      className={classNames(styles.card, {
        [styles[view]]: view
      })}
      onMouseEnter={onHover}
      onMouseLeave={outHover}
    >
      <div
        className={classNames(styles.card__wrapp, {
          [styles[view]]: view
        })}
      >
        <div className={styles.card__iconWrapp}>{renderIcon()}</div>
        <div className={styles.card__info}>
          <h3
            className={classNames(styles.card__name, {
              [styles.bold]: elementType === TypeElements.USERS
            })}
          >
            {elementType === TypeElements.USERS ? `${asUser?.fname} ${asUser?.sname}` : element?.name}
          </h3>
          {elementType === TypeElements.USERS && asUser?.email && <p>{asUser.email}</p>}
        </div>
        <PopoverContextMenu data={renderContextMenu()} iconVariant="hidden" visible={isHover}>
          <DotsIcon fill="#E7EBEA" />
        </PopoverContextMenu>
      </div>
    </div>
  );
};
