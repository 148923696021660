import { useContentCategories } from "collections/library";
import { LibraryCategories } from "generalComponents/LibraryCategories/LibraryCategories";
import { WorkSpaceWrap } from "generalComponents/WorkSpaceWrap/WorkSpaceWrap";
import { FC } from "react";

interface ILibraryMainWorkSpaceProps {
  isLibrary?: boolean;
}

export const ProjectLibraryMainWorkSpace: FC<ILibraryMainWorkSpaceProps> = (): JSX.Element => {
  const categories = useContentCategories();

  return (
    <WorkSpaceWrap>{!!Object.values(categories).length && <LibraryCategories categories={categories} />}</WorkSpaceWrap>
  );
};
