import classNames from "classnames";
import { IButtonProps } from "models/generalComponents/button";
import { forwardRef, LegacyRef } from "react";

import styles from "./Button.module.sass";

const Button = forwardRef(
  (
    {
      className,
      onClick,
      variant,
      text,
      type = "button",
      disabled = false,
      full = false,
      isActive,
      iconL,
      iconR,
      size,
      isSquare = false,
      title,
      onMouseDown
    }: IButtonProps,
    ref: LegacyRef<HTMLButtonElement>
  ): JSX.Element => {
    return (
      <button
        ref={ref}
        type={type}
        className={classNames(className, styles.btn, styles[variant], styles[size], {
          [styles.full]: full,
          [styles.active]: isActive,
          [styles.square]: isSquare
        })}
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
        title={title}
      >
        {iconL && <span className={styles.iconBox}>{iconL}</span>}
        {text && <span className={styles.text}>{text}</span>}
        {iconR && <span className={styles.iconBox}> {iconR}</span>}
      </button>
    );
  }
);

export default Button;
Button.displayName = "Button";
