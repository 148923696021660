import "react-image-crop/dist/ReactCrop.css";

import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from "react-image-crop";

interface CropImageProps {
  aspect?: number;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  imgSrc: string;
}

const CropImage: React.FC<CropImageProps> = ({ aspect = 1, canvasRef, imgSrc }) => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement>(null);

  const canvasPreview = (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: Crop) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("No 2d context");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.save();
    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
    ctx.restore();
  };

  const useDebounceEffect = (func: () => void, waitTime: number, deps: any[]) => {
    useEffect(() => {
      const t = setTimeout(() => {
        func();
      }, waitTime);

      return () => {
        clearTimeout(t);
      };
    }, deps); // eslint-disable-line
  };

  const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
    // Assuming `centerCrop` and `makeAspectCrop` are defined elsewhere
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 50
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
      canvasPreview(imgRef.current, canvasRef.current, centerAspectCrop(width, height, aspect));
    }
  };

  useDebounceEffect(
    async () => {
      if (imgRef.current && canvasRef.current && crop) {
        canvasPreview(imgRef.current, canvasRef.current, completedCrop ?? (crop as Crop));
      }
    },
    100,
    [completedCrop]
  );

  return (
    <ReactCrop
      crop={crop as Crop}
      onChange={(_, percentCrop) => setCrop(percentCrop)}
      onComplete={(c) => setCompletedCrop(c)}
      aspect={aspect}
    >
      <img ref={imgRef} src={imgSrc} alt="img" onLoad={onImageLoad} />
    </ReactCrop>
  );
};

export default CropImage;
