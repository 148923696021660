import { ReactComponent as DotsVerticalRoundIcon } from "assets/PrivateCabinet/dots-vertical-round.svg";
import { ReactComponent as LockIcon } from "assets/PrivateCabinet/lock.svg";
import classNames from "classnames";
import { useContextMenuNote } from "collections/tasks";
import FileTags from "generalComponents/FileTags/FileTags";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { TASK_MODALS } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { IMyTask } from "models/store/tasks/tasks";
import React from "react";
import { useLocales } from "react-localized";

import { createMarkup } from "../Services/browserServices";
import styles from "./Note.module.sass";

interface INoteProps {
  note: IMyTask;
}

const Note: React.FC<INoteProps> = ({ note }): JSX.Element => {
  const { __ } = useLocales();
  const contextMenu = useContextMenuNote();
  const { onSetTaskModal } = useActions();
  const { formatDate } = useDateFormat();

  const onOpen = (): void => {
    if (note.pass && !note?.showNote) {
      onSetTaskModal({
        type: TASK_MODALS.READ_PASSWORD,
        params: { note: note, callbackType: TASK_MODALS.OPEN_NOTE }
      });
      return;
    }
    onSetTaskModal({
      type: TASK_MODALS.OPEN_NOTE,
      params: note
    });
  };

  return (
    <div
      style={{ background: note.color }}
      className={classNames(styles.note, { [styles.noteGrey]: Boolean(!note.color) })}
      onClick={onOpen}
    >
      <div className={styles.header}>
        <p className={styles.name}>{note.name}</p>
        <PopoverContextMenu data={contextMenu(note)} title={__("Действия")}>
          <DotsVerticalRoundIcon className={classNames({ [styles.dots]: note.color })} />
        </PopoverContextMenu>
      </div>
      <div className={styles.info}>
        <div className={styles.elements}>
          {note.tags.length > 0 && <FileTags item={note} />}
          {note.emo && <span dangerouslySetInnerHTML={{ __html: note.emo }} />}
        </div>
        <span className={styles.date}>{formatDate(note.ut)}</span>
      </div>

      <div className={styles.text}>
        {note.pass && !note?.showNote ? (
          <div className={styles.lockBlock}>
            <LockIcon className={classNames({ [styles.icon]: note.color })} />
            <p className={styles.content}>{__("Данная заметка находиться под паролем")}</p>
          </div>
        ) : (
          <div
            className={classNames("htmlContent", styles.content)}
            dangerouslySetInnerHTML={createMarkup(note.prim)}
          />
        )}
      </div>
      <div className={styles.corner} style={{ background: note.color }} />
    </div>
  );
};

export default Note;
