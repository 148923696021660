import classNames from "classnames";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { FC, MouseEvent } from "react";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./Options.module.sass";

interface OptionsProps {
  name?: string;
  options?: ISelectCustom[];
  onSelected?: (e: MouseEvent<HTMLButtonElement>) => void;
  selected?: ISelectCustom;
  handleClick: (el: ISelectCustom) => void;
}

export const Options: FC<OptionsProps> = ({ onSelected, name, options, selected, handleClick }) => {
  const { theme } = useUserSelectors();

  return (
    <ul className={classNames(styles.list, `scrollbar-medium-${theme}`)}>
      {options.map((el, i) => (
        <li key={i} className={styles.list__item}>
          {el?.label && <span className={styles.list__label}>{el.label}</span>}
          {el.name && (
            <button
              type="button"
              onClick={(e) => {
                onSelected && onSelected(e);
                handleClick(el);
                el?.callback && el.callback();
              }}
              className={classNames(styles.list__btn, {
                [styles.active]: selected.value === el.value
              })}
              name={name}
              value={el.value}
            >
              {el?.icon && el.icon}
              <span className={styles.list__btnName}>{el.name}</span>
            </button>
          )}
        </li>
      ))}
    </ul>
  );
};
