import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import PopUp from "generalComponents/PopUp/PopUp";
import SelectChosen from "generalComponents/SelectChosen/SelectChosen";
import { CHAT_MESSAGE_ACTION, CHAT_TASK } from "generalComponents/variables/chat";
import { useWebSocketContext } from "generalComponents/WebSocketsProvider/WebSocketsProvider";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { ProjectTypes } from "Store/types/projectTypes";
import { taskTypes } from "types/Project";

import { generateTaskMessage } from "../AddTask/AddTask.service";
import styles from "./AddQuickTask.module.sass";

function AddQuickTask({ onClose }) {
  const { __ } = useLocales();
  const { project, projectsList } = useSelector((s) => s.Projects);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    prim: ""
  });
  const [taskDest, setTaskDest] = useState(CHAT_TASK.BACKLOG);
  const { selectedContact } = useSelector((s) => s.Cabinet.chat);
  const { uid } = useSelector((s) => s.user);
  const socket = useWebSocketContext();

  const selectBacklog = () => setTaskDest((_) => CHAT_TASK.BACKLOG);
  const selectSprint = () => setTaskDest((_) => CHAT_TASK.SPRINT);

  const onChangeName = ({ target }) => setForm((s) => ({ ...s, name: target.value }));
  const onChangePrim = ({ target }) => setForm((s) => ({ ...s, prim: target.value }));

  const onSelectProject = (id) => {
    const currentProject = projectsList.find((item) => item.id === id);
    dispatch({ type: ProjectTypes.CURRENT_PROJECT, payload: currentProject });
  };

  const renderProject = (project) => (
    <div key={project.id} onClick={() => onSelectProject(project.id)} className={styles.project}>
      {project.icon ? (
        <img src={project.icon} alt="sign" width={33} height={33} className={styles.logo} />
      ) : (
        <div className={styles.noLogo} />
      )}
      <span className={styles.name}>{project.name}</span>
      {project.id_fig && <img src={`/assets/PrivateCabinet/signs/${project.id_fig}.svg`} alt="sign" />}
      {project.id_emo && <img src={`/assets/PrivateCabinet/smiles/${project.id_emo}.svg`} alt="smile" />}
    </div>
  );

  const onSubmit = () => {
    const payload = {
      action: CHAT_MESSAGE_ACTION.ADD_TASK,
      uid,
      chat_id: selectedContact.id_group ? selectedContact.id_group : undefined,
      id_group: selectedContact.id_group,
      is_group: !!selectedContact.id_group,
      id_contact: selectedContact.id,
      id_user_to: selectedContact.id_real_user,
      name: form.name,
      prim: form.prim,
      id_project: project.id,
      is_backlog: taskDest === CHAT_TASK.BACKLOG ? -1 : 0
    };

    socket.socket.send(JSON.stringify(generateTaskMessage(payload)));
    onClose();
  };

  return (
    <PopUp set={onClose}>
      <div className={classNames(styles.addQuickTask)}>
        <span className={classNames("cross")} onClick={onClose} />
        <span className={styles.title}>{__("Создание быстрой задачи")}</span>
        <div className={styles.container}>
          <p className={styles.label}>{__("Выберите проект")}</p>
          <SelectChosen
            placeholder={__("Список проектов")}
            variant="formField"
            value={project ? <div className={styles.selectedProject}>{renderProject(project)}</div> : ""}
          >
            <ul className={styles.projectsList}>
              {projectsList && projectsList.map((project) => renderProject(project))}
            </ul>
          </SelectChosen>
        </div>
        <div className={styles.container}>
          <p className={styles.label}>{__("Имя задачи")}</p>
          <input
            className={styles.input}
            type="text"
            name="taskName"
            value={form.name}
            onChange={onChangeName}
            placeholder={__("Введите имя задачи")}
          />
        </div>
        <div className={styles.container}>
          <p className={styles.label}>{__("Определить задачу в:")}</p>
          <div className={styles.selectRow}>
            <div className={styles.select} onClick={selectBacklog}>
              <div
                className={classNames(styles.radioContact, {
                  [styles.radioContactChosen]: taskDest === "backlog"
                })}
              />
              <div>{__("Бэклог")}</div>
            </div>
            <div className={styles.select} onClick={selectSprint}>
              <div
                className={classNames(styles.radioContact, {
                  [styles.radioContactChosen]: taskDest === "sprint"
                })}
              />
              <div>{__("Текущий спринт")}</div>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <p className={styles.label}>{__("Описание задачи")}</p>
          <textarea
            className={styles.textarea}
            rows={5}
            placeholder={__("Введите описание")}
            onChange={onChangePrim}
            value={form.prim}
          />
        </div>
        <Button type="button" variant="ok" text={__("Создать")} onClick={onSubmit} disabled={form.name.length === 0} />
      </div>
    </PopUp>
  );
}

export default AddQuickTask;

AddQuickTask.propTypes = {
  onClose: PropTypes.func,
  params: taskTypes,
  type: PropTypes.string
};
