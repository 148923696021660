import classNames from "classnames";
import { usePropertySettingsList } from "collections/myFolders";
import Button from "generalComponents/Button/Button";
import { ChooseFolder } from "generalComponents/ChooseFolder/ChooseFolder";
import CombinedElements from "generalComponents/Elements/CombinedElements";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { useAllFolders } from "generalComponents/Services/folderServices/folderServices";
import { calculateSize } from "generalComponents/Services/helpers";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IGeneralTabInfoProps } from "models/modals/propertiesModal/components/generalTabInfo";
import { PropertyParameterTypes } from "models/modals/propertiesModal/propertiesModal";
import { initialPropertiesModalState } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IFolder, initialFolderState } from "models/store/folders/foldersStore";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";

import styles from "./GeneralTabInfo.module.sass";

export const GeneralTabInfo: React.FC<IGeneralTabInfoProps> = ({
  _isFolder,
  params,
  renderName,
  folderInfo,
  isFolderInfoLoading
}): JSX.Element => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const { onSetPropertiesModal, onEditFolders, onEditFiles, onEditSafeFolder, onSetUpdateFiles, onSetUpdateNextFiles } =
    useActions();
  const { propertySettingsFolder, propertySettingsFile } = usePropertySettingsList();

  const { folders } = useFoldersSelectors();

  const { isLinesPreviewView } = useGetItemView();

  const allFolders = useAllFolders();
  const safeAuthorized = useAuthorizedSafe();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const isFolderPage = useCurrentPathStarstWith(ROUTES.FOLDERS);

  const closeModal = () => onSetPropertiesModal(initialPropertiesModalState());

  const getFileParent = () => allFolders.find((f) => f.id_dir === params.id_dir);
  const getFolderParent = () => {
    return (params as IFolder).id_parent !== "0"
      ? allFolders.find((f) => f.id_dir === (params as IFolder).id_parent)
      : initialFolderState();
  };

  const _isSystemFolder: boolean = _isFolder && (params as IFolder).is_system === "1";
  const _isMy: boolean = params.my !== undefined ? Boolean(params.my) : true;
  const [_folder, _setFolder] = useState<IFolder>(params.is_dir === 1 ? getFolderParent() : getFileParent());
  const [emo, setEmo] = useState<string>(params.emo);
  const [color, setColor] = useState<string>();
  const [tags, setTags] = useState<string[]>(params.tags);

  const onSelect = (folder: IFolder): void => {
    if (params.is_dir && params.id_dir === folder.id_dir) return;
    _setFolder(folder);
  };

  const renderParameterInfo = (type: PropertyParameterTypes): string | number => {
    if (_isFolder) {
      if (isFolderInfoLoading) return "...";

      switch (type) {
        case PropertyParameterTypes.SIZE:
          return calculateSize(folderInfo.size);

        case PropertyParameterTypes.NUMBER_OF_FILES:
          return folderInfo.col_total;

        case PropertyParameterTypes.DATE_CREATION:
          return formatDate(folderInfo.cdate, "full");

        case PropertyParameterTypes.DATE_CHANGE:
          return formatDate(folderInfo.mdate, "full");
      }
    } else {
      switch (type) {
        case PropertyParameterTypes.SIZE:
          return calculateSize((params as IFile).size);

        case PropertyParameterTypes.DATE_CREATION:
          return formatDate((params as IFile).ctime, "full");

        case PropertyParameterTypes.DATE_CHANGE:
          return formatDate((params as IFile).mtime_now, "full");
      }
    }
  };

  const renderChooseFolderOption = (): JSX.Element => {
    if (_isSystemFolder) {
      return (
        <div className={styles.propertiesInfo}>
          <div className={styles.iconWrapper}>
            <ItemIcon width={21} height={17} color={params.color} passIcon={params.is_pass === 1} isFolder />
          </div>
          <p className={styles.itemText}>{renderName()}</p>
        </div>
      );
    }

    return <ChooseFolder onSelect={onSelect} folder={_folder} folders={folders} />;
  };

  const renderParameterListType = (): JSX.Element[] => {
    return (_isFolder ? propertySettingsFolder : propertySettingsFile).map((item) => (
      <li key={item.type} className={styles.parameterItem}>
        <p className={styles.parameterText}>
          {item.name}: <span>{renderParameterInfo(item.type)}</span>
        </p>
      </li>
    ));
  };

  const isDisabledSubmit = () => {
    if (
      (_isFolder && (tags !== (params as IFolder).tags || color !== params.color || emo !== params.emo)) ||
      (params as IFolder).id_parent !== _folder?.id_dir
    ) {
      return false;
    }
    if (!_isFolder && (tags !== (params as IFile).tags || color !== params.color || emo !== params.emo)) {
      return false;
    }
    return true;
  };

  const renderButtonsOption = (): JSX.Element => {
    if (_isSystemFolder) {
      return (
        <Button
          type="button"
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.LARGE}
          text={__("Готово")}
          onClick={closeModal}
        />
      );
    }

    return (
      <>
        <Button
          type="button"
          variant={ButtonVariantType.LIGHT}
          size={ButtonSizeType.LARGE}
          text={__("Отмена")}
          onClick={closeModal}
        />
        <Button
          type="button"
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.LARGE}
          text={__("Сохранить")}
          onClick={onSubmit}
          disabled={isDisabledSubmit()}
        />
      </>
    );
  };

  const onSubmit = (): void => {
    if (_isFolder) {
      if (safeAuthorized) {
        const payload = {
          ...safeAuthorized,
          name: params.name,
          id_dirs: [params.id_dir],
          idParent: (params as IFolder).id_parent,
          tags: (JSON.stringify(tags) as unknown as string[]) || undefined,
          color: color || undefined,
          emo: emo || undefined
        };
        onEditSafeFolder(payload, EVENT_TYPE.EDIT);
        return;
      }
      const payload = {
        id_dirs: [(params as IFolder).id_dir],
        tags: (JSON.stringify(tags) as unknown as string[]) || undefined,
        color: color !== params.color ? color : undefined,
        emo: emo !== params.emo ? emo : undefined,
        id_parent: (params as IFolder).id_parent !== _folder?.id_dir ? _folder.id_dir : undefined,
        id_parent_dir_moved:
          (params as IFolder).id_parent !== _folder?.id_dir ? (params as IFolder).id_parent : undefined
      };
      onEditFolders(payload, EVENT_TYPE.EDIT, (folder: IFolder) => {
        const folders = [folder];

        if (isLinesPreviewView) {
          onSetUpdateNextFiles(folders);
        } else {
          onSetUpdateFiles(folders);
        }

        closeModal();
      });
    } else {
      const payload = {
        id_dir: _isFolder && params?.id_dir ? params?.id_dir : undefined,
        fids: [(params as IFile).fid],
        tags: (JSON.stringify(tags) as unknown as string[]) || undefined,
        color: color || undefined,
        emo: emo || undefined
      };

      onEditFiles(payload, EVENT_TYPE.EDIT, safeAuthorized, (file: IFile) => {
        const files = [file];

        if (isLinesPreviewView && isFolderPage) {
          onSetUpdateNextFiles(files);
        } else {
          onSetUpdateFiles(files);
        }

        closeModal();
      });
    }
  };

  return (
    <div className={styles.root}>
      {_isMy && !isSafePage && <div className={styles.itemWrapper}>{renderChooseFolderOption()}</div>}
      {!_isSystemFolder && (
        <div className={classNames(styles.itemWrapper, styles.margin)}>
          <CombinedElements
            tags={tags}
            onSelectTags={setTags}
            color={color}
            setColor={setColor}
            emo={emo}
            setEmo={setEmo}
            disabled={!_isMy}
          />
        </div>
      )}
      <div className={classNames(styles.itemWrapper, styles.flex)}>
        <span className={styles.topTitle}>{_isFolder ? __("Параметры папки") : __("Параметры файла")}</span>
        <ul className={styles.parameterList}>{renderParameterListType()}</ul>
      </div>

      {_isMy && <div className={styles.buttonsWrapper}>{renderButtonsOption()}</div>}
    </div>
  );
};
