import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Button from "generalComponents/Button/Button";
import { ROUTES } from "generalComponents/variables/routing";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IProjectTask } from "models/store/joinProjects/joinProgects";
import { Fragment, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import Select, { components, MenuProps, OnChangeValue } from "react-select";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./TagSelect.module.sass";

export interface Option {
  readonly value: string;
  readonly label: string;
}

const TagSelect = ({ item, onChange }: { item: IFile | IFolder | IProjectTask; onChange: Function }) => {
  const { __ } = useLocales();
  const { tags } = useUserSelectors();
  const [options, setOptions] = useState<Option[]>([]);
  const [active, setActive] = useState<Option[]>([]);
  const { onSetTagEditModal } = useActions();

  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const safe = useAuthorizedSafe();

  useEffect(() => {
    if (!tags) return;

    const newOptions = tags.map((tag) => ({
      value: tag.id,
      label: tag.name
    }));
    setOptions(newOptions);

    const defaultValuesFromItem = item.tags.map((tagId) => {
      const correspondingTag = tags.find((tag) => tag.id === tagId);
      return correspondingTag ? { value: correspondingTag.id, label: correspondingTag.name } : null;
    });
    setActive(defaultValuesFromItem.filter((value) => value !== null));
  }, [tags, item]);

  const onTagChange = (newValue: OnChangeValue<Option, true>) => {
    setActive(newValue as Option[]);

    if ((item as IFile)?.mime_type !== undefined) {
      // Если item - файл
      onChange(
        { fids: [(item as IFile).fid], tags: JSON.stringify(newValue.map((item) => item.value)) },
        isSafePage ? safe : null
      );
    } else if ((item as IFolder)?.id_dir !== undefined) {
      // Если item - папка
      onChange({ id_dirs: [(item as IFolder).id_dir], tags: JSON.stringify(newValue.map((item) => item.value)) });
    } else {
      // В противном случае
      onChange(newValue.map((item) => item.value));
    }
  };

  const Menu = (props: MenuProps<Option, true>) => {
    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <Fragment>
        <components.Menu<Option, true, any> {...props}>
          {props.children}
          <Button
            className={styles.button}
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={__("Добавить")}
            onClick={() => onSetTagEditModal({ open: true })}
            iconL={<PlusIcon width={10} height={10} />}
          />
        </components.Menu>
      </Fragment>
    );
  };

  return (
    <Select
      value={active}
      closeMenuOnSelect={false}
      isMulti
      components={{ Menu }}
      options={options}
      maxMenuHeight={150}
      onChange={onTagChange}
      placeholder={"#" + __("Тег")}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          isDisabled: true,
          borderRadius: 0,
          borderLeft:
            (item as IFile)?.mime_type !== undefined || (item as IFolder)?.id_dir !== undefined
              ? "none"
              : baseStyles.border,
          borderRight:
            (item as IFile)?.mime_type !== undefined || (item as IFolder)?.id_dir !== undefined
              ? "none"
              : baseStyles.border
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          display: "none"
        }),
        indicatorsContainer: (baseStyles) => ({
          ...baseStyles,
          display: "none"
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 10
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          maxHeight: "90px",
          overflow: "auto"
        })
      }}
    />
  );
};

export default TagSelect;
