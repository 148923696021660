export enum MailModalsTypes {
  NEW_MAIL = "NewMail",
  NEW_POSTBOX = "NewPostbox",
  ENTER_POSTBOX = "EnterPostbox",
  ADD_FOLDER = "addFolder",
  EDIT_FOLDER = "EditFolder",
  DELETE_FOLDER = "deleteFolder",
  DELETE_MAIL = "deleteMail",
  EMPTY_TRASH = "emptyTrash",
  CONFIRM_MAIL = "confirm",
  MOVE_IN_FOLDER = "moveInFolder",
  DELETE_POSTBOX = "deletePostbox",
  FULL_SCREEN_MAIL = "fullScreenMail",
  SAVE_TO_DRAFT = "saveToDrafts",
  BLOCK_AGENT = "blockAgent",
  DEVICE_LOCK = "deviceLock",
  UNLOCK_AGENT = "unlockAgent",
  DEVICE_UNLOCK = "DEVICE_UNLOCK",
  SIGN_OUT = "signOut",
  USER_BLOCKED_BY_DEVICE = "userBlockedByDevice",
  SEND_REQUEST = "sendRequest",
  CONTACT_SUPPORT = "contactSupport",
  UNKNOWN_DEVICE = "unknownDevice",
  FOLDER_ALREADY_EXISTS = "folderAlreadyExists",
  MOVE_TO_SPAM = "moveToSpam",
  MOVE_TO_SPAM_SUCCESS = "moveToSpamSuccess",
  USER_BLOCKED = "userBlocked",
  REMOVE_FROM_SPAM = "removeFromSpam",
  REMOVE_FROM_SPAM_WITH_USER_UNLOCK = "removeFromSpamWithUserUnlock",
  USER_UNLOCKED = "userUnlocked",
  BLOCKED_USERS = "blockedUsers"
}

export enum MailContextTypes {
  POSTBOX_EXIT = "postboxExit",
  POSTBOX_DELETE = "postboxDelete",
  FOLDER_RENAME = "folderRename",
  FOLDER_DELETE = "folderDelete",
  MAIL_MOVE = "mailMove",
  MOVE_IN_FOLDER = "moveInFolder",
  BLOCK_AGENT = "blockAgent",
  UNLOCK_AGENT = "unlockAgent",
  SET_MAIL_AS_READ = "setMailAsRead",
  SET_MAIL_UNREAD = "setMailUnread"
}

export enum MailCardTypes {
  CARD_MAIL_PATH = "CARD_MAIL",
  CARD_MAIL_LEFT_PATH = "CARD_MAIL_LEFT_PATH",
  PREVIEW_MAIL_PATH = "PREVIEW_MAIL",
  PREVIEW_MAIL_LEFT_PATH = "PREVIEW_MAIL__LEFT_PATH",
  ANSWER_MAIL_PATH = "ANSWER_MAIL"
}

export enum MailViewTypes {
  COLUMN = "column",
  LINE = "line"
}

export enum MailSettingsTabs {
  ACCOUNTS = "ACCOUNTS",
  SESSIONS = "SESSIONS",
  BLOCKED_USER = "BLOCKED_USER",
  BLOCKED_DEVICE = "BLOCKED_DEVICE"
}

export enum MailboxRegErrorsTypes {
  UNKNOWN_DOMAIN = "unknown domain",
  EMAIL_NAME_EXISTS = "email_name exists"
}

export enum MailboxAuthErrorsTypes {
  CANNOT_LOGIN = "cannot login to mailbox",
  AGENT_IS_BLOCK = "agent block for this email"
}

export enum POSTBOX_MODALS {
  NO_MODAL = "",
  SETTINGS = "SETTINGS",
  CREATE_ACCOUNT = "CREATE_ACCOUNT",
  CONNECT_ACCOUNT = "CONNECT_ACCOUNT",
  CHECK_PASS = "CHECK_PASS",
  ADD_FOLDER = "ADD_FOLDER",
  EDIT_FOLDER = "EDIT_FOLDER",
  MAIL_PRINT = "MAIL_PRINT",
  DRAFT = "DRAFT",
  PREVIEW_FILE = "PREVIEW_FILE",
  PASSWORD_RESTORE = "PASSWORD_RESTORE",
  PASSWORD_CHANGE = "PASSWORD_CHANGE",
  NAME_CHANGE = "NAME_CHANGE",
  SAVE_FILE = "SAVE_FILE"
}
