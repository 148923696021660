export enum TypeElements {
  FILES = "files",
  SAFE_FILES = "safe_files",
  TASKS = "tasks",
  SAFE = "safe",
  PROJECT = "project",
  FOLDERS = "folders",
  USERS = "users",
  TEAMS = "teams",
  SOFT = "soft"
}
