import Button from "generalComponents/Button/Button";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./BacklogInput.module.sass";

const BacklogInput = ({ textButton, taskName, onSubmit }) => {
  const { __ } = useLocales();
  const [value, setValue] = useState(taskName);

  const handleChange = (e) => setValue(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(value);
  };

  return (
    <form className={styles.inputBlock} onSubmit={handleSubmit}>
      <input value={value} onChange={handleChange} placeholder={__("Введите текст")} autoFocus={true} />
      <Button type="submit" variant="add" text={textButton} />
    </form>
  );
};

export default BacklogInput;
BacklogInput.defaultProps = {
  taskName: ""
};
BacklogInput.propTypes = {
  textButton: PropTypes.string,
  taskName: PropTypes.string,
  onSubmit: PropTypes.func
};
