import { SIGNS } from "generalComponents/variables/elements";

export enum TagViewTypes {
  LIGHT = "light",
  GREY = "grey",
  BLUE = "blue"
}

export interface IColors {
  dark: string;
  light: string;
  color: string;
  name: string;
}

export interface ICombinedElementsProps {
  emo: string;
  setEmo?: (el: string) => void;
  color: string;
  setColor?: (el: string) => void;
  tags: string[];
  onSelectTags?: (el: string[]) => void;
  disabled?: boolean;
}

export interface ITagPickerProps {
  tags: string[];
  onSelectTags: (el: string[]) => void;
  disabled?: boolean;
}

export interface ISignsProps {
  sign: SIGNS;
  setSign: (el: SIGNS) => void;
  disabled?: boolean;
}

export interface IEmojiProps {
  emo: string;
  setEmo: (el: string) => void;
  disabled?: boolean;
}

export interface ISignItemProps {
  sign: SIGNS;
  size?: number;
}
export interface IEmojiItemProps {
  emoji: string;
  size?: number;
}

export interface ITagProps {
  children: string;
  variant?: TagViewTypes;
  maxWidth?: number | string;
  lineHeight?: number;
}
