import { ChartData, ChartOptions } from "chart.js";
import { FC } from "react";
import { Bar } from "react-chartjs-2";
import { useLocales } from "react-localized";

interface IProps {
  dataChart: { users: string[]; times: number[] };
}

const TaskCompletionTimeChart: FC<IProps> = ({ dataChart }) => {
  const { __ } = useLocales();

  const max = Math.max(...dataChart.times);
  const align = dataChart.times.map((el) => {
    const perstange = (el / max) * 100;
    return perstange >= 8 ? "center" : "end";
  });

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: true,
        color: "#494949",
        align
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: __("часы")
        },
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  const data: ChartData<"bar", number[], string> = {
    labels: dataChart.users,
    datasets: [
      {
        data: dataChart.times,
        type: "bar",
        label: __("Время выполнения задач"),
        borderRadius: 4,
        backgroundColor: "#34C6A2"
      }
    ]
  };

  return <Bar data={data} options={options} />;
};

export default TaskCompletionTimeChart;
