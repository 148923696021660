import { FolderBox } from "generalComponents/FolderBox/FolderBox";
import { useProjectMsg } from "generalComponents/Services/projectServices";
import { EMOJIS, SIGNS } from "generalComponents/variables/elements";
import { useActions } from "hooks/useActions";
import { IElementsFilter } from "models/project/elementsFilter";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import React, { useEffect, useMemo, useState } from "react";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import TrashServicePanelFiles from "../TrashServicePanel/TrashServicePanelFiles";
import styles from "./TrashFiles.module.sass";

const TrashFiles: React.FC = (): JSX.Element => {
  const { onFetchTrashFiles } = useActions();
  const MSG = useProjectMsg();
  const { foldersTrash, filesTrash } = useProjectsSelectors();

  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [filters, setFilters] = useState<IElementsFilter>({
    COLOR: null,
    EMO: EMOJIS.NO_EMOJI,
    FIGURE: SIGNS.NO_SIGN,
    TAG: ""
  });

  useEffect(() => {
    onFetchTrashFiles(MSG.ERROR);
  }, []); // eslint-disable-line

  const toggleSelect = (): void => {
    setIsSelect((isSelect) => !isSelect);
  };
  const filtredFolders: IFolder[] = useMemo(() => {
    if (!filters.COLOR && !filters.EMO && !filters.FIGURE && !filters.TAG) {
      return foldersTrash;
    }
    return foldersTrash
      .filter((folder) => {
        if (filters?.COLOR) {
          return folder.color === filters.COLOR;
        }
        return folder;
      })
      .filter((folder) => {
        if (filters?.EMO) {
          return folder.emo === filters.EMO;
        }
        return folder;
      })
      .filter((folder) => {
        if (filters?.FIGURE) {
          return folder.fig === filters.FIGURE;
        }
        return folder;
      })
      .filter((folder) => {
        if (filters?.TAG) {
          return folder.tags?.some((tag) => tag.toLowerCase().includes(filters.TAG.toLowerCase()));
        }
        return folder;
      });
  }, [filters.COLOR, filters.EMO, filters.FIGURE, filters.TAG, foldersTrash]);

  const filtredFiles: IFile[] = useMemo(() => {
    if (!filters.COLOR && !filters.EMO && !filters.FIGURE && !filters.TAG) {
      return filesTrash;
    }
    return filesTrash
      .filter((file) => {
        if (filters?.COLOR) {
          return file.color === filters.COLOR;
        }
        return file;
      })
      .filter((file) => {
        if (filters?.EMO) {
          return file.emo === filters.EMO;
        }
        return file;
      })
      .filter((file) => {
        if (filters?.FIGURE) {
          return file.fig === filters.FIGURE;
        }
        return file;
      })
      .filter((file) => {
        if (filters?.TAG) {
          return file.tag?.toLowerCase().includes(filters.TAG.toLowerCase());
        }
        return file;
      });
  }, [filters.COLOR, filters.EMO, filters.FIGURE, filters.TAG, filesTrash]);

  return (
    <>
      <TrashServicePanelFiles
        isSelect={isSelect}
        toggleSelect={toggleSelect}
        filters={filters}
        setFilters={setFilters}
      />
      <div className={styles.wrap}>
        {/* TODO -mk- replace with a component when the redesign is ready */}
        {filtredFiles.map((file) => (
          <span key={file.fid} className={styles.file}>
            {file.name}
          </span>
        ))}

        {filtredFolders.map((folder) => (
          <FolderBox key={folder.id_dir} folder={folder} />
        ))}
      </div>
    </>
  );
};

export default TrashFiles;
