import { ReactComponent as EmojiIcon } from "assets/emojis/happy-3.svg";
import { ReactComponent as FigureIcon } from "assets/figures/heart-13.svg";
import { ReactComponent as ColorIcon } from "assets/PrivateCabinet/colors.svg";
import { ReactComponent as FilterIcon } from "assets/PrivateCabinet/filter.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import SignItem from "generalComponents/Elements/SignItem";
import Tag from "generalComponents/Elements/Tag";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { EMOJIS, SIGNS } from "generalComponents/variables/elements";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { TagViewTypes } from "models/generalComponents/elements";
import { IFilterCriteriaState } from "models/store/filterCriteria/filterCriteria";
import React, { useEffect, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { useLocales } from "react-localized";
import { useFilterCriteriaSelector } from "Store/selectors/filterCriteriaSelectors";
import { useUserFiltersSelector } from "Store/selectors/usersFilters";

import styles from "./FilterElements.module.sass";

interface IFilterElementsProps {
  size?: number;
}

const FilterElements: React.FC<IFilterElementsProps> = ({ size }): JSX.Element => {
  const { __ } = useLocales();
  const { userFilters } = useUserFiltersSelector();
  const { filterCriteria } = useFilterCriteriaSelector();
  const { onAddFilterCriteria, onNullifyFilterCriteria } = useActions();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<IFilterCriteriaState>({
    color: filterCriteria.color,
    figure: filterCriteria.figure,
    emo: filterCriteria.emo,
    tags: filterCriteria.tags
  });
  const target = useRef<HTMLButtonElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  useOutsideClick(divRef, () => setIsOpen(false));

  const selectTag = (el: string): void => {
    setSelected((prev) => ({
      ...prev,
      tags: prev.tags.includes(el) ? prev.tags.filter((tag) => tag !== el) : [...prev.tags, el]
    }));
  };
  const selectColor = (el: string): void => {
    setSelected((prev) => ({ ...prev, color: prev.color === el ? NO_VALUE : el }));
  };
  const selectFigure = (el: SIGNS): void => {
    setSelected((prev) => ({ ...prev, figure: prev.figure === el ? SIGNS.NO_SIGN : el }));
  };
  const selectEmoji = (el: EMOJIS): void => {
    setSelected((prev) => ({ ...prev, emo: prev.emo === el ? EMOJIS.NO_EMOJI : el }));
  };

  const onOpen = (): void => setIsOpen(true);

  const isSelected = (): boolean => {
    return (
      Boolean(filterCriteria.color) ||
      Boolean(filterCriteria.emo) ||
      Boolean(filterCriteria.figure) ||
      Boolean(filterCriteria.tags)
    );
  };

  const handleClear = (): void => {
    onNullifyFilterCriteria();
    setIsOpen(false);
  };

  const handleSubmit = (): void => {
    onAddFilterCriteria(selected);
    setIsOpen(false);
  };

  const renderTags = (): JSX.Element => {
    return (
      <>
        <ul className={styles.list}>
          <li className={styles.title}>#{__("Тег")}</li>
          {userFilters.tags.map((tag, i) => (
            <li key={i}>
              <button onClick={() => selectTag(tag)}>
                <Tag variant={selected.tags.includes(tag) ? TagViewTypes.BLUE : TagViewTypes.GREY}>{`#${tag}`}</Tag>
              </button>
            </li>
          ))}
        </ul>
      </>
    );
  };

  const renderColors = (): JSX.Element => {
    return (
      <>
        <ul className={styles.list}>
          <li className={styles.title}>
            <ColorIcon />
          </li>
          {userFilters.colors.map((el, i) => (
            <li key={i}>
              <button
                onClick={() => selectColor(el)}
                className={classNames(styles.btnItem, { [styles.activeItem]: selected.color === el })}
              >
                <div
                  className={styles.color}
                  style={{
                    background: el
                  }}
                />
              </button>
            </li>
          ))}
        </ul>
      </>
    );
  };

  const renderFigures = (): JSX.Element => {
    return (
      <>
        <ul className={styles.list}>
          <li className={styles.title}>
            <FigureIcon className={styles.defaultFig} width={16} />
          </li>
          {userFilters.figures.map((fig, i) => (
            <li key={i}>
              <button
                className={classNames(styles.btnItem, { [styles.activeItem]: selected.figure === fig })}
                onClick={() => selectFigure(fig)}
              >
                <SignItem sign={fig} size={20} />
              </button>
            </li>
          ))}
        </ul>
      </>
    );
  };

  const renderEmojis = (): JSX.Element => {
    return (
      <>
        <ul className={styles.list}>
          <li className={styles.title}>
            <EmojiIcon className={styles.defaultEmo} width={16} />
          </li>
          {userFilters.emojis.map((emo, i) => (
            <li key={i}>
              <button
                className={classNames(styles.btnItem, { [styles.activeItem]: selected.emo === emo })}
                onClick={() => selectEmoji(emo)}
              >
                <span dangerouslySetInnerHTML={{ __html: emo }} />
              </button>
            </li>
          ))}
        </ul>
      </>
    );
  };

  const renderFilters = (): JSX.Element => {
    return (
      <div className={styles.container}>
        {Boolean(userFilters.tags) && renderTags()}
        {Boolean(userFilters.colors) && renderColors()}
        {Boolean(userFilters.emojis) && renderEmojis()}
        {Boolean(userFilters.figures) && renderFigures()}
        <div className={styles.btns}>
          {isSelected() && (
            <Button
              type="button"
              variant={ButtonVariantType.LIGHT}
              size={ButtonSizeType.LARGE}
              onClick={handleClear}
              text={__("Очистить")}
            />
          )}
          <Button
            type="button"
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.LARGE}
            onClick={handleSubmit}
            text={__("Применить")}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    setSelected({
      color: filterCriteria.color,
      figure: filterCriteria.figure,
      emo: filterCriteria.emo,
      tags: filterCriteria.tags
    });
  }, [isOpen]); // eslint-disable-line

  return (
    <>
      <button
        className={classNames(styles.button, { [styles.open]: isOpen || isSelected() })}
        style={{ height: size }}
        title={__("Фильтр")}
        ref={target}
        onClick={onOpen}
      >
        <FilterIcon className={styles.icon} />
      </button>
      <Overlay placement={"auto"} rootClose show={isOpen} flip target={target.current}>
        <Popover className={styles.popoverRoot} style={{ maxWidth: 290 }} id="popover-context-menu">
          <div ref={divRef}>
            <Popover.Header className={styles.popoverHeader}>{__("Фильтр")}</Popover.Header>
            <Popover.Body className={styles.popoverBody}>{renderFilters()}</Popover.Body>
          </div>
        </Popover>
      </Overlay>
    </>
  );
};

export default FilterElements;
