import { ReactComponent as LockIcon } from "assets/PrivateCabinet/lock.svg";
import Button from "generalComponents/Button/Button";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import CombinedElements from "generalComponents/Elements/CombinedElements";
import Input from "generalComponents/Input/Input";
import TextEditorNote from "generalComponents/TextEditorNote/TextEditorNote";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { TASK_MODALS, TASK_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { IAddNoteProps } from "models/store/Cabinet/modals/taskModals";
import { IAddTaskPayload, IEditTaskPayload } from "models/store/tasks/tasksPayloads";
import { ChangeEvent, FC, useState } from "react";
import { useLocales } from "react-localized";

import styles from "./AddNote.module.sass";

const AddNote: FC<IAddNoteProps> = ({ type, params, closeModal }): JSX.Element => {
  const { __ } = useLocales();
  const { onAddNewTask, onSetTaskModal, onEditTask } = useActions();
  const [name, setName] = useState<string>(params.name || "");
  const [prim, setPrim] = useState<string>(params.prim || "");
  const [tags, setTags] = useState<string[]>(params.tags ? params.tags : []);
  const [color, setColor] = useState<string>(params.color);
  const [emo, setEmo] = useState<string>(params.emo ? params.emo : "");

  const onSubmit = (): void => {
    if (type === TASK_MODALS.ADD_NOTE) {
      const payload: IAddTaskPayload = {
        id_type: TASK_TYPES.NOTES,
        name: name,
        prim: prim,
        tags,
        color,
        emo
      };
      onAddNewTask(payload);
    }
    if (type === TASK_MODALS.EDIT_NOTE) {
      const payload: IEditTaskPayload = {
        id_task: params.id,
        name: name !== params.name ? name : undefined,
        prim: prim !== params.prim ? prim : undefined,
        tags: tags !== params.tags ? tags : [],
        color,
        emo: emo !== params.emo ? emo : undefined
      };
      onEditTask(payload, EVENT_TYPE.EDIT);
    }
  };

  const onAddPassword = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.ADD_PASSWORD,
      params: { note: params, callbackType: type }
    });
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);
  const onChangePrim = (value: string) => setPrim(value);

  return (
    <div className={styles.addNoteWrap}>
      <Input value={name} onChange={onChangeName} placeholder={__("Имя заметки")} />
      <div className={styles.editorWrap}>
        <TextEditorNote content={prim} setContent={onChangePrim} placeholder={__("Текст заметки")} />
        <div className={styles.btnPass}>
          <ButtonIcon icon={<LockIcon />} variant="white" handleClick={onAddPassword} />
        </div>
      </div>
      <CombinedElements
        tags={tags}
        onSelectTags={setTags}
        color={color}
        setColor={setColor}
        emo={emo}
        setEmo={setEmo}
      />
      <div className={styles.btns}>
        <Button type="button" variant={ButtonVariantType.CANCEL} text={__("Отмена")} onClick={closeModal} />
        <Button
          type="button"
          variant={ButtonVariantType.OK}
          text={type === TASK_MODALS.ADD_NOTE ? __("Добавить") : __("Сохранить")}
          onClick={onSubmit}
          disabled={!name || !prim}
        />
      </div>
    </div>
  );
};

export default AddNote;
