import { ReactComponent as SuccessIcon } from "assets/PrivateCabinet/new-success.svg";
import Button from "generalComponents/Button/Button";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import React from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import PopUp from "../PopUp/PopUp";
import styles from "./SuccessModal.module.sass";

const SuccessModal = (): JSX.Element => {
  const { __ } = useLocales();
  const { onSetSuccessModal } = useActions();

  const {
    success: { message, title, icon }
  } = useGlobalModalsSelectors();

  const closeSuccess = (): void => {
    onSetSuccessModal({
      open: false,
      title: "",
      message: ""
    });
  };

  return (
    <PopUp set={closeSuccess}>
      <div className={styles.successWrap}>
        {icon || <SuccessIcon className={styles.check} />}
        <div className={styles.body}>
          {title && <h3 className={styles.title}>{title}</h3>}
          {message && <p className={styles.text}>{message}</p>}
          <div className={styles.buttonsWrap}>
            <Button
              type="button"
              className={styles.button}
              variant={ButtonVariantType.OK}
              text={__("Готово")}
              onClick={closeSuccess}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default SuccessModal;
