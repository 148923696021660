import api from "api";
import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { MAILBOX_DOMAIN } from "generalComponents/variables/global";
import { useValidCheckPass, useValidPass } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { FC, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { onHideText } from "utils/onHideText";

import AlertModal from "../../AlertModal/AlertModal";
import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./PasswordRestore.module.sass";

interface Props {
  closeModal: () => void;
}

const PasswordRestore: FC<Props> = ({ closeModal }) => {
  const { __ } = useLocales();
  const { uid, userInfo } = useUserSelectors();
  const { postboxes } = usePostboxSelectors();
  const { onSetTopMessageModal, onChangePasswordPostbox } = useActions();
  const { value: email, onChange: onChangeEmail, error: emailError, onError: onEmaiError } = useInputValue();
  const { value: code, onChange: onChangeCode, error: codeError, onError: onCodeError } = useInputValue();
  const { value: pass, onChange: onChangePass, error: passError, onError: onPassError } = useInputValue();
  const { value: checkPass, onChange: onChangeCheckPass, error: checkError, onError: onCheckError } = useInputValue();
  const [error, setError] = useState<string>(emailError || codeError || passError || checkError || "");

  const [step, setStep] = useState<string>("email");
  const onValidPass = useValidPass();
  const onValidCheckPass = useValidCheckPass();

  useEffect(() => {
    if (emailError) {
      setError(emailError);
      return;
    }
    if (codeError) {
      setError(codeError);
      return;
    }
    if (passError) {
      setError(passError);
      return;
    }
    if (checkError) {
      setError(checkError);
      return;
    }
    setError("");
  }, [emailError, codeError, passError, checkError]);

  const onSendCode = async (): Promise<void> => {
    try {
      const params = {
        uid,
        email: `${email}${MAILBOX_DOMAIN}`
      };
      const { data } = await api.get("/ajax/email_pass_restore.php", { params });
      if (data.error === "email not exist") {
        return onEmaiError(__("Токой Email не существует, проверьте правильность ввода"));
      }
      checkResponseStatus(data.ok);
      setStep("code");
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR,
        message: __("Что-то пошло не так. Попробуйте позже")
      });
    }
  };
  const onRestore = async (): Promise<void> => {
    try {
      const params = {
        uid,
        email: `${email}${MAILBOX_DOMAIN}`,
        pass,
        code
      };
      const { data } = await api.get("/ajax/email_pass_set.php", { params });

      if (data.error) {
        return onCodeError(__("Неверный код"));
      }
      checkResponseStatus(data.ok);
      if (postboxes.some((el) => el.email === `${email}${MAILBOX_DOMAIN}`)) {
        onChangePasswordPostbox(pass, `${email}${MAILBOX_DOMAIN}`);
      }

      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.SUCCESS,
        message: __("Пароль был изменен")
      });
      closeModal();
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR,
        message: __("Что-то пошло не так. Попробуйте позже")
      });
    }
  };
  return (
    <div className={styles.wrap}>
      <HeaderModal onClose={closeModal} title={__("Восстановление пароля ")} />
      {step === "email" && (
        <>
          {error && <AlertModal variant="red" text={error} />}
          <div className={styles.body}>
            <p className={styles.text}>{__("Введите email к которому хотите восстановить пароль")}</p>
            <Input
              value={email}
              onChange={onChangeEmail}
              label="Email"
              placeholder={__("Введите")}
              rightText={MAILBOX_DOMAIN}
            />
          </div>
        </>
      )}
      {step === "code" && (
        <>
          <AlertModal
            variant={error ? "red" : "green"}
            text={error ? error : __(`На ${onHideText(userInfo.email)} отправлен проверочный код, введите его ниже`)}
          />
          <div className={styles.body}>
            <Input onChange={onChangeCode} value={code} placeholder="Введите" label="Код" require />
            <Input
              onChange={onChangePass}
              value={pass}
              placeholder="Введите"
              label="Пароль"
              require
              isPass
              onBlur={() => onValidPass(pass, onPassError)}
            />
            <Input
              onChange={onChangeCheckPass}
              value={checkPass}
              placeholder="Введите"
              label="Повторите пароль"
              isPass
              require
              onBlur={() => onValidCheckPass(pass, checkPass, onCheckError)}
            />
          </div>
        </>
      )}
      <div className={styles.btns}>
        <Button
          type="button"
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.SMALL}
          text={__("Отменить")}
          onClick={closeModal}
        />
        <Button
          type="submit"
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.SMALL}
          text={__("Восстановить")}
          onClick={step === "email" ? onSendCode : onRestore}
          disabled={step === "email" ? !email : !code || !pass || !checkPass}
        />
      </div>
    </div>
  );
};

export default PasswordRestore;
