export type SystemPostboxFolders = Record<POSTBOX_SYSTEM_FOLDER, ISystemPostboxFolder>;

export enum POSTBOX_SYSTEM_FOLDER {
  INBOX = "INBOX",
  Sent = "Sent",
  ARCHIVE = "ARCHIVE",
  Drafts = "Drafts",
  LIKED = "LIKED",
  IMPORTANT = "IMPORTANT",
  SCHEDULED = "SCHEDULED",
  Spam = "Spam",
  Trash = "Trash"
}

export interface ISystemPostboxFolder {
  name: string;
  img: React.ReactNode;
}
