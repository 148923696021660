import freeice from "freeice";
import { useActions } from "hooks/useActions";
import {
  CustomMediaStreamTrack,
  IGetMedia,
  IHandleNewPeer,
  ISetRemoteMedia,
  IUseWebRTCSetConnectionServices
} from "models/generalComponents/hooks/WebRTC/useWebRTCSetConnectionServices";
import { useCallback } from "react";

import {
  IReconnectUserParams,
  ISendSocketParamsRelayIce
} from "../../../models/generalComponents/hooks/WebRTC/useWebRTCSocketMessages";
import { MediKinds } from "../../../models/generalComponents/hooks/WebRTC/WebRTCProvider";
import { useChatSelectors } from "../../../Store/selectors/chatSelectors";
import { useUserSelectors } from "../../../Store/selectors/userSelectors";
import { createUniqueArrayOfValues } from "../../Services/chatServices";
import { createWhiteSheetDataUrl } from "../../Services/mediaServices";
import { CHAT_CALLROOM, CHAT_CALLROOM_ACTIONS, LOCAL_CLIENT, VIDEO } from "../../variables/chat";
import { useWebSocketContext } from "../../WebSocketsProvider/WebSocketsProvider";
import { useWebRTCCheckServices } from "./useWebRTCCheckServices";
import { useWebRTCContext } from "./WebRTCProvider";

export function useWebRTCSetConnectionServices(): IUseWebRTCSetConnectionServices {
  const {
    peerConnections,
    peerMediaElements,
    localMediaStream,
    minimizedMediaElement,
    sender,
    politeRef,
    peerScreenElements,
    screenStreams,
    sendSocket,
    setMakingOffer,
    media,
    setMedia,
    clients,
    updateClients,
    makingOffer,
    remoteStreams
  } = useWebRTCContext();
  const { setCallRoomState } = useActions();
  const { callRoom } = useChatSelectors();
  const { userInfo } = useUserSelectors();
  const { socket } = useWebSocketContext();
  const { setCallRoomConnectedUsers, onSetPreviewFileModal } = useActions();
  const { setIsLoadingUsers } = useWebRTCContext();
  const { checkWebRTCConnection, checkPeerConnection, checkPeerMediaElements, checkClients } = useWebRTCCheckServices();

  // Получение медиаэлементов для переключения потоков
  const getMedia = useCallback(
    (senderList: RTCRtpSender[]): IGetMedia => {
      let newMedia = { audio: media.audio, video: false, screen: false };
      senderList.forEach((sender) => {
        const track = sender?.track as CustomMediaStreamTrack;
        if (track?.info === "SCREEN") {
          newMedia.screen = true;
        } else {
          if (sender?.track?.kind && sender?.track?.enabled) {
            newMedia[sender.track.kind as MediKinds] = true;
          }
        }
      });

      const action = CHAT_CALLROOM_ACTIONS.SCREEN_SHARE;
      // TODO - mkortelov - need to check for video switch old media state is used
      // media.screen !== newMedia.screen ? CHAT_CALLROOM_ACTIONS.SCREEN_SHARE : CHAT_CALLROOM_ACTIONS.SWITCH_VIDEO;

      return { newMedia, action };
    },
    [media]
  );

  // Добавления треков к пиру
  const addTrack = useCallback(
    (peerID: string): void => {
      localMediaStream.current.getTracks().forEach((track) => {
        const t = peerConnections.current[peerID].addTrack(track, localMediaStream.current);
        if (!sender.current[peerID]) {
          sender.current[peerID] = null;
        }
        sender.current[peerID] = {
          ...sender.current[peerID],
          [track.kind]: t
        };
      });
    },
    // eslint-disable-next-line
    [localMediaStream, peerConnections]
  );

  // добавление нового пользователя в комнате
  const addNewClient = useCallback(
    (newClient: string, cb: () => void): void => {
      updateClients((list) => {
        if (!list.includes(newClient)) {
          return [...list, newClient];
        }

        return list;
      }, cb);
    },
    //eslint-disable-next-line
    [clients, updateClients]
  );

  // добавление медиаэлементов
  const switchScreenElement = useCallback(
    (remoteStream: MediaStream, peerID: string): void => {
      const tracks = remoteStream.getTracks();
      if (Object.values(tracks).length === 1) {
        peerScreenElements.current[peerID].srcObject = remoteStream;
        if (minimizedMediaElement.current) {
          minimizedMediaElement.current.volume = 0;
          minimizedMediaElement.current.srcObject = remoteStream;
        }
        screenStreams.current[peerID] = remoteStream;
      }
    },
    [peerScreenElements, screenStreams, minimizedMediaElement]
  );

  // Добавляет нового пира и все слушатели событий для этого пира
  const handleNewPeer = useCallback(
    async ({ peerID, createOffer }: IHandleNewPeer): Promise<void> => {
      if (peerID in peerConnections.current) {
        console.warn(`Already connected to peer ${peerID}`);
        return;
      }

      // Получение данных об устройстве с использоваием бесплатного сервера
      peerConnections.current[peerID] = await new RTCPeerConnection({
        iceServers: freeice()
      });

      // Ответ для второй стороны при получениии данных айс кандидата
      peerConnections.current[peerID].onicecandidate = (event) => {
        if (event.candidate) {
          sendSocket({
            action: CHAT_CALLROOM_ACTIONS.RELAY_ICE,
            users_to: [peerID],
            iceCandidate: event.candidate
          } as ISendSocketParamsRelayIce);
        }
      };

      // Если пирсоединение успешно установлено - меняет состояние комнаты на подключенное
      peerConnections.current[peerID].onconnectionstatechange = (ev) => {
        const currentTarget = ev.currentTarget as RTCPeerConnection;
        console.log("currentTarget", peerID, currentTarget);
        if (currentTarget.connectionState === "connected") {
          console.info("connected to conversation");
          setCallRoomState(CHAT_CALLROOM.JOINED);
        }
      };

      // отлавливает ошибки айскандидадтов (возможны ошибки, но при успешном обмене данных остальные айскандидаты игнорирутся, как следствие возникает ошибка)
      peerConnections.current[peerID].onicecandidateerror = async (e) => {
        console.log("error", e);
      };

      // Кореектирует добавление медипотока с экраном
      let initialCall = true;

      // срабатывает при любом изменения sessionDescription
      peerConnections.current[peerID].onnegotiationneeded = async (event) => {
        const currentTarget = event.currentTarget as RTCPeerConnection;

        if (!initialCall) {
          const { newMedia, action } = getMedia(currentTarget.getSenders());
          setMedia(newMedia);
          try {
            setMakingOffer(true);
            const offer = await peerConnections.current[peerID].createOffer();
            if (peerConnections.current[peerID].signalingState !== "stable") {
              return;
            }
            await peerConnections.current[peerID].setLocalDescription(offer);
            politeRef.current[peerID] = true;
            sendSocket({
              action,
              users_to: [peerID],
              from: {
                media: newMedia
              },
              polite: !politeRef.current[peerID],
              sessionDescription: offer
            });
          } catch (e) {
            console.log(`ONN ${e}`);
          } finally {
            setMakingOffer(false);
          }
        }
        initialCall = false;
      };

      // При добавлении медиапотоков - пользователя становиться видно в комнате
      peerConnections.current[peerID].ontrack = ({ streams: [remoteStream] }) => {
        switchScreenElement(remoteStream, peerID);
        addNewClient(peerID, () => {
          console.log("ONTRACK", peerID);
          // FIX LONG RENDER IN CASE OF MANY CLIENTS
          let settled = false;
          window.callRoomReconnection = {};
          window.callRoomReconnection[peerID] = setInterval(() => {
            console.log("reconnection interval");
            if (peerMediaElements.current[peerID]) {
              peerMediaElements.current[peerID].srcObject = remoteStream;
              minimizedMediaElement.current.volume = 0;
              minimizedMediaElement.current.srcObject = remoteStream;
              remoteStreams.current[peerID] = remoteStream;
              settled = true;
            }

            if (settled) {
              clearInterval(window.callRoomReconnection[peerID]);
            }
          }, 1000);
        });
      };

      addTrack(peerID);

      // при первой установки соединения между клиентами идет создание и отправка офера
      if (createOffer) {
        const offer = await peerConnections.current[peerID].createOffer();

        await peerConnections.current[peerID].setLocalDescription(offer);
        sendSocket({
          action: CHAT_CALLROOM_ACTIONS.RELAY_SDP,
          users_to: [peerID],
          from: {
            media: {
              audio: true,
              video: callRoom.initialCallType === CHAT_CALLROOM.VIDEO_CALL
            },
            handUp: false
          },
          sessionDescription: offer
        });
      }
    },
    //eslint-disable-next-line
    [addNewClient, socket, clients, peerConnections, peerMediaElements]
  );

  // При получении sessionDescription от удаленного пользователя добавляется информация в пир соединение
  // !!!! Если сразу несколько запросов - срабатывает rollback
  async function setRemoteMedia({ peerID, sessionDescription, peer, initialConnection }: ISetRemoteMedia) {
    const offerCollision =
      sessionDescription.type === "offer" &&
      (makingOffer || peerConnections.current[peerID]?.signalingState !== "stable");

    const ignoreOffer = !politeRef.current[peerID] && offerCollision;

    console.log(ignoreOffer, !initialConnection, offerCollision, sessionDescription);
    if (ignoreOffer && !initialConnection) {
      return;
    }
    if (offerCollision) {
      await Promise.all([
        peerConnections.current[peerID].setLocalDescription({ type: "rollback" }),
        peerConnections.current[peerID].setRemoteDescription(sessionDescription)
      ]);
    } else {
      await peerConnections.current[peerID]
        .setRemoteDescription(new RTCSessionDescription(sessionDescription))
        .catch((e) => {
          console.error("Failed to set remote description", e);
        });
      setCallRoomConnectedUsers(peer);
    }

    if (sessionDescription.type === "offer") {
      politeRef.current[peerID] = false;
      try {
        const answer = await peerConnections.current[peerID].createAnswer();

        await peerConnections.current[peerID].setLocalDescription(answer);

        sendSocket({
          action: CHAT_CALLROOM_ACTIONS.RELAY_SDP,
          users_to: [peerID],
          from: {
            media: {
              audio: true,
              video: callRoom.callType === CHAT_CALLROOM.VIDEO_CALL
            },
            handUp: false
          },
          sessionDescription: answer
        });
      } catch (error) {
        console.log("Answer is not created", error);
      }
    }
  }

  // Полное отключение пользователя и разрыв всех соединений
  function handleRemovePeer({ peerID }: { peerID: string }) {
    if (peerConnections.current[peerID]) {
      peerConnections.current[peerID].close();
    }

    console.log("handleRemovePeer".toUpperCase(), "tracks stopped");
    if (remoteStreams.current[peerID] && remoteStreams.current[peerID].getTracks()) {
      remoteStreams.current[peerID].getTracks().forEach((track) => {
        track.stop();
      });
    }

    delete peerConnections.current[peerID];
    delete peerMediaElements.current[peerID];
    delete peerScreenElements.current[peerID];
    delete politeRef.current[peerID];
    delete screenStreams.current[peerID];
    delete sender.current[peerID];
    delete remoteStreams.current[peerID];

    updateClients((c) => c.filter((it) => it !== peerID));
  }

  // Запрос на переподключение
  const askForReconnection = (users: string[]) => {
    const message = {
      action: CHAT_CALLROOM_ACTIONS.ASK_RECONNECTION,
      users_to: users
    };
    sendSocket(message);
  };

  // Экшн, который срабатывает при переподключении пользователей
  const reconnectUsers = (users: string[]) => {
    if (callRoom.state === CHAT_CALLROOM.JOINED || callRoom.state === CHAT_CALLROOM.ACCEPTED) {
      setIsLoadingUsers(true);
    }
    const notConnectedPeers = createUniqueArrayOfValues([
      ...checkPeerConnection(users, Object.keys(peerConnections.current)),
      ...checkWebRTCConnection(users),
      ...checkPeerMediaElements(users),
      ...checkClients(users)
    ]);
    if (notConnectedPeers.length > 0) {
      // console.log(`Reconnecting users:`, notConnectedPeers);
      notConnectedPeers.forEach((it) => {
        handleRemovePeer({ peerID: it });
      });
      askForReconnection(notConnectedPeers);
    } else {
      setIsLoadingUsers(false);
    }
  };

  // Открытие комнаты рисования
  const openPaintRoom = () => {
    createWhiteSheetDataUrl().then((dataUrl) => {
      if (typeof dataUrl === "string") {
        onSetPreviewFileModal({
          open: true,
          file: {
            preview: dataUrl,
            mime_type: "image/png",
            ext: "PNG",
            is_preview: 1,
            fid: "printScreen"
          },
          message: null
        });
      }
    });
  };

  // Создание локальной информации для установки соедениенеия и передачи данных другим клиентам
  async function startCall(isVideo: boolean): Promise<void> {
    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: 1920,
        height: 1080
      }
    });

    if (!isVideo) {
      localMediaStream.current
        .getTracks()
        .forEach((it) => (it.kind === VIDEO ? (it.enabled = false) : (it.enabled = true)));
    }

    addNewClient(LOCAL_CLIENT, () => {
      const localVideoElement = peerMediaElements.current[LOCAL_CLIENT];

      if (localVideoElement) {
        localVideoElement.volume = 0;
        localVideoElement.srcObject = localMediaStream.current;
        minimizedMediaElement.current.volume = 0;
        minimizedMediaElement.current.srcObject = localMediaStream.current;
      }
    });
  }

  // Функция в ответ на принятия вызова - открывает комнату разговора
  const acceptCall = () => {
    sendSocket({
      action: CHAT_CALLROOM_ACTIONS.ACCEPT_CALL,
      id_group: callRoom.id_group, // TODO - mkortelov - useless field ???
      room_id: callRoom.room_id,
      createOffer: false
    });

    setCallRoomConnectedUsers({
      // state: CHAT_CALLROOM.ACCEPTED,
      icon: callRoom.icon,
      id_user: userInfo.id,
      name: userInfo.fname,
      sname: userInfo.sname,
      media: {
        audio: true,
        video: callRoom.initialCallType === CHAT_CALLROOM.VIDEO_CALL
      },
      handUp: false
    });
  };

  // Обновление медиа данных
  function reloadMediaStream(clientID: string): void {
    peerMediaElements.current[clientID].srcObject = remoteStreams.current[clientID];
  }

  // Повторный запро на отправку медиастримов
  function askMediaStream(clientID: string): void {
    sendSocket({
      action: CHAT_CALLROOM_ACTIONS.REQUEST_MEDIA_STREAM,
      users_to: [clientID]
    });
  }

  // Функция которая срабатывает на экшн RECONNECT_PEER
  function reconnectPeer(clientID: string): void {
    handleRemovePeer({ peerID: clientID });

    setTimeout(async () => {
      await handleNewPeer({ peerID: clientID, createOffer: false }).then(() => {
        sendSocket({
          action: CHAT_CALLROOM_ACTIONS.REQUEST_MEDIA_STREAM,
          users_to: [clientID],
          isReconnect: true
        } as IReconnectUserParams);
      });
    }, 1000);
  }

  return {
    handleNewPeer,
    setRemoteMedia,
    handleRemovePeer,
    reconnectUsers,
    openPaintRoom,
    startCall,
    acceptCall,
    reloadMediaStream,
    askMediaStream,
    reconnectPeer
  };
}
