import classNames from "classnames";
import { PlacesType, Tooltip } from "react-tooltip";

import styles from "./CustomTooltip.module.sass";

const CustomTooltip = ({
  id,
  bgColor,
  textColor,
  title,
  text,
  onClick = false,
  place
}: {
  id: string;
  bgColor?: "orange" | "grey";
  textColor?: string;
  title?: string;
  text?: string;
  onClick?: boolean;
  place?: PlacesType;
}) => {
  return (
    <Tooltip
      id={id}
      className={classNames(styles.tooltip, styles[`tooltip_${bgColor}`])}
      classNameArrow={classNames(styles.arrow, styles[`arrow_${bgColor}`])}
      data-tooltip-place="right"
      openOnClick={onClick}
      place={place}
      opacity={1}
    >
      <div className={styles.wrapper}>
        {title && (
          <h6 style={{ color: textColor ? textColor : `var(--color-d900)` }} className={styles.title}>
            {title}
          </h6>
        )}
        {text && (
          <p
            style={{ color: textColor ? textColor : `var(--color-d900)` }}
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default CustomTooltip;
