import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/create_arrow.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/plus-3.svg";
import Button from "generalComponents/Button/Button";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import React from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";

import { onSetModals } from "../../../../../../Store/actions/ModalActions";
import InfoBlock from "../InfoBlock/InfoBlock";
import Roles from "../Roles/Roles";
import styles from "./ProjectRoles.module.sass";

const ProjectRoles = () => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const project = useSelector((s) => s.Projects.project);

  const onAddRolles = () =>
    dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.ADD_ROLES, params: { ...project } }));

  return (
    <div className={styles.rolesWrap}>
      <Roles />
      {Number(project.is_read) > 0 && (
        <div className={styles.addRole}>
          <Button
            type="button"
            variant="addGrey"
            text={__("Добавить роль")}
            icon={<PlusIcon className={styles.plusIcon} />}
            full={true}
            onClick={onAddRolles}
          />
          {project.roles?.length === 0 && (
            <>
              <ArrowIcon className={styles.creaeteArrow} />
              <div className={styles.info}>
                <InfoBlock
                  title={__("Добавить роль")}
                  text={__(
                    "Описание категории Lorem Ipsum - это текст- 'рыба', часто используемый в печати и вэб-дизайне. Lorem Ipsum является cтандартной 'рыбой' для текстов на латинице с начала XVI века."
                  )}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectRoles;
