import { ReactComponent as CalendarIcon } from "assets/PrivateCabinet/calendar-6.svg";
import { ReactComponent as CloseIcon } from "assets/PrivateCabinet/close.svg";
import { ReactComponent as SearchIcon } from "assets/PrivateCabinet/search.svg";
import classNames from "classnames";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import Calendar from "generalComponents/Calendars/Calendar/Calendar";
import PopUp from "generalComponents/PopUp/PopUp";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import styles from "./TasksSearch.module.sass";

const TasksSearch = ({ searchValue, handleChange, dateValue, setDateValue }) => {
  const [openSearch, setOpenSearch] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const inputRef = useRef(null);

  const handleOpenInputSearch = () => {
    !openSearch ? inputRef.current.focus() : inputRef.current.blur();
    setOpenSearch((open) => (open ? false : true));
    handleChange("");
  };

  const handleOpenInputCalendar = () => {
    setOpenCalendar((open) => (open ? false : true));
    setDateValue("");
    if (!openCalendar) {
      setShowCalendar(true);
    }
  };

  const inputChange = ({ target }) => handleChange(target.value);

  const handleOpenCalendar = () => setShowCalendar((open) => !open);
  return (
    <>
      <input
        value={searchValue}
        onChange={inputChange}
        type="text"
        className={classNames(styles.input, { [styles.visible]: openSearch, [styles.hidden]: !openSearch })}
        ref={inputRef}
      />
      <ButtonIcon handleClick={handleOpenInputSearch} variant="grey" icon={<SearchIcon />} isActive={openSearch} />

      <input
        value={dateValue}
        type="text"
        className={classNames(styles.input, {
          [styles.visible]: dateValue,
          [styles.hidden]: !dateValue
        })}
        readOnly
        onClick={handleOpenCalendar}
      />
      {dateValue ? (
        <div className={styles.btn} onClick={handleOpenInputCalendar}>
          <CloseIcon width={15} height={15} />
        </div>
      ) : (
        <ButtonIcon handleClick={handleOpenInputCalendar} variant="grey" icon={<CalendarIcon />} />
      )}

      {showCalendar && (
        <PopUp set={handleOpenCalendar}>
          <Calendar setDateValue={setDateValue} setShowCalendar={handleOpenCalendar} />
        </PopUp>
      )}
    </>
  );
};

export default TasksSearch;

TasksSearch.propTypes = {
  searchValue: PropTypes.string,
  handleChange: PropTypes.func,
  setDateValue: PropTypes.func,
  dateValue: PropTypes.string
};
