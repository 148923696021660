import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as FireIcon } from "assets/icons/fire.svg";
import classNames from "classnames";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useTaskPriority } from "hooks/joinProjectHooks";
import { TASK_PRIORITY } from "models/store/joinProjects/joinProgects";
import { FC, MouseEvent, useState } from "react";

import styles from "./TaskPriority.module.sass";

interface IProps {
  priority: TASK_PRIORITY;
  setPriority: (val: TASK_PRIORITY) => void;
  readOnly?: boolean;
}

const TaskPriority: FC<IProps> = ({ priority, setPriority, readOnly = false }) => {
  const [show, setShow] = useState<boolean>(false);
  const { taskPriority } = useTaskPriority();

  const handleChange = (value: TASK_PRIORITY): void => {
    setPriority(value);
    setShow(false);
  };

  const renderOverlay = () => {
    return (
      <ul>
        <li className={styles.item} onClick={() => handleChange(TASK_PRIORITY.MINOR)}>
          {taskPriority[TASK_PRIORITY.MINOR]}
        </li>
        <li className={styles.item} onClick={() => handleChange(TASK_PRIORITY.NORMAL)}>
          {taskPriority[TASK_PRIORITY.NORMAL]}
        </li>
        <li className={classNames(styles.item, styles.itemUrgent)} onClick={() => handleChange(TASK_PRIORITY.URGENT)}>
          <FireIcon />
          &nbsp;
          {taskPriority[TASK_PRIORITY.URGENT]}
        </li>
      </ul>
    );
  };

  return (
    <PopoverOverlay show={show} overlayContent={renderOverlay()} setShow={setShow} placement="bottom" btn="extra">
      <div
        className={classNames(styles.label, {
          [styles.labelUrgent]: priority === TASK_PRIORITY.URGENT,
          [styles.active]: show
        })}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          !readOnly && setShow(true);
        }}
      >
        <span>
          {priority === TASK_PRIORITY.URGENT && (
            <>
              <FireIcon />
              &nbsp;
            </>
          )}
          {taskPriority[priority]}
        </span>
        <ArrowIcon className={classNames(styles.arrow, { [styles.arrowOpen]: show })} />
      </div>
    </PopoverOverlay>
  );
};

export default TaskPriority;
