import { ILibraryCategories } from "collections/library";
import { SectionTitle } from "generalComponents/SectionTitle/SectionTitle";
import { FC } from "react";
import { useLocales } from "react-localized";

import { LibraryCategory } from "./components/LibraryCategory/LibraryCategory";
import styles from "./LibraryCategories.module.sass";

interface ILibraryCategoriesProps {
  categories: ILibraryCategories;
}

const LibraryCategories: FC<ILibraryCategoriesProps> = ({ categories }): JSX.Element => {
  const { __ } = useLocales();
  const categoriesArr = Object.values(categories);

  return (
    !!categoriesArr.length && (
      <div>
        <SectionTitle>{__("Категории")}</SectionTitle>
        <div className={styles.list}>
          {categoriesArr.map((item) => (
            <LibraryCategory key={item.category} category={item} />
          ))}
        </div>
      </div>
    )
  );
};

export { LibraryCategories };
