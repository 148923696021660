import { initialSafeState, ISafeState } from "models/store/safe/safe";
import { SafeActions } from "models/store/safe/safeActions";
import { SafeTypes } from "Store/types/safeTypes";

const INITIAL_STATE = initialSafeState();

export const SafeReducer = (state = INITIAL_STATE, action: SafeActions): ISafeState => {
  switch (action.type) {
    case SafeTypes.GET_SAFES: {
      return { ...state, safeList: action.payload };
    }
    case SafeTypes.ADD_SAFE: {
      return { ...state, safeList: [...state.safeList, action.payload] };
    }
    case SafeTypes.ADD_AUTHORIZED: {
      return { ...state, authorized: [...state.authorized, action.payload] };
    }
    case SafeTypes.REMOVE_AUTHORIZED: {
      return { ...state, authorized: state.authorized.filter((el) => el.id_safe !== action.payload) };
    }
    case SafeTypes.CLEAR_AUTHORIZED: {
      return { ...state, authorized: [] };
    }
    case SafeTypes.SAFE_FOLDERS: {
      return { ...state, folders: action.payload };
    }
    default:
      return state;
  }
};
