import { FileBox } from "generalComponents/FileBox/FileBox";
import { FolderBox } from "generalComponents/FolderBox/FolderBox";
import { ObjectElementsBox } from "generalComponents/ObjectElementsBox/ObjectElementsBox";
import { TaskBox } from "generalComponents/TaskBox/TaskBox";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IProject } from "models/store/joinProjects/joinProgects";
import { ISafe } from "models/store/safe/safe";
import { IMyTask } from "models/store/tasks/tasks";
import { AllElementsType } from "models/store/typeElements";
import { IUserInfo } from "models/store/user/user";
import { TypeElements } from "models/typeElements/typeElements";
import { FC } from "react";

interface ITypeElementBox {
  element: AllElementsType;
}

const TypeElementBox: FC<ITypeElementBox> = ({ element }) => {
  switch (element.table) {
    case TypeElements.FILES:
      return <FileBox file={element as IFile} />;

    case TypeElements.TASKS:
      return <TaskBox task={element as IMyTask} />;

    case TypeElements.PROJECT:
    case TypeElements.SAFE:
    case TypeElements.USERS:
      return <ObjectElementsBox element={element as IProject | ISafe | IUserInfo} />;

    case TypeElements.SAFE_FILES:
      return <FileBox file={element as IFile} />;

    case TypeElements.FOLDERS:
      return <FolderBox folder={element as IFolder} />;
  }
};

export { TypeElementBox };
