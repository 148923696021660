import { ReactComponent as FileNewIcon } from "assets/PrivateCabinet/file-new.svg";
import { ReactComponent as FolderIcon } from "assets/PrivateCabinet/folder.svg";
import { ReactComponent as LockedIcon } from "assets/PrivateCabinet/locked.svg";
import classNames from "classnames";
import { IItemIconProps } from "models/generalComponents/itemIcon";
import React from "react";

import styles from "./ItemIcon.module.sass";

export const ItemIcon: React.FC<IItemIconProps> = ({
  isManyItems,
  isFolder,
  width,
  height,
  color,
  passIcon,
  ext
}): JSX.Element => {
  const getFileColor = (fileType: string): string => {
    const videoFormats = ["mp4", "avi", "mkv", "mov", "wmv"]; // Форматы видео
    const wordFormats = ["doc", "docx", "rtf", "txt"]; // Форматы, которые можно открыть через Word
    const excelFormats = ["xls", "xlsx", "csv"]; // Форматы, которые можно открыть через Excel
    const archiveFormats = ["rar", "zip", "7z"]; // Форматы архивов
    const imageFormats = ["jpg", "jpeg", "png", "gif", "bmp"]; // Форматы изображений
    const pdfFormats = ["pdf", "pdfx", "pdfa"]; // PDF и похожие форматы
    const audioFormats = ["mp3", "wav", "flac", "aac"]; // Аудиоформаты

    if (videoFormats.includes(fileType?.toLowerCase())) {
      return "#FA0000";
    } else if (wordFormats.includes(fileType?.toLowerCase())) {
      return "#0263D1";
    } else if (excelFormats.includes(fileType?.toLowerCase())) {
      return "#00733B";
    } else if (archiveFormats.includes(fileType?.toLowerCase())) {
      return "#FFB11F";
    } else if (imageFormats.includes(fileType?.toLowerCase())) {
      return "lightgreen";
    } else if (pdfFormats.includes(fileType?.toLowerCase())) {
      return "#E5252A";
    } else if (audioFormats.includes(fileType?.toLowerCase())) {
      return "#9900CC";
    }
  };

  const renderPassIcon = (): JSX.Element => {
    if (passIcon) {
      return (
        <LockedIcon
          width={width ? (width * 0.25).toFixed(1) : 8}
          height={height ? (height * 0.25).toFixed(1) : 8}
          className={classNames(styles.lockedIcon, {
            [styles.isFolder]: isFolder,
            [styles.isFile]: !isFolder
          })}
          style={{ flex: `0 0 ${(width * 0.25).toFixed(1)}px` }}
        />
      );
    }
  };

  const renderItemIcon = (): JSX.Element => {
    if (isFolder) {
      return (
        <FolderIcon
          className={styles.folderIcon}
          width={width ?? 21}
          height={height ?? 17}
          color={color ? color : "#FFD873"}
          style={{ flex: `0 0 ${width}px` }}
        />
      );
    }

    return (
      <>
        <FileNewIcon
          className={styles.fileIcon}
          color={color ? color : getFileColor(ext)}
          width={width ?? 27}
          height={height ?? 27}
          style={{ flex: `0 0 ${width}px` }}
        />
        {ext && (
          <div className={styles.extInfo} style={{ fontSize: `${((width ?? 27) * 0.3).toFixed(1)}px` }}>
            {ext.toUpperCase()}
          </div>
        )}
      </>
    );
  };

  const renderIconOptions = (): JSX.Element => {
    return (
      <>
        {renderPassIcon()}
        {renderItemIcon()}
      </>
    );
  };

  const renderContent = () => {
    if (!isManyItems) {
      return renderIconOptions();
    }

    // here is another version of the icon
    return <></>;
  };

  return <div className={styles.root}>{renderContent()}</div>;
};
