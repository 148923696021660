import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { useQuestionnaireData, useSliderData } from "collections/questionnaire";
import Button from "containers/Authentication/Button/Button";
import Question from "containers/Authentication/Questionnaire/Question/Question";
import SideBar from "containers/Authentication/Questionnaire/Sidebar/SideBar";
import Slider from "containers/Authentication/Slider/Slider";
import { ROUTES } from "generalComponents/variables/routing";
import { useState } from "react";
import { useLocales } from "react-localized";
import { Link, useNavigate } from "react-router-dom";

import styles from "./Questionnaire.module.sass";

export interface IQuestion {
  title: string;
  answers: string[];
}

export interface IAnswer {
  question: string;
  answers: string[];
}

const Questionnaire = () => {
  const { __ } = useLocales();
  const [active, setActive] = useState<number>(0);
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const navigate = useNavigate();
  const questionsData = useQuestionnaireData();
  const data = useSliderData();

  const updateAnswer = (currentAnswers: string[], selectedAnswer: string) => {
    if (currentAnswers.includes(selectedAnswer)) {
      return currentAnswers.filter((item) => item !== selectedAnswer);
    } else {
      return [...currentAnswers, selectedAnswer];
    }
  };

  const onSelectAnswer = (selectedAnswer: string) => {
    const updatedAnswers = [...answers];
    const currentQuestionData = questionsData[active];

    if (currentQuestionData?.images) {
      updatedAnswers[active] = {
        question: currentQuestionData.title,
        answers: updateAnswer(answers[active]?.answers || [], selectedAnswer)
      };
    } else {
      const currentAnswers = updatedAnswers[active]?.answers || [];
      updatedAnswers[active] = {
        question: currentQuestionData.title,
        answers: currentAnswers[0] === selectedAnswer ? [] : [selectedAnswer]
      };
    }

    setAnswers(updatedAnswers);
  };

  const toggleNextButton = () => {
    if (questionsData.length === answers.length || active === questionsData.length - 1) {
      navigate(`/${ROUTES.SIGN_UP}`, { state: { answers } });
    }
    setActive((prevActive) => (prevActive < questionsData.length - 1 ? prevActive + 1 : prevActive));
  };

  const toggleBackButton = () => {
    setActive((prevActive) => (prevActive > 0 ? prevActive - 1 : 0));
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Link to="/">
            <Logo className={styles.logo} />
          </Link>
          <button onClick={toggleNextButton} className={styles.button}>
            {__("Пропустить")}
          </button>
          <button onClick={toggleBackButton} className={styles.button}>
            <Arrow />
            {__("Назад")}
          </button>
        </div>
        <Question
          length={active + 1 + "/" + questionsData.length}
          question={questionsData[active].title}
          answers={questionsData[active].answers}
          activeAnswers={answers[active]?.answers}
          onSelectAnswer={onSelectAnswer}
        />
        <div className={styles.footer}>
          <button onClick={toggleBackButton} className={styles.button}>
            <Arrow />
            {__("Назад")}
          </button>
          <div className={styles.buttons}>
            <button onClick={toggleNextButton} className={styles.button}>
              {__("Пропустить")}
            </button>
            <Button
              disabled={answers[active]?.answers?.length === 0 || !answers[active]?.answers}
              onClick={toggleNextButton}
              className={styles.next_button}
            >
              {__("Далее")}
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.slider}>
        {questionsData[active].images && answers[active]?.answers.length > 0 ? (
          <SideBar question={questionsData[active]} answer={answers[active]} />
        ) : (
          <Slider data={data} activeSlideIndex={active} autoplay={false} />
        )}
      </div>
    </div>
  );
};

export default Questionnaire;
