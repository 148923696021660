import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { MAILBOX_DOMAIN } from "generalComponents/variables/global";
import { useValidCheckPass, useValidPass } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import React from "react";
import { useLocales } from "react-localized";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./CreatePostbox.module.sass";

interface ICreatePostboxProps {
  closeModal: () => void;
}

const CreatePostbox: React.FC<ICreatePostboxProps> = ({ closeModal }): JSX.Element => {
  const { __ } = useLocales();
  const { value: name, onChange: onChangeName } = useInputValue();
  const { value: email, onChange: onChangeEmail } = useInputValue();
  const { value: pass, onChange: onChangePass, error: passError, onError: onPassError } = useInputValue();
  const { value: checkPass, onChange: onChangeCheckPass, error: checkError, onError: onCheckError } = useInputValue();
  const { onCreatePostbox } = useActions();
  const onValidPass = useValidPass();
  const onValidCheckPass = useValidCheckPass();

  const createAccountHandler = () => {
    if (pass !== checkPass) {
      onCheckError(__("Пароли не совпадают"));
      return;
    }
    const payload = {
      name: name,
      email: `${email}${MAILBOX_DOMAIN}`,
      password: pass,
      server: "",
      message: {
        title: __("Почтовый ящик успешно создан"),
        success: __(`Ваш почтовый ящик успешно создан. Вы можете пользоваться всеми услугами`),
        error_email_name_exists: __(`Почтовый ящик с таким именем уже используется`),
        error_email_unknown_domain: __("Почтовый ящик должен содержать доменное имя @fs2.mh.net.ua"),
        error_unknown_error: __("Что-то пошло не так, пожалуйста, повторите попытку позже")
      }
    };

    onCreatePostbox(payload);
  };

  return (
    <div className={styles.root}>
      <HeaderModal title={__("Email аккаунт 4Hub")} onClose={closeModal} />
      <div className={styles.body}>
        <Input onChange={onChangeName} value={name} placeholder="Введите" label="Имя" errorText="" require />
        <Input
          onChange={onChangeEmail}
          value={email}
          placeholder="Введите"
          label="Email"
          rightText={MAILBOX_DOMAIN}
          require
          errorText=""
        />
        <Input
          onChange={onChangePass}
          value={pass}
          placeholder="Введите"
          label="Пароль"
          require
          isPass
          error={Boolean(passError)}
          errorText={passError}
          onBlur={() => onValidPass(pass, onPassError)}
        />

        <Input
          onChange={onChangeCheckPass}
          value={checkPass}
          placeholder="Введите"
          label="Повторите пароль"
          isPass
          require
          error={Boolean(checkError)}
          errorText={checkError}
          onBlur={() => onValidCheckPass(pass, checkPass, onCheckError)}
        />
        <div className={styles.buttonsWrapper}>
          <Button
            type="button"
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.MEDIUM}
            text={__("Отменить")}
            onClick={closeModal}
          />
          <Button
            type="submit"
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.MEDIUM}
            text={__("Создать")}
            onClick={createAccountHandler}
            disabled={!email || !pass || !checkPass || !name}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatePostbox;
