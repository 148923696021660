import classNames from "classnames";
import { useGetStatus } from "generalComponents/Services/projectServices";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";
import { taskTypes } from "types/Project";

import { getChildrenTask, getParentTask } from "../../helpers";
import Executors from "../Executors/Executors";
import TaskNum from "../TaskNum/TaskNum";
import Arrow from "./Arrow";
import Lines from "./Lines";
import styles from "./TaskDependency.module.sass";

const screenHeight = window.innerHeight;
const TYPES = { PARENT: "parent", CURRENT: "current", CHILD: "child" };

const TaskDependency = ({ task, x, y, onClose }) => {
  const { __ } = useLocales();
  const status = useGetStatus();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { project } = useProjectsSelectors();
  const [position, setPosition] = useState(null);

  useEffect(() => {
    if (ref) {
      const elWidth = ref.current.offsetHeight;
      const isDown = screenHeight - y > elWidth;
      setPosition(isDown ? { top: y + 15, position: "down" } : { top: y - elWidth - 15, position: "up" });
    }
  }, []); //eslint-disable-line

  const getMsg = (type) => {
    switch (type) {
      case TYPES.PARENT:
        return __("Родительская задача");
      case TYPES.CURRENT:
        return __("Текущая задача");
      case TYPES.CHILD:
        return __("Дочерняя задача");

      default:
        break;
    }
  };
  const children = useMemo(() => getChildrenTask(project.tasks, task.id), [project.tasks, task.id]);
  const parentTask = useMemo(() => getParentTask(project.tasks, task.id_parent), [project.tasks, task.id_parent]);

  const onOpenTask = (task) => dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.OPEN_TASK, params: task }));

  const renderTask = (task, type, statusTask) => {
    return (
      <div
        className={classNames(styles.task)}
        style={{ background: statusTask?.color.light, borderColor: statusTask?.color.dark }}
        onClick={() => onOpenTask(task)}
      >
        <div className={styles.top}>
          <TaskNum num={task.num_epic} color={statusTask?.color.dark} />
          <div className={styles.name} title={task.name}>
            {task.name}
          </div>
        </div>
        <div className={styles.type}>{getMsg(type)}</div>
        <div className={styles.bottom}>
          <div className={styles.executors}>
            <Executors executors={task.id_executor} />
          </div>
          {task.tags?.trim() && (
            <p className={styles.role} title={task.tags}>
              {task.tags}
            </p>
          )}
          <div className={styles.statusBox}>
            <div className={styles.circle} style={{ background: statusTask?.color.dark }} />
            <span className={styles.status} title={statusTask?.name}>
              {statusTask?.name}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderParent = () => {
    const type = TYPES.PARENT;
    const statusTask = status(parentTask);
    const color = statusTask?.color.dark;

    return (
      <div>
        {renderTask(parentTask, type, statusTask)}
        <Arrow color={color} id={parentTask.id} />
      </div>
    );
  };

  const renderCurrent = () => {
    const type = TYPES.CURRENT;
    const statusTask = status(task);
    const color = statusTask?.color.dark;
    return (
      <div>
        {renderTask(task, type, statusTask)}
        {children.length > 0 && <Arrow color={color} id={task.id} />}
      </div>
    );
  };

  const renderChildren = () => {
    return children.map((t, i) => {
      const statusTask = status(t);
      const currentColor = status(task)?.color.dark;
      return (
        <div key={i} className={styles.childrenTask}>
          {renderTask(t, TYPES.CHILD, statusTask)}
          {children.length !== i + 1 && (
            <div className={styles.svgBox}>
              <Lines color={currentColor} id={task.id} first={i === 0} />
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className={styles.wrap} onClick={onClose}>
      <div
        className={classNames(styles.content, {
          [styles.down]: position?.position === "down",
          [styles.up]: position?.position === "up"
        })}
        style={{ top: position?.top, left: x }}
        ref={ref}
      >
        <div className={styles.tasks}>
          <div className={styles.title}>{__("Зависимость задач")}</div>
          {parentTask && renderParent()}
          {renderCurrent()}
          {renderChildren()}
        </div>
      </div>
    </div>
  );
};

export default TaskDependency;
TaskDependency.propTypes = {
  x: PropTypes.string,
  y: PropTypes.string,
  onClose: PropTypes.func,
  task: taskTypes
};
