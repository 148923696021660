import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { PHOTO, VIDEO } from "generalComponents/variables/chat";

export async function clearAllChatMessages(uid, contact, all) {
  return api
    .get("/ajax/chat_message_del_all.php", {
      params: {
        uid,
        id_user_to: contact.id_real_user,
        is_all: all
      }
    })
    .then((res) => checkResponseStatus(res.data.ok));
}

export function checkFilePath(option, subOption) {
  if (subOption?.name === PHOTO || subOption?.name === VIDEO) {
    return subOption?.name;
  }
  return option?.name;
}

export function constructChatMessage(socketMessage) {
  return {
    attachment: socketMessage.attachment,
    day: socketMessage.api?.day ?? "today",
    deadline: socketMessage.api?.deadline ?? "0",
    id: socketMessage.api?.id_message || socketMessage.chat_id || socketMessage.id,
    id_user: socketMessage.api?.id_user || socketMessage.chat_id || socketMessage.id_user,
    id_user_to: socketMessage.api?.id_user_to,
    is_del: socketMessage.api?.is_del ?? "0",
    is_read: socketMessage.api?.is_read ?? "0",
    text: socketMessage.text,
    ut: socketMessage.api?.ut_message,
    id_group: socketMessage?.id_group,
    is_group: socketMessage?.is_group ?? false,
    id_contact: socketMessage?.id_contact,
    originMessage: socketMessage,
    type: socketMessage.type,
    id_project: socketMessage.id_project,
    task_data: socketMessage.task_data,
    prim: socketMessage.prim,
    id_task: socketMessage.id_task
  };
}

export function constructFromHttpChatMessage(message) {
  return {
    attachment: message.attachment,
    day: message.day ?? "today",
    deadline: message.deadline ?? "0",
    id: message.id,
    id_user: message.id_user,
    id_user_to: message.id_user_to,
    is_del: message.is_del ?? "0",
    is_read: message.is_read ?? "0",
    text: message.text,
    ut: message.ut,
    id_group: message.id_group
  };
}
