import { endOfDay, getTime, startOfDay } from "date-fns";
import { dateToMs } from "generalComponents/Calendars/CalendarHelper";
import { TYPES } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { URGENCY_TYPES } from "generalComponents/variables/tasks";
import {
  IProjectMsg,
  TChildrenTask,
  TGetRolesGroup,
  TParentTask,
  TRoleName,
  TRolesGroup,
  TWithoutRole,
  TWithRole
} from "models/generalComponents/services/projectServices";
import { ICategoryProject, IRoleProject, ITaskProject, IUserProject } from "models/store/projects/projectStore";
import { useLocales } from "react-localized";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import { typeCheck } from "./browserServices";

export const useGetStatus = () => {
  const { project } = useProjectsSelectors();
  return (task: ITaskProject): ICategoryProject => project.tasks_category.find((el) => el.id === task.id_category);
};

export const renderEmptyTable = (column: number): string => {
  let row = "";
  for (let i = 0; i < 3; i++) {
    let col = "";
    for (let t = 0; t < column; t++) {
      col += "<td></td>";
    }
    row += `<tr>${col}</tr>`;
  }
  return row;
};

export const useFiltredTask = (): ITaskProject[] => {
  const { project, selectRole, selectUser, selectUrgency, selectPriority } = useProjectsSelectors();
  if (!project.tasks || project.tasks.length === 0) {
    return [];
  }
  return project.tasks
    .sort((a, b) => dateToMs(b.ut) - dateToMs(a.ut))
    .filter((t) => {
      if (selectRole !== "0" && selectUser === "0") {
        const users = project.users.filter((user) => user.id_role === selectRole);
        return users.some(
          (u) => typeCheck(t.id_executor) === TYPES.ARRAY && t.id_executor.some((i) => i === u.id_user)
        );
      }
      if (selectUser !== "0") {
        return t.id_executor?.includes(selectUser);
      }
      return t;
    })
    .filter((t) => {
      if (selectUrgency) {
        return t.id_act === URGENCY_TYPES.URGENT;
      }
      return t;
    })
    .filter((t) => {
      if (selectPriority) {
        const today = Date.now();
        const startDay = getTime(startOfDay(new Date(t.date_long || t.date_end)));
        const endDay = getTime(endOfDay(new Date(t.date_long || t.date_end)));
        return startDay < today && today < endDay;
      }
      return t;
    });
};

export const useFiltredTrashTask = (): ITaskProject[] => {
  const { project, selectRole, selectUser, tasksTrash } = useProjectsSelectors();

  return tasksTrash
    .sort((a, b) => dateToMs(a.del_date) - dateToMs(b.del_date))
    .filter((t) => {
      if (selectRole !== "0" && selectUser === "0") {
        const users = project.users.filter((user) => user.id_role === selectRole);
        return users.some((u) => t.id_executor.some((i) => i === u.id_user));
      }
      if (selectUser !== "0") {
        return t.id_executor?.includes(selectUser);
      }
      return t;
    });
};

export const useFiltredRole = (): IRoleProject[] => {
  const { project, selectRole } = useProjectsSelectors();

  if (selectRole !== "0") {
    return project.roles.filter((r) => r.id === selectRole);
  }
  return project.roles;
};

export const useFiltredUsers = (): IUserProject[] => {
  const { project, selectRole } = useProjectsSelectors();

  return project.users.filter((user) => {
    if (selectRole !== "0") {
      return user.id_role === selectRole;
    }
    return user;
  });
};

export const useUsersRole = (): Record<string, string> => {
  const { project } = useProjectsSelectors();
  return project.users.reduce(
    (acc, u) => ({
      ...acc,
      [u.id_user]: u.id_role
    }),
    {}
  );
};

export const useTasksWithoutRole = (): TWithoutRole => {
  const usersRole = useUsersRole();
  return (tasks: ITaskProject[]) =>
    tasks.filter((t) => t.id_executor.length === 0 || t.id_executor.some((i) => usersRole[i] === "0"));
};

export const useTasksWithRole = (): TWithRole => {
  const { project } = useProjectsSelectors();

  return (tasks: ITaskProject[], role: IRoleProject) =>
    tasks.filter((t) => {
      return t.id_executor.some((i) => project.users.some((u) => u.id_user === i && u.id_role === role.id));
    });
};

export const useGetRolesGroup = (): TGetRolesGroup => {
  const tasksWithoutRole = useTasksWithoutRole();
  const tasksWithRoles = useTasksWithRole();
  const filtredRoles = useFiltredRole();

  return (tasks: ITaskProject[]) => {
    return filtredRoles.reduce(
      (acc: TRolesGroup, r) => ({
        ...acc,
        [r.id]: tasksWithRoles(tasks, r),
        "0": tasksWithoutRole(tasks)
      }),
      {}
    );
  };
};

export const useRoleName = (): TRoleName => {
  const { project } = useProjectsSelectors();
  return (roleId: string) => project.roles.find((r) => r.id === roleId)?.name;
};

export const useParentTask = (): TParentTask => {
  const { project } = useProjectsSelectors();
  return (id: string): ITaskProject => project.tasks.find((el) => el.id === id);
};

export const useChildrenTask = (): TChildrenTask => {
  const { project } = useProjectsSelectors();
  return (id: string): ITaskProject[] => project.tasks.filter((el) => el.id_parent === id);
};

export const useProjectMsg = (): IProjectMsg => {
  const { __ } = useLocales();
  return {
    ERROR_PROJECT: __("Ошибка при получении проектов"),
    ERROR: __("Что-то пошло не так, попробуйте еще раз"),
    [PROJECT_MODALS.ADD_PROJECT]: __("Проект успешно добавлен"),
    [PROJECT_MODALS.EDIT_PROJECT]: __("Ваш проект 4Hub успешно изменен"),
    [PROJECT_MODALS.ADD_ROLES]: {
      title: __("Роли успешно добавлены"),
      text: __(
        "Выбранные Вами роли успешно добавленны, в дольнейшем вы сможите добавить участиков ролей или сделайте это сейчас нажав на кнопку добавить участников проекта"
      )
    },
    [PROJECT_MODALS.EDIT_ROLE]: {
      title: __("Роли изменена"),
      text: __("Выбранная Вами роли успешно изменена")
    },
    DEL_ROLE: {
      title: __("Роль удалена"),
      text: __("Выбранная Вами роль удалена")
    },
    [PROJECT_MODALS.ADD_CATEGORY]: __("Категория успешно добавлена"),
    [PROJECT_MODALS.EDIT_CATEGORY]: __("Категория успешно изменена"),
    DELETE_CATEGORY: {
      title: __("Категория удалена"),
      text: __("Выбранная Вами Категория удалена")
    },
    [PROJECT_MODALS.ADD_TASK]: __("Задача успешно добавлена"),
    [PROJECT_MODALS.EDIT_TASK]: __("Задача изменена"),
    DEL_TASK: __("Задача удалена"),
    DEL_TASKS: __("Задачи удалены"),
    ADD_FILE: {
      title: __("Файл добавлен"),
      text: __("Файл добавлен в файлы проекта")
    },
    MOVE_TO_BACKLOG: {
      title: __("Задача изменена"),
      text: __("Задача перемещена в backlog")
    },
    DEL_USER: __("Участник удален из проєкта"),
    ADD_LINK: __("Ссылка добавлена"),
    EDIT_LINK: __("Ссылка изменена"),
    DEL_LINK: __("Ссылка удалена"),
    DEL_FILE: __("Файл удален"),
    ADD_DOCS: __("Документ добавлен"),
    ADD_TEMPLATE: __("Шаблон добавлен"),
    DEL_DOC: __("Документ удален"),
    EDIT_DOC: __("Документ изменен")
  };
};
