import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

interface IProtectedRoutesProps {
  redirectTo: string;
  component: JSX.Element;
}

const ProtectedRoutes: React.FC<IProtectedRoutesProps> = ({ redirectTo, component }) => {
  const { pathname } = useLocation();
  const { uid, userInfo } = useUserSelectors();
  return uid && userInfo.uid ? component : <Navigate to={`/${redirectTo}`} state={{ from: pathname }} />;
};

export default ProtectedRoutes;
