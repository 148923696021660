import classNames from "classnames";
import { MS_OF_DAY } from "generalComponents/Calendars/CalendarHelper";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { ROUTES } from "generalComponents/variables/routing";
import { useCurrentPath } from "hooks/useCurrentPath";
import { ArchivedElementsType } from "models/store/archive/archivedFiles";
import { FilesNextArrayType, FilesNextObjectType } from "models/store/files/files";
import { AllElementsArrayType, AllElementsNextObjectType } from "models/store/typeElements";
import ItemAddedDate from "Pages/Cabinet/Components/WorkElements/ItemAddedDate/ItemAddedDate";
import { FC } from "react";
import { useMemo } from "react";

import { useItemsViewType } from "../Hooks/useItemsViewType";
import styles from "./ItemsDate.module.sass";

interface IItemsDateProps {
  files: FilesNextArrayType | AllElementsArrayType;
}

const ItemsDate: FC<IItemsDateProps> = ({ files }): JSX.Element => {
  const { isBarsPreviewView } = useGetItemView();
  const { renderItemsViewType } = useItemsViewType();
  const isArchivePage = useCurrentPath(ROUTES.ARCHIVE);
  const isTrashPage = useCurrentPath(ROUTES.TRASH);

  const periodsArr = [1, 7, 14, 30, 60, 365, 999];

  const nowDate = Date.now();

  const filesByDateCreared: FilesNextObjectType | AllElementsNextObjectType = useMemo(() => {
    return periodsArr.reduce((periods, period, index) => {
      const prevPeriod = index > 0 ? periodsArr[index - 1] : undefined;
      const periodInMSeconds = period * MS_OF_DAY;
      const prevPeriodInMSeconds = prevPeriod * MS_OF_DAY;
      const filesCopy = files?.length ? [...files] : [];

      return {
        ...periods,
        [period]: filesCopy?.filter((item) => {
          const dateCreated = new Date(
            isArchivePage ? (item as ArchivedElementsType)?.atime : isTrashPage ? item?.dtime : item?.ctime
          ).getTime();

          if (index === 0) {
            return nowDate - dateCreated < periodInMSeconds;
          } else {
            return nowDate - dateCreated > prevPeriodInMSeconds && nowDate - dateCreated < periodInMSeconds;
          }
        })
      };
    }, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    Boolean(Object.values(files).length) && (
      <div className={classNames(styles.itemsWrap, { [styles.itemsWrapBar]: isBarsPreviewView })}>
        {Object.entries(filesByDateCreared)
          .filter(([_, items]) => items.length !== 0)
          .map(
            ([date, items]): JSX.Element =>
              !!items.length && (
                <div key={Number(date)} className={styles.itemWrap}>
                  <ItemAddedDate date={Number(date)} itemsLength={items.length} />
                  {renderItemsViewType(items)}
                </div>
              )
          )}
      </div>
    )
  );
};

export default ItemsDate;
