import { FC } from "react";

interface IProps {
  startPosition: {
    x: number;
    y: number;
  };
  endPosition: {
    x: number;
    y: number;
  };
}

export const DrawLine: FC<IProps> = ({ startPosition, endPosition }) => {
  return (
    <line
      x1={startPosition.x}
      y1={startPosition.y}
      x2={endPosition.x}
      y2={endPosition.y}
      stroke="#E4C500"
      strokeWidth="2"
      strokeDasharray="10,5"
    />
  );
};
