export enum SIGNS {
  F1 = "F1",
  F2 = "F2",
  F3 = "F3",
  F4 = "F4",
  F5 = "F5",
  F6 = "F6",
  F7 = "F7",
  F8 = "F8",
  F9 = "F9",
  F10 = "F10",
  F11 = "F11",
  F12 = "F12",
  F13 = "F13",
  F14 = "F14",
  F15 = "F15",
  F16 = "F16",
  F17 = "F17",
  F18 = "F18",
  F19 = "F19",
  F20 = "F20",
  F21 = "F21",
  F22 = "F22",
  F23 = "F23",
  F24 = "F24",
  F25 = "F25",
  F26 = "F26",
  F27 = "F27",
  F28 = "F28",
  F29 = "F29",
  F30 = "F30",
  F31 = "F31",
  F32 = "F32",
  F33 = "F33",
  F34 = "F34",
  F35 = "F35",
  F36 = "F36",
  F37 = "F37",
  F38 = "F38",
  F39 = "F39",
  F40 = "F40",
  F41 = "F41",
  F42 = "F42",
  F43 = "F43",
  F44 = "F44",
  F45 = "F45",
  F46 = "F46",
  F47 = "F47",
  F48 = "F48",
  F49 = "F49",
  F50 = "F50",
  F51 = "F51",
  F52 = "F52",
  F53 = "F53",
  F54 = "F54",
  F55 = "F55",
  F56 = "F56",
  F57 = "F57",
  F58 = "F58",
  F59 = "F59",
  F60 = "F60",
  F61 = "F61",
  F62 = "F62",
  F63 = "F63",
  F64 = "F64",
  F65 = "F65",
  F66 = "F66",
  F67 = "F67",
  F68 = "F68",
  F69 = "F69",
  F70 = "F70",
  F71 = "F71",
  F72 = "F72",
  F73 = "F73",
  F74 = "F74",
  F75 = "F75",
  F76 = "F76",
  F77 = "F77",
  F78 = "F78",
  F79 = "F79",
  F80 = "F80",
  F81 = "F81",
  F82 = "F82",
  F83 = "F83",
  NO_SIGN = ""
}

export enum EMOJIS {
  E1 = "E1",
  E2 = "E2",
  E3 = "E3",
  E4 = "E4",
  E5 = "E5",
  E6 = "E6",
  E7 = "E7",
  E8 = "E8",
  E9 = "E9",
  E10 = "E10",
  E11 = "E11",
  E12 = "E12",
  E13 = "E13",
  E14 = "E14",
  E15 = "E15",
  E16 = "E16",
  E17 = "E17",
  E18 = "E18",
  E19 = "E19",
  E20 = "E20",
  E21 = "E21",
  E22 = "E22",
  E23 = "E23",
  E24 = "E24",
  E25 = "E25",
  E26 = "E26",
  E27 = "E27",
  E28 = "E28",
  E29 = "E29",
  E30 = "E30",
  E31 = "E31",
  E32 = "E32",
  E33 = "E33",
  E34 = "E34",
  E35 = "E35",
  E36 = "E36",
  E37 = "E37",
  E38 = "E38",
  E39 = "E39",
  E40 = "E40",
  E41 = "E41",
  E42 = "E42",
  E43 = "E43",
  E44 = "E44",
  E45 = "E45",
  E46 = "E46",
  E47 = "E47",
  E48 = "E48",
  E49 = "E49",
  E50 = "E50",
  E51 = "E51",
  E52 = "E52",
  E53 = "E53",
  E54 = "E54",
  E55 = "E55",
  E56 = "E56",
  E57 = "E57",
  E58 = "E58",
  E59 = "E59",
  E60 = "E60",
  E61 = "E61",
  E62 = "E62",
  E63 = "E63",
  E64 = "E64",
  E65 = "E65",
  E66 = "E66",
  E67 = "E67",
  E68 = "E68",
  E69 = "E69",
  E70 = "E70",
  E71 = "E71",
  E72 = "E72",
  E73 = "E73",
  E74 = "E74",
  E75 = "E75",
  E76 = "E76",
  E77 = "E77",
  E78 = "E78",
  E79 = "E79",
  E80 = "E80",
  E81 = "E81",
  E82 = "E82",
  E83 = "E83",
  NO_EMOJI = ""
}
