import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useDurationToString } from "hooks/joinProjectHooks";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ChangeEvent, FC, MouseEvent, useEffect, useState } from "react";
import { useLocales } from "react-localized";

import styles from "./TaskDuration.module.sass";

interface IProps {
  duration: number;
  setDuration: (val: number) => void;
  readOnly?: boolean;
}
const TIMES = ["1", "3", "5", "8", "16", "24", "32", "40", "80"];

const TaskDuration: FC<IProps> = ({ duration, setDuration, readOnly = false }) => {
  const { __ } = useLocales();
  const durationToString = useDurationToString();
  const [show, setShow] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    const value = durationToString(duration);
    setValue(value);
  }, [duration]); //eslint-disable-line

  const onSubmit = (): void => {
    const numbers: number[] = value.match(/\d+/g).map((el) => +el);
    let _duration = 0;
    if (numbers.length === 1) {
      _duration = numbers[0] * 60;
    }
    if (numbers.length === 2) {
      _duration = numbers[0] * 60 + numbers[1];
    }
    if (_duration < 120000 && _duration > 0) {
      setDuration(_duration);
      setValue(durationToString(_duration));
      setShow(false);
    } else {
      setDuration(duration);
      setValue(durationToString(duration));
      setShow(false);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  const renderOverlay = () => {
    return (
      <div className={styles.select}>
        <div className={styles.item}>
          <Input value={value} onChange={handleChange} rightIcon={<ClockIcon />} />
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.EXTRA_SMALL}
            iconL={<DoneIcon />}
            onClick={onSubmit}
            isSquare
          />
        </div>
        <div className={styles.times}>
          {TIMES.map((item) => (
            <Button
              key={item}
              variant={ButtonVariantType.DARK}
              size={ButtonSizeType.EXTRA_SMALL}
              text={`${item}${__("ч")}`}
              onClick={() => setValue(item)}
              className={styles.btn}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <PopoverOverlay
      show={show}
      overlayContent={renderOverlay()}
      setShow={setShow}
      placement="bottom"
      btn="extra"
      width={160}
    >
      <div
        className={classNames(styles.label, { [styles.active]: show })}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          !readOnly && setShow(true);
        }}
      >
        <span>{durationToString(duration)}</span>
        <ArrowIcon className={classNames(styles.arrow, { [styles.arrowOpen]: show })} />
      </div>
    </PopoverOverlay>
  );
};

export default TaskDuration;
