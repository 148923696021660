import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import classNames from "classnames";
import { useTaskDependencies } from "collections/joinProject";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useActions } from "hooks/useActions";
import { ButtonSizeType } from "models/generalComponents/button";
import { IProjectTask, TASK_DEPENDENCIES } from "models/store/joinProjects/joinProgects";
import { useState } from "react";
import { useLocales } from "react-localized";
import { getDependencyType2 } from "utils/jointPtoject";

import styles from "./TaskDependenciesButton.module.sass";

const TaskDependenciesButton = ({ task, disabled }: { task: IProjectTask; disabled?: boolean }) => {
  const { __ } = useLocales();
  const { onSetTasksConnectionModal } = useActions();
  const [show, setShow] = useState<boolean>(false);

  const { taskDpendencies } = useTaskDependencies();

  const handleClick = (type1: TASK_DEPENDENCIES) => {
    setShow(false);
    onSetTasksConnectionModal({ open: true, type1, type2: getDependencyType2(type1), task });
  };

  const renderContent = () => (
    <ul>
      <li className={classNames(styles.item, styles.border)} onClick={() => handleClick(TASK_DEPENDENCIES.RELATION)}>
        {taskDpendencies[TASK_DEPENDENCIES.RELATION]}
      </li>
      <li className={styles.item} onClick={() => handleClick(TASK_DEPENDENCIES.REQUIRED_FOR)}>
        {taskDpendencies[TASK_DEPENDENCIES.REQUIRED_FOR]}
      </li>
      <li className={classNames(styles.item, styles.border)} onClick={() => handleClick(TASK_DEPENDENCIES.DEPENDS_ON)}>
        {taskDpendencies[TASK_DEPENDENCIES.DEPENDS_ON]}
      </li>
      <li className={styles.item} onClick={() => handleClick(TASK_DEPENDENCIES.PARENT_FOR)}>
        {taskDpendencies[TASK_DEPENDENCIES.PARENT_FOR]}
      </li>
      <li className={styles.item} onClick={() => handleClick(TASK_DEPENDENCIES.CHILD_FOR)}>
        {taskDpendencies[TASK_DEPENDENCIES.CHILD_FOR]}
      </li>
    </ul>
  );

  return (
    <PopoverOverlay
      overlayContent={renderContent()}
      classes={{ popoverBody: styles.nopadding }}
      show={show}
      setShow={setShow}
      btn="custom"
      iconLBtn={<LinkIcon className={styles.icon} />}
      title={__("Связи")}
      sizeBtn={ButtonSizeType.EXTRA_SMALL}
      visibleHeader={false}
      width={200}
      disabled={disabled}
    />
  );
};

export default TaskDependenciesButton;
