import { useContentCategories } from "collections/library";
import Clue from "generalComponents/Clue/Clue";
import { LibraryCategories } from "generalComponents/LibraryCategories/LibraryCategories";
import { LibraryRubrics } from "generalComponents/LibraryRubrics/LibraryRubrics";
import Loader from "generalComponents/Loaders/4HUB";
import { WorkSpaceWrap } from "generalComponents/WorkSpaceWrap/WorkSpaceWrap";
import { LoaderTypes } from "models/generalComponents/loader";
import { useLocales } from "react-localized";
import { useLibrarySelectors } from "Store/selectors/librarySelectors";

const LibraryMainWorkSpace = (): JSX.Element => {
  const { loader, rubrics, page } = useLibrarySelectors();
  const { __ } = useLocales();
  const categories = useContentCategories();

  return (
    <WorkSpaceWrap>
      {loader && page === 1 ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : !!Object.values(categories).length || !!rubrics.length ? (
        <>
          <LibraryCategories categories={categories} />
          <LibraryRubrics rubrics={rubrics} />
        </>
      ) : (
        <Clue icon="sad" text={__("Нет данных")} />
      )}
    </WorkSpaceWrap>
  );
};

export { LibraryMainWorkSpace };
