import { ReactComponent as CalendarIcon } from "assets/PrivateCabinet/calendar-6.svg";
import { ReactComponent as LinesIcon } from "assets/PrivateCabinet/lines.svg";
import { ReactComponent as BarsIcon } from "assets/PrivateCabinet/tasks/bars.svg";
import { ReactComponent as ListIcon } from "assets/PrivateCabinet/tasks/list.svg";
import { format } from "date-fns";
import TabsPicker from "generalComponents/TabsPicker/TabsPicker";
import { TASK_VIEW } from "generalComponents/variables/tasks";
import PropTypes from "prop-types";
import React from "react";
import { taskTypes } from "types/Project";

import AnaliticsPanel from "./AnaliticsPanel/AnaliticsPanel";
import styles from "./ServicePanel.module.sass";

const ServicePanel = ({ tabSelect, setTabSelect, filtredTasks }) => {
  const ELEMENTS = [
    { img: ListIcon, id: TASK_VIEW.GANT },
    { img: BarsIcon, id: TASK_VIEW.KANBAN },
    { img: LinesIcon, id: TASK_VIEW.LINE }
  ];

  return (
    <div className={styles.wrap}>
      <div className={styles.date}>
        <CalendarIcon className={styles.icon} />
        <span>{format(new Date(), "dd.MM.yyyy")}</span>
      </div>
      {filtredTasks && <AnaliticsPanel filtredTasks={filtredTasks} />}
      <div className={styles.row}>
        <TabsPicker ELEMENTS={ELEMENTS} selected={tabSelect} onClick={setTabSelect} />
      </div>
    </div>
  );
};

export default ServicePanel;

ServicePanel.propTypes = {
  tabSelect: PropTypes.string,
  setTabSelect: PropTypes.func,
  filtredTasks: PropTypes.objectOf(PropTypes.arrayOf(taskTypes))
};
