export const getObjDate = (value: string): { y: string; m: string; d: string } => {
  const date = new Date(value);

  const y = date.getFullYear().toString();
  const m = date.getMonth() + 1;
  const d = date.getDate().toString();

  return {
    y,
    m: m <= 9 ? `0${m}` : m.toString(),
    d
  };
};
