import { ReactComponent as AddSafeIcon } from "assets/icons/addSafe.svg";
import { ReactComponent as SafeIcon } from "assets/icons/safe.svg";
import { ReactComponent as ExclamationIcon } from "assets/PrivateCabinet/exclamation.svg";
import classNames from "classnames";
import { useSafeTabs } from "collections/safe";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import FileServicePanel from "generalComponents/FilesServicePanel/FilesServicePanel";
import List from "generalComponents/List/List";
import SafeItem from "generalComponents/SafeItem/SafeItem";
import Tabs from "generalComponents/Tabs/Tabs";
import { PROFILE_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { SAFE_MODALS } from "generalComponents/variables/safe";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useActions } from "hooks/useActions";
import useInactivityTracker from "hooks/useInactivityTracker";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSafeSelectors } from "Store/selectors/safeSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./SafePage.module.sass";

const SafePage = (): JSX.Element => {
  const { __ } = useLocales();
  const { safeId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { onFetchSafes, onSetSafeModal, onClearAuthorized, onNullifyFilterCriteria } = useActions();
  const isInactive = useInactivityTracker(5);
  const { safeList } = useSafeSelectors();
  const { userInfo, rights } = useUserSelectors();
  const { safeTabs, value } = useSafeTabs();
  const [listCollapsed, setListCollapsed] = useState<boolean>(false);

  useEffect(() => {
    if (!rights.IS_SAFE) {
      navigate("/", { replace: true });
      return;
    }
    const controller = new AbortController();
    const isLoader = safeList.length === 0;
    onFetchSafes(isLoader, controller);
    return () => {
      controller.abort();
      onNullifyFilterCriteria();
      onClearAuthorized();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isInactive) {
      onClearAuthorized();
      navigate(`/${ROUTES.SAFE}${search}`, { replace: true });
    }
  }, [isInactive]); // eslint-disable-line

  const filtredSafeList = useMemo(() => {
    if (value === "my") {
      return safeList.filter((el) => el.is_my);
    }
    if (value === "other") {
      return safeList.filter((el) => !el.is_my);
    }
    return safeList;
  }, [safeList, value]);

  const addSafe = (): void => {
    onSetSafeModal({ open: true, type: SAFE_MODALS.ADD_SAFE });
  };
  const addFirstSafe = (): void => {
    onSetSafeModal({ open: true, type: SAFE_MODALS.ADD_FIRST_SAFE });
  };
  const handleClick = (): void => {
    navigate(`/${ROUTES.PROFILE}/${PROFILE_ROUTES.ABOUT_ME}${search}`, { state: { activeTel: true } });
  };

  return (
    <div className={styles.workAreaWrap}>
      <List
        title={__("Сейф")}
        tooltipBtn={__("Новый сейф")}
        icon={<AddSafeIcon />}
        setListCollapsed={setListCollapsed}
        listCollapsed={listCollapsed}
        onCreate={addSafe}
      >
        <>
          <div className={styles.tabs}>
            <Tabs tabs={safeTabs} value={value} full />
          </div>
          <ul className={styles.safeList}>
            {safeList.length === 0 ? (
              <li
                onClick={addFirstSafe}
                className={classNames(styles.btn, {
                  [styles.collapsed]: listCollapsed
                })}
              >
                <SafeIcon fill="#DF9A14" />
                <span className={styles.name}>{__("Мой сейф")}</span>
              </li>
            ) : (
              filtredSafeList.map((safe) => <SafeItem key={safe.id} safe={safe} listCollapsed={listCollapsed} />)
            )}
          </ul>
        </>
      </List>
      <WorkAreaWrap>
        <FileServicePanel />
        {safeId ? (
          <Outlet />
        ) : userInfo.tel ? (
          <Clue text={__("Выберите Сейф для начала работы ")} icon="happy" />
        ) : (
          <div className={styles.alertBlock}>
            <div className={styles.alert}>
              <div className={styles.icon}>
                <ExclamationIcon width={24} height={24} />
              </div>
              <div>
                <h3 className={styles.title}>{__("Важно")}</h3>
                <p className={styles.text}>
                  {__("Для работы с функционалом сейфа необходимо подтвердить")} <b>{__("Основной Телефон")}</b>
                </p>
                <ul>
                  <li>{__("номер телефона используется для доступа к сейфу")}</li>
                  <li>{__("номер телефона никому невиден")}</li>
                </ul>
                <Button
                  variant={ButtonVariantType.DARK}
                  size={ButtonSizeType.SMALL}
                  text={__("Подтвердить")}
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        )}
      </WorkAreaWrap>
    </div>
  );
};

export default SafePage;
