import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import PopUp from "generalComponents/PopUp/PopUp";
import Switcher from "generalComponents/Switcher/Switcher";
import { useAccessesProjectRole } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialCreateProjectRoleState } from "models/store/Cabinet/modals/createProjectRole";
import { ROLE_ACCESSES } from "models/store/joinProjects/joinProgects";
import { ChangeEvent, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./CreateProjectRole.module.sass";

export const INITIAL_ACCESS: Record<ROLE_ACCESSES, boolean> = {
  [ROLE_ACCESSES.ANALITYCS]: false,
  [ROLE_ACCESSES.MGR_TEAMS]: false,
  [ROLE_ACCESSES.MGR_LINKS]: false,
  [ROLE_ACCESSES.MGR_FILES]: false,
  [ROLE_ACCESSES.MGR_LIBRARY]: false,
  [ROLE_ACCESSES.MGR_DOCS]: false,
  [ROLE_ACCESSES.SETTINGS]: false
};
const accessesArr: ROLE_ACCESSES[] = [
  ROLE_ACCESSES.ANALITYCS,
  ROLE_ACCESSES.MGR_TEAMS,
  ROLE_ACCESSES.MGR_LINKS,
  ROLE_ACCESSES.MGR_FILES,
  ROLE_ACCESSES.MGR_LIBRARY,
  ROLE_ACCESSES.MGR_DOCS,
  ROLE_ACCESSES.SETTINGS
];

const CreateProjectRole = () => {
  const { __ } = useLocales();
  const accessesRole = useAccessesProjectRole();
  const { project } = useJoinProjectsSelectors();
  const { onSetCreateProjectRoleModal, onCreateRole, onEditRole } = useActions();
  const {
    createProjectRole: { type, role }
  } = useGlobalModalsSelectors();
  const _isAdd = type === "add";
  const [accesses, setAccesses] = useState<Record<ROLE_ACCESSES, boolean>>(_isAdd ? INITIAL_ACCESS : role.accesses);
  const [name, setName] = useState<string>(_isAdd ? "" : role.name);

  const closeModal = () => onSetCreateProjectRoleModal(initialCreateProjectRoleState());
  const onChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);
  const onSubmit = () => {
    const params = {
      data: {
        id_item: _isAdd ? undefined : role.id,
        name,
        accesses: JSON.stringify(accesses),
        id_project: project.id
      },
      messages: {
        success: _isAdd ? __("Новая роль добавлена") : __("Роль изменена"),
        error: __("Такая роль существует")
      }
    };
    _isAdd ? onCreateRole(params) : onEditRole(params);
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.wrap}>
        <HeaderModal onClose={closeModal} title={__("Создать роль")} />
        <div className={styles.body}>
          <Input value={name} onChange={onChange} require label={__("Название")} />
          <h3 className={styles.subTitle}>{__("Доступы")}</h3>
          <ul className={styles.list}>
            {accessesArr.map((el, i) => (
              <li key={i} className={styles.item}>
                <Switcher
                  checked={accesses[el]}
                  onClickCheckBox={() => setAccesses((prev) => ({ ...prev, [el]: !prev[el] }))}
                />
                <div>
                  <p className={styles.title}>{accessesRole[el].title}</p>
                  <p className={styles.prim}>{accessesRole[el].prim}</p>
                </div>
              </li>
            ))}
          </ul>
          <div className={styles.footer}>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Отмена")}
              onClick={closeModal}
            />
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={_isAdd ? __("Создать") : __("Сохранить")}
              onClick={onSubmit}
              disabled={Boolean(!name)}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default CreateProjectRole;
