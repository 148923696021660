import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import { POSTBOX_MODALS } from "generalComponents/variables/mail";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IMailFolder, IPostbox } from "models/store/postbox/postbox";
import React, { useState } from "react";
import { useLocales } from "react-localized";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./EditPostboxFolder.module.sass";

interface IEditPostboxFolderProps {
  closeModal: () => void;
  type: POSTBOX_MODALS;
  folder: IMailFolder;
  postbox: IPostbox;
}

const EditPostboxFolder: React.FC<IEditPostboxFolderProps> = ({ closeModal, type, folder, postbox }) => {
  const { __ } = useLocales();
  const { onCreateFolderPostbox, onRenameFolderPostbox } = useActions();
  const [name, setName] = useState<string>(folder ? folder.name : "");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => setName(e.currentTarget.value);
  const _isAdd = type === POSTBOX_MODALS.ADD_FOLDER;

  const onSubmit = () => {
    if (_isAdd) {
      const payload = {
        email: postbox.email,
        pass: postbox.pass,
        folder: name
      };
      onCreateFolderPostbox(payload);
    } else {
      const payload = {
        email: postbox.email,
        pass: postbox.pass,
        folder: folder.orig,
        folder_new: name
      };
      const messages = {
        error: __("Не удалось переименовать папку. Попробуйте еще раз"),
        success: __("Папка переименована")
      };
      onRenameFolderPostbox(payload, messages);
    }
  };
  return (
    <div className={styles.wrap}>
      <HeaderModal onClose={closeModal} title={_isAdd ? __("Создать папку") : __("Редактировать")} />
      <div className={styles.body}>
        <Input value={name} onChange={handleChange} label={__("Имя")} />
        <div className={styles.btns}>
          <Button
            onClick={closeModal}
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.LARGE}
            text={__("Отменить")}
            type="button"
          />
          <Button
            onClick={onSubmit}
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.LARGE}
            text={_isAdd ? __("Создать папку") : __("Редактировать")}
            type="button"
            disabled={!name}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPostboxFolder;
