import { IMailsState, initialMailsState } from "models/store/postbox/postbox";
import { PostboxActions } from "models/store/postbox/postboxActions";
import { PostboxTypes } from "Store/types/postboxTypes";

const INITIAL_STATE: IMailsState = initialMailsState();

export const PostboxReducer = (state = INITIAL_STATE, action: PostboxActions): IMailsState => {
  switch (action.type) {
    case PostboxTypes.SET_POSTBOXES: {
      return {
        ...state,
        postboxes: action.payload
      };
    }
    case PostboxTypes.ADD_POSTBOX:
      return {
        ...state,
        postboxes: [...state.postboxes, action.payload]
      };
    case PostboxTypes.REMOVE_POSTBOX: {
      return {
        ...state,
        postboxes: state.postboxes.filter((el) => el.email !== action.payload)
      };
    }

    case PostboxTypes.UPDATE_POSTBOX_FOLDERS: {
      const postboxes = state.postboxes.map((el) =>
        el.email === action.payload.email ? { ...el, folders: action.payload.folders } : el
      );
      return {
        ...state,
        postboxes
      };
    }

    case PostboxTypes.SET_LOADER:
      return {
        ...state,
        loader: action.payload
      };

    case PostboxTypes.SET_LETTERS:
      return {
        ...state,
        letters: [...state.letters, ...action.payload],
        isObserver: action.payload.length < 20 ? false : true,
        loader: false,
        pickedLetters: []
      };

    case PostboxTypes.CLEAR_LETTERS:
      return {
        ...state,
        letters: []
      };

    case PostboxTypes.CHANGE_FLAG: {
      const letters = state.letters.map((el) => {
        if (action.payload.uid.includes(el.message_id)) {
          return { ...el, [action.payload.flag]: action.payload.value };
        } else {
          return el;
        }
      });

      return {
        ...state,
        letters,
        pickedLetters: []
      };
    }

    case PostboxTypes.DELETE_LETTER: {
      const ids = action.payload;
      const letters = state.letters.filter((el) => {
        if (el.id) {
          return !ids.includes(+el.id);
        } else {
          return !ids.includes(el.uid);
        }
      });
      return {
        ...state,
        letters,
        pickedLetters: []
      };
    }

    case PostboxTypes.CHOOSE_LETTER: {
      return {
        ...state,
        pickedLetters: [...state.pickedLetters, action.payload]
      };
    }

    case PostboxTypes.CANCEL_CHOOSE_LETTER: {
      return {
        ...state,
        pickedLetters: state.pickedLetters.filter((el) => el.uid !== action.payload)
      };
    }
    case PostboxTypes.CANCEL_CHOOSE_SCHEDULE_LETTER: {
      return {
        ...state,
        pickedLetters: state.pickedLetters.filter((el) => el.id !== action.payload)
      };
    }

    case PostboxTypes.SELECT_ALL: {
      return {
        ...state,
        pickedLetters: state.pickedLetters.length === state.letters.length ? [] : [...state.letters]
      };
    }

    case PostboxTypes.NULLIFY_SELECTED: {
      return {
        ...state,
        pickedLetters: []
      };
    }

    case PostboxTypes.CHANGE_PASSWORD_POSTBOX: {
      return {
        ...state,
        postboxes: state.postboxes.map((el) =>
          el.email === action.payload.email ? { ...el, pass: action.payload.pass } : el
        )
      };
    }
    case PostboxTypes.CHANGE_NAME_POSTBOX: {
      return {
        ...state,
        postboxes: state.postboxes.map((el) =>
          el.email === action.payload.email ? { ...el, name: action.payload.name } : el
        )
      };
    }

    case PostboxTypes.INCREASE_UNREAD: {
      const postboxes = state.postboxes.map((el) => {
        if (el.email === action.payload.email) {
          const isSystem = el.folders.system.some((el) => el.orig === action.payload.folder);
          const type = isSystem ? "system" : "custom";
          const folders = {
            ...el.folders,
            [type]: el.folders[type].map((el) =>
              el.orig === action.payload.folder
                ? { ...el, info: { ...el.info, Unread: el.info.Unread + action.payload.amount } }
                : el
            )
          };
          return { ...el, folders };
        } else return el;
      });
      return {
        ...state,
        postboxes
      };
    }
    case PostboxTypes.DECREASE_UNREAD: {
      const postboxes = state.postboxes.map((el) => {
        if (el.email === action.payload.email) {
          const isSystem = el.folders.system.some((el) => el.orig === action.payload.folder);
          const type = isSystem ? "system" : "custom";
          const folders = {
            ...el.folders,
            [type]: el.folders[type].map((el) =>
              el.orig === action.payload.folder
                ? { ...el, info: { ...el.info, Unread: el.info.Unread - action.payload.amount } }
                : el
            )
          };
          return { ...el, folders };
        } else return el;
      });
      return {
        ...state,
        postboxes
      };
    }

    case PostboxTypes.UPDATE_UNREAD: {
      const postboxes = state.postboxes.map((el) => {
        if (el.email === action.payload.email) {
          const isSystem = el.folders.system.some((el) => el.orig === action.payload.folder);
          const type = isSystem ? "system" : "custom";
          const folders = {
            ...el.folders,
            [type]: el.folders[type].map((el) =>
              el.orig === action.payload.folder ? { ...el, info: action.payload.info } : el
            )
          };
          return { ...el, folders };
        } else return el;
      });

      return {
        ...state,
        postboxes
      };
    }

    case PostboxTypes.ADD_NEW_LETTERS: {
      const lettersIds = state.letters.map((letter) => letter.message_id);
      const newLetters = action.payload.filter((letter) => !lettersIds.includes(letter.message_id));
      const letters = [...newLetters, ...state.letters];
      return {
        ...state,
        letters
      };
    }

    case PostboxTypes.SET_SEARCH_LETTER: {
      return {
        ...state,
        searchLetter: action.payload
      };
    }

    default:
      return state;
  }
};
