import TagTable from "containers/Profile/Tags/TagTable/TagTable";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";

import styles from "./TagsPage.module.sass";

const TagsPage = () => {
  return (
    <WorkAreaWrap className={styles.workArea}>
      <TagTable />
    </WorkAreaWrap>
  );
};

export default TagsPage;
