import { useUserSelectors } from "Store/selectors/userSelectors";

export type VariantDate =
  | "date"
  | "time"
  | "full"
  | "month"
  | "monthShort"
  | "fullDay"
  | "dateMonth"
  | "fullDayShortMonth"
  | "year";

export const useLocale = (): { userLocale: string } => {
  const {
    userInfo: { lang }
  } = useUserSelectors();
  const userLocale = lang || navigator.language || "en-GB";

  return { userLocale };
};

export const useDateFormat = () => {
  const { userLocale } = useLocale();

  const formatDate = (date: string | number | Date, variant: VariantDate): string => {
    const dateRgx = /^\d{4}-\d{2}-\d{2}(T|\s)\d{2}:\d{2}(:\d{2})?$/;
    if (typeof date === "string" && !dateRgx.test(date)) {
      return "DateError";
    }

    const dateISO = typeof date === "string" ? (date as string).replace(/ /, "T") : date;
    const options: Intl.DateTimeFormatOptions = {};

    //  22 oct 2023
    if (variant === "date") {
      options.year = "numeric";
      options.month = "short";
      options.day = "numeric";
    }
    // 18:00
    if (variant === "time") {
      options.hour = "2-digit";
      options.minute = "2-digit";
    }
    //22 oct 2023 18:00
    if (variant === "full") {
      options.year = "numeric";
      options.month = "short";
      options.day = "numeric";
      options.hour = "2-digit";
      options.minute = "2-digit";
    }
    // October 2023
    if (variant === "month") {
      options.year = "2-digit";
      options.month = "long";
    }
    // Oct
    if (variant === "monthShort") {
      options.month = "short";
    }
    // Fr, 22 oct 2023 18:00
    if (variant === "fullDay") {
      options.year = "numeric";
      options.month = "short";
      options.day = "numeric";
      options.hour = "2-digit";
      options.minute = "2-digit";
      options.weekday = "short";
    }
    // 15 oct.
    if (variant === "dateMonth") {
      options.month = "short";
      options.day = "numeric";
    }

    // Friday, 13 Oct
    if (variant === "fullDayShortMonth") {
      options.weekday = "long";
      options.day = "numeric";
      options.month = "short";
    }
    // 2023
    if (variant === "year") {
      options.year = "numeric";
    }

    const result = new Date(dateISO).toLocaleString(userLocale, options);
    return result;
  };

  return { formatDate: (date: string | number | Date, variant: VariantDate = "date") => formatDate(date, variant) };
};
