import api from "api";
import { type AxiosResponse } from "axios";
import { TYPE_TOOLS } from "generalComponents/variables/global";

export const UserAPI = {
  async getEmployeesStorage(
    params: { uid: string; page: number; per_page: number; filter_chapter: TYPE_TOOLS },
    signal: AbortSignal
  ): Promise<AxiosResponse> {
    return await api.get("/ajax/user_storage.php", { params, signal });
  }
};
