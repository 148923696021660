import Button from "generalComponents/Button/Button";
import PopUp from "generalComponents/PopUp/PopUp";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialCreateRubricLibraryModalState } from "models/store/Cabinet/modals/createRubricLibraryModals";
import { useReducer } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { dataURLToFileSize } from "utils/filesConvert";
import { requiredRule } from "utils/validation";

import HeaderModal from "../HeaderModal/HeaderModal";
import { CreateDataRubric } from "./components/CreateDataObject/CreateDataRubric";
import { FormDataCreateaRubricState } from "./components/CreateDataObject/lib/createDataRubricReducer";
import { FieldsName, initialState, reducerData } from "./components/CreateDataObject/lib/createDataRubricReducer";
import styles from "./CreateRubricLibraryModal.module.sass";

export const CreateRubricLibraryModal = (): JSX.Element => {
  const { __ } = useLocales();

  const { onSetCreateRubricLibraryModal, onAddLibraryRubric } = useActions();

  const {
    createRubricLibraryModal: { titleHead, btnLabel, rubric }
  } = useGlobalModalsSelectors();

  const closeModal = (): void => {
    onSetCreateRubricLibraryModal(initialCreateRubricLibraryModalState());
  };

  const initialStateRubric: FormDataCreateaRubricState = {
    [FieldsName.NAME_RUBRIC]: {
      value: rubric?.name ?? "",
      required: true,
      error: requiredRule(rubric?.name),
      errorMessage: ""
    },
    [FieldsName.ICON]: {
      value: rubric?.icon ? `${projectSrc}${rubric.icon}` : "",
      required: false,
      error: false,
      errorMessage: ""
    }
  };

  const [data, dispatchData] = useReducer(reducerData, !rubric ? initialState : initialStateRubric);
  const dataCategory = data[FieldsName.CATEGORY]?.value;
  const dataNameRubric = data[FieldsName.NAME_RUBRIC].value;
  const dataIcon = data[FieldsName.ICON].value;

  const notChangedRubric = rubric?.name && rubric.name === dataNameRubric;
  const notChangedFiles = (rubric?.icon && `${projectSrc}${rubric.icon}` === dataIcon) || rubric?.icon === dataIcon;

  const getErrorForm = (): boolean => Object.values(data).some(({ error, required }) => (error && required) || error);
  const getErrorEdit = (): boolean => notChangedRubric && notChangedFiles;

  const onSubmitDataRubric = async () => {
    let icon = undefined;
    if (!notChangedFiles && dataIcon) {
      icon = await dataURLToFileSize(dataIcon);
    }
    const params = {
      id_category: dataCategory ? dataCategory : undefined,
      id_item: rubric?.id ? rubric.id : undefined,
      name: !notChangedRubric ? dataNameRubric : undefined,
      icon: !notChangedFiles && dataIcon ? icon : !notChangedFiles && !dataIcon ? "" : undefined
    };

    onAddLibraryRubric(params);
    closeModal();
  };

  return (
    <PopUp set={closeModal}>
      <HeaderModal onClose={closeModal} title={titleHead || __("Добавить рубрику")} />
      <div className={styles.wrap}>
        <form className={styles.contentWrap}>
          <div className={styles.topWrap}>
            <CreateDataRubric data={data} dispatchData={dispatchData} />
          </div>
          <div className={styles.buttonsWrap}>
            <Button
              type="button"
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Отменить")}
              onClick={closeModal}
            />
            <Button
              type="button"
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={btnLabel || __("Создать")}
              onClick={onSubmitDataRubric}
              disabled={getErrorForm() || getErrorEdit()}
            />
          </div>
        </form>
      </div>
    </PopUp>
  );
};
