import BarsPreviewList from "generalComponents/ItemsViewTypeVariants/BarsPreviewView/BarsPreviewList/BarsPreviewList";
import BarsView from "generalComponents/ItemsViewTypeVariants/BarsView/BarsView";
import LinesPreviewView from "generalComponents/ItemsViewTypeVariants/LinesPreviewView/LinesPreviewView";
import LinesView from "generalComponents/ItemsViewTypeVariants/LinesView/LinesView";
import { LinesViewElements } from "generalComponents/ItemsViewTypeVariants/LinesViewElements/LinesViewElements";
import { ViewType } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { FilesNextArrayType } from "models/store/files/files";
import { AllElementsArrayType } from "models/store/typeElements";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

export const useItemsViewType = () => {
  const { view } = useFilesSelectors();
  const isArchive = useCurrentPathStarstWith(ROUTES.ARCHIVE);
  const isTrash = useCurrentPathStarstWith(ROUTES.TRASH);

  const renderItemsViewType = (files: FilesNextArrayType | AllElementsArrayType): JSX.Element => {
    switch (view) {
      case ViewType.BARS:
        return <BarsView items={files} />;
      case ViewType.BARS_PREVIEW:
        return <BarsPreviewList items={files} />;
      case ViewType.LINES:
        if (isArchive || isTrash) {
          return <LinesViewElements items={files} />;
        }
        return <LinesView items={files} />;
      case ViewType.LINES_PREVIEW:
        return <LinesPreviewView />;
    }
  };

  return {
    renderItemsViewType
  };
};
