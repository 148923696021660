import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow-down.svg";
import { ReactComponent as TaskIcon } from "assets/PrivateCabinet/calendar-4.svg";
import { ReactComponent as CollapseIcon } from "assets/PrivateCabinet/collapse.svg";
import { ReactComponent as ExpandIcon } from "assets/PrivateCabinet/frame.svg";
import { ReactComponent as MailIcon } from "assets/PrivateCabinet/mail/unread.svg";
import { ReactComponent as MeetingIcon } from "assets/PrivateCabinet/meeting.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-5.svg";
import { ReactComponent as AddIcon } from "assets/PrivateCabinet/plus-3.svg";
import classNames from "classnames";
import { STATUS_TYPES, TASK_MODALS, TASK_TYPES, TASKS_SCHEMA } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { ITasksBlockProps } from "models/calendarPage/taskBlock";
import { IMyTask, TASK_DEP } from "models/store/tasks/tasks";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { INITIAL_ADD_TASK } from "utils/tasks";

import CallItem from "../../Tasks/Components/CallItem/CallItem";
import MailItem from "../../Tasks/Components/MailItem/MailItem";
import MeetingItem from "../../Tasks/Components/MeetingItem/MeetingItem";
import TaskLine from "../../Tasks/Components/TaskLine/TaskLine";
import styles from "./TasksBlock.module.sass";

const TasksBlock: React.FC<ITasksBlockProps> = ({ tasks, type, isFull, setSchema, isGrid = false }): JSX.Element => {
  const { __ } = useLocales();
  const { onSetTaskModal } = useActions();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getTitle = (): JSX.Element => {
    switch (type) {
      case TASK_TYPES.TASK:
        return (
          <>
            <span>{__("Задачи")}</span>
            <TaskIcon className={styles.taskIcon} />
          </>
        );
      case TASK_TYPES.MEETINGS:
        return (
          <>
            <span>{__("Встречи")}</span>
            <MeetingIcon className={styles.taskIcon} />
          </>
        );
      case TASK_TYPES.CALLS:
        return (
          <>
            <span>{__("Звонки")}</span>
            <PhoneIcon className={styles.taskIcon} />
          </>
        );
      case TASK_TYPES.MAILS:
        return (
          <>
            <span>{__("Письма")}</span>
            <MailIcon />
          </>
        );
      default:
        return;
    }
  };

  const getAmountTasks = (): string => {
    switch (type) {
      case TASK_TYPES.TASK:
        return tasks.length > 0 ? `${tasks.length} ${__("задачи")}` : __("Нет задач");
      case TASK_TYPES.MEETINGS:
        return tasks.length > 0 ? `${tasks.length} ${__("встреч")}` : __("Нет встреч");
      case TASK_TYPES.CALLS:
        return tasks.length > 0 ? `${tasks.length} ${__("звонков")}` : __("Нет звонков");
      case TASK_TYPES.MAILS:
        return tasks.length > 0 ? `${tasks.length} ${__("писем")}` : __("Нет писем");

      default:
        return;
    }
  };

  const onExpand = (): void => {
    switch (type) {
      case TASK_TYPES.TASK:
        return setSchema(isFull ? TASKS_SCHEMA.GRID_BAR : TASKS_SCHEMA.EXPANDED_TASKS_BOARD);
      case TASK_TYPES.MEETINGS:
        return setSchema(isFull ? TASKS_SCHEMA.GRID_BAR : TASKS_SCHEMA.EXPANDED_MEETINGS_BOARD);
      case TASK_TYPES.CALLS:
        return setSchema(isFull ? TASKS_SCHEMA.GRID_BAR : TASKS_SCHEMA.EXPANDED_CALLS_BOARD);
      case TASK_TYPES.MAILS:
        return setSchema(isFull ? TASKS_SCHEMA.GRID_BAR : TASKS_SCHEMA.EXPANDED_MAIL_BOARD);

      default:
        return;
    }
  };

  const getTypeModal = (): TASK_MODALS => {
    switch (type) {
      case TASK_TYPES.TASK:
        return TASK_MODALS.ADD_TASK;
      case TASK_TYPES.MEETINGS:
        return TASK_MODALS.ADD_MEETING;
      case TASK_TYPES.CALLS:
        return TASK_MODALS.ADD_CALL;
      case TASK_TYPES.MAILS:
        return TASK_MODALS.ADD_LETTER;

      default:
        return;
    }
  };

  const renderTask = (task: IMyTask): JSX.Element => {
    switch (type) {
      case TASK_TYPES.TASK:
        return <TaskLine key={task.id} task={task} isFull={isFull} />;
      case TASK_TYPES.MEETINGS:
        return <MeetingItem key={task.id} task={task} isFull={isFull} />;
      case TASK_TYPES.CALLS:
        return <CallItem key={task.id} task={task} isFull={isFull} />;
      case TASK_TYPES.MAILS:
        return <MailItem key={task.id} task={task} isFull={isFull} />;

      default:
        return;
    }
  };
  const toggleOpen = (): void => setIsOpen((open) => !open);

  const onAddTask = (): void => {
    onSetTaskModal({
      type: getTypeModal(),
      params: {
        ...INITIAL_ADD_TASK,
        id_type: type === TASK_TYPES.MEETINGS ? TASK_TYPES.OFFLINE_MEETING : type,
        id_dep: TASK_DEP.WORK,
        id_status: type === TASK_TYPES.TASK ? STATUS_TYPES.QUEUE : STATUS_TYPES.IN_PROGRESS
      }
    });
  };

  return (
    <div className={classNames(styles.wrap, styles[type], { [styles.full]: isFull, [styles.grid]: isGrid })}>
      <div className={styles.header}>
        <div className={styles.block}>
          <AddIcon onClick={onAddTask} className={styles.addIcon} />
          {getTitle()}
        </div>
        <div className={styles.block}>
          {!isFull && !isGrid && (
            <button className={classNames(styles.arrowBtn, { [styles.open]: isOpen })} onClick={toggleOpen}>
              <ArrowIcon />
            </button>
          )}
          <button onClick={onExpand}>{isFull ? <CollapseIcon /> : <ExpandIcon className={styles.expandIcon} />}</button>
        </div>
      </div>
      <div className={styles.amount}>{getAmountTasks()}</div>
      {(isFull || isGrid || (isOpen && tasks.length > 0)) && (
        <ul className={styles.taskList}>{tasks.map((task) => renderTask(task))}</ul>
      )}
    </div>
  );
};

export default TasksBlock;
