//  return st******last
export const onHideText = (text: string, visibleStartChars: number = 2, visibleEndChars: number = 5): string => {
  const textLength = text.length;

  if (textLength >= visibleStartChars + visibleEndChars) {
    const visiblePartStart = text.substring(0, visibleStartChars);
    const visiblePartEnd = text.substring(textLength - visibleEndChars, textLength);
    const maskedPart = "*".repeat(textLength - visibleStartChars - visibleEndChars);
    const result = visiblePartStart + maskedPart + visiblePartEnd;
    return result;
  } else {
    return text;
  }
};
