import classNames from "classnames";
import { isToday } from "date-fns";
import { useGenerateCalendarWeeks } from "generalComponents/Calendars/CalendarHelper";
import { createTasksObj, useTasksCalendar } from "generalComponents/Services/calendarPageServices";
import { TASK_TYPES, TaskFilters } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { ICalendarDay, TYPE_DAY } from "models/generalComponents/calendars";
import { IMyTask } from "models/store/tasks/tasks";
import React, { useMemo } from "react";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import DaysOfWeeks from "../DaysOfWeeks/DaysOfWeeks";
import TasksPopover from "../TasksPopover/TasksPopover";
import styles from "./MonthView.module.sass";

const MonthView = (): JSX.Element => {
  const weeksOfMonth = useGenerateCalendarWeeks();
  const {
    filters: { date }
  } = useTasksSelectors();
  const { tasks, calls, meetings, mails, reminder } = useTasksCalendar();
  const { onSelectFilterType, onSelectFilterDate } = useActions();

  const selectDay = (day: number): void => {
    onSelectFilterDate({ ...date, day });
    onSelectFilterType(TaskFilters.BY_DAY);
  };

  const objTasks: Record<string, IMyTask[]> = useMemo(() => createTasksObj(tasks), [tasks]);
  const objMeetings: Record<string, IMyTask[]> = useMemo(() => createTasksObj(meetings), [meetings]);
  const objCalls: Record<string, IMyTask[]> = useMemo(() => createTasksObj(calls), [calls]);
  const objMails: Record<string, IMyTask[]> = useMemo(() => createTasksObj(mails), [mails]);
  const objReminder: Record<string, IMyTask[]> = useMemo(() => createTasksObj(reminder), [reminder]);

  const tasksView = (day: number): JSX.Element => {
    const time = new Date(date.year, date.month, day).getTime();
    return (
      <div className={styles.tasks}>
        {objTasks[time]?.length && <TasksPopover type={TASK_TYPES.TASK} tasks={objTasks[time]} />}
        {objMeetings[time]?.length && <TasksPopover type={TASK_TYPES.MEETINGS} tasks={objMeetings[time]} />}
        {objCalls[time]?.length && <TasksPopover type={TASK_TYPES.CALLS} tasks={objCalls[time]} />}
        {objMails[time]?.length && <TasksPopover type={TASK_TYPES.MAILS} tasks={objMails[time]} />}
        {objReminder[time]?.length && <TasksPopover type={TASK_TYPES.REMINDER} tasks={objReminder[time]} />}
      </div>
    );
  };

  const renderDay = (week: ICalendarDay[]): JSX.Element[] => {
    return week.map((day, idx) => {
      return (
        <div
          key={idx}
          className={classNames(styles.dayBox, {
            [styles.today]: isToday(new Date(date.year, date.month, day.value)),
            [styles.light]: idx > 4
          })}
        >
          <div
            className={classNames(styles.dayValue, { [styles.hidden]: day.typeDay !== TYPE_DAY.CURRENT })}
            onClick={() => selectDay(day.value)}
          >
            {day.value}
          </div>
          <div className={styles.tasksContainer}> {tasksView(day.value)}</div>
        </div>
      );
    });
  };

  const renderMonth = (): JSX.Element[] => {
    const weeks = weeksOfMonth({ year: date.year, month: date.month });
    return weeks.map((week, i) => (
      <div key={i} className={styles.weekRow}>
        {renderDay(week)}
      </div>
    ));
  };

  return (
    <div className={styles.wrap}>
      <DaysOfWeeks />
      <div className={styles.month}>{renderMonth()}</div>
    </div>
  );
};

export default MonthView;
