import Button from "generalComponents/Button/Button";
import { ChooseFolder } from "generalComponents/ChooseFolder/ChooseFolder";
import CombinedElements from "generalComponents/Elements/CombinedElements";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import PopUp from "generalComponents/PopUp/PopUp";
import { useCurrentFolder } from "generalComponents/Services/folderServices/folderServices";
import { Times } from "generalComponents/Times/Times";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialEditItemsModalState } from "models/store/Cabinet/modals/modals";
import { EditItemsTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./EditItemsModal.module.sass";

const EditItemsModal = (): JSX.Element => {
  const { __ } = useLocales();
  const { onSetEditItemsModal, onEditFolders, onEditFiles, onEditSafeFolder, onSetUpdateFiles, onSetUpdateNextFiles } =
    useActions();
  const { safeDir } = useParams();
  const {
    editItemsModal: { type, params }
  } = useGlobalModalsSelectors();
  const currentFolder = useCurrentFolder();

  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const isFilePage = useCurrentPathStarstWith(ROUTES.FILES);

  const { isLinesPreviewView } = useGetItemView();

  const safe = useAuthorizedSafe();

  const { folders } = useFoldersSelectors();

  const [_folder, _setFolder] = useState<IFolder>(currentFolder);
  const [_isEditFoldersType] = useState<boolean>(type === EditItemsTypes.EDIT_FOLDERS);

  const [color, setColor] = useState<string>();
  const [emo, setEmo] = useState<string>("");
  const [tagOption, setTagOption] = useState<string[]>([]);

  const closeModal = (): void => {
    onSetEditItemsModal(initialEditItemsModalState());
  };

  const onSelect = (folder: IFolder): void => {
    _setFolder(folder);
  };

  const renderTitleOption = (): string => {
    return _isEditFoldersType ? __("Редактирование нескольких папок") : __("Редактирование нескольких файлов");
  };

  const onSubmit = (): void => {
    const safe_dir = safeDir ?? "0";
    if (_isEditFoldersType) {
      const folders = params.items.filter((item) => item.is_dir === 1);
      const updateFolders = folders.map((item) => ({
        ...item,
        tags: tagOption || item.tags,
        color: color || item.color,
        emo: emo || item.emo
      })) as IFolder[];

      const payload = {
        id_parent: isSafePage ? safe_dir : _folder.id_dir,
        id_dirs: folders.map((folder) => folder.id_dir),
        tags: tagOption || undefined,
        color: color || undefined,
        emo: emo || undefined
      };

      const payloadSafe = {
        ...safe,
        ...payload
      };

      isSafePage
        ? onEditSafeFolder(payloadSafe, EVENT_TYPE.EDIT)
        : onEditFolders(payload, EVENT_TYPE.EDIT, () => {
            if (isLinesPreviewView) {
              onSetUpdateNextFiles(updateFolders);
            } else {
              onSetUpdateFiles(updateFolders);
            }

            closeModal();
          });
    } else {
      const files = params.items.filter((item) => item.is_dir === 0);
      const updateFiles = files.map((item) => ({
        ...item,
        tags: tagOption || item.tags,
        color: color || item.color,
        emo: emo || item.emo
      })) as IFile[];

      const payload = {
        id_dir: isSafePage ? safe_dir : _folder.id_dir,
        fids: files.map((file) => (file as IFile).fid),
        tags: tagOption || undefined,
        color: color || undefined,
        emo: emo || undefined
      };

      onEditFiles(payload, EVENT_TYPE.EDIT, safe, () => {
        if (isLinesPreviewView) {
          onSetUpdateNextFiles(updateFiles);
        } else {
          onSetUpdateFiles(updateFiles);
        }

        closeModal();
      });
    }
  };

  const onClose = (): void => {
    closeModal();
    if (params.callbackCancel) params.callbackCancel();
  };

  return (
    <PopUp set={onClose}>
      <div className={styles.wrap}>
        <Times handleClick={onClose} />
        <h2 className={styles.title}>{renderTitleOption()}</h2>
        <div className={styles.content}>
          {!isSafePage && !isFilePage && <ChooseFolder onSelect={onSelect} folder={_folder} folders={folders} />}
          <CombinedElements
            tags={tagOption}
            onSelectTags={setTagOption}
            color={color}
            setColor={setColor}
            emo={emo}
            setEmo={setEmo}
          />
        </div>
        <div className={styles.buttonsWrap}>
          <Button
            type="button"
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.LARGE}
            text={__("Отмена")}
            onClick={onClose}
          />
          <Button
            type="button"
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.LARGE}
            text={__("Сохранить")}
            onClick={onSubmit}
            disabled={
              (!_folder &&
                !isSafePage &&
                !isFilePage &&
                params.items.some((el) => el.is_dir === 1) &&
                params.items.findIndex((el) => el.id_dir === _folder.id_dir) >= 0) ||
              (!_folder && !tagOption?.length && !color && !emo)
            }
          />
        </div>
      </div>
    </PopUp>
  );
};

export default EditItemsModal;
