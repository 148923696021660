/* eslint-disable react/display-name */
import { Image } from "generalComponents/Image/Image";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { PreviewUploadImgPdf } from "generalComponents/PreviewUploadImgPdf/PreviewUploadImgPdf";
import { PreviewUploadImgVideo } from "generalComponents/PreviewUploadImgVideo/PreviewUploadImgVideo";
import { splitFilename } from "generalComponents/Services/helpers";
import { memo } from "react";

import styles from "./PreviewUploadFileLibrary.module.sass";

export interface IPreviewUploadFileLibraryProps {
  file: File;
}

export const PreviewUploadFileLibrary = memo<IPreviewUploadFileLibraryProps>(
  ({ file }: IPreviewUploadFileLibraryProps): JSX.Element => {
    const fileName = splitFilename(file.name).name;
    const fileFormat = splitFilename(file.name).format;

    const image = file.type.includes("image") && !file.type.includes("svg") ? URL.createObjectURL(file) : "";
    const pdf = file.type.includes("pdf") ? URL.createObjectURL(file) : "";
    const videoFile: File | undefined = file.type.includes("video") ? file : undefined;

    return (
      <div className={styles.card}>
        <div className={`${styles.card__head} flex-center`}>
          {image && <Image src={image} alt={fileName} />}
          {pdf && <PreviewUploadImgPdf pdfUrl={pdf} fileName={fileName} />}
          {videoFile && <PreviewUploadImgVideo file={videoFile} />}
          {!image && !pdf && !videoFile && (
            <ItemIcon isFolder={false} width={33} height={44} color={""} ext={fileFormat.toLocaleUpperCase()} />
          )}
        </div>
        <div className={styles.card__body}>
          <p className={styles.card__name}>{fileName}</p>
        </div>
      </div>
    );
  }
);
