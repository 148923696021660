import { IJoinProjectLink } from "models/store/joinProjects/joinProgects";

export interface ILinksModalState {
  open: boolean;
  link?: IJoinProjectLink;
}

export function initialLinksModalState(): ILinksModalState {
  return { open: false, link: undefined };
}
