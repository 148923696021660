import { ReactComponent as LeaveIcon } from "assets/PrivateCabinet/logout.svg";
import Button from "generalComponents/Button/Button";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import React from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";

import styles from "./LeaveProject.module.sass";

const LeaveProject = () => {
  const { __ } = useLocales();
  const dispatch = useDispatch();

  const onChosseAdmin = () =>
    dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.CHOOSE_ADMIN, params: { isSeveral: false } }));

  return (
    <div className={styles.wrap}>
      <LeaveIcon className={styles.icon} />
      <p className={styles.text}>
        {__(
          "Вы являитесь администратором данного проекта, для выхода Вам необходимо назначить нового администратора проекта с передачей всех прав администратора"
        )}
      </p>
      <Button type="button" variant="ok" onClick={onChosseAdmin} text={__("Выбрать Администратора")} />
    </div>
  );
};

export default LeaveProject;
