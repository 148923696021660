import classNames from "classnames";
import html2canvas from "html2canvas";
import React, { useLayoutEffect, useRef, useState } from "react";

import ProjectRoles from "../Components/ProjectRoles/ProjectRoles";
import AnaliticCharts from "./AnaliticCharts/AnaliticCharts";
import styles from "./ProjectAnalytics.module.sass";
import ServicePanel from "./ServicePanel/ServicePanel";

const ProjectAnalytics = () => {
  const [date, setDate] = useState(null);
  const [height, setHeight] = useState("100%");

  const ref = useRef();
  const iframeRef = useRef();

  useLayoutEffect(() => {
    if (height === "auto") {
      getPrint();
    }
  }, [height]);

  const getPrint = async () => {
    try {
      let block = ref.current;
      const canvas = await html2canvas(block);
      const dataUrl = canvas.toDataURL("image/png");
      if (iframeRef.current) {
        let isPrinted = false;
        const img = new Image();
        img.width = 750;
        img.onload = () => {
          if (!isPrinted) {
            const iframeWindow = iframeRef.current.contentWindow || iframeRef.current;
            iframeWindow.document.body.appendChild(img);
            iframeWindow.focus();
            iframeWindow.print();
            iframeWindow.document.body.removeChild(img);
            isPrinted = true;
          }
        };
        img.src = dataUrl;
        setHeight("100%");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSnapshot = async () => {
    setHeight("auto");
  };

  return (
    <div ref={ref} style={{ height }} className={classNames(styles.wrap)}>
      <iframe ref={iframeRef} style={{ display: "none" }} title="analiticFrame" />
      <ProjectRoles />
      <ServicePanel date={date} setDate={setDate} getSnapshot={getSnapshot} />
      <AnaliticCharts date={date} setDate={setDate} />
    </div>
  );
};

export default ProjectAnalytics;
