import { switchSign } from "generalComponents/Services/elementsServices";
import { ISignItemProps } from "models/generalComponents/elements";
import React from "react";

import styles from "./SignItem.module.sass";

const SignItem: React.FC<ISignItemProps> = ({ sign, size = 20 }): JSX.Element => {
  return (
    <div className={styles.box} style={{ width: size, height: size }}>
      {switchSign(sign)}
    </div>
  );
};

export default SignItem;
