import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow-down.svg";
import { ReactComponent as EditIcon } from "assets/PrivateCabinet/edit.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import { ReactComponent as CopyIcon } from "assets/PrivateCabinet/link-2.svg";
import { ReactComponent as OpenIcon } from "assets/PrivateCabinet/open.svg";
import { ReactComponent as SubstractIcon } from "assets/PrivateCabinet/subtract.svg";
import classNames from "classnames";
import { format } from "date-fns";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { useCopyLink } from "generalComponents/Services/browserServices";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { useActions } from "hooks/useActions";
import PropTypes from "prop-types";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";
import { taskTypes } from "types/Project";

import BacklogInput from "../../Components/BacklogInput/BacklogInput";
import Role from "../../Components/Role/Role";
import { useProjectMsg } from "../../helpers";
import { BacklogProvider } from "../ProjectBacklog";
import styles from "./BacklogTask.module.sass";

const BacklogTask = ({ task, handleSelected, selectedTasks }) => {
  const { __ } = useLocales();
  const MSG = useProjectMsg();
  const dispatch = useDispatch();
  const [taskName, setTaskName] = useState(task.name);
  const [isOpenMove, setisOpenMove] = useState(false);
  const { isEdit, setIsEdit, isSelect } = useContext(BacklogProvider);
  const linkRef = useRef(null);
  const moveRef = useRef(null);
  const { project } = useProjectsSelectors();
  const copyLink = useCopyLink();
  const { onEditBacklogTask } = useActions();

  useOutsideClick(moveRef, () => setisOpenMove(false));

  const handleOpen = (e) => {
    e.stopPropagation();
    dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.OPEN_TASK, params: task }));
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setIsEdit(task.id);
  };

  const handleCopyLink = (e) => {
    e.stopPropagation();
    copyLink(linkRef, task.link);
  };

  const onSubmit = (value) => {
    if (value.trim()) {
      setTaskName(value);
      const params = {
        id: task.id,
        name: value,
        is_backlog: task.is_backlog
      };
      const messages = {
        error: MSG.ERROR,
        success: MSG[PROJECT_MODALS.EDIT_TASK]
      };
      onEditBacklogTask(params, messages);
    }
    setIsEdit("");
  };

  const handleMove = (e, id) => {
    e.stopPropagation();
    const is_user = task.id_executor.length > 0;
    if (is_user && id !== -1) {
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.WARNING,
          params: { text: <p className={styles.text}>{__("Вы не можете переместить задачу в выбранную роль")}</p> }
        })
      );
    } else {
      const params = {
        id: task.id,
        name: task.name,
        is_backlog: id
      };
      const messages = {
        error: MSG.ERROR,
        success: { title: __("Задача пермещена") }
      };
      onEditBacklogTask(params, messages);
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.TASK_TO_TRASH, params: task }));
  };

  const toggleOpenMove = (e) => {
    e.stopPropagation();
    setisOpenMove((open) => !open);
  };

  const isSelected = useMemo(() => selectedTasks.some((t) => t.id === task.id), [selectedTasks, task.id]);

  return (
    <>
      {isEdit === task.id ? (
        <BacklogInput taskName={taskName} textButton={__("Сохранить")} onSubmit={onSubmit} />
      ) : (
        <div
          className={classNames(styles.wrap, { [styles.select]: isSelected })}
          onClick={(e) => (isSelect ? handleSelected(task) : handleOpen(e))}
        >
          <div className={styles.name}>{taskName}</div>
          <div className={styles.date}>{format(new Date(task.ut), "dd.MM.yyyy")}</div>
          <ButtonIcon handleClick={handleOpen} icon={<OpenIcon />} variant="white" />
          <div className={styles.roleSelectBlock}>
            <ButtonIcon handleClick={toggleOpenMove} icon={<ArrowIcon />} variant="white" isActive={isOpenMove} />
            {isOpenMove && (
              <div ref={moveRef} className={styles.roleSelect}>
                {project.roles.map((r) => (
                  <div key={r.id} onClick={(e) => handleMove(e, r.id)}>
                    <Role role={r} />
                  </div>
                ))}
              </div>
            )}
          </div>

          <ButtonIcon handleClick={handleEdit} icon={<EditIcon />} variant="white" />
          <ButtonIcon handleClick={handleCopyLink} icon={<CopyIcon />} variant="white" />
          <ButtonIcon handleClick={(e) => handleMove(e, -1)} icon={<SubstractIcon />} variant="white" />
          <ButtonIcon handleClick={handleDelete} variant="white" notHover>
            <TrashIcon className={styles.icon} />
          </ButtonIcon>
        </div>
      )}
      <input value={task.link} readOnly className={styles.hiddenInput} ref={linkRef} />
    </>
  );
};

export default BacklogTask;

BacklogTask.propTypes = {
  task: taskTypes,
  handleSelected: PropTypes.func,
  selectedTasks: PropTypes.arrayOf(taskTypes)
};
