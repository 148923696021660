import { format } from "date-fns";

// convert Date | yyyy-MM-dd HH:mm => yyyy-MM-ddTHH:mm
export const dateISO = (value: string | Date): string => {
  if (typeof value === "string") {
    return value.replace(/ /, "T");
  }
  if (value instanceof Date) {
    return format(value, "yyyy-MM-dd HH:mm:ss").replace(/ /, "T");
  }
  return "";
};
