import classNames from "classnames";
import { colors, emojis } from "collections/markers";
import { FC, ReactNode } from "react";
import { useFilterCriteriaSelector } from "Store/selectors/filterCriteriaSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./MarksContent.module.sass";

interface IMarksContentProps {
  tags: string[];
  onSetTag: (v: string) => void;
  color: string;
  onSetColor: (v: string) => void;
  emoji: string;
  onSetEmoji: (v: string) => void;
  onSetShow: (show: boolean) => void;
  children?: ReactNode;
  isFilter?: boolean;
  showColors?: boolean;
  showEmojis?: boolean;
  showTags?: boolean;
}

export const MarksContent: FC<IMarksContentProps> = ({
  tags,
  onSetTag,
  color,
  onSetColor,
  emoji,
  onSetEmoji,
  onSetShow,
  children,
  showColors = true,
  showEmojis = true,
  showTags = true
}) => {
  const { tags: userTags } = useUserSelectors();
  const { filterCriteria } = useFilterCriteriaSelector();

  const onSelectColor = (v: string) => {
    onSetColor(v === color ? "" : v);
    onSetShow(false);
  };

  const onSelectEmoji = (v: string) => {
    onSetEmoji(v === emoji ? "" : v);
    onSetShow(false);
  };

  const onSelectTag = (v: string) => {
    onSetTag(v);
    onSetShow(false);
  };

  return (
    <div>
      {children && <div className={styles.block}>{children}</div>}
      {showTags && (
        <div className={styles.block}>
          <div className={styles.list}>
            {userTags.map((item) => (
              <div
                key={item.id}
                className={classNames(styles.tag, {
                  [styles.active]: tags?.includes(item.id) || filterCriteria.tags.includes(item.id)
                })}
                onClick={() => onSelectTag(item.id)}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {showColors && (
        <div className={styles.block}>
          <div className={styles.list}>
            {colors.map((el, i) => (
              <div
                key={i}
                className={classNames(styles.element, { [styles.active]: el === color })}
                onClick={() => onSelectColor(el)}
              >
                <div className={styles.color} style={{ background: el }} />
              </div>
            ))}
          </div>
        </div>
      )}
      {showEmojis && (
        <div className={styles.block}>
          <div className={styles.list}>
            {emojis.map((el, i) => (
              <div
                key={i}
                className={classNames(styles.element, { [styles.active]: el === emoji })}
                onClick={() => onSelectEmoji(el)}
              >
                <div className={styles.emoji} dangerouslySetInnerHTML={{ __html: el }} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
