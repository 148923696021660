import { useTaskStatuses } from "collections/tasks";
import { ITaskStatus } from "models/generalComponents/collections/tasks";
import { IMyTask } from "models/store/tasks/tasks";
import { ITasksLineProps } from "models/tasks/myTasks";
import StatusLine from "Pages/Cabinet/Components/Tasks/Components/StatusLine/StatusLine";
import TaskDate from "Pages/Cabinet/Components/Tasks/Components/TaskDate/TaskDate";
import TaskLine from "Pages/Cabinet/Components/Tasks/Components/TaskLine/TaskLine";
import React from "react";
import { taskOfStatus } from "utils/tasks";

import styles from "./TasksLine.module.sass";

const TasksLine: React.FC<ITasksLineProps> = ({ tasks }): JSX.Element => {
  const { myTasks } = useTaskStatuses();

  const renderStatus = (status: ITaskStatus, values: IMyTask[]) => {
    const tasks = taskOfStatus(status.status, values);
    return (
      tasks.length > 0 && (
        <StatusLine title={status.nameTask}>
          {tasks.map((t, i) => (
            <TaskLine key={t.id} task={t} num={i + 1} />
          ))}
        </StatusLine>
      )
    );
  };

  return (
    <div className={styles.wrap}>
      {Object.entries(tasks).map(([key, values], i) => (
        <div key={key}>
          <TaskDate amountWeeks={Object.keys(tasks).length} idx={i + 1} time={key} values={values} />
          {myTasks.map((el, i) => (
            <React.Fragment key={i}>{renderStatus(el, values)}</React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};
export default TasksLine;
