import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import { Observer } from "generalComponents/Observer/Observer";
import { useActions } from "hooks/useActions";
import { LoaderTypes } from "models/generalComponents/loader";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useMemorySelectors } from "Store/selectors/memorySelectors";

import { ListEmployees } from "../../../ListEmployees/ListEmployees";
import { PanelBox } from "../PanelBox/PanelBox";
import styles from "./PanelEmployees.module.sass";

export const PanelEmploees: FC = (): JSX.Element => {
  const { __ } = useLocales();

  const { loader, employees, page, total } = useMemorySelectors();

  const { onGetEmploeesMemory, onSetEmployeesMemoryPage } = useActions();

  const message = {
    success: "",
    error: __("Не удалось получить список сотрудников, попробуйте еще раз")
  };

  const onLoadMore = (): void => {
    const controller = new AbortController();

    onSetEmployeesMemoryPage(page + 1);

    onGetEmploeesMemory(message, controller);
  };

  const renderEmployees = (): JSX.Element => {
    if (employees !== null) {
      if (total === 0) {
        return <Clue icon="sad" text={__("Здесь пока пусто")} />;
      }

      return <ListEmployees employees={employees} />;
    }
  };

  return (
    <PanelBox title={__("Сотрудники")}>
      {loader && page === 1 ? (
        <div className={styles.loaderBox}>
          <Loader
            type={LoaderTypes.BOUNCING_DOTS}
            position="absolute"
            background="white"
            zIndex={5}
            width="100px"
            height="100px"
            containerType="bounceDots"
          />
        </div>
      ) : (
        <>
          {renderEmployees()}
          {<Observer loader={loader} page={page} total={total} callBack={onLoadMore} />}
        </>
      )}
    </PanelBox>
  );
};
