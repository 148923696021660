import { useEmployeeRights } from "collections/profile";
import Switcher from "generalComponents/Switcher/Switcher";
import { EMPLOYEE_RIGHTS } from "models/store/user/user";
import { FC } from "react";
import { useLocales } from "react-localized";

import { RightsState } from "../lib/stateReducer";
import styles from "./EmployeeRights.module.sass";

interface IProps {
  rights: RightsState;
  dispatchRights: Function;
}

const mgrRights = [
  EMPLOYEE_RIGHTS.IS_MGR_EMPLOYEE,
  EMPLOYEE_RIGHTS.IS_MGR_FINANCE,
  EMPLOYEE_RIGHTS.IS_MGR_LIBRARY,
  EMPLOYEE_RIGHTS.IS_MGR_PROJECT
];
const accessRights = [
  EMPLOYEE_RIGHTS.IS_FILES,
  EMPLOYEE_RIGHTS.IS_SAFE,
  EMPLOYEE_RIGHTS.IS_PROJECT,
  EMPLOYEE_RIGHTS.IS_MAIL,
  EMPLOYEE_RIGHTS.IS_CHAT,
  EMPLOYEE_RIGHTS.IS_ARCHIVE,
  EMPLOYEE_RIGHTS.IS_LIBRARY,
  EMPLOYEE_RIGHTS.IS_JOURNAL
];

const EmployeeRights: FC<IProps> = ({ rights, dispatchRights }) => {
  const { __ } = useLocales();
  const RIGHTS = useEmployeeRights();
  return (
    <div className={styles.wrap}>
      <div className={styles.block}>
        <span className={styles.title}>{__("Администрирование")}</span>
        <ul className={styles.list}>
          {mgrRights.map((field, i) => (
            <li key={i} className={styles.item}>
              <Switcher checked={rights[field]} onClickCheckBox={() => dispatchRights({ type: "CHANGE", field })} />
              <span className={styles.name}>{RIGHTS[field]}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.block}>
        <span className={styles.title}>{__("Инструменты")}</span>
        <ul className={styles.list}>
          {accessRights.map((field, i) => (
            <li key={i} className={styles.item}>
              <Switcher checked={rights[field]} onClickCheckBox={() => dispatchRights({ type: "CHANGE", field })} />
              <span className={styles.name}>{RIGHTS[field]}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EmployeeRights;
