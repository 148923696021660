import api from "api";
import { ReactComponent as LockIcon } from "assets/icons/sign_in_lock.svg";
import Button from "containers/Authentication/Button/Button";
import Input from "containers/Authentication/Input/Input";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { ROUTES } from "generalComponents/variables/routing";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router-dom";

import styles from "./ThirdStep.module.sass";

const ThirdStep = ({ code, login }: { code: string; login: string }): JSX.Element => {
  const { __ } = useLocales();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setError("");
  };

  const handleSubmit = async () => {
    if (password.length < 4) {
      setError(__("Пароль должен состоять минимум из 4 символов."));
    } else {
      try {
        const params = {
          name: login,
          code: code,
          pass: password
        };
        const { data } = await api.post(`/ajax/user_pass_set.php`, null, { params });
        checkResponseStatus(data.ok);
        navigate(`/${ROUTES.SIGN_IN}`);
      } catch {
        setError(__("Упс!!! Что-то пошло не так!"));
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4>{__("Новый пароль")}</h4>
        <p>{__("Ваш предыдущий пароль был сброшен. Введите новый пароль.")}</p>
        <div className={styles.input}>
          <Input
            value={password}
            onChange={handlePasswordChange}
            placeholder={__("Пароль")}
            icon={<LockIcon />}
            type="password"
            error={error}
          />
        </div>
      </div>
      <Button
        disabled={!password}
        animationClassName={styles.animation}
        className={styles.confirm_button}
        onClick={handleSubmit}
      >
        {__("Сохранить")}
      </Button>
    </div>
  );
};

export default ThirdStep;
