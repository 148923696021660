import classNames from "classnames";
import { IDefaultButtonIconProps } from "models/generalComponents/defaultButtonIcon";
import React from "react";

import styles from "./DefaultButtonIcon.module.sass";

export const DefaultButtonIcon: React.FC<IDefaultButtonIconProps> = ({
  className,
  width,
  height,
  children
}): JSX.Element => {
  return (
    <span className={classNames(className, styles.root)} style={{ width, height }}>
      {children}
    </span>
  );
};
