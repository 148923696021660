import { ReactComponent as InfoIcon } from "assets/icons/info_3.svg";
import { ReactComponent as ReturnIcon } from "assets/icons/restore_2.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash_2.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload_2.svg";
import { msToDateObject } from "generalComponents/Calendars/CalendarHelper";
import { CLEANING_TIME } from "generalComponents/variables/global";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { PROFILE_ROUTES, PROJECT_PATH, ROUTES, ROUTES_SEARCH_PARAMS } from "generalComponents/variables/routing";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { initialPropertiesElementModalState } from "models/store/Cabinet/modals/propertiesElementModals";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IProject } from "models/store/joinProjects/joinProgects";
import { ISafe } from "models/store/safe/safe";
import { IMyTask, TaskFilters } from "models/store/tasks/tasks";
import { IUserInfo } from "models/store/user/user";
import { TypeElements } from "models/typeElements/typeElements";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { getTheRestorePath } from "utils/getTheRestorePath";

export const useMenuCleaningPeriodElements = (): ISelectCustom[] => {
  const { __ } = useLocales();

  return [
    {
      name: __(`5 минут`),
      callback: null,
      value: CLEANING_TIME.HALF_AN_HOUR
    },
    {
      name: __(`15 дней`),
      callback: null,
      value: CLEANING_TIME.HALF_MONTH
    },
    {
      name: __(`30 дней`),
      callback: null,
      value: CLEANING_TIME.MONTH
    },
    {
      name: __(`90 дней`),
      callback: null,
      value: CLEANING_TIME.QUARTER
    }
  ];
};

export const useContextTrashFile = () => {
  const { __ } = useLocales();
  const {
    onRestoreTrashFiles,
    onTrashFiles,
    onSetPropertiesElementModal,
    onSetApproveModal,
    onSetRestoreFileModal,
    fetchFileZipLink
  } = useActions();
  const navigate = useNavigate();
  const { trashDirId } = useParams();

  const onRestoreFileCallback = (file: IFile) => {
    if (trashDirId) {
      onSetRestoreFileModal({
        open: true,
        element: file
      });
    } else {
      const callback = () => {
        if (TypeElements.FILES === file.table) {
          navigate(
            getTheRestorePath({
              path: file.is_archive === 1 ? ROUTES.ARCHIVE : ROUTES.FOLDERS,
              id_dir: file.is_archive === 1 ? undefined : (file as IFile).id_dir,
              id_parent: undefined,
              search: `${ROUTES_SEARCH_PARAMS.byDateChanged}`
            })
          );
        }

        if (TypeElements.SAFE_FILES === file.table) {
          navigate(`/${ROUTES.SAFE}/${file.id_safe}${ROUTES_SEARCH_PARAMS.byDateChanged}`);
        }
      };

      onRestoreTrashFiles(file, { id_safe: file.id_safe }, callback);
    }
  };

  const onTrashFileCallback = (file: IFile) => {
    const params = {
      title: __("Удаление файла"),
      text: __(`Вы действительно хотите удалить файл “${file.name}” навсегда?`),
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => {
        onTrashFiles(file);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  return {
    contextMenuTrashFileCallback: (file: IFile): IPopoverData[] => [
      {
        name: __("Восстановить"),
        img: <ReturnIcon />,
        callback: (): void => onRestoreFileCallback(file)
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        callback: (): void => {
          onSetPropertiesElementModal({
            open: true,
            element: file,
            onRestore: () => {
              onRestoreFileCallback(file);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            },
            onTrash: () => {
              onTrashFileCallback(file);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            }
          });
        }
      },
      {
        name: __("Скачать"),
        img: <UploadIcon />,
        callback: (): void => {
          fetchFileZipLink(file.fid, __("Не удалось скачать файл. Попробуйте еще раз"));
        }
      },
      {
        name: __("Удалить навсегда"),
        img: <DeleteIcon />,
        callback: (): void => onTrashFileCallback(file)
      }
    ]
  };
};

export const useContextTrashFolder = () => {
  const { __ } = useLocales();
  const {
    onRestoreTrashFolder,
    onTrashFolder,
    onSetPropertiesElementModal,
    onSetFolderPassModal,
    onSetApproveModal,
    fetchFolderZipLink,
    onSetRestoreFileModal
  } = useActions();
  const navigate = useNavigate();
  const { trashDirId } = useParams();

  const onRestoreFolderCallback = (folder: IFolder) => {
    if (trashDirId) {
      onSetRestoreFileModal({ open: true, element: folder });
    } else {
      const payload = {
        id_dirs: [folder.id_dir]
      };

      const callback = () => {
        navigate(
          getTheRestorePath({
            path: folder.is_archive === 1 ? ROUTES.ARCHIVE : ROUTES.FOLDERS,
            id_dir: folder.is_archive === 1 ? undefined : folder.id_dir,
            id_parent: folder.is_archive === 1 || folder.id_parent === "0" ? undefined : folder.id_parent,
            search: `${ROUTES_SEARCH_PARAMS.byDateChanged}`
          })
        );
      };

      onRestoreTrashFolder(folder, payload, callback);
    }
  };

  const onTrashFolderCallback = (folder: IFolder) => {
    const params = {
      titleHead: __("Удалить навсегда"),
      title: __(`Вы действительно хотите удалить папку “${folder.name}”?`),
      text: __("Это действие необратимо"),
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => {
        onTrashFolder(folder);
      }
    };

    if (folder.pass) {
      onSetFolderPassModal({
        open: true,
        type: "enterPassword",
        folder,
        callbackOk: () => onSetApproveModal({ open: true, params })
      });
    } else {
      onSetApproveModal({ open: true, params });
    }
  };

  return {
    contextMenuTrashFolderCallback: (folder: IFolder): IPopoverData[] => [
      {
        name: __("Восстановить"),
        img: <ReturnIcon />,
        callback: (): void => onRestoreFolderCallback(folder)
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        callback: (): void => {
          if (folder.pass !== NO_VALUE) {
            onSetFolderPassModal({
              open: true,
              type: "enterPassword",
              folder,
              callbackOk: () => {
                onSetPropertiesElementModal({
                  open: true,
                  element: folder,
                  onRestore: () => {
                    onRestoreFolderCallback(folder);
                    onSetPropertiesElementModal(initialPropertiesElementModalState());
                  },
                  onTrash: () => {
                    onTrashFolderCallback(folder);
                    onSetPropertiesElementModal(initialPropertiesElementModalState());
                  }
                });
              }
            });
          } else {
            onSetPropertiesElementModal({
              open: true,
              element: folder,
              onRestore: () => {
                onRestoreFolderCallback(folder);
                onSetPropertiesElementModal(initialPropertiesElementModalState());
              },
              onTrash: () => {
                onTrashFolderCallback(folder);
                onSetPropertiesElementModal(initialPropertiesElementModalState());
              }
            });
          }
        }
      },
      {
        name: __("Скачать"),
        img: <UploadIcon />,
        isSystem: false,
        callback: (): void => {
          if (folder.pass) {
            onSetFolderPassModal({
              open: true,
              type: "enterPassword",
              folder,
              callbackOk: () => fetchFolderZipLink(folder.id_dir, __("Не удалось скачать папку"))
            });
          } else {
            fetchFolderZipLink(folder.id_dir, __("Не удалось скачать папку"));
          }
        }
      },
      {
        name: __("Удалить навсегда"),
        img: <DeleteIcon />,
        callback: (): void => onTrashFolderCallback(folder)
      }
    ]
  };
};

export const useContextTrashTasks = () => {
  const { __ } = useLocales();
  const {
    onRestoreTrashTasks,
    onTrashTasks,
    onSetPropertiesElementModal,
    onSetApproveModal,
    onSelectFilterDate,
    onSelectFilterType
  } = useActions();
  const navigate = useNavigate();

  const onRestoreTrashTasksCallback = (task: IMyTask) => {
    const callback = () => {
      onSelectFilterDate(msToDateObject(task.date_start));
      onSelectFilterType(TaskFilters.BY_DAY);

      if (task.is_archive) {
        navigate(`/${ROUTES.ARCHIVE}${ROUTES_SEARCH_PARAMS.byDateChanged}`);
      } else {
        if (task.table === TypeElements.TASKS && task.id_type === TASK_TYPES.REMINDER) {
          navigate(`/${ROUTES.CALENDAR}`);
        } else {
          navigate(`/${ROUTES.TASKS}`);
        }
      }
    };
    onRestoreTrashTasks(task, callback);
  };

  const onTrashTasksCallback = (task: IMyTask) => {
    const params = {
      titleHead: __("Удалить навсегда"),
      title: __(`Вы действительно хотите удалить задачу “${task.name}”?`),
      text: __("Это действие необратимо"),
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => {
        onTrashTasks(task);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  return {
    contextMenuTrashTasksCallback: (task: IMyTask): IPopoverData[] => [
      {
        name: __("Восстановить"),
        img: <ReturnIcon />,
        callback: (): void => onRestoreTrashTasksCallback(task)
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        callback: (): void => {
          onSetPropertiesElementModal({
            open: true,
            element: task,
            onRestore: () => {
              onRestoreTrashTasksCallback(task);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            },
            onTrash: () => {
              onTrashTasksCallback(task);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            }
          });
        }
      },
      {
        name: __("Удалить навсегда"),
        img: <DeleteIcon />,
        callback: (): void => onTrashTasksCallback(task)
      }
    ]
  };
};

export const useContextTrashSafe = () => {
  const { __ } = useLocales();
  const { onRestoreTrashSafe, onTrashSafe, onSetPropertiesElementModal, onSetApproveModal } = useActions();
  const navigate = useNavigate();

  const onRestoreTrashSafeCallback = (safe: ISafe) => {
    const callback = () => {
      if (safe.is_archive === 1) {
        navigate(`/${ROUTES.ARCHIVE}${ROUTES_SEARCH_PARAMS.byDateChanged}`);
      } else {
        navigate(`/${ROUTES.SAFE}/${safe.id}${ROUTES_SEARCH_PARAMS.byDateChanged}`);
      }
    };

    onRestoreTrashSafe(safe, callback);
  };

  const onTrashSafeCallback = (safe: ISafe) => {
    const params = {
      titleHead: __("Удалить навсегда"),
      title: __(`Вы действительно хотите удалить сейф “${safe.name}”?`),
      text: __("Это действие необратимо"),
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => {
        onTrashSafe(safe);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  return {
    contextMenuTrashSafeCallback: (safe: ISafe): IPopoverData[] => [
      {
        name: __("Восстановить"),
        img: <ReturnIcon />,
        callback: (): void => onRestoreTrashSafeCallback(safe)
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        callback: (): void => {
          onSetPropertiesElementModal({
            open: true,
            element: safe,
            onRestore: () => {
              onRestoreTrashSafeCallback(safe);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            },
            onTrash: () => {
              onTrashSafeCallback(safe);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            }
          });
        }
      },
      {
        name: __("Удалить навсегда"),
        img: <DeleteIcon />,
        callback: (): void => onTrashSafeCallback(safe)
      }
    ]
  };
};

export const useContextTrashProject = () => {
  const { __ } = useLocales();
  const { onSetPropertiesElementModal, onSetApproveModal, onRestoreTrashProject, onTrashProject } = useActions();

  const navigate = useNavigate();

  const onRestoreTrashProjectCallback = (project: IProject) => {
    const callback = () => {
      if (project.is_archive === 1) {
        navigate(`/${ROUTES.ARCHIVE}${ROUTES_SEARCH_PARAMS.byDateChanged}`);
      } else {
        navigate(`/${ROUTES.PROJECT}/${project.id}/${PROJECT_PATH.TASKS}${ROUTES_SEARCH_PARAMS.byDateChanged}`);
      }
    };

    onRestoreTrashProject(project, callback);
  };

  const onTrashProjectCallback = (project: IProject) => {
    const params = {
      titleHead: __("Удалить навсегда"),
      title: __(`Вы действительно хотите удалить проект “${project.name}”?`),
      text: __("Это действие необратимо"),
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => {
        onTrashProject(project);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  return {
    contextMenuTrashProjectCallback: (project: IProject): IPopoverData[] => [
      {
        name: __("Восстановить"),
        img: <ReturnIcon />,
        callback: (): void => onRestoreTrashProjectCallback(project)
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        callback: (): void => {
          onSetPropertiesElementModal({
            open: true,
            element: project,
            onRestore: () => {
              onRestoreTrashProjectCallback(project);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            },
            onTrash: () => {
              onTrashProjectCallback(project);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            }
          });
        }
      },
      {
        name: __("Удалить навсегда"),
        img: <DeleteIcon />,
        callback: (): void => onTrashProjectCallback(project)
      }
    ]
  };
};

export const useContextTrashEmployee = () => {
  const { __ } = useLocales();
  const { onSetPropertiesElementModal, onSetApproveModal, onRestoreTrashEmployee, onTrashEmployee } = useActions();

  const navigate = useNavigate();

  const onRestoreTrashEmployeeCallback = (employee: IUserInfo) => {
    const callback = () => {
      navigate(`/${ROUTES.PROFILE}/${PROFILE_ROUTES.EMPLOYEES}`);
    };

    onRestoreTrashEmployee(employee, callback);
  };

  const onTrashEmployeetCallback = (employee: IUserInfo) => {
    const params = {
      titleHead: __("Удалить навсегда"),
      title: __(`Вы действительно хотите удалить сотрудника “${employee.name}”?`),
      text: __("Это действие необратимо"),
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => {
        onTrashEmployee(employee);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  return {
    contextMenuTrashEmloyeeCallback: (employee: IUserInfo): IPopoverData[] => [
      {
        name: __("Восстановить"),
        img: <ReturnIcon />,
        callback: (): void => onRestoreTrashEmployeeCallback(employee)
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        callback: (): void => {
          onSetPropertiesElementModal({
            open: true,
            element: employee,
            onRestore: () => {
              onRestoreTrashEmployeeCallback(employee);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            },
            onTrash: () => {
              onTrashEmployeetCallback(employee);
              onSetPropertiesElementModal(initialPropertiesElementModalState());
            }
          });
        }
      },
      {
        name: __("Удалить навсегда"),
        img: <DeleteIcon />,
        callback: (): void => onTrashEmployeetCallback(employee)
      }
    ]
  };
};
