import FirstStep from "containers/Authentication/ForgotPasswordSteps/FirstStep/FirstStep";
import SecondStep from "containers/Authentication/ForgotPasswordSteps/SecondStep/SecondStep";
import ThirdStep from "containers/Authentication/ForgotPasswordSteps/ThirdStep/ThirdStep";
import Layout from "containers/Authentication/Layout/Layout";
import { useState } from "react";

const ForgotPassword = (): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const [login, setLogin] = useState("");
  const [code, setCode] = useState("");

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <Layout>
      {step === 1 && <FirstStep handleNextStep={handleNextStep} login={login} setLogin={setLogin} />}
      {step === 2 && <SecondStep handleNextStep={handleNextStep} login={login} code={code} setCode={setCode} />}
      {step === 3 && <ThirdStep login={login} code={code} />}
    </Layout>
  );
};

export default ForgotPassword;
