import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { useSearchParams } from "react-router-dom";

const useSelectedSearchParams = (param: SEARCH_PARAMS, depParam?: SEARCH_PARAMS) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedValue = searchParams.get(param);
  const onSelectValue = (id: string): void => {
    if (selectedValue === id) {
      searchParams.delete(param);
      depParam && searchParams.delete(depParam);
      setSearchParams(searchParams);
      return;
    }
    searchParams.set(param, id);
    depParam && searchParams.delete(depParam);
    setSearchParams(searchParams);
  };
  return { selectedValue, onSelectValue };
};

const useMultipleSelectedSearchParams = (param: SEARCH_PARAMS) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedValue = searchParams.get(param);

  const onSelectMultipleValue = (id: string) => {
    const participantsArray = selectedValue ? selectedValue.split(",") : [];

    if (participantsArray.includes(id)) {
      const updatedParticipants = participantsArray.filter((participantId) => participantId !== id);

      if (updatedParticipants.length > 0) {
        searchParams.set(param, updatedParticipants.join(","));
      } else {
        searchParams.has(param) && searchParams.delete(param);
      }
    } else {
      participantsArray.push(id);
      searchParams.set(param, participantsArray.join(","));
    }

    setSearchParams(searchParams);
  };
  return { selectedValue: selectedValue?.split(","), onSelectMultipleValue };
};

export const useAllSelectedSearchParams = () => {
  const color = useSelectedSearchParams(SEARCH_PARAMS.COLOR);
  const tags = useMultipleSelectedSearchParams(SEARCH_PARAMS.TAGS);
  const emo = useSelectedSearchParams(SEARCH_PARAMS.EMO);
  const search = useSelectedSearchParams(SEARCH_PARAMS.SEARCH_QUERY);
  const priority = useSelectedSearchParams(SEARCH_PARAMS.PRIORITY);
  const id_sprint = useSelectedSearchParams(SEARCH_PARAMS.SPRINT);
  const team = useSelectedSearchParams(SEARCH_PARAMS.TEAM, SEARCH_PARAMS.PARTICIPANTS);
  const participants = useMultipleSelectedSearchParams(SEARCH_PARAMS.PARTICIPANTS);

  return {
    color,
    tags,
    emo,
    search,
    priority,
    id_sprint,
    team,
    participants
  };
};

export interface IOptionsSetObjSearchParams {
  depParams?: Array<SEARCH_PARAMS>;
  multiple?: boolean;
  toggle?: boolean;
}

export const useSelectedObjSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const objSearchParams = Object.fromEntries([...searchParams]) as Record<SEARCH_PARAMS, string>;

  const onSetObjSearchParams = (obj: Record<string, string>, options?: IOptionsSetObjSearchParams): void => {
    if (options?.toggle) {
      Object.entries(objSearchParams).forEach(([k, v]) => {
        if (Object.keys(obj).some((keys) => keys === k && v === obj[keys])) {
          searchParams.delete(k);
          if (options?.depParams?.length) {
            options.depParams.forEach((item) => {
              searchParams.delete(item);
            });
          }
          return;
        }

        if (options?.depParams?.length) {
          options.depParams.forEach((item) => {
            searchParams.delete(item);
          });
        }
      });

      const updateSearchParamsObj = { ...objSearchParams, ...obj };

      setSearchParams(updateSearchParamsObj);
      return;
    }

    if (options?.multiple) {
      const updateSearchParamsObj: Record<SEARCH_PARAMS, string> = Object.entries(objSearchParams).reduce(
        (acc, [k, v]) => {
          const selectedValuesArray = objSearchParams[k as SEARCH_PARAMS]
            ? objSearchParams[k as SEARCH_PARAMS].split(",")
            : [];

          if (selectedValuesArray.includes(obj[k])) {
            const updatedValues = selectedValuesArray.filter((value) => value !== obj[k]);

            if (updatedValues.length > 0) {
              // eslint-disable-next-line no-sequences
              return (acc[k as SEARCH_PARAMS] = updatedValues.join(",")), acc;
            } else {
              return (
                v !== obj[k] &&
                  // eslint-disable-next-line no-sequences
                  (acc[k as SEARCH_PARAMS] = v),
                acc
              );
            }
          } else {
            selectedValuesArray.push(obj[k]);

            // eslint-disable-next-line no-sequences
            return (acc[k as SEARCH_PARAMS] = selectedValuesArray.join(",")), acc;
          }
        },
        {} as Record<SEARCH_PARAMS, string>
      );

      setSearchParams(updateSearchParamsObj);
      return;
    }

    const updateSearchParamsObj = { ...objSearchParams, ...obj };

    setSearchParams(updateSearchParamsObj);
  };

  return { objSearchParams, onSetObjSearchParams };
};
