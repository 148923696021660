import { TUserInfoStorage } from "../user/user";

export interface IEmploeesMemory {
  id_user: string;
  email: string;
  fname: string;
  name: string;
  sname: string;
  icon: string;
  storage: TUserInfoStorage;
  total?: number;
}

export interface IMemoryState {
  loader: boolean;
  employees: IEmploeesMemory[];
  page: number;
  total: number;
}

export const initialMemoryState = (): IMemoryState => ({
  loader: false,
  employees: null,
  page: 1,
  total: 0
});
