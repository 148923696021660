import { endOfDay, getTime, startOfDay } from "date-fns";
import { MS_OF_DAY } from "generalComponents/Calendars/CalendarHelper";
import { useDurationToString, useFindStatus, useGetDuration, useGetTaskEnd } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { TASK_DEPENDENCIES, TASK_TYPE } from "models/store/joinProjects/joinProgects";
import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { dateISO } from "utils/dateToISO";

import { DependencyArrow } from "../components/DependencyArrow";
import { DrawLine } from "../components/DrawLine";
import { GantHeader } from "../components/GantHeader/GantHeader";
import { RowTask } from "../components/RowTask";
import { SprintsRect } from "../components/SprintsRect";
import TaskGant from "../components/TaskGant/TaskGant";
import { TodayLine } from "../components/TodayLine";
import { TooltipeText } from "../components/TooltipeText";
import { H, PT, W } from "../GantSpace/GantSpace";
import { IBlockItem, ICommonData, IGantTypeProps, ITaskData } from "../models";

export const GantDay: FC<IGantTypeProps> = ({ tasks, hoverRow, setHoverRow, setScrollLeft, minDate, maxDate }) => {
  const { __ } = useLocales();
  const { onUpdateTaskProject, onAddTaskDependency } = useActions();
  const { project } = useJoinProjectsSelectors();
  const findStatus = useFindStatus();
  const durationToString = useDurationToString();
  const getDateEnd = useGetTaskEnd();
  const getDuration = useGetDuration();
  const [tooltipe, setTooltipe] = useState(null);
  const [commonData, setCommonData] = useState<ICommonData>(null);
  const [tasksData, setTasksData] = useState<ITaskData[]>([]);
  const [arrowsData, setArrowsData] = useState<string[]>([]);

  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [activeTask, setActiveTask] = useState<ITaskData>(null);
  const [mouseOffset, setMouseOffset] = useState<number>(0);

  const [isResizingLeft, setIsResizingLeft] = useState<boolean>(false);
  const [isResizingRight, setIsResizingRight] = useState<boolean>(false);

  const [isDrawLine, setIsDrawLine] = useState<boolean>(false);
  const [startPosition, setStartPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [endPosition, setEndPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [activeParentTask, setActiveParentTask] = useState<ITaskData>(null);
  const [activeChildTask, setActiveChaildTask] = useState<ITaskData>(null);
  const [parentHover, setParentHover] = useState<ITaskData>(null);
  const [childHover, setChildHover] = useState<ITaskData>(null);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tasks.length < 0 || !ref?.current) return;
    setCommonData(getCommonData());
  }, [tasks]); // eslint-disable-line

  useEffect(() => {
    if (!commonData) return;
    setTasksData(getTasksData());
    setScrollLeft(commonData.todayX);
  }, [commonData]); // eslint-disable-line

  useEffect(() => {
    if (tasksData.length <= 0) return;
    setArrowsData(getArrowsData());
  }, [tasksData]); // eslint-disable-line

  const getCommonData = () => {
    const dateStart = getTime(startOfDay(new Date(minDate)));
    const dateEnd = getTime(startOfDay(new Date(maxDate)));
    const blockWidth = project.work_hours.length * W;

    const dates: number[] = [];
    let firstDay = dateStart - MS_OF_DAY;
    while (!project.work_days.includes(new Date(firstDay).getDay())) {
      firstDay -= MS_OF_DAY;
    }
    for (let i = firstDay; getTime(startOfDay(new Date(i))) <= dateEnd; i += MS_OF_DAY) {
      if (project.work_days.includes(new Date(i).getDay())) {
        dates.push(getTime(startOfDay(new Date(i))));
      }
    }
    let lastDay = dates[dates.length - 1] + MS_OF_DAY;
    while (!project.work_days.includes(new Date(lastDay).getDay())) {
      lastDay += MS_OF_DAY;
    }
    dates.push(lastDay);

    const getBlockItem = (idx: number) => {
      return project.work_hours.map((h, i) => ({ value: h, x: W / 2 + blockWidth * idx + i * W, name: h }));
    };
    const blocks: Record<number, IBlockItem[]> = dates.reduce((acc, day, idx) => {
      return { ...acc, [day]: getBlockItem(idx) };
    }, {});
    //
    const gantWidth = dates.length * project.work_hours.length * W;
    const taskHeight = tasks.length * H + PT;
    const gantHeight = taskHeight > ref.current.clientHeight - 38 ? taskHeight : ref.current.clientHeight - 38;
    //
    const allDaysAmount = project.work_hours.length * dates.length;
    const verticalLines = Array.from({ length: allDaysAmount }, (_, index) => index + 1)
      .map((el) => `M${el * W},0 L${el * W},${gantHeight}`)
      .join(" ");
    //
    //
    const sprints = project.sprints.map((s) => {
      const amountDays = (getTime(new Date(s.date_end)) - getTime(new Date(s.date_start))) / MS_OF_DAY;
      let amountWorkDays = 0;
      for (let i = 0; i <= amountDays; i++) {
        const d = getTime(new Date(s.date_start)) + MS_OF_DAY * i;
        if (project.work_days.includes(new Date(d).getDay())) {
          amountWorkDays++;
        }
      }
      const width = amountWorkDays * blockWidth - 4;
      const x = dates.indexOf(getTime(startOfDay(new Date(s.date_start)))) * blockWidth + 2;
      const isCurrent = new Date(s.date_start) < new Date() && new Date() < new Date(s.date_end);
      const isText = s.name.length * 7 < width;

      return { id: s.id, name: s.name, width, x, isCurrent, isText };
    });

    const getToday = (): number => {
      const today = new Date();
      if (today > new Date(dates[0]) && today < endOfDay(new Date(dates[dates.length - 1]))) {
        let todayIdx = 0;
        for (let i = 0; i < dates.length; i++) {
          if (today.getTime() > dates[i]) {
            todayIdx = i;
          }
        }
        // если сегодняшняя дата попадает на выходной день возвращаем начало ближайшего дня
        if (!project.work_days.includes(today.getDay())) {
          return (todayIdx + 1) * blockWidth;
        }

        const widthDay = todayIdx * blockWidth;
        let idxHour = project.work_hours.indexOf(today.getHours());
        if (idxHour < 0) {
          const big = project.work_hours.filter((el) => el > today.getHours());
          if (big.length === 0) {
            return (todayIdx + 1) * blockWidth;
          }
          return widthDay + project.work_hours.indexOf(big[0]) * W;
        }

        const widthHour = idxHour * W;
        const min = (new Date().getMinutes() * W) / 60;
        return widthDay + widthHour + min;
      }
      return 0;
    };

    const todayX = getToday();
    return { dates, gantWidth, gantHeight, blockWidth, sprints, verticalLines, todayX, blocks };
  };

  const getTasksData = () => {
    const _tasks = tasks.map((t, i) => {
      const index = commonData.dates.indexOf(getTime(startOfDay(new Date(t.date_start))));
      const day = index * commonData.blockWidth;
      const hourIndex = project.work_hours.indexOf(new Date(t.date_start).getHours());
      const hour = hourIndex * W;
      const min = (new Date(t.date_start).getMinutes() / 60) * W;
      const x = day + hour + min;
      const y = i * H + PT + 3;
      const isTask = t.id_type === TASK_TYPE.TASK;

      const isOverdue = t.time_long > 0;

      const widthPrev = (t.duration / 60) * W;
      const widthOverdue = (t.time_long / 60) * W;
      const widthTotal = widthPrev + widthOverdue;
      const xEnd = x + widthTotal;
      const xGradient = 100 - (widthOverdue * 100) / widthTotal;
      const textOverdue = isOverdue ? __(`(просроченно на ${durationToString(t.time_long)})`) : "";
      const title = `${t.num} ${t.name} ${textOverdue}`;
      const textLength = title.length;

      const isText = textLength * 6 < widthTotal;
      const statusColor = findStatus(t.id_status).color;
      return {
        ...t,
        title,
        x,
        y,
        isText,
        statusColor,
        isTask,
        widthPrev,
        widthOverdue,
        widthTotal,
        isOverdue,
        xGradient,
        xEnd
      };
    });
    return _tasks;
  };

  const getArrowsData = () => {
    const _tasks = tasksData.filter((el) => el.links?.REQUIRED_FOR);
    const arrows = _tasks.map((el) => {
      const a = el.links.REQUIRED_FOR.map((t) => {
        const tChild = tasksData.find((item) => item.id === t.id);
        if (!tChild) return "";
        const xStart = el.isTask ? el.xEnd : el.x + 14;
        const yStart = el.y + 12;
        const xEnd = tChild?.x - 5;
        const yEnd = tChild.y + 12;
        let diff = "";
        if (el.y < tChild.y) {
          if (xStart + 10 < xEnd - 10) {
            diff = ` V${yEnd}`;
          }
          if (xStart + 10 >= xEnd - 10) {
            diff = `V${yStart + H / 2} H${xEnd - 10} V${yEnd}`;
          }
        } else {
          if (xStart + 10 < xEnd - 10) {
            diff = `V${yEnd}`;
          }
          if (xStart + 10 >= xEnd - 10) {
            diff = `V${yStart - H / 2} H${xEnd - 10} V${yEnd}`;
          }
        }

        const path = `M${xStart},${yStart} H${xStart + 10} ${diff} H${xEnd}`;
        return path;
      });
      return a;
    });
    return arrows.flat();
  };

  const getDateFromX = (x: number): Date => {
    const idxDay = Math.floor(x / commonData.blockWidth); // определяем индекс в массиве дат
    const date = new Date(commonData.dates[idxDay]); // определяем новый день начала задачи
    const remainderDay = x % commonData.blockWidth; // расчитываем остаток от начала дня
    const idxHour = Math.floor(remainderDay / W); // определяем индекс в массиве рабочих часов компании
    const _h = project.work_hours[idxHour]; // определяем новый час начала задачи
    date.setHours(_h); // записываем часы к дате
    const remainderHour = remainderDay % W; // расчитываем останок от начала часа
    const _m = Math.round((remainderHour * 60) / W); // расчитывем минуты относительно ширины ячейки одного часа
    date.setMinutes(_m); // записываем минуты к дате
    return date;
  };

  const clearDrawing = () => {
    setActiveChaildTask(null);
    setActiveParentTask(null);
    setIsDrawLine(false);
    setStartPosition({ x: 0, y: 0 });
    setEndPosition({ x: 0, y: 0 });
    setChildHover(null);
    setParentHover(null);
  };
  const handleMouseUp = () => {
    if (isMoving && activeTask) {
      const _task = tasksData.find((el) => el.id === activeTask.id);
      const dateStart = getDateFromX(_task.x);
      const dateEnd = getDateEnd(dateStart, _task.duration); // расчитываем новую дату окончания задачи
      const dateLong = _task.date_long ? getDateEnd(dateEnd, _task.time_long) : "";
      const updTask = {
        ..._task,
        date_start: dateISO(dateStart),
        date_end: dateISO(dateEnd),
        date_long: dateISO(dateLong)
      };
      setTasksData((prev) => prev.map((el) => (el.id === updTask.id ? updTask : el)));

      const data = new FormData();
      data.append("id_project", project.id);
      data.append("id_task", _task.id);
      data.append("date_start", dateISO(dateStart));
      data.append("date_end", dateISO(dateEnd));
      dateLong && data.append("date_long", dateISO(dateLong));
      onUpdateTaskProject({ data });

      setIsMoving(false);

      return;
    }
    if (isResizingLeft && activeTask) {
      const _task = tasksData.find((el) => el.id === activeTask.id);
      const dateStart = getDateFromX(_task.x);
      const duration = getDuration(dateStart, new Date(_task.date_end)); // расчитываем новую дату окончания задачи

      const data = new FormData();
      data.append("id_project", project.id);
      data.append("id_task", _task.id);
      data.append("date_start", dateISO(dateStart));
      data.append("duration", String(duration));
      onUpdateTaskProject({ data });

      setIsResizingLeft(false);
      setActiveTask(null);
      return;
    }
    if (isResizingRight && activeTask !== null) {
      const _task = tasksData.find((el) => el.id === activeTask.id);
      const dateEnd = getDateFromX(_task.xEnd);
      let date_end = _task.date_end;
      let duration = _task.duration;
      let timeLong = _task.time_long;
      let dateLong = _task.date_long;
      if (_task.date_long && dateEnd > new Date(_task.date_end)) {
        timeLong = getDuration(new Date(_task.date_end), dateEnd);
        dateLong = dateISO(dateEnd);
      } else {
        timeLong = 0;
        dateLong = "";
        date_end = dateISO(dateEnd);
        duration = getDuration(new Date(_task.date_start), dateEnd);
      }
      const updTask = { ..._task, date_end: date_end, duration, time_long: timeLong, date_long: dateLong };
      setTasksData((prev) => prev.map((el) => (el.id === updTask.id ? updTask : el)));

      const data = new FormData();
      data.append("id_project", project.id);
      data.append("id_task", _task.id);
      data.append("date_end", dateISO(date_end));
      data.append("duration", String(duration));
      data.append("date_long", dateISO(dateLong));
      data.append("time_long", String(timeLong));
      onUpdateTaskProject({ data });

      setIsResizingRight(false);
      setActiveTask(null);
      return;
    }
    if (isDrawLine) {
      if (activeParentTask && childHover) {
        if (activeParentTask?.links?.DEPENDS_ON?.some((el) => el.id === childHover.id)) {
          clearDrawing();
          return;
        }
        const links =
          activeParentTask.links && activeParentTask.links.REQUIRED_FOR
            ? { ...activeParentTask.links, REQUIRED_FOR: [...activeParentTask.links.REQUIRED_FOR, childHover] }
            : { REQUIRED_FOR: [childHover] };
        setTasksData((prev) => prev.map((el) => (el.id === activeParentTask.id ? { ...el, links } : el)));
        const payload = {
          id_task1: activeParentTask.id,
          id_task2: childHover.id,
          type1: TASK_DEPENDENCIES.REQUIRED_FOR,
          type2: TASK_DEPENDENCIES.DEPENDS_ON
        };
        onAddTaskDependency(payload);
      }
      if (activeChildTask && parentHover) {
        if (parentHover?.links?.DEPENDS_ON?.some((el) => el.id === activeChildTask.id)) {
          clearDrawing();
          return;
        }
        const links =
          parentHover.links && parentHover.links.REQUIRED_FOR
            ? { ...parentHover.links, REQUIRED_FOR: [...parentHover.links.REQUIRED_FOR, activeChildTask] }
            : { REQUIRED_FOR: [activeChildTask] };
        setTasksData((prev) => prev.map((el) => (el.id === parentHover.id ? { ...el, links } : el)));
        const payload = {
          id_task1: parentHover.id,
          id_task2: activeChildTask.id,
          type1: TASK_DEPENDENCIES.REQUIRED_FOR,
          type2: TASK_DEPENDENCIES.DEPENDS_ON
        };
        onAddTaskDependency(payload);
      }
      clearDrawing();
    }
  };

  const handleMouseMove = (e: MouseEvent<SVGSVGElement>) => {
    if (isMoving && activeTask) {
      const { offsetX } = e.nativeEvent;
      const x = offsetX - mouseOffset;
      const xEnd = x + activeTask.widthTotal;
      const updTask = { ...activeTask, x, xEnd };
      setTasksData((prev) => prev.map((t) => (t.id === updTask.id ? updTask : t)));
    }
    if (isResizingLeft && activeTask) {
      const { offsetX } = e.nativeEvent;
      const x = offsetX - mouseOffset;
      const xDelta = x - activeTask.x;
      const widthPrev = activeTask.widthPrev - xDelta;
      const widthTotal = activeTask.widthOverdue + widthPrev;
      const xGradient = 100 - (activeTask.widthOverdue * 100) / widthTotal;
      const isText = activeTask.title.length * 6 + 10 < widthTotal;
      if (widthPrev < 30) return;
      const updTask = { ...activeTask, x, widthPrev, isText, xGradient, widthTotal };
      setTasksData((prev) => prev.map((t) => (t.id === updTask.id ? updTask : t)));
    }
    if (isResizingRight && activeTask) {
      const { offsetX } = e.nativeEvent;
      const xEnd = offsetX + mouseOffset;
      const xDelta = xEnd - activeTask.xEnd;
      let widthOverdue = activeTask.widthOverdue;
      let widthPrev = activeTask.widthPrev;
      if (widthOverdue > 0) {
        widthOverdue = widthOverdue + xDelta;
      } else {
        widthPrev = widthPrev + xDelta;
      }

      const widthTotal = widthOverdue + widthPrev;
      const xOverdue = 100 - (widthOverdue * 100) / widthTotal;
      const isText = activeTask.title.length * 6 + 10 < widthTotal;
      if (widthTotal < 30) return;
      const updTask = { ...activeTask, widthPrev, widthOverdue, widthTotal, xOverdue, isText, xEnd };
      setTasksData((prev) => prev.map((t) => (t.id === updTask.id ? updTask : t)));
    }
    if (isDrawLine) {
      const { offsetX, offsetY } = e.nativeEvent;
      if (childHover || parentHover) return;
      setEndPosition({ x: offsetX, y: offsetY });
    }
  };

  return (
    <div ref={ref}>
      {commonData && (
        <div style={{ width: commonData.gantWidth }}>
          <GantHeader commonData={commonData} format="date" />
          <div>
            <svg
              width={commonData.gantWidth}
              height={commonData.gantHeight}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={() => setHoverRow("")}
            >
              <path d={commonData.verticalLines} stroke="#E7EBEA" strokeWidth="1" />
              {commonData?.sprints?.map((s) => (
                <SprintsRect key={s.id} sprint={s} />
              ))}
              {commonData.todayX > 0 && <TodayLine x={commonData.todayX} />}
              {arrowsData.length > 0 && arrowsData.map((path) => <DependencyArrow key={path} path={path} />)}

              {tasksData.map((t, i) => (
                <g key={t.id}>
                  <RowTask id={t.id} y={i * H + PT} isHover={t.id === hoverRow} setHoverRow={setHoverRow} />
                  <TaskGant
                    t={t}
                    activeChildTask={activeChildTask}
                    activeParentTask={activeParentTask}
                    hoverRow={hoverRow}
                    isDrawLine={isDrawLine}
                    isMoving={isMoving}
                    setChildHover={setChildHover}
                    setParentHover={setParentHover}
                    setTooltipe={setTooltipe}
                    setActiveTask={setActiveTask}
                    setIsMoving={setIsMoving}
                    setMouseOffset={setMouseOffset}
                    setIsResizingLeft={setIsResizingLeft}
                    setIsResizingRight={setIsResizingRight}
                    setIsDrawLine={setIsDrawLine}
                    setStartPosition={setStartPosition}
                    setEndPosition={setEndPosition}
                    setActiveChaildTask={setActiveChaildTask}
                    setActiveParentTask={setActiveParentTask}
                  />
                </g>
              ))}

              {tooltipe && (
                <g>
                  <text x={tooltipe.x} y={tooltipe.y} textAnchor="start" fontSize={12}>
                    {tooltipe.text}
                  </text>
                </g>
              )}
              {tooltipe && <TooltipeText tooltipe={tooltipe} />}
              {isDrawLine && <DrawLine startPosition={startPosition} endPosition={endPosition} />}
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};
