import classNames from "classnames";
import Comments from "containers/Tasks/Comments/Comments";
import Button from "generalComponents/Button/Button";
import { createMarkup } from "generalComponents/Services/browserServices";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { IAddCommentTaskProps } from "models/store/Cabinet/modals/taskModals";
import React, { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./AddComments.module.sass";

const AddComments: React.FC<IAddCommentTaskProps> = ({ params, closeModal }): JSX.Element => {
  const { __ } = useLocales();
  const { onAddTaskComment } = useActions();
  const [comment, setComment] = useState<string>(NO_VALUE);

  const handleSubmit = (isClose: boolean): void => {
    if (comment.length === 0) return;
    const payload = {
      id_task: params.id,
      text: comment
    };
    onAddTaskComment(payload, isClose);
    setComment(NO_VALUE);
  };

  const renderComments = (): JSX.Element[] => {
    return params.comments.map((item) => (
      <div
        key={item.id}
        className={classNames("htmlContent", styles.comment)}
        dangerouslySetInnerHTML={createMarkup(item.text)}
      />
    ));
  };

  return (
    <div className={styles.wrap}>
      <ul className={styles.list}>{renderComments()}</ul>
      <Comments handleSubmit={handleSubmit} setComment={setComment} comment={comment} isAddFile={false} />
      <div className={styles.buttonWrap}>
        <Button text={__("Отмена")} variant={ButtonVariantType.CANCEL} onClick={closeModal} />
        <Button
          text={__("Отправить")}
          variant={ButtonVariantType.OK}
          onClick={() => handleSubmit(true)}
          disabled={!comment}
        />
      </div>
    </div>
  );
};

export default AddComments;
