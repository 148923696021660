import { ReactComponent as BacklogIcon } from "assets/icons/backlog.svg";
import { ReactComponent as ChartIcon } from "assets/icons/chart.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock_1.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as FileIcon } from "assets/icons/file.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder_2.svg";
import { ReactComponent as GantIcon } from "assets/icons/gant.svg";
import { ReactComponent as KanbanIcon } from "assets/icons/kanban.svg";
import { ReactComponent as LibraryIcon } from "assets/icons/library.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as TeamIcon } from "assets/icons/person_2.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person_5.svg";
import { ReactComponent as ReturnIcon } from "assets/icons/restore_2.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share_2.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { ReactComponent as ViewColumnIcon } from "assets/icons/view-column.svg";
import { ReactComponent as ViewRowIcon } from "assets/icons/view-row.svg";
import { useAllFolders, useRenderFolders } from "generalComponents/Services/folderServices/folderServices";
import { ERROR_SERVER, ViewType } from "generalComponents/variables/global";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { PROJECT_PATH, ROUTES } from "generalComponents/variables/routing";
import { useWebSocketContext } from "generalComponents/WebSocketsProvider/WebSocketsProvider";
import { useProjectMainAccess, useUsersToProject } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { ButtonVariantType } from "models/generalComponents/button";
import { DEFAULT_PROJECT_DOC_FOLDERS, IDefaultProjectDocFolders } from "models/generalComponents/folders";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { MOVE_ELEMENTS_TYPE } from "models/store/Cabinet/modals/moveElements";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IProjectTask, IProjectTeam, ROLE_ACCESSES, TASK_DEPENDENCIES } from "models/store/joinProjects/joinProgects";
import { IDelProjectTeamPayload } from "models/store/joinProjects/joinProjectsPayloads";
import { MouseEvent } from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { getPageDep } from "utils/getPageDep";
import { getTheRestorePath } from "utils/getTheRestorePath";

import { useErrorFolder } from "./myFolders";

interface IJoinProjectMenuItem {
  path: string;
  icon: JSX.Element;
  name: string;
}
interface IJoinProjectMenu {
  joinProjectMenuMain: IJoinProjectMenuItem[];
  joinProjectMenuAnalytics: IJoinProjectMenuItem[];
  joinProjectMenuSecondary: IJoinProjectMenuItem[];
}

export const jointProjectsColors = [
  "#B6C2BF",
  "#F3DE5E",
  "#70D7BD",
  "#80B2FE",
  "#D377EB",
  "#F3B97D",
  "#FE9391",
  "#000000",
  "#E4C500",
  "#388E3C",
  "#039BE5",
  "#8E24AA",
  "#F57C00",
  "#CC4036"
];

export const useJoinProjectMenu = (accesses: Record<ROLE_ACCESSES, boolean>): IJoinProjectMenu => {
  const { __ } = useLocales();
  const isMainAccess = useProjectMainAccess();

  const { id_sprint } = useAllSelectedSearchParams();

  const tasks = {
    path: id_sprint.selectedValue ? `${PROJECT_PATH.TASKS}?sprint=${id_sprint.selectedValue}` : PROJECT_PATH.TASKS,
    icon: <KanbanIcon />,
    name: __("Задачи проекта")
  };
  const myTasks = {
    path: id_sprint.selectedValue
      ? `${PROJECT_PATH.MY_TASKS}?sprint=${id_sprint.selectedValue}`
      : PROJECT_PATH.MY_TASKS,
    icon: <PersonIcon />,
    name: __("Мои задачи")
  };
  const backlog = {
    path: PROJECT_PATH.BACKLOG,
    icon: <BacklogIcon />,
    name: "Backlog"
  };
  const history = {
    path: PROJECT_PATH.HISTORY,
    icon: <ClockIcon fill="#6E8680" />,
    name: __("История задач")
  };
  const teams = {
    path: PROJECT_PATH.TEAM,
    icon: <TeamIcon fill="#6E8680" />,
    name: __("Команды")
  };
  const trash = {
    path: PROJECT_PATH.TRASH,
    icon: <TrashIcon fill="#6E8680" />,
    name: __("Корзина")
  };
  const analytics = {
    path: PROJECT_PATH.ANALYTICS,
    icon: <ChartIcon />,
    name: __("Аналитика")
  };
  const gant = {
    path: PROJECT_PATH.GANT,
    icon: <GantIcon />,
    name: __("Диаграмма Ганта")
  };
  const links = {
    path: PROJECT_PATH.LINKS,
    icon: <LinkIcon fill="#6E8680" />,
    name: __("Ссылки")
  };
  const library = {
    path: PROJECT_PATH.LIBRARY,
    icon: <LibraryIcon fill="#6E8680" />,
    name: __("Библиотека")
  };
  const files = {
    path: PROJECT_PATH.FILES,
    icon: <FileIcon fill="#6E8680" />,
    name: __("Файлы")
  };
  const docs = {
    path: PROJECT_PATH.DOC,
    icon: <FolderIcon fill="#6E8680" />,
    name: __("Документация")
  };
  const settings = {
    path: PROJECT_PATH.SETTINGS,
    icon: <SettingsIcon fill="#6E8680" />,
    name: __("Настройки")
  };
  const joinProjectMenuMain = [tasks, myTasks, backlog, history, teams, trash];
  const joinProjectMenuAnalytics = [analytics, gant];
  const joinProjectMenuSecondary = [links, library, files, docs];

  if (isMainAccess || accesses.SETTINGS) {
    joinProjectMenuSecondary.push(settings);
  }

  return { joinProjectMenuMain, joinProjectMenuAnalytics, joinProjectMenuSecondary };
};

export const useMenuViewTabs = (): ISelectCustom[] => {
  const { __ } = useLocales();

  return [
    {
      icon: <ViewColumnIcon />,
      label: __("Колонкой"),
      name: "view",
      value: ViewType.BARS
    },
    {
      icon: <ViewRowIcon />,
      label: __("В ряд"),
      name: "view",
      value: ViewType.LINES
    }
  ];
};

export const useProjectTeamContext = () => {
  const { __ } = useLocales();
  const { onSetCreateProjectTeamModal, onSetApproveModal, onDelTeam } = useActions();
  const navigate = useNavigate();
  const { id } = useParams();
  const onEdit = (team: IProjectTeam): IPopoverData => ({
    name: __("Редактировать"),
    isSystem: true,
    callback: () => onSetCreateProjectTeamModal({ open: true, type: "edit", team, isAddParticipant: false })
  });

  const addParticipant = (team: IProjectTeam): IPopoverData => {
    return {
      name: __("Добавить участника"),
      isSystem: true,
      callback: () => onSetCreateProjectTeamModal({ open: true, type: "edit", team, isAddParticipant: true })
    };
  };
  const teamTasks = (team: IProjectTeam): IPopoverData => {
    return {
      name: __("Задачи команды"),
      isSystem: true,
      callback: () => navigate(`/${ROUTES.PROJECT}/${id}/${PROJECT_PATH.TASKS}?team=${team.id}`)
    };
  };

  const deleteTeam = (team: IProjectTeam): IPopoverData => {
    return {
      name: __("Удалить"),
      isSystem: true,
      callback: () => {
        const payload: IDelProjectTeamPayload = {
          data: { id_project: id, id_item: team.id },
          message: __("Команда удалена")
        };
        const params = {
          titleHead: __("Удалить команду"),
          text: (
            <p style={{ color: "#274A42" }}>
              {__(`Вы действительно хотите удалить ${team.name}?`)}
              <br />
              <span style={{ color: "#56716B" }}>
                {__(`Задачи всех участников команды станут недоступны. Вы сможете восстановить ${team.name} с корзины`)}
              </span>
            </p>
          ),
          approveBtn: __("Удалить"),
          approveBtnVariant: ButtonVariantType.BLUE,
          callback: () => onDelTeam(payload)
        };

        onSetApproveModal({ open: true, params });
      }
    };
  };

  return {
    admin: (team: IProjectTeam): IPopoverData[] => [
      onEdit(team),
      addParticipant(team),
      teamTasks(team),
      deleteTeam(team)
    ],
    user: (team: IProjectTeam): IPopoverData[] => [teamTasks(team)]
  };
};

export const useProjectTaskContext = () => {
  const { __ } = useLocales();
  const {
    onSetCreateProjectTaskModal,
    onSetApproveModal,
    onUpdateTaskProject,
    onDeleteTasksProject,
    onSetCopyLinkModal
  } = useActions();
  const { id } = useParams();
  const { uid } = useUserSelectors();
  const users_to = useUsersToProject();
  const { sendMessage } = useWebSocketContext();

  const edit = (task: IProjectTask) => ({
    name: __("Редактировать"),
    callback: () => onSetCreateProjectTaskModal({ open: true, type: "edit", task })
  });

  const copy = (task: IProjectTask) => ({
    name: __("Копировать"),
    callback: () => onSetCreateProjectTaskModal({ open: true, type: "add", task })
  });
  const copyLink = (task: IProjectTask) => ({
    name: __("Скопировать ссылку"),
    callback: () =>
      onSetCopyLinkModal({
        open: true,
        params: `${projectSrc}${ROUTES.PROJECT}/${id}/${PROJECT_PATH.TASK}/${task.id}`
      })
  });
  const toBacklog = (task: IProjectTask) => ({
    name: __("В BackLog"),
    callback: () => {
      const formData = new FormData();
      formData.append("id_project", id);
      formData.append("id_task", task.id);
      formData.append("id_sprints", JSON.stringify([]));
      formData.append("date_start", "");
      formData.append("date_end", "");

      const payload = {
        data: formData,
        cb: (task: IProjectTask) =>
          sendMessage(
            JSON.stringify({
              action: "task_info",
              task,
              uid,
              users_to,
              actionType: "toBacklog"
            })
          )
      };
      onUpdateTaskProject(payload);
      onDeleteTasksProject(task);
    }
  });
  const remove = (task: IProjectTask) => ({
    name: __("Удалить"),
    callback: () => {
      const formData = new FormData();
      formData.append("id_project", id);
      formData.append("id_task", task.id);
      formData.append("name", task.name);
      formData.append("is_del", "1");

      const payload = {
        data: formData,
        message: __("Удалено"),
        cb: (task: IProjectTask) =>
          sendMessage(
            JSON.stringify({
              action: "task_info",
              task,
              uid,
              users_to,
              actionType: "del"
            })
          )
      };

      const params = {
        titleHead: __("Удалить"),
        text: (
          <p style={{ color: "#274A42" }}>
            {__(`Вы действительно хотите удалить ${task.name}?`)}
            <br />
            <span style={{ color: "#56716B" }}>{__(` Вы сможете восстановить ${task.name} с корзины`)}</span>
          </p>
        ),
        approveBtn: __("Удалить"),
        approveBtnVariant: ButtonVariantType.BLUE,
        callback: () => {
          onDeleteTasksProject(task);
          onUpdateTaskProject(payload);
        }
      };

      onSetApproveModal({ open: true, params });
    }
  });

  return {
    menu: (task: IProjectTask): IPopoverData[] => [
      edit(task),
      copy(task),
      copyLink(task),
      toBacklog(task),
      remove(task)
    ],
    menuBacklog: (task: IProjectTask): IPopoverData[] => [edit(task), copy(task), copyLink(task), remove(task)]
  };
};

export const useContextProjectLibraryFolder = () => {
  const { __ } = useLocales();
  const { onSetApproveModal, onTrashProjectLibraryFolder, onSetCreateObjectLibraryModal } = useActions();

  const onTrashProjectLibraryFolderCallback = (folder: IFolder) => {
    const params = {
      titleHead: __("Удалить навсегда"),
      title: __(`Вы действительно хотите удалить папку “${folder.name}”?`),
      text: __("Это действие необратимо"),
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => {
        onTrashProjectLibraryFolder(folder);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  const onVisibleEditObjectProjectLibraryModal = (folder: IFolder) => {
    onSetCreateObjectLibraryModal({
      open: true,
      titleHead: __("Изменить"),
      btnLabel: __("Сохранить"),
      folder,
      type: "editProjectLibrary"
    });
  };

  return {
    onTrashProjectLibraryFolderCallback,
    onVisibleEditObjectProjectLibraryModal
  };
};

export const useSystemFoldersDoc = (): Record<DEFAULT_PROJECT_DOC_FOLDERS, IDefaultProjectDocFolders> => {
  const { __ } = useLocales();
  const { project } = useJoinProjectsSelectors();
  const navigate = useNavigate();

  const getIdDir = (dirName: DEFAULT_PROJECT_DOC_FOLDERS) =>
    project?.folders_doc?.find(({ name }) => name === dirName)?.id_dir;

  return {
    [DEFAULT_PROJECT_DOC_FOLDERS.DOCS]: {
      name: __("Документы"),
      onClick: () => navigate(getIdDir(DEFAULT_PROJECT_DOC_FOLDERS.DOCS)),
      value: DEFAULT_PROJECT_DOC_FOLDERS.DOCS
    },
    [DEFAULT_PROJECT_DOC_FOLDERS.TEMPLATES]: {
      name: __("Шаблоны"),
      onClick: () => navigate(getIdDir(DEFAULT_PROJECT_DOC_FOLDERS.TEMPLATES)),
      value: DEFAULT_PROJECT_DOC_FOLDERS.TEMPLATES
    }
  };
};

export const useContextProjecDocFile = () => {
  const { __ } = useLocales();

  const navigate = useNavigate();

  const { dirId, did } = useParams();

  const { userInfo } = useUserSelectors();

  const isMainAccess = useProjectMainAccess();

  const { project } = useJoinProjectsSelectors();

  const {
    fetchFileZipLink,
    fetchFolderZipLink,
    onSetMoveElementsModal,
    onCopyFiles,
    onCopyFolders,
    onSetCreateFolderModal,
    onEditProjectFolders,
    onSetShareToMessengersModal,
    onSetApproveModal,
    onSetTrashProjectDocFile,
    onEditProjectElement,
    onCopyProjectEditFile,
    onRewriteProjecDocFile,
    onDelCopyProjectFile,
    onSetUpdateProjectDocDefaultFolders
  } = useActions();

  const folders = useRenderFolders();
  const foldersUser = useRenderFolders().filter(({ name }) => name !== DEFAULT_PROJECT_DOC_FOLDERS.TEMPLATES);

  const { currentFolder } = useFoldersSelectors();

  const systemFolders = Object.values(useSystemFoldersDoc());

  const systemFolderName = systemFolders.find(({ value }) =>
    currentFolder.id_parent === "0" ? value === dirId : value === currentFolder.id_parent
  )?.name;

  const errorFolder = useErrorFolder();

  const callbackOpenEditFile = (file: IFile) => {
    const callbackApprove = (copyFile: IFile): void => {
      const paramsDel = {
        fids: [copyFile.fid],
        dep: copyFile.dep
      };

      const params = {
        titleHead: __(__("Сохранить")),
        title: userInfo.is_admin
          ? __(`Сохранить копию в Ваши Файлы? Или обновить файл ${systemFolderName}?`)
          : __("Исходный файл недоступен для изменения, сохранить копию?"),
        closeBtn: __("Не сохранять"),
        updateBtn: userInfo.is_admin ? __(`Обновить ${systemFolderName}`) : undefined,
        approveBtn: __("Сохранить"),
        onlyCloseCallback: () => {
          onDelCopyProjectFile(paramsDel);
        },
        updateCallback: userInfo.is_admin
          ? (): void => {
              onRewriteProjecDocFile({
                fid: copyFile.fid,
                fid_to: file.fid,
                dep: copyFile.dep
              });
            }
          : undefined,
        callback: (): void => {
          onSetMoveElementsModal({
            open: true,
            type: MOVE_ELEMENTS_TYPE.COPY_FILE,
            elements: copyFile,
            folders: userInfo.is_admin && systemFolderName === DEFAULT_PROJECT_DOC_FOLDERS.DOCS ? folders : foldersUser,
            actionsCallback: { onCopyFiles, onDelCopyFile: () => onDelCopyProjectFile(paramsDel) }
          });
        }
      };

      onSetApproveModal({
        open: true,
        params
      });

      return;
    };

    onCopyProjectEditFile(file, (copyFile: IFile) => callbackApprove(copyFile));
  };

  const contextMenuDocFileCallbackArr = (file: IFile): IPopoverData[] => [
    {
      name: __("Открыть"),
      img: <EyeIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        callbackOpenEditFile(file);

        // onFileView({ open: true, params: file as IFile });
      },
      isSystem: false
    },
    {
      name: __("Копировать в"),
      img: <CopyIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        onSetMoveElementsModal({
          open: true,
          type: MOVE_ELEMENTS_TYPE.COPY_FILE,
          elements: file,
          folders,
          actionsCallback: { onCopyFiles }
        });
      },
      isSystem: false
    },
    {
      name: __("Скачать"),
      img: <UploadIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        fetchFileZipLink(file.fid, did);
      },
      isSystem: false
    },
    {
      name: __("Поделиться"),
      img: <ShareIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        onSetShareToMessengersModal({
          open: true,
          params: file
        });
      },
      isSystem: false
    },
    {
      name: __("Изменить"),
      img: <EditIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        callbackOpenEditFile(file);
      },
      isSystem: true
    },
    {
      name: __("Удалить"),
      img: <TrashIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        const params = {
          titleHead: __("Удалить"),
          title: `${__("Вы действительно хотите удалить")} <span>${file.name}</span>?`,
          text: `${__(`Вы сможете восстановить ${file.name} с корзины`)}`,
          approveBtn: __("Удалить"),
          approveBtnVariant: ButtonVariantType.BLUE,
          callback: (): void => {
            const payload = {
              fids: [file.fid],
              is_del: 1,
              dep: file.dep,
              chapter: file?.chapter,
              callback: () => {
                onSetTrashProjectDocFile({ id: file.fid });
              }
            };

            onEditProjectElement(payload, "file");
          }
        };

        onSetApproveModal({
          open: true,
          params
        });
      },
      isSystem: true
    }
  ];

  const contextMenuDocFolderCallbackArr = (folder: IFolder): IPopoverData[] => [
    {
      name: __("Открыть"),
      img: <EyeIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        navigate(`/${ROUTES.PROJECT}/${project.id}/${PROJECT_PATH.DOC}/${folder.id_dir}`);
      },
      isSystem: false
    },
    {
      name: __("Копировать в"),
      img: <CopyIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        onSetMoveElementsModal({
          open: true,
          type: MOVE_ELEMENTS_TYPE.COPY_FOLDER,
          elements: folder,
          folders,
          actionsCallback: { onCopyFolders }
        });
      },
      isSystem: false
    },
    {
      name: __("Скачать"),
      img: <UploadIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        fetchFolderZipLink(folder.id_dir, null, null, errorFolder[ERROR_SERVER.NO_FILES]);
      },
      isSystem: false
    },
    {
      name: __("Поделиться"),
      img: <ShareIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        onSetShareToMessengersModal({
          open: true,
          params: folder
        });
      },
      isSystem: false
    },
    {
      name: __("Переименовать"),
      img: <EditIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        onSetCreateFolderModal({
          open: true,
          type: "edit",
          folder,
          folders,
          onEditFolder: onEditProjectFolders
        });
      },
      isSystem: true
    },
    {
      name: __("Удалить"),
      img: <TrashIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        const params = {
          titleHead: __(__("Удалить")),
          title: `${__("Вы действительно хотите удалить")} <span>${folder.name}</span>?`,
          text: `${__(`Вы сможете восстановить ${folder.name} с корзины`)}`,
          approveBtn: __("Удалить"),
          approveBtnVariant: ButtonVariantType.BLUE,
          callback: (): void => {
            const payload = {
              id_dirs: [folder.id_dir],
              is_del: 1,
              dep: folder.dep,
              chapter: folder?.chapter,
              callback: (data: any) => {
                onSetTrashProjectDocFile({ id: folder.id_dir });
                data?.folders_doc && onSetUpdateProjectDocDefaultFolders(data.folders_doc);
              }
            };

            onEditProjectElement(payload, "dir");
          }
        };

        onSetApproveModal({
          open: true,
          params
        });
      },
      isSystem: true
    }
  ];

  return {
    contextMenuDocFileCallback: (file: IFile) =>
      isMainAccess || project.accesses.MGR_DOCS
        ? contextMenuDocFileCallbackArr(file)
        : contextMenuDocFileCallbackArr(file).filter(({ isSystem }) => !isSystem),
    contextMenuDocFolderCallback: (folder: IFolder) =>
      isMainAccess || project.accesses.MGR_DOCS
        ? contextMenuDocFolderCallbackArr(folder)
        : contextMenuDocFolderCallbackArr(folder).filter(({ isSystem }) => !isSystem)
  };
};

export const useContextProjecFile = () => {
  const { __ } = useLocales();

  const errorFolder = useErrorFolder();

  const isMainAccess = useProjectMainAccess();

  const { project } = useJoinProjectsSelectors();

  const {
    fetchFileZipLink,
    fetchFolderZipLink,
    onSetCreateFolderModal,
    onEditProjectFolders,
    onSetShareToMessengersModal,
    onSetApproveModal,
    onSetCopyLinkModal,
    onSetTrashProjectFile,
    onEditProjectElement,
    onSetUpdateProjectFilesDefaultFolders
  } = useActions();

  const contextMenuFileCallbackArr = (file: IFile): IPopoverData[] => [
    {
      name: __("Копировать ссылку"),
      img: <CopyIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        onSetCopyLinkModal({
          open: true,
          params: file.file_link
        });
      },
      isSystem: false
    },
    {
      name: __("Скачать"),
      img: <UploadIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        fetchFileZipLink(file.fid);
      },
      isSystem: false
    },
    {
      name: __("Поделиться"),
      img: <ShareIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        onSetShareToMessengersModal({
          open: true,
          params: file
        });
      },
      isSystem: false
    },
    {
      name: __("Удалить"),
      img: <TrashIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        const params = {
          titleHead: __("Удалить"),
          title: `${__("Вы действительно хотите удалить")} <span>${file.name}</span>?`,
          text: `${__(`Вы сможете восстановить ${file.name} с корзины`)}`,
          approveBtn: __("Удалить"),
          approveBtnVariant: ButtonVariantType.BLUE,
          callback: (): void => {
            const payload = {
              fids: [file.fid],
              is_del: 1,
              dep: file.dep,
              callback: () => {
                onSetTrashProjectFile({ id: file.fid });
              }
            };
            onEditProjectElement(payload, "file");
          }
        };
        onSetApproveModal({
          open: true,
          params
        });
      },
      isSystem: true
    }
  ];

  const contextMenuFolderCallbackArr = (folder: IFolder): IPopoverData[] => [
    {
      name: __("Копировать ccылку"),
      img: <CopyIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        onSetCopyLinkModal({
          open: true,
          params: `${projectSrc}${ROUTES.PROJECT}/${project.id}/${PROJECT_PATH.FILES}/${folder.id_dir}`
        });
      },
      isSystem: false
    },
    {
      name: __("Скачать"),
      img: <UploadIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        fetchFolderZipLink(folder.id_dir, null, null, errorFolder[ERROR_SERVER.NO_FILES]);
      },
      isSystem: false
    },
    {
      name: __("Поделиться"),
      img: <ShareIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        onSetShareToMessengersModal({
          open: true,
          params: folder
        });
      },
      isSystem: false
    },
    {
      name: __("Переименовать"),
      img: <EditIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        onSetCreateFolderModal({
          open: true,
          type: "edit",
          folder,
          onEditFolder: onEditProjectFolders
        });
      },
      isSystem: true
    },
    {
      name: __("Удалить"),
      img: <TrashIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        const params = {
          titleHead: __(__("Удалить")),
          title: `${__("Вы действительно хотите удалить")} <span>${folder.name}</span>?`,
          text: `${__(`Вы сможете восстановить ${folder.name} с корзины`)}`,
          approveBtn: __("Удалить"),
          approveBtnVariant: ButtonVariantType.BLUE,
          callback: (): void => {
            const payload = {
              id_dirs: [folder.id_dir],
              is_del: 1,
              dep: folder.dep,
              callback: (data: any) => {
                onSetTrashProjectFile({ id: folder.id_dir });
                data?.folders_file && onSetUpdateProjectFilesDefaultFolders(data.folders_file);
              }
            };

            onEditProjectElement(payload, "dir");
          }
        };

        onSetApproveModal({
          open: true,
          params
        });
      },
      isSystem: true
    }
  ];

  return {
    contextMenuFileCallback: (file: IFile) =>
      isMainAccess || project.accesses.MGR_FILES
        ? contextMenuFileCallbackArr(file)
        : contextMenuFileCallbackArr(file).filter(({ isSystem }) => !isSystem),
    contextMenuFolderCallback: (folder: IFolder) =>
      isMainAccess || project.accesses.MGR_FILES
        ? contextMenuFolderCallbackArr(folder)
        : contextMenuFolderCallbackArr(folder).filter(({ isSystem }) => !isSystem)
  };
};

export const useTaskDependencies = (): {
  taskDpendencies: Record<any, string>;
  taskDependenciesArr: any[];
} => {
  const { __ } = useLocales();
  const taskDpendencies = {
    [TASK_DEPENDENCIES.RELATION]: __("Связана с "),
    [TASK_DEPENDENCIES.REQUIRED_FOR]: __("Обязятельна для"),
    [TASK_DEPENDENCIES.DEPENDS_ON]: __("Зависит от"),
    [TASK_DEPENDENCIES.PARENT_FOR]: __("Родитель для"),
    [TASK_DEPENDENCIES.CHILD_FOR]: __("Подзадача для")
  };
  const taskDependenciesArr = Object.entries(taskDpendencies);

  return { taskDpendencies, taskDependenciesArr };
};

export const useContextProjecTrashElement = () => {
  const { __ } = useLocales();

  const { project } = useJoinProjectsSelectors();

  const navigate = useNavigate();

  const {
    onSetApproveModal,
    onSetUpdateProjectDocDefaultFolders,
    onSetUpdateProjectFilesDefaultFolders,
    onEditProjectElement,
    onSetDelProjectTrashElement,
    onDelProjectElement,
    onSetBackLogProject,
    onEditProjectTeam
  } = useActions();

  const allFoldersProject = useAllFolders();

  const getIdDirPath = (id: string, chapter: string): string => {
    const recoveryFolder = allFoldersProject.find(({ id_dir }) => id_dir === id);

    if (recoveryFolder) {
      return recoveryFolder.id_dir;
    } else {
      if (chapter === DEFAULT_PROJECT_DOC_FOLDERS.DOCS) {
        return project?.folders_doc[0]?.id_dir;
      }

      if (chapter === DEFAULT_PROJECT_DOC_FOLDERS.TEMPLATES) {
        return project?.folders_doc[1]?.id_dir;
      }

      return;
    }
  };

  const contextMenuTrashFile = (file: IFile): IPopoverData[] => [
    {
      name: __("Восстановить"),
      img: <ReturnIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        const { _PROJECT_DOC_ } = getPageDep(file.dep);

        const callback = (): void => {
          navigate(
            getTheRestorePath({
              path: `${ROUTES.PROJECT}/${project.id}/${_PROJECT_DOC_ ? PROJECT_PATH.DOC : PROJECT_PATH.FILES}`,
              id_dir: getIdDirPath(file.id_dir, file.chapter),
              id_parent: undefined
            })
          );
        };

        const payload = {
          fids: [file.fid],
          is_del: 0,
          dep: file.dep,
          chapter: file?.chapter,
          callback: () => {
            onSetDelProjectTrashElement({ id: file.fid });
          }
        };

        onEditProjectElement(payload, "file", callback);
      },
      isSystem: false
    },
    {
      name: __("Удалить навсегда"),
      img: <TrashIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        const params = {
          titleHead: __("Удалить навсегда"),
          title: `${__("Вы действительно хотите удалить")} <span>${file.name}</span>?`,
          text: __("Это действие необратимо"),
          approveBtn: __("Удалить"),
          approveBtnVariant: ButtonVariantType.BLUE,
          callback: (): void => {
            const payload = {
              fids: [file.fid],
              dep: file.dep,
              callback: () => {
                onSetDelProjectTrashElement({ id: file.fid });
              }
            };
            onDelProjectElement(payload, "file_del_force");
          }
        };
        onSetApproveModal({
          open: true,
          params
        });
      },
      isSystem: true
    }
  ];

  const contextMenuTrashFolder = (folder: IFolder): IPopoverData[] => [
    {
      name: __("Восстановить"),
      img: <ReturnIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        const { _PROJECT_DOC_, _PROJECT_FILES_ } = getPageDep(folder.dep);

        const callback = (): void => {
          navigate(
            getTheRestorePath({
              path: `${ROUTES.PROJECT}/${project.id}/${_PROJECT_DOC_ ? PROJECT_PATH.DOC : PROJECT_PATH.FILES}`,
              id_dir: getIdDirPath(folder.id_parent, folder.chapter),
              id_parent: undefined
            })
          );
        };

        const payload = {
          id_dirs: [folder.id_dir],
          is_del: 0,
          chapter: folder?.chapter,
          dep: folder.dep,
          callback: (data: any) => {
            onSetDelProjectTrashElement({ id: folder.id_dir });
            if (_PROJECT_DOC_ && data?.folders_doc) {
              onSetUpdateProjectDocDefaultFolders(data.folders_doc);
            }

            if (_PROJECT_FILES_ && data?.folders_file) {
              onSetUpdateProjectFilesDefaultFolders(data?.folders_file);
            }
          }
        };

        onEditProjectElement(payload, "dir", callback);
      },
      isSystem: true
    },
    {
      name: __("Удалить навсегда"),
      img: <TrashIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        const params = {
          titleHead: __(__("Удалить навсегда")),
          title: `${__("Вы действительно хотите удалить")} <span>${folder.name}</span>?`,
          text: __("Это действие необратимо"),
          approveBtn: __("Удалить"),
          approveBtnVariant: ButtonVariantType.BLUE,
          callback: (): void => {
            const payload = {
              id_dir: folder.id_dir,
              dep: folder.dep,
              callback: () => {
                onSetDelProjectTrashElement({ id: folder.id_dir });
              }
            };

            onDelProjectElement(payload, "dir_del");
          }
        };

        onSetApproveModal({
          open: true,
          params
        });
      },
      isSystem: true
    }
  ];

  const contextMenuTrashTask = (task: IProjectTask): IPopoverData[] => [
    {
      name: __("Восстановить"),
      img: <ReturnIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        const callback = (): void => {
          navigate(`/${ROUTES.PROJECT}/${project.id}/${!task.is_backlog ? PROJECT_PATH.TASKS : PROJECT_PATH.BACKLOG}`);
        };

        const payload = {
          id_task: task.id,
          is_del: 0,
          callback: (data: any) => {
            onSetDelProjectTrashElement({ id: task.id });
            if (task.is_backlog) {
              onSetBackLogProject(data?.project?.backlog);
            }
          }
        };

        onEditProjectElement(payload, "task", callback);
      },
      isSystem: true
    },
    {
      name: __("Удалить навсегда"),
      img: <TrashIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        const params = {
          titleHead: __(__("Удалить навсегда")),
          title: `${__("Вы действительно хотите удалить")} <span>${task.name}</span>?`,
          text: __("Это действие необратимо"),
          approveBtn: __("Удалить"),
          approveBtnVariant: ButtonVariantType.BLUE,
          callback: (): void => {
            const payload = {
              id_task: task.id,
              callback: () => {
                onSetDelProjectTrashElement({ id: task.id });
              }
            };

            onDelProjectElement(payload, "task_del");
          }
        };

        onSetApproveModal({
          open: true,
          params
        });
      },
      isSystem: true
    }
  ];

  const contextMenuTrashTeam = (team: IProjectTeam): IPopoverData[] => [
    {
      name: __("Восстановить"),
      img: <ReturnIcon />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        const callback = (): void => {
          navigate(`/${ROUTES.PROJECT}/${project.id}/${PROJECT_PATH.TEAM}`);
        };

        const payload = {
          id_item: team.id,
          is_del: 0,
          callback: (data: any) => {
            onSetDelProjectTrashElement({ id: team.id });
            onEditProjectTeam({ teams: data.project_teams, participants: data.participants });
          }
        };

        onEditProjectElement(payload, "project_teams", callback);
      },
      isSystem: true
    },
    {
      name: __("Удалить навсегда"),
      img: <TrashIcon width={13} height={13} />,
      callback: (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        const params = {
          titleHead: __(__("Удалить навсегда")),
          title: `${__("Вы действительно хотите удалить")} <span>${team.name}</span>?`,
          text: __("Это действие необратимо"),
          approveBtn: __("Удалить"),
          approveBtnVariant: ButtonVariantType.BLUE,
          callback: (): void => {
            const payload = {
              id_item: team.id,
              callback: () => {
                onSetDelProjectTrashElement({ id: team.id });
              }
            };

            onDelProjectElement(payload, "project_teams_del");
          }
        };

        onSetApproveModal({
          open: true,
          params
        });
      },
      isSystem: true
    }
  ];

  return {
    contextMenuTrashFile,
    contextMenuTrashFolder,
    contextMenuTrashTask,
    contextMenuTrashTeam
  };
};
