import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info_3.svg";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import { useDefaultProjectRole } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IParticipant, SYSTEM_ROLE } from "models/store/joinProjects/joinProgects";
import { ChangeEvent, FC, useMemo } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./Participant.module.sass";

interface IProps {
  user: IParticipant;
  onChangeJobTitle: (value: string, id: string) => void;
  onChangeRole: (value: string, id: string) => void;
}

const Participant: FC<IProps> = ({ user, onChangeJobTitle, onChangeRole }) => {
  const { __ } = useLocales();
  const { employees } = useUserSelectors();
  const { project } = useJoinProjectsSelectors();
  const defaultRoles = useDefaultProjectRole();
  const { formatDate } = useDateFormat();
  const { onSetUserDeleteModal } = useActions();
  const _user = employees.find((u) => u.id === user.id);

  const handleChangeJobTitle = (e: ChangeEvent<HTMLInputElement>) => onChangeJobTitle(e.currentTarget.value, user.id);

  const options = useMemo(() => {
    return project.roles.map((role) => ({
      name: role.is_system ? defaultRoles[role.name as SYSTEM_ROLE].title : role.name,
      value: role.id,
      callback: () => onChangeRole(role.id, user.id)
    }));
  }, [defaultRoles, onChangeRole, project.roles, user.id]);

  const selectedDefault = useMemo(() => {
    const findRole = project.roles.find((role) =>
      user.idRole ? role.id === user.idRole : role.name === SYSTEM_ROLE.DEVELOPER
    );
    return {
      name: findRole.is_system ? defaultRoles[findRole.name as SYSTEM_ROLE].title : findRole.name,
      value: findRole.id
    };
  }, [defaultRoles, project.roles, user.idRole]);

  return (
    <li className={styles.block}>
      <div className={styles.info}>
        <div className={styles.info}>
          <Avatar name={user.fname + " " + user.sname} imgSrc={user.icon} size={40} />
          <div>
            <p className={styles.name}>
              {user.fname}&nbsp;{user.sname}
            </p>
            <p className={styles.email}>{user.email}</p>
          </div>
        </div>
        <SelectCustom options={options} selectedDefault={selectedDefault} width={132} label={__("Роль")} />
        <div className={styles.inputBox}>
          <Input value={user.jobTitle} onChange={handleChangeJobTitle} label={__("Должность")} />
        </div>
        <div className={styles.btn}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            onClick={() => onSetUserDeleteModal({ open: true, user })}
            iconL={<CrossIcon />}
            isSquare
          />
        </div>
      </div>
      {_user && !_user?.pass && (
        <div className={styles.alert}>
          <InfoIcon />
          &nbsp;
          {__(`Приглашение отправленно ${formatDate(_user.date_send)}`)}
        </div>
      )}
    </li>
  );
};

export default Participant;
