import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./ThreeDots.module.sass";

function ThreeDots({ onClick, horizontal }) {
  return (
    <div className={classNames(styles.menuWrap, { [styles.horizontal]: horizontal })} onClick={onClick}>
      <span className={styles.menu} />
    </div>
  );
}

export default ThreeDots;

ThreeDots.defaultProps = {
  onClick: () => {},
  horizontal: false
};

ThreeDots.propTypes = {
  onClick: PropTypes.func,
  horizontal: PropTypes.bool
};
