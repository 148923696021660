import { ANALITIC_INFO, IAnaliticContent, IHelperContentProps } from "models/project/helperContent";
import React from "react";

import { useAnaliticsInfo } from "../../../../../../generalComponents/collections/project";
import styles from "./HelperContent.module.sass";

const HelperContent: React.FC<IHelperContentProps> = ({ block }): JSX.Element => {
  const INFO: Record<ANALITIC_INFO, IAnaliticContent> = useAnaliticsInfo();

  return (
    <div className={styles.content}>
      <p>{INFO[block].title}</p>
      <ol>
        {INFO[block].list.map((it, i) => (
          <li key={i}>
            {it.listItem}
            {it.subList && (
              <ul>
                {it.subList.map((el, i) => (
                  <li key={i}>{el}</li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default HelperContent;
