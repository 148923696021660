import { ReactComponent as ArchiveIcon } from "assets/icons/archive_to.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder_in.svg";
import { ReactComponent as UnreadIcon } from "assets/icons/mail_unread.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as SpamIcon } from "assets/icons/warning.svg";
import { ReactComponent as HeartIcon } from "assets/PrivateCabinet/greyHeart.svg";
import { ReactComponent as FlagIcon } from "assets/PrivateCabinet/mail/important.svg";
import classNames from "classnames";
import BottomMenu from "generalComponents/BottomMenu/BottomMenu";
import Button from "generalComponents/Button/Button";
import { useFindPostbox, usePostboxFolder } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import React, { useMemo } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";

import styles from "./PostboxBottomPanel.module.sass";

const PostboxBottomPanel = () => {
  const { __ } = useLocales();
  const { pickedLetters } = usePostboxSelectors();
  const { postbox } = useFindPostbox();
  const { folderName } = useParams();
  const { _isInbox, _isSent, _isDraft, _isFlag, _isSpam, _isTrash, _isArchive, _isSchedule } = usePostboxFolder();
  const {
    onChangeStandartFlagMail,
    onChangeCustomFlagMail,
    onMoveLetter,
    onNullifyAllLetters,
    onScheduleDelete,
    onRemoveLetter,
    onScheduleEdit
  } = useActions();

  const filtredFolders = useMemo(
    () => postbox.folders.custom.filter((el) => el.orig !== folderName),
    [folderName, postbox.folders.custom]
  );

  const isLiked = () => pickedLetters.every((el) => el.is_liked);
  const isFlagged = () => pickedLetters.every((el) => el.flagged);
  const isSeen = () => pickedLetters.every((el) => el.seen);

  const errorMsg = __("Что-то пошло не так. Попробуйте позже!");

  const onChangeFlag = (flag: string): void => {
    const val = flag === "flagged" ? (isFlagged() ? "0" : "1") : isSeen() ? "0" : "1";
    const unreadAmount = pickedLetters.filter((el) => (val === "1" ? !el.seen : el.seen)).length;
    const data = pickedLetters.map((el) => ({
      folder: el.folder,
      message_nums: el.uid,
      flag: flag,
      flag_value: val,
      message_id: el.message_id
    }));
    const payload = {
      data: {
        user: postbox.email,
        pass: postbox.pass,
        server: postbox.server,
        data: JSON.stringify(data)
      },
      value: { flag, val, folder: folderName, unreadAmount }
    };
    onChangeStandartFlagMail(payload, errorMsg);
  };

  const onChangeFavorite = (): void => {
    const val = isLiked() ? "0" : "1";
    const data = pickedLetters.map((el) => ({
      folder: el.folder,
      message_nums: el.uid,
      is_liked: val,
      message_id: el.message_id
    }));
    const payload = {
      data: {
        user: postbox.email,
        pass: postbox.pass,
        server: postbox.server,
        data: JSON.stringify(data)
      },
      value: { flag: "is_liked", val }
    };
    onChangeCustomFlagMail(payload, errorMsg);
  };

  const onChangeFavoriteSchedule = (): void => {
    const ids = pickedLetters.map((mail) => String(mail.id));
    const val = isLiked() ? "0" : "1";

    const formData = new FormData();
    formData.append("is_liked", val);
    ids.forEach((id) => formData.append("id_items[]", id));

    formData.append("user", postbox?.email);

    const messages = {
      error: __("Не удалось изменить письма"),
      success: __('Выбранные письма отмечены как "Избранные"')
    };
    onScheduleEdit(formData, messages);
  };
  const onChangeFlaggedSchedule = (): void => {
    const ids = pickedLetters.map((mail) => String(mail.id));
    const val = isFlagged() ? "0" : "1";

    const formData = new FormData();
    formData.append("flagged", val);
    ids.forEach((id) => formData.append("id_items[]", id));
    formData.append("user", postbox?.email);
    console.log("wwwwww");
    const messages = {
      error: __("Не удалось изменить письма"),
      success: __('Выбранные письма отмечены как "Важные"')
    };
    onScheduleEdit(formData, messages);
  };

  const onMoveLetters = (folder: string): void => {
    const data = pickedLetters.map((el) => ({ folder: el.folder, message_nums: el.uid, folder_new: folder }));
    const payload = {
      user: postbox.email,
      pass: postbox.pass,
      server: postbox.server,
      data: JSON.stringify(data)
    };
    onMoveLetter(payload, errorMsg);
  };

  const onDeleteSchedule = () => {
    const messages = {
      success: __("Запланированніе письма удалені"),
      error: __("Что-то пошло не так! Попробуйте еще раз")
    };
    onScheduleDelete(
      pickedLetters.map((el) => el.id),
      messages
    );
  };

  const onHardDelete = () => {
    const payload = {
      user: postbox.email,
      pass: postbox.pass,
      server: postbox.server,
      data: JSON.stringify(pickedLetters.map((el) => ({ folder: el.folder, message_nums: el.uid })))
    };

    const errorMsg = __("Что-то пошло не так. Попробуйте позже!");
    onRemoveLetter(payload, errorMsg);
  };

  const unreadBtn = (): React.ReactNode => (
    <Button
      key={1}
      variant={ButtonVariantType.EXRTA_LIGHT}
      size={ButtonSizeType.MEDIUM}
      iconL={<UnreadIcon />}
      onClick={() => onChangeFlag("seen")}
      title={isSeen() ? __("Отметить как непрочитаное") : __("Отметить как прочитаное")}
    />
  );

  const archiveBtn = (): React.ReactNode => (
    <Button
      variant={ButtonVariantType.EXRTA_LIGHT}
      size={ButtonSizeType.MEDIUM}
      iconL={<ArchiveIcon />}
      onClick={() => onMoveLetters(POSTBOX_SYSTEM_FOLDER.ARCHIVE)}
      title={__("В архив")}
    />
  );

  const folderBtn = (): React.ReactNode => {
    return (
      filtredFolders.length > 0 && (
        <div className={styles.folderBlock}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.MEDIUM}
            iconL={<FolderIcon />}
            onClick={() => {}}
            title={__("Переместить в папку")}
          />
          <ul className={styles.folderList}>
            {filtredFolders.map((el, idx) => (
              <li key={idx} className={styles.folderItem}>
                <button onClick={() => onMoveLetters(el.orig)} type="button">
                  {el.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )
    );
  };

  const flaggedBtn = (): React.ReactNode => (
    <Button
      variant={ButtonVariantType.EXRTA_LIGHT}
      size={ButtonSizeType.MEDIUM}
      iconL={<FlagIcon className={classNames(styles.icon, { [styles.active]: isFlagged() })} />}
      onClick={_isSchedule ? onChangeFlaggedSchedule : () => onChangeFlag("flagged")}
      title={__("Важные")}
    />
  );

  const likedBtn = (): React.ReactNode => (
    <Button
      variant={ButtonVariantType.EXRTA_LIGHT}
      size={ButtonSizeType.MEDIUM}
      iconL={<HeartIcon className={classNames(styles.icon, { [styles.active]: isLiked() })} />}
      onClick={_isSchedule ? onChangeFavoriteSchedule : onChangeFavorite}
      title={__("Избранные")}
    />
  );

  const spamBtn = (): React.ReactNode => (
    <Button
      variant={ButtonVariantType.EXRTA_LIGHT}
      size={ButtonSizeType.MEDIUM}
      iconL={<SpamIcon />}
      onClick={() => onMoveLetters(POSTBOX_SYSTEM_FOLDER.Spam)}
      title={__("В спам")}
    />
  );

  const deleteBtn = (): React.ReactNode => (
    <Button
      variant={ButtonVariantType.EXRTA_LIGHT}
      size={ButtonSizeType.MEDIUM}
      iconL={<TrashIcon />}
      onClick={_isSchedule ? onDeleteSchedule : () => onMoveLetters(POSTBOX_SYSTEM_FOLDER.Trash)}
      title={__("Удалить")}
    />
  );

  const hardDeleteBtn = (): React.ReactNode => (
    <Button
      variant={ButtonVariantType.EXRTA_LIGHT}
      size={ButtonSizeType.MEDIUM}
      iconL={<TrashIcon />}
      onClick={onHardDelete}
      title={__("Удалить навсегда")}
    />
  );

  const renderOpions = () => {
    if (_isInbox) {
      return [unreadBtn(), archiveBtn(), folderBtn(), likedBtn(), flaggedBtn(), spamBtn(), deleteBtn()];
    }
    if (_isSent) {
      return [archiveBtn(), folderBtn(), likedBtn(), flaggedBtn(), deleteBtn()];
    }
    if (_isArchive) {
      return [unreadBtn(), folderBtn(), likedBtn(), flaggedBtn(), deleteBtn()];
    }
    if (_isDraft) {
      return [archiveBtn(), likedBtn(), flaggedBtn(), deleteBtn()];
    }
    if (_isFlag) {
      return [archiveBtn(), folderBtn(), likedBtn(), flaggedBtn(), deleteBtn()];
    }
    if (_isSpam) {
      return [unreadBtn(), archiveBtn(), folderBtn(), likedBtn(), flaggedBtn(), deleteBtn()];
    }
    if (_isSchedule) {
      return [likedBtn(), flaggedBtn(), deleteBtn()];
    }
    if (_isTrash) {
      return [unreadBtn(), archiveBtn(), folderBtn(), likedBtn(), flaggedBtn(), hardDeleteBtn()];
    }
    return [unreadBtn(), archiveBtn(), folderBtn(), likedBtn(), flaggedBtn(), deleteBtn()];
  };
  return (
    <BottomMenu>
      <div className={styles.container}>
        <span>{__("Выбранно")}</span>
        <span className={styles.amount}>{pickedLetters.length}</span>
        {renderOpions()}
        <Button
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.MEDIUM}
          onClick={onNullifyAllLetters}
          text={__("Отмена")}
        />
      </div>
    </BottomMenu>
  );
};

export default PostboxBottomPanel;
