import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import ContactSingle from "containers/Profile/ContactSingle/ContactSingle";
import ContactsSidebar from "containers/Profile/ContactsSidebar/ContactsSidebar";
import Button from "generalComponents/Button/Button";
import SearchField from "generalComponents/SearchField/SearchField";
import Tabs from "generalComponents/Tabs/Tabs";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IContact } from "models/store/user/user";
import { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./Contacts.module.sass";

const Contacts = () => {
  const { __ } = useLocales();
  const { onSetContactsModal } = useActions();

  const [active, setActive] = useState("All");
  const [activeContact, setActiveContact] = useState<IContact>();
  const [search, setSearch] = useState("");

  const onAddContact = (): void => {
    onSetContactsModal({ open: true });
  };

  const tabs = [
    { name: __("Все контакты"), value: "All", onClick: () => setActive("All") },
    { name: __("Избранное"), value: "Favorites", onClick: () => setActive("Favorites") }
  ];

  return (
    <WorkAreaWrap>
      <ToolsPanel>
        <Tabs tabs={tabs} value={active} size="large" />
        <div className={styles.control}>
          <SearchField variant="secondary" value={search} setValue={setSearch} placeholder={__("Введите")} />
          <Button
            onClick={onAddContact}
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={__("Добавить")}
            iconL={<PlusIcon width={10} height={10} />}
          />
        </div>
      </ToolsPanel>
      <div className={styles.container}>
        <ContactsSidebar
          isFavorites={active === "Favorites"}
          search={search}
          setActiveContact={setActiveContact}
          activeContact={activeContact}
        />
        {activeContact && <ContactSingle data={activeContact} />}
      </div>
    </WorkAreaWrap>
  );
};

export default Contacts;
