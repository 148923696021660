import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ISafe } from "models/store/safe/safe";
import React, { useState } from "react";
import { useLocales } from "react-localized";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./RenameSafe.module.sass";

interface IRenameSafeProps {
  onClose: () => void;
  safe: ISafe;
}
const RenameSafe: React.FC<IRenameSafeProps> = ({ onClose, safe }) => {
  const { __ } = useLocales();
  const { onEditSafe } = useActions();
  const [name, setName] = useState<string>(safe.name);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => setName(e.currentTarget.value);

  const onSubmit = () => {
    const payload = {
      name,
      id: safe.id,
      pass: safe.pass
    };

    onEditSafe(payload, EVENT_TYPE.EDIT_NAME);
  };
  return (
    <div className={styles.wrap}>
      <HeaderModal onClose={onClose} title={__("Переименовать")} />
      <div className={styles.body}>
        <Input value={name} onChange={handleChange} label={__("Имя")} />
        <div className={styles.btns}>
          <Button
            onClick={onClose}
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.LARGE}
            text={__("Отменить")}
            type="button"
          />
          <Button
            onClick={onSubmit}
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.LARGE}
            text={__("Применить")}
            type="button"
            disabled={!name}
          />
        </div>
      </div>
    </div>
  );
};

export default RenameSafe;
