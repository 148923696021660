import "swiper/css";

import AudioPlayer from "generalComponents/AudioPlayer/AudioPlayer";
import { Image } from "generalComponents/Image/Image";
import { EditFileView } from "generalComponents/Modals/Components/EditFileView/EditFileView";
import { NoFormatFileView } from "generalComponents/Modals/Components/NoFormatFileView/NoFormatFileView";
import { PdfView } from "generalComponents/Modals/Components/PdfView/PdfView";
import { isSafari } from "generalComponents/Services/browserServices";
import { splitFilename } from "generalComponents/Services/helpers";
import { getMedia } from "generalComponents/Services/mediaServices";
import { projectSrc } from "generalComponents/variables/globalVariables";
import VideoPlayer from "generalComponents/VideoPlayer/VideoPlayer";
import { IFile } from "models/store/files/files";
import { FC, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperClass from "swiper/types/swiper-class";
import { v4 as uuid } from "uuid";

import { SliderNav } from "./components/SliderNav/SliderNav";
import styles from "./SliderFiles.module.sass";

interface ISliderFilesProps {
  files: IFile[];
  initialSlide: number;
  onSetCurrentFile: ({ name, fid }: { name: string; fid: string }) => void;
  onCloseModal: () => void;
}

export const SliderFiles: FC<ISliderFilesProps> = ({ files, initialSlide, onSetCurrentFile, onCloseModal }) => {
  const [swiperSlider, setSwiperSlider] = useState<SwiperClass>(null);
  const [activeIndex, seActiveIndex] = useState<number>(initialSlide + 1);
  const [audio, setAudio] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [collapse, setCollapse] = useState<boolean>(false);

  const totalSlides = files?.length;

  const collapseToggle = (): void => setCollapse((prev) => !prev);

  useEffect(() => {
    if (files?.length !== 0 && activeIndex) {
      const currentFile = files[activeIndex - 1];

      onSetCurrentFile({
        name: currentFile?.name,
        fid: currentFile?.fid
      });

      const currentFileType = currentFile?.mime_type;
      const currentFilePreview = currentFile?.preview;

      if (currentFileType) {
        if (currentFileType?.includes("video") && currentFilePreview) {
          isSafari() ? getMedia(currentFilePreview, currentFileType, setVideo) : setVideo(currentFilePreview);
        }

        if (currentFileType?.includes("audio") && currentFilePreview) {
          isSafari() ? getMedia(currentFilePreview, currentFileType, setAudio) : setAudio(currentFilePreview);
        }
      }
    }
  }, [activeIndex]); //eslint-disable-line

  const renderTypeFile = ({
    name,
    preview,
    mime_type,
    ext,
    edit_url
  }: {
    name: string;
    preview: string;
    mime_type: string;
    ext: string;
    edit_url: string;
  }) => {
    if (mime_type) {
      if (mime_type.includes("image")) {
        return <Image src={`${projectSrc}${preview}`} alt={splitFilename(name)?.name} />;
      }

      if (mime_type.includes("video") && ext !== "MOV") {
        return video && <VideoPlayer collapse={collapse} setCollapse={collapseToggle} video={video} />;
      }

      if (mime_type.includes("audio")) {
        return audio && <AudioPlayer collapse={collapse} setCollapse={collapseToggle} audio={audio} />;
      }

      if (mime_type.includes("pdf")) {
        return <PdfView src={`${projectSrc}${preview}`} />;
      }
    }

    if (edit_url) {
      return <EditFileView name={name} src={edit_url} onClose={onCloseModal} />;
    }

    return <NoFormatFileView />;
  };

  return (
    !!files?.length &&
    activeIndex && (
      <>
        <Swiper
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={15}
          navigation={false}
          grabCursor
          wrapperClass={styles.sliderWrapper}
          className={styles.sliderFiles}
          initialSlide={initialSlide}
          centeredSlides
          onSwiper={setSwiperSlider}
          onActiveIndexChange={(slider: SwiperClass) => seActiveIndex(slider?.activeIndex + 1)}
        >
          {files.map(({ name, preview, mime_type, ext, edit_url }) => (
            <SwiperSlide key={uuid()}>{renderTypeFile({ name, preview, mime_type, ext, edit_url })}</SwiperSlide>
          ))}
        </Swiper>
        {files?.length > 1 && (
          <SliderNav
            activeIndex={activeIndex}
            total={totalSlides}
            slidePrev={() => swiperSlider.slidePrev()}
            slideNext={() => swiperSlider.slideNext()}
          />
        )}
      </>
    )
  );
};
