import { IItemAddedDateProps } from "models/workElements/itemAddedDate";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./ItemAddedDate.module.sass";

const ItemAddedDate: React.FC<IItemAddedDateProps> = ({ date, itemsLength }): JSX.Element => {
  const { __ } = useLocales();

  const getItemAddedDate = (): string => {
    switch (date) {
      case 1:
        return `${__("Сегодня добавлено")} ${itemsLength} ${__("объектов")}`;

      case 7:
        return `${__("за последние 7 дней добавлено")} ${itemsLength} ${__("объектов")}`;

      case 14:
        return `${__("за последние 2 недели добавлено")} ${itemsLength} ${__("объектов")}`;

      case 30:
        return `${__("за последние 30 дней добавлено")} ${itemsLength} ${__("объектов")}`;

      case 60:
        return `${__("за последние 60 дней добавлено")} ${itemsLength} ${__("объектов")}`;

      case 365:
        return `${__("за последний год добавлено")} ${itemsLength} ${__("объектов")}`;

      case 999:
        return `${__("более года назад добавлено")} ${itemsLength} ${__("объектов")}`;
    }
  };

  return <span className={styles.addedDateText}>{getItemAddedDate()}</span>;
};

export default ItemAddedDate;
