import api from "api";
import { ReactComponent as SendIcon } from "assets/icons/arrow-send.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as LinkIcon } from "assets/icons/copy.svg";
import { ReactComponent as RadioIcon } from "assets/icons/radio.svg";
import { ReactComponent as RadioEmptyIcon } from "assets/icons/radio_empty.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import classNames from "classnames";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import PopUp from "generalComponents/PopUp/PopUp";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialShareToMessengersModalState, ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IUserInfo } from "models/store/user/user";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { isEmail } from "utils/validation";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./ShareToMessengers.module.sass";

const ShareToMessengers = (): JSX.Element => {
  const { __ } = useLocales();
  const { uid, employees, theme } = useUserSelectors();
  const { onSetShareToMessengersModal, onSetTopMessageModal, onSetCopyLinkModal } = useActions();
  const { value, onChange } = useInputValue();
  const {
    shareToMessengers: { params, email }
  } = useGlobalModalsSelectors();

  const { project } = useJoinProjectsSelectors();

  const [checked, setChecked] = useState<IUserInfo[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [emailValue, setEmailValue] = useState<string>(email || "");
  const [isError, setIsError] = useState<boolean>(false);

  const [_isFolder] = useState<boolean>(params.is_dir === 1);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const closeModal = (): void => {
    onSetShareToMessengersModal(initialShareToMessengersModalState());
  };

  useEffect(() => {
    window.addEventListener("keypress", onAddEmail);
    return () => window.removeEventListener("keypress", onAddEmail);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo({ left: listRef.current.scrollLeft + 200, behavior: "smooth" });
    }
  }, [emails]); // eslint-disable-line

  const onAddEmail = (e: KeyboardEvent): void => {
    if (e.code === "Enter" || e.code === "Space") {
      handleNewEmail();
    }
  };

  const handleNewEmail = (): void => {
    if (isEmail(inputRef.current.value.trim())) {
      inputRef.current.focus();
      setEmails((prev) => [...prev, inputRef.current.value.trim()]);
      setEmailValue("");
    } else setTimeout(() => setIsError(true), 0);
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (isError) setIsError(false);
    setEmailValue(e.currentTarget.value);
  };

  const onCopyLink = (): void => {
    if (_isFolder) {
      onSetCopyLinkModal({
        open: true,
        params: (params as IFolder).link_share_to_user
      });
    } else {
      onSetCopyLinkModal({
        open: true,
        params: (params as IFile).file_link
      });
    }
  };

  const addChecked = (user: IUserInfo): void => setChecked((prev) => [...prev, user]);
  const removeChecked = (user: IUserInfo): void => setChecked((prev) => prev.filter((el) => el.uid !== user.uid));
  const isChecked = (user: IUserInfo): boolean => checked.findIndex((el) => el.uid === user.uid) >= 0;
  const removeEmailItem = (value: string) => setEmails((emails) => emails.filter((el) => el !== value));
  const isDisabledEmails = (): boolean => checked.length > 0;
  const isDisabledUsers = (): boolean => Boolean(emailValue.trim()) || emails.length > 0;

  const filtredEmployyes = useMemo(
    () =>
      employees.filter(
        (el) =>
          el.sname.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
          el.fname.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
          el.pname.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      ),
    [employees, value]
  );

  const onSendToChat = () => {
    // TODO add send file to chat
  };

  const onSendToEmail = async (): Promise<void> => {
    if (!emailValue.trim() && emails.length === 0) return;
    if (emailValue.trim() && !isEmail(emailValue.trim())) {
      setIsError(true);
      return;
    }
    const emailForSend = [...emails];
    if (emailValue.trim() && isEmail(emailValue.trim())) {
      emailForSend.push(emailValue);
    }

    try {
      const payload = {
        uid,
        id_dir: (params as IFolder)?.id_dir,
        prim: (params as IFolder)?.prim,
        fid: !params.is_dir ? (params as IFile).fid : undefined,
        id_project: project?.id,
        dep: params?.dep ? params.dep : undefined
      };

      const result = await Promise.all(
        emailForSend.map(async (item) => {
          const { data } = await api.get(`/ajax/${params.is_dir ? "dir" : "file"}_send.php`, {
            params: { ...payload, user_to: item }
          });
          return data.ok;
        })
      );
      if (result.every((el) => el === true)) {
        closeModal();
        onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: __("Ссылкa отправлена") });
      }
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.root}>
        <HeaderModal title={__("Поделиться")} onClose={closeModal} />
        <div className={styles.body}>
          <div className={styles.container}>
            <div className={styles.inputBox}>
              <Input
                value={value}
                onChange={onChange}
                rightIcon={<SearchIcon fill="var(--color-d100)" />}
                placeholder={__("Введите")}
              />
            </div>
            <ul className={styles.userList}>
              {filtredEmployyes.map((user) => (
                <li
                  className={classNames(styles.user, { [styles.checked]: isChecked(user) })}
                  key={user.uid}
                  onClick={isChecked(user) ? () => removeChecked(user) : () => addChecked(user)}
                >
                  <Avatar
                    name={`${user.fname} ${user.sname}`}
                    size={28}
                    imgSrc={user.icon ? `${projectSrc + user.icon}` : undefined}
                  />
                  <div className={styles.name}>{`${user.sname} ${user.fname} ${user.pname}`}</div>
                  {isChecked(user) ? <RadioIcon /> : <RadioEmptyIcon />}
                </li>
              ))}
            </ul>
            <div className={classNames({ [styles.disabled]: isDisabledUsers() })} />
          </div>

          <div className={classNames(styles.container, styles.block)}>
            <div className={styles.subtitle}>{__("Отправить на Email")}</div>
            <div className={styles.emailsBox}>
              <ul
                className={classNames(styles.emails, `scrollbar-thin-${theme}`, { [styles.error]: isError })}
                ref={listRef}
              >
                {emails.map((email) => (
                  <li key={email} className={styles.emailItem}>
                    <div className={styles.contactName}>{email}</div>
                    <button className={styles.removeBtn} onClick={() => removeEmailItem(email)}>
                      <CloseIcon width={12} height={12} />
                    </button>
                  </li>
                ))}
                <li className={styles.inpupLi}>
                  <input
                    type="text"
                    className={styles.searchInput}
                    value={emailValue.trim()}
                    onChange={onChangeEmail}
                    ref={inputRef}
                    placeholder={__("Имя или email")}
                    readOnly={Boolean(email)}
                  />
                </li>
              </ul>
              <Button
                type="button"
                variant={ButtonVariantType.BLUE}
                size={ButtonSizeType.SMALL}
                iconL={<SendIcon fill="var(--color-white)" />}
                onClick={onSendToEmail}
                disabled={isError}
              />
            </div>
            <div className={classNames({ [styles.disabled]: isDisabledEmails() })} />
          </div>

          <div className={classNames(styles.container, styles.block)}>
            <div className={styles.subtitle}>{__("Публичная ссылка")}</div>
            <Button
              type="button"
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              iconL={<LinkIcon />}
              text={__("Копировать")}
              onClick={onCopyLink}
            />
            <div className={classNames({ [styles.disabled]: isDisabledEmails() })} />
          </div>

          <div className={styles.footer}>
            <Button
              type="button"
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Отмена")}
              onClick={closeModal}
            />
            <Button
              type="button"
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={__("Поделиться")}
              onClick={onSendToChat}
              disabled={checked.length === 0}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default ShareToMessengers;
