import { useEffect, useState } from "react";

const useInactivityTracker = (time: number): boolean => {
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    let inactivityTimer: NodeJS.Timeout;

    // Обработчик события активности пользователя
    const handleActivity = () => {
      if (inactive) {
        setInactive(false); // Сбросить статус неактивности, если пользователь снова активен
      }

      // Перезапустить таймер после каждого действия пользователя
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => setInactive(true), time * 60 * 1000); // 5 минут (5 * 60 * 1000 миллисекунд)
    };

    // Добавить обработчики событий для отслеживания активности пользователя
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Очистка таймера и удаление обработчиков событий при размонтировании хука
    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [inactive, time]);

  return inactive;
};

export default useInactivityTracker;
