import { TYPES } from "generalComponents/variables/global";
import { MailboxAuthErrorsTypes, MailboxRegErrorsTypes } from "generalComponents/variables/mail";

import api from "../../api";
import { typeCheck } from "./browserServices";

export const replaceFile = async (uid, info, file) => {
  const newFIle = file.replace("image/png", "image/octet-stream");
  const blob = new Blob([newFIle], { type: "image/png" });
  let data = new FormData();
  data.append("uid", uid);
  data.append("myfile", blob);
  data.append("fid", info.fid);
  data.append("id_dir", info.id_dir);
  api.post(`/ajax/file_replace.php`, data).catch((e) => console.log(e));
};

export const replaceChatMessage = (message, uid, file) => {
  const newFIle = file.preview.replace("image/png", "image/octet-stream");
  const blob = new Blob([newFIle], { type: "image/png" });
  let data = new FormData();
  data.append("uid", uid);
  data.append("attachment", blob);
  data.append("id_message", message.id);
  data.append("text", ""); //TODO - mkortelov - check for text message
  api.post(`/ajax/chat_message_edit.php`, data).catch((e) => console.log(e));
};

//Moves file to another folder
export const moveFile = (folder, file, uid) => {
  return api
    .post(`/ajax/file_move.php?uid=${uid}&fid=${file.fid}&dir=${folder.path}`)
    .then((res) => {
      return !!res.data.ok;
    })
    .catch(() => false);
};

//Moves folder to another folder
export const moveFolder = (folder, folderToMove, uid) => {
  if (folder.path.startsWith(folderToMove.path))
    return Promise().reject(new Error("Folder cannot be move to itself or to children"));
  return api
    .post(`/ajax/dir_move.php?uid=${uid}&dir=${folderToMove.name}&parent=${folderToMove.gdir}&dir_new=${folder.path}`)
    .then((res) => {
      return !!res.data.ok;
    })
    .catch(() => false);
};

//Create an array for php request
export function arrayForPhpRequest(key, array) {
  return array.reduce((acc, item, i) => {
    return (acc += `&${key}[${i}]=${item}`);
  }, "");
}

export const checkResponseStatus = (status) => {
  if (typeCheck(status) === TYPES.NUMBER && status === 1) {
    return status === 1;
  }
  if (typeCheck(status) === TYPES.BOOLEAN && status) {
    return status;
  }
  throw Error(`status ${status} with type ${typeCheck(status)} is not ok`);
};

// Mail
export const checkResponseStatusMailboxCreation = (status, error) => {
  if (typeCheck(status) === TYPES.NUMBER && status === 1 && error === "") {
    return status;
  }
  if (typeCheck(status) === TYPES.NUMBER && status === 1 && error === MailboxRegErrorsTypes.EMAIL_NAME_EXISTS) {
    throw Object.assign(Error(`status ${status} with type ${typeCheck(status)} is not ok`), {
      type: error
    });
  }
  if (typeCheck(status) === TYPES.NUMBER && status === 0 && error === MailboxRegErrorsTypes.UNKNOWN_DOMAIN) {
    throw Object.assign(Error(`status ${status} with type ${typeCheck(status)} is not ok`), {
      type: error
    });
  }

  throw Object.assign(Error(`status ${status} with type ${typeCheck(status)} is not ok`), {
    type: error
  });
};

export const checkResponseStatusMailboxAuth = (status, error) => {
  if (typeCheck(status) === TYPES.NUMBER && status === 1 && error === "") {
    return status;
  }
  if (typeCheck(status) === TYPES.NUMBER && status === 0 && error === MailboxAuthErrorsTypes.CANNOT_LOGIN) {
    throw Object.assign(Error(`status ${status} with type ${typeCheck(status)} is not ok`), {
      type: error
    });
  }
  if (typeCheck(status) === TYPES.NUMBER && status === 0 && error === MailboxAuthErrorsTypes.AGENT_IS_BLOCK) {
    throw Object.assign(Error(`status ${status} with type ${typeCheck(status)} is not ok`), {
      type: error
    });
  }

  throw Object.assign(Error(`status ${status} with type ${typeCheck(status)} is not ok`), {
    type: error
  });
};
