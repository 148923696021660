import classnames from "classnames";
import ProgramItem from "containers/Programs/ProgramItem/ProgramItem";
import Loader from "generalComponents/Loaders/4HUB";
import { useIsFavoriteProgram } from "hooks/programHooks";
import { useActions } from "hooks/useActions";
import { LoaderTypes } from "models/generalComponents/loader";
import React, { FC, useEffect } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useProgramsSelectors } from "Store/selectors/programsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./ProgramsWorkSpace.module.sass";

const ProgramsWorkSpace: FC = () => {
  const { __ } = useLocales();
  const { programId } = useParams();
  const { onGetProgramsByCategory, setProgramsByCategory } = useActions();

  const { programs, isGetProgramsByCatLoading } = useProgramsSelectors();
  const { theme } = useUserSelectors();
  const isFavorite = useIsFavoriteProgram();

  useEffect(() => {
    const controller = new AbortController();
    const nessageError = __("Не удалось получить программы! Попробуйте еще раз!");
    const payload = {
      depId: programId,
      isFavorite: isFavorite ? "1" : undefined
    };
    onGetProgramsByCategory(payload, nessageError, controller);
    return () => {
      setProgramsByCategory([]);
      controller.abort();
    };
  }, [programId, isFavorite]); // eslint-disable-line

  return (
    <div className={classnames(styles.fileList, `scrollbar-medium-${theme}`)}>
      {!isGetProgramsByCatLoading ? (
        programs.length > 0 && programs.map((program, i) => <ProgramItem key={i} program={program} />)
      ) : (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="static"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      )}
    </div>
  );
};

export default ProgramsWorkSpace;
