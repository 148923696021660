import { LibraryTypesRubrics } from "generalComponents/variables/library";

import { IFile } from "../files/files";
import { IFolder } from "../folders/foldersStore";
import { IInitialsUploadLibraryFiles, initialUploadLibraryFiles } from "./uploadFilesModals/uploadLibraryFiles";

export interface ILibraryRubric {
  id: string;
  name: Exclude<LibraryTypesRubrics, LibraryTypesRubrics.ALL_RUBRICS>;
  id_user: string;
  is_system: string;
  is_del: string;
  id_parent: string;
  ctime?: string;
  mdate: string;
  icon: string;
  icons: string[];
}

export interface IFolderInfo {
  name: Exclude<LibraryTypesRubrics, LibraryTypesRubrics.ALL_RUBRICS>;
  id_user: string;
  is_system: string;
  is_del: string;
}

export type FoldersNextArrayType = (IFile | IFolder)[];
export type FoldersNextObjectType = Record<string, FoldersNextArrayType>;
export type FoldersNextType = FoldersNextObjectType | FoldersNextArrayType;

export interface ILibraryState {
  rubrics: ILibraryRubric[];
  folders: FoldersNextType;
  folderInfo: IFolder;
  total: number;
  page: number;
  loader: boolean;
  uploadFiles: IInitialsUploadLibraryFiles;
}

export function initialLibraryState(): ILibraryState {
  return {
    rubrics: [],
    folders: null,
    folderInfo: null,
    total: 0,
    page: 1,
    loader: false,
    uploadFiles: initialUploadLibraryFiles()
  };
}
