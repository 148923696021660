import { JoinProjectSearchTasks } from "containers/JoinProject/JoinProjectSearchTasks/JoinProjectSearchTasks";
import { JointProjectSearch } from "containers/JoinProject/JointProjectSearch/JointProjectSearch";
import ParticipantsButtons from "containers/JoinProject/JointProjectsTasks/components/ParticipantsButtons/ParticipantsButtons";
import { FilterTasksPriority } from "containers/JoinProject/JointProjectsTasks/components/ProjectsTasksServicePanel/components/FilterTasksPriority/FilterTasksPriority";
import TeamButtons from "containers/JoinProject/JointProjectsTasks/components/TeamButtons/TeamButtons";
import Button from "generalComponents/Button/Button";
import { FilterMarkers } from "generalComponents/FilterMarkers/FilterMarkers";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { PROJECT_PATH } from "generalComponents/variables/routing";
import { useIsSystemWatherRole } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useLocation } from "react-router-dom";
import { initialsProjectTask } from "utils/jointPtoject";

import styles from "./BacklogServicePanel.module.sass";

interface IProps {
  showAddButton?: boolean;
}

export const BacklogServicePanel = ({ showAddButton = true }: IProps) => {
  const { __ } = useLocales();
  const { onSetCreateProjectTaskModal } = useActions();
  const onAddTask = () => onSetCreateProjectTaskModal({ open: true, type: "add", task: { ...initialsProjectTask() } });

  const [show, setShow] = useState<boolean>();

  const location = useLocation();

  const { priority, search, team, participants } = useAllSelectedSearchParams();

  const isProjectBacklogPage = location.pathname.includes(PROJECT_PATH.BACKLOG);

  const isSystemWatherRole = useIsSystemWatherRole();

  return (
    <ToolsPanel size="extraLarge">
      <div className={styles.wrapper}>
        <div className={styles.tools}>
          <FilterMarkers hide={!show} depFilter={priority.selectedValue}>
            <FilterTasksPriority
              priority={priority.selectedValue}
              onSetPriority={priority.onSelectValue}
              onSetShow={setShow}
            />
          </FilterMarkers>
          <div className={styles.delimetr} />
          <TeamButtons selectedTeam={team.selectedValue} onSelectTeam={team.onSelectValue} />
          <div className={styles.btns}>
            {isProjectBacklogPage ? (
              <JointProjectSearch searchQuery={search.selectedValue} setSearchQuery={search.onSelectValue} />
            ) : (
              <JoinProjectSearchTasks />
            )}
            {showAddButton && !isSystemWatherRole && (
              <Button
                variant={ButtonVariantType.BLUE}
                onClick={onAddTask}
                size={ButtonSizeType.SMALL}
                className={styles.addBtn}
                text={`+ ${__("Задача")}`}
              />
            )}
          </div>
        </div>

        <ParticipantsButtons
          selectedParticipants={participants.selectedValue}
          onSelectParticipiant={participants.onSelectMultipleValue}
          teamId={team.selectedValue}
        />
      </div>
    </ToolsPanel>
  );
};
