import { ReactComponent as AddContactIcon } from "assets/PrivateCabinet/addContact.svg";
import { ReactComponent as CameraIcon } from "assets/PrivateCabinet/camera.svg";
import { ReactComponent as CopyLinkIcon } from "assets/PrivateCabinet/copy-link.svg";
import { ReactComponent as VideoIcon } from "assets/PrivateCabinet/film.svg";
import { ReactComponent as InfoIcon } from "assets/PrivateCabinet/info-2.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-5.svg";
import { ReactComponent as PictureIcon } from "assets/PrivateCabinet/picture-2.svg";
import classNames from "classnames";
import { CHAT_CALLROOM, CHAT_CALLROOM_ACTIONS } from "generalComponents/variables/chat";
import { MODALS } from "generalComponents/variables/global";
import { imageSrc } from "generalComponents/variables/globalVariables";
import PropTypes from "prop-types";
import React from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { setCallRoom } from "Store/actions/ChatActions";
import { onSetModals } from "Store/actions/ModalActions";
import { onSetPaint } from "Store/actions/PaintActions";
import { selectedContactProps } from "types/Chat";
import { socketProps } from "types/Socket";
import { v4 as uuidv4 } from "uuid";

import styles from "./ServePanel.module.sass";

const ServePanel = ({ selectedContact, setAction, setRightPanelContentType, socket }) => {
  const { __ } = useLocales();
  const chatTheme = useSelector((state) => state.Cabinet.chat.theme);
  const { userId, callRoom } = useSelector((state) => state.Cabinet.chat);
  const dispatch = useDispatch();
  const paint = useSelector((state) => state.Cabinet.paint);
  const printScreen = useSelector((state) => state.Cabinet.modals.printScreen);

  const startVoiceCall = () => {
    if (Number(selectedContact.room_col) > 0) {
      dispatch(
        setCallRoom({
          ...callRoom,
          state: CHAT_CALLROOM_ACTIONS.INCOMING_CALL,
          callType: CHAT_CALLROOM.VOICE_CALL,
          contacts: selectedContact.contacts,
          initialCallType: CHAT_CALLROOM.VOICE_CALL,
          action: CHAT_CALLROOM_ACTIONS.ACCEPT_CALL,
          room_id: selectedContact.room_id,
          id_group: selectedContact.id_group
        })
      );
    } else {
      dispatch(
        setCallRoom({
          state: CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL,
          callType: CHAT_CALLROOM.VIDEO_CALL,
          initialCallType: CHAT_CALLROOM.VOICE_CALL,
          contacts: selectedContact.id_group
            ? selectedContact.users.map((it) => it.id_user)
            : [selectedContact.id_real_user],
          socket,
          user_id: userId,
          icon: selectedContact.id_group ? null : selectedContact?.icon[0],
          room_id: uuidv4(),
          id_group: selectedContact.id_group
        })
      );
    }
  };

  const startVideoCall = () => {
    if (Number(selectedContact.room_col) > 0) {
      dispatch({
        ...callRoom,
        state: CHAT_CALLROOM_ACTIONS.INCOMING_CALL,
        callType: CHAT_CALLROOM.VIDEO_CALL,
        contacts: selectedContact.contacts,
        initialCallType: CHAT_CALLROOM.VIDEO_CALL,
        action: CHAT_CALLROOM_ACTIONS.ACCEPT_CALL,
        room_id: selectedContact.room_id,
        id_group: selectedContact.id_group
      });
    } else {
      dispatch(
        setCallRoom({
          state: CHAT_CALLROOM_ACTIONS.OUTGOING_VIDEO_CALL,
          callType: CHAT_CALLROOM.VIDEO_CALL,
          initialCallType: CHAT_CALLROOM.VIDEO_CALL,
          contacts: selectedContact.id_group
            ? selectedContact.users.map((it) => it.id_user)
            : [selectedContact.id_real_user],
          socket,
          user_id: userId,
          icon: selectedContact.id_group ? null : selectedContact?.icon[0],
          room_id: uuidv4(),
          id_group: selectedContact.id_group
        })
      );
    }
  };

  return (
    <div
      className={classNames({
        [styles.chatBoardHeader]: true,
        [styles.darkTheme]: chatTheme.name === "dark"
      })}
    >
      {selectedContact ? (
        <div className={styles.groupName}>
          <img
            src={
              selectedContact?.icon?.[0] ||
              `${imageSrc}assets/PrivateCabinet/${selectedContact?.isGroup ? "chatGroup" : "profile-noPhoto"}.svg`
            }
            alt="img"
            className={styles.avatar}
          />
          <div className={styles.info}>
            <div className={styles.name}>{`${selectedContact?.sname || ""} ${selectedContact?.name || ""}`}</div>
            <div className={styles.status}>{selectedContact?.status || ""}</div>
          </div>
        </div>
      ) : null}
      {selectedContact ? (
        <div className={styles.headerOptions}>
          {(selectedContact.id_real_user && selectedContact.id_real_user !== "0") || selectedContact.isGroup ? (
            <div
              onClick={() =>
                setAction(
                  selectedContact?.isGroup
                    ? { type: "addUsersToGroup" }
                    : {
                        type: "addChat",
                        chatsType: "groups",
                        initialUser: selectedContact
                      }
                )
              }
              className={styles.iconView}
              title={
                selectedContact.isGroup
                  ? __("Добавить участников в группу")
                  : __(`Создать групповой чат с ${selectedContact.name}`)
              }
            >
              <AddContactIcon title="" className={styles.addContactIcon} />
            </div>
          ) : null}
          <div className={styles.iconView} onClick={startVoiceCall}>
            <PhoneIcon />
          </div>
          <div className={styles.iconView} onClick={startVideoCall}>
            <VideoIcon />
          </div>
          <div className={styles.separating} />
          <div className={styles.iconView}>
            <CopyLinkIcon />
          </div>
          <div
            className={classNames(styles.iconView, styles.PicInPicIcon)}
            onClick={() =>
              dispatch(
                onSetPaint("mutualEdit", {
                  ...paint.mutualEdit,
                  open: true,
                  destination: "global/all"
                })
              )
            }
          >
            <PictureIcon />
            <div className={styles.line} />
            <PictureIcon />
          </div>
          {!selectedContact?.is_secret_chat ? (
            <div
              onClick={() =>
                printScreen.open ? null : dispatch(onSetModals(MODALS.PRINT_SCREEN, { ...printScreen, open: true }))
              }
              className={classNames({
                [styles.iconView]: true,
                [styles.disable]: printScreen?.open
              })}
            >
              <CameraIcon />
            </div>
          ) : (
            ""
          )}
          <div
            className={styles.iconView}
            onClick={() => setRightPanelContentType((state) => (state === "info" ? "" : "info"))}
          >
            <InfoIcon />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ServePanel;

ServePanel.propTypes = {
  selectedContact: selectedContactProps,
  socket: socketProps,
  setAction: PropTypes.func.isRequired,
  setRightPanelContentType: PropTypes.func.isRequired
};
