import { JointProjectLinksItem } from "containers/JoinProject/JointProjectLinks/JointProjectLinksIGrid/JointProjectLinksItem/JointProjectLinksItem";
import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import { useActions } from "hooks/useActions";
import { useIsFiltersMarkers } from "hooks/useIsFilters";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { LoaderTypes } from "models/generalComponents/loader";
import { IJoinProjectLink } from "models/store/joinProjects/joinProgects";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectLinksGrid.module.sass";

interface IGroupedItems {
  category: string;
  items: IJoinProjectLink[];
}

export const JointProjectLinksGrid = () => {
  const { __ } = useLocales();

  const { project } = useJoinProjectsSelectors();
  const { onFetchJointProjectLinks } = useActions();
  const [isLoading, setIsLoading] = useState(false);

  const isFiltersMarkers = useIsFiltersMarkers();

  const { emo } = useAllSelectedSearchParams();

  useEffect(() => {
    if (!project) return;
    onFetchJointProjectLinks({ id: project.id, setIsLoading });
  }, [project.id]); //eslint-disable-line

  if (!project || !project.links) {
    return null;
  }

  let filteredLinks = project.links;
  if (emo.selectedValue) {
    filteredLinks = filteredLinks.filter((item) => item.emo === emo.selectedValue);
  }

  const groupedItems: IGroupedItems[] = groupItemsByCategory(filteredLinks, project.linksCategories);

  return (
    <div className={styles.content}>
      {isLoading && (
        <Loader
          position="absolute"
          type={LoaderTypes.BOUNCING_DOTS}
          background="rgba(0, 0, 0, 0.35)"
          containerType="bounceDots"
        />
      )}
      {isFiltersMarkers && !groupedItems?.length ? (
        <Clue icon="neutral" text={__("Нет ссылок соответствующих параметрам фильтрации")} />
      ) : !isFiltersMarkers && !groupedItems?.length ? (
        <Clue icon="sad" text={__("Тут пока пусто")} />
      ) : (
        groupedItems.map((group, i) => (
          <div key={i}>
            <h2>{group.category}</h2>
            <div className={styles.itemsGrid}>
              {group.items.map((item, j) => (
                <JointProjectLinksItem key={j} item={item} />
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

const groupItemsByCategory = (items: IJoinProjectLink[], categories: any[]): IGroupedItems[] => {
  const groupedItems: { [key: string]: IJoinProjectLink[] } = {};

  items.forEach((item) => {
    const categoryObject = categories.find((category) => category.id === item.id_dep);

    if (categoryObject) {
      const categoryName = categoryObject.name;

      if (!groupedItems[categoryName]) {
        groupedItems[categoryName] = [];
      }

      groupedItems[categoryName].push(item);
    }
  });

  Object.keys(groupedItems).forEach((category) => {
    groupedItems[category].sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateA - dateB;
    });
  });

  return Object.keys(groupedItems).map((category) => ({
    category,
    items: groupedItems[category]
  }));
};
