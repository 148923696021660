import { CHAT_CALLROOM_ACTIONS } from "generalComponents/variables/chat";

import { ICallRoomConnectedUser } from "../store/chat/chat";
import { DrawParams } from "./minitoolbar";

export interface IPreviewsProps {
  file: IFileType;
  errorHandler: (it: string) => void;
}

export interface IPreviewFile {
  is_preview: number;
  ext: string;
  color: string;
  name: string;
  fid: string;
}

export interface IMediaFile extends IPreviewFile {
  mime_type: MIME_TYPES;
  preview: string;
}

export interface IOfficeFile extends IPreviewFile {
  edit_url: string;
  ext: string;
}

type IFileType = IMediaFile | IOfficeFile;

export type MIME_TYPES = string | "image/jpeg";

export interface ISocketPaintTransferData {
  action: CHAT_CALLROOM_ACTIONS.DRAW_CANVAS;
  uid: string;
  users_to: string[];
  from: ICallRoomConnectedUser;
  tool: PaintTools;
  color: string;
  lineWidth: number;
  drawCoordinates: DrawParams;
}

export enum PaintTools {
  BRUSH = "brush",
  PENCIL = "pencil",
  MARKER = "marker",
  ERASER = "eraser",
  CIRCLE = "circle",
  SQUARE = "square",
  TRIANGLE = "triangle"
}
