import { MODALS } from "generalComponents/variables/global";
import { IAccountDeletingModalState } from "models/store/Cabinet/modals/accountDeleting";
import { IAllFileViewModalModalState } from "models/store/Cabinet/modals/allFileViewModal";
import { IAvatarCropModalState } from "models/store/Cabinet/modals/avatarCropModal";
import { IChangeStatusModalState } from "models/store/Cabinet/modals/changeStatusModal";
import { IConfirmCodeModalState } from "models/store/Cabinet/modals/confirmCodeModal";
import { IContactsModalState, ISocialModalState } from "models/store/Cabinet/modals/contactsModal";
import { ICreateFileModalState } from "models/store/Cabinet/modals/createFileModal";
import { ICreateFolderModal } from "models/store/Cabinet/modals/createFolder";
import { ICreateObjectLibraryModalState } from "models/store/Cabinet/modals/createObjectLibraryModals";
import { ICreateProjectModal } from "models/store/Cabinet/modals/createProject";
import { ICreateProjectRoleModal } from "models/store/Cabinet/modals/createProjectRole";
import { ICreateProjectTaskModal } from "models/store/Cabinet/modals/createProjectTask";
import { ICreateProjectTeamModal } from "models/store/Cabinet/modals/createProjectTeam";
import { ICreateRubricLibraryModalState } from "models/store/Cabinet/modals/createRubricLibraryModals";
import { ICreateSprintModalState } from "models/store/Cabinet/modals/createSprintModal";
import { IDashboardJointProjectStatistic } from "models/store/Cabinet/modals/dashboardJointProjectStatistic";
import { IDashboardJointProjectTeam } from "models/store/Cabinet/modals/dashboardJointProjectTeams";
import { IFolderPassModal } from "models/store/Cabinet/modals/folderPass";
import { ILinksCategoryModalState } from "models/store/Cabinet/modals/linksCategoryModal";
import { ILinksModalState } from "models/store/Cabinet/modals/linksModal";
import {
  IAvatarCropModalActions,
  IConfirmCodeActions,
  IMoveElementsModalActions,
  IProfileModalActions,
  ITariffActions,
  ModalActions
} from "models/store/Cabinet/modals/modalActions";
import {
  IAccessIsLimitedModal,
  IApproveModal,
  ICalendarModals,
  IContextMenuModals,
  ICopyLinkModal,
  ICreateZipModal,
  ICustomizeFileModal,
  IEditItemsModal,
  IErrorModal,
  IFileAccessRightsModal,
  IFilePrintModal,
  IFileViewModal,
  IFolderNavigationModal,
  ILibraryModals,
  IMoveToSafeModalState,
  INextActionModal,
  IPreviewFileModal,
  IPreviewWithCommentsModal,
  IPrintScreenModal,
  IProjectModals,
  IPropertiesModal,
  ISafeModalState,
  IShareModal,
  IShareToMessengersModal,
  ISuccessModal,
  ITaskModals,
  ITopMessageModal,
  ModalState
} from "models/store/Cabinet/modals/modals";
import { IMoveElementsModal } from "models/store/Cabinet/modals/moveElements";
import { INoteEditState } from "models/store/Cabinet/modals/noteEdit";
import { INotesCategoryState } from "models/store/Cabinet/modals/notesCategory";
import { INotesCategoryFullState } from "models/store/Cabinet/modals/notesCategoryFull";
import { INotesSidebarState } from "models/store/Cabinet/modals/notesSidebar";
import { IPasswordModalState } from "models/store/Cabinet/modals/passwordModal";
import {
  IMailDashboardModal,
  IMailNewMessageModal,
  IPostboxModalState
} from "models/store/Cabinet/modals/postboxModal";
import { IProfileModalState } from "models/store/Cabinet/modals/profileModals";
import { IProjectTasksInfoModalState } from "models/store/Cabinet/modals/projectTaskInfoModal";
import { IPropertiesElementModalState } from "models/store/Cabinet/modals/propertiesElementModals";
import { IRecordModalState } from "models/store/Cabinet/modals/record";
import { IRemoveNonEmptyRubricLibraryModalState } from "models/store/Cabinet/modals/removeNonEmptyRubricLibraryModal";
import { IRestoreFileModalState } from "models/store/Cabinet/modals/restoreFileModals";
import { ISelectFilesModalState } from "models/store/Cabinet/modals/selectFileModal";
import { IStatusModalState } from "models/store/Cabinet/modals/statusModal";
import { ITagEditState } from "models/store/Cabinet/modals/tagEdit";
import { ITariffModalState } from "models/store/Cabinet/modals/tariffModal";
import { ITasksOverdueModalState } from "models/store/Cabinet/modals/taskOverdue";
import { ITasksConnectionModalState } from "models/store/Cabinet/modals/tasksConnection";
import { ITasksQueueModalState } from "models/store/Cabinet/modals/tasksQueue";
import { ITransferDutiesModalState } from "models/store/Cabinet/modals/transferDuties";
import { IUserDeleteState } from "models/store/Cabinet/modals/userDelete";

import { ModalTypes } from "../types/modalTypes";

/**
 * @deprecated use specific action instead
 */
export const onSetModals = (key: MODALS, value: ModalState): ModalActions => {
  // value type must be added to modalPayloads to store according key
  if (key === MODALS.NO_MODAL) {
    return;
  }
  return {
    type: ModalTypes.SET_MODALS,
    payload: { key, value }
  };
};

export const onSetErrorModal = (value: IErrorModal): ModalActions => {
  return {
    type: ModalTypes.SET_ERROR_MODAL,
    payload: value
  };
};

export const onSetSuccessModal = (value: ISuccessModal): ModalActions => {
  return {
    type: ModalTypes.SET_SUCCESS_MODAL,
    payload: value
  };
};

export const onSetTopMessageModal = (value: ITopMessageModal): ModalActions => {
  return {
    type: ModalTypes.SET_TOP_MESSAGE_MODAL,
    payload: value
  };
};

export const onSetLoaderModal = (value: boolean): ModalActions => {
  return {
    type: ModalTypes.SET_LOADER_MODAL,
    payload: value
  };
};

export const onSetShareModal = (value: IShareModal): ModalActions => {
  return {
    type: ModalTypes.SET_SHARE_MODAL,
    payload: value
  };
};

export const onSetPreviewWithCommentsModal = (value: IPreviewWithCommentsModal): ModalActions => {
  return {
    type: ModalTypes.SET_PREVIEW_WITH_COMMENTS_MODAL,
    payload: value
  };
};

export const onSetPrintScreenModal = (value: IPrintScreenModal): ModalActions => {
  return {
    type: ModalTypes.SET_PRINT_SCREEN_MODAL,
    payload: value
  };
};

export const onSetPreviewFileModal = (value: IPreviewFileModal): ModalActions => {
  return {
    type: ModalTypes.SET_PREVIEW_FILE_MODAL,
    payload: value
  };
};

export const onSetFileAccessRightsModal = (value: IFileAccessRightsModal): ModalActions => {
  return {
    type: ModalTypes.SET_FILE_ACCESS_RIGHTS_MODAL,
    payload: value
  };
};

export const onSetContextMenuModals = (value: IContextMenuModals): ModalActions => {
  return {
    type: ModalTypes.SET_CONTEXT_MENU_MODAL,
    payload: value
  };
};

export const onSetTaskModal = (value: ITaskModals): ModalActions => {
  return {
    type: ModalTypes.SET_TASK_MODAL,
    payload: value
  };
};

export const onSetLibraryModal = (value: ILibraryModals): ModalActions => {
  return {
    type: ModalTypes.SET_LIBRARY_MODAL,
    payload: value
  };
};

export const onSetCalendarModal = (value: ICalendarModals): ModalActions => {
  return {
    type: ModalTypes.SET_CALENDAR_MODAL,
    payload: value
  };
};

export const onSetProjectModal = (value: IProjectModals): ModalActions => {
  return {
    type: ModalTypes.SET_PROJECT_MODAL,
    payload: value
  };
};

export const onSetCreateFolderModal = (value: ICreateFolderModal): ModalActions => {
  return {
    type: ModalTypes.SET_CREATE_FOLDER_MODAL,
    payload: value
  };
};

export const onSetApproveModal = (value: IApproveModal): ModalActions => {
  return {
    type: ModalTypes.SET_APPROVE_MODAL,
    payload: value
  };
};

export const onSetAccessIsLimitedModal = (value: IAccessIsLimitedModal): ModalActions => {
  return {
    type: ModalTypes.SET_ACCESS_IS_LIMITED_MODAL,
    payload: value
  };
};

export const onSetPropertiesModal = (value: IPropertiesModal): ModalActions => {
  return {
    type: ModalTypes.SET_PROPERTIES_MODAL,
    payload: value
  };
};

export const onSetCopyLinkModal = (value: ICopyLinkModal): ModalActions => {
  return {
    type: ModalTypes.SET_COPY_LINK_MODAL,
    payload: value
  };
};

export const onSetEditItemsModal = (value: IEditItemsModal): ModalActions => {
  return {
    type: ModalTypes.SET_EDIT_ITEMS_MODAL,
    payload: value
  };
};

export const onSetCustomizeFileModal = (value: ICustomizeFileModal): ModalActions => {
  return {
    type: ModalTypes.SET_CUSTOMIZE_FILE_MODAL,
    payload: value
  };
};

export const onSetCreateZipModal = (value: ICreateZipModal): ModalActions => {
  return {
    type: ModalTypes.SET_CREATE_ZIP_MODAL,
    payload: value
  };
};

export const onDownloadLink = (link: string): ModalActions => ({
  type: ModalTypes.DOWNLOAD_LINK,
  payload: link
});

export const onFileView = (payload: IFileViewModal): ModalActions => ({
  type: ModalTypes.FILE_VIEW,
  payload
});

export const onSetFolderNavigationModal = (value: IFolderNavigationModal): ModalActions => ({
  type: ModalTypes.FOLDER_NAVIGATION_MODAL,
  payload: value
});

export const onFilePrint = (payload: IFilePrintModal): ModalActions => ({
  type: ModalTypes.FILE_PRINT,
  payload
});

export const onNextAction = (payload: INextActionModal): ModalActions => ({
  type: ModalTypes.SET_NEXT_ACTION_MODAL,
  payload
});

export const onSetShareToMessengersModal = (payload: IShareToMessengersModal): ModalActions => ({
  type: ModalTypes.SHARE_TO_MESSENGERS_MODAL,
  payload
});

export const onMailNewMessageModal = (payload: IMailNewMessageModal): ModalActions => ({
  type: ModalTypes.MAIL_NEW_MESSAGE_MODAL,
  payload
});

export const onSetSafeModal = (payload: ISafeModalState): ModalActions => ({
  type: ModalTypes.SET_SAFE_MODALS,
  payload
});

export const onSetMoveToSafeModal = (payload: IMoveToSafeModalState): ModalActions => ({
  type: ModalTypes.MOVE_TO_SAFE,
  payload
});

export const onSetPostboxModal = (payload: IPostboxModalState): ModalActions => ({
  type: ModalTypes.SET_POSTBOX_MODALS,
  payload
});

export const onSetPropertiesElementModal = (payload: IPropertiesElementModalState): ModalActions => ({
  type: ModalTypes.SET_PROPERTIES_ELEMENT_MODALS,
  payload
});

export const onSetRestoreFileModal = (payload: IRestoreFileModalState): ModalActions => ({
  type: ModalTypes.SET_RESTORE_FILE_MODALS,
  payload
});

export const onSetProfileModal = (payload: IProfileModalState): IProfileModalActions => ({
  type: ModalTypes.SET_PROFILE_MODALS,
  payload
});

export const onSetCreateObjectLibraryModal = (payload: ICreateObjectLibraryModalState): ModalActions => ({
  type: ModalTypes.SET_CREATE_OBJECT_LIBRARY_MODALS,
  payload
});

export const onSetMoveElementsModal = (payload: IMoveElementsModal): IMoveElementsModalActions => ({
  type: ModalTypes.SET_MOVE_ELEMENTS_MODAL,
  payload
});

export const onSetAvatarCropModal = (payload: IAvatarCropModalState): IAvatarCropModalActions => ({
  type: ModalTypes.ON_AVATAR_CROP,
  payload
});

export const onSetConfirmCodeModal = (payload: IConfirmCodeModalState): IConfirmCodeActions => ({
  type: ModalTypes.CONFIRM_CODE,
  payload
});

export const onSetTariffModal = (payload: ITariffModalState): ITariffActions => ({
  type: ModalTypes.TARIFF_MODAL,
  payload
});

export const onSetAllFileViewModal = (payload: IAllFileViewModalModalState): ModalActions => ({
  type: ModalTypes.SET_ALL_FILE_VIEW_MODAL,
  payload
});

export const onSetContactsModal = (payload: IContactsModalState): ModalActions => ({
  type: ModalTypes.CONTACTS_MODAL,
  payload
});

export const onSetSocialModal = (payload: ISocialModalState): ModalActions => ({
  type: ModalTypes.SOCIAL_MODAL,
  payload
});

export const onSetCreateRubricLibraryModal = (payload: ICreateRubricLibraryModalState): ModalActions => ({
  type: ModalTypes.SET_CREATE_RUBRIC_LIBRARY_MODALS,
  payload
});

export const onSetRemoveNonEmptyRubricLibraryModal = (
  payload: IRemoveNonEmptyRubricLibraryModalState
): ModalActions => ({
  type: ModalTypes.SET_REMOVE_NON_EMPTY_RUBRIC_LIBRARY_MODAL,
  payload
});

export const onSetFolderPassModal = (payload: IFolderPassModal): ModalActions => ({
  type: ModalTypes.FOLDER_PASSWORD,
  payload
});

export const onSetMailDashboardModal = (payload: IMailDashboardModal): ModalActions => ({
  type: ModalTypes.MAIL_DASHBOARD_MODAL,
  payload
});

export const onSetTransferDutiesModal = (payload: ITransferDutiesModalState): ModalActions => ({
  type: ModalTypes.TRANSFER_DUTIES,
  payload
});

export const onSetStatusModal = (payload: IStatusModalState): ModalActions => ({
  type: ModalTypes.STATUS_MODAL,
  payload
});

export const onSetCreateProjectModal = (payload: ICreateProjectModal): ModalActions => ({
  type: ModalTypes.CREATE_PROJECT,
  payload
});

export const onSetChangeStatusModal = (payload: IChangeStatusModalState): ModalActions => ({
  type: ModalTypes.CHANGE_STATUS_MODAL,
  payload
});

export const onSetCreateProjectRoleModal = (payload: ICreateProjectRoleModal): ModalActions => ({
  type: ModalTypes.CREATE_PROJECT_ROLE,
  payload
});

export const onSetCreateProjectTeamModal = (payload: ICreateProjectTeamModal): ModalActions => ({
  type: ModalTypes.CREATE_PROJECT_TEAM,
  payload
});

export const onSetAccountDeletingModal = (payload: IAccountDeletingModalState): ModalActions => ({
  type: ModalTypes.ACCOUNT_DELETING_MODAL,
  payload
});

export const onSetCreateProjectTaskModal = (payload: ICreateProjectTaskModal): ModalActions => ({
  type: ModalTypes.CREATE_PROJECT_TASK,
  payload
});

export const onSetCreateSprintModal = (payload: ICreateSprintModalState): ModalActions => ({
  type: ModalTypes.SET_CREATE_SPRINT_MODAL,
  payload
});

export const onSetLinksModal = (payload: ILinksModalState): ModalActions => ({
  type: ModalTypes.LINKS_MODAL,
  payload
});

export const onSetLinksCategoryModal = (payload: ILinksCategoryModalState): ModalActions => ({
  type: ModalTypes.LINKS_CATEGORY_MODAL,
  payload
});

export const onSetSelectFileModal = (payload: ISelectFilesModalState): ModalActions => ({
  type: ModalTypes.SELECT_FILE,
  payload
});

export const onSetCreateFileModal = (payload: ICreateFileModalState): ModalActions => ({
  type: ModalTypes.SET_CREATE_FILE_MODAL,
  payload
});

export const onSetTasksQueueModal = (payload: ITasksQueueModalState): ModalActions => ({
  type: ModalTypes.TASKS_QUEUE_MODAL,
  payload
});

export const onSetTasksConnectionModal = (payload: ITasksConnectionModalState): ModalActions => ({
  type: ModalTypes.TASKS_CONNECTION_MODAL,
  payload
});

export const onSetProjectTaskInfoModal = (payload: IProjectTasksInfoModalState): ModalActions => ({
  type: ModalTypes.PROJECT_TASK_INFO,
  payload
});

export const onSetDashboardJointProjectStatisticModal = (payload: IDashboardJointProjectStatistic): ModalActions => ({
  type: ModalTypes.DASHBOARD_JOINT_PROJECT_STATISTIC,
  payload
});

export const onSetDashboardJointProjectTeamModal = (payload: IDashboardJointProjectTeam): ModalActions => ({
  type: ModalTypes.DASHBOARD_JOINT_PROJECT_TEAM,
  payload
});

export const onSetTaskOverdueModal = (payload: ITasksOverdueModalState): ModalActions => ({
  type: ModalTypes.TASK_OVERDUE,
  payload
});

export const onSetTagEditModal = (payload: ITagEditState): ModalActions => ({
  type: ModalTypes.TAG_EDIT,
  payload
});

export const onSetUserDeleteModal = (payload: IUserDeleteState): ModalActions => ({
  type: ModalTypes.USER_DELETE,
  payload
});

export const onSetNoteEditModal = (payload: INoteEditState): ModalActions => ({
  type: ModalTypes.NOTE_EDIT,
  payload
});

export const onSetRecordModal = (payload: IRecordModalState): ModalActions => ({
  type: ModalTypes.RECORD_MODAL,
  payload
});

export const onSetNotesSidebar = (payload: INotesSidebarState): ModalActions => ({
  type: ModalTypes.NOTES_SIDEBAR,
  payload
});

export const onSetPasswordModal = (payload: IPasswordModalState): ModalActions => ({
  type: ModalTypes.PASSWORD_MODAL,
  payload
});

export const onSetNotesCategoriesModal = (payload: INotesCategoryState): ModalActions => ({
  type: ModalTypes.NOTES_CATEGORIES,
  payload
});

export const onSetNoteFullCategoryModal = (payload: INotesCategoryFullState): ModalActions => ({
  type: ModalTypes.NOTES_FULL_CATEGORY,
  payload
});
