import JointProjectsTaskCard from "containers/JoinProject/JointProjectsTasks/components/JointProjectsTaskCard/JointProjectsTaskCard";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IProjectTask, IProjectTeam } from "models/store/joinProjects/joinProgects";
import { TypeElements } from "models/typeElements/typeElements";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import { JointProjectTrashFile } from "./components/JointProjectTrashFile/JointProjectTrashFile";
import { JointProjectTrashTeam } from "./components/JointProjectTrashTeam/JointProjectTrashTeam";
import styles from "./JointProjectTrashElementsGrid.module.sass";

export const JointProjectTrashElementsGrid = () => {
  const { project } = useJoinProjectsSelectors();
  const projectTrashElements = project?.trash;

  const renderElement = (item: IProjectTask | IFile | IFolder | IProjectTeam): JSX.Element => {
    switch (item.table) {
      case TypeElements.FILES:
      case TypeElements.FOLDERS:
        return (
          <JointProjectTrashFile
            key={(item as IFile)?.fid || (item as IFolder)?.id_dir}
            item={item as IFile | IFolder}
          />
        );

      case TypeElements.TASKS: {
        const projectTask = item as unknown as IProjectTask;

        return <JointProjectsTaskCard key={projectTask.id} item={projectTask} />;
      }

      case TypeElements.TEAMS: {
        const projectTeam = item as IProjectTeam;

        return <JointProjectTrashTeam key={projectTeam.id} item={projectTeam} />;
      }

      default: {
        return null;
      }
    }
  };

  return (
    <div className={styles.content}>
      {!!projectTrashElements?.length && projectTrashElements.map((item) => renderElement(item))}
    </div>
  );
};
