import { ProjectLibraryElementsList } from "containers/JoinProject/JoinProjectLibrary/components/Library/ProjectLibraryElementsList/ProjectLibraryElementsList";
import { useActions } from "hooks/useActions";
import { useDebounce } from "hooks/useDebounce";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";

const ProjectLibrarySearchPage = () => {
  const {
    objSearchParams: { chapter, search_query, year, month }
  } = useSelectedObjSearchParams();

  const { onGetProjectLibraryElements, onResetProjectLibraryFolders } = useActions();

  const controllerSearch = new AbortController();

  const debounceCallback = useDebounce(() => onGetProjectLibraryElements(controllerSearch), 300);

  useEffect(() => {
    onResetProjectLibraryFolders();

    if (search_query) {
      debounceCallback();
    }

    return () => {
      controllerSearch.abort();
    };
  }, [chapter, search_query, year, month]); //eslint-disable-line

  useEffect(() => {
    return () => {
      onResetProjectLibraryFolders();
    };
  }, []); //eslint-disable-line

  return <ProjectLibraryElementsList />;
};

export default ProjectLibrarySearchPage;
