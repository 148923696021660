import { ReactComponent as AvatarIcon } from "assets/PrivateCabinet/avatar2.svg";
import { imageSrc } from "generalComponents/variables/globalVariables";
import PropTypes from "prop-types";
import React from "react";

import styles from "./UserAvatar.module.sass";

const UserAvatar = ({ icon, diameter }) => {
  return (
    <>
      {icon?.length > 0 ? (
        <img
          src={`${imageSrc}${icon[0]}`}
          alt="avatar"
          className={styles.image}
          style={{ width: diameter, height: diameter }}
        />
      ) : (
        <AvatarIcon className={styles.image} style={{ width: diameter, height: diameter }} />
      )}
    </>
  );
};

export default UserAvatar;
UserAvatar.defaultProps = {
  diameter: 24
};

UserAvatar.propTypes = {
  icon: PropTypes.arrayOf(PropTypes.string),
  diameter: PropTypes.number
};
