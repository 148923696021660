import { projectSrc } from "generalComponents/variables/globalVariables";
import { EMPLOYEE_RIGHTS, IUserInfo } from "models/store/user/user";
import { v4 as uuid } from "uuid";

export interface DataState {
  fname: string;
  sname: string;
  pname: string;
  tels: Record<string, string>;
  tel: string;
  emails: Record<string, string>;
  email: string;
  icon: string;
  emailError: string;
  gmt: string;
  is_gmt_auto?: boolean;
}
export type RightsState = Record<EMPLOYEE_RIGHTS, boolean>;

export enum DataFormTypes {
  CHANGE = "CHANGE",
  CHANGE_PHONE = "CHANGE_PHONE",
  ADD_PHONE = "ADD_PHONE",
  REMOVE_PHONE = "REMOVE_PHONE",
  CHANGE_EMAIL = "CHANGE_EMAIL",
  ADD_EMAIL = "ADD_EMAIL",
  REMOVE_EMAIL = "REMOVE_EMAIL",
  NEW_EMPLOYEE = "NEW_EMPLOYEE",
  CHANGE_TIMEZONE = "CHANGE_TIMEZONE",
  CHANGE_AUTO_TIMEZONE = "CHANGE_AUTO_TIMEZONE"
}

type FormAction = { type: DataFormTypes; field?: string; value?: string; employee?: DataState; boolValue?: boolean };
type FormActionRights = { type: "CHANGE"; field: EMPLOYEE_RIGHTS };

// Создаем начальное состояние и редуктор
export const INITIAL_DATA_STATE: DataState = {
  fname: "",
  sname: "",
  pname: "",
  tels: { "1": "" },
  tel: "",
  emails: { "1": "" },
  email: "",
  icon: "",
  emailError: "",
  gmt: "",
  is_gmt_auto: false
};
export const INITIAL_RIGHTS_STATE: RightsState = {
  IS_MGR_ORG: false,
  IS_MGR_EMPLOYEE: false,
  IS_MGR_FINANCE: false,
  IS_MGR_LIBRARY: false,
  IS_MGR_PROJECT: false,
  //
  IS_FILES: true,
  IS_SAFE: true,
  IS_MAIL: true,
  IS_PROJECT: true,
  IS_CHAT: true,
  IS_ARCHIVE: true,
  IS_LIBRARY: true,
  IS_JOURNAL: true
};

export const reducerData = (state: DataState, action: FormAction): DataState => {
  switch (action.type) {
    case DataFormTypes.CHANGE:
      return {
        ...state,
        [action.field]: action.value,
        emailError: action.field === "email" ? "" : action.field === "emailError" ? action.value : state.emailError
      };

    case DataFormTypes.CHANGE_PHONE:
      return {
        ...state,
        tels: { ...state.tels, [action.field]: action.value }
      };
    case DataFormTypes.ADD_PHONE: {
      const id = uuid();
      return {
        ...state,
        tels: { ...state.tels, [id]: "" }
      };
    }
    case DataFormTypes.REMOVE_PHONE: {
      const tels = { ...state.tels };
      delete tels[action.field];
      return {
        ...state,
        tels
      };
    }
    case DataFormTypes.CHANGE_EMAIL:
      return {
        ...state,
        emails: { ...state.emails, [action.field]: action.value }
      };

    case DataFormTypes.ADD_EMAIL: {
      const id = uuid();
      return {
        ...state,
        emails: { ...state.emails, [id]: "" }
      };
    }
    case DataFormTypes.REMOVE_EMAIL: {
      const emails = { ...state.emails };
      delete emails[action.field];
      return {
        ...state,
        emails
      };
    }
    case DataFormTypes.NEW_EMPLOYEE: {
      return action.employee;
    }

    case DataFormTypes.CHANGE_TIMEZONE:
      return {
        ...state,
        gmt: action.value
      };

    case DataFormTypes.CHANGE_AUTO_TIMEZONE:
      return {
        ...state,
        is_gmt_auto: action.boolValue
      };
    default:
      return state;
  }
};

export const reducerRights = (state: RightsState, action: FormActionRights): RightsState => {
  switch (action.type) {
    case "CHANGE": {
      return {
        ...state,
        [action.field]: !state[action.field]
      };
    }

    default:
      return state;
  }
};
export const editData = (employee: IUserInfo) => ({
  fname: employee.fname,
  sname: employee.sname,
  pname: employee.pname,
  tel: employee.tel ? "+" + employee.tel : "",
  email: employee.email,
  gmt: employee.gmt,
  is_gmt_auto: employee.is_gmt_auto,
  icon: employee.icon ? projectSrc + employee.icon : "",
  tels:
    employee.tels.length > 0 ? employee.tels.reduce((acc, tel, i) => ({ ...acc, [i]: "+" + tel }), {}) : { "1": "" },
  emails:
    employee.emails.length > 0 ? employee.emails.reduce((acc, email, i) => ({ ...acc, [i]: email }), {}) : { "1": "" },
  emailError: ""
});
