import { LibraryElementsList } from "containers/Library/LibraryElementsList/LibraryElementsList";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { LibraryCategoryTypes } from "generalComponents/variables/library";
import { LIBRARY_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPath } from "hooks/useCurrentPath";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLibrarySelectors } from "Store/selectors/librarySelectors";

const LibraryCategoryPage = () => {
  const { libraryDirId } = useParams();

  const isLibraryCategoriesPage = useCurrentPath(`${ROUTES.LIBRARY}/${LIBRARY_ROUTES.CATEGORIES}`);
  const isLibraryRubricsPage = useCurrentPath(`${ROUTES.LIBRARY}/${LIBRARY_ROUTES.RUBRICS}`);

  const {
    objSearchParams: { chapter, id_dep, search_query, year, month }
  } = useSelectedObjSearchParams();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { rubrics } = useLibrarySelectors();

  const { onGetLibraryElements, onResetLibraryFolders } = useActions();

  useEffect(() => {
    const controllerFolders = new AbortController();

    onResetLibraryFolders();

    if ((chapter && !search_query) || (id_dep && !search_query)) {
      onGetLibraryElements(controllerFolders);
    }

    if (!isNaN(Number(libraryDirId))) {
      onGetLibraryElements(controllerFolders, { id_dir: libraryDirId });
    }

    return () => {
      controllerFolders.abort();
    };
  }, [chapter, id_dep, search_query, year, month, libraryDirId]); //eslint-disable-line

  useEffect(() => {
    if (isLibraryCategoriesPage && !chapter) {
      const path = `${pathname}?${[SEARCH_PARAMS.CHAPTER]}=${LibraryCategoryTypes.EDUCATION}`;

      navigate(path, { state: { from: ROUTES.LIBRARY } });
    }

    if (isLibraryRubricsPage && !id_dep) {
      const path = `${pathname}?${[SEARCH_PARAMS.ID_DEP]}=${rubrics[0].id}`;

      navigate(path, { state: { from: ROUTES.LIBRARY } });
    }

    return () => {
      onResetLibraryFolders();
    };
  }, []); //eslint-disable-line

  return <LibraryElementsList />;
};

export default LibraryCategoryPage;
