import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export function useNotesSelectors() {
  const Notes = useTypeSelector((s) => s.Notes);
  const notes = Notes.notes;
  const activeNote = Notes.activeNote;
  const categories = Notes.categories;

  return {
    notes,
    activeNote,
    categories
  };
}
