import Button from "generalComponents/Button/Button";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { projectProps } from "types/Project";

import styles from "./AddImage.module.sass";

const AddImage = ({ onClose, params, callback }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const fileSelect = () => inputRef.current.click();

  const uploadImage = (event) => {
    const file = event.target.files[0] ?? null;
    if (file && file.type.substr(0, 5) === "image") {
      const objectUrl = URL.createObjectURL(file);
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.AVATAR_CROP,
          callback,
          params: { ...params, project_icon: objectUrl }
        })
      );
    }
  };
  const onChooseAvatar = () =>
    dispatch(
      onSetModals(MODALS.PROJECT, {
        type: PROJECT_MODALS.CHOOSE_AVATAR,
        callback,
        params
      })
    );

  return (
    <div className={styles.wrap}>
      <p className={styles.text}>{__("Выберите откуда Вы хотите загрузить аватар проекта")}</p>
      <div className={styles.btns}>
        <Button type="button" variant="ok" onClick={onChooseAvatar} text={__("Система 4 Hub")} full={true} />
        <Button type="button" variant="ok" onClick={fileSelect} text={__("Мой компьютер")} full={true} />
        <input
          ref={inputRef}
          className={styles.input}
          type="file"
          name={"name"}
          accept="image/*"
          onChange={uploadImage}
        />
      </div>
      <Button type="button" variant="cancel" onClick={onClose} text={__("Отмена")} />
    </div>
  );
};

export default AddImage;

AddImage.propTypes = {
  onClose: PropTypes.func,
  params: projectProps,
  callback: PropTypes.string
};
