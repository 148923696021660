import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import Empty from "containers/Dashboard/Empty/Empty";
import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import Loader from "generalComponents/Loaders/4HUB";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import LinksCategoryItem from "generalComponents/Modals/Components/JointProject/LinksCategoryModal/LinkCategoryItem/LinksCategoryItem";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { initialLinksCategoryModalState } from "models/store/Cabinet/modals/linksCategoryModal";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./LinksCategoryModal.module.sass";

const LinksCategoryModal = () => {
  const { project } = useJoinProjectsSelectors();
  const { __ } = useLocales();
  const { onSetLinksCategoryModal, onAddJointProjectLinksCategory } = useActions();
  const [showAddInput, setShowAddInput] = useState(false);
  const [name, setName] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = (): void => {
    onSetLinksCategoryModal(initialLinksCategoryModalState());
  };

  const handleConfirm = () => {
    if (!name || !project.id) return;
    const params = {
      id_project: project.id,
      name
    };
    onAddJointProjectLinksCategory({ item: params, setIsLoading });
    setShowAddInput(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const toggleAdd = () => {
    setShowAddInput(true);
    setName("");
  };

  return (
    <PopUp set={closeModal} position="top" zIndex={9}>
      <HeaderModal onClose={closeModal} title={__("Настройки категорий")} />
      <div className={styles.content}>
        <div className={styles.header}>
          {__("Название")}
          <Button
            onClick={toggleAdd}
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={`+ ${__("Добавить")}`}
          />
        </div>
        {isLoading ? (
          <Loader
            position="absolute"
            type={LoaderTypes.BOUNCING_DOTS}
            background="rgba(0, 0, 0, 0.35)"
            containerType="bounceDots"
          />
        ) : (
          <div className={styles.list}>
            {showAddInput && (
              <div className={styles.item}>
                <Input className={styles.input} value={name} onChange={handleInputChange} />

                <div className={styles.buttons}>
                  <Button
                    isSquare
                    variant={ButtonVariantType.OPACITY}
                    size={ButtonSizeType.SMALL}
                    iconL={<CloseIcon />}
                    onClick={() => setShowAddInput(false)}
                  />
                  <Button
                    isSquare
                    variant={ButtonVariantType.OPACITY}
                    size={ButtonSizeType.SMALL}
                    iconL={<DoneIcon />}
                    onClick={handleConfirm}
                  />
                </div>
              </div>
            )}
            {project?.linksCategories?.length > 0 ? (
              project.linksCategories.map((item) => <LinksCategoryItem key={item.id} item={item} />)
            ) : (
              <div className={styles.empty}>
                <Empty />
              </div>
            )}
          </div>
        )}
      </div>
    </PopUp>
  );
};

export default LinksCategoryModal;
