import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import classNames from "classnames";
import { ChangeEvent, Dispatch, FC, ReactNode, SetStateAction, useRef, useState, useTransition } from "react";
import { useLocales } from "react-localized";

import styles from "./SearchField.module.sass";

interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  placeholder: string;
  variant?: "primary" | "secondary" | "notOutline";
  children?: ReactNode;
  autoFocus?: boolean;
}

const SearchField: FC<Props> = ({ value, setValue, placeholder, variant, children, autoFocus }) => {
  const { __ } = useLocales();
  const [_, startTransition] = useTransition();
  const [isFocus, setIsFocus] = useState(false);
  const blurTimeout = useRef<NodeJS.Timeout>();

  if (!placeholder) {
    placeholder = __("Введите имя пользователя");
  }

  const onFocusHandler = () => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
    }
    setIsFocus(true);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    startTransition(() => {
      setValue(e.target.value);
    });
  };

  const onBlurHandler = () => {
    blurTimeout.current = setTimeout(() => {
      setIsFocus(false);
    }, 500);
  };

  return (
    <div className={styles.wrapper}>
      <input
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        className={classNames(styles[variant], styles.input)}
        placeholder={placeholder}
        type="text"
        onChange={onChangeHandler}
        value={value}
        autoFocus={autoFocus}
      />
      <div className={styles.icon}>
        <SearchIcon fill="#757575" />
      </div>
      {children && isFocus && <div className={classNames("scrollbar-thin-blue", styles.searchResults)}>{children}</div>}
    </div>
  );
};

export default SearchField;
