import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
// import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
// import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { ISprint } from "models/store/joinProjects/joinProgects";
import { FC, MouseEvent } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { getConvertFormatDateTime } from "utils/getConvertDateTime";

import styles from "./Options.module.sass";

interface OptionsProps {
  options?: ISprint[];
  onSelected?: (e: MouseEvent<HTMLButtonElement>) => void;
  selected?: ISelectCustom;
  handleClick: (el: ISelectCustom) => void;
  onHidden: () => void;
}

export const Options: FC<OptionsProps> = ({ onSelected, options, selected, handleClick, onHidden }) => {
  const { theme } = useUserSelectors();
  const {
    user: {
      userInfo: { lang }
    }
  } = useUserSelectors();

  const { __ } = useLocales();

  const { onSetCreateSprintModal } = useActions();

  return (
    <ul className={classNames(styles.list, `scrollbar-thin-${theme}`)}>
      {options.map((el, i) => {
        const { date } = getConvertFormatDateTime({
          dateStart: el?.date_start,
          dateEnd: el?.date_end,
          locale: lang
        });

        return (
          <li key={i} className={styles.list__item}>
            {el.name && (
              <button
                type="button"
                onClick={(e) => {
                  onSelected && onSelected(e);
                  handleClick(el);
                }}
                className={classNames(styles.list__btn, { [styles.active]: selected.value === el.id })}
                value={el.id}
              >
                <span className={styles.list__btnName}>{el.name}</span>
                <span className={styles.list__btnDate}>{date}</span>
              </button>
            )}
            <Button
              variant={ButtonVariantType.OPACITY}
              size={ButtonSizeType.MEDIUM}
              isSquare
              iconL={<EditIcon />}
              onClick={() => {
                onHidden();
                onSetCreateSprintModal({
                  open: true,
                  titleHead: __("Редактировать"),
                  btnLabel: __("Сохранить"),
                  sprint: el as ISprint
                });
              }}
              className={styles.btnEdit}
            />
          </li>
        );
      })}
    </ul>
  );
};
