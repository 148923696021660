import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export const useLibrarySelectors = () => {
  const Library = useTypeSelector((s) => s.Library);
  const rubrics = Library.rubrics;
  const page = Library.page;
  const total = Library.total;
  const foldersLibrary = Library.folders;
  const loader = Library.loader;
  const uploadFiles = Library.uploadFiles;
  const folderInfo = Library.folderInfo;

  return {
    rubrics,
    page,
    total,
    foldersLibrary,
    folderInfo,
    loader,
    uploadFiles
  };
};
