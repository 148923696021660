import { ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import styles from "./DoughnutChart.module.sass";

interface DoughnutChartProps {
  color: string;
  name: string;
  count: number;
  total: number;
  size: number;
}

const options: ChartOptions<"doughnut"> = {
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      display: false
    },
    tooltip: {
      enabled: false
    }
  }
};

const DoughnutChart = ({ color, name, count = 0, total = 0, size }: DoughnutChartProps) => {
  const data = {
    datasets: [
      {
        data: [count, total || 1],
        backgroundColor: [color, "#E7EBEA"],
        borderWidth: 0,
        cutout: size * 0.4
      }
    ]
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span style={{ background: color }} />
        {name}
      </div>
      <div className={styles.chartWrapper} style={{ width: size, height: size }}>
        <Doughnut data={data} options={options} />
        <div className={styles.percentage} style={{ color }}>
          {count === 0 ? "0" : total !== 0 && `${((count / total) * 100).toFixed(1)}%`}
        </div>
      </div>
      <div className={styles.subtitle}>{count}</div>
    </div>
  );
};

export default DoughnutChart;
