import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export function useUserSelectors() {
  const user = useTypeSelector((s) => s.user);
  const uid = user.uid;
  const userInfo = user.userInfo;
  const theme = userInfo.theme;
  const trash_minutes_del = userInfo.trash_minutes_del;
  const employees = user.employees;
  const users_storage = user.users_storage;
  const contacts = user.contacts;
  const rights = userInfo.rights;
  const tags = user.tags;

  return {
    user,
    uid,
    userInfo,
    theme,
    trash_minutes_del,
    employees,
    users_storage,
    contacts,
    rights,
    tags
  };
}
