import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { getUser } from "../../helpers";
import UserBlock from "../UserBlock/UserBlock";
import UserInitials from "../UserInitials/UserInitials";
import styles from "./Executors.module.sass";

const Executors = ({ executors }) => {
  const project = useSelector((s) => s.Projects.project);

  const oneExecutor = () => {
    return executors.map((item) =>
      getUser(project.users, item) ? <UserBlock key={item} user={getUser(project.users, item)} variant="light" /> : null
    );
  };

  const executorsList = () => {
    return executors.map((item) => <UserInitials key={item} id={item} />);
  };

  return <div className={styles.executors}>{executors?.length === 1 ? oneExecutor() : executorsList()}</div>;
};

export default Executors;

Executors.propTypes = {
  executors: PropTypes.arrayOf(PropTypes.string)
};
