import { INoteCategory } from "models/store/notes/notes";

export interface INotesCategoryFullState {
  open: boolean;
  category: INoteCategory;
}

export function initialNotesCategoryFullState(): INotesCategoryFullState {
  return { open: false, category: undefined };
}
