import { ReactComponent as CalendarIcon } from "assets/PrivateCabinet/calendar-6.svg";
import { ReactComponent as FilterIcon } from "assets/PrivateCabinet/filter.svg";
import { ReactComponent as FireIcon } from "assets/PrivateCabinet/fire.svg";
import { ReactComponent as SearchIcon } from "assets/PrivateCabinet/search-4.svg";
import classNames from "classnames";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { useFiltredTask, useGetStatus } from "generalComponents/Services/projectServices";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import PropTypes from "prop-types";
import React, { Fragment, useMemo, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { ProjectTypes } from "Store/types/projectTypes";

import TaskNum from "../TaskNum/TaskNum";
import styles from "./TaskFilter.module.sass";

const TaskFilter = ({ variant }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const tasks = useFiltredTask();
  const status = useGetStatus();
  const priority = useSelector((s) => s.Projects.selectPriority);
  const urgency = useSelector((s) => s.Projects.selectUrgency);
  const ref = useRef(null);
  useOutsideClick(ref, () => setOpen(false));

  const searchTasks = useMemo(() => {
    return value ? tasks.filter((t) => t.name.toLowerCase().includes(value.toLowerCase())) : [];
  }, [tasks, value]);

  const handleClearSearch = (e) => {
    e.stopPropagation();
    setIsSearch(false);
    setValue("");
  };
  const handleCalendar = () => {
    dispatch({ type: ProjectTypes.SELECT_PRIORITY, payload: true });
  };

  const handleFire = () => {
    dispatch({ type: ProjectTypes.SELECT_URGENCY, payload: true });
  };
  const handleClearCalendar = (e) => {
    e.stopPropagation();
    dispatch({ type: ProjectTypes.SELECT_PRIORITY, payload: false });
  };

  const handleClearFire = (e) => {
    e.stopPropagation();
    dispatch({ type: ProjectTypes.SELECT_URGENCY, payload: false });
  };
  const openTask = (task) => dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.OPEN_TASK, params: task }));

  const hightlight = (str) => {
    if (!value) return str;
    const regexp = new RegExp(value, "ig");
    const matchValue = str.match(regexp);

    if (matchValue) {
      return str.split(regexp).map((s, index, array) => {
        if (index < array.length - 1) {
          const c = matchValue.shift();
          return (
            <Fragment key={index}>
              {s}
              <span className={styles.hightlight}>{c}</span>
            </Fragment>
          );
        }
        return s;
      });
    }
    return str;
  };

  const renderTask = (task) => {
    const color = status(task)?.color;
    return (
      <div key={task.id} className={styles.task} onClick={() => openTask(task)}>
        <TaskNum num={task.num_epic} color={color?.dark} />
        <div>{hightlight(task.name)}</div>
      </div>
    );
  };

  return (
    <div className={styles.container} ref={ref}>
      <ButtonIcon
        handleClick={() => setOpen((open) => !open)}
        icon={<FilterIcon />}
        isActive={priority || urgency}
        variant="grey"
      />

      {open && (
        <div className={classNames(styles.wrap, { [styles.left]: variant === "left" })}>
          <div className={styles.filters}>
            <div className={styles.row} onClick={handleCalendar}>
              <CalendarIcon className={styles.calendar} />
              <span>{__("Завершающие задачи")}</span>
              {priority && (
                <div className={styles.clear} onClick={handleClearCalendar}>
                  <span className={styles.span} />
                </div>
              )}
            </div>
            <div className={styles.row} onClick={handleFire}>
              <FireIcon />
              <span>{__("Срочные задачи")}</span>
              {urgency && (
                <div className={styles.clear} onClick={handleClearFire}>
                  <span className={styles.span} />
                </div>
              )}
            </div>
            <div
              className={classNames(styles.inputBox, { [styles.border]: isSearch })}
              onClick={() => setIsSearch(true)}
            >
              <SearchIcon />
              <input
                className={styles.input}
                value={isSearch ? value : __("Поиск задачи по словам")}
                onChange={(e) => setValue(e.target.value)}
                type="text"
                readOnly={!isSearch}
              />
              {value && (
                <button className={styles.clear} type="button" onClick={handleClearSearch}>
                  <span className={styles.span} />
                </button>
              )}
            </div>
            <div className={styles.tasks}>{searchTasks.map((t) => renderTask(t))}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskFilter;

TaskFilter.propTypes = {
  variant: PropTypes.string
};
