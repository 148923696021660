import { TypeElements } from "models/typeElements/typeElements";
import { ProjectTypes } from "Store/types/projectTypes";

import { IFile } from "../files/files";
import { IFolder } from "../folders/foldersStore";

export interface IRoleProject {
  id: string;
  id_user: string;
  name: string;
}

export interface IUserProject {
  id_user: string;
  sname: string;
  name: string;
  email: string | string[];
  icon: string[];
  is_read: string;
  id_role: string;
}

export interface ICategoryColor {
  dark: string;
  light: string;
  line: string;
  secondary: string;
  name: string;
  color?: string;
}

export interface ICategoryProject {
  id: string;
  id_user: string;
  name: string;
  color: ICategoryColor;
}
export interface ICommentTask {
  id: string;
  id_task: string;
  id_user: string;
  text: string;
}

export interface IHistoryTask {
  date: string;
  id_category_new: string;
  id_category_old: string;
  id_datelong_old: string;
  id_datelong_new: string;
  id_user: string;
  ut: number;
}

export interface ITaskProject {
  id: string;
  name: string;
  id_user: string;
  ut: string;
  date_start: string;
  date_long: string;
  date_end: string;
  tags: string;
  prim: string;
  id_emo: string;
  id_fig: string;
  id_act: string;
  id_executor: string[];
  id_category: string;
  id_parent: string;
  time_start: string;
  comments: ICommentTask[];
  author_info: IUserProject;
  author: string;
  sort: string;
  link: string;
  del_date: string;
  fids: string[];
  is_archive: string;
  is_epic: number;
  id_epic: string;
  num_epic: string;
  id_user_move: string;
  move_history: IHistoryTask[];
  files_col: number;
  is_backlog: number;
  id_project: string;
  is_del: string;
}

export interface IProject {
  id: string;
  id_user: string;
  name: string;
  tags: string;
  id_emo: string;
  id_fig: string;
  icon: string;
  project_icon: string;
  link: string;
  is_my: number;
  is_read: string;
  show_message: string;
  roles: IRoleProject[];
  users: IUserProject[];
  tasks_category_sort: string[];
  tasks_category: ICategoryProject[];
  tasks: ITaskProject[];
  is_del?: number;
  is_archive?: number;
  table?: TypeElements;
  ctime?: string;
  cdate?: string;
}

export interface ILinkProject {
  name: string;
  link: string;
  tags: string;
  emo: string;
  color: string;
  id: string;
  icon?: string;
}

export interface IDocData {
  id: string;
  section: string;
  content: string;
  tooltip?: string;
}

export interface INumberedData extends IDocData {
  data: IDocData[];
}

export interface IDocsProject {
  id: string;
  name: string;
  is_template: number;
  data: IDocData[];
  inputs: IDocData[];
  numberedData: INumberedData[];
  footer: number; //TODO -mk- change to boolean or string when add all default templates
  tooltip?: string;
}

export interface IProjectsState {
  selectRole: string;
  selectUser: string;
  selectUrgency: boolean;
  selectPriority: boolean;
  project: IProject;
  projectsList: IProject[];
  tasksTrash: ITaskProject[];
  links: ILinkProject[];
  docs: IDocsProject[];
  foldersTrash: IFolder[];
  filesTrash: IFile[];
}

export function initialProjectsState(): IProjectsState {
  return {
    projectsList: [],
    project: {
      id: "",
      id_user: "",
      name: "",
      tags: "",
      id_emo: "",
      id_fig: "",
      icon: "",
      project_icon: "",
      link: "",
      is_my: 0,
      is_read: "",
      show_message: "",
      roles: [],
      users: [],
      tasks_category_sort: [],
      tasks_category: [],
      tasks: []
    },
    selectUser: "0",
    selectRole: "0",
    selectPriority: false,
    selectUrgency: false,
    tasksTrash: [],
    foldersTrash: [],
    filesTrash: [],
    links: [],
    docs: []
  };
}

// actions
export interface INullifyProject {
  type: ProjectTypes.NULLIFY_PROJECT;
}

export interface IGetProjects {
  type: ProjectTypes.GET_PROJECTS;
  payload: IProject[];
}

export interface IAddProject {
  type: ProjectTypes.ADD_PROJECT;
  payload: IProject;
}

export interface IDelProject {
  type: ProjectTypes.DELETE_PROJECT;
  payload: string;
}

export interface ICurrentProject {
  type: ProjectTypes.CURRENT_PROJECT;
  payload: IProject;
}

export interface IEditProject {
  type: ProjectTypes.EDIT_PROJECT;
  payload: IProject;
}

export interface IAddUser {
  type: ProjectTypes.ADD_USER;
  payload: IUserProject[];
}

export interface IDelUser {
  type: ProjectTypes.DELETE_USER;
  payload: string;
}

export interface IEditUser {
  type: ProjectTypes.EDIT_USER;
  payload: IUserProject[];
}

export interface IUpdateTasks {
  type: ProjectTypes.UPDATE_TASKS;
  payload: ITaskProject[];
}

export interface IAddCategory {
  type: ProjectTypes.ADD_CATEGORY;
  payload: ICategoryProject;
}

export interface IEditCategory {
  type: ProjectTypes.EDIT_CATEGORY;
  payload: ICategoryProject;
}

export interface IDelCategory {
  type: ProjectTypes.DELETE_CATEGORY;
  payload: string;
}

export interface ISelectRole {
  type: ProjectTypes.SELECT_ROLE;
  payload: string;
}
export interface ISelectUser {
  type: ProjectTypes.SELECT_USER;
  payload: string;
}

export interface ICategoryMove {
  type: ProjectTypes.MOVE_CATEGORY;
  payload: ICategoryProject[];
}

export interface IFetchTasksTrash {
  type: ProjectTypes.FETCH_TASKS_TRASH;
  payload: ITaskProject[];
}

export interface IRestoreTask {
  type: ProjectTypes.RESTORE_TASK;
  payload: { project_tasks: ITaskProject[]; restore_tasks: string[] };
}

export interface IDelTask {
  type: ProjectTypes.DELETE_PROJECT_TASK;
  payload: string[];
}

export interface IClearTrash {
  type: ProjectTypes.CLEAR_TRASH;
}

export interface ISelectUrgency {
  type: ProjectTypes.SELECT_URGENCY;
  payload: boolean;
}

export interface ISelectPriority {
  type: ProjectTypes.SELECT_PRIORITY;
  payload: boolean;
}

export interface IFetchLinks {
  type: ProjectTypes.GET_LINKS;
  payload: ILinkProject[];
}

export interface IFetchDocs {
  type: ProjectTypes.FETCH_DOCS;
  payload: IDocsProject[];
}

export interface IFetchFoldersTrash {
  type: ProjectTypes.FETCH_FOLDERS_TRASH;
  payload: IFolder[];
}

export interface IFetchFilesTrash {
  type: ProjectTypes.FETCH_FILES_TRASH;
  payload: IFile[];
}

export type ProjectAction =
  | INullifyProject
  | IGetProjects
  | IAddProject
  | IDelProject
  | ICurrentProject
  | IEditProject
  | IAddUser
  | IDelUser
  | IEditUser
  | IUpdateTasks
  | IAddCategory
  | IEditCategory
  | IDelCategory
  | ISelectRole
  | ISelectUser
  | ICategoryMove
  | IFetchTasksTrash
  | IRestoreTask
  | IDelTask
  | IClearTrash
  | ISelectUrgency
  | ISelectPriority
  | IFetchLinks
  | IFetchDocs
  | IFetchFoldersTrash
  | IFetchFilesTrash;
