import classNames from "classnames";
import { ITagProps } from "models/generalComponents/elements";
import React from "react";

import styles from "./Tag.module.sass";

const Tag: React.FC<ITagProps> = ({ variant, maxWidth, lineHeight, children }): JSX.Element => {
  return (
    <div className={classNames(styles.miniTag, styles[variant])} style={{ maxWidth, lineHeight: `${lineHeight}px` }}>
      {children}
    </div>
  );
};

export default Tag;
