import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { ChangeEvent, useState } from "react";

export const useInputValue = (defaultValue?: string) => {
  const [value, setValue] = useState<string>(defaultValue ?? NO_VALUE);
  const [error, setError] = useState<string>(NO_VALUE);

  const onChange = (event: ChangeEvent<HTMLInputElement> | string): void => {
    if (error) setError(NO_VALUE);
    if (typeof event === "string") {
      setValue(event);
      return;
    }
    const { value } = (event as ChangeEvent<HTMLInputElement>).currentTarget;
    setValue(value);
  };
  const onError = (val: string): void => {
    setError(val);
  };
  return {
    value,
    onChange,
    error,
    onError
  };
};
