import { useTypesMeeting, useUrgencyTask } from "collections/tasks";
import Button from "generalComponents/Button/Button";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import CombinedElements from "generalComponents/Elements/CombinedElements";
import Input from "generalComponents/Input/Input";
import SelectChosen from "generalComponents/SelectChosen/SelectChosen";
import TextEditorNote from "generalComponents/TextEditorNote/TextEditorNote";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { TASK_MODALS, TASK_TYPES, URGENCY_TYPES } from "generalComponents/variables/tasks";
import { useDepName } from "hooks/tasksHooks";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { IAddTaskProps } from "models/store/Cabinet/modals/taskModals";
import { TASK_DEP } from "models/store/tasks/tasks";
import { IAddTaskPayload, IEditTaskPayload } from "models/store/tasks/tasksPayloads";
import { ChangeEvent, FC, useState } from "react";
import { useLocales } from "react-localized";
import { dateISO } from "utils/dateToISO";

import styles from "./EditTask.module.sass";

const EditTask: FC<IAddTaskProps> = ({ type, params, closeModal, setIsClose }): JSX.Element => {
  const { __ } = useLocales();
  const urgency = useUrgencyTask();
  const meetingTypes = useTypesMeeting();
  const { onAddNewTask, onEditTask } = useActions();
  const departmentName = useDepName();
  const [dep, setDep] = useState<TASK_DEP>(params.id_dep || TASK_DEP.NO_DEP);
  const [name, setName] = useState<string>(params.name || "");
  const [prim, setPrim] = useState<string>(params.prim || "");
  const [tags, setTags] = useState<string[]>(params.tags ? params.tags : []);
  const [color, setColor] = useState<string>(params.color);
  const [emo, setEmo] = useState<string>(params.emo ? params.emo : "");
  const [emails, setEmeils] = useState<string>(params.emails || "");
  const [act, setAct] = useState<URGENCY_TYPES>(params.id_act || URGENCY_TYPES.PLANNED);
  const [typeTask, setTypeTask] = useState<TASK_TYPES>(params.id_type || TASK_TYPES.OFFLINE_MEETING);
  const [dateStart, setDateStart] = useState<Date>(params.date_start ? new Date(params.date_start) : null);
  const [dateEnd, setDateEnd] = useState<Date>(params.date_end ? new Date(params.date_end) : null);

  const isTask: boolean = params.id_type === TASK_TYPES.TASK;
  const isMeeting: boolean =
    params.id_type === TASK_TYPES.ONLINE_MEETING || params.id_type === TASK_TYPES.OFFLINE_MEETING;
  const isMail = params.id_type === TASK_TYPES.MAILS;
  const isReminder = params.id_type === TASK_TYPES.REMINDER;
  const isAdd =
    type === TASK_MODALS.ADD_TASK ||
    type === TASK_MODALS.ADD_MEETING ||
    type === TASK_MODALS.ADD_CALL ||
    type === TASK_MODALS.ADD_LETTER ||
    type === TASK_MODALS.ADD_REMINDER;

  const onChangeDep = (value: TASK_DEP) => setDep(value);
  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);
  const onChangEmails = (e: ChangeEvent<HTMLInputElement>) => setEmeils(e.currentTarget.value);
  const onChangePrim = (value: string) => setPrim(value);
  const onSelectDateStart = (date: Date): void => setDateStart(date);
  const onSelectDateEnd = (date: Date): void => setDateEnd(date);

  const getSelectUrgency = (id: string): string => urgency.find((item) => item.id === id)?.name;
  const getSelectType = (id: string): string => meetingTypes.find((item) => item.id === id)?.name;

  const onSubmit = (): void => {
    if (isAdd) {
      const payload: IAddTaskPayload = {
        id_type: typeTask,
        name: name,
        id_dep: dep,
        id_act: act,
        id_status: params.id_status,
        date_start: dateISO(dateStart),
        date_end: dateEnd ? dateISO(dateEnd) : "",
        prim: prim,
        tags,
        color,
        emo,
        emails: emails
      };
      onAddNewTask(payload);
    } else {
      const payload: IEditTaskPayload = {
        id_task: params.id,
        name: name !== params.name ? name : undefined,
        id_dep: dep !== params.id_dep ? dep : undefined,
        id_act: act !== params.id_act ? act : undefined,
        id_type: typeTask !== params.id_type ? typeTask : undefined,
        date_start: dateISO(dateStart) !== params.date_start ? dateISO(dateStart) : undefined,
        date_end: dateEnd && dateISO(dateEnd) !== params.date_end ? dateISO(dateEnd) : undefined,
        prim: prim !== params.prim ? prim : undefined,
        tags: tags !== params.tags ? tags : [],
        color,
        emo: emo !== params.emo ? emo : undefined,
        emails: emails !== params.emails ? emails : undefined
      };
      onEditTask(payload, EVENT_TYPE.EDIT);
    }
  };

  const getPlaceholderName = (): string => {
    switch (params.id_type) {
      case TASK_TYPES.TASK:
        return __("Имя задачи");

      case TASK_TYPES.OFFLINE_MEETING:
      case TASK_TYPES.ONLINE_MEETING:
        return __("Имя встречи");
      case TASK_TYPES.CALLS:
        return __("Имя звонка");
      case TASK_TYPES.MAILS:
        return __("Тема письма");
      case TASK_TYPES.REMINDER:
        return __("Название");

      default:
        break;
    }
  };
  const getPlaceholderCalendar = (): string => {
    switch (params.id_type) {
      case TASK_TYPES.TASK:
        return __("Дата  с ");
      case TASK_TYPES.OFFLINE_MEETING:
      case TASK_TYPES.ONLINE_MEETING:
        return __("Укажите дату и время встречи");
      case TASK_TYPES.CALLS:
        return __("Укажите дату и время звонка");
      case TASK_TYPES.MAILS:
        return __("Укажите дату и время отправки");
      case TASK_TYPES.REMINDER:
        return __("Укажите дату и время");

      default:
        break;
    }
  };

  const getDetails = () => {
    switch (params.id_type) {
      case TASK_TYPES.TASK:
        return getSelectUrgency(act);

      case TASK_TYPES.OFFLINE_MEETING:
      case TASK_TYPES.ONLINE_MEETING:
        return getSelectType(typeTask);

      default:
        break;
    }
  };
  const depName = (value: TASK_DEP): string => {
    switch (value) {
      case TASK_DEP.WORK:
        return __("Рабочие задачи");
      case TASK_DEP.PERSONAL:
        return __("Личные задачи");

      default:
        return "";
    }
  };

  return (
    <div className={styles.editTaskWrap}>
      {!isReminder && (
        <SelectChosen placeholder={__("Имя категории")} value={depName(dep)}>
          <ul className={styles.eventsList}>
            {Object.values(TASK_DEP)
              .slice(0, 2)
              .map((dep) => (
                <li key={dep} onClick={() => onChangeDep(dep)} className={styles.eventItem}>
                  <p className={styles.eventName}>{departmentName(dep)}</p>
                </li>
              ))}
          </ul>
        </SelectChosen>
      )}
      <Input value={name} onChange={onChangeName} placeholder={getPlaceholderName()} />
      {isMail && <Input value={emails} onChange={onChangEmails} placeholder={__("Получатель")} />}

      {(isTask || isMeeting) && (
        <SelectChosen placeholder={__("Имя категории")} value={getDetails()}>
          <ul className={styles.eventsList}>
            {isTask && (
              <>
                {urgency.map((item) => (
                  <li key={item.id} onClick={() => setAct(item?.id)} className={styles.eventItem}>
                    <p className={styles.eventName}>{item.name}</p>
                  </li>
                ))}
              </>
            )}
            {isMeeting && (
              <>
                {meetingTypes.map((item) => (
                  <li key={item.id} onClick={() => setTypeTask(item?.id)} className={styles.eventItem}>
                    <p className={styles.eventName}>{item.name}</p>
                  </li>
                ))}
              </>
            )}
          </ul>
        </SelectChosen>
      )}
      <div className={styles.dates}>
        <InputDate
          startDate={dateStart}
          setStartDate={onSelectDateStart}
          placeholder={getPlaceholderCalendar()}
          maxDate={dateEnd}
          withTime
          isBtnTooday
          setIsClose={setIsClose}
        />
        {isTask && (
          <InputDate
            startDate={dateEnd}
            setStartDate={onSelectDateEnd}
            placeholder={__("Дата по")}
            minDate={dateStart}
            withTime
            isBtnClear
            setIsClose={setIsClose}
            isClearable
          />
        )}
      </div>
      <TextEditorNote
        content={prim}
        setContent={onChangePrim}
        placeholder={isMail ? __("Текст письма ") : __("Описание события")}
      />
      <CombinedElements
        tags={tags}
        onSelectTags={setTags}
        color={color}
        setColor={setColor}
        emo={emo}
        setEmo={setEmo}
      />
      <div className={styles.btns}>
        <Button type="button" variant={ButtonVariantType.CANCEL} text={__("Отмена")} onClick={closeModal} />
        <Button
          type="button"
          variant={ButtonVariantType.OK}
          text={type === TASK_MODALS.ADD_TASK ? __("Добавить") : __("Сохранить")}
          onClick={onSubmit}
          disabled={!name || !dateStart || (!dep && !isReminder)}
        />
      </div>
    </div>
  );
};

export default EditTask;
