import { ReactComponent as ArrowNext } from "assets/PrivateCabinet/carousel/arrow-next.svg";
import { ReactComponent as ArrowPrev } from "assets/PrivateCabinet/carousel/arrow-prev.svg";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Children, cloneElement, useEffect, useState } from "react";

import styles from "./Carousel.module.sass";

const WIDTH_BLOCK = 62;

function CarouselWrapper({ children }) {
  const [file, setFile] = useState([]);
  const [offset, setOffset] = useState(0);

  const handleLeftArrow = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset + WIDTH_BLOCK;
      return newOffset;
    });
  };

  const handleRightArrow = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset - WIDTH_BLOCK;
      return newOffset;
    });
  };

  useEffect(() => {
    setFile(
      Children.map(children, (child) => {
        return cloneElement(child, {
          style: {
            minWidth: "47px",
            maxWidth: "47px",
            minHeight: "47px",
            maxHeight: "47px"
          }
        });
      })
    );
  }, [children]);

  return (
    <div className={styles.carousel}>
      <div className={styles.wrapper}>
        <div
          className={styles.blocks}
          style={{
            transform: `translateX(${offset}px)`
          }}
        >
          {file}
        </div>
        <div
          className={classNames(styles.arrow, styles.arrowPrev, {
            [styles.isActive]: offset <= -1
          })}
          onClick={handleLeftArrow}
        >
          <ArrowPrev />
        </div>
        <div
          className={classNames(styles.arrow, styles.arrowNext, {
            [styles.isActive]: offset >= -((children.length - 8) * WIDTH_BLOCK)
          })}
          onClick={handleRightArrow}
        >
          <ArrowNext />
        </div>
      </div>
    </div>
  );
}

export default CarouselWrapper;

CarouselWrapper.propTypes = {
  children: PropTypes.node
};
