import { FC } from "react";

import { PanelEmploees } from "./components/PanelEmploees/PanelEmploees";
import { PanelStorageDiagram } from "./components/PanelStorageDiagram/PanelStorageDiagram";
import { PanelTools } from "./components/PanelTools/PanelTools";
import styles from "./PanelsMemory.module.sass";

export const PanelsMemory: FC = () => (
  <div className={styles.box}>
    <PanelStorageDiagram />
    <PanelTools />
    <PanelEmploees />
  </div>
);
