import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { initialProfileModalState, PROFILE_MODALS } from "models/store/Cabinet/modals/profileModals";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import AddEmployee from "./AddEmployee/AddEmployee";
import ChangePassword from "./ChangePassword/ChangePassword";
import ChangeStatus from "./ChangeStatus/ChangeStatus";
import EmployeeSignin from "./EmployeeSignin/EmployeeSignin";

const ProfileModals = () => {
  const { onSetProfileModal } = useActions();
  const {
    profileModals: { type, employee }
  } = useGlobalModalsSelectors();

  const closeModal = (): void => {
    onSetProfileModal(initialProfileModalState());
  };

  return (
    <PopUp
      set={closeModal}
      position={type === PROFILE_MODALS.CHANGE_STATUS || type === PROFILE_MODALS.EMPLOYEE_SIGNIN ? "center" : "top"}
      top={type === PROFILE_MODALS.CHANGE_PASSWORD ? 250 : 50}
    >
      {(type === PROFILE_MODALS.ADD_EMPLOYEE || type === PROFILE_MODALS.EDIT_EMPLOYEE) && (
        <AddEmployee closeModal={closeModal} type={type} employee={employee} />
      )}
      {type === PROFILE_MODALS.CHANGE_PASSWORD && <ChangePassword closeModal={closeModal} />}
      {type === PROFILE_MODALS.CHANGE_STATUS && <ChangeStatus closeModal={closeModal} employee={employee} />}
      {type === PROFILE_MODALS.EMPLOYEE_SIGNIN && <EmployeeSignin closeModal={closeModal} employee={employee} />}
    </PopUp>
  );
};

export default ProfileModals;
