import { SORTING_TYPE } from "generalComponents/variables/global";

export interface ISortCriteriaState {
  sort: SORTING_TYPE;
  group: number;
  sort_reverse: string;
  sort_reset?: boolean;
}

export function initialSortCriterionState(): ISortCriteriaState {
  return {
    sort: SORTING_TYPE.DATE_CREATED,
    group: 1,
    sort_reverse: "1",
    sort_reset: true
  };
}
