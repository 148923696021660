import { initialTrashElementsState, ITrashElementsState } from "models/store/trash/trashElements";
import { TrashElementsActions } from "models/store/trash/trashElementssActions";
import { TrashElementsTypes } from "Store/types/trashedElemetsTypes";
import { removesElement } from "Store/utils/removesElement";

const INITIAL_STATE: ITrashElementsState = initialTrashElementsState();

export const TrashReducer = (state = INITIAL_STATE, action: TrashElementsActions): ITrashElementsState => {
  switch (action.type) {
    case TrashElementsTypes.SET_TRASH_ELEMENTS:
      if (state.elements) {
        return {
          ...state,
          elements: [...state.elements, ...action.payload]
        };
      } else {
        return {
          ...state,
          elements: action.payload
        };
      }

    case TrashElementsTypes.SET_TOTAL_TRASH_ELEMENTS:
      return { ...state, total: action.payload };

    case TrashElementsTypes.SET_TRASH_PAGE:
      return { ...state, page: action.payload };

    case TrashElementsTypes.RESET_TRASH_ELEMENTS_LIST:
      return { ...state, page: 1, elements: null, total: 0 };

    case TrashElementsTypes.TRASH_ELEMENTS_LOADER:
      return { ...state, loader: action.payload };

    case TrashElementsTypes.SET_DEL_TRASH_ELEMENT: {
      const updateElements = removesElement(action.payload, state.elements);

      return {
        ...state,
        elements: updateElements,
        total: updateElements?.length
      };
    }

    case TrashElementsTypes.SET_EMPTY_TRASH_ELEMENTS:
      return { ...state, page: 1, elements: [], total: 0 };

    case TrashElementsTypes.NULLIFY_TRASH_ELEMENTS: {
      return {
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
};
