import iconDefault from "assets/images/library/rubrics/default.png";
import iconDefault2x from "assets/images/library/rubrics/default@2x.png";
import { ReactComponent as ArrowLeft } from "assets/PrivateCabinet/mail/arrow-right.svg";
import { useContentRubrics } from "collections/library";
import { Image } from "generalComponents/Image/Image";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { LibraryTypesRubrics } from "generalComponents/variables/library";
import { ROUTES } from "generalComponents/variables/routing";
import { ILibraryRubric } from "models/store/library/library";
import { FC } from "react";
import { Link } from "react-router-dom";

import styles from "./LibraryRubric.module.sass";

interface ILibraryCategoryProps {
  rubric: ILibraryRubric;
}

export const LibraryRubric: FC<ILibraryCategoryProps> = ({ rubric }): JSX.Element => {
  const rubrics = useContentRubrics();

  const rubricName = rubric.name as Exclude<LibraryTypesRubrics, LibraryTypesRubrics.ALL_RUBRICS>;
  const rubricDefault = rubric.is_system === "1";

  return (
    <Link to={`/${ROUTES.LIBRARY}/rubrics?${SEARCH_PARAMS.ID_DEP}=${rubric.id}`} className={styles.item}>
      <div className={styles.item__imgBox}>
        <Image
          src={
            rubricDefault ? rubrics[rubricName]?.image?.src : !rubric.icon ? iconDefault : `${projectSrc}${rubric.icon}`
          }
          srcSet={rubricDefault ? rubrics[rubricName]?.image?.srcSet : !rubric.icon ? `${iconDefault2x} 2x` : undefined}
          alt={rubricDefault ? rubrics[rubricName]?.image?.alt : rubricName}
          className={styles.item__img}
        />
      </div>
      <span className={styles.item__title}>{rubricDefault ? rubrics[rubricName]?.title : rubricName}</span>
      <span className={`${styles.item__wrappBtn} flex-center`}>
        <span className={`${styles.item__btnIcon} flex-center`}>
          <ArrowLeft width={16} height={16} fill="#274A42" />
        </span>
      </span>
    </Link>
  );
};
