import React from "react";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import TeamProject from "../TeamProject/TeamProject";
import styles from "./JoinProjectTeams.module.sass";

const JoinProjectTeams = () => {
  const { project } = useJoinProjectsSelectors();
  return (
    <ul className={styles.list}>
      {project.teams.map((team) => (
        <TeamProject key={team.id} team={team} />
      ))}
    </ul>
  );
};

export default JoinProjectTeams;
