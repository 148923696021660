import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload_3.svg";
import classNames from "classnames";
import { useTimeZones } from "collections/profile";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import IconTooltip from "generalComponents/IconTooltip/IconTooltip";
import Input from "generalComponents/Input/Input";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import Switcher from "generalComponents/Switcher/Switcher";
import { useActions } from "hooks/useActions";
import { useWorkDays, workHours } from "hooks/useWorkDays";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { ChangeEvent, useRef } from "react";
import { useLocales } from "react-localized";
import { hoursTostring, INITIAL_WORK_HOURS } from "utils/jointPtoject";
import { getCurrentTimezoneOffset } from "utils/timezone";

import styles from "./JointProjectInfo.module.sass";

interface IProps {
  name: string;
  icon: string;
  setIcon: (v: string) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  startDate: Date;
  endDate: Date;
  setStartDate: any;
  setEndDate: any;
  setIsClose?: (v: boolean) => void;
  work_days: number[];
  setWork_days: Dispatch<SetStateAction<number[]>>;
  work_hours: number[];
  setWork_hours: Dispatch<SetStateAction<number[]>>;
  timezone: string;
  setTimezone: Dispatch<SetStateAction<string>>;
  autoTimezone: boolean;
  setAutoTimezone: Dispatch<SetStateAction<boolean>>;
}

const JointProjectInfo: FC<IProps> = ({
  name,
  icon,
  setIcon,
  onChange,
  endDate,
  setEndDate,
  setStartDate,
  startDate,
  setIsClose,
  work_days,
  setWork_days,
  work_hours,
  setWork_hours,
  timezone,
  setTimezone,
  autoTimezone,
  setAutoTimezone
}) => {
  const { __ } = useLocales();
  const { onSetAvatarCropModal } = useActions();
  const { workDays } = useWorkDays();
  const [showHours, setShowHours] = useState<boolean>(false);
  const [visibleHours, setVisibleHours] = useState<string[]>(hoursTostring(work_hours));

  const inputRef = useRef<HTMLInputElement>();
  const hoursRef = useRef<HTMLDivElement>();

  const timeZoneOptions = useTimeZones();

  const removeIcon = () => setIcon("");
  const fileSelect = () => inputRef.current.click();
  const uploadImage = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image")) {
      const imgSrc = URL.createObjectURL(file);
      onSetAvatarCropModal({
        open: true,
        imgSrc,
        callback: (icon) => setIcon(icon)
      });
    }
  };

  useOutsideClick(hoursRef, () => {
    setShowHours(false);
    work_hours.length === 0 && setWork_hours(INITIAL_WORK_HOURS);
    setVisibleHours(hoursTostring(work_hours.length > 0 ? work_hours : INITIAL_WORK_HOURS));
    setIsClose && setTimeout(() => setIsClose(false), 500);
  });

  const openHours = () => {
    setIsClose && setIsClose(true);
    setShowHours(true);
  };

  const changeWorkDays = (el: number) =>
    setWork_days((prev) => {
      const arr = prev.includes(el) ? prev.filter((i) => i !== el) : [...prev, el];
      return arr.length === 0 ? [1, 2, 3, 4, 5, 6, 0] : arr;
    });

  const changeWorkHours = (el: number) =>
    setWork_hours((prev) =>
      prev.includes(el) ? prev.filter((i) => i !== el).sort((a, b) => a - b) : [...prev, el].sort((a, b) => a - b)
    );

  useEffect(() => {
    if (!autoTimezone) return;
    setTimezone(getCurrentTimezoneOffset());
  }, [autoTimezone]); //eslint-disable-line

  return (
    <>
      <div className={styles.avatarBlock}>
        <Avatar size={56} name={name} imgSrc={icon && icon.startsWith("data:image/jpeg;base64") ? icon : `${icon}`} />
        <div className={styles.uploadBtns}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.EXTRA_SMALL}
            onClick={fileSelect}
            iconL={<UploadIcon />}
            text={__("Загрузить")}
          />
          {Boolean(icon) && (
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.EXTRA_SMALL}
              onClick={removeIcon}
              iconL={<TrashIcon width={9} height={13} />}
              text={__("Удалить")}
            />
          )}
        </div>
      </div>
      <input
        type="file"
        ref={inputRef}
        className="hidden"
        onChange={uploadImage}
        style={{ display: "none" }}
        accept="image/png, image/gif, image/jpeg, image/jpg"
      />
      <Input value={name} onChange={onChange} require label={__("Название")} />
      <div className={styles.block}>
        <div className={styles.top}>
          <p>
            {__("Рабочие дни")}&nbsp;{__(`(${work_days.length}д)`)}
          </p>
          <IconTooltip
            text={__(
              "Настройка <b>Рабочие дни</b> — необходимы для корректного отображение диаграмы ганта и планирования спринтов "
            )}
            id="d"
          />
        </div>
        <ul className={styles.days}>
          {workDays.map((el) => (
            <li key={el.numDay}>
              <Button
                variant={work_days.includes(el.numDay) ? ButtonVariantType.BLUE : ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.LARGE}
                text={el.name}
                isSquare
                onClick={() => changeWorkDays(el.numDay)}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.block}>
        <div className={styles.top}>
          <p>
            {__("Рабочие часы")}&nbsp;{__(`(${work_hours.length}ч)`)}
          </p>
          <IconTooltip
            text={__(
              "Настройка <b>Рабочие часы</b> — необходимы для корректного отображение диаграмы ганта и планирования спринтов "
            )}
            id="h"
          />
        </div>
        <div className={styles.hoursBlock} ref={hoursRef}>
          <div className={styles.hours} onClick={openHours}>
            {visibleHours.map((el) => (
              <span key={el} className={styles.hour}>
                {el}
              </span>
            ))}
          </div>
          {showHours && (
            <div className={styles.hoursSelect}>
              {workHours.map((item) => (
                <div
                  key={item.id}
                  className={classNames(styles.hour, { [styles.inactive]: !work_hours.includes(item.id) })}
                  onClick={() => changeWorkHours(item.id)}
                >
                  {item.value}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.top}>
          <p>{__("Часовой пояс")}</p>
        </div>
        <div className={styles.hoursBlock} ref={hoursRef}>
          <div className={classNames(styles.timezoneSelect, autoTimezone && styles.disabled)}>
            <SelectCustom
              disabled={autoTimezone}
              selectedDefault={timeZoneOptions.find((item) => item.value === timezone.toString())}
              value={timeZoneOptions.find((item) => item.value === timezone.toString())}
              name="timeZone"
              onSelected={(e) => setTimezone(e.currentTarget.value)}
              options={timeZoneOptions}
              width={384}
              variantHeight="medium"
            />
          </div>
          <div className={styles.autoTimezone}>
            <div>
              <Switcher checked={autoTimezone} onClickCheckBox={() => setAutoTimezone((prev) => !prev)} />
              {__("Автоопределение")}
            </div>
            <IconTooltip
              title={__("Автоопределение")}
              text={__(
                "<b>Вкл</b> — используется системный часовой пояс. <br/> <b>Выкл</b> — выбор часового пояса вручную, переход на зимнее/летнее время осуществляется вручную."
              )}
              id="tz"
            />
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.top}>
          <p>{__("Сроки проекта")}</p>
          <IconTooltip
            text={__("<b>Даты начала и конца</b> проекта нужны для блока статистики Прогресс создания проекта")}
            id="p"
          />
        </div>
        <div className={styles.dates}>
          <div style={{ position: "relative" }}>
            <InputDate
              startDate={startDate}
              setStartDate={setStartDate}
              dateFormat="dd MMM yyyy"
              isClearable
              maxDate={endDate}
              setIsClose={setIsClose}
            />
            <div className={styles.label}>{__("Начало")}</div>
          </div>
          <div style={{ position: "relative" }}>
            <InputDate
              startDate={endDate}
              setStartDate={setEndDate}
              dateFormat="dd MMM yyyy"
              isClearable
              minDate={startDate}
              setIsClose={setIsClose}
            />
            <div className={styles.label}>{__("Конец")}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export { JointProjectInfo };
