import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { ROUTES, ROUTES_SEARCH_PARAMS } from "generalComponents/variables/routing";
import { ITopMessages } from "models/common/common";
import { IRestoreInFolder } from "models/restoreInFolder/restoreInFolder";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { FilesActions } from "models/store/files/filesActions";
import { FoldersAction } from "models/store/folders/foldersActions";
import { IFolder } from "models/store/folders/foldersStore";
import { IProject } from "models/store/joinProjects/joinProgects";
import { ISafe } from "models/store/safe/safe";
import { IMyTask } from "models/store/tasks/tasks";
import { TrashElementsActions } from "models/store/trash/trashElementssActions";
import { AllElementsArrayType, AllElementsType } from "models/store/typeElements";
import { IUserInfo } from "models/store/user/user";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { TrashElementsTypes } from "Store/types/trashedElemetsTypes";
import { getLocationSearchParams } from "utils/getLocationSearchParams";
import { getTheRestorePath } from "utils/getTheRestorePath";

import { onSetFolders } from "./FoldersActions";
import { onSetLoaderModal } from "./ModalActions";
import { onSetTopMessageModal } from "./ModalActions";
import { onSetRestoreFileModal } from "./ModalActions";

export const onTrashElementLoader = (payload: boolean): TrashElementsActions => ({
  type: TrashElementsTypes.TRASH_ELEMENTS_LOADER,
  payload
});

export const onSetTrashElements = (payload: AllElementsArrayType): TrashElementsActions => ({
  type: TrashElementsTypes.SET_TRASH_ELEMENTS,
  payload
});

export const onSetTotalTrashElements = (payload: number): TrashElementsActions => ({
  type: TrashElementsTypes.SET_TOTAL_TRASH_ELEMENTS,
  payload
});

export const onResetTrashElementsList = (): TrashElementsActions => ({
  type: TrashElementsTypes.RESET_TRASH_ELEMENTS_LIST
});

export const onNullifyTrashElements = (): TrashElementsActions => ({
  type: TrashElementsTypes.NULLIFY_TRASH_ELEMENTS
});

export const onSetTrashPage = (payload: number): TrashElementsActions => ({
  type: TrashElementsTypes.SET_TRASH_PAGE,
  payload
});

export const onSetDelTrashElement = (payload: AllElementsType): TrashElementsActions => ({
  type: TrashElementsTypes.SET_DEL_TRASH_ELEMENT,
  payload
});

export const onSetEmptyTrashElements = (): TrashElementsActions => ({
  type: TrashElementsTypes.SET_EMPTY_TRASH_ELEMENTS
});

export const onGetAllTrashElements =
  (controller?: AbortController): ThunkAction<void, RootState, unknown, TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { sort, sort_reverse, color, emo, tags, table, id_type, year, month, day } = getLocationSearchParams();

    const { trash_minutes_del } = getState().user.userInfo;

    const params = {
      uid: getState().user.uid,
      per_page: PER_PAGE,
      page: getState().Trash.page,
      emo: emo || undefined,
      color: color || undefined,
      tags: tags ? tags.split(",") : undefined,
      sort_reverse,
      sort,
      group: 0,
      table: table ? table.split(",") : undefined,
      id_type: id_type ? id_type.split(",") : undefined,
      y: year || undefined,
      m: month || undefined,
      d: day || undefined,
      trash_minutes_del
    };
    try {
      dispatch(onTrashElementLoader(true));
      const { data } = await api.get("/ajax/trash_list.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetTrashElements(data.files));
      dispatch(onSetTotalTrashElements(data.total));
    } catch {
      if (!controller?.signal.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onTrashElementLoader(false));
    }
  };

export const onGetTrashFolderFiles =
  (
    payload: { id_dir: string; pass?: string },
    controller?: AbortController
  ): ThunkAction<void, RootState, unknown, TrashElementsActions | CabinetActions | FoldersAction> =>
  async (dispatch, getState) => {
    const { sort, sort_reverse, color, emo, tags } = getLocationSearchParams();

    const params = {
      uid: getState().user.uid,
      per_page: PER_PAGE,
      id_dir: payload.id_dir,
      pass: payload.pass,
      page: getState().Trash.page,
      emo: emo || undefined,
      color: color || undefined,
      tags: tags ? tags.split(",") : undefined,
      sort_reverse,
      sort,
      group: 0
    };
    try {
      dispatch(onTrashElementLoader(true));
      const { data } = await api.get("/ajax/file_list.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetTrashElements(data.files));
      dispatch(onSetTotalTrashElements(data.total));
    } catch {
      if (!controller?.signal.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onTrashElementLoader(false));
    }
  };

export const onRestoreTrashFiles =
  (
    element: IFile,
    payload: { id_dir?: string; id_safe?: string } & IRestoreInFolder,
    callback?: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | FoldersAction | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_dir: payload.id_dir,
      fids: [element.fid],
      add_dir_name: payload.add_dir_name,
      add_pass: payload.add_pass,
      add_id_parent: payload.add_id_parent,
      add_tags: JSON.stringify(payload.add_tags),
      add_color: payload.add_color,
      add_emo: payload.add_emo,
      is_del: 0,
      id_safe: payload.id_safe ? payload.id_safe : undefined
    };

    let isNotDirError: boolean = false;

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/${payload.id_safe ? "safe_file_edit" : "file_edit"}.php`, { params });

      isNotDirError = data.dir_ok === 0;

      if (isNotDirError) {
        dispatch(
          onSetRestoreFileModal({
            open: true,
            element
          })
        );
      }

      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(element));

      data?.folders && dispatch(onSetFolders(data.folders.global, data.folders.other));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.RESTORED,
          callback,
          newPath:
            data.add_id_dir || data.add_id_parent
              ? getTheRestorePath({
                  path: ROUTES.FOLDERS,
                  id_dir: data.add_id_dir,
                  id_parent: data.add_id_parent,
                  search: `${ROUTES_SEARCH_PARAMS.byDateChanged}`
                })
              : undefined
        })
      );
    } catch (error) {
      console.log(error);
      if (!isNotDirError) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRestoreTrashTasks =
  (
    payload: IMyTask,
    callback: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_task: payload.id,
      is_del: 0
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/task_edit.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.RESTORED,
          callback
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRestoreTrashFolder =
  (
    element: IFolder,
    payload: { id_dirs?: string[]; id_parent?: string; current_id_parent?: string } & IRestoreInFolder,
    callback: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | FoldersAction | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_dirs: payload.id_dirs,
      id_parent: payload.id_parent,
      add_dir_name: payload.add_dir_name,
      add_id_parent: payload.add_id_parent,
      add_tags: JSON.stringify(payload.add_tags),
      add_color: payload?.add_color,
      add_emo: payload.add_emo,
      add_pass: payload.add_pass,
      is_del: 0
    };

    let isNotDirError: boolean = false;

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/dir_edit.php`, { params });

      isNotDirError = data.dir_ok === 0;

      if (isNotDirError) {
        dispatch(
          onSetRestoreFileModal({
            open: true,
            element
          })
        );
      }
      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(element));

      data.folders && dispatch(onSetFolders(data.folders.global, data.folders.other));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.RESTORED,
          callback,
          newPath:
            data.add_id_dir || data.add_id_parent
              ? getTheRestorePath({
                  path: ROUTES.FOLDERS,
                  id_dir: data.add_id_dir,
                  id_parent: data.add_id_parent,
                  search: `${ROUTES_SEARCH_PARAMS.byDateChanged}`
                })
              : undefined
        })
      );
    } catch {
      if (!isNotDirError) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR
          })
        );
      }
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRestoreTrashSafe =
  (
    payload: ISafe,
    callback: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_safe: payload.id,
      is_del: 0
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/safe_edit.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.RESTORED,
          callback
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRestoreTrashProject =
  (
    payload: IProject,
    callback: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_item: payload.id,
      is_del: 0
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/project_edit.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.RESTORED,
          callback
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRestoreTrashEmployee =
  (
    payload: IUserInfo,
    callback: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_item: payload.id,
      is_del: 0
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/org_user_edit.php`, { params });

      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.RESTORED,
          callback
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onTrashFiles =
  (payload: IFile): ThunkAction<void, RootState, unknown, FilesActions | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      fid: !payload?.id_safe ? payload.fid : undefined,
      fids: payload?.id_safe ? [payload.fid] : undefined,
      id_safe: payload?.id_safe
    };

    // fid: !file.id_safe ? file.fid : undefined,
    // fids: file.id_safe ? [file.fid] : undefined,
    // id_safe: file?.id_safe ? Number(file.id_safe) : undefined,
    // id_dir: file.id_dir

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/${payload.id_safe ? "safe_file_del" : "file_del_force"}.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onTrashTasks =
  (payload: IMyTask): ThunkAction<void, RootState, unknown, FilesActions | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_task: payload.id
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/task_del.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onTrashFolder =
  (payload: IFolder): ThunkAction<void, RootState, unknown, FilesActions | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_dir: payload.id_dir
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/dir_del.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onTrashSafe =
  (payload: ISafe): ThunkAction<void, RootState, unknown, FilesActions | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_safe: payload.id
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/safe_del.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onTrashProject =
  (payload: IProject): ThunkAction<void, RootState, unknown, FilesActions | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_item: payload.id
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/project_del.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.IS_DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onTrashEmployee =
  (payload: IUserInfo): ThunkAction<void, RootState, unknown, FilesActions | TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_item: payload.id
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/org_user_del.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetDelTrashElement(payload));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onFullCleaningTrash =
  (message?: ITopMessages): ThunkAction<void, RootState, unknown, TrashElementsActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      del_all: 1
    };
    try {
      dispatch(onTrashElementLoader(true));
      const { data } = await api.get("/ajax/trash_list.php", { params });
      checkResponseStatus(data.ok);

      dispatch(onSetEmptyTrashElements());

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          message: message.success
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onTrashElementLoader(false));
    }
  };
