import { useMonths } from "generalComponents/Calendars/CalendarHelper";
import { useTasksCalendar } from "generalComponents/Services/calendarPageServices";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { ISidePanelProps } from "models/calendarPage/sidePanel";
import React from "react";

import TasksBlock from "../TasksBlock/TasksBlock";
import styles from "./SidePanel.module.sass";

const SidePanel: React.FC<ISidePanelProps> = ({ setSchema }): JSX.Element => {
  const { tasks, meetings, calls, mails } = useTasksCalendar();
  const months = useMonths();
  return (
    <div className={styles.wrap}>
      <div className={styles.date}>
        <div className={styles.day}>{new Date().getDate()}</div>
        <div className={styles.month}>{months()[new Date().getMonth()].declensionName}</div>
      </div>
      <TasksBlock tasks={tasks} type={TASK_TYPES.TASK} isFull={false} setSchema={setSchema} />
      <TasksBlock tasks={meetings} type={TASK_TYPES.MEETINGS} isFull={false} setSchema={setSchema} />
      <TasksBlock tasks={calls} type={TASK_TYPES.CALLS} isFull={false} setSchema={setSchema} />
      <TasksBlock tasks={mails} type={TASK_TYPES.MAILS} isFull={false} setSchema={setSchema} />
    </div>
  );
};

export default SidePanel;
