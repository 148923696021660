import { ReactComponent as UserIcon } from "assets/icons/user-white.svg";
import Button from "containers/Authentication/Button/Button";
import AppleButton from "containers/Authentication/SignUp/Buttons/AppleButton";
import FacebookButton from "containers/Authentication/SignUp/Buttons/FacebookButton";
import GoogleButton from "containers/Authentication/SignUp/Buttons/GoogleButton";
import { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./SecondStep.module.sass";

const SecondStep = ({ handleNextStep }: { handleNextStep: () => void }): JSX.Element => {
  const { __ } = useLocales();
  const [error, setError] = useState("");

  return (
    <div className={styles.container}>
      <h4>{__("Добро пожаловать в 4HUB")}</h4>
      <p>{__("Выберите метод авторизации")}</p>
      <div className={styles.buttons_wrapper}>
        <Button onClick={handleNextStep}>
          <UserIcon className={styles.icon} /> {__("Email / Телефон")}
        </Button>
        <GoogleButton setError={setError} />
        <FacebookButton />
        <AppleButton />
        {error && <span className={styles.error}>{__(error)}</span>}
      </div>
    </div>
  );
};

export default SecondStep;
