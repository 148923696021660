import { IProjectTask } from "models/store/joinProjects/joinProgects";

export interface IProjectTasksInfoModalState {
  open: boolean;
  task: IProjectTask;
}

export function initialProjectTasksInfoState(): IProjectTasksInfoModalState {
  return { open: false, task: null };
}
