import { getTime } from "date-fns";
import ContextMenu from "generalComponents/ContextMenu";
import ContextMenuItem from "generalComponents/ContextMenu/ContextMenuItem";
import { useCopyLink } from "generalComponents/Services/browserServices";
import { useGetStatus } from "generalComponents/Services/projectServices";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_CONTEXT_MENU, PROJECT_MODALS } from "generalComponents/variables/project";
import { URGENCY_TYPES } from "generalComponents/variables/tasks";
import PropTypes from "prop-types";
import React, { useMemo, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onChangeStatusTask, onEditBacklogTask, onMoveToArchiv } from "Store/actions/ProjectActions";
import { ProjectTypes } from "Store/types/projectTypes";
import { colorCategoryProps, taskTypes } from "types/Project";

import { onSetModals } from "../../../../../../Store/actions/ModalActions";
import { useContextProjectTask, useProjectMsg } from "../../helpers";
import TaskColumn from "../TaskColumn/TaskColumn";
import TaskDependency from "../TaskDependency/TaskDependency";
import TaskLine from "../TaskLine/TaskLine";
import styles from "./ProjectTask.module.sass";

const ProjectTask = ({ task, accordion, changeStatus, color, variant, isMenu }) => {
  const MSG = useProjectMsg();
  const dispatch = useDispatch();
  const project = useSelector((s) => s.Projects.project);
  const [mouseParams, setMouseParams] = useState(null);
  const CONTEXT_MENU = useContextProjectTask();
  const closeContextMenu = () => setMouseParams(null);
  const linkRef = useRef(null);
  const [dependency, setDependency] = useState(null);
  const status = useGetStatus();
  const copyLink = useCopyLink();

  const openDependency = (e) => {
    e.stopPropagation();
    setDependency((prev) => (!prev ? { x: e.clientX, y: e.clientY, task: task } : null));
  };

  const onChangeUrgency = (type) => {
    const projectTasks = [...project.tasks];
    const index = projectTasks.findIndex((t) => t.id === task.id);
    const newTask = { ...task, id_act: type };
    projectTasks.splice(index, 1, newTask);
    dispatch({
      type: ProjectTypes.UPDATE_TASKS,
      payload: projectTasks
    });
  };
  const onChangeStatus = (e, type) => {
    e.stopPropagation();
    closeContextMenu();
    const endDay = getTime(new Date(task.date_long ? task.date_long : task.date_end));
    const now = getTime(new Date());

    if (task.id_category === "90" && type !== "80" && endDay < now) {
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.EXTEND_PERIOD,
          params: { task: { ...task, id_category: type }, isOpen: false }
        })
      );
      return;
    }
    // change local redux store
    const projectTasks = [...project.tasks];
    const index = projectTasks.findIndex((t) => t.id === task.id);
    const newTask = { ...task, id_category: type, sort: 9 };
    projectTasks.splice(index, 1, newTask);
    dispatch({
      type: ProjectTypes.UPDATE_TASKS,
      payload: projectTasks
    });
    // server reques
    const payload = {
      id_category: type,
      sort: 9,
      id: task.id,
      name: task.name,
      id_act: task.id_act
    };
    dispatch(onChangeStatusTask(payload, MSG.ERROR));
  };

  const filtredContexMenu = useMemo(() => {
    return CONTEXT_MENU.filter((el) => {
      if (task.id_act === URGENCY_TYPES.PLANNED) {
        return el.type !== PROJECT_CONTEXT_MENU.DEL_URGENT;
      } else {
        return el.type !== PROJECT_CONTEXT_MENU.ADD_URGENT;
      }
    });
  }, [task.id_act]); //eslint-disable-line

  const callbacks = {
    [PROJECT_CONTEXT_MENU.OPEN_TASK]: () =>
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.OPEN_TASK, params: task })),
    [PROJECT_CONTEXT_MENU.EDIT_TASK]: () =>
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.EDIT_TASK, params: task })),

    [PROJECT_CONTEXT_MENU.COPY_LINK]: () => {
      copyLink(linkRef, task.link);
    },

    [PROJECT_CONTEXT_MENU.ADD_URGENT]: () => {
      onChangeUrgency(URGENCY_TYPES.URGENT);

      const payload = {
        id: task.id,
        name: task.name,
        sort: task.sort,
        id_category: task.id_category,
        id_act: URGENCY_TYPES.URGENT
      };
      dispatch(onChangeStatusTask(payload, MSG.ERROR));
    },
    [PROJECT_CONTEXT_MENU.DEL_URGENT]: () => {
      // change local redux store
      onChangeUrgency(URGENCY_TYPES.PLANNED);

      // server reques
      const payload = {
        id: task.id,
        name: task.name,
        sort: task.sort,
        id_category: task.id_category,
        id_act: URGENCY_TYPES.PLANNED
      };
      dispatch(onChangeStatusTask(payload, MSG.ERROR));
    },

    [PROJECT_CONTEXT_MENU.TASK_HISTORY]: () =>
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.TASK_HISTORY, params: task })),

    [PROJECT_CONTEXT_MENU.MOVE_TO_ARCHIVE]: () => dispatch(onMoveToArchiv(task, MSG.ERROR)),
    [PROJECT_CONTEXT_MENU.MOVE_TO_BACKLOG]: () => {
      const params = {
        id: task.id,
        name: task.name,
        is_backlog: 0
      };
      const messages = {
        error: MSG.ERROR,
        success: MSG.MOVE_TO_BACKLOG
      };
      dispatch(onEditBacklogTask(params, messages));
    },

    [PROJECT_CONTEXT_MENU.TASK_TO_TRASH]: () =>
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.TASK_TO_TRASH, params: task }))
  };

  const onOpenTask = () => dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.OPEN_TASK, params: task }));

  const getStyleTaskCard = {
    backgroundColor: color?.light || status(task)?.color.light || "#fff",
    borderColor: color?.dark || status(task)?.color.dark || "#c2c2c2"
  };

  const handleDateLong = (e) => {
    e.stopPropagation();
    dispatch(
      onSetModals(MODALS.PROJECT, {
        type: PROJECT_MODALS.EXTEND_PERIOD,
        params: { task: { ...task }, isOpen: true }
      })
    );
  };

  return (
    <>
      <div className={styles.wrap} style={getStyleTaskCard} onClick={onOpenTask}>
        {variant === "column" && (
          <TaskColumn
            task={task}
            accordion={accordion}
            changeStatus={changeStatus}
            color={color ? color : status(task).color}
            setMouseParams={setMouseParams}
            status={status(task).name}
            handleDateLong={handleDateLong}
            openDependency={openDependency}
          />
        )}
        {variant === "row" && (
          <TaskLine
            task={task}
            accordion={accordion}
            changeStatus={changeStatus}
            color={color ? color : status(task)?.color}
            setMouseParams={setMouseParams}
            status={status(task)?.name}
            isMenu={isMenu}
            handleDateLong={handleDateLong}
            openDependency={openDependency}
          />
        )}
        <input value={task.link} readOnly className={styles.hiddenInput} ref={linkRef} />
      </div>
      {mouseParams !== null && filtredContexMenu && (
        <ContextMenu params={mouseParams} setParams={closeContextMenu} tooltip={true}>
          {mouseParams.type === "status" &&
            project.tasks_category.map((status) => (
              <div className={styles.itemMenu} key={status.id}>
                <div className={styles.circle} style={{ background: status.color.dark }} />
                <ContextMenuItem
                  width={mouseParams.width}
                  height={mouseParams.height}
                  text={status.name}
                  callback={(e) => onChangeStatus(e, status.id)}
                />
              </div>
            ))}
          {mouseParams.type === "task" && (
            <>
              {filtredContexMenu.map((item, i) => (
                <div key={i}>
                  <ContextMenuItem
                    width={mouseParams.width}
                    height={mouseParams.height}
                    text={item.name}
                    icon={item.img}
                    callback={callbacks[item.type]}
                  />
                </div>
              ))}
            </>
          )}
        </ContextMenu>
      )}
      {dependency && <TaskDependency task={task} x={dependency.x} y={dependency.y} onClose={openDependency} />}
    </>
  );
};

export default ProjectTask;

ProjectTask.propTypes = {
  task: taskTypes,
  accordion: PropTypes.bool,
  changeStatus: PropTypes.bool,
  color: colorCategoryProps,
  variant: PropTypes.oneOf(["column", "row"]),
  isMenu: PropTypes.bool
};
