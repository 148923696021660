import { ReactComponent as AddProposeIcon } from "assets/PrivateCabinet/addPropose-2.svg";
import { ReactComponent as AddIcon } from "assets/PrivateCabinet/plus-3.svg";
import React, { useContext } from "react";
import { useLocales } from "react-localized";

import { ShellContext } from "../Shell/Shell";
import styles from "./UploadArea.module.sass";

const UploadArea = (): JSX.Element => {
  const { __ } = useLocales();
  const { fileSelect } = useContext(ShellContext);

  return (
    <div className={styles.addButtonBlock}>
      <button onClick={() => fileSelect} className={styles.addFileButtonInput} type="button">
        <AddIcon className={styles.addIcon} />
        <span>{__("Перетащите файл или нажмите загрузить")}</span>
      </button>
      <AddProposeIcon />
    </div>
  );
};

export default UploadArea;
