import api from "api";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import Role from "Pages/Cabinet/Components/Project/Components/Role/Role";
import { useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onEditProject } from "Store/actions/JoinProjectActions";
import { onSetModals } from "Store/actions/ModalActions";
import { projectProps } from "types/Project";

import styles from "./AddRoles.module.sass";

const AddRoles = ({ onClose }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const theme = useSelector((s) => s.user.userInfo.theme);
  const project = useSelector((s) => s.Projects.project);
  const projectRoles = useSelector((s) => s.Projects.project.roles);

  const MSG = useProjectMsg();
  const uid = useSelector((s) => s.user.uid);
  const [roles, setRoles] = useState([]);
  const [value, setValue] = useState("");
  const [chosenRoles, setChosenRoles] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    onFetchRoles();
  }, []); // eslint-disable-line

  const onFetchRoles = async () => {
    try {
      setloading(true);
      const { data } = await api.get(`/ajax/project_roles_list.php`, { params: { uid } });
      checkResponseStatus(data.ok);
      const rolesList = data.projects_roles.filter((role) => projectRoles.findIndex((el) => el.id === role.id) < 0);
      setRoles(rolesList);
    } catch {
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.NO_MODAL, params: {} }));
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: MSG.ERROR
        })
      );
    } finally {
      setloading(false);
    }
  };

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  const filtredRoles = useMemo(() => {
    return roles.filter((el) => el.name.toLowerCase().includes(value.toLowerCase()));
  }, [roles, value]);

  const isChosen = (item) => {
    return chosenRoles.find((el) => el.id === item.id) ? true : false;
  };

  const onSelectRole = (item) => {
    if (isChosen(item)) {
      setChosenRoles((prev) => prev.filter((el) => el.id !== item.id));
    } else {
      setChosenRoles((prev) => [...prev, item]);
    }
  };

  const onSubmit = () => {
    const payload = {
      ...project,
      roles: [...projectRoles, ...chosenRoles]
    };
    const messages = {
      error: MSG.ERROR,
      success: MSG[PROJECT_MODALS.ADDED_ROLES]
    };
    dispatch(onEditProject(payload, messages, PROJECT_MODALS.ADDED_ROLES));
  };

  const onCreateRole = () => {
    dispatch(
      onSetModals(MODALS.PROJECT, {
        type: PROJECT_MODALS.CREATE_ROLE,
        params: project
      })
    );
  };

  return (
    <div className={styles.wrap}>
      <span className={styles.subTitle} htmlFor="search">
        {__("Введите имя роли")}
      </span>
      <input className={styles.input} value={value} onChange={handleChange} />
      <div className={styles.chosenRoles}>
        {chosenRoles.map((role) => (
          <Role key={role.id} role={role} />
        ))}
      </div>
      <span className={styles.subTitle} htmlFor="search">
        {__("Выберите роль из списка")}
      </span>
      <div className={classNames(styles.rolesList, `scrollbar-medium-${theme}`)}>
        {loading ? (
          <Loader
            type="bounceDots"
            position={"relative"}
            background="transparent"
            zIndex={5}
            width="200px"
            height="200px"
            containerType="bounceDots"
          />
        ) : (
          <>
            {filtredRoles.map((el) => (
              <div key={el.id} onClick={() => onSelectRole(el)}>
                <Role role={el} isChosen={isChosen(el)} />
              </div>
            ))}
          </>
        )}
      </div>
      <div className={styles.btns}>
        <Button type="button" variant="cancel" text={__("Отмена")} onClick={onClose} />
        <Button type="button" variant="ok" text={__("Готово")} onClick={onSubmit} disabled={chosenRoles.length === 0} />
        <Button type="button" variant="ok" text={__("Создать свою роль")} onClick={onCreateRole} />
      </div>
    </div>
  );
};

export default AddRoles;

AddRoles.propTypes = {
  onClose: PropTypes.func,
  params: projectProps
};
