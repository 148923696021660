import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { BUTTON_TYPES } from "../variables/customableButton";
import styles from "./CustomableButton.module.sass";

function Button({ children, style, onClick, isSelected, disabled }) {
  const { theme } = useSelector((s) => s.user.userInfo);

  const renderLightButton = () => {
    return (
      <div
        className={classNames(
          styles.buttonLight,
          styles[style],
          `button-light-${theme}`,
          isSelected ? styles.active : ""
        )}
        onClick={(e) => {
          if (!disabled) {
            onClick(e);
          }
        }}
      >
        {children}
      </div>
    );
  };

  const renderButton = () => {
    return (
      <div
        className={classNames(styles.button, styles[style], `button-${theme}`, {
          [styles.disabled]: disabled
        })}
        onClick={(e) => {
          if (!disabled) {
            onClick(e);
          }
        }}
      >
        {children}
      </div>
    );
  };

  const renderIcon = () => {
    return (
      <div
        className={classNames(styles[style])}
        onClick={(e) => {
          if (!disabled) {
            onClick(e);
          }
        }}
      >
        {children}
      </div>
    );
  };

  const renderSwitch = (style) => {
    switch (style) {
      case BUTTON_TYPES.LIGHT_LONG:
        return renderLightButton();
      case BUTTON_TYPES.ICON || BUTTON_TYPES.ROUND_GREY:
        return renderIcon();
      case BUTTON_TYPES.ICON_DISABLED:
        return renderIcon();
      case BUTTON_TYPES.ICON_CHOSEN_DISABLED:
        return renderIcon();
      case BUTTON_TYPES.RED:
        return renderIcon();
      default:
        return renderButton();
    }
  };

  return <>{renderSwitch(style)}</>;
}

export default Button;

Button.defaultProps = {
  onClick: () => {},
  typeButton: "",
  disabled: false
};

Button.propTypes = {
  children: PropTypes.node.isRequired, //could be anything to render inside
  style: PropTypes.oneOf(Object.values(BUTTON_TYPES)).isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool
};
