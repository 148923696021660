import { ReactComponent as AnswerIcon } from "assets/icons/answer.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as ForwardIcon } from "assets/icons/forward.svg";
import { ReactComponent as CloseIcon } from "assets/PrivateCabinet/closeModal.svg";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import PopUp from "generalComponents/PopUp/PopUp";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialMailDashboardModalState } from "models/store/Cabinet/modals/postboxModal";
import { IChangeFlagMail } from "models/store/postbox/postboxPayloads";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router";
import { getContacts } from "Store/actions/userAction";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./MailDashboardModal.module.sass";

const MailDashboardModal = () => {
  const { __ } = useLocales();
  const { onSetMailDashboardModal, onChangeStandartFlagMail, onSetSearchLetter, onMailNewMessageModal } = useActions();
  const {
    mailDashboardModal: { letter }
  } = useGlobalModalsSelectors();
  const [icon, setIcon] = useState<string>();
  const { contacts, employees } = useUserSelectors();

  useEffect(() => {
    const findIcon = () => {
      const employeeMatch = employees.find((employee) => {
        const hasMatchingEmail = employee.emails.includes(letter.from);
        const hasMatchingEmail2 = employee.email === letter.from;
        return hasMatchingEmail || hasMatchingEmail2;
      });

      if (!employeeMatch) {
        const contactMatch = contacts.find((contact) => {
          const hasMatchingEmail = contact.email.includes(letter.from);
          return hasMatchingEmail;
        });

        if (contactMatch) {
          return contactMatch.icon;
        }
      } else {
        return employeeMatch.icon;
      }

      return null;
    };

    const newIcon = findIcon();
    if (newIcon !== null) {
      setIcon(newIcon);
    }
  }, [contacts, letter]); //eslint-disable-line

  const closeModal = () => {
    onSetMailDashboardModal(initialMailDashboardModalState());
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (letter.seen) return;
    getContacts();
    const data = {
      folder: letter.folder,
      message_nums: letter.uid,
      flag: "seen",
      flag_value: "1",
      message_id: letter.message_id
    };
    const payload: IChangeFlagMail = {
      data: {
        user: letter.postbox.email,
        pass: letter.postbox.pass,
        server: letter.postbox.server,
        data: JSON.stringify([data])
      },
      value: { flag: "seen", val: "1", folder: letter.folder }
    };
    const errorMsg = __("Что-то пошло не так. Попробуйте позже!");
    onChangeStandartFlagMail(payload, errorMsg);
  }, []); //eslint-disable-line

  const handleViewInMailbox = () => {
    closeModal();
    onSetSearchLetter(letter);
    navigate(`/${ROUTES.MAIL}/${letter.postbox.id}/${letter.folder}?split=true`);
  };

  return (
    <PopUp set={closeModal} position="top">
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.info}>
            <Avatar name={letter.from_name} size={36} imgSrc={icon ? projectSrc + icon : undefined} />
            <div>
              <div className={styles.title}>{letter.from_name}</div>
              <div className={styles.mail}>{letter.from}</div>
            </div>
          </div>
          <Button
            iconL={<CloseIcon />}
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.MEDIUM}
            onClick={closeModal}
          />
        </div>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: letter.html }} />
        <div className={styles.footer}>
          <Button
            iconL={<EyeIcon />}
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            onClick={handleViewInMailbox}
            text={__("Смотреть в почтовике")}
          />
          <div className={styles.footerLeft}>
            <Button
              iconL={<AnswerIcon />}
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Ответить")}
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
                onMailNewMessageModal({
                  open: true,
                  letter: { letter, type: "answer" }
                });
              }}
            />
            <Button
              iconL={<ForwardIcon />}
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Переслать")}
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
                onMailNewMessageModal({
                  open: true,
                  letter: { letter, type: "forward" }
                });
              }}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default MailDashboardModal;
