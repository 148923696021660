import { ANALITIC_INFO } from "models/project/helperContent";
import { useLocales } from "react-localized";

import { PROJECT_CONTEXT_MENU, PROJECT_MODALS } from "../variables/project";

export function useProjectModalTitles() {
  const { __ } = useLocales();

  return {
    [PROJECT_MODALS.ADD_PROJECT]: __("Добавить проект"),
    [PROJECT_MODALS.EDIT_PROJECT]: __("Редактировать проект"),
    [PROJECT_MODALS.DEL_PROJECT]: __("Удалить проект"),
    [PROJECT_MODALS.AVATAR_WAY]: __("Загрузить аватар проекта"),
    [PROJECT_MODALS.CHOOSE_AVATAR]: __("Выберите аватар"),
    [PROJECT_MODALS.AVATAR_CROP]: __("Отредактируйте фото"),
    [PROJECT_MODALS.ADD_ROLES]: __("Добавить роль проекта"),
    [PROJECT_MODALS.CREATE_ROLE]: __("Создать свою роль"),
    [PROJECT_MODALS.EDIT_ROLE]: __("Изменить роль"),
    [PROJECT_MODALS.ADDED_ROLES]: __("Роль(и) успешно добавлены"),
    [PROJECT_MODALS.ADD_USERS]: __("Добавить участников проекта"),
    [PROJECT_MODALS.APPOINT_ROLES]: __("Назначте роль сотрудников в проекте"),
    [PROJECT_MODALS.ADDED_USERS]: __("Роль проекта и участники добавленны"),
    [PROJECT_MODALS.ADD_TASK]: __("Добавить задачу"),
    [PROJECT_MODALS.EDIT_TASK]: __("Редактировать задачу"),
    [PROJECT_MODALS.TASK_TO_TRASH]: __("Удалить задачу"),
    [PROJECT_MODALS.DEL_TASK]: __("Удалить задачу"),
    [PROJECT_MODALS.ADD_CATEGORY]: __("Добавить категорию"),
    [PROJECT_MODALS.EDIT_CATEGORY]: __("Изменить категорию"),
    [PROJECT_MODALS.DEL_CATEGORY]: __("Удалить категорию"),
    [PROJECT_MODALS.ADD_EXECUTORS]: __("Добавить исполнителя"),
    [PROJECT_MODALS.LEAVE_PROJECT]: __("Покинуть проект"),
    [PROJECT_MODALS.CHOOSE_ADMIN]: __("Выбор администратора проекта"),
    [PROJECT_MODALS.ADDED_ADMIN]: __("Администратор успешно заменен"),
    [PROJECT_MODALS.APPOINT_SUBADMIN]: __("Назначить заместителя администратора"),
    [PROJECT_MODALS.CHOOSE_SUBADMIN]: __("Выбор заместителя администратора проекта"),
    [PROJECT_MODALS.TASK_HISTORY]: __("История задачи"),
    [PROJECT_MODALS.CLEAR_TRASH]: __("Вы уверены что хотите очистеть корзину?"),
    [PROJECT_MODALS.EXTEND_PERIOD]: __("Продление срока задачи"),
    [PROJECT_MODALS.USER_INFO]: __("Информация о сотруднике"),
    [PROJECT_MODALS.ADD_LINK]: __("Добавить ссылку"),
    [PROJECT_MODALS.EDIT_LINK]: __("Редактировать ссылку"),
    [PROJECT_MODALS.WARNING]: __("Внимание!!!"),
    [PROJECT_MODALS.ADD_SECTION]: __("Добавить раздел"),
    [PROJECT_MODALS.EDIT_SECTION]: __("Редактировать раздел")
  };
}

export function useContextProjectRole() {
  const { __ } = useLocales();
  return [
    { name: __("Редактировать роль"), img: "edit", type: PROJECT_CONTEXT_MENU.EDIT_ROLE },
    { name: __("Удалить роль"), img: "garbage", type: PROJECT_CONTEXT_MENU.DEL_ROLE }
  ];
}

export const PROJECT_CATEGORIES = {
  PENDING: "91",
  CLARIFICATION: "82",
  IN_WORK: "81",
  DONE: "80",
  NOT_IMPLEMENTED: "90"
};

export const useAnaliticsInfo = () => {
  const { __ } = useLocales();
  return {
    [ANALITIC_INFO.CATEGORIES]: {
      title: __("По данному блоку можно увидеть количество задач в разрезе каждой категории, и соответственно:"),
      list: [
        {
          listItem: __(
            " При выборе диапазона времени, можно получить конкретные результаты за этот период. Временной диапазон:"
          ),
          subList: [__("Спринт"), __("Месяц"), __("Год")]
        },
        { listItem: __("Есть возможность увидеть статистику в разрезе:"), subList: [__("Роли"), __("Исполнителя")] }
      ]
    },
    [ANALITIC_INFO.PROGRESS]: {
      title: __("По данному графику можно получить следующую информацию:"),
      list: [
        {
          listItem: __("На сколько запланированная дата проекта отклоняется от спрогнозированной;")
        },
        { listItem: __("Фактический прогресс выполнения проекта в процентах;") },
        {
          listItem: __(
            "Прогресс выполнения в разрезе конкретного исполнителя/роли даст понять – кто является якорем, который тормозит процесс разработки, и кто двигается с оптимальной скоростью."
          )
        }
      ]
    },
    [ANALITIC_INFO.EXTEND]: {
      title: __("По данному графику можно получить следующую информацию:"),
      list: [
        {
          listItem: __(
            "Кол-во просроченных задач в разрезе каждого специалиста, которая даст понять, кто из сотрудников больше всего не справляется с задачами;"
          )
        },
        { listItem: __("Кол-во часов/дней/недель, на которые будет перенесен крайний срок выполнения проекта;") },
        {
          listItem: __(
            "Время, на сколько в среднем продлевался срок выполнения задач. По этой информации можно узнать – кто из специалистов брал больше и меньше всего дополнительного времени на выполнение задач."
          )
        }
      ]
    },

    [ANALITIC_INFO.AVERANGE_DONE]: {
      title: __("Данный график для пользователя даёт возможность:"),
      list: [
        {
          listItem: __("Определить, у какого исполнителя больше и меньше всего уходит времени на выполнение задачи;")
        },
        { listItem: __("Видеть, среднее время выполнения задачи по всем выбранным исполнителям проекта.") }
      ]
    },

    [ANALITIC_INFO.TASKS]: {
      title: __("По данному графику можно получить важную информацию:"),
      list: [
        {
          listItem: __(
            "Динамика кол-ва задач – даст понять какая нагрузка по кол-ву задач на каждого исполнителя или роли;"
          )
        },
        {
          listItem: __(
            "Динамика кол-ва просроченных задач – даст понять, у кого из специалистов больше всего просроченных задач за выбранный диапазон времени год/месяц/спринт."
          )
        }
      ]
    }
  };
};
