import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { selectedItemProps } from "types/Contacts";
import { projectUserProps } from "types/Project";

import UserAvatar from "../UserAvatar/UserAvatar";
import styles from "./UserBlock.module.sass";

const UserBlock = ({ user, isChosen, variant }) => {
  return (
    <div className={classNames(styles.user, styles[variant])}>
      <UserAvatar icon={user.icon} diameter={24} />
      <div className={styles.name}>{`${user.name} ${user.sname ? user.sname : ""}`}</div>
      {isChosen !== undefined ? (
        isChosen ? (
          <CheckIcon className={styles.check} />
        ) : (
          <div className={styles.noCheck} />
        )
      ) : null}
    </div>
  );
};

export default UserBlock;

UserBlock.propTypes = {
  isChosen: PropTypes.bool,
  user: PropTypes.oneOfType([selectedItemProps, projectUserProps]),
  variant: PropTypes.string
};
