import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { useActions } from "hooks/useActions";
import md5 from "md5";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ISafe } from "models/store/safe/safe";
import React, { useEffect } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import AlertModal from "../../AlertModal/AlertModal";
import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./AddSafe.module.sass";

interface IAddSafeProps {
  onClose: () => void;
  type: SAFE_MODALS;
}

const AddSafe: React.FC<IAddSafeProps> = ({ onClose, type }) => {
  const { __ } = useLocales();
  const { onCreateSafe, onSetMoveToSafeModal } = useActions();
  const {
    safeModals: { callbackType },
    moveToSafe: { file }
  } = useGlobalModalsSelectors();
  const _isFirstSafe = type === SAFE_MODALS.ADD_FIRST_SAFE;

  const { value: name, onChange: onChangeName } = useInputValue();
  const { value: pass, onChange: onChangePass, error: passError, onError: onPassError } = useInputValue();
  const { value: checkPass, onChange: onChangeCheckPass, error: checkError, onError: onCheckError } = useInputValue();
  const { value: clue, onChange: onChangeClue } = useInputValue();
  useEffect(() => {
    if (_isFirstSafe) {
      onChangeName(__("Мой сейф"));
    }
  }, []); //eslint-disable-line
  const isDisabled = (): boolean => !name || !pass || !checkPass;

  const onValidPass = (): void => {
    if (pass.length < 8) onPassError(__("Пароль должен быть не менее 8-ми символов"));
  };

  const onValidCheckPass = (): void => {
    if (pass !== checkPass) {
      onCheckError(__("Пароли не совпадают"));
    }
  };

  const onMoveToSafe = (safe: ISafe) => {
    onSetMoveToSafeModal({ open: true, safe, file });
  };

  const onSubmit = (): void => {
    if (pass !== checkPass) {
      onCheckError(__("Пароли не совпадают"));
      return;
    }
    const payload = {
      name,
      pass: md5(pass),
      prim: clue ? clue : undefined,
      is_system: _isFirstSafe ? "1" : "0"
    };
    const cb = callbackType === SAFE_CALLBACK_MODAL.MOVE_TO_SAFE ? onMoveToSafe : null;
    onCreateSafe(payload, cb);
  };

  return (
    <div className={styles.wrap}>
      <HeaderModal onClose={onClose} title={_isFirstSafe ? __("Установка пароля") : __("Создать сейф")} />
      <AlertModal
        title={_isFirstSafe ? __("Установите пароль для вашего сейфа") : null}
        text={__(
          "Пароль к сейфу используется для шифрования данных. Потеря пароля приведет к невозможности восстановления доступа к зашифрованным данным."
        )}
      />
      <form className={styles.form} autoComplete="new-password">
        <div className={styles.inputBox}>
          <Input
            value={name}
            onChange={onChangeName}
            label={__("Имя")}
            placeholder={__("Введите")}
            readOnly={_isFirstSafe}
            require
          />
        </div>
        <div className={styles.inputBox}>
          <Input
            value={pass}
            onChange={onChangePass}
            label={__("Пароль")}
            placeholder={__("Введите")}
            require
            isPass
            error={Boolean(passError)}
            errorText={passError}
            onBlur={onValidPass}
          />
        </div>
        <div className={styles.inputBox}>
          <Input
            value={checkPass}
            onChange={onChangeCheckPass}
            label={__("Повторите пароль")}
            placeholder={__("Введите")}
            require
            isPass
            error={Boolean(checkError)}
            errorText={checkError}
            onBlur={onValidCheckPass}
          />
        </div>
        <div className={styles.inputBox}>
          <Input value={clue} onChange={onChangeClue} label={__("Подсказка")} placeholder={__("Введите")} />
        </div>
      </form>
      <div className={styles.btns}>
        <Button
          onClick={onClose}
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.LARGE}
          text={__("Отменить")}
          type="button"
        />
        <Button
          onClick={onSubmit}
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.LARGE}
          text={__("Создать")}
          type="button"
          disabled={isDisabled()}
        />
      </div>
    </div>
  );
};

export default AddSafe;
