import { ReactComponent as MoreIcon } from "assets/PrivateCabinet/more.svg";
import { IProgram } from "models/store/programs/programs";

import styles from "./ProgramIcon.module.sass";

const ProgramIcon = ({ item }: { item: IProgram }) => {
  return (
    <a className={styles.container} href={item.link}>
      <div className={styles.iconWrapper}>{item.icon ? <img src={item.icon} alt="ico" /> : <MoreIcon />}</div>
      <div className={styles.text}>
        <div>{item.name}</div>
        <div>{item.link}</div>
      </div>
    </a>
  );
};

export default ProgramIcon;
