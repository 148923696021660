import classNames from "classnames";
import React, { useEffect } from "react";

import styles from "./PopUp.module.sass";

interface IPopUpProps {
  set?: Function;
  position?: "right-bottom" | "center" | "top" | "right-top";
  children: React.ReactNode;
  disableCloseModal?: boolean;
  display?: string;
  zIndex?: number;
  background?: string;
  top?: number;
}

const PopUp: React.FC<IPopUpProps> = ({
  set,
  position,
  disableCloseModal,
  display,
  zIndex,
  background,
  children,
  top
}) => {
  useEffect(() => {
    document.addEventListener("keydown", onCloseEsc);
    return () => document.removeEventListener("keydown", onCloseEsc);
  }, []); // eslint-disable-line

  const onCloseEsc = (e: KeyboardEvent): void => {
    if (e.code === "Escape") {
      set();
    }
  };

  const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (disableCloseModal) return;
    if (e.target === e.currentTarget) {
      set();
    }
  };

  return (
    <div
      id="popUp"
      className={classNames(styles.overlay, {
        [styles.rightBottom]: position === "right-bottom",
        [styles.rightTop]: position === "right-top",
        [styles.top]: position === "top"
      })}
      style={{
        display: display ? display : undefined,
        background: background,
        zIndex: zIndex,
        paddingTop: top ? top : undefined,
        paddingBottom: top ? top : undefined
      }}
      onMouseDown={onClickHandler}
    >
      <div className={styles.content} style={{ zIndex: `${(zIndex ? zIndex : 100) + 1}` }}>
        {children}
      </div>
    </div>
  );
};

export default PopUp;
