import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classNames from "classnames";
import CheckBoxNew from "generalComponents/CheckBoxNew/CheckBoxNew";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import SearchField from "generalComponents/SearchField/SearchField";
import { ISprint } from "models/store/joinProjects/joinProgects";
import { MouseEvent, useState } from "react";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { getConvertFormatDateTime } from "utils/getConvertDateTime";

import styles from "./SelectAnalyticsSprint.module.sass";

const SelectAnalyticsSprint = (): JSX.Element => {
  const { __ } = useLocales();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    userInfo: { lang }
  } = useUserSelectors();
  const { project } = useJoinProjectsSelectors();

  const selectedSprints = searchParams.get("sprint");
  const [show, setShow] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [_sprints, set_Sprints] = useState<ISprint[]>(
    selectedSprints ? project.sprints.filter((el) => selectedSprints.split(",").includes(el.id)) : []
  );

  const onSelectSprint = () => {
    if (_sprints.length === 0) {
      searchParams.delete("sprint");
    } else {
      searchParams.set("sprint", _sprints.map((s) => s.id).join(","));
    }
    setSearchParams(searchParams);
  };

  const handleChange = (sprint: ISprint) => {
    if (!sprint) {
      set_Sprints([]);
      return;
    }
    if (_sprints.findIndex((s) => s.id === sprint.id) >= 0) {
      set_Sprints((prev) => prev.filter((s) => s.id !== sprint.id));
      return;
    }
    set_Sprints([..._sprints, sprint]);
  };

  const renderOverlay = () => {
    return (
      <div>
        <SearchField value={value} setValue={setValue} placeholder={__("Ведите")} />

        <ul className={styles.participants}>
          <li className={styles.item} onClick={() => handleChange(null)}>
            <CheckBoxNew isChecked={_sprints.length === 0} />
            <span>{__("Все время")}</span>
          </li>
          {project.sprints.map((item) => {
            const { date } = getConvertFormatDateTime({
              dateStart: item?.date_start,
              dateEnd: item?.date_end,
              locale: lang
            });
            return (
              <li key={item.id} className={styles.item} onClick={() => handleChange(item)}>
                <CheckBoxNew isChecked={_sprints.findIndex((s) => s.id === item.id) >= 0} />
                <span>{item.name}</span>
                <span className={styles.date}>{date}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <PopoverOverlay
      width={280}
      placement="bottom"
      overlayContent={renderOverlay()}
      show={show}
      setShow={setShow}
      btn="extra"
      onExit={onSelectSprint}
    >
      <div
        className={classNames(styles.label, { [styles.active]: show || selectedSprints })}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <p className={styles.value}>{_sprints.length ? _sprints.map((s) => s?.name).join(", ") : __("Все время")}</p>

        <ArrowIcon className={classNames(styles.arrow, { [styles.arrowOpen]: show })} />
      </div>
    </PopoverOverlay>
  );
};

export default SelectAnalyticsSprint;
