import { renderEmptyTable } from "generalComponents/Services/projectServices";
import { IDocsProject } from "models/store/projects/projectStore";
import { useLocales } from "react-localized";
import { v4 as uuid } from "uuid";

export const useDefaultTemplates = (): IDocsProject[] => {
  const { __ } = useLocales();
  return [
    {
      id: uuid(),
      is_template: 1,
      name: __("Правила проекта"),
      data: [
        { id: uuid(), content: "", section: __("Правила взаимодействия") },
        { id: uuid(), content: "", section: __("Правила работы") },
        { id: uuid(), content: "", section: __("Правила совещаний") }
      ],
      inputs: [],
      numberedData: [],
      footer: 0
    },

    {
      id: uuid(),
      is_template: 2,
      name: __("Статут проекта"),
      data: [
        {
          id: uuid(),
          content: "",
          section: __("Вступление"),
          tooltip: `<p>${__("Раздел должен ответить на вопрос - ")}<strong>${__(
            "«Для чего и зачем создавать этот документ»."
          )}<strong></p>`
        }
      ],
      inputs: [],
      numberedData: [
        {
          id: uuid(),
          content: "",
          section: __("1. Назначение и обоснование проекта"),
          data: null,
          tooltip: `<p>${__("Раздел должен ответить на вопрос - ")}<strong>${__(
            "«Для чего и зачем инициировать проект?»."
          )}<strong></p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("2. Цели проекта и соответствующие критерии успеха"),
          data: null,
          tooltip: `<p>${__("Раздел должен ответить на вопрос - ")}<strong>${__(
            "«Что нужно достичь в результате проекта? Как и чем будем измерять эти результаты?"
          )}<strong></p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("3. Описание результатов и содержания высокого уровня проекта"),
          data: [
            { id: uuid(), content: "", section: __("3.1. Конечные результаты:") },
            {
              id: uuid(),
              content: "",
              section: __("3.2. Объем проекта включает следующие работы и виды работ:")
            },
            { id: uuid(), content: "", section: __("3.3. Вне объема проекта:") }
          ],
          tooltip: `<p>${__("Раздел должен ответить на вопрос - ")}<strong>${__(
            "«Как будут выглядеть результаты проекта?»."
          )}<strong></p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("4. Риски высокого уровня"),
          data: null,
          tooltip: `<p>${__("Раздел должен ответить на вопрос - ")}<strong>${__(
            "«Что или кто и как может остановить проект или помешать реализации проекта?»."
          )}<strong></p>`
        },
        {
          id: uuid(),

          content: `
           <table>
             <thead>
               <tr>
                 <th>${__("Даты")}</th>
                 <th>${__("Событие")}</th>
                 <th>${__("Объем")}</th>
               </tr>
             </thead>            
             <tbody>
              ${renderEmptyTable(3)}
             </tbody>
           </table>`,
          section: __("5. Расписание контрольных событий (вех) "),
          data: null,
          tooltip: `<p>${__("Раздел должен ответить на вопрос - ")}<strong>${__(
            "«Какие будут важнейшие события проекта?». "
          )}<strong></p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("6. Бюджет"),
          data: null,
          tooltip: `<p>${__("Раздел должен ответить на вопрос - ")}<strong>${__(
            "«Какие статьи расходов будет содержать бюджет проекта?»."
          )}<strong></p>`
        },
        {
          id: uuid(),

          content: `
           <table>
             <thead>
               <tr>
                 <th>${__("Имя Фамилия")}</th>
                 <th>${__("Роль")}</th>
                 <th>${__("Область ответственности")}</th>
                 <th>${__("Контактные данные")}</th>
               </tr>
             </thead>
             <tbody>
             ${renderEmptyTable(4)}
             </tbody>
           </table>`,
          section: __("7. Руководитель и команда проекта"),
          data: null,
          tooltip: `<p>${__("Раздел должен ответить на вопрос - ")}<strong>${__(
            "«Кто отвечает за успешную реализацию проекта (внутренние, внешние)? Что им для этого необходимо (полномочия)?»."
          )}<strong></p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("8. Спонсор проекта"),
          data: null,
          tooltip: `<p>${__("Раздел должен ответить на вопрос - ")}<strong>${__(
            "«За счет каких людей и организаций проект будет наполняться ресурсами и деньгами?»."
          )}<strong></p>`
        },
        {
          id: uuid(),

          content: `
           <table>
             <thead>
               <tr>
                 <th>${__("Версия")}</th>
                 <th>${__("Дата")}</th>
                 <th>${__("Автор")}</th>
                 <th>${__("Изменения")}</th>
               </tr>
             </thead>
             <tbody>
             ${renderEmptyTable(4)}
             </tbody>
           </table>`,
          section: __("9. История перемен"),
          data: null
        }
      ],
      footer: 0
    },

    {
      id: uuid(),
      is_template: 2,
      name: __("Бизнес-кейс"),
      data: [],
      footer: 0,
      inputs: [
        { id: uuid(), content: "", section: __("Название проекта:") },
        { id: uuid(), content: "", section: __("Менеджер проекта:	") }
      ],
      numberedData: [
        {
          id: uuid(),
          content: "",
          section: __("1. Предпосылки"),
          data: null,
          tooltip: `<p>${__("Опишите предпосылки для проекта. Они включают:")}</p>
          <ul>
            <li>${__("Дата потенциального формирования проекта")}</li>
            <li>${__("Способ формирования и уровень утверждения")}</li>
            <li>${__("Общая бизнес-проблема, к которой адресован проект.")}</li>
          </ul>`
        },
        {
          id: uuid(),
          content: "",
          section: __("2. Текущий обзор бизнеса"),
          data: null,
          tooltip: `<p>${__(
            "Объясните текущую возможность/ситуацию/проблемы/помехи и почему ее следует изменить или улучшить. Возможно, вам придется объяснить какие-либо организационные проблемы, юридические вопросы и т.д. Кроме того, может возникнуть необходимость объяснить последствия бездействия."
          )}</p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("3. Стратегия"),
          data: null,
          tooltip: `<p>${__(
            "Объясните свою стратегию использования возможностей или решения проблем в широком смысле, используя заголовки ниже, если это уместно. При необходимости вы можете предложить варианты разбирательства"
          )}</p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("4. Обзор"),
          data: null,
          tooltip: `<p>${__("Предоставьте исчерпывающее описание решения")}</p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("5. Проблемы"),
          data: null,
          tooltip: `<p>${__(
            "Перечислите какие-либо вопросы, которые следует учитывать при рассмотрении возможной реализации вышеупомянутого"
          )}</p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("6. Ограничение"),
          data: null,
          tooltip: `<p>${__(
            "Перечислите какие-либо ограничения, которые следует учитывать при рассмотрении возможной реализации вышеупомянутого"
          )}</p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("7. Риски"),
          data: null,
          tooltip: `<p>${__(
            "Перечислите любые риски (например, коммерческие, безопасные, нормативные), которые следует учитывать при рассмотрении возможной реализации вышеупомянутого"
          )}</p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("8. Предположение"),
          data: null,
          tooltip: `<p>${__(
            "Перечислите какие-либо предположения, которые следует учитывать при рассмотрении возможной реализации вышеупомянутого"
          )}</p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("9. Финансовые расходы"),
          data: null,
          tooltip: `<p>${__(
            "Вычислите финансовые расходы, связанные с решением. Прежде всего, важно определить текущие расходы/потребности в поддержку после завершения проекта (например, ежегодные платы за лицензию на программное обеспечение)."
          )}</p>
          <p>${__("Примеры финансовых затрат включают:")}</p>
        <ul>
          <li>${__("Единовременные затраты на проект")}</li>
          <li>${__("Постоянные расходы на поддержку")}</li>
        </ul>`
        },
        {
          id: uuid(),
          content: "",
          section: __("10. Нефинансовые расходы"),
          data: null,
          tooltip: `<p>${__(
            "Определите все нефинансовые расходы, связанные с решением. Примеры нефинансовых расходов включают:"
          )}</p>
          <ul>
            <li>${__("Временное сокращение обслуживания при внедрении")}</li>
          </ul>`
        },
        {
          id: uuid(),
          content: "",
          section: __("11. Финансовые преимущества"),
          data: null,
          tooltip: `<p>${__(
            "Вычислите финансовые преимущества, связанные с решением. Примеры финансовых выгод включают:"
          )}</p>
          <ul>
            <li>${__("Доход, возможности, услуги, генерация зачисления")}</li>
            <li>${__("Уменьшение затрат")}</li>
          </ul>`
        },
        {
          id: uuid(),
          content: "",
          section: __("12. Нефинансовые преимущества"),
          data: null,
          tooltip: `<p>${__(
            "Определите все другие нефинансовые преимущества, связанные с решением. Примеры нефинансовых преимуществ включают:"
          )}</p>
          <ul>
            <li>${__("Улучшенная организационная культура")}</li>
            <li>${__("Улучшенный имидж")}</li>
            <li>${__("Улучшенное удовлетворение клиентов/студентов")}</li>
            <li>${__("Эффективность в деловой практике")}</li>
          </ul>`
        },
        {
          id: uuid(),
          content: "",
          section: __("13. Продолжительность"),
          data: null,
          tooltip: `<p>${__("Определите вероятность продолжительности проекта для решения.")}</p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("14. Рекомендации"),
          data: null,
          tooltip: `<p>${__("Определите все другие нефинансовые преимущества, связанные с решением. ")}</p>
          <p>${__("Примеры рекомендаций включают:")}</p>
        <ul>
          <li>${__("Немедленно утвердить рекомендуемый вариант")}</li>
          <li>${__("Немедленно утвердить сроки применения")}</li>
          <li>${__("Следующие шаги (как определено ниже) немедленно утверждаются к выполнению")}</li>
          <li>${__("Необходимый бюджет немедленно выделяется для того, чтобы взять первоначальный объем")}</li>
          <li>${__("Немедленно выделить руководителя проекта для перехода к следующему этапу")}</li>
          <li>${__("Немедленно выделить необходимые ресурсы для перехода на следующий этап")}</li>
        </ul>`
        },
        {
          id: uuid(),
          content: "",
          section: __("15. Следующие шаги"),
          data: null,
          tooltip: `<p>${__(
            "После того, как на основе информации, содержащейся в настоящем документе, будет согласован и установлен дальнейший путь, необходимо назначить руководителя проекта (если он еще не установлен) и создать Сферу проекта."
          )}</p>`
        },
        {
          id: uuid(),
          content: `
           <table>
             <thead>
               <tr>
                 <th>${__("Версия #")}</th>
                 <th>${__("Описание изменений")}</th>
                 <th>${__("Дата")}</th>
                 <th>${__("Автор")}</th>
               </tr>
             </thead>
             <tbody>
             ${renderEmptyTable(4)}
             </tbody>
           </table>`,
          section: __("16. Контроль документа"),
          data: null
        }
      ],
      tooltip: `<p>${__("Целью этого раздела является краткий обзор всего бизнес-кейса.")}</p>`
    },

    {
      id: uuid(),
      is_template: 2,
      name: __("Журнал предположений"),
      data: [
        {
          id: uuid(),
          content: `<table style="width: 100%">
           <thead>
             <tr>
               <th colspan=5>${__("Проект:")}</th>
               <th colspan=2>${__("Дата:")}</th>
             </tr>          
             <tr>
               <th>ID</th>
               <th>${__("Категория")}</th>
               <th>${__("Предположение")}</th>
               <th>${__("Ответственный")}</th>
               <th>${__("Дата подтверждения")}</th>
               <th>${__("Статус")}</th>
               <th>${__("Действия")}</th>
             </tr>
           </thead>
           <tbody>
           ${renderEmptyTable(7)}
           </tbody>
           </table>`,
          section: __("Журнал предположений"),
          tooltip: `
            <p>
              <strong>ID</strong>
              ${__(" - Каждое предположение должно иметь соответствующий идентификационный номер.")}
            </p>
            <p>
              <strong>${__("Категория")}</strong>
              ${__(" - Здесь должно быть указано, на какую часть проекта влияет предположение.")}
            </p>
            <p>
              <strong>${__("Предположение")}</strong>
              ${__(" - Определите предположение в этом столбце.")}
            </p>
            <p>
              <strong>${__("Ответственный")}</strong>
              ${__(" - Предположения должны быть назначены члену команды для проверки.")}
            </p>
            <p>
              <strong>${__("Дата подтверждения")}</strong>
              ${__(" - Это дата, до которой должно быть подтверждено предположение.")}
            </p>
            <p>
              <strong>${__("Статус")}</strong>
              ${__(" - Это отслеживает проверку предположения и то, открыто оно или закрыто.")}
            </p>
            <p>
              <strong>${__("Действия")}</strong>
              ${__(" - Здесь должны быть перечислены любые действия, связанные с допущением или проверкой допущения.")}
            </p>
            `
        }
      ],
      inputs: [
        { id: uuid(), content: "", section: __("Название компании:") },
        { id: uuid(), content: "", section: __("Адресс:") }
      ],
      numberedData: [],
      footer: 0,
      tooltip: `<p>${__(
        "Журнал предположений — это документ, который менеджер проекта и команда используют для регистрации, документирования и отслеживания предположений на протяжении всего жизненного цикла проекта. Предположения являются важной частью любого проекта. Предположения обычно требуют некоторой последующей проверки или проверки, чтобы определить, повлияют ли они на проект. Многие допущения могут фактически быть рисками проекта или могут стать рисками в течение срока действия проекта. В дополнение к созданию журнала предположений обязательно выполните надлежащее управление рисками в своем проекте с помощью плана управления рисками и реестра рисков. Журнал предположений следует использовать для дополнения вашего реестра рисков — его никогда нельзя использовать вместо реестра рисков."
      )}</p>
      <p>${__(
        "У каждого предположения должен быть владелец или член команды, ответственный за контроль и проверку предположения. В журнале допущений каждому допущению присваивается идентификатор или справочный номер, имя и описание каждого допущения, ответственное лицо, срок выполнения, статус, дата закрытия и любые действия, которые могут потребоваться в рамках последующих действий или проверки."
      )}</p>
      <p>${__(
        "Журнал предположений следует обновлять по мере закрытия элементов или получения дополнительной информации. Команда проекта также должна регулярно просматривать журнал предположений, чтобы убедиться, что все члены команды проекта проинформированы и зафиксированы любые дополнительные действия или информация."
      )}</p>`
    },

    {
      id: uuid(),
      is_template: 2,
      name: "Project Charter",
      data: [],
      footer: 1,
      inputs: [
        { id: uuid(), content: "", section: __("Название компании:") },
        { id: uuid(), content: "", section: __("Руководитель проекта:") },
        { id: uuid(), content: "", section: __("Спонсор:") },
        { id: uuid(), content: "", section: __("Исполнительный комитет:") },
        { id: uuid(), content: "", section: __("Версия документа и дата:") },
        { id: uuid(), content: "", section: __("Организация:") }
      ],
      numberedData: [
        {
          id: uuid(),
          content: `
           <table>
             <thead>
               <tr>
                 <th>${__("Номер ревизии")}</th>
                 <th>${__("Дата выдачи")}</th>
                 <th>${__("Авторы")}</th>
                 <th>${__("Краткое описание")}</th>
               </tr>
             </thead>
             <tbody>
             ${renderEmptyTable(4)}
             </tbody>
           </table>`,
          section: __("1. Контроль изменений документов"),
          data: null
        },
        {
          id: uuid(),
          content: "",
          section: __("2. Объем проекта"),
          data: [
            {
              id: uuid(),
              content: "",
              section: __("2.1. Деловая потребность:"),
              tooltip: `<p>${__(
                "Предоставьте краткий обзор и справочную информацию о проекте. Объясните в высокий уровень, чего должен достичь проект, кто инициировал проект и ожидал выгоды (как материальные, так и нематериальные)."
              )}</p>`
            },
            {
              id: uuid(),
              content: "",
              section: __("2.2. Цели проекта:"),
              tooltip: `<p>${__("Определите ключевые цели проекта.")}</p>`
            },
            {
              id: uuid(),
              content: "",
              section: __("2.3. Описание продукта:"),
              tooltip: `<p>${__("Опишите продукт или услугу, которую должен предоставить проект.")}</p>`
            },
            {
              id: uuid(),
              content: "",
              section: __("2.4. В рамках /вне объем:"),
              tooltip: `<p>${__(
                "Масштаб устанавливает границы проекта. Введите те ключевые элементы, которые являются частью проект и те основные элементы, над которыми не будут работать в рамках этого проекта. Это высокоуровневое описание того, что находится внутри и что находится за пределами границ проект."
              )}</p>`
            },
            {
              id: uuid(),
              content: "",
              section: __("2.5. Критический успех, Факторы:"),
              tooltip: `<p>${__(
                "Определите критические факторы успеха проекта. Критическими факторами успеха являются. Результаты, которые должны быть достигнуты, чтобы проект считался успешным."
              )}</p>`
            },
            {
              id: uuid(),
              content: "",
              section: __("2.6. Предложения проекта:"),
              tooltip: `<p>${__(
                "Перечислите все предположения, сделанные при определении проекта. Предположения могут повлиять на любую область проекта, включая объем, заинтересованные стороны, бизнес-цели и функциональные Требования."
              )}</p>`
            },
            {
              id: uuid(),
              content: "",
              section: __("2.7. Ограничения проекта:"),
              tooltip: `<p>${__(
                "Обобщите ключевые ограничения, которые будут служить ограничениями и границами для команда проекта в принятии решений.	"
              )}</p>`
            },
            {
              id: uuid(),
              content: "",
              section: __("2.8. Результаты проекта:"),
              tooltip: `<p>${__("Перечислите основные результаты проекта с кратким описанием каждого.")}</p>`
            }
          ]
        },
        {
          id: uuid(),
          content: "",
          section: __("3. Требования"),
          data: null,
          tooltip: `<p>${__(
            "Определите функциональные требования здесь. Функциональные требования описывают, какими характеристиками должен обладать предлагаемый продукт или услуга, и предоставляют список минимальных функций, которые должны быть реализованы после завершения проекта."
          )}</p>`
        },

        {
          id: uuid(),
          content: `
             <table>
               <thead>
                 <tr>
                   <th>${__("Веха/Задача")}</th>
                   <th>${__("Дата завершения")}</th>
                 </tr>
               </thead>
               <tbody>
               ${renderEmptyTable(2)}
               </tbody>
             </table>`,
          section: __("4. Вехи высокого уровня и временная шкала"),
          data: null,
          tooltip: `<p>${__(
            "Вехи — это результаты или основные события, которые можно легко определить как завершенные или не завершенные к определенной дате. Вехи проекта — это пороговые значения «как у нас дела», которые показывают, находится ли проект на пути к ожидаемому завершению."
          )}</p>
          <p>${__("Введите ключевые вехи проекта с предполагаемой датой завершения вехи.")}</p>`
        },
        {
          id: uuid(),
          content: `
             <table>
               <thead>
                 <tr>
                   <th>${__("Роль")}</th>
                   <th>${__("Проект Ответственный")}</th>
                   <th>${__("Требуются навыки")}</th>
                   <th>${__("Стоимость")}</th>
                   <th>${__("Рекомендации")}</th>
                 </tr>
               </thead>
               <tbody>
               ${renderEmptyTable(5)}
               </tbody>
             </table>`,
          section: __("5. Роли высокого уровня"),
          data: null,
          tooltip: `<p>${__(
            "Введите роли и ответственность за проект для каждой роли, необходимой для проекта. Введите навыки, необходимые для роли. Введите предварительные оценки FTE для выделения ресурсов на финансовый год. Введите общую сумму FTE за финансовый год. Введите планируемый или рекомендуемый источник для получения ресурса. "
          )}</p>
          <p>${__("Пример: Подрядчик или название организации, которая может предоставить ресурс.")}</p>`
        },
        {
          id: uuid(),

          content: `
         <table>
           <thead>
             <tr>
               <th>${__("Тип стоимости")}</th>
               <th>${__("Стоимость")}</th>
               <th>${__("Общее")}</th>
             </tr>
           </thead>
           <tbody>
           ${renderEmptyTable(3)}
           </tbody>
         </table>`,
          section: __("6. Бюджет высокого уровня"),
          data: null,
          tooltip: `<p>${__(
            "Введите предварительную информацию о бюджете, необходимую для поддержки проекта. Включите затраты на рабочую силу, материалы и другие статьи расходов. Если ваш проект требует текущих затрат на техническое обслуживание и поддержку, добавьте эту информацию в общий бюджет. Предварительный бюджет также может быть разбит по основным этапам или ключевым результатам/вехам проекта."
          )}</p>`
        },
        {
          id: uuid(),
          content: "",
          section: __("7. Коммуникационная стратегия"),
          data: null,
          tooltip: `<p>${__(
            "Коммуникационная стратегия обеспечивает руководство по достижению конечной цели коммуникации проекта.Разработайте заявление высокого уровня об общем подходе к коммуникациям по ключевым вопросам:"
          )}</p>
          <ul>
            <li>${__("Коммуникационные цели проекта")}</li>
            <li>${__("Ключевое коммуникационное сообщение")}</li>
            <li>${__("Ключевые аудитории")}</li>
          </ul>`
        },
        {
          id: uuid(),
          content: "",
          section: __("8. Стратегия управления изменений"),
          data: [
            {
              id: uuid(),
              content: "",
              section: __("8.1. Зоны риска:"),
              tooltip: `</p>${__("Опишите на высоком уровне наиболее значимые области риска для этого проекта.")}</p>`
            },
            {
              id: uuid(),
              content: "",
              section: __("8.2. Критические риски и пороги риска заинтересованных сторон:"),
              tooltip: `</p>${__(
                "Включите этот блок, если заинтересованные стороны определили области риска, где либо риск вообще недопустим, либо порог риска для которых очень низок."
              )}</p>`
            },
            {
              id: uuid(),
              content: "",
              section: __("8.3. Цели управления рисками:"),
              tooltip: `</p>${__("Определите общие цели проекта по управлению рисками.")}</p>`
            },
            {
              id: uuid(),
              content: "",
              section: __("8.4. Процес управления рисками высокого уровня:"),
              tooltip: `</p>${__(
                "Определите на высоком уровне процесс, которому необходимо следовать для обеспечения достижения целей управления рисками на протяжении всего жизненного цикла проекта."
              )}</p>`
            },
            {
              id: uuid(),
              content: "",
              section: __("8.5. Лица принимающие решения о рисках:"),
              tooltip: `</p>${__(
                "Определите роли для обеспечения достижения целей управления рисками на протяжении всего жизненного цикла проекта"
              )}</p>`
            }
          ],
          tooltip: `<p>${__(
            "Стратегия управления изменениями представляет собой высокоуровневое изложение общего подхода к вопросам управления изменениями для следующих ключевых элементов:"
          )}</p>
          <ul>
            <li>${__("Ожидаемые проблемы управления изменениями")}</li>
            <li>${__("Ключевые ограничения проекта")}</li>
            <li>${__("Ключевые методы работы с клиентами по управлению изменениями")}</li>
            <li>${__("Краткое изложение методов управления изменениями")}</li>
          </ul>`
        }
      ]
    }
  ];
};
