import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { ITopMessages } from "models/common/common";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { initialProfileModalState } from "models/store/Cabinet/modals/profileModals";
import { IContact, ITag, IUserInfo, IUserStorage, STATUS_COLOR, USER_STATUS } from "models/store/user/user";
import {
  IGetContacts,
  IGetUserInfo,
  ILogUser,
  ISetEmployees,
  ISetTags,
  ISetUsersStorage,
  IUpdateUserInfo,
  UserAction
} from "models/store/user/userActions";
import {
  IAddContactPayload,
  IAddEmployeePayload,
  IAddTagPayload,
  IAddToFavoriteContactPayload,
  IBlockEmployeePayload,
  IDelContactPayload,
  IDelEmployeePayload,
  IDeletesRestoresUserAccountPayload,
  IEditTagPayload,
  IEditUserSettingsPayload,
  IEditUserUnfoPayload,
  IGetContactsPayload,
  IRetryEmployeePayload,
  IUpdateContactPayload
} from "models/store/user/userPayloads";
import { ThunkAction } from "redux-thunk";

import api from "../../api";
import { ModalActions } from "../../models/store/Cabinet/modals/modalActions";
import { RootState } from "../reducers";
import { UserTypes } from "../types/userTypes";
import { onSetProfileModal, onSetTopMessageModal } from "./ModalActions";

export const onGetUserInfo = (): ThunkAction<void, RootState, unknown, UserAction> => async (dispatch, getState) => {
  try {
    const params = {
      uid: getState().user.uid
    };
    const { data } = await api.get("/ajax/user_get.php?", { params });
    if (data.data.is_block) {
      dispatch(onLog(""));
      return;
    }
    dispatch(onUserInfo(data.data));
    dispatch(onSetUsersStorage(data?.users_storage));
  } catch (error) {
    console.log(error);
  }
};

export const onChangeSettings =
  (
    payload: IUserInfo | any,
    cb: () => void,
    messages?: ITopMessages
  ): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState): Promise<void> => {
    try {
      const formData = new FormData();
      payload?.theme && formData.set("theme", payload.theme);
      payload?.lang && formData.set("lang", payload.lang);
      payload?.notify && formData.set("notify", JSON.stringify(payload.notify));
      formData.set("uid", getState().user.uid);
      payload?.trash_minutes_del >= 0 && formData.set("trash_minutes_del", JSON.stringify(payload.trash_minutes_del));

      const { data } = await api.post(`/ajax/user_edit2.php`, formData);
      if (data.ok === 1 || data.ok === true) {
        dispatch(onUserInfo(data.data));

        if (messages?.success) {
          dispatch(
            onSetTopMessageModal({
              open: true,
              type: ITopMessageTypes.SUCCESS,
              message: messages.success
            })
          );
        }
        dispatch({
          type: UserTypes.UPDATE_SETTINGS,
          payload: data
        });
        if (typeof cb === "function") cb();
      }
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    }
  };

export const onEditUserInfo =
  (params: IEditUserUnfoPayload): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("fname", params.data.fname);
      payload.append("sname", params.data.sname);
      payload.append("pname", params.data.pname);
      payload.append("email", params.data.email);
      if (params.data.tel) {
        payload.append("tel", params.data.tel);
      }
      payload.append("emails", params.data.emails);
      payload.append("tels", params.data.tels);
      payload.append("gmt", params.data.gmt);
      payload.append("is_gmt_auto", params.data.is_gmt_auto ? "true" : "false");
      payload.append("icon", params.data.icon);
      if (params.data.icon) {
        payload.append("icon", params.data.icon);
      }
      if (params.data.icon_del) {
        payload.append("icon_del", "1");
      }

      const { data } = await api.post("/ajax/user_edit2.php", payload);
      if (data.error === "tel exists") {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: params.message.error }));
        return;
      }
      checkResponseStatus(data.ok);

      dispatch({
        type: UserTypes.USER_INFO,
        payload: data.data
      });
      if (getState().user.userInfo.id === data.data.id) dispatch(onUserInfo(data.data));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: params.message.success }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onChangeUserSettings =
  (payload?: IEditUserSettingsPayload): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        ...payload
      };

      const { data } = await api.get("/ajax/user_edit2.php", { params });
      checkResponseStatus(data.ok);
      dispatch(onUserInfo(data.data));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onDeletesRestoresAdminAccount =
  (
    payload: IDeletesRestoresUserAccountPayload,
    messages?: ITopMessages
  ): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const params = new FormData();
      params.append("uid", getState().user.uid);
      params.append("is_del", payload.is_del.toString());
      payload.html && params.append("html", payload.html);

      const { data } = await api.post("/ajax/user_edit2.php", params);
      checkResponseStatus(data.ok);

      if (Number(data?.data?.is_del) === 1) {
        dispatch(onChangeUserSettings({ status: USER_STATUS.OFFLINE, status_color: STATUS_COLOR.OFFLINE }));
        dispatch(onLog(""));
      } else {
        dispatch({
          type: UserTypes.UPDATE_SETTINGS,
          payload: data?.data
        });
      }
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: messages.error }));
    }
  };

export const getEmployees =
  (controller?: AbortController): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid
      };
      const { data } = await api.get("/ajax/org_user_list.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(setEmployees(data.data.users));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const addEmployee =
  (params?: IAddEmployeePayload): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("fname", params.data.fname);
      payload.append("sname", params.data.sname);
      payload.append("pname", params.data.pname);
      payload.append("email", params.data.email);
      payload.append("tel", params.data.tel);
      payload.append("emails", params.data.emails);
      payload.append("tels", params.data.tels);
      payload.append("rights", params.data.rights);
      payload.append("is_admin", params.data.is_admin);
      payload.append("id_company", params.data.id_company);
      payload.append("html", params.data.html);
      if (params.data.icon) {
        payload.append("icon", params.data.icon);
      }
      const { data } = await api.post("/ajax/org_user_add.php", payload);

      if (data?.error === "user exists") {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: params.message.error }));
        return;
      }
      checkResponseStatus(data.ok);
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: params.message.succes }));
      dispatch(setEmployees(data.data.users));
      dispatch(onSetProfileModal(initialProfileModalState()));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };
export const retryEmployee =
  (payload?: IRetryEmployeePayload): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        ...payload.data
      };
      const { data } = await api.get("/ajax/org_user_retry.php", { params });

      checkResponseStatus(data.ok);
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: payload.message.succes }));
      dispatch(setEmployees(data.data.users));
      dispatch(onSetProfileModal(initialProfileModalState()));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const editEmployee =
  (
    params: IAddEmployeePayload,
    message: ITopMessages,
    callback?: Function
  ): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const payload = new FormData();
      payload.append("uid", params.data.uid ?? getState().user.uid);
      payload.append("fname", params.data.fname);
      payload.append("sname", params.data.sname);
      payload.append("pname", params.data.pname);
      payload.append("email", params.data.email);
      payload.append("tel", params.data.tel);
      payload.append("emails", params.data.emails);
      payload.append("tels", params.data.tels);
      payload.append("is_admin", params.data.is_admin);
      payload.append("id_company", params.data.id_company);
      if (params.data.icon) {
        payload.append("icon", params.data.icon);
      }
      if (params.data.icon_del) {
        payload.append("icon_del", "1");
      }
      if (params.data.rights) {
        payload.append("rights", params.data.rights);
      }

      if (params.data.pass) {
        payload.append("pass", params.data.pass);
      }
      payload.append("id_item", params.data.id_item);

      const { data } = await api.post("/ajax/org_user_edit.php", payload);
      console.log(message.success);
      checkResponseStatus(data.ok);
      if (callback) {
        return callback(data);
      }
      dispatch(setEmployees(data.data.users));
      dispatch(onSetProfileModal(initialProfileModalState()));
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          message: message.success
        })
      );
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };
export const trashEmployee =
  (payload: IDelEmployeePayload): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_item: payload.data.id_item,
        is_del: 1
      };
      const { data } = await api.get("/ajax/org_user_edit.php", { params });
      checkResponseStatus(data.ok);
      dispatch(setEmployees(data.data.users));
      dispatch(onSetProfileModal(initialProfileModalState()));
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          message: payload.message
        })
      );
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const blockEmployee =
  (payload: IBlockEmployeePayload): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_item: payload.data.id_item,
        is_block: payload.data.is_block
      };

      const { data } = await api.get("/ajax/org_user_edit.php", { params });
      checkResponseStatus(data.ok);
      dispatch(setEmployees(data.data.users));
      dispatch(onSetProfileModal(initialProfileModalState()));
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          message: payload.message
        })
      );
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const updateSettings = (settings: IUserInfo): IUpdateUserInfo => ({
  type: UserTypes.UPDATE_SETTINGS,
  payload: settings
});

export const setEmployees = (payload: IUserInfo[]): ISetEmployees => ({
  type: UserTypes.SET_EMPLOYEES,
  payload
});

export const onLog = (payload: string): ILogUser => ({
  type: UserTypes.LOG_DATA,
  payload
});

export const onUserInfo = (payload: IUserInfo): IGetUserInfo => ({
  type: UserTypes.USER_INFO,
  payload
});

export const onSetUsersStorage = (payload: IUserStorage[]): ISetUsersStorage => ({
  type: UserTypes.SET_USERS_STORAGE,
  payload
});

export const getContacts = (): ThunkAction<void, RootState, unknown, UserAction> => async (dispatch, getState) => {
  const params = {
    uid: getState().user.uid
  };
  try {
    const { data } = await api.get<IGetContactsPayload>(`/ajax/contacts_list.php`, { params });
    const resData = data?.data ?? [];
    dispatch(onSetContacts(resData));
  } catch (error) {
    console.log(error);
  }
};

export const addContact =
  (params: IAddContactPayload): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("name", params.name);
      payload.append("sname", params.sname);
      payload.append("company", params.company);
      payload.append("bdate", params.bdate);
      payload.append("prim", params.prim);
      payload.append("email", JSON.stringify(params.email));
      payload.append("tel", JSON.stringify(params.tel));
      payload.append("soc", JSON.stringify(params.soc));
      payload.append("mes", JSON.stringify(params.mes));
      if (params.profileImage) {
        payload.append("file", params.profileImage);
      }
      const { data } = await api.post("/ajax/contacts_add.php", payload);
      checkResponseStatus(data.ok);
      dispatch(onSetContacts(data.data));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const updateContact =
  (params: IUpdateContactPayload): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const payload = new FormData();
      payload.append("uid", getState().user.uid);
      payload.append("name", params.name);
      payload.append("sname", params.sname);
      payload.append("company", params.company);
      payload.append("bdate", params.bdate);
      payload.append("prim", params.prim);
      payload.append("email", JSON.stringify(params.email));
      payload.append("tel", JSON.stringify(params.tel));
      payload.append("soc", JSON.stringify(params.soc));
      payload.append("mes", JSON.stringify(params.mes));
      payload.append("id", params.id);
      if (params.profileImage) {
        payload.append("file", params.profileImage);
      }
      const { data } = await api.post("/ajax/contacts_edit.php", payload);
      checkResponseStatus(data.ok);
      dispatch(onSetContacts(data.data));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };
export const deleteContact =
  (payload: IDelContactPayload): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const { data } = await api.get(`/ajax/contacts_del.php`, {
        params: {
          uid: getState().user.uid,
          id: payload.id
        }
      });
      checkResponseStatus(data.ok);
      dispatch(onSetContacts(data.data));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const addToFavoriteContact =
  (payload: IAddToFavoriteContactPayload): ThunkAction<void, RootState, unknown, ModalActions | UserAction> =>
  async (dispatch, getState) => {
    try {
      const isFav = payload.is_fav ? 0 : 1;
      const { data } = await api.get(`/ajax/contacts_fav.php`, {
        params: {
          uid: getState().user.uid,
          id: payload.id,
          is_fav: isFav
        }
      });
      checkResponseStatus(data.ok);
      dispatch(onSetContacts(data.data));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onSetContacts = (payload: IContact[]): IGetContacts => ({
  type: UserTypes.CONTACT_LIST,
  payload
});

export const onSetTags = (payload: ITag[]): ISetTags => ({
  type: UserTypes.SET_TAG_LIST,
  payload
});

export const getTagList = (): ThunkAction<void, RootState, unknown, UserAction> => async (dispatch, getState) => {
  const params = {
    uid: getState().user.uid
  };
  try {
    const { data } = await api.get(`/ajax/tags_list.php`, { params });
    checkResponseStatus(data.ok);
    dispatch(onSetTags(data.data.tags));
  } catch (error) {
    console.log(error);
  }
};

export const addTag =
  (payload: IAddTagPayload, tags: ITag[]): ThunkAction<void, RootState, unknown, UserAction> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      name: payload.name,
      id_company: payload.id_company
    };
    try {
      const { data } = await api.get(`/ajax/tags_add.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetTags([...tags, data.data.tags]));
    } catch (error) {
      console.error(error);
    }
  };

export const editTag =
  (payload: IEditTagPayload, tags: ITag[]): ThunkAction<void, RootState, unknown, UserAction> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_item: payload.id_item,
      name: payload.name,
      id_company: payload.id_company
    };
    try {
      const { data } = await api.get(`/ajax/tags_edit.php`, { params });
      checkResponseStatus(data.ok);
      const index = tags.findIndex((tag) => tag.id === data.data.tags.id);
      if (index !== -1) {
        const updatedTags = [...tags];
        updatedTags[index] = data.data.tags;

        dispatch(onSetTags(updatedTags));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const deleteTag =
  (payload: string): ThunkAction<void, RootState, unknown, UserAction> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_item: payload
    };
    try {
      const { data } = await api.get(`/ajax/tags_del.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetTags(data.data.tags));
    } catch (error) {
      console.error(error);
    }
  };
