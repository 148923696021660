import { EventAPI } from "api/EventAddAPI";
import CryptoJS from "crypto-js";
import Button from "generalComponents/Button/Button";
import { Textarea } from "generalComponents/Textarea/Textarea";
import { EVENT_TYPE, TYPE_ELEMENTS } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IUserInfo } from "models/store/user/user";
import { ChangeEvent, FC, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./EmployeeSignin.module.sass";

interface IProps {
  closeModal: () => void;
  employee: IUserInfo;
}

const EmployeeSignin: FC<IProps> = ({ closeModal, employee }) => {
  const { __ } = useLocales();
  const [value, setValue] = useState<string>("");
  const { userInfo } = useUserSelectors();
  const encryptedUID = CryptoJS.AES.encrypt(employee.uid, process.env.REACT_APP_CRYPTO).toString();
  const encryptedBase64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptedUID));
  const ref = useRef<HTMLAnchorElement>(null);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setValue(e.currentTarget.value);
  };

  const onSubmit = async () => {
    const params = {
      uid: employee.uid,
      text: value,
      id_user_event: userInfo.id,
      event_type: EVENT_TYPE.EMPLOYEE_ENTER,
      event_group: TYPE_ELEMENTS.ORG_USERS,
      event_object: employee.uid,
      data: [{ email: userInfo.email }]
    };
    const { data } = await EventAPI.add(params);
    if (data.ok) {
      localStorage.setItem("uid", "");
      localStorage.setItem("rememberUser", null);

      ref.current.click();
      closeModal();
    }
  };
  return (
    <div className={styles.wrap}>
      <HeaderModal title={__("Вход под сотрудников")} onClose={closeModal} />
      <div className={styles.body}>
        <Textarea value={value} onChange={onChange} placeholder={__("Введите")} label={__("Укажите причину входа")} />
        <div className={styles.footer}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.MEDIUM}
            onClick={closeModal}
            text={__("Отменить")}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.MEDIUM}
            onClick={onSubmit}
            text={__("Применить")}
            disabled={value.trim().length < 5}
          />
        </div>
      </div>
      <a className="d-none" href={`/${ROUTES.EMPLOYEE}/${encryptedBase64}`} target="_blank" rel="noreferrer" ref={ref}>
        .
      </a>
    </div>
  );
};

export default EmployeeSignin;
