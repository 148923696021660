import { ReactComponent as CloseEyeIcon } from "assets/icons/eye-close.svg";
import { ReactComponent as OpenEyeIcon } from "assets/icons/open-eye.svg";
import classNames from "classnames";
import { ChangeEventHandler, useEffect, useState } from "react";

import styles from "./Input.module.sass";

const Input = ({
  value,
  onChange,
  placeholder,
  icon,
  error,
  type = "text"
}: {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  icon?: JSX.Element;
  error?: string;
  type?: string;
}): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const [position, setPosition] = useState({ left: "25%", top: "30%" });

  const getRandomCombination = () => {
    const combinations = [
      { left: "25%", top: "30%" },
      { left: "75%", top: "30%" },
      { left: "25%", top: "60%" },
      { left: "75%", top: "60%" }
    ];

    const randomIndex = Math.floor(Math.random() * combinations.length);
    return combinations[randomIndex];
  };

  useEffect(() => {
    const randomCombination = getRandomCombination();
    setPosition(randomCombination);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.animation_wrapper}>
        <span className={styles.circle} style={position} />
        {icon && <div className={styles.icon}>{icon}</div>}
        <input
          value={value}
          onChange={onChange}
          className={classNames(styles.input, icon && styles.withIcon, { [styles.error_input]: error })}
          placeholder={placeholder}
          type={type === "password" && showPassword ? "text" : type}
        />
        {type === "password" && (
          <button className={styles.eye} onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
          </button>
        )}
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default Input;
