import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { TASK_MODALS } from "generalComponents/variables/tasks";
import { IDateObject } from "models/generalComponents/calendars";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IMyTask, ITaskComment, TaskFilters } from "models/store/tasks/tasks";
import {
  IAddTasks,
  IEditTasks,
  IFetchTasks,
  ISelectDateFilter,
  ISelectTasks,
  ISelectTypeFilter,
  TasksActions
} from "models/store/tasks/tasksActions";
import { IAddTaskPayload, IEditTaskPayload } from "models/store/tasks/tasksPayloads";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { TasksTypes } from "Store/types/tasksTypes";

import { CabinetActions } from "../../models/store/Cabinet/Cabinet";
import { onSetLoaderModal, onSetTaskModal, onSetTopMessageModal } from "./ModalActions";

export const onSelectFilterType = (type: TaskFilters): ISelectTypeFilter => ({
  type: TasksTypes.SELECT_TYPE_FILTER,
  payload: type
});

export const onAddTask = (payload: IMyTask): IAddTasks => ({
  type: TasksTypes.ADD_TASK,
  payload
});

export const onSelectFilterDate = (date: IDateObject): ISelectDateFilter => ({
  type: TasksTypes.SELECT_DATE_FILTER,
  payload: date
});

export const onUpdateTask = (task: IMyTask): IEditTasks => ({
  type: TasksTypes.EDIT_TASK,
  payload: task
});
export const onSetTasks = (payload: IMyTask[]): IFetchTasks => ({
  type: TasksTypes.FETCH_TASKS,
  payload
});

export const onSelectTask = (data: IMyTask): ISelectTasks => ({
  type: TasksTypes.SELECT_TASK,
  payload: data
});

export const onAddNewTask =
  (payload: IAddTaskPayload): ThunkAction<void, RootState, unknown, TasksActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const params = {
        uid: getState().user.uid,
        ...payload,
        tags: JSON.stringify(payload.tags)
      };
      const { data } = await api.get(`/ajax/task_add.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.ADD }));
      dispatch(onAddTask(data.task));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
      dispatch(onSetTaskModal({ type: TASK_MODALS.NO_MODAL, params: null }));
    }
  };

export const onGetAllTasks =
  (
    message?: string,
    isLoader?: boolean,
    query?: string
  ): ThunkAction<void, RootState, unknown, TasksActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      isLoader && dispatch(onSetLoaderModal(true));

      const params = {
        uid: getState().user.uid,
        search: query ?? ""
      };

      const response = await api.get(`/ajax/task_get.php`, { params });
      checkResponseStatus(response.data.ok);
      dispatch({
        type: TasksTypes.FETCH_TASKS,
        payload: response.data.tasks ?? []
      });
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: message }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onDeleteTask =
  (id: string): ThunkAction<void, RootState, unknown, TasksActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_task: id,
      is_del: 1
    };

    try {
      dispatch(onSetLoaderModal(true));

      const { data } = await api.get(`/ajax/task_edit.php`, { params });
      checkResponseStatus(data.ok);

      dispatch({ type: TasksTypes.DELETE_TASK, payload: data.task.id });
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.IS_DEL }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
      dispatch(onSetTaskModal({ type: TASK_MODALS.NO_MODAL, params: null }));
    }
  };

export const onEditTask =
  (
    payload: IEditTaskPayload,
    typeMessage: EVENT_TYPE,
    isClose: boolean = true,
    isLoader: boolean = true
  ): ThunkAction<void, RootState, unknown, TasksActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      isLoader && dispatch(onSetLoaderModal(true));

      const params = {
        uid: getState().user.uid,
        ...payload,
        tags: JSON.stringify(payload.tags)
      };
      const { data } = await api.get(`/ajax/task_edit.php`, { params });
      checkResponseStatus(data.ok);
      payload.is_archive === "1"
        ? dispatch({ type: TasksTypes.DELETE_TASK, payload: data.task.id })
        : dispatch({ type: TasksTypes.EDIT_TASK, payload: data.task });

      isClose &&
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: typeMessage }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
      isClose && dispatch(onSetTaskModal({ type: TASK_MODALS.NO_MODAL, params: null }));
    }
  };

export const onAddTaskComment =
  (payload: ITaskComment, isClose: boolean): ThunkAction<void, RootState, unknown, TasksActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      let params = new FormData();
      params.append("uid", getState().user.uid);
      params.append("text", payload.text);
      params.append("id_task", payload.id_task);
      // id_item

      const { data } = await api.post(`/ajax/task_com_add.php`, params);
      checkResponseStatus(data.ok);
      dispatch({ type: TasksTypes.ADD_TASK_COMMENT, payload: data.comment });
      isClose &&
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.SUCCESS,
            variantMessage: EVENT_TYPE.ADD
          })
        );
    } catch {
      dispatch(onSetTaskModal({ type: TASK_MODALS.NO_MODAL, params: null }));
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      isClose && dispatch(onSetTaskModal({ type: TASK_MODALS.NO_MODAL, params: null }));
    }
  };
