import { ReactComponent as CommentIcon } from "assets/PrivateCabinet/comm.svg";
import { ReactComponent as CopyIcon } from "assets/PrivateCabinet/copyLink.svg";
import { ReactComponent as FireIcon } from "assets/PrivateCabinet/fire.svg";
import { ReactComponent as ClipIcon } from "assets/PrivateCabinet/mail/clip.svg";
import classNames from "classnames";
import { format } from "date-fns";
import DownArrow from "generalComponents/DownArrow/DownArrow";
import { createMarkup } from "generalComponents/Services/browserServices";
import ThreeDots from "generalComponents/ThreeDots/ThreeDots";
import { URGENCY_TYPES } from "generalComponents/variables/tasks";
import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useSelector } from "react-redux";
import { colorCategoryProps, taskTypes } from "types/Project";

import { getChildrenTask, getDateColor, getParentTask } from "../../helpers";
import Executors from "../Executors/Executors";
import TaskNum from "../TaskNum/TaskNum";
import styles from "./TaskColumn.module.sass";

const TaskColumn = ({
  task,
  accordion,
  changeStatus,
  color,
  setMouseParams,
  status,
  handleDateLong,
  openDependency
}) => {
  const { __ } = useLocales();
  const project = useSelector((s) => s.Projects.project);
  const [showDescription, setShowDescription] = useState(accordion ? false : true);

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <TaskNum num={task.num_epic} color={color.dark} epicId={task.id_epic} />
        {task.tags?.trim() && <p className={styles.role}>{task.tags}</p>}
        <div>
          <div className={styles.icons}>
            {task.comments.length > 0 && <CommentIcon width={14} height={14} />}
            {task?.files_col > 0 && (
              <span className={styles.clip}>
                <ClipIcon /> {task.files_col}
              </span>
            )}
            {(getParentTask(project.tasks, task.id_parent) || getChildrenTask(project.tasks, task.id).length > 0) && (
              <CopyIcon className={styles.icon} width={14} height={14} onClick={openDependency} />
            )}
          </div>
          <ThreeDots
            onClick={(e) => {
              e.stopPropagation();
              setMouseParams({
                x: e.clientX,
                y: e.clientY,
                width: 240,
                height: 25,
                type: "task"
              });
            }}
          />
        </div>
      </div>
      <h3 className={styles.name}>
        {task.id_act === URGENCY_TYPES.URGENT && <FireIcon className={styles.icon} />}
        {task.name}
      </h3>
      {showDescription && (
        <>
          {task.prim && (
            <div className={classNames(styles.block)} style={{ borderColor: color.line }}>
              <p className={styles.subTitle}>{__("Описание:")}</p>
              <div
                className={classNames("htmlContent", styles.text)}
                dangerouslySetInnerHTML={createMarkup(task.prim)}
              />
            </div>
          )}
          <div className={classNames(styles.block, styles.blockFlex)} style={{ borderColor: color.line }}>
            <p className={styles.subTitle}>{__("Статус:")}</p>
            <div className={styles.statusBox}>
              <div className={styles.circle} style={{ background: color.dark }} />
              <span className={styles.status}>{status}</span>
              {changeStatus && (
                <DownArrow
                  isOpen={false}
                  clickHandler={(e) => {
                    e.stopPropagation();
                    setMouseParams({
                      x: e.clientX,
                      y: e.clientY,
                      width: 146,
                      height: 20,
                      type: "status"
                    });
                  }}
                />
              )}
            </div>
          </div>
          {task.id_executor.length > 0 && (
            <div className={classNames(styles.block, styles.user)} style={{ borderColor: color.line }}>
              <p className={styles.subTitle}>{__("Исполнитель :")}</p>
              <Executors executors={task.id_executor} />
            </div>
          )}
          {task.date_start && task.date_end && (
            <div className={classNames(styles.block, styles.blockFlex)} style={{ borderColor: color.line }}>
              <p className={styles.subTitle}>{__("Спринт:")}&nbsp;</p>
              <p className={styles.text} style={getDateColor(task)}>{`${format(
                new Date(task.date_start),
                "dd.MM.yyyy"
              )} - ${format(new Date(task.date_end), "dd.MM.yyyy")}`}</p>
            </div>
          )}
          {task.date_long && (
            <div
              className={classNames(styles.block, styles.blockFlex, styles.cursor)}
              style={{ borderColor: color.line }}
              onClick={handleDateLong}
            >
              <p className={styles.subTitle}>{__("Продленно до:")}&nbsp;</p>
              <p className={styles.text}>{format(new Date(task.date_long), "dd.MM.yyyy")}</p>
            </div>
          )}
        </>
      )}

      {accordion && (
        <div className={styles.arrowBox} onClick={() => setShowDescription((prev) => !prev)}>
          <DownArrow isOpen={showDescription} />
        </div>
      )}
    </div>
  );
};

export default TaskColumn;

TaskColumn.defaultProps = {
  changeStatus: true,
  isMenu: true
};

TaskColumn.propTypes = {
  task: taskTypes,
  accordion: PropTypes.bool,
  changeStatus: PropTypes.bool,
  color: colorCategoryProps,
  setMouseParams: PropTypes.func,
  status: PropTypes.string,
  handleDateLong: PropTypes.func,
  openDependency: PropTypes.func
};
