import { ReactComponent as ClipIcon } from "assets/icons/clip.svg";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useActions } from "hooks/useActions";
import { IFile } from "models/store/files/files";
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { Editor, EditorState as IEditorState } from "react-draft-wysiwyg";
import { useLocales } from "react-localized";
import { v4 as uuidv4 } from "uuid";

import styles from "./TaskPrimEditor.module.sass";

interface IProps {
  prim: string;
  setPrim: (val: string) => void;
  setUploadFiles: Dispatch<SetStateAction<(IFile | { file: File; fid: string })[]>>;
}

const TaskPrimEditor: FC<IProps> = ({ prim, setPrim, setUploadFiles }) => {
  const { __ } = useLocales();
  const { onSetSelectFileModal } = useActions();
  const [show, setShow] = useState<boolean>(false);
  const [editorState, setEditorState] = useState<IEditorState>(
    prim
      ? () => {
          const blocksFromHTML = convertFromHTML(prim);
          const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );

          return EditorState.createWithContent(contentState);
        }
      : EditorState.createEmpty()
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const onEditorStateChange = (editorState: IEditorState) => {
    setEditorState(editorState);
    setPrim(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = [...Array.from(e.target.files)].map((file) => ({ file, fid: uuidv4() }));
    setUploadFiles((prev) => [...prev, ...files]);
  };

  const fileSelect = () => {
    inputRef.current.click();
    setShow(false);
  };

  const renderBtn = () => {
    return (
      <PopoverOverlay
        show={show}
        setShow={setShow}
        overlayContent={
          <ul>
            <li className={styles.item} onClick={fileSelect}>
              {__("Локальные файлы")}
            </li>
            <li
              className={styles.item}
              onClick={() => {
                onSetSelectFileModal({
                  open: true,
                  callback: (files: IFile[]) => setUploadFiles((prev) => [...prev, ...files])
                });
                setShow(false);
              }}
            >
              {__("Файлы с 4HUB")}
            </li>
          </ul>
        }
        placement="top"
      >
        <span className={styles.clip}>
          <ClipIcon fill="var(--color-d800)" />
        </span>
      </PopoverOverlay>
    );
  };

  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName={styles.toolbar}
        wrapperClassName={styles.wrapper}
        editorClassName={styles.editor}
        onEditorStateChange={onEditorStateChange}
        toolbarCustomButtons={[renderBtn()]}
      />
      <input ref={inputRef} className={styles.hidden} type="file" multiple onChange={uploadImage} />
    </>
  );
};

export default TaskPrimEditor;
