import api from "api";
import Button from "containers/Authentication/Button/Button";
import Input from "containers/Authentication/Input/Input";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useState } from "react";
import { useLocales } from "react-localized";
import { isEmail, isPhoneNumber } from "utils/validation";

import styles from "./FirstStep.module.sass";

export const sendCode = async (login: string) => {
  const params = {
    name: login
  };
  const { data } = await api.post(`/ajax/user_pass_remember.php`, null, { params });
  return data;
};

const FirstStep = ({
  handleNextStep,
  login,
  setLogin
}: {
  handleNextStep: () => void;
  login: string;
  setLogin: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  const { __ } = useLocales();
  const [error, setError] = useState("");

  const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLogin(value);
    setError("");
  };

  const validateLogin = (login: string): boolean => {
    return isEmail(login) || isPhoneNumber(login);
  };

  const handleNextStepClick = async () => {
    if (!validateLogin(login)) {
      setError(__("Введите правильный адрес электронной почты или номер телефона."));
    } else {
      try {
        const data = await sendCode(login);
        checkResponseStatus(data.ok);
        handleNextStep();
      } catch {
        setError(__("Упс!!! Что-то пошло не так!"));
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4>{__("Забыли пароль?")}</h4>
        <p>{__("Не волнуйся! Бывает. Выберите метод восстановления пароля")}</p>
        <Input
          value={login}
          placeholder={__("Введите адрес электронной почты/телефон")}
          onChange={handleLoginChange}
          error={error}
        />
      </div>
      <Button
        disabled={!login}
        className={styles.confirm_button}
        animationClassName={styles.animation}
        onClick={handleNextStepClick}
      >
        {__("Продолжить")}
      </Button>
    </div>
  );
};

export default FirstStep;
