import { useListLanguageSettings } from "collections/profile";
import { useListThemeSettings } from "collections/profile";
import Button from "generalComponents/Button/Button";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import { LANGUAGES, THEMES } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { ITopMessages } from "models/common/common";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { FC, MouseEvent, useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { AccountDeleting } from "./components/AccountDeleting/AccountDeleting";
import { ListThemes } from "./components/ListThemes/ListThemes";
import styles from "./PanelPersonalization.module.sass";

export const PanelPersonalization: FC = () => {
  const { __ } = useLocales();

  const { theme, userInfo } = useUserSelectors();

  const { onChangeSettings } = useActions();

  const optionsLanguages = useListLanguageSettings();

  const listThemeSettings = useListThemeSettings();

  const selectedDefaultLanguage = optionsLanguages.find(({ value }) => value === userInfo?.lang);
  const selectedDefaultTheme = listThemeSettings.find(({ cheked }) => cheked);

  const [chekedTheme, setChekedTheme] = useState<THEMES>(selectedDefaultTheme?.value as THEMES);
  const [selectedLanguage, setSelectedLanguage] = useState<LANGUAGES>(selectedDefaultLanguage?.value as LANGUAGES);

  const isChangeTheme = theme === chekedTheme;
  const isChangeLanguage = userInfo?.lang === selectedLanguage;

  const onSelectedLanguage = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    setSelectedLanguage(value as LANGUAGES);
  };

  const messages: ITopMessages = {
    success: __("Настройки успешно изменены"),
    error: __("Не удалось обновить настройки")
  };

  const params = {
    theme: chekedTheme,
    lang: selectedLanguage
  };

  const onSaveSetttings = () => {
    onChangeSettings(params, () => {}, messages);
  };

  return (
    <div className={styles.box}>
      <div className={styles.panel}>
        <div className={styles.panel__box}>
          <h2 className={styles.panel__title}>{__("Тема")}</h2>
          <ListThemes checkedTheme={chekedTheme} onCheckedTheme={setChekedTheme} themes={listThemeSettings} />
        </div>
        <div className={styles.panel__box}>
          <h2 className={styles.panel__title}>{__("Язык интерфейса")}</h2>
          <SelectCustom
            width={256}
            variantHeight="medium"
            options={optionsLanguages}
            onSelected={onSelectedLanguage}
            selectedDefault={{
              name: selectedDefaultLanguage?.name || optionsLanguages[0]?.name,
              value: selectedDefaultLanguage?.value || optionsLanguages[0]?.value
            }}
          />
        </div>
        <Button
          variant={ButtonVariantType.BLUE}
          onClick={onSaveSetttings}
          disabled={isChangeTheme && isChangeLanguage}
          size={ButtonSizeType.SMALL}
          text={__("Сохранить")}
        />
      </div>
      {userInfo?.is_admin ? <AccountDeleting /> : null}
    </div>
  );
};
