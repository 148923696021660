import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";

export function checkBrowser(name) {
  if (window.navigator.userAgent) {
    return navigator.userAgent.toLowerCase().includes(name.toLowerCase());
  } else {
    return false;
  }
}

export const createMarkup = (text) => {
  return { __html: text };
};

export function copyToClipboard(text) {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(text);
  } else {
    const input = document.createElement("input");
    input.style = "display: none";
    document.appendChild(input);
    input.value = text;
    input.focus();
    input.select();
    document.execCommand("copy");
    document.removeChild(input);
  }
}

// Checks types of incoming value
export function typeCheck(value) {
  const return_value = Object.prototype.toString.call(value);
  const type = return_value.substring(return_value.indexOf(" ") + 1, return_value.indexOf("]"));
  return type.toLowerCase();
}

export const useCopyLink = () => {
  const dispatch = useDispatch();
  const { __ } = useLocales();
  return (ref, link) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(link);
    } else {
      if (ref.current !== null) {
        ref.current.focus();
        ref.current.select();
        document.execCommand("copy");
      }
    }
    dispatch(
      onSetModals("topMessage", {
        open: true,
        type: "message",
        message: __("Ссылка скопирована")
      })
    );
  };
};

export const onPrintIFrame = (id) => {
  const iframe = document.frames ? document.frames[id] : document.getElementById(id);
  const iframeWindow = iframe.contentWindow || iframe;
  iframe.focus();
  iframeWindow.print();
  return false;
};

const appleExpression = /Apple/i;
const safariExpression = /Safari/i;
export const isSafari = () => {
  return appleExpression.test(navigator.vendor) && safariExpression.test(navigator.userAgent);
};
