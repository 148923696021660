import { ReactComponent as FolderIcon } from "assets/icons/folder_in.svg";
import EmailIcon from "containers/Search/SearchItem/components/EmailIcon/EmailIcon";
import FileIcon from "containers/Search/SearchItem/components/FileIcon/FileIcon";
import NoteIcon from "containers/Search/SearchItem/components/NoteIcon/NoteIcon";
import ProgramIcon from "containers/Search/SearchItem/components/ProgramIcon/ProgramIcon";
import SafeIcon from "containers/Search/SearchItem/components/SafeIcon/SafeIcon";
import TaskIcon from "containers/Search/SearchItem/components/TaskIcon/TaskIcon";
import UserIcon from "containers/Search/SearchItem/components/UserIcon/UserIcon";
import Button from "generalComponents/Button/Button";
import IconTooltip from "generalComponents/IconTooltip/IconTooltip";
import { calculateSize } from "generalComponents/Services/helpers";
import { ROUTES } from "generalComponents/variables/routing";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IParticipant, IProjectTask } from "models/store/joinProjects/joinProgects";
import { INote } from "models/store/notes/notes";
import { ILetterInfo } from "models/store/postbox/postbox";
import { IProgram } from "models/store/programs/programs";
import { ISafe } from "models/store/safe/safe";
import { IMyTask } from "models/store/tasks/tasks";
import { IUserInfo } from "models/store/user/user";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router";
import { getPageDep } from "utils/getPageDep";

import styles from "./SearchItem.module.sass";

const SearchItem = ({
  item
}: {
  item: IFolder | IFile | IMyTask | IProjectTask | ISafe | IProgram | ILetterInfo | IUserInfo | IParticipant | INote;
}) => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const isTask = "id_type" in item;
  const isSafe = "table" in item && item.table === "safe";
  const isProgram = "table" in item && item.table === "soft";
  const isMail = "message_id" in item;
  const isUser = "table" in item && (item.table === "contacts" || item.table === "users");
  const isNote = "table" in item && item.table === "notes";

  const navigate = useNavigate();

  const openInFolder = () => {
    if ("dep" in item) {
      const { _PROJECT_FILES_, _PROJECT_LIBRARY_, _PROJECT_DOC_, _LIBRARY_ } = getPageDep(item.dep);
      const projectRoute = item.id_project ? `/${ROUTES.PROJECT}/${item.id_project}` : "";
      if (_PROJECT_LIBRARY_ || _PROJECT_DOC_ || _PROJECT_FILES_) {
        if (item.is_del && item.id_project) {
          return navigate(`${projectRoute}/trash`);
        }
        if (_PROJECT_FILES_ && item.id_project) {
          return navigate(`${projectRoute}/files/${item.id_dir}`);
        }
        if (_PROJECT_LIBRARY_ && item.id_project) {
          return navigate(`${projectRoute}/library/dir/${item.id_dir}`);
        }
        if (_PROJECT_DOC_ && item.id_project) {
          return navigate(`${projectRoute}/doc/${item.id_dir}`);
        }
      } else if (_LIBRARY_) {
        return navigate(`/library/dir/${item.id_dir}`);
      }

      if (item.is_del) {
        return navigate(`/trash`);
      }
      if (item.is_archive) {
        return navigate(`/archive`);
      }
      return navigate(`/folders/${item.id_dir}`);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        {isNote ? (
          <NoteIcon item={item as unknown as INote} />
        ) : isTask ? (
          <TaskIcon item={item as IMyTask | IProjectTask} />
        ) : isSafe ? (
          <SafeIcon item={item as unknown as ISafe} />
        ) : isProgram ? (
          <ProgramIcon item={item as unknown as IProgram} />
        ) : isMail ? (
          <EmailIcon item={item as unknown as ILetterInfo} />
        ) : isUser ? (
          <UserIcon item={item as unknown as IUserInfo | IParticipant} />
        ) : (
          <FileIcon item={item as IFolder | IFile} />
        )}
      </div>
      <div className={styles.date}>
        <div>{"mtime" in item ? formatDate(item.mtime, "full") : isMail && formatDate(item.time, "full")}</div>
        <div className={styles.help}>
          {"is_archive" in item && item.is_archive === 1 && <div>{__("Архив")}</div>}
          {"is_del" in item && item.is_del === 1 && <div>{__("В корзине")}</div>}
        </div>
      </div>
      <div className={styles.sizes}>
        {"size" in item && (
          <>
            {typeof item.size === "number" ? calculateSize(item.size) : !isMail && item.size}
            {!isSafe && !isMail && (
              <IconTooltip bgColor="grey" text={__("Показать в исходной папке")}>
                <Button
                  className={styles.button}
                  variant={ButtonVariantType.OPACITY}
                  size={ButtonSizeType.SMALL}
                  iconL={<FolderIcon />}
                  onClick={openInFolder}
                />
              </IconTooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchItem;
