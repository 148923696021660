import { ReactComponent as FolderIcon } from "assets/PrivateCabinet/folder-3.svg";
import { useContextMenuCreateFile } from "generalComponents/collections/folders";
import { useCurrentFolder } from "generalComponents/Services/folderServices/folderServices";
import { PAGE_DEPTH } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectsCreateMenu.module.sass";

interface IJointProjectsCreateMenuProps {
  setShowCreateMenu: () => void;
}

export const JointProjectsCreateMenu: FC<IJointProjectsCreateMenuProps> = ({ setShowCreateMenu }) => {
  const { __ } = useLocales();
  const menu = useContextMenuCreateFile();

  const { onSetCreateFolderModal, onCreateProjectFolder, onSetCreateFileModal, onSetProjectLoadingDocFiles } =
    useActions();

  const { project } = useJoinProjectsSelectors();

  const currentFolder = useCurrentFolder();

  const onSelect = (ext: string): void => {
    if (!ext) return;
    onSetCreateFileModal({
      open: true,
      dep: PAGE_DEPTH.PROJECT_DOC,
      ext,
      folders: project?.folders_doc,
      onLoading: (payload) => onSetProjectLoadingDocFiles(payload)
    });
    setShowCreateMenu();
  };

  return (
    <>
      <ul className={styles.list}>
        <li>
          <button
            className={styles.item}
            type="button"
            onClick={() => {
              onSetCreateFolderModal({
                open: true,
                type: "add",
                folder: currentFolder ?? null,
                folders: project?.folders_doc,
                onCreateFolder: onCreateProjectFolder
              });
              setShowCreateMenu();
            }}
          >
            <FolderIcon />
            <span>{__("Папку")}</span>
          </button>
        </li>
      </ul>
      <ul className={styles.list}>
        {menu.office.map((el, i) => (
          <li key={i} className={styles.item} onClick={() => onSelect(el.ext)}>
            {el.img}
            <span>{el.name}</span>
          </li>
        ))}
      </ul>
      <ul className={styles.list}>
        {menu.microsoft.map((el, i) => (
          <li key={i} className={styles.item} onClick={() => onSelect(el.ext)}>
            {el.img}
            <span>{el.name}</span>
          </li>
        ))}
      </ul>
      <ul className={styles.list}>
        {menu.google.map((el, i) => (
          <li key={i} className={styles.item} onClick={() => onSelect(el.ext)}>
            {el.img}
            <span>{el.name}</span>
          </li>
        ))}
      </ul>
    </>
  );
};
