import { SectionTitle } from "generalComponents/SectionTitle/SectionTitle";
import { ILibraryRubric } from "models/store/library/library";
import { FC } from "react";
import { useLocales } from "react-localized";

import { LibraryRubric } from "./components/LibraryRubric/LibraryRubric";
import styles from "./LibraryRubrics.module.sass";

interface ILibraryCategoriesProps {
  rubrics: ILibraryRubric[];
}

export const LibraryRubrics: FC<ILibraryCategoriesProps> = ({ rubrics }): JSX.Element => {
  const { __ } = useLocales();

  return (
    !!rubrics.length && (
      <div>
        <SectionTitle>{__("Рубрики")}</SectionTitle>
        <div className={styles.list}>
          {rubrics.map((rubric) => (
            <LibraryRubric key={rubric.id} rubric={rubric} />
          ))}
        </div>
      </div>
    )
  );
};
