export interface IDashboardJointProjectTeam {
  open: boolean;
  projectId: string;
  teamId: string;
  callBack?: (projectId: string, teamId: string) => void;
}

export function initialDashboardJointProjectTeamState(): IDashboardJointProjectTeam {
  return { open: false, projectId: undefined, teamId: undefined, callBack: undefined };
}
