import { useDateFormat } from "hooks/useDateFormat";
import { ISafe } from "models/store/safe/safe";
import React from "react";
import { useLocales } from "react-localized";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./PropertiesSafe.module.sass";

interface IPropertiesSafePropps {
  onClose: () => void;
  safe: ISafe;
}

const PropertiesSafe: React.FC<IPropertiesSafePropps> = ({ onClose, safe }) => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();

  return (
    <div className={styles.wrap}>
      <HeaderModal onClose={onClose} title={__("Свойства")} />
      <div className={styles.body}>
        <p className={styles.text}>
          {__("Имя")}
          &nbsp;<span className={styles.value}>{safe.name}</span>
        </p>
        <p className={styles.text}>
          {__("Дата создания")}
          &nbsp;<span className={styles.value}>{formatDate(safe.ut, "full")}</span>
        </p>
        <p className={styles.text}>
          {__("Дата последнего открытия")}
          &nbsp;
          <span className={styles.value}>{safe.date_open ? formatDate(safe.date_open, "full") : `\u2013`}</span>
        </p>
        <p className={styles.text}>
          {__("Объем")}
          &nbsp;<span className={styles.value}>{safe.size}</span>
        </p>
      </div>
    </div>
  );
};

export default PropertiesSafe;
