import { ReactComponent as ClockIcon } from "assets/icons/clock-2.svg";
import { ReactComponent as HeartIcon } from "assets/PrivateCabinet/greyHeart.svg";
import { ReactComponent as FlagIcon } from "assets/PrivateCabinet/mail/important.svg";
import { ReactComponent as StarIcon } from "assets/PrivateCabinet/star-3.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import CheckBoxNew from "generalComponents/CheckBoxNew/CheckBoxNew";
import { MAIL_PARAMS } from "generalComponents/variables/routing";
import { useFindPostbox, useLetterTime, usePostboxFolder } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ILetterInfo } from "models/store/postbox/postbox";
import { IChangeFlagMail } from "models/store/postbox/postboxPayloads";
import React, { Fragment, useMemo } from "react";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";

import LetterActions from "../LetterActions/LetterActions";
import LetterAttachment from "../LetterAttachment/LetterAttachment";
import styles from "./LetterItem.module.sass";

interface ILetterItemProps {
  letter: ILetterInfo;
}

const LetterItem: React.FC<ILetterItemProps> = ({ letter }) => {
  const { __ } = useLocales();
  const { letterTime } = useLetterTime();
  const [params, setSearchParams] = useSearchParams();
  const viewType = params.get(`${[MAIL_PARAMS.SPLIT]}`);
  const { pickedLetters, searchLetter } = usePostboxSelectors();
  const {
    onScheduleEdit,
    onChooseLetters,
    onSetSearchLetter,
    onCancelChooseLetters,
    onMailNewMessageModal,
    onChangeCustomFlagMail,
    onChangeStandartFlagMail,
    onCancelChooseScheduleLetters
  } = useActions();
  const { postbox } = useFindPostbox();
  const { _isSchedule, _isSent, _isDraft } = usePostboxFolder();
  const { formatDate } = useDateFormat();
  const onOpenLetter = () => {
    console.log("test");
    if (!letter.seen && !_isSchedule) addFlag("seen");
    if (_isDraft) {
      onMailNewMessageModal({
        open: true,
        letter: { letter, type: "draft" }
      });
      return;
    }
    params.set(`${[MAIL_PARAMS.SPLIT]}`, "true");

    onSetSearchLetter(letter);
    setSearchParams(params);
  };

  const isChecked = () => {
    if (_isSchedule) {
      return pickedLetters.some((el) => el.id === letter.id);
    }
    return pickedLetters.some((el) => el.uid === letter.uid);
  };

  const onClickSelectHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (_isSchedule) {
      return isChecked() ? onCancelChooseScheduleLetters(letter.id) : onChooseLetters(letter);
    }
    isChecked() ? onCancelChooseLetters(letter.uid) : onChooseLetters(letter);
  };

  const addFlag = (flag: string, e?: React.MouseEvent<HTMLButtonElement>): void => {
    e?.stopPropagation();
    const isStandart = flag === "flagged" || flag === "seen";
    const val = letter[flag] ? "0" : "1";
    const data = isStandart
      ? {
          folder: letter.folder,
          message_nums: letter.uid,
          flag: flag,
          flag_value: val,
          message_id: letter.message_id
        }
      : {
          folder: letter.folder,
          message_nums: letter.uid,
          message_id: letter.message_id,
          [flag]: val
        };
    const payload: IChangeFlagMail = {
      data: {
        user: postbox.email,
        pass: postbox.pass,
        server: postbox.server,
        data: JSON.stringify([data])
      },
      value: { flag, val, folder: letter.folder }
    };
    const errorMsg = __("Что-то пошло не так. Попробуйте позже!");

    isStandart ? onChangeStandartFlagMail(payload, errorMsg) : onChangeCustomFlagMail(payload, errorMsg);
  };

  const onChangeFlagSchedule = (flag: string, e?: React.MouseEvent<HTMLButtonElement>): void => {
    e?.stopPropagation();

    const formData = new FormData();
    const val = letter[flag] ? "0" : "1";
    formData.append(`${flag}`, val);
    formData.append("id_items[]", letter.id);
    formData.append("user", postbox?.email);

    const messages = {
      error: __("Что-то пошло не так. Попробуйте позже"),
      success: __("Письмо изменено")
    };
    onScheduleEdit(formData, messages);
  };

  const getAttachments = useMemo(() => {
    if (Array.isArray(letter.attachments)) {
      const end = viewType ? 1 : 3;
      const cutAttachments = letter.attachments.slice(0, end);
      return { attachments: cutAttachments, length: letter.attachments.length - cutAttachments.length };
    }
    return { attachments: [], length: 0 };
  }, [letter.attachments, viewType]);

  return (
    <div
      className={classNames(styles.box, {
        [styles.unread]: !letter.seen && !_isSent && !_isSchedule,
        [styles.split]: viewType,
        [styles.current]: searchLetter && letter.message_id === searchLetter.message_id
      })}
      onClick={onOpenLetter}
    >
      <div className={styles.controls}>
        <CheckBoxNew isChecked={isChecked()} setIsChecked={onClickSelectHandler} padding={8} />
        <Button
          onClick={_isSchedule ? (e) => onChangeFlagSchedule("is_liked", e) : (e) => addFlag("is_liked", e)}
          variant={ButtonVariantType.OPACITY}
          size={ButtonSizeType.MEDIUM}
          iconL={<HeartIcon className={classNames(styles.icon, { [styles.active]: letter.is_liked })} />}
          isSquare
        />
        <Button
          onClick={_isSchedule ? (e) => onChangeFlagSchedule("flagged", e) : (e) => addFlag("flagged", e)}
          variant={ButtonVariantType.OPACITY}
          size={ButtonSizeType.MEDIUM}
          iconL={<FlagIcon className={classNames(styles.icon, { [styles.active]: letter.flagged })} />}
          isSquare
        />
      </div>
      <div className={styles.main}>
        <div className={styles.info}>
          <div className={styles.name}>
            <button
              className={styles.starBtn}
              onClick={_isSchedule ? (e) => onChangeFlagSchedule("is_favorite", e) : (e) => addFlag("is_favorite", e)}
            >
              <StarIcon className={classNames(styles.star, { [styles.active]: letter.is_favorite })} />
            </button>
            <p className={styles.from}>
              {_isSchedule || _isSent ? (letter.to ? letter.to?.[0].address : "") : letter.from_name || letter.from}
            </p>
          </div>
          <p className={styles.subject}>{letter.subject}</p>
        </div>
        {Boolean(getAttachments.attachments.length) && (
          <div className={styles.attachments}>
            {getAttachments.attachments.map((attachment, i) => (
              <LetterAttachment key={i} name={attachment.filename} type={attachment.type} letter={letter} />
            ))}
            {getAttachments.length > 0 && <div className={styles.amount}>+{getAttachments.length}</div>}
          </div>
        )}
      </div>
      <p className={styles.time}>
        {!_isSchedule ? (
          letterTime(letter.time)
        ) : (
          <>
            <span>{formatDate(letter.time)}</span> <ClockIcon />
          </>
        )}
      </p>

      {!_isSchedule && (
        <div className={styles.menu}>
          <LetterActions letter={letter} postbox={postbox} />
        </div>
      )}
    </div>
  );
};

export default LetterItem;
