import { ReactComponent as DotsIcon } from "assets/PrivateCabinet/dots-horizontal-square.svg";
import { ReactComponent as DotsIconVertical } from "assets/PrivateCabinet/dots-vertical-round.svg";
import classNames from "classnames";
import { useContextArchivedFolder } from "collections/archive";
import { useContextFolder } from "collections/myFolders";
import { useContextSafeFolder } from "collections/safe";
import { useContextTrashFolder } from "collections/trash";
import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
import FileTags from "generalComponents/FileTags/FileTags";
import { useGetItemSize } from "generalComponents/Hooks/Files/useGetItemSize";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { calculateSize } from "generalComponents/Services/helpers";
import { HOT_KEYS, ViewType } from "generalComponents/variables/global";
import { ROUTES, SHARED_PARAMS } from "generalComponents/variables/routing";
import { useSafeFind } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { useDateFormat } from "hooks/useDateFormat";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { initialPickedItems } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useGetIconSize } from "Pages/Cabinet/Components/WorkElements/Hooks/useGetIconSize";
import { FC, MouseEvent, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { getStartEndIndexsSelectedElements } from "utils/getStartEndIndexsSelectedElements";

import styles from "./FolderBox.module.sass";

export interface IFolderBoxProps {
  folder: IFolder;
  view?: ViewType;
}

export const FolderBox: FC<IFolderBoxProps> = ({ folder }): JSX.Element => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { safeId } = useParams();
  const { isShared } = useIsShared();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const currentSafe = useSafeFind();
  const { formatDate } = useDateFormat();
  const isArchive = useCurrentPathStarstWith(ROUTES.ARCHIVE);
  const isTrash = useCurrentPathStarstWith(ROUTES.TRASH);
  const isSharedFolderPage = useCurrentPathStarstWith(ROUTES.SHARE_FOLDER);

  const [isHover, setIsHover] = useState<boolean>(false);

  const onHover = (): void => setIsHover(true);
  const outHover = (): void => {
    setIsHover(false);
  };

  const [_, setSearchParams] = useSearchParams();
  const { isMediumSize, isBigSize } = useGetItemSize();
  const { isLinesView } = useGetItemView();
  const {
    contextMenuFolderCallback,
    contextMenuSharedFolderCallback,
    consextMenuSystemFolderCallbak,
    contextMenuSharedLinkFolderCallback
  } = useContextFolder();
  const { contextMenuArchivedFolderCallback } = useContextArchivedFolder();
  const { contextMenuTrashFolderCallback } = useContextTrashFolder();
  const { contextMenuSafeFolderCallback, contextMenuSafeFolderToMeCallback } = useContextSafeFolder();
  const { onSetPickedItems, onSetCurrentItem, onSendFolderPath } = useActions();
  const { getFolderIconWidth, getFolderIconHeight, getFileIconHeight } = useGetIconSize();
  const { files, view } = useFilesSelectors();

  const {
    currentItem,
    pickedItems: { open, items, isLeftSide, isRightSide }
  } = useFilesSelectors();

  const setElementType = () => {
    return items.some((item) => item.id_dir === folder.id_dir)
      ? CheckBoxVariantTypes.CHECKED
      : CheckBoxVariantTypes.NO_TYPE;
  };

  const [checkBoxOption, setCheckBoxOption] = useState<CheckBoxVariantTypes>(setElementType());
  const [_isCheckedOption] = useState<boolean>(checkBoxOption === CheckBoxVariantTypes.CHECKED);

  const onClickFolderBoxItem = (): void => {
    onSetCurrentItem(null);
    onSetPickedItems(initialPickedItems());
    if (isShared && folder.my === 0) {
      onSendFolderPath([folder]);
      setSearchParams({ [SHARED_PARAMS.DIR]: folder.id_dir });
      return;
    }
    if (isSafePage) {
      navigate(`/${ROUTES.SAFE}/${safeId}/${folder.id_dir}${search}`);
      return;
    }
    if (isArchive) {
      navigate(`/${ROUTES.ARCHIVE}/${folder.id_dir}${search}`);
      return;
    }

    if (isTrash) {
      navigate(`/${ROUTES.TRASH}/${folder.id_dir}${search}`);
      return;
    }

    if (isSharedFolderPage) {
      navigate(`${folder.id_dir}`);
      return;
    }

    navigate(`/${ROUTES.FOLDERS}/${folder.id_dir}${search}`, { state: { from: pathname } });
  };

  const onClickCheckBox = (): void => {
    onSetPickedItems({
      open: true,
      items: items.some((item) => item.id_dir === folder.id_dir)
        ? items.filter((item) => item.id_dir !== folder.id_dir)
        : [...items, folder],
      isLeftSide,
      isRightSide
    });
    onSetCurrentItem(folder);
  };

  const onMouseEnterFolder = (): void => {
    if (currentSafe && !currentSafe.is_my) return;
    if (_isCheckedOption || (isShared && !folder.my)) return;
    setCheckBoxOption(CheckBoxVariantTypes.DEFAULT);
  };

  const onMouseLeaveFolder = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.NO_TYPE);
  };

  const onMouseEnterCheckBox = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.HOVER);
  };

  const onMouseLeaveCheckBox = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.DEFAULT);
  };

  const renderContextMenu = (): IPopoverData[] => {
    if (isShared && !folder.my) {
      return contextMenuSharedFolderCallback(folder);
    }
    if (isSafePage) {
      return currentSafe?.is_my ? contextMenuSafeFolderCallback(folder) : contextMenuSafeFolderToMeCallback(folder);
    }
    if (isArchive) {
      return contextMenuArchivedFolderCallback(folder);
    }

    if (isTrash) {
      return contextMenuTrashFolderCallback(folder);
    }
    if (isSharedFolderPage) {
      return contextMenuSharedLinkFolderCallback(folder);
    }

    if (folder.is_system === "1") {
      return consextMenuSystemFolderCallbak(folder);
    }

    return contextMenuFolderCallback(folder);
  };

  const onSelectFolderRange = (event: MouseEvent<HTMLDivElement>) => {
    if (event[HOT_KEYS.SHIFT]) {
      if (!items.length) {
        onClickCheckBox();
      }

      if (items.length >= 1) {
        const [indexFirst, indexSecond] = getStartEndIndexsSelectedElements(files, items);

        const currentIndex = files.findIndex((el) => (el as IFolder).id_dir === folder.id_dir);
        const isSelectedItem = items.some((item) => (item as IFolder).id_dir === folder.id_dir);

        let startIndex = indexFirst;
        let endIndex = indexSecond;

        if (currentIndex > indexFirst && currentIndex > indexSecond && isLeftSide && !isRightSide) {
          startIndex = indexSecond;
        }

        if (currentIndex < indexFirst && currentIndex < indexSecond && !isLeftSide && isRightSide) {
          endIndex = indexFirst;
        }

        if (isSelectedItem && isLeftSide) {
          startIndex = currentIndex;
        }

        if (isSelectedItem && isRightSide) {
          endIndex = currentIndex;
        }

        const filesRange = files.slice(Math.min(startIndex, currentIndex), Math.max(endIndex + 1, currentIndex + 1));

        onSetPickedItems({
          open: true,
          items: filesRange,
          isRightSide:
            (currentIndex > indexFirst && currentIndex > indexSecond) || (isRightSide && isSelectedItem) ? true : false,
          isLeftSide:
            (currentIndex < indexFirst && currentIndex < indexSecond) || (isLeftSide && isSelectedItem) ? true : false
        });
      }
    }
  };

  const renderContextContent = (): JSX.Element => {
    return (
      <PopoverContextMenu
        data={renderContextMenu()}
        unzoomClickArea={!isLinesView}
        classes={isLinesView && styles}
        iconVariant={isLinesView ? "hidden" : undefined}
        visible={isHover}
      >
        {!isLinesView ? <DotsIcon className={styles.dots} /> : <DotsIconVertical fill="#E7EBEA" />}
      </PopoverContextMenu>
    );
  };

  const isSelected = (): boolean => {
    if (open) {
      return items.some((item) => item.id_dir === folder.id_dir);
    }

    return currentItem?.is_dir && currentItem?.id_dir === folder.id_dir;
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.selected]: isSelected(),
        [styles.isMediumSize]: isMediumSize,
        [styles.isBigSize]: isBigSize,
        [styles[view]]: view
      })}
      onMouseEnter={onHover}
      onMouseLeave={outHover}
      onClick={
        !isArchive && !isTrash
          ? (event: MouseEvent<HTMLDivElement>) => {
              if (event[HOT_KEYS.META] || event[HOT_KEYS.CTRL]) {
                onClickCheckBox();
              }

              onSelectFolderRange(event);
            }
          : undefined
      }
    >
      <div
        className={classNames(styles.body, {
          [styles[view]]: view
        })}
      >
        {!isLinesView && <div className={styles.context}>{renderContextContent()}</div>}
        {(folder.emo || folder.color) && !isLinesView && (
          <div className={styles.symbols}>
            <div className={styles.emoFigWrapper}>
              {folder.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: folder.emo }} />}
              {folder.color && <div className={styles.color} style={{ background: folder.color }} />}
            </div>
          </div>
        )}
        <div
          className={styles.bodyContent}
          onDoubleClick={onClickFolderBoxItem}
          onMouseEnter={onMouseEnterFolder}
          onMouseLeave={onMouseLeaveFolder}
        >
          {!isArchive && !isTrash && !isSharedFolderPage && (
            <div className={styles.options}>
              <CheckBox
                variant={checkBoxOption}
                onClick={onClickCheckBox}
                onMouseEnter={onMouseEnterCheckBox}
                onMouseLeave={onMouseLeaveCheckBox}
                width={14}
                height={14}
                increaseArea
              />
            </div>
          )}
          <div
            className={classNames(styles.iconWrapperFlex, {
              [styles[view]]: view
            })}
          >
            <div className={styles.iconWrapper} style={{ height: getFileIconHeight() }}>
              <ItemIcon
                width={getFolderIconWidth()}
                height={getFolderIconHeight()}
                color={folder.color}
                passIcon={!isSafePage && folder.is_pass === 1}
                isFolder
              />
              {folder.tags.length > 0 && (
                <div className={styles.tags}>
                  <FileTags item={folder} />
                </div>
              )}
            </div>
            {!isLinesView && (
              <div
                className={classNames(styles.name, {
                  [styles[view]]: view
                })}
              >
                {folder.name}
              </div>
            )}
            {isLinesView && (
              <div>
                <div
                  className={classNames(styles.name, {
                    [styles[view]]: view
                  })}
                >
                  {folder.name}
                </div>
                <div
                  className={classNames(styles.bottomInfo, {
                    [styles[view]]: view
                  })}
                >
                  <div className={styles.size}>{calculateSize(folder.size)}</div>
                  <div className={styles.dateCreation}>{formatDate(folder.cdate)}</div>
                </div>
              </div>
            )}
          </div>
          {!isLinesView && (
            <div
              className={classNames(styles.bottomInfo, {
                [styles[view]]: view
              })}
            >
              <div className={styles.size}>{calculateSize(folder.size)}</div>
              <div className={styles.dateCreation}>{formatDate(folder.cdate)}</div>
            </div>
          )}
          {isLinesView && renderContextContent()}
        </div>
      </div>
    </div>
  );
};
