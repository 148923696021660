import { FOLDER_ROUTES, PROJECT_PATH, ROUTES, SHARED_PARAMS, SHARED_TYPES } from "generalComponents/variables/routing";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { IFolder } from "models/store/folders/foldersStore";
import { useMemo } from "react";
import { useMatch, useParams, useSearchParams } from "react-router-dom";
import { useFilterCriteriaSelector } from "Store/selectors/filterCriteriaSelectors";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

export const useRenderFolders = () => {
  const { folders } = useFoldersSelectors();
  const { project } = useJoinProjectsSelectors();

  const { id } = useParams();

  const isProjectDoc = useCurrentPathStarstWith(`${ROUTES.PROJECT}/${id}/${PROJECT_PATH.DOC}`);
  const isProjectFiles = useCurrentPathStarstWith(`${ROUTES.PROJECT}/${id}/${PROJECT_PATH.FILES}`);
  const isProjectTrash = useCurrentPathStarstWith(`${ROUTES.PROJECT}/${id}/${PROJECT_PATH.TRASH}`);

  if (isProjectDoc) {
    const foldersProjectDocSystem = project?.folders_doc;
    const foldersGlobalSystem = folders.filter(({ is_system }) => is_system === "1");

    return [...foldersGlobalSystem, ...foldersProjectDocSystem];
  }

  if (isProjectFiles) {
    const foldersProjectFilesSystem = project?.folders_file;

    return foldersProjectFilesSystem;
  }

  if (isProjectTrash) {
    const foldersProjectDocSystem = project?.folders_doc;
    const foldersProjectFilesSystem = project?.folders_file;

    return [...foldersProjectDocSystem, ...foldersProjectFilesSystem];
  }

  return folders;
};

export const useAllFolders = (): IFolder[] => {
  const folders = useRenderFolders();

  const foldersAll: IFolder[] = useMemo(() => {
    const result: IFolder[] = [];
    const allFolders = (folders: IFolder[]): void => {
      for (let i = 0; i <= folders.length - 1; i++) {
        result.push(folders[i]);
        if (folders[i].folders?.length > 0) {
          allFolders(folders[i].folders);
        }
      }
    };
    allFolders([...folders]);
    return result;
  }, [folders]);

  return foldersAll;
};

export const useFolderPath = (): ((cb: IFolder) => IFolder[]) => {
  const allFolders = useAllFolders();

  return (folder: IFolder): IFolder[] => {
    const path: IFolder[] = [];
    const getPath = (folder: IFolder) => {
      path.unshift(folder);
      if (folder.id_parent === "0" || !folder.id_parent) {
        if (!folder.id_parent) {
          const f = allFolders.find((f) => f.id_dir === folder.id_dir);
          getPath(f);
        }
      } else {
        const f = allFolders.find((f) => f.id_dir === folder.id_parent);
        getPath(f);
      }
    };
    getPath(folder);
    return path;
  };
};

export const useCurrentFolder = (): IFolder => {
  const { dirId } = useParams();
  const folders = useAllFolders();
  const { project } = useJoinProjectsSelectors();

  if (dirId) {
    const currentFolder = folders.find((folder) => folder.id_dir === dirId);

    return currentFolder;
  }

  if (project?.folders_file[0]?.id_dir) {
    const currentFolder = folders.find((folder) => folder.id_dir === project.folders_file[0].id_dir);

    return currentFolder;
  }
};

export const useIsFoldersPage = () => {
  const isFoldersPage = useMatch(`/${ROUTES.FOLDERS}/:idDir`);
  return { isFoldersPage };
};

export const useIsShared = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get(SHARED_PARAMS.TYPE);
  const isSharedToMe = type === SHARED_TYPES.TO_ME;
  const isSharedByMe = type === SHARED_TYPES.BY_ME;
  const isShared = useMatch(`${ROUTES.FOLDERS}/${FOLDER_ROUTES.SHARED}`);
  return { isShared, isSharedToMe, isSharedByMe };
};

export const useIsFavorite = () => {
  const isFavorite = useMatch(`${ROUTES.FOLDERS}/${FOLDER_ROUTES.FAVORITE}`);
  return { isFavorite };
};

export const useIsFilters = (): boolean => {
  const { filterCriteria } = useFilterCriteriaSelector();
  const isFilters =
    Boolean(filterCriteria.color) ||
    Boolean(filterCriteria.emo) ||
    Boolean(filterCriteria.figure) ||
    Boolean(filterCriteria.tags.length);
  return isFilters;
};
