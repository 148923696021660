import { useFaq } from "collections/faq";
import FaqFooter from "containers/Faq/FaqFooter/FaqFooter";
import FaqHeader from "containers/Faq/FaqHeader/FaqHeader";
import { Link } from "react-router-dom";

import styles from "./FaqPage.module.sass";

const FaqPage = () => {
  const faq = useFaq();

  return (
    <div className={styles.container}>
      <FaqHeader />
      <div className={styles.content}>
        {faq.map((item, i) => (
          <Link to={`/faqdetails/${item.route}`} key={i} className={styles.block}>
            {item.icon}
            <div className={styles.title}>{item.title}</div>
            <div className={styles.desc}>{item.describe}</div>
          </Link>
        ))}
      </div>
      <FaqFooter />
    </div>
  );
};

export default FaqPage;
