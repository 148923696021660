import classNames from "classnames";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ILetterInfo } from "models/store/postbox/postbox";

import styles from "./MailItem.module.sass";

const MailItem = ({ item }: { item: ILetterInfo }) => {
  const { formatDate } = useDateFormat();
  const { onSetMailDashboardModal } = useActions();

  return (
    <div
      className={classNames(styles.container, !item.seen && styles.container_new)}
      onClick={() => onSetMailDashboardModal({ open: true, letter: item })}
    >
      <div className={styles.header}>
        <div className={styles.title}>{item.from_name}</div> <span>{formatDate(item.time, "time")}</span>
      </div>
      <div className={styles.info}>{item.preview}</div>
    </div>
  );
};

export default MailItem;
