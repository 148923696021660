import { INoteCategory } from "models/store/notes/notes";

export interface INotesCategoryState {
  open: boolean;
  category?: INoteCategory;
}

export function initialNotesCategoryState(): INotesCategoryState {
  return { open: false, category: undefined };
}
