import { ReactComponent as Team } from "assets/icons/group-team.svg";
import { ReactComponent as Person } from "assets/icons/person_2.svg";
import { ReactComponent as Segment } from "assets/icons/segment.svg";
import TariffServices from "containers/Profile/TariffServices/TariffServices";
import TarrifTable from "containers/Profile/TarrifTable/TarrifTable";
import Tabs from "generalComponents/Tabs/Tabs";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./ProfileTariff.module.sass";

const Tariff = () => {
  const { __ } = useLocales();
  const [active, setActive] = useState("1");

  const tabs = [
    { name: __("1 мес"), value: __("1"), onClick: () => setActive("1") },
    { name: __("3 мес"), value: __("3"), onClick: () => setActive("3") },
    { name: __("6 мес"), value: __("6"), onClick: () => setActive("6"), tip: "-10%" },
    { name: __("12 мес"), value: __("12"), onClick: () => setActive("12"), tip: "-20%" }
  ];

  const services = [
    {
      title: __("Дополнительно место на виртуальном диске"),
      icon: <Segment />,
      data: [
        { title: "500 ГБ", subTitle: "+500 гб дополнительно", price: 5 },
        { title: "1000 ГБ", subTitle: "+1000 гб дополнительно", price: 10 },
        { title: "5000 ГБ", subTitle: "+5000 гб дополнительно", price: 15 }
      ]
    },
    {
      title: __("Дополнительные сотрудники"),
      icon: <Person />,
      data: [
        { title: "5", subTitle: "+5 сотрудников", price: 5 },
        { title: "25", subTitle: "+25 сотрудников", price: 10 },
        { title: "100", subTitle: "+100 сотрудников", price: 15 }
      ]
    },
    {
      title: __("Дополнительные совместные проекты"),
      icon: <Team height={24} width={24} />,
      data: [
        { title: "2", subTitle: "+2 Проектов", price: 5 },
        { title: "5", subTitle: "+5 Проектов", price: 10 },
        { title: "50", subTitle: "+50 Проектов", price: 15 }
      ]
    }
  ];

  return (
    <WorkAreaWrap>
      <ToolsPanel size="large">
        <div className={styles.tabsWrapper}>
          <Tabs tabs={tabs} value={active} size="large" variant="darkGreen" />
        </div>
      </ToolsPanel>
      <div className={styles.content}>
        <span className={styles.title}>{__("Цены указаны с учетом НДС")}</span>
        <TarrifTable active={active} />
        {services.map((service, index) => (
          <TariffServices key={index} title={service.title} data={service.data} icon={service.icon} />
        ))}
      </div>
    </WorkAreaWrap>
  );
};

export default Tariff;
