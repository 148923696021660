import { ReactComponent as ArchiveIcon } from "assets/icons/archive.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar_3.svg";
import { ReactComponent as ChatIcon } from "assets/icons/chat.svg";
import { ReactComponent as FileIcon } from "assets/icons/file_2.svg";
import { ReactComponent as JointIcon } from "assets/icons/joint.svg";
import { ReactComponent as LibraryIcon } from "assets/icons/library-2.svg";
import { ReactComponent as MagazineIcon } from "assets/icons/magazine.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { ReactComponent as ProgramIcon } from "assets/icons/program.svg";
import { ReactComponent as TaskIcon } from "assets/icons/task.svg";
import { Slide } from "containers/Authentication/Slider/Slider";
import { FC, SVGProps } from "react";
import { useLocales } from "react-localized";

export interface IQuestionsData {
  title: string;
  answers: string[];
  images?: string[];
  icon?: FC<SVGProps<SVGSVGElement>>[];
  position?: string[];
  descriptions?: string[];
}

export const useQuestionnaireData = () => {
  const { __ } = useLocales();

  return [
    {
      title: __("Какая ваша основная роль?"),
      answers: [__("Менеджер"), __("Директор"), __("Фрилансер"), __("Студент"), __("Член команды")]
    },
    {
      title: __("Над чем вы работаете?"),
      answers: [
        __("Управление проектами или программами"),
        __("Маркетинг"),
        __("Дизайн"),
        __("Финансы"),
        __("Данные или аналитика"),
        __("Коммуникации"),
        __("Продажи"),
        __("Медицинский работник"),
        __("Административный ассистент"),
        __("Управление продуктом"),
        __("Другое")
      ]
    },
    {
      title: __("Какая ваша основная цель в 4HUB?"),
      answers: [__("Управление проектами и процессами"), __("Управление личными задачами")]
    },
    {
      title: __("Сколько людей будет работать с вами?"),
      answers: [
        __("Только я"),
        __("2-5"),
        __("6-10"),
        __("11-25"),
        __("26-50"),
        __("51-200"),
        __("201-500"),
        __("501+"),
        __("Я не знаю")
      ]
    },
    {
      title: __("На какой функционал вы больше всего ориентированы?"),
      answers: [
        __("Хранение файлов"),
        __("Календарь"),
        __("Таск-бук"),
        __("Менеджер задач"),
        __("Чат"),
        __("Почта"),
        __("Архив"),
        __("Совместный проект"),
        __("Журнал"),
        __("Библиотека"),
        __("Программа")
      ],
      images: [
        "assets/Questionnaire/filestorage.png",
        "assets/Questionnaire/calendar.png",
        "assets/Questionnaire/taskboard.png",
        "assets/Questionnaire/taskmanager.png",
        "assets/Questionnaire/chat.png",
        "assets/Questionnaire/mail.png",
        "assets/Questionnaire/archive.png",
        "assets/Questionnaire/joint.png",
        "assets/Questionnaire/magazine.png",
        "assets/Questionnaire/library.png",
        "assets/Questionnaire/program.png"
      ],
      position: [
        "right center",
        "right center",
        "right center",
        "center",
        "center",
        "right center",
        "center",
        "right center",
        "center",
        "right bottom",
        "right center"
      ],
      icon: [
        FileIcon,
        CalendarIcon,
        TaskIcon,
        TaskIcon,
        ChatIcon,
        MailIcon,
        ArchiveIcon,
        JointIcon,
        MagazineIcon,
        LibraryIcon,
        ProgramIcon
      ],
      descriptions: [
        __(
          "Контроль и организация ваших файлов. Разделение на группы и настройка. Больше путаницы. Удобная фильтрация по тегам, названию и даже эмоциям."
        ),
        __(
          "Организация вашего времени с помощью встроенного календаря. Назначайте звонки, встречи, задачи и даже электронные письма."
        ),
        __(
          "Доска со всеми вашими задачами. Добавляйте задачи, отслеживайте прогресс, отмечайте выполнение и устанавливайте сроки."
        ),
        __(
          "Доска со всеми вашими задачами. Добавляйте задачи, отслеживайте прогресс, отмечайте выполнение и устанавливайте сроки."
        ),
        __(
          "Больше не переключайтесь для взаимодействия с сотрудниками. Общайтесь непосредственно на платформе, организуйте конференции и видеозвонки."
        ),
        __(
          "Вся коммуникация и хранение файлов на одной платформе для повышения производительности, оптимизации времени и безопасности компании."
        ),
        __(
          "Раздел для хранения и архивирования старых или редко используемых файлов и данных, обеспечивающий их долговременную сохранность и место для активных данных."
        ),
        __("Создавайте и контролируйте все свои проекты в одном месте. Задачи, команды, аналитика и все файлы."),
        __(
          "В разделе представлена ​​информация о действиях и событиях, связанных с данными и активностью пользователей."
        ),
        __(
          "Раздел представляет собой организованное хранилище документов, доступное для общего пользования, облегчающее совместную работу и обмен информацией."
        ),
        __(
          "Этот раздел используется для хранения приложений и управления ими, которые можно запускать и использовать для обеспечения удобства и доступности веб-приложений."
        )
      ]
    }
  ];
};

export const useSliderData = () => {
  const { __ } = useLocales();
  return [
    {
      title: __("Доска задач"),
      text: __(
        "Доска со всеми вашими задачами. Добавляйте задачи, отслеживайте прогресс, отмечайте завершение и устанавливайте сроки."
      ),
      src: "assets/Authentication/Slider/1.png",
      alt: "chat",
      size: "contain",
      position: "right center",
      isMargin: true
    },
    {
      title: __("Контроль файлов"),
      text: __(
        "Контролируйте и систематизируйте свои файлы. Разделите на группы и настройте. Больше никакой путаницы. Удобная навигация по тегам, названию и даже эмоциям."
      ),
      src: "assets/Authentication/Slider/2.png",
      alt: "control",
      size: "contain",
      position: "right bottom",
      isMargin: true
    },
    {
      title: __("Совместный проект"),
      text: __(
        "Доска со всеми задачами вашего проекта и задачами для ваших сотрудников. Добавляйте задачи, отслеживайте прогресс, отмечайте завершение и устанавливайте сроки."
      ),
      src: "assets/Authentication/Slider/3.png",
      alt: "chat",
      size: "contain",
      position: "right bottom",
      isMargin: true
    },
    {
      title: __("Доска задач"),
      text: __(
        "Доска со всеми вашими задачами. Добавляйте задачи, отслеживайте прогресс, отмечайте завершение и устанавливайте сроки."
      ),
      src: "assets/Authentication/Slider/4.png",
      alt: "task board",
      size: "70%",
      position: "center"
    },
    {
      title: __("Ваш личный календарь"),
      text: __(
        "Организуйте свое время с помощью встроенного календаря. Назначайте звонки, встречи, задачи и даже электронные письма."
      ),
      src: "assets/Authentication/Slider/5.png",
      alt: "calendar",
      size: "contain",
      position: "center"
    },
    {
      title: __("Интеграция"),
      text: __("Подключите любимые сервисы для более удобной и высокой производительности"),
      src: "assets/Authentication/Slider/6.png",
      alt: "integration",
      size: "contain",
      position: "center right",
      isMargin: true
    },
    {
      title: __("Команда"),
      text: __(
        "Работа в команде – самая важная часть работы. Организуйте команду на платформе, добавляйте участников, отслеживайте роли и задачи, где бы вы ни находились."
      ),
      src: "assets/Authentication/Slider/7.png",
      alt: "team",
      size: "cover",
      position: "top center"
    }
  ] as Slide[];
};
