import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./SoundRecord.module.sass";

const SoundRecord = () => {
  const { __ } = useLocales();
  const {
    record: { callBack }
  } = useGlobalModalsSelectors();
  const [isLoading, setIsLoading] = useState(false);
  const recorderControls = useVoiceVisualizer();
  const { startRecording, stopRecording, recordedBlob, error, audioRef } = recorderControls;

  useEffect(() => {
    const requestMicrophonePermission = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        startRecording();
      } catch (err) {
        console.error("Error accessing microphone:", err);
      }
    };

    requestMicrophonePermission();

    // Функция для остановки записи при размонтировании компонента
    return () => {
      stopRecording();
    };
  }, []); //eslint-disable-line

  // Получаем записанный аудиоблок
  useEffect(() => {
    if (!recordedBlob) return;
    setIsLoading(true);
    callBack(recordedBlob);
  }, [recordedBlob]); //eslint-disable-line

  // Получаем ошибку, когда она возникает
  useEffect(() => {
    if (!error) return;

    console.log(error);
  }, [error]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loader}>
          <Loader
            position="relative"
            width="100px"
            height="100px"
            type={LoaderTypes.BOUNCING_DOTS}
            containerType="bounceDots"
          />
        </div>
      ) : (
        <>
          <VoiceVisualizer
            fullscreen={false}
            controls={recorderControls}
            isControlPanelShown={false}
            barWidth={4}
            gap={1}
            height={50}
            width={300}
            mainBarColor="#9FAEAB"
            ref={audioRef}
          />
          <Button
            variant={ButtonVariantType.RED}
            size={ButtonSizeType.SMALL}
            onClick={stopRecording}
            text={__("Стоп")}
          />
        </>
      )}
    </div>
  );
};

export default SoundRecord;
