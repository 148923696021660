import React from "react";

import styles from "./BottomMenu.module.sass";

interface Props {
  children: React.ReactNode;
}

const BottomMenu: React.FC<Props> = ({ children }) => {
  return <div className={styles.wrap}>{children}</div>;
};

export default BottomMenu;
