import { EMOJIS, SIGNS } from "generalComponents/variables/elements";

export enum FiltersFields {
  EMOJIS = "id_emo",
  COLORS = "id_color",
  TAGS = "tags",
  SIGNS = "id_fig"
}

export enum FiltersSources {
  FOLDERS = "folders",
  FILES = "files",
  TASKS = "tasks",
  SAFE = "safe"
}
export interface IUserFiltersPayload {
  sources: FiltersSources[];
  fields: FiltersFields[];
}

export interface IDetchUserFiltersDTO {
  data: {
    id_emo: EMOJIS[];
    id_color: string[];
    tags: string[];
    id_fig: SIGNS[];
  };
  ok: number;
}
