import { AllElementsType } from "models/store/typeElements";

export interface IRestoreFileModalState {
  open: boolean;
  element: AllElementsType | null;
  onRestore?: () => void | undefined;
}

export const initialRestoreFileModalState = (): IRestoreFileModalState => {
  return { open: false, element: null };
};
