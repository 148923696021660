import Button from "generalComponents/Button/Button";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import PopUp from "generalComponents/PopUp/PopUp";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { initialDashboardJointProjectTeamState } from "models/store/Cabinet/modals/dashboardJointProjectTeams";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./DashboardJointProjectTeamModal.module.sass";

const DashboardJointProjectTeamModal = () => {
  const { projectList } = useJoinProjectsSelectors();
  const [projects, setProjects] = useState<ISelectCustom[]>();
  const [activeProject, setActiveProject] = useState<ISelectCustom>();
  const [teams, setTeams] = useState<ISelectCustom[]>();
  const [activeTeam, setActiveTeam] = useState<ISelectCustom>();
  const { __ } = useLocales();
  const { onSetDashboardJointProjectTeamModal } = useActions();

  const {
    dashboardJointProjectTeam: { projectId, teamId, callBack }
  } = useGlobalModalsSelectors();

  const closeModal = () => {
    onSetDashboardJointProjectTeamModal(initialDashboardJointProjectTeamState());
  };

  useEffect(() => {
    const updatedProjects = projectList.map((project) => ({
      name: project.name,
      value: project.id
    }));
    setProjects(updatedProjects || []);
  }, [projectList]);

  useEffect(() => {
    if (!activeProject) return;
    const active = projectList.find((project) => project.id === activeProject.value);
    if (active) {
      const updatedTeams = active.teams.map((team) => ({
        name: team.name,
        value: team.id
      }));
      setTeams([{ name: "Все команды", value: "" }, ...updatedTeams] || []);
    } else {
      setTeams([{ name: "Все команды", value: "" }]);
    }
  }, [projects, activeProject]); //eslint-disable-line

  useEffect(() => {
    if (!projectId || !projects) return;
    const current = projects.find((item) => item.value === projectId);
    setActiveProject(current);
  }, [projects, projectId]);

  useEffect(() => {
    if (!teamId || !teams) return;
    const current = teams.find((item) => item.value === teamId);
    setActiveTeam(current);
  }, [teams, teamId]);

  const handleProjectChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | MouseEvent<HTMLButtonElement>
  ): void => {
    const { value, name } = e.currentTarget;
    setActiveProject({ name, value });
    setTeams([{ name: "Все команды", value: "" }]);
    setActiveTeam({ name: "Все команды", value: "" });
  };

  const handleTeamChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | MouseEvent<HTMLButtonElement>
  ): void => {
    const { value } = e.currentTarget;
    setActiveTeam({ name: teams.find((item) => item.value === value).name, value });
  };

  const handleConfirm = () => {
    callBack(activeProject.value, activeTeam.value);
    closeModal();
  };

  return (
    <PopUp set={closeModal} position="top">
      <HeaderModal onClose={closeModal} title={__("Настройка виджета - Команда проекта")} />
      <div className={styles.container}>
        <div className={styles.content}>
          <SelectCustom
            variantHeight="small"
            width={368}
            options={projects}
            selectedDefault={activeProject}
            value={activeProject}
            onSelected={handleProjectChange}
            label={__("Проект")}
          />
          <SelectCustom
            variantHeight="small"
            width={368}
            options={teams}
            onSelected={handleTeamChange}
            selectedDefault={activeProject}
            value={activeTeam}
            label={__("Команда")}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("Отмена")}
            onClick={closeModal}
          />
          <Button
            disabled={!activeProject?.value}
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={__("Применить")}
            onClick={handleConfirm}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default DashboardJointProjectTeamModal;
