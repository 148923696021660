import classNames from "classnames";
import { useProfileMenu } from "collections/profile";
import IconTooltip from "generalComponents/IconTooltip/IconTooltip";
import List from "generalComponents/List/List";
import { useLocales } from "react-localized";
import { NavLink } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./ProfileMenu.module.sass";

const ProfileMenu = () => {
  const { __ } = useLocales();
  const { userInfo } = useUserSelectors();
  const { main, secondary, addition1, addition2 } = useProfileMenu(userInfo);
  return (
    <List title={__("Мой Профиль")} childrenBackground="#fff">
      <div className={styles.block}>
        {main.map((item, idx) => (
          <NavLink
            to={item.path}
            key={idx}
            className={({ isActive }) => classNames(styles.link, { [styles.active]: isActive })}
          >
            <div className={styles.iconBox}>{item.icon}</div>
            <span className={styles.name}>{item.name}</span>
            {item.isAdminLabel && (
              <IconTooltip bgColor="orange" text={__("У вас есть административный доступ к этому разделу")}>
                <span className={styles.admin}>Admin</span>
              </IconTooltip>
            )}
          </NavLink>
        ))}
      </div>

      <div className={styles.block}>
        {secondary.map((item, idx) => (
          <NavLink
            to={item.path}
            key={idx}
            className={({ isActive }) => classNames(styles.link, { [styles.active]: isActive })}
          >
            <div className={styles.iconBox}>{item.icon}</div>
            <span className={styles.name}>{item.name}</span>
          </NavLink>
        ))}
      </div>
      <div className={styles.block}>
        {addition1.map((item, idx) => (
          <NavLink
            to={item.path}
            key={idx}
            className={({ isActive }) => classNames(styles.link, { [styles.active]: isActive })}
          >
            <div className={styles.iconBox}>{item.icon}</div>
            <span className={styles.name}>{item.name}</span>
          </NavLink>
        ))}
      </div>
      <div className={styles.block}>
        {addition2.map((item, idx) => (
          <NavLink
            to={item.path}
            key={idx}
            className={({ isActive }) => classNames(styles.link, { [styles.active]: isActive })}
          >
            <div className={styles.iconBox}>{item.icon}</div>
            <span className={styles.name}>{item.name}</span>
          </NavLink>
        ))}
      </div>
    </List>
  );
};

export default ProfileMenu;
