import { FoldersAction } from "models/store/folders/foldersActions";
import { IFoldersState, initialFoldersState } from "models/store/folders/foldersStore";
import { FoldersTypes } from "Store/types/foldersTypes";

const INITIAL_STATE: IFoldersState = initialFoldersState();

export const FoldersReducer = (state = INITIAL_STATE, action: FoldersAction): IFoldersState => {
  switch (action.type) {
    case FoldersTypes.SET_FOLDERS:
      return {
        ...state,
        folders: [...action.payload.systemFolders, ...action.payload.otherFolders]
      };

    case FoldersTypes.OPEN_FOLDER:
      return {
        ...state,
        openFolder: action.payload
      };

    case FoldersTypes.SELECT_FOLDER:
      return {
        ...state,
        currentFolder: action.payload
      };

    case FoldersTypes.FOLDER_PATH:
      return {
        ...state,
        folderPath: action.payload
      };

    case FoldersTypes.NULLIFY_FOLDERS: {
      return {
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
};
