import { ReactComponent as TeamIcon } from "assets/icons/group-team.svg";
import { ReactComponent as NoteIcon } from "assets/icons/note.svg";
import { ReactComponent as ReminderIcon } from "assets/PrivateCabinet/bell.svg";
import { ReactComponent as Taskicon } from "assets/PrivateCabinet/calendar-4.svg";
import { ReactComponent as MailIcon } from "assets/PrivateCabinet/mail/unread.svg";
import { ReactComponent as MeetingIcon } from "assets/PrivateCabinet/meeting.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-3.svg";
import { ROUTES } from "generalComponents/variables/routing";
import { TASK_MODALS, TASK_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { IProjectTask } from "models/store/joinProjects/joinProgects";
import { IMyTask } from "models/store/tasks/tasks";
import { useNavigate } from "react-router";

import styles from "./TaskIcon.module.sass";

const TaskIcon = ({ item }: { item: IMyTask | IProjectTask }) => {
  const { formatDate } = useDateFormat();
  const { onSetTaskModal } = useActions();
  const navigate = useNavigate();

  const renderIcon = () => {
    switch (item.id_type) {
      case TASK_TYPES.MAILS:
        return <MailIcon fill="#EFBB45" width={23} height={23} />;
      case TASK_TYPES.CALLS:
        return <PhoneIcon fill="#2ABD27" width={23} height={23} />;
      case TASK_TYPES.ONLINE_MEETING:
      case TASK_TYPES.OFFLINE_MEETING:
      case TASK_TYPES.MEETINGS:
        return <MeetingIcon width={23} height={23} />;
      case TASK_TYPES.REMINDER:
        return <ReminderIcon width={23} height={23} />;
      case TASK_TYPES.NOTES:
        return <NoteIcon width={23} height={23} />;
      case TASK_TYPES.TASK:
        if ("id_project" in item && !item.id_project) {
          return <Taskicon width={23} height={23} />;
        }
        break;
      default:
        return <TeamIcon width={23} height={23} fill="#2FB292" />;
    }
  };

  const onOpen = (): void => {
    "id_project" in item && item.id_project
      ? navigate(`/${ROUTES.PROJECT}/${item.id_project}/task/${item.id}`)
      : onSetTaskModal({
          type: TASK_MODALS.OPEN_TASK,
          params: item
        });
  };

  return (
    <div className={styles.container} onClick={onOpen}>
      <div className={styles.iconWrapper}>{renderIcon()}</div>
      <div className={styles.text}>
        <div>{item.name}</div>
        <div>
          {item.date_end.length > 0
            ? formatDate(item.date_end, "date")
            : "date_start" in item && item.date_start.length > 0 && formatDate(item.date_start, "date")}
        </div>
      </div>
    </div>
  );
};

export default TaskIcon;
