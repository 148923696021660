import { ReactComponent as CommentIcon } from "assets/PrivateCabinet/comm.svg";
import { ReactComponent as FireIcon } from "assets/PrivateCabinet/fire.svg";
import classNames from "classnames";
import { useContextMenuTask } from "collections/tasks";
import FileTags from "generalComponents/FileTags/FileTags";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { createMarkup } from "generalComponents/Services/browserServices";
import { TASK_MODALS, URGENCY_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ITaskKanbanProps } from "models/tasks/myTasks";
import React from "react";
import { useLocales } from "react-localized";

import TaskStatusSelect from "../TaskStatusSelect/TaskStatusSelect";
import styles from "./TaskKanban.module.sass";

const TaskKanban: React.FC<ITaskKanbanProps> = ({ task, color, num }): JSX.Element => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const contextMenu = useContextMenuTask();
  const { onSetTaskModal } = useActions();

  const onAddComment = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.ADD_COMMENT_TASK,
      params: {
        ...task
      }
    });
  };

  const onOpen = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.OPEN_TASK,
      params: task
    });
  };

  return (
    <div className={styles.taskBox} style={{ background: color.light, borderColor: color.line }} onClick={onOpen}>
      <div className={styles.top}>
        <div className={styles.num} style={{ background: color.dark }}>
          {num}
        </div>
        <div className={styles.attr}>
          {task.tags.length > 0 && <FileTags item={task} />}
          {task.color && <div className={styles.color} style={{ background: task.color }} />}
          {task.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: task.emo }} />}
          <div
            className={classNames(styles.commentBox, { [styles.isComment]: task.comments?.length > 0 })}
            onClick={onAddComment}
          >
            <CommentIcon />
          </div>
        </div>
        <PopoverContextMenu data={contextMenu(task)} title={__("Действия")} />
      </div>
      <div className={styles.name} style={{ borderColor: color.secondary }}>
        {task.id_act === URGENCY_TYPES.URGENT && <FireIcon className={styles.fireIcon} />}
        <span>{task.name}</span>
      </div>
      <div className={styles.textBox}>
        <p className={styles.subtitle}>{__("Описание:")}</p>
        <div className={classNames("htmlContent", styles.text)} dangerouslySetInnerHTML={createMarkup(task.prim)} />
      </div>
      <div className={styles.bodyItem}>
        <p className={styles.subtitle}>{__("Статус задачи:")}</p>
        <TaskStatusSelect task={task} />
      </div>
      <div className={styles.bodyItem}>
        <p className={styles.subtitle}>{__("Срок выполнения:")}</p>
        <div className={styles.sprint} style={{ borderColor: color.secondary }}>
          {formatDate(task.date_start)} - <br />
          {formatDate(task.date_end)}
        </div>
      </div>
    </div>
  );
};

export default TaskKanban;
