import { useSystemFolder } from "generalComponents/collections/folder";
import FolderInfo from "generalComponents/FolderInfo/FolderInfo";
import { IFolderItemProps } from "models/generalComponents/folderItem";
import { IDefaultFolders } from "models/generalComponents/folders";
import { IFolder } from "models/store/folders/foldersStore";
import React, { useMemo } from "react";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";

import styles from "./FolderItem.module.sass";

const FolderItem: React.FC<IFolderItemProps> = ({
  folder,
  listCollapsed,
  indent = 10,
  handleSelectFolder,
  filteredContextMenu,
  contextMenu
}): JSX.Element => {
  const SYSTEM_FOLDERS: Record<string, IDefaultFolders> = useSystemFolder();
  const { openFolder, folderPath } = useFoldersSelectors();

  const isChosen = useMemo((): boolean => {
    const idx = folderPath.findIndex((el) => el.id_dir === folder.id_dir);

    return idx >= 0;
  }, [folder.id_dir, folderPath]);

  const getTooltip = (): string | null => {
    if (listCollapsed) {
      return folder.is_system === "1" ? SYSTEM_FOLDERS[folder.name].nameRu : folder.name;
    }
    return null;
  };

  const getInsideFolder = (folder: IFolder): JSX.Element => (
    <FolderItem
      key={folder.id_dir}
      folder={folder}
      listCollapsed={listCollapsed}
      indent={indent + 15}
      handleSelectFolder={handleSelectFolder}
      filteredContextMenu={filteredContextMenu}
      contextMenu={filteredContextMenu(folder)}
    />
  );

  const renderInsideFolder = (): JSX.Element => {
    if (!listCollapsed && openFolder.includes(folder.id_dir) && folder.folders.length > 0) {
      return <ul className={styles.folderList}>{folder.folders.map((folder) => getInsideFolder(folder))}</ul>;
    }
  };
  if (folder.is_archive || folder.is_del) return;

  return (
    <li className={styles.folderItem} onClick={(e) => handleSelectFolder(e, folder)} title={getTooltip()}>
      <FolderInfo
        folder={folder}
        indent={indent}
        listCollapsed={listCollapsed}
        isChosen={isChosen}
        contextMenu={contextMenu}
      />
      {renderInsideFolder()}
    </li>
  );
};

export default FolderItem;
