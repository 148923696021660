import classNames from "classnames";
import JointProjectsBacklogSidebar from "containers/JoinProject/JointProjectsTasks/components/JointProjectsBacklogSidebar/JointProjectsBacklogSidebar";
import JointProjectsTaskCard from "containers/JoinProject/JointProjectsTasks/components/JointProjectsTaskCard/JointProjectsTaskCard";
import { startOfHour } from "date-fns";
import Button from "generalComponents/Button/Button";
import { ViewType } from "generalComponents/variables/global";
import { PROJECT_PATH } from "generalComponents/variables/routing";
import { useWebSocketContext } from "generalComponents/WebSocketsProvider/WebSocketsProvider";
import {
  useDefaultProjectStatuses,
  useFilteredTasks,
  useFindCurrentSprint,
  useFindSprint,
  useGetDuration,
  useGetTaskEnd,
  useUsersToProject
} from "hooks/joinProjectHooks";
import { useIsSystemWatherRole } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IJointProjectStatus, IProjectTask, STATUSES } from "models/store/joinProjects/joinProgects";
import { useEffect, useRef, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { useLocales } from "react-localized";
import { useLocation } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { dateISO } from "utils/dateToISO";
import { initialsProjectTask } from "utils/jointPtoject";
import { v4 as uuid } from "uuid";

import styles from "./JointProjectsTasks.module.sass";

const ReactGridLayout = WidthProvider(RGL);

export interface IExtendsProjectTask extends IProjectTask {
  w: number;
  i: string;
  h: number;
  x: number;
  y: number;
}

export interface ExtendedLayout extends RGL.Layout, IExtendsProjectTask {}

const JointProjectsTasks = ({ showBacklog, viewTab }: { showBacklog: boolean; viewTab: ViewType }) => {
  const { __ } = useLocales();
  const location = useLocation();
  const { sendMessage } = useWebSocketContext();
  const { uid, userInfo } = useUserSelectors();
  const { project } = useJoinProjectsSelectors();
  const { filteredTasks } = useFilteredTasks(project.tasks);
  const users_to = useUsersToProject();
  const {
    onEditJointProjectTasks,
    onSetCreateProjectTaskModal,
    onSetBackLogProject,
    onSetTaskOverdueModal,
    onSetTasksProject
  } = useActions();
  const defaultStatuses = useDefaultProjectStatuses();

  const { id_sprint } = useAllSelectedSearchParams();

  const [wrapperWidth, setWrapperWidth] = useState<number | null>(null);
  const [gridWidth, setGridWidth] = useState<number | null>(null);

  // основная доска с задачами
  const [mainBoard, setMainBoard] = useState<IExtendsProjectTask[]>([]);
  // елемент который перетаскивается из сайдбара беклога
  const [draggedWidget, setDraggedWidget] = useState<IExtendsProjectTask | null>(null);
  // елемент который перетаскивается внутри доски
  const [dragedElement, setDragedElement] = useState<ExtendedLayout>();
  // обьект для хранения высоты задач
  const [cardHeights, setCardHeights] = useState<Record<string, number>>({});
  // массив задач с которым мы работаем при перетаскивании комонентов преждем чем они попапут в mainBoard
  const [newLayout, setNewLayout] = useState<ExtendedLayout[]>([]);
  // массив активных статусов
  const [activeTabStatus, setActiveTabStatus] = useState<IJointProjectStatus>();

  const containerRef = useRef(null);
  const gridRef = useRef(null);
  const wrapperRef = useRef(null);
  const getDateEnd = useGetTaskEnd();
  const getDuration = useGetDuration();
  const findSprint = useFindSprint();
  const currentSprint = useFindCurrentSprint();

  const controller = new AbortController();

  const isSystemWatherRole = useIsSystemWatherRole();

  // функция для обновления массива карточек
  const updateCardHeight = (itemI: string, height: number) => {
    setCardHeights((prev) => ({ ...prev, [itemI]: height }));
  };

  // отслеживания ширины елементов для правильного расчета ширины карточки и скрола экрана
  useEffect(() => {
    const container = containerRef.current;
    const resizeObserver = new ResizeObserver(() => {
      setGridWidth(gridRef?.current?.getBoundingClientRect().width);
      setWrapperWidth(wrapperRef?.current?.getBoundingClientRect().width);
    });
    if (container) {
      resizeObserver.observe(container);
    }
    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [containerRef]);

  // сортеруем статусы по очереди и выводим только те что видимы
  const sortedStatuses = project.statuses
    .filter((item) => item.is_visible)
    .sort((a, b) => a.y - b.y)
    .map((item, index) => ({
      ...item,
      x: index,
      y: 0,
      i: item.id,
      w: 1,
      h: 28,
      static: true
    }));

  // добавляем активный элемент при перетаскивании из сайдбара бэклога
  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, item: IExtendsProjectTask) => {
    e.dataTransfer.setData("text/plain", "");
    setDraggedWidget(item);
  };

  // сбрасываем активный элемент после перетаскивании из сайдбара бэклога
  const dragEndHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData("text/plain", "");
    setDraggedWidget(null);
  };

  // срабатывает при любом изминении newLayout
  useEffect(() => {
    // проверяем или он есть
    if (newLayout.length === 0) return;
    // получаем активные статусы для проверки и активный елементс которым сейчас работаем в текущем newLayout и mainBoard
    const overdueStatus = sortedStatuses.find((status) => status.system_name === STATUSES.OVERDUE);
    const doneStatus = sortedStatuses.find((status) => status.system_name === STATUSES.DONE);
    const taskInActiveLayout = newLayout.find((item) => item.i === dragedElement.i);
    const taskInMainboard = mainBoard.find((item) => item.i === dragedElement.i);
    const dateEnd = taskInMainboard?.date_long || taskInMainboard?.date_end;
    // проверяем или элемент перетаскивается из "Просроченно" в любую колонку кроме "Готово" и его даты
    if (
      dragedElement.x === overdueStatus.x &&
      taskInActiveLayout.x !== doneStatus.x &&
      taskInActiveLayout.x !== overdueStatus.x &&
      dateEnd &&
      new Date(dateEnd) < new Date()
    ) {
      // вызываем модальное окно
      onSetTaskOverdueModal({
        open: true,
        task: taskInMainboard,
        callbackOk: () => {
          // при успехе запускаем onDragStop
          onDragStop(newLayout);
        },
        callbackCancel: () => {
          // при отмене обновляем mainBoard через redux что бы вернуть елемент в прошлую колонку
          const updatedTasks = project.tasks.map((task) => {
            if (task.id === dragedElement.i) {
              return {
                ...task,
                updated: uuid()
              };
            }
            return task;
          });
          onSetTasksProject(updatedTasks);
        }
      });
    } else {
      // если елемент перетаскиваеться не из "Просроченно" запускаем onDragStop
      onDragStop(newLayout);
    }

    return () => controller.abort();
  }, [newLayout]); //eslint-disable-line

  // функуция для сокетов при перемещении из беклога
  const sendTaskFromBacklogkWS = (tasks: IProjectTask[], idTask: string) => {
    sendMessage(
      JSON.stringify({
        action: "task_info",
        tasks,
        idTask,
        idSprint: id_sprint.selectedValue,
        uid,
        users_to: users_to.filter((el) => el !== userInfo.id),
        actionType: "fromBacklog"
      })
    );
  };

  // функуция для сокетов при перемещении внутри доски
  const sendTasksMovedkWS = (tasks: IProjectTask[]) => {
    sendMessage(
      JSON.stringify({
        action: "task_info",
        tasks,
        idSprint: id_sprint.selectedValue,
        uid,
        users_to: users_to.filter((el) => el !== userInfo.id),
        actionType: "movedTasks"
      })
    );
  };

  const onDragStop = async (layout: ExtendedLayout[]) => {
    // получаем обновленную доску
    const updatedBoard = layout.map((item) => {
      // ищим задачи из одной колонки
      const tasksWithSameX = layout.filter((task) => task.x === item.x);
      // сортируем задачи по y
      const sortedTasks = tasksWithSameX.sort((a, b) => a.y - b.y);
      // получаем очередь
      const order = sortedTasks.findIndex((task) => task.i === item.i);
      // нахходим статус
      const id_status = sortedStatuses.find((s) => s.x === item.x).id;
      // нахоим елемент который перетаскивали
      const currentMianBoardItem = mainBoard.find((task) => task.i === item.i);
      return {
        ...item,
        id_status,
        date_end: currentMianBoardItem.date_end,
        date_long: currentMianBoardItem.date_long,
        position: {
          ...currentMianBoardItem.position,
          [id_sprint.selectedValue]: {
            order
          }
        }
      };
    });

    // находим все элементы данные который были изменены в последствии перемещения
    const changedItems = updatedBoard.filter((currentItem) => {
      const prevItem = mainBoard.find((prevItem) => prevItem.i === currentItem.i);
      const shouldInclude =
        prevItem?.x !== currentItem?.x || JSON.stringify(prevItem?.position) !== JSON.stringify(currentItem?.position);
      return shouldInclude;
    });

    // если есть измененные елементы
    if (changedItems.length > 0) {
      // создаем обьект с измененными елементами где ключ их id
      const changedItemsMap = changedItems.reduce((result, currentItem) => {
        // добавляем статус и позицию
        result[currentItem.i] = {
          id_status: currentItem.id_status,
          position: { ...currentItem.position }
        };
        // Пересчитываем date_long если зада перестаскиваеться в готово но была ранее в просоченых
        if (
          currentItem.i === dragedElement.i &&
          currentItem.date_long &&
          currentItem.id_status === sortedStatuses.find((status) => status.system_name === STATUSES.DONE).id
        ) {
          const timeLong = getDuration(new Date(currentItem.date_end), new Date());
          const dateLong = getDateEnd(new Date(currentItem.date_end), timeLong);
          result[currentItem.i].date_long = dateISO(dateLong);
          result[currentItem.i].time_long = timeLong;
        }

        return result;
      }, {} as Record<string, { id_status: string; position: IProjectTask["position"]; date_long?: string; time_long?: number }>);

      // вызываем функцию взаимодействия с бэком
      onEditJointProjectTasks(changedItemsMap, id_sprint.selectedValue, controller, (tasks: IProjectTask[]) => {
        sendTasksMovedkWS(tasks);
      });
    }
    // сбрасываем активный елемент
    setDragedElement(undefined);
  };

  const onDrop = (layout: ExtendedLayout[]) => {
    // получаем обновленную доску
    const updatedBoard = layout.map((item) => {
      // ищим задачи из одной колонки
      const tasksWithSameX = layout.filter((task) => task.x === item.x);
      // сортируем задачи по y
      const sortedTasks = tasksWithSameX.sort((a, b) => a.y - b.y);
      // получаем очередь
      const order = sortedTasks.findIndex((task) => task.i === item.i);
      // нахходим статус
      const id_status = sortedStatuses.find((s) => s.x === item.x).id;
      // находим позицию елемента который перетаскивали
      const currentMianBoardPosition = mainBoard.find((task) => task.i === item.i)?.position;
      return {
        ...item,
        id: item.i,
        id_status,
        position: {
          ...currentMianBoardPosition,
          [id_sprint.selectedValue]: {
            order
          }
        }
      };
    });

    // находим все элементы данные который были изменены в последствии перемещения
    const changedItems = updatedBoard.filter((currentItem) => {
      const prevItem = mainBoard.find((prevItem) => prevItem.i === currentItem.i);
      const shouldInclude =
        prevItem?.x !== currentItem?.x || JSON.stringify(prevItem?.position) !== JSON.stringify(currentItem?.position);
      return shouldInclude;
    });

    // если есть измененные елементы
    if (changedItems?.length > 0) {
      // создаем обьект с измененными елементами где ключ их id
      const changedItemsMap = changedItems.reduce((result, currentItem) => {
        // добавляем статус и позицию и спринт
        result[currentItem.i as string] = {
          id_status: currentItem.id_status,
          id_sprints: currentItem.id_sprints?.length > 0 ? currentItem.id_sprints : [id_sprint.selectedValue],
          position: { ...currentItem.position }
        };

        // высчитываем и добавляем date_long, date_end и date_end
        if (draggedWidget.i === currentItem.i) {
          const _sprint = findSprint(id_sprint.selectedValue);
          let dateStart = null;
          if (currentSprint.id === _sprint?.id) {
            dateStart = startOfHour(new Date()).setHours(project.work_hours[0]);
          } else {
            dateStart = new Date(_sprint?.date_start).setHours(project.work_hours[0]);
          }
          const duration = project.work_hours.length * 60;
          const dateEnd = getDateEnd(new Date(dateStart), duration);
          result[currentItem.i].date_start = dateISO(new Date(dateStart));
          result[currentItem.i].date_end = dateISO(dateEnd);
          result[currentItem.i].duration = duration;
        }

        return result;
      }, {} as Record<string, { id_status: string; position: IProjectTask["position"]; id_sprints: IProjectTask["id_sprints"]; date_start?: string; date_end?: string; duration?: number }>);

      // вызываем функцию взаимодействия с бэком
      onEditJointProjectTasks(
        changedItemsMap,
        id_sprint.selectedValue,
        controller,
        (tasks: IProjectTask[]) => sendTaskFromBacklogkWS(tasks, draggedWidget.id),
        false
      );
    }

    // находим обновленные задачи в текущем проекте
    const updatedTasks = project.tasks.map((task) => {
      // находим задачу и перезаписываем ей id_status и position
      const changedItem = changedItems.find((item) => item.id === task.id);
      if (changedItem) {
        return {
          ...task,
          id_status: changedItem.id_status,
          position: changedItem.position
        };
      }
      return task;
    });

    // находим активную задачу в беклоге
    const activeTask = project.backlog.find((item) => item.id === draggedWidget.id);
    // находим активную задачу в данных которые были изменены в последствии перемещения
    const activeTaskLauout = changedItems.find((item) => item.id === draggedWidget.id);
    // перезаписываем ей id_status и position для активной задачи в беклоге
    activeTask.position = activeTaskLauout.position;
    activeTask.id_status = activeTaskLauout.id_status;
    // обновляем локально список задач
    onSetTasksProject([...updatedTasks, activeTask]);
    // обновляем беклог
    const updatedBackLog = project.backlog.filter((task) => task.id !== draggedWidget.id);
    onSetBackLogProject(updatedBackLog);
    // сбрасываем активные елементы
    setDraggedWidget(undefined);
    setDragedElement(undefined);
  };

  useEffect(() => {
    const updatedTasks = filteredTasks
      ?.map((task) => {
        const statusObject = sortedStatuses.find((status) => status.id === task.id_status);
        if (statusObject) {
          return {
            ...task,
            x: statusObject.x,
            y: task.position[id_sprint.selectedValue]?.order || 0,
            i: task.id,
            w: 1,
            h: 28
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);
    if (JSON.stringify(mainBoard) !== JSON.stringify(updatedTasks)) {
      setMainBoard(updatedTasks);
    }
  }, [filteredTasks]); //eslint-disable-line

  const isTopItem = (item: IExtendsProjectTask) => {
    const itemsWithSameX = mainBoard.filter((boardItem) => boardItem.x === item.x);
    const maxItem = itemsWithSameX.reduce((max, current) => (current.y > max.y ? current : max), itemsWithSameX[0]);
    return item.y === maxItem.y;
  };

  const addTask = (defaultStatus: string) =>
    onSetCreateProjectTaskModal({
      open: true,
      type: "add",
      task: {
        ...initialsProjectTask(),
        id_sprints: id_sprint.selectedValue ? [id_sprint.selectedValue] : [],
        id_status: defaultStatus
      }
    });

  const calculateGridWidth = () => {
    const baseWidth = sortedStatuses.length * 260;

    if (baseWidth < gridWidth) {
      if (wrapperWidth > gridWidth) return wrapperWidth;
      return gridWidth;
    } else if (sortedStatuses.length > 4) {
      return `${baseWidth}px`;
    } else {
      return "auto";
    }
  };

  useEffect(() => {
    if (!sortedStatuses) return;
    setActiveTabStatus(sortedStatuses.find((status) => status.x === 0));
  }, [viewTab]); //eslint-disable-line

  const isMyTasksPage = location.pathname.includes(PROJECT_PATH.MY_TASKS);

  return (
    <div className={styles.container} ref={containerRef}>
      <JointProjectsBacklogSidebar
        showBacklog={showBacklog}
        dragStartHandler={!isSystemWatherRole ? dragStartHandler : undefined}
        dragEndHandler={!isSystemWatherRole ? dragEndHandler : undefined}
      />
      <div
        className={classNames(
          styles.scrollWrapper,
          isMyTasksPage && styles.scrollWrapperMyTasksPage,
          "scrollbar-medium-blue"
        )}
        ref={wrapperRef}
      >
        <div
          className={classNames(
            viewTab === ViewType.LINES && styles.scrollTitlesWrapper,
            viewTab === ViewType.LINES && "scrollbar-medium-blue"
          )}
          style={{ width: viewTab === ViewType.LINES ? "100%" : calculateGridWidth() }}
        >
          <ReactGridLayout
            cols={sortedStatuses.length}
            className={styles.titles}
            margin={[0, 0]}
            style={{ width: calculateGridWidth() }}
            rowHeight={1}
          >
            {sortedStatuses.map((item) => (
              <div
                key={item.id}
                className={classNames(
                  styles.itemTitle,
                  viewTab === ViewType.LINES && styles.inlineItemTitle,
                  viewTab === ViewType.LINES && activeTabStatus?.id === item.id && styles.activeItemTitle
                )}
                data-grid={item}
                onClick={() => setActiveTabStatus(item)}
              >
                <div className={styles.title}>
                  <div className={styles.color} style={{ background: item.color }} />
                  {item.name ? item.name : defaultStatuses[item.system_name]}
                </div>
                <div className={styles.count}>{mainBoard.filter((task) => task.x === item.x).length}</div>
                {!dragedElement &&
                  !draggedWidget &&
                  !mainBoard.some((task) => task.x === item.x) &&
                  !isSystemWatherRole && (
                    <Button
                      className={styles.addButton}
                      variant={ButtonVariantType.OPACITY}
                      size={ButtonSizeType.SMALL}
                      onClick={() => addTask(item.id)}
                      onMouseDown={(e) => e.stopPropagation()}
                      text={__("+ Добавить")}
                    />
                  )}
              </div>
            ))}
          </ReactGridLayout>
        </div>
        <div
          className={classNames(styles.content, "scrollbar-thin-blue")}
          style={{ width: viewTab === ViewType.LINES ? "100%" : calculateGridWidth() }}
          ref={gridRef}
        >
          <ReactGridLayout
            isDraggable={!isSystemWatherRole}
            cols={viewTab === ViewType.LINES ? 1 : sortedStatuses.length}
            style={{
              width: viewTab === ViewType.LINES ? "100%" : calculateGridWidth()
            }}
            rowHeight={1}
            margin={[0, 0]}
            onDragStart={(_, item) => {
              setDragedElement(item as ExtendedLayout);
            }}
            onDragStop={(layout) => {
              setNewLayout(layout as ExtendedLayout[]);
            }}
            onDrop={(layout) => onDrop(layout as ExtendedLayout[])}
            className={styles.grid}
            droppingItem={draggedWidget}
            isDroppable={true}
          >
            {mainBoard.length > 0 &&
              mainBoard
                .filter((item) => (viewTab === ViewType.LINES ? item.x === activeTabStatus?.x : true))
                .map((item) => {
                  return (
                    <div
                      key={item.i}
                      className={styles.item}
                      data-grid={{
                        ...item,
                        h: cardHeights[item.i] + 10 || 28,
                        isResizable: false
                      }}
                    >
                      <JointProjectsTaskCard
                        item={item}
                        onHeightChange={(height: number) => updateCardHeight(item.i, height)}
                      />
                      {!dragedElement && !draggedWidget && isTopItem(item) && !isSystemWatherRole && (
                        <Button
                          className={styles.addButton}
                          variant={ButtonVariantType.OPACITY}
                          size={ButtonSizeType.SMALL}
                          onClick={() => addTask(sortedStatuses.find((status) => status.x === item.x)?.id)}
                          onMouseDown={(e) => e.stopPropagation()}
                          text={__("+ Добавить")}
                        />
                      )}
                    </div>
                  );
                })}
            {viewTab === ViewType.LINES && mainBoard.filter((item) => item.x === activeTabStatus?.x).length === 0 && (
              <div
                className={styles.item}
                key={"emptyColumn"}
                data-grid={{
                  h: 28,
                  w: 1,
                  x: 0,
                  y: 0,
                  isResizable: false
                }}
              >
                {!isSystemWatherRole && (
                  <Button
                    variant={ButtonVariantType.OPACITY}
                    size={ButtonSizeType.SMALL}
                    onClick={() => addTask(sortedStatuses.find((status) => status.x === activeTabStatus?.x)?.id)}
                    onMouseDown={(e) => e.stopPropagation()}
                    text={__("+ Добавить")}
                  />
                )}
              </div>
            )}
          </ReactGridLayout>
        </div>
      </div>
    </div>
  );
};

export default JointProjectsTasks;
