import { format } from "date-fns";
import Button from "generalComponents/Button/Button";
import Calendar from "generalComponents/Calendars/Calendar/Calendar";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { taskTypes } from "types/Project";

import styles from "./ExtendPeriod.module.sass";

const ExtendPeriod = ({ onClose, params }) => {
  const { __ } = useLocales();
  const MSG = useProjectMsg();
  const [openCalendar, setOpenCalendar] = useState(false);
  const { onEditProjectTask } = useActions();

  const onSubmit = (value) => {
    const payload = {
      ...params.task,
      tag: params.task.tags ?? "",
      prim: params.task.prim ?? "",
      date_long: value ? `${value} 23:59:59` : ""
    };

    const messages = {
      error: MSG.ERROR,
      success: {
        title: __("Дата успешно обновленна"),
        text: value
          ? `${__("Вы успешно продлили срок выполнения задачи до")} ${value} ${
              !params.isOpen ? __("и перенесли в выбранную категорию") : ""
            }`
          : __("Продление удалено")
      }
    };
    onEditProjectTask(payload, messages);
  };

  const onExtend = () => setOpenCalendar(true);

  const setShowCalendar = () => setOpenCalendar((prev) => !prev);

  const getDate = (date) => {
    return { year: new Date(date).getFullYear(), month: new Date(date).getMonth(), day: new Date(date).getDate() };
  };
  return (
    <div className={styles.wrap}>
      {!params.isOpen ? (
        <p className={styles.text}>
          {__(
            "У данной задачи истек срок выполнения! Чтобы переместить данную задачу в выбранную категорию, Вам нужно увеличить срок выполнения"
          )}
        </p>
      ) : (
        <p className={styles.text}>
          {__("Срок этой задачи продлен до")} {format(new Date(params.task.date_long), "dd.MM.yyyy")}.{" "}
          {__("Вы можете изменить или удалить срок продления")}
        </p>
      )}
      <div className={styles.btns}>
        <Button type="button" variant="cancel" onClick={onClose} text={__("Отмена")} />
        <Button type="button" variant="ok" onClick={onExtend} text={__("Продлить")} />
        {params.isOpen && (
          <Button type="button" variant="ok" onClick={() => onSubmit("")} text={__("Удалить продление")} />
        )}
      </div>
      {openCalendar && (
        <PopUp set={setShowCalendar} zIndex={102}>
          <Calendar
            setShowCalendar={setShowCalendar}
            setDateValue={(value) => onSubmit(value)}
            extend={{ start: getDate(params.task.date_start), end: getDate(params.task.date_end) }}
          />
        </PopUp>
      )}
    </div>
  );
};

export default ExtendPeriod;

ExtendPeriod.propTypes = {
  onClose: PropTypes.func,
  params: PropTypes.exact({
    task: taskTypes,
    isOpen: PropTypes.bool
  })
};
