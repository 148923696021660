import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export const useTrashElementsSelectors = () => {
  const TrashElements = useTypeSelector((s) => s.Trash);
  const elements = TrashElements.elements;
  const total = TrashElements.total;
  const page = TrashElements.page;
  const loader = TrashElements.loader;

  return {
    elements,
    total,
    page,
    loader
  };
};
