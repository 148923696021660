import { ReactComponent as AppleIcon } from "assets/icons/apple.svg";
import Button from "containers/Authentication/Button/Button";

import styles from "./Buttons.module.sass";

const AppleButton = (): JSX.Element => {
  return (
    <Button>
      Apple
      <AppleIcon className={styles.icon} />
    </Button>
  );
};

export default AppleButton;
