import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";
import { IProgramsState } from "models/store/programs/programs";

export const useProgramsSelectors = (): IProgramsState => {
  const programsSelector = useTypeSelector((s) => s.Programs);
  const programs = programsSelector.programs;
  const categories = programsSelector.categories;
  const searchedPrograms = programsSelector.searchedPrograms;
  const isSearchProgramsLoading = programsSelector.isSearchProgramsLoading;
  const isGetProgramsByCatLoading = programsSelector.isGetProgramsByCatLoading;

  return {
    categories,
    programs,
    searchedPrograms,
    isSearchProgramsLoading,
    isGetProgramsByCatLoading
  };
};
