import { ReactComponent as ExclamationIcon } from "assets/PrivateCabinet/exclamation.svg";
import classNames from "classnames";
import React from "react";

import styles from "./AlertModal.module.sass";

interface IAlertModalProps {
  text: string;
  title?: string;
  variant?: "red" | "green" | "yellow";
}

const AlertModal: React.FC<IAlertModalProps> = ({ text, title, variant = "red" }) => {
  return (
    <div className={classNames(styles.box, styles[variant])}>
      <div className={styles.icon}>
        <ExclamationIcon width={24} height={24} />
      </div>
      <div>
        {title && <h3 className={styles.title}>{title}</h3>}
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};

export default AlertModal;
