import { ReactComponent as ClockIcon } from "assets/icons/clock-2.svg";
import { ReactComponent as CloseIcon } from "assets/icons/cross.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import classNames from "classnames";
import LetterAttachment from "containers/Postbox/LetterAttachment/LetterAttachment";
import TinyMCE, { TinyFile } from "containers/Postbox/TinyMCE/TinyMCE";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import { useFindPostbox } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ILetterInfo, IPostbox } from "models/store/postbox/postbox";
import { FC, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { Editor as TinyMCEEditor } from "tinymce";
import { dateISO } from "utils/dateToISO";
import { v4 as uuid } from "uuid";

import styles from "./ScheduleView.module.sass";

interface IScheduleViewProps {
  letter: ILetterInfo;
  postbox: IPostbox;
}

const ScheduleView: FC<IScheduleViewProps> = ({ letter }) => {
  const { __ } = useLocales();
  const { onScheduleEdit, onScheduleDelete } = useActions();
  const [showDatepicker, setShowDatepicker] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [scheduleTime, setScheduleTime] = useState<string>(letter.time);
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const submitButton = useRef<HTMLButtonElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const filesRef = useRef<HTMLInputElement>(null);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const { postbox } = useFindPostbox();
  const { formatDate } = useDateFormat();

  const onChangeFilesHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUploadFiles((prevState) => [...prevState, ...Array.from(e.target.files)]);
  };

  const onDelete = () => {
    const messages = {
      success: __("Запланированое письмо удалено"),
      error: __("Что-то пошло не так! Попробуйте еще раз")
    };
    onScheduleDelete([letter.id], messages);
  };

  const editTime = () => {
    const messages = {
      error: __("Не удалось изменить время отправки"),
      success: __("Время отпраки изменено")
    };
    const formData = new FormData();
    formData.append("time", scheduleTime);
    formData.append("id_items[]", letter.id);
    formData.append("user", postbox?.email);

    onScheduleEdit(formData, messages);
    setShowDatepicker(false);
  };

  const editLetter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const messages = {
      error: __("Не удалось изменить письмо"),
      success: __("Письмо изменено")
    };
    const formData = new FormData();
    uploadFiles.forEach((file) => formData.append("files[]", file));
    formData.append("time", scheduleTime);
    formData.append("id_items[]", letter.id);
    formData.append("html", editorRef.current.getContent());
    formData.append("user", postbox?.email);

    onScheduleEdit(formData, messages);
    setIsEdit(false);
  };

  const getUploadFiles = (): TinyFile[] => {
    const upload: TinyFile[] = uploadFiles.map((file: File) => ({
      filename: file.name,
      mime: file.type,
      variant: "upload"
    }));
    return upload;
  };

  const onRemoveFiles = (file: TinyFile): void => {
    if (file.variant === "upload") {
      setUploadFiles((prev) => prev.filter((item) => item.name !== file.filename));
    }
  };

  return isEdit ? (
    <form className={styles.form} ref={formRef} onSubmit={editLetter}>
      <TinyMCE
        refFiles={filesRef}
        refEditor={editorRef}
        refSubmit={submitButton}
        setDateValue={setScheduleTime}
        dateValue={scheduleTime}
        files={getUploadFiles()}
        onRemoveFiles={onRemoveFiles}
        initialContent={letter.html}
        btnText={__("Сохранить")}
      />
      <button ref={submitButton} type="submit" style={{ display: "none" }}></button>
      <input
        style={{ display: "none" }}
        name="files[]"
        type="file"
        multiple
        onChange={onChangeFilesHandler}
        ref={filesRef}
      />
    </form>
  ) : (
    <div className={styles.letterItem}>
      <div className={styles.messageHeader}>
        <div className={styles.messageHeaderLeft}>
          <Avatar name={letter.from_name || letter.from} />

          <div className={styles.messageName}>
            <div className={styles.name}>{letter.from_name}</div>
            <div className={styles.email}>{letter.from}</div>
          </div>
        </div>
        <div className={classNames(styles.time, { [styles.show]: showDatepicker })}>
          <span>{formatDate(letter.time, "full")}</span> <ClockIcon />
        </div>
        <div className={classNames(styles.actions, { [styles.show]: showDatepicker })}>
          <Button
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.MEDIUM}
            onClick={() => {
              setIsEdit(true);
            }}
            iconL={<EditIcon />}
            isSquare
          />
          <Button
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.MEDIUM}
            onClick={() => setShowDatepicker(!showDatepicker)}
            iconL={<ClockIcon />}
            isSquare
            isActive={showDatepicker}
          />
          <Button
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.MEDIUM}
            onClick={onDelete}
            iconL={<TrashIcon />}
            isSquare
          />
          {showDatepicker && (
            <div className={styles.scheduleMessageModal}>
              <div className={styles.scheduleMessageHeader}>
                <div className={styles.scheduleMessageTitle}>{__("Запланированная отправка")}</div>
                <button className={styles.crossBtn} onClick={() => setShowDatepicker(false)}>
                  <CloseIcon fill="#ce3ce3" width={24} height={40} />
                </button>
              </div>
              <div className={styles.scheduleMessageContent}>
                <InputDate
                  startDate={new Date(scheduleTime)}
                  setStartDate={(v: Date) => setScheduleTime(dateISO(v))}
                  withTime
                />
              </div>
              <div className={styles.scheduleActions}>
                <Button
                  type="button"
                  variant={ButtonVariantType.EXRTA_LIGHT}
                  size={ButtonSizeType.MEDIUM}
                  text={__("Отменить")}
                  onClick={() => {
                    setScheduleTime(letter.time);
                    setShowDatepicker(false);
                  }}
                />
                <Button
                  type="button"
                  variant={ButtonVariantType.BLUE}
                  size={ButtonSizeType.MEDIUM}
                  text={__("Готово")}
                  onClick={editTime}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.text} dangerouslySetInnerHTML={{ __html: letter.html }} />
      {Array.isArray(letter.attachments) && (
        <div className={styles.attachments}>
          {letter.attachments.map((atachment) => (
            <LetterAttachment name={atachment.filename} type={atachment.type} key={uuid()} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ScheduleView;
