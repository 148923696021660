import { ReactComponent as MoveToArchiveIcon } from "assets/PrivateCabinet/myFolders/contextMenu/move-to-archive.svg";
import { ReactComponent as ShareIcon } from "assets/PrivateCabinet/myFolders/contextMenu/share.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/myFolders/contextMenu/trash.svg";
import { ReactComponent as PrintIcon } from "assets/PrivateCabinet/myFolders/fileContextMenu/print.svg";
import { useContextFile } from "collections/myFiles";
import { useContextFolder, useOnFolderToArchive, useOnFolderToTrash } from "collections/myFolders";
import { useContextSafeFile, useContextSafeFolder } from "collections/safe";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useAuthorizedSafe, useSafeFind } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./RightSide.module.sass";

const RightSide = ({ item }: { item: IFile | IFolder }): JSX.Element => {
  const { __ } = useLocales();
  const { contextMenuFolderCallback, contextMenuSharedFolderCallback } = useContextFolder();
  const { isShared } = useIsShared();
  const { safeId } = useParams();

  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const currentSafe = useSafeFind();
  const { uid } = useUserSelectors();
  const { contextMenuFileCallbackLinesView, contextMenuFileToMe } = useContextFile();
  const { contextMenuSafeFolderCallback, contextMenuSafeFolderToMeCallback } = useContextSafeFolder();
  const { onFolderToArchive } = useOnFolderToArchive();
  const { onFolderToTrash } = useOnFolderToTrash();
  const authorizedSafe = useAuthorizedSafe();

  const { contextMenuSafeFileLines } = useContextSafeFile();

  const {
    onFilePrint,
    onDeleteFiles,
    onDelSafeFolder,
    fetchPrintFileUrl,
    onDeleteHardFiles,
    onSetApproveModal,
    onMoveFileToArchive,
    onSetShareToMessengersModal
  } = useActions();

  const safe = useAuthorizedSafe();

  const handleClickMoveToArchiveButton = (): void => {
    if (item.is_dir === 1) {
      onFolderToArchive(item as IFolder);
      return;
    }

    const payload = {
      fids: [(item as IFile).fid],
      id_dir: item.id_dir
    };

    const params = {
      title: __("Переместить в архив"),
      text: __("Вы действительно хотите переместить выбранный файл в архив?"),
      approveBtn: __("Переместить"),
      callback: (): void => {
        onMoveFileToArchive(payload, EVENT_TYPE.ARCHIVE, safe);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  const handleClickPrintButton = (): void => {
    if (isSafePage) {
      onFilePrint({
        open: true,
        url: `ajax/safe_file_preview.php?&uid=${uid}&fid=${(item as IFile).fid}&code=${safe.code}&pass=${
          safe.pass
        }&id_safe=${safe.id_safe}`,
        fid: (item as IFile).fid
      });
    }
    const messages = {
      error: __("Не удалось напечатать файл. Попробуйте еще раз!")
    };

    fetchPrintFileUrl(item as IFile, messages);
  };

  const handleShareToMessengersModal = (): void => {
    onSetShareToMessengersModal({
      open: true,
      params: item
    });
  };

  const handleClickDeleteButton = (): void => {
    if (item.is_dir && !isSafePage) {
      onFolderToTrash(item as IFolder);
      return;
    }
    if (item.is_dir && isSafePage) {
      const payload = {
        id_safe: safeId,
        code: authorizedSafe.code,
        id_dirs: [item.id_dir],
        pass: authorizedSafe.pass,
        id_parent: (item as IFolder).id_parent
      };

      const params = {
        titleHead: __("Удалить"),
        text: __("Вы действительно хотите удалить папку?"),
        approveBtn: __("Удалить"),
        callback: (): void => {
          onDelSafeFolder(payload);
        }
      };

      onSetApproveModal({
        open: true,
        params
      });
      return;
    }
    const getTrashTitle = (): string => {
      if (item.is_dir === 1) return __("Удаление папки");
      return __("Удаление файла");
    };

    const getTrashText = (): string => {
      if (item.is_dir === 1) {
        return __("Вы действительно хотите удалить выбранную папку?");
      }

      return __("Вы действительно хотите удалить выбранный файл?");
    };

    const params = {
      titleHeaed: getTrashTitle(),
      text: getTrashText(),
      approveBtn: __("Удалить"),
      callback: (): void => {
        const payload = {
          fids: [(item as IFile).fid],
          id_dir: item.id_dir
        };

        isSafePage ? onDeleteHardFiles(payload, safe) : onDeleteFiles(payload);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  const renderPopoverContextMenuData = (): IPopoverData[] => {
    if (item.is_dir === 1) {
      if (isShared && !item.my) {
        return contextMenuSharedFolderCallback(item as IFolder);
      }
      if (isSafePage) {
        return currentSafe?.is_my
          ? contextMenuSafeFolderCallback(item as IFolder)
          : contextMenuSafeFolderToMeCallback(item as IFolder);
      }
      return contextMenuFolderCallback(item as IFolder);
    }
    if (item.is_dir === 0) {
      if (isSafePage) {
        return contextMenuSafeFileLines(item as IFile);
      }
      if (isShared && !item.my) return contextMenuFileToMe(item as IFile);
      return contextMenuFileCallbackLinesView(item as IFile);
    }
    return [];
  };

  const renderBtns = () => {
    if (isSafePage) {
      if (currentSafe?.is_my) {
        return (
          <>
            <ButtonIcon
              handleClick={handleClickMoveToArchiveButton}
              icon={<MoveToArchiveIcon />}
              variant="grey"
              tooltip={__("Переместить в архив")}
            />
            {item.is_dir === 0 && (
              <ButtonIcon
                handleClick={handleClickPrintButton}
                icon={<PrintIcon />}
                variant="grey"
                tooltip={__("Печать")}
              />
            )}
            <ButtonIcon handleClick={handleClickDeleteButton} variant="grey" tooltip={__("Удалить")} notHover>
              <TrashIcon className={styles.trashIcon} />
            </ButtonIcon>
          </>
        );
      }
      return (
        <>
          {item.is_dir === 0 && (
            <ButtonIcon
              handleClick={handleClickPrintButton}
              icon={<PrintIcon />}
              variant="grey"
              tooltip={__("Печать")}
            />
          )}
        </>
      );
    }
    if (!isShared || (isShared && Boolean(item.my))) {
      return (
        <>
          <ButtonIcon
            handleClick={handleClickMoveToArchiveButton}
            icon={<MoveToArchiveIcon />}
            variant="grey"
            tooltip={__("Переместить в архив")}
          />
          {item.is_dir === 0 && (
            <ButtonIcon
              handleClick={handleClickPrintButton}
              icon={<PrintIcon />}
              variant="grey"
              tooltip={__("Печать")}
            />
          )}
          <ButtonIcon handleClick={handleShareToMessengersModal} variant="grey" tooltip={__("Поделиться")} isStroke>
            <ShareIcon className={styles.icon} />
          </ButtonIcon>
          <ButtonIcon handleClick={handleClickDeleteButton} variant="grey" tooltip={__("Удалить")} notHover>
            <TrashIcon className={styles.trashIcon} />
          </ButtonIcon>
        </>
      );
    }
    return <></>;
  };

  return (
    <div className={styles.rightSide}>
      {renderBtns()}
      <div className={styles.popoverWrapper}>
        <PopoverContextMenu data={renderPopoverContextMenuData()} />
      </div>
    </div>
  );
};

export default RightSide;
