export enum FilesTypes {
  SET_MY_FILES = "SET_MY_FILES",
  NULLIFY_FILES = "NULLIFY_FILES",
  SET_FILES_NEXT = "SET_FILES_NEXT",
  CHANGE_FILES_NEXT = "CHANGE_FILES_NEXT",
  SET_TOTAL_ITEMS = "SET_TOTAL_ITEMS",
  SET_PAGE = "SET_PAGE",
  RESET_FILES_LIST = "RESET_FILES_LIST",
  FILES_LOADER = "FILES_LOADER",
  SET_PICKED_ITEMS = "SET_PICKED_ITEMS",
  SET_CURRENT_ITEM = "SET_CURRENT_ITEM",
  // Upload files
  SET_SELECTED_FILES = "SET_SELECTED_FILES",
  SET_CREATE_FILES = "SET_CREATE_FILES",
  SET_LOADING_FILES = "SET_LOADING_FILES",
  CHANGE_LOADING_FILES = "CHANGE_LOADING_FILES",
  CANCEL_FILES_UPLOAD = "CANCEL_FILES_UPLOAD",
  CLOSE_LOADING_FILES = "CLOSE_LOADING_FILES",
  // Upload folders
  SET_LOADING_FOLDERS = "SET_LOADING_FOLDERS",
  CHANGE_LOADING_FOLDERS = "CHANGE_LOADING_FOLDERS",
  CANCEL_FOLDERS_UPLOAD = "CANCEL_FOLDERS_UPLOAD",
  // Update files
  SET_ADD_FILES = "SET_ADD_FILES",
  SET_DEL_FILES = "SET_DEL_FILES",
  SET_UPDATE_FILES = "SET_UPDATE_FILES",
  SET_SELECTED_UPLOAD_REPLACEMENT_FILE = "SET_SELECTED_UPLOAD_REPLACEMENT_FILE",
  // Update filesNext
  SET_ADD_NEXT_FILES = "SET_ADD_NEXT_FILES",
  SET_DEL_NEXT_FILES = "SET_DEL_NEXT_FILES",
  SET_UPDATE_NEXT_FILES = "SET_UPDATE_NEXT_FILES",
  SET_REPLACE_FILE = "SET_REPLACE_FILE",
  SET_REPLACE_NEXT_FILE = "SET_REPLACE_NEXT_FILE"
}
