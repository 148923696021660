import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import classNames from "classnames";
import ContextMenu from "generalComponents/ContextMenu";
import ContextMenuItem from "generalComponents/ContextMenu/ContextMenuItem";
import ThreeDots from "generalComponents/ThreeDots/ThreeDots";
import { MODALS } from "generalComponents/variables/global";
import { imageSrc } from "generalComponents/variables/globalVariables";
import { PROJECT_CONTEXT_MENU, PROJECT_MODALS } from "generalComponents/variables/project";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onEditProject } from "Store/actions/JoinProjectActions";
import { projectRoleProps } from "types/Project";

import { useContextProjectRole } from "../../../../../../generalComponents/collections/project";
import { onSetModals } from "../../../../../../Store/actions/ModalActions";
import { useProjectMsg } from "../../helpers";
import styles from "./Role.module.sass";

const Role = ({ role, context, isChosen, onSelectRole, isMain }) => {
  const dispatch = useDispatch();
  const user = useSelector((s) => s.user.userInfo);
  const [mouseParams, setMouseParams] = useState(null);
  const contextMenu = useContextProjectRole();
  const project = useSelector((s) => s.Projects.project);
  const selectRole = useSelector((s) => s.Projects.selectRole);
  const selectUser = useSelector((s) => s.Projects.selectUser);
  const MSG = useProjectMsg();

  const onClickDots = (e) =>
    setMouseParams({
      x: e.clientX,
      y: e.clientY,
      width: 200,
      height: 25
    });

  const closeContextMenu = () => setMouseParams(null);

  const callbacks = {
    [PROJECT_CONTEXT_MENU.EDIT_ROLE]: () => {
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.EDIT_ROLE, params: role }));
    },

    [PROJECT_CONTEXT_MENU.DEL_ROLE]: () => {
      const payload = {
        ...project,
        roles: project.roles.filter((el) => el.id !== role.id)
      };
      const messages = { error: MSG.ERROR, success: MSG.DEL_ROLE };
      dispatch(onEditProject(payload, messages, PROJECT_MODALS.PROJECT_SUCCESS));
    }
  };

  const filtredContextRoles = () => {
    return contextMenu.filter((el) => {
      if (role.id_user === "0" || role.id_user !== user.id_user) {
        return el.type === PROJECT_CONTEXT_MENU.DEL_ROLE;
      }
      return el;
    });
  };

  const handleClick = () => onSelectRole(role.id);

  const isRoleActive = useMemo(() => {
    const user = project.users.find((u) => u.id_user === selectUser);
    if (selectRole === role.id) {
      return true;
    }
    if (user?.id_role === role.id) {
      return true;
    }
    if (isMain && selectUser === "0" && selectRole === "0") {
      return true;
    }
    return false;
  }, [isMain, project.users, role.id, selectRole, selectUser]);

  return (
    <div
      className={classNames(styles.role, {
        [styles.select]: isRoleActive,
        [styles.isContext]: context
      })}
    >
      <span className={styles.name} onClick={handleClick}>
        {role?.name}
      </span>
      {isChosen !== undefined ? (
        isChosen ? (
          <CheckIcon className={styles.check} />
        ) : (
          <div className={styles.noCheck} />
        )
      ) : null}
      {context && <ThreeDots onClick={onClickDots} />}
      {mouseParams !== null && (
        <ContextMenu params={mouseParams} setParams={closeContextMenu} tooltip={true}>
          <div className={styles.mainMenuItems}>
            {filtredContextRoles().map((item, i) => (
              <ContextMenuItem
                key={i}
                width={mouseParams.width}
                height={mouseParams.height}
                text={item.name}
                callback={callbacks[item.type]}
                imageSrc={`${imageSrc}assets/PrivateCabinet/contextMenuFile/${item.img}.svg`}
              />
            ))}
          </div>
        </ContextMenu>
      )}
    </div>
  );
};

export default Role;
Role.defaultProps = {
  onSelectRole: () => {}
};

Role.propTypes = {
  role: projectRoleProps,
  context: PropTypes.bool,
  isChosen: PropTypes.bool,
  onSelectRole: PropTypes.func,
  isMain: PropTypes.bool
};
