import { IFaqCategory, IFaqItem, useFaq } from "collections/faq";
import FaqDetails from "containers/Faq/FaqDetails/FaqDetails";
import FaqFooter from "containers/Faq/FaqFooter/FaqFooter";
import FaqHeader from "containers/Faq/FaqHeader/FaqHeader";
import FaqSubcategories from "containers/Faq/FaqSubcategories/FaqSubcategories";
import { useState } from "react";

import styles from "./FaqDetailsPage.module.sass";

const FaqDetailsPage = () => {
  const faq = useFaq();
  const [active, setActive] = useState<IFaqItem | IFaqCategory>();

  return (
    <div className={styles.container}>
      <FaqHeader />
      <div className={styles.wrapper}>
        <div className={styles.leftBar}>
          <ul>
            {faq.map((item, i) => (
              <FaqSubcategories key={i} data={item} active={active} setActive={setActive} />
            ))}
          </ul>
        </div>
        {active && <FaqDetails data={active} />}
      </div>
      <FaqFooter />
    </div>
  );
};

export default FaqDetailsPage;
