import { ReactComponent as Card } from "assets/icons/card.svg";
import { ReactComponent as Done } from "assets/icons/done.svg";
import { ReactComponent as Upload } from "assets/icons/upload_2.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useLocales } from "react-localized";
import { Link } from "react-router-dom";

import styles from "./InvoicesItem.module.sass";

interface IInvoicesItem {
  id: string;
  date: string;
  name: string;
  title: string;
  link: string;
  price: string;
  isPaid: boolean;
}

const InvoicesItem = ({ data }: { data: IInvoicesItem }) => {
  const { id, date, link, price, name, isPaid, title } = data;
  const { __ } = useLocales();

  return (
    <div className={styles.item}>
      <div className={styles.number}>
        <span>{id}</span>
        <span>{date}</span>
      </div>
      <div className={styles.invoice}>
        <span>{title}</span>
        <Link to={link}>
          <Upload />
          {name}
        </Link>
      </div>
      <div className={styles.price}>{price}</div>
      <div className={styles.buttons}>
        {!isPaid ? (
          <Button
            className={styles.button}
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.EXTRA_SMALL}
            iconL={<Card />}
            text={__("Оплатить")}
            onClick={() => {}}
          />
        ) : (
          <div className={classNames(styles.button, styles.payedButton)}>
            <Done />
            {__("Оплачено")}
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoicesItem;
