import { BacklogServicePanel } from "containers/JoinProject/JointProjectsBacklog/BacklogServicePanel/BacklogServicePanel";
import { JointProjectsBacklogGrid } from "containers/JoinProject/JointProjectsBacklog/JointProjectsBacklogGrid/JointProjectsBacklogGrid";
import { WrapperPage } from "containers/JoinProject/WrapperPage/WrapperPage";
import { useFilterSelectedParticipants, useSelectedTeam } from "hooks/joinProjectHooks";
import { useState } from "react";

const ProjectBacklogPage = () => {
  const [selectedParticipiants, setSelectedParticipiants] = useState<string[]>([]);
  const { selectedTeam } = useSelectedTeam();

  useFilterSelectedParticipants(selectedTeam, selectedParticipiants, setSelectedParticipiants);

  return (
    <WrapperPage>
      <BacklogServicePanel />
      <JointProjectsBacklogGrid />
    </WrapperPage>
  );
};

export default ProjectBacklogPage;
