import classNames from "classnames";
import { useMenuCleaningPeriodElements } from "collections/trash";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { getStorageItem, setStorageItem } from "generalComponents/Services/storageServices";
import { useActions } from "hooks/useActions";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { FC, useEffect, useState } from "react";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { Options } from "./components/Options/Options";
import styles from "./SelectPeriod.module.sass";

interface ISelectPeriodProps {
  checked: boolean;
}

export const SelectPeriod: FC<ISelectPeriodProps> = ({ checked }) => {
  const options = useMenuCleaningPeriodElements();

  const {
    userInfo: { trash_minutes_del }
  } = useUserSelectors();
  const { onChangeSettings } = useActions();

  const trash_minutes_del_storage = getStorageItem("trash_minutes_del") && +getStorageItem("trash_minutes_del");

  const currentPeriodCleaning = options.find((item) => {
    const value = +item.value;
    return value === trash_minutes_del_storage || value === trash_minutes_del;
  });

  const [isOpenSelect, setIsOpenSelect] = useState<boolean>(false);
  const [selected, setSelected] = useState<Omit<ISelectCustom, "callback">>({
    name: currentPeriodCleaning?.name || options[0].name,
    value: currentPeriodCleaning?.value || options[0].value
  });

  useEffect(() => {
    if (!checked) {
      onChangeSettings({ trash_minutes_del: 0 }, null);
    }

    if (checked && !trash_minutes_del) {
      onChangeSettings({ trash_minutes_del: +selected.value }, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const handleClickSelect = (el: ISelectCustom): void => {
    setSelected((prev) => ({ ...prev, name: el.name, value: el.value }));

    onChangeSettings({ trash_minutes_del: +el.value }, null);

    setStorageItem("trash_minutes_del", el.value);

    setIsOpenSelect(true);
  };

  return (
    <PopoverOverlay
      overlayContent={<Options options={options} handleClick={handleClickSelect} selected={selected} />}
      width={112}
      show={isOpenSelect}
      setShow={setIsOpenSelect}
      disabled={!checked}
    >
      <span className={classNames(styles.buttonLabel, { [styles.open]: isOpenSelect, [styles.disabled]: !checked })}>
        {selected.name}
      </span>
    </PopoverOverlay>
  );
};
