export enum DEFAULT_FOLDERS {
  ALL = "all",
  VIDEO = "video",
  MUSIC = "music",
  IMAGES = "images",
  DOCS = "docs",
  UPLOADED = "uploaded"
}

export interface IDefaultFolders {
  nameRu: DEFAULT_FOLDERS;
  icon: JSX.Element;
}

export enum DEFAULT_PROJECT_DOC_FOLDERS {
  DOCS = "docs",
  TEMPLATES = "templates"
}

export interface IDefaultProjectDocFolders {
  name: DEFAULT_PROJECT_DOC_FOLDERS;
  onClick: () => void;
  value: string;
}
