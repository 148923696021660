import { ReactComponent as ClockIcon } from "assets/icons/clock_3.svg";
import { ReactComponent as LinekIcon } from "assets/icons/line.svg";
import classNames from "classnames";
import { MS_OF_DAY } from "generalComponents/Calendars/CalendarHelper";
import DoughnutChart from "generalComponents/DoughnutChart/DoughnutChart";
import Tabs from "generalComponents/Tabs/Tabs";
import {
  useDefaultProjectStatuses,
  useDurationToString,
  useFilteredTasks,
  useSelectedParticipant,
  useSelectedTeam
} from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { TASK_TYPE } from "models/store/joinProjects/joinProgects";
import { useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectAnalytics.module.sass";
import ProjectProgressChart from "./ProjectProgressChart/ProjectProgressChart";
import TasksAmountChart from "./TasksAmountChart/TasksAmountChart";
import TasksOverdueChart from "./TasksOverdueChart/TasksOverdueChart";
import TaskCompletionTimeChart from "./TaskTimeChart/TaskCompletionTimeChart";

const JointProjectAnalytics = () => {
  const { __ } = useLocales();
  const [searchParams] = useSearchParams();
  const sprints = searchParams.get("sprint");
  const { selectedTeam } = useSelectedTeam();
  const { selectedParticipants } = useSelectedParticipant();
  const durationToString = useDurationToString();

  const { onFetchAllTaskProject, onResetAllTasksProject } = useActions();
  const defaultStatuses = useDefaultProjectStatuses();

  const { project } = useJoinProjectsSelectors();
  const { filteredTasks } = useFilteredTasks(project.allTasks);

  const [tabTaskAmount, setTabTaskAmount] = useState<"line" | "bar">("line");
  const [tabTaskOverdue, setTabTaskOverdue] = useState<"time" | "amount">("time");

  const tabsTaskAmount = [
    { name: __("Диаграмма"), value: "line", onClick: () => setTabTaskAmount("line") },
    { name: __("Гистограмма"), value: "bar", onClick: () => setTabTaskAmount("bar") }
  ];
  const tabsTaskOverdue = [
    { name: __("Часы"), value: "time", onClick: () => setTabTaskOverdue("time") },
    { name: __("Задачи"), value: "amount", onClick: () => setTabTaskOverdue("amount") }
  ];
  const selectedSprints = useMemo(() => (sprints ? sprints?.split(",") : []), [sprints]);

  useEffect(() => {
    const controller = new AbortController();
    onResetAllTasksProject();

    onFetchAllTaskProject({ controller });
    return () => {
      controller.abort();
    };
  }, []); //eslint-disable-line

  const filteredTasksBySprint = useMemo(() => {
    let tasks = filteredTasks.filter((t) => t.id_sprints.length > 0 && t.id_type === TASK_TYPE.TASK);
    if (sprints) tasks = tasks.filter((t) => t.id_sprints.some((sprint) => sprints.split(",").includes(sprint)));

    return tasks;
  }, [filteredTasks, sprints]);

  const sortedStatuses = project?.statuses.filter((item) => item.is_visible).sort((a, b) => a.y - b.y);

  const progressData = useMemo(() => {
    if (project.date_start && project.date_end) {
      const start = new Date(project.date_start).getTime(); // старт проекта в мс
      const end = new Date(project.date_end).getTime(); // окончание проекта в мс
      const present = Date.now() - start; // продолжительность проекта от начала в мс
      const totalTime = end - start; // общая продолжительность проекта
      const progress = Math.round((present * 100) / totalTime); // прогресс в процентах
      const lastSprint = project.sprints[project.sprints.length - 1]; // последний спринт
      const isOverdue = new Date(lastSprint.date_end) > new Date(project.date_end); // проверяем просрочен ли проект
      const overdueDays = Math.round(
        (new Date(lastSprint.date_end).getTime() - new Date(project.date_end).getTime()) / MS_OF_DAY
      );
      const overdueEnd = new Date(lastSprint.date_end).getTime();
      const overdueTotal = overdueEnd - start;
      const percentFinish = Math.round((totalTime * 100) / overdueTotal);
      return { progress, isOverdue, overdueDays, percentFinish, lastSprint };
    }
  }, [project.date_end, project.date_start, project.sprints]);

  const taskOverdueTime = useMemo(() => {
    const _sprints = sprints ? project.sprints.filter((s) => selectedSprints.includes(s.id)) : project.sprints;

    const data: Record<string, { amountTasks: number; time: number }> = _sprints.reduce((acc, s) => {
      const taskAtSprint = filteredTasks.filter((t) => t.id_sprints.includes(s.id));
      const overdueTaskAtSprint = taskAtSprint.filter((t) => t.time_long || t.date_long);
      const time = overdueTaskAtSprint.reduce((summ, t) => (summ += t.time_long / 60), 0);
      return {
        ...acc,
        [s.name]: {
          amountTasks: overdueTaskAtSprint.length,
          time: Number.isInteger(time) ? time : time.toFixed(2)
        }
      };
    }, {});
    const total = Object.values(data).reduce((summ, el) => (summ += Number(el.time)), 0);

    return { data, total };
  }, [filteredTasks, project.sprints, selectedSprints, sprints]);

  const taskCompletionTime = useMemo(() => {
    let users = [];
    let info: { timeAmount: number; taskAmount: number }[] = [];
    if (selectedTeam) {
      const findUsers = project.teams.find((team) => team.id === selectedTeam)?.users || [];
      users = project.participants.filter((participant) => findUsers.includes(participant.id));
    } else if (selectedParticipants) {
      users = project.participants.filter((participant) => selectedParticipants.split(",").includes(participant.id));
    } else {
      users = project.participants;
    }

    if (users.length > 0) {
      info = users.map((el) => {
        const tasks = filteredTasksBySprint.filter((task) => task.id_executor === el.id);
        const time = tasks.reduce((summ, i) => (summ += i.duration / 60), 0);
        return { taskAmount: tasks.length, timeAmount: Number(time.toFixed(2)) };
      });
    }
    const totalTime = info.reduce((summ, i) => (summ += i.timeAmount), 0);
    const totalTasks = info.reduce((summ, i) => (summ += i.taskAmount), 0);
    const times = info.map((i) => i.timeAmount);
    const average = totalTime / totalTasks;
    const usersName = users.map((u) => `${u?.fname} ${u?.sname}`);
    return { users: usersName, times, average: Number.isInteger(average) ? average : parseFloat(average.toFixed(2)) };
  }, [filteredTasksBySprint, project.participants, project.teams, selectedParticipants, selectedTeam]);

  const amountTaskInSprint = useMemo(() => {
    let _sprints = project.sprints;
    if (tabTaskAmount === "line") {
      if (selectedSprints.length === 1) {
        const idx = project.sprints.findIndex((s) => s.id === sprints);
        _sprints = [project.sprints[idx]];
        idx > 0 && _sprints.unshift(project.sprints[idx - 1]);
        idx < project.sprints.length - 1 && _sprints.push(project.sprints[idx + 1]);
      }
      if (selectedSprints.length > 1) {
        _sprints = project.sprints.filter((s) => selectedSprints.includes(s.id));
      }
    }
    if (tabTaskAmount === "bar") {
      if (selectedSprints.length > 0) {
        _sprints = project.sprints.filter((s) => selectedSprints.includes(s.id));
      }
    }

    const _tasks: Record<string, { all: number; overdue: number }> = _sprints.reduce((acc, s) => {
      const taskAtSprint = filteredTasks.filter((t) => t.id_sprints.includes(s.id));
      return {
        ...acc,
        [s.name]: {
          all: taskAtSprint.length,
          overdue: taskAtSprint.filter((t) => t.date_long).length
        }
      };
    }, {});
    return _tasks;
  }, [filteredTasks, project.sprints, selectedSprints, sprints, tabTaskAmount]);

  return (
    <div className={styles.wrap}>
      <div className={styles.row}>
        <div className={classNames(styles.block)}>
          <div className={styles.header}>
            <h3 className={styles.subTitle}>{__("Задачи")}</h3>
          </div>
          <div className={classNames(styles.allTasks, "scrollbar-thin-blue")}>
            {sortedStatuses?.map((item) => (
              <DoughnutChart
                key={item.id}
                name={item.name ? item.name : defaultStatuses[item.system_name]}
                count={filteredTasksBySprint.filter((task) => task.id_status === item.id).length}
                total={filteredTasksBySprint.length}
                color={item.color}
                size={60}
              />
            ))}
          </div>
        </div>
        {project.date_start && project.date_end && (
          <div className={classNames(styles.progressBlock)}>
            <div className={styles.header}>
              <h3 className={styles.subTitle}>{__("Прогресс создания проекта")}</h3>
              {!progressData.isOverdue ? (
                <p className={styles.subTitle}>
                  <b>{progressData.progress}%</b>
                </p>
              ) : (
                <div className={styles.overdueTitle}>
                  {__("Опоздание ")}
                  <span>
                    {progressData.overdueDays}
                    {__("д")}
                  </span>
                </div>
              )}
            </div>
            <div className={styles.progress}>{<ProjectProgressChart data={progressData} />}</div>
          </div>
        )}
      </div>

      <div className={classNames(styles.block)}>
        <div className={styles.header}>
          <h3 className={styles.subTitle}>{__("Просроченные задач")}</h3>
          <Tabs tabs={tabsTaskOverdue} value={tabTaskOverdue} size="small" variant="darkGreen" />
        </div>
        <div className={styles.chartContainet}>
          <TasksOverdueChart dataChart={taskOverdueTime.data} tab={tabTaskOverdue} />
        </div>
        {Boolean(taskOverdueTime.total) && (
          <div className={styles.legend}>
            <div className={styles.icon}>
              <ClockIcon fill="#CC4036" />
            </div>
            <div>
              <p>{__("Отставание за период")}</p>
              <p className={styles.bold}>
                {taskOverdueTime.total}&nbsp;{__("чч")}
              </p>
            </div>
          </div>
        )}
      </div>

      <div className={classNames(styles.block)}>
        <div className={styles.header}>
          <h3 className={styles.subTitle}>{__("Время выполнения задач")}</h3>
        </div>
        <div className={styles.chartContainet}>
          <TaskCompletionTimeChart dataChart={taskCompletionTime} />
        </div>
        {Boolean(taskCompletionTime.average) && (
          <div className={styles.legend}>
            <div className={styles.icon}>
              <LinekIcon />
            </div>
            <div>
              <p>{__("Среднее время выполнения задач")}</p>
              <p className={styles.bold}>{durationToString(taskCompletionTime.average * 60)}</p>
            </div>
          </div>
        )}
      </div>

      <div className={classNames(styles.block)}>
        <div className={styles.header}>
          <h3 className={styles.subTitle}>{__("Количество задач")}</h3>
          <Tabs tabs={tabsTaskAmount} value={tabTaskAmount} size="small" variant="darkGreen" />
        </div>
        <div className={styles.chartContainet}>
          <TasksAmountChart dataChart={amountTaskInSprint} tab={tabTaskAmount} />
        </div>
      </div>
    </div>
  );
};

export default JointProjectAnalytics;
