import api from "api";
import { EventAPI } from "api/EventAddAPI";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { MODALS, TYPES } from "generalComponents/variables/global";
import {
  ICallRoomInvitations,
  IChatNotification,
  IReminderNotification
} from "models/store/notifications/notifications";
import {
  IEditNotificationChat,
  IGetAllNotifications,
  ISetAllCallRoomInvitations,
  ISetEditNotification,
  NotificationsAction
} from "models/store/notifications/notificationsActions";
import {
  IAddNotificationPayload,
  IEditNotificationPayload,
  IMessages
} from "models/store/notifications/notificationsPayloads";
import { constructFromHttpChatMessage } from "Pages/Cabinet/Components/Chat/chatService";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { Notifications, NotificationsTypes, NotificationTypes } from "Store/types/notificationsTypes";

import { onSetModals } from "./ModalActions";

export const onFetchNotifications =
  (): ThunkAction<void, RootState, unknown, NotificationsAction> => async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid
    };

    try {
      const { data } = await api.get(`/ajax/event_get.php`, { params });
      checkResponseStatus(data.ok);
      if (data.events) {
        dispatch(onSetAllNotifications(data.events));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const addNotification =
  (payload: IAddNotificationPayload, messages: IMessages): ThunkAction<void, RootState, unknown, NotificationsAction> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      type: payload.type,
      text: payload.text,
      date: payload.date,
      period_d: payload.period_d,
      period_w: payload.period_w,
      period_m: payload.period_m,
      period_y: payload.period_y
    };

    try {
      // TODO - androfficial - delete after all store is typed
      // @ts-ignore
      dispatch(onSetModals(MODALS.LOADER, true));
      const { data } = await EventAPI.add(params);
      checkResponseStatus(data.ok);
      dispatch(
        // TODO - androfficial - delete after all store is typed
        // @ts-ignore
        onSetModals(MODALS.SUCCESS, {
          open: true,
          message: messages.success
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        // TODO - androfficial - delete after all store is typed
        // @ts-ignore
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    } finally {
      // TODO - androfficial - delete after all store is typed
      // @ts-ignore
      dispatch(onSetModals(MODALS.LOADER, false));
      dispatch(
        // TODO - androfficial - delete after all store is typed
        // @ts-ignore
        onSetModals(MODALS.TASKS, {
          type: MODALS.NO_MODAL,
          params: null
        })
      );
    }
  };

export const editNotificationReminder =
  (
    payload: IEditNotificationPayload,
    editType: NotificationsTypes.DELAY_NOTIFICATION | NotificationsTypes.HIDDEN_NOTIFICATION,
    messages?: IMessages
  ): ThunkAction<void, RootState, unknown, NotificationsAction> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_event: payload.id,
      date2: payload.date2,
      is_read: payload.is_read,
      date: payload.date,
      text: payload.text,
      date_view: payload.date_view
    };

    try {
      const { data } = await api.get(`/ajax/event_edit.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetEditNotification(editType, payload));
    } catch (error) {
      console.log(error);
      if (messages) {
        dispatch(
          // TODO - androfficial - delete after all store is typed
          // @ts-ignore
          onSetModals(MODALS.ERROR, {
            open: true,
            message: messages.error
          })
        );
      }
    }
  };

export const deleteNotification =
  (
    payload: IEditNotificationPayload,
    messages: IMessages
  ): ThunkAction<void, RootState, unknown, NotificationsAction> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_event: payload.id
    };

    try {
      const { data } = await api.get(`/ajax/event_del.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetEditNotification(NotificationsTypes.DELETE_NOTIFICATION, payload));
    } catch (error) {
      console.log(error);
      dispatch(
        // TODO - androfficial - delete after all store is typed
        // @ts-ignore
        onSetModals(MODALS.ERROR, {
          open: true,
          message: messages.error
        })
      );
    }
  };

// check what comes in response.data.group and extend the IChatNotification interface
export const onFetchChatNotifications =
  (): ThunkAction<void, RootState, unknown, NotificationsAction> => async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid
    };

    try {
      const response = await api.get("/ajax/chat_unread_get.php", { params });
      const payload = [...response.data.private, ...response.data.group].map((it) => ({
        ...constructFromHttpChatMessage(it),
        type: Notifications.CHAT
      }));

      dispatch(onSetAllNotifications(payload as IChatNotification[]));
    } catch (error) {
      console.log(error);
    }
  };

// TODO - androfficial - change type any
export const onFetchCallRoomInvitations =
  (): ThunkAction<void, RootState, unknown, NotificationsAction> => async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid
    };

    try {
      const response = await api.get("/ajax/chat_invite_get.php", { params });
      if (typeof response.data?.chat_invite === TYPES.OBJECT) {
        const payload = response.data.chat_invite.map((it: any) => ({
          ...it.text,
          is_read: "0",
          type: Notifications.INVITE_CALLROOM
        }));

        dispatch(onSetCallRoomInvitations(payload));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const editNotificationChat =
  (messagesId: string[]): ThunkAction<void, RootState, unknown, NotificationsAction> =>
  (dispatch, getState) => {
    const payload = getState().Notifications.notifications.map((it) =>
      messagesId.find((el) => "id" in it && el === it.id)
        ? {
            ...it,
            is_read: "1"
          }
        : it
    );

    dispatch(onEditNotificationChat(payload as IChatNotification[]));
  };

export const deleteNotificationChat =
  (messageId: string): ThunkAction<void, RootState, unknown, NotificationsAction> =>
  (dispatch, getState) => {
    const payload = getState().Notifications.notifications.filter((it) => "id" in it && it.id !== messageId);

    dispatch(onEditNotificationChat(payload as IChatNotification[]));
  };

export const listNotification = (notification: NotificationTypes, type: Notifications) => ({
  type: NotificationsTypes.LIST_NOTIFICATION,
  payload: {
    ...notification,
    type
  }
});

export const onEditNotificationChat = (payload: IChatNotification[]): IEditNotificationChat => ({
  type: NotificationsTypes.EDIT_NOTIFICATION_CHAT,
  payload
});

export const onSetAllNotifications = (
  payload: (IReminderNotification | IChatNotification)[]
): IGetAllNotifications => ({
  type: NotificationsTypes.GET_ALL_NOTIFICATIONS,
  payload
});

const onSetEditNotification = (
  type:
    | NotificationsTypes.DELAY_NOTIFICATION
    | NotificationsTypes.HIDDEN_NOTIFICATION
    | NotificationsTypes.DELETE_NOTIFICATION,
  payload: IEditNotificationPayload
): ISetEditNotification => ({
  type,
  payload
});

const onSetCallRoomInvitations = (payload: ICallRoomInvitations[]): ISetAllCallRoomInvitations => ({
  type: NotificationsTypes.GET_ALL_CALL_ROOM_INVITATIONS,
  payload
});
