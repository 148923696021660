import classNames from "classnames";
import Empty from "containers/Dashboard/Empty/Empty";
import RecentFile from "generalComponents/RecentFiles/RecentFile/RecentFile";
import { ROUTES } from "generalComponents/variables/routing";
import { useLocales } from "react-localized";
import { Link } from "react-router-dom";
import { useRecentFilesSelectors } from "Store/selectors/recentFilesSelectors";

import styles from "./LastFiles.module.sass";

const LastFiles = ({ type }: { type: string }) => {
  const { __ } = useLocales();
  const { recentFiles } = useRecentFilesSelectors();

  if (!recentFiles) return null;

  return (
    <div className={classNames(styles.container, styles[`container_${type}`])}>
      <Link to={ROUTES.FILES} className={styles.title}>
        {__("Последние файлы")}
      </Link>
      {recentFiles.length === 0 ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={classNames(styles.listWrapper, "scrollbar-thin-blue")}>
          <div className={classNames(styles.list)}>
            {recentFiles.map((file) => (
              <div key={file.fid} className={styles.item}>
                <RecentFile file={file} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LastFiles;
