import { initialFilterTypeElementsState } from "models/store/filterTypeElements/filterTypeElements";
import { IFilterTypeElementsState } from "models/store/filterTypeElements/filterTypeElements";
import { FilterTypeElementsActions } from "models/store/filterTypeElements/filterTypeElementsActions";
import { FilterTypeElementsTypes } from "Store/types/filterTypeElements";

const INITIAL_STATE = initialFilterTypeElementsState();

export const FilterTypeElementsReducer = (
  state = INITIAL_STATE,
  action: FilterTypeElementsActions
): IFilterTypeElementsState => {
  switch (action.type) {
    case FilterTypeElementsTypes.ADD_FILTER:
      return { ...state, ...action.payload };

    case FilterTypeElementsTypes.NULLIFY_FILTERS:
      return INITIAL_STATE;

    default:
      return state;
  }
};
