import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classNames from "classnames";
import { useJoinProjectMenu } from "collections/joinProject";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import { PROJECT_PATH } from "generalComponents/variables/routing";
import { useProjectMainAccess } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IJoinProject } from "models/store/joinProjects/joinProgects";
import { FC, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";

import styles from "./ProjectItem.module.sass";

interface IProps {
  project: IJoinProject;
  collapsed: boolean;
}
const ProjectItem: FC<IProps> = ({ project, collapsed }) => {
  const isMainAccess = useProjectMainAccess();
  const { id } = useParams();
  const { onSetTasksProject } = useActions();
  const { pathname } = useLocation();

  const { joinProjectMenuMain, joinProjectMenuAnalytics, joinProjectMenuSecondary } = useJoinProjectMenu(
    project.accesses
  );
  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => setShow((show) => !show);
  return (
    <li>
      <div
        className={classNames(styles.head, {
          [styles.open]: show,
          [styles.hidden]: collapsed,
          [styles.current]: project.id === id
        })}
      >
        <Avatar imgSrc={project.icon} name={project.name} />
        <span className={styles.name}>{project.name}</span>
        <Button
          variant={ButtonVariantType.OPACITY}
          size={ButtonSizeType.MEDIUM}
          onClick={handleShow}
          iconL={<ArrowIcon className={styles.btn} />}
          isSquare
        />
      </div>
      {show && (
        <>
          <ul className={classNames(styles.menu, { [styles.hidden]: collapsed })}>
            {joinProjectMenuMain.map((item, idx) => (
              <li key={idx}>
                <NavLink
                  to={`${project.id}/${item.path}`}
                  className={({ isActive }) =>
                    classNames(styles.link, {
                      [styles.active]: isActive
                    })
                  }
                  end
                  onClick={() => {
                    pathname.includes(PROJECT_PATH.GANT) && onSetTasksProject([]);
                  }}
                >
                  <div className={styles.iconBox}>{item.icon}</div>
                  <span className={styles.name}>{item.name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
          {(isMainAccess || project.accesses.ANALITYCS) && (
            <ul className={classNames(styles.menu, { [styles.hidden]: collapsed })}>
              {joinProjectMenuAnalytics.map((item, idx) => (
                <li key={idx}>
                  <NavLink
                    to={`${project.id}/${item.path}`}
                    className={({ isActive }) =>
                      classNames(styles.link, {
                        [styles.active]: isActive
                      })
                    }
                    onClick={() => {
                      item.path === PROJECT_PATH.GANT && onSetTasksProject([]);
                    }}
                  >
                    <div className={styles.iconBox}>{item.icon}</div>
                    <span className={styles.name}>{item.name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
          <ul className={classNames(styles.menu, { [styles.hidden]: collapsed })}>
            {joinProjectMenuSecondary.map((item, idx) => (
              <li key={idx}>
                <NavLink
                  to={`${project.id}/${item.path}`}
                  className={({ isActive }) =>
                    classNames(styles.link, {
                      [styles.active]: isActive
                    })
                  }
                >
                  <div className={styles.iconBox}>{item.icon}</div>
                  <span className={styles.name}>{item.name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </>
      )}
    </li>
  );
};

export default ProjectItem;
