import { ReactComponent as HeartIcon } from "assets/figures/heart-13.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as MoveIcon } from "assets/icons/move.svg";
import { ReactComponent as PassAddIcon } from "assets/icons/pass_add.svg";
import { ReactComponent as PassRemoveIcon } from "assets/icons/pass_remove.svg";
import { ReactComponent as EditIcon } from "assets/PrivateCabinet/myFolders/contextMenu/edit.svg";
import { ReactComponent as GrantedAccessIcon } from "assets/PrivateCabinet/myFolders/contextMenu/granted-access.svg";
import { ReactComponent as InfoIcon } from "assets/PrivateCabinet/myFolders/contextMenu/info.svg";
import { ReactComponent as MoveToArchiveIcon } from "assets/PrivateCabinet/myFolders/contextMenu/move-to-archive.svg";
import { ReactComponent as ShareIcon } from "assets/PrivateCabinet/myFolders/contextMenu/share.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/myFolders/contextMenu/trash.svg";
import { ReactComponent as UploadIcon } from "assets/PrivateCabinet/myFolders/contextMenu/upload.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/myFolders/folderContextMenu/plus.svg";
import ColorPicker from "generalComponents/ColorPicker/ColorPicker";
import EmojiPicker from "generalComponents/EmojiPicker/EmojiPicker";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import TagSelect from "generalComponents/TagSelect/TagSelect";
import { ERROR_SERVER, EVENT_TYPE } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import {
  AccessPeriodTypes,
  AccessTypes,
  IAccessPeriod,
  IUseAccessSelectList
} from "models/contextMenuComponents/generalContextMenuComponents/share";
import { ButtonVariantType } from "models/generalComponents/button";
import { IUseContextFolder } from "models/generalComponents/collections/myFolders";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import {
  IUseCategoryPropertiesList,
  IUsePropertySettingsList,
  PropertyParameterTypes
} from "models/modals/propertiesModal/propertiesModal";
import { CategoriesPropertiesTypes } from "models/modals/propertiesModal/propertiesModal";
import { PropertiesTypes, ShareActionTypes } from "models/store/Cabinet/modals/modals";
import { MOVE_ELEMENTS_TYPE } from "models/store/Cabinet/modals/moveElements";
import { IFolder, initialFolderState } from "models/store/folders/foldersStore";
import { MouseEvent } from "react";
import { useLocales } from "react-localized";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";

export const useErrorFolder = () => {
  const { __ } = useLocales();

  return {
    [ERROR_SERVER.NO_FILES]: __("Папка пустая, скачивание недоступно")
  };
};

export const useOnFolderToArchive = () => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const { folderPath } = useFoldersSelectors();
  const { dirId } = useParams();
  const { search } = useLocation();
  const { onEditFolders, onSetApproveModal, onSetDelFiles, onSetDelNextFiles } = useActions();

  const { currentFolder } = useFoldersSelectors();

  const { isLinesPreviewView } = useGetItemView();

  const onFolderToArchive = (folder: IFolder) => {
    const onArchiveCalback = () => {
      if (folderPath.findIndex((f) => f.id_dir === folder.id_dir) >= 0) {
        if (folder.id_parent === "0") navigate(`/${ROUTES.FOLDERS}${search}`, { replace: true });
        if (folder.id_dir === dirId) navigate(`/${ROUTES.FOLDERS}/${folder.id_parent}${search}`, { replace: true });
      }

      if (isLinesPreviewView) {
        onSetDelNextFiles([folder]);
      } else {
        if (folder.id_parent === currentFolder?.id_dir) {
          onSetDelFiles({ ids: [folder.id_dir] });
        }
      }
    };
    const payload = {
      id_dirs: [folder.id_dir],
      is_archive: 1
    };

    const params = {
      titleHead: __("Переместить в архив"),
      title: `${__("Вы действительно хотите переместить папку")} <span>${folder.name}</span> в архив?`,
      text: `${__(`Вы сможете восстановить ${folder.name} с архива`)}`,
      approveBtn: __("Переместить"),
      callback: (): void => {
        onEditFolders(payload, EVENT_TYPE.ARCHIVE, onArchiveCalback);
      }
    };

    onSetApproveModal({ open: true, params });
  };

  return { onFolderToArchive };
};

export const useOnFolderToTrash = () => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const { folderPath } = useFoldersSelectors();
  const { dirId } = useParams();
  const { search } = useLocation();
  const { onEditFolders, onSetApproveModal, onSetDelFiles, onSetDelNextFiles } = useActions();
  const { currentFolder } = useFoldersSelectors();
  const { isLinesPreviewView } = useGetItemView();

  const onFolderToTrash = (folder: IFolder) => {
    const onDeleteCalback = () => {
      if (folderPath.findIndex((f) => f.id_dir === folder.id_dir) >= 0) {
        if (folder.id_parent === "0") navigate(`/${ROUTES.FOLDERS}${search}`, { replace: true });
        if (folder.id_dir === dirId) navigate(`/${ROUTES.FOLDERS}/${folder.id_parent}${search}`, { replace: true });
      }

      const ids = [folder.id_dir];

      if (isLinesPreviewView) {
        onSetDelNextFiles([folder]);
      } else {
        if (folder.id_parent === currentFolder?.id_dir) {
          onSetDelFiles({ ids });
        }
      }
    };
    const payload = {
      id_dirs: [folder.id_dir],
      is_del: 1
    };

    const params = {
      titleHead: __("Удалить"),
      title: `${__("Вы действительно хотите удалить")} <span>${folder.name}</span>?`,
      text: `${__(`Вы сможете восстановить ${folder.name} с корзины`)}`,
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.OK_RED,
      callback: (): void => {
        onEditFolders(payload, EVENT_TYPE.IS_DEL, onDeleteCalback);
      }
    };

    onSetApproveModal({ open: true, params });
  };
  return { onFolderToTrash };
};

export const useContextFolder = (): IUseContextFolder => {
  const { __ } = useLocales();
  const { dirId, did } = useParams();
  const { onFolderToArchive } = useOnFolderToArchive();
  const { onFolderToTrash } = useOnFolderToTrash();
  const { folders } = useFoldersSelectors();
  const {
    onEditFolders,
    onEditFoldersLocal,
    onCopyFolders,
    onSetShareModal,
    fetchFolderZipLink,
    onSetPropertiesModal,
    onSetMoveElementsModal,
    onSetCreateFolderModal,
    onSetShareToMessengersModal,
    onSetFolderPassModal,
    onCreateFolder,
    onSetDelFiles,
    onSetUpdateFiles,
    onSetDelNextFiles,
    onSetUpdateNextFiles,
    onSetAddNextFiles
  } = useActions();

  const { currentFolder } = useFoldersSelectors();

  const { isLinesPreviewView } = useGetItemView();

  const errorFolder = useErrorFolder();

  const addFolder = (folder: IFolder) => ({
    name: __("Добавить папку"),
    img: <PlusIcon width={13} height={13} />,
    callback: (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      onSetCreateFolderModal({
        open: true,
        type: "add",
        folder: {
          ...initialFolderState(),
          color: folder.is_system === "0" ? folder.color : "",
          id_parent: folder.id_dir
        },
        folders,
        onCreateFolder
      });
    }
  });

  const onShare = (folder: IFolder) => ({
    name: __("Предоставленный доступ"),
    img: <GrantedAccessIcon width={13} height={13} />,
    callback: (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();

      if (folder.pass && dirId !== folder.id_dir) {
        onSetFolderPassModal({
          open: true,
          type: "enterPassword",
          folder,
          callbackOk: () =>
            onSetShareModal({ open: true, actionType: ShareActionTypes.DIR_ACCESS_ADD, folders: [folder], files: [] })
        });
      } else {
        onSetShareModal({ open: true, actionType: ShareActionTypes.DIR_ACCESS_ADD, folders: [folder], files: [] });
      }
    }
  });

  const onGetAccess = (folder: IFolder) => ({
    name: __("Поделиться"),
    img: <ShareIcon width={13} height={13} />,
    callback: (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();

      if (folder.pass && dirId !== folder.id_dir) {
        onSetFolderPassModal({
          open: true,
          type: "enterPassword",
          folder,
          callbackOk: () => onSetShareToMessengersModal({ open: true, params: folder })
        });
      } else {
        onSetShareToMessengersModal({ open: true, params: folder });
      }
    }
  });

  const toggleFavorite = (folder: IFolder) => ({
    name: __("Избранное"),
    img: <HeartIcon width={13} height={13} fill={folder.is_favorite ? "#0277BD" : "#b1b1b1"} />,
    callback: (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();

      const payload = {
        id_dirs: [folder.id_dir],
        is_favorite: folder.is_favorite ? 0 : 1
      };
      onEditFolders(
        payload,
        folder.is_favorite ? EVENT_TYPE.UNFAVORITE : EVENT_TYPE.ADD_FAVORITE,
        (folder: IFolder) => {
          if (isLinesPreviewView) {
            onSetUpdateNextFiles([folder]);
          } else {
            onSetUpdateFiles([folder]);
          }
        }
      );
    }
  });

  const onRename = (folder: IFolder) => ({
    name: __("Переименовать"),
    img: <EditIcon width={13} height={13} />,
    callback: (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      onSetCreateFolderModal({
        open: true,
        type: "edit",
        folder,
        folders,
        onEditFolder: onEditFolders,
        onSetUpdate: (folder: IFolder) => {
          if (isLinesPreviewView) {
            onSetUpdateNextFiles([folder]);
          } else {
            onSetUpdateFiles([folder]);
          }
        }
      });
    }
  });
  const onMove = (folder: IFolder) => ({
    name: __("Переместить"),
    img: <MoveIcon width={13} height={13} fill="#b1b1b1" />,
    callback: (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      onSetMoveElementsModal({
        open: true,
        type: MOVE_ELEMENTS_TYPE.MOVE_FOLDER,
        elements: folder,
        folders,
        actionsCallback: {
          onEditFolders,
          onSetUpdate: (updateFolder: IFolder) => {
            const ids = [folder.id_dir];

            if (isLinesPreviewView) {
              onSetDelNextFiles([folder]);
              onSetAddNextFiles(updateFolder);
            } else {
              if (folder.id_parent === currentFolder?.id_dir) {
                onSetDelFiles({ ids });
              }
            }
          }
        }
      });
    }
  });
  const onCopy = (folder: IFolder) => ({
    name: __("Скопировать"),
    img: <CopyIcon width={13} height={13} fill="#b1b1b1" />,
    callback: (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      onSetMoveElementsModal({
        open: true,
        type: MOVE_ELEMENTS_TYPE.COPY_FOLDER,
        elements: folder,
        folders,
        actionsCallback: { onCopyFolders }
      });
    }
  });

  const onProperties = (folder: IFolder) => ({
    name: __("Свойства"),
    img: <InfoIcon width={13} height={13} />,
    callback: (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (folder.pass && dirId !== folder.id_dir) {
        onSetFolderPassModal({
          open: true,
          type: "enterPassword",
          folder,
          callbackOk: () => onSetPropertiesModal({ open: true, type: PropertiesTypes.FOLDER, params: folder })
        });
      } else {
        onSetPropertiesModal({ open: true, type: PropertiesTypes.FOLDER, params: folder });
      }
    }
  });

  const onDownload = (folder: IFolder) => ({
    name: __("Скачать"),
    img: <UploadIcon width={13} height={13} />,
    callback: (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();

      if (folder.pass && dirId !== folder.id_dir) {
        onSetFolderPassModal({
          open: true,
          type: "enterPassword",
          folder,
          callbackOk: () => fetchFolderZipLink(folder.id_dir, null, did)
        });
      } else {
        fetchFolderZipLink(folder.id_dir, null, did, errorFolder[ERROR_SERVER.NO_FILES]);
      }
    }
  });

  const onArchive = (folder: IFolder) => ({
    name: __("Переместить в архив"),
    img: <MoveToArchiveIcon width={13} height={13} />,
    callback: (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      onFolderToArchive(folder);
    }
  });

  const onDeleteToTrash = (folder: IFolder) => ({
    name: __("Удалить"),
    img: <TrashIcon width={13} height={13} />,
    callback: (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      onFolderToTrash(folder);
    }
  });

  const onPassword = (folder: IFolder) => ({
    name: folder.pass ? __("Снять защиту") : __("Защита паролем"),
    img: folder.pass ? <PassRemoveIcon /> : <PassAddIcon />,
    callback: (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      onSetFolderPassModal({
        open: true,
        type: folder.pass ? "removePassword" : "addPassword",
        folder
      });
    }
  });

  const onColor = (folder: IFolder) => ({
    name: "",
    component: <ColorPicker item={folder} onColorClick={onEditFoldersLocal} />,
    callback: () => {}
  });

  const onEmo = (folder: IFolder) => ({
    name: "",
    component: <EmojiPicker item={folder} onEmojiClick={onEditFoldersLocal} />,
    callback: () => {}
  });

  const onTag = (folder: IFolder) => ({
    name: "",
    component: <TagSelect onChange={onEditFoldersLocal} item={folder} />,
    callback: () => {}
  });

  return {
    contextMenuFolderCallback: (folder: IFolder): IPopoverData[] => [
      addFolder(folder),
      onShare(folder),
      onGetAccess(folder),
      toggleFavorite(folder),
      onPassword(folder),
      onRename(folder),
      onMove(folder),
      onCopy(folder),
      onArchive(folder),
      onProperties(folder),
      onTag(folder),
      onColor(folder),
      onEmo(folder),
      onDownload(folder),
      onDeleteToTrash(folder)
    ],
    consextMenuSystemFolderCallbak: (folder: IFolder): IPopoverData[] => [addFolder(folder), onProperties(folder)],
    contextMenuSharedFolderCallback: (folder: IFolder): IPopoverData[] => [onProperties(folder), onDownload(folder)],
    contextMenuSharedLinkFolderCallback: (folder: IFolder): IPopoverData[] => [onDownload(folder)]
  };
};

export const useAccessSelectList = (): IUseAccessSelectList => {
  const { __ } = useLocales();

  return {
    accessSelectList: [
      {
        name: __("Может смотреть"),
        type: AccessTypes.VIEWING
      },
      {
        name: __("Может редактировать"),
        type: AccessTypes.EDITING
      }
    ]
  };
};

export const useAccessPeriod = (): IAccessPeriod[] => {
  const { __ } = useLocales();

  return [
    {
      name: __("Один день"),
      type: AccessPeriodTypes.DAY
    },
    {
      name: __("Три дня"),
      type: AccessPeriodTypes.THREE
    },
    {
      name: __("Неделя"),
      type: AccessPeriodTypes.WEEK
    },
    {
      name: __("Месяц"),
      type: AccessPeriodTypes.MONTH
    }
  ];
};

export const useCategoriesPropertiesList = (): IUseCategoryPropertiesList => {
  const { __ } = useLocales();

  return {
    categoriesProperties: [
      {
        name: __("Общее"),
        type: CategoriesPropertiesTypes.GENERAL
      },
      {
        name: __("Доступы"),
        type: CategoriesPropertiesTypes.ACCESSES
      },
      {
        name: __("Версии"),
        type: CategoriesPropertiesTypes.VERSIONS
      }
    ]
  };
};

export const usePropertySettingsList = (): IUsePropertySettingsList => {
  const { __ } = useLocales();

  return {
    propertySettingsFolder: [
      {
        name: __("Размер"),
        type: PropertyParameterTypes.SIZE
      },
      {
        name: __("Кол-во файлов"),
        type: PropertyParameterTypes.NUMBER_OF_FILES
      },
      {
        name: __("Дата создания"),
        type: PropertyParameterTypes.DATE_CREATION
      },
      {
        name: __("Дата изменения"),
        type: PropertyParameterTypes.DATE_CHANGE
      }
    ],
    propertySettingsFile: [
      {
        name: __("Размер"),
        type: PropertyParameterTypes.SIZE
      },
      {
        name: __("Дата создания"),
        type: PropertyParameterTypes.DATE_CREATION
      },
      {
        name: __("Дата изменения"),
        type: PropertyParameterTypes.DATE_CHANGE
      }
    ]
  };
};
