import FileLoader from "generalComponents/FileLoader/FileLoader";
import HeaderMain from "generalComponents/HeaderMain/HeaderMain";
import Modals from "generalComponents/Modals/Modals";
import { splitFilename } from "generalComponents/Services/helpers";
import { PAGE_DEPTH } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { createContext, useRef } from "react";
import { type ChangeEvent, type DragEvent } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { v4 as uuid } from "uuid";

import Header from "../Header/Header";
import SideMenu from "../SideMenu/SideMenu";
import styles from "./Shell.module.sass";

export const ShellContext = createContext({
  fileSelect: (dataAttribute?: string) => {
    console.log(dataAttribute);
  },
  folderFileSelect: () => {}
});

const Shell = (): JSX.Element => {
  const { onSelectedFiles, onSetProjectSelectedFiles, onLoadingFiles, onSetLoadingFolders } = useActions();

  const { uploadFiles } = useFilesSelectors();

  const { project } = useJoinProjectsSelectors();

  const location = useLocation();

  const { uid } = useUserSelectors();

  const isProject = useCurrentPathStarstWith(ROUTES.PROJECT);

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => e.preventDefault();

  const projectUploadfiles = project?.uploadFiles;

  const onInputFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const files = [...Array.from(e.target.files)]
      .map((file) => ({
        file
      }))
      .filter(({ file }) => file.name !== ".DS_Store" && file.name !== "Thumbs.db");

    if (isProject) {
      onSetProjectSelectedFiles(files);
    } else {
      onSelectedFiles(files);
    }

    if (e.target?.dataset?.replace === "replace" && Boolean(uploadFiles.replacementFile)) {
      files.forEach((file) => {
        onLoadingFiles({
          ...file,
          options: {
            name: splitFilename(file.file.name).name,
            tags: [],
            emo: "",
            color: "",
            id_dir: uploadFiles.replacementFile.id_dir,
            ext: splitFilename(file.file.name).format
          },
          time: 0,
          percent: 0,
          id: uuid(),
          done: false,
          create: false,
          replace: true
        });
      });
    }

    inputFileRef.current.value = "";

    const dataAttributesKeys = Object.keys(inputFileRef.current.dataset);

    if (dataAttributesKeys.length) {
      dataAttributesKeys.forEach((key) => {
        delete inputFileRef.current.dataset[key];
      });
    }
  };

  const onInputFolderFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const files = [...Array.from(e.target.files)]
      .filter(({ name }) => name !== ".DS_Store" && name !== "Thumbs.db")
      .map((file) => ({
        file,
        options: {
          name: splitFilename(file.name).name,
          tags: [],
          emo: "",
          color: "",
          id_dir: "",
          ext: splitFilename(file.name).format,
          path: file.webkitRelativePath.replace(`/${file.name}`, ""),
          dep: PAGE_DEPTH.FILES
        },
        time: 0,
        percent: 0,
        id: uuid(),
        done: false,
        create: false,
        replace: false
      }))
      .sort((a, b) => a.file.webkitRelativePath.length - b.file.webkitRelativePath.length);

    let rootFolders: string[] = files.map(({ file }) => file.webkitRelativePath?.replace(`/${file.name}`, ""));
    rootFolders = rootFolders.filter(
      (path, index) => index === rootFolders.indexOf(path) && index === path.indexOf(path.split("/")[0])
    );

    const folders = rootFolders.map((folder) => {
      const folderName = folder.split("/")[0];

      return {
        name: folderName,
        id: uuid(),
        files: files.filter(({ file }) => file.webkitRelativePath.split("/")[0] === folderName)
      };
    });

    onSetLoadingFolders(folders);

    inputFolderRef.current.value = "";
  };

  const inputFileRef = useRef<HTMLInputElement>();
  const inputFolderRef = useRef<HTMLInputElement>();

  const fileSelect = (dataAttribute?: string) => {
    if (dataAttribute) {
      if (!inputFileRef.current.hasAttribute(`data-${dataAttribute}`)) {
        inputFileRef.current.setAttribute(`data-${dataAttribute}`, `${dataAttribute}`);
      }

      if (dataAttribute === "replace") {
        inputFileRef.current.multiple = false;
      }
    } else {
      inputFileRef.current.multiple = true;

      const dataAttributesKeys = Object.keys(inputFileRef.current.dataset);

      if (dataAttributesKeys.length) {
        dataAttributesKeys.forEach((key) => {
          delete inputFileRef.current.dataset[key];
        });
      }
    }

    inputFileRef.current.click();
  };

  const folderFileSelect = (): void => {
    inputFolderRef.current.click();
  };

  const isHeaderVisible = ![
    `/${ROUTES.SIGN_IN}`,
    `/${ROUTES.SIGN_UP}`,
    `/${ROUTES.FORGOT_PASSWORD}`,
    `/${ROUTES.QUESTIONNAIRE}`,
    `/${ROUTES.FAQ}`,
    `/${ROUTES.FAQDETAILS}`
  ].some((path) => location.pathname.toLowerCase().startsWith(path));

  return (
    <>
      {uid ? (
        <ShellContext.Provider value={{ fileSelect, folderFileSelect }}>
          <div className={styles.cabinetWrap} onDragOver={handleDragOver}>
            {isHeaderVisible && <SideMenu />}
            {/* <FolderInput /> */}
            <div className={styles.workArea}>
              {isHeaderVisible && <Header />}
              <main className={styles.main}>
                <Outlet />
              </main>
            </div>
            {/* <BottomPanel /> */}
          </div>
        </ShellContext.Provider>
      ) : (
        <>
          {isHeaderVisible && <HeaderMain />}
          <main className={styles.mainWrap}>
            <Outlet />
          </main>
        </>
      )}
      <Modals />
      {(uploadFiles.loadingFiles.length > 0 ||
        uploadFiles.loadingFolders.length > 0 ||
        projectUploadfiles?.loadingFiles?.length > 0) && <FileLoader />}
      <div style={{ display: "none" }}>
        <input type="file" multiple onChange={onInputFiles} ref={inputFileRef} />
        <input
          type="file"
          onChange={onInputFolderFiles}
          ref={(node) => {
            inputFolderRef.current = node;

            if (node) {
              ["webkitdirectory", "directory", "mozdirectory"].forEach((attr) => {
                node.setAttribute(attr, "");
              });
            }
          }}
        />
      </div>
    </>
  );
};

export default Shell;
