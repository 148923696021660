import TaskInfo from "containers/JoinProject/TaskInfo/TaskInfo";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import React from "react";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./TaskProjectInfoModal.module.sass";

const TaskProjectInfoModal = () => {
  const {
    taskProjectInfo: { task }
  } = useGlobalModalsSelectors();
  const { onSetProjectTaskInfoModal } = useActions();

  const closeModal = () => onSetProjectTaskInfoModal({ open: false, task: null });

  return (
    <PopUp set={closeModal}>
      <div className={styles.wrap}>
        <HeaderModal title={task.name} onClose={closeModal} />
        <div className={styles.body}>
          <TaskInfo id_task={task.id} isModal={true} closeModal={closeModal} />
        </div>
      </div>
    </PopUp>
  );
};

export default TaskProjectInfoModal;
