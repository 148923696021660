import { ReactComponent as PlayIcon } from "assets/PrivateCabinet/play-grey.svg";
import { ReactComponent as RadioIcon } from "assets/PrivateCabinet/radio-3.svg";
import { ReactComponent as SendIcon } from "assets/PrivateCabinet/send.svg";
import { ReactComponent as SmileIcon } from "assets/PrivateCabinet/smile.svg";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./CommentHandler.module.sass";

const CommentHandler = ({ handleSubmit, isComment }) => {
  return (
    <div className={styles.fielsdWrap}>
      <div className={styles.messageBox}>
        <div onClick={() => handleSubmit(false)}>
          <SendIcon className={classNames(styles.sendIcon, { [styles.active]: isComment })} />
        </div>
      </div>
      <div className={styles.btn}>
        <RadioIcon className={styles.icon} />
      </div>
      <div className={styles.btn}>
        <PlayIcon className={styles.playIcon} />
      </div>
      <div className={styles.btn}>
        <SmileIcon className={styles.icon} />
      </div>
    </div>
  );
};

export default CommentHandler;

CommentHandler.propTypes = {
  handleSubmit: PropTypes.func,
  isComment: PropTypes.bool
};
