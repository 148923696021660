import { BacklogServicePanel } from "containers/JoinProject/JointProjectsBacklog/BacklogServicePanel/BacklogServicePanel";
import { JointProjectsHistoryContent } from "containers/JoinProject/JointProjectsHistory/components/JointProjectsHistoryContent/JointProjectsHistoryContent";
import { WrapperPage } from "containers/JoinProject/WrapperPage/WrapperPage";
import { useActions } from "hooks/useActions";
import { useDebounce } from "hooks/useDebounce";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";

const ProjectHistoryPage = () => {
  const { color, emo, tags, priority, search, team, participants } = useAllSelectedSearchParams();

  const { onFetchAllTaskProject, onResetAllTasksProject, onSetProjectLoader } = useActions();

  const controller = new AbortController();

  const debouncedSearchQuery = useDebounce(
    (search: string, team: string, participants: string[]) => {
      onSetProjectLoader(true);

      const payload = {
        per_page: 500,
        search,
        selectedParticipants: participants?.join(","),
        team,
        id_color: color.selectedValue,
        id_emo: emo.selectedValue,
        tags: tags.selectedValue,
        priority: priority.selectedValue,
        callBack: () => onSetProjectLoader(false),
        controller
      };
      onFetchAllTaskProject(payload);
    },
    search?.selectedValue?.length > 0 ? 300 : 0
  );

  useEffect(() => {
    onResetAllTasksProject();

    debouncedSearchQuery(search.selectedValue, team.selectedValue, participants.selectedValue);

    return () => {
      onResetAllTasksProject();
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    team.selectedValue,
    participants.selectedValue,
    search.selectedValue,
    color.selectedValue,
    emo.selectedValue,
    tags.selectedValue,
    priority.selectedValue
  ]);

  return (
    <WrapperPage>
      <BacklogServicePanel showAddButton={false} />
      <JointProjectsHistoryContent />
    </WrapperPage>
  );
};

export default ProjectHistoryPage;
