import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import defaultImage from "assets/images/default.png";
import defaultImage2x from "assets/images/default@2x.png";
import classNames from "classnames";
import { useContextProjectLibraryFolder } from "collections/joinProject";
import { useContextLibraryFolder } from "collections/library";
import Button from "generalComponents/Button/Button";
import { Image } from "generalComponents/Image/Image";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { LIBRARY_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { useCurrentPath } from "hooks/useCurrentPath";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IFolder } from "models/store/folders/foldersStore";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

import styles from "./PreviewFolderLibrary.module.sass";

export interface IPreviewFolderLibraryProps {
  folder: IFolder;
  isLinePreview?: boolean;
}

export const PreviewFolderLibrary: FC<IPreviewFolderLibraryProps> = ({
  folder,
  isLinePreview = false
}): JSX.Element => {
  const { onTrashFolderCallback, onVisibleEditObjectLibraryModal } = useContextLibraryFolder();
  const { onTrashProjectLibraryFolderCallback, onVisibleEditObjectProjectLibraryModal } =
    useContextProjectLibraryFolder();

  const { __ } = useLocales();

  const navigate = useNavigate();

  const folderName = folder.name;

  const altDefault = __("Нет изображения");

  const { id } = useParams();

  const { pathname } = useLocation();

  const isHeighInherit = folder?.files?.length < 3;
  const projectLibraryPage = `${ROUTES.PROJECT}/${id}/${ROUTES.LIBRARY}`;
  const isProjectLibrarySettings = useCurrentPath(`/${projectLibraryPage}/${LIBRARY_ROUTES.SETTINGS}`);
  const isLibrarySetttings = useCurrentPath(`/${ROUTES.LIBRARY}/${LIBRARY_ROUTES.SETTINGS}`);

  const renderPath = () => {
    if (!pathname?.includes(projectLibraryPage) && !isLibrarySetttings) {
      return navigate(`/${ROUTES.LIBRARY}/dir/${folder.id_dir}`);
    }

    if (pathname?.includes(projectLibraryPage) && !isProjectLibrarySettings) {
      return navigate(`/${projectLibraryPage}/dir/${folder.id_dir}`);
    }

    return;
  };

  const { formatDate } = useDateFormat();

  return (
    <div
      onClick={renderPath}
      className={classNames(styles.card, {
        [styles.isLinePreview]: isLinePreview
      })}
    >
      <div
        className={classNames(styles.card__head, {
          [styles.isLinePreview]: isLinePreview
        })}
      >
        {folder?.files?.length ? (
          folder.files
            .filter((_, index) => index <= 3)
            .map(({ preview_image }) => (
              <div
                key={uuid()}
                className={classNames(
                  styles.card__imgBox,
                  {
                    [styles.default]: !preview_image,
                    [styles.isLinePreview]: isLinePreview,
                    [styles.heightInherit]: isHeighInherit
                  },
                  "flex-center"
                )}
              >
                {preview_image ? (
                  <Image src={`${projectSrc}${preview_image}`} alt={folderName || altDefault} />
                ) : (
                  <Image src={defaultImage} alt={altDefault} srcSet={`${defaultImage2x} 2x`} />
                )}
              </div>
            ))
        ) : (
          <div
            className={classNames(
              styles.card__imgBox,
              {
                [styles.default]: true,
                [styles.isLinePreview]: isLinePreview,
                [styles.heightInherit]: isHeighInherit
              },
              "flex-center"
            )}
          >
            <Image src={defaultImage} alt={altDefault} srcSet={`${defaultImage2x} 2x`} />
          </div>
        )}
      </div>
      <div
        className={classNames(styles.card__body, {
          [styles.isLinePreview]: isLinePreview
        })}
      >
        <h3
          className={classNames(styles.card__name, {
            [styles.isLinePreview]: isLinePreview
          })}
        >
          {folderName}
        </h3>
        {isLinePreview && <span className={styles.card__date}>{formatDate(folder.ctime, "full")}</span>}
      </div>
      {(isLibrarySetttings || isProjectLibrarySettings) && (
        <div className={styles.card__btns}>
          <Button
            variant={ButtonVariantType.OPACITY}
            onClick={() =>
              isLibrarySetttings
                ? onVisibleEditObjectLibraryModal(folder)
                : onVisibleEditObjectProjectLibraryModal(folder)
            }
            size={ButtonSizeType.MEDIUM}
            iconL={<EditIcon width={20} height={20} fill="#274A42" />}
          />
          <Button
            variant={ButtonVariantType.OPACITY}
            onClick={() =>
              isLibrarySetttings ? onTrashFolderCallback(folder) : onTrashProjectLibraryFolderCallback(folder)
            }
            size={ButtonSizeType.MEDIUM}
            iconL={<TrashIcon width={20} height={20} fill="#274A42" />}
          />
        </div>
      )}
    </div>
  );
};
