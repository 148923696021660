import { ReactComponent as AddUserIcon } from "assets/PrivateCabinet/add-user.svg";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { onSetModals } from "../../../../../../Store/actions/ModalActions";
import styles from "./AddUser.module.sass";

const AddUser = () => {
  const dispatch = useDispatch();
  const project = useSelector((s) => s.Projects.project);

  const addUsers = () =>
    dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.ADD_USERS, params: { ...project } }));

  return <>{Number(project.is_read) > 0 && <AddUserIcon className={styles.addIcon} onClick={addUsers} />}</>;
};

export default AddUser;
