import { IFolder } from "models/store/folders/foldersStore";

import { FolderInfoClassNameTypes } from "./folderInfo";

export enum FolderPathClassNameTypes {
  NO_BORDER_RADIUS = "noBorderRadius"
}

export interface IFolderPathProps {
  className?: FolderPathClassNameTypes;
  folderInfoClassName?: FolderInfoClassNameTypes;
  folders: IFolder[];
  onSelect: (e: React.MouseEvent<HTMLElement>, el: IFolder) => void;
  indent?: number;
  folderPath: IFolder[];
  currentFolder?: IFolder;
}
