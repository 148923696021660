import { ReactComponent as GearIcon } from "assets/PrivateCabinet/gear.svg";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { ButtonSizeType } from "models/generalComponents/button";
import { IServePanel } from "models/servePanel";
import { FC, useState } from "react";

import { ContentСleaning } from "./components/ContentСleaning";

export const AutomaticСleaning: FC<IServePanel> = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <PopoverOverlay
      overlayContent={<ContentСleaning />}
      width={320}
      show={isOpen}
      setShow={setIsOpen}
      btn="custom"
      iconLBtn={<GearIcon fill="#274A42" />}
      sizeBtn={ButtonSizeType.SMALL}
    />
  );
};
