import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ChangeEvent } from "react";
import { useLocales } from "react-localized";

import styles from "./EmailBlock.module.sass";

interface IEmailBlock {
  email: string;
  onEmailChange: (e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onEmailBlur: (index: number) => void;
  onAddEmail: () => void;
  onRemoveEmail: (index: number) => void;
  index: number;
  emailError: string[];
}

const EmailBlock = ({
  email,
  onEmailChange,
  onEmailBlur,
  onAddEmail,
  onRemoveEmail,
  index,
  emailError
}: IEmailBlock) => {
  const { __ } = useLocales();

  return (
    <div className={styles.phoneBlock}>
      <Input
        className={styles.input}
        value={email}
        onChange={(e) => onEmailChange(e, index)}
        placeholder={__("Введите")}
        label={`Email ${index + 1}`}
        error={emailError[index] ? true : false}
        errorText={emailError[index]}
        onBlur={() => onEmailBlur(index)}
      />
      {index === 0 ? (
        <Button
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.MEDIUM}
          onClick={onAddEmail}
          iconR={<PlusIcon />}
          isSquare
        />
      ) : (
        <Button
          variant={ButtonVariantType.OPACITY}
          size={ButtonSizeType.MEDIUM}
          onClick={() => onRemoveEmail(index)}
          iconR={<TrashIcon />}
          isSquare
        />
      )}
    </div>
  );
};

export default EmailBlock;
