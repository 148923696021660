import { ReactComponent as BlockIcon } from "assets/icons/block.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person_3.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Button from "generalComponents/Button/Button";
import { useOnBlockEmployee, useOnTrashEmployee } from "hooks/employeesHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IUserInfo } from "models/store/user/user";
import { FC } from "react";
import { useLocales } from "react-localized";

import styles from "./EmployeeActions.module.sass";

interface IProps {
  employee: IUserInfo;
  closeModal: () => void;
}

const EmployeeActions: FC<IProps> = ({ employee, closeModal }) => {
  const { __ } = useLocales();
  const { onDelete } = useOnTrashEmployee();
  const { onBlock } = useOnBlockEmployee();
  const { onSetTransferDutiesModal } = useActions();

  const onTransfer = () => {
    closeModal();
    onSetTransferDutiesModal({ open: true, employee });
  };

  return (
    <div className={styles.block}>
      <Button
        variant={ButtonVariantType.DARK}
        size={ButtonSizeType.SMALL}
        onClick={() => onBlock(employee)}
        text={employee.is_block ? __("Разблокировать") : __("Заблокировать")}
        iconL={<BlockIcon />}
      />
      <Button
        variant={ButtonVariantType.DARK}
        size={ButtonSizeType.SMALL}
        onClick={() => onDelete(employee)}
        text={__("Удалить")}
        iconL={<TrashIcon />}
      />
      <Button
        variant={ButtonVariantType.DARK}
        size={ButtonSizeType.SMALL}
        onClick={onTransfer}
        text={__("Передача обязанностей")}
        iconL={<PersonIcon />}
      />
    </div>
  );
};

export default EmployeeActions;
