import { ReactComponent as PaintIcon } from "assets/icons/paint.svg";
import { ReactComponent as CloseModal } from "assets/PrivateCabinet/closeModal.svg";
import classNames from "classnames";
import { colors } from "collections/markers";
import Button from "generalComponents/Button/Button";
import PopUp from "generalComponents/PopUp/PopUp";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import styles from "./Colors.module.sass";

export interface IColorsProps {
  color: string;
  setColor: Function;
  disabled?: boolean;
}

const Colors: React.FC<IColorsProps> = ({ color, setColor, disabled = false }): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = (): void => setIsOpen((open) => !open);

  const renderColors = (): JSX.Element[] => {
    return colors.map((current) => (
      <div
        key={uuid()}
        className={classNames(styles.color, color === current && styles.active)}
        onClick={() => setColor((prev: string) => (prev === current ? undefined : current))}
      >
        <span style={{ background: current }} />
      </div>
    ));
  };

  return (
    <>
      <Button
        disabled={disabled}
        isSquare
        size={ButtonSizeType.LARGE}
        variant={ButtonVariantType.ADD_GREY}
        onClick={toggleOpen}
        iconL={
          !color ? (
            <PaintIcon />
          ) : (
            <span
              className={styles.color}
              style={{
                background: color,
                height: 16,
                width: 16
              }}
            />
          )
        }
      />

      {isOpen && (
        <PopUp set={toggleOpen}>
          <ul className={styles.wrap}>
            {renderColors()}
            <button className={styles.closeBtn} onClick={toggleOpen}>
              <CloseModal width={15} height={15} />
            </button>
          </ul>
        </PopUp>
      )}
    </>
  );
};

export default Colors;
