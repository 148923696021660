import classNames from "classnames";
// import PropTypes from "prop-types";
import React, { FC, MouseEvent, ReactNode } from "react";

import styles from "./ContextMenuItem.module.sass";

interface Props {
  width: number;
  height: number;
  color?: string;
  text?: string;
  imageSrc?: ReactNode;
  icon?: ReactNode;
  endIcon?: ReactNode;
  callback?: (e: MouseEvent<HTMLDivElement>) => void;
  nextGroup?: boolean;
}

const ContextMenuItem: FC<Props> = ({ width, height, color, text, nextGroup, icon, endIcon, callback }) => {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    if (callback) callback(e);
  };

  return (
    <div
      onClick={onClick}
      className={classNames(styles.itemWrap, {
        [styles.nextGroup]: nextGroup,
        [styles.withIcon]: icon,
        [styles.withEndIcon]: endIcon
      })}
      style={{ width, height, color }}
    >
      {icon && icon}
      <div className={styles.text}>{text}</div>
      {endIcon && <div className={styles.endIcon}>{endIcon}</div>}
    </div>
  );
};

export default ContextMenuItem;
