import { IProjectTask } from "models/store/joinProjects/joinProgects";

export interface ICreateProjectTaskModal {
  open: boolean;
  type: "add" | "edit";
  task: IProjectTask;
  defaultStatus?: string;
}

export function initialCreateProjectTaskState(): ICreateProjectTaskModal {
  return { open: false, type: "add", task: null, defaultStatus: undefined };
}
