import classNames from "classnames";
import { FC, ReactNode } from "react";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./PanelBox.module.sass";

interface IPanelBoxProps {
  title?: string;
  children?: ReactNode;
}

export const PanelBox: FC<IPanelBoxProps> = ({ title, children }): JSX.Element => {
  const { theme } = useUserSelectors();

  return (
    <div className={styles.panel}>
      {title && <h2 className={styles.panel__title}>{title}</h2>}
      <div className={classNames(styles.panel__content, `scrollbar-thin-${theme}`)}>{children}</div>
    </div>
  );
};
