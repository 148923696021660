import classNames from "classnames";
import FolderInfo from "generalComponents/FolderInfo/FolderInfo";
import { IFolderPathProps } from "models/generalComponents/folderPath";
import { IFolder } from "models/store/folders/foldersStore";
import React from "react";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";

import styles from "./FolderPath.module.sass";

const FolderPath: React.FC<IFolderPathProps> = ({
  className,
  folderInfoClassName,
  folders,
  onSelect,
  indent = 10,
  folderPath,
  currentFolder
}): JSX.Element => {
  const { openFolder } = useFoldersSelectors();

  const isChosen = (folder: IFolder): boolean => {
    const idx = folderPath.findIndex((el) => el.id_dir === folder.id_dir);
    return idx >= 0;
  };

  return (
    <ul className={classNames(styles.list, styles[className])}>
      {folders
        .filter((folder) => !folder.is_archive && !folder.is_del)
        .filter((el) => (currentFolder ? el.id_dir !== currentFolder.id_dir : el))
        .map((folder) => (
          <li key={folder.id_dir} onClick={(e) => onSelect(e, folder)} className={styles.listItem}>
            <FolderInfo className={folderInfoClassName} folder={folder} indent={indent} isChosen={isChosen(folder)} />
            {openFolder.includes(folder.id_dir) && (
              <FolderPath
                folderInfoClassName={folderInfoClassName}
                folders={folder.folders}
                onSelect={onSelect}
                indent={indent + 15}
                folderPath={folderPath}
                currentFolder={currentFolder}
              />
            )}
          </li>
        ))}
    </ul>
  );
};

export default FolderPath;
