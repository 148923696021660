import styles from "./SidebarImage.module.sass";

interface SidebarImageProps {
  title: string;
  text: string;
  img: string;
  position: string;
}

const SidebarImage = ({ data }: { data: SidebarImageProps }) => {
  const { title, text, img, position } = data;
  return (
    <div className={styles.container}>
      <h4>{title}</h4>
      <p>{text}</p>
      <div className={styles.image} style={{ backgroundImage: `url('${img}')`, backgroundPosition: position }} />
    </div>
  );
};

export default SidebarImage;
