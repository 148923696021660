import React from "react";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

import styles from "./LoadTime.module.sass";

interface ILoadTimeProps {
  onCancel: () => void;
}

const LoadTime: React.FC<ILoadTimeProps> = ({ onCancel }): JSX.Element => {
  const { __ } = useLocales();
  const {
    uploadFiles: { loadingFiles, loadingFolders }
  } = useFilesSelectors();

  const timeLeft = Math.max(
    ...loadingFiles.map((el) => el.time),
    loadingFolders.reduce(
      (accFolders, item) => accFolders + item.files.reduce((accFiles, el) => accFiles + el.time, 0),
      0
    )
  );

  return (
    <div className={styles.timeBlock}>
      {timeLeft > 0 && (
        <>
          <span className={styles.time}>
            {timeLeft < 60
              ? `${__("Осталось меньше минуты")}`
              : `${__("Осталось ")} ${Math.floor(timeLeft / 60)} ${__("мин")}`}
          </span>
          <button className={styles.cancelBtn} onClick={onCancel}>
            {__("Отмена")}
          </button>
        </>
      )}
    </div>
  );
};

export default LoadTime;
