import { ReactComponent as AudioIcon } from "assets/icons/audio.svg";
import { ReactComponent as DocIcon } from "assets/icons/doc.svg";
import { ReactComponent as FileIcon } from "assets/icons/file.svg";
import { ReactComponent as ImageIcon } from "assets/icons/image-mail.svg";
import { ReactComponent as PdfIcon } from "assets/icons/pdf-mail.svg";
import { ReactComponent as VideoIcon } from "assets/icons/video.svg";
import { ReactComponent as XlsIcon } from "assets/icons/xls.svg";
import { IPostbox } from "models/store/postbox/postbox";
import { ReactNode } from "react";

const getTextBlockquote = (text: string): { visible: string; unvisible: string } => {
  const searchText = "blockquote>";

  const startIdx = text.indexOf("<blockquote");
  const endIdx = text.lastIndexOf(searchText) + searchText.length;
  const firstPart = text.slice(0, startIdx);
  const lastPart = text.slice(endIdx);
  const unvisible = text.slice(startIdx, endIdx);
  return { visible: firstPart + lastPart, unvisible };
};

const getTextOutlook = (text: string): { visible: string; unvisible: string } => {
  const idxFrom = text.toLowerCase().indexOf(">from:<");
  const startCut = text.slice(0, idxFrom);
  const idxP = startCut.lastIndexOf("<p");
  const startString = text.slice(0, idxP);
  const idxEnd = text.lastIndexOf("</div>");
  const endString = text.slice(idxEnd);
  const visible = startString + endString;
  const unvisible = text.slice(idxP, idxEnd);
  return { visible, unvisible };
};

export const getLetterText = (text: string): { visible: string; unvisible: string } => {
  const outlookStr = "MsoNormal";
  const blockquoteStr = "<blockquote";
  const idxOutlook = text.indexOf(outlookStr);
  const idxBlockquote = text.indexOf(blockquoteStr);
  if (idxOutlook < 0 && idxBlockquote < 0) {
    return { visible: text, unvisible: "" };
  }

  if (idxOutlook > 0) {
    if (idxBlockquote < 0 || idxOutlook < idxBlockquote) {
      return getTextOutlook(text);
    }
  }

  if (idxBlockquote > 0) {
    if (idxOutlook < 0 || idxBlockquote < idxOutlook) {
      return getTextBlockquote(text);
    }
  }
};

export const renderLogo = () => {
  return `
      <div style="display: flex; align-items: center; gap: 10px; font-size: 24px">
        <svg width="39" height="42" viewBox="0 0 39 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5773 11.0692C20.5773 9.69341 21.6746 8.57812 23.0283 8.57812H31.4318C32.7855 8.57812 33.8829 9.69341 33.8829 11.0692V19.61C33.8829 20.9857 32.7855 22.101 31.4318 22.101H23.0283C21.6746 22.101 20.5773 20.9857 20.5773 19.61V11.0692Z" fill="#EED428"/>
          <path d="M20.5773 26.0155C20.5773 24.6398 21.6746 23.5245 23.0283 23.5245H31.4318C32.7855 23.5245 33.8829 24.6398 33.8829 26.0155V34.5563C33.8829 35.9321 32.7855 37.0474 31.4318 37.0474H23.0283C21.6746 37.0474 20.5773 35.9321 20.5773 34.5563V26.0155Z" fill="#EED428"/>
          <path d="M5.87109 26.0155C5.87109 24.6398 6.96846 23.5245 8.32212 23.5245H16.7257C18.0793 23.5245 19.1767 24.6398 19.1767 26.0155V34.5563C19.1767 35.9321 18.0793 37.0474 16.7257 37.0474H8.32212C6.96846 37.0474 5.87109 35.9321 5.87109 34.5563V26.0155Z" fill="#EED428"/>
          <path d="M3.69338 6.29225C3.95009 4.94214 5.23574 4.0616 6.56495 4.32552L14.8167 5.96389C16.1459 6.2278 17.0153 7.53623 16.7586 8.88635L15.1649 17.2678C14.9082 18.6179 13.6225 19.4985 12.2933 19.2346L4.04162 17.5962C2.71241 17.3323 1.84298 16.0238 2.09969 14.6737L3.69338 6.29225Z" fill="#34C6A2"/>
        </svg>
        <span>4Hub</span>
      </div>`;
};

const renderInfo = (postbox: IPostbox): string => `
      <div style="display: flex; align-items: center; gap: 10px;  justify-content: space-between">
        <strong>${postbox.name || ""} </strong>
        &lt;${postbox.email}&gt;
      </div>`;

export const renderHeader = (postbox: IPostbox) => `
      <div style="display: flex; align-items: center; gap: 10px; justify-content: space-between; border-bottom: 1px dashed #2e2e2e; padding: 20px 16px; margin-bottom: 24px">
        ${renderLogo()}
        ${renderInfo(postbox)}
      </div>`;

export const renderSubject = (subject: string) => `
      <div style=" border-bottom: 1px dashed #2e2e2e; padding: 0 16px 16px; margin-bottom: 24px">
        <strong style="font-size: 24px">${subject}</strong>          
      </div>`;

export const typeOfAttachmentImage = (type: string): ReactNode => {
  if (type.startsWith("image")) {
    return <ImageIcon />;
  }
  if (type.startsWith("application/pdf")) {
    return <PdfIcon />;
  }
  if (type.startsWith("video")) {
    return <VideoIcon />;
  }
  if (type.startsWith("audio")) {
    return <AudioIcon />;
  }
  if (type.includes("sheet") || type.includes("excel")) {
    return <XlsIcon />;
  }
  if (type.includes("word")) {
    return <DocIcon />;
  }

  return <FileIcon />;
};
