import { GARBAGE_TABS } from "generalComponents/variables/project";
import React, { useState } from "react";

import styles from "./ProjectTrash.module.sass";
import TrashFiles from "./TrashFiles/TrashFiles";
import TrashTabs from "./TrashTabs/TrashTabs";
import TrashTasks from "./TrashTasks/TrashTasks";

const ProjectTrash = () => {
  const [currentTab, setCurrentTab] = useState<GARBAGE_TABS>(GARBAGE_TABS.TASK);

  const onSelectTab = (tab: GARBAGE_TABS): void => {
    setCurrentTab(tab);
  };

  return (
    <div className={styles.wrap}>
      <TrashTabs currentTab={currentTab} onSelect={onSelectTab} />
      {currentTab === GARBAGE_TABS.TASK && <TrashTasks />}
      {currentTab === GARBAGE_TABS.FILES && <TrashFiles />}
    </div>
  );
};

export default ProjectTrash;
