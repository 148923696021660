import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Button from "generalComponents/Button/Button";
import { useAccessesProjectRole, useDefaultProjectRole } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IProjectRole, ROLE_ACCESSES, SYSTEM_ROLE } from "models/store/joinProjects/joinProgects";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./SettingsRoles.module.sass";

const SettingsRoles = () => {
  const { __ } = useLocales();
  const { project } = useJoinProjectsSelectors();
  const defaultRoles = useDefaultProjectRole();
  const accessesRole = useAccessesProjectRole();
  const { onSetCreateProjectRoleModal, onEditRole, onSetApproveModal } = useActions();

  const renderRoleAccesses = (accesses: Record<ROLE_ACCESSES, boolean>): string => {
    const arr = Object.keys(accesses);
    const str = arr
      .reduce((acc, el) => {
        if (accesses[el as ROLE_ACCESSES]) {
          acc.push(accessesRole[el as ROLE_ACCESSES].title);
        }
        return acc;
      }, [])
      .join(", ")
      .toLowerCase();

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const onEdit = (role: IProjectRole) => {
    onSetCreateProjectRoleModal({ open: true, type: "edit", role });
  };

  const onDelete = (role: IProjectRole) => {
    const payload = {
      data: {
        id_item: role.id,
        id_project: project.id,
        is_del: true
      },
      messages: { success: __("Роль удалена") }
    };

    const params = {
      titleHead: __("Удалить"),
      approveBtn: __("Удалить"),
      callback: () => onEditRole(payload),
      text: __(
        `Вы действительно хотите удалить ${role.name}? Участникам проекта с ролью ${role.name} будут сохранены привилегии и доступы, назначить роль новому участнику будет невозможно`
      )
    };
    onSetApproveModal({ open: true, params });
  };

  return (
    <ul className={styles.list}>
      {project.roles
        .filter((role) => !role.is_del)
        .map((role) => (
          <li key={role.id} className={styles.roleItem}>
            <p className={styles.title}>{role.is_system ? defaultRoles[role.name as SYSTEM_ROLE].title : role.name}</p>
            <p className={styles.prim}>
              {role.is_system ? defaultRoles[role.name as SYSTEM_ROLE].prim : renderRoleAccesses(role.accesses)}
            </p>
            {!role.is_system && (
              <div className={styles.btns}>
                <Button
                  onClick={() => onEdit(role)}
                  variant={ButtonVariantType.OPACITY}
                  size={ButtonSizeType.MEDIUM}
                  iconL={<EditIcon width={15} height={15} />}
                  isSquare
                />
                <Button
                  onClick={() => onDelete(role)}
                  variant={ButtonVariantType.OPACITY}
                  size={ButtonSizeType.MEDIUM}
                  iconL={<TrashIcon width={15} height={15} />}
                  isSquare
                />
              </div>
            )}
          </li>
        ))}
    </ul>
  );
};

export default SettingsRoles;
