import api from "api";
import axios from "axios";
import { PAGE_DEPTH } from "generalComponents/variables/global";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import { FavoriteItemsActions } from "models/store/favoriteItems/favoriteItemsActions";
import { SORTING_TYPE } from "models/store/files/sort/sort";
import { FoldersAction } from "models/store/folders/foldersActions";
import { JoinProjectActions } from "models/store/joinProjects/joinProjectsActions";
import { LibraryActions } from "models/store/library/libraryActions";
import { PostboxActions } from "models/store/postbox/postboxActions";
import { ProgramsActions } from "models/store/programs/programsActions";
import { RecentFilesActions } from "models/store/recentFiles/recentFilesActions";
import { SafeActions } from "models/store/safe/safeActions";
import { SharedActions } from "models/store/shared/sharedActions";
import { TasksActions } from "models/store/tasks/tasksActions";
import { TrashElementsActions } from "models/store/trash/trashElementssActions";
import { UserAction } from "models/store/user/userActions";
import { ThunkAction } from "redux-thunk";
import { onSetProjectList } from "Store/actions/JoinProjectActions";
import { onSetTrashElements } from "Store/actions/TrashActions";
import { RootState } from "Store/reducers";

import { onSetFavoriteItems } from "./FavoriteItemsActions";
import { onSetFolders } from "./FoldersActions";
import { onSetLibraryRubrics } from "./LibraryActions";
import { onSetPostboxes } from "./PostboxActions";
import { setProgramCategories } from "./ProgramsActions";
import { onSetRecentFiles } from "./RecentFilesActions";
import { onSetSafeList } from "./SafeActions";
import { onSetShared } from "./SharedActions";
import { onSetTasks } from "./TasksActions";
import { onLog, onSetContacts, onSetTags, onSetUsersStorage, onUserInfo, setEmployees } from "./userAction";

type TypesAction =
  | FoldersAction
  | UserAction
  | RecentFilesActions
  | PostboxActions
  | CabinetActions
  | SafeActions
  | ProgramsActions
  | TasksActions
  | LibraryActions
  | FavoriteItemsActions
  | SharedActions
  | TrashElementsActions
  | JoinProjectActions;

// TODO -mk- Добавить Запрос на чат, совместные проекты
const promises = [
  { url: "/ajax/user_get.php?" },
  { url: "/ajax/get_folders.php?", params: { show_all: 1 } },
  { url: "/ajax/file_recent.php?" },
  { url: "/ajax/email_list.php?" },
  { url: "/ajax/safe_list.php?" },
  { url: "/ajax/soft_dep_list.php?" },
  { url: "/ajax/task_get.php?" },
  { url: "/ajax/get_folders.php?", params: { dep: PAGE_DEPTH.LIBRARY } },
  { url: "/ajax/org_user_list.php" },
  { url: "/ajax/contacts_list.php" },
  {
    url: "/ajax/file_list.php",
    params: { per_page: PER_PAGE, page: 1, filter_favorite: 1, sort: SORTING_TYPE.DATE_CREATED }
  },
  { url: "/ajax/file_share_get.php", params: { per_page: PER_PAGE, page: 1, sort: SORTING_TYPE.DATE_CREATED } },
  { url: "/ajax/trash_list.php", params: { sort_reverse: 1, group: 0, sort: SORTING_TYPE.DATE_CREATED } },
  { url: "/ajax/project_list.php" },
  { url: "/ajax/tags_list.php" }
];

export const onGlobalInfo =
  (cb: () => void): ThunkAction<void, RootState, unknown, TypesAction> =>
  (dispatch, getState) => {
    const uid = getState().user.uid;
    axios
      .all(promises.map((item) => api.get(item.url, { params: { uid, ...item.params } })))
      .then(
        axios.spread((...responses) => {
          const [
            { data: user },
            { data: folders },
            { data: recentFiles },
            { data: emails },
            { data: safes },
            { data: programs },
            { data: tasks },
            { data: library },
            { data: employyes },
            { data: contacts },
            { data: favorite },
            { data: shared },
            { data: trash },
            { data: projects },
            { data: tags }
          ] = responses;
          if (user.data.is_block) {
            dispatch(onLog(""));
            return;
          }
          dispatch(onUserInfo(user.data));
          dispatch(onSetUsersStorage(user?.users_storage));
          dispatch(onSetFolders(folders.folders.global, folders.folders.other));
          dispatch(onSetRecentFiles(recentFiles.files));
          dispatch(onSetPostboxes(emails.emails));
          dispatch(onSetSafeList(safes.safes));
          dispatch(setProgramCategories(programs.data.soft_dep));
          dispatch(onSetTasks(tasks.tasks));
          dispatch(onSetLibraryRubrics(library.deps));
          dispatch(setEmployees(employyes.data.users));
          dispatch(onSetContacts(contacts.data?.data || []));
          dispatch(onSetFavoriteItems(favorite.files || []));
          dispatch(onSetShared(shared.files || []));
          dispatch(onSetTrashElements(trash.files));
          dispatch(onSetProjectList(projects.projects));
          dispatch(onSetTags(tags.data.tags));
        })
      )
      .finally(() => cb());
  };
