import { ReactComponent as OpenIcon } from "assets/PrivateCabinet/open-all.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/plus-3.svg";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { onSetModals } from "../../../../../../../Store/actions/ModalActions";
import TaskFilter from "../../../Components/TaskFilter/TaskFilter";
import Category from "../Category/Category";
import { TasksProvider } from "../Tasks";
import styles from "./TasksLine.module.sass";

const TasksLine = () => {
  const dispatch = useDispatch();
  const [currentTask, setCurrentTask] = useState(null);
  const project = useSelector((s) => s.Projects.project);

  const { toggleOpen, isOpenAll } = useContext(TasksProvider);

  const onAddCategory = () =>
    dispatch(
      onSetModals(MODALS.PROJECT, {
        type: PROJECT_MODALS.ADD_CATEGORY,
        params: {}
      })
    );

  return (
    <div className={styles.wrap}>
      <div className={styles.stikyBox}>
        <ButtonIcon handleClick={toggleOpen} icon={<OpenIcon />} isActive={isOpenAll} variant="grey" />
        <TaskFilter variant="left" />
        {Number(project.is_read) > 0 && (
          <ButtonIcon handleClick={onAddCategory} icon={<PlusIcon width={16} />} variant="grey" />
        )}
      </div>

      {project.tasks_category.map((category) => (
        <div key={category.id}>
          <Category category={category} currentTask={currentTask} setCurrentTask={setCurrentTask} variant="row" />
        </div>
      ))}
    </div>
  );
};

export default TasksLine;
