import classNames from "classnames";
import { GARBAGE_TABS } from "generalComponents/variables/project";
import { ITrashTabsProps } from "models/project/projectTrash";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./TrashTabs.module.sass";

const TrashTabs: React.FC<ITrashTabsProps> = ({ currentTab, onSelect }): JSX.Element => {
  const { __ } = useLocales();
  return (
    <div className={styles.tabs}>
      <div
        className={classNames(styles.tab, { [styles.active]: currentTab === GARBAGE_TABS.TASK })}
        onClick={() => onSelect(GARBAGE_TABS.TASK)}
      >
        {__("Задачи")}
      </div>
      <div
        className={classNames(styles.tab, { [styles.active]: currentTab === GARBAGE_TABS.FILES })}
        onClick={() => onSelect(GARBAGE_TABS.FILES)}
      >
        {__("Файлы")}
      </div>
    </div>
  );
};

export default TrashTabs;
