import classNames from "classnames";
import ElementsFilter from "generalComponents/ElementsFilter/ElementsFilter";
import { ITrashServicePanelFilesProps } from "models/project/projectTrash";
import React from "react";
import { useLocales } from "react-localized";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import styles from "./TrashServicePanel.module.sass";

const TrashServicePanelFiles: React.FC<ITrashServicePanelFilesProps> = ({
  isSelect,
  toggleSelect,
  filters,
  setFilters
}): JSX.Element => {
  const { project } = useProjectsSelectors();
  const { __ } = useLocales();

  return (
    <div className={styles.wrap}>
      swefwef
      <ElementsFilter filters={filters} setFilters={setFilters} />
      {project.is_read === "1" && (
        <div className={classNames(styles.selectBtn, { [styles.active]: isSelect })} onClick={toggleSelect}>
          {__("Выбрать")}
        </div>
      )}
    </div>
  );
};

export default TrashServicePanelFiles;
