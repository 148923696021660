import { ReactComponent as CrossIcon } from "assets/PrivateCabinet/cross.svg";
import { ITypeOfFile } from "models/store/postbox/postbox";
import { FC } from "react";
import { typeOfAttachmentImage } from "utils/mail";

import styles from "./AddedAttachment.module.sass";

interface Props {
  name: string;
  type: ITypeOfFile | string;
  remove?: () => void;
}

const AddedAttachment: FC<Props> = ({ name, type, remove }) => {
  const _type = typeof type !== "string" ? type.mimeType : type;

  return (
    <div className={styles.atachment}>
      {typeOfAttachmentImage(_type)}
      <span className={styles.filename}>{name ?? "File"}</span>
      <CrossIcon width={12} height={12} onClick={remove} />
    </div>
  );
};

export default AddedAttachment;
