export const convertToRGBA = (color: string, transparency: number): string | null => {
  const hexToRGBA = (hex: string, alpha: number): string => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  // Проверка наличия символа # в начале строки
  if (color.startsWith("#")) {
    return hexToRGBA(color, transparency / 100);
  } else {
    // Создаем элемент div с прозрачностью 0
    const element = document.createElement("div");
    element.style.color = color;
    element.style.opacity = "0";
    document.body.appendChild(element);

    // Получение вычисленного цвета
    const computedColor = window.getComputedStyle(element).color;

    // Удаление временного элемента
    document.body.removeChild(element);

    // Попытка извлечь прозрачность из rgba-строки
    const match = computedColor.match(/rgba?\((\d+), (\d+), (\d+)(, ([\d.]+))?\)/);

    // Проверка корректности извлечения прозрачности
    if (!match || match.length < 4) {
      return null;
    }

    // Извлечение значений цвета
    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);

    // Извлечение прозрачности, если указана
    const alpha = match[5] ? parseFloat(match[5]) : 1;

    // Обновление прозрачности
    const newAlpha = transparency / 100;

    // Возвращение строки с обновленной прозрачностью
    return `rgba(${r}, ${g}, ${b}, ${alpha * newAlpha})`;
  }
};
