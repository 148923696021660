import classNames from "classnames";
import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
import Input from "generalComponents/Input/Input";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { ChangeEvent, FC, useState } from "react";
import { useLocales } from "react-localized";

import styles from "./SetPassword.module.sass";

export interface ISetPasswordProps {
  password: string;
  setPassword: (e: ChangeEvent<HTMLInputElement>) => void;
  passwordRepeat: string;
  setPasswordRepeat: (e: ChangeEvent<HTMLInputElement>) => void;
  passwordError: string;
  onPasswordError: (val: string) => void;
  repeatError: string;
  onRepeatError: (val: string) => void;
  isPasswordSet: boolean;
  onClickSetButton: () => void;
}

export const SetPassword: FC<ISetPasswordProps> = ({
  password,
  setPassword,
  passwordRepeat,
  setPasswordRepeat,
  passwordError,
  repeatError,
  onPasswordError,
  onRepeatError,
  isPasswordSet,
  onClickSetButton
}): JSX.Element => {
  const { __ } = useLocales();
  const [,] = useState<boolean>(false);

  const onPasswordBlur = (): void => {
    if (password.length >= 4) return;

    onPasswordError(__("Пароль должен быть не менее 4 символов"));
  };

  const onRepeatBlur = (): void => {
    if (password !== passwordRepeat) {
      onRepeatError(__("Пароли не совпадают"));
    }
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.inputBlock}>
        <div>
          <Input
            className={styles.inputField}
            disabled={!isPasswordSet}
            value={password}
            onChange={setPassword}
            label={__("Пароль")}
            placeholder={__("Введите")}
            isPass
            error={Boolean(passwordError)}
            errorText={passwordError}
            onBlur={onPasswordBlur}
          />
          <div style={{ height: 8 }} />
          {isPasswordSet && (
            <Input
              className={styles.inputField}
              value={passwordRepeat}
              onChange={setPasswordRepeat}
              onBlur={onRepeatBlur}
              label={__("Повторите пароль")}
              placeholder={__("Введите")}
              error={Boolean(repeatError)}
              errorText={repeatError}
              isPass
            />
          )}
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={onClickSetButton}
          className={classNames(styles.switcher, {
            [styles.checked]: isPasswordSet
          })}
        >
          <CheckBox
            variant={isPasswordSet ? CheckBoxVariantTypes.CHECKED : CheckBoxVariantTypes.DEFAULT}
            width={24}
            height={24}
            increaseArea
            className={styles.switcher_checkbox}
          />
        </div>
      </div>
    </div>
  );
};
