import { IContact } from "models/store/user/user";

export interface IContactsModalState {
  open: boolean;
  params?: IContact;
}

export interface ISocialModalState {
  open: boolean;
  title: string;
  data: IContact["soc"] | IContact["mes"];
  icons: Record<string, JSX.Element>;
  updateData: (updateData: IContact["mes"] | IContact["soc"]) => void;
}

export function initialContactsModalState(): IContactsModalState {
  return { open: false, params: undefined };
}

export function initialSocialModalState(): ISocialModalState {
  return { open: false, title: "", data: [], icons: undefined, updateData: () => {} };
}
