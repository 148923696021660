import "./App.sass";

import api from "api";
import AppRouting from "generalComponents/AppRouting";
import { DeletedAccount } from "generalComponents/DeletedAccount/DeletedAccount";
import Loader from "generalComponents/Loaders/4HUB";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { getStorageItem, setStorageItem } from "generalComponents/Services/storageServices";
import { useActions } from "hooks/useActions";
import useInactivityTracker from "hooks/useInactivityTracker";
import Locales from "locales";
import { LoaderTypes } from "models/generalComponents/loader";
import { STATUS_COLOR, USER_STATUS } from "models/store/user/user";
import { useEffect, useState } from "react";
import { LocalizedProvider } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { getCurrentTimezoneOffset } from "utils/timezone";
import { v4 as uuid } from "uuid";

const App = (): JSX.Element => {
  const isInactive = useInactivityTracker(30);
  const { onLog, onGlobalInfo, onChangeUserSettings, onUserInfo } = useActions();
  const { uid, userInfo } = useUserSelectors();
  const lang = getStorageItem("lang") || "ru";
  const [loading, setLoading] = useState<boolean>(false);
  const [geo, setGeo] = useState(null);

  useEffect(() => {
    // проверяем или стоит галочка "Запомнить меня"
    const rememberUser = localStorage.getItem("rememberUser");
    if (rememberUser) return;
    // если нету галочки и пользователь неактивен определенное время - разлогиниваем
    if (isInactive) {
      onChangeUserSettings({ status: USER_STATUS.OFFLINE, status_color: STATUS_COLOR.OFFLINE });
      onLog("");
    }
  }, [isInactive]); // eslint-disable-line

  const stayOnline = async () => {
    try {
      let device_id = localStorage.getItem("device_id");

      if (!device_id) {
        const did = uuid();
        localStorage.setItem("device_id", did);
        device_id = did;
      }
      const { data } = await api.post(
        `/ajax/user_alive.php?uid=${uid}&device_id=${device_id}&ip_info=${JSON.stringify(geo)}`
      );

      if (userInfo.is_gmt_auto && userInfo.gmt.toString() !== getCurrentTimezoneOffset()) {
        const params = new FormData();
        params.append("uid", uid);
        params.append("gmt", getCurrentTimezoneOffset());
        const res = await api.post("/ajax/user_edit2.php", params);
        checkResponseStatus(res.data.ok);
        onUserInfo(res.data.data);
      }

      if (data.is_block_device || data.is_block_user || data.is_logoff) {
        onChangeUserSettings({ status: USER_STATUS.OFFLINE, status_color: STATUS_COLOR.OFFLINE });
        onLog("");
      }
    } catch {
      console.log("Error stayOnline");
    }
  };

  const onGetGeoInfo = async () => {
    const { data } = await api.get("https://2ip.supportio.ua/ip_info.php");
    setGeo(data);
  };

  useEffect(() => {
    // проверяем или есть "uid" в localStorage и залогиниваемся через него с функционалом "Запомнить меня"
    const uid = localStorage.getItem("uid");
    if (uid) {
      onLog(uid);
    }

    // проверяем, если с даты последнего входа с "Запомнить меня" прошло 30 дней то разлогиниваем
    const rememberUser = localStorage.getItem("rememberUser");
    if (rememberUser && +rememberUser < Date.now()) {
      onLog("");
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!getStorageItem("lang")) {
      setStorageItem("lang", "ru");
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!uid) return;
    setLoading(true);
    const onStopLoader = () => setLoading(false);
    onGlobalInfo(onStopLoader);

    onGetGeoInfo();
  }, [uid]); // eslint-disable-line

  useEffect(() => {
    if (!geo || !uid) return;
    stayOnline();
    const intervalId = setInterval(stayOnline, 60000);
    return () => clearInterval(intervalId);
  }, [geo, uid]); // eslint-disable-line

  return (
    <LocalizedProvider
      locales={Locales}
      selected={lang}
      alias={{
        gettext: "__",
        pgettext: "__p",
        ngettext: "__n",
        npgettext: "__np"
      }}
    >
      {({ localeReady }): JSX.Element => {
        if (localeReady) {
          if (loading) {
            return (
              <Loader
                position="absolute"
                zIndex={10000}
                containerType="bounceDots"
                type={LoaderTypes.BOUNCING_DOTS}
                background="white"
                animation={false}
              />
            );
          }

          if (Number(userInfo?.is_del) === 1 && userInfo?.status === USER_STATUS.ONLINE) {
            return <DeletedAccount />;
          }

          return <AppRouting />;
        }

        return (
          <Loader
            position="absolute"
            zIndex={10000}
            containerType="bounceDots"
            type={LoaderTypes.BOUNCING_DOTS}
            background="white"
            animation={false}
          />
        );
      }}
    </LocalizedProvider>
  );
};

export default App;
