import Button from "generalComponents/Button/Button";
import { ChooseFolder } from "generalComponents/ChooseFolder/ChooseFolder";
import CombinedElements from "generalComponents/Elements/CombinedElements";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import PopUp from "generalComponents/PopUp/PopUp";
import { SetPassword } from "generalComponents/SetPassword/SetPassword";
import { EVENT_TYPE, PAGE_DEPTH } from "generalComponents/variables/global";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { PROJECT_PATH, ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialCreateFolderState } from "models/store/Cabinet/modals/createFolder";
import { ICreateFolderPayload, IEditFolderPayload } from "models/store/folders/foldersPayloads";
import { IFolder, initialFolderState } from "models/store/folders/foldersStore";
import { ChangeEvent, FC, useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./CreateFolder.module.sass";

const CreateFolder: FC = (): JSX.Element => {
  const { __ } = useLocales();
  const { onSetCreateFolderModal } = useActions();
  const {
    createFolder: { type, folder, folders, onCreateFolder, onEditFolder, onSetUpdate }
  } = useGlobalModalsSelectors();

  const { id } = useParams();

  const isProject = useCurrentPathStarstWith(ROUTES.PROJECT);
  const isProjectDoc = useCurrentPathStarstWith(`${ROUTES.PROJECT}/${id}/${PROJECT_PATH.DOC}`);
  const isProjectFiles = useCurrentPathStarstWith(`${ROUTES.PROJECT}/${id}/${PROJECT_PATH.FILES}`);

  const _isAddType = type === "add";
  const [_folder, _setFolder] = useState<IFolder>(folder || { ...initialFolderState(), id_parent: "0" });
  const { value: password, onChange: setPassword, error: passwordError, onError: onPasswordError } = useInputValue();
  const {
    value: passwordRepeat,
    onChange: setPasswordRepeat,
    error: repeatError,
    onError: onRepeatError
  } = useInputValue();
  const [isPasswordSet, setIsPasswordSet] = useState<boolean>(false);
  const [name, setName] = useState<string>(_isAddType ? NO_VALUE : folder.name);
  const [emo, setEmo] = useState<string>("");
  const [tagOption, setTagOption] = useState<string[]>();
  const [color, setColor] = useState<string>();

  const closeModal = (): void => {
    onSetCreateFolderModal(initialCreateFolderState());
  };

  const onSelect = (folder: IFolder): void => _setFolder(folder);
  const onAddName = (e: ChangeEvent<HTMLInputElement>): void => setName(e.target.value);

  const onClickSetButton = (): void => {
    setIsPasswordSet((prev) => !prev);
    setPassword("");
    setPasswordRepeat("");
  };

  const renderDep = (): PAGE_DEPTH => {
    if (isProjectDoc) {
      return PAGE_DEPTH.PROJECT_DOC;
    }

    if (isProjectFiles) {
      return PAGE_DEPTH.PROJECT_FILES;
    }

    return PAGE_DEPTH.FILES;
  };

  const getPayload = (): ICreateFolderPayload | IEditFolderPayload => {
    return _isAddType
      ? {
          dir_name: name.trim(),
          id_parent: _folder.id_dir === NO_VALUE ? _folder.id_parent : _folder.id_dir,
          tags: tagOption || [],
          color,
          emo: emo || undefined,
          pass: password || undefined,
          dep: renderDep(),
          chapter: _folder?.chapter ? _folder?.chapter : undefined
        }
      : {
          dir_name_new: name,
          id_dirs: [folder.id_dir],
          id_parent: folder.id_parent,
          dep: folder.dep,
          chapter: _folder?.chapter ? _folder?.chapter : undefined
        };
  };

  const onSubmit = (): void => {
    if (isPasswordSet && password !== passwordRepeat) {
      onRepeatError(__("Пароли не совпадают"));
      return;
    }
    if (isPasswordSet && password.length <= 4) {
      onPasswordError(__("Пароль должен быть не менее 4 символов"));
      return;
    }
    _isAddType
      ? onCreateFolder(getPayload())
      : onEditFolder(getPayload(), EVENT_TYPE.EDIT_NAME, (folder: IFolder) => {
          closeModal();

          onSetUpdate(folder);
        });
  };

  const disableButton = (): boolean => name.trim() === NO_VALUE || Boolean(passwordError) || Boolean(repeatError);

  return (
    <PopUp set={closeModal} position={_isAddType ? "top" : "center"} top={200}>
      <div className={styles.wrap}>
        <HeaderModal onClose={closeModal} title={_isAddType ? __("Создать папку") : __("Переименовать")} />
        <div className={styles.body}>
          <Input
            value={name}
            onChange={onAddName}
            label={__("Имя папки")}
            placeholder={__("Введите имя папки")}
            className={styles.input}
            autoFocus={_isAddType ? true : false}
          />
          {_isAddType && !!folders?.length && (
            <>
              <div className={styles.selectBox}>
                <ChooseFolder onSelect={onSelect} folder={_folder} folders={folders} />
              </div>
              {!isProject && (
                <SetPassword
                  password={password}
                  setPassword={setPassword}
                  passwordRepeat={passwordRepeat}
                  setPasswordRepeat={setPasswordRepeat}
                  passwordError={passwordError}
                  onPasswordError={onPasswordError}
                  repeatError={repeatError}
                  onRepeatError={onRepeatError}
                  isPasswordSet={isPasswordSet}
                  onClickSetButton={onClickSetButton}
                />
              )}
            </>
          )}
        </div>
        {_isAddType && !isProject && (
          <div className={styles.elements}>
            <CombinedElements
              tags={tagOption}
              onSelectTags={setTagOption}
              color={color}
              setColor={setColor}
              emo={emo}
              setEmo={setEmo}
            />
          </div>
        )}
        <div className={styles.footer}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("Отмена")}
            onClick={closeModal}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={_isAddType ? __("Добавить") : __("Сохранить")}
            onClick={onSubmit}
            disabled={disableButton()}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default CreateFolder;
