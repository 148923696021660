import { ReactComponent as HeartIcon } from "assets/figures/heart-13.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as MoveIcon } from "assets/icons/move.svg";
import { ReactComponent as ReplaceIcon } from "assets/icons/replace.svg";
import { ReactComponent as SafeIcon } from "assets/icons/safe.svg";
import { ReactComponent as EditIcon } from "assets/PrivateCabinet/myFolders/contextMenu/edit.svg";
import { ReactComponent as GrantedAccessIcon } from "assets/PrivateCabinet/myFolders/contextMenu/granted-access.svg";
import { ReactComponent as InfoIcon } from "assets/PrivateCabinet/myFolders/contextMenu/info.svg";
import { ReactComponent as MoveToArchiveIcon } from "assets/PrivateCabinet/myFolders/contextMenu/move-to-archive.svg";
import { ReactComponent as ShareIcon } from "assets/PrivateCabinet/myFolders/contextMenu/share.svg";
import { ReactComponent as ShowSourceFolderIcon } from "assets/PrivateCabinet/myFolders/contextMenu/show-source-folder.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/myFolders/contextMenu/trash.svg";
import { ReactComponent as UploadIcon } from "assets/PrivateCabinet/myFolders/contextMenu/upload.svg";
import { ReactComponent as CompressToZipIcon } from "assets/PrivateCabinet/myFolders/fileContextMenu/compress-to-zip.svg";
import { ReactComponent as PrintIcon } from "assets/PrivateCabinet/myFolders/fileContextMenu/print.svg";
import ColorPicker from "generalComponents/ColorPicker/ColorPicker";
import EmojiPicker from "generalComponents/EmojiPicker/EmojiPicker";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { ShellContext } from "generalComponents/Shell/Shell";
import TagSelect from "generalComponents/TagSelect/TagSelect";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ButtonVariantType } from "models/generalComponents/button";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { PropertiesTypes, ShareActionTypes } from "models/store/Cabinet/modals/modals";
import { MOVE_ELEMENTS_TYPE } from "models/store/Cabinet/modals/moveElements";
import { IFile } from "models/store/files/files";
import { MouseEvent, useContext } from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";

export const useContextFile = () => {
  const { __ } = useLocales();
  const { did } = useParams();
  const {
    onEditFiles,
    onEditFilesLocal,
    onDeleteFiles,
    onSetShareModal,
    fetchFileZipLink,
    onSetApproveModal,
    fetchPrintFileUrl,
    onSetCopyLinkModal,
    onMoveFileToArchive,
    onSetCreateZipModal,
    onSetMoveToSafeModal,
    onSetPropertiesModal,
    onSetMoveElementsModal,
    onSetCustomizeFileModal,
    onSetShareToMessengersModal,
    onCopyFiles,
    onSetAddFiles,
    onSetDelFiles,
    onSetUpdateFiles,
    onSetAddNextFiles,
    onSetUpdateNextFiles,
    onSetDelNextFiles,
    onSetSelectFileModal,
    onSetSelectedUploadReplacementFile,
    onReplaceFiles
  } = useActions();
  const navigate = useNavigate();

  const { fileSelect } = useContext(ShellContext);

  const { folders } = useFoldersSelectors();

  const { currentFolder } = useFoldersSelectors();

  const { isLinesPreviewView } = useGetItemView();

  const isFolderPage = useCurrentPathStarstWith(ROUTES.FOLDERS);

  return {
    contextMenuFileCallback: (file: IFile): IPopoverData[] => [
      {
        name: __("Предоставленный доступ"),
        img: <GrantedAccessIcon width={13} height={13} />,
        callback: (): void => {
          onSetShareModal({
            open: true,
            actionType: ShareActionTypes.FILE_SHARE,
            folders: [],
            files: [file]
          });
        }
      },
      {
        name: __("Поделиться"),
        img: <ShareIcon width={13} height={13} />,
        callback: (): void => {
          onSetShareToMessengersModal({
            open: true,
            params: file
          });
        }
      },
      {
        name: __("Переместить"),
        img: <MoveIcon width={13} height={13} fill="#b1b1b1" />,
        isSystem: false,
        callback: (e: MouseEvent<HTMLButtonElement>): void => {
          e.stopPropagation();
          onSetMoveElementsModal({
            open: true,
            type: MOVE_ELEMENTS_TYPE.MOVE_FILE,
            elements: file,
            folders,
            actionsCallback: {
              onEditFiles,
              onSetUpdate: (updateFile: IFile) => {
                if (isLinesPreviewView && isFolderPage) {
                  onSetDelNextFiles([file]);
                  onSetAddNextFiles(updateFile);
                } else {
                  if (file.id_dir === currentFolder?.id_dir) {
                    onSetDelFiles({ ids: [file.fid] });
                  }

                  if (updateFile.id_dir === currentFolder?.id_dir) {
                    onSetAddFiles(updateFile);
                  }
                }
              }
            }
          });
        }
      },
      {
        name: __("Скопировать"),
        img: <CopyIcon width={13} height={13} fill="#b1b1b1" />,
        isSystem: false,
        callback: (e: MouseEvent<HTMLButtonElement>): void => {
          e.stopPropagation();
          onSetMoveElementsModal({
            open: true,
            type: MOVE_ELEMENTS_TYPE.COPY_FILE,
            elements: file,
            folders,
            actionsCallback: { onCopyFiles }
          });
        }
      },
      {
        name: __("Переименовать"),
        img: <EditIcon width={13} height={13} />,
        callback: (): void => {
          onSetCustomizeFileModal({
            open: true,
            params: file
          });
        }
      },
      {
        name: __("Избранное"),
        img: <HeartIcon width={13} height={13} fill={file.is_favorite ? "#0277BD" : "#b1b1b1"} />,
        callback: (): void => {
          const payload = {
            fids: [file.fid],
            is_favorite: file.is_favorite ? 0 : 1
          };
          onEditFiles(
            payload,
            file.is_favorite ? EVENT_TYPE.UNFAVORITE : EVENT_TYPE.ADD_FAVORITE,
            null,
            (updateFile: IFile) => {
              if (isLinesPreviewView && isFolderPage) {
                onSetUpdateNextFiles([updateFile]);
              } else {
                onSetUpdateFiles([updateFile]);
              }
            }
          );
        }
      },

      {
        name: __("Переместить в архив"),
        img: <MoveToArchiveIcon width={13} height={13} />,
        callback: (): void => {
          const payload = {
            fids: [file.fid],
            id_dir: file.id_dir,
            files: isFolderPage ? [file] : undefined
          };

          const params = {
            titleHead: __("Переместить в архив"),
            title: `${__("Вы действительно хотите переместить")} <span>${file.name}</span> в архив?`,
            text: `${__(`Вы сможете восстановить ${file.name} с архива`)}`,
            approveBtn: __("Переместить"),
            callback: (): void => {
              onMoveFileToArchive(payload, EVENT_TYPE.ARCHIVE);
            }
          };

          onSetApproveModal({
            open: true,
            params
          });
        }
      },
      {
        name: __("Сжать в Zip"),
        img: <CompressToZipIcon width={13} height={13} />,
        callback: (): void => {
          onSetCreateZipModal({
            open: true,
            params: [file]
          });
        }
      },
      {
        name: __("Свойства"),
        img: <InfoIcon width={13} height={13} />,
        callback: (): void => {
          onSetPropertiesModal({
            open: true,
            type: PropertiesTypes.FILE,
            params: file
          });
        }
      },
      {
        name: __("Скачать"),
        img: <UploadIcon width={13} height={13} />,
        callback: (): void => {
          fetchFileZipLink(file.fid, did);
        }
      },
      {
        name: undefined,
        component: <TagSelect onChange={onEditFilesLocal} item={file} />,
        callback: undefined
      },
      {
        name: undefined,
        component: <ColorPicker onColorClick={onEditFilesLocal} item={file} />,
        callback: undefined
      },
      {
        name: undefined,
        component: <EmojiPicker onEmojiClick={onEditFilesLocal} item={file} />,
        callback: undefined
      },
      {
        name: __("Напечатать файл"),
        img: <PrintIcon width={13} height={13} />,
        callback: (): void => {
          const messages = {
            error: __("Не удалось напечатать файл. Попробуйте еще раз!")
          };
          fetchPrintFileUrl(file, messages);
        }
      },
      {
        name: __("Переместить в сейф"),
        img: <SafeIcon width={15} height={15} fill="#B6B6B6" />,
        callback: (): void => {
          onSetMoveToSafeModal({ open: true, file });
        }
      },
      {
        name: __("Заменить"),
        img: <ReplaceIcon width={13} height={13} fill="#B1B1B1" />,
        callback: null,
        next: [
          {
            name: "Локальные файлы",
            callback: () => {
              onSetSelectedUploadReplacementFile(file);

              fileSelect("replace");
            }
          },
          {
            name: "Файлы с 4HUB",
            callback: () => {
              onSetSelectedUploadReplacementFile(file);

              onSetSelectFileModal({
                open: true,
                callback: (items: IFile[]) => {
                  onReplaceFiles({ file_from: items[0] });
                }
              });
            }
          }
        ]
      },
      {
        name: __("Удалить"),
        img: <TrashIcon width={13} height={13} />,
        callback: (): void => {
          const params = {
            titleHead: __("Удалить"),
            title: `${__("Вы действительно хотите удалить")} <span>${file.name}</span>?`,
            text: `${__(`Вы сможете восстановить ${file.name} с корзины`)}`,
            approveBtn: __("Удалить"),
            approveBtnVariant: ButtonVariantType.OK_RED,
            callback: (): void => {
              const payload = {
                fids: [file.fid],
                files: isFolderPage ? [file] : undefined
              };

              onDeleteFiles(payload);
            }
          };

          onSetApproveModal({
            open: true,
            params
          });
        }
      }
    ],

    contextMenuFileCallbackLinesView: (file: IFile): IPopoverData[] => {
      return [
        {
          name: __("Предоставленный доступ"),
          img: <GrantedAccessIcon width={13} height={13} />,
          callback: (): void => {
            onSetShareModal({
              open: true,
              actionType: ShareActionTypes.FILE_SHARE,
              folders: [],
              files: [file]
            });
          }
        },
        {
          name: __("Показать в исходной папке"),
          img: <ShowSourceFolderIcon width={13} height={13} />,
          callback: (): void => {
            navigate(`/folders/${file.id_dir}`);
          }
        },
        {
          name: __("Редактировать файл"),
          img: <EditIcon width={13} height={13} />,
          callback: (): void => {
            onSetCustomizeFileModal({
              open: true,
              params: file
            });
          }
        },
        {
          name: __("Сжать в Zip"),
          img: <CompressToZipIcon width={13} height={13} />,
          callback: (): void => {
            onSetCreateZipModal({
              open: true,
              params: [file]
            });
          }
        },
        {
          name: __("Переместить"),
          img: <MoveIcon width={13} height={13} fill="#b1b1b1" />,
          isSystem: false,
          callback: (e: MouseEvent<HTMLButtonElement>): void => {
            e.stopPropagation();
            onSetMoveElementsModal({
              open: true,
              type: MOVE_ELEMENTS_TYPE.MOVE_FILE,
              elements: file,
              folders,
              actionsCallback: {
                onEditFiles,
                onSetUpdate: (updateFile: IFile) => {
                  if (isLinesPreviewView && isFolderPage) {
                    onSetDelNextFiles([file]);
                    onSetAddNextFiles(updateFile);
                  } else {
                    if (file.id_dir === currentFolder.id_dir) {
                      onSetDelFiles({ ids: [file.fid] });
                    }
                  }
                }
              }
            });
          }
        },
        {
          name: __("Скопировать"),
          img: <CopyIcon width={13} height={13} fill="#b1b1b1" />,
          isSystem: false,
          callback: (e: MouseEvent<HTMLButtonElement>): void => {
            e.stopPropagation();
            onSetMoveElementsModal({
              open: true,
              type: MOVE_ELEMENTS_TYPE.COPY_FILE,
              elements: file,
              folders,
              actionsCallback: { onCopyFiles }
            });
          }
        },
        {
          name: __("Свойства"),
          img: <InfoIcon width={13} height={13} />,
          callback: (): void => {
            onSetPropertiesModal({
              open: true,
              type: PropertiesTypes.FILE,
              params: file
            });
          }
        },

        {
          name: undefined,
          component: <ColorPicker onColorClick={onEditFilesLocal} item={file} />,
          callback: undefined
        },
        {
          name: undefined,
          component: <EmojiPicker onEmojiClick={onEditFilesLocal} item={file} />,
          callback: undefined
        },
        {
          name: __("Переместить в сейф"),
          img: <SafeIcon width={13} height={13} />,
          callback: (): void => {
            onSetMoveToSafeModal({ open: true, file });
          }
        },
        {
          name: __("Скачать"),
          img: <UploadIcon width={13} height={13} />,
          callback: (): void => {
            fetchFileZipLink(file.fid);
          }
        }
      ];
    },
    contextMenuFileToMe: (file: IFile): IPopoverData[] => {
      return [
        {
          name: __("Скопировать ссылку"),
          img: <LinkIcon width={13} height={13} />,
          callback: (): void => {
            onSetCopyLinkModal({
              open: true,
              params: file.file_link
            });
          }
        },
        {
          // TODO -mk- not work
          name: __("Сжать в Zip"),
          img: <CompressToZipIcon width={13} height={13} />,
          callback: (): void => {
            onSetCreateZipModal({
              open: true,
              // type: CreateZipTypes.FILES,
              params: [file]
            });
          }
        },
        {
          name: __("Скачать"),
          img: <UploadIcon width={13} height={13} />,
          callback: (): void => {
            fetchFileZipLink(file.fid);
          }
        },

        {
          name: __("Свойства"),
          img: <InfoIcon width={13} height={13} />,
          callback: (): void => {
            onSetPropertiesModal({
              open: true,
              type: PropertiesTypes.FILE,
              params: file
            });
          }
        },
        {
          name: __("Напечатать файл"),
          img: <PrintIcon width={13} height={13} />,
          callback: (): void => {
            const messages = {
              error: __("Не удалось напечатать файл. Попробуйте еще раз!")
            };
            fetchPrintFileUrl(file, messages);
          }
        }
      ];
    },

    contextMenuRecentFile: (file: IFile): IPopoverData[] => {
      return [
        {
          name: __("Показать в исходной папке"),
          img: <ShowSourceFolderIcon width={13} height={13} />,
          callback: (): void => {
            navigate(`/folders/${file.id_dir}`);
          }
        }
      ];
    },
    contextMenuFileLinkFolder: (file: IFile): IPopoverData[] => {
      return [
        {
          name: __("Скачать"),
          img: <UploadIcon width={13} height={13} />,
          callback: (): void => {
            fetchFileZipLink(file.fid, did);
          }
        }
      ];
    }
  };
};
