import { IDrawSquareParams, IToolOptions } from "../../../../../../models/workElements/minitoolbar";
import { PaintTools } from "../../../../../../models/workElements/previews";
import Tool from "./Tool";

class Square extends Tool {
  constructor(canvas: HTMLCanvasElement, options: IToolOptions) {
    super(canvas, options);
    setTimeout(() => this.listen(), 0);
  }

  name = PaintTools.SQUARE;
  startX: number;
  startY: number;
  defaultImage = "";
  mouseDown = false;

  // draw params
  squareWidth: number;
  squareHeight: number;

  listen(): void {
    this.canvas.onmousemove = (e) => this.mouseMoveHandler.apply(this, [e as unknown as MouseEvent]);
    this.canvas.onmousedown = (e) => this.mouseDownHandler.apply(this, [e as unknown as MouseEvent]);
    this.canvas.onmouseup = this.mouseUpHandler.bind(this);
  }

  mouseUpHandler(): void {
    if (this.socket && this.startX && this.startY && this.squareWidth && this.squareHeight) {
      const params: IDrawSquareParams = {
        drawStartX: this.startX,
        drawStartY: this.startY,
        drawWidth: this.squareWidth,
        drawHeight: this.squareHeight
      };
      console.log(params);
      this.sendSocketPaint(params, this.name, String(this.ctx.strokeStyle));
    }
    this.mouseDown = false;
    this.startX = undefined;
    this.startY = undefined;
    this.squareWidth = undefined;
    this.squareHeight = undefined;
    this.defaultImage = "";
  }

  mouseDownHandler(e: MouseEvent): void {
    this.pushInDrawHistory(this.canvas.toDataURL());
    this.mouseDown = true;
    this.startX = e.offsetX;
    this.startY = e.offsetY;
    this.defaultImage = this.canvas.toDataURL();
  }

  mouseMoveHandler(e: MouseEvent): any {
    if (this.mouseDown) {
      const currentX = e.offsetX;
      const currentY = e.offsetY;
      this.squareWidth = currentX - this.startX;
      this.squareHeight = currentY - this.startY;
      this.draw(this.startX, this.startY, this.squareWidth, this.squareHeight);
    }
  }

  draw(x: number, y: number, width: number, height: number): void {
    const img = new Image();
    img.src = this.defaultImage;
    img.onload = () => {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);
      this.ctx.beginPath();
      this.ctx.rect(x, y, width, height);
      this.ctx.stroke();
    };
  }
}

export default Square;
