export const colors: string[] = [
  "#E53935",
  "#F57C00",
  "#FFD000",
  "#388E3C",
  "#039BE5",
  "#8E24AA",
  "#4D35BE",
  "#FB8C00",
  "#E55B35",
  "#F5AB00",
  "#FFE46B",
  "#388E87",
  "#036CE5",
  "#AA246E",
  "#7C35BE",
  "#F3B261"
];

export const emojis: string[] = [
  "&#128077;", // 👍 - U+1F44D
  "&#128514;", // 😂 - U+1F602
  "&#129505;", // ❤️ - U+2764
  "&#128561;", // 😱 - U+1F631
  "&#128545;", // 😡 - U+1F621
  "&#128522;", // 😊 - U+1F60A
  "&#128169;", // 💩 - U+1F4A9
  "&#129327;", // 🤯 - U+1F92F
  "&#128591;", // 🙏 - U+1F64F
  "&#129309;", // 🤝 - U+1F91D
  "&#128557;", // 😭 - U+1F62D
  "&#129300;", // 🤔 - U+1F914
  "&#128526;", // 😎 - U+1F60E
  "&#128588;", // 🙌 - U+1F64C
  "&#127881;", // 🎉 - U+1F389
  "&#129313;" // 🤡 - U+1F921
];
