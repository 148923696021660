import { TASK_VIEW } from "generalComponents/variables/tasks";
import React, { createContext, useState } from "react";

import { useProjectTasks } from "../../helpers";
import ServicePanel from "../ServicePanel/ServicePanel";
import styles from "./Tasks.module.sass";
import TasksGant from "./TasksGant/TasksGant";
import TasksKanban from "./TasksKanban/TasksKanban";
import TasksLine from "./TasksLine/TasksLine";

export const TasksProvider = createContext(null);

const Tasks = () => {
  const [tabSelect, setTabSelect] = useState(TASK_VIEW.KANBAN);
  const { tasksGroupByCategoryAll, tasksGroupByCategory } = useProjectTasks();
  const [isOpenAll, setIsOpenAll] = useState(false);

  const toggleOpen = () => setIsOpenAll((prev) => !prev);
  return (
    <TasksProvider.Provider value={{ isOpenAll, toggleOpen }}>
      <div className={styles.wrap}>
        <ServicePanel
          tabSelect={tabSelect}
          setTabSelect={setTabSelect}
          filtredTasks={tabSelect === TASK_VIEW.GANT ? tasksGroupByCategoryAll : tasksGroupByCategory}
        />
        {tabSelect === TASK_VIEW.KANBAN && <TasksKanban />}
        {tabSelect === TASK_VIEW.LINE && <TasksLine />}
        {tabSelect === TASK_VIEW.GANT && <TasksGant />}
      </div>
    </TasksProvider.Provider>
  );
};

export default Tasks;
