import classNames from "classnames";
import { SHARED_PARAMS, SHARED_TYPES } from "generalComponents/variables/routing";
import React from "react";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";

import styles from "./SharedOptions.module.sass";

const SharedOptions = () => {
  const { __ } = useLocales();
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get(SHARED_PARAMS.TYPE);

  return (
    <div className={styles.wrap}>
      <button
        className={classNames(styles.button, { [styles.active]: type === null })}
        onClick={() => setSearchParams()}
      >
        {__("Все")}
      </button>
      <button
        className={classNames(styles.button, { [styles.active]: type === SHARED_TYPES.TO_ME })}
        title={__("Доступ предоставленный мне")}
        onClick={() => setSearchParams({ type: SHARED_TYPES.TO_ME })}
      >
        {__("Для меня")}
      </button>
      <button
        className={classNames(styles.button, { [styles.active]: type === SHARED_TYPES.BY_ME })}
        title={__("Доступ предоставленный мной")}
        onClick={() => setSearchParams({ type: SHARED_TYPES.BY_ME })}
      >
        {__("Для других")}
      </button>
    </div>
  );
};

export default SharedOptions;
