import PropTypes from "prop-types";
import React from "react";
import { Line } from "react-chartjs-2";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  elements: {
    line: {
      tension: 0.3
    }
  },
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      display: false
    }
  }
};

const ChartTasks = ({ datas }) => {
  const data = {
    labels: datas.daysArray,
    datasets: [
      {
        data: datas.amountExtension,
        backgroundColor: "rgba(199, 39, 28, 0.1)",
        borderColor: "#C7271C",
        borderWidth: 1,
        pointRadius: 0.5,
        fill: true,
        borderJoinStyle: "bevel"
      },
      {
        data: datas.amountTasks,
        backgroundColor: "rgba(64, 134, 241, 0.1)",
        borderColor: "#4086F1",
        borderWidth: 1,
        pointRadius: 0.5,
        fill: true,
        borderJoinStyle: "bevel"
      }
    ]
  };

  return <Line data={data} options={options} />;
};

export default ChartTasks;
ChartTasks.propTypes = {
  datas: PropTypes.exact({
    daysArray: PropTypes.arrayOf(PropTypes.string),
    amountTasks: PropTypes.arrayOf(PropTypes.number),
    amountExtension: PropTypes.arrayOf(PropTypes.number)
  })
};
