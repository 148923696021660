import NavigationOption from "generalComponents/FilesServicePanel/NavigationOption/NavigationOption";
import SharedOptions from "generalComponents/FilesServicePanel/SharedOptions/SharedOptions";
import TempCreate from "generalComponents/FilesServicePanel/TempCreate/TempCreate";
import TempDownload from "generalComponents/FilesServicePanel/TempDownload/TempDownload";
import TempSize from "generalComponents/FilesServicePanel/TempSize/TempSize";
import TempSort from "generalComponents/FilesServicePanel/TempSort/TempSort";
import ViewTipeTabs from "generalComponents/FilesServicePanel/ViewTipeTabs/ViewTipeTabs";
// import FilterElements from "generalComponents/FilterElements/FilterElements";
import { FilterMarkers } from "generalComponents/FilterMarkers/FilterMarkers";
import { useIsShared } from "generalComponents/Services/folderServices/folderServices";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { ROUTES } from "generalComponents/variables/routing";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { IServePanel } from "models/servePanel";
import { useParams } from "react-router-dom";

import styles from "./FileServicePanel.module.sass";

const FileServicePanel: React.FC<IServePanel> = (): JSX.Element => {
  const { safeId } = useParams();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const { isShared } = useIsShared();

  return (
    <ToolsPanel size="large">
      <div className={styles.groupStart}>
        <NavigationOption />
        <ViewTipeTabs />
        <div className={styles.groupStart}>
          <TempSize />
          <TempSort />
          <FilterMarkers />
          {/* <FilterElements size={28} /> */}
        </div>
        {isShared && <SharedOptions />}
      </div>
      {(!isSafePage || (isSafePage && safeId)) && (
        <div className={styles.groupStart}>
          <TempDownload />
          <TempCreate />
        </div>
      )}
    </ToolsPanel>
  );
};

export default FileServicePanel;
