import { CircleProgress } from "generalComponents/CircleProgress/CircleProgress";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { useActions } from "hooks/useActions";
import { ILoadingFolders } from "models/store/files/uploadFilesModals/uploadFiles";
import { FC, useEffect } from "react";

import styles from "./LoadItemFolder.module.sass";

export interface ILoadItemProps {
  folder: ILoadingFolders;
}

const LoadItemFolder: FC<ILoadItemProps> = ({ folder }): JSX.Element => {
  const { onUploadFolderFiles } = useActions();

  const getAmountNotCompleted = (): number => folder.files.filter((el) => !el.done).length;

  const getAmountCompleted = (): number => folder.files.length - getAmountNotCompleted();

  const getPercent = (): number => (getAmountCompleted() / folder.files.length) * 100;

  useEffect(() => {
    const controller = new AbortController();
    onUploadFolderFiles({ id: folder.id }, controller);
    return () => controller.abort();
  }, []); // eslint-disable-line

  return (
    <li className={styles.item}>
      <ItemIcon isFolder={true} width={22} height={30} />
      <div className={styles.name}>{folder.name}</div>
      <span>
        {getAmountCompleted()}/{folder.files.length}
      </span>
      <CircleProgress
        className={styles.progress}
        done={folder?.files.every(({ done }) => done)}
        percent={getPercent()}
      />
    </li>
  );
};

export default LoadItemFolder;
