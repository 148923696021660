import { ReactComponent as RestoreIcon } from "assets/icons/restore.svg";
import { ReactComponent as GearIcon } from "assets/PrivateCabinet/gear.svg";
import { ReactComponent as StarIcon } from "assets/PrivateCabinet/star-3.svg";
import classNames from "classnames";
import { useMailsTabs, useMailsViewTabs } from "collections/mail/mail";
import Button from "generalComponents/Button/Button";
import CheckBoxNew from "generalComponents/CheckBoxNew/CheckBoxNew";
import Tabs from "generalComponents/Tabs/Tabs";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { LETTERS_LIMIT } from "generalComponents/variables/globalVariables";
import { POSTBOX_MODALS } from "generalComponents/variables/mail";
import { MAIL_PARAMS } from "generalComponents/variables/routing";
import { useFindPostbox } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import { IGetPostboxMailsPayload } from "models/store/postbox/postboxPayloads";
import React from "react";
import { useLocales } from "react-localized";
import { useParams, useSearchParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";

import MailSearch from "../MailSearch/MailSearch";
import styles from "./MailServicePanel.module.sass";

const MailServicePanel = () => {
  const { __ } = useLocales();
  const { folderName } = useParams();
  const [params, setParams] = useSearchParams();
  const isFavorite = params.get(`${[MAIL_PARAMS.FAVORITE]}`);

  const _isShedule = folderName === POSTBOX_SYSTEM_FOLDER.SCHEDULED;
  const _isSent = folderName === POSTBOX_SYSTEM_FOLDER.Sent;
  const _isDraft = folderName === POSTBOX_SYSTEM_FOLDER.Drafts;
  const { onSetPostboxModal, onMailNewMessageModal, onSelectAllLetters, onClearLetters, fetchPostboxMails } =
    useActions();
  const { postbox } = useFindPostbox();
  const { postboxes, letters, pickedLetters } = usePostboxSelectors();
  const unseen = params.get(MAIL_PARAMS.UNREAD);
  const favorite = params.get(MAIL_PARAMS.FAVORITE);

  const { mailTabs, isReadFilter } = useMailsTabs();
  const { mailView, viewsTabs } = useMailsViewTabs();

  const onClickForwardMessage = () => {
    onMailNewMessageModal({ open: true });
  };

  const clickSettingsHandler = () => {
    onSetPostboxModal({ open: true, type: POSTBOX_MODALS.SETTINGS });
  };

  const filterByFavouriteHandler = () => {
    if (isFavorite) {
      params.delete(`${[MAIL_PARAMS.FAVORITE]}`);
    } else {
      params.set(`${[MAIL_PARAMS.FAVORITE]}`, "true");
    }
    setParams(params);
  };

  const onSelectAll = () => {
    if (letters.length === 0) return;
    onSelectAllLetters();
  };

  const onUpdate = () => {
    onClearLetters();
    const payload: IGetPostboxMailsPayload = {
      user: postbox.email,
      pass: postbox.pass,
      folder: folderName,
      limit: letters.length > LETTERS_LIMIT ? letters.length : LETTERS_LIMIT,
      start: 0,
      isSchedule: folderName === POSTBOX_SYSTEM_FOLDER.SCHEDULED,
      is_favorite: favorite ? "1" : undefined,
      is_unseen: unseen ? "1" : undefined
    };
    fetchPostboxMails(payload);
  };

  return (
    <div>
      <ToolsPanel>
        <div className={styles.toolsLeft}>
          <div className={styles.selectAll}>
            <CheckBoxNew
              isChecked={letters.length > 0 && pickedLetters.length === letters.length}
              setIsChecked={onSelectAll}
              padding={"8px 8px 8px 0"}
            />
          </div>
          <div className={styles.favourite}>
            <Button
              isActive={false}
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              onClick={onUpdate}
              iconL={<RestoreIcon />}
            />
          </div>
          <div className={styles.favourite}>
            <Button
              isActive={false}
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              onClick={filterByFavouriteHandler}
              iconL={<StarIcon className={classNames({ [styles.red]: isFavorite })} />}
            />
          </div>
          {!_isDraft && !_isSent && !_isShedule && <Tabs tabs={mailTabs} value={isReadFilter} size="medium" />}
        </div>
        <div className={styles.toolsRight}>
          <MailSearch />
          <Tabs tabs={viewsTabs} value={mailView} />
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            onClick={clickSettingsHandler}
            size={ButtonSizeType.SMALL}
            iconL={<GearIcon fill="#274A42" />}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            onClick={onClickForwardMessage}
            size={ButtonSizeType.SMALL}
            text={`+ ${__("Письмо")}`}
            disabled={postboxes.length === 0}
          />
        </div>
      </ToolsPanel>
    </div>
  );
};

export default MailServicePanel;
