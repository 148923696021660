import { ReactComponent as CommentIcon } from "assets/PrivateCabinet/comm.svg";
import { ReactComponent as CopyIcon } from "assets/PrivateCabinet/copyLink.svg";
import { ReactComponent as FireIcon } from "assets/PrivateCabinet/fire.svg";
import { ReactComponent as ClipIcon } from "assets/PrivateCabinet/mail/clip.svg";
import classNames from "classnames";
import { format } from "date-fns";
import DownArrow from "generalComponents/DownArrow/DownArrow";
import { createMarkup } from "generalComponents/Services/browserServices";
import ThreeDots from "generalComponents/ThreeDots/ThreeDots";
import { URGENCY_TYPES } from "generalComponents/variables/tasks";
import PropTypes from "prop-types";
import React from "react";
import { useLocales } from "react-localized";
import { useSelector } from "react-redux";
import { colorCategoryProps, taskTypes } from "types/Project";

import { getChildrenTask, getDateColor, getParentTask } from "../../helpers";
import Executors from "../Executors/Executors";
import TaskNum from "../TaskNum/TaskNum";
import styles from "./TaskLine.module.sass";

const TaskLine = ({ task, changeStatus, color, setMouseParams, status, isMenu, handleDateLong, openDependency }) => {
  const { __ } = useLocales();
  const project = useSelector((s) => s.Projects.project);

  return (
    <div className={classNames([styles.taskWrap])}>
      <div className={styles.left}>
        <div className={styles.top}>
          <div className={styles.header}>
            <TaskNum num={task.num_epic} color={color?.dark} epicId={task.id_epic} />
            <h3 className={styles.name}>{task.name}</h3>
            <div className={styles.icons}>
              {task.id_act === URGENCY_TYPES.URGENT && <FireIcon className={styles.icon} />}
              {(getParentTask(project.tasks, task.id_parent) || getChildrenTask(project.tasks, task.id).length > 0) && (
                <CopyIcon className={styles.icon} width={14} height={14} onClick={openDependency} />
              )}
              {task.comments.length > 0 && <CommentIcon width={14} height={14} />}
              {task?.files_col > 0 && (
                <span className={styles.clip}>
                  <ClipIcon /> {task.files_col}
                </span>
              )}
            </div>

            {task.tags?.trim() && <p className={styles.role}>{task.tags}</p>}
          </div>
          {task.id_executor?.length > 0 && (
            <div className={classNames(styles.block, styles.user)}>
              <p className={styles.subTitle}>{__("Исполнитель :")}</p>
              <Executors executors={task.id_executor} />
            </div>
          )}
        </div>
        {getParentTask(project.tasks, task.id_parent) && (
          <div className={classNames(styles.block)}>
            <p className={styles.subTitle}>{__("Родительская задача:")}</p>
            <p className={styles.text}>{getParentTask(project.tasks, task.id_parent).name}</p>
          </div>
        )}
        {getChildrenTask(project.tasks, task.id).length > 0 && (
          <div className={classNames(styles.block)}>
            <p className={styles.subTitle}>{__("Дочерняя задача:")}</p>
            {getChildrenTask(project.tasks, task.id).map((item, i) => (
              <p key={i} className={styles.text}>
                {item.name}
              </p>
            ))}
          </div>
        )}
        {task.prim && (
          <div className={classNames(styles.block)}>
            <p className={styles.subTitle}>{__("Описание:")}</p>
            <div className={classNames("htmlContent", styles.text)} dangerouslySetInnerHTML={createMarkup(task.prim)} />
          </div>
        )}
      </div>

      <div className={styles.right}>
        <div>
          {status && (
            <div className={classNames(styles.block)}>
              <p className={styles.subTitle}>{__("Статус:")}</p>

              <div className={styles.statusBox}>
                <div className={styles.circle} style={{ background: color?.dark }} />
                <span className={styles.status}>{status}</span>
                {changeStatus && (
                  <DownArrow
                    isOpen={false}
                    clickHandler={(e) => {
                      e.stopPropagation();
                      setMouseParams({
                        x: e.clientX,
                        y: e.clientY,
                        width: 146,
                        height: 20,
                        type: "status"
                      });
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {task.date_start && task.date_end && (
            <div className={classNames(styles.block)}>
              <p className={styles.subTitle}>{__("Спринт:")}&nbsp;</p>
              <p className={styles.text} style={getDateColor(task)}>{`${format(
                new Date(task.date_start),
                "dd.MM.yyyy"
              )} - ${format(new Date(task.date_end), "dd.MM.yyyy")}`}</p>
            </div>
          )}
          {task.date_long && (
            <div className={classNames(styles.block, styles.cursor)} onClick={handleDateLong}>
              <p className={styles.subTitle}>{__("Продленно до:")}&nbsp;</p>
              <p className={styles.text}>{format(new Date(task.date_long), "dd.MM.yyyy")}</p>
            </div>
          )}
        </div>
        {isMenu && (
          <ThreeDots
            onClick={(e) => {
              e.stopPropagation();
              setMouseParams({
                x: e.clientX,
                y: e.clientY,
                width: 240,
                height: 25,
                type: "task"
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TaskLine;

TaskLine.defaultProps = {
  changeStatus: true,
  isMenu: true
};

TaskLine.propTypes = {
  task: taskTypes,
  changeStatus: PropTypes.bool,
  color: colorCategoryProps,
  setMouseParams: PropTypes.func,
  status: PropTypes.string,
  isMenu: PropTypes.bool,
  handleDateLong: PropTypes.func,
  openDependency: PropTypes.func
};
