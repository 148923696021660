import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import classNames from "classnames";
import Empty from "containers/Dashboard/Empty/Empty";
import Button from "generalComponents/Button/Button";
import DoughnutChart from "generalComponents/DoughnutChart/DoughnutChart";
import { useDefaultProjectStatuses } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IJoinProject, IProjectTeam } from "models/store/joinProjects/joinProgects";
import { ILayuotWidget } from "Pages/DashboardPage/DashboardPage";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectStatusStatistic.module.sass";

const JointProjectStatusStatistic = ({
  type,
  projectId,
  teamId,
  setBoard,
  i,
  handleConfirm
}: {
  type: string;
  projectId: string;
  teamId: string;
  setBoard?: React.Dispatch<React.SetStateAction<ILayuotWidget[]>>;
  i: string;
  handleConfirm: () => void;
}) => {
  const { __ } = useLocales();
  const { projectList } = useJoinProjectsSelectors();
  const [boardUpdated, setBoardUpdated] = useState(false);
  const [project, setProject] = useState<IJoinProject>();
  const [team, setTeam] = useState<IProjectTeam>();
  const defaultStatuses = useDefaultProjectStatuses();
  const { onSetDashboardJointProjectTeamModal } = useActions();

  useEffect(() => {
    const selectedProject = projectList.find((project) => project.id === projectId);
    if (selectedProject) {
      setProject(selectedProject);
      const selectedTeam = selectedProject.teams.find((team) => team.id === teamId);
      setTeam(selectedTeam);
    }
  }, [projectList, projectId, teamId]);

  const updateJointProjectIds = (projectId: string, teamId: string) => {
    setBoardUpdated(false); // Сбрасываем флаг перед обновлением состояния
    // Используйте функцию обновления состояния для setBoard
    setBoard((prev) => {
      // Ваш код обновления состояния
      const updatedBoard = prev.map((item) => {
        if (item.i === i) {
          return { ...item, projectId: projectId, teamId: teamId };
        }
        return item;
      });
      // Устанавливаем флаг после успешного обновления состояния
      setBoardUpdated(true);

      return updatedBoard;
    });
  };

  useEffect(() => {
    // Вызываем handleConfirm только если setBoard был вызван
    if (boardUpdated) {
      handleConfirm();
      setBoardUpdated(false);
    }
  }, [boardUpdated]); // eslint-disable-line

  const sortedStatuses = project?.statuses.filter((item) => item.is_visible).sort((a, b) => a.y - b.y);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{project && `${project.name} - ` + (team ? team.name : __("Все команды"))}</div>
        <Button
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.EXTRA_SMALL}
          isSquare
          iconL={<SettingsIcon />}
          onClick={() =>
            onSetDashboardJointProjectTeamModal({
              open: true,
              projectId,
              teamId,
              callBack: updateJointProjectIds
            })
          }
        />
      </div>
      {!sortedStatuses || sortedStatuses?.length === 0 ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={classNames(styles.content, styles[`content_${type}`])}>
          <div className={classNames(styles.list, "scrollbar-thin-blue")}>
            {sortedStatuses?.map((item) => (
              <DoughnutChart
                key={item.id}
                name={item.name ? item.name : defaultStatuses[item.system_name]}
                count={
                  team
                    ? project.active_tasks.filter(
                        (task) => task.id_status === item.id && team.users.includes(task.id_executor)
                      ).length
                    : project.active_tasks.filter((task) => task.id_status === item.id).length
                }
                total={
                  team
                    ? project.active_tasks.filter((task) => team.users.includes(task.id_executor)).length
                    : project.active_tasks.length
                }
                color={item.color}
                size={100}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default JointProjectStatusStatistic;
