import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { type DragEvent, useState } from "react";

type TDragEvent = (e: DragEvent<HTMLDivElement>) => void;

interface IUseDragDropUpload {
  isDrag: boolean;
  handleDrop: TDragEvent;
  dragStartHandler: TDragEvent;
  dragEndHandler: TDragEvent;
}

export const useDragDropUpload = (callBack: (files: { file?: File }[]) => void): IUseDragDropUpload => {
  const [isDrag, setIsDrag] = useState<boolean>(false);

  const { isLinesPreviewView } = useGetItemView();

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    if (isLinesPreviewView) return;

    const files = Array.from(e.dataTransfer.files).map((file) => ({
      file
    }));
    callBack(files);
    setIsDrag(false);
  };

  const dragStartHandler = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    if (isLinesPreviewView) return;
    setIsDrag(true);
  };

  const dragEndHandler = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDrag(false);
  };

  return {
    handleDrop,
    dragStartHandler,
    dragEndHandler,
    isDrag
  };
};
