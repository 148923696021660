import { PAGE_DEPTH } from "generalComponents/variables/global";

import { IFile } from "../files";

export interface IFileOptions {
  name: string;
  tags?: string[];
  color: string;
  emo: string;
  id_dir: string;
  ext?: string;
  path?: string;
  dep?: PAGE_DEPTH;
  chapter?: string;
}

export interface ISelectedFiles {
  file?: File;
}

export interface ILoadingFiles extends ISelectedFiles {
  options: IFileOptions;
  time: number;
  percent: number;
  id: string;
  done: boolean;
  create: boolean;
  replace: boolean;
  isOpen?: boolean;
}

export interface ILoadingFolders {
  name: string;
  id: string;
  files?: ILoadingFiles[];
}

export interface IInitialsUploadFiles {
  selectedFiles: ISelectedFiles[];
  loadingFiles: ILoadingFiles[];
  loadingFolders: ILoadingFolders[];
  replacementFile: IFile;
}

export function initialUploadFiles(): IInitialsUploadFiles {
  return {
    selectedFiles: [],
    loadingFiles: [],
    loadingFolders: [],
    replacementFile: null
  };
}
