import { ReactComponent as BacklogIcon } from "assets/PrivateCabinet/backlog.svg";
import { ReactComponent as BatteryIcon } from "assets/PrivateCabinet/battery.svg";
import { ReactComponent as ClockIcon } from "assets/PrivateCabinet/clock.svg";
import { ReactComponent as DocIcon } from "assets/PrivateCabinet/doc.svg";
import { ReactComponent as FileIcon } from "assets/PrivateCabinet/file-4.svg";
import { ReactComponent as GarbageIcon } from "assets/PrivateCabinet/garbage.svg";
import { ReactComponent as GraphIcon } from "assets/PrivateCabinet/graph.svg";
import { ReactComponent as LinkIcon } from "assets/PrivateCabinet/link.svg";
import { ReactComponent as MenIcon } from "assets/PrivateCabinet/men.svg";
import { ReactComponent as RestoreIcon } from "assets/PrivateCabinet/restore.svg";
import { ReactComponent as BagIcon } from "assets/PrivateCabinet/suitcase.svg";
import { PROJECT_CONTEXT_MENU, PROJECT_PATH } from "generalComponents/variables/project";
import { IContextProject, IProjectPath } from "models/generalComponents/collections/projects";
import React from "react";
import { useLocales } from "react-localized";

export const useProjectPath = (): IProjectPath[] => {
  const { __ } = useLocales();
  return [
    { path: PROJECT_PATH.TASKS, name: __("Задачи проекта"), icon: <BagIcon width={16} height={16} /> },
    { path: PROJECT_PATH.MY_TASKS, name: __("Мои задачи"), icon: <BatteryIcon width={16} height={16} /> },
    { path: PROJECT_PATH.BACKLOG, name: "Backlog", icon: <BacklogIcon /> },
    { path: PROJECT_PATH.TEAM, name: __("Команда"), icon: <MenIcon width={16} height={16} /> },
    { path: PROJECT_PATH.HISTORY, name: __("История задач"), icon: <ClockIcon width={14} height={14} /> },
    { path: PROJECT_PATH.FILES, name: __("Файлы"), icon: <FileIcon width={11} height={14} /> },
    { path: PROJECT_PATH.LINKS, name: __("Ссылки"), icon: <LinkIcon width={16} height={16} /> },
    { path: PROJECT_PATH.ANALYTICS, name: __("Аналитика"), icon: <GraphIcon width={16} height={16} /> },
    { path: PROJECT_PATH.DOC, name: __("Документация"), icon: <DocIcon /> },
    { path: PROJECT_PATH.TRASH, name: __("Корзина"), icon: <GarbageIcon width={13} height={14} /> }
  ];
};

export const useContextProject = (): IContextProject[] => {
  const { __ } = useLocales();
  return [
    { name: __("Редактировать проект"), img: "edit", type: PROJECT_CONTEXT_MENU.EDIT_PROJECT },
    { name: __("Удалить проект"), img: "garbage", type: PROJECT_CONTEXT_MENU.DEL_PROJECT }
  ];
};

export const useContextProjectCategory = (): IContextProject[] => {
  const { __ } = useLocales();
  return [
    { name: __("Редактировать"), img: "edit", type: PROJECT_CONTEXT_MENU.EDIT_CATEGORY },
    { name: __("Удалить"), img: "garbage", type: PROJECT_CONTEXT_MENU.DELETE_CATEGORY }
  ];
};

export const useContextTrashTask = () => {
  const { __ } = useLocales();
  return [
    {
      name: __("Восстановить"),
      img: <RestoreIcon />,
      type: PROJECT_CONTEXT_MENU.RESTORE_TASK
    },
    {
      name: `${__("Преместить в ")}backlog`,
      img: <BacklogIcon width={20} height={20} viewBox="-2 -2 20 20" />,
      type: PROJECT_CONTEXT_MENU.MOVE_TO_BACKLOG
    },
    {
      name: __("Удалить задачу"),
      img: <GarbageIcon className="contextIcon" viewBox="0 0 22 22" width={20} height={20} />,
      type: PROJECT_CONTEXT_MENU.DEL_TASK
    }
  ];
};
