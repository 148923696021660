import JointProjectAnalytics from "containers/JoinProject/JointProjectAnalytics/JointProjectAnalytics";
import ParticipantsButtons from "containers/JoinProject/JointProjectsTasks/components/ParticipantsButtons/ParticipantsButtons";
import TeamButtons from "containers/JoinProject/JointProjectsTasks/components/TeamButtons/TeamButtons";
import SelectAnalyticsSprint from "containers/JoinProject/SelectAnalyticsSprint/SelectAnalyticsSprint";
import { WrapperPage } from "containers/JoinProject/WrapperPage/WrapperPage";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { ROUTES } from "generalComponents/variables/routing";
import { useProjectMainAccess } from "hooks/joinProjectHooks";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { Navigate } from "react-router";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./ProjectAnalyticsPage.module.sass";

const ProjectAnalyticsPage = () => {
  const { team, participants } = useAllSelectedSearchParams();

  const isMainAccess = useProjectMainAccess({ or: false });

  const { project } = useJoinProjectsSelectors();

  if (!isMainAccess && !project.accesses.ANALITYCS) return <Navigate to={`/${ROUTES.PROJECT}`} replace />;

  return (
    <WrapperPage>
      <ToolsPanel size="extraLarge">
        <div className={styles.wrapper}>
          <div className={styles.tools}>
            <SelectAnalyticsSprint />
            <div className={styles.delimetr} />
            <TeamButtons selectedTeam={team.selectedValue} onSelectTeam={team.onSelectValue} />
          </div>
          <ParticipantsButtons
            selectedParticipants={participants.selectedValue}
            onSelectParticipiant={participants.onSelectMultipleValue}
            teamId={team.selectedValue}
          />
        </div>
      </ToolsPanel>
      <JointProjectAnalytics />
    </WrapperPage>
  );
};

export default ProjectAnalyticsPage;
