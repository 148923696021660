import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useActions } from "hooks/useActions";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFileAccessInfoDTO } from "models/store/files/filesPayloads";
import { IAccessInfo } from "models/store/folders/foldersPayloads";
import { useUserSelectors } from "Store/selectors/userSelectors";

export const useGetFileAccessInfo = () => {
  const { onSetTopMessageModal } = useActions();
  const { uid } = useUserSelectors();

  const onGetFileAccessInfo = async (
    fid: string,
    setData: (el: IAccessInfo[]) => void,
    setLoading: (el: boolean) => void
  ) => {
    try {
      const params = { uid, fid };
      setLoading(true);
      const { data } = await api.get<IFileAccessInfoDTO>("/ajax/file_share_list.php", { params });
      checkResponseStatus(data.ok);
      setData(data.access);
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    } finally {
      setLoading(false);
    }
  };

  return {
    onGetFileAccessInfo
  };
};
