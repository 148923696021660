import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as StarIcon } from "assets/icons/star-2.svg";
import { ReactComponent as FillStarIcon } from "assets/icons/star-fill.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Button from "generalComponents/Button/Button";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IContact } from "models/store/user/user";
import { useLocales } from "react-localized";

import styles from "./ProfileControl.module.sass";

const ProfileControl = ({ data }: { data: IContact }) => {
  const { __ } = useLocales();
  const { onSetApproveModal, deleteContact, addToFavoriteContact, onSetContactsModal, onSetTopMessageModal } =
    useActions();

  const { formatDate } = useDateFormat();

  const addToFavourite = () => {
    addToFavoriteContact({ id: data.id, is_fav: data.is_fav });
  };

  const onDeleteConfirm = () => {
    deleteContact({ id: data.id });
  };

  const edit = () => {
    onSetContactsModal({ open: true, params: data });
  };

  const formatArrayToClipboard = (name: string, array: string[]) => {
    let formattedItem = array.map(function (item, index) {
      return name + (index + 1) + " " + item;
    });
    return formattedItem;
  };

  function formatSocialToClipboard(array: IContact["soc"] | IContact["mes"]) {
    var formattedSocial = array.map(function (item) {
      return item.type + " " + item.link;
    });
    return formattedSocial;
  }

  const copyData = () => {
    if (!data) return;

    let formattedData = [];

    if (data.name || data.sname) {
      formattedData.push(data.name + " " + data.sname);
    }

    if (data.company) {
      formattedData.push(__("Компания") + ": " + data.company);
    }

    if (data.bdate) {
      formattedData.push(__("День рождения") + ": " + formatDate(data.bdate, "date"));
      formattedData.push(__("Заметка") + ": " + formatDate(data.bdate, "date"));
    }

    if (data.tel && data.tel.length > 0) {
      formattedData.push(...formatArrayToClipboard(__("Телефон"), data.tel));
    }

    if (data.email && data.email.length > 0) {
      formattedData.push(...formatArrayToClipboard(__("Email"), data.email));
    }

    if (data.soc && data.soc.length > 0) {
      formattedData.push(__("Социальные сети"));
      formattedData.push(formatSocialToClipboard(data.soc));
    }

    if (data.mes && data.mes.length > 0) {
      formattedData.push(__("Мессенджеры"));
      formattedData.push(formatSocialToClipboard(data.mes));
    }

    let clipboardData = formattedData.join("\n");
    navigator.clipboard.writeText(clipboardData);
    onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: __("Скопировано в буфер обмена") });
  };

  return (
    <div className={styles.control}>
      <Button
        variant={ButtonVariantType.OPACITY}
        size={ButtonSizeType.MEDIUM}
        isSquare={true}
        iconL={data.is_fav ? <FillStarIcon /> : <StarIcon />}
        onClick={addToFavourite}
      />
      <Button
        variant={ButtonVariantType.OPACITY}
        size={ButtonSizeType.MEDIUM}
        isSquare={true}
        iconL={<EditIcon />}
        onClick={edit}
      />
      <Button
        variant={ButtonVariantType.OPACITY}
        size={ButtonSizeType.MEDIUM}
        isSquare={true}
        iconL={<CopyIcon />}
        onClick={copyData}
      />
      <Button
        variant={ButtonVariantType.OPACITY}
        size={ButtonSizeType.MEDIUM}
        isSquare={true}
        iconL={<TrashIcon />}
        onClick={() =>
          onSetApproveModal({
            open: true,
            params: {
              title: __("Удалить контакт"),
              text: __("Вы действительно хотите удалить контакт?"),
              approveBtn: __("Удалить?"),
              callback: (): void => {
                onDeleteConfirm();
              }
            }
          })
        }
      />
    </div>
  );
};

export default ProfileControl;
