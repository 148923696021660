export enum SAFE_MODALS {
  NO_MODAL = "",
  ADD_SAFE = "ADD_SAFE",
  ADD_FIRST_SAFE = "ADD_FIRST_SAFE",
  RENAME_SAFE = "RENAME_SAFE",
  ENTER_SAFE_PASSWORD = "ENTER_SAFE_PASSWORD",
  ENTER_SAFE_CODE = "ENTER_SAFE_CODE",
  SAFE_BLOKED = "SAFE_BLOKED",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  PROPERTIES = "PROPERTIES",
  CREATE_FOLDER = "CREATE_FOLDER",
  RENAME_FOLDER = "RENAME_FOLDER",
  SHARED_SAFE = "SHARED_SAFE"
}

export enum SAFE_CALLBACK_MODAL {
  DELETE = "DELETE",
  FETCH_FILES = "FETCH_FILES",
  MOVE_TO_SAFE = "MOVE_TO_SAFE"
}
