import { ReactComponent as DefaultUserIcon } from "assets/PrivateCabinet/default-user-icon.svg";
import classNames from "classnames";
import { IAccessTabInfoProps } from "models/modals/propertiesModal/components/accessTabInfo";
import { IAccessInfo } from "models/store/folders/foldersPayloads";
import React from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./AccessTabInfo.module.sass";

export const AccessTabInfo: React.FC<IAccessTabInfoProps> = ({
  _isFolder,
  usersFileAccess,
  folderAccessData,
  isUsersFileAccessLoading,
  isFolderAccessDataLoading
}): JSX.Element => {
  const { __ } = useLocales();
  const {
    userInfo: { theme }
  } = useUserSelectors();

  const fileViewAccess: IAccessInfo[] = usersFileAccess.filter((item) => item.is_read === 1);
  const fileEditAccess: IAccessInfo[] = usersFileAccess.filter((item) => item.is_read === 0);
  const folderViewAccess: IAccessInfo[] = folderAccessData.filter((item) => item.is_read === 1);
  const folderEditAccess: IAccessInfo[] = folderAccessData.filter((item) => item.is_read === 0);

  const renderList = (accessArray: IAccessInfo[]): JSX.Element => {
    return (
      <ul className={classNames(styles.list, `scrollbar-thin-${theme}`)}>
        {accessArray.map((item) => (
          <li key={item.id_user} className={styles.item}>
            <DefaultUserIcon className={styles.avatar} />
            <p className={styles.email}>{item.user_name}</p>
          </li>
        ))}
      </ul>
    );
  };

  const renderUsersList = (isPreview: boolean): JSX.Element => {
    if (_isFolder) {
      if (isFolderAccessDataLoading) return <p className={styles.infoText}>{__("Загружается...")}</p>;

      if (isPreview && folderViewAccess.length !== 0) {
        return renderList(folderViewAccess);
      }

      if (folderEditAccess.length !== 0) {
        return renderList(folderEditAccess);
      }

      return <p className={styles.infoText}>{__("Список пуст.")}</p>;
    } else {
      if (isUsersFileAccessLoading) return <p className={styles.infoText}>{__("Загружается...")}</p>;

      if (isPreview && fileViewAccess.length !== 0) {
        return renderList(fileViewAccess);
      }

      if (fileEditAccess.length !== 0) {
        return renderList(fileEditAccess);
      }

      return <p className={styles.infoText}>{__("Список пуст.")}</p>;
    }
  };

  const renderContent = (isPreview: boolean): JSX.Element => {
    return (
      <div className={styles.infoWrapper}>
        <div className={styles.title}>
          {__("Список пользователей с правом доступа на")}{" "}
          <span>“{isPreview ? __("Просмотр") : __("Редактирование")}”:</span>
        </div>

        {renderUsersList(isPreview)}
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {renderContent(true)}
      {renderContent(false)}
    </div>
  );
};
