import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { useMenuFilterLibraryRubrics } from "collections/library";
import { useMenuFilterLibraryCategories } from "collections/library";
import Button from "generalComponents/Button/Button";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { TABS } from "generalComponents/variables/library";
import { LIBRARY_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPath } from "hooks/useCurrentPath";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IServePanel } from "models/servePanel";
import { useCallback } from "react";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLibrarySelectors } from "Store/selectors/librarySelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { LibrarySearch } from "./components/LibrarySearch/LibrarySearch";
import { LibrarySettingsTabs } from "./components/LibrarySettingsTabs/LibrarySettingsTabs";
import { NavigationLibraryOption } from "./components/NavigationLibraryOption/NavigationLibraryOption";
import styles from "./LibraryServicePanel.module.sass";

export const LibraryServicePanel: FC<IServePanel> = (): JSX.Element => {
  const { onSetCreateObjectLibraryModal, onSetCreateRubricLibraryModal, fetchFolderZipLink } = useActions();
  const {
    objSearchParams: { tab, chapter, id_dep, year, month },
    onSetObjSearchParams
  } = useSelectedObjSearchParams();
  const { folderInfo } = useLibrarySelectors();
  const { rights } = useUserSelectors();

  const { pathname } = useLocation();

  const isLibrary = useCurrentPath(ROUTES.LIBRARY);
  const isLibrarySearch = useCurrentPath(`/${ROUTES.LIBRARY}/${LIBRARY_ROUTES.SEARCH}`);
  const isLibrarySetttings = useCurrentPath(`/${ROUTES.LIBRARY}/${LIBRARY_ROUTES.SETTINGS}`);
  const isLibraryCategoriesPage = useCurrentPath(`${ROUTES.LIBRARY}/${LIBRARY_ROUTES.CATEGORIES}`);
  const isLibraryRubricsPage = useCurrentPath(`${ROUTES.LIBRARY}/${LIBRARY_ROUTES.RUBRICS}`);

  const navigate = useNavigate();
  const { libraryDirId } = useParams();

  const { __ } = useLocales();

  const onChangeDateFilter = (v: Date): void => {
    const y = v ? v.getFullYear().toString() : undefined;
    const m = v ? (v.getMonth() + 1).toString() : undefined;
    onSetObjSearchParams({
      [SEARCH_PARAMS.YEAR]: y ?? "",
      [SEARCH_PARAMS.MONTH]: m ?? ""
    });
  };

  const renderSelectedDate = (): Date => {
    if (year && month) {
      return new Date(Number(year), Number(month) - 1, 1);
    }
    return null;
  };

  const optionsFilterLibraryRubrics = useMenuFilterLibraryRubrics();
  const optionsFilterLibraryCategories = useMenuFilterLibraryCategories();

  const selectedDefaultRubric = optionsFilterLibraryRubrics.find(({ value }) => value === id_dep);
  const selectedDefaultCategory = optionsFilterLibraryCategories.find(({ value }) => value === chapter);

  const onVisibleCreateObjectLibraryModal = () => {
    onSetCreateObjectLibraryModal({ open: true, type: "addLibrary" });
  };

  const onVisibleCreateRubrictLibraryModal = () => {
    onSetCreateRubricLibraryModal({ open: true });
  };

  const downloadFolder = useCallback(() => {
    fetchFolderZipLink(folderInfo?.id_dir, __("Не удалось скачать папку. Попробуйте еще раз"));
  }, [folderInfo?.id_dir]); //eslint-disable-line

  return (
    <ToolsPanel size="large">
      <div className={styles.toolsLeft}>
        <NavigationLibraryOption
          isLibrary={isLibrary}
          isLibrarySearch={isLibrarySearch}
          isLibrarySetttings={isLibrarySetttings}
          libraryCategory={chapter}
          libraryRubricId={id_dep}
          libraryDirId={libraryDirId}
        />
        {!isLibrary && (
          <>
            {isLibrarySetttings && <LibrarySettingsTabs />}
            {(isLibraryCategoriesPage || isLibrarySearch || (isLibrarySetttings && tab === TABS.OBJECTS)) && (
              <SelectCustom
                width={200}
                variantHeight="small"
                options={optionsFilterLibraryRubrics}
                selectedDefault={{
                  name: selectedDefaultRubric?.name || optionsFilterLibraryRubrics[0].name,
                  value: selectedDefaultRubric?.value || optionsFilterLibraryRubrics[0]?.value
                }}
              />
            )}
            {(isLibrarySearch || isLibraryRubricsPage || (isLibrarySetttings && tab === TABS.OBJECTS)) && (
              <SelectCustom
                width={200}
                variantHeight="small"
                options={optionsFilterLibraryCategories}
                selectedDefault={{
                  name: selectedDefaultCategory?.name || optionsFilterLibraryCategories[0]?.name,
                  value: selectedDefaultCategory?.value || optionsFilterLibraryCategories[0]?.value
                }}
              />
            )}
            {!libraryDirId && (
              <InputDate
                startDate={renderSelectedDate()}
                setStartDate={(v: Date) => onChangeDateFilter(v)}
                dateFormat="MMM yy"
                isClearable
                maxDate={new Date()}
                showMonthYearPicker
              />
            )}
          </>
        )}
      </div>
      <div className={styles.toolsRight}>
        {!isLibrarySearch && !libraryDirId && !isLibrarySetttings && (
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            onClick={() =>
              navigate(`/${ROUTES.LIBRARY}/${LIBRARY_ROUTES.SEARCH}`, {
                state: { from: pathname.slice(1) || ROUTES.LIBRARY }
              })
            }
            size={ButtonSizeType.SMALL}
            iconL={<SearchIcon fill="#274A42" />}
          />
        )}
        {isLibrarySearch && <LibrarySearch />}
        {isLibrary && rights.IS_LIBRARY && (
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            onClick={() => {
              const path = `/${ROUTES.LIBRARY}/${LIBRARY_ROUTES.SETTINGS}?${[SEARCH_PARAMS.TAB]}=${TABS.OBJECTS}`;

              navigate(path, { state: { from: ROUTES.LIBRARY } });
            }}
            size={ButtonSizeType.SMALL}
            iconL={<SettingsIcon fill="#274A42" />}
          />
        )}
        {(isLibrary || (isLibrarySetttings && tab === TABS.OBJECTS)) && (
          <Button
            variant={ButtonVariantType.BLUE}
            onClick={onVisibleCreateObjectLibraryModal}
            size={ButtonSizeType.SMALL}
            text={`+ ${__("Создать")}`}
          />
        )}
        {isLibrarySetttings && tab === TABS.RUBRICS && (
          <Button
            variant={ButtonVariantType.BLUE}
            onClick={onVisibleCreateRubrictLibraryModal}
            size={ButtonSizeType.SMALL}
            text={`+ ${__("Добавить рубрику")}`}
          />
        )}
        {libraryDirId && libraryDirId === folderInfo?.id_dir && (
          <Button
            variant={ButtonVariantType.BLUE}
            onClick={downloadFolder}
            size={ButtonSizeType.SMALL}
            text={`${__("Скачать все")}`}
            iconL={<UploadIcon width={16} height={16} />}
          />
        )}
      </div>
    </ToolsPanel>
  );
};
