import classNames from "classnames";
import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { FC } from "react";
import { useLocales } from "react-localized";

import styles from "./Switcher.module.sass";

interface IProps {
  checked: boolean;
  onClickCheckBox: () => void;
}
const Switcher: FC<IProps> = ({ checked, onClickCheckBox }) => {
  const { __ } = useLocales();
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClickCheckBox}
      className={classNames(styles.switcher, {
        [styles.checked]: checked
      })}
    >
      <CheckBox
        variant={checked ? CheckBoxVariantTypes.CHECKED : CheckBoxVariantTypes.DEFAULT}
        width={20}
        height={20}
        increaseArea
        className={styles.switcher_checkbox}
      />
      <span className={styles.switcher_label}>{checked ? __("Вкл") : __("Выкл")}</span>
    </div>
  );
};

export default Switcher;
