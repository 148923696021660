import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/create_arrow.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/plus-3.svg";
import classNames from "classnames";
import { endOfDay, getTime } from "date-fns";
import { useContextProjectCategory } from "generalComponents/collections/projects";
import ContextMenu from "generalComponents/ContextMenu";
import ContextMenuItem from "generalComponents/ContextMenu/ContextMenuItem";
import ThreeDots from "generalComponents/ThreeDots/ThreeDots";
import { MODALS } from "generalComponents/variables/global";
import { imageSrc } from "generalComponents/variables/globalVariables";
import { PROJECT_CATEGORIES, PROJECT_CONTEXT_MENU, PROJECT_MODALS } from "generalComponents/variables/project";
import { useActions } from "hooks/useActions";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onChangeStatusTask } from "Store/actions/ProjectActions";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";
import { ProjectTypes } from "Store/types/projectTypes";
import { projectCategoryProps, taskTypes } from "types/Project";

import { onSetModals } from "../../../../../../../Store/actions/ModalActions";
import InfoBlock from "../../../Components/InfoBlock/InfoBlock";
import ProjectTask from "../../../Components/ProjectTask/ProjectTask";
import { useProjectMsg, useProjectTasks } from "../../../helpers";
import RolesTasks from "../RolesTasks/RolesTasks";
import styles from "./Category.module.sass";

const Category = ({ category, currentTask, setCurrentTask, setCurrentCategory, variant }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const MSG = useProjectMsg();
  const CONTEXT_MENU = useContextProjectCategory();
  const { project, selectUser } = useProjectsSelectors();
  const [mouseParams, setMouseParams] = useState(null);
  const [taskDragEnter, setTaskDragEnter] = useState(0);
  const [boardDragEnter, setBoardDragEnter] = useState(0);
  const { tasksGroupByCategory, tasksGroupByCategoryRoles } = useProjectTasks();
  const { onDeleteCategory } = useActions();

  const onAddTask = () => dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.ADD_TASK, params: {} }));

  const onClickDots = (e) => {
    setMouseParams({
      x: e.clientX,
      y: e.clientY,
      width: 200,
      height: 25
    });
  };

  const closeContextMenu = () => {
    setMouseParams(null);
  };

  const callbacks = {
    [PROJECT_CONTEXT_MENU.EDIT_CATEGORY]: () =>
      // TODO - mk - delete after all store is typed
      // @ts-ignore
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.EDIT_CATEGORY, params: category })),

    [PROJECT_CONTEXT_MENU.DELETE_CATEGORY]: () => {
      const tasks = project.tasks?.filter((t) => t.id_category === category.id);
      const tasksIds = tasks.length > 0 ? tasks.map((t) => t.id) : [];
      const params = {
        title: __("Удалить категорию"),
        text:
          tasks.length > 0
            ? `${__("В категории")} "${category.name}" ${__("есть задачи.")} ${__("Они будут перемещены в корзину")}`
            : `${__("Категория")} "${category.name}" ${__("будет удалена")}`,
        callback: () => onDeleteCategory({ id_tasks: tasksIds, id_category: category.id }, MSG.ERROR),
        approveBtn: __("Удалить")
      };
      // TODO - mk - delete after all store is typed
      // @ts-ignore
      dispatch(onSetModals(MODALS.APPROVE, { open: true, params }));
    }
  };

  const dragStartHandler = (task) => {
    setCurrentTask(task);
  };

  const dragEnterHandler = (task) => {
    // stop the request to the server if you release the task on yourself
    if (task.id === currentTask.id) {
      return;
    }
    setTaskDragEnter(task.id);
    setBoardDragEnter(0);
  };

  const dragEndHandler = () => {
    setCurrentTask(null);
  };

  const dragLeaveHandler = () => {
    setTaskDragEnter(0);
  };

  const dropHandler = (e, task) => {
    e.stopPropagation();

    setTaskDragEnter(0);
    setBoardDragEnter(0);

    // stop the request to the server if you release the task on yourself
    if (task.id === currentTask.id) {
      return;
    }

    const endDay = getTime(endOfDay(new Date(currentTask.date_end)));
    const now = getTime(new Date());
    if (
      currentTask.id_category === PROJECT_CATEGORIES.NOT_IMPLEMENTED &&
      task.id_category !== PROJECT_CATEGORIES.DONE &&
      endDay < now
    ) {
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.EXTEND_PERIOD,
          params: { task: { ...currentTask, id_category: task.id_category }, isOpen: false }
        })
      );
      return;
    }

    // change local redux store
    const tasks = [...project.tasks];
    const sort = tasks.find((el) => el.id === task.id).sort - 1;
    const indexCurrent = tasks.findIndex((t) => t.id === currentTask.id);
    const newTask = { ...currentTask, sort: sort, id_category: task.id_category };
    tasks.splice(indexCurrent, 1, newTask);

    dispatch({
      type: ProjectTypes.UPDATE_TASKS,
      payload: tasks
    });

    // server request
    const payload = {
      name: currentTask.name,
      id: currentTask.id,
      id_category: task.id_category,
      sort: task.sort - 1,
      id_act: currentTask.id_act
    };
    setCurrentTask(null);
    setCurrentCategory(null);
    dispatch(onChangeStatusTask(payload, MSG.ERROR));
  };

  const handelColumnDrop = (id) => {
    setBoardDragEnter(0);
    const endDay = getTime(endOfDay(new Date(currentTask.date_long ? currentTask.date_long : currentTask.date_end)));
    const now = getTime(new Date());
    if (
      currentTask.id_category === PROJECT_CATEGORIES.NOT_IMPLEMENTED &&
      id !== PROJECT_CATEGORIES.DONE &&
      endDay < now
    ) {
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.EXTEND_PERIOD,
          params: { task: { ...currentTask, id_category: id }, isOpen: false }
        })
      );
      return;
    }
    // change local redux store
    const tasks = [...project.tasks];
    const indexCurrent = tasks.findIndex((t) => t.id === currentTask.id);
    const sort = tasksGroupByCategory[id].length * 10 + 1;
    const newTask = { ...currentTask, sort, id_category: id };
    tasks.splice(indexCurrent, 1, newTask);
    dispatch({
      type: ProjectTypes.UPDATE_TASKS,
      payload: tasks
    });

    // server request
    const payload = {
      name: currentTask.name,
      id: currentTask.id,
      sort: sort,
      id_category: id,
      id_act: currentTask.id_act
    };
    dispatch(onChangeStatusTask(payload, MSG.ERROR));

    setCurrentTask(null);
    setCurrentCategory(null);
  };

  const handelColumnDragEnter = (id) => {
    setBoardDragEnter(id);
  };

  const handelColumnDragLeave = () => {
    setBoardDragEnter(0);
  };

  return (
    <div
      className={classNames(styles.category, {
        [styles.categoryRow]: variant === "row"
      })}
    >
      <div className={styles.title}>
        <div className={styles.categoryColor} style={{ background: category.color?.dark }} />
        <span className={styles.name}>{category.name}</span>
        {project.is_read === "1" && category.id_user !== "0" && (
          <ThreeDots onClick={(e) => onClickDots(e)} horizontal />
        )}
        {mouseParams && (
          <ContextMenu params={mouseParams} setParams={closeContextMenu} tooltip={true}>
            <div className={styles.mainMenuItems}>
              {CONTEXT_MENU.map((item, i) => (
                <ContextMenuItem
                  key={i}
                  width={mouseParams.width}
                  height={mouseParams.height}
                  text={item.name}
                  callback={callbacks[item.type]}
                  imageSrc={`${imageSrc}assets/PrivateCabinet/contextMenuFile/${item.img}.svg`}
                />
              ))}
            </div>
          </ContextMenu>
        )}
      </div>

      <div
        className={classNames(styles.list, {
          [styles.noScroll]: project.tasks?.length === 0,
          [styles.dragBoard]: boardDragEnter === category.id
        })}
        onDrop={() => handelColumnDrop(category.id)}
        onDragEnter={() => handelColumnDragEnter(category.id)}
        onDragLeave={handelColumnDragLeave}
      >
        {project.tasks?.length > 0 ? (
          <>
            {selectUser === "0" ? (
              <>
                {Object.entries(tasksGroupByCategoryRoles[category.id]).map(
                  ([name, group]) =>
                    name !== "0" && (
                      <RolesTasks key={name} name={name} group={group} color={category.color} variant={variant} />
                    )
                )}
                {Object.entries(tasksGroupByCategoryRoles[category.id]).map(
                  ([name, group]) =>
                    name === "0" && (
                      <RolesTasks key={name} name={name} group={group} color={category.color} variant={variant} />
                    )
                )}
              </>
            ) : (
              <>
                {tasksGroupByCategory[category.id]?.map((task) => (
                  <div
                    key={task.id}
                    className={classNames(styles.taskWrap, {
                      [styles.dragStart]: currentTask,
                      [styles.dragEnter]: taskDragEnter === task.id,
                      [styles.opacity]: currentTask?.id === task.id
                    })}
                    draggable={true}
                    onDragStart={() => dragStartHandler(task)}
                    onDragOver={() => dragEnterHandler(task)}
                    onDragEnd={dragEndHandler}
                    onDragLeave={dragLeaveHandler}
                    onDrop={(e) => dropHandler(e, task)}
                  >
                    <ProjectTask task={task} color={category.color} variant={variant} />
                  </div>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            {category.id === PROJECT_CATEGORIES.PENDING && (
              <div className={classNames(styles.noTask, { [styles.noTaskRow]: variant === "row" })} onClick={onAddTask}>
                <PlusIcon />
                <ArrowIcon className={classNames(styles.createArrow, { [styles.createArrowRow]: variant === "row" })} />
                <div className={classNames(styles.info, { [styles.infoRow]: variant === "row" })}>
                  <InfoBlock
                    title={__("Создать Задачу")}
                    text={__(
                      "Создайте задачу,Lorem Ipsum - это текст-'рыба', часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной 'рыбой' для текстов на латинице с начала XVI века."
                    )}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Category;

Category.propTypes = {
  category: projectCategoryProps,
  currentTask: taskTypes,
  setCurrentTask: PropTypes.func,
  setCurrentCategory: PropTypes.func,
  variant: PropTypes.oneOf(["column", "row"])
};
