import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import Button from "generalComponents/Button/Button";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialAllFileViewModalState } from "models/store/Cabinet/modals/allFileViewModal";
import { IFile } from "models/store/files/files";
import { useCallback, useState } from "react";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useLibrarySelectors } from "Store/selectors/librarySelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./AllFileViewModal.module.sass";
import { SliderFiles } from "./components/SliderFiles/SliderFiles";

interface ICurrentFile {
  name: string;
  fid: string;
}

export const AllFileViewModal = (): JSX.Element => {
  const { onSetAllFileViewModal, fetchFileZipLink } = useActions();

  const {
    allFileViewModal: { params }
  } = useGlobalModalsSelectors();

  const { foldersLibrary } = useLibrarySelectors();
  const { project } = useJoinProjectsSelectors();

  const projectLibraryFiles = project?.library?.folders;

  const onCloseModal = (): void => {
    onSetAllFileViewModal(initialAllFileViewModalState());
  };

  const [currentFile, setCurrentFile] = useState<ICurrentFile>({ name: params.name, fid: params.fid });

  const downloadFile = useCallback(() => {
    fetchFileZipLink(currentFile?.fid);
  }, [currentFile]); //eslint-disable-line

  const onSetCurrentFile = ({ name, fid }: ICurrentFile): void => {
    setCurrentFile({
      name,
      fid
    });
  };

  const initialSlideLibrary = (foldersLibrary as IFile[])?.findIndex(({ fid }) => fid === params.fid);
  const initialSlideProjectLibrary = (projectLibraryFiles as IFile[])?.findIndex(({ fid }) => fid === params.fid);

  return (
    <PopUp set={onCloseModal}>
      <HeaderModal onClose={onCloseModal} title={currentFile?.name}>
        <Button
          variant={ButtonVariantType.BLUE}
          onClick={downloadFile}
          size={ButtonSizeType.MEDIUM}
          iconL={<UploadIcon width={20} height={20} />}
        />
      </HeaderModal>
      <div className={styles.wrap}>
        <SliderFiles
          initialSlide={initialSlideLibrary || initialSlideProjectLibrary}
          files={(foldersLibrary as IFile[]) || (projectLibraryFiles as IFile[])}
          onSetCurrentFile={onSetCurrentFile}
          onCloseModal={onCloseModal}
        />
      </div>
    </PopUp>
  );
};
