import Editor from "@draft-js-plugins/editor";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { convertFromRaw, EditorState } from "draft-js";
import { useActions } from "hooks/useActions";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { INote } from "models/store/notes/notes";
import { useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";

import styles from "./NoteSidebarItem.module.sass";

// Функция-помощник для создания начального состояния редактора из JSON-строки
export const createEditorStateFromJson = (jsonString: string) => {
  if (!jsonString) {
    return EditorState.createEmpty(); // Если jsonString пустая, возвращаем пустое состояние редактора
  }
  try {
    const contentState = convertFromRaw(JSON.parse(jsonString));
    return EditorState.createWithContent(contentState);
  } catch (error) {
    console.error("Ошибка при разборе JSON:", error);
    return EditorState.createEmpty(); // Если произошла ошибка при разборе JSON, возвращаем пустое состояние редактора
  }
};

const NoteSidebarItem = ({ item }: { item: INote }) => {
  const { __ } = useLocales();
  const { onSetNoteEditModal, onSetPasswordModal, onSetTopMessageModal, onSetActiveNote } = useActions();
  const [editorState, setEditorState] = useState(
    item.prim ? createEditorStateFromJson(item.prim) : EditorState.createEmpty()
  );
  const editorRef = useRef(null);

  useEffect(() => {
    setEditorState(item.prim ? createEditorStateFromJson(item.prim) : EditorState.createEmpty());
  }, [item.prim]);

  const openModal = () => {
    if (item.pass) {
      onSetPasswordModal({
        open: true,
        title: __("Заметка — защита паролем"),
        successButtonText: __("Открыть"),
        successCallback: (receivedPass) => {
          // Сравниваем полученное значение с item.pass
          if (receivedPass === item.pass) {
            onSetActiveNote(item);
            onSetNoteEditModal({ open: true, isFull: true });
          } else {
            onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: "Неверный пароль" });
          }
        }
      });
    } else {
      onSetActiveNote(item);
      onSetNoteEditModal({ open: true, isFull: true });
    }
  };

  const customBlockStyleFn = () => {
    return styles.noMargin;
  };

  const renderEditorContent = () => {
    if (item.pass) {
      // Если у нас есть item.pass
      const text = item.prim ? JSON.parse(item.prim).blocks[0].text : ""; // Получаем текст из первого блока контента
      const truncatedText = text.slice(0, 3); // Берем первые три символа текста
      const stars = "*\u00A0".repeat(20); // Создаем строку из 20 звездочек
      return (
        <div>
          <div>{truncatedText + stars}</div>
          <div>{stars}</div>
          <LockIcon className={styles.icon} />
        </div>
      ); // Возвращаем первые три символа и звездочки
    } else {
      return (
        <Editor
          blockStyleFn={customBlockStyleFn}
          editorState={editorState}
          ref={editorRef}
          readOnly={true}
          onChange={() => {}}
        />
      );
    }
  };

  return (
    <div key={item.id} className={styles.container} onClick={openModal}>
      {renderEditorContent()}
    </div>
  );
};

export default NoteSidebarItem;
