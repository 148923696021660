import { endOfDay } from "date-fns";
import Button from "generalComponents/Button/Button";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IEditProjectPayload } from "models/store/joinProjects/joinProjectsPayloads";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { dateISO } from "utils/dateToISO";
import { dataURLToFileSize } from "utils/filesConvert";
import { INITIAL_WORK_DAYS, INITIAL_WORK_HOURS } from "utils/jointPtoject";

import { JointProjectInfo } from "../JointProjectInfo/JointProjectInfo";
import styles from "./SettingsProject.module.sass";

const SettingsProject = () => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const { projectList } = useJoinProjectsSelectors();
  const { id } = useParams();
  const { onEditProject, onSetApproveModal, onArchiveTrashProject } = useActions();
  const project = projectList.find((el) => el.id === id);
  const [name, setName] = useState<string>(project?.name);
  const [icon, setIcon] = useState<string>(project?.icon);
  const [startDate, setStartDate] = useState<Date | null>(project.date_start ? new Date(project.date_start) : null);
  const [endDate, setEndDate] = useState<Date | null>(project.date_end ? new Date(project.date_end) : null);
  const [work_days, setWork_days] = useState<number[]>(
    project.work_days.length ? project.work_days : INITIAL_WORK_DAYS
  );
  const [work_hours, setWork_hours] = useState<number[]>(
    project.work_hours.length ? project.work_hours : INITIAL_WORK_HOURS
  );
  const [timezone, setTimezone] = useState(project.gmt);
  const [autoTimezone, setAutoTimezone] = useState(project.is_gmt_auto);

  useEffect(() => {
    setName(project?.name);
    setIcon(project?.icon);
  }, [project]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);

  const onCalcel = () => {
    setName(project.name);
    setIcon(project.icon);
    setStartDate(new Date(project.date_start));
    setEndDate(new Date(project.date_end));
    setWork_days(project.work_days);
    setWork_hours(project.work_hours);
  };

  const onSubmit = async () => {
    let iconFile = null;
    if (icon?.startsWith("data:image/jpeg;base64")) {
      iconFile = await dataURLToFileSize(icon);
    }
    const params: IEditProjectPayload = {
      data: {
        id: project.id,
        name: project.name !== name ? name : undefined,
        icon: iconFile,
        work_days: JSON.stringify(work_days),
        work_hours: JSON.stringify(work_hours),
        date_start: startDate ? dateISO(startDate) : undefined,
        date_end: startDate ? dateISO(endOfDay(endDate)) : undefined,
        gmt: timezone,
        is_gmt_auto: autoTimezone
      },
      messages: {
        success: __("Данные о проекте изменены"),
        error: __("Проект с таким названием уже есть")
      }
    };
    onEditProject(params);
  };

  const handleArchive = () => {
    const params = {
      titleHead: __("Заархивировать"),
      approveBtn: __("В архив"),
      callback: () => {
        onArchiveTrashProject({
          data: { id_item: project.id, is_archive: "1" },
          message: __("Проект перемещен в архив")
        });
        navigate(`/${ROUTES.PROJECT}`, { replace: true });
      },
      text: __(
        `Вы действительно хотите заархивировать проект ${project.name}? Вы сможете восстановить проект из архива`
      )
    };
    onSetApproveModal({ open: true, params });
  };

  const handleDelete = () => {
    const params = {
      titleHead: __("Удалить"),
      approveBtn: __("Удалить"),
      callback: () => {
        onArchiveTrashProject({
          data: { id_item: project.id, is_del: "1" },
          message: __("Проект перемещен в корзину")
        });
        navigate(`/${ROUTES.PROJECT}`, { replace: true });
      },
      text: __(`Вы действительно хотите удалить проект ${project.name}? Вы сможете восстановить проект из корзины`)
    };
    onSetApproveModal({ open: true, params });
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.info}>
        <JointProjectInfo
          endDate={endDate}
          icon={icon}
          name={name}
          onChange={onChange}
          setEndDate={setEndDate}
          setIcon={setIcon}
          setStartDate={setStartDate}
          startDate={startDate}
          work_days={work_days}
          setWork_days={setWork_days}
          work_hours={work_hours}
          setWork_hours={setWork_hours}
          timezone={timezone}
          setTimezone={setTimezone}
          autoTimezone={autoTimezone}
          setAutoTimezone={setAutoTimezone}
        />

        <div className={styles.btns}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.MEDIUM}
            text={__("Отмена")}
            onClick={onCalcel}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.MEDIUM}
            text={__("Сохранить")}
            onClick={onSubmit}
            disabled={Boolean(!name)}
          />
        </div>
      </div>
      {project.is_my && (
        <div className={styles.btns}>
          <Button
            variant={ButtonVariantType.DARK}
            size={ButtonSizeType.MEDIUM}
            text={__("Архивировать проект ")}
            onClick={handleArchive}
          />
          <Button
            variant={ButtonVariantType.RED}
            size={ButtonSizeType.MEDIUM}
            text={__("Удалить проект")}
            onClick={handleDelete}
          />
        </div>
      )}
    </div>
  );
};

export default SettingsProject;
