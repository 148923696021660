import { projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import React, { useEffect, useRef } from "react";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

const DownloadOfLink = () => {
  const linkRef = useRef<HTMLAnchorElement>();
  const { onDownloadLink } = useActions();
  const { downloadLink } = useGlobalModalsSelectors();

  useEffect(() => {
    if (linkRef.current) linkRef.current.click();
    onDownloadLink("");
  }, []); //eslint-disable-line

  return (
    <a href={`${projectSrc}${downloadLink}`} download ref={linkRef} style={{ display: "none" }}>
      .
    </a>
  );
};

export default DownloadOfLink;
