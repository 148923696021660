import { IFile } from "models/store/files/files";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import { JointProjectFile } from "./components/JointProjectFile/JointProjectFile";
import styles from "./JointProjectFilesGrid.module.sass";

export const JointProjectFilesGrid = () => {
  const { project } = useJoinProjectsSelectors();
  const projectFiles = project?.files;

  return (
    <div className={styles.content}>
      {!!projectFiles?.length &&
        projectFiles.map((item) => <JointProjectFile key={(item as IFile)?.fid || item?.id_dir} item={item} />)}
    </div>
  );
};
