import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { useProjectMsg } from "generalComponents/Services/projectServices";
import TextEditorArea from "generalComponents/TextEditorArea/TextEditorArea";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { ICreateDocsProps } from "models/project/docsWorkSpace";
import { PROJECT_DOCS_VIEW } from "models/project/projectDocs";
import { IAddDocs, ITopMessages } from "models/store/projects/projectThunk";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { v4 as uuid } from "uuid";

import styles from "./WorkSpace.module.sass";

const CreateDoc: React.FC<ICreateDocsProps> = ({ pressedButton, changeToolbar, setTypes }): JSX.Element => {
  const { __ } = useLocales();
  const { onAddDocs } = useActions();
  const MSG = useProjectMsg();

  const [name, setName] = useState<string>(NO_VALUE);
  const [content, setContent] = useState<string>(NO_VALUE);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => setName(e.target.value);

  const onSave = (): void => {
    const payload: IAddDocs[] = [
      {
        is_template: 0,
        name,
        data: JSON.stringify([{ id: uuid(), section: "", content }]),
        numberedData: JSON.stringify([]),
        inputs: JSON.stringify([]),
        footer: 0
      }
    ];
    const messages: ITopMessages = {
      error: MSG.ERROR,
      success: MSG.ADD_DOCS
    };
    const cb = (): void => setTypes(PROJECT_DOCS_VIEW.DOCS);
    onAddDocs(payload, messages, cb);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.document}>
        <div className={styles.sheet}>
          <input
            type="text"
            value={name}
            onChange={handleChangeName}
            className={classNames(styles.inputName, { [styles.inputFilled]: name.length > 0 })}
            placeholder={__("Имя документа")}
          />
          <div
            className={classNames(styles.inputName, styles.editorWrap, { [styles.inputFilled]: content.length > 0 })}
            tabIndex={1}
          >
            <TextEditorArea
              content={content}
              setContent={setContent}
              pressedButton={pressedButton}
              changeToolbar={changeToolbar}
            />
          </div>
        </div>
        <div className={styles.stickyBtn}>
          <Button
            text={__("Сохранить")}
            type="button"
            onClick={onSave}
            variant={ButtonVariantType.OK}
            disabled={!name}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateDoc;
