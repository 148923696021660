import { ReactComponent as LockIcon } from "assets/icons/lock_2.svg";
import classNames from "classnames";
import { useProjectTeamContext } from "collections/joinProject";
import Avatar from "generalComponents/Avatar/Avatar";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { useFindParticipant, useProjectMainAccess } from "hooks/joinProjectHooks";
import { IProjectTeam, ROLE_ACCESSES } from "models/store/joinProjects/joinProgects";
import { FC, useState } from "react";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./TeamProject.module.sass";

interface IProps {
  team: IProjectTeam;
}

const TeamProject: FC<IProps> = ({ team }) => {
  const findParticipant = useFindParticipant();
  const { admin, user } = useProjectTeamContext();
  const { project } = useJoinProjectsSelectors();
  const isMainAccess = useProjectMainAccess();
  const [isActive, setIsActive] = useState<boolean>(false);

  const renderAvatar = (id: string): JSX.Element => {
    const user = findParticipant(id);
    if (!user) return;
    return <Avatar name={user.fname + " " + user.sname} key={id} imgSrc={user.icon} size={44} />;
  };

  return (
    <li className={classNames(styles.team, { [styles.isActive]: isActive })}>
      <div className={styles.block}>
        <div>
          <p className={styles.name}>
            {Boolean(team.is_close) && <LockIcon />}
            {team.name}
          </p>
          <p className={styles.prim}>{team.prim}</p>
        </div>
        <div className={styles.actions}>
          <PopoverContextMenu
            data={isMainAccess || project?.accesses[ROLE_ACCESSES.MGR_TEAMS] ? admin(team) : user(team)}
            iconVariant="button"
            onEnter={() => setIsActive(true)}
            onExit={() => setIsActive(false)}
          />
        </div>
      </div>
      <ul className={styles.avatars}>{team.users.map((id) => renderAvatar(id))}</ul>
    </li>
  );
};

export default TeamProject;
