import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  getTime,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear
} from "date-fns";
import { createArrayOfHours, MS_OF_DAY } from "generalComponents/Calendars/CalendarHelper";
import { MIDNIGHT } from "generalComponents/variables/global";
import { TASK_PARAMS } from "generalComponents/variables/routing";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { ITasksTypes } from "models/generalComponents/services/tasksServices";
import { IMyTask, TaskFilters } from "models/store/tasks/tasks";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useFilterCriteriaSelector } from "Store/selectors/filterCriteriaSelectors";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import { taskOfPeriod } from "../../utils/tasks";

export const useTasksCalendarPeriod = (): IMyTask[] => {
  const {
    myTasks,
    filters: { date, type }
  } = useTasksSelectors();
  const [searchParams] = useSearchParams();
  const dep = searchParams.get(TASK_PARAMS.DEP);
  const {
    filterCriteria: { color, emo, figure, tags }
  } = useFilterCriteriaSelector();

  const filtredDepartment: IMyTask[] = useMemo(() => {
    if (dep) {
      return myTasks.filter((task) => task.id_dep === dep);
    }
    return myTasks;
  }, [dep, myTasks]);

  const filtredTasks: IMyTask[] = useMemo(() => {
    if (!color && !emo && !figure && !tags.length) {
      return filtredDepartment;
    }
    return filtredDepartment
      .filter((task) => {
        if (color) {
          return task.color === color;
        }
        return task;
      })
      .filter((task) => {
        if (emo) {
          return task.emo === emo;
        }
        return task;
      })
      .filter((task) => {
        if (tags.length) {
          return task.tags.some((tag) => tags.includes(tag));
        }
        return task;
      });
  }, [color, emo, figure, filtredDepartment, tags.length]); // eslint-disable-line

  const getTasksPeriod = (): IMyTask[] => {
    switch (type) {
      case TaskFilters.BY_DAY: {
        const time = getTime(new Date(date.year, date.month, date.day));
        const periodStart = getTime(startOfDay(new Date(time)));
        const periodEnd = getTime(endOfDay(new Date(time)));
        return taskOfPeriod(filtredTasks, periodStart, periodEnd);
      }
      case TaskFilters.BY_WEEK: {
        const time = getTime(new Date(date.year, date.month, date.day));
        const periodStart = getTime(startOfWeek(new Date(time), { weekStartsOn: 1 }));
        const periodEnd = getTime(endOfWeek(new Date(time), { weekStartsOn: 1 }));
        return taskOfPeriod(filtredTasks, periodStart, periodEnd);
      }
      case TaskFilters.BY_MONTH: {
        const periodStart = getTime(startOfMonth(new Date(date.year, date.month)));
        const periodEnd = getTime(endOfMonth(new Date(date.year, date.month)));
        return taskOfPeriod(filtredTasks, periodStart, periodEnd);
      }
      case TaskFilters.BY_YEAR: {
        const periodStart = getTime(startOfYear(new Date(date.year, date.month)));
        const periodEnd = getTime(endOfYear(new Date(date.year, date.month)));
        return taskOfPeriod(filtredTasks, periodStart, periodEnd);
      }

      default:
        return myTasks;
    }
  };

  return getTasksPeriod();
};

export const useTasksCalendar = (): ITasksTypes => {
  const myTasks = useTasksCalendarPeriod();

  const tasks = myTasks.filter((t) => t.id_type === TASK_TYPES.TASK);
  const meetings = myTasks.filter(
    (t) => t.id_type === TASK_TYPES.OFFLINE_MEETING || t.id_type === TASK_TYPES.ONLINE_MEETING
  );
  const calls = myTasks.filter((t) => t.id_type === TASK_TYPES.CALLS);
  const mails = myTasks.filter((t) => t.id_type === TASK_TYPES.MAILS);
  const reminder = myTasks.filter((t) => t.id_type === TASK_TYPES.REMINDER);

  return {
    tasks,
    meetings,
    calls,
    mails,
    reminder
  };
};

export const createTasksObj = (tasks: IMyTask[]): Record<string, IMyTask[]> => {
  return tasks.reduce((acc: Record<string, IMyTask[]>, item) => {
    if (item.id_type === TASK_TYPES.TASK) {
      const key = getTime(startOfDay(new Date(item.date_start)));
      const taskEnd = getTime(new Date(item.date_end));
      for (let i = key; i <= taskEnd; i += MS_OF_DAY) {
        acc[i] ? acc[i].push(item) : (acc[i] = [item]);
      }
      return acc;
    } else {
      const key = getTime(startOfDay(new Date(item.date_start)));
      acc[key] ? acc[key].push(item) : (acc[key] = [item]);
      return acc;
    }
  }, {});
};

export const defaultRows = (): Record<string, []> => {
  const timePeriod = createArrayOfHours(new Date("1971-01-01T" + MIDNIGHT), 1);
  return timePeriod.reduce((acc: Record<string, []>, el) => {
    acc[el] = [];
    return acc;
  }, {});
};
