import { endOfDay } from "date-fns";
import Button from "generalComponents/Button/Button";
import InputDate from "generalComponents/Calendars/InputDate/InputDate";
import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import PopUp from "generalComponents/PopUp/PopUp";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { initialCreateSprintModalState } from "models/store/Cabinet/modals/createSprintModal";
import { initialApproveModalState } from "models/store/Cabinet/modals/modals";
import { ISprint } from "models/store/joinProjects/joinProgects";
import {
  TAddSprintProjectPayload,
  TDelSprintProjectPayload,
  TEditSprintProjectPayload
} from "models/store/joinProjects/joinProjectsPayloads";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { dateISO } from "utils/dateToISO";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./CreateSprintModal.module.sass";

export const CreateSprintModal = (): JSX.Element => {
  const { __ } = useLocales();
  const {
    createSprintModal: { titleHead, btnLabel, sprint }
  } = useGlobalModalsSelectors();

  const [isCheked, setChecked] = useState<boolean>();
  const [startDate, setStartDate] = useState<Date | null>(sprint?.date_start ? new Date(sprint.date_start) : null);
  const [endDate, setEndDate] = useState<Date | null>(sprint?.date_end ? new Date(sprint.date_end) : null);

  const { id } = useParams();

  const { onSetCreateSprintModal, onSetChangesSprintProject, onSetApproveModal } = useActions();

  const { project } = useJoinProjectsSelectors();

  const getClosestSprintTo = (): ISprint => {
    const sprints = project?.sprints
      ? [...project.sprints]?.filter(({ date_end }) => new Date(date_end) >= new Date()).sort()
      : undefined;

    return sprints?.length ? sprints[0] : undefined;
  };

  const closestSprintTo = getClosestSprintTo();

  const getOutstandingIdTasks = (): string[] => {
    const tasks = project.tasks
      .filter(
        ({ id_status, id_sprints }) =>
          id_sprints.length === 1 && id_sprints.includes(closestSprintTo?.id) && id_status !== "370"
      )
      .map(({ id }) => id);

    return tasks;
  };

  const outstandingIdTasks = getOutstandingIdTasks();

  const closeModal = (): void => {
    onSetCreateSprintModal(initialCreateSprintModalState());
  };

  const { value: valueName, onChange: onChangeName, error: errorName } = useInputValue(sprint?.name);

  const disablesRangeSelection = (selectedDateStart: Date, selectedDateEnd: Date): boolean => {
    return (
      !!project?.sprints &&
      project.sprints.some(({ date_start, date_end }) => {
        const dateStart: Date = new Date(date_start);
        const dateEnd: Date = new Date(date_end);

        const daysLag = Math.ceil(Math.abs(dateStart?.getTime() - selectedDateStart?.getTime()) / (1000 * 3600 * 24));

        return daysLag === 1 || (selectedDateStart < dateStart && selectedDateEnd > dateEnd);
      })
    );
  };

  const onChangeDate = (dates: [Date, Date]) => {
    const [start, end] = dates;

    if (disablesRangeSelection(start, endOfDay(end)) || (end && start && end.getTime() === start.getTime())) {
      setStartDate(end);
      setEndDate(null);
    } else {
      setStartDate(start);
      setEndDate(end ? endOfDay(end) : null);
    }
  };

  const isFormDisabled = !startDate || !endDate || !valueName;
  const isNotEditStarDate = sprint?.date_start && startDate && sprint.date_start === dateISO(startDate);
  const isNotEditEndDate = sprint?.date_end && endDate && sprint.date_end === dateISO(endDate);
  const isNotEditName = sprint?.name && valueName && sprint.name === valueName;
  const isNotEdit = isNotEditStarDate && isNotEditEndDate && isNotEditName;

  const excludeDateIntervals = project?.sprints?.map(({ date_start, date_end }) => ({
    start: new Date(date_start),
    end: new Date(date_end)
  }));

  const onSubmitDataSprint = () => {
    const paramsAdd: TAddSprintProjectPayload = {
      id_project: id,
      name: valueName,
      date_start: dateISO(startDate),
      date_end: dateISO(endDate),
      id_tasks: isCheked ? outstandingIdTasks : undefined,
      id_sprint_from: isCheked ? closestSprintTo.id : undefined
    };

    const paramsEdit: TEditSprintProjectPayload = {
      id_project: id,
      id_item: sprint?.id,
      name: !isNotEditName ? valueName : undefined,
      date_start: !isNotEditStarDate ? dateISO(startDate) : undefined,
      date_end: !isNotEditEndDate ? dateISO(endDate) : undefined
    };

    sprint
      ? onSetChangesSprintProject(EVENT_TYPE.EDIT, paramsEdit)
      : onSetChangesSprintProject(EVENT_TYPE.ADD, paramsAdd);

    closeModal();
  };

  const onApproveDeletion = () => {
    const paramsDel: TDelSprintProjectPayload = {
      id_project: id,
      id_item: sprint?.id
    };

    const params = {
      titleHead: __("Удалить"),
      title: __(`Вы действительно хотите удалить ${sprint?.name}?`),
      text: __("Задачи не будут удалены"),
      approveBtn: __("Удалить"),
      callback: (): void => {
        onSetChangesSprintProject(EVENT_TYPE.DEL, paramsDel);
        onSetApproveModal(initialApproveModalState());
      }
    };

    closeModal();

    onSetApproveModal({ open: true, params });
  };

  return (
    <PopUp set={closeModal}>
      <HeaderModal onClose={closeModal} title={titleHead || __("Новый спринт")} />
      <div className={styles.wrap}>
        <form className={styles.contentWrap}>
          <div className={styles.topWrap}>
            <div className={styles.fieldBox}>
              <Input
                value={valueName}
                onChange={onChangeName}
                name="sprintName"
                label={__("Название")}
                placeholder={__("Введите")}
                error={Boolean(errorName)}
                errorText={errorName}
                require
                className={styles.input}
              />
            </div>
            <div className={styles.fieldBox}>
              <InputDate
                startDate={startDate}
                endDate={endDate}
                setStartDate={onChangeDate}
                dateFormat="dd MMM yyyy"
                isClearable
                minDate={project.date_start ? new Date(project.date_start) : null}
                selectsRange
                excludeDateIntervals={excludeDateIntervals}
              />
            </div>
            {Boolean(closestSprintTo) && Boolean(outstandingIdTasks?.length) && !sprint && (
              <div role="button" tabIndex={0} onClick={() => setChecked(!isCheked)} className={styles.boxCheked}>
                <CheckBox
                  variant={isCheked ? CheckBoxVariantTypes.CHECKED : CheckBoxVariantTypes.DEFAULT}
                  width={20}
                  height={20}
                  increaseArea
                />
                <div
                  className={styles.boxCheked__content}
                  dangerouslySetInnerHTML={{
                    __html: `<p>${__(
                      `Перенести <strong>${outstandingIdTasks.length}</strong> невыполненных задач с <strong>${closestSprintTo.name}</strong>`
                    )}</p>`
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles.buttonsWrap}>
            {sprint && (
              <div>
                <Button
                  variant={ButtonVariantType.RED}
                  onClick={onApproveDeletion}
                  size={ButtonSizeType.SMALL}
                  text={__("Удалить")}
                />
              </div>
            )}
            <div className={styles.rightBtns}>
              <Button
                type="button"
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.SMALL}
                text={__("Отменить")}
                onClick={closeModal}
              />
              <Button
                type="button"
                variant={ButtonVariantType.BLUE}
                size={ButtonSizeType.SMALL}
                text={btnLabel || __("Добавить")}
                onClick={onSubmitDataSprint}
                disabled={isFormDisabled || isNotEdit}
              />
            </div>
          </div>
        </form>
      </div>
    </PopUp>
  );
};
