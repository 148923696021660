import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import PopUp from "generalComponents/PopUp/PopUp";
import Switcher from "generalComponents/Switcher/Switcher";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialTagEditState } from "models/store/Cabinet/modals/tagEdit";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./TagEditModal.module.sass";

const TagEditModal = () => {
  const { __ } = useLocales();
  const { onSetTagEditModal, addTag, editTag } = useActions();
  const {
    tagEdit: { tag }
  } = useGlobalModalsSelectors();
  const { userInfo, tags } = useUserSelectors();

  const [name, setName] = useState<string>(tag?.name);
  const [access, setAccess] = useState<boolean>(true);

  const closeModal = () => {
    onSetTagEditModal(initialTagEditState());
  };

  const onSetName = (e: React.ChangeEvent<HTMLInputElement>): void => setName(e.target.value);

  const title = tag ? __("Изменить") : __("Добавить");

  const handleSubmit = () => {
    if (tag) {
      editTag({ name, id_item: tag.id, id_company: access && userInfo.id_company }, tags);
    } else {
      addTag({ name, id_company: access ? userInfo.id_company : undefined }, tags);
    }
    closeModal();
  };

  return (
    <PopUp set={closeModal} zIndex={1080}>
      <HeaderModal title={title} onClose={closeModal} />
      <div className={styles.container}>
        <div className={styles.content}>
          <Input value={name} onChange={onSetName} label={__("Название")} />
          <div className={styles.check}>
            <Switcher checked={access} onClickCheckBox={() => setAccess((prev) => !prev)} />
            {__("Общий доступ")}
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            variant={ButtonVariantType.ADD_GREY}
            size={ButtonSizeType.SMALL}
            text={__("Отменить")}
            onClick={closeModal}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={__("Добавить")}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default TagEditModal;
