import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialSocialModalState } from "models/store/Cabinet/modals/contactsModal";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./SocialModal.module.sass";

const SocialModal = () => {
  const { __ } = useLocales();
  const { onSetSocialModal } = useActions();
  const {
    socialModal: { title, data, icons, updateData }
  } = useGlobalModalsSelectors();

  const closeModal = (): void => {
    onSetSocialModal(initialSocialModalState());
  };

  const initialSocialData = Object.keys(icons).map((type) => {
    const dataItem = data.find((item) => item.type === type);
    return {
      type,
      link: dataItem ? dataItem.link : undefined
    };
  });

  const [socialData, setSocialData] = useState(initialSocialData);

  const handleInputChange = (index: number, newValue: string) => {
    const updatedData = [...socialData];
    updatedData[index].link = newValue;
    setSocialData(updatedData);
  };

  const onSubmit = () => {
    updateData(socialData);
    closeModal();
  };

  const availableSocialTypes = Object.keys(icons);

  return (
    <PopUp set={closeModal} position="top" top={50}>
      <div className={styles.wrap}>
        <HeaderModal title={title} onClose={closeModal} />
        <div className={styles.body}>
          {availableSocialTypes.map((type) => {
            const socialItem = socialData.find((item) => item.type === type) || { type, link: "" };
            return (
              <div key={type} className={styles.inputWrapper}>
                {icons[type]}
                <Input
                  className={styles.input}
                  value={socialItem.link}
                  onChange={(e) => handleInputChange(socialData.indexOf(socialItem), e.target.value)}
                  placeholder={__("Введите")}
                />
              </div>
            );
          })}
          <div className={styles.btns}>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.MEDIUM}
              text={__("Отменить")}
              onClick={closeModal}
            />
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.MEDIUM}
              onClick={onSubmit}
              text={__("Сохранить")}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default SocialModal;
