import { PAGE_DEPTH } from "generalComponents/variables/global";

import { IFolder } from "./foldersStore";

export enum FolderUrlTypes {
  DIR_ADD = "dir_add.php",
  DIR_EDIT = "dir_edit.php",
  PROJECT_FOLDERS_ADD = "project_folders_add.php",
  PROJECT_FOLDERS_EDIT = "project_folders_edit.php"
}

export interface ICreateFolderPayload {
  dir_name?: string;
  id_parent?: string;
  tags?: string[];
  color?: string;
  emo?: string;
  pass?: string;
  dep?: PAGE_DEPTH;
  chapter?: string;
}

export interface IEditFolderPayload {
  id_dirs?: string[]; // айди папок
  dir_name?: string;
  dir_name_new?: string;
  id_parent?: string;
  tags?: string[];
  color?: string;
  emo?: string;
  pass?: string;
  is_favorite?: number;
  is_del?: number;
  is_archive?: number;
  dep?: PAGE_DEPTH;
  chapter?: string;
}

export interface IHandleFolderPayload {
  tag?: string;
  color?: string;
  emo?: string;
  id_dirs?: string[]; // айди папок
  id_parent?: string; // айди родителя выбранной папки куда нужно переместить выбранные папки
  id_parent_dir_moved?: string; // айди родительской папки у перемещаемой папки
  dir_name?: string;
  dir_name_new?: string;
  pass?: string;
  dep?: PAGE_DEPTH;
}

export interface IGetFoldersDTO {
  folders: {
    global: IFolder[];
    other: IFolder[];
  };
}

export interface IShareFoldersPayload {
  usersTo: string;
  idDirs: string[];
}

export interface ICreateZipFolderPayload {
  idDir: string;
  idDirs: string[];
  fids: string[];
  zipName: string;
  zipPass?: string;
  tags: string[];
  color: string;
  emo: string;
}

export interface IAccessInfo {
  id_user: string;
  user_name: string;
  is_read: number;
}
