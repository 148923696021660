import { useActions } from "hooks/useActions";
import React from "react";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

const ZipBtn: React.FC = () => {
  const { __ } = useLocales();
  const { onSetCreateZipModal } = useActions();
  const {
    pickedItems: { items }
  } = useFilesSelectors();
  const onZip = (): void => {
    onSetCreateZipModal({
      open: true,
      params: items
    });
  };
  return (
    <button onClick={onZip} type="button">
      {__("Сжать в ZIP")}
    </button>
  );
};

export default ZipBtn;
