import { ReactComponent as ExitIcon } from "assets/icons/exit.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as SmileIcon } from "assets/icons/smile.svg";
import { ReactComponent as SupportIcon } from "assets/icons/support.svg";
import classNames from "classnames";
import { useUserStatus } from "collections/profile";
import Avatar from "generalComponents/Avatar/Avatar";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { PROFILE_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { PROFILE_MODALS } from "models/store/Cabinet/modals/profileModals";
import { STATUS_COLOR, USER_STATUS } from "models/store/user/user";
import { ReactNode, useState } from "react";
import { useLocales } from "react-localized";
import { Link } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./Profile.module.sass";

const Profile = () => {
  const { __ } = useLocales();
  const { onLog, onChangeUserSettings, onSetProfileModal } = useActions();
  const { userInfo } = useUserSelectors();
  const { icon, fname, sname, status } = userInfo;
  const [isOpenSelect, setIsOpenSelect] = useState<boolean>(false);
  const { userStatuses } = useUserStatus();
  const onExit = async () => {
    onChangeUserSettings({ status: USER_STATUS.OFFLINE, status_color: STATUS_COLOR.OFFLINE });
    onLog("");
  };

  const closeMenu = (): void => setIsOpenSelect(false);

  const onChangeStatus = (status: USER_STATUS, status_color: STATUS_COLOR): void => {
    onChangeUserSettings({ status, status_color });
    closeMenu();
  };

  const onAddCustomStatus = (): void => {
    onSetProfileModal({ open: true, type: PROFILE_MODALS.CHANGE_STATUS, employee: userInfo });
    closeMenu();
  };

  const renderMenu = (): ReactNode => {
    return (
      <div>
        <ul className={styles.block}>
          {userStatuses.map((el, idx) => (
            <li key={idx}>
              <button
                type="button"
                onClick={() => onChangeStatus(el.status, el.status_color)}
                className={classNames(styles.item, { [styles.active]: el.status === status })}
              >
                <div className={styles.status}>
                  <div className={styles[el.status]} />
                </div>
                <span>{el.text}</span>
              </button>
            </li>
          ))}
          <li>
            <button type="button" onClick={onAddCustomStatus} className={styles.item}>
              <SmileIcon />
              <span>{userInfo.prim || __("Свой статус...")}</span>
            </button>
          </li>
        </ul>
        <div className={styles.block}>
          <Link to={`${ROUTES.PROFILE}/${PROFILE_ROUTES.ABOUT_ME}`} className={styles.item} onClick={closeMenu}>
            <PersonIcon />
            <span>{__("Мой профиль")}</span>
          </Link>
          <Link to={`${ROUTES.PROFILE}/${PROFILE_ROUTES.FAQ}`} className={styles.item} onClick={closeMenu}>
            <SupportIcon />
            <span>{__("Поддержка")}</span>
          </Link>
          <Link to={`${ROUTES.PROFILE}/${PROFILE_ROUTES.SETTINGS}`} className={styles.item} onClick={closeMenu}>
            <SettingsIcon />
            <span>{__("Настройки")}</span>
          </Link>
        </div>
        <div className={styles.block}>
          <button type="button" onClick={onExit} className={styles.item}>
            <ExitIcon width={12} height={12} />
            <span>{__("Выход")}</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <PopoverOverlay overlayContent={renderMenu()} show={isOpenSelect} setShow={setIsOpenSelect} width={192}>
      <Avatar name={`${fname} ${sname}`} imgSrc={`${projectSrc}${icon}`} status={status} />
    </PopoverOverlay>
  );
};

export default Profile;
