import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { ITopMessages } from "models/common/common";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IEvent } from "models/store/journal/journal";
import {
  INullifyJournal,
  IResetJournalElements,
  ISetJournalElements,
  ISetJournalLoader,
  ISetJournalPage,
  ISetJournalTotalElements,
  JournalActions
} from "models/store/journal/journalActions";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { JournalEventsTypes } from "Store/types/journalEventsTypes";

import { onSetTopMessageModal } from "./ModalActions";

export const onSetJournalLoader = (payload: boolean): ISetJournalLoader => ({
  type: JournalEventsTypes.SET_JOURNAL_LOADER,
  payload
});

export const onSetJournalElements = (payload: IEvent[]): ISetJournalElements => ({
  type: JournalEventsTypes.SET_JOURNAL_ELEMENTS,
  payload
});

export const onSetJournalPage = (payload: number): ISetJournalPage => ({
  type: JournalEventsTypes.SET_JOURNAL_PAGE,
  payload
});

export const onSetJournalTotalElements = (payload: number): ISetJournalTotalElements => ({
  type: JournalEventsTypes.SET_TOTAL_JOURNAL_ELEMENTS,
  payload
});

export const onResetJournalElements = (): IResetJournalElements => ({
  type: JournalEventsTypes.RESET_JOURNAL_ELEMENTS
});

export const onNullifyJournal = (): INullifyJournal => ({
  type: JournalEventsTypes.NULLIFY_JOURNAL
});

export const onGetJournalElements =
  (
    messages?: ITopMessages,
    controller?: AbortController,
    payload?: { filter_object: string; filter_group: string[] }
  ): ThunkAction<void, RootState, unknown, JournalActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { d, m, y } = getState().SortByDate;
    const { table, id_type, event_type } = getState().FilterTypeElements;

    const { searchParams } = new URL(window?.location?.href);
    const user_id = getState()?.user?.userInfo?.is_admin ? searchParams.get(SEARCH_PARAMS.USER_ID) : undefined;

    const params = {
      uid: getState().user.uid,
      per_page: PER_PAGE,
      page: getState()?.Journal?.page,
      filter_user: user_id ? user_id : getState()?.user?.userInfo?.id,
      filter_group: payload?.filter_group || table,
      tasks_id_type: id_type?.length ? id_type : undefined,
      filter_date: y && m && d ? `${y}-${m}-${d}` : undefined,
      filter_object: payload?.filter_object ? payload.filter_object : undefined,
      filter_type: event_type ? event_type : undefined
    };

    try {
      dispatch(onSetJournalLoader(true));
      const { data } = await api.get("/ajax/event_get.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetJournalElements(data.events));
      dispatch(onSetJournalTotalElements(data.total));
    } catch {
      if (!controller?.signal?.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR,
            message: messages?.error
          })
        );
      }
    } finally {
      dispatch(onSetJournalLoader(false));
    }
  };
