import { ReactComponent as CheckIcon } from "assets/icons/checkmark.svg";
import { createElement, FC, SVGProps } from "react";

import styles from "./SidebarList.module.sass";

interface SidebarListProps {
  title: string;
  text: string;
  icon: FC<SVGProps<SVGSVGElement>>;
}

const colors = [
  "var(--color-p500)",
  "var(--color-pp500)",
  "var(--color-mb500)",
  "var(--color-blue-background)",
  "var(--color-b900)"
];

const SidebarList = ({ data }: { data: SidebarListProps[] }) => {
  return (
    <div className={styles.container}>
      {data.map((item, index) => (
        <div key={item.title} className={styles.item} style={{ backgroundColor: colors[index % colors.length] }}>
          <h5 className={styles.title}>
            {createElement(item.icon, { className: styles.icon })}
            {item.title}
          </h5>
          <CheckIcon className={styles.rightIcon} />
          <p className={styles.text}>{item.text}</p>
        </div>
      ))}
    </div>
  );
};

export default SidebarList;
