import Button from "generalComponents/Button/Button";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import Input from "generalComponents/Input/Input";
import PopUp from "generalComponents/PopUp/PopUp";
import { splitFilename } from "generalComponents/Services/helpers";
import { Times } from "generalComponents/Times/Times";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ButtonVariantType } from "models/generalComponents/button";
import { initialCustomizeFileModalState } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./RenameFile.module.sass";

const RenameFile = (): JSX.Element => {
  const { __ } = useLocales();
  const { onSetCustomizeFileModal, onEditFiles, onSetUpdateFiles, onSetUpdateNextFiles, onSetCurrentItem } =
    useActions();

  const { currentItem } = useFilesSelectors();

  const {
    customizeFile: { params }
  } = useGlobalModalsSelectors();
  const safe = useAuthorizedSafe();

  const { isLinesPreviewView } = useGetItemView();

  const isFolderPage = useCurrentPathStarstWith(ROUTES.FOLDERS);

  const [name, setName] = useState<string>(splitFilename(params.name).name);

  const closeModal = (): void => {
    onSetCustomizeFileModal(initialCustomizeFileModalState());
  };

  const onAddName = (e: React.ChangeEvent<HTMLInputElement>): void => setName(e.target.value);

  const onSubmit = (): void => {
    const payload = {
      fids: [params.fid],
      name: `${name}.${splitFilename(params.name).format}`,
      id_dir: params?.id_dir || undefined
    };

    onEditFiles(payload, EVENT_TYPE.EDIT_NAME, safe, (updateFile: IFile) => {
      if (isLinesPreviewView && isFolderPage) {
        onSetUpdateNextFiles([updateFile]);
      } else {
        onSetUpdateFiles([updateFile]);
      }

      if (currentItem) {
        onSetCurrentItem(updateFile);
      }
    });
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.root}>
        <Times handleClick={closeModal} />
        <div className={styles.title}>{__("Переименовать")}</div>
        <div className={styles.contentWrap}>
          <Input value={name} onChange={onAddName} placeholder={__("Имя файла")} />
        </div>
        <div className={styles.buttonsWrap}>
          <Button type="button" variant={ButtonVariantType.CANCEL} text={__("Отмена")} onClick={closeModal} />
          <Button type="button" variant={ButtonVariantType.OK} text={__("Сохранить")} onClick={onSubmit} />
        </div>
      </div>
    </PopUp>
  );
};

export default RenameFile;
