import { ReactComponent as CleaningIcon } from "assets/icons/cleaning.svg";
import classNames from "classnames";
import DashboardItem from "containers/Dashboard/DashboardItem/DashboardItem";
import Empty from "containers/Dashboard/Empty/Empty";
import Button from "generalComponents/Button/Button";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { ITopMessages } from "models/store/projects/projectThunk";
import { useEffect } from "react";
import { useLocales } from "react-localized";
import { Link } from "react-router-dom";
import { useTrashElementsSelectors } from "Store/selectors/trashSelectors";

import styles from "./Trash.module.sass";

const Trash = () => {
  const { __ } = useLocales();
  const { onSetApproveModal, onFullCleaningTrash } = useActions();
  const { elements } = useTrashElementsSelectors();

  const onСleansСompletely = () => {
    const params = {
      titleHead: __("Очистить"),
      title: __("Вы действительно хотите очистить корзину?"),
      text: __("Это действие необратимо"),
      approveBtn: __("Очистить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: (): void => {
        const messages: ITopMessages = {
          success: __("Корзина очищена"),
          error: ""
        };
        onFullCleaningTrash(messages);
      }
    };

    onSetApproveModal({
      open: true,
      params
    });
  };

  useEffect(() => {
    console.log(elements);
  }, [elements]);

  return (
    <div className={classNames(styles.container)}>
      <div className={styles.header}>
        <Link to={ROUTES.TRASH} className={styles.title}>
          {__("Корзина")}
        </Link>
        <Button
          variant={ButtonVariantType.OPACITY}
          onClick={onСleansСompletely}
          isSquare
          size={ButtonSizeType.SMALL}
          iconL={<CleaningIcon />}
        />
      </div>
      {(Array.isArray(elements) && elements.length === 0) || !Array.isArray(elements) ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={classNames(styles.scrollWrapper, "scrollbar-thin-blue")}>
          <div className={styles.list}>
            {Array.isArray(elements) &&
              elements.map((item, i) => (
                <DashboardItem item={item as IFile | IFolder} key={i} type={"normal"} isClickable={false} />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Trash;
