export interface IPasswordModalState {
  open: boolean;
  title: string;
  successButtonText: string;
  successCallback: (val: string) => void;
  currentValue?: string;
}

export function initialPasswordModalState(): IPasswordModalState {
  return { open: false, title: "", successButtonText: "", successCallback: () => {}, currentValue: "" };
}
