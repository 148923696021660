import { useTasksCalendar } from "generalComponents/Services/calendarPageServices";
import { TASK_TYPES, TASKS_SCHEMA } from "generalComponents/variables/tasks";
import { TaskFilters } from "models/store/tasks/tasks";
import React, { useState } from "react";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import MonthView from "../MonthView/MonthView";
import SidePanel from "../SidePanel/SidePanel";
import TasksBlock from "../TasksBlock/TasksBlock";
import WeekView from "../WeekView/WeekView";
import YearView from "../YearView/YearView";
import styles from "./SidePanelView.module.sass";

const SidePanelView: React.FC = (): JSX.Element => {
  const { tasks, meetings, calls, mails } = useTasksCalendar();
  const {
    filters: { type }
  } = useTasksSelectors();

  const [schema, setSchema] = useState<TASKS_SCHEMA>(TASKS_SCHEMA.GRID_BAR);

  return (
    <>
      {schema === TASKS_SCHEMA.GRID_BAR ? (
        <div className={styles.workSpace}>
          <SidePanel setSchema={setSchema} />
          {type === TaskFilters.BY_YEAR && <YearView />}
          {type === TaskFilters.BY_MONTH && <MonthView />}
          {type === TaskFilters.BY_WEEK && <WeekView />}
        </div>
      ) : (
        <div className={styles.wrap}>
          {schema === TASKS_SCHEMA.EXPANDED_TASKS_BOARD && (
            <TasksBlock tasks={tasks} type={TASK_TYPES.TASK} isFull={true} setSchema={setSchema} />
          )}
          {schema === TASKS_SCHEMA.EXPANDED_MEETINGS_BOARD && (
            <TasksBlock tasks={meetings} type={TASK_TYPES.MEETINGS} isFull={true} setSchema={setSchema} />
          )}
          {schema === TASKS_SCHEMA.EXPANDED_CALLS_BOARD && (
            <TasksBlock tasks={calls} type={TASK_TYPES.CALLS} isFull={true} setSchema={setSchema} />
          )}
          {schema === TASKS_SCHEMA.EXPANDED_MAIL_BOARD && (
            <TasksBlock tasks={mails} type={TASK_TYPES.MAILS} isFull={true} setSchema={setSchema} />
          )}
        </div>
      )}
    </>
  );
};

export default SidePanelView;
