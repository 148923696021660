import BottomMenuOption from "generalComponents/BottomMenuOption/BottomMenuOption";
import ElementsList from "generalComponents/ElementsList/ElementsList";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { ROUTES } from "generalComponents/variables/routing";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams, useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useSafeSelectors } from "Store/selectors/safeSelectors";

const SafeFiles = () => {
  const { safeId, safeDir } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { onGetSafeFiles, onSetSafeModal, onResetFilesList, onGetSafeFolders } = useActions();
  const { isLinesPreviewView } = useGetItemView();

  const { safeList, authorized } = useSafeSelectors();
  const authorizedSafe = useAuthorizedSafe();
  const { pickedItems } = useFilesSelectors();
  const {
    objSearchParams: { group, sort, sort_reverse }
  } = useSelectedObjSearchParams();

  const { color, emo, tags } = useAllSelectedSearchParams();

  useEffect(() => {
    // запрос за файлами если сейф был открыт
    if (authorizedSafe) {
      if (isLinesPreviewView && safeDir) return;
      onResetFilesList();
      const payload = {
        id_safe: safeId,
        code: authorizedSafe.code,
        id_dir: safeDir
      };
      onGetSafeFiles(payload);
      onGetSafeFolders(safeId);
    } else {
      const safe = safeList.find((el) => el.id === safeId);
      if (safe) {
        // открываем модалку с информацией что сейф заблокирован
        if (safe.time_blocked) {
          onSetSafeModal({
            open: true,
            type: SAFE_MODALS.SAFE_BLOKED,
            safe
          });
          // открываем модалку ввода пароля к сейфу
        } else {
          onSetSafeModal({
            open: true,
            type: SAFE_MODALS.ENTER_SAFE_PASSWORD,
            callbackType: SAFE_CALLBACK_MODAL.FETCH_FILES,
            safe
          });
        }
        // переход на страницу сейфов если сейфа нет в списке
      } else {
        navigate(`/${ROUTES.SAFE}${search}`, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    safeId,
    safeDir,
    group,
    sort,
    sort_reverse,
    color.selectedValue,
    emo.selectedValue,
    tags.selectedValue?.length,
    authorized
  ]);

  return (
    <>
      <ElementsList />
      {pickedItems.open && <BottomMenuOption />}
    </>
  );
};

export default SafeFiles;
