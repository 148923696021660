import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import { useIsFilters } from "generalComponents/Services/folderServices/folderServices";
import { LoaderTypes } from "models/generalComponents/loader";
import { FilesNextType } from "models/store/files/files";
import ItemAddedDate from "Pages/Cabinet/Components/WorkElements/ItemAddedDate/ItemAddedDate";
import React from "react";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

import LinesPreviewList from "../LinesPreviewList/LinesPreviewList";
import styles from "./RenderColumns.module.sass";

interface IRenderColumnsProps {
  items: FilesNextType;
}

const RenderColumns: React.FC<IRenderColumnsProps> = ({ items }): JSX.Element => {
  const { __ } = useLocales();
  const isFilters = useIsFilters();
  const { loader } = useFilesSelectors();
  if (loader) {
    return (
      <Loader
        type={LoaderTypes.BOUNCING_DOTS}
        position="absolute"
        background="white"
        zIndex={5}
        width="100px"
        height="100px"
        containerType="bounceDots"
      />
    );
  }
  if (!items) return <></>;

  if (Array.isArray(items)) {
    return items.length > 0 ? (
      <LinesPreviewList items={items} />
    ) : isFilters ? (
      <Clue icon="sad" text={__("Нет файлов соответствующих параметрам фильтрации")} />
    ) : (
      <Clue icon="neutral" text={__("Тут пока пусто")} />
    );
  }

  if (!Array.isArray(items)) {
    return (
      <>
        {Object.values(items).flat().length > 0 ? (
          Object.entries(items)
            .filter(([_, items]) => items.length !== 0)
            .map(
              ([date, item]): JSX.Element => (
                <div key={Number(date)} className={styles.itemWrap}>
                  <ItemAddedDate date={Number(date)} itemsLength={item.length} />
                  <LinesPreviewList items={item} />
                </div>
              )
            )
        ) : isFilters ? (
          <Clue icon="sad" text={__("Нет файлов соответствующих параметрам фильтрации")} />
        ) : (
          <Clue icon="neutral" text={__("Тут пока пусто")} />
        )}
      </>
    );
  }

  return <></>;
};

export default RenderColumns;
