export enum SafeTypes {
  GET_SAFES = "GET_SAFES",
  ADD_SAFE = "ADD_SAFE",
  ADD_AUTHORIZED = "ADD_AUTHORIZED",
  REMOVE_AUTHORIZED = "REMOVE_AUTHORIZED",
  CLEAR_AUTHORIZED = "CLEAR_AUTHORIZED",
  SAFE_FOLDERS = "SAFE_FOLDERS",
  //
  LOAD_SAFE_FILELIST = "LOAD_SAFE_FILELIST",
  CHOOSE_SAFE_FILELIST = "CHOOSE_SAFE_FILELIST",
  AUTHORIZED_SAFE = "AUTHORIZED_SAFE",
  CHOOSE_SAFE_FILES = "CHOOSE_ALL_FILES",
  CUSTOMIZE_SAFE_FILE = "CUSTOMIZE_SAFE_FILE",
  SAFE_FILE_DELETE = "SAFE_FILE_DELETE",
  CODE_TEL = "CODE_TEL"
}
