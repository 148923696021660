import { ReactComponent as FileSizeIcon } from "assets/PrivateCabinet/file_size.svg";
import classNames from "classnames";
import { useGetItemSize } from "generalComponents/Hooks/Files/useGetItemSize";
import { useActions } from "hooks/useActions";
import { SizeTypes } from "models/store/files/sort/sortPayloads";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

import styles from "./TempSize.module.sass";

const TempSize = (): JSX.Element => {
  const { __ } = useLocales();
  const { size } = useFilesSelectors();
  const { onSetFileSize } = useActions();
  const { isSmallSize, isMediumSize, isBigSize } = useGetItemSize();

  const changeSize = (storeSize: SizeTypes): SizeTypes => {
    const sizes =
      window.innerHeight > 693
        ? [SizeTypes.SMALL, SizeTypes.MEDIUM, SizeTypes.BIG]
        : [SizeTypes.SMALL, SizeTypes.MEDIUM];
    if (storeSize === sizes[sizes.length - 1]) return sizes[0];

    return sizes[sizes.indexOf(storeSize) + 1];
  };

  const onButtonClick = (): void => {
    onSetFileSize(changeSize(size));
  };

  return (
    <button
      onClick={onButtonClick}
      className={classNames(styles.button, {
        [styles.isSmallSize]: isSmallSize,
        [styles.isMediumSize]: isMediumSize,
        [styles.isBigSize]: isBigSize
      })}
      type="button"
      title={__("Масштаб")}
    >
      <FileSizeIcon className={styles.icon} />
    </button>
  );
};

export default TempSize;
