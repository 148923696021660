import { IAuthorized, ISafe } from "models/store/safe/safe";
import { useParams } from "react-router-dom";
import { useSafeSelectors } from "Store/selectors/safeSelectors";

export const useSafeFind = (): ISafe => {
  const { safeList } = useSafeSelectors();
  const { safeId } = useParams();
  return safeList.find((safe) => safe.id === safeId);
};

export const useAuthorizedSafe = (): IAuthorized => {
  const { authorized } = useSafeSelectors();
  const { safeId } = useParams();
  const authorizedSafe = authorized.find((safe) => safe.id_safe === safeId);
  return authorizedSafe;
};
