import { ReactComponent as PlayDownIcon } from "assets/PrivateCabinet/arrow-down.svg";
import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/create_arrow.svg";
import { ReactComponent as AddIcon } from "assets/PrivateCabinet/Path.svg";
import { ReactComponent as PlayIcon } from "assets/PrivateCabinet/play-grey.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/plus-3.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { useContextProject, useProjectPath } from "generalComponents/collections/projects";
import ContextMenu from "generalComponents/ContextMenu";
import ContextMenuItem from "generalComponents/ContextMenu/ContextMenuItem";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { setStorageItem } from "generalComponents/Services/storageServices";
import ThreeDots from "generalComponents/ThreeDots/ThreeDots";
import { MODALS } from "generalComponents/variables/global";
import { imageSrc } from "generalComponents/variables/globalVariables";
import { PROJECT_ADMIN_ROLES, PROJECT_CONTEXT_MENU, PROJECT_MODALS } from "generalComponents/variables/project";
import { ButtonVariantType } from "models/generalComponents/button";
import { IMouseParams } from "models/generalComponents/contextMenu";
import { IProjectSideMenuProps } from "models/project/sideMenu";
import React, { useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { onSetModals } from "Store/actions/ModalActions";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { ProjectTypes } from "Store/types/projectTypes";

import styles from "./ProjectSideMenu.module.sass";

const ProjectSideMenu: React.FC<IProjectSideMenuProps> = ({ menuCollapsed, setMenuCollapsed }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const { projects, project } = useProjectsSelectors();
  const { userInfo } = useUserSelectors();
  const pathes = useProjectPath();
  const contextMenu = useContextProject();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mouseParams, setMouseParams] = useState<IMouseParams | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  const onClickDots = (e: React.MouseEvent<HTMLElement>): void =>
    setMouseParams({
      x: e.clientX,
      y: e.clientY,
      width: 200,
      height: 25
    });

  const closeContextMenu = (): void => setMouseParams(null);

  const callbacks: Record<string, () => void> = {
    [PROJECT_CONTEXT_MENU.EDIT_PROJECT]: () =>
      dispatch(
        // TODO - mk - delete after all store is typed
        // @ts-ignore
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.EDIT_PROJECT,
          params: project
        })
      ),
    [PROJECT_CONTEXT_MENU.DEL_PROJECT]: () =>
      // TODO - mk - delete after all store is typed
      // @ts-ignore
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.DEL_PROJECT, params: project }))
  };

  const onAddProject = (): void => {
    dispatch(
      // TODO - mk - delete after all store is typed
      // @ts-ignore
      onSetModals(MODALS.PROJECT, {
        type: PROJECT_MODALS.ADD_PROJECT,
        params: {}
      })
    );
  };

  const onSelectProject = (id: string): void => {
    setIsOpen(false);
    setStorageItem("projectId", id);
    const currentProject = projects.find((item) => item.id === id);
    dispatch({ type: ProjectTypes.CURRENT_PROJECT, payload: currentProject });
    dispatch({ type: ProjectTypes.NULLIFY_PROJECT });
  };

  const toggleColapsed = (): void => setMenuCollapsed((prev) => !prev);

  const onAddTask = (): void => {
    // TODO - mk - delete after all store is typed
    // @ts-ignore
    dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.ADD_TASK, params: {} }));
  };

  return (
    <div className={classNames(styles.wrap, { [styles.wrapCollapse]: menuCollapsed })}>
      <div className={styles.header}>
        {
          <div className={classNames(styles.title, { [styles.collapsed]: menuCollapsed })}>
            {__("Создать новый проект")}
          </div>
        }
        <PlayIcon
          className={classNames(styles.icon, { [styles.iconCollapse]: menuCollapsed })}
          onClick={toggleColapsed}
        />
        <PlusIcon className={classNames(styles.icon, { [styles.collapsed]: menuCollapsed })} onClick={onAddProject} />
      </div>
      <div className={styles.selectBox} ref={ref}>
        <div className={classNames(styles.text, { [styles.collapsed]: menuCollapsed })}>{__("Список проектов")}</div>
        <PlayDownIcon className={styles.iconDown} onClick={() => setIsOpen((prev) => !prev)} />
        {isOpen && (
          <ul className={styles.projectsList}>
            {projects &&
              projects.map((item) => (
                <Link
                  to={`/project/${item.id}/tasks`}
                  key={item.id}
                  onClick={() => onSelectProject(item.id)}
                  className={styles.project}
                >
                  {item.project_icon ? (
                    <img src={item.project_icon} alt="sign" width={39} height={39} className={styles.logo} />
                  ) : (
                    <div className={styles.noLogo} />
                  )}
                  <span className={styles.name}>{item.name}</span>
                  {item.id_fig && <img src={`/assets/PrivateCabinet/signs/${item.id_fig}.svg`} alt="sign" />}
                  {item.id_emo && <img src={`/assets/PrivateCabinet/smiles/${item.id_emo}.svg`} alt="smile" />}
                </Link>
              ))}
          </ul>
        )}
      </div>
      {project.id && (
        <div className={styles.menu}>
          <div className={styles.button}>
            {!menuCollapsed ? (
              <Button
                type="button"
                text={__("Добавить задачу")}
                iconR={<AddIcon />}
                variant={ButtonVariantType.ADD}
                full={true}
                onClick={onAddTask}
              />
            ) : (
              <div className={styles.addBtn} onClick={onAddTask}>
                <AddIcon />
              </div>
            )}
          </div>
          <div className={styles.info}>
            {project.project_icon ? (
              <img src={project.project_icon} alt="sign" width={39} height={39} className={styles.logo} />
            ) : (
              <div className={styles.noLogo} />
            )}
            {!menuCollapsed && (
              <>
                <span className={styles.name}>{project.name}</span>
                {project.id_fig && (
                  <img src={`${imageSrc}assets/PrivateCabinet/signs/${project.id_fig}.svg`} alt="sign" />
                )}
                {project.id_emo && (
                  <img src={`${imageSrc}assets/PrivateCabinet/smiles/${project.id_emo}.svg`} alt="smile" />
                )}
                {project.is_read === PROJECT_ADMIN_ROLES.ADMIN && <ThreeDots onClick={onClickDots} />}
              </>
            )}
          </div>
          <div className={classNames(styles.menuList, `scrollbar-thin-${userInfo.theme}`)}>
            {pathes.map((path, i) => (
              <NavLink
                key={i}
                to={`/project/${project.id}/${path.path}`}
                className={classNames(styles.menuItem, { [styles.menuCollapsed]: menuCollapsed })}
                // TODO -mk- RRD
                // activeClassName={styles.active}
              >
                <div className={styles.menuIconBox}>{path.icon}</div>
                <span className={styles.name}>{path.name}</span>
              </NavLink>
            ))}
          </div>
        </div>
      )}
      {projects.length === 0 && (
        <div className={styles.createImg}>
          <ArrowIcon className={styles.arrow} />
          <p className={styles.text}>{__("Добавьте Ваш первый проект")}</p>
        </div>
      )}
      {mouseParams !== null && (
        <ContextMenu params={mouseParams} setParams={closeContextMenu} tooltip={true}>
          <div className={styles.mainMenuItems}>
            {contextMenu.map((item, i) => (
              <ContextMenuItem
                key={i}
                width={mouseParams.width}
                height={mouseParams.height}
                text={item.name}
                callback={callbacks[item.type]}
                imageSrc={`${imageSrc}assets/PrivateCabinet/contextMenuFile/${item.img}.svg`}
              />
            ))}
          </div>
        </ContextMenu>
      )}
    </div>
  );
};

export default ProjectSideMenu;
