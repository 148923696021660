export type ConfirmCodeTypes =
  | ""
  | "CONFIRM_PHONE"
  | "CHANGE_PHONE"
  | "CHANGE_EMAIL"
  | "CHANGE_PASS"
  | "CHANGE_FOLDER_PASS";

export interface IConfirmCodeModalState {
  open: boolean;
  type: ConfirmCodeTypes;
  callbackSuccess: (code: string) => void;
  callbackRepeat: Function;
  info?: {
    uid?: string;
    newValue?: string;
    prevValue?: string;
    step?: number;
    tel?: string;
    email?: string;
  };
}

export const initialConfirmCodeModalState = (): IConfirmCodeModalState => {
  return { open: false, type: "", callbackSuccess: null, callbackRepeat: null, info: null };
};
