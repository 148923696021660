import classNames from "classnames";
import React, { Dispatch, SetStateAction, useState } from "react";
import { v4 as uuid } from "uuid";

import styles from "./Textarea.module.sass";

interface ITextareaProps {
  className?: string;
  require?: boolean;
  style?: React.CSSProperties;
  value: string;
  onClick?: (e: React.MouseEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  setValue?: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  label?: string;
  error?: boolean;
  errorText?: string;
  name?: string;
}

export const Textarea: React.FC<ITextareaProps> = ({
  className,
  require,
  style,
  value,
  onClick,
  onFocus,
  onChange,
  onBlur,
  onKeyUp,
  disabled,
  readOnly,
  placeholder,
  label,
  error,
  errorText,
  name
}): JSX.Element => {
  const id = uuid();
  const [onTextareaBlur, setOnTextareaBlur] = useState<boolean>(false);

  const handleInputBlur = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    setOnTextareaBlur(true);
    if (onBlur) onBlur(e);
  };

  return (
    <div className={classNames(styles.textareaWrapper, { [styles.isError]: errorText !== undefined })}>
      <textarea
        className={classNames(className, styles.textarea, {
          [styles.error]: onTextareaBlur && error
        })}
        style={style}
        value={value}
        onClick={onClick}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={handleInputBlur}
        onKeyUp={onKeyUp}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        // type={isPass ? (showPassword ? "text" : "password") : type}
        // autoComplete="new-password"
        id={id}
        name={name}
      />
      <label className={styles.label} htmlFor={id}>
        {require && <span className={styles.require}>*</span>} {label}
      </label>
      {error && errorText && <span className={styles.errortext}>{errorText}</span>}
    </div>
  );
};
