import { IProjectTask, TASK_DEPENDENCIES, TASK_PRIORITY, TASK_TYPE } from "models/store/joinProjects/joinProgects";

export const initialsProjectTask = (): IProjectTask => ({
  author: "",
  comments: [],
  date_start: "",
  date_end: "",
  date_long: "",
  dependencies: "",
  duration: 0,
  id: "",
  color: "",
  emo: "",
  id_executor: "",
  id_project: "",
  id_sprints: [],
  id_status: "",
  id_team: "",
  id_type: TASK_TYPE.TASK,
  name: "",
  num: "",
  prim: "",
  priority: TASK_PRIORITY.NORMAL,
  queue: "",
  position: null,
  ut: "",
  tags: [],
  orders: null,
  links: null,
  is_backlog: 0
});
export const getDependencyType2 = (type1: TASK_DEPENDENCIES): TASK_DEPENDENCIES => {
  switch (type1) {
    case TASK_DEPENDENCIES.RELATION:
      return TASK_DEPENDENCIES.RELATION;
    case TASK_DEPENDENCIES.DEPENDS_ON:
      return TASK_DEPENDENCIES.REQUIRED_FOR;
    case TASK_DEPENDENCIES.REQUIRED_FOR:
      return TASK_DEPENDENCIES.DEPENDS_ON;
    case TASK_DEPENDENCIES.PARENT_FOR:
      return TASK_DEPENDENCIES.CHILD_FOR;
    case TASK_DEPENDENCIES.CHILD_FOR:
      return TASK_DEPENDENCIES.PARENT_FOR;

    default:
      break;
  }
};

export const INITIAL_WORK_DAYS: number[] = [1, 2, 3, 4, 5];
export const INITIAL_WORK_HOURS: number[] = [9, 10, 11, 12, 14, 15, 16, 17];

export const hoursTostring = (array: number[]): string[] => {
  let result = [];
  let start = array[0];
  let end = array[0];

  for (let i = 1; i < array.length; i++) {
    if (array[i] === end + 1) {
      end = array[i];
    } else {
      result.push([start, end + 1]);
      start = array[i];
      end = array[i];
    }
  }

  result.push([start, end + 1]);
  const strings = result.map((el) => `${el[0]}:00 - ${el[1]}:00`);

  return strings;
};
