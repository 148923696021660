import { useListEmployees } from "collections/profile";
import { useSystemFolder } from "generalComponents/collections/folder";
import { NavPrevPage } from "generalComponents/NavPrevPage/NavPrevPage";
import { PROFILE_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { DEFAULT_FOLDERS } from "models/generalComponents/folders";
import { IFolder } from "models/store/folders/foldersStore";
import { IPostbox } from "models/store/postbox/postbox";
import { FC } from "react";
import { useJournalSelectors } from "Store/selectors/journalSelectors";

interface INavigationJournalOption {
  actionLogId?: string;
}

export const NavigationJournalOption: FC<INavigationJournalOption> = ({ actionLogId }): JSX.Element => {
  const { events } = useJournalSelectors();

  const listtEmployees = useListEmployees();
  const SYSTEM_FOLDERS = useSystemFolder();

  const renderPageName = () => {
    if (actionLogId && !!events?.length) {
      const currentObj = events?.find(({ event_object }) => event_object === actionLogId);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const userName = listtEmployees?.find(({ value }) => value === currentObj?.object?.id)?.name;

      const folderObj = currentObj?.object as IFolder;

      if (Boolean(folderObj?.is_dir) && folderObj?.is_system === "1") {
        return SYSTEM_FOLDERS[folderObj?.name as DEFAULT_FOLDERS].nameRu;
      }

      return (
        currentObj?.object?.name ||
        (!!listtEmployees?.length && userName) ||
        (currentObj?.data?.old as IPostbox)?.email ||
        currentObj?.data?.old?.name
      );
    }
  };

  return <NavPrevPage title={renderPageName()} defaultPage={`${ROUTES.PROFILE}/${PROFILE_ROUTES.ACTIONS_LOG}`} />;
};
