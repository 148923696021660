import classNames from "classnames";
import { DragEvent, FC, ReactNode } from "react";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./WorkSpaceWrap.module.sass";

interface IWorkSpaceWrapProps {
  children?: ReactNode;
  className?: string;
  isDrag?: boolean;
  isBarsView?: boolean;
  isLinesView?: boolean;
  onDragStart?: (e: DragEvent<HTMLDivElement>) => void;
  onDragOver?: (e: DragEvent<HTMLDivElement>) => void;
}

export const WorkSpaceWrap: FC<IWorkSpaceWrapProps> = ({
  children,
  className,
  isDrag,
  isBarsView,
  isLinesView,
  onDragStart,
  onDragOver
}) => {
  const { theme } = useUserSelectors();

  return (
    <div
      className={classNames(styles.root, className, `scrollbar-medium-${theme}`, {
        [styles.marginTop]: isBarsView || isLinesView,
        [styles.bgnd]: isDrag
      })}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
    >
      {children}
    </div>
  );
};
