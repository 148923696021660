import api from "api";
import Button from "generalComponents/Button/Button";
import EmployeeData from "generalComponents/Modals/Components/ProfileModals/AddEmployee/EmployeeData/EmployeeData";
import {
  DataFormTypes,
  editData,
  reducerData
} from "generalComponents/Modals/Components/ProfileModals/AddEmployee/lib/stateReducer";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { PROFILE_MODALS } from "models/store/Cabinet/modals/profileModals";
import { IEditUserUnfoPayload } from "models/store/user/userPayloads";
import { useEffect, useMemo, useReducer, useState } from "react";
import { useLocales } from "react-localized";
import { useLocation } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { dataURLToFileSize } from "utils/filesConvert";

import styles from "./AboutMe.module.sass";

const AboutMe = () => {
  const { __ } = useLocales();
  const { onSetConfirmCodeModal, onSetTopMessageModal, onEditUserInfo, onSetProfileModal } = useActions();
  const { userInfo, uid } = useUserSelectors();
  const [data, dispatchData] = useReducer(reducerData, editData(userInfo));
  const [isEditTel, setIsEditTel] = useState<boolean>(false);
  const [isEditEmail, setIsEditEmail] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (!userInfo.email) return;
    const employee = editData(userInfo);
    dispatchData({ type: DataFormTypes.NEW_EMPLOYEE, employee });
  }, [userInfo]); //eslint-disable-line

  useEffect(() => {
    if (!data.tel) return setIsEditTel(false);
    if (data.tel !== `+${userInfo.tel}`) setIsEditTel(true);
    else setIsEditTel(false);
  }, [data.tel, userInfo.tel]);

  useEffect(() => {
    if (!data.email) return setIsEditEmail(false);
    if (data.email !== userInfo.email) setIsEditEmail(true);
    else setIsEditEmail(false);
  }, [data.email, userInfo.email]);

  const isVisibleSave = useMemo(() => {
    const { fname, sname, pname, tel, email, icon, tels, emails, gmt, is_gmt_auto } = userInfo;
    if (
      data.fname !== fname ||
      data.sname !== sname ||
      data.pname !== pname ||
      data.tel.replace("+", "") !== tel.replace("+", "") ||
      data.email !== email ||
      data.icon !== `${projectSrc + icon}`
    )
      return true;
    const _tels = Object.values(data.tels);
    if (
      (_tels[0] && !tels.includes(_tels[0]?.replace("+", ""))) ||
      (_tels[1] && !tels.includes(_tels[1]?.replace("+", ""))) ||
      (_tels[2] && !tels.includes(_tels[2]?.replace("+", "")))
    )
      return true;
    const _emails = Object.values(data.emails);
    if (
      (_emails[0] && !emails.includes(_emails[0]?.replace("+", ""))) ||
      (_emails[1] && !emails.includes(_emails[1]?.replace("+", ""))) ||
      (_emails[2] && !emails.includes(_emails[2]?.replace("+", ""))) ||
      _emails.length !== emails.length
    )
      return true;
    if (data.gmt !== gmt) return true;
    if (data.is_gmt_auto !== is_gmt_auto) return true;
    return false;
  }, [
    data.email,
    data.emails,
    data.fname,
    data.icon,
    data.pname,
    data.sname,
    data.tel,
    data.tels,
    data.is_gmt_auto,
    data.gmt,
    userInfo
  ]);

  const onChangeEmail = async () => {
    try {
      const params = {
        uid,
        email: userInfo.email ? userInfo.email : data.email,
        action: "send"
      };
      await api.get("/ajax/user_confirm.php?", { params });
      onSetConfirmCodeModal({
        open: true,
        type: "CHANGE_EMAIL",
        callbackSuccess: () => setIsEditEmail(false),
        callbackRepeat: () => onChangeEmail(),
        info: {
          uid,
          newValue: data.email,
          prevValue: userInfo.email,
          step: 1
        }
      });
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  const onChangeTel = async () => {
    try {
      const params = {
        uid,
        tel: userInfo.tel ? userInfo.tel : data.tel.replace("+", ""),
        action: "send"
      };
      await api.get("/ajax/user_confirm.php?", { params });
      onSetConfirmCodeModal({
        open: true,
        type: userInfo.tel ? "CHANGE_PHONE" : "CONFIRM_PHONE",
        callbackSuccess: () => setIsEditTel(false),
        callbackRepeat: () => onChangeTel(),
        info: {
          uid,
          newValue: data.tel,
          prevValue: `+${userInfo.tel}`,
          step: userInfo.tel ? 1 : 0
        }
      });
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  const onChangePass = () => {
    onSetProfileModal({ open: true, type: PROFILE_MODALS.CHANGE_PASSWORD });
  };

  const onChangePassCode = async () => {
    try {
      if (userInfo.tel && userInfo.email) {
        const params = {
          uid,
          tel: userInfo.tel,
          action: "send"
        };
        await api.get("/ajax/user_confirm.php?", { params });
        onSetConfirmCodeModal({
          open: true,
          type: "CHANGE_PASS",
          callbackSuccess: () => onChangePass(),
          callbackRepeat: () => onChangePassCode(),
          info: {
            uid,
            tel: userInfo.tel,
            email: userInfo.email,
            step: 0
          }
        });
      }
      if (!userInfo.tel) {
        const params = {
          uid,
          email: userInfo.email,
          action: "send"
        };
        await api.get("/ajax/user_confirm.php?", { params });
        onSetConfirmCodeModal({
          open: true,
          type: "CHANGE_PASS",
          callbackSuccess: () => onChangePass(),
          callbackRepeat: () => onChangePassCode(),
          info: {
            uid,
            tel: userInfo.tel,
            email: userInfo.email,
            step: 0
          }
        });
      }
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  const onSave = async () => {
    const tels = Object.values(data.tels)
      .map((t) => t.replace("+", ""))
      .filter((v) => v);
    const emails = Object.values(data.emails).filter((v) => v);

    let icon = null;
    if (data.icon.startsWith("data:image")) {
      icon = await dataURLToFileSize(data.icon, 50, 50, "jpeg");
    }
    const params: IEditUserUnfoPayload = {
      data: {
        fname: data.fname,
        sname: data.sname,
        pname: data.pname,
        email: data.email,
        gmt: data.gmt,
        is_gmt_auto: data.is_gmt_auto,
        tel: data.tel.replace("+", "") !== userInfo.tel ? data.tel.replace("+", "") : undefined,
        emails: JSON.stringify(emails),
        tels: JSON.stringify(tels),
        icon: icon,
        icon_del: Boolean(!data.icon)
      },
      message: { success: __("Сохранено"), error: __("Номер телефона уже используется") }
    };

    onEditUserInfo(params);
  };

  const onChangeTimeZone = (value: string) => {
    dispatchData({ type: DataFormTypes.CHANGE_TIMEZONE, value });
  };

  const onChangeAutoTimeZone = (boolValue: boolean) => {
    dispatchData({ type: DataFormTypes.CHANGE_AUTO_TIMEZONE, boolValue });
  };

  return (
    <WorkAreaWrap>
      <div className={styles.wrap}>
        <div className={styles.form}>
          <EmployeeData
            data={data}
            dispatchData={dispatchData}
            isEditEmail={isEditEmail}
            onChangeEmail={onChangeEmail}
            isEditTel={isEditTel}
            onChangeTel={onChangeTel}
            onChangeTimeZone={onChangeTimeZone}
            onChangeAutoTimeZone={onChangeAutoTimeZone}
            isEdit
            isActiveTel={location?.state?.activeTel}
          />
          <div className={styles.pass}>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.MEDIUM}
              text={__("Изменить пароль")}
              onClick={onChangePassCode}
            />
          </div>
          {isVisibleSave && (
            <div className={styles.footer}>
              <Button
                variant={ButtonVariantType.BLUE}
                size={ButtonSizeType.MEDIUM}
                text={__("Сохранить")}
                onClick={onSave}
                disabled={!data.fname || isEditTel || isEditEmail}
              />
            </div>
          )}
        </div>
      </div>
    </WorkAreaWrap>
  );
};

export default AboutMe;
