import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { LETTERS_LIMIT } from "generalComponents/variables/globalVariables";
import { ROUTES } from "generalComponents/variables/routing";
import { ITopMessages } from "models/common/common";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { initialPostboxModalState } from "models/store/Cabinet/modals/postboxModal";
import { ILetterInfo, IPostbox } from "models/store/postbox/postbox";
import {
  IAddNewLetters,
  IAddPostbox,
  ICancelChooseLetters,
  ICancelChooseScheduleLetters,
  IChangeFlag,
  IChangeName,
  IChangePassword,
  IChangeUnread,
  IChooseLetters,
  IClearLetters,
  IDecreaseUnread,
  IDeleteLetter,
  IIncreaseUnread,
  INullifySelected,
  IRemovePostbox,
  ISelectAll,
  ISetLetters,
  ISetLoader,
  ISetPostboxes,
  ISetSearchLetter,
  IUpdatePostboxFolders,
  IUpdatePostboxFoldersPayload,
  IUpdateUnread,
  IUpdateUnreadPayload,
  PostboxActions
} from "models/store/postbox/postboxActions";
import { MailboxRegError } from "models/store/postbox/postboxErrors";
import {
  IChangeFlagMail,
  IGetPostboxMailsPayload,
  IMoveLetter,
  IOnAddNewPostboxPayload,
  IOnEditPostboxFolderPayload,
  IOnEnterPostbox,
  IOnExitPostboxPayload,
  IOnSendEmailPayload,
  IRemoveLetter,
  IUpdateInboxPayload,
  OrderByList
} from "models/store/postbox/postboxPayloads";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { PostboxTypes } from "Store/types/postboxTypes";

import {
  onSetErrorModal,
  onSetLoaderModal,
  onSetPostboxModal,
  onSetSuccessModal,
  onSetTopMessageModal
} from "./ModalActions";

// POSTBOXES
export const onFetchPostboxes =
  (
    message?: string,

    controller?: AbortController
  ): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid
      };
      const { data } = await api.get("/ajax/email_list.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetPostboxes(data.emails));
    } catch {
      if (!controller?.signal.aborted) {
        dispatch(
          onSetErrorModal({
            open: true,
            message
          })
        );
      }
    }
  };

export const onCreatePostbox =
  (payload: IOnAddNewPostboxPayload): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        email: payload.email,
        pass: payload.password,
        name: payload.name
      };
      const { data } = await api.get(`/ajax/email_reg.php`, { params });

      if (data.error) {
        if (data.error === MailboxRegError.EMAIL_NAME_EXISTS) {
          dispatch(
            onSetTopMessageModal({
              open: true,
              type: ITopMessageTypes.ERROR,
              message: payload.message.error_email_name_exists
            })
          );
          return;
        }
        if (data.error === MailboxRegError.UNKNOWN_DOMAIN) {
          dispatch(
            onSetTopMessageModal({
              open: true,
              type: ITopMessageTypes.ERROR,
              message: payload.message.error_email_unknown_domain
            })
          );
          return;
        }
        throw new Error();
      }
      checkResponseStatus(data.ok);
      dispatch(onSetPostboxModal(initialPostboxModalState()));
      dispatch(onAddPostbox(data?.postbox));
      dispatch(
        onSetSuccessModal({
          open: true,
          title: payload.message.title,
          message: payload.message.success
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR,
          message: payload.message.error_unknown_error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onEnterPostbox =
  (payload: IOnEnterPostbox): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        email: payload.email,
        pass: payload.pass,
        server: payload.server,
        name: payload.name
      };
      const { data } = await api.get(`/ajax/email_enter.php`, { params });
      if (data.error) {
        if (data.error === MailboxRegError.ERROR_LOGIN) {
          dispatch(
            onSetTopMessageModal({
              open: true,
              type: ITopMessageTypes.ERROR,
              message: payload.messages.errorLogin
            })
          );
          return;
        }
        if (data.error === MailboxRegError.AGENT_IS_BLOCK) {
          dispatch(
            onSetTopMessageModal({
              open: true,
              type: ITopMessageTypes.ERROR,
              message: payload.messages.errorBlocked
            })
          );
          return;
        }
        if (data.error === MailboxRegError.EMAIL_NAME_EXISTS) {
          dispatch(
            onSetTopMessageModal({
              open: true,
              type: ITopMessageTypes.ERROR,
              message: payload.messages.errorExist
            })
          );
          return;
        }
        throw new Error();
      }
      dispatch(onAddPostbox(data?.postbox));
      dispatch(onSetPostboxModal(initialPostboxModalState()));
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          message: payload.messages.succes
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR,
          message: payload.messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onExitPostbox =
  (
    payload: IOnExitPostboxPayload,
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        email: payload.email,
        pass: payload.pass,
        id_agent: payload.id_agent
      };
      const { data } = await api.get("/ajax/email_close.php", { params });
      checkResponseStatus(data.ok);
      dispatch(onRemovePostbox(payload.email));
      dispatch(onSetPostboxModal(initialPostboxModalState()));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: messages.success }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: messages.error }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onDeletePostbox =
  (
    payload: IOnExitPostboxPayload,
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));

      const params = {
        uid: getState().user.uid,
        email: payload.email,
        pass: payload.pass
      };
      const { data } = await api.get("/ajax/email_folders_del.php", { params });
      checkResponseStatus(data.ok);
      dispatch(onRemovePostbox(payload.email));
      dispatch(onSetPostboxModal(initialPostboxModalState()));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: messages.success }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: messages.error }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

// POSTBOX FOLDERS

export const onCreateFolderPostbox =
  (payload: IOnEditPostboxFolderPayload): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        user: payload.email,
        pass: payload.pass,
        folder: payload.folder
      };
      const { data } = await api.get(`/ajax/email_folder_add.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onUpdateMailFolders({ email: payload.email, folders: data.data.folders }));
      dispatch(onSetPostboxModal(initialPostboxModalState()));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.ADD }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRenameFolderPostbox =
  (
    payload: IOnEditPostboxFolderPayload,
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: getState().user.uid,
        user: payload.email,
        pass: payload.pass,
        folder: payload.folder,
        folder_new: payload.folder_new
      };
      const { data } = await api.get(`/ajax/email_folder_rename.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onUpdateMailFolders({ email: payload.email, folders: data.data.folders }));
      dispatch(onSetPostboxModal(initialPostboxModalState()));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: messages.success }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: messages.error }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onRemoveFolderPostbox =
  (
    payload: IOnEditPostboxFolderPayload,
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        user: payload.email,
        pass: payload.pass,
        folder: payload.folder
      };
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/email_folder_del.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onUpdateMailFolders({ email: payload.email, folders: data.data.folders }));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: messages.success }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: messages.error }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

// MAILS
export const onSendEmail =
  (
    formData: FormData,
    payload?: IOnSendEmailPayload
  ): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch) => {
    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.post(`/ajax/email_send.php`, formData);
      checkResponseStatus(data.ok);
      payload.cb && payload.cb(data.message);
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.MESSAGE,
          message: payload?.messages.success
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR,
          message: payload?.messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const fetchPostboxMails =
  (
    payload: IGetPostboxMailsPayload,
    controller?: AbortController
  ): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoader(true));
      const params = {
        uid: getState().user.uid,
        user: payload.user,
        pass: payload.pass,
        folder: payload.folder,
        limit: payload.limit,
        start: payload.start,
        orderBy: payload.orderBy ?? OrderByList.SORT_DATE,
        is_unseen: payload.is_unseen,
        is_favorite: payload.is_favorite
      };
      const url = payload.isSchedule ? `/ajax/email_send_delay_list.php` : `/ajax/email_messages_get.php`;
      const { data } = await api.get(url, { params, signal: controller?.signal });
      dispatch(onSetLetters(payload.isSchedule ? data.data.email_send_delay : data.data.messages));
      if (payload.folder === POSTBOX_SYSTEM_FOLDER.INBOX) {
        dispatch(onUpdateUnread({ email: payload.user, info: data.data.folder_info, folder: payload.folder }));
      }
    } catch {
      if (payload.messages) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR,
            message: payload.messages.error
          })
        );
      }
    } finally {
      dispatch(onSetLoader(false));
    }
  };

export const onUpdateInbox =
  (payload: IUpdateInboxPayload): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      user: payload.user,
      pass: payload.pass,
      folder: POSTBOX_SYSTEM_FOLDER.INBOX,
      limit: payload.limit,
      start: payload.start,
      orderBy: OrderByList.SORT_DATE,
      is_unseen: payload.is_unseen,
      is_favorite: payload.is_favorite
    };
    const { data } = await api.get(`/ajax/email_messages_get.php`, { params });

    const isUpdate = window.location.pathname === `/${ROUTES.MAIL}/${payload.userId}/${POSTBOX_SYSTEM_FOLDER.INBOX}`;
    isUpdate && dispatch(onAddNewLetters(data.data.messages));
    dispatch(onUpdateUnread({ email: payload.user, info: data.data.folder_info, folder: POSTBOX_SYSTEM_FOLDER.INBOX }));
  };

export const onChangeStandartFlagMail =
  (payload: IChangeFlagMail, message: string): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        ...payload.data,
        uid: getState().user.uid
      };
      const { data } = await api.get(`/ajax/email_messages_flag_set.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onChangeFlag(payload.value.flag, +payload.value.val, data.message_nums));

      const obj: IChangeUnread = {
        amount: payload.value.unreadAmount || 1,
        email: payload.data.user,
        folder: payload.value.folder
      };
      if (payload.value.flag === "seen") {
        if (payload.value.val === "0") dispatch(onIncreaseUnread(obj));
        if (payload.value.val === "1") dispatch(onDecreaseUnread(obj));
      }
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message }));
    }
  };

export const onChangeCustomFlagMail =
  (payload: IChangeFlagMail, message: string): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        ...payload.data,
        uid: getState().user.uid
      };
      const { data } = await api.get(`/ajax/email_message_flag_set.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onChangeFlag(payload.value.flag, +payload.value.val, data.message_nums));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message }));
    }
  };
export const onMoveLetter =
  (payload: IMoveLetter, message: string): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        ...payload,
        uid: getState().user.uid
      };

      const { data } = await api.get(`/ajax/email_mail_move.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onDeleteLetter(data.message_nums));
      // dispatch(fetchPostboxFolders(getState().Postbox.postboxes));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message }));
    }
  };

export const onRemoveLetter =
  (payload: IRemoveLetter, message: string): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        ...payload,
        uid: getState().user.uid
      };

      const { data } = await api.get(`/ajax/email_message_del.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onDeleteLetter(data.message_nums));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message }));
    }
  };

export const onMoveToSpan =
  (
    email: string,
    postbox: IPostbox,
    message: string
  ): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        email,
        uid: getState().user.uid
      };

      const { data } = await api.get(`/ajax/email_spam_add.php`, { params });
      checkResponseStatus(data.ok);
      const payload = {
        user: postbox.email,
        pass: postbox.pass,
        folder: POSTBOX_SYSTEM_FOLDER.INBOX,
        limit: LETTERS_LIMIT,
        start: 0
      };
      dispatch(fetchPostboxMails(payload));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message }));
    }
  };

export const onSendEmailDelay =
  (
    formData: FormData,
    isUpdate: boolean,
    payload?: IOnSendEmailPayload
  ): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch) => {
    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.post(`/ajax/email_send_delay.php`, formData);
      checkResponseStatus(data.ok);
      if (isUpdate) {
        dispatch(onClearLetters());
        dispatch(onSetLetters(data.data.email_send_delay));
      }
      dispatch(onIncreaseUnread({ amount: 1, email: payload.user, folder: POSTBOX_SYSTEM_FOLDER.SCHEDULED }));
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.MESSAGE,
        message: payload?.messages.success
      });
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR,
          message: payload?.messages.error
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onLetterToDraft =
  (
    formData: FormData,
    messages: ITopMessages,
    callback: () => void,
    isUpdate?: boolean
  ): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onSetLoaderModal(true));
      formData.append("uid", getState().user.uid);

      const { data } = await api.post("/ajax/email_append.php", formData);
      checkResponseStatus(data.ok);
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.SUCCESS,
        message: messages.success
      });
      callback();
      if (isUpdate) {
        dispatch(onClearLetters());
        dispatch(onSetLetters(data.data.messages));
      }
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR,
        message: messages.error
      });
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onScheduleDelete =
  (id: string[], messages?: ITopMessages): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_items: id
      };

      const { data } = await api.get(`/ajax/email_send_delay_del.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onClearLetters());
      dispatch(onSetLetters(data.data.email_send_delay));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: messages.success }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: messages.error }));
    }
  };

export const onScheduleEdit =
  (
    formData: FormData,
    messages: ITopMessages
  ): ThunkAction<void, RootState, unknown, PostboxActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      formData.append("uid", getState().user.uid);

      const { data } = await api.post(`/ajax/email_send_delay_edit.php`, formData);
      checkResponseStatus(data.ok);
      dispatch(onClearLetters());
      dispatch(onSetLetters(data.data.email_send_delay));
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: messages.success }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: messages.error }));
    }
  };

export const onSetPostboxes = (payload: IPostbox[]): ISetPostboxes => ({
  type: PostboxTypes.SET_POSTBOXES,
  payload
});

export const onAddPostbox = (payload: IPostbox): IAddPostbox => ({
  type: PostboxTypes.ADD_POSTBOX,
  payload
});

export const onRemovePostbox = (payload: string): IRemovePostbox => ({
  type: PostboxTypes.REMOVE_POSTBOX,
  payload
});

export const onUpdateMailFolders = (payload: IUpdatePostboxFoldersPayload): IUpdatePostboxFolders => ({
  type: PostboxTypes.UPDATE_POSTBOX_FOLDERS,
  payload
});

export const onSetLoader = (payload: boolean): ISetLoader => ({
  type: PostboxTypes.SET_LOADER,
  payload
});
export const onSetLetters = (payload: ILetterInfo[]): ISetLetters => ({
  type: PostboxTypes.SET_LETTERS,
  payload
});
export const onClearLetters = (): IClearLetters => ({
  type: PostboxTypes.CLEAR_LETTERS
});
export const onChangeFlag = (flag: string, value: number, uid: string[]): IChangeFlag => ({
  type: PostboxTypes.CHANGE_FLAG,
  payload: { flag, value, uid }
});
export const onDeleteLetter = (payload: number[]): IDeleteLetter => ({
  type: PostboxTypes.DELETE_LETTER,
  payload
});

export const onChooseLetters = (payload: ILetterInfo): IChooseLetters => ({
  type: PostboxTypes.CHOOSE_LETTER,
  payload
});

export const onCancelChooseLetters = (payload: number): ICancelChooseLetters => ({
  type: PostboxTypes.CANCEL_CHOOSE_LETTER,
  payload
});

export const onCancelChooseScheduleLetters = (payload: string): ICancelChooseScheduleLetters => ({
  type: PostboxTypes.CANCEL_CHOOSE_SCHEDULE_LETTER,
  payload
});

export const onSelectAllLetters = (): ISelectAll => ({
  type: PostboxTypes.SELECT_ALL
});

export const onNullifyAllLetters = (): INullifySelected => ({
  type: PostboxTypes.NULLIFY_SELECTED
});

export const onChangePasswordPostbox = (pass: string, email: string): IChangePassword => ({
  type: PostboxTypes.CHANGE_PASSWORD_POSTBOX,
  payload: { pass, email }
});

export const onChangeNamePostbox = (name: string, email: string): IChangeName => ({
  type: PostboxTypes.CHANGE_NAME_POSTBOX,
  payload: { name, email }
});

export const onIncreaseUnread = (payload: IChangeUnread): IIncreaseUnread => ({
  type: PostboxTypes.INCREASE_UNREAD,
  payload
});

export const onDecreaseUnread = (payload: IChangeUnread): IDecreaseUnread => ({
  type: PostboxTypes.DECREASE_UNREAD,
  payload
});

export const onUpdateUnread = (payload: IUpdateUnreadPayload): IUpdateUnread => ({
  type: PostboxTypes.UPDATE_UNREAD,
  payload
});

export const onAddNewLetters = (payload: ILetterInfo[]): IAddNewLetters => ({
  type: PostboxTypes.ADD_NEW_LETTERS,
  payload
});

export const onSetSearchLetter = (payload: ILetterInfo): ISetSearchLetter => ({
  type: PostboxTypes.SET_SEARCH_LETTER,
  payload
});
