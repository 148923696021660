import { IProjectTaskForm, MessageType } from "models/chat/TaskMessage";
import { useLocales } from "react-localized";
import { getContacts } from "Store/actions/userAction";

import api from "../../api";
import { IConnectedUsersToProfileAndOthers } from "../../models/generalComponents/services/chatServices";
import { ICallRoomConnectedUser } from "../../models/store/chat/chat";
import { useMonths } from "../Calendars/CalendarHelper";

// TODO - mkortelov - move to chat actions
export const contactDelete = async (
  contact: any,
  id_company: string,
  dispatch: any,
  uid: string,
  nullifyAction: () => void
): Promise<void> => {
  nullifyAction();
  const addOrgParams = () => (id_company ? `&id_company=${id_company}` : "");
  api
    .post(`/ajax/${id_company ? "org_" : ""}contacts_del.php?uid=${uid}&id=${contact.id}${addOrgParams()}`)
    .then((res) => {
      if (res.data.ok) {
        dispatch(getContacts());
      } else {
        console.log(res.data.error);
      }
    })
    .catch((err) => console.log(err));
};

const gtmToString = (gmt: number): string => {
  if (gmt > 9) return `+${gmt}:00`;
  if (gmt > 0) return `+0${gmt}:00`;
  if (gmt === 0) return `+00:00`;
  if (gmt < 0) return gmt < -9 ? `${gmt}:00` : `-0${gmt * -1}:00`;
  return "";
};

export const useCreateContactStatus = () => {
  const { __ } = useLocales();
  const months = useMonths();
  return (isUser: boolean, currentDate: Date, contactLastVisitDate: string, isOnline: number, gmt: number) => {
    if (!isUser) return __("Пользователя нет в системе 4Hub");
    if (!gmt || !contactLastVisitDate || !currentDate) return "";

    const lastVisitWithGmt = new Date(contactLastVisitDate.replace(" ", "T") + gtmToString(gmt));
    const timeToString = lastVisitWithGmt.toLocaleTimeString("ru");
    const lastVisitTime = timeToString.slice(0, timeToString.lastIndexOf(":"));
    const lastVisitDate = lastVisitWithGmt.toLocaleDateString("ru");

    if (currentDate.getFullYear() === lastVisitWithGmt.getFullYear()) {
      //this year
      if (currentDate.getMonth() === lastVisitWithGmt.getMonth()) {
        if (currentDate.getDate() === lastVisitWithGmt.getDate()) {
          //today
          const minutesDifference = (currentDate.getTime() - new Date(lastVisitWithGmt).getTime()) / 60000;
          if (minutesDifference > 12 * 60) {
            // more than 12 hours ago
            return __(`сегодня в ${lastVisitTime}`);
          } else if (minutesDifference <= 60) {
            //less than an hour ago
            const minutes = Math.floor(minutesDifference);
            //contact online
            return minutes < 1 || isOnline === 1 ? __("в сети") : __(`${minutes} мин. назад`);
          } else {
            //more than an hour and less than 12 hours ago
            const hours = Math.floor(minutesDifference / 60);
            let word_ending = __("a");
            if (hours > 4) word_ending = __("ов");
            if (hours === 1) word_ending = "";
            return __(`${hours > 1 ? hours : ""} час${word_ending} назад`);
          }
        } else if (currentDate.getDate() - 1 === lastVisitWithGmt.getDate()) {
          //yesterday
          return __(`вчера в ${lastVisitTime}`);
        }
      }
      return __(`был в сети ${lastVisitWithGmt.getDate()} ${months()[lastVisitWithGmt.getMonth()].declensionName}`);
    }
    //not this year
    return __(`был в сети ${lastVisitDate}`);
  };
};

export const useMessageTime = () => {
  const { __ } = useLocales();
  return (currentDate: Date, message_ut: string, gmt: number) => {
    if (!gmt || !message_ut || !currentDate) return "";

    const date = new Date(message_ut.replace(" ", "T") + gtmToString(gmt));
    const time = date.toLocaleTimeString("ru");
    const minutesDifference = (currentDate.getTime() - date.getTime()) / 60000;
    if (minutesDifference >= 60) {
      return time.slice(0, time.lastIndexOf(":"));
    } else {
      return minutesDifference >= 1
        ? __(`${Math.floor(minutesDifference)} мин назад`)
        : minutesDifference < 0.5
        ? __("только что")
        : __("менее минуты назад");
    }
  };
};

export const wantMimeType = (constraints: { video: boolean }): string => {
  return constraints.video
    ? MediaRecorder.isTypeSupported("video/webm;codecs=vp8,opus")
      ? "video/webm;codecs=vp8,opus"
      : "video/mp4"
    : MediaRecorder.isTypeSupported("audio/webm;codecs=opus")
    ? "audio/webm;codecs=opus"
    : "audio/mp3";
};

export const cameraAccess = async (constraints = { audio: true, video: true }) => {
  // @ts-ignore
  navigator.getUserMedia =
    // @ts-ignore
    navigator.getUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.webkitGetUserMedia;

  if (navigator.mediaDevices && MediaRecorder.isTypeSupported(wantMimeType(constraints))) {
    return navigator.mediaDevices.getUserMedia(constraints);
  }
  return false;
};

export const ducationTimerToString = (seconds: number): string =>
  `${Math.floor(seconds / 60) < 10 ? `0${Math.floor(seconds / 60)}` : Math.floor(seconds / 60)}:${
    seconds % 60 < 10 ? `0${Math.floor(seconds % 60)}` : Math.floor(seconds % 60)
  }`;

export const themes = [
  {
    name: "white",
    background: "#fff",
    textColor: "#49494B",
    iconColor: "#B8B8B8",
    inputBgColor: "#F7F7F7",
    inputColor: "#AEAEAE",
    accentColor: ""
  },
  {
    name: "dark",
    background: "#323232",
    textColor: "#fff",
    iconColor: "#fff",
    inputBgColor: "#292929",
    inputColor: "#fff",
    accentColor: "#272727"
  }
];

export const imageFormats = ["JPEG", "JPG", "PNG"];

export const calcImageSize = (parent: Element, imageCount: number): { width: number; height: number } => {
  const parentWidth = parent?.clientWidth;
  if (!parentWidth || !imageCount) return { width: 0, height: 0 };
  const padding = parseInt(getComputedStyle(parent, null).getPropertyValue("padding")) ?? 0;
  const calcRowsCount = () => {
    if (imageCount < 6) return 1;
    else if (imageCount < 12) return 2;
    else if (imageCount < 16) return 3;
    else return 4;
  };
  const width = (parentWidth - padding * 2) / Math.ceil(imageCount / calcRowsCount());
  const height = (width * 3) / 4;

  return { width, height };
};

export const initialProjectTaskForm = (): IProjectTaskForm => ({
  name: "",
  type: MessageType.TASK,
  tag: "",
  prim: "",
  date_start: "",
  date_end: "",
  date_long: "",
  id_act: "",
  id_executor: "",
  id_project: "",
  id_parent: "",
  id_task: "",
  id_status: "",
  sort: "",
  author: "",
  uploadFiles: [],
  fids: [],
  is_epic: 0,
  id_epic: "",
  tasksChildren: [],
  is_backlog: 0, // -1 - task goes to backlog, 0 - task doesn't go to backlog
  text: "New task"
});

export function connectedUsersToProfileAndOthers(
  connectedUsers: ICallRoomConnectedUser[] = [],
  id_user: string
): IConnectedUsersToProfileAndOthers {
  const users_to = connectedUsers.filter((it) => it.id_user !== id_user).map((it) => it.id_user);
  const from = connectedUsers.filter((it) => it.id_user === id_user)[0];

  return { users_to, from };
}

export function createUniqueArrayOfValues(values: string[]): string[] {
  return values.filter((value, index, array) => array.indexOf(value) === index);
}
