import { FilesLinePreview, IFile, IFilesState, initialFilesState } from "models/store/files/files";
import { FilesActions } from "models/store/files/filesActions";
import { SortActions } from "models/store/files/sort/sortActions";
import { IFolder } from "models/store/folders/foldersStore";
import { FilesTypes } from "Store/types/filesTypes";
import { SortTypes } from "Store/types/sortTypes";

const INITIAL_STATE: IFilesState = initialFilesState();

export const FilesReducer = (state = INITIAL_STATE, action: FilesActions | SortActions): IFilesState => {
  switch (action.type) {
    case FilesTypes.SET_MY_FILES: {
      if (state.files) {
        return {
          ...state,
          files: [...state.files, ...action.payload]
        };
      } else {
        return {
          ...state,
          files: action.payload
        };
      }
    }

    case FilesTypes.SET_ADD_FILES:
      return {
        ...state,
        files: [action.payload, ...state.files],
        total: ++state.total
      };

    case FilesTypes.SET_DEL_FILES: {
      const updateFiles = state.files?.filter(
        (e) =>
          (!action.payload.ids.includes((e as IFile)?.fid) && !e.is_dir) ||
          (!action.payload.ids.includes((e as IFolder)?.id_dir) && Boolean(e.is_dir))
      );

      return {
        ...state,
        files: updateFiles,
        total: updateFiles?.length
      };
    }

    case FilesTypes.SET_UPDATE_FILES:
      return {
        ...state,
        files: [
          ...state.files.map((el) => {
            const isFile: boolean = !el.is_dir;
            const isFolder: boolean = Boolean(el.is_dir);

            return (action.payload as IFile[]).some((file) => file?.fid === (el as IFile).fid && isFile)
              ? action.payload.find((item: IFolder | IFile) => (item as IFile)?.fid === (el as IFile).fid && isFile)
              : (action.payload as IFolder[]).some((folder) => folder?.id_dir === (el as IFolder)?.id_dir && isFolder)
              ? action.payload.find(
                  (item: IFolder | IFile) => (item as IFolder)?.id_dir === (el as IFolder)?.id_dir && isFolder
                )
              : { ...el };
          })
        ]
      };

    case FilesTypes.SET_FILES_NEXT: {
      return {
        ...state,
        filesNext: { ...state.filesNext, ...action.payload }
      };
    }

    case FilesTypes.SET_REPLACE_FILE: {
      const indexReplacementFile = state.files.findIndex(
        (el) => el.is_dir === 0 && (el as IFile).fid === state.uploadFiles.replacementFile.fid
      );
      const updateFiles = [...state.files];

      updateFiles.splice(indexReplacementFile, 1, action.payload);

      return {
        ...state,
        files: updateFiles
      };
    }

    case FilesTypes.CHANGE_FILES_NEXT: {
      return {
        ...state,
        filesNext: action.payload
      };
    }

    case FilesTypes.SET_ADD_NEXT_FILES: {
      const id =
        (!action.payload.is_dir && (action.payload as IFile)?.id_dir) ||
        (Boolean(action.payload.is_dir) && (action.payload as IFolder)?.id_parent);

      return {
        ...state,
        filesNext: {
          ...state.filesNext,
          [id]: {
            ...state.filesNext[id],
            files: [action.payload, ...state.filesNext[id].files],
            total: ++state.filesNext[id].total
          }
        }
      };
    }

    case FilesTypes.SET_UPDATE_NEXT_FILES: {
      const updateFilesNext: FilesLinePreview = Object.entries(state.filesNext).reduce(
        (acc, [k, item]) => {
          const id =
            action.payload.find((el) => !el.is_dir && el.id_dir === k)?.id_dir ||
            (action.payload.find((el) => Boolean(el.is_dir) && (el as IFolder).id_parent === k) as IFolder)?.id_parent;

          if (id) {
            return {
              ...acc,
              [id]: {
                ...item,
                files: item.files.map((el) => {
                  const isFile: boolean = !el.is_dir;
                  const isFolder: boolean = Boolean(el.is_dir);

                  return (action.payload as IFile[]).some((file) => file?.fid === (el as IFile).fid && isFile)
                    ? action.payload.find(
                        (item: IFolder | IFile) => (item as IFile)?.fid === (el as IFile).fid && isFile
                      )
                    : (action.payload as IFolder[]).some(
                        (folder) => folder?.id_dir === (el as IFolder)?.id_dir && isFolder
                      )
                    ? action.payload.find(
                        (item: IFolder | IFile) => (item as IFolder)?.id_dir === (el as IFolder)?.id_dir && isFolder
                      )
                    : { ...el };
                })
              }
            };
          }

          return {
            ...acc
          };
        },
        { ...state.filesNext }
      );

      return {
        ...state,
        filesNext: updateFilesNext
      };
    }

    case FilesTypes.SET_REPLACE_NEXT_FILE: {
      const id: string = !state.uploadFiles.replacementFile.is_dir && state.uploadFiles.replacementFile.id_dir;

      const indexReplacementFile: number = state.filesNext[id].files.findIndex(
        (el) => el.is_dir === 0 && (el as IFile).fid === state.uploadFiles.replacementFile.fid
      );
      const updateFiles = [...state.filesNext[id].files];

      updateFiles.splice(indexReplacementFile, 1, action.payload);

      return {
        ...state,
        filesNext: {
          ...state.filesNext,
          [id]: {
            ...state.filesNext[id],
            files: updateFiles
          }
        }
      };
    }

    case FilesTypes.SET_DEL_NEXT_FILES: {
      const updateFilesNext: FilesLinePreview = Object.entries(state.filesNext).reduce(
        (acc, [k, item]) => {
          const id =
            action.payload.find((el) => !el.is_dir && el.id_dir === k)?.id_dir ||
            (action.payload.find((el) => Boolean(el.is_dir) && (el as IFolder).id_parent === k) as IFolder)?.id_parent;

          if (id) {
            const updateFiles = item.files?.filter(
              (e) =>
                !action.payload.some(
                  (file) =>
                    (Boolean(e.id_dir) && file.is_dir && (file as IFile).id_dir === (e as IFile).id_dir) ||
                    (!e.is_dir && !file.is_dir && (file as IFile).fid === (e as IFile).fid)
                )
            );

            return {
              ...acc,
              [id]: {
                ...item,
                files: updateFiles,
                total: updateFiles?.length
              }
            };
          }

          return {
            ...acc
          };
        },
        { ...state.filesNext }
      );

      return {
        ...state,
        filesNext: updateFilesNext
      };
    }

    case FilesTypes.SET_TOTAL_ITEMS:
      return { ...state, total: action.payload };

    case FilesTypes.SET_PAGE:
      return { ...state, page: action.payload };

    case FilesTypes.RESET_FILES_LIST:
      return { ...state, page: 1, files: null, total: 0 };

    case FilesTypes.FILES_LOADER:
      return { ...state, loader: action.payload };

    case FilesTypes.SET_PICKED_ITEMS: {
      return { ...state, pickedItems: action.payload };
    }

    case FilesTypes.SET_CURRENT_ITEM:
      return { ...state, currentItem: action.payload };

    // FILES SORT && FILTER
    case SortTypes.SET_SORT_FILE: {
      return {
        ...state,
        files: null,
        filesNext: null,
        page: 1
      };
    }

    case SortTypes.SET_SIZE:
      return {
        ...state,
        size: action.payload
      };

    case SortTypes.SET_WORK_ELEMENTS_VIEW:
      return {
        ...state,
        view: action.payload
      };

    case FilesTypes.NULLIFY_FILES: {
      return {
        ...INITIAL_STATE
      };
    }

    case FilesTypes.SET_SELECTED_UPLOAD_REPLACEMENT_FILE:
      return {
        ...state,
        uploadFiles: { ...state.uploadFiles, replacementFile: action.payload }
      };

    case FilesTypes.SET_SELECTED_FILES:
      return { ...state, uploadFiles: { ...state.uploadFiles, selectedFiles: action.payload } };

    case FilesTypes.SET_LOADING_FILES:
      return {
        ...state,
        uploadFiles: { ...state.uploadFiles, loadingFiles: [action.payload, ...state.uploadFiles.loadingFiles] }
      };

    case FilesTypes.CHANGE_LOADING_FILES:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          loadingFiles: [
            ...state.uploadFiles.loadingFiles.map((el) => {
              return el.id === action.payload.id ? { ...el, ...action.payload } : { ...el };
            })
          ]
        }
      };
    case FilesTypes.CANCEL_FILES_UPLOAD: {
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          loadingFiles: state.uploadFiles.loadingFiles.filter((e) => e.id !== action.payload)
        }
      };
    }

    case FilesTypes.CLOSE_LOADING_FILES: {
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          loadingFiles: [],
          loadingFolders: []
        }
      };
    }

    case FilesTypes.SET_LOADING_FOLDERS:
      return {
        ...state,
        uploadFiles: { ...state.uploadFiles, loadingFolders: [...action.payload, ...state.uploadFiles.loadingFolders] }
      };

    case FilesTypes.CHANGE_LOADING_FOLDERS:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          loadingFolders: state.uploadFiles.loadingFolders.map((el) => {
            return el.id === action.payload.id
              ? {
                  ...el,
                  files: state.uploadFiles.loadingFolders
                    .find((el) => el.id === action.payload.id)
                    .files.map((item) =>
                      item.id === action.payload.file.id ? { ...item, ...action.payload.file } : { ...item }
                    )
                }
              : { ...el };
          })
        }
      };

    case FilesTypes.CANCEL_FOLDERS_UPLOAD: {
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          loadingFolders: state.uploadFiles.loadingFolders.filter((e) => e.id !== action.payload)
        }
      };
    }

    default:
      return state;
  }
};
