import { ReactComponent as InfoIcon } from "assets/PrivateCabinet/info-2.svg";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { IInfoTooltipProps } from "models/project/infoTooltip";
import React, { useRef, useState } from "react";

import styles from "./InfoTooltip.module.sass";

const InfoTooltip: React.FC<IInfoTooltipProps> = ({ title, text }): JSX.Element => {
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>();
  useOutsideClick(ref, () => setOpenInfo(false));

  return (
    <div className={styles.wrap} ref={ref}>
      <ButtonIcon
        handleClick={() => setOpenInfo((prev) => !prev)}
        icon={<InfoIcon />}
        variant="grey"
        isActive={openInfo}
      />
      {openInfo && (
        <div className={styles.container}>
          <div className={styles.title}>{title}</div>
          <div className={styles.textBlock}>{text}</div>
        </div>
      )}
    </div>
  );
};

export default InfoTooltip;
