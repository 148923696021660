import { ReactComponent as AnswerIcon } from "assets/icons/answer.svg";
import { ReactComponent as ForwardIcon } from "assets/icons/forward.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useContextLetter } from "collections/mail/mail";
import Button from "generalComponents/Button/Button";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import { ILetterInfo, IPostbox } from "models/store/postbox/postbox";
import React from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";

interface ILetterActions {
  letter: ILetterInfo;
  postbox: IPostbox;
  onHideLetter?: (el: ILetterInfo) => void;
}

const LetterActions: React.FC<ILetterActions> = ({ letter, postbox, onHideLetter = () => {} }) => {
  const { __ } = useLocales();
  const { folderName } = useParams();

  const { contextLetter } = useContextLetter();
  const { searchLetter } = usePostboxSelectors();
  const { onMoveLetter, onRemoveLetter, onSetApproveModal, onMailNewMessageModal, onSetSearchLetter } = useActions();
  const _isTrash = folderName === POSTBOX_SYSTEM_FOLDER.Trash;
  const _isDrafts = folderName === POSTBOX_SYSTEM_FOLDER.Drafts;
  const clearParams = (uids: number[]) => {
    if (searchLetter && !uids.includes(searchLetter.uid)) return;
    onSetSearchLetter(null);
  };

  const onDelete = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    const payload = {
      user: postbox.email,
      pass: postbox.pass,
      server: postbox.server
    };

    const errorMsg = __("Что-то пошло не так. Попробуйте позже!");
    console.log("delete", letter);

    clearParams([letter.uid]);
    if (_isTrash) {
      const params = {
        title: __("Удалить"),
        text: __("Вы действительно хотите удалить выбранное письмо навсегда?"),
        approveBtn: __("Удалить"),
        approveBtnVariant: ButtonVariantType.BLUE,

        callback: () =>
          onRemoveLetter(
            { ...payload, data: JSON.stringify([{ folder: POSTBOX_SYSTEM_FOLDER.Drafts, message_nums: letter.uid }]) },
            errorMsg
          )
      };
      onSetApproveModal({ open: true, params });
      onHideLetter(letter);
      return;
    }
    onMoveLetter(
      {
        ...payload,
        data: JSON.stringify([
          { folder: letter.folder, folder_new: POSTBOX_SYSTEM_FOLDER.Trash, message_nums: letter.uid }
        ])
      },
      errorMsg
    );
    onHideLetter(letter);
  };

  return (
    <>
      {!_isDrafts && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onMailNewMessageModal({
              open: true,
              letter: { letter, type: "answer" }
            });
          }}
          variant={ButtonVariantType.OPACITY}
          size={ButtonSizeType.MEDIUM}
          iconL={<AnswerIcon />}
          isSquare
        />
      )}
      {!_isDrafts && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onMailNewMessageModal({
              open: true,
              letter: { letter, type: "forward" }
            });
          }}
          variant={ButtonVariantType.OPACITY}
          size={ButtonSizeType.MEDIUM}
          iconL={<ForwardIcon />}
          isSquare
        />
      )}

      <Button
        onClick={onDelete}
        variant={ButtonVariantType.OPACITY}
        size={ButtonSizeType.MEDIUM}
        iconL={<TrashIcon />}
        isSquare
      />
      <PopoverContextMenu data={contextLetter(letter, postbox)} iconVariant="button" />
    </>
  );
};

export default LetterActions;
