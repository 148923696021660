import { ReactComponent as FolderIcon } from "assets/icons/folder_2.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import classNames from "classnames";
import NotesItemCategory from "containers/Notes/NotesCategories/components/NotesItemCategory/NotesItemCategory";
import NotesUncategories from "containers/Notes/NotesCategories/components/NotesUncategories/NotesUncategories";
import Button from "generalComponents/Button/Button";
import { FilterMarkers } from "generalComponents/FilterMarkers/FilterMarkers";
import Input from "generalComponents/Input/Input";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { INote } from "models/store/notes/notes";
import { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { useLocales } from "react-localized";
import { useNotesSelectors } from "Store/selectors/notesSelectors";

import styles from "./NotesCategories.module.sass";

const NotesCategories = () => {
  const { __ } = useLocales();
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { onGetAllNoteCategories, onSetNotesCategoriesModal, onEditNote, onSetNoteEditModal, onSetActiveNote } =
    useActions();
  const { categories } = useNotesSelectors();

  const [_, drop] = useDrop(() => ({
    accept: "NOTE_ITEM",
    drop: (item: INote, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }
      onEditNote({ id_item: item.id, id_dep: "" });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  }));
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;
    setSearch(newSearchValue);
  };

  useEffect(() => {
    onGetAllNoteCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container} ref={drop}>
      <div className={styles.header}>
        <div className={classNames(styles.editHeader, isEdit && styles.show)}>
          <Button
            variant={ButtonVariantType.LIGHT}
            size={ButtonSizeType.SMALL}
            iconL={<FolderIcon />}
            text={__("Новая папка")}
            onClick={() => onSetNotesCategoriesModal({ open: true })}
          />
          <Button
            variant={ButtonVariantType.DARK_GREEN}
            size={ButtonSizeType.SMALL}
            text={__("Применить")}
            onClick={() => setIsEdit((prev) => !prev)}
          />
        </div>
        <h6>{__("Заметки")}</h6>
        <div className={styles.right}>
          <div className={styles.buttons}>
            <Input
              value={search}
              onChange={handleInputChange}
              placeholder={__("Поиск")}
              rightIcon={<SearchIcon fill="#274A42" />}
            />
            <FilterMarkers />
            <Button
              variant={ButtonVariantType.LIGHT}
              size={ButtonSizeType.SMALL}
              isSquare
              iconL={<SettingsIcon />}
              onClick={() => setIsEdit((prev) => !prev)}
            />
          </div>

          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={__("+ Создать")}
            onClick={() => {
              onSetActiveNote(null);
              onSetNoteEditModal({ open: true });
            }}
          />
        </div>
      </div>

      <div className={classNames(styles.content, "scrollbar-thin-blue")}>
        <div className={styles.categories}>
          {categories?.map((item) => (
            <NotesItemCategory key={item.id} category={item} search={search} isEdit={isEdit} />
          ))}
        </div>
        <NotesUncategories search={search} />
      </div>
    </div>
  );
};

export default NotesCategories;
