import { ReactComponent as CalendarIcon } from "assets/PrivateCabinet/calendar-6.svg";
import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import { ReactComponent as FireIcon } from "assets/PrivateCabinet/fire.svg";
import classNames from "classnames";
import { useUrgencyTask } from "collections/tasks";
import Button from "generalComponents/Button/Button";
import Calendar from "generalComponents/Calendars/Calendar/Calendar";
import MultiSelect from "generalComponents/MultiSelect/MultiSelect";
import PopUp from "generalComponents/PopUp/PopUp";
import SelectChosen from "generalComponents/SelectChosen/SelectChosen";
import { getFormatDate } from "generalComponents/Services/dateServices";
import { useFiltredUsers } from "generalComponents/Services/projectServices";
import { CHAT_MESSAGE_ACTION, CHAT_TASK } from "generalComponents/variables/chat";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { URGENCY_TYPES } from "generalComponents/variables/tasks";
import { useWebSocketContext } from "generalComponents/WebSocketsProvider/WebSocketsProvider";
import TaskNum from "Pages/Cabinet/Components/Project/Components/TaskNum/TaskNum";
import UserAvatar from "Pages/Cabinet/Components/Project/Components/UserAvatar/UserAvatar";
import UserBlock from "Pages/Cabinet/Components/Project/Components/UserBlock/UserBlock";
import { dateToMs, getUser, useProjectTasks } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useMemo, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { ProjectTypes } from "Store/types/projectTypes";
import { taskTypes } from "types/Project";
import { v4 as uuidv4 } from "uuid";

import ChooseFile from "../../ProjectModals/ChooseFile/ChooseFile";
import TaskFile from "../../ProjectModals/Components/TaskFile/TaskFile";
import styles from "./AddTask.module.sass";
import { generateTaskMessage } from "./AddTask.service";

const AddTask = ({ onClose, params, type }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const { userInfo, uid } = useSelector((s) => s.user);
  const { project, projectsList, selectRole } = useSelector((s) => s.Projects);
  const projectUsers = useFiltredUsers();
  const { epicTasks, currentTasks } = useProjectTasks();
  const URGENCY = useUrgencyTask();
  const selectUser = useSelector((s) => s.Projects.selectUser);
  const executorsState = () => {
    if (type === PROJECT_MODALS.ADD_TASK) {
      return +selectUser > 0 ? [selectUser] : [];
    }
    return params.id_executor;
  };
  const [isEpic] = useState(params?.task?.is_epic ?? 0);
  const [name, setName] = useState(params.editTask ? params.task?.name : params.name ?? "");
  const [date, setDate] = useState({
    start: params.date_start ? getFormatDate(params.date_start) : params?.task?.date_start ?? "",
    end: params.date_end ? getFormatDate(params.date_end) : params?.task?.date_end ?? ""
  });
  const [id_act, setUrgency] = useState(URGENCY.find((el) => el.id === params.id_act) ?? URGENCY[1]);
  const [tags, setTags] = useState(params.tags ? params.tags : params?.task?.tags ?? "");
  const [author, setAuthor] = useState(params.author_info ?? getUser(project.users, userInfo.id_user));
  const [executors, setExecutors] = useState(executorsState());
  const [idEpic, setIdEpic] = useState(params.id_epic ? params.id_epic : params?.task?.id_epic ?? "");
  const [parentTask, setParentTask] = useState(params.id_parent ? params.id_parent : params?.task?.id_parent ?? "");
  const [files, setFiles] = useState(params.files ? params.files : params?.task?.files ?? []);
  const [prim, setPrim] = useState(params.prim ? params.prim : params?.task?.files ?? "");
  const [tasksChildren, setTaskChildren] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const fids = params.fids ?? [];
  const inputRef = useRef(null);
  const socket = useWebSocketContext();
  const [taskDest, setTaskDest] = useState(CHAT_TASK.BACKLOG);
  const { selectedContact } = useSelector((s) => s.Cabinet.chat);

  const selectBacklog = () => setTaskDest((_) => CHAT_TASK.BACKLOG);
  const selectSprint = () => setTaskDest((_) => CHAT_TASK.SPRINT);

  const getSelectList = () =>
    project.users.map((item) => ({
      id: item.id_user,
      text: <span>{`${item.name} ${item.sname ? item.sname : ""}`.trim()}</span>,
      img: <UserAvatar icon={item.icon} />
    }));

  const getExecutors = (arr) => {
    return getSelectList().filter((el) => arr.indexOf(el.id) > -1);
  };

  const getTasksChildren = (arr) => {
    return getChildrenTasks().filter((el) => arr.indexOf(el.id) > -1);
  };

  const getChildrenTasks = () => {
    return currentTasks
      .filter((t) => !t.id_epic || t.id_epic === "0")
      .map((item) => ({
        id: item.id,
        text: <div>{item.name}</div>
      }));
  };

  const onChangeName = ({ target }) => setName(target.value);

  const onSelectDate = (value) => {
    setDate({ ...value });
    setOpenCalendar((prev) => !prev);
  };

  const onChangePrim = ({ target }) => setPrim(target.value);

  const setShowCalendar = () => setOpenCalendar((prev) => !prev);

  const onClearDate = (e) => {
    e.stopPropagation();
    setDate({ start: "", end: "" });
  };

  const renderTask = (id, tasks) => {
    const task = tasks.find((t) => t.id === id);
    return (
      <div className={styles.task}>
        {task?.num_epic && <TaskNum num={task?.num_epic} />}
        &nbsp;{task?.name}
      </div>
    );
  };

  const onSelectItem = (item, type) => {
    type === "add"
      ? setExecutors((prev) => [...prev, item.id])
      : setExecutors((prev) => prev.filter((el) => el !== item.id));
  };

  const onSelectChildren = (item, type) => {
    type === "add"
      ? setTaskChildren((prev) => [...prev, item.id])
      : setTaskChildren((prev) => prev.filter((el) => el !== item.id));
  };

  const getFids = () => [...fids, ...files.map((item) => item.fid)];
  const getDateLong = () => {
    if (!date.end || !date.start) {
      return "";
    }
    if (dateToMs(params.date_long) < dateToMs(date.end)) {
      return "";
    }
    return params.date_long;
  };

  const onSubmit = () => {
    const payload = {
      action: params.editTask ? CHAT_MESSAGE_ACTION.EDIT_TASK : CHAT_MESSAGE_ACTION.ADD_TASK,
      uid,
      chat_id: selectedContact.id_group ? selectedContact.id_group : undefined,
      id_group: selectedContact.id_group,
      is_group: !!selectedContact.id_group,
      id_contact: selectedContact.id,
      id_user_to: selectedContact.id_real_user,
      name,
      tag: tags ? tags : "",
      prim,
      date_start: date.start,
      date_end: date.end ? `${date.end} 23:59:59` : "",
      date_long: getDateLong(),
      id_act: id_act.id,
      id_executor: JSON.stringify(executors.filter((it) => it)),
      id_project: project.id,
      id_parent: parentTask,
      id_task: params.id ?? params?.task?.id,
      id_status: params.id_status,
      sort: params.sort,
      author: author.id_user,
      uploadFiles: uploadFiles,
      fids: getFids(),
      is_epic: isEpic,
      id_epic: idEpic,
      tasksChildren: tasksChildren,
      is_backlog: taskDest === CHAT_TASK.BACKLOG ? -1 : 0,
      text: JSON.stringify(this) ?? "new task",
      task_data: JSON.stringify({ id_project: project.id }),
      type: params.editTask ? "task_edit" : "task"
    };

    socket.socket.send(JSON.stringify(generateTaskMessage(payload)));
    onClose();
  };

  const getDisabled = useMemo(() => {
    if (!name) {
      return true;
    }
    if ((date.start && !date.end) || (!date.start && date.end)) {
      return true;
    }
    return false;
  }, [date.end, date.start, name]);

  const onDeleteFile = (id) => {
    setFiles((prev) => prev.filter((item) => item.fid !== id));
  };
  const onDeleteUpload = (id) => {
    setUploadFiles((prev) => prev.filter((item) => item.fid !== id));
  };

  const fileSelect = () => inputRef.current.click();

  const uploadImage = (event) => {
    const files = [...event.target.files].map((file) => ({ file, fid: uuidv4() }));
    setUploadFiles((prev) => [...prev, ...files]);
  };
  const onAddFile = (file) => {
    setFiles((prev) => [...prev, file]);
    setOpenFile(false);
  };

  const onSelectUser = (id) => {
    const payload = selectUser === id ? "0" : id;
    dispatch({ type: ProjectTypes.SELECT_USER, payload });
  };

  const onSelectRole = (id) => {
    const payload = selectRole === id ? "0" : id;
    dispatch({ type: ProjectTypes.SELECT_ROLE, payload });
  };

  const onSelectProject = (id) => {
    const currentProject = projectsList.find((item) => item.id === id);
    dispatch({ type: ProjectTypes.CURRENT_PROJECT, payload: currentProject });
  };

  const renderProject = (project) => (
    <div key={project.id} onClick={() => onSelectProject(project.id)} className={styles.project}>
      {project.icon ? (
        <img src={project.icon} alt="sign" width={33} height={33} className={styles.logo} />
      ) : (
        <div className={styles.noLogo} />
      )}
      <span className={styles.name}>{project.name}</span>
      {project.id_fig && <img src={`/assets/PrivateCabinet/signs/${project.id_fig}.svg`} alt="sign" />}
      {project.id_emo && <img src={`/assets/PrivateCabinet/smiles/${project.id_emo}.svg`} alt="smile" />}
    </div>
  );

  return (
    <PopUp set={onClose}>
      <div className={classNames(styles.form, { [styles.noPadding]: type === PROJECT_MODALS.OPEN_TASK })}>
        <div className={classNames(styles.wrap, `scrollbar-thin-${userInfo.theme}`)}>
          <span className={styles.title}>{params.editTask ? __("Редактировать задачу") : __("Добавить задачу")}</span>
          <div className={styles.row}>
            <div className={styles.container}>
              <p className={styles.label}>{__("Определить задачу в:")}</p>
              <div className={styles.selectRow}>
                <div className={styles.select} onClick={selectBacklog}>
                  <div
                    className={classNames(styles.radioContact, {
                      [styles.radioContactChosen]: taskDest === "backlog"
                    })}
                  />
                  <div>{__("Бэклог")}</div>
                </div>
                <div className={styles.select} onClick={selectSprint}>
                  <div
                    className={classNames(styles.radioContact, {
                      [styles.radioContactChosen]: taskDest === "sprint"
                    })}
                  />
                  <div>{__("Текущий спринт")}</div>
                </div>
              </div>
            </div>
            <div className={styles.container}>
              <p className={styles.label}>{__("Выберите проект")}</p>
              <SelectChosen
                placeholder={__("Список проектов")}
                variant="formField"
                value={project ? <div className={styles.selectedProject}>{renderProject(project)}</div> : ""}
              >
                <ul className={styles.projectsList}>
                  {projectsList && projectsList.map((project) => renderProject(project))}
                </ul>
              </SelectChosen>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.container}>
              <p className={styles.label}>{__("Имя задачи")}</p>
              <input
                className={styles.input}
                type="text"
                name="taskName"
                value={name}
                onChange={onChangeName}
                placeholder={__("Введите имя задачи")}
              />
            </div>
            <div>
              <p className={styles.label}>{__("Установить даты")}</p>
              <div className={styles.dateBox} onClick={setShowCalendar}>
                {date.start ? (
                  <>
                    <span>{`${date.start}-${date.end}`}</span>
                    <span className={styles.clear} onClick={onClearDate} />
                  </>
                ) : (
                  <CalendarIcon className={styles.calendarIcon} />
                )}
              </div>
            </div>
          </div>
          <div className={styles.row}>
            {!isEpic && (
              <div className={styles.container}>
                <p className={styles.label}>{__("Выбирите актуальность задачи")}</p>
                <SelectChosen placeholder={__("Имя категории")} value={id_act.name} variant="low">
                  <ul className={styles.tasksList}>
                    {URGENCY.map((item) => (
                      <li key={item.id} onClick={() => setUrgency(item)} className={styles.task}>
                        {item.id === URGENCY_TYPES.URGENT ? <FireIcon /> : <div className={styles.noIcon} />}
                        <span>{item.name}</span>
                      </li>
                    ))}
                  </ul>
                </SelectChosen>
              </div>
            )}

            <div className={styles.container}>
              <p className={styles.label}>{__("Добавьте тег")}</p>
              <SelectChosen
                value={tags}
                handleChange={(value) => setTags(value)}
                readOnly={false}
                placeholder={__("Выбирите тег из списка или введите вручную")}
                variant="low"
                onClear={() => setTags("")}
              >
                <div className={styles.tags}>
                  {project.roles.map((role) => (
                    <p key={role.id} onClick={() => setTags(role.name)}>
                      {role.name}
                    </p>
                  ))}
                </div>
              </SelectChosen>
            </div>
          </div>
          {!isEpic && (
            <>
              <div className={styles.row}>
                <div className={classNames(styles.container, styles.authorSelect)}>
                  <p className={styles.label}>{__("Выберите автора задачи")}</p>
                  <SelectChosen
                    placeholder={__("Выбирите из списка участников")}
                    value={<UserBlock user={author} variant="short" />}
                    variant="low"
                  >
                    <ul className={styles.tasksList}>
                      {project.users.map((item) => (
                        <li key={item.id_user} className={styles.user} onClick={() => setAuthor(item)}>
                          <UserBlock user={item} variant="light" />
                        </li>
                      ))}
                    </ul>
                  </SelectChosen>
                </div>

                <div className={classNames(styles.container, styles.executorsSelect)}>
                  <p className={styles.label}>{__("Выберете исполнителя задачи")}</p>
                  <MultiSelect
                    data={getSelectList()}
                    values={getExecutors(executors)}
                    placeholder={__("Выбирите из списка участников")}
                    onSelectItem={onSelectItem}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.container}>
                  <p className={styles.label}>{__("Последовательность задачи")}</p>
                  <SelectChosen
                    placeholder={__("Выбирите последовательность задачи из списка задач")}
                    value={parentTask ? renderTask(parentTask, currentTasks) : ""}
                    variant="low"
                    onClear={() => setParentTask("")}
                  >
                    <div className={styles.taskFilter}>
                      <div className={styles.filterList}>
                        <div className={styles.header}>{__("Выбирите роль")}</div>
                        {project.roles.map((r) => (
                          <div key={r.id} className={styles.item} onClick={() => onSelectRole(r.id)}>
                            <div>{r.name}</div>
                            {r.id === selectRole ? (
                              <CheckIcon className={styles.check} />
                            ) : (
                              <div className={styles.noCheck} />
                            )}
                          </div>
                        ))}
                      </div>

                      <div className={styles.filterList}>
                        <div className={styles.header}>{__("Выбирите исполнителя")}</div>
                        {projectUsers.map((u) => (
                          <div key={u.id_user} className={styles.item} onClick={() => onSelectUser(u.id_user)}>
                            <UserBlock user={u} variant="light" />
                            {u.id_user === selectUser ? (
                              <CheckIcon className={styles.check} />
                            ) : (
                              <div className={styles.noCheck} />
                            )}
                          </div>
                        ))}
                      </div>
                      <div className={classNames(styles.filterList)}>
                        <div className={styles.header}>{__("Выбирите задачу")}</div>
                        {currentTasks.map((item, i) => (
                          <div key={i} onClick={() => setParentTask(item.id)} className={styles.task}>
                            {item.num_epic && <TaskNum num={item.num_epic} />}
                            &nbsp;{item.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </SelectChosen>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.container}>
                  <p className={styles.label}>{__("Добавить Epic задачи")}</p>
                  <SelectChosen
                    placeholder={__("Выбирите задачи из списка")}
                    value={idEpic ? renderTask(idEpic, epicTasks) : ""}
                    variant="low"
                    onClear={() => setIdEpic("")}
                  >
                    <ul className={styles.tasksList}>
                      {epicTasks.map((item, i) => (
                        <li key={i} onClick={() => setIdEpic(item.id)} className={styles.task}>
                          <TaskNum num={item?.num_epic} />
                          <span>{item.name}</span>
                        </li>
                      ))}
                    </ul>
                  </SelectChosen>
                </div>
              </div>
            </>
          )}
          {!!isEpic && (
            <>
              <div className={styles.row}>
                <div className={styles.container}>
                  <p className={styles.label}>{__("Добавить Task (Дочерная задача)")}</p>
                  <MultiSelect
                    data={getChildrenTasks()}
                    placeholder={__("Выбирите задачи из списка задач")}
                    onSelectItem={onSelectChildren}
                    values={getTasksChildren(tasksChildren)}
                  />
                </div>
              </div>
            </>
          )}

          <p className={styles.label}>{__("Добавить файл")}</p>
          <div className={styles.row}>
            <div className={styles.loadFile}>
              {files.length > 0 ? (
                <div className={styles.filesBox}>
                  <div className={styles.files}>
                    {files.map((file, i) => (
                      <TaskFile
                        key={file.fid}
                        file={file}
                        onDelete={() => onDeleteFile(file.fid)}
                        amount={files.length > 7 && i === 6 ? files.length - 6 : null}
                      />
                    ))}
                    {files.length === 1 && <span className={styles.fileName}>{files[0].name}</span>}
                  </div>
                  <button type="button" className={styles.loadFileBtn} onClick={() => setOpenFile(true)}>
                    {__("Загрузить ещё")}
                  </button>
                </div>
              ) : (
                <button type="button" className={styles.loadFileBtn} onClick={() => setOpenFile(true)}>
                  {__("Загрузить файл с системы 4Hub")}
                </button>
              )}
            </div>
            <div className={styles.loadFile}>
              {uploadFiles.length > 0 ? (
                <div className={styles.filesBox}>
                  <div className={styles.files}>
                    {uploadFiles.map((file, i) => (
                      <TaskFile
                        key={file.fid}
                        file={file.file}
                        onDelete={() => onDeleteUpload(file.fid)}
                        amount={uploadFiles.length > 7 && i === 6 ? uploadFiles.length - 6 : null}
                      />
                    ))}
                    {uploadFiles.length === 1 && <span className={styles.fileName}>{uploadFiles[0].file.name}</span>}
                  </div>
                  <button type="button" className={styles.loadFileBtn} onClick={fileSelect}>
                    {__("Загрузить ещё")}
                  </button>
                </div>
              ) : (
                <button type="button" className={styles.loadFileBtn} onClick={fileSelect}>
                  {__("Загрузить файл с локального компьютера")}
                </button>
              )}

              <input ref={inputRef} className={styles.hidden} type="file" multiple="multiple" onChange={uploadImage} />
            </div>
          </div>
          <div className={styles.container}>
            <textarea
              className={styles.textarea}
              rows={5}
              placeholder={__("Введите описание задачи")}
              onChange={onChangePrim}
              value={prim}
            />
          </div>
          <div className={styles.btns}>
            <Button type="button" variant="cancel" text={__("Отмена")} onClick={onClose} />
            <Button
              type="button"
              variant="ok"
              text={type === PROJECT_MODALS.ADD_TASK ? __("Добавить") : __("Сохранить")}
              onClick={onSubmit}
              disabled={getDisabled}
            />
          </div>
          {openCalendar && (
            <PopUp set={setShowCalendar} zIndex={102}>
              <Calendar setShowCalendar={setShowCalendar} setDateValue={(value) => onSelectDate(value)} selectRange />
            </PopUp>
          )}
          {openFile && (
            <PopUp set={() => setOpenFile(false)} zIndex={102}>
              <ChooseFile onClose={() => setOpenFile(false)} onAddFile={onAddFile} />
            </PopUp>
          )}
        </div>
      </div>
    </PopUp>
  );
};

export default AddTask;

AddTask.propTypes = {
  onClose: PropTypes.func,
  params: taskTypes,
  type: PropTypes.string
};
