import PopUp from "generalComponents/PopUp/PopUp";
import { MODALS } from "generalComponents/variables/global";
import MiniToolBar from "Pages/Cabinet/Components/WorkElements/MiniToolBar/MiniToolBar";
import Previews from "Pages/Cabinet/Components/WorkElements/Previews/Previews";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";

import styles from "./PreviewFile.module.sass";

const PreviewFile = () => {
  const file = useSelector((s) => s.Cabinet.modals.previewFile.file);
  const previewFile = useSelector((s) => s.Cabinet.modals.previewFile);
  const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const canvasWrapRef = useRef(null);
  const set = (e) => {
    let close = false;
    if (e?.target?.className === styles.preview) close = true;
    if (close) closeModal();
  };

  const closeModal = () =>
    dispatch(
      onSetModals(MODALS.FILE_PREVIEW, {
        ...previewFile,
        open: false,
        file: null,
        message: null,
        from: ""
      })
    );

  return (
    <PopUp set={set} background={"none"} padding="0" zIndex={100}>
      <div className={styles.preview} onClick={set}>
        {file ? (
          <div className={styles.imagePreviewWrap} ref={canvasWrapRef}>
            {file?.mime_type && file?.mime_type.includes("image") ? (
              <MiniToolBar
                canvasRef={canvasRef}
                canvasWrapRef={canvasWrapRef}
                file={file}
                share={true}
                closePreview={set}
                from={previewFile.from}
              />
            ) : null}
            <Previews file={file} ref={canvasRef} errorHandler={closeModal} />
          </div>
        ) : null}
      </div>
    </PopUp>
  );
};

export default PreviewFile;
