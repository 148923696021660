import classnames from "classnames";
import { endOfWeek, getWeekOfMonth, startOfMonth, startOfWeek } from "date-fns";
import Button from "generalComponents/Button/Button";
import {
  areEqual,
  getToday,
  useDaysOfWeeks,
  useGenerateCalendarWeeks,
  useMonths
} from "generalComponents/Calendars/CalendarHelper";
import { Times } from "generalComponents/Times/Times";
import { ButtonVariantType } from "models/generalComponents/button";
import { ICalendarDay, ICalendarMonthProps, IDateObject, TYPE_DAY } from "models/generalComponents/calendars";
import React, { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./CalendarMonth.module.sass";

const CalendarMonth: React.FC<ICalendarMonthProps> = ({
  onSelectDate,
  setShowCalendar,
  isWeekSelection
}): JSX.Element => {
  const { __ } = useLocales();
  const months = useMonths();
  const daysOfWeeks = useDaysOfWeeks();
  const weeksOfMonth = useGenerateCalendarWeeks();
  const [date, setDate] = useState<IDateObject>({ ...getToday() });
  const [dateWeek, setDateWeek] = useState<IDateObject>(null);

  const switchMonth = (day: number): void => {
    if (day >= 15) {
      if (date.month === 0) {
        return setDate({
          day: day <= 31 ? day : 0,
          month: 11,
          year: date.year - 1
        });
      }
      return setDate({
        ...date,
        day: day <= 31 ? day : 0,
        month: date.month - 1
      });
    } else {
      if (date.month === 11) {
        return setDate({
          day: day <= 31 ? day : 0,
          month: 0,
          year: date.year + 1
        });
      }
      return setDate({
        ...date,
        day: day <= 31 ? day : 0,
        month: date.month + 1
      });
    }
  };

  const switchYear = (status: string): void => {
    status === "increase"
      ? setDate({ ...date, year: date.year + 1, day: 0 })
      : setDate({ ...date, year: date.year - 1, day: 0 });
  };

  const onClickWeek = (day: number): void => {
    setDateWeek({ ...date, day });
  };

  const onClickDay = (day: ICalendarDay): void => {
    if (isWeekSelection) {
      if (day.typeDay === TYPE_DAY.PREV) {
        const d = startOfMonth(
          endOfWeek(new Date(date.year, date.month - 1, day.value), { weekStartsOn: 1 })
        ).getDate();
        setDateWeek({ ...date, day: d });
        return;
      }
      if (day.typeDay === TYPE_DAY.NEXT) {
        const d = startOfWeek(new Date(date.year, date.month + 1, day.value), { weekStartsOn: 1 }).getDate();
        setDateWeek({ ...date, day: d });
        return;
      }
      const d =
        day.value < 7
          ? startOfMonth(new Date(date.year, date.month, day.value)).getDate()
          : startOfWeek(new Date(date.year, date.month, day.value), { weekStartsOn: 1 }).getDate();

      setDateWeek({ ...date, day: d });
      return;
    }
    if (day.typeDay === TYPE_DAY.PREV || day.typeDay === TYPE_DAY.NEXT) {
      return switchMonth(day.value);
    }
    setDate((state) => ({ ...state, day: day.value }));
  };

  const renderWeekDay = (): JSX.Element[] => {
    return daysOfWeeks.short.map((d, idx) => (
      <div key={idx} className={styles.weekDayTitle}>
        {d}
      </div>
    ));
  };

  const renderDay = (week: ICalendarDay[]): JSX.Element[] => {
    return week.map((day, idx) => {
      const eachDate = { ...date, day: day.value };
      const isChosen = areEqual(date, eachDate) && day.typeDay !== TYPE_DAY.PREV && day.typeDay !== TYPE_DAY.NEXT;
      return (
        <div
          key={idx}
          className={classnames({ [styles.dateBlock]: !isWeekSelection, [styles.nohover]: isWeekSelection })}
          onClick={() => onClickDay(day)}
        >
          <div
            className={classnames(styles[day.typeDay], {
              [styles.today]: areEqual(getToday(), eachDate),
              [styles.chosen]: isChosen
            })}
          >
            {day.value}
          </div>
        </div>
      );
    });
  };

  const renderWeek = (weeks: ICalendarDay[][]): JSX.Element[] => {
    return weeks.map((week, i) => {
      const isCurrentWeek =
        getWeekOfMonth(new Date(getToday().year, getToday().month, getToday().day)) === i + 1 &&
        date.month === getToday().month &&
        date.year === getToday().year;
      const day = week.find((el) => el.typeDay === TYPE_DAY.CURRENT)?.value;
      const eachDate = { ...date, day };
      const isChosenWeek = areEqual(dateWeek, eachDate);
      return (
        <div key={i} className={styles.line}>
          <div
            className={classnames(styles.weekNumber, {
              [styles.chosenWeek]: isChosenWeek,
              [styles.hidden]: !isWeekSelection
            })}
            onClick={() => onClickWeek(day)}
          >
            {`${i + 1} ${__("неделя").slice(0, 3)}`}
          </div>
          <div
            className={classnames(styles.weekDays, {
              [styles.thisWeek]: isCurrentWeek && isWeekSelection,
              [styles.chosenWeek]: isChosenWeek,
              [styles.isEvent]: isWeekSelection
            })}
          >
            {renderDay(week)}
          </div>
        </div>
      );
    });
  };

  const renderMonth = (): JSX.Element => {
    const weeks = weeksOfMonth(date);
    return (
      <>
        <div className={styles.line}>
          <div className={classnames(styles.weekNumberNone, { [styles.hidden]: !isWeekSelection })} />
          <div className={classnames(styles.weekDays)}>{renderWeekDay()}</div>
        </div>
        {renderWeek(weeks)}
      </>
    );
  };
  const changeDate = (): void => {
    onSelectDate(isWeekSelection ? dateWeek : date);
    setShowCalendar();
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.yearPicker}>
          <div className={styles.yearDecrease} onClick={() => switchYear("decrease")} />
          <div className={styles.monthTitle}>{date.year}</div>
          <div className={styles.yearIncrease} onClick={() => switchYear("increase")} />
        </div>
        <div className={styles.yearPicker}>
          <div className={styles.yearDecrease} onClick={() => switchMonth(32)} />
          <div className={styles.monthTitle}>{months(date.year)[date.month].name}</div>
          <div className={styles.yearIncrease} onClick={() => switchMonth(0)} />
        </div>
        <Times handleClick={setShowCalendar} />
      </div>
      <div className={styles.main}>{renderMonth()}</div>
      <div className={styles.footer}>
        <Button
          variant={ButtonVariantType.OK}
          text={__("Готово")}
          type="button"
          onClick={changeDate}
          disabled={isWeekSelection ? !dateWeek : false}
        />
      </div>
    </div>
  );
};

export default CalendarMonth;
