import classNames from "classnames";
import SignItem from "generalComponents/Elements/SignItem";
import Tag from "generalComponents/Elements/Tag";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { SIGNS } from "generalComponents/variables/elements";
import { TagViewTypes } from "models/generalComponents/elements";
import { IVersionsTabInfoProps } from "models/modals/propertiesModal/components/versionsTabInfo";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./VersionsTabInfo.module.sass";

// TODO - androfficial - finalize the component as versioning with api will be ready
export const VersionsTabInfo: React.FC<IVersionsTabInfoProps> = ({ _isFolder, params }): JSX.Element => {
  const { __ } = useLocales();

  return (
    <div className={styles.root}>
      <div className={styles.infoWrapper}>
        <div className={styles.title}>{_isFolder ? __("Папка") : __("Файл")}</div>
        <div className={styles.card}>
          <div className={styles.iconWrapper}>
            <ItemIcon
              isFolder={_isFolder}
              width={21}
              height={_isFolder ? 17 : 27}
              color={params.color}
              passIcon={params.is_pass === 1}
              ext={(params as IFile).ext}
            />
            <p className={styles.name}>{__("Статистика задач")}</p>
          </div>
        </div>
      </div>

      <div
        className={classNames(styles.infoWrapper, {
          [styles.paddingBottom]: true
        })}
      >
        <div className={styles.title}>{__("Дата изменения: 10.11.2023 10:30 ")}</div>
        <div className={styles.card}>
          <div className={styles.iconWrapper}>
            <ItemIcon
              isFolder={_isFolder}
              width={21}
              height={_isFolder ? 17 : 27}
              color={params.color}
              passIcon={params.is_pass === 1}
              ext={(params as IFile).ext}
            />
            <p className={styles.name}>{__("Статистика задач")}</p>
          </div>
          <div className={styles.optionsList}>
            <div className={styles.minitagWrap}>
              <Tag variant={TagViewTypes.LIGHT} lineHeight={18}>
                {`#${_isFolder ? (params as IFolder).tags : (params as IFile).tag}`}
              </Tag>
            </div>
            <SignItem sign={SIGNS.F2} size={14} />
            {/* <EmojiItem emoji={EMOJIS.E2} size={14} /> */}
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <button className={styles.restoreButton} type="button">
            {__("Восстановить данную версию")}
          </button>
        </div>
      </div>
    </div>
  );
};
