import Button from "generalComponents/Button/Button";
import { ChooseFolder } from "generalComponents/ChooseFolder/ChooseFolder";
import CombinedElements from "generalComponents/Elements/CombinedElements";
import Input from "generalComponents/Input/Input";
import { ItemInfoDynamic } from "generalComponents/ItemInfoDynamic/ItemInfoDynamic";
import PopUp from "generalComponents/PopUp/PopUp";
import { useCurrentFolder } from "generalComponents/Services/folderServices/folderServices";
import { Times } from "generalComponents/Times/Times";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { ROUTES } from "generalComponents/variables/routing";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ButtonVariantType } from "models/generalComponents/button";
import { initialCreateZipModalState } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import React, { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./CreateZip.module.sass";

const CreateZip = (): JSX.Element => {
  const { __ } = useLocales();
  const { onSetCreateZipModal, onCreateZipFolder, onCreateZipFile, onSetApproveModal } = useActions();

  const currentFolder = useCurrentFolder();
  const {
    createZip: { params }
  } = useGlobalModalsSelectors();
  const safe = useAuthorizedSafe();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const { safeId, safeDir } = useParams();

  const { folders } = useFoldersSelectors();

  const [_folder, _setFolder] = useState<IFolder>(currentFolder);
  const _isFilesType = params.every((item) => item.is_dir === 0);

  const [name, setName] = useState<string>(NO_VALUE);
  const [color, setColor] = useState<string>();
  const [emo, setEmo] = useState<string>("");
  const [tagOption, setTagOption] = useState<string[]>([]);

  const [zipFolderCompressError, setZipFolderCompressError] = useState<boolean>(false);

  const onSelect = (folder: IFolder): void => _setFolder(folder);
  const onAddName = (e: React.ChangeEvent<HTMLInputElement>): void => setName(e.target.value);

  const closeModal = (): void => {
    onSetCreateZipModal(initialCreateZipModalState());
  };

  const renderTitleOption = (): string => {
    if (_isFilesType) {
      if (params.length > 1) {
        return __("Сжать несколько файлов в ZIP");
      } else {
        return __("Сжать файл в ZIP");
      }
    }
    if (params.length > 1) {
      return __("Сжать в ZIP");
    } else {
      return __("Сжать папку в ZIP");
    }
  };

  const onSubmit = (): void => {
    if (isSafePage) {
      const fids = (params as IFile[]).filter((e) => e.is_dir === 0).map((file) => file.fid);
      const dirs = (params as IFolder[]).filter((e) => e.is_dir === 1).map((folder) => folder.id_dir);
      const payload = {
        idDir: isSafePage ? safeDir || "0" : _folder?.id_dir || undefined,
        id_dirs: dirs.length > 0 ? dirs : undefined,
        fids: fids.length > 0 ? fids : undefined,
        zipName: name,
        tags: tagOption || [],
        color,
        emo: emo || undefined
      };

      onCreateZipFile(payload, safe);
      return;
    }
    if (_isFilesType) {
      const payload = {
        idDir: isSafePage ? safeDir || "0" : _folder?.id_dir || undefined,
        fids: (params as IFile[]).map((file) => file.fid),
        zipName: name,
        tags: tagOption || undefined,
        color,
        emo: emo || undefined
      };

      onCreateZipFile(payload, safe);
    } else {
      const fids = (params as IFile[]).filter((e) => e.is_dir === 0).map((file) => file.fid);
      const payload = {
        idDir: isSafePage ? safeDir || "0" : _folder?.id_dir || undefined,
        idDirs: (params as IFolder[]).filter((e) => e.is_dir === 1).map((folder) => folder.id_dir),
        fids: fids.length > 0 ? fids : undefined,
        zipName: name,
        tags: tagOption || [],
        color,
        emo: emo || undefined
      };

      onCreateZipFolder(payload, setZipFolderCompressError);
    }
  };

  const disableButton = (): boolean => name === NO_VALUE && (!_folder || !safeDir || !safeId);

  useEffect(() => {
    if (zipFolderCompressError) {
      closeModal();

      const text = params.length > 1 ? __("Сжать пустые папки - невозможно") : __("Сжать пустую папку - невозможно");
      const approveModalParams = {
        title: __("Ошибка"),
        text,
        approveBtn: __("Ясно"),
        callback: () => {}
      };

      onSetApproveModal({
        open: true,
        params: approveModalParams
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipFolderCompressError]);

  return (
    <PopUp set={closeModal}>
      <div className={styles.root}>
        <Times handleClick={closeModal} />
        <h2 className={styles.title}>{renderTitleOption()}</h2>
        <ItemInfoDynamic
          setColor={setColor}
          setEmo={setEmo}
          color={color}
          ext="zip"
          passIcon={false}
          name={name}
          tags={tagOption}
          emo={emo}
          isFolder={false}
        />
        <div className={styles.content}>
          <Input value={name} onChange={onAddName} placeholder={__("Имя ZIP файла")} />
          {!isSafePage && <ChooseFolder onSelect={onSelect} folder={_folder} folders={folders} />}
          <CombinedElements
            tags={tagOption}
            onSelectTags={setTagOption}
            color={color}
            setColor={setColor}
            emo={emo}
            setEmo={setEmo}
          />
        </div>
        <div className={styles.buttonsWrap}>
          <Button type="button" variant={ButtonVariantType.CANCEL} text={__("Отмена")} onClick={closeModal} />
          <Button
            type="button"
            variant={ButtonVariantType.BLUE}
            text={__("Добавить")}
            onClick={onSubmit}
            disabled={disableButton()}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default CreateZip;
