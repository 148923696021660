import { ILinkListProps } from "models/project/linkList";
import { ILinkProject } from "models/store/projects/projectStore";
import React, { useMemo } from "react";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import LinkItem from "../LinkItem/LinkItem";
import styles from "./LinkList.module.sass";

const LinkList: React.FC<ILinkListProps> = ({ handleDelete, selectedLinks, handleSelected, filters }): JSX.Element => {
  const { links } = useProjectsSelectors();

  const filtredLinks: ILinkProject[] = useMemo(() => {
    if (!filters.COLOR && !filters.EMO && !filters.FIGURE && !filters.TAG) {
      return links;
    }
    return links
      .filter((link) => {
        if (filters?.COLOR) {
          return link.color === filters.COLOR;
        }
        return link;
      })
      .filter((link) => {
        if (filters?.EMO) {
          return link.emo === filters.EMO;
        }
        return link;
      })
      .filter((link) => {
        if (filters?.TAG) {
          return link.tags.toLowerCase().includes(filters.TAG.toLowerCase());
        }
        return link;
      });
  }, [filters, links]);

  return (
    <div className={styles.wrap}>
      {filtredLinks.map((link) => (
        <LinkItem
          key={link.id}
          link={link}
          handleDelete={handleDelete}
          selectedLinks={selectedLinks}
          handleSelected={handleSelected}
        />
      ))}
    </div>
  );
};

export default LinkList;
