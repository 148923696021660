import { IMediaScreenVideLayout, IUpToFiveUsersLayout } from "models/modals/chatModals";

// renders different layouts according to the number of users and screen sharing if it's more than 5 users
export function layout(clientsNumber = 1, isScreen: boolean): IMediaScreenVideLayout[] {
  let pairs = [];

  if (isScreen) {
    for (let i = 0; i < clientsNumber; i++) {
      if (i >= 3) {
        break;
      }
      pairs.push({ width: "97%", height: `calc(${100 / 4}% - 15px)`, margin: `10px 0 0 0` });
    }
    return pairs;
  }

  for (let i = 0; i < clientsNumber; i++) {
    if (clientsNumber === 1) {
      pairs.push({
        width: "98%",
        height: "97%"
      });
    }
    if (clientsNumber >= 2 && clientsNumber <= 5) {
      pairs.push(upToFiveUsers(i));
    }
    if (clientsNumber === 6) {
      pairs.push({
        width: `calc(100%/3 - 20px)`,
        height: `calc(100%/2 - 20px)`
      });
    }
    if (clientsNumber > 6 && clientsNumber <= 9) {
      pairs.push({
        width: `calc(100%/3 - 20px)`,
        height: `calc(100%/3 - 20px)`
      });
    }
    if (clientsNumber >= 10 && clientsNumber <= 25) {
      pairs.push({
        height: `calc(100%/${clientsNumber <= 15 ? 3 : clientsNumber <= 20 ? 4 : 5} - 20px)`,
        width: `calc(100%/5 - 20px)`
      });
    }
  }
  return pairs;
}

// Рендерит вид отображения пользователей, если в комнате до 5 пользователей включительно
function upToFiveUsers(index: number): IUpToFiveUsersLayout {
  if (index === 1) {
    return {
      width: "80%",
      height: "98%",
      position: "absolute",
      top: `20px`,
      left: "20px"
    };
  } else {
    return {
      width: "17%",
      height: "24%",
      position: "absolute",
      top: `calc(20px + 25% * ${index === 0 ? index : index - 1})`,
      right: "10px"
    };
  }
}
