import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { INote, INoteCategory } from "models/store/notes/notes";
import { ISetActiveNote, ISetNoteCategories, ISetNotes, NotesActions } from "models/store/notes/notesActions";
import {
  IAddNoteCategoryPayload,
  IAddNotePayload,
  IDeleteNoteCategoryPayload,
  IDeleteNotePayload,
  IEditNoteCategoryPayload,
  IEditNotePayload
} from "models/store/notes/notesPayloads";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { NotesTypes } from "Store/types/notesTypes";

import { CabinetActions } from "../../models/store/Cabinet/Cabinet";
import { onSetTopMessageModal } from "./ModalActions";

export const onSetNotes = (payload: INote[]): ISetNotes => ({
  type: NotesTypes.SET_NOTES,
  payload
});

export const onSetActiveNote = (payload: INote | null): ISetActiveNote => ({
  type: NotesTypes.SET_ACTIVE_NOTE,
  payload
});

export const onSetNoteCategories = (payload: INoteCategory[]): ISetNoteCategories => ({
  type: NotesTypes.SET_NOTE_CATEGORIES,
  payload
});

export const onAddNewNote =
  (
    payload: IAddNotePayload,
    setIsLoading?: (val: boolean) => void
  ): ThunkAction<void, RootState, unknown, NotesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      setIsLoading && setIsLoading(true);
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      payload.tags && formData.append("tags", JSON.stringify(payload.tags));
      formData.append("prim", payload.prim);
      payload.emo && formData.append("emo", payload.emo);
      payload.color && formData.append("color", payload.color);
      payload.pass && formData.append("pass", payload.pass);
      payload.id_dep && formData.append("id_dep", payload.id_dep);
      const { data } = await api.post(`/ajax/notes_add.php`, formData);
      checkResponseStatus(data.ok);
      dispatch(onSetNotes(data.data.notes));
      dispatch(onSetActiveNote(data.data.note));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

export const onGetAllNotes = (): ThunkAction<void, RootState, unknown, NotesActions> => async (dispatch, getState) => {
  try {
    const params = {
      uid: getState().user.uid
    };

    const { data } = await api.get(`/ajax/notes_get.php`, { params });
    checkResponseStatus(data.ok);
    dispatch(onSetNotes(data.notes));
  } catch (error) {
    console.log(error);
  } finally {
    /*  */
  }
};

export const onEditNote =
  (
    payload: IEditNotePayload,
    setIsLoading?: (val: boolean) => void
  ): ThunkAction<void, RootState, unknown, NotesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      setIsLoading && setIsLoading(true);
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_item", payload.id_item);
      payload.tags && formData.append("tags", JSON.stringify(payload.tags));
      payload.prim && formData.append("prim", payload.prim);
      payload.emo && formData.append("emo", payload.emo);
      payload.color && formData.append("color", payload.color);
      payload.pass && formData.append("pass", payload.pass);
      payload.id_dep && formData.append("id_dep", payload.id_dep);
      payload.is_archive && formData.append("is_archive", payload.is_archive);
      const { data } = await api.post(`/ajax/notes_edit.php`, formData);
      checkResponseStatus(data.ok);
      dispatch(onSetNotes(data.data.notes));
      dispatch(onSetActiveNote(data.data.note));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

export const onDeleteNote =
  (
    payload: IDeleteNotePayload,
    setIsLoading?: (val: boolean) => void
  ): ThunkAction<void, RootState, unknown, NotesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      setIsLoading && setIsLoading(true);
      const params = {
        uid: getState().user.uid,
        ...payload
      };
      const { data } = await api.get(`/ajax/notes_del.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetNotes(data.data.notes));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

export const onGetAllNoteCategories =
  (): ThunkAction<void, RootState, unknown, NotesActions> => async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid
      };

      const { data } = await api.get(`/ajax/notes_dep_list.php`, { params });

      checkResponseStatus(data.ok);
      dispatch(onSetNoteCategories(data.data.notes_dep));
    } catch (error) {
      console.log(error);
    } finally {
      /*  */
    }
  };

export const onAddNoteCategory =
  (
    payload: IAddNoteCategoryPayload,
    setIsLoading?: (val: boolean) => void
  ): ThunkAction<void, RootState, unknown, NotesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      setIsLoading && setIsLoading(true);
      const params = {
        uid: getState().user.uid,
        ...payload,
        background: payload.color
      };
      const { data } = await api.get(`/ajax/notes_dep_add.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetNoteCategories(data.data.notes_dep));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

export const onEditNoteCategory =
  (
    payload: IEditNoteCategoryPayload,
    setIsLoading?: (val: boolean) => void
  ): ThunkAction<void, RootState, unknown, NotesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      setIsLoading && setIsLoading(true);
      const params = {
        uid: getState().user.uid,
        id_item: payload.id,
        ...payload
      };
      const { data } = await api.get(`/ajax/notes_dep_edit.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetNoteCategories(data.data.notes_dep));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };

export const onDeleteNoteCategory =
  (
    payload: IDeleteNoteCategoryPayload,
    setIsLoading?: (val: boolean) => void
  ): ThunkAction<void, RootState, unknown, NotesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      setIsLoading && setIsLoading(true);
      const params = {
        uid: getState().user.uid,
        id_item: payload.id
      };
      const { data } = await api.get(`/ajax/notes_dep_del.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetNoteCategories(data.data.notes_dep));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      setIsLoading && setIsLoading(false);
    }
  };
