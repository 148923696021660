import api from "api";
import { ReactComponent as EmailIcon } from "assets/icons/mail_1.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";
import { ReactComponent as RestoreIcon } from "assets/icons/restore.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import DeadlineTimer from "generalComponents/DeadlineTimer/DeadlineTimer";
import PopUp from "generalComponents/PopUp/PopUp";
import Tabs from "generalComponents/Tabs/Tabs";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialConfirmCodeModalState } from "models/store/Cabinet/modals/confirmCodeModal";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { ChangeEvent, FormEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./ConfirmCodeModal.module.sass";

let currentActiveCode: number = 0;

const ConfirmCodeModal = () => {
  const { __ } = useLocales();
  const { onSetConfirmCodeModal, onSetTopMessageModal } = useActions();
  const {
    confirmCode: { type, info, callbackSuccess, callbackRepeat },
    folderPassModal: { folder }
  } = useGlobalModalsSelectors();

  const [code, setCode] = useState<string[]>(new Array(4).fill(""));
  const [activeCode, setActiveCode] = useState<number>(0);
  const [time, setTime] = useState<number>(Date.now() + 60000);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [tabValue, setTabValue] = useState<"tel" | "email">("tel");
  const _isTabs = Boolean(info.tel) && Boolean(info.email);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeCode]);

  const closeModal = (): void => {
    onSetConfirmCodeModal(initialConfirmCodeModalState());
  };

  const getTitle = (): string => {
    switch (type) {
      case "CONFIRM_PHONE":
        return __("Подтверждение телефона");
      case "CHANGE_PHONE":
        return __("Смена телефона");
      case "CHANGE_EMAIL":
        return __("Смена Email");
      case "CHANGE_PASS":
        return __("Изменить пароль");
      case "CHANGE_FOLDER_PASS":
        return __("Восстановление пароля");

      default:
        break;
    }
  };
  const getValue = (): string => {
    switch (type) {
      case "CONFIRM_PHONE":
      case "CHANGE_PHONE":
      case "CHANGE_EMAIL":
        return info.step === 1 ? info.prevValue : info.newValue;

      case "CHANGE_PASS":
      case "CHANGE_FOLDER_PASS":
        return tabValue === "tel" && info.tel ? `+${info.tel}` : info.email;

      default:
        break;
    }
  };

  const viewsTabs = [
    {
      name: __("Телефон"),
      onClick: () => onChangeTab("tel"),
      value: "tel"
    },
    {
      name: "Email",
      onClick: () => onChangeTab("email"),
      value: "email"
    }
  ];

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    const val = value.substring(value.length - 1);
    if (error) setError(false);
    if (!val) setActiveCode(currentActiveCode - 1);
    else setActiveCode(currentActiveCode + 1);
    setCode((prev) => {
      const newCode: string[] = [...prev];
      newCode[currentActiveCode] = val;
      return newCode;
    });
  };

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>, idx: number): void => {
    currentActiveCode = idx;
    if (key === "Backspase") setActiveCode(currentActiveCode - 1);
  };

  const onConfirmFolderCode = async () => {
    try {
      const params = {
        uid: info.uid,
        id_dir: folder.id_dir,
        code: code.join("")
      };
      const { data } = await api.get("/ajax/dir_pass_code_test.php", { params });
      if (data.ok) {
        callbackSuccess(code.join(""));
        closeModal();
      } else {
        setError(true);
        setCode(new Array(4).fill(""));
        setActiveCode(0);
      }
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  const onConfirmCode = async () => {
    try {
      const params: { uid: string; tel?: string; email?: string; code: string; type?: string } = {
        uid: info.uid,
        code: code.join("")
      };
      if (type === "CONFIRM_PHONE" || type === "CHANGE_PHONE") {
        params.tel = info.step === 1 ? info.prevValue.replace("+", "") : info.newValue.replace("+", "");
        params.type = "confirm_tel";
      }
      if (type === "CHANGE_EMAIL") {
        params.email = info.step === 1 ? info.prevValue : info.newValue;
        params.type = "confirm_email";
      }
      if (type === "CHANGE_PASS") {
        if (_isTabs) {
          if (tabValue === "tel") {
            params.type = "confirm_tel";
            params.tel = info.tel;
          }
          if (tabValue === "email") {
            params.type = "confirm_email";
            params.email = info.email;
          }
        } else {
          params.type = "confirm_email";
          params.email = info.email;
        }
      }
      const { data } = await api.get("/ajax/user_check_code.php?", { params });
      if (data.ok) {
        if (info.step === 1) {
          onNextStep();
          setTime(Date.now() + 60000);
          return;
        }
        callbackSuccess("");
        closeModal();
      } else {
        setError(true);
        setCode(new Array(4).fill(""));
        setActiveCode(0);
      }
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  const onChangeTab = async (value: "tel" | "email") => {
    if (value === tabValue) return;
    try {
      if (type === "CHANGE_PASS") {
        const params = {
          uid: info.uid,
          tel: value === "tel" ? info.tel : undefined,
          email: value === "email" ? info.email : undefined,
          action: "send"
        };
        await api.get("/ajax/user_confirm.php?", { params });
        setCode(new Array(4).fill(""));
        setTabValue(value);
        onSetConfirmCodeModal({
          open: true,
          type,
          callbackSuccess: () => callbackSuccess(""),
          callbackRepeat: () => onChangeTab(value),
          info: {
            uid: info.uid,
            tel: info.tel,
            email: info.email,
            step: 0
          }
        });
      }
      if (type === "CHANGE_FOLDER_PASS") {
        const params = {
          uid: info.uid,
          id_dir: folder.id_dir,
          to: value
        };
        const { data } = await api.get("/ajax/dir_pass_restore.php", { params });
        if (data.ok) {
          setCode(new Array(4).fill(""));
          setTabValue(value);
          onSetConfirmCodeModal({
            open: true,
            type: "CHANGE_FOLDER_PASS",
            callbackSuccess: (code) => callbackSuccess(code),
            callbackRepeat: () => onChangeTab(value),
            info: {
              uid: info.uid,
              tel: info.tel,
              email: info.email
            }
          });
        }
      }
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  const onNextStep = async () => {
    try {
      const params = {
        uid: info.uid,
        tel: info.newValue?.replace("+", "") || undefined,
        email: info.newValue || undefined,
        action: "send"
      };
      await api.get("/ajax/user_confirm.php?", { params });
      setCode(new Array(4).fill(""));
      onSetConfirmCodeModal({
        open: true,
        type,
        callbackSuccess: () => callbackSuccess(""),
        callbackRepeat: () => onNextStep(),
        info: {
          uid: info.uid,
          newValue: info.newValue,
          prevValue: info.prevValue,
          step: 2
        }
      });
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };
  const onRepeat = () => {
    setDisabled(true);
    setTime(Date.now() + 60000);
    if (_isTabs) {
      onChangeTab(tabValue);
      return;
    }
    callbackRepeat();
  };

  const onSend = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (type === "CHANGE_FOLDER_PASS") {
      onConfirmFolderCode();
      return;
    }
    onConfirmCode();
  };

  return (
    <PopUp set={closeModal} top={250} position="top">
      <div className={styles.wrap}>
        <HeaderModal title={getTitle()} onClose={closeModal} />
        {(type === "CHANGE_PASS" || type === "CHANGE_FOLDER_PASS") && _isTabs && (
          <div className={styles.tabBlock}>
            <p>{__("Подтверждение через")}</p>
            <Tabs tabs={viewsTabs} value={tabValue} size="medium" variant="darkGreen" />
          </div>
        )}
        <div className={styles.body}>
          {type === "CHANGE_PHONE" && (
            <div className={styles.images}>
              <div className={styles.item}>
                <PhoneIcon fill={info.step === 1 ? "var(--main-orange)" : "var(--main-green)"} />
                <span>{info.prevValue}</span>
              </div>
              <div className={styles.delimetr} />
              <div className={styles.item}>
                <PhoneIcon fill={info.step === 1 ? "var(--color-d700)" : "var(--main-orange)"} />
                <span>{info.newValue}</span>
              </div>
            </div>
          )}
          {type === "CHANGE_EMAIL" && (
            <div className={styles.images}>
              <div className={styles.item}>
                <EmailIcon fill={info.step === 1 ? "var(--main-orange)" : "var(--main-green)"} />
                <span>{info.prevValue}</span>
              </div>
              <div className={styles.delimetr} />
              <div className={styles.item}>
                <EmailIcon fill={info.step === 1 ? "var(--color-d700)" : "var(--main-orange)"} />
                <span>{info.newValue}</span>
              </div>
            </div>
          )}

          <p className={styles.text}>
            {__("Введите код подтверждения отправленый на")}&nbsp;
            <strong style={{ whiteSpace: "nowrap" }}>{getValue()}</strong>
          </p>
          <div className={styles.restoreBox}>
            <Button
              type="button"
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.EXTRA_SMALL}
              iconL={<RestoreIcon />}
              text={__("Выслать код повторно")}
              onClick={onRepeat}
              disabled={disabled}
            />
            {disabled && <DeadlineTimer deadline={time} onComplete={(v) => setDisabled(v)} />}
          </div>
          <form onSubmit={onSend}>
            <div className={styles.inputs}>
              {code.map((_, idx) => (
                <input
                  ref={idx === activeCode ? inputRef : null}
                  type="number"
                  key={idx}
                  onChange={handleChange}
                  onKeyDown={(e) => handleKeyDown(e, idx)}
                  value={code[idx]}
                  className={classNames(styles.input, { [styles.error]: error })}
                />
              ))}
            </div>
            {error && <span className={styles.errorText}>{__("Неверный код")}</span>}

            <div className={styles.footer}>
              {Boolean(info.step) && (
                <span>
                  {__("Шаг")}&nbsp;{info.step}/2
                </span>
              )}
              <div className={styles.btns}>
                <Button
                  onClick={closeModal}
                  variant={ButtonVariantType.EXRTA_LIGHT}
                  size={ButtonSizeType.MEDIUM}
                  text={__("Отменить")}
                  type="button"
                />
                <Button
                  variant={ButtonVariantType.BLUE}
                  size={ButtonSizeType.MEDIUM}
                  text={__("Продолжить")}
                  type="submit"
                  disabled={code.some((el) => !el) || error}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </PopUp>
  );
};

export default ConfirmCodeModal;
