import { ReactComponent as PrintIcon } from "assets/icons/print.svg";
import { useContextLetter } from "collections/mail/mail";
import Button from "generalComponents/Button/Button";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ILetterInfo, IPostbox } from "models/store/postbox/postbox";
import React from "react";

import styles from "./DialogActions.module.sass";

interface ILetterActionsProps {
  letters: ILetterInfo[];
  postbox: IPostbox;
  onPrint: () => void;
}

const DialogActions: React.FC<ILetterActionsProps> = ({ letters, postbox, onPrint }) => {
  const { actionsLetter } = useContextLetter();

  return (
    <ul className={styles.options}>
      {actionsLetter(letters, postbox).map((item, i) =>
        item?.next ? (
          <li key={i} className={styles.next}>
            <div className={styles.toFolder}>{item.img}</div>
            <ul className={styles.folderList}>
              {item.next.map((el, idx) => (
                <li key={idx} className={styles.folderItem}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      el.callback(e);
                    }}
                    type="button"
                  >
                    {el.name}
                  </button>
                </li>
              ))}
            </ul>
          </li>
        ) : (
          <li key={i}>
            <Button
              variant={ButtonVariantType.OPACITY}
              size={ButtonSizeType.MEDIUM}
              onClick={item?.callback}
              isSquare
              iconL={item.img}
            />
          </li>
        )
      )}
      <Button
        variant={ButtonVariantType.OPACITY}
        size={ButtonSizeType.MEDIUM}
        onClick={onPrint}
        isSquare
        iconL={<PrintIcon />}
        className={styles.printBtn}
      />
    </ul>
  );
};

export default DialogActions;
