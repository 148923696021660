import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./TextButton.module.sass";

const TextButton = ({ className, text, type, callback, disabled, style }) => {
  return (
    <button
      onClick={callback}
      style={style}
      className={classNames(className, styles.wrapper, {
        [styles[type]]: true,
        [styles.disabled]: disabled
      })}
      type="button"
    >
      {text}
    </button>
  );
};

export default TextButton;

TextButton.defaultProps = {
  className: "",
  type: "ok" || "cancel",
  callback: () => {},
  disabled: false,
  style: {}
};

TextButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.node,
  type: PropTypes.oneOf(["ok", "cancel", "cancelRed", "yellow"]),
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    color: PropTypes.string
  })
};
