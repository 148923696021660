import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export const useMemorySelectors = () => {
  const Memory = useTypeSelector((s) => s.Memory);
  const employees = Memory.employees;
  const total = Memory.total;
  const page = Memory.page;
  const loader = Memory.loader;

  return {
    employees,
    total,
    page,
    loader
  };
};
