// emojis
import { ReactComponent as E81 } from "assets/emojis/add-friend1.svg";
import { ReactComponent as E48 } from "assets/emojis/alarm-clock.svg";
import { ReactComponent as E13 } from "assets/emojis/angry.svg";
import { ReactComponent as E24 } from "assets/emojis/angry-2.svg";
import { ReactComponent as E68 } from "assets/emojis/archive.svg";
import { ReactComponent as E44 } from "assets/emojis/balloon.svg";
import { ReactComponent as E66 } from "assets/emojis/bar-chart.svg";
import { ReactComponent as E35 } from "assets/emojis/batman.svg";
import { ReactComponent as E62 } from "assets/emojis/battery.svg";
import { ReactComponent as E43 } from "assets/emojis/bomb.svg";
import { ReactComponent as E25 } from "assets/emojis/bored.svg";
import { ReactComponent as E65 } from "assets/emojis/calendar.svg";
import { ReactComponent as E73 } from "assets/emojis/cancel.svg";
import { ReactComponent as E83 } from "assets/emojis/chat-bubble.svg";
import { ReactComponent as E76 } from "assets/emojis/check.svg";
import { ReactComponent as E53 } from "assets/emojis/confetti.svg";
import { ReactComponent as E54 } from "assets/emojis/confetti-3.svg";
import { ReactComponent as E15 } from "assets/emojis/confused.svg";
import { ReactComponent as E37 } from "assets/emojis/deadpool.svg";
import { ReactComponent as E30 } from "assets/emojis/deer.svg";
import { ReactComponent as E60 } from "assets/emojis/diamond.svg";
import { ReactComponent as E28 } from "assets/emojis/dog.svg";
import { ReactComponent as E82 } from "assets/emojis/download-3.svg";
import { ReactComponent as E27 } from "assets/emojis/embarrassed.svg";
import { ReactComponent as E7 } from "assets/emojis/emoji-6.svg";
import { ReactComponent as E77 } from "assets/emojis/favourite.svg";
import { ReactComponent as E59 } from "assets/emojis/folder-7.svg";
import { ReactComponent as E49 } from "assets/emojis/frame.svg";
import { ReactComponent as E46 } from "assets/emojis/gear.svg";
import { ReactComponent as E31 } from "assets/emojis/gorilla.svg";
import { ReactComponent as E69 } from "assets/emojis/gun.svg";
import { ReactComponent as E8 } from "assets/emojis/happy.svg";
import { ReactComponent as E23 } from "assets/emojis/happy-2.svg";
import { ReactComponent as E2 } from "assets/emojis/happy-3.svg";
import { ReactComponent as E70 } from "assets/emojis/heart-8.svg";
import { ReactComponent as E18 } from "assets/emojis/ill.svg";
import { ReactComponent as E6 } from "assets/emojis/in-love.svg";
import { ReactComponent as E67 } from "assets/emojis/keyboard.svg";
import { ReactComponent as E10 } from "assets/emojis/kissing.svg";
import { ReactComponent as E47 } from "assets/emojis/laptop.svg";
import { ReactComponent as E40 } from "assets/emojis/lego.svg";
import { ReactComponent as E41 } from "assets/emojis/lego-2.svg";
import { ReactComponent as E56 } from "assets/emojis/letter.svg";
import { ReactComponent as E5 } from "assets/emojis/like-2.svg";
import { ReactComponent as E11 } from "assets/emojis/like-3.svg";
import { ReactComponent as E29 } from "assets/emojis/lion.svg";
import { ReactComponent as E63 } from "assets/emojis/loupe.svg";
import { ReactComponent as E19 } from "assets/emojis/mad.svg";
import { ReactComponent as E38 } from "assets/emojis/mariachi.svg";
import { ReactComponent as E55 } from "assets/emojis/money.svg";
import { ReactComponent as E32 } from "assets/emojis/monkey.svg";
import { ReactComponent as E26 } from "assets/emojis/nerd.svg";
import { ReactComponent as E17 } from "assets/emojis/ninja.svg";
import { ReactComponent as E75 } from "assets/emojis/no-entry.svg";
import { ReactComponent as E79 } from "assets/emojis/notification.svg";
import { ReactComponent as E33 } from "assets/emojis/panda.svg";
import { ReactComponent as E50 } from "assets/emojis/paperclip.svg";
import { ReactComponent as E52 } from "assets/emojis/pencil-2.svg";
import { ReactComponent as E34 } from "assets/emojis/pig.svg";
import { ReactComponent as E42 } from "assets/emojis/pin-2.svg";
import { ReactComponent as E57 } from "assets/emojis/printer-2.svg";
import { ReactComponent as E64 } from "assets/emojis/push-pin.svg";
import { ReactComponent as E3 } from "assets/emojis/quiet.svg";
import { ReactComponent as E9 } from "assets/emojis/sad.svg";
import { ReactComponent as E39 } from "assets/emojis/santa-claus.svg";
import { ReactComponent as E58 } from "assets/emojis/scissors.svg";
import { ReactComponent as E80 } from "assets/emojis/share-link.svg";
import { ReactComponent as E1 } from "assets/emojis/shocked.svg";
import { ReactComponent as E20 } from "assets/emojis/smart.svg";
import { ReactComponent as E22 } from "assets/emojis/smile-2.svg";
import { ReactComponent as E74 } from "assets/emojis/sos.svg";
import { ReactComponent as E36 } from "assets/emojis/superman.svg";
import { ReactComponent as E16 } from "assets/emojis/surprised.svg";
import { ReactComponent as E14 } from "assets/emojis/suspicious.svg";
import { ReactComponent as E72 } from "assets/emojis/symbols.svg";
import { ReactComponent as E45 } from "assets/emojis/thermometer.svg";
import { ReactComponent as E4 } from "assets/emojis/thinking.svg";
import { ReactComponent as E21 } from "assets/emojis/tongue-out.svg";
import { ReactComponent as E61 } from "assets/emojis/trash.svg";
import { ReactComponent as E12 } from "assets/emojis/unhappy.svg";
import { ReactComponent as E51 } from "assets/emojis/unlocked.svg";
import { ReactComponent as E71 } from "assets/emojis/warning-3.svg";
import { ReactComponent as E78 } from "assets/emojis/world.svg";
// sings
import { ReactComponent as F17 } from "assets/figures/abstract.svg";
import { ReactComponent as F19 } from "assets/figures/abstract-2.svg";
import { ReactComponent as F20 } from "assets/figures/abstract-3.svg";
import { ReactComponent as F21 } from "assets/figures/abstract-4.svg";
import { ReactComponent as F22 } from "assets/figures/abstract-5.svg";
import { ReactComponent as F23 } from "assets/figures/abstract-6.svg";
import { ReactComponent as F24 } from "assets/figures/abstract-7.svg";
import { ReactComponent as F25 } from "assets/figures/abstract-8.svg";
import { ReactComponent as F27 } from "assets/figures/abstract-9.svg";
import { ReactComponent as F18 } from "assets/figures/abstract-shape-2.svg";
import { ReactComponent as F28 } from "assets/figures/abstract-shape-2.svg";
import { ReactComponent as F29 } from "assets/figures/abstract-shape-3.svg";
import { ReactComponent as F30 } from "assets/figures/abstract-shape-3.svg";
import { ReactComponent as F31 } from "assets/figures/abstract-shape-4.svg";
import { ReactComponent as F33 } from "assets/figures/abstract-shape-5.svg";
import { ReactComponent as F34 } from "assets/figures/abstract-shape-6.svg";
import { ReactComponent as F35 } from "assets/figures/abstract-shape-7.svg";
import { ReactComponent as F36 } from "assets/figures/abstract-shape-8.svg";
import { ReactComponent as F43 } from "assets/figures/abstract-shape-9.svg";
import { ReactComponent as F15 } from "assets/figures/arrow.svg";
import { ReactComponent as F16 } from "assets/figures/arrow-2.svg";
import { ReactComponent as F82 } from "assets/figures/ball-of-basketball.svg";
import { ReactComponent as F64 } from "assets/figures/bell-2.svg";
import { ReactComponent as F61 } from "assets/figures/bookmark.svg";
import { ReactComponent as F62 } from "assets/figures/briefcase.svg";
import { ReactComponent as F2 } from "assets/figures/circle.svg";
import { ReactComponent as F37 } from "assets/figures/correct-2.svg";
import { ReactComponent as F67 } from "assets/figures/crown.svg";
import { ReactComponent as F70 } from "assets/figures/dashboard.svg";
import { ReactComponent as F51 } from "assets/figures/down-arrow.svg";
import { ReactComponent as F52 } from "assets/figures/down-arrow-2.svg";
import { ReactComponent as F53 } from "assets/figures/down-arrow-3.svg";
import { ReactComponent as F54 } from "assets/figures/down-arrow-4.svg";
import { ReactComponent as F8 } from "assets/figures/ellipse.svg";
import { ReactComponent as F44 } from "assets/figures/equal.svg";
import { ReactComponent as F45 } from "assets/figures/equal-2.svg";
import { ReactComponent as F80 } from "assets/figures/fire.svg";
import { ReactComponent as F56 } from "assets/figures/forbidden.svg";
import { ReactComponent as F71 } from "assets/figures/gear-2.svg";
import { ReactComponent as F48 } from "assets/figures/greater-than.svg";
import { ReactComponent as F47 } from "assets/figures/greater-than-2.svg";
import { ReactComponent as F26 } from "assets/figures/growth.svg";
import { ReactComponent as F1 } from "assets/figures/heart-13.svg";
import { ReactComponent as F4 } from "assets/figures/hexagon.svg";
import { ReactComponent as F69 } from "assets/figures/horse-black-head-shape-of-a-chess-piece.svg";
import { ReactComponent as F77 } from "assets/figures/idea.svg";
import { ReactComponent as F81 } from "assets/figures/leaf.svg";
import { ReactComponent as F63 } from "assets/figures/left-quote.svg";
import { ReactComponent as F42 } from "assets/figures/local.svg";
import { ReactComponent as F66 } from "assets/figures/microphone.svg";
import { ReactComponent as F50 } from "assets/figures/multiplication-sign.svg";
import { ReactComponent as F32 } from "assets/figures/mystic.svg";
import { ReactComponent as F65 } from "assets/figures/paw.svg";
import { ReactComponent as F55 } from "assets/figures/peace-symbo.svg";
import { ReactComponent as F57 } from "assets/figures/phone-receiver-silhouette.svg";
import { ReactComponent as F7 } from "assets/figures/pig.svg";
import { ReactComponent as F58 } from "assets/figures/plane-2.svg";
import { ReactComponent as F3 } from "assets/figures/plus-4.svg";
import { ReactComponent as F10 } from "assets/figures/plus-5.svg";
import { ReactComponent as F46 } from "assets/figures/plus-6.svg";
import { ReactComponent as F68 } from "assets/figures/queen.svg";
import { ReactComponent as F75 } from "assets/figures/refresh-2.svg";
import { ReactComponent as F6 } from "assets/figures/rhomb.svg";
import { ReactComponent as F9 } from "assets/figures/ring-2.svg";
import { ReactComponent as F49 } from "assets/figures/sign.svg";
import { ReactComponent as F74 } from "assets/figures/space-rocket-launch.svg";
import { ReactComponent as F14 } from "assets/figures/square.svg";
import { ReactComponent as F12 } from "assets/figures/star-3.svg";
import { ReactComponent as F59 } from "assets/figures/star-5.svg";
import { ReactComponent as F72 } from "assets/figures/students-cap.svg";
import { ReactComponent as F38 } from "assets/figures/swap.svg";
import { ReactComponent as F39 } from "assets/figures/swap-2.svg";
import { ReactComponent as F40 } from "assets/figures/swap-3.svg";
import { ReactComponent as F41 } from "assets/figures/swap-4.svg";
import { ReactComponent as F79 } from "assets/figures/thunder.svg";
import { ReactComponent as F60 } from "assets/figures/time.svg";
import { ReactComponent as F83 } from "assets/figures/timer.svg";
import { ReactComponent as F5 } from "assets/figures/trefoil.svg";
import { ReactComponent as F11 } from "assets/figures/triangle.svg";
import { ReactComponent as F13 } from "assets/figures/triangle-2.svg";
import { ReactComponent as F73 } from "assets/figures/trophy-3.svg";
import { ReactComponent as F76 } from "assets/figures/web.svg";
import { ReactComponent as F78 } from "assets/figures/wind.svg";
import { EMOJIS, SIGNS } from "generalComponents/variables/elements";

export const switchSign = (el: SIGNS): JSX.Element => {
  switch (el) {
    case SIGNS.F1:
      return <F1 />;
    case SIGNS.F2:
      return <F2 />;
    case SIGNS.F3:
      return <F3 />;
    case SIGNS.F4:
      return <F4 />;
    case SIGNS.F5:
      return <F5 />;
    case SIGNS.F6:
      return <F6 />;
    case SIGNS.F7:
      return <F7 />;
    case SIGNS.F8:
      return <F8 />;
    case SIGNS.F9:
      return <F9 />;
    case SIGNS.F10:
      return <F10 />;
    case SIGNS.F11:
      return <F11 />;
    case SIGNS.F12:
      return <F12 />;
    case SIGNS.F13:
      return <F13 />;
    case SIGNS.F14:
      return <F14 />;
    case SIGNS.F15:
      return <F15 />;
    case SIGNS.F16:
      return <F16 />;
    case SIGNS.F17:
      return <F17 />;
    case SIGNS.F18:
      return <F18 />;
    case SIGNS.F19:
      return <F19 />;
    case SIGNS.F20:
      return <F20 />;
    case SIGNS.F21:
      return <F21 />;
    case SIGNS.F22:
      return <F22 />;
    case SIGNS.F23:
      return <F23 />;
    case SIGNS.F24:
      return <F24 />;
    case SIGNS.F25:
      return <F25 />;
    case SIGNS.F26:
      return <F26 />;
    case SIGNS.F27:
      return <F27 />;
    case SIGNS.F28:
      return <F28 />;
    case SIGNS.F29:
      return <F29 />;
    case SIGNS.F30:
      return <F30 />;
    case SIGNS.F31:
      return <F31 />;
    case SIGNS.F32:
      return <F32 />;
    case SIGNS.F33:
      return <F33 />;
    case SIGNS.F34:
      return <F34 />;
    case SIGNS.F35:
      return <F35 />;
    case SIGNS.F36:
      return <F36 />;
    case SIGNS.F37:
      return <F37 />;
    case SIGNS.F38:
      return <F38 />;
    case SIGNS.F39:
      return <F39 />;
    case SIGNS.F40:
      return <F40 />;
    case SIGNS.F41:
      return <F41 />;
    case SIGNS.F42:
      return <F42 />;
    case SIGNS.F43:
      return <F43 />;
    case SIGNS.F44:
      return <F44 />;
    case SIGNS.F45:
      return <F45 />;
    case SIGNS.F46:
      return <F46 />;
    case SIGNS.F47:
      return <F47 />;
    case SIGNS.F48:
      return <F48 />;
    case SIGNS.F49:
      return <F49 />;
    case SIGNS.F50:
      return <F50 />;
    case SIGNS.F51:
      return <F51 />;
    case SIGNS.F52:
      return <F52 />;
    case SIGNS.F53:
      return <F53 />;
    case SIGNS.F54:
      return <F54 />;
    case SIGNS.F55:
      return <F55 />;
    case SIGNS.F56:
      return <F56 />;
    case SIGNS.F57:
      return <F57 />;
    case SIGNS.F58:
      return <F58 />;
    case SIGNS.F59:
      return <F59 />;
    case SIGNS.F60:
      return <F60 />;
    case SIGNS.F61:
      return <F61 />;
    case SIGNS.F62:
      return <F62 />;
    case SIGNS.F63:
      return <F63 />;
    case SIGNS.F64:
      return <F64 />;
    case SIGNS.F65:
      return <F65 />;
    case SIGNS.F66:
      return <F66 />;
    case SIGNS.F67:
      return <F67 />;
    case SIGNS.F68:
      return <F68 />;
    case SIGNS.F69:
      return <F69 />;
    case SIGNS.F70:
      return <F70 />;
    case SIGNS.F71:
      return <F71 />;
    case SIGNS.F72:
      return <F72 />;
    case SIGNS.F73:
      return <F73 />;
    case SIGNS.F74:
      return <F74 />;
    case SIGNS.F75:
      return <F75 />;
    case SIGNS.F76:
      return <F76 />;
    case SIGNS.F77:
      return <F77 />;
    case SIGNS.F78:
      return <F78 />;
    case SIGNS.F79:
      return <F79 />;
    case SIGNS.F80:
      return <F80 />;
    case SIGNS.F81:
      return <F81 />;
    case SIGNS.F82:
      return <F82 />;
    case SIGNS.F83:
      return <F83 />;
    default:
      break;
  }
};

export const switchEmoji = (el: EMOJIS): JSX.Element => {
  switch (el) {
    case EMOJIS.E1:
      return <E1 />;
    case EMOJIS.E2:
      return <E2 />;
    case EMOJIS.E3:
      return <E3 />;
    case EMOJIS.E4:
      return <E4 />;
    case EMOJIS.E5:
      return <E5 />;
    case EMOJIS.E6:
      return <E6 />;
    case EMOJIS.E7:
      return <E7 />;
    case EMOJIS.E8:
      return <E8 />;
    case EMOJIS.E9:
      return <E9 />;
    case EMOJIS.E10:
      return <E10 />;
    case EMOJIS.E11:
      return <E11 />;
    case EMOJIS.E12:
      return <E12 />;
    case EMOJIS.E13:
      return <E13 />;
    case EMOJIS.E14:
      return <E14 />;
    case EMOJIS.E15:
      return <E15 />;
    case EMOJIS.E16:
      return <E16 />;
    case EMOJIS.E17:
      return <E17 />;
    case EMOJIS.E18:
      return <E18 />;
    case EMOJIS.E19:
      return <E19 />;
    case EMOJIS.E20:
      return <E20 />;
    case EMOJIS.E21:
      return <E21 />;
    case EMOJIS.E22:
      return <E22 />;
    case EMOJIS.E23:
      return <E23 />;
    case EMOJIS.E24:
      return <E24 />;
    case EMOJIS.E25:
      return <E25 />;
    case EMOJIS.E26:
      return <E26 />;
    case EMOJIS.E27:
      return <E27 />;
    case EMOJIS.E28:
      return <E28 />;
    case EMOJIS.E29:
      return <E29 />;
    case EMOJIS.E30:
      return <E30 />;
    case EMOJIS.E31:
      return <E31 />;
    case EMOJIS.E32:
      return <E32 />;
    case EMOJIS.E33:
      return <E33 />;
    case EMOJIS.E34:
      return <E34 />;
    case EMOJIS.E35:
      return <E35 />;
    case EMOJIS.E36:
      return <E36 />;
    case EMOJIS.E37:
      return <E37 />;
    case EMOJIS.E38:
      return <E38 />;
    case EMOJIS.E39:
      return <E39 />;
    case EMOJIS.E40:
      return <E40 />;
    case EMOJIS.E41:
      return <E41 />;
    case EMOJIS.E42:
      return <E42 />;
    case EMOJIS.E43:
      return <E43 />;
    case EMOJIS.E44:
      return <E44 />;
    case EMOJIS.E45:
      return <E45 />;
    case EMOJIS.E46:
      return <E46 />;
    case EMOJIS.E47:
      return <E47 />;
    case EMOJIS.E48:
      return <E48 />;
    case EMOJIS.E49:
      return <E49 />;
    case EMOJIS.E50:
      return <E50 />;
    case EMOJIS.E51:
      return <E51 />;
    case EMOJIS.E52:
      return <E52 />;
    case EMOJIS.E53:
      return <E53 />;
    case EMOJIS.E54:
      return <E54 />;
    case EMOJIS.E55:
      return <E55 />;
    case EMOJIS.E56:
      return <E56 />;
    case EMOJIS.E57:
      return <E57 />;
    case EMOJIS.E58:
      return <E58 />;
    case EMOJIS.E59:
      return <E59 />;
    case EMOJIS.E60:
      return <E60 />;
    case EMOJIS.E61:
      return <E61 />;
    case EMOJIS.E62:
      return <E62 />;
    case EMOJIS.E63:
      return <E63 />;
    case EMOJIS.E64:
      return <E64 />;
    case EMOJIS.E65:
      return <E65 />;
    case EMOJIS.E66:
      return <E66 />;
    case EMOJIS.E67:
      return <E67 />;
    case EMOJIS.E68:
      return <E68 />;
    case EMOJIS.E69:
      return <E69 />;
    case EMOJIS.E70:
      return <E70 />;
    case EMOJIS.E71:
      return <E71 />;
    case EMOJIS.E72:
      return <E72 />;
    case EMOJIS.E73:
      return <E73 />;
    case EMOJIS.E74:
      return <E74 />;
    case EMOJIS.E75:
      return <E75 />;
    case EMOJIS.E76:
      return <E76 />;
    case EMOJIS.E77:
      return <E77 />;
    case EMOJIS.E78:
      return <E78 />;
    case EMOJIS.E79:
      return <E79 />;
    case EMOJIS.E80:
      return <E80 />;
    case EMOJIS.E81:
      return <E81 />;
    case EMOJIS.E82:
      return <E82 />;
    case EMOJIS.E83:
      return <E83 />;

    default:
      break;
  }
};
