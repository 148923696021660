import Button from "generalComponents/Button/Button";
import { ButtonVariantType } from "models/generalComponents/button";
import { IWarningModalProps } from "models/modals/projectModals";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./WarningModal.module.sass";

const WarningModal: React.FC<IWarningModalProps> = ({ onClose, params }): JSX.Element => {
  const { __ } = useLocales();

  return (
    <div className={styles.wrap}>
      {params.text}
      <Button type="button" variant={ButtonVariantType.OK} onClick={onClose} text={__("Закрыть")} />
    </div>
  );
};

export default WarningModal;
