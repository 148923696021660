import styles from "./TarrifPrice.module.sass";

interface TariffPriceProps {
  value: string | number;
  discount: string | number | null;
}

const TariffPrice = ({ value, discount }: TariffPriceProps) => {
  const hasDiscount = discount !== null;
  const isValueString = typeof value === "string";

  return (
    <div className={styles.titleCell}>
      {hasDiscount ? (
        <>
          <span className={styles.discount}>
            {isValueString ? (
              value
            ) : (
              <>
                <del>{value}</del>
                <sup>$</sup>
              </>
            )}
          </span>
          <div>
            {discount}
            <sup>$</sup>
          </div>
        </>
      ) : (
        <div>
          {isValueString ? (
            value
          ) : (
            <>
              {value}
              <sup>$</sup>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TariffPrice;
