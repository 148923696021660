import SignItem from "generalComponents/Elements/SignItem";
import Tag from "generalComponents/Elements/Tag";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { Tooltip } from "generalComponents/MainTooltip/Tooltip";
import { calculateSize } from "generalComponents/Services/helpers";
import { TagViewTypes } from "models/generalComponents/elements";
import { IItemInfo } from "models/generalComponents/itemInfo";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./ItemInfo.module.sass";

const ItemInfo: React.FC<IItemInfo> = ({ items, item }): JSX.Element => {
  const { __ } = useLocales();

  const renderIconContent = (): JSX.Element => {
    if (item.is_dir === 1) {
      return <ItemIcon width={58} height={48} color={item.color} passIcon={item.is_pass === 1} isFolder />;
    }

    return (
      <ItemIcon
        width={42}
        height={56}
        color={item.color}
        passIcon={item.is_pass === 1}
        ext={(item as IFile).ext}
        isFolder={false}
      />
    );
  };

  const renderContentOption = (): JSX.Element => {
    if (items.length === 1) {
      return (
        <>
          <div className={styles.innerItemWrap}>
            <Tooltip content={item.name}>{renderIconContent()}</Tooltip>
          </div>
          <div className={styles.descriptionWrap}>
            <div className={styles.topContentWrap}>
              <div className={styles.itemName}>{item.name}</div>
              <div className={styles.itemSize}>{calculateSize(item.size)}</div>
            </div>
            <div className={styles.descriptionGroup}>
              {((item as IFolder).tags || (item as IFile).tag) && (
                <Tag variant={TagViewTypes.LIGHT} lineHeight={18} maxWidth={115}>
                  {`#${(item as IFolder).tags || (item as IFile).tag}`}
                </Tag>
              )}
              {item.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: item.emo }} />}
              {item.fig && <SignItem sign={item.fig} size={16} />}
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <ul className={styles.itemsIconList}>
          {items.map((item) => (
            <li key={item.is_dir === 1 ? (item as IFolder).id_dir : (item as IFile).fid} className={styles.itemIcon}>
              <Tooltip content={item.name}>
                <ItemIcon
                  isFolder={item.is_dir === 1}
                  width={item.is_dir ? 58 : 42}
                  height={item.is_dir ? 48 : 56}
                  color={item.color}
                  passIcon={item.is_pass === 1}
                  ext={(item as IFile).ext}
                />
              </Tooltip>
            </li>
          ))}
        </ul>
        <div className={styles.severalChosen}>{`${__("Выбранно")} ${items.length} ${__("элементов")}`}</div>
      </>
    );
  };

  return <div className={styles.wrap}>{renderContentOption()}</div>;
};

export default ItemInfo;
