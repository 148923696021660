import classNames from "classnames";
import { useMonths } from "generalComponents/Calendars/CalendarHelper";
import { useTasksCalendar } from "generalComponents/Services/calendarPageServices";
import { BOARDS, STYLED_CLASSES, TASK_TYPES, TASKS_SCHEMA } from "generalComponents/variables/tasks";
import React, { useState } from "react";

import DaysOfWeeks from "../DaysOfWeeks/DaysOfWeeks";
import TasksBlock from "../TasksBlock/TasksBlock";
import TasksTimetable from "../TasksTimetable/TasksTimetable";
import styles from "./DayView.module.sass";

const DayView: React.FC = (): JSX.Element => {
  const [schema, setSchema] = useState<TASKS_SCHEMA>(TASKS_SCHEMA.GRID_BAR);
  const { meetings, calls, mails } = useTasksCalendar();
  const months = useMonths();

  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        <div className={styles.date}>
          <div className={styles.day}>{new Date().getDate()}</div>
          <div className={styles.month}>{months()[new Date().getMonth()].declensionName}</div>
        </div>
        <div className={styles.weekBox}>
          <DaysOfWeeks />
        </div>
      </div>
      <div className={classNames(styles.girdBoardWrap, styles[schema])}>
        {Object.values(BOARDS).map((boardName, i) => (
          <div key={i} className={styles[STYLED_CLASSES[schema][i]]}>
            {boardName === BOARDS.MEETINGS && (
              <TasksBlock
                tasks={meetings}
                type={TASK_TYPES.MEETINGS}
                isFull={schema === TASKS_SCHEMA.EXPANDED_MEETINGS_BOARD}
                setSchema={setSchema}
                isGrid
              />
            )}
            {boardName === BOARDS.CALLS && (
              <TasksBlock
                tasks={calls}
                type={TASK_TYPES.CALLS}
                isFull={schema === TASKS_SCHEMA.EXPANDED_CALLS_BOARD}
                setSchema={setSchema}
                isGrid
              />
            )}
            {boardName === BOARDS.MAILS && (
              <TasksBlock
                tasks={mails}
                type={TASK_TYPES.MAILS}
                isFull={schema === TASKS_SCHEMA.EXPANDED_MAIL_BOARD}
                setSchema={setSchema}
                isGrid
              />
            )}
            {boardName === BOARDS.TASK && <TasksTimetable />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DayView;
