import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import iconDefault from "assets/images/library/rubrics/default.png";
import iconDefault2x from "assets/images/library/rubrics/default@2x.png";
import classNames from "classnames";
import { useContentRubrics } from "collections/library";
import { useContextLibraryRubric } from "collections/library";
import Button from "generalComponents/Button/Button";
import { Image } from "generalComponents/Image/Image";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { LibraryTypesRubrics } from "generalComponents/variables/library";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ILibraryRubric } from "models/store/library/library";
import { FC } from "react";

import styles from "./PreviewRubricLibrary.module.sass";

export interface IPreviewFolderLibraryProps {
  rubric: ILibraryRubric;
}

export const PreviewRubricLibrary: FC<IPreviewFolderLibraryProps> = ({ rubric }): JSX.Element => {
  const rubrics = useContentRubrics();

  const rubricName = rubric.name as Exclude<LibraryTypesRubrics, LibraryTypesRubrics.ALL_RUBRICS>;
  const rubricDefault = rubric.is_system === "1";

  const { formatDate } = useDateFormat();

  const { onTrashRubricModal, onVisibleEditRubricLibraryModal } = useContextLibraryRubric();

  return (
    <div className={styles.card}>
      <div
        className={classNames(styles.card__imgBox, "flex-center", {
          [styles.notSystem]: !rubricDefault
        })}
      >
        <Image
          src={
            rubricDefault ? rubrics[rubricName]?.image?.src : !rubric.icon ? iconDefault : `${projectSrc}${rubric.icon}`
          }
          srcSet={rubricDefault ? rubrics[rubricName]?.image?.srcSet : !rubric.icon ? `${iconDefault2x} 2x` : undefined}
          alt={rubricDefault ? rubrics[rubricName]?.image?.alt : rubricName}
          className={styles.item__img}
        />
      </div>
      <div className={styles.card__body}>
        <h3 className={styles.card__name}>{rubricDefault ? rubrics[rubricName]?.title : rubricName}</h3>
        {!rubricDefault && <span className={styles.card__date}>{formatDate(rubric.mdate, "full")}</span>}
      </div>
      {!rubricDefault && (
        <div className={styles.card__btns}>
          <Button
            variant={ButtonVariantType.OPACITY}
            onClick={() => onVisibleEditRubricLibraryModal(rubric)}
            size={ButtonSizeType.MEDIUM}
            iconL={<EditIcon width={20} height={20} fill="#274A42" />}
          />
          <Button
            variant={ButtonVariantType.OPACITY}
            onClick={() => onTrashRubricModal(rubric)}
            size={ButtonSizeType.MEDIUM}
            iconL={<TrashIcon width={20} height={20} fill="#274A42" />}
          />
        </div>
      )}
    </div>
  );
};
