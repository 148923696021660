import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/Path.svg";
import DownArrow from "generalComponents/DownArrow/DownArrow";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { URGENCY_TYPES } from "generalComponents/variables/tasks";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onAddProjectTask } from "Store/actions/ProjectActions";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { taskTypes } from "types/Project";

import BacklogInput from "../../Components/BacklogInput/BacklogInput";
import { useProjectMsg } from "../../helpers";
import BacklogTask from "../BacklogTask/BacklogTask";
import { BacklogProvider } from "../ProjectBacklog";
import styles from "./BacklogRole.module.sass";

const BacklogRole = ({ roleId, tasks, handleSelected, selectedTasks }) => {
  const { __ } = useLocales();
  const MSG = useProjectMsg();

  const dispatch = useDispatch();
  const { project } = useProjectsSelectors();
  const { userInfo } = useUserSelectors();
  const [isOpen, setIsOpen] = useState(false);
  const [isAddFile, setIsAddFile] = useState(false);

  const { isOpenAll } = useContext(BacklogProvider);

  useEffect(() => {
    setIsOpen(isOpenAll);
  }, [isOpenAll]);

  const toggleOpen = () => setIsOpen((open) => !open);
  const toggleAdd = () => setIsAddFile((open) => !open);

  const handleSubmit = (value) => {
    const messages = {
      error: MSG.ERROR,
      success: MSG[PROJECT_MODALS.ADD_TASK]
    };

    const payload = {
      name: value,
      tags: "",
      prim: "",
      date_start: "",
      date_end: "",
      date_long: "",
      id_act: URGENCY_TYPES.PLANNED,
      id_executor: [],
      id_project: project.id,
      id_parent: "",
      sort: "10",
      fids: [],
      is_epic: 0,
      id_epic: 0,
      is_backlog: Number(roleId),
      author: userInfo.id_user
    };
    dispatch(onAddProjectTask(payload, messages));
    toggleAdd();
  };

  const name = useMemo(() => project.roles.find((r) => r.id === roleId)?.name, [project.roles, roleId]);

  return (
    <div className={styles.wrap}>
      <div className={styles.block}>
        <div className={styles.name}>
          <span>
            {name ? name : __("Без роли")}&nbsp;({tasks.length})
          </span>
          <DownArrow isOpen={isOpen} clickHandler={toggleOpen} />
        </div>
        <div className={styles.add} onClick={toggleAdd}>
          <PlusIcon />
        </div>
      </div>
      {isAddFile && <BacklogInput textButton={__("Создать")} onSubmit={handleSubmit} />}
      {isOpen && (
        <>
          {tasks.map((task) => (
            <BacklogTask key={task.id} task={task} handleSelected={handleSelected} selectedTasks={selectedTasks} />
          ))}
        </>
      )}
    </div>
  );
};

export default BacklogRole;

BacklogRole.propTypes = {
  roleId: PropTypes.string,
  tasks: PropTypes.arrayOf(taskTypes),
  handleSelected: PropTypes.func,
  selectedTasks: PropTypes.arrayOf(taskTypes)
};
