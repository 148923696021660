import classNames from "classnames";
import Loader from "generalComponents/Loaders/4HUB";
import SearchField from "generalComponents/SearchField/SearchField";
import { useActions } from "hooks/useActions";
import { useDebounce } from "hooks/useDebounce";
import { LoaderTypes } from "models/generalComponents/loader";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useProgramsSelectors } from "Store/selectors/programsSelectors";

import styles from "./ProgramSearch.module.sass";

const ProgramSearch = () => {
  const { __ } = useLocales();

  const { searchPrograms } = useActions();
  const { searchedPrograms, isSearchProgramsLoading } = useProgramsSelectors();

  const [search, setSearch] = useState("");
  const searchPlaceholder = __("Введите название программы");

  const searchProgramsCallback = () => {
    searchPrograms(search);
  };

  const debounceCallback = useDebounce(searchProgramsCallback, 300);

  useEffect(() => {
    debounceCallback();
  }, [search]); //eslint-disable-line

  return (
    <div className={styles.searchWrapper}>
      <SearchField value={search} setValue={setSearch} placeholder={searchPlaceholder}>
        {!isSearchProgramsLoading ? (
          searchedPrograms.length > 0 ? (
            searchedPrograms.map((program) => (
              <div key={program.id} className={styles.searchItemWrapper}>
                <a href={program.link} className={styles.searchItem} target="_blank" rel="noreferrer">
                  <div className={styles.searchItemIcon}>
                    <img src={program.icon} alt="" />
                  </div>
                  {program.name}
                </a>
              </div>
            ))
          ) : search.length > 1 && !isSearchProgramsLoading ? (
            <div>{__("Нет результатов")}</div>
          ) : null
        ) : (
          <div
            className={classNames({
              [styles.programsSearchLoader]: isSearchProgramsLoading
            })}
          >
            <Loader
              type={LoaderTypes.BOUNCING_DOTS}
              position="absolute"
              background="white"
              zIndex={5}
              width="100px"
              height="100px"
              containerType="bounceDots"
            />
          </div>
        )}
      </SearchField>
    </div>
  );
};

export default ProgramSearch;
