import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as LogoIcon } from "assets/PrivateCabinet/sideMenu/logo.svg";
import { ReactComponent as LogoTextIcon } from "assets/PrivateCabinet/sideMenu/logo-text.svg";
import classNames from "classnames";
import LangPicker from "generalComponents/LangPicker";
import { ROUTES } from "generalComponents/variables/routing";
import React from "react";
import { useLocales } from "react-localized";
import { Link, NavLink } from "react-router-dom";

import styles from "./HeaderMain.module.sass";

const HeaderMain = () => {
  const { __ } = useLocales();
  return (
    <header className={styles.header}>
      <Link to="https://hub-landing-df642.web.app/">
        <LogoIcon />
        <LogoTextIcon />
      </Link>
      <div className={styles.left}>
        <NavLink
          to={ROUTES.INFO}
          className={({ isActive }) => {
            return classNames(styles.info, {
              [styles.active]: isActive
            });
          }}
        >
          <InfoIcon />
        </NavLink>
        <LangPicker />
        <NavLink
          to={ROUTES.SIGN_IN}
          className={({ isActive }) => {
            return classNames(styles.link, {
              [styles.active]: isActive
            });
          }}
        >
          {__("Вход")}
        </NavLink>
        <NavLink
          to={ROUTES.SIGN_UP}
          className={({ isActive }) => {
            return classNames(styles.link, styles.registration, {
              [styles.active]: isActive
            });
          }}
        >
          {__("Регистрация")}
        </NavLink>
      </div>
    </header>
  );
};

export default HeaderMain;
