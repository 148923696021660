import calendarNormal from "assets/Dashboard/calendar.png";
import calendarBig from "assets/Dashboard/calendarBig.png";
import calendarHigh from "assets/Dashboard/calendarHigh.png";
import employee from "assets/Dashboard/employee.png";
import employeeLong from "assets/Dashboard/employeeLong.png";
import favorite from "assets/Dashboard/favorite.png";
import favoriteGrid from "assets/Dashboard/favoriteGrid.png";
import jointProjectStatistic from "assets/Dashboard/jointProjectStatistic.png";
import jointProjectStatusStatistic from "assets/Dashboard/jointProjectStatusStatistic.png";
import jointProjectStatusStatisticNormal from "assets/Dashboard/jointProjectStatusStatisticNormal.png";
import lastFilesLong from "assets/Dashboard/last-files-long.png";
import lastFiles from "assets/Dashboard/lastfile.png";
import mail from "assets/Dashboard/mail.png";
import safeNormal from "assets/Dashboard/safe.png";
import safeGrid from "assets/Dashboard/safeGrid.png";
import sharedFile from "assets/Dashboard/sharedFile.png";
import sharedFileGrid from "assets/Dashboard/sharedFileGrid.png";
import sharedFileLong from "assets/Dashboard/sharedFileLong.png";
import tasks from "assets/Dashboard/tasks.png";
import tasksBig from "assets/Dashboard/tasksBig.png";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar_4.svg";
import { ReactComponent as TeamIcon } from "assets/icons/group-team.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail_2.svg";
import { ReactComponent as EmployeesIcon } from "assets/icons/person_4.svg";
import { ReactComponent as SafeIcon } from "assets/icons/safe.svg";
import { ReactComponent as TaskIcon } from "assets/PrivateCabinet/calendar-4.svg";
import { ReactComponent as FolderIcon } from "assets/PrivateCabinet/folder.svg";
import { useLocales } from "react-localized";

export interface IPlaceholder {
  w: number;
  h: number;
  img: string;
}

export interface IWidget {
  name?: string;
  w: number;
  h: number;
  category: WidgetCategory;
  placeholder: IPlaceholder;
  type?: "long" | "normal" | "big" | "normal-grid" | "high";
  projectId?: string;
  teamId?: string;
  updateJointProjectIds?: () => void;
}

export interface IDashboardSection {
  name: string;
  icon: React.ReactNode;
  content: IWidget[];
}

export interface IDashboardSettings {
  [key: string]: IWidget[];
}

export enum WidgetCategory {
  LastFiles = "lastFiles",
  Share = "share",
  Favorites = "favorites",
  Basket = "basket",
  Safe = "safe",
  Calendar = "calendar",
  Tasks = "tasks",
  Employees = "employees",
  Mail = "mail",
  JointProjectStatistic = "jointProjectStatistic",
  JointProjectTeam = "JointProjectTeam",
  JointProjectStatusStatistic = "JointProjectStatusStatistic"
}

export const useDashboardSettings = (): IDashboardSection[] => {
  const { __ } = useLocales();

  return [
    {
      name: __("Папки"),
      icon: <FolderIcon />,
      content: [
        {
          name: __("Последние файлы"),
          w: 6,
          h: 1,
          category: WidgetCategory.LastFiles,
          type: "long",
          placeholder: { w: 6, h: 1, img: lastFilesLong }
        },
        {
          name: __("Последние файлы"),
          w: 2,
          h: 2,
          category: WidgetCategory.LastFiles,
          type: "normal",
          placeholder: { w: 3, h: 2, img: lastFiles }
        },

        {
          name: __("Предоставленный доступ"),
          w: 4,
          h: 2,
          category: WidgetCategory.Share,
          type: "big",
          placeholder: { w: 6, h: 2, img: sharedFileLong }
        },
        {
          name: __("Предоставленный доступ"),
          w: 2,
          h: 2,
          category: WidgetCategory.Share,
          type: "normal-grid",
          placeholder: { w: 3, h: 2, img: sharedFile }
        },
        {
          name: __("Предоставленный доступ"),
          w: 2,
          h: 2,
          category: WidgetCategory.Share,
          type: "normal",
          placeholder: { w: 3, h: 2, img: sharedFileGrid }
        },
        {
          name: __("Избранное"),
          w: 2,
          h: 2,
          category: WidgetCategory.Favorites,
          type: "normal-grid",
          placeholder: { w: 3, h: 2, img: favoriteGrid }
        },
        {
          name: __("Избранное"),
          w: 2,
          h: 2,
          category: WidgetCategory.Favorites,
          type: "normal",
          placeholder: { w: 3, h: 2, img: favorite }
        },
        {
          name: __("Корзина"),
          w: 2,
          h: 2,
          category: WidgetCategory.Basket,
          placeholder: { w: 3, h: 2, img: favorite }
        }
      ]
    },
    {
      name: __("Сейф"),
      icon: <SafeIcon />,
      content: [
        {
          name: __("Сейф"),
          w: 2,
          h: 2,
          category: WidgetCategory.Safe,
          type: "normal-grid",
          placeholder: { w: 3, h: 2, img: safeGrid }
        },
        {
          name: __("Сейф"),
          w: 2,
          h: 2,
          type: "normal",
          category: WidgetCategory.Safe,
          placeholder: { w: 3, h: 2, img: safeNormal }
        }
      ]
    },
    {
      name: __("Календарь"),
      icon: <CalendarIcon width={25} height={25} fill="#EAB001" />,
      content: [
        {
          w: 2,
          h: 6,
          category: WidgetCategory.Calendar,
          type: "high",
          placeholder: { w: 3, h: 4, img: calendarHigh }
        },
        {
          w: 2,
          h: 2,
          type: "normal",
          category: WidgetCategory.Calendar,
          placeholder: { w: 3, h: 2, img: calendarNormal }
        },
        {
          w: 4,
          h: 2,
          category: WidgetCategory.Calendar,
          type: "long",
          placeholder: { w: 6, h: 2, img: calendarBig }
        }
      ]
    },
    {
      name: __("Задачи"),
      icon: <TaskIcon width={20} height={20} />,
      content: [
        {
          name: __("Задачи"),
          w: 4,
          h: 2,
          category: WidgetCategory.Tasks,
          type: "big",
          placeholder: { w: 6, h: 2, img: tasksBig }
        },
        {
          name: __("Задачи"),
          w: 2,
          h: 2,
          category: WidgetCategory.Tasks,
          type: "normal",
          placeholder: { w: 3, h: 2, img: tasks }
        }
      ]
    },
    {
      name: __("Совместный проект"),
      icon: <TeamIcon width={22} height={22} fill="#02AAB0" />,
      content: [
        {
          name: __("Совместный проект"),
          w: 2,
          h: 2,
          category: WidgetCategory.JointProjectStatistic,
          type: "normal",
          placeholder: { w: 3, h: 2, img: jointProjectStatistic }
        },
        {
          name: __("Команды"),
          w: 2,
          h: 2,
          category: WidgetCategory.JointProjectTeam,
          type: "normal",
          placeholder: { w: 3, h: 2, img: employee },
          projectId: undefined,
          teamId: undefined
        },
        {
          name: __("Команды"),
          w: 6,
          h: 1,
          category: WidgetCategory.JointProjectTeam,
          type: "long",
          placeholder: { w: 6, h: 1, img: employeeLong },
          projectId: undefined,
          teamId: undefined
        },
        {
          name: __("Статистика"),
          w: 6,
          h: 2,
          category: WidgetCategory.JointProjectStatusStatistic,
          type: "long",
          placeholder: { w: 6, h: 1, img: jointProjectStatusStatistic },
          projectId: undefined,
          teamId: undefined
        },
        {
          name: __("Статистика"),
          w: 4,
          h: 2,
          category: WidgetCategory.JointProjectStatusStatistic,
          type: "normal",
          placeholder: { w: 3, h: 2, img: jointProjectStatusStatisticNormal },
          projectId: undefined,
          teamId: undefined
        }
      ]
    },
    {
      name: __("Колеги"),
      icon: <EmployeesIcon width={30} height={30} fill="#1429E3" />,
      content: [
        {
          name: __("Колеги"),
          w: 6,
          h: 1,
          category: WidgetCategory.Employees,
          type: "long",
          placeholder: { w: 6, h: 1, img: employeeLong }
        },
        {
          name: __("Колеги"),
          w: 2,
          h: 2,
          category: WidgetCategory.Employees,
          type: "normal",
          placeholder: { w: 3, h: 2, img: employee }
        }
      ]
    },
    {
      name: __("Почта"),
      icon: <MailIcon width={25} height={25} />,
      content: [
        {
          name: __("Почта"),
          w: 2,
          h: 2,
          category: WidgetCategory.Mail,
          placeholder: { w: 3, h: 2, img: mail }
        }
      ]
    }
  ];
};
