import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import classNames from "classnames";
import { colors } from "collections/markers";
import { useNotesCategoriesNames } from "collections/notes";
import NoteItem from "containers/Notes/NotesCategories/components/NoteItem/NoteItem";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { INote, INoteCategory, INoteSize } from "models/store/notes/notes";
import { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { useLocales } from "react-localized";
import { useNotesSelectors } from "Store/selectors/notesSelectors";
import { convertToRGBA } from "utils/convertToRGBA";

import styles from "./NotesItemCategory.module.sass";

interface INotesCategoryItemProps {
  category: INoteCategory;
  search?: string;
  isEdit?: boolean;
}

const NotesItemCategory = ({ category, search, isEdit }: INotesCategoryItemProps) => {
  const { __ } = useLocales();
  const {
    onSetNotesCategoriesModal,
    onDeleteNoteCategory,
    onEditNoteCategory,
    onEditNote,
    onSetNoteFullCategoryModal,
    onSetNoteEditModal,
    onSetActiveNote
  } = useActions();
  const { color, emo, tags } = useAllSelectedSearchParams();

  const [{ isOverCurrent }, drop] = useDrop(() => ({
    accept: "NOTE_ITEM",
    drop: (item: INote, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }
      onEditNote({ id_item: item.id, id_dep: category.id });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  }));

  const [categoryColor, setCategoryColor] = useState(category.color);
  const defaultNames = useNotesCategoriesNames();
  const { notes } = useNotesSelectors();

  useEffect(() => {
    if (categoryColor === category.color) return;
    onEditNoteCategory({ ...category, color: categoryColor });
  }, [color]); //eslint-disable-line

  const filteredNotes = notes.filter((item) => {
    const containsSearchText = JSON.parse(item.prim).blocks.some((block: any) => block.text.includes(search));

    return (
      item.id_dep === category.id &&
      (!color.selectedValue || item.color === color.selectedValue) &&
      (!emo.selectedValue || item.emo === emo.selectedValue) &&
      (!tags.selectedValue || tags.selectedValue?.some((tag) => item.tags?.includes(tag))) &&
      (!search || containsSearchText)
    );
  });

  return (
    <div
      className={classNames(styles.category, styles[`size_${category.size}`])}
      style={{
        background: category.color && convertToRGBA(category.color, 50),
        boxShadow: isOverCurrent ? `0px 0px 15px 5px ${convertToRGBA(category.color, 50)}` : undefined
      }}
      key={category.id}
      ref={drop}
      onClick={(e) => {
        e.stopPropagation();
        onSetNoteFullCategoryModal({ open: true, category });
      }}
    >
      <div className={styles.header}>
        <h6>
          {category.name || defaultNames[category.default_name]}{" "}
          {isEdit && (
            <Button
              isSquare
              variant={ButtonVariantType.OPACITY}
              size={ButtonSizeType.EXTRA_SMALL}
              iconL={<EditIcon />}
              onClick={(e) => {
                e.stopPropagation();
                onSetNotesCategoriesModal({ open: true, category });
              }}
            />
          )}
        </h6>
        <Button
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.SMALL}
          text={__("Создать")}
          onClick={(e) => {
            e.stopPropagation();
            onSetActiveNote(null);
            onSetNoteEditModal({ open: true, category_id: category.id });
          }}
        />
      </div>
      <div className={styles.notesList}>
        {filteredNotes.length > 0 ? (
          filteredNotes.map((item) => <NoteItem key={item.id} item={item} />)
        ) : (
          <div className={styles.empty}>
            <Clue text={__("Тут пока пусто")} icon="folder" />
          </div>
        )}
      </div>
      {isEdit && (
        <>
          <div className={styles.bottomBar}>
            <div className={styles.colorList}>
              {colors.slice(0, 8).map((el, i) => (
                <div
                  key={i}
                  className={classNames(styles.element, { [styles.active]: el === categoryColor })}
                  onClick={() => setCategoryColor((prev: string) => (prev === el ? "#cfd7d54d" : el))}
                >
                  <div className={styles.color} style={{ background: el }} />
                </div>
              ))}
            </div>
            <div className={styles.sizes}>
              {__("Размер")}
              <div className={styles.sizeButtons}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditNoteCategory({ ...category, size: INoteSize.SMALL });
                  }}
                >
                  <span className={category.size === INoteSize.SMALL && styles.active} />
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditNoteCategory({ ...category, size: INoteSize.MEDIUM });
                  }}
                >
                  <span className={category.size === INoteSize.MEDIUM && styles.active} />
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditNoteCategory({ ...category, size: INoteSize.LARGE });
                  }}
                >
                  <span className={category.size === INoteSize.LARGE && styles.active} />
                </div>
              </div>
            </div>
          </div>
          <Button
            isSquare
            className={styles.deleteButton}
            variant={ButtonVariantType.DARK_GREEN}
            size={ButtonSizeType.MEDIUM}
            iconL={<TrashIcon fill="#fff" />}
            onClick={(e) => {
              e.stopPropagation();
              onDeleteNoteCategory(category);
            }}
          />
        </>
      )}
    </div>
  );
};

export default NotesItemCategory;
