import ServicesItem from "containers/Profile/Services/ServicesItem/ServicesItem";
import { useLocales } from "react-localized";

import styles from "./Services.module.sass";

const Services = () => {
  const { __ } = useLocales();

  const data = [
    {
      title: __("Бизнес+"),
      isPaid: true,
      paidUntil: 1699422673
    },

    {
      title: __("Бизнес+"),
      isPaid: true,
      paidUntil: 1698422673
    },
    {
      title: __("Бизнес+"),
      isPaid: false,
      paidUntil: 1529764148
    },
    {
      title: __("500 Гб"),
      isPaid: true,
      paidUntil: 1699422673
    },

    {
      title: __("+10 сотрудников"),
      isPaid: true,
      paidUntil: 1698422673
    },
    {
      title: __("Бизнес+"),
      isPaid: false,
      paidUntil: 1529764148
    }
  ];

  return (
    <div className={styles.container}>
      {data.map((item, index) => (
        <ServicesItem key={index} data={item} />
      ))}
    </div>
  );
};

export default Services;
