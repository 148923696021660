export enum ANALITIC_INFO {
  CATEGORIES = "CATEGORIES",
  PROGRESS = "PROGRESS",
  EXTEND = "EXTEND",
  AVERANGE_DONE = "AVERANGE_DONE",
  TASKS = "TASKS"
}

export interface IHelperContentProps {
  block: ANALITIC_INFO;
}

export interface IAnaliticContent {
  title: string;
  list: { listItem: string; subList?: string[] }[];
}
