import { useActions } from "hooks/useActions";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { initialCopyLinkModalState } from "models/store/Cabinet/modals/modals";
import React, { useEffect, useRef } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./CopyLinkModal.module.sass";

export const CopyLinkModal = (): JSX.Element => {
  const { __ } = useLocales();
  const { onSetTopMessageModal, onSetCopyLinkModal } = useActions();
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    copyLinkModal: { params }
  } = useGlobalModalsSelectors();

  useEffect(() => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(params);
    } else {
      if (inputRef.current !== null) {
        inputRef.current.focus();
        inputRef.current.select();
        document.execCommand("copy");
      }
    }

    onSetTopMessageModal({
      open: true,
      type: ITopMessageTypes.SUCCESS,
      message: __("Ссылка скопирована")
    });
    onSetCopyLinkModal(initialCopyLinkModalState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return <input ref={inputRef} className={styles.hidden} value={params} readOnly />;
};
