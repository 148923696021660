import classNames from "classnames";
import React from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { ProjectTypes } from "Store/types/projectTypes";

import Role from "../Role/Role";
import styles from "./Roles.module.sass";

const Roles = () => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const project = useSelector((s) => s.Projects.project);
  const selectRole = useSelector((s) => s.Projects.selectRole);
  const selectUser = useSelector((s) => s.Projects.selectUser);

  const theme = useSelector((s) => s.user.userInfo.theme);

  const onSelectRole = (id) => {
    const payload = selectRole === id ? "0" : id;
    dispatch({ type: ProjectTypes.SELECT_ROLE, payload });
  };

  return (
    <div className={classNames(styles.roles, `scrollbar-thin-${theme}`)}>
      {project?.roles && (
        <>
          <div
            className={classNames(styles.allRoles, { [styles.active]: selectRole === "0" && selectUser === "0" })}
            onClick={() => onSelectRole("0")}
          >
            {__("Все")}
          </div>
          {project.roles.map((role) => (
            <Role key={role.id} role={role} context={project.is_read === "1"} onSelectRole={onSelectRole} isMain />
          ))}
        </>
      )}
    </div>
  );
};

export default Roles;
