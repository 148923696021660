import { EventAPI } from "api/EventAddAPI";
import { EVENT_TYPE, TYPE_ELEMENTS } from "generalComponents/variables/global";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useCallback, useLayoutEffect } from "react";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

const FilePrint = (): JSX.Element => {
  const {
    filePrint: { url, fid }
  } = useGlobalModalsSelectors();

  const { uid, userInfo } = useUserSelectors();

  const printFile = useCallback((): void => {
    // @ts-ignore
    const iframe = document.frames ? document.frames["printfile"] : document.getElementById("printfile");
    const iframeWindow = iframe.contentWindow || iframe;
    setTimeout(() => {
      iframe.focus(iframe);
      iframeWindow.print();

      // closeModal();
    }, 1000);
    return;
  }, []);

  const onSubmit = async () => {
    const params = {
      uid,
      id_user_event: userInfo.id,
      event_type: EVENT_TYPE.PRINTED,
      event_group: TYPE_ELEMENTS.FILES,
      event_object: fid
    };

    await EventAPI.add(params);
  };

  useLayoutEffect(() => {
    printFile();
    onSubmit();
  }, [url, printFile]); // eslint-disable-line

  return (
    <>
      <iframe src={`${projectSrc}${url}`} style={{ display: "none" }} title="print" id="printfile" />
    </>
  );
};

export default FilePrint;
