import { ReactComponent as ArchiveIcon } from "assets/PrivateCabinet/sideMenu/archive.svg";
import { ReactComponent as CalendarIcon } from "assets/PrivateCabinet/sideMenu/calendar.svg";
import { ReactComponent as ChatIcon } from "assets/PrivateCabinet/sideMenu/chat.svg";
import { ReactComponent as FilesIcon } from "assets/PrivateCabinet/sideMenu/files.svg";
import { ReactComponent as FoldersIcon } from "assets/PrivateCabinet/sideMenu/folders.svg";
// import { ReactComponent as JournalIcon } from "assets/PrivateCabinet/sideMenu/journal.svg";
import { ReactComponent as LibraryIcon } from "assets/PrivateCabinet/sideMenu/library.svg";
import { ReactComponent as MailIcon } from "assets/PrivateCabinet/sideMenu/mail.svg";
import { ReactComponent as ProgramsIcon } from "assets/PrivateCabinet/sideMenu/programs.svg";
import { ReactComponent as ProjectIcon } from "assets/PrivateCabinet/sideMenu/project.svg";
import { ReactComponent as SafeIcon } from "assets/PrivateCabinet/sideMenu/safe.svg";
import { ReactComponent as SupportIcon } from "assets/PrivateCabinet/sideMenu/support.svg";
import { ReactComponent as TasksIcon } from "assets/PrivateCabinet/sideMenu/tasks.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/sideMenu/trash.svg";
import { imageSrc } from "generalComponents/variables/globalVariables";
import { ROUTES, ROUTES_SEARCH_PARAMS } from "generalComponents/variables/routing";
import { EMPLOYEE_RIGHTS } from "models/store/user/user";
import { useLocales } from "react-localized";

export interface ISideMenuData {
  name: string;
  path: ROUTES;
  icon: JSX.Element;
  access: EMPLOYEE_RIGHTS;
}

export const useMenu = () => {
  const { __ } = useLocales();
  const menu = [
    {
      name: __("Папки"),
      path: `${ROUTES.FOLDERS}${ROUTES_SEARCH_PARAMS.byDateCreated}`,
      icon: <FoldersIcon />,
      access: EMPLOYEE_RIGHTS.IS_FILES
    },
    {
      name: __("Файлы"),
      path: `${ROUTES.FILES}${ROUTES_SEARCH_PARAMS.byDateCreated}`,
      icon: <FilesIcon />,
      access: EMPLOYEE_RIGHTS.IS_FILES
    },
    {
      name: __("Сейф"),
      path: `${ROUTES.SAFE}${ROUTES_SEARCH_PARAMS.byDateCreated}`,
      icon: <SafeIcon />,
      access: EMPLOYEE_RIGHTS.IS_SAFE
    },
    {
      name: __("Задачи"),
      path: ROUTES.TASKS,
      icon: <TasksIcon />
    },
    {
      name: __("Совместный проект"),
      path: ROUTES.PROJECT,
      icon: <ProjectIcon />,
      access: EMPLOYEE_RIGHTS.IS_PROJECT
    },
    {
      name: __("Календарь"),
      path: ROUTES.CALENDAR,
      icon: <CalendarIcon />
    },
    {
      name: __("Чат"),
      path: ROUTES.CHAT,
      icon: <ChatIcon />,
      access: EMPLOYEE_RIGHTS.IS_CHAT
    },
    {
      name: __("Почта"),
      path: ROUTES.MAIL,
      icon: <MailIcon />,
      access: EMPLOYEE_RIGHTS.IS_MAIL
    },
    {
      name: __("Программы"),
      path: ROUTES.PROGRAMS,
      icon: <ProgramsIcon />
    },
    {
      name: __("Архив"),
      path: `${ROUTES.ARCHIVE}${ROUTES_SEARCH_PARAMS.byDateCreated}`,
      icon: <ArchiveIcon />,
      access: EMPLOYEE_RIGHTS.IS_ARCHIVE
    },
    {
      name: __("Библиотека"),
      path: ROUTES.LIBRARY,
      icon: <LibraryIcon />,
      access: EMPLOYEE_RIGHTS.IS_LIBRARY
    },
    // {
    //   name: __("Журнал"),
    //   path: ROUTES.JOURNAL,
    //   icon: <JournalIcon />,
    //   access: EMPLOYEE_RIGHTS.IS_JOURNAL
    // },
    {
      name: __("Корзина"),
      path: `${ROUTES.TRASH}${ROUTES_SEARCH_PARAMS.byDateCreated}`,
      icon: <TrashIcon />
    },
    {
      name: __("Поддержка"),
      path: ROUTES.FAQ,
      icon: <SupportIcon />
    }
  ];

  return {
    menu
  };
};

export const useBusinessMenu = () => {
  const { __ } = useLocales();

  return {
    businessMenu: [
      {
        name: __("Компания"),
        path: "/company",
        src: `${imageSrc}assets/BusinessCabinet/menu-icons/company.svg`
      },
      {
        name: __("Мой отдел"),
        path: "/department",
        src: `${imageSrc}assets/BusinessCabinet/menu-icons/my-department.svg`
      },
      {
        name: __("Совместный проект"),
        path: "/project",
        src: `${imageSrc}assets/BusinessCabinet/menu-icons/joined-project.svg`
      },
      {
        name: __("Мои папки"),
        path: "/folders",
        src: `${imageSrc}assets/PrivateCabinet/folder.svg`
      },
      {
        name: __("Мои файлы"),
        path: "/files",
        src: `${imageSrc}assets/PrivateCabinet/file.svg`
      },
      {
        name: __("Мои задачи"),
        path: "/devices",
        src: `${imageSrc}assets/BusinessCabinet/menu-icons/tasks.svg`
      },
      {
        name: __("Календарь"),
        path: "/calendar",
        src: `${imageSrc}assets/BusinessCabinet/menu-icons/calendar.svg`
      },
      {
        name: __("Чат"),
        path: "/chat-page",
        src: `${imageSrc}assets/PrivateCabinet/sms.svg`
      },
      {
        name: __("Программы"),
        path: "/programs",
        src: `${imageSrc}assets/PrivateCabinet/programs.svg`
      },
      {
        name: __("Сейф"),
        path: "/safe",
        src: `${imageSrc}assets/PrivateCabinet/safe.svg`
      },
      {
        name: __("Расшаренные файлы"),
        path: "/shared-files",
        src: `${imageSrc}assets/PrivateCabinet/sharedFiles.svg`
      },
      {
        name: __("Загруженные файлы"),
        path: "/downloaded-files",
        src: `${imageSrc}assets/PrivateCabinet/loadedFiles.svg`
      },
      {
        name: __("Архив"),
        path: "/archive",
        src: `${imageSrc}assets/PrivateCabinet/archive.svg`
      },
      {
        name: __("Библиотека"),
        path: "/library",
        src: `${imageSrc}assets/PrivateCabinet/book-1.svg`
      },
      {
        name: __("Журнал"),
        path: "/journal",
        src: `${imageSrc}assets/PrivateCabinet/clock.svg`
      },
      {
        name: __("Корзина"),
        path: "/cart",
        src: `${imageSrc}assets/PrivateCabinet/trash.svg`
      }
    ]
  };
};
