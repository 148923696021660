import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow-2.svg";
import classNames from "classnames";
import { endOfDay, getTime, startOfDay } from "date-fns";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { IWeekDayProps } from "models/calendarPage/weekDay";
import { IMyTask } from "models/store/tasks/tasks";
import React, { useEffect, useRef, useState } from "react";
import { taskOfPeriod } from "utils/tasks";

import TasksPopover from "../TasksPopover/TasksPopover";
import styles from "./WeekDay.module.sass";

const WeekDay: React.FC<IWeekDayProps> = ({ day, timeRows }): JSX.Element => {
  const [isShow, setIsShow] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref) {
      isShow ? (ref.current.scrollLeft = 60) : (ref.current.scrollLeft = -60);
    }
  }, [isShow]);

  const periodStart: number = getTime(startOfDay(new Date(day)));
  const periodEnd: number = getTime(endOfDay(new Date(day)));
  const tasksDay: IMyTask[] = taskOfPeriod(timeRows, periodStart, periodEnd);

  const tasks: IMyTask[] = tasksDay.filter(
    (t) => t.id_type === TASK_TYPES.TASK && periodStart < new Date(t.date_start).getTime()
  );
  const meetings: IMyTask[] = tasksDay.filter(
    (t) => t.id_type === TASK_TYPES.ONLINE_MEETING || t.id_type === TASK_TYPES.OFFLINE_MEETING
  );
  const calls: IMyTask[] = tasksDay.filter((t) => t.id_type === TASK_TYPES.CALLS);
  const mails: IMyTask[] = tasksDay.filter((t) => t.id_type === TASK_TYPES.MAILS);
  const reminder: IMyTask[] = tasksDay.filter((t) => t.id_type === TASK_TYPES.REMINDER);

  const isAllTasks: boolean =
    tasks.length > 0 && meetings.length > 0 && calls.length > 0 && mails.length > 0 && reminder.length > 0;

  const isToday: boolean = getTime(startOfDay(new Date(day))) === getTime(startOfDay(new Date()));

  return (
    <div className={classNames(styles.day, { [styles.today]: isToday, [styles.scroll]: isShow })} ref={ref}>
      {tasks.length > 0 && <TasksPopover type={TASK_TYPES.TASK} tasks={tasks} />}
      {meetings.length > 0 && <TasksPopover type={TASK_TYPES.MEETINGS} tasks={meetings} />}
      {calls.length > 0 && <TasksPopover type={TASK_TYPES.CALLS} tasks={calls} />}
      {isAllTasks ? (
        <>
          {!isShow ? (
            <button className={styles.more} onClick={() => setIsShow(true)}>
              +2
            </button>
          ) : (
            <>
              {mails.length > 0 && <TasksPopover type={TASK_TYPES.MAILS} tasks={mails} />}
              {reminder.length > 0 && <TasksPopover type={TASK_TYPES.REMINDER} tasks={reminder} />}
              <button className={styles.more} onClick={() => setIsShow(false)}>
                <ArrowIcon />
              </button>
            </>
          )}
        </>
      ) : (
        <>
          {mails.length > 0 && <TasksPopover type={TASK_TYPES.MAILS} tasks={mails} />}
          {reminder.length > 0 && <TasksPopover type={TASK_TYPES.REMINDER} tasks={reminder} />}
        </>
      )}
    </div>
  );
};

export default WeekDay;
