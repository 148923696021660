import { ReactComponent as CheckedIcon } from "assets/checkboxIcons/checkbox-checked.svg";
import { ReactComponent as NoCheckedIcon } from "assets/checkboxIcons/checkbox-default.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as ArrowDownIcon } from "assets/PrivateCabinet/arrow-down.svg";
import { ReactComponent as CircleIcon } from "assets/PrivateCabinet/circle.svg";
import { ReactComponent as CloseModal } from "assets/PrivateCabinet/closeModal.svg";
import classNames from "classnames";
import { useAccessPeriod, useAccessSelectList } from "collections/myFolders";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import Calendar from "generalComponents/Calendars/Calendar/Calendar";
import { MS_OF_DAY } from "generalComponents/Calendars/CalendarHelper";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import IconTooltip from "generalComponents/IconTooltip/IconTooltip";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import PopUp from "generalComponents/PopUp/PopUp";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useDateFormat } from "hooks/useDateFormat";
import { AccessPeriodTypes, IAccessSelectList } from "models/contextMenuComponents/generalContextMenuComponents/share";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ISharedUserProps } from "models/modals/sharedUser";
import React, { useRef, useState } from "react";
import { useLocales } from "react-localized";

import styles from "./SharedUser.module.sass";

const SharedUser: React.FC<ISharedUserProps> = ({ user, onChangeUserAccess, onChangeUserTime, onRemoveUser }) => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const { accessSelectList } = useAccessSelectList();
  const accessPeriod = useAccessPeriod();

  const [isShowTime, setIsShowTime] = useState<boolean>(false);
  const [isTime, setIsTime] = useState<boolean>(!!user.deadline);
  const [isShowPeriod, setIsShowPeriod] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const [periodDeadline, setPeriodDeadline] = useState<AccessPeriodTypes>(AccessPeriodTypes.EMPTY);

  const timeRef = useRef<HTMLDivElement>(null);

  useOutsideClick(timeRef, () => setIsShowTime(false));

  const onTimeToggle = (): void => {
    if (isTime) {
      onChangeUserTime(user.id, "");
      setPeriodDeadline(AccessPeriodTypes.EMPTY);
      setIsShowPeriod(false);
    } else {
      changePeriod(AccessPeriodTypes.WEEK);
    }
    setIsTime((prev) => !prev);
  };

  const onClickSetDeadlineButton = (): void => setShowCalendar(true);

  const getPeriodDeadline = (value: AccessPeriodTypes): string => {
    switch (value) {
      case AccessPeriodTypes.EMPTY:
        return "-";
      case AccessPeriodTypes.DAY:
        return __("Один день");
      case AccessPeriodTypes.THREE:
        return __("Три дня");
      case AccessPeriodTypes.WEEK:
        return __("Неделя");
      case AccessPeriodTypes.MONTH:
        return __("Месяц");

      default:
        break;
    }
  };

  const changePeriod = (type: AccessPeriodTypes): void => {
    setPeriodDeadline(type);
    setIsShowPeriod(false);
    switch (type) {
      case AccessPeriodTypes.DAY: {
        const time = Date.now() + MS_OF_DAY;
        return onChangeUserTime(user.id, formatDate(time, "full"));
      }
      case AccessPeriodTypes.THREE: {
        const time = Date.now() + MS_OF_DAY * 3;
        return onChangeUserTime(user.id, formatDate(time, "full"));
      }
      case AccessPeriodTypes.WEEK: {
        const time = Date.now() + MS_OF_DAY * 7;
        return onChangeUserTime(user.id, formatDate(time, "full"));
      }
      case AccessPeriodTypes.MONTH: {
        const time = Date.now() + MS_OF_DAY * 30;
        return onChangeUserTime(user.id, formatDate(time, "full"));
      }

      default:
        break;
    }
  };

  const overlayAccess = (): JSX.Element => {
    return (
      <ul>
        {accessSelectList.map((item, i) => (
          <li
            key={i}
            className={styles.aceesItem}
            onClick={() => {
              onChangeUserAccess(user.id, item.type);
              setShowPopover(false);
            }}
          >
            {item.name}
          </li>
        ))}
      </ul>
    );
  };

  const setDateValue = (value: string): void => {
    setPeriodDeadline(AccessPeriodTypes.EMPTY);
    onChangeUserTime(user.id, value);
  };

  const getUserAccess = (): IAccessSelectList => accessSelectList.find((el) => el.type === user.accessType);

  return (
    <div className={styles.container}>
      <div className={styles.imageBox}>
        <Avatar imgSrc={projectSrc + user.icon} name={`${user.name} ${user.sname}`} />
      </div>
      <div className={styles.contactContentWrapper}>
        <div className={styles.name}>{`${user.name} ${user.sname ?? ""}`}</div>
        <div className={styles.email}>{user.email[0]}</div>
      </div>
      <div className={classNames(styles.clockWrap, { [styles.active]: user.deadline || isShowTime })} ref={timeRef}>
        <Button
          type="button"
          variant={ButtonVariantType.OPACITY}
          size={ButtonSizeType.MEDIUM}
          iconL={<ClockIcon />}
          onClick={() => setIsShowTime((prev) => !prev)}
          className={styles.clockBtn}
        />
        {user.deadline && <CircleIcon fill="#ff0000" width={10} height={10} className={styles.marker} />}
        {isShowTime && (
          <div className={styles.timeBox}>
            <div className={styles.toggleBox}>
              <div className={styles.lineFlex}>
                <IconTooltip text={__("После истечения срока доступ к файлу будет прекращен")} place="top" />
                <p className={styles.text}>{__("Таймер доступа")}</p>
              </div>
              <button className={classNames(styles.timeBtn, { [styles.on]: isTime })} onClick={onTimeToggle}>
                <div className={styles.icon}>
                  {isTime ? <CheckedIcon width={24} height={24} /> : <NoCheckedIcon width={24} height={24} />}
                </div>
                <span className={styles.text}>{isTime ? __("Вкл") : __("Выкл")}</span>
              </button>
            </div>

            <div className={styles.lineFlex}>
              <div className={styles.parent}>
                <button
                  className={classNames(styles.time, styles.period, { [styles.active]: isTime })}
                  disabled={!isTime}
                  onClick={() => setIsShowPeriod((prev) => !prev)}
                >
                  {getPeriodDeadline(periodDeadline)}
                  <ArrowDownIcon />
                </button>
                {isShowPeriod && (
                  <ul className={styles.periodList}>
                    {accessPeriod.map((item, i) => (
                      <li key={i} className={styles.periodItem} onClick={() => changePeriod(item.type)}>
                        {item.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div>/</div>
              <div
                onClick={onClickSetDeadlineButton}
                className={classNames(styles.time, styles.date, { [styles.active]: isTime })}
              >
                <span>{user.deadline ? formatDate(user.deadline) : __("Дата")}</span>
                <CalendarIcon />
              </div>
              <div
                onClick={onClickSetDeadlineButton}
                className={classNames(styles.time, styles.hours, { [styles.active]: isTime })}
              >
                <span>{user.deadline ? formatDate(user.deadline, "time") : __("Время")}</span>
                <ClockIcon width={20} height={20} fill="#B6C2BF" />
              </div>
            </div>
          </div>
        )}
      </div>
      <PopoverOverlay
        placement="bottom"
        overlayContent={overlayAccess()}
        classes={{ popoverBody: styles.popoverBody }}
        show={showPopover}
        setShow={setShowPopover}
      >
        <div className={styles.actionButton}>
          <p className={styles.actionButtonText}>{getUserAccess().name}</p>
          <ArrowDownIcon width={7} height={10} className={styles.arrow} />
        </div>
      </PopoverOverlay>
      <Button
        onClick={() => onRemoveUser(user.id)}
        type="button"
        iconL={<CloseModal />}
        variant={ButtonVariantType.OPACITY}
        size={ButtonSizeType.MEDIUM}
        className={styles.clockBtn}
      />
      {showCalendar && (
        <PopUp set={setShowCalendar} zIndex={102}>
          <Calendar
            title={__("Срок хранения файла")}
            subtitle={__("После завершения срока хранения указанными Вами файл будет удален)")}
            setShowCalendar={setShowCalendar}
            setDateValue={setDateValue}
            isStartTime
          />
        </PopUp>
      )}
    </div>
  );
};

export default SharedUser;
