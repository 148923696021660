import { ReactComponent as FireIcon } from "assets/PrivateCabinet/fire.svg";
import classNames from "classnames";
import Comments from "containers/Tasks/Comments/Comments";
import FileTags from "generalComponents/FileTags/FileTags";
import { createMarkup } from "generalComponents/Services/browserServices";
import { TASK_TYPES, URGENCY_TYPES } from "generalComponents/variables/tasks";
import { useDepName } from "hooks/tasksHooks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { IOpenTaskProps } from "models/store/Cabinet/modals/taskModals";
import TaskStatusSelect from "Pages/Cabinet/Components/Tasks/Components/TaskStatusSelect/TaskStatusSelect";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import styles from "./OpenTask.module.sass";

const OpenTask: React.FC<IOpenTaskProps> = ({ params }): JSX.Element => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const { onAddTaskComment } = useActions();
  const departmentName = useDepName();
  const { myTasks } = useTasksSelectors();
  const task = myTasks.find((task) => task.id === params.id);
  const [comment, setComment] = useState("");

  const handleSubmit = (isClose: boolean): void => {
    if (comment.length === 0) return;
    const payload = {
      id_task: params.id,
      text: comment
    };
    onAddTaskComment(payload, isClose);
    setComment("");
  };

  const renderComments = (): JSX.Element[] => {
    return task.comments.map((item) => (
      <div
        key={item.id}
        className={classNames("htmlContent", styles.comment)}
        dangerouslySetInnerHTML={createMarkup(item.text)}
      />
    ));
  };
  const onCloceStatuse = (): void => document.body.click();

  return (
    <div className={styles.taskWrap} onClick={onCloceStatuse}>
      <h2 className={styles.taskName}>
        {task.id_type !== TASK_TYPES.TASK && <>{formatDate(task.date_start, "time")} &nbsp;</>}
        {task.id_act === URGENCY_TYPES.URGENT && (
          <>
            <FireIcon />
            &nbsp;
          </>
        )}
        {task.name}
      </h2>
      {task.emails && (
        <p className={styles.email}>
          {__("Получатель:")} <span>{task.emails}</span>
        </p>
      )}
      <div className={styles.attributes}>
        <div className={styles.depName}>{departmentName(params.id_dep)}</div>
        {task.tags.length > 0 && <FileTags item={task} />}
        {task.color && <span className={styles.color} style={{ background: task.color }} />}
        {task.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: task.emo }} />}
        <TaskStatusSelect task={task} isClosePopup={false} />
      </div>
      <div className={styles.body}>
        {task.prim && (
          <>
            <h3 className={styles.subtitle}>
              {task.id_type === TASK_TYPES.MAILS ? __("Текст письма:") : __("Описание задачи:")}
            </h3>
            <div className={classNames("htmlContent", styles.text)} dangerouslySetInnerHTML={createMarkup(task.prim)} />
          </>
        )}
        {task.comments?.length > 0 && (
          <>
            <h3 className={styles.subtitle}>{__("Комментарии:")}</h3>
            <ul className={styles.list}>{renderComments()}</ul>
          </>
        )}
      </div>
      <Comments isAddFile={false} handleSubmit={handleSubmit} setComment={setComment} comment={comment} />
    </div>
  );
};

export default OpenTask;
