import Button from "generalComponents/Button/Button";
import CropImage from "generalComponents/CropImage/CropImage";
import { MODALS } from "generalComponents/variables/global";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { projectProps } from "types/Project";

import styles from "./AvatarCrop.module.sass";

const AvatarCrop = ({ onClose, params, callback }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const canvasRef = useRef();
  const onUploadCrop = async () => {
    const image64 = canvasRef.current.toDataURL("image/jpeg");
    dispatch(onSetModals(MODALS.PROJECT, { type: callback, params: { ...params, project_icon: image64 } }));
  };
  return (
    <div className={styles.wrap}>
      {params.project_icon && <CropImage canvasRef={canvasRef} imgSrc={params.project_icon} />}
      <canvas ref={canvasRef} style={{ width: "0px" }} />

      <div className={styles.btns}>
        <Button type="button" variant="cancel" onClick={onClose} text={__("Отмена")} />
        <Button type="button" variant="ok" onClick={onUploadCrop} text={__("Сохранить")} />
      </div>
    </div>
  );
};

export default AvatarCrop;

AvatarCrop.propTypes = {
  onClose: PropTypes.func,
  params: projectProps,
  callback: PropTypes.string
};
