import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IPostbox } from "models/store/postbox/postbox";
import React from "react";
import { useLocales } from "react-localized";

import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./CheckPassPostbox.module.sass";

interface ICheckPassPostboxProps {
  closeModal: () => void;
  postbox: IPostbox;
}

const CheckPassPostbox: React.FC<ICheckPassPostboxProps> = ({ closeModal, postbox }) => {
  const { __ } = useLocales();
  const { onDeletePostbox } = useActions();
  const { value, onChange, error, onError } = useInputValue();

  const onSubmit = (): void => {
    if (value !== postbox.pass) {
      onError(__("Не верный пароль"));
      return;
    }
    const payload = {
      id: postbox.id,
      email: postbox.email,
      pass: postbox.pass
    };
    const messages = {
      success: `${__("Вы успешно удалили аккаунт")} ${postbox.email}`,
      error: __("Что-то пошло не так. Попробуйте еще раз")
    };

    onDeletePostbox(payload, messages);
  };

  return (
    <div className={styles.wrap}>
      <HeaderModal title={__("Доступ Ограничен")} onClose={closeModal} />
      <div className={styles.body}>
        <Input value={value} onChange={onChange} isPass label={__("Пароль")} error={Boolean(error)} errorText={error} />
      </div>
      <div className={styles.btns}>
        <Button
          onClick={closeModal}
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.LARGE}
          text={__("Отменить")}
          type="button"
        />
        <Button
          onClick={onSubmit}
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.LARGE}
          text={__("Открыть доступ ")}
          type="button"
          disabled={!value}
        />
      </div>
    </div>
  );
};

export default CheckPassPostbox;
