import { ReactComponent as CopyIcon } from "assets/PrivateCabinet/copyLink.svg";
import classNames from "classnames";
import { format, startOfDay } from "date-fns";
import { useMonths } from "generalComponents/Calendars/CalendarHelper";
import { useRenderDaysInMonth } from "generalComponents/Calendars/CalendarHelper";
import { useGetStatus } from "generalComponents/Services/projectServices";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { taskTypes } from "types/Project";

import TaskDependency from "../../../Components/TaskDependency/TaskDependency";
import { getChildrenTask, getParentTask } from "../../../helpers";
import styles from "./TasksGant.module.sass";

const W = 22;
const H = 30;
const msOfDay = 86400000;
const today = startOfDay(new Date()).getTime();

const DiagramCalendar = ({ data, showChildren, epics, withoutEpic, filtredTasks }) => {
  const months = useMonths();
  const daysOfMonth = useRenderDaysInMonth();
  const status = useGetStatus();
  const project = useSelector((s) => s.Projects.project);
  const [dependency, setDependency] = useState(null);
  const openDependency = (e, task) => {
    setDependency((prev) => (!prev ? { x: e.clientX, y: e.clientY, task: task } : null));
  };

  const startCalendar = useMemo(
    () => new Date(data.startYear, data.startMonth).getTime(),
    [data.startMonth, data.startYear]
  );

  const monthNum = useMemo(() => {
    const monthOfStart = data.startYear * 12 + data.startMonth;
    const monthOfEnd = data.endYear * 12 + data.endMonth;
    const delta = monthOfEnd - monthOfStart + 4;
    return [...Array(delta)].map((_, i) => i + data.startMonth);
  }, [data.endMonth, data.endYear, data.startMonth, data.startYear]);

  const findMonth = (m, y) => months(y).find((el) => el.id === m);

  const renderMonthName = (n) => {
    const date = new Date(data.startYear, n);
    const month = findMonth(date.getMonth(), date.getFullYear());
    const amountDays = month.days;
    const width = amountDays * W;
    return (
      <div className={styles.monthName} style={{ width }}>
        {month.name} &#8242;{String(date.getFullYear()).slice(2)}
      </div>
    );
  };

  const renderDays = (n, isDays) => {
    const year = new Date(data.startYear, n).getFullYear();
    const month = new Date(data.startYear, n).getMonth();
    const daysArray = daysOfMonth(month, year);
    return daysArray.map((d, i) => {
      const isToday = new Date(year, month, d).getTime() === today;
      return (
        <div
          className={classNames(styles.cellDay, { [styles.date]: isDays, [styles.today]: isToday })}
          key={i}
          style={{ width: W }}
        >
          {isDays && d}
        </div>
      );
    });
  };

  const renderHead = () => {
    return (
      <>
        <div className={styles.flex}>{monthNum.map((el) => renderMonthName(el))}</div>
        <div className={styles.flex}>{monthNum.map((el) => renderDays(el, true))}</div>
      </>
    );
  };

  const getChildren = (id) => {
    return filtredTasks.filter((t) => t.id_epic === id);
  };

  const childrenTasks = (id) => (showChildren.includes(id) ? getChildren(id) : []);

  const getStartExtraEpic = (task) => {
    const times = getChildren(task.id)
      .map((t) => new Date(t.date_start).getTime())
      .filter((el) => !isNaN(el));

    return times.length > 0 ? Math.min.apply(null, times) : 0;
  };
  const getEndExtraEpic = (task) => {
    const times = getChildren(task.id)
      .map((t) => new Date(t.date_long || t.date_end).getTime())
      .filter((el) => !isNaN(el));
    return times.length > 0 ? Math.max.apply(null, times) : 0;
  };

  const taskSprint = (task, isIndent) => {
    const start = task.date_start ? new Date(task.date_start).getTime() : 0;
    const end = task.date_end ? new Date(task.date_end).getTime() : 0;
    const width = Math.round((end - start) / msOfDay) * W;
    const toLeft = ((start - startCalendar) / msOfDay) * W;
    const category = status(task);

    const endExtraTask = task.date_long ? new Date(task.date_long).getTime() : 0;
    const extraDays = Math.round((endExtraTask - end) / msOfDay);
    const extraWidthTask = extraDays * W;
    const toLeftExtra = toLeft + width;

    return (
      <div className={classNames(styles.relative, styles.flex, { [styles.indente]: isIndent })} style={{ height: H }}>
        {monthNum.map((el) => renderDays(el, false))}

        <div
          className={classNames(styles.taskPeriod, { [styles.withExtraPeriod]: task.date_long })}
          style={{
            width: width,
            left: toLeft,
            background: category?.color.dark
          }}
          title={category?.name}
        >
          {category?.name}
          {(getParentTask(project.tasks, task.id_parent) || getChildrenTask(project.tasks, task.id).length > 0) && (
            <CopyIcon className={styles.icon} width={14} height={14} onClick={(e) => openDependency(e, task)} />
          )}
        </div>
        {endExtraTask > 0 && (
          <div
            className={classNames(styles.taskPeriod, styles.extraPeriod)}
            style={{
              width: extraWidthTask,
              left: toLeftExtra
            }}
            title={`+ ${extraDays}`}
          />
        )}
      </div>
    );
  };

  const epicSprint = (task) => {
    const start = task.date_start ? new Date(task.date_start).getTime() : 0;
    const end = task.date_end ? new Date(task.date_end).getTime() : 0;
    const width = Math.round((end - start) / msOfDay) * W;
    const toLeft = width ? ((start - startCalendar) / msOfDay) * W + 1 : 0;

    const startExtraEpic = getStartExtraEpic(task);
    const endExtraEpic = getEndExtraEpic(task);
    const extraWidthEpic = Math.round((endExtraEpic - startExtraEpic) / msOfDay) * W;
    const toLeftExtra = ((startExtraEpic - startCalendar) / msOfDay) * W;
    const sprint = () => {
      if ((start, startExtraEpic)) {
        const startSprint =
          start > startExtraEpic
            ? format(new Date(startExtraEpic || start), "dd.MM.yyyy")
            : format(new Date(start || startExtraEpic), "dd.MM.yyyy");
        const endSprint =
          end > endExtraEpic ? format(new Date(end), "dd.MM.yyyy") : format(new Date(endExtraEpic), "dd.MM.yyyy");
        return `${startSprint}-${endSprint}`;
      }
    };

    return (
      <>
        <div className={classNames(styles.relative, styles.flex)} style={{ height: H }}>
          {monthNum.map((el) => renderDays(el))}
          <>
            <div style={{ position: "absolute", width: "100%" }}>
              <span className={styles.sprint}>{sprint()}</span>
            </div>
            <div
              className={classNames(styles.epicPeriod)}
              style={{
                width: width,
                left: toLeft
              }}
            />

            {extraWidthEpic > 0 && (
              <div
                className={classNames(styles.epicPeriod, styles.extraPeriodEpic)}
                style={{
                  width: extraWidthEpic,
                  left: toLeftExtra
                }}
              />
            )}
          </>
        </div>
        {showChildren.includes(task.id) && getChildren(task.id).map((task) => taskSprint(task))}
      </>
    );
  };

  const renderLines = () => {
    const visibleTasks = [...epics, ...withoutEpic].reduce((acc, key) => {
      acc.push(key, ...childrenTasks(key.id));
      return acc;
    }, []);

    return visibleTasks.map((t) => {
      const parentTask = visibleTasks.find((el) => el.id === t.id_parent);
      if (t.id_parent && parentTask) {
        const findIdxParent = visibleTasks.findIndex((el) => el.id === parentTask.id);
        const startParent = new Date(parentTask.date_start).getTime();
        const endParent = parentTask.date_long
          ? new Date(parentTask.date_long).getTime()
          : new Date(parentTask.date_end).getTime();
        const parentTaskWidth = Math.round((endParent - startParent) / msOfDay) * W;
        const toLeft = ((startParent - startCalendar) / msOfDay) * W;
        const xStart = toLeft + parentTaskWidth;
        const yStart = findIdxParent * H + H / 2 + (!parentTask.id_epic && epics.length > 0 ? 10 : 0);

        const findIdxChildren = visibleTasks.findIndex((el) => el.id === t.id);
        const startChild = new Date(t.date_start).getTime();

        const toLeftChild = ((startChild - startCalendar) / msOfDay) * W;
        const xEnd = toLeftChild;
        const yEnd = findIdxChildren * H + H / 2 + (!t.id_epic && epics.length > 0 ? 10 : 0);
        const colorStroke = status(parentTask).color.dark;
        const path = () => {
          if (yStart < yEnd) {
            if (xStart > xEnd) {
              return `V${yStart + H / 2} H${xEnd - 10} V${yEnd}`;
            }
            if (xStart === xEnd) {
              return `V${yEnd - H / 2} H${xEnd - 10} V${yEnd}`;
            }
            if (xStart < xEnd) {
              return `V${yEnd}`;
            }
          } else {
            if (xStart > xEnd) {
              return `V${yStart - H / 2} H${xEnd - 10} V${yEnd}`;
            }
            if (xStart === xEnd) {
              return `V${yStart - H / 2} H${xEnd - 10} V${yEnd}`;
            }
            if (xStart < xEnd) {
              return `V${yEnd}`;
            }
          }

          return ``;
        };

        return (
          <>
            <defs>
              <marker id={t.id} markerWidth="6" markerHeight="4" refX="0" refY="2" orient="auto" stroke={colorStroke}>
                <polygon points="0 0, 6 2, 0 4" fill={colorStroke} />
              </marker>
            </defs>
            <path
              d={`M${xStart},${yStart} H${xStart + 10} ${path()} H${xEnd - 6} `}
              stroke={colorStroke}
              fill="transparent"
              strokeWidth={1}
              markerEnd={`url(#${t.id})`}
            />
          </>
        );
      }
      return <></>;
    });
  };

  return (
    <div className={classNames(styles.diagram)}>
      <div className={styles.head}>{renderHead()}</div>
      <div className={classNames(styles.relative)}>
        {epics.map((task) => epicSprint(task))}
        {withoutEpic.length > 0 && epics.length > 0 && <div className={styles.indent} />}
        {withoutEpic.map((task, i) => taskSprint(task, i === 0 && epics.length > 0))}
        <svg className={styles.SVGBox} width="100%" height="100%">
          {renderLines()}
        </svg>
      </div>
      {dependency && (
        <TaskDependency task={dependency.task} x={dependency.x} y={dependency.y} onClose={openDependency} />
      )}
    </div>
  );
};

export default DiagramCalendar;

DiagramCalendar.propTypes = {
  data: PropTypes.exact({
    startYear: PropTypes.number,
    startMonth: PropTypes.number,
    endYear: PropTypes.number,
    endMonth: PropTypes.number
  }),
  epics: PropTypes.arrayOf(taskTypes),
  withoutEpic: PropTypes.arrayOf(taskTypes),
  diagramWidth: PropTypes.number,
  showChildren: PropTypes.arrayOf(PropTypes.string),
  filtredTasks: PropTypes.arrayOf(taskTypes)
};
