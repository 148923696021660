import { initialRecentFilesState, IRecentFilesState } from "models/store/recentFiles/recentFiles";
import { RecentFilesActions } from "models/store/recentFiles/recentFilesActions";
import { RecentFilesTypes } from "Store/types/recentFilesTypes";

const INITIAL_STATE = initialRecentFilesState();

export const RecentFilesReducer = (state = INITIAL_STATE, action: RecentFilesActions): IRecentFilesState => {
  switch (action.type) {
    case RecentFilesTypes.SET_RECENT_FILES: {
      return { ...state, files: action.payload };
    }
    case RecentFilesTypes.ADD_RECENT_FILE: {
      const recentFiles = state.files.filter((el) => el.fid !== action.payload.fid);
      return { ...state, files: [action.payload, ...recentFiles] };
    }

    default:
      return state;
  }
};
