import api from "api";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import UserBlock from "Pages/Cabinet/Components/Project/Components/UserBlock/UserBlock";
import { useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";
import { ProjectTypes } from "Store/types/projectTypes";
import { taskTypes } from "types/Tasks";

import styles from "../AddUsers.module.sass";

const AddExecutors = ({ onClose, params }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const MSG = useProjectMsg();
  const uid = useSelector((s) => s.user.uid);
  const theme = useSelector((s) => s.user.userInfo.theme);
  const { project } = useProjectsSelectors();
  const [value, setValue] = useState("");
  const [chosenUsers, setChosenUsers] = useState([]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const usersList = project.users.filter((el) => params.id_executor.indexOf(el.id_user) < 0);
    setContacts(usersList);
  }, [params.id_executor, project.users]);

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  const filtredRoles = useMemo(() => {
    return contacts.filter((el) => el.name.toLowerCase().includes(value.toLowerCase()));
  }, [contacts, value]);

  const isChosen = (item) => {
    return chosenUsers.find((el) => el.id_user === item.id_user) ? true : false;
  };
  const onSelectRole = (item) => {
    if (isChosen(item)) {
      setChosenUsers((prev) => prev.filter((el) => el.id_user !== item.id_user));
    } else {
      setChosenUsers((prev) => [...prev, item]);
    }
  };

  const getExecutorsId = () => {
    return chosenUsers.map((item) => item.id_user);
  };

  const onSubmit = async () => {
    try {
      dispatch(onSetModals(MODALS.LOADER, true));
      const payload = new FormData();
      payload.append("uid", uid);
      payload.append("id_task", params.id);
      payload.append("name", params.name);
      payload.append("id_executor", JSON.stringify([...params.id_executor, ...getExecutorsId()]));
      const { data } = await api.post(`/ajax/task_edit.php`, payload);
      dispatch({ type: ProjectTypes.UPDATE_TASKS, payload: data.project_tasks });
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.OPEN_TASK, params: data.task }));
    } catch {
      dispatch(
        onSetModals(MODALS.ERROR, {
          open: true,
          message: MSG.ERROR
        })
      );
    } finally {
      dispatch(onSetModals(MODALS.LOADER, false));
    }
  };

  const toInviteUser = () => {};

  return (
    <div className={styles.wrap}>
      <span className={styles.subTitle}>{__("Введите имя для посика из списка")}</span>
      <input className={styles.input} value={value} onChange={handleChange} />
      <div className={styles.chosenUsers}>
        {chosenUsers.map((el) => (
          <UserBlock key={el.id_user} user={el} />
        ))}
      </div>
      <span className={styles.subTitle}>{__("Выберите из списка")}</span>
      <div className={classNames(styles.usersList, `scrollbar-medium-${theme}`)}>
        <>
          {filtredRoles.map((el) => (
            <div key={el.id_user} onClick={() => onSelectRole(el)}>
              <UserBlock user={el} isChosen={isChosen(el)} />
            </div>
          ))}
        </>
      </div>
      <div className={styles.btns}>
        <Button type="button" variant="cancel" text={__("Отмена")} onClick={onClose} />
        <Button
          type="button"
          variant="ok"
          text={__("Добавить исполнителя")}
          onClick={onSubmit}
          disabled={chosenUsers.length === 0}
        />
      </div>
      <p className={styles.text}>
        {__("Пригласить нововго пользователя в проект, для отправки приглошение нажмите на кнопку пригласить")}
      </p>
      <div className={styles.btns}>
        <Button type="button" variant="ok" text={__("Пригласить")} onClick={toInviteUser} />
      </div>
    </div>
  );
};

export default AddExecutors;

AddExecutors.propTypes = {
  onClose: PropTypes.func,
  params: taskTypes
};
