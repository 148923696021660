import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import StatusItem from "generalComponents/Modals/Components/JointProject/StatusModal/StatusItem/StatusItem";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialStatusModalState } from "models/store/Cabinet/modals/statusModal";
import { IJointProjectStatus, STATUSES } from "models/store/joinProjects/joinProgects";
import { useEffect, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { useLocales } from "react-localized";
import { useParams } from "react-router";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { generateUniqueId } from "utils/generateUniqueId";

import styles from "./StatusModal.module.sass";

const ReactGridLayout = WidthProvider(RGL);

export interface IExtendedIJointProjectStatus extends IJointProjectStatus {
  w: number;
  h: number;
  i: string;
}

const StatusModal = () => {
  const { __ } = useLocales();
  const { onSetStatusModal, onSetChangeStatusModal, onSetApproveModal } = useActions();
  const { project } = useJoinProjectsSelectors();
  const { id } = useParams();
  const { onEditStatuses } = useActions();

  const [isStatusesChanged, setIsStatusesChanged] = useState(false);

  const [statuses, setStatuses] = useState<IExtendedIJointProjectStatus[]>([]);

  useEffect(() => {
    const hasChanges = statuses.some((status) => {
      const selectedStatus = project.statuses.find((s) => s.id === status.id);
      return (
        !selectedStatus ||
        status.name !== selectedStatus.name ||
        status.color !== selectedStatus.color ||
        status.y !== selectedStatus.y ||
        status.is_visible !== selectedStatus.is_visible
      );
    });
    setIsStatusesChanged(hasChanges);
  }, [statuses]); //eslint-disable-line

  useEffect(() => {
    setStatuses([
      ...project.statuses.map((item) => {
        return {
          ...item,
          i: item.id || generateUniqueId(),
          isResizable: false,
          w: 1,
          h: item.system_name === STATUSES.OVERDUE ? 3 : 2
        };
      })
    ]);
  }, [project]); //eslint-disable-line

  const toggleClose = (): void => {
    if (isStatusesChanged) {
      const params = {
        titleHead: __("Применить изменения?"),
        title: __(`Применить изменения перед выходом?`),
        approveBtn: __("Да, применить"),
        closeBtn: __("Нет, выйти без применения"),
        approveBtnVariant: ButtonVariantType.BLUE,
        callback: (): void => {
          toogleSubmit();
        },
        closeCallback: (): void => {
          onSetStatusModal(initialStatusModalState());
        }
      };
      onSetApproveModal({
        open: true,
        params
      });
    } else {
      onSetStatusModal(initialStatusModalState());
    }
  };

  const onLayoutChange = (layout: RGL.Layout[]) => {
    setStatuses((prev) => {
      return prev.map((item) => {
        const layoutItem = layout.find((lItem: RGL.Layout) => lItem.i === item.i);
        if (layoutItem) {
          return { ...item, x: layoutItem.x, y: layoutItem.y };
        }
        return item;
      });
    });
  };

  const toogleSubmit = () => {
    const params = {
      id,
      statuses
    };
    onEditStatuses(params);
    onSetStatusModal(initialStatusModalState());
  };

  return (
    <PopUp position="top" zIndex={9}>
      <HeaderModal onClose={toggleClose} title={__("Настройки")} />
      <div className={styles.container}>
        <Button
          className={styles.addButton}
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.SMALL}
          text="Добавить статус/колонку"
          onClick={() => onSetChangeStatusModal({ open: true, setStatuses })}
        />
        <div className={classNames(styles.list, "scrollbar-thin-blue")}>
          <ReactGridLayout
            className={styles.content}
            cols={1}
            width={608}
            rowHeight={18}
            onLayoutChange={(layout) => onLayoutChange(layout)}
          >
            {statuses.length > 0 &&
              statuses.map((item) => (
                <div data-grid={item} key={item.i}>
                  <StatusItem item={item} setStatuses={setStatuses} />
                </div>
              ))}
          </ReactGridLayout>
        </div>
        <div className={styles.buttons}>
          <Button
            variant={ButtonVariantType.CANCEL}
            size={ButtonSizeType.SMALL}
            text="Отменить"
            onClick={toggleClose}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text="Применить"
            onClick={toogleSubmit}
            disabled={!isStatusesChanged}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default StatusModal;
