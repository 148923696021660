import classNames from "classnames";
import SignItem from "generalComponents/Elements/SignItem";
import FileTags from "generalComponents/FileTags/FileTags";
import { useGetItemSize } from "generalComponents/Hooks/Files/useGetItemSize";
import { useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { calculateSize } from "generalComponents/Services/helpers";
import { ROUTES, SHARED_PARAMS } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { useDateFormat } from "hooks/useDateFormat";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { IFile, initialPickedItems } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useGetIconSize } from "Pages/Cabinet/Components/WorkElements/Hooks/useGetIconSize";
import { useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

import LinesIcon from "../../LinesIcon/LinesIcon";
import RightSide from "../RightSide/RightSide";
import styles from "./LinesViewItem.module.sass";

const LinesViewItem = ({ item }: { item: IFile | IFolder }): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { safeId } = useParams();
  const [_, setSearchParams] = useSearchParams();
  const { formatDate } = useDateFormat();
  const { isShared } = useIsShared();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const { isMediumSize, isBigSize } = useGetItemSize();
  const { onFileView, onSetPickedItems, onSetCurrentItem, onSendFolderPath } = useActions();
  const { getFolderIconWidth } = useGetIconSize();

  const {
    pickedItems: { items }
  } = useFilesSelectors();

  const setElementType = (): CheckBoxVariantTypes.NO_TYPE | CheckBoxVariantTypes.CHECKED => {
    return items.some((element) =>
      element.is_dir === 1 ? element.id_dir === item.id_dir : (element as IFile).fid === (item as IFile).fid
    )
      ? CheckBoxVariantTypes.CHECKED
      : CheckBoxVariantTypes.NO_TYPE;
  };

  const [checkBoxOption, setCheckBoxOption] = useState<CheckBoxVariantTypes>(setElementType());
  const [_isCheckedOption] = useState<boolean>(checkBoxOption === CheckBoxVariantTypes.CHECKED);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (e.detail === 2) {
      if (item.is_dir === 1) {
        onSetCurrentItem(null);
        onSetPickedItems(initialPickedItems());
        if (isShared && item.my === 0) {
          onSendFolderPath([item as IFolder]);
          setSearchParams({ [SHARED_PARAMS.DIR]: item.id_dir });
          return;
        }
        if (isSafePage) {
          navigate(`/${ROUTES.SAFE}/${safeId}/${item.id_dir}`);
          return;
        }
        //
        navigate(`/${ROUTES.FOLDERS}/${(item as IFolder).id_dir}`, { state: { from: pathname } });
      } else {
        onFileView({ open: true, params: item as IFile });
      }
    }
  };

  const onMouseEnterItem = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.DEFAULT);
  };

  const onMouseLeaveItem = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.NO_TYPE);
  };

  const isSelected = (): boolean => {
    return items.some((element) =>
      element.is_dir === 1 ? element.id_dir === item.id_dir : (element as IFile).fid === (item as IFile).fid
    );
  };

  return (
    <li className={styles.item} onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseLeaveItem}>
      <div
        onClick={handleClick}
        className={classNames(styles.button, {
          [styles.selected]: isSelected(),
          [styles.isMediumSize]: isMediumSize,
          [styles.isBigSize]: isBigSize
        })}
      >
        <div className={styles.leftSide}>
          <div className={styles.itemIconWrapper} style={{ width: getFolderIconWidth() }}>
            <LinesIcon item={item} checkBoxOption={checkBoxOption} setCheckBoxOption={setCheckBoxOption} />
          </div>
          <div className={styles.itemInfoWrapper}>
            <div className={styles.itemName}>{item.name}</div>
            <div className={styles.itemSubInfoWrapper}>
              <span className={styles.itemSize}>
                {(item as IFile).size_now ? calculateSize((item as IFile).size) : calculateSize((item as IFolder).size)}
              </span>
              <span className={styles.itemDateCreate}>
                {formatDate((item as IFile).ctime ? (item as IFile).ctime : (item as IFolder).cdate)}
              </span>
            </div>
          </div>
          <div className={styles.itemDescriptionGroup}>
            {item.tags.length > 0 && <FileTags item={item} />}
            {item.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: item.emo }} />}
            {item.fig && <SignItem sign={item.fig} size={16} />}
          </div>
        </div>
        <RightSide item={item} />
      </div>
    </li>
  );
};

export default LinesViewItem;
