import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import Loader from "generalComponents/Loaders/4HUB";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { IJoinProjectLinkCategory } from "models/store/joinProjects/joinProgects";
import { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./LinksCategoryItem.module.sass";

const LinksCategoryItem = ({ item }: { item: IJoinProjectLinkCategory }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState<string>(item.name);
  const [isLoading, setIsLoading] = useState(false);
  const { onDelJointProjectLinksCategory, onEditJointProjectLinksCategory, onSetApproveModal } = useActions();
  const { __ } = useLocales();
  const toogleEdit = () => {
    setIsEdit((prev) => !prev);
    setName(item.name);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRemove = () => {
    const params = {
      titleHead: __("Удалить"),
      approveBtn: __("Удалить"),
      callback: () =>
        onDelJointProjectLinksCategory({
          item: {
            id_project: item.id_project,
            id_item: item.id
          },
          setIsLoading
        }),
      text: __(`Вы действительно хотите удалить категорию?`)
    };
    onSetApproveModal({ open: true, params });
  };

  const handleConfirm = () => {
    const params = {
      id_project: item.id_project,
      id_item: item.id,
      name
    };
    onEditJointProjectLinksCategory({ item: params, setIsLoading });
    toogleEdit();
  };

  return (
    <div className={classNames(styles.item, isEdit && styles.active)}>
      {isLoading && (
        <Loader
          position="absolute"
          type={LoaderTypes.BOUNCING_DOTS}
          background="rgba(0, 0, 0, 0.35)"
          containerType="bounceDots"
          width={"150px"}
          height={"150px"}
        />
      )}
      {isEdit ? <Input className={styles.input} value={name} onChange={handleInputChange} /> : item.name}
      <div className={styles.buttons}>
        {isEdit ? (
          <>
            <Button
              isSquare
              variant={ButtonVariantType.OPACITY}
              size={ButtonSizeType.SMALL}
              iconL={<CloseIcon />}
              onClick={toogleEdit}
            />
            <Button
              isSquare
              variant={ButtonVariantType.OPACITY}
              size={ButtonSizeType.SMALL}
              iconL={<DoneIcon />}
              onClick={handleConfirm}
            />
          </>
        ) : (
          <>
            <Button
              isSquare
              variant={ButtonVariantType.OPACITY}
              size={ButtonSizeType.SMALL}
              iconL={<EditIcon />}
              onClick={toogleEdit}
            />
            <Button
              isSquare
              variant={ButtonVariantType.OPACITY}
              size={ButtonSizeType.SMALL}
              iconL={<TrashIcon />}
              onClick={handleRemove}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default LinksCategoryItem;
