import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow-down.svg";
import classNames from "classnames";
import { useSystemFoldersDoc } from "collections/joinProject";
import { useSystemFolder } from "generalComponents/collections/folder";
import FolderPath from "generalComponents/FolderPath/FolderPath";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useFolderPath } from "generalComponents/Services/folderServices/folderServices";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { PROJECT_PATH, ROUTES } from "generalComponents/variables/routing";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { FolderInfoClassNameTypes } from "models/generalComponents/folderInfo";
import { FolderPathClassNameTypes } from "models/generalComponents/folderPath";
import { DEFAULT_FOLDERS, DEFAULT_PROJECT_DOC_FOLDERS } from "models/generalComponents/folders";
import { IFolder } from "models/store/folders/foldersStore";
import { FC, useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";

import styles from "./ChooseFolder.module.sass";

interface ISelectFolderProps {
  onSelect?: (el: IFolder) => void;
  folder?: IFolder;
  folders: IFolder[];
  indent?: number;
}

export const ChooseFolder: FC<ISelectFolderProps> = ({ onSelect, folder, folders, indent = 25 }): JSX.Element => {
  const { __ } = useLocales();

  const { id } = useParams();

  const SYSTEM_FOLDERS = useSystemFolder();
  const PROJECT_SYSTEM_DOC_FOLDERS = useSystemFoldersDoc();
  const getFolderPath = useFolderPath();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [folderPath, setFolderPath] = useState<IFolder[]>(folder ? getFolderPath(folder) : []);

  const isProjectDoc = useCurrentPathStarstWith(`${ROUTES.PROJECT}/${id}/${PROJECT_PATH.DOC}`);

  const renderPath = (): string => {
    if (folderPath.length === 0) {
      return NO_VALUE;
    }

    if (isProjectDoc) {
      return folderPath
        .map((el) =>
          el.is_system === "1" ? PROJECT_SYSTEM_DOC_FOLDERS[el.name as DEFAULT_PROJECT_DOC_FOLDERS]?.name : el.name
        )
        .join(" / ");
    }

    return folderPath
      .map((el) => (el.is_system === "1" ? SYSTEM_FOLDERS[el.name as DEFAULT_FOLDERS]?.nameRu : el.name))
      .join(" / ");
  };

  const onSelectFolder = (e: React.MouseEvent<HTMLElement>, folder: IFolder): void => {
    e.stopPropagation();

    setFolderPath(getFolderPath(folder));
    if (onSelect) onSelect(folder);
    setIsOpen(false);
  };

  return (
    <PopoverOverlay
      width="328px"
      placement="bottom"
      overlayContent={
        <FolderPath
          className={FolderPathClassNameTypes.NO_BORDER_RADIUS}
          folderInfoClassName={FolderInfoClassNameTypes.NO_BORDER}
          folders={folders}
          onSelect={onSelectFolder}
          indent={indent}
          folderPath={folderPath}
        />
      }
      show={isOpen}
      setShow={setIsOpen}
    >
      <span className={styles.root}>
        <span className={styles.label}>{__("Местоположения папки")}</span>
        <span
          id="selectFolder"
          className={classNames(styles.inputField, {
            [styles.open]: isOpen,
            [styles.folderIcon]: folder
          })}
        >
          {renderPath() || <span className={styles.placeholder}>{__("Выберите папку")}</span>}
        </span>
        <ArrowIcon
          className={classNames(styles.arrow, {
            [styles.active]: isOpen
          })}
        />
      </span>
    </PopoverOverlay>
  );
};
