import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { useProjectMainAccess } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IJoinProjectLink } from "models/store/joinProjects/joinProgects";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectLinksItem.module.sass";

export const JointProjectLinksItem = ({ item }: { item: IJoinProjectLink }) => {
  const [showSettings, setShowSettings] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { formatDate } = useDateFormat();

  const isMainAccess = useProjectMainAccess();

  const { project } = useJoinProjectsSelectors();

  const { onSetTopMessageModal, onDelJointProjectLink, onSetLinksModal, onSetApproveModal } = useActions();
  const { __ } = useLocales();

  const handleCopyLink = () => {
    onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: __("Скопировано в буфер обмена") });
    navigator.clipboard.writeText(item.link);
  };

  const handleRemove = () => {
    const params = {
      titleHead: __("Удалить"),
      approveBtn: __("Удалить"),
      callback: () => onDelJointProjectLink({ item: { id_project: item.id_project, id_item: item.id }, setIsLoading }),
      text: __(`Вы действительно хотите удалить ссылку?`)
    };
    onSetApproveModal({ open: true, params });
  };

  const handleEdit = () => {
    onSetLinksModal({ open: true, link: item });
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setShowSettings(true)}
      onMouseLeave={() => setShowSettings(false)}
    >
      {isLoading && (
        <Loader
          height={"150px"}
          width={"150px"}
          position="absolute"
          type={LoaderTypes.BOUNCING_DOTS}
          background="rgba(0, 0, 0, 0.35)"
          containerType="bounceDots"
        />
      )}
      <img src={item.icon} alt="icon" />
      {item.emo && <div className={styles.emoji} dangerouslySetInnerHTML={{ __html: item.emo }} />}
      <div className={styles.info}>
        <div className={styles.title}>{item.name}</div>
        <div className={styles.link}>{item.link.replace(/^https?:\/\//, "")}</div>
        <div className={styles.date}>{formatDate(item.date)}</div>
      </div>
      <div className={classNames(styles.settings, showSettings && styles.show)}>
        <Button
          variant={ButtonVariantType.OPACITY}
          size={ButtonSizeType.MEDIUM}
          iconL={<CopyIcon />}
          onClick={handleCopyLink}
        />
        {(isMainAccess || project.accesses.MGR_LINKS) && (
          <>
            <Button
              variant={ButtonVariantType.OPACITY}
              size={ButtonSizeType.MEDIUM}
              iconL={<EditIcon />}
              onClick={handleEdit}
            />
            <Button
              variant={ButtonVariantType.OPACITY}
              size={ButtonSizeType.MEDIUM}
              iconL={<TrashIcon />}
              onClick={handleRemove}
            />
          </>
        )}
      </div>
    </div>
  );
};
