import * as ArchivedFilesActions from "./ArchivedFilesActions";
import * as ChatActions from "./ChatActions";
import * as FavoriteActions from "./FavoriteItemsActions";
import * as FilesActions from "./FilesActions";
import * as FilterCriteriaActions from "./FilterCriteriaActions";
import * as FilterLibraryActions from "./FilterLibraryActions";
import * as FiltersActions from "./FiltersActions";
import * as FilterTypeElementsActions from "./FilterTypeElementsActions";
import * as FoldersActions from "./FoldersActions";
import * as GlobalActions from "./GlobalActions";
import * as JoinProject from "./JoinProjectActions";
import * as JournalActions from "./JournalActions";
import * as LibrartActions from "./LibraryActions";
import * as MemoryActions from "./MemoryActions";
import * as ModalActions from "./ModalActions";
import * as NotesActions from "./NotesActions";
import * as NotificationsActions from "./NotificationsActions";
import * as PaintActions from "./PaintActions";
import * as PostboxActions from "./PostboxActions";
import * as ProgramsActions from "./ProgramsActions";
import * as ProjectActions from "./ProjectActions";
import * as RecentFilesActions from "./RecentFilesActions";
import * as SafeActions from "./SafeActions";
import * as SearchActions from "./SearchActions";
import * as SharedActions from "./SharedActions";
import * as SortByDateActions from "./SortByDateActions";
import * as SortCriteriaActions from "./SortCriteriaActions";
import * as TasksAction from "./TasksActions";
import * as TrashElementsActions from "./TrashActions";
import * as UserActions from "./userAction";
import * as UserFiltersActions from "./UserFiltersActions";
// Add new action-creators to the object - mkortelov
const actions = {
  ...ChatActions,
  ...MemoryActions,
  ...FilesActions,
  ...ArchivedFilesActions,
  ...LibrartActions,
  ...JournalActions,
  ...TrashElementsActions,
  ...FiltersActions,
  ...FilterTypeElementsActions,
  ...FilterLibraryActions,
  ...SortByDateActions,
  ...SortCriteriaActions,
  ...FoldersActions,
  ...NotificationsActions,
  ...ProgramsActions,
  ...ProjectActions,
  ...SearchActions,
  ...UserActions,
  ...ModalActions,
  ...PaintActions,
  ...SafeActions,
  ...TasksAction,
  ...UserFiltersActions,
  ...FilterCriteriaActions,
  ...RecentFilesActions,
  ...PostboxActions,
  ...GlobalActions,
  ...FavoriteActions,
  ...SharedActions,
  ...JoinProject,
  ...NotesActions
};

export default actions;
