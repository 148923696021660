import { ReactComponent as PlayIcon } from "assets/PrivateCabinet/play-grey.svg";
import userImage, { ReactComponent as UserIcon } from "assets/PrivateCabinet/userIcon.svg";
import classNames from "classnames";
import ContextMenuItem from "generalComponents/ContextMenu/ContextMenuItem";
import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";
import { CHAT_MESSAGE_ACTION, CHAT_MODALS } from "generalComponents/variables/chat";
import { MODALS } from "generalComponents/variables/global";
import { IMessage, ITaskMessage } from "models/chat/TaskMessage";
import { IContact, IUserInfo } from "models/store/user/user";
import React, { MouseEvent, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { ProjectTypes } from "Store/types/projectTypes";
import { chatMessageTask } from "types/Chat";

import { ReactComponent as TrashIcon } from "../../../../../../../assets/PrivateCabinet/trash.svg";
import ContextMenu from "../../../../../../../generalComponents/ContextMenu";
import { useWebSocketContext } from "../../../../../../../generalComponents/WebSocketsProvider/WebSocketsProvider";
import styles from "./TaskMessage.module.sass";

function TaskMessage({ message }: ITaskMessage): JSX.Element {
  const { __ } = useLocales();
  // TODO - mkortelov - fix after Store is refactored to TS
  const { projectsList } = useTypeSelector<{ projectsList: { id: string }[] }>((s) => s.Projects);
  const { contacts } = useUserSelectors();
  const { userInfo, uid } = useTypeSelector((s) => s.user);
  const dispatch = useDispatch();
  const [mouseParams, setMouseParams] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  } | null>(null);
  const { socket, sendMessage } = useWebSocketContext();

  const findProjectId = (message: IMessage): string => {
    if (message.task_data) {
      const savedMessage = JSON.parse(message.task_data);
      return savedMessage?.id_project;
    } else {
      return message.id_project;
    }
  };

  //TODO - mkortelov - temp structure - delete after Store types is set
  const selectedProject = useMemo<{
    id: string;
    tasks?: { id: string }[];
    project_icon?: string;
    name?: string;
    tasks_category?: {
      id: string;
      name: string;
      color: { color: string; dark: string };
    }[];
  } | null>(() => {
    const projectId = findProjectId(message);
    if (projectsList) {
      return projectsList.find((it) => it.id === projectId) ?? null;
    }
    return null;
  }, [projectsList, message]);

  //TODO - mkortelov - temp structure - delete after Store types is set
  const selectedTask = useMemo<{
    id: string;
    link?: string;
    name?: string;
    prim?: string;
    date_end?: string;
    date_start?: string;
    author_info?: {
      name: string;
      sname: string;
      icon: string[];
    };
    id_executor?: string[];
    id_category?: string;
    is_backlog?: number;
  } | null>(() => {
    if (selectedProject?.tasks) {
      return selectedProject.tasks.find((it) => it.id === message.id_task) || null;
    }
    return null;
  }, [selectedProject, message.id_task]);

  const selectedCategory = useMemo<{ id: string; name: string; color: { color: string; dark: string } } | null>(() => {
    if (selectedProject?.tasks_category && selectedTask?.id_category) {
      return selectedProject.tasks_category.find((it) => it.id === selectedTask.id_category) ?? null;
    }
    return null;
  }, [selectedTask, selectedProject]);

  // TODO - mkortelov - fix after Store is refactored to TS
  const findUser = (userId: string): IUserInfo | IContact | null => {
    if (userId === userInfo.id) {
      return userInfo;
    }
    if (contacts?.length > 0) {
      return contacts.find((it) => it.id_user === userId) ?? null;
    }
    return null;
  };

  const navigateToTask = () => {
    if (selectedTask?.link) {
      window.location.href = selectedTask.link;
    }
  };

  const editTask = () => {
    dispatch({ type: ProjectTypes.CURRENT_PROJECT, payload: selectedProject });
    dispatch(
      onSetModals(MODALS.CHAT, {
        type: CHAT_MODALS.ADD_TASK,
        params: { id_executor: [userInfo.id], editTask: true, task: selectedTask, project: selectedProject }
      })
    );
  };

  const onChangeStatus = (e: React.MouseEvent<HTMLDivElement>, categoryId: string) => {
    e.preventDefault();
    if (socket) {
      sendMessage(
        JSON.stringify({
          uid,
          id_category: categoryId,
          type: "task_edit",
          action: CHAT_MESSAGE_ACTION.EDIT_TASK,
          id_task: selectedTask?.id,
          name: selectedTask?.name,
          date_start: selectedTask?.date_start,
          date_end: selectedTask?.date_end,
          is_backlog: selectedTask?.is_backlog
        })
      );
    }
    closeContextMenu();
  };

  const closeContextMenu = () => setMouseParams(null);

  const openTasksCategory = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setMouseParams({
      x: e.clientX,
      y: e.clientY,
      width: 240,
      height: 25
    });
  };

  return (
    <>
      {selectedProject && selectedTask ? (
        <div className={classNames(styles.taskMessage)}>
          <div className={styles.projectHeader}>
            <div className={styles.projectTitle}>
              <div className={styles.projectIcon}>
                {selectedProject?.project_icon && <img src={selectedProject?.project_icon} alt="logo" />}
              </div>
              <div className={styles.name}>{selectedProject?.name}</div>
            </div>
            {selectedTask?.date_start && selectedTask?.date_end ? (
              <div
                className={styles.categoryButton}
                onClick={openTasksCategory}
                style={{ backgroundColor: selectedCategory !== null ? selectedCategory.color.dark : "" }}
              >
                <span>{selectedCategory?.name}</span>
                <PlayIcon className={styles.menuTriangle} />
              </div>
            ) : (
              <div className={styles.categoryButton}>
                <span>{__("Бэклог")}</span>
              </div>
            )}
          </div>
          <div className={styles.taskParts}>
            <div className={styles.taskLeftPart}>
              <div className={styles.taskDetails}>
                <h1>{selectedTask?.name}</h1>
                <div>
                  <h2>{__("Описание задачи:")}</h2>
                  <div>{selectedTask?.prim || "-"}</div>
                </div>
              </div>
            </div>
            <div className={styles.taskRightPart}>
              <div className={classNames("text-blue-smooth")}>{__("Исполнитель/и")}:</div>
              <div className={styles.user}>
                {selectedTask?.id_executor?.[0] ? (
                  <img
                    src={findUser(selectedTask.id_executor[0])?.icon[0] ?? userImage}
                    alt="userIcon"
                    className={styles.userIcon}
                  />
                ) : (
                  <UserIcon className={styles.userIcon} />
                )}
                {selectedTask?.id_executor?.[0] ? (
                  <div>
                    {findUser(selectedTask.id_executor[0])?.name} {findUser(selectedTask?.id_executor?.[0])?.sname}
                  </div>
                ) : (
                  <div className={classNames("text-danger")}>{__("Не выбран")}</div>
                )}
              </div>
              <div className={classNames("text-blue-smooth")}>{__("Спринт")}:</div>
              <div className={classNames("text-danger", styles.sprint)}>
                {selectedTask?.date_start && selectedTask?.date_end ? (
                  <div className={styles.dateSprintWrap}>
                    <span className={styles.sprintDate}>{selectedTask.date_start.split("T")[0]}</span> -{" "}
                    <span className={styles.sprintDate}>{selectedTask.date_end.split("T")[0]}</span>
                  </div>
                ) : (
                  __("Не выбран")
                )}
              </div>
              <div className={classNames("text-blue-smooth")}>{__("Автор задачи")}:</div>
              {selectedTask?.author_info ? (
                <div className={styles.user}>
                  <img
                    src={selectedTask?.author_info.icon?.[0] ?? userImage}
                    alt="avatar"
                    className={styles.userIcon}
                  />
                  {selectedTask?.author_info.name} {selectedTask?.author_info.sname}
                </div>
              ) : (
                <div className={styles.user}>
                  <UserIcon className={styles.userIcon} />
                  <div className={classNames("text-danger")}>{__("Не выбран")}</div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttons}>
            <div className={styles.openTaskButton} onClick={navigateToTask}>
              {__("Открыть задачу")}
            </div>
            <div className={styles.editTaskButton} onClick={editTask}>
              {__("Редактировать задачу")}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.deletedTaskWrap}>
          <div className={styles.deletedTask}>
            <div className={styles.trashIcon}>
              <TrashIcon />
            </div>
            <span>{__("данная задача была удалена")}</span>
          </div>
        </div>
      )}
      {mouseParams && (
        <ContextMenu params={mouseParams} setParams={closeContextMenu} tooltip={false}>
          {selectedProject?.tasks_category &&
            selectedProject.tasks_category.map((status) => (
              <div className={styles.itemMenu} key={status.id}>
                <div className={styles.circle} style={{ background: status.color.dark }} />
                <ContextMenuItem
                  width={mouseParams.width}
                  height={mouseParams.height}
                  text={status.name}
                  callback={(e: MouseEvent<HTMLDivElement>) => onChangeStatus(e, status.id)}
                />
              </div>
            ))}
        </ContextMenu>
      )}
    </>
  );
}

export default TaskMessage;

TaskMessage.propTypes = {
  message: chatMessageTask
};
