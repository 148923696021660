import { initialUserState, IUserState } from "models/store/user/user";
import { UserAction } from "models/store/user/userActions";
import { UserTypes } from "Store/types/userTypes";

const INITIAL_STATE: IUserState = initialUserState();

export const UserReducer = (state = INITIAL_STATE, action: UserAction): IUserState => {
  switch (action.type) {
    case UserTypes.LOG_DATA:
      if (action.payload === "") {
        // Если uid пустая строка, (разлогинивание), удаляем «uid» и «rememberUser» из локального хранилища.
        localStorage.removeItem("uid");
        localStorage.removeItem("rememberUser");
        return {
          ...INITIAL_STATE
        };
      }
      return {
        ...state,
        uid: action.payload
      };

    case UserTypes.USER_INFO:
      return {
        ...state,
        userInfo: action.payload
      };

    case UserTypes.UPDATE_SETTINGS:
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload }
      };

    case UserTypes.SET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload
      };

    case UserTypes.SET_USERS_STORAGE:
      return {
        ...state,
        users_storage: action.payload
      };

    case UserTypes.CONTACT_LIST:
      return { ...state, contacts: action.payload.slice().sort((a, b) => a.name?.localeCompare(b.name)) };

    case UserTypes.GET_TAG_LIST:
      return { ...state, tags: action.payload };

    case UserTypes.SET_TAG_LIST:
      return { ...state, tags: action.payload };

    default:
      return state;
  }
};
