import { ReactComponent as ArrowDownIcon } from "assets/PrivateCabinet/arrow-down.svg";
import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check-mark.svg";
import classNames from "classnames";
import { useTaskStatuses } from "collections/tasks";
import { DefaultButtonIcon } from "generalComponents/DefaultButtonIcon/DefaultButtonIcon";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { STATUS_TYPES, TASK_TYPES } from "generalComponents/variables/tasks";
import { useTaskSatus } from "hooks/tasksHooks";
import { useActions } from "hooks/useActions";
import { ITaskStatus } from "models/generalComponents/collections/tasks";
import { ITaskStatusSelectProps } from "models/tasks/myTasks";
import React, { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./TaskStatusSelect.module.sass";

const TaskStatusSelect: React.FC<ITaskStatusSelectProps> = ({ task, isClosePopup = true }) => {
  const { __ } = useLocales();

  const { myTasks } = useTaskStatuses();
  const status = useTaskSatus(TASK_TYPES.TASK);
  const { onEditTask } = useActions();

  const [isOpenStatus, setIsOpenStatus] = useState<boolean>(false);

  const onChangeStatus = (e: React.MouseEvent<HTMLDivElement>, status: STATUS_TYPES): void => {
    e.stopPropagation();
    const params = {
      id_task: task.id,
      id_status: status
    };
    onEditTask(params, EVENT_TYPE.EDIT_STATUS, isClosePopup);
    setIsOpenStatus(false);
  };

  const getStatusName = (el: ITaskStatus): string => {
    switch (task.id_type) {
      case TASK_TYPES.TASK:
      case TASK_TYPES.REMINDER:
        return el.shortNameTask;
      case TASK_TYPES.OFFLINE_MEETING:
      case TASK_TYPES.ONLINE_MEETING:
        return el.shortNameMeeting;
      case TASK_TYPES.CALLS:
        return el.shortNameCall;
      case TASK_TYPES.MAILS:
        return el.shortNameMail;

      default:
        break;
    }
  };

  const getStatusTitle = (): string => {
    switch (task.id_type) {
      case TASK_TYPES.TASK:
      case TASK_TYPES.REMINDER:
        return status(task.id_status)?.shortNameTask;
      case TASK_TYPES.OFFLINE_MEETING:
      case TASK_TYPES.ONLINE_MEETING:
        return status(task.id_status)?.shortNameMeeting;
      case TASK_TYPES.CALLS:
        return status(task.id_status)?.shortNameCall;
      case TASK_TYPES.MAILS:
        return status(task.id_status)?.shortNameMail;

      default:
        break;
    }
  };

  const renderStatusesSelect = (): JSX.Element => {
    const contextStatuses = myTasks.map((el) => ({ ...el, isCurrent: el.status === status(task.id_status)?.status }));
    return (
      <>
        {contextStatuses.map((el, i) => (
          <div key={i} className={styles.statusItem} onClick={(e) => onChangeStatus(e, el.status)}>
            <DefaultButtonIcon>{el.img}</DefaultButtonIcon>
            <span>{getStatusName(el)}</span>
            {el.isCurrent && <CheckIcon className={styles.checkMark} />}
          </div>
        ))}
      </>
    );
  };

  return (
    <PopoverOverlay
      overlayContent={renderStatusesSelect()}
      title={__("Статус")}
      show={isOpenStatus}
      setShow={setIsOpenStatus}
    >
      <span className={classNames(styles.statusBox, { [styles.isOpen]: isOpenStatus })}>
        <span className={classNames(styles.name, styles.noThrough)}>{getStatusTitle()}</span>
        <ArrowDownIcon width="8px" height="6px" className={classNames({ [styles.isOpenArrow]: isOpenStatus })} />
      </span>
    </PopoverOverlay>
  );
};

export default TaskStatusSelect;
