import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import Input from "generalComponents/Input/Input";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./JointProjectSearch.module.sass";

interface IProps {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
}

export const JointProjectSearch = ({ searchQuery, setSearchQuery }: IProps) => {
  const { __ } = useLocales();
  const [showSearch, setShowSearch] = useState(false);

  return (
    <PopoverOverlay
      overlayContent={
        <Input
          value={searchQuery ?? ""}
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
          placeholder={__("Введите")}
          rightIcon={<SearchIcon fill="var(--color-d100)" />}
        />
      }
      placement="bottom"
      classes={{ popoverBody: styles.popup }}
      show={showSearch}
      setShow={setShowSearch}
      variantBtn={ButtonVariantType.EXRTA_LIGHT}
      sizeBtn={ButtonSizeType.SMALL}
      iconLBtn={<SearchIcon fill="#274A42" />}
      btn={"custom"}
      width={360}
    />
  );
};
