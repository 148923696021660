import { ReactComponent as CommentIcon } from "assets/PrivateCabinet/comm.svg";
import { ReactComponent as FileIcon } from "assets/PrivateCabinet/file-4.svg";
import { ReactComponent as FireIcon } from "assets/PrivateCabinet/fire.svg";
import { ReactComponent as NestedIcon } from "assets/PrivateCabinet/nested.svg";
import classNames from "classnames";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { useContextTrashTask } from "generalComponents/collections/projects";
import ContextMenu from "generalComponents/ContextMenu";
import ContextMenuItem from "generalComponents/ContextMenu/ContextMenuItem";
import {
  useChildrenTask,
  useGetStatus,
  useParentTask,
  useProjectMsg
} from "generalComponents/Services/projectServices";
import ThreeDots from "generalComponents/ThreeDots/ThreeDots";
import { PROJECT_CONTEXT_MENU, PROJECT_MODALS } from "generalComponents/variables/project";
import { URGENCY_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { IMouseParams } from "models/generalComponents/contextMenu";
import { ITrashTaskProps } from "models/project/projectTrash";
import React, { useState } from "react";
import { useLocales } from "react-localized";

import TaskDependency from "../../Components/TaskDependency/TaskDependency";
import TaskNum from "../../Components/TaskNum/TaskNum";
import UserInitials from "../../Components/UserInitials/UserInitials";
import styles from "./TrashTask.module.sass";

const TrashTask: React.FC<ITrashTaskProps> = ({ task, isSelect, toggleCurrentTask, chosenTasks }): JSX.Element => {
  const { __ } = useLocales();
  const status = useGetStatus();
  const parentTask = useParentTask();
  const childrenTask = useChildrenTask();
  const TRASH_CONTEXT_MENU = useContextTrashTask();
  const { onRestoreTask, onSetApproveModal, onDeleteProjectTask, onMoveToBacklog, onSetProjectModal } = useActions();
  const MSG = useProjectMsg();
  const { formatDate } = useDateFormat();

  const [mouseParams, setMouseParams] = useState<IMouseParams>(null);
  const [dependency, setDependency] = useState<{ x: number; y: number }>(null);

  const openDependency = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    setDependency((prev) => (!prev ? { x: e.clientX, y: e.clientY } : null));
  };

  const closeContextMenu = (): void => setMouseParams(null);

  const onClickDots = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    setMouseParams({
      x: e.clientX,
      y: e.clientY,
      width: 240,
      height: 25
    });
  };

  const callbacks: Record<string, () => void> = {
    [PROJECT_CONTEXT_MENU.RESTORE_TASK]: () => onRestoreTask([task.id], MSG.ERROR),

    [PROJECT_CONTEXT_MENU.MOVE_TO_BACKLOG]: () => {
      const messages = {
        error: MSG.ERROR,
        success: MSG.MOVE_TO_BACKLOG.text
      };
      onMoveToBacklog([task], messages);
    },

    [PROJECT_CONTEXT_MENU.DEL_TASK]: () => {
      const messages = {
        error: MSG.ERROR,
        success: MSG.DEL_TASK
      };
      onSetApproveModal({
        open: true,
        params: {
          title: __("Удаление задачи"),
          text: __("Вы действительно хотите удалить выбранную задачу?"),
          approveBtn: __("Удалить"),
          callback: () => onDeleteProjectTask([task.id], messages)
        }
      });
    }
  };

  const onTaskClick = (): void => {
    isSelect ? toggleCurrentTask(task) : onSetProjectModal({ type: PROJECT_MODALS.OPEN_TASK, params: task });
  };

  return (
    <div>
      <div
        className={classNames(styles.task, { [styles.active]: chosenTasks.some((el) => el.id === task.id) })}
        onClick={onTaskClick}
      >
        <TaskNum color={status(task)?.color.dark} num={task.num_epic} />
        {task.id_act === URGENCY_TYPES.URGENT && <FireIcon />}
        <span className={styles.taskName}>{task.name}</span>
        {task.id_executor.map((id) => (
          <UserInitials key={id} id={id} />
        ))}
        {task.date_start && task.date_end && (
          <div className={styles.sprint}>
            {formatDate(task.date_start)} - {formatDate(task.date_end)}
          </div>
        )}
        {task.tags && <div className={styles.tag}>#{task.tags}</div>}
        {task.comments.length > 0 && <CommentIcon width={14} height={14} />}
        {(parentTask(task.id_parent) || childrenTask(task.id).length > 0) && (
          <ButtonIcon icon={<NestedIcon />} variant="grey" handleClick={openDependency} />
        )}
        {task.files_col > 0 && (
          <span className={styles.clip}>
            <FileIcon width={11} height={15} />
          </span>
        )}
        <div className={styles.statusBox}>
          <div className={styles.circle} style={{ background: status(task)?.color.dark }} />
          <span className={styles.status}>{status(task).name}</span>
        </div>
        <ThreeDots onClick={onClickDots} />
      </div>
      {mouseParams !== null && (
        <ContextMenu params={mouseParams} setParams={closeContextMenu} tooltip={true}>
          {TRASH_CONTEXT_MENU.map((item, i) => (
            <div key={i}>
              <ContextMenuItem
                width={mouseParams.width}
                height={mouseParams.height}
                text={item.name}
                icon={item.img}
                callback={callbacks[item.type]}
              />
            </div>
          ))}
        </ContextMenu>
      )}
      {/* TODO -mk- fixed task props
      @ts-ignore */}
      {dependency && <TaskDependency task={task} x={dependency.x} y={dependency.y} onClose={openDependency} />}
    </div>
  );
};

export default TrashTask;
