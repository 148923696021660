import api from "../../api";
import { onSetModals } from "../../Store/actions/ModalActions";
import { MODALS, TOP_MESSAGE_TYPE } from "../variables/global";
import { checkResponseStatus } from "./requestServices";

/**
 * @deprecated use hook useDateFormat
 */
export const getFormatDate = (date) => {
  const formatDate = new Date(date).toLocaleString("ru", { year: "numeric", month: "2-digit", day: "2-digit" });
  return formatDate;
};

export const getFormatTime = (date) => {
  if (date) {
    const formatTime = new Date(date).toLocaleString("ru", { hour: "2-digit", minute: "2-digit" });
    return formatTime;
  }
};

export const getMaskDate = (date) => {
  const tempValue = date.replace(/\D/gim, "");
  return tempValue.replace(
    ...({
      2: [/(\d{2})/g, "$1"],
      3: [/(\d{2})/g, "$1."],
      4: [/(\d{2})(\d{0,2})/g, "$1.$2"],
      5: [/(\d{2})(\d{2})/g, "$1.$2."],
      6: [/(\d{2})(\d{2})(\d{0,4})/g, "$1.$2.$3"],
      7: [/(\d{2})(\d{2})(\d{1,4})/g, "$1.$2.$3"],
      8: [/(\d{2})(\d{2})(\d{4})/g, "$1.$2.$3"]
    }[tempValue.length] || [])
  );
};

export const changePreviewTime = async (uid, fid, dispatch) => {
  return api
    .post(`/ajax/history_file_update.php?uid=${uid}&fid=${fid}`)
    .then((response) => {
      checkResponseStatus(response.data.ok);
    })
    .catch((e) => {
      dispatch(
        onSetModals(MODALS.TOP_MESSAGE, {
          open: true,
          type: TOP_MESSAGE_TYPE.ERROR,
          message: "Error change modify time (mtime)"
        })
      );
      console.log(e);
    });
};
