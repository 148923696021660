import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/play-grey.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import Select from "generalComponents/Select/Select";
import { Times } from "generalComponents/Times/Times";
import { ButtonVariantType } from "models/generalComponents/button";
import { ICalendarProps, IDateObject, TYPE_DAY } from "models/generalComponents/calendars";
import React, { useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";

import { NO_VALUE } from "../../variables/globalVariables";
import { areEqual, getToday, useDaysOfWeeks, useGenerateCalendar, useMonths } from "../CalendarHelper";
import styles from "./Calendar.module.sass";

const Calendar: React.FC<ICalendarProps> = ({
  title,
  subtitle,
  setShowCalendar,
  setDateValue,
  selectRange,
  extend,
  isStartTime,
  isEndTime,
  selectYear
}): JSX.Element => {
  const { __ } = useLocales();
  const generateCalendar = useGenerateCalendar();
  const daysOfWeeks = useDaysOfWeeks();
  const months = useMonths();
  const [date, setDate] = useState<IDateObject>(getToday());
  const [dateStart, setDateStart] = useState<IDateObject>(extend ? extend.start : null);
  const [dateEnd, setDateEnd] = useState<IDateObject>(extend ? extend.end : null);
  const [hoverStart, setHoverStart] = useState<IDateObject>(null);
  const [hoverEnd, setHoverEnd] = useState<IDateObject>(null);
  const [daysInMonth, setDaysInMonth] = useState<number[][]>(generateCalendar(6, date));
  const [timeStart, setTimeStart] = useState<string>(NO_VALUE);
  const [timeEnd, setTimeEnd] = useState<string>(NO_VALUE);
  const [typeTimeStart, setTypeTimeStart] = useState<string>("text");
  const [typeTimeEnd, setTypeTimeEnd] = useState<string>("text");

  useEffect(() => setDaysInMonth(generateCalendar(6, date)), [date]); //eslint-disable-line

  const onSetRange = (obj: IDateObject): void => {
    if (!obj.day) return;
    if (dateStart && dateEnd) {
      setDateStart({ ...obj });
      setDateEnd(null);
      return;
    }
    if (dateStart) {
      const timeClicked = new Date(obj.year, obj.month, obj.day);
      const timeStart = new Date(dateStart.year, dateStart.month, dateStart.day);
      setHoverEnd(null);
      setHoverStart(null);
      if (timeClicked > timeStart) {
        return setDateEnd({ ...obj });
      } else {
        setDateEnd({ ...dateStart });
        setDateStart({ ...obj });
      }
    } else {
      return setDateStart({ ...obj });
    }
  };

  const onSetDate = (obj: IDateObject): void => {
    if (selectRange) {
      onSetRange(obj);
    }
    setDate(obj);
  };

  const chooseDate = (day: number): void => onSetDate({ ...date, day });

  const switchMonth = (day: number): void => {
    if (day >= 15) {
      if (date.month === 0) {
        return onSetDate({
          day: day && day <= 31 ? day : 0,
          month: 11,
          year: date.year - 1
        });
      }
      return onSetDate({
        ...date,
        day: day && day <= 31 ? day : 0,
        month: date.month - 1
      });
    } else {
      if (date.month === 11) {
        return onSetDate({
          day: day && day <= 31 ? day : 0,
          month: 0,
          year: date.year + 1
        });
      }
      return onSetDate({
        ...date,
        day: day && day <= 31 ? day : 0,
        month: date.month + 1
      });
    }
  };

  const switchYear = (status: string): void => {
    status === "increase" ? setDate({ ...date, year: date.year + 1 }) : setDate({ ...date, year: date.year - 1 });
  };

  const isSelectRange = (day: number, typeDay: TYPE_DAY): boolean => {
    const month = (): number => {
      switch (typeDay) {
        case TYPE_DAY.PREV:
          return date.month - 1;
        case TYPE_DAY.CURRENT:
          return date.month;
        case TYPE_DAY.NEXT:
          return date.month + 1;

        default:
          break;
      }
    };

    const timeDay = new Date(date.year, month(), day);

    if ((selectRange || extend) && hoverEnd && hoverStart) {
      const timeStartH = new Date(hoverStart.year, hoverStart.month, hoverStart.day);
      const timeEndH = new Date(hoverEnd.year, hoverEnd.month, hoverEnd.day);
      if (timeDay >= timeStartH && timeDay <= timeEndH) {
        return true;
      }
    }

    if ((selectRange || extend) && dateStart && dateEnd) {
      const timeStart = new Date(dateStart.year, dateStart.month, dateStart.day);
      const timeEnd = new Date(dateEnd.year, dateEnd.month, dateEnd.day);

      if (timeDay >= timeStart && timeDay <= timeEnd) {
        return true;
      }
    }
    return false;
  };

  const mouseEnter = (day: number, typeDay: TYPE_DAY): void => {
    if (!selectRange) return;
    if (dateStart && dateEnd) return;
    if (dateStart) {
      const month = (): number => {
        switch (typeDay) {
          case TYPE_DAY.PREV:
            return date.month - 1;
          case TYPE_DAY.CURRENT:
            return date.month;
          case TYPE_DAY.NEXT:
            return date.month + 1;

          default:
            break;
        }
      };
      const hoverDate = new Date(date.year, month(), day);
      const chosenDate = new Date(dateStart.year, dateStart.month, dateStart.day);

      if (hoverDate > chosenDate) {
        setHoverStart(dateStart);
        setHoverEnd({ year: date.year, month: month(), day: day });
      }
      if (hoverDate < chosenDate) {
        setHoverStart({ year: date.year, month: month(), day: day });
        setHoverEnd(dateStart);
      }
    }
  };

  const onClickDay = (day: number, typeDay: TYPE_DAY): void => {
    switch (typeDay) {
      case TYPE_DAY.PREV:
        return switchMonth(32);
      case TYPE_DAY.CURRENT:
        return chooseDate(day);
      case TYPE_DAY.NEXT:
        return switchMonth(0);

      default:
        break;
    }
  };

  const renderWeek = (): JSX.Element => {
    return (
      <div className={styles.week}>
        {daysOfWeeks.short.map((el) => (
          <div key={el} className={styles.day}>
            {el}
          </div>
        ))}
      </div>
    );
  };

  const renderCal = (days: number[], typeDay: TYPE_DAY): JSX.Element[] => {
    return days.map((day) => {
      const eachDate = { ...date, day };
      return (
        <div
          key={day + Math.random()}
          className={classNames(styles.dayBox, styles[typeDay], {
            [styles.today]: areEqual(getToday(), eachDate),
            [styles.chosen]: !selectRange && areEqual(date, eachDate) && typeDay === TYPE_DAY.CURRENT,
            [styles.range]: isSelectRange(day, typeDay)
          })}
          onPointerDown={() => onClickDay(day, typeDay)}
          onMouseEnter={() => mouseEnter(day, typeDay)}
        >
          {day}
        </div>
      );
    });
  };

  const onSubmitDate = (): void => {
    let newDate = (date: IDateObject) => ({
      ...date,
      day: `0${date.day}`.slice(-2),
      month: `0${date.month + 1}`.slice(-2)
    });
    setShowCalendar(false);

    if (selectRange) {
      const newDateStart = newDate(dateStart);
      const newDateEnd = newDate(dateEnd);

      const dates = {
        start: `${newDateStart.year}-${newDateStart.month}-${newDateStart.day} ${timeStart ? timeStart : "00:00"}:00`,
        end: `${newDateEnd.year}-${newDateEnd.month}-${newDateEnd.day} 23:59:59`
      };
      return setDateValue(dates);
    }
    if (isEndTime && timeStart) {
      const newDateObj = newDate(date);
      const { day, month, year } = newDateObj;

      const dates = {
        start: `${year}-${month}-${day} ${timeStart ? timeStart : "00:00"}:00`,
        end: timeEnd ? `${year}-${month}-${day} ${timeEnd}:00` : NO_VALUE
      };
      return setDateValue(dates);
    }
    if (date.day) {
      const newDateObj = newDate(date);
      const { day, month, year } = newDateObj;
      const formattedDate = `${year}-${month}-${day} ${timeStart}:00`;
      setDateValue(formattedDate);
    }
  };

  const getYears = (): { id: string; text: string }[] => {
    const years = [];
    for (let i = new Date().getFullYear(); i >= 1920; i--) {
      years.push({ id: i.toString(), text: i.toString() });
    }
    return years;
  };

  const getDisabled = (): boolean => {
    if (selectRange) {
      return dateStart && dateEnd ? false : true;
    }
    if (dateStart && dateEnd) {
      return (
        new Date(dateEnd.year, dateStart.month - 1, dateEnd.day).getTime() >=
        new Date(date.year, date.month - 1, date.day).getTime()
      );
    }
    if (isStartTime) {
      return timeValidation;
    }
    return false;
  };

  const onChangeTimeStart = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTimeStart(e.target.value);
  };
  const onChangeTimeEnd = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTimeEnd(e.target.value);
  };

  const timeValidation = useMemo(() => {
    if (timeStart) {
      const timeDate = `${date.year}-${date.month + 1}-${date.day} ${timeStart}:00`;
      const chosenTime = new Date(timeDate).getTime();
      return chosenTime < Date.now();
    }
    return true;
  }, [date.day, date.month, date.year, timeStart]);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        {<div className={styles.calendarTitle}>{title || __("Выберите дату")}</div>}
        {subtitle && <div className={styles.calendarSubtitle}>{subtitle}</div>}
        <div className={styles.calendarPicWrapper}>
          {selectYear ? (
            <div className={styles.calendarPic}>
              <Select
                placeholder={__("Год")}
                initValue={date.year.toString()}
                data={getYears()}
                onChange={(value: string) => setDate({ ...date, year: +value })}
                cleareFilter={undefined}
                chatTheme={undefined}
              />
            </div>
          ) : (
            <div className={styles.switchBlock}>
              <button type="button" className={styles.iconDecrease} onClick={() => switchYear(NO_VALUE)}>
                <ArrowIcon />
              </button>
              <span>{date.year}</span>
              <button type="button" className={styles.iconIncrease} onClick={() => switchYear("increase")}>
                <ArrowIcon />
              </button>
            </div>
          )}
          {isStartTime && (
            <div className={styles.timeBlock}>
              <input
                type={typeTimeStart}
                value={timeStart}
                className={styles.inputTime}
                onChange={onChangeTimeStart}
                onFocus={() => setTypeTimeStart("time")}
                onBlur={() => setTypeTimeStart(timeStart ? "time" : "text")}
                placeholder={isEndTime ? __("Начало") : __("Время")}
              />
              {isEndTime && (
                <>
                  <span>-</span>
                  <input
                    type={typeTimeEnd}
                    value={timeEnd}
                    className={styles.inputTime}
                    onChange={onChangeTimeEnd}
                    onFocus={() => setTypeTimeEnd("time")}
                    onBlur={() => setTypeTimeEnd(timeEnd ? "time" : "text")}
                    placeholder={__("Конец")}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <Times handleClick={() => setShowCalendar(false)} />

      <div className={styles.main}>
        <div className={styles.switchBlock}>
          <button type="button" className={styles.iconDecrease} onClick={() => switchMonth(32)}>
            <ArrowIcon />
          </button>
          <div className={styles.month}>{months(date.year)[date.month].name}</div>
          <button type="button" className={styles.iconIncrease} onClick={() => switchMonth(0)}>
            <ArrowIcon />
          </button>
        </div>
        <div className={styles.calendarBox}>
          {renderWeek()}
          <div className={styles.days}>
            {renderCal(daysInMonth[0], TYPE_DAY.PREV)}
            {renderCal(daysInMonth[1], TYPE_DAY.CURRENT)}
            {renderCal(daysInMonth[2], TYPE_DAY.NEXT)}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          type="button"
          variant={ButtonVariantType.OK}
          onClick={onSubmitDate}
          text={extend ? __("Продлить") : __("Готово")}
          disabled={getDisabled()}
        />
      </div>
    </div>
  );
};

export default Calendar;
