import { endOfWeek, startOfWeek } from "date-fns";
import { TaskFilters } from "generalComponents/variables/tasks";
import { useDateFormat } from "hooks/useDateFormat";
import { IMyTask } from "models/store/tasks/tasks";
import React from "react";
import { useLocales } from "react-localized";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import styles from "./TaskDate.module.sass";

export interface ITaskDateProps {
  values: IMyTask[];
  time: string;
  amountWeeks: number;
  idx: number;
}

const TaskDate: React.FC<ITaskDateProps> = ({ amountWeeks, time, values, idx }): JSX.Element => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const { filters } = useTasksSelectors();

  const renderDate = (): string => {
    switch (filters.type) {
      case TaskFilters.BY_DAY:
        return formatDate(new Date(+time));
      case TaskFilters.BY_WEEK: {
        const start = startOfWeek(new Date(Number(time)), { weekStartsOn: 1 });
        const end = endOfWeek(new Date(Number(time)), { weekStartsOn: 1 });
        return `${formatDate(start)} - ${formatDate(end)}`;
      }
      case TaskFilters.BY_MONTH: {
        const isLastWeek = idx === amountWeeks;
        const isFirstWeek = idx === 1;
        const start = isFirstWeek ? new Date(Number(time)) : startOfWeek(new Date(Number(time)), { weekStartsOn: 1 });
        const end = isLastWeek ? new Date(Number(time)) : endOfWeek(new Date(Number(time)), { weekStartsOn: 1 });
        const weekNum = `${idx}${__("неделя").slice(0, 1)}`;
        return `${weekNum} ${formatDate(start)} - ${formatDate(end)}`;
      }
      case TaskFilters.BY_YEAR: {
        return formatDate(new Date(Number(time)), "month");
      }

      default:
        break;
    }
  };

  return (
    <div className={styles.dateBlock}>
      <span>({values.length})</span>
      <span className={styles.date}>
        <span>{renderDate()}</span>
      </span>
    </div>
  );
};

export default TaskDate;
