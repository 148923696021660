import { ReactComponent as CloseModal } from "assets/PrivateCabinet/closeModal.svg";
import { ITimes } from "models/generalComponents/times";
import React from "react";

import styles from "./Times.module.sass";

export const Times: React.FC<ITimes> = ({ handleClick, size = 17, top, right }): JSX.Element => {
  const position: {
    top: string;
    right: string;
  } = {
    top: top ? `${top}px` : null,
    right: right ? `${right}px` : null
  };

  return (
    <button onClick={handleClick} className={styles.times} style={position} type="button">
      <CloseModal width={size} height={size} />
    </button>
  );
};
