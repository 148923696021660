import { LibraryFolderInfo } from "containers/Library/LibraryFolderInfo/LibraryFolderInfo";
import Clue from "generalComponents/Clue/Clue";
import { FoldersLibrary } from "generalComponents/FoldersLibrary/FoldersLibrary";
import Loader from "generalComponents/Loaders/4HUB";
import { Observer } from "generalComponents/Observer/Observer";
import { WorkSpaceWrap } from "generalComponents/WorkSpaceWrap/WorkSpaceWrap";
import { useActions } from "hooks/useActions";
import { useIsFiltersLibrary } from "hooks/useIsFilters";
import { LoaderTypes } from "models/generalComponents/loader";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

interface IProjectLibraryElementsListProps {
  isLinePreview?: boolean;
}

export const ProjectLibraryElementsList: FC<IProjectLibraryElementsListProps> = ({
  isLinePreview = false
}): JSX.Element => {
  const { __ } = useLocales();

  const { onGetProjectLibraryElements, onSetProjectPage } = useActions();

  const { loader, project, page, total } = useJoinProjectsSelectors();

  const { libraryDirId } = useParams();

  const isFiltersLibrary = useIsFiltersLibrary();

  const onLoadMore = (): void => {
    const controller = new AbortController();

    onSetProjectPage(page + 1);

    onGetProjectLibraryElements(controller, libraryDirId ? { is_dir: 0, id_dir: libraryDirId } : null);
  };

  const foldersProjectLibrary = project?.library?.folders;
  const folderInfoProjectLibrary = project?.library?.folderInfo;

  const renderMyFiles = (): JSX.Element => {
    if (foldersProjectLibrary !== null) {
      if (total === 0) {
        if (isFiltersLibrary) {
          return <Clue icon="neutral" text={__("Нет файлов соответствующих параметрам фильтрации")} />;
        }

        return <Clue icon="sad" text={__("Здесь пока пусто")} />;
      }
      return (
        <>
          {libraryDirId && libraryDirId === folderInfoProjectLibrary?.id_dir && (
            <LibraryFolderInfo prim={folderInfoProjectLibrary?.prim} ctime={folderInfoProjectLibrary?.ctime} />
          )}
          <FoldersLibrary folders={foldersProjectLibrary} isLinePreview={isLinePreview} />
        </>
      );
    }
  };

  return (
    <WorkSpaceWrap>
      {loader && page === 1 ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <>
          {renderMyFiles()}
          {<Observer loader={loader} page={page} total={total} callBack={onLoadMore} />}
        </>
      )}
    </WorkSpaceWrap>
  );
};
