import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export const useFilesSelectors = () => {
  const Files = useTypeSelector((s) => s.Files);
  const files = Files.files;
  const filesNext = Files.filesNext;
  const pickedItems = Files.pickedItems;
  const currentItem = Files.currentItem;
  const dragged = Files.dragged;
  const size = Files.size;
  const view = Files.view;
  const total = Files.total;
  const page = Files.page;
  const loader = Files.loader;
  const uploadFiles = Files.uploadFiles;

  return {
    files,
    filesNext,
    pickedItems,
    currentItem,
    dragged,
    size,
    view,
    total,
    page,
    loader,
    uploadFiles
  };
};
