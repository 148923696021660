import classNames from "classnames";

import styles from "./PayVariant.module.sass";

interface TariffPriceProps {
  title: string;
  subtitle: string;
  img: string;
  active: boolean;
  onClick: () => void;
}

const PayVariant = ({ title, subtitle, img, active, onClick }: TariffPriceProps) => {
  return (
    <div className={classNames(styles.container, active && styles.active)} onClick={onClick}>
      <img src={img} alt="icon" />
      <div className={styles.info}>
        <h6>{title}</h6>
        <span>{subtitle}</span>
      </div>
    </div>
  );
};

export default PayVariant;
