import { IElementsItemProps } from "models/myFolders/workElements";
import { FilesNextArrayType, IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { FC } from "react";
import { v4 as uuid } from "uuid";

import styles from "./LinesView.module.sass";
import LinesViewItem from "./LinesViewItem/LinesViewItem";

const LinesView: FC<IElementsItemProps> = ({ items }): JSX.Element => {
  return (
    <ul className={styles.list}>
      {(items as FilesNextArrayType).map((item: IFile | IFolder) => (
        <LinesViewItem key={uuid()} item={item} />
      ))}
    </ul>
  );
};

export default LinesView;
