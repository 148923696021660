export interface ITaskMessage {
  message: IMessage;
}

export interface IMessage {
  attachment: null;
  day: string;
  deadline: string;
  id: string;
  id_user: string;
  id_user_to: string;
  is_del: string;
  is_read: string;
  text: string;
  ut: string;
  id_group: string;
  is_group: boolean;
  id_contact: string;
  originMessage: null;
  type: MessageType;
  task_data?: string;
  id_project: string;
  prim: string;
  id_task: string;
  name: string;
}

export enum MessageType {
  TASK = "task"
}

export interface IProjectTaskForm {
  name: string;
  type: MessageType;
  tag: string;
  prim: string;
  date_start: string;
  date_end: string;
  date_long: string;
  id_act: string;
  id_executor: string;
  id_project: string;
  id_parent: string;
  id_task: string;
  id_status: string;
  sort: string;
  author: string;
  uploadFiles: any[];
  fids: any[];
  is_epic: number;
  id_epic: string;
  tasksChildren: any[];
  is_backlog: number; // -1 - task goes to backlog, 0 - task doesn't go to backlog
  text: string;
}

// export interface ProjectTaskForm {
//   name: string;
//   type: string;
//   tag: string;
//   prim: string;
//   date_start: string;
//   date_end: string;
//   date_long: string;
//   id_act: string;
//   id_executor: string;
//   id_project: string;
//   id_parent: string;
//   id_task: string;
//   id_status: string;
//   sort: string;
//   author: string;
//   uploadFiles: any[];
//   fids: string[];
//   is_epic: number;
//   id_epic: string;
//   tasksChildren: any[];
//   is_backlog: number; // -1 - task goes to backlog, 0 - task doesn't go to backlog
//   text: string;
// }
