export const stringToColor = (str: string) => {
  if (str.length === 0) {
    return { color1: `rgb(2, 120, 189)`, color2: `rgb(137, 207, 240)` };
  }
  // Генерация Hex-кода на основе строки
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let hexColor = ((hash & 0x00ffffff) | 0x1000000).toString(16).slice(1);

  // Преобразование Hex в RGB
  let bigint = parseInt(hexColor, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = 0;

  // Возвращаем строку RGB
  return { color1: `rgb(${r}, ${g}, ${b})`, color2: `rgba(${r}, ${g}, ${b}, 0.7)` };
};
