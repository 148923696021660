import classNames from "classnames";
import { getTime, startOfDay } from "date-fns";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { IWeekDayProps } from "models/calendarPage/weekDay";
import React from "react";

import TasksPopover from "../TasksPopover/TasksPopover";
import styles from "./AllDayTasks.module.sass";

const AllDayTasks: React.FC<IWeekDayProps> = ({ day, timeRows }): JSX.Element => {
  const isToday: boolean = getTime(startOfDay(new Date(day))) === getTime(startOfDay(new Date()));

  return (
    <div className={classNames(styles.day, { [styles.today]: isToday })}>
      {timeRows.length > 0 && <TasksPopover type={TASK_TYPES.TASK} tasks={timeRows} />}
    </div>
  );
};

export default AllDayTasks;
