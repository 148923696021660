import { ReactComponent as CommentIcon } from "assets/PrivateCabinet/comm.svg";
import classNames from "classnames";
import {
  useContextMenuCall,
  useContextMenuMail,
  useContextMenuMeeting,
  useContextMenuReminder,
  useContextMenuTask
} from "collections/tasks";
import FileTags from "generalComponents/FileTags/FileTags";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { createMarkup } from "generalComponents/Services/browserServices";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { useDepName } from "hooks/tasksHooks";
import { useDateFormat } from "hooks/useDateFormat";
import { ITaskPopoverItem } from "models/calendarPage/taskPopoverItem";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./TaskPopoverItem.module.sass";

const TaskPopoverItem: React.FC<ITaskPopoverItem> = ({ task, type, num }): JSX.Element => {
  const { __ } = useLocales();
  const departmentName = useDepName();
  const { formatDate } = useDateFormat();

  const contextTask = useContextMenuTask();
  const contextMeeting = useContextMenuMeeting();
  const contextCall = useContextMenuCall();
  const contextMail = useContextMenuMail();
  const contextReminder = useContextMenuReminder();

  const getContextMenu = (): IPopoverData[] => {
    switch (type) {
      case TASK_TYPES.TASK:
        return contextTask(task);
      case TASK_TYPES.MEETINGS:
        return contextMeeting(task);
      case TASK_TYPES.CALLS:
        return contextCall(task);
      case TASK_TYPES.MAILS:
        return contextMail(task);
      case TASK_TYPES.REMINDER:
        return contextReminder(task);
      default:
        return;
    }
  };

  const renderDate = (): JSX.Element => {
    switch (type) {
      case TASK_TYPES.TASK: {
        const timeStart = new Date(task.date_start).getHours() + new Date(task.date_start).getMinutes();
        return (
          <span>{`${timeStart ? formatDate(task.date_start, "time") + "," : ""} ${formatDate(
            task.date_start
          )} - ${formatDate(task.date_end)}`}</span>
        );
      }
      case TASK_TYPES.MEETINGS:
      case TASK_TYPES.CALLS: {
        return (
          <span>
            {`${formatDate(task.date_start, "time")}`}
            <>{task.date_end && ` - ${formatDate(task.date_end, "time")}`}</>
            <>{` ${formatDate(task.date_start)}`}</>
          </span>
        );
      }
      case TASK_TYPES.MAILS:
      case TASK_TYPES.REMINDER: {
        return <span>{`${formatDate(task.date_start, "full")}`}</span>;
      }

      default:
        break;
    }
  };

  return (
    <div className={styles.box}>
      <div className={styles.top}>
        <div className={styles.num}>{num}</div>
        <div className={styles.date}>{renderDate()}</div>
        {task.color && <div className={styles.color} style={{ background: task.color }} />}
        {task.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: task.emo }} />}

        {task.comments?.length > 0 && (
          <div className={classNames(styles.commentBox)}>
            <CommentIcon />
          </div>
        )}
        <PopoverContextMenu data={getContextMenu()} title={__("Действия / Статус")} />
      </div>
      <div className={styles.medium}>
        {task.tags.length > 0 && <FileTags item={task} />}
        {task.id_dep && (
          <div className={styles.dep}>
            {__("Тип:")}&nbsp;{departmentName(task.id_dep)}
          </div>
        )}
      </div>
      <p className={styles.name}>{task.name}</p>
      <div className={classNames("htmlContent", styles.text)} dangerouslySetInnerHTML={createMarkup(task.prim)} />
    </div>
  );
};

export default TaskPopoverItem;
