import { useEffect } from "react";

const useBeforeUnloadWarning = (isSend: boolean): void => {
  useEffect(() => {
    if (!isSend) return;
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isSend]);
};

export default useBeforeUnloadWarning;
