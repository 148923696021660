import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload_3.svg";
import { useSelectLibraryCategories } from "collections/library";
import Input from "generalComponents/Input/Input";
import { InputUpload } from "generalComponents/InputUpload/InputUpload";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ChangeEvent, FC, MouseEvent } from "react";
import { useLocales } from "react-localized";
import { requiredRule } from "utils/validation";

import { PreviewUploadIcon } from "./components/PreviewUploadIcon/PreviewUploadIcon";
import styles from "./CreateDataRubric.module.sass";
import { FieldsName } from "./lib/createDataRubricReducer";
import { FormCreateRubricLibraryTypes, FormDataCreateaRubricState } from "./lib/createDataRubricReducer";

interface IProps {
  data: FormDataCreateaRubricState;
  dispatchData: Function;
}

export const CreateDataRubric: FC<IProps> = ({ data, dispatchData }) => {
  const { __ } = useLocales();

  const optionsCategories = useSelectLibraryCategories();
  const selectedDefaultCategory = optionsCategories.find(({ value }) => value === data[FieldsName.CATEGORY]?.value);

  const { onSetAvatarCropModal } = useActions();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | MouseEvent<HTMLButtonElement>
  ): void => {
    const { value, name } = e.currentTarget;

    dispatchData({
      type: FormCreateRubricLibraryTypes.CHANGE,
      field: name,
      value,
      error: data[name as FieldsName].required ? requiredRule(value) : false,
      errorMessage: ""
    });
  };

  const uploadIcon = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image")) {
      const imgSrc = URL.createObjectURL(file);

      onSetAvatarCropModal({
        open: true,
        imgSrc,
        callback: (icon) =>
          dispatchData({ type: FormCreateRubricLibraryTypes.CHANGE, field: FieldsName.ICON, value: icon })
      });
    }
  };

  const removeIcon = () =>
    dispatchData({ type: FormCreateRubricLibraryTypes.CHANGE, field: FieldsName.ICON, file: null });

  return (
    <>
      <div className={styles.iconBlock}>
        <PreviewUploadIcon src={data[FieldsName.ICON].value} alt={data[FieldsName.NAME_RUBRIC].value} />
        <InputUpload
          name={FieldsName.ICON}
          textUpload={__("Загрузить")}
          uploadIcon={<UploadIcon />}
          variantBtnUpload={ButtonVariantType.EXRTA_LIGHT}
          sizeBtnUpload={ButtonSizeType.EXTRA_SMALL}
          textTrash={__("Удалить")}
          trashIcon={<TrashIcon width={9} height={13} />}
          variantBtnTrash={ButtonVariantType.EXRTA_LIGHT}
          sizeBtnTrash={ButtonSizeType.EXTRA_SMALL}
          fileUpload={data[FieldsName.ICON].value}
          uploadFile={uploadIcon}
          removeFile={removeIcon}
          accept="image/png, image/gif, image/jpeg, image/jpg"
          visibleTrashBtn
        />
      </div>
      {FieldsName.CATEGORY in data && (
        <SelectCustom
          name={FieldsName.CATEGORY}
          width={320}
          variantHeight="medium"
          options={optionsCategories}
          label={__("Категория")}
          selectedDefault={{
            name: selectedDefaultCategory?.name,
            value: selectedDefaultCategory?.value ?? ""
          }}
          onSelected={handleChange}
          required={data[FieldsName.CATEGORY].required}
        />
      )}
      <Input
        value={data[FieldsName.NAME_RUBRIC].value}
        onChange={handleChange}
        name={FieldsName.NAME_RUBRIC}
        label={__("Название")}
        placeholder={__("Введите")}
        require={data[FieldsName.NAME_RUBRIC].required}
        className={styles.inputName}
      />
    </>
  );
};
