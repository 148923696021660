import Empty from "containers/Dashboard/Empty/Empty";
import TagsItem from "containers/Profile/Tags/TagsItem/TagsItem";
import TagsServicePanel, { ITagTab } from "containers/Profile/Tags/TagsServicePanel/TagsServicePanel";
import { useActions } from "hooks/useActions";
import { ITag } from "models/store/user/user";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./TagTable.module.sass";

const TagTable = () => {
  const { getTagList } = useActions();
  const { tags, userInfo } = useUserSelectors();

  const [search, setSearch] = useState<string>("");
  const [active, setActive] = useState(ITagTab.All);

  useEffect(() => {
    getTagList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterTags = (tag: ITag) => {
    const isNameMatch = tag.name.toLowerCase().includes(search.toLowerCase());
    const isTabMatch =
      active === ITagTab.All ||
      (active === ITagTab.My && tag.id_user === userInfo.id) ||
      (active === ITagTab.Аnother && tag.id_user !== userInfo.id);

    return isNameMatch && isTabMatch;
  };

  const filteredTags = tags?.filter(filterTags);

  const { __ } = useLocales();
  return (
    <>
      <TagsServicePanel search={search} setSearch={setSearch} active={active} setActive={setActive} />
      <div className={styles.wrap}>
        <div className={styles.headerWrap}>
          <div className={styles.header}>
            <div className={styles.name}>{__("Название")}</div>
            <div className={styles.user}>{__("Автор")}</div>
            <div className={styles.settings}>{__("Общий доступ")}</div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <ul className={styles.table}>
            {filteredTags.length > 0 ? (
              filteredTags?.map((tag, i) => <TagsItem key={i} tag={tag} />)
            ) : (
              <div className={styles.empty}>
                <Empty />
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TagTable;
