import { IChatState, initialChatState } from "./chat/chat";
import { ChatActions } from "./chat/chatActions";
import { ModalActions } from "./modals/modalActions";
import { IModalsState, initialModalsState } from "./modals/modals";
import { initialPaintState, IPaint } from "./paint/paint";
import { PaintActions } from "./paint/paintActions";
import { initialSearchState, ISearchState } from "./search/search";
import { SearchActions } from "./search/searchActions";

export interface ICabinet {
  // SEARCH
  search: ISearchState["search"];

  // PAINT
  paint: IPaint;

  // CHAT
  chat: IChatState;

  // GLOBAL MODALS
  modals: IModalsState;
}

export const initialStateCabinet = (): ICabinet => ({
  // SEARCH
  ...initialSearchState(),

  // PAINT
  paint: initialPaintState(),

  // CHAT
  chat: initialChatState(),

  // GLOBAL MODALS
  modals: initialModalsState()
});

export type CabinetActions = ChatActions | ModalActions | SearchActions | PaintActions;
