import BottomMenu from "generalComponents/BottomMenu/BottomMenu";
import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { FilesNextArrayType, initialPickedItems } from "models/store/files/files";
import { useEffect } from "react";
import { useLocales } from "react-localized";
import { useLocation } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

import styles from "./BottomMenuOption.module.sass";
import CancelBtn from "./CancelBtn";
import DeleteBtn from "./DeleteBtn";
import EditBtn from "./EditBtn";
import MoveToArchiveBtn from "./MoveToArchiveBtn";
import ShareBtn from "./ShareBth";
import ZipBtn from "./ZipBtn";

const BottomMenuOption = (): JSX.Element => {
  const { __ } = useLocales();
  const { pathname } = useLocation();
  const { onSetPickedItems, onSetCurrentItem } = useActions();

  const {
    files,
    filesNext,
    pickedItems: { items }
  } = useFilesSelectors();
  const { isLinesPreviewView } = useGetItemView();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);

  const onCancel = (): void => {
    onSetCurrentItem(null);
    onSetPickedItems(initialPickedItems());
  };

  const getAllFiles = (): FilesNextArrayType => {
    if (isLinesPreviewView) {
      const a = Object.values(filesNext).map((el) => {
        if (Array.isArray(el.files)) return el.files;
        return Object.keys(el.files)
          .map((key) => el.files[key as any])
          .flat(1);
      });
      return a.flat();
    }
    if (Array.isArray(files)) return files;
    return Object.keys(files)
      .map((key) => files[key as any])
      .flat(1);
  };

  const onClickCheckboxButton = (): void => {
    if (items.length < getAllFiles().length) {
      onSetPickedItems({
        open: true,
        items: getAllFiles()
      });
    } else {
      onCancel();
    }
  };

  const isAllFilesSelected = (): boolean => {
    if (items?.length === 0) return;
    return items?.length < getAllFiles()?.length;
  };

  const renderCartOption = (): JSX.Element => {
    return <CancelBtn onCancel={onCancel} />;
  };

  const renderOtherPageOption = (): JSX.Element => {
    return (
      <>
        <CancelBtn onCancel={onCancel} />
        <ZipBtn />
        <ShareBtn />
        <MoveToArchiveBtn />
        <EditBtn />
        <DeleteBtn />
      </>
    );
  };

  const renderSafeOptions = (): JSX.Element => {
    return (
      <>
        <CancelBtn onCancel={onCancel} />
        <ZipBtn />
        <MoveToArchiveBtn />
        <EditBtn />
        <DeleteBtn />
      </>
    );
  };

  const renderBottomMenuOption = (): JSX.Element => {
    if (pathname.startsWith("/cart")) {
      return renderCartOption();
    }
    if (isSafePage) {
      return renderSafeOptions();
    }
    return renderOtherPageOption();
  };

  useEffect(() => {
    if (items.length === 0) onCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    (!items || items.length !== 0) && (
      <BottomMenu>
        <div className={styles.wrapper}>
          <div className={styles.leftSide}>
            <div className={styles.checkboxWrapper}>
              <CheckBox
                variant={CheckBoxVariantTypes.CHECKED}
                onClick={onClickCheckboxButton}
                width={24}
                tooltip={isAllFilesSelected() ? __("Выбрать все") : __("Сбросить все")}
              />
            </div>
            <div className={styles.selectedText}>{__("Выбрано")}</div>
            <div className={styles.selectedLengthWrapper}>
              <span className={styles.selectedLength}>{items?.length}</span>
            </div>
          </div>
          <div className={styles.buttonsWrapper}>{renderBottomMenuOption()}</div>
        </div>
      </BottomMenu>
    )
  );
};

export default BottomMenuOption;
