import { IFolder } from "models/store/folders/foldersStore";

export interface IFolderPassModal {
  open: boolean;
  type: "" | "addPassword" | "removePassword" | "restorePassword" | "enterPassword";
  folder: IFolder;
  code?: string;
  callbackOk?: (value: string) => void;
  callbackCancel?: () => void;
}

export function initialFolderPassState(): IFolderPassModal {
  return { open: false, type: "", folder: null, code: "", callbackOk: null, callbackCancel: null };
}
