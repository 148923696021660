import { JoinProjectTrashContent } from "containers/JoinProject/JointProjectTrash/components/JoinProjectTrashContent/JoinProjectTrashContent";
import { JointProjectTrashPanel } from "containers/JoinProject/JointProjectTrash/components/JointProjectTrashPanel/JointProjectTrashPanel";
import { WrapperPage } from "containers/JoinProject/WrapperPage/WrapperPage";
import { useActions } from "hooks/useActions";
import { useDebounce } from "hooks/useDebounce";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";

const ProjectTrashPage = () => {
  const { search, team, participants } = useAllSelectedSearchParams();

  const { onGetProjectTrashFiles, onSetResetProjectTrashElements } = useActions();

  const controller = new AbortController();

  const debouncedSearchQuery = useDebounce(
    (search: string, team: string, participants: string[]) => {
      console.log("debouncedSearchQuery");
      const payload = {
        id_team: team,
        id_executor: participants?.join(","),
        search
      };

      onGetProjectTrashFiles(payload, controller);
    },
    search?.selectedValue?.length > 0 ? 300 : 0
  );

  useEffect(() => {
    onSetResetProjectTrashElements();

    debouncedSearchQuery(search.selectedValue, team.selectedValue, participants.selectedValue);

    return () => {
      onSetResetProjectTrashElements();
      controller.abort();
    };
  }, [search.selectedValue, team.selectedValue, participants.selectedValue]); // eslint-disable-line

  return (
    <WrapperPage>
      <JointProjectTrashPanel />
      <JoinProjectTrashContent
        selectedTeam={team.selectedValue}
        selectedParticipants={participants.selectedValue}
        searchQuery={search.selectedValue}
      />
    </WrapperPage>
  );
};

export default ProjectTrashPage;
