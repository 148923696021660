import { ReactComponent as CloseModal } from "assets/PrivateCabinet/closeModal.svg";
import { FC, ReactNode } from "react";

import styles from "./HeaderModal.module.sass";

interface IHeaderModal {
  title: string;
  onClose?: () => void;
  children?: ReactNode;
}
const HeaderModal: FC<IHeaderModal> = ({ title, children, onClose }) => (
  <div className={styles.wrap}>
    <h2 className={styles.title}>{title}</h2>
    <div className={styles.btns}>
      {children}
      {onClose && (
        <button onClick={onClose} className={styles.btn}>
          <CloseModal fill="#ffffff" width={14} height={14} />
        </button>
      )}
    </div>
  </div>
);

export default HeaderModal;
