import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

import { hexToRgbA } from "../../../../../../generalComponents/Services/mediaServices";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import { IColorPicker } from "../../../../../../models/workElements/minitoolbar";
import { onSetPaint } from "../../../../../../Store/actions/PaintActions";
import { usePaintSelectors } from "../../../../../../Store/selectors/paintSelectors";

function ColorPicker({ colorPickerRef }: IColorPicker) {
  const dispatch = useDispatch();
  const { tool } = usePaintSelectors();

  const setStrokeColorOpacity = (value: string) => {
    dispatch(onSetPaint("color", `rgba(${hexToRgbA(value)},1)`));
    if (tool.name === "pencil") return (tool.strokeStyle = `rgba(${hexToRgbA(value)},1)`);
    if (tool.name === "marker") return (tool.strokeStyle = `rgba(${hexToRgbA(value)},0.2)`);
    return (tool.strokeStyle = value);
  };
  const setColor = (value: string) => {
    setStrokeColorOpacity(value);
  };
  const debounceCallback = useDebounce(setColor, 200);
  const handleChangeColor = (e: React.ChangeEvent<HTMLInputElement>) => debounceCallback(e.target.value);

  return (
    <input
      style={{
        position: "absolute",
        visibility: "hidden",
        left: -12,
        bottom: 0
      }}
      ref={colorPickerRef}
      type="color"
      onChange={handleChangeColor}
    />
  );
}

export default ColorPicker;

ColorPicker.propTypes = {
  colorPickerRef: PropTypes.exact({ current: PropTypes.instanceOf(Element) })
};
