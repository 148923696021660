import JoinProjectTeams from "containers/JoinProject/JoinProjectTeams/JoinProjectTeams";
import SettingsProject from "containers/JoinProject/SettingsProject/SettingsProject";
import SettingsRoles from "containers/JoinProject/SettingsRoles/SettingsRoles";
import Button from "generalComponents/Button/Button";
import Tabs from "generalComponents/Tabs/Tabs";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { ROUTES } from "generalComponents/variables/routing";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useProjectMainAccess } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useState } from "react";
import { useLocales } from "react-localized";
import { Navigate } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./ProjectSettingsPage.module.sass";

enum SETTINGS_TABS {
  COMMON = "COMMON",
  TEAMS = "TEAMS",
  ROLES = "ROLES"
}

const ProjectSettingsPage = () => {
  const { __ } = useLocales();
  const isMainAccess = useProjectMainAccess({ or: false });
  const [tabView, setTabView] = useState<SETTINGS_TABS>(SETTINGS_TABS.COMMON);
  const { onSetCreateProjectRoleModal, onSetCreateProjectTeamModal } = useActions();
  const { project } = useJoinProjectsSelectors();
  const viewsTabs = [
    {
      name: __("Общее"),
      onClick: () => setTabView(SETTINGS_TABS.COMMON),
      value: SETTINGS_TABS.COMMON
    },
    {
      name: __("Команды"),
      onClick: () => setTabView(SETTINGS_TABS.TEAMS),
      value: SETTINGS_TABS.TEAMS
    },
    {
      name: __("Роли"),
      onClick: () => setTabView(SETTINGS_TABS.ROLES),
      value: SETTINGS_TABS.ROLES
    }
  ];

  const createRole = () => onSetCreateProjectRoleModal({ open: true, type: "add", role: null });
  const createTeam = () => onSetCreateProjectTeamModal({ open: true, type: "add", team: null });

  if (!isMainAccess && !project.accesses.SETTINGS) return <Navigate to={`/${ROUTES.PROJECT}`} replace />;

  return (
    <WorkAreaWrap>
      <ToolsPanel size="large">
        <div className={styles.tools}>
          <Tabs tabs={viewsTabs} value={tabView} size="medium" variant="main" />
          {tabView === SETTINGS_TABS.TEAMS && (
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={__("Создать команду")}
              onClick={createTeam}
            />
          )}
          {tabView === SETTINGS_TABS.ROLES && (
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={__("Создать роль")}
              onClick={createRole}
            />
          )}
        </div>
      </ToolsPanel>
      <div className={styles.container}>
        {tabView === SETTINGS_TABS.COMMON && <SettingsProject />}
        {tabView === SETTINGS_TABS.TEAMS && <JoinProjectTeams />}
        {tabView === SETTINGS_TABS.ROLES && <SettingsRoles />}
      </div>
    </WorkAreaWrap>
  );
};

export default ProjectSettingsPage;
