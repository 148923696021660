import { JournalEventsList } from "containers/Profile/Journal/JournalEventsList/JournalEventsList";
import { useActions } from "hooks/useActions";
import { useEffect } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";

const SingleObjJournalPage = () => {
  const { actionLogId, actionLogGroup } = useParams();

  const { __ } = useLocales();

  const { onGetJournalElements, onNullifyJournal } = useActions();

  const message = {
    success: "",
    error: __("Не удалось получить список элементов, попробуйте еще раз")
  };

  useEffect(() => {
    const controllerElements = new AbortController();

    if (actionLogId && actionLogGroup) {
      onGetJournalElements(message, controllerElements, {
        filter_object: actionLogId,
        filter_group: [actionLogGroup.toLocaleUpperCase()]
      });
    }

    return () => {
      controllerElements?.abort();
      onNullifyJournal();
    };
  }, [actionLogId, actionLogGroup]); //eslint-disable-line

  return <JournalEventsList />;
};

export default SingleObjJournalPage;
