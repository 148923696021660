import { ReactComponent as EditIcon } from "assets/PrivateCabinet/edit.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/Path.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { createMarkup } from "generalComponents/Services/browserServices";
import { useProjectMsg } from "generalComponents/Services/projectServices";
import TextEditorArea from "generalComponents/TextEditorArea/TextEditorArea";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { useActions } from "hooks/useActions";
import html2canvas from "html2canvas";
import { ButtonVariantType } from "models/generalComponents/button";
import { ITemplateProps } from "models/project/docsWorkSpace";
import { PROJECT_DOCS_VIEW } from "models/project/projectDocs";
import { IDocData, IDocsProject, INumberedData } from "models/store/projects/projectStore";
import { IAddDocs, IEditDocs, ITopMessages } from "models/store/projects/projectThunk";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";
import { v4 as uuid } from "uuid";

import InfoTooltip from "../../Components/InfoTooltip/InfoTooltip";
import { useDefaultTemplates } from "../defaultsTemplate";
import styles from "./WorkSpace.module.sass";

const Template: React.FC<ITemplateProps> = ({
  currentDocs,
  selectDoc,
  isEdit,
  changeToolbar,
  handleEdit,
  pressedButton,
  setTypes,
  currentEditor,
  setCurrentEditor,
  isPrint,
  setIsPrint
}): JSX.Element => {
  const { __ } = useLocales();
  const { onEditDocs, onAddDocs, onSetProjectModal } = useActions();
  const MSG = useProjectMsg();
  const { docs } = useProjectsSelectors();
  const defaultTemplates: IDocsProject[] = useDefaultTemplates();

  const [editableTemp, setEditableTemp] = useState<Record<string, IDocsProject>>({});
  const visibleDocs: IDocsProject[] = Object.values(editableTemp) || [];

  const ref = useRef();
  const iRefFrame = useRef(null);

  useEffect(() => {
    const result = currentDocs.reduce(
      (acc, el) => ({
        ...acc,
        [el.id]: el
      }),
      {}
    );
    setEditableTemp(result);
  }, [currentDocs]);

  useEffect(() => {
    if (isPrint) {
      getPrint();
    }
  }, [isPrint]); // eslint-disable-line
  const getPrint = async () => {
    try {
      let block = ref.current;
      const canvas = await html2canvas(block);
      const dataUrl = canvas.toDataURL("image/png");
      if (iRefFrame.current) {
        let isPrinted = false;
        const img = new Image();
        img.width = 750;
        img.onload = () => {
          if (!isPrinted) {
            const iframeWindow = iRefFrame.current.contentWindow || iRefFrame.current;
            iframeWindow.document.body.appendChild(img);
            img.style.position = "absolute";
            img.style.top = "0";
            img.style.left = "0";
            iframeWindow.focus();
            iframeWindow.print();
            iframeWindow.document.body.removeChild(img);
            isPrinted = true;
          }
        };
        img.src = dataUrl;
        setIsPrint(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const templates: IDocsProject[] = useMemo(() => {
    const custom = docs.filter((d) => d.is_template === 1);
    return [...custom, ...defaultTemplates];
  }, [docs]); //eslint-disable-line

  const isActive = (id: string): boolean => currentDocs.some((el) => el.id === id);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>, id: string): void => {
    setEditableTemp((prev) => ({ ...prev, [id]: { ...prev[id], name: e.target.value } }));
  };

  const handleChangeInputs = (e: React.ChangeEvent<HTMLInputElement>, id: string, sectionId: string): void => {
    setEditableTemp((prev) => {
      const copy = [...prev[id].inputs];
      const section = copy.find((el) => el.id === sectionId);
      const index = copy.findIndex((el) => el.id === sectionId);
      if (section) {
        copy.splice(index, 1, { ...section, content: e.target.value });
      }
      return { ...prev, [id]: { ...prev[id], inputs: copy } };
    });
  };

  const handleChangeSection = (value: string, id: string, sectionId: string): void => {
    setEditableTemp((prev) => {
      const copy = [...prev[id].data];
      const section = copy.find((el) => el.id === sectionId);
      const index = copy.findIndex((el) => el.id === sectionId);
      if (section) {
        copy.splice(index, 1, { ...section, section: value });
      }
      return { ...prev, [id]: { ...prev[id], data: copy } };
    });
  };

  const setContent = (value: string, id: string, sectionId: string): void => {
    setEditableTemp((prev) => {
      const copy = [...prev[id].data];
      const section = copy.find((el) => el.id === sectionId);
      const index = copy.findIndex((el) => el.id === sectionId);
      if (section) {
        copy.splice(index, 1, { ...section, content: value });
      }
      return { ...prev, [id]: { ...prev[id], data: copy } };
    });
  };

  const setNumeredContent = (value: string, id: string, sectionId: string): void => {
    setEditableTemp((prev) => {
      const copy = [...prev[id].numberedData];
      const section = copy.find((el) => el.id === sectionId);
      const index = copy.findIndex((el) => el.id === sectionId);
      if (section) {
        copy.splice(index, 1, { ...section, content: value });
      }
      return { ...prev, [id]: { ...prev[id], numberedData: copy } };
    });
  };

  const setNestedContent = (value: string, id: string, sectionId: string, nestedId: string): void => {
    setEditableTemp((prev) => {
      const copy = [...prev[id].numberedData];
      const section = copy.find((el) => el.id === sectionId);
      const index = copy.findIndex((el) => el.id === sectionId);
      const copyNested = [...section.data];
      const sectionNested = copyNested.find((el) => el.id === nestedId);
      const indexNested = copyNested.findIndex((el) => el.id === nestedId);
      if (sectionNested && section) {
        copyNested.splice(indexNested, 1, { ...sectionNested, content: value });
        copy.splice(index, 1, { ...section, data: copyNested });
      }

      return { ...prev, [id]: { ...prev[id], numberedData: copy } };
    });
  };

  const editSection = (name: string, id: string, sectionId: string): void => {
    const params = {
      callback: (value: string) => {
        handleChangeSection(value, id, sectionId);
      },
      name: name
    };
    onSetProjectModal({ type: PROJECT_MODALS.EDIT_SECTION, params });
  };

  const deleteSection = (id: string, sectionId: string): void => {
    setEditableTemp((prev) => {
      return { ...prev, [id]: { ...prev[id], data: prev[id].data.filter((s) => s.id !== sectionId) } };
    });
  };

  const addSection = (id: string): void => {
    const params = {
      callback: (value: string) => {
        const newSection = { id: uuid(), section: value, content: "" };
        setEditableTemp((prev) => ({ ...prev, [id]: { ...prev[id], data: [...prev[id].data, newSection] } }));
      }
    };
    onSetProjectModal({ type: PROJECT_MODALS.ADD_SECTION, params });
  };

  const onSave = (): void => {
    if (isEdit) {
      const payloadEdit: IEditDocs[] = visibleDocs.map((el) => ({
        ...el,
        data: JSON.stringify(el.data.map((it) => ({ ...it, content: "" }))),
        numberedData: JSON.stringify(el.numberedData),
        inputs: JSON.stringify(el.inputs),
        footer: el.footer
      }));
      const messages: ITopMessages = {
        error: MSG.ERROR,
        success: MSG.EDIT_DOC
      };
      const cb = (): void => {
        handleEdit();
      };
      onEditDocs(payloadEdit, messages, cb);
    } else {
      const payloadAdd: IAddDocs[] = visibleDocs.map((el) => ({
        data: JSON.stringify(el.data),
        is_template: 0,
        name: el.name,
        numberedData: JSON.stringify(el.numberedData),
        inputs: JSON.stringify(el.inputs),
        footer: el.footer
      }));

      const messages: ITopMessages = {
        error: MSG.ERROR,
        success: MSG.ADD_DOCS
      };

      const cb = (): void => setTypes(PROJECT_DOCS_VIEW.DOCS);
      onAddDocs(payloadAdd, messages, cb);
    }
  };

  const renderTitle = (doc: IDocsProject): JSX.Element => {
    return isEdit ? (
      <input
        type="text"
        value={editableTemp[doc.id].name}
        onChange={(e) => handleChangeName(e, doc.id)}
        className={classNames(styles.inputName, { [styles.inputFilled]: doc.name.length > 0 })}
        placeholder={__("Имя документа")}
      />
    ) : (
      <h2 className={styles.docName}>
        {doc.name}
        {doc.tooltip && !isPrint && (
          <InfoTooltip
            title={__("Информация")}
            text={<div className={classNames(styles.content)} dangerouslySetInnerHTML={createMarkup(doc.tooltip)} />}
          />
        )}
      </h2>
    );
  };

  const renderInput = (doc: IDocsProject, item: IDocData): JSX.Element => {
    return (
      <input
        type="text"
        value={item.content}
        onChange={(e) => handleChangeInputs(e, doc.id, item.id)}
        placeholder={isPrint ? NO_VALUE : item.section}
      />
    );
  };

  const renderData = (doc: IDocsProject, item: IDocData): JSX.Element => {
    return (
      <div className={styles.section} key={item.id}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionName}>{item.section}</h3>
          {item.tooltip && !isPrint && (
            <InfoTooltip
              text={<div className={classNames(styles.content)} dangerouslySetInnerHTML={createMarkup(item.tooltip)} />}
              title={__("Информация")}
            />
          )}
          {isEdit && doc.is_template !== 2 && (
            <div className={styles.btns}>
              <ButtonIcon
                handleClick={() => editSection(item.section, doc.id, item.id)}
                icon={<EditIcon />}
                variant="white"
              />
              <ButtonIcon handleClick={() => deleteSection(doc.id, item.id)} variant="white" notHover>
                <TrashIcon className={styles.trashIcon} />
              </ButtonIcon>
            </div>
          )}
        </div>
        <div
          className={classNames(styles.editorBox, {
            [styles.editorBoxTable]: item.content.includes("<table")
          })}
          onClick={() => setCurrentEditor(item.id)}
        >
          {currentEditor === item.id && !isEdit ? (
            <TextEditorArea
              content={item.content}
              setContent={(value) => setContent(value, doc.id, item.id)}
              pressedButton={pressedButton}
              changeToolbar={changeToolbar}
              idx={item.id}
            />
          ) : (
            <div
              className={classNames(styles.content)}
              dangerouslySetInnerHTML={createMarkup(
                item.content ? item.content : isPrint ? NO_VALUE : __("Введите текст")
              )}
            />
          )}
        </div>
      </div>
    );
  };

  const renderNestedList = (doc: IDocsProject, item: IDocData, el: any): JSX.Element => {
    return (
      <li key={el.id} className={styles.li}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionName}>{el.section}</h3>
          {el.tooltip && !isPrint && (
            <InfoTooltip
              text={
                <div
                  className={classNames(styles.content, styles.tooltip)}
                  dangerouslySetInnerHTML={createMarkup(el.tooltip)}
                />
              }
              title={__("Информация")}
            />
          )}
        </div>
        <div
          className={classNames(styles.editorBox, {
            [styles.editorBoxTable]: el.content.includes("<table")
          })}
          onClick={() => setCurrentEditor(el.id)}
        >
          {currentEditor === el.id && !isEdit ? (
            <TextEditorArea
              content={el.content}
              setContent={(value) => setNestedContent(value, doc.id, item.id, el.id)}
              pressedButton={pressedButton}
              changeToolbar={changeToolbar}
              idx={el.id}
            />
          ) : (
            <div
              className={classNames(styles.content)}
              dangerouslySetInnerHTML={createMarkup(el.content ? el.content : isPrint ? NO_VALUE : __("Введите текст"))}
            />
          )}
        </div>
      </li>
    );
  };

  const renderNumeredData = (doc: IDocsProject, item: INumberedData): JSX.Element => {
    return (
      <li key={item.id} className={styles.li}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionName}>{item.section}</h3>
          {item.tooltip && !isPrint && (
            <InfoTooltip
              text={
                <div
                  className={classNames(styles.content, styles.tooltip)}
                  dangerouslySetInnerHTML={createMarkup(item.tooltip)}
                />
              }
              title={__("Информация")}
            />
          )}
        </div>
        {item.data ? (
          <ol className={classNames(styles.numeredList, styles.nestedList)}>
            {item.data.map((el) => renderNestedList(doc, item, el))}
          </ol>
        ) : (
          <div
            className={classNames(styles.editorBox, {
              [styles.editorBoxTable]: item.content.includes("<table>")
            })}
            onClick={() => setCurrentEditor(item.id)}
          >
            {currentEditor === item.id && !isEdit ? (
              <TextEditorArea
                content={item.content}
                setContent={(value) => setNumeredContent(value, doc.id, item.id)}
                pressedButton={pressedButton}
                changeToolbar={changeToolbar}
                idx={item.id}
              />
            ) : (
              <div
                className={classNames(styles.content)}
                dangerouslySetInnerHTML={createMarkup(
                  item.content ? item.content : isPrint ? NO_VALUE : __("Введите текст")
                )}
              />
            )}
          </div>
        )}
      </li>
    );
  };

  return (
    <div className={styles.wrap}>
      <iframe ref={iRefFrame} style={{ display: "none" }} title="tempFrame" />

      <div className={styles.document}>
        {visibleDocs.length > 0 && (
          <>
            {visibleDocs.map((doc) => (
              <div key={doc.id} className={styles.sheet} ref={ref}>
                {renderTitle(doc)}
                {doc?.inputs.length > 0 && (
                  <div className={styles.inputsBlock}>{doc.inputs.map((item) => renderInput(doc, item))}</div>
                )}
                {doc.data.map((item) => renderData(doc, item))}
                {doc.numberedData && (
                  <ol className={styles.numeredList}>{doc.numberedData.map((item) => renderNumeredData(doc, item))}</ol>
                )}
                {doc.is_template !== 2 && !isPrint && (
                  <div className={styles.add} onClick={() => addSection(doc.id)}>
                    <PlusIcon />
                  </div>
                )}
                {doc.footer !== 0 && (
                  <div className={styles.footer}>
                    <p className={styles.text}>{__("Подписи об утверждении Устава проекта")}</p>
                    <p className={styles.text}>{__("Руководитель проекта")}</p>
                    <div className={styles.signatures}>
                      <p>{__("Подпись")}</p>
                      <p>{__("Дата")}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
        )}

        {currentDocs.length > 0 && (
          <div className={styles.stickyBtn}>
            <Button
              text={isEdit ? __("Сохранить шаблон") : __("Сохранить")}
              type="button"
              onClick={onSave}
              variant={ButtonVariantType.OK}
            />
          </div>
        )}
      </div>

      <div className={styles.sidePanel}>
        {templates.map((doc) => (
          <div
            key={doc.id}
            className={classNames(styles.sheet, { [styles.sheetActive]: isActive(doc.id) })}
            onClick={() => (!isEdit ? selectDoc(doc) : null)}
          >
            <h2 className={styles.docName}>{doc?.name}</h2>
            <div className={styles.inputsBlock}>
              {doc?.inputs?.length > 0 && doc.inputs?.map((el) => renderInput(doc, el))}
            </div>

            {doc.data.length > 0 &&
              doc.data.map((el) => (
                <div key={el.id} className={styles.section}>
                  {el.section && <h3 className={styles.sectionName}>{el.section}</h3>}
                  <div
                    className={classNames(styles.sideContent, {
                      [styles.empty]: !el.content
                    })}
                    dangerouslySetInnerHTML={createMarkup(el.content)}
                  />
                </div>
              ))}

            {doc.numberedData.length > 0 &&
              doc.numberedData.map((el) => (
                <div key={el.id}>
                  <h3 className={styles.sectionName}>{el.section}</h3>
                  {el.data ? (
                    el.data.map((el) => (
                      <div key={el.id}>
                        <h3 className={styles.sectionName}>{el.section}</h3>
                        <div
                          className={classNames(styles.sideContent, {
                            [styles.empty]: !el.content
                          })}
                          dangerouslySetInnerHTML={createMarkup(el.content)}
                        />
                      </div>
                    ))
                  ) : (
                    <div
                      className={classNames(styles.sideContent, {
                        [styles.empty]: !el.content
                      })}
                      dangerouslySetInnerHTML={createMarkup(el.content)}
                    />
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Template;
