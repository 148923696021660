import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { IFile } from "models/store/files/files";
import { IAddRecentFile, IRecentFiles, RecentFilesActions } from "models/store/recentFiles/recentFilesActions";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { RecentFilesTypes } from "Store/types/recentFilesTypes";

// onSetAddRecentFiles
export const fetchRecentFiles =
  (): ThunkAction<void, RootState, unknown, RecentFilesActions> => async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid
      };
      const { data } = await api.get("/ajax/file_recent.php", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetRecentFiles(data.files));
    } catch (error) {
      console.log(error);
    }
  };
export const onChangeRecentFile =
  (file: IFile): ThunkAction<void, RootState, unknown, RecentFilesActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        odate: new Date(),
        fids: [file.fid]
      };
      await api.get("/ajax/file_edit.php", { params });
      dispatch(onAddRecentFile(file));
    } catch (error) {
      console.log(error);
    }
  };

export const onAddRecentFiles =
  (url?: string): ThunkAction<void, RootState, unknown, RecentFilesActions> =>
  async (_, getState) => {
    const params = {
      uid: getState().user.uid
    };

    try {
      const { data } = await api.get(`/ajax/${url ?? "history_files"}.php`, { params });
      console.log(data);
      // dispatch(onSetRecentFiles(data));
    } catch (error) {
      console.log(error);
    }
  };

export const onSetRecentFiles = (payload: IFile[]): IRecentFiles => ({
  type: RecentFilesTypes.SET_RECENT_FILES,
  payload
});

export const onAddRecentFile = (payload: IFile): IAddRecentFile => ({
  type: RecentFilesTypes.ADD_RECENT_FILE,
  payload
});
