import api from "api";
import { AxiosProgressEvent } from "axios";
import { previewFormats } from "generalComponents/collections/folders";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { EVENT_TYPE, ViewType } from "generalComponents/variables/global";
import { SORTING_TYPE } from "generalComponents/variables/global";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { FOLDER_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { ITopErrorMessage } from "models/common/common";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import {
  initialCreateZipModalState,
  initialCustomizeFileModalState,
  initialEditItemsModalState,
  initialMoveToSafeModalState,
  initialPropertiesModalState,
  initialShareState,
  ITopMessageTypes
} from "models/store/Cabinet/modals/modals";
import { initialMoveElementsModalState } from "models/store/Cabinet/modals/moveElements";
import {
  FilesLinePreview,
  FilesNextArrayType,
  IFile,
  initialPickedItems,
  IPickedItems
} from "models/store/files/files";
import {
  FilesActions,
  IChangeNextFiles,
  IFileLoader,
  INullifyFiles,
  IResetFilesList,
  ISetCurrentItem,
  ISetMyFiles,
  ISetNextFiles,
  ISetPage,
  ISetPickedItems,
  ISetTotalItems
} from "models/store/files/filesActions";
import {
  ICreateZipFilePayload,
  IEditFilesPayload,
  IEditSafePayload,
  IFileListPayload,
  IGetMyFilesDTO,
  IGetMyFilesPayload,
  IMoveToSafeOptions,
  IShareFilesPayload
} from "models/store/files/filesPayloads";
import { ISetSortFile } from "models/store/files/sort/sortActions";
import { ILoadingFiles, ILoadingFolders, ISelectedFiles } from "models/store/files/uploadFilesModals/uploadFiles";
import {
  ICancelFilesUpload,
  IChangeLoadingFiles,
  ICLoseLoadingFiles,
  ISetLoadingFiles,
  ISetSelectedFiles
} from "models/store/files/uploadFilesModals/uploadFilesActions";
import { IChangeLoadingFoldersPayload } from "models/store/files/uploadFilesModals/uploadFilesPayload";
import { FoldersAction } from "models/store/folders/foldersActions";
import { IFolder } from "models/store/folders/foldersStore";
import { JoinProjectActions } from "models/store/joinProjects/joinProjectsActions";
import { ISafe } from "models/store/safe/safe";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { FilesTypes } from "Store/types/filesTypes";
import { SortTypes } from "Store/types/sortTypes";
import { getLocationSearchParams } from "utils/getLocationSearchParams";
import { getPageDep } from "utils/getPageDep";

import { onSetFolders } from "./FoldersActions";
import { onSetAddProjectDocFiles } from "./JoinProjectActions";
import {
  onDownloadLink,
  onFilePrint,
  onFileView,
  onSetCreateZipModal,
  onSetCustomizeFileModal,
  onSetEditItemsModal,
  onSetLoaderModal,
  onSetMoveElementsModal,
  onSetMoveToSafeModal,
  onSetPropertiesModal,
  onSetShareModal,
  onSetTopMessageModal
} from "./ModalActions";

export const onGetAllFiles =
  (controller?: AbortController): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { sort, sort_reverse, color, emo, tags } = getLocationSearchParams();

    const params = {
      uid: getState().user.uid,
      per_page: PER_PAGE,
      page: getState().Files.page,
      emo,
      color,
      tags: tags ? tags.split(",") : undefined,
      sort_reverse,
      sort,
      group: 0
    };
    try {
      dispatch(onFileLoader(true));
      const { data } = await api.get("/ajax/file_list_all.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetMyFiles(data.files));
      dispatch(onSetTotalItems(data.total));
    } catch {
      if (!controller?.signal.aborted) {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
      }
    } finally {
      dispatch(onFileLoader(false));
    }
  };

export const onGetMyFiles =
  (dataRequest?: IGetMyFilesPayload): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { payload, isReset, controller } = dataRequest;

    const { sort, sort_reverse, color, emo, tags } = getLocationSearchParams();

    const isFavorite = window.location.pathname === `/${ROUTES.FOLDERS}/${FOLDER_ROUTES.FAVORITE}`;
    isReset && dispatch(onResetFilesList());
    const params = {
      uid: payload?.did ? undefined : getState().user.uid,
      id_dir: isFavorite
        ? undefined
        : getState().Folders.currentFolder?.id_dir
        ? getState().Folders.currentFolder?.id_dir
        : payload?.id_dir,
      pass: payload?.pass,
      search: payload?.search,
      per_page: PER_PAGE,
      page: getState().Files.page,
      emo,
      color,
      tags: tags ? tags.split(",") : undefined,
      sort_reverse,
      sort,
      group: 0,
      filter_favorite: isFavorite ? 1 : undefined,
      did: payload?.did
    };

    try {
      dispatch(onFileLoader(true));
      const { data } = await api.get<IGetMyFilesDTO>("/ajax/file_list.php", { params, signal: controller?.signal });
      checkResponseStatus(data.ok);
      dispatch(onSetMyFiles(data.files));
      dispatch(onSetTotalItems(data.total));
    } catch {
      if (!controller?.signal.aborted) {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
      }
    } finally {
      dispatch(onFileLoader(false));
    }
  };

export const onGetFilesSharedFolder =
  (payload?: IFileListPayload): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        did: payload?.did,
        id_dir: payload.id_dir,
        pass: payload?.pass,
        per_page: PER_PAGE,
        page: getState().Files.page,
        sort_reverse: 1,
        sort: SORTING_TYPE.DATE_CHANGED,
        group: 0
      };
      dispatch(onFileLoader(true));
      const { data } = await api.get<IGetMyFilesDTO>("/ajax/file_list.php", { params });
      checkResponseStatus(data.ok);
      dispatch(onSetMyFiles(data.files));
      dispatch(onSetTotalItems(data.total));
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onFileLoader(false));
    }
  };

export const onGetMyFilesLinePreview =
  (
    payload?: IFileListPayload,
    messages?: string,
    cb?: () => void
  ): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { sort, sort_reverse, color, emo, tags } = getLocationSearchParams();

    const params = {
      uid: getState().user.uid,
      search: payload?.search,
      per_page: PER_PAGE,
      page: payload?.page ?? 1,
      id_dir: payload.id_dir,
      emo,
      color,
      tags: tags ? tags.split(",") : undefined,
      sort_reverse,
      sort,
      group: 0,
      filter_favorite: payload.isFavorite
    };
    try {
      const { data } = await api.get<IGetMyFilesDTO>("/ajax/file_list.php", {
        params
      });
      const idDir = payload.id_dir;
      const state = getState().Files.filesNext;
      const result: { files: FilesNextArrayType; total: number } = { files: null, total: data.total };
      if (payload.page === 1) {
        result.files = data.files;
      } else {
        result.files = [...(state[idDir].files as FilesNextArrayType), ...data.files];
      }

      dispatch(onSetNextFiles({ [idDir]: result }));
    } catch {
      if (messages) {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: messages }));
      }
    } finally {
      if (cb) cb();
    }
  };

export const onUploadFile =
  (
    file: ILoadingFiles,
    controller: AbortController
  ): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("myfile", file.file);
      formData.append("id_dir", file.options.id_dir);
      formData.append("fileName", file.options.name);
      formData.append("tags", JSON.stringify(file.options.tags));
      formData.append("color", file.options.color);
      formData.append("emo", file.options.emo);

      const { data } = await api.post(`/ajax/file_add.php`, formData, {
        signal: controller.signal,
        onUploadProgress: (progressEvent: AxiosProgressEvent): void => {
          const time = Math.floor(
            progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
          );
          const percent = progressEvent.progress * 100;
          dispatch(onChangeLoadingFiles({ ...file, percent, time }));
        }
      });
      dispatch(onChangeLoadingFiles({ ...file, done: true }));
      if (getState().Files.view === ViewType.LINES_PREVIEW) {
        dispatch(onSetAddNextFiles(data?.file_info));
      } else {
        dispatch(onSetAddFiles(data?.file_info));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const onUploadFolderFiles =
  (
    payload: { id: string },
    controller: AbortController
  ): ThunkAction<void, RootState, unknown, FilesActions | FoldersAction | CabinetActions> =>
  async (dispatch, getState) => {
    const folderFiles = getState().Files.uploadFiles.loadingFolders.find(({ id }) => id === payload.id).files;
    const uid = getState().user.uid;
    const rootIdDir = getState()?.Folders?.currentFolder?.id_dir || getState()?.Folders?.folders[0]?.id_dir;

    try {
      let results;

      for (const file of folderFiles) {
        const formData = new FormData();
        formData.append("uid", uid);
        formData.append("myfile", file.file);
        formData.append("id_dir", rootIdDir);
        formData.append("dir", file.options.path);
        formData.append("fileName", file.options.name);
        formData.append("tags", JSON.stringify(file.options.tags));
        formData.append("color", file.options.color);
        formData.append("emo", file.options.emo);
        formData.append("dep", file.options.dep);

        results = await api.post(`/ajax/file_add.php`, formData, {
          signal: controller.signal,
          onUploadProgress: (progressEvent: AxiosProgressEvent): void => {
            const time = Math.floor(
              progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
            );
            console.log(time);
            const percent = progressEvent.progress * 100;
            dispatch(onChangeLoadingFolders({ id: payload.id, file: { ...file, percent, time } }));
          }
        });

        dispatch(onChangeLoadingFolders({ id: payload.id, file: { ...file, done: true } }));
      }

      checkResponseStatus(results.data.ok);

      dispatch(onSetFolders(results.data.folders.global, results.data.folders.other));
      if (getState().Files.view === ViewType.LINES_PREVIEW) {
        dispatch(onSetAddNextFiles(results.data?.file_info));
      } else {
        dispatch(onSetAddFiles(results.data?.file_info));
      }
    } catch (error) {
      console.log(error);
      if (!controller?.signal?.aborted) {
        dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
      }
    }
  };

export const setCreateFile =
  (
    file: ILoadingFiles,
    controller: AbortController
  ): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const params = {
        uid: getState().user.uid,
        id_dir: file.options.id_dir,
        fileName: file.options.name,
        tags: file.options.tags ? JSON.stringify(file.options.tags) : ([] as string[]),
        color: file.options.color ? file.options.color : undefined,
        emo: file.options.emo ? file.options.emo : undefined,
        ext: file.options.ext
      };

      const { data } = await api.get("/ajax/file_new.php", {
        params,
        signal: controller.signal,
        onUploadProgress: (progressEvent: AxiosProgressEvent): void => {
          const time = Math.floor(
            progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
          );
          const percent = progressEvent.progress * 100;
          dispatch(onChangeLoadingFiles({ ...file, percent, time }));
        }
      });
      dispatch(onChangeLoadingFiles({ ...file, done: true }));
      if (getState().Files.view === ViewType.LINES_PREVIEW) {
        dispatch(onSetAddNextFiles(data.file));
      } else {
        if (file.options.id_dir === getState().Folders.currentFolder.id_dir) {
          dispatch(onSetAddFiles(data.file));
        }
      }

      if (file.isOpen) {
        dispatch(onFileView({ open: true, params: data.file }));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const onShareFiles =
  (payload: IShareFilesPayload): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      fids: payload.fids,
      users_to: payload.usersTo
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get("/ajax/file_share.php", { params });
      if (data.ok) {
        dispatch(onSetPickedItems(initialPickedItems()));
        dispatch(onSetShareModal(initialShareState()));
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.SUCCESS,
            variantMessage: EVENT_TYPE.SHARED
          })
        );
        return;
      }

      throw new Error();
    } catch (error) {
      console.log(error);
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onEditFiles =
  (
    payload: IEditFilesPayload,
    typeMessage: EVENT_TYPE,
    safe?: IEditSafePayload,
    callback?: (file?: IFile) => void
  ): ThunkAction<void, RootState, unknown, FoldersAction | FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      ...payload,
      code: safe ? safe.code : undefined,
      pass: safe ? safe.pass : undefined,
      id_safe: safe ? safe.id_safe : undefined
    };
    try {
      if (!payload?.is_favorite && payload?.is_favorite !== 0) {
        dispatch(onSetLoaderModal(true));
      }

      const { data } = await api.get(`/ajax/${safe ? "safe_" : ""}file_edit.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetCurrentItem(data?.file_info));
      dispatch(onSetPickedItems(initialPickedItems()));
      dispatch(onSetEditItemsModal(initialEditItemsModalState()));
      dispatch(onSetPropertiesModal(initialPropertiesModalState()));
      dispatch(onSetCustomizeFileModal(initialCustomizeFileModalState()));
      dispatch(onSetMoveElementsModal(initialMoveElementsModalState()));
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: typeMessage
        })
      );

      if (window.location.pathname === `/${ROUTES.FOLDERS}/${FOLDER_ROUTES.SHARED}`) {
        dispatch(onResetFilesList());
        dispatch(onLoadSharedFiles(undefined));
      } else {
        if (callback) callback(data.file_info);
      }
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      if (!payload?.is_favorite && payload?.is_favorite !== 0) {
        dispatch(onSetLoaderModal(false));
      }
    }
  };

export const onEditFilesLocal =
  (
    payload: IEditFilesPayload,
    safe?: IEditSafePayload
  ): ThunkAction<void, RootState, unknown, FoldersAction | FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      ...payload,
      code: safe ? safe.code : undefined,
      pass: safe ? safe.pass : undefined,
      id_safe: safe ? safe.id_safe : undefined
    };
    try {
      dispatch(onSetLoaderModal(true));

      const { data } = await api.get(`/ajax/${safe ? "safe_" : ""}file_edit.php`, { params });
      checkResponseStatus(data.ok);

      if (
        getState().Files.view === ViewType.LINES_PREVIEW &&
        window.location.pathname.startsWith(`/${ROUTES.FOLDERS}`)
      ) {
        dispatch(onSetUpdateNextFiles([data.file_info]));
      } else {
        dispatch(onSetUpdateFiles([data.file_info]));
      }

      if (getState().Files.currentItem) {
        dispatch(onSetCurrentItem(data?.file_info));
      }

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.EDIT
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onCopyFiles =
  (
    payload: IEditFilesPayload,
    callback?: () => void
  ): ThunkAction<void, RootState, unknown, FoldersAction | JoinProjectActions | FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_project: getState()?.JoinProjects?.project?.id,
      ...payload
    };
    try {
      dispatch(onSetLoaderModal(true));

      const { data } = await api.get(`/ajax/file_copy.php`, { params });

      const { _FILES_, _PROJECT_DOC_ } = getPageDep(payload?.dep);

      if (_FILES_) {
        if (
          getState().Files.view === ViewType.LINES_PREVIEW &&
          window.location.pathname.startsWith(`/${ROUTES.FOLDERS}`)
        ) {
          dispatch(onSetAddNextFiles(data?.file_info));
        } else {
          dispatch(onSetAddFiles(data?.file_info));
        }
      }

      if (_PROJECT_DOC_) {
        dispatch(onSetAddProjectDocFiles(data?.file_info));
      }

      callback && callback();

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.COPY
        })
      );
      dispatch(onSetMoveElementsModal(initialMoveElementsModalState()));
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onReplaceFiles =
  (
    payload: {
      file?: ILoadingFiles;
      file_from?: IFile;
      safe?: ISafe;
    },
    controller?: AbortController
  ): ThunkAction<void, RootState, unknown, FoldersAction | FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const formData = new FormData();
    formData.append("uid", getState().user.uid);
    formData.append("fid", getState().Files.uploadFiles.replacementFile?.fid);
    payload?.safe?.pass && formData.append("pass", payload.safe.pass);
    payload?.file && formData.append("myfile", payload.file.file);
    payload?.file_from && formData.append("fid_from", payload.file_from.fid);

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.post(`/ajax/file_replace.php`, formData, {
        signal: controller?.signal,
        onUploadProgress: payload?.file
          ? (progressEvent: AxiosProgressEvent): void => {
              const time = Math.floor(
                progressEvent.total / (progressEvent.loaded / progressEvent.event.timeStamp) / 60000 - 5
              );
              const percent = progressEvent.progress * 100;
              dispatch(onChangeLoadingFiles({ ...payload.file, percent, time }));
            }
          : undefined
      });

      payload?.file && dispatch(onChangeLoadingFiles({ ...payload.file, done: true }));

      checkResponseStatus(data.ok);

      if (getState().Files.view === ViewType.LINES_PREVIEW) {
        dispatch(onSetReplaceNextFile(data.file_info));
      } else {
        dispatch(onSetReplaceFile(data.file_info));
      }

      dispatch(onSelectedFiles([]));

      dispatch(onSetSelectedUploadReplacementFile(null));

      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.EDIT }));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onDeleteHardFiles =
  (
    payload: { fids: string[]; id_dir: string },
    safe?: IEditSafePayload
  ): ThunkAction<void, RootState, unknown, FoldersAction | FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      fids: payload.fids,
      code: safe ? safe.code : undefined,
      pass: safe ? safe.pass : undefined,
      id_safe: safe ? safe.id_safe : undefined,
      is_del: 1
    };
    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/${safe ? "safe_" : ""}file_edit.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetPickedItems(initialPickedItems()));

      dispatch(onSetDelFiles({ ids: payload.fids }));

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.IS_DEL
        })
      );
    } catch {
      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.ERROR
        })
      );
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onMoveFileToArchive =
  (
    payload: { fids: string[]; id_dir: string; files?: IFile[] },
    typeMessage: EVENT_TYPE,
    safe?: IEditSafePayload
  ): ThunkAction<void, RootState, unknown, FoldersAction | FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      fids: payload.fids,
      is_archive: 1,
      id_safe: safe ? safe.id_safe : undefined
    };
    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/${safe ? "safe_file_archive" : "file_edit"}.php`, { params });
      checkResponseStatus(data.ok);

      dispatch(onSetPickedItems(initialPickedItems()));

      if (getState().Files.view === ViewType.LINES_PREVIEW && payload?.files?.length) {
        dispatch(onSetDelNextFiles(payload.files));
      } else {
        dispatch(onSetDelFiles({ ids: payload.fids }));
      }

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: typeMessage
        })
      );
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onCreateZipFile =
  (
    payload: ICreateZipFilePayload,
    safe?: IEditSafePayload
  ): ThunkAction<void, RootState, unknown, FoldersAction | FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      id_dir: payload.idDir,
      id_dirs: payload.id_dirs,
      fids: payload.fids,
      zip_name: payload.zipName,
      zip_pass: payload.zipPass,
      tags: JSON.stringify(payload.tags),
      color: payload.color,
      emo: payload.emo,
      code: safe ? safe.code : undefined,
      pass: safe ? safe.pass : undefined,
      id_safe: safe ? safe.id_safe : undefined
    };

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/${safe ? "safe_" : ""}file_zip.php`, { params });

      checkResponseStatus(data.ok);
      dispatch(onSetPickedItems(initialPickedItems()));

      dispatch(onSetCreateZipModal(initialCreateZipModalState()));

      if (
        getState().Files.view === ViewType.LINES_PREVIEW &&
        window.location.pathname.startsWith(`/${ROUTES.FOLDERS}`)
      ) {
        dispatch(onSetAddNextFiles(data?.file_info));
      } else {
        dispatch(onSetAddFiles(data?.file_info));
      }

      dispatch(
        onSetTopMessageModal({
          open: true,
          type: ITopMessageTypes.SUCCESS,
          variantMessage: EVENT_TYPE.ZIP
        })
      );
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const fetchFileZipLink =
  (id: string, did?: string): ThunkAction<void, RootState, unknown, FoldersAction | CabinetActions> =>
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(onSetLoaderModal(true));
      const params = {
        uid: did ? undefined : getState().user.uid,
        fid: id,
        did
      };
      const { data } = await api.get("/ajax/file_download.php?", { params });
      dispatch(onDownloadLink(data.link));
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onLoadSharedFiles =
  (idDir: string, messages?: ITopErrorMessage): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      dispatch(onFileLoader(true));
      const { sort, sort_reverse } = getLocationSearchParams();
      const { color, emo, figure, tags } = getState().FilterCriteria;

      const params = {
        uid: getState().user.uid,
        id_dir: idDir,
        per_page: PER_PAGE,
        page: getState().Files.page,
        emo: emo || undefined,
        fig: figure,
        color: color || undefined,
        tags: tags.length ? JSON.stringify(tags) : undefined,
        sort_reverse,
        sort,
        group: 0
      };
      const { data } = await api.get(`/ajax/file_share_get.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetMyFiles(data.files));
      dispatch(onSetTotalItems(data.total));
    } catch (error) {
      console.log(error);
      if (messages) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR,
            message: messages.error
          })
        );
      }
    } finally {
      dispatch(onFileLoader(false));
    }
  };

export const fetchPrintFileUrl =
  (file: IFile, messages?: ITopErrorMessage): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const mType = file?.mime_type;
      const isFormat = previewFormats.filter((format) => file.ext.toLowerCase().includes(format)).length > 0;
      const preview = file?.preview;
      if (mType === "application/pdf" || (mType && mType?.includes("image"))) {
        dispatch(onFilePrint({ open: true, url: preview, fid: file?.fid }));
      } else {
        if (isFormat) {
          const params = {
            uid: getState().user.uid,
            fid: file.fid
          };
          const { data } = await api.get(`/ajax/file_preview.php?`, { params });
          dispatch(onFilePrint({ open: true, url: data.file_pdf, fid: file?.fid }));
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      console.log(error);
      if (messages) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR,
            message: messages.error
          })
        );
      }
    }
  };
export const onDeleteFiles =
  (payload: {
    fids: string[];
    files?: IFile[];
  }): ThunkAction<void, RootState, unknown, FoldersAction | FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    const params = {
      uid: getState().user.uid,
      fids: payload.fids,
      is_del: 1
    };
    const isLinesPreviewView = getState().Files.view === ViewType.LINES_PREVIEW;

    try {
      dispatch(onSetLoaderModal(true));
      const { data } = await api.get(`/ajax/file_edit.php`, { params });
      checkResponseStatus(data.ok);
      dispatch(onSetPickedItems(initialPickedItems()));

      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.IS_DEL }));

      if (window.location.pathname === `/${ROUTES.FOLDERS}/${FOLDER_ROUTES.SHARED}`) {
        dispatch(onLoadSharedFiles(undefined));
      } else {
        if (isLinesPreviewView && payload?.files?.length) {
          dispatch(onSetDelNextFiles(payload.files));
        } else {
          dispatch(onSetDelFiles({ ids: payload.fids }));
        }
      }
    } catch {
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    } finally {
      dispatch(onSetLoaderModal(false));
    }
  };

export const onMoveToSafeFile =
  (
    fid: string,
    options: IMoveToSafeOptions,
    safe: ISafe,
    file: IFile
  ): ThunkAction<void, RootState, unknown, FilesActions | CabinetActions> =>
  async (dispatch, getState) => {
    try {
      const formData = new FormData();
      formData.append("uid", getState().user.uid);
      formData.append("id_safe", safe.id);
      formData.append("pass", safe.pass);
      formData.append("fid_from", fid);
      options?.tags && formData.append("tags", JSON.stringify(options.tags));
      options?.color && formData.append("color", options.color);
      options?.emo && formData.append("emoji", options.emo);

      const { data } = await api.post(`/ajax/safe_file_add.php`, formData);
      checkResponseStatus(data.ok);

      dispatch(onSetMoveToSafeModal(initialMoveToSafeModalState()));

      if (getState().Files.view === ViewType.LINES_PREVIEW) {
        dispatch(onSetDelNextFiles([file]));
      } else {
        dispatch(onSetDelFiles({ ids: [fid] }));
      }

      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, variantMessage: EVENT_TYPE.SAFE }));
    } catch (error) {
      console.log(error);
      dispatch(onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR }));
    }
  };

export const onSetMyFiles = (payload: FilesNextArrayType): ISetMyFiles => ({
  type: FilesTypes.SET_MY_FILES,
  payload
});

export const onSetNextFiles = (payload: FilesLinePreview): ISetNextFiles => ({
  type: FilesTypes.SET_FILES_NEXT,
  payload
});

export const onChangeNextFiles = (payload: FilesLinePreview): IChangeNextFiles => ({
  type: FilesTypes.CHANGE_FILES_NEXT,
  payload
});

export const onSetTotalItems = (payload: number): ISetTotalItems => ({
  type: FilesTypes.SET_TOTAL_ITEMS,
  payload
});

export const onSetPage = (payload: number): ISetPage => ({
  type: FilesTypes.SET_PAGE,
  payload
});

export const onResetFilesList = (): IResetFilesList => ({
  type: FilesTypes.RESET_FILES_LIST
});

export const onFileLoader = (payload: boolean): IFileLoader => ({
  type: FilesTypes.FILES_LOADER,
  payload
});

export const onSetPickedItems = (payload: IPickedItems): ISetPickedItems => ({
  type: FilesTypes.SET_PICKED_ITEMS,
  payload
});

export const onNullifyFiles = (): INullifyFiles => ({
  type: FilesTypes.NULLIFY_FILES
});

export const onSetCurrentItem = (payload: IFile | IFolder): ISetCurrentItem => ({
  type: FilesTypes.SET_CURRENT_ITEM,
  payload
});

export const onSetSortCriterionFile = (): ISetSortFile => ({
  type: SortTypes.SET_SORT_FILE
});

// LoadFiles

export const onSelectedFiles = (payload: ISelectedFiles[]): ISetSelectedFiles => ({
  type: FilesTypes.SET_SELECTED_FILES,
  payload
});

export const onLoadingFiles = (payload: ILoadingFiles): ISetLoadingFiles => ({
  type: FilesTypes.SET_LOADING_FILES,
  payload
});

export const onChangeLoadingFiles = (payload: ILoadingFiles): IChangeLoadingFiles => ({
  type: FilesTypes.CHANGE_LOADING_FILES,
  payload
});

export const onCancelFilesUpload = (payload: string): ICancelFilesUpload => ({
  type: FilesTypes.CANCEL_FILES_UPLOAD,
  payload
});

export const onCloseLoadingFiles = (): ICLoseLoadingFiles => ({
  type: FilesTypes.CLOSE_LOADING_FILES
});

// LoadFolders

export const onSetLoadingFolders = (payload: ILoadingFolders[]): FilesActions => ({
  type: FilesTypes.SET_LOADING_FOLDERS,
  payload
});

export const onChangeLoadingFolders = (payload: IChangeLoadingFoldersPayload): FilesActions => ({
  type: FilesTypes.CHANGE_LOADING_FOLDERS,
  payload
});

export const onCancelFoldersUpload = (payload: string): FilesActions => ({
  type: FilesTypes.CANCEL_FOLDERS_UPLOAD,
  payload
});

// Update files

export const onSetAddFiles = (payload: IFile | IFolder): FilesActions => ({
  type: FilesTypes.SET_ADD_FILES,
  payload
});

export const onSetDelFiles = (payload: { ids: string[] }): FilesActions => ({
  type: FilesTypes.SET_DEL_FILES,
  payload
});

export const onSetUpdateFiles = (payload: (IFile | IFolder)[]): FilesActions => ({
  type: FilesTypes.SET_UPDATE_FILES,
  payload
});

export const onSetAddNextFiles = (payload: IFile | IFolder): FilesActions => ({
  type: FilesTypes.SET_ADD_NEXT_FILES,
  payload
});

export const onSetUpdateNextFiles = (payload: (IFile | IFolder)[]): FilesActions => ({
  type: FilesTypes.SET_UPDATE_NEXT_FILES,
  payload
});

export const onSetDelNextFiles = (payload: (IFile | IFolder)[]): FilesActions => ({
  type: FilesTypes.SET_DEL_NEXT_FILES,
  payload
});

export const onSetSelectedUploadReplacementFile = (payload: IFile | null): FilesActions => ({
  type: FilesTypes.SET_SELECTED_UPLOAD_REPLACEMENT_FILE,
  payload
});

export const onSetReplaceFile = (payload: IFile): FilesActions => ({
  type: FilesTypes.SET_REPLACE_FILE,
  payload
});

export const onSetReplaceNextFile = (payload: IFile): FilesActions => ({
  type: FilesTypes.SET_REPLACE_NEXT_FILE,
  payload
});
