import JointProjectsTaskCard from "containers/JoinProject/JointProjectsTasks/components/JointProjectsTaskCard/JointProjectsTaskCard";
import Clue from "generalComponents/Clue/Clue";
import { useFilteredTasks } from "hooks/joinProjectHooks";
import { useIsFiltersJoinProject } from "hooks/useIsFilters";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectsBacklogGrid.module.sass";

export const JointProjectsBacklogGrid = () => {
  const { __ } = useLocales();

  const { project } = useJoinProjectsSelectors();

  const { filteredTasks } = useFilteredTasks(project.backlog);

  const isFilters = useIsFiltersJoinProject();

  return (
    <div className={styles.container}>
      {isFilters && !filteredTasks?.length ? (
        <Clue icon="neutral" text={__("Нет задач соответствующих параметрам фильтрации")} />
      ) : !isFilters && !filteredTasks?.length ? (
        <Clue icon="sad" text={__("Тут пока пусто")} />
      ) : (
        filteredTasks.map((item) => (
          <div key={item.id}>
            <JointProjectsTaskCard item={item} />
          </div>
        ))
      )}
    </div>
  );
};
