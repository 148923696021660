export enum JoinProjectsTypes {
  SET_PROJECT_LIST = "SET_PROJECT_LIST",
  ADD_PROJECT = "ADD_PROJECT",
  EDIT_PROJECT = "EDIT_PROJECT",
  DEL_PROJECT = "DEL_PROJECT",
  SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT",
  ADD_ROLE = "ADD_ROLE",
  EDIT_ROLE = "EDIT_ROLE",
  EDIT_STATUSES = "EDIT_STATUSES",
  ADD_TEAM = "ADD_TEAM",
  UPDATE_PROJECT_TEAMS = "UPDATE_PROJECT_TEAMS",
  EDIT_TEAM = "EDIT_TEAM",
  DEL_TEAM = "DEL_TEAM",
  UPDATE_SPRINTS_PROJECT = "UPDATE_SPRINTS_PROJECT",
  SET_PROJECT_LINKS_CATEGORY = "SET_PROJECT_LINKS_CATEGORY",
  SET_PROJECT_LINKS = "SET_PROJECT_LINKS",
  ADD_PROJECT_LINKS = "ADD_PROJECT_LINKS",
  EDIT_PROJECT_LINKS = "EDIT_PROJECT_LINKS",
  DEL_PROJECT_LINKS = "DEL_PROJECT_LINKS",
  ADD_PROJECT_LINKS_CATEGORY = "ADD_PROJECT_LINKS_CATEGORY",
  EDIT_PROJECT_LINKS_CATEGORY = "EDIT_PROJECT_LINKS_CATEGORY",
  DEL_PROJECT_LINKS_CATEGORY = "DEL_PROJECT_LINKS_CATEGORY",
  SET_PROJECT_LOADER = "SET_PROJECT_LOADER",
  SET_PROJECT_PAGE = "SET_PROJECT_PAGE",
  SET_PROJECT_TOTAL_ITEMS = "SET_PROJECT_TOTAL_ITEMS",
  //LIBRARY
  SET_PROJECT_LIBRARY_FOLDER_INFO = "SET_PROJECT_LIBRARY_FOLDER_INFO",
  SET_PROJECT_LIBRARY_FOLDERS = "SET_PROJECT_LIBRARY_FOLDERS",
  SET_PROJECT_ADD_LIBRARY_FOLDER = "SET_PROJECT_ADD_LIBRARY_FOLDER",
  SET_PROJECT_UPDATE_LIBRARY_FOLDER = "SET_PROJECT_UPDATE_LIBRARY_FOLDER",
  SET_PROJECT_DEL_LIBRARY_FOLDER = "SET_PROJECT_DEL_LIBRARY_FOLDER",
  SET_PROJECT_DEL_LIBRARY_FILES = "SET_PROJECT_DEL_LIBRARY_FILES",
  RESET_PROJECT_LIBRARY_FOLDERS = "RESET_PROJECT_LIBRARY_FOLDERS",
  CHANGE_PROJECT_LIBRARY_LOADING_FILES = "CHANGE_PROJECT_LIBRARY_LOADING_FILES",
  RESET_PROJECT_LIBRARY_LOADING_FILES = "RESET_PROJECT_LIBRARY_LOADING_FILES",
  // TASK
  SET_PROJECT_TASKS = "SET_PROJECT_TASKS",
  SET_PROJECT_BACKLOG = "SET_PROJECT_BACKLOG",
  ADD_PROJECT_TASK = "ADD_PROJECT_TASK",
  EDIT_PROJECT_TASK = "EDIT_PROJECT_TASK",
  DELETE_PROJECT_TASK = "DELETE_PROJECT_TASK",
  MOVE_TASK_TO_BACKLOG = "MOVE_TASK_TO_BACKLOG",
  MOVE_TASK_FROM_BACKLOG = "MOVE_TASK_FROM_BACKLOG",
  MOVED_TASKS = "MOVED_TASKS",
  SET_ALL_PROJECT_TASKS = "SET_ALL_PROJECT_TASKS",
  RESET_ALL_PROJECT_TASKS = "RESET_ALL_PROJECT_TASKS",
  SET_CURRENT_TASK = "SET_CURRENT_TASK",
  // DOCUMENTATION
  SET_PROJECT_DOC_FILES = "SET_PROJECT_DOC_FILES",
  SET_TRASH_PROJECT_DOC_FILE = "SET_TRASH_PROJECT_DOC_FILE",
  UPDATE_PROJECT_DOC_DEFAULT_FOLDERS = "UPDATE_PROJECT_DOC_DEFAULT_FOLDERS",
  SET_COPY_PROJECT_EDIT_FILE = "SET_COPY_PROJECT_EDIT_FILE",
  ADD_PROJECT_DOC_FILES = "ADD_PROJECT_DOC_FILES",
  UPDATE_PROJECT_DOC_FILES = "UPDATE_PROJECT_DOC_FILES",
  NULLIFY_PROJECT_DOC = "NULLIFY_DOCUMENTATION",
  RESET_PROJECT_DOC_FILES_LIST = "RESET_PROJECT_DOC_FILES_LIST",
  SET_PROJECT_SELECTED_FILES = "SET_PROJECT_SELECTED_FILES",
  SET_PROJECT_LOADING_FILES = "SET_PROJECT_LOADING_FILES",
  CHANGE_PROJECT_LOADING_FILES = "CHANGE_PROJECT_LOADING_FILES",
  CANCEL_PROJECT_FILES_UPLOAD = "CANCEL_PROJECT_DOC_UPLOAD",
  CLOSE_PROJECT_LOADING_FILES = "CLOSE_PROJECT_LOADING_FILES",
  //FILES
  SET_PROJECT_FILES = "SET_PROJECT_FILES",
  ADD_PROJECT_FILES = "ADD_PROJECT_FILES",
  UPDATE_PROJECT_FILES = "UPDATE_PROJECT_FILES",
  UPDATE_PROJECT_FILES_DEFAULT_FOLDERS = "UPDATE_PROJECT_FILES_DEFAULT_FOLDERS",
  SET_TRASH_PROJECT_FILE = "SET_TRASH_PROJECT_FILE",
  RESET_PROJECT_FILES_LIST = "RESET_PROJECT_FILES_LIST",
  //TRASH
  SET_PROJECT_TRASH_FILES = "SET_PROJECT_TRASH_FILES",
  SET_UPDATE_PROJECT_TRASH_FILES = "SET_UPDATE_PROJECT_TRASH_FILES",
  SET_DEL_PROJECT_TRASH_ELEMENT = "SET_DEL_PROJECT_TRASH_ELEMENT",
  SET_RESET_PROJECT_TRASH_ELEMENTS = "SET_RESET_PROJECT_TRASH_ELEMENTS"
}
