import { DEFAULT_PROJECT_DOC_FOLDERS } from "models/generalComponents/folders";
import { TypeElements } from "models/typeElements/typeElements";

import { IFile } from "../files/files";
import { IInitialsUploadFiles } from "../files/uploadFilesModals/uploadFiles";
import { IFolder } from "../folders/foldersStore";
import { ILibraryState } from "../library/library";

export interface IJoinProject {
  name: string;
  icon: string;
  id: string;
  is_my: boolean;
  date_start: string;
  date_end: string;
  work_days: number[];
  work_hours: number[];
  gmt: string;
  is_gmt_auto: boolean;
  accesses: Record<ROLE_ACCESSES, boolean>;
  statuses: IJointProjectStatus[];
  participants?: IParticipant[];
  teams?: IProjectTeam[];
  active_tasks?: IProjectTask[];
}

export enum TASK_DEPENDENCIES {
  RELATION = "RELATION",
  REQUIRED_FOR = "REQUIRED_FOR",
  DEPENDS_ON = "DEPENDS_ON",
  PARENT_FOR = "PARENT_FOR",
  CHILD_FOR = "CHILD_FOR"
}

export enum ROLE_ACCESSES {
  ANALITYCS = "ANALITYCS",
  MGR_TEAMS = "MGR_TEAMS",
  MGR_LINKS = "MGR_LINKS",
  MGR_LIBRARY = "MGR_LIBRARY",
  MGR_FILES = "MGR_FILES",
  MGR_DOCS = "MGR_DOCS",
  SETTINGS = "SETTINGS"
}

export enum SYSTEM_ROLE {
  ADMIN = "admin",
  DEVELOPER = "developer",
  APPLICANT = "applicant",
  WATCHER = "watcher"
}

export enum STATUSES {
  PENDING = "PENDING",
  IN_WORK = "IN_WORK",
  DONE = "DONE",
  OVERDUE = "OVERDUE"
}
// Task
export enum TASK_PRIORITY {
  MINOR = "MINOR",
  NORMAL = "NORMAL",
  URGENT = "URGENT"
}

export enum TASK_TYPE {
  EPIC = "EPIC",
  TASK = "TASK"
}

export interface ITaskComment {
  id: string;
  id_task: string;
  text: string;
  files: IFile[];
  files_4hub: IFile[];
  id_user: "string";
  date: string;
}

export type TaskDependenciesType = Partial<Record<TASK_DEPENDENCIES, IProjectTask[]>>;

export interface IProjectTask {
  id: string;
  name: string;
  priority: TASK_PRIORITY;
  id_type: TASK_TYPE;
  id_status: string;
  id_team: string;
  author: string;
  id_executor: string;
  id_sprints: string[];
  duration: number;
  date_start: string;
  date_end: string;
  date_long: string;
  comments: ITaskComment[];
  emo: string;
  color: string;
  tags: string[];
  queue: any;
  dependencies: any;
  num: string;
  id_project: string;
  prim: string;
  position: { [key: string]: { order: number } };
  ut: string;
  orders: { [key: string]: number };
  table?: TypeElements.TASKS;
  links: TaskDependenciesType;
  files?: IFile[];
  files_4hub?: IFile[];
  col_com?: number;
  col_links?: number;
  is_del?: number;
  is_backlog: number;
  time_long?: number;
}
// ====

export interface IProjectRole {
  id: string;
  name: SYSTEM_ROLE | string;
  accesses: Record<ROLE_ACCESSES, boolean>;
  is_del: boolean;
  is_system: boolean;
}

export interface IParticipant {
  id: string;
  fname: string;
  sname: string;
  email: string;
  icon: string;
  idRole: string;
  jobTitle: string;
}

export interface IProjectTeam {
  id: string;
  id_project: string;
  name: string;
  prim: string;
  users: string[];
  is_close: number;
  tasks?: IProjectTask[];
  table?: TypeElements.TEAMS;
}

export interface IJointProjectStatus {
  id?: string;
  color: string;
  name: string;
  system_name?: STATUSES;
  x: number;
  y: number;
  is_visible: boolean;
}

export interface ISprint {
  id: string;
  name: string;
  date_start: string;
  date_end: string;
}

export interface IJoinProjectLink {
  id: string;
  name: string;
  link: string;
  id_dep: string;
  emo: string;
  icon: string;
  id_project: string;
  date: string;
}

export interface IJoinProjectLinkCategory {
  id: string;
  id_project: string;
  name: string;
}

export interface IJoinProjectsLinksState {
  categories: IJoinProjectLinkCategory[];
  links: IJoinProjectLink[];
}

export type TProjectFilesArray = (IFile | IFolder)[];

export type TProjectElementsArray = (IFile | IFolder | IProjectTask | IProjectTeam)[];

export interface IProjectSystemDocFolders {
  id: string;
  name: DEFAULT_PROJECT_DOC_FOLDERS;
}

export interface IDocumentation {
  files: TProjectFilesArray;
  folderInfo: IFolder;
}

export type TJoinProjectLibraryState = Omit<ILibraryState, "rubrics" | "page" | "loader" | "total">;

export interface IProject {
  id: string;
  name: string;
  icon: string;
  work_days: number[];
  work_hours: number[];
  date_start: string;
  date_end: string;
  id_admin?: string; // не уверен что нужно
  is_my: boolean;
  gmt: string;
  is_gmt_auto: boolean;
  roles?: IProjectRole[];
  participants?: IParticipant[];
  teams?: IProjectTeam[];
  sprints?: ISprint[];
  statuses?: IJointProjectStatus[];
  accesses?: Record<ROLE_ACCESSES, boolean>;
  links?: IJoinProjectLink[];
  linksCategories?: IJoinProjectLinkCategory[];
  trash: TProjectElementsArray;
  library?: TJoinProjectLibraryState;
  documentation: IDocumentation;
  files: TProjectFilesArray;
  tasks: IProjectTask[];
  allTasks: IProjectTask[];
  backlog: IProjectTask[];
  uploadFiles: IInitialsUploadFiles;
  folders_doc: IFolder[];
  folders_file: IFolder[];
  tmp_copy: IFolder[];
  is_del?: number;
  is_archive?: number;
  table?: TypeElements;
  atime?: string;
  cdate?: string;
  ctime?: string;
  mtime?: string;
  dtime?: string;
}

export interface IJoinProjectsState {
  projectList: IJoinProject[];
  project: IProject;
  currentTask: IProjectTask;
  total: number;
  page: number;
  loader: boolean;
}

export const initialJoinProjectState = (): IJoinProjectsState => {
  return {
    projectList: [],
    project: null,
    currentTask: null,
    total: 0,
    page: 1,
    loader: false
  };
};
