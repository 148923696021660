import { ReactComponent as ArrowLeft } from "assets/PrivateCabinet/mail/arrow-left.svg";
import { DefaultButtonIcon } from "generalComponents/DefaultButtonIcon/DefaultButtonIcon";
import IconTooltip from "generalComponents/IconTooltip/IconTooltip";
import { useCurrentPath } from "hooks/useCurrentPath";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./NavPrevPage.module.sass";

interface INavPrevPageProps {
  title: string;
  defaultPage: string;
}

export const NavPrevPage: FC<INavPrevPageProps> = ({ title, defaultPage }): JSX.Element => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const { state, key } = useLocation();
  const isDefaultPage = useCurrentPath(defaultPage);
  const { userInfo } = useUserSelectors();

  const onClickNavPrevPage = (): void => {
    if (state?.from) {
      navigate(`/${state.from}`);
      return;
    } else if (key === "default") {
      navigate(`/${defaultPage}`);
      return;
    } else {
      navigate(-1);
      return;
    }
  };

  return (
    <div className={styles.root}>
      <button type="button" onClick={onClickNavPrevPage} className={styles.btn} title={title} disabled={isDefaultPage}>
        {!isDefaultPage && (
          <DefaultButtonIcon className={styles.defaultBtnIcon}>
            <ArrowLeft className={styles.icon} />
          </DefaultButtonIcon>
        )}
        <span className={styles.btn__label}>{title}</span>
        {isDefaultPage && userInfo.is_admin === 0 && userInfo.rights.IS_MGR_LIBRARY && (
          <IconTooltip bgColor="orange" text={__("У вас есть административный доступ к этому разделу")}>
            <span className={styles.admin}>Admin</span>
          </IconTooltip>
        )}
      </button>
    </div>
  );
};
