import { ReactComponent as MailIcon } from "assets/icons/email.svg";
import { useActions } from "hooks/useActions";
import { ILetterInfo } from "models/store/postbox/postbox";

import styles from "./EmailIcon.module.sass";

const EmailIcon = ({ item }: { item: ILetterInfo }) => {
  const { onSetMailDashboardModal } = useActions();

  const onOpen = (): void => {
    onSetMailDashboardModal({ open: true, letter: item });
  };

  return (
    <div className={styles.container} onClick={onOpen}>
      <div className={styles.iconWrapper}>{<MailIcon />}</div>
      <div className={styles.text}>
        <div>{item.from_name}</div>
        <div>{item.text}</div>
      </div>
    </div>
  );
};

export default EmailIcon;
