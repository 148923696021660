import { ReactComponent as WarningIcon } from "assets/PrivateCabinet/new-warning.svg";
import { CountDown } from "generalComponents/CountDown/CountDown";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { useActions } from "hooks/useActions";
import { ISafe } from "models/store/safe/safe";
import React, { useEffect, useState } from "react";
import { useLocales } from "react-localized";

import styles from "./SafeBlocked.module.sass";

interface ISafeBlockedProps {
  safe: ISafe;
}

const SafeBlocked: React.FC<ISafeBlockedProps> = ({ safe }) => {
  const { __ } = useLocales();
  const { onSetSafeModal, onFetchSafes } = useActions();
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    if (safe.time_blocked) {
      const blockedEnd = new Date(safe.time_blocked).getTime();
      const time = Math.ceil((blockedEnd - Date.now()) / 1000);
      setTime(time);
    } else {
      const blockedEnd = Date.now() + 3600000;
      const time = Math.ceil((blockedEnd - Date.now()) / 1000);
      setTime(time);
    }
    onFetchSafes();
  }, []); //eslint-disable-line
  const handleCountdownComplete = (val: boolean): void => {
    if (val)
      onSetSafeModal({
        open: true,
        type: SAFE_MODALS.ENTER_SAFE_PASSWORD,
        callbackType: SAFE_CALLBACK_MODAL.FETCH_FILES,
        safe
      });
  };

  return (
    <div className={styles.wrap}>
      <WarningIcon className={styles.icon} />
      <div className={styles.body}>
        <h5 className={styles.title}>{__("Доступ ограничен")}</h5>
        <p className={styles.text}>{__("Превышен лимит попыток ввода пароля, ввод пароля станет доступен через ")}</p>
        <div className={styles.counter}>
          {time > 0 ? <CountDown time={time} onComplete={() => handleCountdownComplete(true)} /> : <p>00:00:00</p>}
        </div>
      </div>
    </div>
  );
};

export default SafeBlocked;
