export enum CONTEXT_MENU_FILE {
  NO_MODAL = "",
  CREATE_ZIP = "CreateZip",
  CUSTOMIZE_FILE = "CustomizeFile",
  COPY_LINK_SHARE = "CopyLinkShare",
  FILE_PROPERTY = "FileProperty",
  MOVE_TO_ARCHIVE = "MoveToArchive",
  DOWNLOAD_FILE = "DownloadFile",
  PRINT_FILE = "PrintFile",
  DELETE_FILE = "DeleteFile"
}

export const MAX_SIZE_FILE = 4e9;
