import { ProjectLibraryElementsList } from "containers/JoinProject/JoinProjectLibrary/components/Library/ProjectLibraryElementsList/ProjectLibraryElementsList";
import { ROUTES } from "generalComponents/variables/routing";
import { useProjectMainAccess } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

const ProjectLibrarySettingsPage = (): JSX.Element => {
  const isMainAccess = useProjectMainAccess({ or: false });

  const {
    objSearchParams: { chapter, year, month }
  } = useSelectedObjSearchParams();

  const { project } = useJoinProjectsSelectors();

  const { onGetProjectLibraryElements, onResetProjectLibraryFolders } = useActions();

  useEffect(() => {
    const controllerFolders = new AbortController();

    onResetProjectLibraryFolders();
    onGetProjectLibraryElements(controllerFolders);

    return () => {
      controllerFolders.abort();
    };
  }, [chapter, year, month]); //eslint-disable-line

  useEffect(() => {
    return () => {
      onResetProjectLibraryFolders();
    };
  }, []); //eslint-disable-line

  if (!isMainAccess && !project.accesses.MGR_LIBRARY) return <Navigate to={`/${ROUTES.PROJECT}`} replace />;

  return <ProjectLibraryElementsList isLinePreview />;
};

export default ProjectLibrarySettingsPage;
