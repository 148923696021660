import classNames from "classnames";
import { useMenuLibrarySettingsTabs } from "collections/library";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { MouseEvent } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import styles from "./LibrarySettingsTabs.module.sass";

export const LibrarySettingsTabs = () => {
  const tabs = useMenuLibrarySettingsTabs();

  const [tabParams] = useSearchParams();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const onSelectedTab = (e: MouseEvent<HTMLButtonElement>): void => {
    const { value } = e.currentTarget;

    const path = {
      pathname,
      search: createSearchParams({
        [SEARCH_PARAMS.TAB]: value
      }).toString(),
      replace: true
    };

    navigate(path, { state });
  };

  return (
    !!tabs?.length && (
      <div className={styles.btns}>
        {tabs.map(({ label, name, value }) => (
          <button
            key={value}
            type="button"
            onClick={onSelectedTab}
            title={label}
            aria-label={label}
            name={name}
            value={value}
            className={classNames(styles.btn, {
              [styles.active]: value === tabParams.get(name)
            })}
          >
            {label}
          </button>
        ))}
      </div>
    )
  );
};
