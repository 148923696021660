import { ISortByDateState } from "models/store/sortByDate/sortByDate";
import { IAddSortByDate } from "models/store/sortByDate/sortByDateActions";
import { INullifySortByDate } from "models/store/sortByDate/sortByDateActions";
import { SortByDateTypes } from "Store/types/sortByDate";

export const onAddSortByDate = (payload: ISortByDateState): IAddSortByDate => ({
  type: SortByDateTypes.ADD_SORT_BY_DATE,
  payload
});

export const onNullifySortByDate = (): INullifySortByDate => ({
  type: SortByDateTypes.NULLIFY_SORTS_BY_DATE
});
