import Clue from "generalComponents/Clue/Clue";
import React from "react";
import { useLocales } from "react-localized";
import { Outlet, useParams } from "react-router-dom";

import styles from "./PostboxWorkSpace.module.sass";

const PostboxWorkSpace = () => {
  const { __ } = useLocales();
  const { folderName } = useParams();

  return (
    <div className={styles.wrap}>{folderName ? <Outlet /> : <Clue text={__("Выберите папку")} icon="happy" />}</div>
  );
};

export default PostboxWorkSpace;
