import { ReactComponent as BlockIcon } from "assets/icons/block.svg";
import { ReactComponent as TariffIcon } from "assets/icons/card.svg";
import { ReactComponent as ActionIcon } from "assets/icons/clock_3.svg";
import { ReactComponent as ContactIcon } from "assets/icons/contacts.svg";
import { ReactComponent as SessionIcon } from "assets/icons/devices.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as EnterIcon } from "assets/icons/enter.svg";
import { ReactComponent as TagIcon } from "assets/icons/label.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as EmployeIcon } from "assets/icons/person_2.svg";
import { ReactComponent as MemoryIcon } from "assets/icons/segment.svg";
import { ReactComponent as ServiceIcon } from "assets/icons/service.svg";
import { ReactComponent as SettingIcon } from "assets/icons/settings.svg";
import { ReactComponent as FAQIcon } from "assets/icons/support.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Avatar from "generalComponents/Avatar/Avatar";
import { LANGUAGES, SEARCH_PARAMS, THEMES, TYPE_TABLE_ACCESS, TYPE_TOOLS } from "generalComponents/variables/global";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { PROFILE_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { useOnBlockEmployee, useOnTrashEmployee } from "hooks/employeesHooks";
import { useActions } from "hooks/useActions";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { PROFILE_MODALS } from "models/store/Cabinet/modals/profileModals";
import { EMPLOYEE_RIGHTS, IUserInfo, STATUS_COLOR, USER_STATUS } from "models/store/user/user";
import moment from "moment-timezone";
import { ReactNode } from "react";
import { useLocales } from "react-localized";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

export interface IProfileMenuItem {
  icon: ReactNode;
  name: string;
  path: PROFILE_ROUTES | string;
  isAdminLabel: boolean;
}

export interface IMemoryTools {
  name: string;
  color: string;
  value?: string;
  size: number;
  callback?: () => void;
}

export interface IListThemeSettings {
  name: string;
  value: string;
  cheked: boolean;
}

export const useProfileMenu = (user: IUserInfo): Record<string, IProfileMenuItem[]> => {
  const { __ } = useLocales();

  const getMain = (): IProfileMenuItem[] => {
    const list = [
      {
        icon: <PersonIcon width={16} height={16} />,
        name: __("Мои данные"),
        path: PROFILE_ROUTES.ABOUT_ME,
        isAdminLabel: false
      }
    ];
    if (user?.is_admin) {
      list.push({
        icon: <EmployeIcon />,
        name: __("Мои сотрудники"),
        path: PROFILE_ROUTES.EMPLOYEES,
        isAdminLabel: false
      });
    } else {
      list.push({
        icon: <EmployeIcon />,
        name: __("Мои коллеги"),
        path: PROFILE_ROUTES.EMPLOYEES,
        isAdminLabel: user.rights.IS_MGR_EMPLOYEE
      });
    }
    list.push({
      icon: <ContactIcon />,
      name: __("Контакты"),
      path: PROFILE_ROUTES.CONTACTS,
      isAdminLabel: false
    });
    if (user?.is_admin || user.rights.IS_MGR_FINANCE) {
      list.push(
        {
          icon: <ServiceIcon />,
          name: __("Мои услуги "),
          path: PROFILE_ROUTES.MY_SERVICES,
          isAdminLabel: user.rights.IS_MGR_FINANCE && !user.is_admin
        },
        {
          icon: <TariffIcon />,
          name: __("Тарифные планы"),
          path: PROFILE_ROUTES.TARIFF,
          isAdminLabel: false
        }
      );
    }
    return list;
  };

  const getSecondary = (): IProfileMenuItem[] => {
    const list = [];
    if (user.is_admin) {
      list.push({
        icon: <MemoryIcon />,
        name: __("Память"),
        path: `${PROFILE_ROUTES.MEMORY}?${SEARCH_PARAMS.CHAPTER}=${TYPE_TOOLS.FILES}`,
        isAdminLabel: false
      });
    }
    list.push(
      {
        icon: <ActionIcon />,
        name: __("Журнал"),
        path: PROFILE_ROUTES.ACTIONS_LOG,
        isAdminLabel: false
      },
      {
        icon: <SessionIcon />,
        name: __("Активные сессии"),
        path: PROFILE_ROUTES.SESSEIONS,
        isAdminLabel: false
      }
    );
    return list;
  };
  const addition1 = [
    {
      icon: <SettingIcon />,
      name: __("Настройки"),
      path: PROFILE_ROUTES.SETTINGS,
      isAdminLabel: false
    },
    {
      icon: <TagIcon />,
      name: __("Теги"),
      path: PROFILE_ROUTES.TAGS,
      isAdminLabel: false
    }
  ];
  const addition2 = [
    {
      icon: <FAQIcon />,
      name: "FAQ",
      path: `/${ROUTES.FAQ}`,
      isAdminLabel: false
    }
  ];

  return { main: getMain(), secondary: getSecondary(), addition1, addition2 };
};

export const useEmployeeRights = () => {
  const { __ } = useLocales();
  return {
    [EMPLOYEE_RIGHTS.IS_MGR_ORG]: __("Управление Орг. Структурой"),
    [EMPLOYEE_RIGHTS.IS_MGR_EMPLOYEE]: __("Управление сотрудниками"),
    [EMPLOYEE_RIGHTS.IS_MGR_FINANCE]: __("Управление Финансамы"),
    [EMPLOYEE_RIGHTS.IS_MGR_LIBRARY]: __("Управление Библиотекой"),
    [EMPLOYEE_RIGHTS.IS_MGR_PROJECT]: __("Управление Совместным проектом"),
    //
    [EMPLOYEE_RIGHTS.IS_FILES]: __("Папки/Файлы"),
    [EMPLOYEE_RIGHTS.IS_SAFE]: __("Сейф"),
    [EMPLOYEE_RIGHTS.IS_PROJECT]: __("Совместный проект"),
    [EMPLOYEE_RIGHTS.IS_MAIL]: __("Почта"),
    [EMPLOYEE_RIGHTS.IS_CHAT]: __("Чат"),
    [EMPLOYEE_RIGHTS.IS_ARCHIVE]: __("Архив"),
    [EMPLOYEE_RIGHTS.IS_LIBRARY]: __("Библиотека"),
    [EMPLOYEE_RIGHTS.IS_JOURNAL]: __("Журнал")
  };
};

export const useTransferAccess = (): Record<string, string> => {
  const { __ } = useLocales();
  return {
    IS_FILES: __("Папки/Файлы"),
    IS_TASKS: __("Задачи"),
    IS_PROJECT: __("Совместный проект"),
    IS_CALENDAR: __("Календарь"),
    IS_CHAT: __("Чат"),
    IS_MAIL: __("Почта")
  };
};

export const useTableAccess = (): Record<TYPE_TABLE_ACCESS, string> => {
  const { __ } = useLocales();

  return {
    [TYPE_TABLE_ACCESS.FOLDERS]: __("Папки"),
    [TYPE_TABLE_ACCESS.FILES]: __("Файлы"),
    [TYPE_TABLE_ACCESS.TASKS]: __("Задачи"),
    [TYPE_TABLE_ACCESS.PROJECTS]: __("Совместный проект"),
    [TYPE_TABLE_ACCESS.CALENDARS]: __("Календарь"),
    [TYPE_TABLE_ACCESS.EMAILS]: __("Почта"),
    [TYPE_TABLE_ACCESS.CHAT]: __("Чат")
  };
};

export const useEmployeeContext = () => {
  const { __ } = useLocales();
  const { onSetProfileModal } = useActions();
  const navigate = useNavigate();
  const { onDelete } = useOnTrashEmployee();
  const { onBlock } = useOnBlockEmployee();

  const onEdit = (employee: IUserInfo): IPopoverData => ({
    name: __("Редактировать"),
    img: <EditIcon />,
    isSystem: true,
    callback: () => onSetProfileModal({ open: true, type: PROFILE_MODALS.EDIT_EMPLOYEE, employee })
  });

  const activeSession = (employee: IUserInfo): IPopoverData => {
    return {
      name: __("Активные сессии"),
      img: <SessionIcon />,
      isSystem: true,
      callback: () => navigate(`/${ROUTES.PROFILE}/${PROFILE_ROUTES.SESSEIONS}?uid=${employee.uid}`)
    };
  };
  const journal = (employee: IUserInfo): IPopoverData => {
    return {
      name: __("Журнал"),
      img: <ActionIcon />,
      isSystem: true,
      callback: () => navigate(`/${ROUTES.PROFILE}/${PROFILE_ROUTES.ACTIONS_LOG}?user_id=${employee.id}`)
    };
  };

  const altEnter = (employee: IUserInfo): IPopoverData => {
    return {
      name: __("Вход под сотрудников"),
      img: <EnterIcon />,
      isSystem: true,
      callback: () => onSetProfileModal({ open: true, type: PROFILE_MODALS.EMPLOYEE_SIGNIN, employee: employee })
    };
  };

  const blockEmployee = (employee: IUserInfo): IPopoverData => {
    return {
      name: employee.is_block ? __("Разблокировать") : __("Заблокировать"),
      img: <BlockIcon />,
      isSystem: true,
      callback: () => onBlock(employee)
    };
  };

  const deleteEmployee = (employee: IUserInfo): IPopoverData => {
    return {
      name: __("Удалить"),
      img: <TrashIcon fill="#274A42" />,
      isSystem: true,
      callback: () => onDelete(employee)
    };
  };

  return {
    main: (employee: IUserInfo): IPopoverData[][] => [
      [onEdit(employee), activeSession(employee), journal(employee)],
      [altEnter(employee), blockEmployee(employee), deleteEmployee(employee)]
    ],
    own: (employee: IUserInfo): IPopoverData[] => [onEdit(employee), activeSession(employee), journal(employee)]
  };
};

export const useUserStatus = () => {
  const { __ } = useLocales();

  const userStatuses = [
    { status: USER_STATUS.ONLINE, status_color: STATUS_COLOR.ONLINE, text: "Online" },
    { status: USER_STATUS.BUSY, status_color: STATUS_COLOR.BUSY, text: __("Занят") },
    { status: USER_STATUS.MOVED_AWAY, status_color: STATUS_COLOR.MOVED_AWAY, text: __("Отошел") },
    { status: USER_STATUS.OFFLINE, status_color: STATUS_COLOR.OFFLINE, text: "Offline" }
  ];
  return { userStatuses };
};

export const useListEmployees = (): ISelectCustom[] => {
  const { employees, userInfo } = useUserSelectors();

  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  return [
    {
      icon: <Avatar name={`${userInfo.fname} ${userInfo.sname}`} size={24} imgSrc={`${projectSrc}${userInfo.icon}`} />,
      callback: () => {
        const path = {
          pathname,
          replace: true
        };

        navigate(path, { state });
      },
      name: `${userInfo.fname} ${userInfo.sname}`,
      value: userInfo.id
    },
    ...employees.map((item) => ({
      icon: <Avatar name={`${item.fname} ${item.sname}`} size={24} imgSrc={`${projectSrc}${item.icon}`} />,
      callback: () => {
        const path = {
          pathname,
          search: createSearchParams({
            [SEARCH_PARAMS.USER_ID]: item.id
          }).toString(),
          replace: true
        };

        navigate(path, { state });
      },
      name: `${item.fname} ${item.sname}`,
      value: item.id
    }))
  ];
};

export const useListMemoryTools = () => {
  const { __ } = useLocales();

  const [_, setSerchParams] = useSearchParams();

  const { userInfo } = useUserSelectors();

  return {
    [TYPE_TOOLS.FILES]: {
      name: __("Файлы/Папки"),
      color: "#1E88E5",
      value: TYPE_TOOLS.FILES,
      callback: () => setSerchParams({ [SEARCH_PARAMS.CHAPTER]: TYPE_TOOLS.FILES }),
      size: userInfo?.storage[TYPE_TOOLS.FILES]
    },
    [TYPE_TOOLS.TASKS]: {
      name: __("Задачи и Календарь"),
      color: "#34C6A2",
      value: TYPE_TOOLS.TASKS,
      callback: () => setSerchParams({ [SEARCH_PARAMS.CHAPTER]: TYPE_TOOLS.TASKS }),
      size: userInfo?.storage[TYPE_TOOLS.TASKS]
    },
    [TYPE_TOOLS.ARCHIVE]: {
      name: __("Архив"),
      color: "#FF9100",
      value: TYPE_TOOLS.ARCHIVE,
      callback: () => setSerchParams({ [SEARCH_PARAMS.CHAPTER]: TYPE_TOOLS.ARCHIVE }),
      size: userInfo?.storage[TYPE_TOOLS.ARCHIVE]
    },
    [TYPE_TOOLS.SOFT]: {
      name: __("Программы"),
      color: "#A6A0F9",
      value: TYPE_TOOLS.SOFT,
      callback: () => setSerchParams({ [SEARCH_PARAMS.CHAPTER]: TYPE_TOOLS.SOFT }),
      size: userInfo?.storage[TYPE_TOOLS.SOFT]
    },
    [TYPE_TOOLS.CHAT]: {
      name: __("Чат"),
      color: "#FF00C7",
      value: TYPE_TOOLS.CHAT,
      callback: () => setSerchParams({ [SEARCH_PARAMS.CHAPTER]: TYPE_TOOLS.CHAT }),
      size: userInfo?.storage[TYPE_TOOLS.CHAT]
    },
    [TYPE_TOOLS.SAFE]: {
      name: __("Сейф"),
      color: "#EBBA16",
      value: TYPE_TOOLS.SAFE,
      callback: () => setSerchParams({ [SEARCH_PARAMS.CHAPTER]: TYPE_TOOLS.SAFE }),
      size: userInfo?.storage[TYPE_TOOLS.SAFE]
    },
    [TYPE_TOOLS.EMAILS]: {
      name: __("Почта"),
      color: "#8D27BD",
      value: TYPE_TOOLS.EMAILS,
      callback: () => setSerchParams({ [SEARCH_PARAMS.CHAPTER]: TYPE_TOOLS.EMAILS }),
      size: userInfo?.storage[TYPE_TOOLS.EMAILS]
    },
    [TYPE_TOOLS.PROJECT]: {
      name: __("Совместный проект"),
      color: "#D94A4A",
      value: TYPE_TOOLS.PROJECT,
      callback: () => setSerchParams({ [SEARCH_PARAMS.CHAPTER]: TYPE_TOOLS.PROJECT }),
      size: userInfo?.storage[TYPE_TOOLS.PROJECT]
    },
    [TYPE_TOOLS.LIBRARY]: {
      name: __("Библиотека"),
      color: "#33A333",
      value: TYPE_TOOLS.LIBRARY,
      callback: () => setSerchParams({ [SEARCH_PARAMS.CHAPTER]: TYPE_TOOLS.LIBRARY }),
      size: userInfo?.storage[TYPE_TOOLS.LIBRARY]
    }
  };
};

export const useListThemeSettings = (): IListThemeSettings[] => {
  const { __ } = useLocales();

  const { theme } = useUserSelectors();

  return [
    {
      name: __("Синяя"),
      value: THEMES.BLUE,
      cheked: theme === THEMES.BLUE
    },
    {
      name: __("Оранжевая"),
      value: THEMES.ORANGE,
      cheked: theme === THEMES.ORANGE
    },
    {
      name: __("Бирюзовая"),
      value: THEMES.TURQUOISE,
      cheked: theme === THEMES.TURQUOISE
    },
    {
      name: __("Темная"),
      value: THEMES.DARK,
      cheked: theme === THEMES.DARK
    }
  ];
};

export const useListLanguageSettings = (): ISelectCustom[] => {
  const { __ } = useLocales();

  return [
    {
      name: __("Русский"),
      value: LANGUAGES.RU
    },
    {
      name: __("Украинский"),
      value: LANGUAGES.UK
    },
    {
      name: __("Английский"),
      value: LANGUAGES.EN
    }
  ];
};

export const useListWarningsAccountDeleting = (): string[] => {
  const { __ } = useLocales();

  return [
    __("Использование сервиса станет недоступно"),
    __("Мы будем хранить Ваши данные еще 90 дней"),
    __("Вы сможете восстановить аккаунт в течение 90 дней")
  ];
};

export const useTimeZones = () => {
  const timeZones = [
    { name: "(GMT -12:00) International Date Line West", value: "-12" },
    { name: "(GMT -11:00) Midway Island, Samoa", value: "-11" },
    { name: "(GMT -10:00) Hawaii", value: "-10" },
    { name: "(GMT -09:00) Alaska", value: "-9" },
    { name: "(GMT -08:00) Pacific Time (US & Canada)", value: "-8" },
    { name: "(GMT -07:00) Mountain Time (US & Canada)", value: "-7" },
    { name: "(GMT -06:00) Central Time (US & Canada), Mexico City", value: "-6" },
    { name: "(GMT -05:00) Eastern Time (US & Canada), Bogota, Lima", value: "-5" },
    { name: "(GMT -04:00) Atlantic Time (Canada), Caracas, La Paz", value: "-4" },
    { name: "(GMT -03:30) Newfoundland", value: "-3.5" },
    { name: "(GMT -03:00) Brazil, Buenos Aires, Georgetown", value: "-3" },
    { name: "(GMT -02:00) Mid-Atlantic", value: "-2" },
    { name: "(GMT -01:00) Azores, Cape Verde Islands", value: "-1" },
    { name: "(GMT +00:00) Western Europe Time, London, Lisbon", value: "0" },
    { name: "(GMT +01:00) Brussels, Copenhagen, Madrid, Paris", value: "1" },
    { name: "(GMT +02:00) Kiev, South Africa", value: "2" },
    { name: "(GMT +03:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "3" },
    { name: "(GMT +03:30) Tehran", value: "3.5" },
    { name: "(GMT +04:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "4" },
    { name: "(GMT +04:30) Kabul", value: "4.5" },
    { name: "(GMT +05:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "5" },
    { name: "(GMT +05:30) Bombay, Calcutta, Madras, New Delhi", value: "5.5" },
    { name: "(GMT +05:45) Kathmandu", value: "5.75" },
    { name: "(GMT +06:00) Almaty, Dhaka, Colombo", value: "6" },
    { name: "(GMT +06:30) Yangon, Cocos Islands", value: "6.5" },
    { name: "(GMT +07:00) Bangkok, Hanoi, Jakarta", value: "7" },
    { name: "(GMT +08:00) Beijing, Perth, Singapore, Hong Kong", value: "8" },
    { name: "(GMT +09:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "9" },
    { name: "(GMT +09:30) Adelaide, Darwin", value: "9.5" },
    { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "10" },
    { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "11" },
    { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "12" },
    { name: "(GMT +13:00) Nuku'alofa", value: "13" }
  ];

  const currentTimeZones = timeZones.map((zone) => {
    const currentTime = moment().utcOffset(Number(zone.value) * 60);
    const formattedTime = currentTime.format("HH:mm");
    return { ...zone, name: `${zone.name} (${formattedTime})` };
  });

  return currentTimeZones;
};
