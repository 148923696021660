import { ReactComponent as SmileIcon } from "assets/icons/smile_2.svg";
import { useLocales } from "react-localized";

import styles from "./Empty.module.sass";

const Empty = () => {
  const { __ } = useLocales();
  return (
    <div className={styles.container}>
      <SmileIcon />
      {__("Тут пока пусто")}
    </div>
  );
};

export default Empty;
