import { useCalendarDepartment } from "collections/tasks";
import TaskServicePanel from "generalComponents/TaskServicePanel/TaskServicePanel";
import { TaskFilters } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { FiltersFields, FiltersSources } from "models/store/userFilters/userFiltersPayload";
import DayView from "Pages/Cabinet/Components/Calendar/DayView/DayView";
import SidePanelView from "Pages/Cabinet/Components/Calendar/SidePanelView/SidePanelView";
import React, { useEffect } from "react";
import { useLocales } from "react-localized";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import styles from "./CalendarPage.module.sass";

const CalendarPage: React.FC = (): JSX.Element => {
  const { __ } = useLocales();

  const { onGetAllTasks, fetchUserFilters } = useActions();
  const departmentTabs = useCalendarDepartment();
  const {
    myTasks,
    filters: { type }
  } = useTasksSelectors();

  useEffect(() => {
    const isLoader = myTasks.length === 0;
    onGetAllTasks(__("Ошибка при получении задач"), isLoader);
    fetchUserFilters({
      sources: [FiltersSources.TASKS],
      fields: [FiltersFields.COLORS, FiltersFields.EMOJIS, FiltersFields.SIGNS, FiltersFields.TAGS]
    });
  }, []); //eslint-disable-line

  return (
    <div className={styles.wrap}>
      <TaskServicePanel departmentTabs={departmentTabs} />
      {type === TaskFilters.BY_DAY ? <DayView /> : <SidePanelView />}
    </div>
  );
};

export default CalendarPage;
