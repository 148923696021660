import { ReactComponent as CloseIcon } from "assets/PrivateCabinet/close.svg";
import classNames from "classnames";
import File from "generalComponents/Files";
import PropTypes from "prop-types";
import React from "react";
import { fileProps } from "types/File";

import styles from "./TaskFile.module.sass";

const TaskFile = ({ file, onDelete, amount }) => {
  return (
    <div className={styles.fileBox}>
      <File color={file?.is_write === "0" ? "#C1C1C1" : file?.color} format={file?.ext || file.name.split(".")[1]} />
      <CloseIcon className={classNames({ [styles.deleteBtn]: !amount })} onClick={onDelete} />
      {amount && <div className={styles.amount}>+{amount}</div>}
    </div>
  );
};

export default TaskFile;

TaskFile.propTypes = {
  file: fileProps,
  onDelete: PropTypes.func,
  amount: PropTypes.number
};
