import { ReactComponent as ArchiveIcon } from "assets/icons/archive_to.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info_2.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share_2.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as CrossIcon } from "assets/PrivateCabinet/cross.svg";
import { ReactComponent as UploadIcon } from "assets/PrivateCabinet/myFolders/contextMenu/upload.svg";
import { ReactComponent as CompressToZipIcon } from "assets/PrivateCabinet/myFolders/fileContextMenu/compress-to-zip.svg";
import { ReactComponent as PrintIcon } from "assets/PrivateCabinet/myFolders/fileContextMenu/print.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/myFolders/folderContextMenu/plus.svg";
import ColorPicker from "generalComponents/ColorPicker/ColorPicker";
import EmojiPicker from "generalComponents/EmojiPicker/EmojiPicker";
import { TabsType } from "generalComponents/Tabs/Tabs";
import TagSelect from "generalComponents/TagSelect/TagSelect";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { useAuthorizedSafe, useSafeFind } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { IPopoverData } from "models/generalComponents/popover/popoverOverlay";
import { PropertiesTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { ISafe } from "models/store/safe/safe";
import { IDelSafeFolder, IEditSafeFolder } from "models/store/safe/safePayloads";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

export const useContextSafe = () => {
  const { __ } = useLocales();
  const { onSetSafeModal, onCloseSafe, onResetFilesList, onSetApproveModal, onDeleteSahereSafe, onEditSafe } =
    useActions();
  const { safeId } = useParams();
  const navigate = useNavigate();

  return {
    contextMenuSafeCallback: (safe: ISafe): IPopoverData[] => [
      {
        name: __("Предоставленный доступ"),
        img: <ShareIcon />,
        isSystem: true,
        callback: (e): void => {
          e.stopPropagation();
          onSetSafeModal({ open: true, type: SAFE_MODALS.SHARED_SAFE, safe });
        }
      },
      {
        name: __("Сменить пароль?"),
        img: <LockIcon />,
        isSystem: false,
        callback: (e): void => {
          e.stopPropagation();
          onSetSafeModal({ open: true, type: SAFE_MODALS.CHANGE_PASSWORD, safe });
        }
      },
      {
        name: __("Переименовать"),
        img: <EditIcon />,
        isSystem: false,
        callback: (e): void => {
          e.stopPropagation();
          onSetSafeModal({ open: true, type: SAFE_MODALS.RENAME_SAFE, safe });
        }
      },
      {
        name: __("В Архив"),
        img: <ArchiveIcon />,
        isSystem: false,
        callback: (e): void => {
          e.stopPropagation();
          const payload = {
            id: safe.id,
            pass: safe.pass,
            is_archive: 1
          };
          const params = {
            titleHead: __("Переместить в архив"),
            title: `${__("Вы действительно хотите переместить")} <span>${safe.name}</span> в архив?`,
            text: `${__(`Вы сможете восстановить ${safe.name} с архива`)}`,
            approveBtn: __("Переместить"),
            callback: (): void => {
              onEditSafe(payload, EVENT_TYPE.ARCHIVE);
            }
          };
          onSetApproveModal({ open: true, params });
        }
      },
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        isSystem: true,
        callback: (e): void => {
          e.stopPropagation();
          onSetSafeModal({ open: true, type: SAFE_MODALS.PROPERTIES, safe });
        }
      },
      {
        name: __("Удалить"),
        isSystem: false,
        img: <TrashIcon />,
        callback: (e): void => {
          e.stopPropagation();
          onSetSafeModal({
            open: true,
            type: SAFE_MODALS.ENTER_SAFE_PASSWORD,
            callbackType: SAFE_CALLBACK_MODAL.DELETE,
            safe
          });
        }
      }
    ],
    contextMenuSafeToMeCallback: (safe: ISafe): IPopoverData[] => [
      {
        name: __("Свойства"),
        img: <InfoIcon />,
        isSystem: true,
        callback: (e): void => {
          e.stopPropagation();
          onSetSafeModal({ open: true, type: SAFE_MODALS.PROPERTIES, safe });
        }
      },
      {
        name: __("Удалить"),
        isSystem: false,
        img: <TrashIcon />,
        callback: (e): void => {
          e.stopPropagation();
          const params = {
            titleHead: __("Удалить"),
            title: `${__("Вы действительно хотите удалить")} <span>${safe.name}</span>?`,
            text: `${__(`Вы сможете восстановить ${safe.name} с корзины`)}`,
            approveBtn: __("Удалить"),
            callback: (): void => {
              onDeleteSahereSafe(safe.id);
            }
          };
          onSetApproveModal({ open: true, params });
        }
      }
    ],
    contextCloseSafe: (safe: ISafe): IPopoverData[] => [
      {
        name: __("Закрыть сейф"),
        img: <CrossIcon />,
        isSystem: true,
        callback: (e): void => {
          e.stopPropagation();
          const mesages = {
            error: __("Не удалось закрыть сейф. Попробуйте еще раз"),
            success: __("Сейф закрыт")
          };
          onCloseSafe(safe.id, mesages);
          if (safeId === safe.id) {
            onResetFilesList();
            navigate(`/${ROUTES.SAFE}`, { replace: true });
          }
        }
      }
    ]
  };
};

export const useSafeTabs = () => {
  const { __ } = useLocales();
  const [value, setValue] = useState<string>("all");
  const safeTabs: TabsType[] = [
    {
      name: __("Все"),
      onClick: () => setValue("all"),
      value: "all"
    },
    {
      name: __("Мои"),
      onClick: () => setValue("my"),
      value: "my"
    },
    {
      name: __("Чужие"),
      onClick: () => setValue("other"),
      value: "other"
    }
  ];
  return {
    safeTabs,
    value
  };
};

export const useContextSafeFile = () => {
  const { __ } = useLocales();
  const safe = useAuthorizedSafe();
  const { uid } = useUserSelectors();

  const {
    onFilePrint,
    onDownloadLink,
    onDeleteHardFiles,
    onSetApproveModal,
    onMoveFileToArchive,
    onSetCreateZipModal,
    onSetPropertiesModal,
    onSetCustomizeFileModal,
    onEditFilesLocal
  } = useActions();
  return {
    contextMenuSafeFile: (file: IFile): IPopoverData[] => [
      {
        name: __("Переименовать"),
        img: <EditIcon width={13} height={13} />,
        callback: (): void => {
          onSetCustomizeFileModal({
            open: true,
            params: file
          });
        }
      },
      {
        name: __("Переместить в архив"),
        img: <ArchiveIcon width={13} height={13} />,
        callback: (): void => {
          const payload = {
            fids: [file.fid],
            id_dir: file.id_dir
          };

          const params = {
            titleHead: __("Переместить в архив"),
            title: `${__("Вы действительно хотите переместить")} <span>${file.name}</span> в архив?`,
            text: `${__(`Вы сможете восстановить ${file.name} с архива`)}`,
            approveBtn: __("Переместить"),
            callback: (): void => {
              onMoveFileToArchive(payload, EVENT_TYPE.ARCHIVE, safe);
            }
          };

          onSetApproveModal({
            open: true,
            params
          });
        }
      },
      {
        name: __("Сжать в Zip"),
        img: <CompressToZipIcon width={13} height={13} />,
        callback: (): void => {
          onSetCreateZipModal({
            open: true,
            params: [file]
          });
        }
      },
      {
        name: __("Свойства"),
        img: <InfoIcon width={13} height={13} />,
        callback: (): void => {
          onSetPropertiesModal({
            open: true,
            type: PropertiesTypes.FILE,
            params: file
          });
        }
      },
      {
        name: __("Скачать"),
        img: <UploadIcon width={13} height={13} />,
        callback: (): void => {
          onDownloadLink(
            `/ajax/safe_file_download.php?&uid=${uid}&fid=${file.fid}&code=${safe.code}&pass=${safe.pass}&id_safe=${safe.id_safe}`
          );
        }
      },
      {
        name: undefined,
        component: <TagSelect onChange={onEditFilesLocal} item={file} />,
        callback: undefined
      },
      {
        name: undefined,
        component: <ColorPicker onColorClick={onEditFilesLocal} item={file} />,
        callback: undefined
      },
      {
        name: undefined,
        component: <EmojiPicker onEmojiClick={onEditFilesLocal} item={file} />,
        callback: undefined
      },
      {
        name: __("Напечатать файл"),
        img: <PrintIcon width={13} height={13} />,
        callback: (): void => {
          onFilePrint({
            open: true,
            url: `https://fs2.mh.net.ua/ajax/safe_file_preview.php?&uid=${uid}&fid=${file.fid}&code=${safe.code}&pass=${safe.pass}&id_safe=${safe.id_safe}`,
            fid: file?.fid
          });
        }
      },
      {
        name: __("Удалить"),
        img: <TrashIcon width={13} height={13} />,
        callback: (): void => {
          const params = {
            titleHead: __("Удалить"),
            title: `${__("Вы действительно хотите удалить")} <span>${file.name}</span>?`,
            text: `${__(`Вы сможете восстановить ${file.name} с корзины`)}`,
            approveBtn: __("Удалить"),
            approveBtnVariant: ButtonVariantType.OK_RED,
            callback: (): void => {
              const payload = {
                fids: [file.fid],
                id_dir: file.id_dir
              };

              onDeleteHardFiles(payload, safe);
            }
          };

          onSetApproveModal({
            open: true,
            params
          });
        }
      }
    ],
    contextMenuSafeFileLines: (file: IFile): IPopoverData[] => [
      {
        name: __("Переименовать"),
        img: <EditIcon width={13} height={13} />,
        callback: (): void => {
          onSetCustomizeFileModal({
            open: true,
            params: file
          });
        }
      },
      {
        name: __("Сжать в Zip"),
        img: <CompressToZipIcon width={13} height={13} />,
        callback: (): void => {
          onSetCreateZipModal({
            open: true,
            params: [file]
          });
        }
      },
      {
        name: __("Свойства"),
        img: <InfoIcon width={13} height={13} />,
        callback: (): void => {
          onSetPropertiesModal({
            open: true,
            type: PropertiesTypes.FILE,
            params: file
          });
        }
      },
      {
        name: __("Скачать"),
        img: <UploadIcon width={13} height={13} />,
        callback: (): void => {
          onDownloadLink(
            `/ajax/safe_file_download.php?&uid=${uid}&fid=${file.fid}&code=${safe.code}&pass=${safe.pass}&id_safe=${safe.id_safe}`
          );
        }
      },
      {
        name: undefined,
        component: <TagSelect onChange={onEditFilesLocal} item={file} />,
        callback: undefined
      },
      {
        name: undefined,
        component: <ColorPicker onColorClick={onEditFilesLocal} item={file} />,
        callback: undefined
      },
      {
        name: undefined,
        component: <EmojiPicker onEmojiClick={onEditFilesLocal} item={file} />,
        callback: undefined
      }
    ],
    contextMenuSafeFileToMe: (file: IFile): IPopoverData[] => [
      {
        name: __("Переименовать"),
        img: <EditIcon width={13} height={13} />,
        callback: (): void => {
          onSetCustomizeFileModal({
            open: true,
            params: file
          });
        }
      },

      {
        name: __("Сжать в Zip"),
        img: <CompressToZipIcon width={13} height={13} />,
        callback: (): void => {
          onSetCreateZipModal({
            open: true,
            params: [file]
          });
        }
      },
      {
        name: __("Свойства"),
        img: <InfoIcon width={13} height={13} />,
        callback: (): void => {
          onSetPropertiesModal({
            open: true,
            type: PropertiesTypes.FILE,
            params: file
          });
        }
      },
      {
        name: __("Скачать"),
        img: <UploadIcon width={13} height={13} />,
        callback: (): void => {
          onDownloadLink(
            `/ajax/safe_file_download.php?&uid=${uid}&fid=${file.fid}&code=${safe.code}&pass=${safe.pass}&id_safe=${safe.id_safe}`
          );
        }
      },
      {
        name: __("Напечатать файл"),
        img: <PrintIcon width={13} height={13} />,
        callback: (): void => {
          onFilePrint({
            open: true,
            url: `https://fs2.mh.net.ua/ajax/safe_file_preview.php?&uid=${uid}&fid=${file.fid}&code=${safe.code}&pass=${safe.pass}&id_safe=${safe.id_safe}`,
            fid: file?.fid
          });
        }
      }
    ]
  };
};

export const useContextSafeFolder = () => {
  const { __ } = useLocales();
  const {
    onSetSafeModal,
    onDelSafeFolder,
    onEditSafeFolder,
    onSetApproveModal,
    fetchFolderZipLink,
    onSetCreateZipModal,
    onSetPropertiesModal
  } = useActions();

  const safe = useSafeFind();
  const authorizedSafe = useAuthorizedSafe();

  return {
    contextMenuSafeFolderCallback: (folder: IFolder): IPopoverData[] => [
      {
        name: __("Добавить папку"),
        img: <PlusIcon width={13} height={13} />,
        isSystem: true,
        callback: (): void => {
          onSetSafeModal({ open: true, type: SAFE_MODALS.CREATE_FOLDER, folder, safe });
        }
      },
      {
        name: __("Переименовать папку"),
        img: <EditIcon width={13} height={13} />,
        isSystem: true,
        callback: (): void => {
          onSetSafeModal({ open: true, type: SAFE_MODALS.RENAME_FOLDER, folder, safe });
        }
      },
      {
        name: __("Переместить в архив"),
        img: <ArchiveIcon width={13} height={13} />,
        isSystem: true,
        callback: (): void => {
          const payload: IEditSafeFolder = {
            ...authorizedSafe,
            id_dirs: [folder.id_dir],
            idParent: folder.id_parent,
            name: folder.name,
            is_archive: "1"
          };

          const params = {
            titleHead: __("Переместить в архив"),
            title: `${__("Вы действительно хотите переместить")} <span>${folder.name}</span> в архив?`,
            text: `${__(`Вы сможете восстановить ${folder.name} с архива`)}`,
            approveBtn: __("Переместить"),
            callback: (): void => {
              onEditSafeFolder(payload, EVENT_TYPE.ARCHIVE);
            }
          };

          onSetApproveModal({
            open: true,
            params
          });
        }
      },
      {
        name: __("Свойства"),
        img: <InfoIcon width={13} height={13} />,
        isSystem: true,
        callback: () => {
          onSetPropertiesModal({
            open: true,
            type: PropertiesTypes.FOLDER,
            params: folder
          });
        }
      },
      {
        name: __("Сжать в Zip"),
        img: <CompressToZipIcon width={13} height={13} />,
        isSystem: true,
        callback: () => {
          onSetCreateZipModal({
            open: true,
            params: [folder]
          });
        }
      },
      {
        name: __("Скачать"),
        img: <UploadIcon width={13} height={13} />,
        isSystem: false,
        callback: (): void => {
          fetchFolderZipLink(folder.id_dir, authorizedSafe);
        }
      },
      {
        name: __("Удалить"),
        img: <TrashIcon width={13} height={13} />,
        isSystem: false,
        callback: (): void => {
          const payload: IDelSafeFolder = {
            id_safe: safe.id,
            code: authorizedSafe.code,
            id_dirs: [folder.id_dir],
            pass: authorizedSafe.pass,
            id_parent: folder.id_parent
          };

          const params = {
            title: __("Удалить"),
            text: __("Вы действительно хотите удалить папку?"),
            approveBtn: __("Удалить"),
            callback: (): void => {
              onDelSafeFolder(payload);
            }
          };

          onSetApproveModal({
            open: true,
            params
          });
        }
      }
    ],
    contextMenuSafeFolderToMeCallback: (folder: IFolder): IPopoverData[] => [
      {
        name: __("Добавить папку"),
        img: <PlusIcon width={13} height={13} />,
        isSystem: true,
        callback: (): void => {
          onSetSafeModal({ open: true, type: SAFE_MODALS.CREATE_FOLDER, folder, safe });
        }
      },
      {
        name: __("Переименовать папку"),
        img: <EditIcon width={13} height={13} />,
        isSystem: true,
        callback: (): void => {
          onSetSafeModal({ open: true, type: SAFE_MODALS.RENAME_FOLDER, folder, safe });
        }
      },

      {
        name: __("Свойства"),
        img: <InfoIcon width={13} height={13} />,
        isSystem: true,
        callback: () => {
          onSetPropertiesModal({
            open: true,
            type: PropertiesTypes.FOLDER,
            params: folder
          });
        }
      },
      {
        name: __("Скачать"),
        img: <UploadIcon width={13} height={13} />,
        isSystem: false,
        callback: (): void => {
          fetchFolderZipLink(folder.id_dir, authorizedSafe);
        }
      }
    ]
  };
};
