import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import { ReactComponent as SmileIcon } from "assets/icons/smile_2.svg";
import classNames from "classnames";
import JointProjectsTaskCard from "containers/JoinProject/JointProjectsTasks/components/JointProjectsTaskCard/JointProjectsTaskCard";
import ParticipantsButtons from "containers/JoinProject/JointProjectsTasks/components/ParticipantsButtons/ParticipantsButtons";
import TeamButtons from "containers/JoinProject/JointProjectsTasks/components/TeamButtons/TeamButtons";
import { IExtendsProjectTask } from "containers/JoinProject/JointProjectsTasks/JointProjectsTasks";
import Input from "generalComponents/Input/Input";
import { PROJECT_PATH } from "generalComponents/variables/routing";
import { IProjectTask } from "models/store/joinProjects/joinProgects";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useLocation } from "react-router";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectsBacklogSidebar.module.sass";

const JointProjectsBacklogSidebar = ({
  showBacklog,
  dragStartHandler,
  dragEndHandler
}: {
  showBacklog: boolean;
  dragStartHandler: (e: React.DragEvent<HTMLDivElement>, item: IExtendsProjectTask) => void;
  dragEndHandler: (e: React.DragEvent<HTMLDivElement>) => void;
}) => {
  const { project } = useJoinProjectsSelectors();
  const { __ } = useLocales();
  const location = useLocation();
  const [activeTeam, setActiveTeam] = useState("");
  const [activeParticipiants, setActiveParticipiants] = useState<string[]>([]);
  const [filteredBacklog, setFilteredBacklog] = useState<IProjectTask[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const onSelectParticipiant = (id: string) => {
    if (activeParticipiants.includes(id)) {
      const updatedParticipants = activeParticipiants.filter((participantId) => participantId !== id);
      setActiveParticipiants(updatedParticipants);
    } else {
      setActiveParticipiants((prev) => [...prev, id]);
    }
  };

  useEffect(() => {
    let updatedBacklog = project.backlog;

    if (activeTeam) {
      const team = project.teams.find((team) => team.id === activeTeam);
      if (team) {
        const teamUserIds = team.users.map((userId) => userId);
        updatedBacklog = updatedBacklog.filter((item) => teamUserIds.includes(item.id_executor));
      }
    }

    if (activeParticipiants?.length > 0) {
      updatedBacklog = updatedBacklog.filter((item) => activeParticipiants.includes(item.id_executor));
    }

    if (searchQuery.trim() !== "") {
      updatedBacklog = updatedBacklog.filter(
        (item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.num.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredBacklog(updatedBacklog);
  }, [project.backlog, searchQuery, activeParticipiants, activeTeam, project.teams]);

  const toggleActiveTeam = (id: string) => {
    setActiveTeam((prev) => (prev === id ? "" : id));
  };

  const isMyTasksPage = location.pathname.includes(PROJECT_PATH.MY_TASKS);
  return (
    <div
      className={classNames(
        styles.sidebar,
        isMyTasksPage && styles.sidebarMyTasksPage,
        showBacklog && styles.showSidebar
      )}
    >
      <div>
        <Input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
          placeholder={__("Введите")}
          rightIcon={<SearchIcon fill="var(--color-d100)" />}
        />
        <div className={classNames(styles.teamTabs, "scrollbar-medium-blue")}>
          <TeamButtons selectedTeam={activeTeam} onSelectTeam={toggleActiveTeam} />
        </div>
        <div className={classNames(styles.teamTabs, "scrollbar-medium-blue")}>
          <ParticipantsButtons
            onSelectParticipiant={onSelectParticipiant}
            teamId={activeTeam}
            activeParticipiants={activeParticipiants}
          />
        </div>
      </div>
      <div className={styles.sidebarScroll}>
        {filteredBacklog.length > 0 ? (
          filteredBacklog.map((item) => (
            <div
              key={item.id}
              className={styles.sidebarItem}
              draggable={true}
              onDragEnd={dragEndHandler}
              onDragStart={(e) => dragStartHandler(e, { ...item, x: 0, y: 0, w: 1, h: 40, i: item.id })}
            >
              <JointProjectsTaskCard item={item} />
            </div>
          ))
        ) : (
          <div className={styles.emptySidebar}>
            <SmileIcon />
            {__("Тут пока пусто")}
          </div>
        )}
      </div>
    </div>
  );
};

export default JointProjectsBacklogSidebar;
