import classNames from "classnames";
import { IButtonIconProps } from "models/generalComponents/buttonIcon";
import React from "react";

import styles from "./ButtonIcon.module.sass";

const ButtonIcon: React.FC<IButtonIconProps> = ({
  handleClick,
  icon,
  variant,
  children,
  notHover,
  isStroke,
  isActive,
  disabled,
  height,
  tooltip
}): JSX.Element => (
  <div className={classNames(styles.root, styles[variant])}>
    <button
      type="button"
      onClick={handleClick}
      className={classNames(styles.button, {
        [styles.notHover]: notHover,
        [styles.isStroke]: isStroke,
        [styles.isActive]: isActive
      })}
      style={{ height }}
      disabled={disabled}
      title={tooltip}
    >
      {icon || children}
    </button>
  </div>
);

export default ButtonIcon;
