import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { PROJECT_ADMIN_ROLES, PROJECT_MODALS } from "generalComponents/variables/project";
import UserBlock from "Pages/Cabinet/Components/Project/Components/UserBlock/UserBlock";
import { getNames, useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onEditProject } from "Store/actions/JoinProjectActions";
import { onEditUser } from "Store/actions/ProjectActions";

import styles from "../AddUsers.module.sass";

const ChooseAdmin = ({ onClose, type }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const MSG = useProjectMsg();
  const theme = useSelector((s) => s.user.userInfo.theme);
  const project = useSelector((s) => s.Projects.project);
  const userInfo = useSelector((s) => s.user.userInfo);

  const [value, setValue] = useState("");
  const [chosenUser, setChosenUser] = useState(null);
  const [chosenUsers, setChosenUsers] = useState([]);

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  const isChosen = (item) => {
    if (type === PROJECT_MODALS.CHOOSE_SUBADMIN) {
      return chosenUsers.some((el) => el.id_user === item.id_user);
    }
    return chosenUser?.id_user === item.id_user;
  };

  const onSelectUser = (item) => {
    if (type === PROJECT_MODALS.CHOOSE_SUBADMIN) {
      if (isChosen(item)) {
        return setChosenUsers((prev) => prev.filter((el) => el.id_user !== item.id_user));
      } else {
        return setChosenUsers((prev) => [...prev, item]);
      }
    }
    setChosenUser((prev) => (prev?.id_user !== item.id_user ? item : null));
  };

  const filtredUsers = useMemo(() => {
    return project.users.filter(
      (el) => el.id_user !== userInfo.id_user && el?.name.toLowerCase().includes(value.toLowerCase())
    );
  }, [value]); //eslint-disable-line

  const onSubmitAdmin = () => {
    const params = [
      {
        ...chosenUser,
        is_read: PROJECT_ADMIN_ROLES.ADMIN,
        id_project: project.id
      }
    ];
    dispatch(onEditUser(params, { error: MSG.ERROR }, PROJECT_MODALS.ADDED_ADMIN));
  };

  const onSubmitSubAdmin = () => {
    const params = chosenUsers.map((user) => ({
      ...user,
      id_project: project.id,
      is_read: PROJECT_ADMIN_ROLES.SUBADMIN
    }));
    const messages = {
      error: MSG.ERROR,
      success: {
        title: __("Заместитель администратора успешно добавлен"),
        text: `${__("Вы добавили администратора проекта ")} “${project.name}”.
          ${__("Права доступа предоставлены ")} ${getNames(chosenUsers)}`
      }
    };
    dispatch(onEditUser(params, messages, PROJECT_MODALS.PROJECT_SUCCESS));
    dispatch(onEditProject({ id: project.id, show_message: PROJECT_ADMIN_ROLES.SUBADMIN }, { error: MSG.ERROR }));
  };

  const toInviteUser = () => {};

  return (
    <div className={styles.wrap}>
      <span className={styles.subTitle}>{__("Введите имя для посика из списка")}</span>
      <input className={styles.input} value={value} onChange={handleChange} />
      {type === PROJECT_MODALS.CHOOSE_SUBADMIN ? (
        <div className={styles.chosenUsers}>
          {chosenUsers.length > 0 && chosenUsers.map((el) => <UserBlock key={el.id_user} user={el} />)}
        </div>
      ) : (
        <div className={styles.chosenUsers}>{chosenUser && <UserBlock user={chosenUser} />}</div>
      )}

      <span className={styles.subTitle}>{__("Выберите из списка")}</span>
      <div className={classNames(styles.usersList, `scrollbar-medium-${theme}`)}>
        {filtredUsers.map((el) => (
          <div key={el.id_user} onClick={() => onSelectUser(el)}>
            <UserBlock user={el} isChosen={isChosen(el)} />
          </div>
        ))}
      </div>
      <div className={styles.btns}>
        <Button type="button" variant="cancel" text={__("Отмена")} onClick={onClose} />
        {type === PROJECT_MODALS.CHOOSE_SUBADMIN ? (
          <Button
            type="button"
            variant="ok"
            text={__("Назначить")}
            onClick={onSubmitSubAdmin}
            disabled={chosenUsers.length === 0}
          />
        ) : (
          <Button type="button" variant="ok" text={__("Назначить")} onClick={onSubmitAdmin} disabled={!chosenUser} />
        )}
      </div>
      <p className={styles.text}>
        {__("Пригласить нововго пользователя в проект, для отправки приглошение нажмите на кнопку пригласить")}
      </p>
      <div className={styles.btns}>
        <Button type="button" variant="ok" text={__("Пригласить")} onClick={toInviteUser} />
      </div>
    </div>
  );
};

export default ChooseAdmin;

ChooseAdmin.propTypes = {
  onClose: PropTypes.func,
  type: PropTypes.string
};
