import BottomMenuOption from "generalComponents/BottomMenuOption/BottomMenuOption";
import ElementsList from "generalComponents/ElementsList/ElementsList";
import FileServicePanel from "generalComponents/FilesServicePanel/FilesServicePanel";
import RecentFiles from "generalComponents/RecentFiles/RecentFiles";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams, useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useRecentFilesSelectors } from "Store/selectors/recentFilesSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./FilesPage.module.sass";

const FilesPage = () => {
  const navigate = useNavigate();
  const { rights } = useUserSelectors();
  const {
    onGetAllFiles,
    onNullifyFiles,
    onGetMyFolders,
    fetchRecentFiles,
    onSetCurrentItem,
    onResetFilesList,
    onNullifyUserFilters,
    onNullifyFilterCriteria
  } = useActions();
  const { pickedItems } = useFilesSelectors();
  const { recentFiles } = useRecentFilesSelectors();

  const {
    objSearchParams: { group, sort, sort_reverse }
  } = useSelectedObjSearchParams();

  const { color, emo, tags } = useAllSelectedSearchParams();

  useEffect(() => {
    const controllerFiles = new AbortController();

    onResetFilesList();
    onSetCurrentItem(null);
    onGetAllFiles(controllerFiles);

    return () => controllerFiles.abort();
  }, [color.selectedValue, emo.selectedValue, tags.selectedValue?.length, group, sort, sort_reverse]); //eslint-disable-line

  useEffect(() => {
    if (!rights.IS_FILES) {
      navigate("/", { replace: true });
      return;
    }
    const controllerFolders = new AbortController();
    fetchRecentFiles();
    onGetMyFolders(controllerFolders);

    return () => {
      onNullifyFiles();
      onNullifyUserFilters();
      onNullifyFilterCriteria();
      controllerFolders.abort();
    };
  }, []); //eslint-disable-line

  return (
    <div className={styles.workAreaWrap}>
      {recentFiles.length > 0 && <RecentFiles />}
      <FileServicePanel />
      <ElementsList />
      {pickedItems.open && <BottomMenuOption />}
    </div>
  );
};

export default FilesPage;
