import { ReactComponent as HeartIcon } from "assets/PrivateCabinet/heart.svg";
import { ReactComponent as RocketIcon } from "assets/PrivateCabinet/rocket.svg";
import classNames from "classnames";
import ProgramSearch from "containers/Programs/ProgramSearch/ProgramSearch";
import Clue from "generalComponents/Clue/Clue";
import List from "generalComponents/List/List";
import { PROGRAMS_ROUTES } from "generalComponents/variables/routing";
import { useIsFavoriteProgram } from "hooks/programHooks";
import { useActions } from "hooks/useActions";
import React, { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { useProgramsSelectors } from "Store/selectors/programsSelectors";

import styles from "./ProgramsPage.module.sass";

const ProgramsPage: React.FC = () => {
  const { __ } = useLocales();
  const { programId } = useParams();
  const { onGetCategories } = useActions();
  const { categories } = useProgramsSelectors();
  const isFavorite = useIsFavoriteProgram();
  const [listCollapsed, setListCollapsed] = useState(false);

  useEffect(() => {
    const messageError = __("Не удалось получить программы! Попробуйте еще раз!");
    const isLoader = categories.length === 0;

    onGetCategories(messageError, isLoader);
  }, []); // eslint-disable-line

  return (
    <div className={styles.workAreaWrap}>
      <List
        leftIcon={<RocketIcon />}
        title={__("Программы")}
        setListCollapsed={setListCollapsed}
        listCollapsed={listCollapsed}
        onCreate={() => {}}
      >
        {!listCollapsed && <ProgramSearch />}
        <ul>
          <li>
            <NavLink
              to={PROGRAMS_ROUTES.FAVORITE}
              className={({ isActive }) => {
                return classNames(styles.category, {
                  [styles.active]: isActive,
                  [styles.menuItemCollapsed]: listCollapsed
                });
              }}
              title={listCollapsed ? __("Избранные программы") : undefined}
            >
              <div className={styles.imgBox}>
                <HeartIcon width={34} height={34} />
              </div>
              <span className={styles.name}>{__("Избранные программы")}</span>
            </NavLink>
          </li>
          {categories.map((category) => (
            <li key={category.id}>
              <NavLink
                to={category.id}
                className={({ isActive }) => {
                  return classNames(styles.category, {
                    [styles.active]: isActive,
                    [styles.menuItemCollapsed]: listCollapsed
                  });
                }}
                title={listCollapsed ? category.name : undefined}
              >
                <div className={styles.imgBox}>
                  <img src={category.icon} alt="logo" width={34} height={34} />
                </div>
                <span className={styles.name}>{category.name}</span>
                <span className={styles.amount}>({category.count})</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </List>
      {isFavorite || programId ? <Outlet /> : <Clue icon="happy" text={__("Выберите раздел")} />}
    </div>
  );
};

export default ProgramsPage;
