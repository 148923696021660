import React, { useLayoutEffect, useMemo } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { ProjectTypes } from "Store/types/projectTypes";

import Role from "../Components/Role/Role";
import UserProject from "../ProjectTasks/ProjectUsers/UserProject/UserProject";
import Tasks from "../ProjectTasks/Tasks/Tasks";
import styles from "./ProjectMyTasks.module.sass";

const ProjectMyTasks = () => {
  const { __ } = useLocales();
  const dispatch = useDispatch();

  const user = useSelector((s) => s.user.userInfo);
  const project = useSelector((s) => s.Projects.project);
  const currentUser = useMemo(
    () => project.users.find((u) => u.id_user === user.id_user),
    [project.users, user.id_user]
  );

  const role = project.roles.find((r) => r.id === currentUser.id_role);
  useLayoutEffect(() => {
    dispatch({ type: ProjectTypes.SELECT_ROLE, payload: role ? role.id : "0" });
    dispatch({ type: ProjectTypes.SELECT_USER, payload: user.id_user });
    return () => {
      dispatch({ type: ProjectTypes.SELECT_ROLE, payload: "0" });
      dispatch({ type: ProjectTypes.SELECT_USER, payload: "0" });
    };
  }, []); //eslint-disable-line

  return (
    <>
      <div className={styles.block}>
        {role ? <Role role={role} /> : <div className={styles.role}>{__("Роль не назначена")}</div>}
      </div>

      <div className={styles.block}>
        <UserProject user={currentUser} />
      </div>
      <Tasks />
    </>
  );
};

export default ProjectMyTasks;
