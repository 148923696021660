import { ViewType } from "generalComponents/variables/global";
import { ISetSize, ISetWorkElementsView } from "models/store/files/sort/sortActions";
import { SizeTypes } from "models/store/files/sort/sortPayloads";
import { SortTypes } from "Store/types/sortTypes";

export const onSetFileSize = (payload: SizeTypes): ISetSize => ({
  type: SortTypes.SET_SIZE,
  payload
});

export const onSetWorkElementsView = (payload: ViewType): ISetWorkElementsView => ({
  type: SortTypes.SET_WORK_ELEMENTS_VIEW,
  payload
});
