import classNames from "classnames";
import { useContextMenuMail } from "collections/tasks";
import FileTags from "generalComponents/FileTags/FileTags";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { createMarkup } from "generalComponents/Services/browserServices";
import { TASK_MODALS, TASK_TYPES, TASKS_SCHEMA } from "generalComponents/variables/tasks";
import { useTaskSatus } from "hooks/tasksHooks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { IMailItemProps } from "models/tasks/myTasks";
import React, { useContext, useEffect, useRef } from "react";
import { useLocales } from "react-localized";

import { MyTasksProvider } from "../../../../../../containers/Tasks/GridBoard/GridBoard";
import ContextButtons from "../ContextButtons/ContextButtons";
import StatusesButtons from "../StatusesButtons/StatusesButtons";
import TaskStatusSelect from "../TaskStatusSelect/TaskStatusSelect";
import styles from "./MailItem.module.sass";

const MailItem: React.FC<IMailItemProps> = ({ task, isFull = false }) => {
  const { __ } = useLocales();
  const status = useTaskSatus(TASK_TYPES.MAILS);
  const contextMenu = useContextMenuMail();
  const { formatDate } = useDateFormat();
  const { onSetTaskModal } = useActions();

  const refContainer = useRef<HTMLDivElement>(null);
  const refStatuses = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refContainer && refStatuses) {
      const toLeft = refStatuses.current.clientWidth + 5;
      refContainer.current.scrollLeft = toLeft;
    }
  }, []);

  const { schema } = useContext(MyTasksProvider);
  const isLarge: boolean = schema === TASKS_SCHEMA.EXPANDED_MAIL_BOARD || isFull;

  const onOpen = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.OPEN_TASK,
      params: task
    });
  };

  return (
    <div className={classNames(styles.container, { [styles.fullContainer]: isLarge })} ref={refContainer}>
      <div className={styles.additions} ref={refStatuses}>
        <StatusesButtons task={task} />
      </div>
      <div className={classNames(styles.mailBox, { [styles.full]: isLarge })} onClick={onOpen}>
        <div className={styles.top}>
          <div className={styles.time}>{formatDate(task.date_start, "time")}</div>
          {!isLarge && (
            <div className={styles.block}>
              {task.color && <div className={styles.color} style={{ background: task.color }} />}
              {task.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: task.emo }} />}
              <PopoverContextMenu data={contextMenu(task)} title={__("Действия / Статус")} />
            </div>
          )}
        </div>

        <p className={styles.subtitle}>
          {__("Получатель:")}&nbsp;
          <span className={styles.email}>{task.emails}</span>
        </p>
        {isLarge && <p className={styles.subtitle}>{__("Текст письма:")}</p>}
        <div className={classNames("htmlContent", styles.text)} dangerouslySetInnerHTML={createMarkup(task.prim)} />
        {isLarge && (
          <>
            {task.tags.length > 0 && isLarge && <FileTags item={task} />}
            {task.color && <div className={styles.color} style={{ background: task.color }} />}
            {task.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: task.emo }} />}
            <TaskStatusSelect task={task} />
          </>
        )}
        {isLarge && <PopoverContextMenu data={contextMenu(task)} title={__("Действия / Статус")} />}
        <div className={styles.iconStatus}>{status(task.id_status)?.img}</div>
      </div>
      <div className={styles.additions}>
        <ContextButtons task={task} />
      </div>
    </div>
  );
};

export default MailItem;
