import { initialProjectsState, IProjectsState, ProjectAction } from "models/store/projects/projectStore";

import { ProjectTypes } from "../types/projectTypes";

const INITIAL_STATE: IProjectsState = initialProjectsState();

export const ProjectReducer = (state = INITIAL_STATE, action: ProjectAction): IProjectsState => {
  switch (action.type) {
    case ProjectTypes.GET_PROJECTS:
      return { ...state, projectsList: action.payload };
    case ProjectTypes.NULLIFY_PROJECT: {
      return {
        ...state,
        selectUser: "0",
        selectRole: "0",
        selectPriority: false,
        selectUrgency: false,
        tasksTrash: [],
        links: [],
        docs: []
      };
    }

    case ProjectTypes.ADD_PROJECT:
      return { ...state, projectsList: [...state.projectsList, action.payload] };

    case ProjectTypes.DELETE_PROJECT:
      return {
        ...state,
        projectsList: state.projectsList.filter((item) => item.id !== action.payload),
        project: INITIAL_STATE.project
      };

    case ProjectTypes.CURRENT_PROJECT: {
      return {
        ...state,
        tasksTrash: [],
        project: { ...action.payload, tasks: action.payload.tasks ?? [] }
      };
    }

    case ProjectTypes.EDIT_PROJECT: {
      const newList = [...state.projectsList];
      const index = newList.findIndex((item) => item.id === action.payload.id);
      newList.splice(index, 1, action.payload);

      return { ...state, projectsList: newList, project: { ...state.project, ...action.payload } };
    }

    case ProjectTypes.ADD_USER: {
      const newProject = { ...state.project, users: [...state.project.users, ...action.payload] };
      const newList = [...state.projectsList];
      const index = newList.findIndex((item) => item.id === state.project.id);
      newList.splice(index, 1, newProject);
      return { ...state, projectsList: newList, project: newProject };
    }

    case ProjectTypes.DELETE_USER: {
      const newProject = {
        ...state.project,
        users: state.project.users.filter((user) => user.id_user !== action.payload)
      };
      const newList = [...state.projectsList];
      const index = newList.findIndex((item) => item.id === state.project.id);
      newList.splice(index, 1, newProject);
      return { ...state, projectsList: newList, project: newProject };
    }

    case ProjectTypes.EDIT_USER: {
      const users = state.project.users.map((u) => {
        const idx = action.payload.findIndex((i) => i.id_user === u.id_user);
        return idx >= 0 ? action.payload[idx] : u;
      });
      const project = { ...state.project, users };
      const projectsList = [...state.projectsList];
      const index = projectsList.findIndex((item) => item.id === state.project.id);
      projectsList.splice(index, 1, project);
      return { ...state, projectsList, project };
    }

    case ProjectTypes.UPDATE_TASKS: {
      const project = { ...state.project, tasks: action.payload };
      const projectsList = [...state.projectsList];
      const index = projectsList.findIndex((item) => item.id === state.project.id);
      projectsList.splice(index, 1, project);
      return { ...state, projectsList, project };
    }

    case ProjectTypes.ADD_CATEGORY: {
      const project = { ...state.project, tasks_category: [...state.project.tasks_category, action.payload] };
      const projectsList = [...state.projectsList];
      const index = projectsList.findIndex((item) => item.id === state.project.id);
      projectsList.splice(index, 1, project);
      return { ...state, projectsList, project };
    }

    case ProjectTypes.EDIT_CATEGORY: {
      const newCategories = [...state.project.tasks_category];
      const idx = newCategories.findIndex((item) => item.id === action.payload.id);
      newCategories.splice(idx, 1, action.payload);
      const newProject = { ...state.project, tasks_category: newCategories };
      const newList = [...state.projectsList];
      const index = newList.findIndex((item) => item.id === state.project.id);
      newList.splice(index, 1, newProject);
      return { ...state, projectsList: newList, project: newProject };
    }

    case ProjectTypes.SELECT_ROLE: {
      return { ...state, selectRole: action.payload, selectUser: "0" };
    }

    case ProjectTypes.SELECT_USER: {
      return { ...state, selectUser: action.payload };
    }

    case ProjectTypes.MOVE_CATEGORY: {
      return { ...state, project: { ...state.project, tasks_category: action.payload } };
    }

    case ProjectTypes.DELETE_CATEGORY: {
      const project = {
        ...state.project,
        tasks_category: state.project.tasks_category.filter((el) => el.id !== action.payload)
      };
      return { ...state, project };
    }

    case ProjectTypes.FETCH_TASKS_TRASH:
      return { ...state, tasksTrash: action.payload };

    case ProjectTypes.RESTORE_TASK: {
      const project = { ...state.project, tasks: action.payload.project_tasks };
      const tasksTrash = state.tasksTrash.filter((item) => !action.payload.restore_tasks.includes(item.id));
      return { ...state, project, tasksTrash };
    }

    case ProjectTypes.DELETE_PROJECT_TASK: {
      const tasksTrash = state.tasksTrash.filter((task) => {
        return !action.payload.includes(task.id);
      });
      return { ...state, tasksTrash };
    }

    case ProjectTypes.CLEAR_TRASH: {
      return { ...state, tasksTrash: [] };
    }

    case ProjectTypes.SELECT_URGENCY: {
      return { ...state, selectUrgency: action.payload };
    }

    case ProjectTypes.SELECT_PRIORITY: {
      return { ...state, selectPriority: action.payload };
    }

    case ProjectTypes.GET_LINKS: {
      return { ...state, links: action.payload };
    }

    case ProjectTypes.FETCH_DOCS: {
      return { ...state, docs: action.payload };
    }
    case ProjectTypes.FETCH_FOLDERS_TRASH: {
      return { ...state, foldersTrash: action.payload };
    }
    case ProjectTypes.FETCH_FILES_TRASH: {
      return { ...state, filesTrash: action.payload };
    }

    default:
      return state;
  }
};
