import classNames from "classnames";
import { ITooltipProps } from "models/generalComponents/tooltip";
import React from "react";

import styles from "./Tooltip.module.sass";

export const Tooltip: React.FC<ITooltipProps> = ({ className, content, children }): JSX.Element => {
  return (
    <div className={classNames(className, styles.tooltip)} title={content}>
      {children}
    </div>
  );
};
