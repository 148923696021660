import { ReactComponent as FolderIcon } from "assets/icons/folder_2.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/mail/downArrowSharp.svg";
import classNames from "classnames";
import { useContextPostboxFolder } from "collections/mail/mail";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { POSTBOX_MODALS } from "generalComponents/variables/mail";
import { useSystemPostboxFolder } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { POSTBOX_SYSTEM_FOLDER, SystemPostboxFolders } from "models/postbox";
import { IMailFolders, IPostbox } from "models/store/postbox/postbox";
import { FC, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { NavLink } from "react-router-dom";

import styles from "./MailAccountItem.module.sass";

interface IMailAccountItemProps {
  postbox: IPostbox;
  listCollapsed: boolean;
  isOpen: boolean;
  folders: IMailFolders;
}

const MailAccountItem: FC<IMailAccountItemProps> = ({ postbox, listCollapsed, isOpen, folders }) => {
  const { __ } = useLocales();
  const [isShowMailMenu, setIsShowMailMenu] = useState<boolean>(isOpen);
  const { onSetPostboxModal } = useActions();
  const SYSTEM_FOLDERS: SystemPostboxFolders = useSystemPostboxFolder();
  const { contextPostboxFolder } = useContextPostboxFolder();

  const toggleEvents = (): void => {
    setIsShowMailMenu((prevState) => !prevState);
  };

  const onAddFolder = () => {
    onSetPostboxModal({ open: true, type: POSTBOX_MODALS.ADD_FOLDER, postbox, folder: null });
  };

  const getUnreadCustom = (folderOrig: string): number => {
    const amount = folders.custom.find((folder) => folder.orig === folderOrig).info.Unread;
    return amount;
  };

  const getUnreadInbox = useMemo((): number => {
    //  ERROR
    const inbox = folders.system.find((folder) => folder.orig === POSTBOX_SYSTEM_FOLDER.INBOX);
    const amount = inbox?.info?.Unread ?? 0;
    return amount;
  }, [folders]);

  const getAmountScheduled = useMemo((): number => {
    const inbox = folders.system.find((folder) => folder.orig === POSTBOX_SYSTEM_FOLDER.SCHEDULED);
    const amount = inbox.info.Unread ?? 0;
    return amount;
  }, [folders]);

  const getTotalUnread = useMemo((): number => {
    const unreadCustom = folders.custom.reduce((acc, folder) => (acc += folder.info.Unread), 0);
    return unreadCustom + getUnreadInbox;
  }, [folders, getUnreadInbox]);

  if (!folders) return;

  return (
    <>
      <div className={classNames(styles.wrapper, { [styles.mailMenuOpen]: isShowMailMenu })} onClick={toggleEvents}>
        <div className={styles.name}>
          {listCollapsed ? (
            <span>{postbox.email[0]}</span>
          ) : (
            <span>
              {postbox.email}&nbsp;
              {Boolean(getTotalUnread) && (
                <span className={classNames(styles.amount, styles.amount_unread)}>{getTotalUnread}</span>
              )}
            </span>
          )}
        </div>
        {!listCollapsed && (
          <div className={styles.buttonsWrap}>
            <ArrowIcon />
          </div>
        )}
      </div>

      <div
        className={classNames(styles.mailMenu, {
          [styles.linksMailMenuHide]: !isShowMailMenu
        })}
      >
        {folders.system.map(
          (folder, i) =>
            folder && (
              <NavLink
                to={{
                  pathname: `${postbox.id}/${folder?.orig}`
                }}
                key={i}
                className={({ isActive }) => {
                  return classNames(styles.link, {
                    [styles.active]: isActive,
                    [styles.menuItemCollapsed]: listCollapsed
                  });
                }}
              >
                <div className={styles.iconBox}>{SYSTEM_FOLDERS[folder?.orig as POSTBOX_SYSTEM_FOLDER]?.img}</div>
                <span className={styles.folderName}>
                  {SYSTEM_FOLDERS[folder?.orig as POSTBOX_SYSTEM_FOLDER]?.name}&nbsp;
                  {folder.orig === POSTBOX_SYSTEM_FOLDER.INBOX && Boolean(getUnreadInbox) && (
                    <span className={classNames(styles.amount, styles.amount_inbox)}>{getUnreadInbox}</span>
                  )}
                  {folder.orig === POSTBOX_SYSTEM_FOLDER.SCHEDULED && Boolean(getAmountScheduled) && (
                    <span className={classNames(styles.amount, styles.amount_inbox)}>{getAmountScheduled}</span>
                  )}
                </span>
              </NavLink>
            )
        )}
        {folders.custom.map(
          (folder, i) =>
            folder && (
              <NavLink
                to={{
                  pathname: `${postbox.id}/${folder?.orig}`
                }}
                key={i}
                className={({ isActive }) => {
                  return classNames(styles.link, {
                    [styles.active]: isActive,
                    [styles.menuItemCollapsed]: listCollapsed
                  });
                }}
              >
                <div className={styles.iconBox}>
                  <FolderIcon />
                </div>
                <span className={styles.folderName}>
                  {folder.name}&nbsp;
                  {Boolean(getUnreadCustom(folder.orig)) && (
                    <span className={classNames(styles.amount, styles.amount_inbox)}>
                      {getUnreadCustom(folder.orig)}
                    </span>
                  )}
                </span>
                <div className={styles.contextBox}>
                  <PopoverContextMenu data={contextPostboxFolder(folder, postbox)} iconVariant="button" />
                </div>
              </NavLink>
            )
        )}

        <button type="button" className={styles.addBtn} onClick={onAddFolder}>
          <span className={styles.iconBox}>
            <PlusIcon />
          </span>
          <span className={styles.folderName}>{__("Создать папку")}</span>
        </button>
      </div>
    </>
  );
};

export default MailAccountItem;
