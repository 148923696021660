export enum ChatTypes {
  RECENT_CHATS_LIST = "RECENT_CHATS_LIST",
  SECRET_CHATS_LIST = "SECRET_CHATS_LIST",
  CHAT_GROUPS_LIST = "CHAT_GROUPS_LIST",
  INCREASE_NOTIFICATION_COUNTER = "INCREASE_NOTIFICATION_COUNTER",
  NEW_LAST_GROUP_MESSAGE = "NEW_LAST_GROUP_MESSAGE",
  SET_NOTIFICATION_COUNTER = "SET_NOTIFICATION_COUNTER",
  CHAT_GROUPS_MEMBERS = "CHAT_GROUPS_MEMBERS",
  CHAT_GROUP_DELETE = "CHAT_GROUP_DELETE",
  SECRET_CHAT_DELETE = "SECRET_CHAT_DELETE",
  CHAT_SELECTED_CONTACT = "CHAT_SELECTED_CONTACT",
  CHAT_ID_USER = "CHAT_ID_USER",
  GET_CHAT_FILES = "GET_CHAT_FILES",
  ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE",
  GET_MESSAGES = "GET_MESSAGES",
  GET_PREVIUS_MESSAGES = "GET_PREVIUS_MESSAGES",
  MESSAGE_DELETE = "MESSAGE_DELETE",
  SET_MESSAGE_LIFE_TIME = "SET_MESSAGE_LIFE_TIME",
  INSERT_EMODJI = "INSERT_EMODJI",
  SET_CHAT_THEME = "SET_CHAT_THEME",
  SET_MESSAGES_TO_READ = "SET_MESSAGES_TO_READ",
  SET_CHAT_CALLROOM = "SET_CHAT_CALLROOM",
  SET_CHAT_CALLROOM_STATE = "SET_CHAT_CALLROOM_STATE",
  SET_CHAT_CALLROOM_CONNECTED_USERS = "SET_CHAT_CALLROOM_CONNECTED_USERS",
  SET_CHAT_CALLROOM_CHANGE_CONNECTED_USER = "SET_CHAT_CALLROOM_CHANGE_CONNECTED_USER",
  CHANGE_CHAT_CALLROOM_CONNECTED_USERS_MEDIA = "CHANGE_CHAT_CALLROOM_CONNECTED_USERS_MEDIA"
}
