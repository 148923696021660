export interface ILoadingLibraryFiles {
  id_category: string;
  id_dep: string;
  prim: string;
  dir_name: string;
  files: File[];
  time: number;
  percent: number;
  done: boolean;
  isOpen?: boolean;
}

export interface IInitialsUploadLibraryFiles {
  loadingFiles: ILoadingLibraryFiles;
}

export function initialUploadLibraryFiles(): IInitialsUploadLibraryFiles {
  return {
    loadingFiles: {
      id_category: "",
      id_dep: "",
      prim: "",
      dir_name: "",
      files: [],
      time: 0,
      percent: 0,
      done: false,
      isOpen: false
    }
  };
}
