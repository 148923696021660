import classNames from "classnames";
import { previewFormats } from "generalComponents/collections/folders";
import File from "generalComponents/Files";
import Loader from "generalComponents/Loaders/4HUB";
import { getMedia } from "generalComponents/Services/mediaServices";
import { MODALS } from "generalComponents/variables/global";
import { imageSrc, projectSrc } from "generalComponents/variables/globalVariables";
import { LoaderTypes } from "models/generalComponents/loader";
import { IMediaFile, IOfficeFile, IPreviewsProps } from "models/workElements/previews";
import React, { LegacyRef, RefObject, useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./Previews.module.sass";

const Previews = React.forwardRef(
  ({ file: f, errorHandler }: IPreviewsProps, canvasRef: LegacyRef<HTMLCanvasElement>) => {
    const { __ } = useLocales();
    const audioRef = useRef(null);
    const [audio, setAudio] = useState("");
    const [video, setVideo] = useState("");
    const { userInfo } = useUserSelectors();
    const standardPrev = (
      <div className={styles.filePreviewWrapWrap}>
        <div className={styles.filePreviewWrap}>
          <File format={f.ext} color={f.color} />
        </div>
      </div>
    );
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // @ts-ignore
    // TODO - mkortelov - remove, when store types are finished
    const error = useSelector((s) => s.Cabinet.modals.error);

    const renderError = (message: string): void => {
      dispatch(onSetModals(MODALS.ERROR, { ...error, open: true, message }));
      errorHandler(message);
    };

    const renderOfficePreview = (): JSX.Element => {
      const file = f as IOfficeFile;
      const isFormat = previewFormats.filter((type) => file.ext.toLowerCase().includes(type)).length > 0;
      if (isFormat && file.edit_url) {
        return <iframe src={file.edit_url} title={file.name} frameBorder="0" scrolling="no" />;
      } else {
        return standardPrev;
      }
    };

    const renderFilePreview = () => {
      const file = f as IMediaFile;
      if (!loading) {
        switch (file.mime_type.split("/")[0]) {
          case "image": {
            return (
              <div className={classNames(`scrollbar-thin-${userInfo.theme}`, styles.canvasWrap)}>
                <canvas ref={canvasRef} />
              </div>
            );
          }
          case "video": {
            return (
              <video
                controls
                src={video ? video : ""}
                // type={file.mime_type}
                onError={() => renderOfficePreview()}
              >
                <source src={video ? video : ""} type={file.mime_type} />
              </video>
            );
          }
          case "audio": {
            return (
              <div className={styles.audioWrap}>
                <div className={styles.audioPicWrap}>
                  <img
                    className={styles.audioPic}
                    src={`${imageSrc}assets/PrivateCabinet/file-preview_audio.svg`}
                    alt="audio"
                  />
                </div>
                <audio
                  ref={audioRef}
                  src={audio ? audio : ""}
                  // type={file?.mime_type}
                  controls
                  onError={() => renderOfficePreview()}
                >
                  <source src={audio ? audio : ""} type={file?.mime_type} />
                </audio>
              </div>
            );
          }
          case "application": {
            return <iframe src={`${projectSrc}${file?.preview}`} title={file?.name} frameBorder="0" scrolling="no" />;
          }
          default: {
            return (
              <div className={styles.filePreviewWrapWrap}>
                <div className={styles.filePreviewWrap}>
                  <File format={file?.ext} color={file?.color} />
                </div>
              </div>
            );
          }
        }
      }
    };

    useEffect(() => {
      if ("mime_type" in f) {
        const file = f as IMediaFile;
        if (file.mime_type.includes("image")) {
          const img = new Image();
          img.src = `${file.preview}${file.fid === "printScreen" ? "" : `?${new Date()}`}`;
          img.onload = (e: Event) => {
            const image = e.target as HTMLImageElement;
            const cr = canvasRef as RefObject<HTMLCanvasElement>;
            if (cr?.current) {
              const canvas = cr.current.getContext("2d");
              cr.current.width = image.naturalWidth;
              cr.current.height = image.naturalHeight;
              cr.current.style.width = image.naturalWidth + "px";
              cr.current.style.height = image.naturalHeight + "px";
              canvas && canvas.clearRect(0, 0, image.naturalWidth, image.naturalHeight);
              canvas && canvas.drawImage(img, 0, 0, image.naturalWidth, image.naturalHeight);
            }
          };
          img.onerror = () => {
            setLoading(false);
            renderError(__("Не удалось загрузить изображение, попробуйте еще раз"));
          };
        }
        if (file.mime_type && file.mime_type.includes("audio") && file.is_preview) {
          setLoading(true);
          getMedia(`${imageSrc}${file.preview}`, file.mime_type, setAudio, setLoading, renderError);
        }
        if (file.mime_type && file.mime_type.includes("video") && file.is_preview) {
          setLoading(true);
          getMedia(`${imageSrc}${file.preview}`, file.mime_type, setVideo, setLoading, renderError);
        }
      }
      return () => {
        if (window.cancelLoadMedia) window.cancelLoadMedia.cancel();
      };
    }, []); //eslint-disable-line

    return (
      <>
        {loading ? (
          <Loader
            type={LoaderTypes.BOUNCING_DOTS}
            position="absolute"
            background="rgba(0, 0, 0, 0)"
            zIndex={5}
            containerType="bounceDots"
          />
        ) : null}
        <div className={styles.previewsWrap}>{f.is_preview === 1 ? renderFilePreview() : renderOfficePreview()}</div>
      </>
    );
  }
);

export default Previews;

Previews.displayName = "Previews";
