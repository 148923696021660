import { ReactComponent as DragIcon } from "assets/icons/drag.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as InvisibleIcon } from "assets/icons/invisible.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Button from "generalComponents/Button/Button";
import { IExtendedIJointProjectStatus } from "generalComponents/Modals/Components/JointProject/StatusModal/StatusModal";
import { useDefaultProjectStatuses } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { STATUSES } from "models/store/joinProjects/joinProgects";
import { useLocales } from "react-localized";

import styles from "./StatusItem.module.sass";

const CategoryItem = ({
  item,
  setStatuses
}: {
  item: IExtendedIJointProjectStatus;
  setStatuses: React.Dispatch<React.SetStateAction<IExtendedIJointProjectStatus[]>>;
}) => {
  const { __ } = useLocales();
  const { onSetChangeStatusModal } = useActions();
  const defaultStatuses = useDefaultProjectStatuses();

  const toggleHidden = () => {
    setStatuses((prev) => {
      return prev.map((active) => {
        if (active.i === item.i) {
          return { ...active, is_visible: !active.is_visible };
        }
        return active;
      });
    });
  };

  const removeItem = () => {
    setStatuses((prev) => {
      return prev.filter((active) => {
        return active.i !== item.i;
      });
    });
  };

  return (
    <div data-grid={item} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.mainContent}>
          <DragIcon className={styles.icon} />
          <div className={styles.color} style={{ background: item.color }}></div>
          <span>{item.name ? item.name : defaultStatuses[item.system_name]}</span>
        </div>
        <div className={styles.editor}>
          <Button
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.MEDIUM}
            isSquare
            iconL={<EditIcon />}
            onClick={() => onSetChangeStatusModal({ open: true, setStatuses, item })}
            onMouseDown={(e) => e.stopPropagation()}
          />
          <Button
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.MEDIUM}
            isSquare
            iconL={item.is_visible ? <EyeIcon /> : <InvisibleIcon />}
            onClick={toggleHidden}
            onMouseDown={(e) => e.stopPropagation()}
          />
          {!item.system_name && (
            <Button
              variant={ButtonVariantType.OPACITY}
              size={ButtonSizeType.MEDIUM}
              isSquare
              iconL={<TrashIcon />}
              onClick={removeItem}
              onMouseDown={(e) => e.stopPropagation()}
            />
          )}
        </div>
      </div>
      {item?.system_name === STATUSES.OVERDUE && (
        <div className={styles.desc}>{__("Все просроченные задачи переносятся в колонку Просрочено")}</div>
      )}
    </div>
  );
};

export default CategoryItem;
