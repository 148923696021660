import ParticipantsButtons from "containers/JoinProject/JointProjectsTasks/components/ParticipantsButtons/ParticipantsButtons";
import TeamButtons from "containers/JoinProject/JointProjectsTasks/components/TeamButtons/TeamButtons";
import Tabs from "generalComponents/Tabs/Tabs";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { TAB } from "Pages/JoinProjectPage/ProjectPage/ProjectGantPage/ProjectGantPage";
import { FC } from "react";
import { useLocales } from "react-localized";

import styles from "./GantServicePanel.module.sass";

interface IProps {
  tab: TAB;
  setTab: (v: TAB) => void;
}

export const GantServicePanel: FC<IProps> = ({ tab, setTab }) => {
  const { __ } = useLocales();

  const { team, participants } = useAllSelectedSearchParams();

  const tabs = [
    {
      name: __("День"),
      value: TAB.DAY,
      onClick: () => setTab(TAB.DAY)
    },
    {
      name: __("Неделя"),
      value: TAB.WEEK,
      onClick: () => setTab(TAB.WEEK)
    },
    {
      name: __("Месяц"),
      value: TAB.MONTH,
      onClick: () => setTab(TAB.MONTH)
    },
    {
      name: __("Год"),
      value: TAB.YEAR,
      onClick: () => setTab(TAB.YEAR)
    }
  ];

  return (
    <ToolsPanel size="extraLarge">
      <div className={styles.wrapper}>
        <div className={styles.tools}>
          <TeamButtons selectedTeam={team.selectedValue} onSelectTeam={team.onSelectValue} />
          <div className={styles.btns}>
            <Tabs tabs={tabs} value={tab} />
          </div>
        </div>

        <ParticipantsButtons
          selectedParticipants={participants.selectedValue}
          onSelectParticipiant={participants.onSelectMultipleValue}
          teamId={team.selectedValue}
        />
      </div>
    </ToolsPanel>
  );
};
