import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import { RubricsLibrary } from "generalComponents/RubricsLibrary/RubricsLibrary";
import { WorkSpaceWrap } from "generalComponents/WorkSpaceWrap/WorkSpaceWrap";
import { useIsFiltersLibrary } from "hooks/useIsFilters";
import { LoaderTypes } from "models/generalComponents/loader";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useLibrarySelectors } from "Store/selectors/librarySelectors";

interface ILibraryElementsListProps {
  isLinePreview?: boolean;
}

export const LibraryRubricsList: FC<ILibraryElementsListProps> = (): JSX.Element => {
  const { __ } = useLocales();

  const { loader, rubrics, page } = useLibrarySelectors();

  const isFiltersLibrary = useIsFiltersLibrary();

  const renderMyFiles = (): JSX.Element => {
    if (rubrics !== null) {
      if (isFiltersLibrary) {
        return <Clue icon="neutral" text={__("Нет рубрик соответствующих параметрам фильтрации")} />;
      }

      return <RubricsLibrary rubrics={rubrics} />;
    }
  };

  return (
    <WorkSpaceWrap>
      {loader && page === 1 ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <>{renderMyFiles()}</>
      )}
    </WorkSpaceWrap>
  );
};
