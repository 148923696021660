import { GantServicePanel } from "containers/JoinProject/JointProjectGant/GantServicePanel/GantServicePanel";
import { GantSpace } from "containers/JoinProject/JointProjectGant/GantSpace/GantSpace";
import { WrapperPage } from "containers/JoinProject/WrapperPage/WrapperPage";
import { ROUTES } from "generalComponents/variables/routing";
import { useProjectMainAccess } from "hooks/joinProjectHooks";
import { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

export enum TAB {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR"
}
const getTab = () => {
  const tab = localStorage.getItem("gantTab");
  return tab ? TAB[tab as TAB] : TAB.DAY;
};
const ProjectGantPage = () => {
  const isMainAccess = useProjectMainAccess({ or: false });
  const [tab, setTab] = useState<TAB>(getTab());

  const { project } = useJoinProjectsSelectors();

  useEffect(() => {
    localStorage.setItem("gantTab", tab);
  }, [tab]);

  if (!isMainAccess && !project.accesses.ANALITYCS) return <Navigate to={`/${ROUTES.PROJECT}`} replace />;

  return (
    <WrapperPage>
      <GantServicePanel tab={tab} setTab={setTab} />
      <GantSpace tab={tab} />
    </WrapperPage>
  );
};

export default ProjectGantPage;
