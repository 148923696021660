export enum LibraryTypes {
  SET_LIBRARY_CATEGORIES = "SET_LIBRARY_CATEGORIES",
  SET_LIBRARY_RUBRICS = "SET_LIBRARY_RUBRICS",
  SET_LIBRARY_PAGE = "SET_LIBRARY_PAGE",
  SET_LIBRARY_FOLDERS = "SET_LIBRARY_FOLDERS",
  SET_LIBRARY_FOLDER_INFO = "SET_LIBRARY_FOLDER_INFO",
  SET_LIBRARY_LOADER = "SET_LIBRARY_LOADER",
  SET_LIBRARY_TOTAL_ITEMS = "SET_LIBRARY_TOTAL_ITEMS",
  CHANGE_LIBRARY_LOADING_FILES = "CHANGE_LIBRARY_LOADING_FILES",
  RESET_LIBRARY_FOLDERS = "RESET_LIBRARY_FOLDERS",
  RESET_LIBRARY_LOADING_FILES = "RESET_LIBRARY_LOADING_FILES",
  // Update
  SET_ADD_LIBRARY_RUBRIC = "SET_ADD_LIBRARY_RUBRIC",
  SET_UPDATE_LIBRARY_RUBRIC = "SET_UPDATE_LIBRARY_RUBRIC",
  SET_DEL_LIBRARY_RUBRIC = "SET_DEL_LIBRARY_RUBRIC",
  SET_ADD_LIBRARY_FOLDER = "SET_ADD_LIBRARY_FOLDER",
  SET_UPDATE_LIBRARY_FOLDER = "SET_UPDATE_LIBRARY_FOLDER",
  SET_DEL_LIBRARY_FOLDER = "SET_DEL_LIBRARY_FOLDER",
  SET_DEL_LIBRARY_FILES = "SET_DEL_LIBRARY_FILES"
}
