import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow-2.svg";
import classNames from "classnames";
import { useTasksFiltersType } from "collections/tasks";
import { endOfDay, endOfWeek, getTime, startOfDay, startOfWeek } from "date-fns";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { MS_OF_DAY, msToDateObject } from "generalComponents/Calendars/CalendarHelper";
import CalendarMonth from "generalComponents/Calendars/CalendarMonth/CalendarMonth";
import PopUp from "generalComponents/PopUp/PopUp";
import { BTN_SWITCH, TaskFilters } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import styles from "./CalendarTabs.module.sass";

const CalendarTabs = () => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const { filters } = useTasksSelectors();

  const filterTabs = useTasksFiltersType();
  const { onSelectFilterType, onSelectFilterDate } = useActions();

  const [openMonthCalendar, setOpenMonthCalendar] = useState<boolean>(false);

  const getDayDate = (): string => {
    const todayStart = getTime(startOfDay(new Date()));
    const todayEnd = getTime(endOfDay(new Date()));
    const tomorrowStart = getTime(startOfDay(new Date(todayStart + MS_OF_DAY)));
    const tomorrowEnd = getTime(endOfDay(new Date(todayEnd + MS_OF_DAY)));
    const yesterdayStart = getTime(startOfDay(new Date(todayStart - MS_OF_DAY)));
    const yesterdayEnd = getTime(endOfDay(new Date(todayEnd - MS_OF_DAY)));
    const currentDate = new Date(filters.date.year, filters.date.month, filters.date.day).getTime();
    if (currentDate >= todayStart && currentDate < todayEnd) {
      return __("Сегодня");
    }
    if (currentDate >= yesterdayStart && currentDate < yesterdayEnd) {
      return __("Вчера");
    }
    if (currentDate >= tomorrowStart && currentDate < tomorrowEnd) {
      return __("Завтра");
    }
    return formatDate(currentDate);
  };

  const getWeekDate = (): string => {
    const currentWeek = new Date(filters.date.year, filters.date.month, filters.date.day);
    const weekStart = startOfWeek(currentWeek, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(currentWeek, { weekStartsOn: 1 });

    return `${formatDate(weekStart)} - ${formatDate(weekEnd)}`;
  };

  const getMonthDate = (): string => {
    return formatDate(new Date(filters.date.year, filters.date.month), "month");
  };

  const getYearDate = (): string => {
    return `${filters.date.year}`;
  };

  const getDate = (): string => {
    switch (filters.type) {
      case TaskFilters.BY_DAY:
        return getDayDate();
      case TaskFilters.BY_WEEK:
        return getWeekDate();
      case TaskFilters.BY_MONTH:
        return getMonthDate();
      case TaskFilters.BY_YEAR:
        return getYearDate();

      default:
        return;
    }
  };

  const onSwitchDay = (btn: BTN_SWITCH): void => {
    const currentDate = new Date(filters.date.year, filters.date.month, filters.date.day).getTime();
    const nextDate = btn === BTN_SWITCH.NEXT ? currentDate + MS_OF_DAY : currentDate - MS_OF_DAY;
    const date = msToDateObject(nextDate);
    onSelectFilterDate(date);
  };

  const onSwitchWeek = (btn: BTN_SWITCH): void => {
    const currentDate = new Date(filters.date.year, filters.date.month, filters.date.day).getTime();
    const nextDate = btn === BTN_SWITCH.NEXT ? currentDate + MS_OF_DAY * 7 : currentDate - MS_OF_DAY * 7;
    const date = msToDateObject(nextDate);
    onSelectFilterDate(date);
  };

  const onSwitchMonth = (btn: BTN_SWITCH): void => {
    const currentMonth = filters.date.month;
    let nextYear = filters.date.year;
    let nextMonth = filters.date.month;
    if (btn === BTN_SWITCH.NEXT) {
      if (currentMonth === 11) {
        nextYear += 1;
        nextMonth = 0;
      } else {
        nextMonth += 1;
      }
    }
    if (btn === BTN_SWITCH.PREV) {
      if (currentMonth === 0) {
        nextYear -= 1;
        nextMonth = 11;
      } else {
        nextMonth -= 1;
      }
    }
    onSelectFilterDate({ ...filters.date, year: nextYear, month: nextMonth });
  };

  const onSwitchYear = (btn: BTN_SWITCH): void => {
    const currentYear = filters.date.year;
    const nextYear = btn === BTN_SWITCH.NEXT ? currentYear + 1 : currentYear - 1;
    onSelectFilterDate({ ...filters.date, year: nextYear });
  };

  const onDateSwitch = (btn: BTN_SWITCH): void => {
    switch (filters.type) {
      case TaskFilters.BY_DAY:
        return onSwitchDay(btn);
      case TaskFilters.BY_WEEK:
        return onSwitchWeek(btn);
      case TaskFilters.BY_MONTH:
        return onSwitchMonth(btn);
      case TaskFilters.BY_YEAR:
        return onSwitchYear(btn);

      default:
        return;
    }
  };

  const selectTab = (type: TaskFilters): void => {
    onSelectFilterType(type);
    onSelectFilterDate({ year: new Date().getFullYear(), month: new Date().getMonth(), day: new Date().getDate() });
  };

  const handlerOpenCalendar = (): void => {
    if (TaskFilters.BY_YEAR === filters.type) {
      return;
    }
    setOpenMonthCalendar(true);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.tabs}>
        {filterTabs.map((el, i) => (
          <button
            key={i}
            className={classNames(styles.tabType, { [styles.activeTab]: filters.type === el.type })}
            onClick={() => selectTab(el.type)}
          >
            {el.name}
          </button>
        ))}
      </div>
      <div className={styles.datePicker}>
        <ButtonIcon
          handleClick={() => onDateSwitch(BTN_SWITCH.PREV)}
          variant="white"
          icon={<ArrowIcon className={styles.arrowIcon} />}
        />
        <div className={classNames(styles.btnAdd, styles.date)} onClick={handlerOpenCalendar} title={getDate()}>
          {getDate()}
        </div>
        <ButtonIcon
          handleClick={() => onDateSwitch(BTN_SWITCH.NEXT)}
          variant="white"
          icon={<ArrowIcon className={classNames(styles.arrowIcon, styles.arrowRight)} />}
        />
      </div>
      {openMonthCalendar && (
        <PopUp set={setOpenMonthCalendar} zIndex={102}>
          <CalendarMonth
            onSelectDate={(value) => onSelectFilterDate(value)}
            setShowCalendar={() => setOpenMonthCalendar(false)}
            isWeekSelection={filters.type === TaskFilters.BY_WEEK}
          />
        </PopUp>
      )}
    </div>
  );
};

export default CalendarTabs;
