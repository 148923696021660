import classNames from "classnames";
import { jointProjectsColors } from "collections/joinProject";
import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import PopUp from "generalComponents/PopUp/PopUp";
import { useDefaultProjectStatuses } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialChangeStatusModalState } from "models/store/Cabinet/modals/changeStatusModal";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { generateUniqueId } from "utils/generateUniqueId";

import styles from "./ChangeStatusModal.module.sass";

const ChangeStatusModal = () => {
  const [name, setName] = useState("");
  const [activeColor, setActiveColor] = useState<string>();
  const { __ } = useLocales();
  const { onSetChangeStatusModal } = useActions();
  const defaultStatuses = useDefaultProjectStatuses();

  const {
    changeStatusModal: { item, setStatuses }
  } = useGlobalModalsSelectors();

  const closeModal = (): void => {
    onSetChangeStatusModal(initialChangeStatusModalState());
  };

  useEffect(() => {
    if (!item) return;
    setActiveColor(item.color);
    if (item.name) {
      setName(item.name);
    } else {
      setName(defaultStatuses[item.system_name]);
    }
  }, [item]); //eslint-disable-line

  const toggleSumbit = () => {
    if (!activeColor || !name) return;
    if (!item) {
      const newStatus = {
        name,
        color: activeColor,
        w: 1,
        h: 2,
        x: 0,
        y: 0,
        is_visible: true,
        isResizable: false,
        i: generateUniqueId()
      };

      setStatuses((prevStatuses) => [...prevStatuses, newStatus]);
    } else {
      const updatedStatus = {
        ...item,
        name,
        color: activeColor
      };

      setStatuses((prevStatuses) => {
        const statusIndex = prevStatuses.findIndex((status) => status === item);
        const newStatuses = [...prevStatuses];
        newStatuses[statusIndex] = updatedStatus;
        return newStatuses;
      });
    }
    closeModal();
  };

  return (
    <PopUp set={closeModal} position="top" zIndex={12}>
      <HeaderModal onClose={closeModal} title={__("Добавить статус/колонку")} />
      <div className={styles.container}>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={__("Введите")}
          label={__("Название")}
        />
        <div className={styles.title}>{__("Цвет")}</div>
        <div className={styles.colors}>
          {jointProjectsColors.map((current) => (
            <div
              key={current}
              className={classNames(styles.color, activeColor === current && styles.active)}
              onClick={() => setActiveColor(current)}
            >
              <span style={{ background: current }} />
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          <Button
            variant={ButtonVariantType.ADD_GREY}
            text={__("Отменить")}
            onClick={closeModal}
            size={ButtonSizeType.SMALL}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            text={__("Добавить")}
            size={ButtonSizeType.SMALL}
            onClick={toggleSumbit}
            disabled={!name || !activeColor}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default ChangeStatusModal;
