import { calculateSize } from "generalComponents/Services/helpers";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./StorageHead.module.sass";

export const StorageHead = () => {
  const { __ } = useLocales();

  const {
    userInfo: { used, total, free }
  } = useUserSelectors();

  return (
    <div className={styles.box}>
      <span className={styles.currentSize}>
        {calculateSize(used ?? 0)} / {calculateSize(total ?? 0)}
      </span>
      <span className={styles.total}>
        {__("Свободно")} {free && calculateSize(free ?? 0)}
      </span>
    </div>
  );
};
