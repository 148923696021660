import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export const useJournalSelectors = () => {
  const Journal = useTypeSelector((s) => s.Journal);
  const events = Journal.events;
  const total = Journal.total;
  const page = Journal.page;
  const loader = Journal.loader;

  return {
    events,
    total,
    page,
    loader
  };
};
