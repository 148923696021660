import { ReactComponent as ArchiveIcon } from "assets/icons/archive_to.svg";
import { ReactComponent as EditIcon } from "assets/PrivateCabinet/edit.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { TASK_MODALS, TASK_TYPES } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { IContextButtonsProps } from "models/tasks/myTasks";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./ContextButtons.module.sass";

const ContextButtons: React.FC<IContextButtonsProps> = ({ task }): JSX.Element => {
  const { __ } = useLocales();
  const { onSetTaskModal, onEditTask, onDeleteTask, onSetApproveModal } = useActions();

  const onDelete = (): void => {
    const params = {
      title: __("Удалить задачу"),
      text: __("Вы действительно хотите удалить выбранную задачу?"),
      callback: () => onDeleteTask(task.id),

      approveBtn: __("Удалить")
    };
    onSetApproveModal({ open: true, params });
  };

  const moveToArchive = (): void => {
    const params = {
      id_task: task.id,
      is_archive: "1"
    };
    onEditTask(params, EVENT_TYPE.ARCHIVE);
  };

  const getTypeModal = (): TASK_MODALS => {
    switch (task.id_type) {
      case TASK_TYPES.TASK:
        return TASK_MODALS.EDIT_TASK;
      case TASK_TYPES.OFFLINE_MEETING:
      case TASK_TYPES.ONLINE_MEETING:
        return TASK_MODALS.EDIT_MEETING;
      case TASK_TYPES.CALLS:
        return TASK_MODALS.EDIT_CALL;
      case TASK_TYPES.MAILS:
        return TASK_MODALS.EDIT_LETTER;

      default:
        return;
    }
  };

  const onEdit = (): void => {
    onSetTaskModal({
      type: getTypeModal(),
      params: {
        ...task
      }
    });
  };
  return (
    <>
      <button className={styles.statusBtn} onClick={onEdit}>
        <EditIcon />
      </button>
      <button className={styles.statusBtn} onClick={moveToArchive}>
        <ArchiveIcon fill="#b1b1b1" />
      </button>
      <button className={styles.statusBtn} onClick={onDelete}>
        <TrashIcon />
      </button>
    </>
  );
};

export default ContextButtons;
