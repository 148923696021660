import { TrashElementsList } from "containers/Trash/TrashElementsList/TrashElementsList";
import { TrashServicePanel } from "containers/Trash/TrashServicePanel/TrashServicePanel";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams, useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";

export const TrashPage = () => {
  const { onGetAllTrashElements, onNullifyTrashElements, onResetTrashElementsList, onNullifyFiles } = useActions();

  const {
    objSearchParams: { group, sort, sort_reverse, table, id_type, year, month, day }
  } = useSelectedObjSearchParams();

  const { color, emo, tags } = useAllSelectedSearchParams();

  const { trashDirId } = useParams();

  useEffect(() => {
    const controllerElements = new AbortController();

    if (!trashDirId) {
      onResetTrashElementsList();
      onGetAllTrashElements(controllerElements);

      return () => {
        onNullifyTrashElements();
        controllerElements.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    color.selectedValue,
    emo.selectedValue,
    tags.selectedValue?.length,
    group,
    sort,
    sort_reverse,
    year,
    month,
    day,
    table,
    id_type,
    trashDirId
  ]);

  useEffect(() => {
    return () => {
      onNullifyTrashElements();
      onNullifyFiles();
    };
  }, []); //eslint-disable-line

  return (
    <WorkAreaWrap>
      <TrashServicePanel />
      {trashDirId ? <Outlet /> : <TrashElementsList />}
    </WorkAreaWrap>
  );
};
