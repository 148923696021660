import { IFilterCriteriaState } from "models/store/filterCriteria/filterCriteria";
import { IAddFilterCriteria, INullifyFilterCriteria } from "models/store/filterCriteria/filterCriteriaActions";
import { FilterCriteriaTypes } from "Store/types/filterCriteria";

export const onAddFilterCriteria = (payload: IFilterCriteriaState): IAddFilterCriteria => ({
  type: FilterCriteriaTypes.ADD_FILTER,
  payload
});

export const onNullifyFilterCriteria = (): INullifyFilterCriteria => ({
  type: FilterCriteriaTypes.NULLIFY_FILTERS_CRITERIA
});
