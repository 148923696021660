import { initialNoteState, INotesState } from "models/store/notes/notes";
import { NotesActions } from "models/store/notes/notesActions";
import { NotesTypes } from "Store/types/notesTypes";

const INITIAL_STATE: INotesState = initialNoteState();

export const NotesReducer = (state = INITIAL_STATE, action: NotesActions): INotesState => {
  switch (action.type) {
    case NotesTypes.SET_NOTES:
      return { ...state, notes: action.payload };

    case NotesTypes.SET_ACTIVE_NOTE:
      return { ...state, activeNote: action.payload };

    case NotesTypes.SET_NOTE_CATEGORIES:
      return { ...state, categories: action.payload };

    default:
      return state;
  }
};
