import { JoinProjectElementsList } from "containers/JoinProject/JointProjectFiles/components/JoinProjectElementsList/JoinProjectElementsList";
import { useAllFolders } from "generalComponents/Services/folderServices/folderServices";
import { PAGE_DEPTH } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { IFolder, initialFolderState } from "models/store/folders/foldersStore";
import { TProjectFilesArray } from "models/store/joinProjects/joinProgects";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const ProjectFilesFolderPage = () => {
  const { onGetProjectFiles, onResetProjectFilesList, onSelectFolder, onSetProjectFiles } = useActions();

  const foldersAll = useAllFolders();

  const { dirId } = useParams();

  useEffect(() => {
    const controller = new AbortController();

    if (dirId) {
      const folder: IFolder = foldersAll.find((el) => el.id_dir === dirId);

      if (!folder) return;

      onSelectFolder(folder);

      const payload = {
        id_dir: folder.id_dir,
        dep: PAGE_DEPTH.PROJECT_FILES,
        callback: (files: TProjectFilesArray) => onSetProjectFiles(files)
      };

      onGetProjectFiles(payload, controller);
    }

    return () => {
      onSelectFolder(initialFolderState());
      onResetProjectFilesList();
      controller.abort();
    };
  }, [dirId]); // eslint-disable-line

  return <JoinProjectElementsList />;
};

export default ProjectFilesFolderPage;
