import classNames from "classnames";
import { IListThemeSettings } from "collections/profile";
import { CheckBox } from "generalComponents/CheckBoxFolder/CheckBox";
import { THEMES } from "generalComponents/variables/global";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { Dispatch, FC, SetStateAction } from "react";
import { v4 as uuid } from "uuid";

import styles from "./ListThemes.module.sass";

interface IListThemesProps {
  themes: IListThemeSettings[];
  checkedTheme: string;
  onCheckedTheme: Dispatch<SetStateAction<THEMES>>;
}

export const ListThemes: FC<IListThemesProps> = ({ themes, checkedTheme, onCheckedTheme }): JSX.Element => (
  <div className={styles.list}>
    {themes.map(({ name, value }) => (
      <div
        role="button"
        tabIndex={0}
        onClick={() => onCheckedTheme(value as THEMES)}
        key={uuid()}
        className={styles.list__item}
      >
        <div className={classNames(styles.list__theme, { [styles[value]]: value })} />
        <label className={styles.list__label}>{name}</label>
        <CheckBox
          variant={checkedTheme === value ? CheckBoxVariantTypes.CHECKED : CheckBoxVariantTypes.DEFAULT}
          width={20}
          height={20}
          increaseArea
        />
      </div>
    ))}
  </div>
);
