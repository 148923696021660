import { ViewType } from "generalComponents/variables/global";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

export const useGetItemView = () => {
  const { view } = useFilesSelectors();

  return {
    isBarsView: view === ViewType.BARS,
    isLinesView: view === ViewType.LINES,
    isBarsPreviewView: view === ViewType.BARS_PREVIEW,
    isLinesPreviewView: view === ViewType.LINES_PREVIEW
  };
};
