// global folders

import { ReactComponent as ExelGIcon } from "assets/createFiles/exel_g.svg";
import { ReactComponent as ExelMIcon } from "assets/createFiles/exel_m.svg";
import { ReactComponent as OfficeIcon } from "assets/createFiles/oneOffice.svg";
import { ReactComponent as PointGIcon } from "assets/createFiles/point_g.svg";
import { ReactComponent as PointMIcon } from "assets/createFiles/point_m.svg";
import { ReactComponent as WordGIcon } from "assets/createFiles/word_g.svg";
import { ReactComponent as WordMIcon } from "assets/createFiles/word_m.svg";
import { SORTING_TYPE } from "models/store/files/sort/sort";
import { useLocales } from "react-localized";

import { ISortingFiles, IUseContextMenuCreateFile } from "../../models/generalComponents/collections/folders";

export const useContextMenuCreateFile = (): IUseContextMenuCreateFile => {
  const { __ } = useLocales();
  return {
    office: [{ name: "One office", img: <OfficeIcon />, ext: "docx" }],
    microsoft: [
      { name: __("Документ Word"), img: <WordMIcon />, ext: "docx" },
      { name: __("Таблица Excel"), img: <ExelMIcon />, ext: "xlsx" },
      { name: __("Презентация PowerPoint"), img: <PointMIcon />, ext: "pptx" }
    ],
    google: [
      { name: __("Документ Google"), img: <WordGIcon /> },
      { name: __("Таблица Google"), img: <ExelGIcon /> },
      { name: __("Презентация Google"), img: <PointGIcon /> }
    ]
  };
};

export const previewTypes = ["application/msword", "application/excel"];
export const previewFormats = ["doc", "xls", "ppt", "rtf", "xlt", "csv"];

export function useContextMenuFilters(): ISortingFiles[] {
  const { __ } = useLocales();

  return [
    {
      name: __(`По имени от А до Я`),
      sort: SORTING_TYPE.NAME,
      revers: "0",
      group: "0"
    },
    {
      name: __(`По имени от Я до А`),
      sort: SORTING_TYPE.NAME,
      revers: "1",
      group: "0"
    },
    {
      name: __(`По дате (от новых к старым)`),
      sort: SORTING_TYPE.DATE_CREATED,
      revers: "1",
      group: "1"
    },
    {
      name: __("По дате (от старых к новым)"),
      sort: SORTING_TYPE.DATE_CREATED,
      revers: "0",
      group: "0"
    },
    {
      name: __("По дате изменения"),
      sort: SORTING_TYPE.DATE_CHANGED,
      revers: "1",
      group: "0"
    },
    {
      name: __("По размеру (от большых к малым)"),
      sort: SORTING_TYPE.SIZE,
      revers: "1",
      group: "0"
    },
    {
      name: __("По размеру (от малых к большым)"),
      sort: SORTING_TYPE.SIZE,
      revers: "0",
      group: "0"
    },
    {
      name: __("По типу"),
      sort: SORTING_TYPE.TYPE,
      revers: "1",
      group: "0"
    }
  ];
}
