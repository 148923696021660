import { PAGE_DEPTH } from "generalComponents/variables/global";

export const getPageDep = (dep: PAGE_DEPTH): Record<PAGE_DEPTH, boolean> => {
  const isFiles: boolean = dep === PAGE_DEPTH.FILES;
  const isLibrary: boolean = dep === PAGE_DEPTH.LIBRARY;
  const isProjectLibrary: boolean = dep === PAGE_DEPTH.PROJECT_LIBRARY;
  const isProjectDoc: boolean = dep === PAGE_DEPTH.PROJECT_DOC;
  const isProjectFiles: boolean = dep === PAGE_DEPTH.PROJECT_FILES;

  return {
    [PAGE_DEPTH.FILES]: isFiles,
    [PAGE_DEPTH.LIBRARY]: isLibrary,
    [PAGE_DEPTH.PROJECT_LIBRARY]: isProjectLibrary,
    [PAGE_DEPTH.PROJECT_DOC]: isProjectDoc,
    [PAGE_DEPTH.PROJECT_FILES]: isProjectFiles
  };
};
