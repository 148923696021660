import { ReactComponent as UserIcon } from "assets/PrivateCabinet/user-setting.svg";
import Button from "generalComponents/Button/Button";
import { PROJECT_ADMIN_ROLES } from "generalComponents/variables/project";
import { useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onEditProject } from "Store/actions/JoinProjectActions";

import styles from "./MessageAdmin.module.sass";

const MessageAdmin = ({ onClose, params }) => {
  const { __ } = useLocales();
  const MSG = useProjectMsg();
  const project = useSelector((s) => s.Projects.project);
  const dispatch = useDispatch();

  const onApprove = () => {
    const payload = {
      ...project,
      show_message: 0
    };
    const message = {
      error: MSG.ERROR
    };
    dispatch(onEditProject(payload, message));
    onClose();
  };

  const renderAdmin = () => {
    return (
      <div>
        <p className={styles.title}>
          {__(`Вы назначены администратором проекта `)} <br />“{project.name}”
        </p>
        <UserIcon className={styles.iconGreen} />
        <p className={styles.text}>{__("Главный администратор имеет право: Удалять проект, Редактировать проект,")}</p>
        <p className={styles.text}>{__("Добавлять роли проекта, Удалять роли проекта,")}</p>
        <p className={styles.text}>
          {__("Назначать сотрудников зам администраторами, Добавлять участников в проект,")}
        </p>
        <p className={styles.text}>{__("Удалять участников из проекта, Добавлять категории, Удалять категории")}</p>
      </div>
    );
  };

  const renderSubAdmin = () => {
    return (
      <div>
        <p className={styles.title}>
          {__(`Вы назначены заместителем администратора проекта `)} <br />“{project.name}”
        </p>
        <UserIcon className={styles.icon} />
        <p className={styles.text}>
          -{__("зам администратора не имеет право удалять главного администратора из проекта")};
        </p>
        <p className={styles.text}>-{__("зам администратора имеет право добавлять новых участников в проект")};</p>
        <p className={styles.text}>
          -{__("зам администратора не имеет право назначать других сотрудников администраторами")};
        </p>
        <p className={styles.text}>
          -{__("после выхода из проекта главного администратора, он передаёт свой статус заму")}.
        </p>
      </div>
    );
  };

  return (
    <div className={styles.wrap}>
      {params === PROJECT_ADMIN_ROLES.ADMIN && renderAdmin()}
      {params === PROJECT_ADMIN_ROLES.SUBADMIN && renderSubAdmin()}
      <Button type="button" variant="ok" onClick={onApprove} text={__("Готово")} />
    </div>
  );
};

export default MessageAdmin;

MessageAdmin.propTypes = {
  params: PropTypes.string,
  onClose: PropTypes.func
};
