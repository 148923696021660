import classNames from "classnames";
import { useActionsJournal, useEventGroupElement } from "collections/journal";
import { useContentCategories, useContentRubrics } from "collections/library";
import { useEmployeeRights, useTableAccess } from "collections/profile";
import { useListEmployees } from "collections/profile";
import { useUserStatus } from "collections/profile";
import { useUrgencyTask } from "collections/tasks";
import { useTypesMeeting } from "collections/tasks";
import Button from "generalComponents/Button/Button";
import { useSystemFolder } from "generalComponents/collections/folder";
import { calculateSize } from "generalComponents/Services/helpers";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { LibraryCategoryTypes, LibraryTypesRubrics, TABS } from "generalComponents/variables/library";
import { PROFILE_ROUTES, ROUTES, ROUTES_SEARCH_PARAMS } from "generalComponents/variables/routing";
import { LIBRARY_ROUTES } from "generalComponents/variables/routing";
import { STATUS_TYPES } from "generalComponents/variables/tasks";
import { useDepName } from "hooks/tasksHooks";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { DEFAULT_FOLDERS } from "models/generalComponents/folders";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IEvent } from "models/store/journal/journal";
import { IPostbox } from "models/store/postbox/postbox";
import { IMyTask } from "models/store/tasks/tasks";
import { EMPLOYEE_RIGHTS, IUserInfo } from "models/store/user/user";
import { FC } from "react";
import { useLocales } from "react-localized";
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLibrarySelectors } from "Store/selectors/librarySelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { getConvertFormatDateTime } from "utils/getConvertDateTime";
import { getStringWithoutTags } from "utils/getStringWithoutTags";
import { getTheRestorePath } from "utils/getTheRestorePath";

import styles from "./ContextInfoModal.module.sass";
import { getEventGroup } from "./utils/getEventGroup";
import { getEventType } from "./utils/getEventType";

export interface IContextInfoModalProps {
  setShow: (show: boolean) => void;
  event: IEvent;
}

type TObjWithAppearance = IFolder | IFile | IMyTask;

export const ContextInfoModal: FC<IContextInfoModalProps> = ({ event, setShow }) => {
  const { object, data } = event;

  const {
    user: {
      userInfo: { lang }
    }
  } = useUserSelectors();

  const { rubrics } = useLibrarySelectors();

  const { __ } = useLocales();

  const { formatDate } = useDateFormat();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { actionLogId } = useParams();
  const { state } = useLocation();

  const groupElement = useEventGroupElement();
  const actionElement = useActionsJournal();
  const employeeRights = useEmployeeRights();
  const categoriesLibrary = useContentCategories();
  const rubricsLibraryDefault = useContentRubrics();
  const tableAccess = useTableAccess();
  const SYSTEM_FOLDERS = useSystemFolder();

  const renderPath = (path: string): string => {
    return path
      .split("/")
      .map((item) => {
        if (Object.keys(SYSTEM_FOLDERS).some((key) => key === item)) {
          return SYSTEM_FOLDERS[item as DEFAULT_FOLDERS].nameRu;
        }

        return item;
      })
      .join("/");
  };

  const { userStatuses } = useUserStatus();

  const depName = useDepName();
  const urgency = useUrgencyTask();
  const typesMeeting = useTypesMeeting();

  const getSelectUrgency = (id: string): string => {
    return urgency.find((item) => item.id === id)?.name;
  };

  const getTypeMeeting = (id: string): string => {
    return typesMeeting.find((item) => item.id === id)?.name;
  };

  const listtEmployees = useListEmployees();

  const getUserName = (id: string): string => {
    return listtEmployees.find(({ value }) => value === id)?.name;
  };

  const dataEventOld = data?.old;
  const dataEventNew = data?.new;

  const folderObj = event?.object as IFolder;

  const {
    isCopy,
    isEditName,
    isEdit,
    isMove,
    isEditStatus,
    isArchive,
    isIsDel,
    isShare,
    isSend,
    isEmployeeEnter,
    isUserCopy
  } = getEventType(event.event_type);

  const {
    isFile,
    isFolder,
    isTasks,
    isTask,
    isMails,
    isNotes,
    isOnlineMeeting,
    isOfflineMeeting,
    isEmails,
    isSoft,
    isReminder,
    isMyData,
    isEmployees,
    isLibrary,
    isDeps,
    isSessionEmployee
  } = getEventGroup(event.event_group, (object as IMyTask)?.id_type);

  const getStatusTextTask = (status: string) => {
    switch (status) {
      case STATUS_TYPES.IN_PROGRESS:
        return __("В работе");
      case STATUS_TYPES.QUEUE:
        return __("В очереди");
      case STATUS_TYPES.DONE:
        return __("Готово");
    }
  };

  const getStatusTextTasksOther = (status: string) => {
    switch (status) {
      case STATUS_TYPES.IN_PROGRESS:
        return __("В ожидании");
      case STATUS_TYPES.DONE:
        return __("Состоялась");
      case STATUS_TYPES.NOT_DONE:
        return __("Не состоялась");
    }
  };

  const emptyEvent = "-";

  const navigateHistoryObj = (): void => {
    const pathname = `${event.event_group.toLocaleLowerCase()}/${event.event_object}`;

    if (searchParams.get(SEARCH_PARAMS.USER_ID)) {
      const path = {
        pathname,
        search: createSearchParams({
          [SEARCH_PARAMS.USER_ID]: searchParams.get(SEARCH_PARAMS.USER_ID)
        }).toString(),
        replace: true
      };

      navigate(path, { state });
    } else {
      navigate(pathname);
    }
  };

  const navigatePathObj = (): void => {
    if (isArchive) {
      navigate(`/${ROUTES.ARCHIVE}${ROUTES_SEARCH_PARAMS.byDateChanged}`);
    }

    if (isIsDel) {
      navigate(`/${ROUTES.TRASH}${ROUTES_SEARCH_PARAMS.byDateChanged}`);
    }

    if (!isArchive && !isIsDel) {
      if (isFolder) {
        navigate(
          getTheRestorePath({
            path: `${ROUTES.FOLDERS}`,
            id_dir: undefined,
            id_parent: (object as IFolder).id_parent,
            search: `${ROUTES_SEARCH_PARAMS.byDateChanged}`
          })
        );
      }

      if (isFile) {
        navigate(`/${ROUTES.FILES}`);
      }

      if (isTasks) {
        if (isReminder) {
          navigate(`/${ROUTES.CALENDAR}`);
        } else {
          navigate(`/${ROUTES.TASKS}`);
        }
      }
    }

    if (isEmails) {
      navigate(`/${ROUTES.MAIL}`);
    }

    if (isSoft) {
      navigate(`/${ROUTES.PROGRAMS}`);
    }

    if (isLibrary) {
      navigate(`/${ROUTES.LIBRARY}`);
    }

    if (isDeps) {
      const path = {
        pathname: `/${ROUTES.LIBRARY}/${LIBRARY_ROUTES.SETTINGS}`,
        search: createSearchParams({
          [SEARCH_PARAMS.TAB]: TABS.OBJECTS
        }).toString(),
        replace: true
      };

      navigate(path, { state: { from: ROUTES.LIBRARY } });
    }

    if (isMyData) {
      navigate(`/${ROUTES.PROFILE}/${PROFILE_ROUTES.ABOUT_ME}`);
    }

    if (isEmployees) {
      navigate(`/${ROUTES.PROFILE}/${PROFILE_ROUTES.EMPLOYEES}`);
    }

    if (isSessionEmployee) {
      navigate(`/${ROUTES.PROFILE}/${PROFILE_ROUTES.SESSEIONS}`);
    }

    setShow(true);
  };

  const renderNameObj = () => {
    if (Boolean(folderObj?.is_dir) && folderObj?.is_system === "1") {
      return SYSTEM_FOLDERS[folderObj?.name as DEFAULT_FOLDERS].nameRu;
    }

    return object?.name || (event?.data?.old as IPostbox)?.email || event?.data?.old?.name || object?.email;
  };

  const rendereEditName = (key: "old" | "new") => {
    if (isEditName) {
      return <span>{data[key].name ?? emptyEvent}</span>;
    }

    if (isEdit) {
      if (dataEventOld?.name !== dataEventNew?.name) {
        return <span>{(data[key] as IMyTask).name}</span>;
      }
    }
  };

  const rendereEditPrim = (key: "old" | "new") => {
    if (isFolder || isTasks || isLibrary) {
      if (
        ((dataEventOld as IFolder | IMyTask)?.prim &&
          getStringWithoutTags((dataEventOld as IFolder | IMyTask)?.prim)) !==
        ((dataEventNew as IFolder | IMyTask)?.prim && getStringWithoutTags((dataEventNew as IFolder | IMyTask)?.prim))
      ) {
        if ((data[key] as IFolder | IMyTask).prim) {
          return <p>{getStringWithoutTags((data[key] as IFolder | IMyTask).prim)}</p>;
        }
      }
    }
  };

  const renderEditMeeting = (key: "old" | "new") => {
    if (isOfflineMeeting || isOnlineMeeting) {
      if ((dataEventOld as IMyTask)?.id_type !== (dataEventNew as IMyTask)?.id_type) {
        return <span>{getTypeMeeting((data[key] as IMyTask).id_type)}</span>;
      }
    }
  };

  const renderEditMails = (key: "old" | "new") => {
    if (isMails) {
      if ((dataEventOld as IMyTask)?.emails !== (dataEventNew as IMyTask)?.emails) {
        return <span>{(data[key] as IMyTask).emails}</span>;
      }
    }
  };

  const renderEditUrgency = (key: "old" | "new") => {
    if (isTask) {
      if ((dataEventOld as IMyTask)?.id_act !== (dataEventNew as IMyTask)?.id_act) {
        return <span>{getSelectUrgency((data[key] as IMyTask).id_act)}</span>;
      }
    }
  };

  const renderEditDepName = (key: "old" | "new") => {
    if (isTasks && !isNotes && !isReminder) {
      if ((dataEventOld as IMyTask)?.id_dep !== (dataEventNew as IMyTask)?.id_dep) {
        return <span>{depName((data[key] as IMyTask).id_dep)}</span>;
      }
    }
  };

  const renderEditDate = (key: "old" | "new") => {
    if (isTasks && !isNotes) {
      if (
        (dataEventOld as IMyTask)?.date_start !== (dataEventNew as IMyTask)?.date_start ||
        (dataEventOld as IMyTask)?.date_end !== (dataEventNew as IMyTask)?.date_end
      ) {
        const { date, time } = getConvertFormatDateTime({
          dateStart: (data[key] as IMyTask)?.date_start,
          dateEnd: isTask ? (data[key] as IMyTask)?.date_end : undefined,
          locale: lang
        });

        return <span>{`${date}, ${time}`}</span>;
      }
    }
  };

  const renderEditTag = (key: "old" | "new") => {
    if (isFolder || isFile || isTasks) {
      if ((dataEventOld as TObjWithAppearance)?.tags !== (dataEventNew as TObjWithAppearance)?.tags) {
        return (
          <span>
            {(data[key] as TObjWithAppearance).tags ? `#${(data[key] as TObjWithAppearance).tags}` : emptyEvent}
          </span>
        );
      }
    }
  };

  const renderEditColor = (key: "old" | "new") => {
    if (isFolder || isFile || isTasks) {
      if ((dataEventOld as TObjWithAppearance)?.color !== (dataEventNew as TObjWithAppearance)?.color) {
        if ((data[key] as TObjWithAppearance)?.color) {
          return (
            <span
              className={styles.eventList__itemColor}
              style={{
                background: (data[key] as TObjWithAppearance)?.color
              }}
            />
          );
        }
      }
    }
  };

  const renderEditEmo = (key: "old" | "new") => {
    if (isFolder || isFile || isTasks) {
      if ((dataEventOld as TObjWithAppearance)?.emo !== (dataEventNew as TObjWithAppearance)?.emo) {
        if ((event.data[key] as TObjWithAppearance).emo) {
          return <span className={styles.emo} dangerouslySetInnerHTML={{ __html: "&#129300;" }} />;
        }
      }
    }
  };

  const renderEditStatus = (key: "old" | "new") => {
    if (isTasks) {
      if ((dataEventOld as IMyTask)?.id_status !== (dataEventNew as IMyTask)?.id_status) {
        return (
          <span>
            {(isTask && getStatusTextTask((data[key] as IMyTask)?.id_status)) ||
              (isTasks && !isTask && !isNotes && getStatusTextTasksOther((data[key] as IMyTask)?.id_status))}
          </span>
        );
      }
    }

    if (isMyData) {
      if ((dataEventOld as IUserInfo)?.status !== (dataEventNew as IUserInfo)?.status) {
        const status = (data[key] as IUserInfo)?.status;

        return <span>{userStatuses.find((user) => user.status === status)?.text}</span>;
      }
    }
  };

  const renderEditMove = (key: "old" | "new") => {
    if (isFolder || isFile) {
      return (
        <span>
          {(key === "old" && renderPath(data?.from)) ?? emptyEvent}
          {(key === "new" && renderPath(data?.to)) ?? emptyEvent}
        </span>
      );
    }
  };

  const renderEditShare = (key: "old" | "new") => {
    if (dataEventOld?.share?.length !== dataEventNew?.share?.length) {
      return data[key]?.share.map(({ id }) => <span key={id}>{getUserName(id) ?? emptyEvent}</span>);
    }

    if (dataEventOld?.share?.length === dataEventNew?.share?.length) {
      if (dataEventOld?.share.some(({ id }, index) => id !== dataEventNew?.share[index].id)) {
        return data[key]?.share.map(({ id }) => <span key={id}>{getUserName(id) ?? emptyEvent}</span>);
      }
    }

    return emptyEvent;
  };

  const renderEditSend = (key: "old" | "new") => <span>{data[key]?.send ?? emptyEvent}</span>;

  const renderEdtitUserData = (key: "old" | "new") =>
    (isMyData || isEmployees) && (
      <>
        {/* Name change */}
        {(dataEventOld as IUserInfo)?.fname !== (dataEventNew as IUserInfo)?.fname && (
          <span>{(data[key] as IUserInfo).fname}</span>
        )}
        {/* Last name change */}
        {(dataEventOld as IUserInfo)?.sname !== (dataEventNew as IUserInfo)?.sname && (
          <span>{(data[key] as IUserInfo).sname}</span>
        )}
        {/* Change of middle name */}
        {(dataEventOld as IUserInfo)?.pname !== (dataEventNew as IUserInfo)?.pname && (
          <span>{(data[key] as IUserInfo).pname}</span>
        )}
        {/* Change phone */}
        {(dataEventOld as IUserInfo)?.tel !== (dataEventNew as IUserInfo)?.tel && (
          <span>{(data[key] as IUserInfo).tel}</span>
        )}
        {/* Change email */}
        {(dataEventOld as IUserInfo)?.email !== (dataEventNew as IUserInfo)?.email && (
          <span>{(data[key] as IUserInfo).email}</span>
        )}
        {/* Change language */}
        {(dataEventOld as IUserInfo)?.lang !== (dataEventNew as IUserInfo)?.lang && (
          <span>{(data[key] as IUserInfo).lang}</span>
        )}
        {/* Added email in emails */}
        {(dataEventOld as IUserInfo)?.emails?.length !== (dataEventNew as IUserInfo)?.emails?.length &&
          (data[key] as IUserInfo)?.emails?.map((email, index) => <span key={index}>{email ?? emptyEvent}</span>)}
        {/* Change emails */}
        {(dataEventOld as IUserInfo)?.emails?.length === (dataEventNew as IUserInfo)?.emails?.length &&
          (dataEventOld as IUserInfo)?.emails?.some(
            (email, index) => email !== (dataEventNew as IUserInfo)?.emails[index]
          ) &&
          (data[key] as IUserInfo)?.emails?.map((email, index) => <span key={index}>{email ?? emptyEvent}</span>)}
        {/* Added tel in tels */}
        {(dataEventOld as IUserInfo)?.tels?.length !== (dataEventNew as IUserInfo)?.tels?.length &&
          (data[key] as IUserInfo)?.tels?.map((email, index) => <span key={index}>{email ?? emptyEvent}</span>)}
        {/* Change tels */}
        {(dataEventOld as IUserInfo)?.tels?.length === (dataEventNew as IUserInfo)?.tels?.length &&
          (dataEventOld as IUserInfo)?.tels?.some((tel, index) => tel !== (dataEventNew as IUserInfo)?.tels[index]) &&
          (data[key] as IUserInfo)?.tels?.map((tel, index) => <span key={index}>{tel ?? emptyEvent}</span>)}
      </>
    );

  const renderEdtitUserRights = (key: "old" | "new") =>
    isEmployees && (
      <>
        {/* Rigths change */}
        {Object.entries((dataEventOld as IUserInfo)?.rights)
          .filter(([access, value]) => value !== (dataEventNew as IUserInfo)?.rights[access as EMPLOYEE_RIGHTS])
          .map(([access], index) => {
            const isRights: boolean = (data[key] as IUserInfo)?.rights[access as EMPLOYEE_RIGHTS];

            return <span key={index}>{isRights ? employeeRights[access as EMPLOYEE_RIGHTS] : emptyEvent}</span>;
          })}
      </>
    );

  const renderEditRubric = (key: "old" | "new") => {
    if (isLibrary) {
      if ((dataEventOld as IFolder)?.id_dep !== (dataEventNew as IFolder)?.id_dep) {
        const foundRubric = rubrics.find(({ id }) => id === (data[key] as IFolder)?.id_dep);
        const defaultRubric = foundRubric?.name as Exclude<LibraryTypesRubrics, LibraryTypesRubrics.ALL_RUBRICS>;

        return (
          <span>
            {foundRubric?.is_system === "1" ? rubricsLibraryDefault[defaultRubric]?.title : foundRubric?.name}
          </span>
        );
      }
    }
  };

  const renderEditChapter = (key: "old" | "new") => {
    if (isLibrary) {
      if ((dataEventOld as IFolder)?.chapter !== (dataEventNew as IFolder)?.chapter) {
        const chapter = (data[key] as IFolder)?.chapter as LibraryCategoryTypes;

        return <span>{categoriesLibrary[chapter]?.title}</span>;
      }
    }
  };

  return (
    <div className={styles.wrapp}>
      <ul className={styles.eventList}>
        <li className={styles.eventList__item}>
          <span className={styles.eventList__label}>{__("Группа")}</span>
          <span className={styles.eventList__value}>{groupElement[event?.event_group]}</span>
        </li>
        {data?.admin?.id && (
          <li className={styles.eventList__item}>
            <span className={styles.eventList__label}>{__("Инициатор")}</span>
            <span className={styles.eventList__value}>{getUserName(data.admin.id)}</span>
          </li>
        )}
        {!isMyData && !isEmployees && !isSessionEmployee && (
          <li className={styles.eventList__item}>
            <span className={styles.eventList__label}>{__("Объект")}</span>
            <span className={styles.eventList__value}>{renderNameObj()}</span>
          </li>
        )}
        {(isEmployees || isSessionEmployee) && (
          <li className={styles.eventList__item}>
            <span className={styles.eventList__label}>{__("Сотрудник")}</span>
            <span className={styles.eventList__value}>{getUserName(object?.id)}</span>
          </li>
        )}
        <li className={styles.eventList__item}>
          <span className={styles.eventList__label}>{__("Дата")}</span>
          <span className={styles.eventList__value}>{formatDate(event?.date, "full")}</span>
        </li>
        <li className={styles.eventList__item}>
          <span className={styles.eventList__label}>{__("Действие")}</span>
          <span className={classNames(styles.eventList__value, styles.eventList__value_action)}>
            {actionElement[event?.event_type]}
          </span>
        </li>
        {(isFile || isFolder) && (
          <li className={styles.eventList__item}>
            <span className={styles.eventList__label}>{__("Размер")}</span>
            <span className={styles.eventList__value}>{calculateSize((object as IFolder | IFile)?.size)}</span>
          </li>
        )}
        {isCopy && (
          <>
            <li className={styles.eventList__item}>
              <span className={styles.eventList__label}>{__("Оригинал")}</span>
              <span className={styles.eventList__value}>{renderPath(data?.from)}</span>
            </li>
            <li className={styles.eventList__item}>
              <span className={styles.eventList__label}>{__("Копия")}</span>
              <span className={styles.eventList__value}>{renderPath(data?.to)}</span>
            </li>
          </>
        )}
        {(isMove || isEditName || isEdit || isEditStatus || isShare || isSend) && (
          <>
            <li className={styles.eventList__item}>
              <span className={styles.eventList__label}>{__("Было")}</span>
              <div className={classNames(styles.eventList__value, styles.eventList__value_old)}>
                {(isEdit || isEditName) && rendereEditName("old")}
                {isEdit && rendereEditPrim("old")}
                {isEdit && renderEditMeeting("old")}
                {isEdit && renderEditMails("old")}
                {isEdit && renderEditUrgency("old")}
                {isEdit && renderEditDepName("old")}
                {isEdit && renderEditDate("old")}
                {isEdit && renderEditTag("old")}
                {isEdit && renderEditColor("old")}
                {isEdit && renderEditEmo("old")}
                {isEdit && renderEdtitUserData("old")}
                {isEdit && renderEdtitUserRights("old")}
                {isEdit && renderEditChapter("old")}
                {isEdit && renderEditRubric("old")}
                {(isEditStatus || isEdit) && renderEditStatus("old")}
                {isMove && renderEditMove("old")}
                {isShare && renderEditShare("old")}
                {isSend && renderEditSend("old")}
              </div>
            </li>
            <li className={styles.eventList__item}>
              <span className={styles.eventList__label}>{__("Стало")}</span>
              <div className={classNames(styles.eventList__value, styles.eventList__value_new)}>
                {(isEdit || isEditName) && rendereEditName("new")}
                {isEdit && rendereEditPrim("new")}
                {isEdit && renderEditMeeting("new")}
                {isEdit && renderEditMails("new")}
                {isEdit && renderEditUrgency("new")}
                {isEdit && renderEditDepName("new")}
                {isEdit && renderEditDate("new")}
                {isEdit && renderEditTag("new")}
                {isEdit && renderEditColor("new")}
                {isEdit && renderEditEmo("new")}
                {isEdit && renderEdtitUserData("new")}
                {isEdit && renderEdtitUserRights("new")}
                {isEdit && renderEditChapter("new")}
                {isEdit && renderEditRubric("new")}
                {(isEditStatus || isEdit) && renderEditStatus("new")}
                {isMove && renderEditMove("new")}
                {isShare && renderEditShare("new")}
                {isSend && renderEditSend("new")}
              </div>
            </li>
          </>
        )}
        {isEmployeeEnter && (
          <li className={styles.eventList__item}>
            <span className={styles.eventList__label}>{__("Причина")}</span>
            <div className={styles.eventList__value}>
              <span>{event?.text}</span>
            </div>
          </li>
        )}
        {isUserCopy && (
          <li className={styles.eventList__item}>
            <span className={styles.eventList__label}>{__("Подробно")}</span>
            <div className={styles.eventList__value}>
              <span>{tableAccess[event?.data?.table]}</span>
            </div>
          </li>
        )}
        <li className={styles.eventList__item}>
          <span className={styles.eventList__label}>{__("Сессия")}</span>
          <div className={styles.eventList__value}>
            <span>
              {event?.device_info?.device_type} {event?.device_info?.platform} ({event?.device_info?.browser})
            </span>
            <span>
              {event?.ip_info?.city}, {event?.ip_info?.country} ({event?.ip_info?.query})
            </span>
          </div>
        </li>
      </ul>
      <div className={styles.btns}>
        {!actionLogId && (
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("История объекта")}
            onClick={navigateHistoryObj}
          />
        )}
        {Boolean(event.is_exists) && (
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            onClick={navigatePathObj}
            text={__("Смотреть объект")}
          />
        )}
      </div>
    </div>
  );
};
