import { getStorageItem } from "generalComponents/Services/storageServices";
import { TYPE_TOOLS } from "generalComponents/variables/global";
import { TypeElements } from "models/typeElements/typeElements";

export enum EMPLOYEE_RIGHTS {
  IS_MGR_ORG = "IS_MGR_ORG",
  IS_MGR_EMPLOYEE = "IS_MGR_EMPLOYEE",
  IS_MGR_FINANCE = "IS_MGR_FINANCE",
  IS_MGR_LIBRARY = "IS_MGR_LIBRARY",
  IS_MGR_PROJECT = "IS_MGR_PROJECT",
  IS_FILES = "IS_FILES",
  IS_SAFE = "IS_SAFE",
  IS_MAIL = "IS_MAIL",
  IS_PROJECT = "IS_PROJECT",
  IS_CHAT = "IS_CHAT",
  IS_ARCHIVE = "IS_ARCHIVE",
  IS_LIBRARY = "IS_LIBRARY",
  IS_JOURNAL = "IS_JOURNAL"
}

export type UserRights = Record<EMPLOYEE_RIGHTS, boolean>;

export enum USER_STATUS {
  ONLINE = "online",
  BUSY = "busy",
  MOVED_AWAY = "moved_away",
  OFFLINE = "offline"
}

export enum STATUS_COLOR {
  ONLINE = "#34C6A2",
  BUSY = "#F3DE5E",
  MOVED_AWAY = "#F3A413",
  OFFLINE = "#869A95"
}

export type TUserInfoStorage = {
  [key in TYPE_TOOLS]: number;
};

export interface IUserInfo {
  lang: string;
  id: string;
  name: string;
  date_last: string;
  date_created: string;
  date_send?: string;
  ctime?: string;
  dtime?: string;
  dashboardSettings?: string;
  tabletDashboardSettings?: string;
  dashboard_project_statistic: string[];
  theme: string;
  chat_theme: string;
  notify: boolean;
  is_admin: number;
  id_company: string;
  company: boolean;
  fname: string;
  pname: string;
  sname: string;
  email: string;
  prim: string;
  tel: string;
  total: number;
  used: number;
  free: number;
  gmt: string;
  icon: string;
  trash_minutes_del: number;
  rights: UserRights;
  tels: string[];
  emails: string[];
  pass: string;
  uid: string;
  is_block: number;
  status: USER_STATUS;
  status_color: STATUS_COLOR;
  storage?: Record<TYPE_TOOLS, number>;
  is_del?: number;
  is_archive?: number;
  table?: TypeElements;
  is_gmt_auto: boolean;
}

export interface IContact {
  id: string;
  name: string;
  tel: string[];
  email: string[];
  id_user: string;
  is_fav: number;
  bdate: string;
  sname: string;
  company: string;
  prim: string;
  ut: string;
  soc: ISocialNetwork[];
  mes: ISocialNetwork[];
  id_real_user: string;
  date_message: string;
  job_title: string;
  is_user: number;
  icon: string;
  real_user_date_last: string;
  real_user_date_gmt: number;
  is_online: number;
}

export interface IUserStorage {
  total: number;
  id_user: string;
  icon: string;
  name: string;
  email: string;
  fname: string;
  sname: string;
  pname: string;
  storage?: Record<TYPE_TOOLS, number>;
}

export interface ISocialNetwork {
  type: string;
  link: string;
}

export interface ITag {
  id: string;
  id_company: string | null;
  id_user: string;
  name: string;
}

export interface IUserState {
  uid: string;
  userInfo: IUserInfo;
  employees: IUserInfo[];
  contacts: IContact[];
  users_storage: IUserStorage[];
  tags: ITag[];
}

export function initialUserState(): IUserState {
  return {
    uid: "",
    userInfo: {
      lang: getStorageItem("lang") ?? "",
      name: "",
      date_created: "",
      theme: "blue",
      chat_theme: "white",
      notify: false,
      is_admin: 1,
      id_company: "",
      company: false,
      fname: "",
      sname: "",
      email: "",
      prim: "",
      tel: "",
      free: 0,
      total: 0,
      used: 0,
      gmt: "",
      icon: "",
      trash_minutes_del: Number(getStorageItem("trash_minutes_del")) ?? 0,
      pname: "",
      rights: null,
      tels: [],
      emails: [],
      id: "",
      pass: "",
      uid: "",
      date_last: "",
      is_block: 0,
      status: USER_STATUS.OFFLINE,
      status_color: STATUS_COLOR.OFFLINE,
      is_del: 1,
      dashboard_project_statistic: [],
      is_gmt_auto: false
    },
    employees: [],
    users_storage: [],
    contacts: [],
    tags: []
  };
}
