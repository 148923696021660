import classNames from "classnames";
import DownArrow from "generalComponents/DownArrow/DownArrow";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { colorCategoryProps, taskTypes } from "types/Project";

import { TasksProvider } from "../Tasks";
import UserTaskList from "../UserTaskList/UserTaskList";
import styles from "./RolesTasks.module.sass";

const RolesTasks = ({ name, group, color, variant }) => {
  const { __ } = useLocales();
  const [isOpenUser, setIsOpenUser] = useState(false);
  const { isOpenAll } = useContext(TasksProvider);

  useEffect(() => {
    setIsOpenUser(isOpenAll);
  }, [isOpenAll]);

  const getAmount = useMemo(() => (group ? Object.values(group).flat().length : 0), [group]);

  const toggleOpenUser = () => (getAmount > 0 ? setIsOpenUser((open) => !open) : null);

  return (
    <div className={styles.box}>
      <>
        <div className={classNames(styles.roleName, { [styles.active]: isOpenUser && getAmount > 0 })}>
          <span>
            {name !== "0" ? name : __("Без роли")}&nbsp;({getAmount})
          </span>
          <DownArrow isOpen={isOpenUser && getAmount > 0} clickHandler={toggleOpenUser} />
        </div>
        {isOpenUser && group && (
          <>
            {Object.entries(group).map(([userId, tasks]) => (
              <UserTaskList key={userId} tasks={tasks} userId={userId} color={color} variant={variant} />
            ))}
          </>
        )}
      </>
    </div>
  );
};

export default RolesTasks;

RolesTasks.propTypes = {
  name: PropTypes.string,
  group: PropTypes.objectOf(PropTypes.arrayOf(taskTypes)),
  color: colorCategoryProps,
  variant: PropTypes.string
};
