import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import { useEffect, useState } from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./Slider.module.sass";

export interface Slide {
  title: string;
  text: string;
  src: string;
  alt: string;
  size: "cover" | "contain";
  position: string;
  isMargin?: boolean;
}

const Slider = ({
  data,
  activeSlideIndex,
  autoplay = true
}: {
  data: Slide[];
  activeSlideIndex?: number;
  autoplay?: boolean;
}): JSX.Element => {
  const [swiper, setSwiper] = useState(null);

  const onSwiper = (swiper: any) => {
    setSwiper(swiper);
  };

  useEffect(() => {
    if (swiper && activeSlideIndex !== undefined) {
      swiper.slideTo(activeSlideIndex);
    }
  }, [activeSlideIndex, swiper]);

  return (
    <>
      <Swiper
        className={styles.slider}
        pagination={{
          el: "#pagination",
          clickable: true
        }}
        spaceBetween={30}
        effect={"flip"}
        modules={[Pagination, Autoplay]}
        autoplay={autoplay ? { delay: 6000, disableOnInteraction: false } : false}
        loop={true}
        onSwiper={onSwiper}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className={styles.slide}>
            <div className={styles.frame} />
            <h4>{item.title}</h4>
            <p>{item.text}</p>
            <div
              className={styles.image}
              style={{
                backgroundImage: `url('${item.src}')`,
                backgroundPosition: item.position,
                backgroundSize: item.size,
                marginLeft: item?.isMargin ? "36px" : null
              }}
            />
          </SwiperSlide>
        ))}
        <div className={styles.pagination}>
          <div id="pagination" />
        </div>
      </Swiper>
    </>
  );
};

export default Slider;
