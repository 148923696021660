import { ReactComponent as FilterIcon } from "assets/PrivateCabinet/filter.svg";
import { ReactComponent as SearchIcon } from "assets/PrivateCabinet/search-4.svg";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { EMOJIS, SIGNS } from "generalComponents/variables/elements";
import { ELEMENTS_FILTER, IElementsFilter, IElementsFilterProps } from "models/project/elementsFilter";
import React, { useRef, useState } from "react";
import { useLocales } from "react-localized";

import styles from "./ElementsFilter.module.sass";

const ElementsFilter: React.FC<IElementsFilterProps> = ({ filters, setFilters }): JSX.Element => {
  const { __ } = useLocales();
  const [open, setOpen] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setOpen(false));

  const toggleOpen = (): void => {
    setOpen((prev) => !prev);
  };

  const changeFilter = (value: EMOJIS | SIGNS | string, key: ELEMENTS_FILTER): void => {
    setFilters((prev: IElementsFilter) => ({ ...prev, [key]: value }));
  };

  const getActive = (): boolean => {
    if (filters.COLOR || filters.EMO || filters.FIGURE || filters.TAG || open) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.filterBlock}>
        <ButtonIcon handleClick={toggleOpen} variant="grey" icon={<FilterIcon />} isActive={getActive()} />
        {open && (
          <div className={styles.wrap}>
            <div className={styles.filters}>
              {/* <Colors color={filters.COLOR} setColor={(color) => changeFilter(color, ELEMENTS_FILTER.COLOR)} />
              <Signs sign={filters.FIGURE} setSign={(value) => changeFilter(value, ELEMENTS_FILTER.FIGURE)} />
              <Emoji emoji={filters.EMO} setEmoji={(value) => changeFilter(value, ELEMENTS_FILTER.EMO)} /> */}
              <div className={styles.inputBox}>
                <SearchIcon />
                <input
                  className={styles.input}
                  value={filters.TAG}
                  onChange={(e) => changeFilter(e.target.value, ELEMENTS_FILTER.TAG)}
                  type="text"
                  placeholder={__("Поиск ссылки по тэгу")}
                />
                {filters.TAG && (
                  <button className={styles.clear} type="button" onClick={() => changeFilter("", ELEMENTS_FILTER.TAG)}>
                    <span className={styles.span} />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ElementsFilter;
