import { TypeElements } from "models/typeElements/typeElements";

import { IFolder } from "../folders/foldersStore";

export interface ISafe {
  id: string;
  id_user: string;
  ut: string; // Date
  atime?: string;
  ctime?: string;
  cdate?: string;
  dtime?: string;
  mtime?: string;
  name: string;
  pass: string;
  is_del: number;
  token: string;
  tags: string;
  id_emo: string;
  id_color: string;
  id_fig: string;
  code_tmp: string;
  is_archive: number;
  is_my: number;
  link: string;
  shares: any[];
  date_open: string; // Date
  size: string;
  prim: string;
  time_blocked: string;
  is_system: number;
  is_share: number;
  table?: "" | TypeElements;
}

export interface IAuthorized {
  id_safe: string;
  pass: string;
  code: string;
  deadlineCode: number;
}

export interface ISafeState {
  safeList: ISafe[];
  authorized: IAuthorized[];
  folders: IFolder[];
}

export function initialSafeState(): ISafeState {
  return {
    safeList: [],
    authorized: [],
    folders: []
  };
}
