import classNames from "classnames";
import { useMenuViewTabs } from "collections/joinProject";
import { ViewType } from "generalComponents/variables/global";
import { MouseEvent } from "react";

import styles from "./ViewTasksTabs.module.sass";

export const ViewTasksTabs = ({
  viewTab,
  setViewTab
}: {
  viewTab: ViewType;
  setViewTab: React.Dispatch<React.SetStateAction<ViewType>>;
}) => {
  const tabs = useMenuViewTabs();

  const onSelectedTab = (e: MouseEvent<HTMLButtonElement>): void => {
    const { value } = e.currentTarget;

    setViewTab(value as ViewType);
  };

  return (
    !!tabs?.length && (
      <div className={styles.btns}>
        {tabs.map(({ icon, label, name, value }) => (
          <button
            key={value}
            type="button"
            onClick={(e) => {
              onSelectedTab(e);
            }}
            title={label}
            aria-label={label}
            name={name}
            value={value}
            className={classNames(styles.btn, {
              [styles.active]: value === viewTab
            })}
          >
            {icon}
          </button>
        ))}
      </div>
    )
  );
};
