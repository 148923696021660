export enum TrashElementsTypes {
  SET_TRASH_ELEMENTS = "SET_TRASH_ELEMENTS",
  NULLIFY_TRASH_ELEMENTS = "NULLIFY_TRASH_ELEMENTS",
  SET_TOTAL_TRASH_ELEMENTS = "SET_TOTAL_TRASH_ELEMENTS",
  SET_TRASH_PAGE = "SET_TRASH_PAGE",
  RESET_TRASH_ELEMENTS_LIST = "RESET_TRASH_FILES_LIST",
  TRASH_ELEMENTS_LOADER = "TRASH_ELEMENTS_LOADER",
  // Update
  SET_DEL_TRASH_ELEMENT = "SET_DEL_TRASH_ELEMENT",
  SET_EMPTY_TRASH_ELEMENTS = "SET_EMPTY_TRASH_ELEMENTS"
}
