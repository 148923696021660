import { useActions } from "hooks/useActions";
import React, { useContext, useEffect } from "react";
import { useLocales } from "react-localized";
import useWebSocket, { ReadyState } from "react-use-websocket";

import { IWebSocketContext, IWebSocketProvider } from "../../models/generalComponents/WebSocketProvider";
import { useUserSelectors } from "../../Store/selectors/userSelectors";

const WebSocketContext = React.createContext<IWebSocketContext>({
  socket: null,
  lastMessage: null,
  readyState: ReadyState.UNINSTANTIATED,
  sendMessage: (_) => {
    console.log("sendMessage is not activated");
  }
});

function WebSocketsProvider({ children }: IWebSocketProvider) {
  const { __ } = useLocales();
  const { uid, userInfo } = useUserSelectors();
  const {
    onAddTasksProject,
    onEditTasksProject,
    onDeleteTasksProject,
    onMoveTasksToBacklog,
    onMoveTasksFromBacklog,
    onMovedTasks
  } = useActions();
  const { sendMessage, lastMessage, readyState, getWebSocket, sendJsonMessage } = useWebSocket(
    "wss://fs2.mh.net.ua/ws/",
    {
      onOpen: () => {
        console.log(__("Соединение с websocket установлено"));

        sendJsonMessage({ action: "uid", uid, id_user: userInfo.id });
      },
      reconnectInterval: (lastAttemptNumber) => (lastAttemptNumber % 10 === 0 ? 10000 : 1000),
      onError: () => {
        console.log(__("Ошибка подключения к websocket"));
      }
    },
    !!userInfo.id
  );

  useEffect(() => {
    if (lastMessage) {
      onWebSocketsMessage(lastMessage);
    }
  }, [lastMessage]); //eslint-disable-line react-hooks/exhaustive-deps

  const onWebSocketsMessage = (e: MessageEvent) => {
    const message: any = JSON.parse(e.data);
    switch (message.action) {
      case "Ping": {
        sendMessage(JSON.stringify({ action: "Pong" }));
        break;
      }
      case "task_info": {
        message.actionType === "add" && onAddTasksProject(message.task);
        message.actionType === "edit" && onEditTasksProject(message.task);
        message.actionType === "del" && onDeleteTasksProject(message.task);
        message.actionType === "toBacklog" && onMoveTasksToBacklog(message.task);
        message.actionType === "fromBacklog" &&
          onMoveTasksFromBacklog({ tasks: message.tasks, idTask: message.idTask, idSprint: message.idSprint });
        message.actionType === "movedTasks" && onMovedTasks({ tasks: message.tasks, idSprint: message.idSprint });

        break;
      }
      default: {
        // setLastMessage(() => e.data);
        break;
      }
    }
  };

  return (
    <WebSocketContext.Provider value={{ socket: getWebSocket, lastMessage, sendMessage, readyState }}>
      {children}
    </WebSocketContext.Provider>
  );
}

export default WebSocketsProvider;
export const useWebSocketContext = () => useContext(WebSocketContext);
