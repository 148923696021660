import api from "api";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as QueueIcon } from "assets/icons/queue.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import classNames from "classnames";
import TaskFile from "containers/JoinProject/TaskFile/TaskFile";
import TaskUploadFile from "containers/JoinProject/TaskUploadFile/TaskUploadFile";
import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import Loader from "generalComponents/Loaders/4HUB";
import Tags from "generalComponents/Tags/Tags";
import { PROJECT_PATH, ROUTES } from "generalComponents/variables/routing";
import { useWebSocketContext } from "generalComponents/WebSocketsProvider/WebSocketsProvider";
import {
  useFindProjectUser,
  useFindSprint,
  useGetDuration,
  useGetTaskEnd,
  useUsersToProject
} from "hooks/joinProjectHooks";
import { useIsSystemWatherRole } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { IFile } from "models/store/files/files";
import { IProjectTask, STATUSES, TASK_PRIORITY, TASK_TYPE } from "models/store/joinProjects/joinProgects";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { dateISO } from "utils/dateToISO";

import TaskPrimEditor from "../TaskPrimEditor/TaskPrimEditor";
import TaskSidebar from "../TaskSidebar/TaskSidebar";
import TaskComments from "./TaskComments/TaskComments";
import TaskDependencies from "./TaskDependencies/TaskDependencies";
import TaskDependenciesButton from "./TaskDependenciesButton/TaskDependenciesButton";
import styles from "./TaskInfo.module.sass";

interface IProps {
  id_task: string;
  isModal: boolean;
  closeModal?: () => void;
}

const TaskInfo: FC<IProps> = ({ id_task, isModal, closeModal }) => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const {
    onSetCurrentTask,
    fetchFileZipLink,
    onSetApproveModal,
    onUpdateTaskProject,
    onSetTasksQueueModal,
    onFetchCurrentTaskProject,
    onSetTaskOverdueModal
  } = useActions();
  const { uid, theme } = useUserSelectors();
  const { project, currentTask, loader } = useJoinProjectsSelectors();
  const getDateEnd = useGetTaskEnd();
  const getDuration = useGetDuration();
  const _oneWorkDay = project.work_hours.length * 60;
  const findSprint = useFindSprint();

  const { sendMessage } = useWebSocketContext();
  const { formatDate } = useDateFormat();
  const users_to = useUsersToProject();

  const [isTask, setIsTask] = useState<boolean>(null);

  const authorInfo = useFindProjectUser(currentTask?.author);
  const [name, setName] = useState<string>("");
  const [prim, setPrim] = useState<string>("");
  const [priority, setPriority] = useState<TASK_PRIORITY>();
  const [type, setType] = useState<TASK_TYPE>();
  const [status, setStatus] = useState<string>("");
  const [team, setTeam] = useState<string>("");
  const [executor, setExecutor] = useState<string>("");
  const [sprints, setSprints] = useState<string[]>([]);
  const [duration, setDuration] = useState<number>();
  const [dateStart, setDateStart] = useState<Date>(null);
  const [dateEnd, setDateEnd] = useState<Date>(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [files4hub, setFiles4hub] = useState<IFile[]>([]);
  const [color, setColor] = useState<string>("");
  const [emoji, setEmoji] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<(IFile | { file: File; fid: string })[]>([]);

  useEffect(() => {
    if (!currentTask) return;
    setName(currentTask.name);
    setPrim(currentTask.prim);
    setPriority(currentTask.priority);
    setType(currentTask.id_type);
    setStatus(currentTask.id_status);
    setTeam(currentTask.id_team);
    setExecutor(currentTask.id_executor);
    setSprints(currentTask.id_sprints);
    setDuration(currentTask.duration);
    setDateStart(currentTask.date_start ? new Date(currentTask.date_start) : null);
    setDateEnd(currentTask.date_end ? new Date(currentTask.date_end) : null);
    setFiles(currentTask.files);
    setFiles4hub(currentTask.files_4hub);
    setColor(currentTask.color);
    setEmoji(currentTask.emo);
    setIsTask(true);
  }, [currentTask]); //eslint-disable-line

  useEffect(() => {
    const controller = new AbortController();
    onFetchCurrentTaskProject({ id_task: id_task, controller });
    return () => {
      onSetCurrentTask(null);
      controller.abort();
    };
  }, [id_task]); // eslint-disable-line

  const changeName = (e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);

  const sendTaskWS = (task: IProjectTask, type: string) =>
    sendMessage(
      JSON.stringify({
        action: "task_info",
        uid,
        users_to,
        task,
        actionType: type
      })
    );

  const updateTask = (obj: Record<string, string>) => {
    const formData = new FormData();
    formData.append("id_project", project.id);
    formData.append("id_task", id_task);
    for (let key in obj) {
      formData.append(key, obj[key]);
    }

    if ("is_del" in obj) {
      onUpdateTaskProject({
        data: formData,
        message: __("Удалено"),
        isLoader: true,
        cb: (t) => {
          sendTaskWS(t, "del");
          isModal
            ? closeModal()
            : navigate(`/${ROUTES.PROJECT}/${project.id}/${PROJECT_PATH.TASKS}`, { replace: true });
        }
      });
      return;
    }

    onUpdateTaskProject({
      data: formData,
      message: __("Сохранено"),
      isLoader: false,
      cb: (t) => sendTaskWS(t, "edit")
    });
  };

  const onSaveTask = () => {
    const fids = uploadFiles.filter((f) => (f as IFile)?.id_dir).map((f) => f.fid);
    const myFiles = uploadFiles.filter((f) => !(f as IFile)?.id_dir).map((f) => f.file);
    const formData = new FormData();
    formData.append("id_project", project.id);
    formData.append("id_task", id_task);
    formData.append("name", name);
    formData.append("prim", prim);
    fids.length && formData.append("fids", JSON.stringify(fids));
    myFiles.length && myFiles.forEach((file) => formData.append("myfile[]", file));

    onUpdateTaskProject({
      data: formData,
      message: __("Сохранено"),
      isLoader: true,
      cb: (t) => {
        sendTaskWS(t, "edit");
        setIsEdit(false);
      }
    });
  };
  const onDeleteTask = () => {
    const params = {
      titleHead: __("Удалить"),
      text: (
        <p style={{ color: "#274A42" }}>
          {__(`Вы действительно хотите удалить ${currentTask.name}?`)}
          <br />
          <span style={{ color: "#56716B" }}>{__(` Вы сможете восстановить ${currentTask.name} с корзины`)}</span>
        </p>
      ),
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: () => {
        updateTask({ is_del: "1" });
      }
    };
    onSetApproveModal({ open: true, params });
  };

  const updPriority = (v: TASK_PRIORITY) => {
    updateTask({ priority: v });
    setPriority(v);
  };

  const updType = (type: TASK_TYPE) => {
    if (type === TASK_TYPE.EPIC) {
      setDateEnd(dateStart);
      setDuration(0);
      updateTask({ id_type: type, date_end: dateISO(dateStart), duration: String(0) });
    }
    if (type === TASK_TYPE.TASK) {
      const _dateEnd = getDateEnd(dateStart, _oneWorkDay);
      setDateEnd(_dateEnd);
      setDuration(_oneWorkDay);
      updateTask({ id_type: type, date_end: dateISO(_dateEnd), duration: String(_oneWorkDay) });
    }
    setType(type);
  };

  const updStatus = (v: string) => {
    const targetStatusDone = project.statuses.find((el) => el.id === v)?.system_name === STATUSES.DONE;
    const prevStatusIsOverdue = project.statuses.find((el) => el.id === status)?.system_name === STATUSES.OVERDUE;
    const dateEnd = currentTask.date_long || currentTask.date_end;
    const isOverdue = currentTask.date_long;
    // Если из "Просроченно"  перетаскиваем не в "Готово"
    if (prevStatusIsOverdue && !targetStatusDone && dateEnd && new Date(dateEnd) < new Date()) {
      onSetTaskOverdueModal({
        open: true,
        task: currentTask,
        callbackOk: () => {
          updateTask({ id_status: v });
          setStatus(v);
        },
        callbackCancel: () => null
      });
      return;
    }
    // Если продленную(просроченную) задачу перетаскиваем в "Готово"
    if (isOverdue && targetStatusDone) {
      const time_long = getDuration(new Date(currentTask.date_end), new Date());
      const date_long = getDateEnd(new Date(currentTask.date_end), time_long);
      updateTask({ id_status: v, time_long: String(time_long), date_long: dateISO(date_long) });
      setStatus(v);
      return;
    }
    updateTask({ id_status: v });
    setStatus(v);
  };

  const updTeam = (v: string) => {
    updateTask({ id_team: v });
    setTeam(v);
  };

  const updExecutor = (v: string) => {
    updateTask({ id_executor: v });
    setExecutor(v);
  };

  const updDuration = (v: number) => {
    const _dateEnd = getDateEnd(dateStart, v);
    updateTask({ duration: String(v), date_end: dateISO(_dateEnd) });
    setDuration(v);
    setDateEnd(_dateEnd);
  };

  const updDateStart = (dateStart: Date) => {
    const dateEnd = getDateEnd(dateStart, duration);
    updateTask({ date_end: dateISO(dateEnd), date_start: dateISO(dateStart) });
    setDateStart(dateStart);
    setDateEnd(dateEnd);
  };

  const updDateEnd = (dateEnd: Date) => {
    const duration = getDuration(dateStart, dateEnd);
    updateTask({ date_end: dateISO(dateEnd), duration: String(duration) });
    setDateEnd(dateEnd);
    setDuration(duration);
  };

  const updSprints = (arr: string[]) => {
    if (arr.length === 0) {
      setDateStart(null);
      setDateEnd(null);
      setDuration(_oneWorkDay);
      updateTask({ id_sprints: JSON.stringify(arr), date_start: "", date_end: "", duration: String(_oneWorkDay) });
    }
    if (arr.length === 1) {
      const startSprint = new Date(findSprint(arr[0])?.date_start);
      const dStart = startSprint.setHours(project.work_hours[0]);
      const dEnd = getDateEnd(new Date(dStart), _oneWorkDay);
      setDateStart(new Date(dStart));
      setDateEnd(dEnd);
      setDuration(_oneWorkDay);
      updateTask({ id_sprints: JSON.stringify(arr), date_start: dateISO(new Date(dStart)), date_end: dateISO(dEnd) });
    }
    if (arr.length > 1) {
      const _sprints = project.sprints
        .filter((el) => arr.includes(el.id))
        .sort((a, b) => new Date(a.date_start).getTime() - new Date(b.date_start).getTime());
      const firstSprint = _sprints[0];
      const lastSprint = _sprints[_sprints.length - 1];
      const dStart = new Date(firstSprint?.date_start).setHours(project.work_hours[0]);

      const dEndSprint = new Date(lastSprint?.date_start).setHours(project.work_hours[0]);
      setDateStart(new Date(dStart));
      const dEnd = getDateEnd(new Date(dEndSprint), _oneWorkDay);
      setDateEnd(dEnd);
      const duration = getDuration(new Date(dStart), dEnd);
      setDuration(duration);
      updateTask({ id_sprints: JSON.stringify(arr), date_end: dateISO(dEnd), duration: String(duration) });
    }

    setSprints(arr);
  };

  const updTag = (v: string[]) => {
    updateTask({ tags: JSON.stringify(v) });
  };

  const updColor = (v: string) => {
    updateTask({ color: v });
    setColor(v);
  };

  const updEmoji = (v: string) => {
    updateTask({ emo: v });
    setEmoji(v);
  };

  const deleteFile = async (fid: string) => {
    setFiles((prev) => prev.filter((item) => item.fid !== fid));
    const params = { uid, id_task: currentTask.id, fid };
    await api.get(`/ajax/task_file_del.php`, { params });
  };

  const deleteFile4Hub = (fid: string) => {
    const fids = files4hub.filter((f) => f.fid !== fid).map((f) => f.fid);
    updateTask({ fids: JSON.stringify(fids) });
    setFiles4hub((prev) => prev.filter((f) => f.fid !== fid));
  };

  const deleteUploadFile = (fid: string) => setUploadFiles((prev) => prev.filter((item) => item.fid !== fid));

  const isSystemWatherRole = useIsSystemWatherRole();

  if (loader) {
    return (
      <div className={styles.loaderBox}>
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="transparent"
          zIndex={5}
          width="200px"
          height="200px"
          containerType="bounceDots"
        />
      </div>
    );
  }

  if (!isTask) return;

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        {!isEdit ? (
          <>
            {!currentTask.is_del && (
              <div className={styles.top}>
                <div>
                  <TaskDependenciesButton task={currentTask} disabled={isSystemWatherRole} />
                  <Tags
                    task={currentTask}
                    color={color}
                    emoji={emoji}
                    setTags={updTag}
                    setColor={updColor}
                    setEmoji={updEmoji}
                    disabled={isSystemWatherRole}
                  />
                  <Button
                    variant={ButtonVariantType.LIGHT}
                    size={ButtonSizeType.EXTRA_SMALL}
                    iconL={<QueueIcon />}
                    text={__("Очередь")}
                    disabled={sprints.length === 0 || !executor || isSystemWatherRole}
                    onClick={() => onSetTasksQueueModal({ open: true, userId: currentTask.id_executor })}
                  />
                </div>
                <div>
                  <Button
                    variant={ButtonVariantType.LIGHT}
                    size={ButtonSizeType.EXTRA_SMALL}
                    iconL={<EditIcon />}
                    onClick={() => setIsEdit(true)}
                    disabled={isSystemWatherRole}
                  />
                  <Button
                    variant={ButtonVariantType.LIGHT}
                    size={ButtonSizeType.EXTRA_SMALL}
                    iconL={<TrashIcon />}
                    onClick={onDeleteTask}
                    disabled={isSystemWatherRole}
                  />
                </div>
              </div>
            )}

            <p className={styles.title}>{name}</p>
            <div className={styles.info}>
              <span className={styles.num}>{currentTask.num}</span>
              <span>
                {__("Создана")}&nbsp;
                <span className={styles.author}>{authorInfo?.fname + " " + authorInfo?.sname}</span>
              </span>
              <span>{formatDate(currentTask.ut)}</span>
            </div>
            <div className={styles.prim} dangerouslySetInnerHTML={{ __html: prim }} />
            {currentTask?.links && <TaskDependencies dependencies={currentTask.links} />}
            {(files.length > 0 || files4hub.length > 0) && (
              <ul className={classNames(styles.fileList, `scrollbar-thin-${theme}`)}>
                {files.map((file) => (
                  <TaskFile key={file.fid} file={file} downloadFile={fetchFileZipLink} />
                ))}
                {files4hub.map((file) => (
                  <TaskFile key={file.fid} file={file} downloadFile={fetchFileZipLink} />
                ))}
              </ul>
            )}
            {!currentTask.is_del && !isSystemWatherRole && <TaskComments task={currentTask} />}
          </>
        ) : (
          <div className={styles.editor}>
            <Input value={name} onChange={changeName} label={__("Краткое содержание")} />
            <div className={styles.editorWrapper}>
              <TaskPrimEditor prim={prim} setPrim={setPrim} setUploadFiles={setUploadFiles} />
              <span className={styles.label}>{__("Описание")}</span>
            </div>

            <ul className={classNames(styles.fileList, `scrollbar-thin-${theme}`)}>
              {files.map((file) => (
                <TaskFile key={file.fid} file={file} deleteFile={deleteFile} />
              ))}
              {files4hub.map((file) => (
                <TaskFile key={file.fid} file={file} deleteFile={deleteFile4Hub} />
              ))}
              {uploadFiles.length > 0 &&
                uploadFiles.map((file) =>
                  "id_dir" in file ? (
                    <TaskFile key={file.fid} file={file} deleteFile={deleteUploadFile} />
                  ) : (
                    <TaskUploadFile key={file.fid} file={file} deleteFile={deleteUploadFile} />
                  )
                )}
            </ul>
          </div>
        )}
      </div>
      <div className={styles.right}>
        <TaskSidebar
          priority={priority}
          setPriority={updPriority}
          type={type}
          setType={updType}
          status={status}
          setStatus={updStatus}
          team={team}
          setTeam={updTeam}
          author={currentTask.author}
          executor={executor}
          setExecutor={updExecutor}
          sprints={sprints}
          setSprints={updSprints}
          duration={duration}
          setDuration={updDuration}
          dateStart={dateStart}
          setDateStart={updDateStart}
          dateEnd={dateEnd}
          setDateEnd={updDateEnd}
          readOnly={Boolean(currentTask.is_del) || isSystemWatherRole}
        />
        {isEdit && (
          <div className={styles.editBtns}>
            <Button
              variant={ButtonVariantType.LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Отменить")}
              onClick={() => {
                setName(currentTask.name);
                setPrim(currentTask.prim);
                setIsEdit(false);
              }}
            />
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={__("Сохранить")}
              onClick={onSaveTask}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskInfo;
