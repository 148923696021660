import { useProjectMsg } from "generalComponents/Services/projectServices";
import { EMOJIS, SIGNS } from "generalComponents/variables/elements";
import { useActions } from "hooks/useActions";
import { IElementsFilter } from "models/project/elementsFilter";
import { ITopMessages } from "models/store/projects/projectThunk";
import React, { useEffect, useState } from "react";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import LinkList from "./LinkList/LinkList";
import LinkServicePanel from "./LinkServicePanel/LinkServicePanel";
import styles from "./ProjectLinks.module.sass";

const ProjectLinks: React.FC = (): JSX.Element => {
  const MSG = useProjectMsg();
  const { links } = useProjectsSelectors();
  const [selectedLinks, setSelectedLinks] = useState<string[]>([]);
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const { onFetchLinks, onDeleteLinks } = useActions();
  const [filters, setFilters] = useState<IElementsFilter>({
    COLOR: null,
    EMO: EMOJIS.NO_EMOJI,
    FIGURE: SIGNS.NO_SIGN,
    TAG: ""
  });

  useEffect(() => {
    onFetchLinks(MSG.ERROR);
  }, []); //eslint-disable-line

  const handleSelect = (): void => {
    setIsSelect((prev) => !prev);
    setSelectedLinks([]);
  };

  const handleSelected = (linkId: string): void => {
    if (!isSelect) {
      return;
    }
    setSelectedLinks((prev) => {
      return prev.some((id) => id === linkId) ? prev.filter((id) => id !== linkId) : [...prev, linkId];
    });
  };

  const handleDelete = (id: string[]): void => {
    const messages: ITopMessages = {
      error: MSG.ERROR,
      success: MSG.DEL_LINK
    };
    onDeleteLinks(id, messages);
  };

  const onDelete = (): void => {
    handleDelete(selectedLinks);
    handleSelect();
  };

  return (
    <div className={styles.wrap}>
      <LinkServicePanel
        isSelect={isSelect}
        handleSelect={handleSelect}
        onDelete={onDelete}
        filters={filters}
        setFilters={setFilters}
      />
      {links.length > 0 && (
        <LinkList
          handleDelete={handleDelete}
          selectedLinks={selectedLinks}
          handleSelected={handleSelected}
          filters={filters}
        />
      )}
    </div>
  );
};

export default ProjectLinks;
