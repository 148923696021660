import { initialSortByDateState, ISortByDateState } from "models/store/sortByDate/sortByDate";
import { SortByDateActions } from "models/store/sortByDate/sortByDateActions";
import { SortByDateTypes } from "Store/types/sortByDate";

const INITIAL_STATE = initialSortByDateState();

export const SortByDateReducer = (state = INITIAL_STATE, action: SortByDateActions): ISortByDateState => {
  switch (action.type) {
    case SortByDateTypes.ADD_SORT_BY_DATE:
      return { ...state, ...action.payload };

    case SortByDateTypes.NULLIFY_SORTS_BY_DATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
