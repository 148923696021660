import { useDateFormat } from "hooks/useDateFormat";
import { ISprint } from "models/store/joinProjects/joinProgects";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

interface IProps {
  data: {
    progress: number;
    isOverdue: boolean;
    overdueDays: number;
    percentFinish: number;
    lastSprint: ISprint;
  };
}

const ProjectProgressChart: FC<IProps> = ({ data }) => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const { project } = useJoinProjectsSelectors();

  const toLeft = data.percentFinish >= 50; // определяем справа или слева выводить дату окончания
  const finishX = toLeft // положения по оси х для текста даты в зависимости от того слева или справа
    ? (data.percentFinish / 100) * 510 - 10
    : data.percentFinish > 20 // если меньше 20% фиксируем положение по оси х на 110px
    ? (data.percentFinish / 100) * 510 + 10
    : 110;
  const textAnchor = toLeft ? "end" : "start"; // выравнивание текста даты окончания в зависимости от места положения
  const minX = `${data.percentFinish > 20 ? data.percentFinish : 20}%`; // минимальное положение по оси х 20%

  return (
    <svg width="510" height="120">
      {/* шкала */}
      <path
        d="M5,110 L505,110 M55,110 L55,120 M105,110 L105,120 M155,110 L155,120 M205,110 L205,120 M255,110 L255,120 M305,110 L305,120 M355,110 L355,120 M405,110 L405,120 M455,110 L455,120 "
        stroke="#9FAEAB"
        strokeWidth="1"
      />
      {/* старт */}
      <path d="M5,50 L5,110" stroke="#869A95" strokeDasharray="6 6" strokeWidth="2" />
      <circle r="4" cx="5" cy="50" fill="white" stroke="#869A95" />
      <circle r="4" cx="5" cy="110" fill="white" stroke="#4086F1" />
      <text x="15" y="55" textAnchor="start" fill="#274A42" fontSize="14" fontWeight="500">
        {__("Старт")}
      </text>
      <text x="15" y="70" textAnchor="start" fill="#56716B" fontSize="14">
        {formatDate(project.date_start)}
      </text>

      {/* проект не просросен*/}
      {!data.isOverdue && (
        <>
          <path d="M505,50 L505,110" stroke="#869A95" strokeDasharray="6 6" strokeWidth="2" />
          <circle r="4" cx="505" cy="50" fill="white" stroke="#869A95" />
          <circle r="4" cx="505" cy="110" fill="white" stroke="#869A95" />
          <text x="490" y="55" textAnchor="end" fill="#274A42" fontSize="14" fontWeight="500">
            {__("Финиш")}
          </text>
          <text x="490" y="70" textAnchor="end" fill="#56716B" fontSize="14">
            {formatDate(project.date_end)}
          </text>
          <line x1="9" y1="110" x2={`${data.progress}%`} y2="110" stroke="#4086F1" />
          <circle r="4" cx={`${data.progress}%`} cy="110" fill="white" stroke="#4086F1" />
        </>
      )}

      {/*  проект просрочен (дата окончания последнего спринта больше даты окончания проекта*/}
      {data.isOverdue && (
        <>
          {/* вертикаль запланированого окончания */}
          <line x1={minX} y1="50" x2={minX} y2="110" stroke="#869A95" strokeDasharray="6 6" strokeWidth="2" />

          {/* вертикаль опоздания */}
          <path d="M505,10 L505,110" stroke="#CC4036" strokeDasharray="6 6" strokeWidth="2" />

          {/* текст и дата запланированого окончания */}
          <text x={finishX} y="55" textAnchor={textAnchor} fill="#274A42" fontSize="14" fontWeight="500">
            {__("Финиш")}
          </text>
          <text x={finishX} y="70" textAnchor={textAnchor} fill="#56716B" fontSize="14">
            {formatDate(project.date_end)}
          </text>

          {/* текст и дата опоздания */}
          <text x="495" y="15" textAnchor="end" fill="#274A42" fontSize="14" fontWeight="500">
            {__("Опоздание до")}
          </text>
          <text x="495" y="30" textAnchor="end" fill="#56716B" fontSize="14">
            {formatDate(data.lastSprint.date_end)}
          </text>

          {/* линия от старта до запланированого окончания */}
          <line x1="9" y1="110" x2={minX} y2="110" stroke="#4086F1" />

          {/* линия от запланированого окончания до опоздания*/}
          <line x1={minX} x2="505" y1="110" y2="110" stroke="#CC4036" />

          {/* верхний круг фактического окончания */}
          <circle r="4" cx="505" cy="10" fill="white" stroke="#CC4036" />

          {/* нижний круг фактического окончания */}
          <circle r="4" cx="505" cy="110" fill="white" stroke="#CC4036" />

          {/* верхний круг запланированог окончания */}
          <circle r="4" cx={minX} cy="50" fill="white" stroke="#869A95" />

          {/* нижний круг запланированого окончания */}
          <circle r="4" cx={minX} cy="110" fill="white" stroke="#4086F1" />
        </>
      )}
    </svg>
  );
};

export default ProjectProgressChart;
