import { isIFile } from "containers/Dashboard/DashboardItem/DashboardItem";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router";
import { getPageDep } from "utils/getPageDep";

import styles from "./FileIcon.module.sass";

const FileIcon = ({ item }: { item: IFile | IFolder }) => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const { onFileView } = useActions();

  const open = () => {
    const { _PROJECT_FILES_, _PROJECT_LIBRARY_, _PROJECT_DOC_, _LIBRARY_ } = getPageDep(item.dep);
    const projectRoute = item.id_project ? `/${ROUTES.PROJECT}/${item.id_project}` : "";
    if (item.is_dir === 0) {
      onFileView({ open: true, params: item as IFile });
    } else {
      if (_PROJECT_LIBRARY_ || _PROJECT_DOC_ || _PROJECT_FILES_) {
        if (item.is_del && item.id_project) {
          return navigate(`${projectRoute}/trash`);
        }
        if (_PROJECT_FILES_ && item.id_project) {
          return navigate(`${projectRoute}/files/${item.id_dir}`);
        }
        if (_PROJECT_LIBRARY_ && item.id_project) {
          return navigate(`${projectRoute}/library/dir/${item.id_dir}`);
        }
        if (_PROJECT_DOC_ && item.id_project) {
          return navigate(`${projectRoute}/doc/${item.id_dir}`);
        }
      } else if (_LIBRARY_) {
        return navigate(`/library/dir/${item.id_dir}`);
      }

      if (item.is_del) {
        return navigate(`/trash`);
      }
      if (item.is_archive) {
        return navigate(`/archive`);
      }
      return navigate(`/folders/${item.id_dir}`);
    }
  };

  return (
    <div className={styles.container} onClick={open}>
      <div className={styles.iconWrapper}>
        {isIFile(item) && item.mime_type && item.mime_type.startsWith("image") ? (
          <img src={item.preview} alt={item.name} className={styles.image} />
        ) : (
          <ItemIcon
            width={24}
            height={24}
            color={item.color}
            ext={isIFile(item) ? item.ext : undefined}
            passIcon={isIFile(item) && item.is_pass === 1}
            isFolder={!isIFile(item)}
          />
        )}
      </div>
      <div className={styles.text}>
        <div>{item.name}</div>
        <div>{__("В папке:")}</div>
      </div>
    </div>
  );
};

export default FileIcon;
