import { useId } from "react";

export const DependencyArrow = ({ path }: { path: string }) => {
  const id = useId();
  return (
    <g>
      <defs>
        <marker id={id} markerWidth="5" markerHeight="4" refX="0" refY="2" orient="auto" stroke="green">
          <polygon points="0 0, 2 2, 0 4" fill="green" />
        </marker>
      </defs>
      <path d={path} stroke="green" strokeWidth="2" fillOpacity="0" markerEnd={`url(#${id})`} />
    </g>
  );
};
