import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import classNames from "classnames";
import ProfileControl from "containers/Profile/ContactSingle/ProfileControl/ProfileControl";
import Avatar from "generalComponents/Avatar/Avatar";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { IContact } from "models/store/user/user";
import { Dispatch, SetStateAction } from "react";

import styles from "./ContactItem.module.sass";

interface IContactItem {
  data: IContact;
  activeContact: IContact;
  setActiveContact: Dispatch<SetStateAction<IContact>>;
}

const ContactItem = ({ data, activeContact, setActiveContact }: IContactItem) => {
  return (
    <li
      className={classNames(styles.item, activeContact?.id === data.id && styles.active)}
      onClick={() => setActiveContact(data)}
    >
      <Avatar name={data.name + " " + data.sname} size={24} imgSrc={projectSrc + data.icon} />
      <span className={styles.name}>
        {data.name} {data.sname}
      </span>
      {data.is_fav ? <StarIcon className={styles.favorite} /> : null}

      <div className={styles.buttons}>
        <ProfileControl data={data} />
      </div>
    </li>
  );
};

export default ContactItem;
