import { useContextProjecTrashElement } from "collections/joinProject";
import { isIFile } from "containers/Dashboard/DashboardItem/DashboardItem";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { calculateSize, splitFilename } from "generalComponents/Services/helpers";
import { useIsSystemWatherRole } from "hooks/joinProjectHooks";
import { ButtonSizeType } from "models/generalComponents/button";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";

import styles from "./JointProjectTrashFile.module.sass";

export const JointProjectTrashFile = ({ item }: { item: IFile | IFolder }) => {
  const { contextMenuTrashFile, contextMenuTrashFolder } = useContextProjecTrashElement();
  const renderContextMenu = () => {
    if (item?.is_dir === 1) {
      return contextMenuTrashFolder(item as IFolder);
    } else {
      return contextMenuTrashFile(item as IFile);
    }
  };

  const isSystemWatherRole = useIsSystemWatherRole();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <ItemIcon
          width={29}
          height={39}
          color={item?.color}
          ext={isIFile(item) ? item?.ext : undefined}
          isFolder={!isIFile(item)}
        />
        <div>
          <h3 className={styles.title}>{isIFile(item) ? splitFilename(item.name).name : item.name}</h3>
          <p className={styles.size}>{calculateSize(item.size)}</p>
        </div>
      </div>
      {!isSystemWatherRole && (
        <div className={styles.menuButton}>
          <PopoverContextMenu data={renderContextMenu() || []} iconVariant="button" btnSize={ButtonSizeType.SMALL} />
        </div>
      )}
    </div>
  );
};
