import { ReactComponent as BacklogIcon } from "assets/icons/backlog.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { JoinProjectSearchTasks } from "containers/JoinProject/JoinProjectSearchTasks/JoinProjectSearchTasks";
import { JointProjectSearch } from "containers/JoinProject/JointProjectSearch/JointProjectSearch";
import JointProjectStatusStatistic from "containers/JoinProject/JointProjectsTasks/components/JointProjectStatusStatistic/JointProjectStatusStatistic";
import ParticipantsButtons from "containers/JoinProject/JointProjectsTasks/components/ParticipantsButtons/ParticipantsButtons";
import { SelectSprint } from "containers/JoinProject/SelectSprint/SelectSprint";
import Button from "generalComponents/Button/Button";
import { FilterMarkers } from "generalComponents/FilterMarkers/FilterMarkers";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { ViewType } from "generalComponents/variables/global";
import { PROJECT_PATH } from "generalComponents/variables/routing";
import { useIsSystemWatherRole } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useLocation } from "react-router-dom";
import { initialsProjectTask } from "utils/jointPtoject";

import TeamButtons from "../TeamButtons/TeamButtons";
import { FilterTasksPriority } from "./components/FilterTasksPriority/FilterTasksPriority";
import { ViewTasksTabs } from "./components/ViewTasksTabs/ViewTasksTabs";
import styles from "./ProjectsTasksServicePanel.module.sass";

export const ProjectsTasksServicePanel = ({
  viewTab,
  toggleBacklog,
  setViewTab
}: {
  viewTab: ViewType;
  toggleBacklog: () => void;
  setViewTab: React.Dispatch<React.SetStateAction<ViewType>>;
}) => {
  const [show, setShow] = useState<boolean>();

  const { __ } = useLocales();
  const location = useLocation();

  const { onSetStatusModal, onSetCreateProjectTaskModal } = useActions();

  const onAddTask = () =>
    onSetCreateProjectTaskModal({ open: true, type: "add", task: { ...initialsProjectTask(), id_sprints: [] } });

  const isMyTasksPage = location.pathname.includes(PROJECT_PATH.MY_TASKS);

  const { priority, search, team, participants } = useAllSelectedSearchParams();

  const isSystemWatherRole = useIsSystemWatherRole();

  return (
    <ToolsPanel size={isMyTasksPage ? "small" : "extraLarge"}>
      <div className={styles.wrapper}>
        <div className={styles.tools}>
          <Button
            className={styles.backlogBtn}
            variant={ButtonVariantType.EXRTA_LIGHT}
            onClick={toggleBacklog}
            size={ButtonSizeType.SMALL}
            iconL={<BacklogIcon fill="#274A42" />}
          />
          <div className={styles.delimetr} />
          <FilterMarkers hide={!show} depFilter={priority.selectedValue}>
            <FilterTasksPriority
              priority={priority.selectedValue}
              onSetPriority={priority.onSelectValue}
              onSetShow={setShow}
            />
          </FilterMarkers>
          <div className={styles.delimetr} />
          <SelectSprint />
          <div className={styles.delimetr} />
          <JointProjectStatusStatistic />
          <div className={styles.delimetr} />
          <TeamButtons selectedTeam={team.selectedValue} onSelectTeam={team.onSelectValue} />

          <ViewTasksTabs viewTab={viewTab} setViewTab={setViewTab} />
          <div className={styles.btns}>
            {isMyTasksPage ? (
              <JointProjectSearch searchQuery={search.selectedValue} setSearchQuery={search.onSelectValue} />
            ) : (
              <JoinProjectSearchTasks />
            )}
            {!isSystemWatherRole && (
              <Button
                variant={ButtonVariantType.EXRTA_LIGHT}
                onClick={() => onSetStatusModal({ open: true })}
                size={ButtonSizeType.SMALL}
                iconL={<SettingsIcon fill="#274A42" />}
              />
            )}
          </div>
          {!isSystemWatherRole && (
            <Button
              variant={ButtonVariantType.BLUE}
              onClick={onAddTask}
              size={ButtonSizeType.SMALL}
              text={`+ ${__("Задача")}`}
            />
          )}
        </div>
        {!isMyTasksPage && (
          <ParticipantsButtons
            selectedParticipants={participants.selectedValue}
            onSelectParticipiant={participants.onSelectMultipleValue}
            teamId={team.selectedValue}
          />
        )}
      </div>
    </ToolsPanel>
  );
};
