import { JointProjectInfo } from "containers/JoinProject/JointProjectInfo/JointProjectInfo";
import { endOfDay } from "date-fns";
import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ICreateProjectPayload } from "models/store/joinProjects/joinProjectsPayloads";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { dateISO } from "utils/dateToISO";
import { dataURLToFileSize } from "utils/filesConvert";
import { INITIAL_WORK_DAYS, INITIAL_WORK_HOURS } from "utils/jointPtoject";
import { getCurrentTimezoneOffset } from "utils/timezone";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./CreateProject.module.sass";

const CreateProject = () => {
  const { __ } = useLocales();
  const { userInfo } = useUserSelectors();
  const { onSetCreateProjectModal, onCreateProject } = useActions();
  const closeModal = () => !isClose && onSetCreateProjectModal({ open: false });
  const { value, onChange } = useInputValue();
  const [icon, setIcon] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [work_days, setWork_days] = useState<number[]>(INITIAL_WORK_DAYS);
  const [work_hours, setWork_hours] = useState<number[]>(INITIAL_WORK_HOURS);
  const [timezone, setTimezone] = useState(userInfo.gmt.length > 0 ? userInfo.gmt : getCurrentTimezoneOffset());
  const [isClose, setIsClose] = useState<boolean>(false);
  const [autoTimezone, setAutoTimeZone] = useState<boolean>();

  const onSubmit = async () => {
    let iconFile = null;
    if (icon?.startsWith("data:image/jpeg;base64")) {
      iconFile = await dataURLToFileSize(icon);
    }
    const params: ICreateProjectPayload = {
      data: {
        name: value,
        icon: iconFile,
        date_start: startDate ? dateISO(startDate) : undefined,
        date_end: startDate ? dateISO(endOfDay(endDate)) : undefined,
        work_days: JSON.stringify(work_days),
        work_hours: JSON.stringify(work_hours),
        gmt: timezone,
        is_gmt_auto: autoTimezone
      },
      messages: {
        success: __("Новый проект создан"),
        error: __("Проект с таким названием уже есть")
      }
    };

    onCreateProject(params);
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.wrap}>
        <HeaderModal title={__("Создать проект")} onClose={closeModal} />
        <div className={styles.body}>
          <JointProjectInfo
            endDate={endDate}
            icon={icon}
            name={value}
            onChange={onChange}
            setEndDate={setEndDate}
            setIcon={setIcon}
            setStartDate={setStartDate}
            startDate={startDate}
            setIsClose={setIsClose}
            work_days={work_days}
            setWork_days={setWork_days}
            work_hours={work_hours}
            setWork_hours={setWork_hours}
            timezone={timezone}
            setTimezone={setTimezone}
            setAutoTimezone={setAutoTimeZone}
            autoTimezone={autoTimezone}
          />

          <div className={styles.footer}>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Отмена")}
              onClick={closeModal}
            />
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={__("Создать")}
              onClick={onSubmit}
              disabled={Boolean(!value)}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default CreateProject;
