import createVideoPlugin from "@draft-js-plugins/video";
import api from "api";
import { ReactComponent as ArchiveIcon } from "assets/icons/archive_to.svg";
import { ReactComponent as CameraIcon } from "assets/icons/camera.svg";
import { ReactComponent as TimeIcon } from "assets/icons/clock-2.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/microphone.svg";
import { ReactComponent as TextIcon } from "assets/icons/text.svg";
import { ReactComponent as TextsIcon } from "assets/icons/texts.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { convertToRaw, EditorState, Modifier } from "draft-js";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { initialRecordModalState, IRecordType } from "models/store/Cabinet/modals/record";
import { INote, NOTES_DEFAULT_NAMES } from "models/store/notes/notes";
import { useEffect, useState } from "react";
import { EditorState as IEditorState } from "react-draft-wysiwyg";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router";
import { useUserSelectors } from "Store/selectors/userSelectors";

const videoPlugin = createVideoPlugin();

export const useNotesButtons = () => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const { onSetNoteEditModal, onSetRecordModal, onAddNewNote } = useActions();
  const { uid } = useUserSelectors();
  const [editorState, setEditorState] = useState<IEditorState>(EditorState.createEmpty());
  const navigate = useNavigate();

  const onDownload = async (blob: Blob) => {
    const file = new File([blob], `${Date.now()}`, { type: "video/mp4" });
    try {
      const formData = new FormData();
      formData.append("uid", uid);
      formData.append("id_dir", "0");
      formData.append("myfile", file);
      formData.append("dep", "_NOTES_");
      formData.append("type_to", "mp4");
      formData.append("type_from", "vp8");
      const { data } = await api.post(`/ajax/file_add.php`, formData);
      checkResponseStatus(data.ok);

      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();

      // Создаем стиль "жирный"
      const boldStyle = editorState.getCurrentInlineStyle().add("BOLD");

      // Применяем стиль к тексту "Заметка"
      const contentStateWithText = Modifier.insertText(
        contentState,
        selectionState,
        __(`Заметка от ${formatDate(new Date(), "date")}`),
        boldStyle
      );
      const editorStateWithText = EditorState.push(editorState, contentStateWithText, "insert-characters");
      // Устанавливаем новое состояние редактора
      setEditorState(
        videoPlugin.addVideo(editorStateWithText, {
          src: `${data.preview}.mp4`
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Проверяем, не равно ли текущее состояние редактора EditorState.createEmpty()
    if (!EditorState.createEmpty().getCurrentContent().hasText() && editorState.getCurrentContent().hasText()) {
      // Если редактор содержит что либо и не является пустым, выполняем код
      const text = convertToRaw(editorState.getCurrentContent());
      onAddNewNote({ prim: JSON.stringify(text) });
      setEditorState(EditorState.createEmpty());
      onSetRecordModal(initialRecordModalState());
    }
  }, [editorState]); //eslint-disable-line

  return [
    {
      title: __("Голосовая"),
      icon: <PhoneIcon />,
      onClick: () => onSetRecordModal({ open: true, type: IRecordType.AUDIO, callBack: onDownload })
    },
    {
      title: __("Видео"),
      icon: <CameraIcon />,
      onClick: () => onSetRecordModal({ open: true, type: IRecordType.VIDEO, callBack: onDownload })
    },
    {
      title: __("Текстовая"),
      icon: <TextIcon />,
      onClick: () => onSetNoteEditModal({ open: true })
    },
    {
      title: __("Все заметки"),
      icon: <TextsIcon />,
      onClick: () => navigate(ROUTES.NOTES)
    }
  ];
};

export enum noteTitles {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_30_DAYS = "LAST_30_DAYS"
}

interface NoteTitles {
  [key: string]: noteTitles;
}

export const useNotesTitles = (): NoteTitles => {
  const { __ } = useLocales();

  return {
    [noteTitles.TODAY]: __("Сегодня"),
    [noteTitles.YESTERDAY]: __("Вчера"),
    [noteTitles.LAST_7_DAYS]: __("За последние 7 дней"),
    [noteTitles.LAST_30_DAYS]: __("За последние 30 дней")
  };
};

interface NoteCategoriesNames {
  [key: string]: NOTES_DEFAULT_NAMES;
}

export const useNotesCategoriesNames = (): NoteCategoriesNames => {
  const { __ } = useLocales();

  return {
    [NOTES_DEFAULT_NAMES.IMPORTANT]: __("Важное"),
    [NOTES_DEFAULT_NAMES.PERSONAL]: __("Личное"),
    [NOTES_DEFAULT_NAMES.WORK]: __("Работа")
  };
};

export const useContextNotes = () => {
  const { __ } = useLocales();
  const { onDeleteNote, onEditNote } = useActions();

  return {
    menu: (note: INote) => {
      return [
        {
          name: __("Журнал версий"),
          img: <TimeIcon />,
          callback: (): void => {}
        },
        {
          name: __("В архив"),
          img: <ArchiveIcon />,
          callback: (e: React.MouseEvent<HTMLButtonElement>): void => {
            e.stopPropagation();
            onEditNote({ id_item: note.id, is_archive: "1" });
          }
        },
        {
          name: __("Удалить"),
          img: <TrashIcon />,
          callback: (e: React.MouseEvent<HTMLButtonElement>): void => {
            e.stopPropagation();
            onDeleteNote({ id_item: note.id });
          }
        }
      ];
    }
  };
};
