import { useSelectLibraryRubrics } from "collections/library";
import Button from "generalComponents/Button/Button";
import PopUp from "generalComponents/PopUp/PopUp";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialRemoveNonEmptyRubricLibraryModalState } from "models/store/Cabinet/modals/removeNonEmptyRubricLibraryModal";
import { MouseEvent, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./RemovingNonEmptyRubricLibraryModal.module.sass";

export const RemovingNonEmptyRubricLibraryModal = (): JSX.Element => {
  const { __ } = useLocales();

  const { onSetRemoveNonEmptyRubricLibraryModal, onTrashLibraryRubric } = useActions();

  const {
    removeNonEmptyRubricLibraryModal: { id_dep_del }
  } = useGlobalModalsSelectors();

  const placeholder = __("Выберите");

  const [selectedRubric, setSelectedRubric] = useState<string>();

  const closeModal = (): void => {
    onSetRemoveNonEmptyRubricLibraryModal(initialRemoveNonEmptyRubricLibraryModalState());
  };

  const onSubmit = () => {
    const params = {
      id_item: id_dep_del,
      id_item_new: selectedRubric
    };

    onTrashLibraryRubric(params);

    closeModal();
  };

  const handleChange = (e: MouseEvent<HTMLButtonElement>): void => {
    const { value } = e.currentTarget;
    setSelectedRubric(value);
  };

  const optionsRubrics = useSelectLibraryRubrics().filter(({ id }) => id !== id_dep_del);
  const rubricName = useSelectLibraryRubrics().find(({ value }) => value === id_dep_del)?.name;

  return (
    <PopUp set={closeModal}>
      <HeaderModal onClose={closeModal} title={__("Удалить")} />
      <div className={styles.wrap}>
        <form className={styles.contentWrap}>
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: __(
                `Вы действительно хотите удалить <span>${rubricName}</span>? <br /> Рубрика <span>${rubricName}</span> содержит объекты, выберете раздел куда будут перемещены объекты`
              )
            }}
          ></h2>
          <div className={styles.topWrap}>
            <SelectCustom
              name="rubric"
              width={320}
              variantHeight="small"
              options={optionsRubrics}
              selectedDefault={placeholder}
              onSelected={handleChange}
            />
          </div>
          <div className={styles.buttonsWrap}>
            <Button
              type="button"
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Отменить")}
              onClick={closeModal}
            />
            <Button
              type="button"
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={__("Переместить и Удалить")}
              onClick={onSubmit}
              disabled={!selectedRubric}
            />
          </div>
        </form>
      </div>
    </PopUp>
  );
};
