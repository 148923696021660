import api from "api";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import classNames from "classnames";
import Empty from "containers/Dashboard/Empty/Empty";
import DashboardStatisticItem from "containers/Dashboard/JointProjectStatistic/DashboardStatisticItem/DashboardStatisticItem";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { IJoinProject } from "models/store/joinProjects/joinProgects";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./JointProjectStatistic.module.sass";

const JointProjectStatistic = () => {
  const { userInfo } = useUserSelectors();
  const [projectData, setProjectData] = useState<IJoinProject[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { __ } = useLocales();
  const { onSetDashboardJointProjectStatisticModal } = useActions();
  const [projectStatisticId, setProjectStatisticId] = useState<string>();

  useEffect(() => {
    const projectStatisticString = userInfo.dashboard_project_statistic?.join(",");

    if (!projectStatisticString || projectStatisticString === projectStatisticId) {
      return;
    }

    setProjectStatisticId(projectStatisticString);

    const onFetchProjectData = async () => {
      setIsLoading(true);
      const params = {
        uid: userInfo.uid,
        id_item: userInfo.dashboard_project_statistic
      };

      try {
        const { data } = await api.get(`/ajax/project_get.php`, { params });
        setProjectData(data.project);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    onFetchProjectData();
  }, [userInfo.dashboard_project_statistic]); //eslint-disable-line

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{__("Совместный проект")}</div>
        <Button
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.EXTRA_SMALL}
          isSquare
          iconL={<SettingsIcon />}
          onClick={() => onSetDashboardJointProjectStatisticModal({ open: true })}
        />
      </div>
      {isLoading ? (
        <div className={styles.emptyWrapper}>
          <Loader
            position="absolute"
            height={"100px"}
            width={"100px"}
            type={LoaderTypes.BOUNCING_DOTS}
            containerType="bounceDots"
          />
        </div>
      ) : !projectData || projectData.length === 0 ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={classNames(styles.scrollWrapper, "scrollbar-thin-blue")}>
          <div className={styles.list}>
            {projectData?.map((item) => (
              <DashboardStatisticItem key={item.id} project={item} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default JointProjectStatistic;
