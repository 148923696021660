import classNames from "classnames";
import { FC } from "react";

import styles from "./SectionTitle.module.sass";

interface ISectionTitleProps {
  children: string;
  className?: string;
  variant?: "left" | "center";
}

export const SectionTitle: FC<ISectionTitleProps> = ({ children, className, variant = "left" }) => (
  <h2
    className={classNames(styles.title, className, {
      [styles[variant]]: variant
    })}
  >
    {children}
  </h2>
);
