export interface INote {
  emails: string;
  id: string;
  is_del: number;
  flag: string;
  name: string;
  num: string;
  pass: string;
  prim: string;
  is_archive: number;
  tags: string[];
  ut: string;
  id_dep: string;
  emo: string;
  color: string;
  table: string;
  atime?: string;
  cdate?: string;
  ctime?: string;
  mtime?: string;
  dtime?: string;
}

export enum INoteSize {
  SMALL = "1",
  MEDIUM = "2",
  LARGE = "3"
}

export enum NOTES_DEFAULT_NAMES {
  IMPORTANT = "IMPORTANT",
  WORK = "WORK",
  PERSONAL = "PERSONAL"
}

export interface INoteCategory {
  id: string;
  size: INoteSize;
  color: string;
  name: string;
  default_name: NOTES_DEFAULT_NAMES;
}

export interface INotesState {
  notes: INote[];
  activeNote: INote | null;
  categories: INoteCategory[];
}

export function initialNoteState(): INotesState {
  return {
    notes: [],
    activeNote: null,
    categories: []
  };
}
