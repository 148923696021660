import classNames from "classnames";
import TaskStatus from "containers/JoinProject/TaskSidebar/TaskStatus/TaskStatus";
import Avatar from "generalComponents/Avatar/Avatar";
import { useWebSocketContext } from "generalComponents/WebSocketsProvider/WebSocketsProvider";
import { useFindParticipant, useGetDuration, useGetTaskEnd, useUsersToProject } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { IProjectTask, STATUSES } from "models/store/joinProjects/joinProgects";
import { FC, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { dateISO } from "utils/dateToISO";

import styles from "./GantTask.module.sass";

interface IProps {
  task: IProjectTask;
  hoverRow: string;
  setHoverRow: (v: string) => void;
}
export const GantTask: FC<IProps> = ({ task, hoverRow, setHoverRow }) => {
  const { __ } = useLocales();
  const { onSetProjectTaskInfoModal, onSetTaskOverdueModal, onUpdateTaskProject } = useActions();
  const { project } = useJoinProjectsSelectors();
  const { uid } = useUserSelectors();

  const getDateEnd = useGetTaskEnd();
  const getDuration = useGetDuration();

  const user = useFindParticipant();

  const executor = user(task.id_executor);
  const { sendMessage } = useWebSocketContext();
  const users_to = useUsersToProject();

  const [status, setStatus] = useState<string>(task.id_status);

  const onMouseEnter = () => setHoverRow(task.id);
  const onMouseLeave = () => setHoverRow("");

  const onOpenTask = () => onSetProjectTaskInfoModal({ open: true, task });

  const sendTaskWS = (task: IProjectTask, type: string) =>
    sendMessage(
      JSON.stringify({
        action: "task_info",
        uid,
        users_to,
        task,
        actionType: type
      })
    );

  const updateTask = (obj: Record<string, string>) => {
    const formData = new FormData();
    formData.append("id_project", project.id);
    formData.append("id_task", task.id);
    for (let key in obj) {
      formData.append(key, obj[key]);
    }

    onUpdateTaskProject({
      data: formData,
      message: __("Сохранено"),
      isLoader: false,
      cb: (t) => sendTaskWS(t, "edit")
    });
  };

  const updStatus = (v: string) => {
    const targetStatusDone = project.statuses.find((el) => el.id === v)?.system_name === STATUSES.DONE;
    const prevStatusIsOverdue = project.statuses.find((el) => el.id === status)?.system_name === STATUSES.OVERDUE;
    const dateEnd = task.date_long || task.date_end;
    const isOverdue = task.date_long;
    // Если из "Просроченно"  перетаскиваем не в "Готово"
    if (prevStatusIsOverdue && !targetStatusDone && dateEnd && new Date(dateEnd) < new Date()) {
      onSetTaskOverdueModal({
        open: true,
        task: task,
        callbackOk: () => {
          updateTask({ id_status: v });
          setStatus(v);
        },
        callbackCancel: () => null
      });
      return;
    }
    // Если продленную(просроченную) задачу перетаскиваем в "Готово"
    if (isOverdue && targetStatusDone) {
      const time_long = getDuration(new Date(task.date_end), new Date());
      const date_long = getDateEnd(new Date(task.date_end), time_long);
      updateTask({ id_status: v, time_long: String(time_long), date_long: dateISO(date_long) });
      setStatus(v);
      return;
    }
    updateTask({ id_status: v });
    setStatus(v);
  };

  return (
    <div
      className={classNames(styles.row, { [styles.active]: task.id === hoverRow })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.name} onClick={onOpenTask}>
        <span className={styles.num}>{task.num}</span>
        &nbsp;{task.name}
      </div>
      <div className={styles.executor}>
        {executor && <Avatar name={`${executor.fname} ${executor.sname}`} imgSrc={executor.icon} size={22} isRound />}
      </div>
      <div className={styles.status}>
        <TaskStatus status={status} setStatus={updStatus} />
      </div>
    </div>
  );
};
