export const isEmail = (input: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(input);
};

export const isPhoneNumber = (input: string): boolean => {
  const isValidPhoneNumber = /^\+?[0-9]{9,15}$/.test(input);
  return isValidPhoneNumber;
};

export const maxSizeFileRule = (files: FileList | File[], maxSize: number): boolean =>
  Array.from(files).reduce((items, item) => items + item.size, 0) >= maxSize;

export const requiredRule = (value: string): boolean => !value?.trim();
