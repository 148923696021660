import { HOT_KEYS } from "generalComponents/variables/global";
import { RefObject, useEffect, useRef } from "react";

export const useKeysHotPress = (
  ref: RefObject<HTMLDivElement>,
  key: HOT_KEYS,
  combinationKey: null | HOT_KEYS.ALT | HOT_KEYS.CTRL | HOT_KEYS.META | HOT_KEYS.SHIFT,
  callback: () => void
) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const rootRef = ref?.current;

    const handler = (event: KeyboardEvent) => {
      if (
        ((!combinationKey || event[combinationKey]) && event.code === key) ||
        (combinationKey && event[combinationKey] && !key)
      ) {
        callbackRef.current();
      }
    };

    rootRef.addEventListener("keydown", handler);

    return () => rootRef.removeEventListener("keydown", handler);
  }, [key, combinationKey]); // eslint-disable-line
};
