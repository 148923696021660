import { IUserInfo } from "models/store/user/user";

export interface ITransferDutiesModalState {
  open: boolean;
  employee?: IUserInfo;
}

export function initialTransferDutiesModalState(): ITransferDutiesModalState {
  return { open: false, employee: null };
}
