import api from "api";
import Button from "generalComponents/Button/Button";
import DownArrow from "generalComponents/DownArrow/DownArrow";
import Colors from "generalComponents/Elements/Colors";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onAddCategory, onEditCategory } from "Store/actions/ProjectActions";

import styles from "./AddCategory.module.sass";

const AddCategory = ({ onClose, params, type }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const uid = useSelector((s) => s.user.uid);

  const MSG = useProjectMsg();
  const [value, setValue] = useState(params.name ?? "");
  const [color, setColor] = useState(params.color ?? "");
  const [categories, setCategories] = useState([]);
  const [showList, setShowList] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setShowList(false));

  useEffect(() => {
    onFetchCategories();
  }, []); // eslint-disable-line

  const onFetchCategories = async () => {
    try {
      const { data } = await api.get(`/ajax/task_category_list.php`, { params: { uid } });
      checkResponseStatus(data.ok);
      const categories = data.tasks_category.map((el) => el.name);
      setCategories(Array.from(new Set(categories)));
    } catch (error) {
      console.log(error);
    }
  };

  const toggleShowList = () => setShowList((prev) => !prev);

  const selectValue = (value) => {
    setValue(value);
    toggleShowList();
  };

  const handleChange = ({ target }) => {
    setValue(target.value);
    setShowList(false);
  };

  const onSubmit = () => {
    const messages = {
      error: MSG.ERROR,
      success:
        type === PROJECT_MODALS.ADD_CATEGORY ? MSG[PROJECT_MODALS.ADD_CATEGORY] : MSG[PROJECT_MODALS.EDIT_CATEGORY]
    };
    const payload = {
      name: value,
      color: color
        ? color
        : {
            dark: "#CBCBCB",
            light: "#F2F2F2",
            line: "#D8D8D8",
            secondary: "#E3E3E3",
            name: "lightGrey"
          }
    };
    type === PROJECT_MODALS.ADD_CATEGORY
      ? dispatch(onAddCategory(payload, messages))
      : dispatch(onEditCategory({ ...payload, id: params.id }, messages));
  };

  return (
    <div className={styles.wrap}>
      <span className={styles.subTitle}>{__("Введите имя категории или выберите из списка")}</span>
      <div className={styles.selectBlock} ref={ref}>
        <input className={styles.input} value={value} onChange={handleChange} />
        <DownArrow isOpen={showList} clickHandler={toggleShowList} />
        {showList && (
          <div className={styles.selectList}>
            {categories.map((item, i) => (
              <div key={i} className={styles.item} onClick={() => selectValue(item)}>
                {item}
              </div>
            ))}
          </div>
        )}
      </div>
      <Colors
        title={__("Выберите цвет категории")}
        color={color}
        setColor={(value) => setColor(value)}
        variant="category"
      />
      <div className={styles.btns}>
        <Button type="button" variant="cancel" onClick={onClose} text={__("Отмена")} />
        <Button type="button" variant="ok" onClick={onSubmit} text={__("Готово")} disabled={!value} />
      </div>
    </div>
  );
};

export default AddCategory;

AddCategory.propTypes = {
  onClose: PropTypes.func,
  params: PropTypes.exact({
    name: PropTypes.string,
    id: PropTypes.string,
    id_user: PropTypes.string,
    color: PropTypes.string
  }),
  type: PropTypes.string
};
