import classNames from "classnames";
import { useContextFile } from "collections/myFiles";
import { useContextFolder } from "collections/myFolders";
import { useContextSafeFile, useContextSafeFolder } from "collections/safe";
import { useGetItemSize } from "generalComponents/Hooks/Files/useGetItemSize";
import { useSetFolderData } from "generalComponents/Hooks/useSetSelectFolder";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { useAllFolders, useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { ROUTES, SHARED_PARAMS } from "generalComponents/variables/routing";
import { useSafeFind } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { CheckBoxVariantTypes } from "models/generalComponents/checkBox";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useGetIconSize } from "Pages/Cabinet/Components/WorkElements/Hooks/useGetIconSize";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";

import LinesIcon from "../../LinesIcon/LinesIcon";
import styles from "./LinesPreviewItem.module.sass";

interface ILinesPreviewItemProps {
  item: IFile | IFolder;
}

const LinesPreviewItem: React.FC<ILinesPreviewItemProps> = ({ item }) => {
  const { onFileView, onSetCurrentItem } = useActions();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { dirId, safeId } = useParams();
  const [_, setSearchParams] = useSearchParams();
  const { isShared } = useIsShared();
  const allFolders = useAllFolders();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const currentSafe = useSafeFind();
  const { setFolderData } = useSetFolderData();
  const { folderPath } = useFoldersSelectors();
  const { contextMenuFileCallback } = useContextFile();
  const { contextMenuFolderCallback } = useContextFolder();
  const { contextMenuSafeFolderCallback, contextMenuSafeFolderToMeCallback } = useContextSafeFolder();
  const { contextMenuSafeFile, contextMenuSafeFileToMe } = useContextSafeFile();

  const { isMediumSize, isBigSize } = useGetItemSize();
  const { getFolderIconWidth } = useGetIconSize();
  const {
    pickedItems: { items },
    currentItem
  } = useFilesSelectors();
  const setElementType = (): CheckBoxVariantTypes.NO_TYPE | CheckBoxVariantTypes.CHECKED => {
    return items.some((element) =>
      item.is_dir === 1 ? element.id_dir === item.id_dir : (element as IFile).fid === (item as IFile).fid
    )
      ? CheckBoxVariantTypes.CHECKED
      : CheckBoxVariantTypes.NO_TYPE;
  };

  const [checkBoxOption, setCheckBoxOption] = useState<CheckBoxVariantTypes>(setElementType());
  const [_isCheckedOption] = useState<boolean>(checkBoxOption === CheckBoxVariantTypes.CHECKED);

  const handleClick = (e: React.MouseEvent<HTMLLIElement>): void => {
    switch (e.detail) {
      case 1:
        if (item.is_dir === 1) {
          if (isShared && item.my === 0) {
            setSearchParams({ [SHARED_PARAMS.DIR]: item.id_dir });
            return;
          }
          if (isSafePage) {
            navigate(`/${ROUTES.SAFE}/${safeId}/${item.id_dir}${search}`, { state: { from: pathname } });
            return;
          }
          setFolderData(item as IFolder);
          navigate(`/${ROUTES.FOLDERS}/${item.id_dir}${search}`, { state: { from: pathname } });
          onSetCurrentItem(null);
        } else {
          const pathFolder = allFolders.find((f) => f.id_dir === dirId);
          onSetCurrentItem(item);
          if (pathFolder?.is_system === "0") {
            const fileFolder = allFolders.find((f) => f.id_dir === item.id_dir);
            setFolderData(fileFolder);
            navigate(`/${ROUTES.FOLDERS}/${item.id_dir}${search}`, { state: { from: pathname } });
          }
        }
        break;

      case 2:
        if (item.is_dir === 0) {
          onFileView({ open: true, params: item as IFile });
        }
        break;
    }
  };

  const isActive = (item: IFolder | IFile): boolean => {
    if (item.is_dir === 1) {
      return folderPath.some((el) => el.id_dir === item.id_dir);
    }
    if (item.is_dir === 0) {
      return (currentItem as IFile)?.fid === (item as IFile).fid;
    }
  };

  const onMouseEnterItem = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.DEFAULT);
  };

  const onMouseLeaveItem = (): void => {
    if (_isCheckedOption) return;
    setCheckBoxOption(CheckBoxVariantTypes.NO_TYPE);
  };

  const renderFolderContext = () => {
    if (isSafePage) {
      return currentSafe?.is_my
        ? contextMenuSafeFolderCallback(item as IFolder)
        : contextMenuSafeFolderToMeCallback(item as IFolder);
    }
    return contextMenuFolderCallback(item as IFolder);
  };

  const renderFileContext = () => {
    if (isSafePage) {
      return currentSafe?.is_my ? contextMenuSafeFile(item as IFile) : contextMenuSafeFileToMe(item as IFile);
    }
    return contextMenuFileCallback(item as IFile);
  };

  return (
    <li
      className={classNames(styles.item, {
        [styles.active]: isActive(item),
        [styles.isMediumSize]: isMediumSize,
        [styles.isBigSize]: isBigSize
      })}
      onClick={handleClick}
      onMouseEnter={onMouseEnterItem}
      onMouseLeave={onMouseLeaveItem}
    >
      <div className={styles.itemIconWrapper} style={{ width: getFolderIconWidth() }}>
        <LinesIcon item={item} checkBoxOption={checkBoxOption} setCheckBoxOption={setCheckBoxOption} />
      </div>
      <div className={styles.itemName}>{item.name}</div>
      <div className={styles.itemDescriptionGroup}>
        {item.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: item.emo }} />}
      </div>
      <div className={styles.popoverWrapper}>
        <PopoverContextMenu data={item.is_dir ? renderFolderContext() : renderFileContext()} />
      </div>
    </li>
  );
};

export default LinesPreviewItem;
