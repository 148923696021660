import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { projectProps } from "types/Project";

import styles from "./ChooseFile.module.sass";

const ChooseFile = ({ onClose, params, callback, onAddFile }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const [chosenFile] = useState(null);

  const onSubmit = () => {
    if (onAddFile) {
      onAddFile(chosenFile);
      return;
    }

    dispatch(
      onSetModals(MODALS.PROJECT, {
        type: PROJECT_MODALS.AVATAR_CROP,
        callback: callback,
        params: { ...params, project_icon: chosenFile.preview }
      })
    );
  };

  return (
    <div className={classNames(styles.wrap, { [styles.additional]: onAddFile })}>
      {onAddFile && <div className={styles.header}>{__("Выбирите файл")}</div>}

      <div className={styles.btns}>
        <Button type="button" variant="cancel" onClick={onClose} text={__("Отмена")} />
        <Button type="button" variant="ok" onClick={onSubmit} text={__("Выбрать")} />
      </div>
    </div>
  );
};

export default ChooseFile;

ChooseFile.propTypes = {
  onClose: PropTypes.func,
  params: projectProps,
  callback: PropTypes.string,
  onAddFile: PropTypes.func
};
