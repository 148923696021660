import { ReactComponent as FolderIcon } from "assets/PrivateCabinet/folder.svg";
import { ReactComponent as FolderDoc } from "assets/PrivateCabinet/folderDoc.svg";
import { ReactComponent as ImagesIcon } from "assets/PrivateCabinet/folderImages.svg";
import { ReactComponent as MusicIcon } from "assets/PrivateCabinet/folderMusic.svg";
import { ReactComponent as FolderUploaded } from "assets/PrivateCabinet/folderUpload.svg";
import { ReactComponent as VideoIcon } from "assets/PrivateCabinet/folderVideo.svg";
import { DEFAULT_FOLDERS, IDefaultFolders } from "models/generalComponents/folders";
import { useLocales } from "react-localized";

export const useSystemFolder = (): Record<DEFAULT_FOLDERS, IDefaultFolders> => {
  const { __ } = useLocales();
  return {
    [DEFAULT_FOLDERS.ALL]: {
      nameRu: __("Общая папка"),
      icon: <FolderIcon />
    },
    [DEFAULT_FOLDERS.VIDEO]: {
      nameRu: __("Фильмы"),
      icon: <VideoIcon />
    },
    [DEFAULT_FOLDERS.MUSIC]: {
      nameRu: __("Музыка"),
      icon: <MusicIcon />
    },
    [DEFAULT_FOLDERS.IMAGES]: {
      nameRu: __("Изображения"),
      icon: <ImagesIcon />
    },
    [DEFAULT_FOLDERS.DOCS]: {
      nameRu: __("Документы"),
      icon: <FolderDoc />
    },
    [DEFAULT_FOLDERS.UPLOADED]: {
      nameRu: __("Загруженные"),
      icon: <FolderUploaded />
    }
  };
};
