import api from "api";
import { ReactComponent as DotsIcon } from "assets/PrivateCabinet/dots-horizontal-square.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import CheckBoxNew from "generalComponents/CheckBoxNew/CheckBoxNew";
import Clue from "generalComponents/Clue/Clue";
import SignItem from "generalComponents/Elements/SignItem";
import TempSort from "generalComponents/FilesServicePanel/TempSort/TempSort";
import FileTags from "generalComponents/FileTags/FileTags";
import { FilterMarkers } from "generalComponents/FilterMarkers/FilterMarkers";
import FolderPath from "generalComponents/FolderPath/FolderPath";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import Loader from "generalComponents/Loaders/4HUB";
import PopUp from "generalComponents/PopUp/PopUp";
import SearchField from "generalComponents/SearchField/SearchField";
import { useFolderPath } from "generalComponents/Services/folderServices/folderServices";
import { calculateSize, splitFilename } from "generalComponents/Services/helpers";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { useDebounce } from "hooks/useDebounce";
import { useIsLocalFiltersMarkers, useIsLocalSortCriteria } from "hooks/useIsFilters";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { FiltersFields, FiltersSources } from "models/store/userFilters/userFiltersPayload";
import { MouseEvent, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useFilterCriteriaSelector } from "Store/selectors/filterCriteriaSelectors";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useSortCriteriaSelector } from "Store/selectors/sortCriteriaSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { v4 as uuid } from "uuid";

import styles from "./SelectFile.module.sass";

const SelectFile = () => {
  const { __ } = useLocales();
  const {
    onSetPage,
    onFileLoader,
    onSetMyFiles,
    onNullifyFiles,
    onSetTotalItems,
    onSetPickedItems,
    fetchUserFilters,
    onResetFilesList,
    onSetSelectFileModal,
    onSetFolderPassModal,
    onSetTopMessageModal,
    onNullifyUserFilters,
    onNullifyFilterCriteria,
    onNullifySortCriteria
  } = useActions();
  const getFolderPath = useFolderPath();
  const { uid } = useUserSelectors();
  const {
    sortCriteria: { group, sort, sort_reverse }
  } = useSortCriteriaSelector();

  const { filterCriteria } = useFilterCriteriaSelector();

  const {
    selectFile: { callback }
  } = useGlobalModalsSelectors();

  const {
    uploadFiles: { replacementFile },
    pickedItems: { items, isLeftSide, isRightSide }
  } = useFilesSelectors();

  const { formatDate } = useDateFormat();
  const { ref, inView } = useInView({
    threshold: 0.5
  });

  const isLocalFiltersMarkers: boolean = useIsLocalFiltersMarkers();
  const isLocalSortCriteria: boolean = useIsLocalSortCriteria();

  const { folders } = useFoldersSelectors();
  const { files, loader, page, total } = useFilesSelectors();

  const [folderPath, setFolderPath] = useState<IFolder[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<IFolder>(null);
  const [selectedPass, setSelectedPass] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const closeModal = () => {
    onSetPickedItems({
      open: false,
      items: [],
      isLeftSide,
      isRightSide
    });
    onSetSelectFileModal({ open: false, callback: null });
  };

  const onSelectFolder = (e: MouseEvent<HTMLElement>, folder: IFolder): void => {
    e.stopPropagation();
    setFolderPath(getFolderPath(folder));
    setSelectedFolder(folder);
    setSelectedPass("");
  };
  const fetchFiles = async (pass?: string, isReset?: boolean) => {
    try {
      onFileLoader(true);
      !isReset && onResetFilesList();
      const params = {
        uid: uid,
        id_dir: selectedFolder.id_dir,
        pass: selectedFolder.pass ? pass || selectedPass : undefined,
        search: search ? search : undefined,
        per_page: PER_PAGE,
        page: page,
        emo: filterCriteria.emo || undefined,
        color: filterCriteria.color || undefined,
        tags: filterCriteria.tags.length ? filterCriteria.tags : undefined,
        sort_reverse,
        sort,
        group: 0
      };
      const { data } = await api.get("/ajax/file_list.php", { params, signal: controller?.signal });
      onSetMyFiles(data.files);
      onSetTotalItems(data.total);
    } catch {
      if (!controller.signal.aborted) onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    } finally {
      onFileLoader(false);
    }
  };

  useEffect(() => {
    fetchUserFilters({
      sources: [FiltersSources.FILES],
      fields: [FiltersFields.COLORS, FiltersFields.EMOJIS, FiltersFields.SIGNS, FiltersFields.TAGS]
    });

    return () => {
      onNullifyUserFilters();
      onNullifyFilterCriteria();
      onNullifySortCriteria();
    };
  }, []); // eslint-disable-line

  const onClickCheckBox = (file: IFile): void => {
    onSetPickedItems({
      open: false,
      items: items.find((item) => (item as IFile)?.fid === file.fid)
        ? items.filter((item) => (item as IFile)?.fid !== file.fid)
        : !replacementFile
        ? [...items, file]
        : [file],
      isLeftSide,
      isRightSide
    });
  };

  const controller = new AbortController();

  useEffect(() => {
    if (!selectedFolder) return;
    if (selectedFolder?.pass && !selectedPass) {
      onSetFolderPassModal({
        open: true,
        type: "enterPassword",
        folder: selectedFolder,
        callbackOk: (pass) => {
          setSelectedPass(pass);
          fetchFiles(pass);
        }
      });
      return;
    }
    fetchFiles();

    return () => {
      onNullifyFiles();
      controller.abort();
    };
  }, [selectedFolder, group, sort, sort_reverse, filterCriteria.color, filterCriteria.emo, filterCriteria.tags.length]); // eslint-disable-line

  useEffect(() => {
    if (Math.ceil(total / PER_PAGE) <= page) return;

    if (inView) {
      onSetPage(page + 1);
      fetchFiles(undefined, true);
    }
  }, [inView]); //eslint-disable-line

  const debounceCallback = useDebounce(fetchFiles, 300);

  useEffect(() => {
    if (selectedFolder) {
      debounceCallback();
    }
    return () => {
      controller.abort();
    };
  }, [search]); //eslint-disable-line

  const handleClick = (file: IFolder | IFile) => {
    if (file.is_dir === 0) return;
    setFolderPath(getFolderPath(file as IFolder));
    setSelectedFolder(file as IFolder);
    setSelectedPass("");
  };

  const renderFile = (file: IFile | IFolder) => {
    const isChecked = items.some((item) => (item as IFile).fid === (file as IFile).fid);
    return (
      <li key={uuid()} className={styles.file}>
        <div
          className={classNames(styles.block, {
            [styles.checked]: isChecked
          })}
          onDoubleClick={() => handleClick(file)}
        >
          <div className={styles.context}>
            <DotsIcon />
          </div>
          {((file as IFile).tags || file.emo || file.fig) && (
            <div className={styles.symbols}>
              {file.tags.length > 0 && (
                <div className={styles.tags}>
                  <FileTags item={file} />
                </div>
              )}
              <div className={styles.emoFigWrapper}>
                {file.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: file.emo }} />}
                {file.fig && <SignItem sign={file.fig} size={12} />}
              </div>
            </div>
          )}

          <div className={styles.checkbox}>
            {file.is_dir === 0 && (
              <CheckBoxNew isChecked={isChecked} setIsChecked={() => onClickCheckBox(file as IFile)} />
            )}
          </div>
          <div className={styles.iconBox}>
            <ItemIcon
              isFolder={file.is_dir === 1}
              width={file.is_dir === 1 ? 44 : 33}
              height={file.is_dir === 1 ? 33 : 44}
              color={file.color}
              ext={(file as IFile).ext}
            />
          </div>
          <div className={styles.name}>{file.is_dir === 1 ? file.name : splitFilename(file.name).name}</div>

          <div className={styles.bottom}>
            <div>{calculateSize(file.size)}</div>
            <div>{formatDate(file.ctime)}</div>
          </div>
        </div>
      </li>
    );
  };

  const renderMyFiles = () => {
    if (files) {
      if (total === 0) {
        return isLocalSortCriteria && isLocalFiltersMarkers ? (
          <Clue icon="sad" text={__("Нет файлов соответствующих параметрам фильтрации")} />
        ) : (
          <Clue text={__("Тут пока пусто")} icon="neutral" />
        );
      }
      return <ul className={styles.files}>{Array.isArray(files) && files.map((file) => renderFile(file as IFile))}</ul>;
    }
  };

  const onSubmit = () => {
    callback(items);
    closeModal();
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.wrap}>
        <div className={styles.body}>
          <div className={styles.folders}>
            <FolderPath folders={folders} folderPath={folderPath} onSelect={onSelectFolder} currentFolder={undefined} />
          </div>
          <div className={styles.space}>
            <div className={styles.header}>
              <div>
                <TempSort />
                <FilterMarkers />
              </div>
              <SearchField value={search} setValue={setSearch} placeholder={__("Введите")} />
            </div>
            <div className={styles.container}>
              {loader && page === 1 ? (
                <Loader
                  type={LoaderTypes.BOUNCING_DOTS}
                  position="absolute"
                  background="white"
                  zIndex={5}
                  width="100px"
                  height="100px"
                  containerType="bounceDots"
                />
              ) : (
                <>
                  {renderMyFiles()}
                  {loader ? (
                    <div className={styles.loaderBox}>
                      <Loader
                        type={LoaderTypes.BOUNCING_DOTS}
                        position="absolute"
                        background="white"
                        zIndex={5}
                        width="100px"
                        height="100px"
                        containerType="bounceDots"
                      />
                    </div>
                  ) : (
                    <div ref={ref} className={styles.observer} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            type="button"
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("Отмена")}
            onClick={closeModal}
          />
          <Button
            type="button"
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={__("Применить")}
            onClick={onSubmit}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default SelectFile;
