import api from "api";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import PopUp from "generalComponents/PopUp/PopUp";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import Switcher from "generalComponents/Switcher/Switcher";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { initialDashboardJointProjectStatisticState } from "models/store/Cabinet/modals/dashboardJointProjectStatistic";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./DashboardJointProjectStatisticModal.module.sass";

const DashboardJointProjectStatisticModal = () => {
  const { userInfo } = useUserSelectors();
  const { onUserInfo } = useActions();
  const { projectList } = useJoinProjectsSelectors();
  const [activeProjects, setActiveProjects] = useState<string[]>(userInfo.dashboard_project_statistic);
  const [isLoading, setIsLoading] = useState(false);
  const { __ } = useLocales();
  const { onSetDashboardJointProjectStatisticModal } = useActions();

  const closeModal = () => {
    onSetDashboardJointProjectStatisticModal(initialDashboardJointProjectStatisticState());
  };

  const handleConfirm = async () => {
    const formData = new FormData();
    formData.append("uid", userInfo.uid);
    formData.append("dashboard_project_statistic", JSON.stringify(activeProjects));

    try {
      setIsLoading(true);
      const res = await api.post(`/ajax/user_edit2.php`, formData);
      checkResponseStatus(res.data.ok);
      onUserInfo(res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const toggleProject = (projectId: string) => {
    const updatedProjects = activeProjects?.includes(projectId)
      ? activeProjects.filter((id) => id !== projectId)
      : [...(activeProjects || []), projectId];

    setActiveProjects(updatedProjects);
  };

  if (!projectList) return;

  return (
    <PopUp set={closeModal} position="top">
      <HeaderModal onClose={closeModal} title={__("Настройка виджета - Статистика проекта")} />
      <div className={styles.container}>
        {isLoading ? (
          <Loader
            position="absolute"
            containerType="bounceDots"
            width={"100px"}
            height={"100px"}
            type={LoaderTypes.BOUNCING_DOTS}
            background="white"
            animation={false}
          />
        ) : (
          <>
            <div className={classNames(styles.content, "scrollbar-thin-blue")}>
              <h6>{__("Отображаемые проекты")}</h6>
              {projectList?.map((project) => (
                <div key={project.id} className={styles.item}>
                  <Switcher
                    checked={activeProjects?.includes(project.id)}
                    onClickCheckBox={() => toggleProject(project.id)}
                  />
                  <span>{project.name}</span>
                </div>
              ))}
            </div>
            <div className={styles.buttons}>
              <Button
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.SMALL}
                text={__("Отмена")}
                onClick={closeModal}
              />
              <Button
                variant={ButtonVariantType.BLUE}
                size={ButtonSizeType.SMALL}
                text={__("Применить")}
                onClick={handleConfirm}
              />
            </div>
          </>
        )}
      </div>
    </PopUp>
  );
};

export default DashboardJointProjectStatisticModal;
