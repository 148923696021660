import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { ROUTES } from "generalComponents/variables/routing";
import { useAuthorizedSafe, useSafeFind } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ILoadingFiles } from "models/store/files/uploadFilesModals/uploadFiles";
import { ICreateSafeFile } from "models/store/safe/safePayloads";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import styles from "./LoadItem.module.sass";

export interface ILoadItemProps {
  file: ILoadingFiles;
}

const LoadItem: FC<ILoadItemProps> = ({ file }): JSX.Element => {
  const {
    onUploadFile,
    setCreateFile,
    onCreateProjectDocFile,
    onUploadSafeFile,
    onCreateSafeFile,
    onUploadProjectFile,
    onReplaceFiles
  } = useActions();
  const { safeId } = useParams();
  const currentSafe = useSafeFind();
  const authorizedSafe = useAuthorizedSafe();
  const isProject = useCurrentPathStarstWith(ROUTES.PROJECT);

  useEffect(() => {
    const controller = new AbortController();
    if (isProject) {
      if (!file.done) {
        file.create ? onCreateProjectDocFile(file, controller) : onUploadProjectFile(file, controller);
      }
    } else {
      if (!file.done) {
        if (safeId) {
          const createPayload: ICreateSafeFile = {
            safe: currentSafe,
            code: authorizedSafe.code
          };
          file.create ? onCreateSafeFile(createPayload, file, controller) : onUploadSafeFile(file, safeId, controller);
          return;
        }
        file.create
          ? setCreateFile(file, controller)
          : file.replace
          ? onReplaceFiles({ file }, controller)
          : onUploadFile(file, controller);
      }
    }
    return () => controller.abort();
  }, []); // eslint-disable-line

  return (
    <li className={styles.item}>
      <ItemIcon isFolder={false} width={22} height={30} color={file.options.color} ext={file.options.ext} />
      <div className={styles.name}>{file.options.name}</div>
      {file.done ? (
        <CheckIcon width={22} className={styles.checkIcon} />
      ) : (
        <div
          className={styles.progress}
          style={{
            background: `conic-gradient(#4086F1 ${file.percent}%,#D2D2D2  ${file.percent}%)`
          }}
        />
      )}
    </li>
  );
};

export default LoadItem;
