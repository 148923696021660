import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export const useSafeSelectors = () => {
  const safe = useTypeSelector((s) => s.Safe);
  const safeList = safe.safeList;
  const authorized = safe.authorized;
  const safeFolders = safe.folders;

  return {
    safeList,
    authorized,
    safeFolders
  };
};
