import { initialProgramsState, IProgramsState } from "models/store/programs/programs";
import { ProgramsActions } from "models/store/programs/programsActions";
import { ProgramsTypes } from "Store/types/programsTypes";

const INITIAL_STATE: IProgramsState = initialProgramsState();

export const ProgramsReducer = (state = INITIAL_STATE, action: ProgramsActions): IProgramsState => {
  switch (action.type) {
    case ProgramsTypes.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };

    case ProgramsTypes.GET_PROGRAMS_BY_CAT:
      return {
        ...state,
        programs: action.payload
      };
    case ProgramsTypes.SET_SEARCH_PROGRAMS:
      return {
        ...state,
        searchedPrograms: action.payload
      };
    case ProgramsTypes.CLEAR_SEARCH_PROGRAMS:
      return {
        ...state,
        searchedPrograms: []
      };
    case ProgramsTypes.IS_LOADING_SEARCH_PROGRAMS:
      return {
        ...state,
        isSearchProgramsLoading: action.payload
      };
    case ProgramsTypes.IS_LOADING_GET_PROGRAMS_BY_CAT:
      return {
        ...state,
        isGetProgramsByCatLoading: action.payload
      };
    case ProgramsTypes.CHANGE_FAVORITE: {
      const idx = state.programs.findIndex((el) => el.id === action.payload.id);
      const programs = [...state.programs];
      programs.splice(idx, 1, action.payload);
      return {
        ...state,
        programs
      };
    }

    default:
      return state;
  }
};
