import classNames from "classnames";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IProjectTask } from "models/store/joinProjects/joinProgects";
import { INote } from "models/store/notes/notes";
import { IMyTask } from "models/store/tasks/tasks";
import { ITag } from "models/store/user/user";
import { useState } from "react";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./FileTags.module.sass";

export const getTagById = (tagId: string, tags: ITag[]) => {
  return tags.find((tag) => tag.id === tagId);
};

const FileTags = ({ item }: { item: IFile | IFolder | IMyTask | IProjectTask | INote }) => {
  const { tags } = useUserSelectors();
  const [showTooltip, setShowTooltip] = useState(false);

  const renderTags = () => {
    const activeTagId = item.tags[0];
    const activeTag = getTagById(activeTagId, tags);

    const remainingTagsCount = item.tags.length - 1;

    return (
      <div className={styles.wrapper}>
        <span className={styles.tag}>{activeTag?.name}</span>
        {remainingTagsCount > 0 && (
          <PopoverOverlay
            width={180}
            overlayContent={
              <div className={styles.tooltip}>
                {item.tags.map((id) => (
                  <span key={id} className={classNames(styles.tag, styles.tooltipTag)}>
                    {getTagById(id, tags)?.name}
                  </span>
                ))}
              </div>
            }
            show={showTooltip}
            setShow={setShowTooltip}
          >
            <span
              className={classNames(styles.tag, styles.tooltipTag)}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              +{remainingTagsCount}
            </span>
          </PopoverOverlay>
        )}
      </div>
    );
  };

  return <div>{renderTags()}</div>;
};

export default FileTags;
