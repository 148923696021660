export interface IFilterLibraryState {
  idRubric: string;
  category: string;
  search: string;
}

export function initialFilterLibraryState(): IFilterLibraryState {
  return {
    idRubric: "",
    category: "",
    search: ""
  };
}
