import { IEmploeesMemory, IMemoryState, initialMemoryState } from "models/store/memory/memory";
import { MemoryActions } from "models/store/memory/memoryActions";
import { MemoryTypes } from "Store/types/memoryTypes";

const INITIAL_STATE: IMemoryState = initialMemoryState();

export const MemoryReducer = (state = INITIAL_STATE, action: MemoryActions): IMemoryState => {
  switch (action.type) {
    case MemoryTypes.SET_MEMORY_LOADER:
      return { ...state, loader: action.payload };

    case MemoryTypes.SET_EMPLOYEES_MEMORY:
      if (state.employees) {
        return {
          ...state,
          employees: [...(state.employees as IEmploeesMemory[]), ...action.payload]
        };
      } else {
        return {
          ...state,
          employees: action.payload
        };
      }

    case MemoryTypes.SET_EMPLOYEES_MEMORY_PAGE:
      return { ...state, page: action.payload };

    case MemoryTypes.SET_TOTAL_EMPLOYEES_MEMORY:
      return { ...state, total: action.payload };

    case MemoryTypes.RESET_EMPLOYEES_MEMORY:
      return { ...state, employees: null, page: 1, total: 0 };

    case MemoryTypes.NULLIFY_MEMORY:
      return INITIAL_STATE;

    default:
      return state;
  }
};
