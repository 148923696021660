import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import TaskInfo from "containers/JoinProject/TaskInfo/TaskInfo";
import { WrapperPage } from "containers/JoinProject/WrapperPage/WrapperPage";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./ProjectTaskPage.module.sass";

const ProjectTaskPage = () => {
  const navigate = useNavigate();
  const toBack = () => navigate(-1);
  const { id_task } = useParams();

  return (
    <WrapperPage>
      <div className={styles.tools}>
        <button onClick={toBack} className={styles.button}>
          <ArrowIcon width={9} height={15} />
        </button>
      </div>
      <div className={styles.taskInfo}>
        <TaskInfo id_task={id_task} isModal={false} />
      </div>
    </WrapperPage>
  );
};

export default ProjectTaskPage;
