import { FileBox } from "generalComponents/FileBox/FileBox";
import { FolderBox } from "generalComponents/FolderBox/FolderBox";
import { IElementsItemProps } from "models/myFolders/workElements";
import { FilesNextArrayType, IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { FC } from "react";
import { v4 as uuid } from "uuid";

import styles from "./BarsPreviewList.module.sass";

const BarsPreviewList: FC<IElementsItemProps> = ({ items }): JSX.Element => {
  return (
    <ul className={styles.list}>
      {(items as FilesNextArrayType).map((item: IFile | IFolder) => (
        <li key={uuid()}>
          {item.is_dir === 1 ? <FolderBox folder={item as IFolder} /> : <FileBox file={item as IFile} />}
        </li>
      ))}
    </ul>
  );
};

export default BarsPreviewList;
