export enum MODALS {
  NO_MODAL = "",
  ERROR = "error",
  SUCCESS = "success",
  LOADER = "loader",
  SHARE = "share",
  FILE_PREVIEW = "previewFile",
  PRINT_SCREEN = "printScreen",
  TOP_MESSAGE = "topMessage",
  CONTEXT_MENU_MODAL = "contextMenuModals",
  FILE_ACCESS_RIGHTS = "fileAccessRights",
  TASKS = "taskModals",
  LIBRARY = "libraryModals",
  MAIL = "mailModals",
  CALENDAR = "calendarModals",
  PROJECT = "projectModals",
  CHAT = "chatModals",
  CREATE_FOLDER = "createFolder",
  APPROVE = "approveModal"
}

export const TOP_MESSAGE_TYPE = {
  ERROR: "error",
  MESSAGE: "message"
};

// SORT FILES
export enum SortFiles {
  NAME = "byName",
  GROUP = "mtime",
  SIZE = "bySize",
  TYPE = "byType",
  TAGS = "byTags"
}

export const NO_ELEMENT = -1;

export const TYPES = {
  DATE: "date",
  ARRAY: "array",
  STRING: "string",
  NUMBER: "number",
  BOOLEAN: "boolean",
  NULL: "null",
  UNDEFINED: "undefined",
  FUNCTION: "function",
  OBJECT: "object"
};

export const MIDNIGHT = "00:00:00";
export const MAILBOX_DOMAIN = "@fs2.mh.net.ua";

export const FILE_ACCESS_RIGHTS = {
  API_SHARED_FILES_USER_LIST: "/ajax/file_share_list.php",
  API_DELETE_USER_ACCESS_RIGHTS: "/ajax/file_share_del.php",
  API_ADD_USER_ACCESS_RIGHTS: "/ajax/file_share.php"
};

export const SHARED_ACCESS_RIGHTS = {
  WATCH: "WATCH",
  EDIT: "EDIT",
  DOWNLOAD: "DOWNLOAD"
};

export const ACCESS_RIGHTS_GRANTED = "1";
export const ACCESS_RIGHTS_FORBIDDEN = "0";

export enum ViewType {
  BARS = "bars",
  LINES = "lines",
  BARS_PREVIEW = "preview",
  LINES_PREVIEW = "workLinesPreview"
}

export enum PAGE_DEPTH {
  LIBRARY = "_LIBRARY_",
  PROJECT_LIBRARY = "_PROJECT_LIBRARY_",
  PROJECT_DOC = "_PROJECT_DOC_",
  PROJECT_FILES = "_PROJECT_FILES_",
  FILES = "_FILES_"
}

export enum SEARCH_PARAMS {
  TAB = "tab",
  USER_ID = "user_id",
  CHAPTER = "chapter",
  COLOR = "color",
  EMO = "emo",
  TAGS = "tags",
  SEARCH_QUERY = "search_query",
  PRIORITY = "priority",
  SPRINT = "sprint",
  PARTICIPANTS = "participants",
  TEAM = "team",
  SORT = "sort",
  GROUP = "group",
  SORT_REVERS = "sort_reverse",
  TABLE = "table",
  ID_TYPE = "id_type",
  YEAR = "year",
  MONTH = "month",
  DAY = "day",
  ID_DEP = "id_dep"
}

export enum CLEANING_TIME {
  HALF_AN_HOUR = "5",
  HALF_MONTH = "21600",
  MONTH = "43200",
  QUARTER = "129600"
}

export enum SORTING_TYPE {
  NAME = "byName",
  DATE_CREATED = "byDateCreated",
  DATE_CHANGED = "byDateChanged",
  SIZE = "bySize",
  TYPE = "byType"
}

export enum HOT_KEYS {
  META = "metaKey",
  CTRL = "ctrlKey",
  SHIFT = "shiftKey",
  KEY_A = "KeyA",
  ALT = "altKey"
}

export enum TYPE_ELEMENTS {
  FOLDERS = "FOLDERS",
  FILES = "FILES",
  LIBRARY = "LIBRARY",
  SAFES = "SAFES",
  TASKS = "TASKS",
  EMAILS = "EMAILS",
  SOFT = "SOFT",
  USERS = "USERS",
  ORG_USERS = "ORG_USERS",
  SESSION_USERS = "SESSION_USERS",
  DEPS = "DEPS"
}

export enum TYPE_TABLE_ACCESS {
  FOLDERS = "folders",
  FILES = "files",
  TASKS = "tasks",
  CALENDARS = "calendars",
  EMAILS = "emails",
  PROJECTS = "projects",
  CHAT = "chat"
}

export enum EVENT_TYPE {
  ADD = "add",
  EDIT = "edit",
  LIST = "list",
  SHARE = "share",
  SHARE_ME = "share_me",
  SEND = "send",
  ADD_FAVORITE = "add_favorite",
  UNFAVORITE = "unfavorite",
  EDIT_NAME = "edit_name",
  MOVE = "move",
  COPY = "copy",
  SAFE = "safe",
  ARCHIVE = "archive",
  UNARCHIVE = "unarchive",
  IS_DEL = "is_del",
  UN_IS_DEL = "un_is_del",
  DEL = "del",
  ZIP = "zip",
  DOWNLOAD = "download",
  EDIT_STATUS = "edit_status",
  GET_ACCES = "get_access",
  GET_NO_ACCES = "get_no_access",
  PASS_CHANGE = "pass_change",
  PASS_ADD = "pass_add",
  PASS_DEL = "pass_del",
  PASS_RESTORE = "pass_restore",
  PASS_WRONG = "pass_wrong",
  OPEN_BLOCKED = "open_blocked",
  REG = "reg",
  ENTER = "enter",
  MESSAGE_DEL = "message_del",
  BLOCK = "block",
  UNBLOCK = "unblock",
  LOGGOF = "logoff",
  USER_DEL = "user_del",
  USER_UNDEL = "user_un_del",
  USER_COPY = "user_copy",
  EMPLOYEE_ENTER = "employee_enter",
  PRINTED = "printed",
  SHARED = "shared",
  RESTORED = "restored"
}

export enum TYPE_TOOLS {
  FILES = "files",
  TASKS = "tasks",
  ARCHIVE = "archive",
  SOFT = "soft",
  SAFE = "safe",
  CHAT = "chat",
  EMAILS = "emails",
  PROJECT = "project",
  LIBRARY = "library"
}

export enum THEMES {
  BLUE = "blue",
  ORANGE = "orange",
  TURQUOISE = "turquoise",
  DARK = "dark"
}

export enum LANGUAGES {
  RU = "ru",
  UK = "uk",
  EN = "en"
}

export enum ERROR_SERVER {
  NO_FILES = "no files"
}
