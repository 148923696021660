import classNames from "classnames";
import Clue from "generalComponents/Clue/Clue";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import BarsView from "generalComponents/ItemsViewTypeVariants/BarsView/BarsView";
import { LinesViewElements } from "generalComponents/ItemsViewTypeVariants/LinesViewElements/LinesViewElements";
import Loader from "generalComponents/Loaders/4HUB";
import { Observer } from "generalComponents/Observer/Observer";
import { ViewType } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { useIsFilterByDate, useIsFiltersMarkers, useIsFilterSort } from "hooks/useIsFilters";
import { useIsFilterByDateCreated } from "hooks/useIsFilters";
import { LoaderTypes } from "models/generalComponents/loader";
import { ArchivedElementsArrayType } from "models/store/archive/archivedFiles";
import ItemsDate from "Pages/Cabinet/Components/WorkElements/ItemsDate/ItemsDate";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useArchivedFilesSelectors } from "Store/selectors/arhiveSelectors";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./ArchiveElementsList.module.sass";

const ArchiveElementsList = (): JSX.Element => {
  const { __ } = useLocales();
  const { loader, page, elements, total } = useArchivedFilesSelectors();
  const { view } = useFilesSelectors();

  const { isBarsView, isLinesView } = useGetItemView();
  const { theme } = useUserSelectors();
  const { onGetAllArchivedFiles, onGetArchivedFolderFiles, onSetArchivePage } = useActions();

  const { archivedDirId } = useParams();

  const isFilterByDateCreated = useIsFilterByDateCreated();
  const isFilterSort = useIsFilterSort();
  const isFiltersMarkers = useIsFiltersMarkers();
  const isFilterDate = useIsFilterByDate();

  const loadMore = (): void => {
    const controller = new AbortController();
    onSetArchivePage(page + 1);

    if (archivedDirId) {
      onGetArchivedFolderFiles({ id_dir: archivedDirId }, controller);
    } else {
      onGetAllArchivedFiles(controller);
    }
  };

  const renderItemsViewType = (elements: ArchivedElementsArrayType): JSX.Element => {
    switch (view) {
      case ViewType.BARS:
        return !isFilterByDateCreated ? <BarsView items={elements} /> : <ItemsDate files={elements} />;

      case ViewType.LINES:
        return !isFilterByDateCreated ? <LinesViewElements items={elements} /> : <ItemsDate files={elements} />;
    }
  };

  const renderMyFiles = (): JSX.Element => {
    if (elements !== null) {
      if (total === 0) {
        if ((isFilterSort && isFiltersMarkers) || isFilterDate) {
          return <Clue icon="neutral" text={__("Нет файлов соответствующих параметрам фильтрации")} />;
        }
        return <Clue icon="sad" text={__("Здесь пока пусто")} />;
      }
      return renderItemsViewType(elements);
    }
  };

  return (
    <div
      className={classNames(styles.root, `scrollbar-medium-${theme}`, {
        [styles.marginTop]: isBarsView || isLinesView
      })}
    >
      {loader && page === 1 ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <>
          {renderMyFiles()}
          {<Observer loader={loader} page={page} total={total} callBack={loadMore} />}
        </>
      )}
    </div>
  );
};

export { ArchiveElementsList };
