import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/upload_3.svg";
import Button from "generalComponents/Button/Button";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IFile } from "models/store/files/files";
import { FC, memo, MouseEvent } from "react";

import styles from "./TaskFile.module.sass";

interface IProps {
  file: IFile;
  deleteFile?: (id: string) => void;
  downloadFile?: (id: string) => void;
}
const TaskFile: FC<IProps> = ({ file, deleteFile, downloadFile }) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    downloadFile && downloadFile(file.fid);
    deleteFile && deleteFile(file.fid);
  };
  const { onFileView } = useActions();
  return (
    <div className={styles.taskBox} onClick={() => onFileView({ open: true, params: file })}>
      <div className={styles.preview}>
        {file.mime_type && file.mime_type?.startsWith("image") ? (
          <img src={projectSrc + file.preview} alt="#" className={styles.image} />
        ) : (
          <ItemIcon isFolder={false} width={33} height={44} color={(file as IFile).color} ext={(file as IFile).ext} />
        )}
        <div className={styles.btn}>
          <Button
            variant={ButtonVariantType.LIGHT}
            size={ButtonSizeType.EXTRA_SMALL}
            isSquare
            iconL={deleteFile ? <CrossIcon /> : <DownloadIcon />}
            onClick={handleClick}
          />
        </div>
      </div>
      <p className={styles.name}>{file.name}</p>
    </div>
  );
};
const MemoTaskFile = memo(TaskFile);

export default MemoTaskFile;
