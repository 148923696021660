import LetterList from "containers/Postbox/LetterList/LetterList";
import LetterViewList from "containers/Postbox/LetterViewList/LetterViewList";
import PostboxBottomPanel from "containers/Postbox/PostboxBottomPanel/PostboxBottomPanel";
import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import { LETTERS_LIMIT } from "generalComponents/variables/globalVariables";
import { MAIL_PARAMS, ROUTES } from "generalComponents/variables/routing";
import { useFindPostbox, useIsPostboxFolder } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { LoaderTypes } from "models/generalComponents/loader";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import { IGetPostboxMailsPayload } from "models/store/postbox/postboxPayloads";
import { useEffect } from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";

import styles from "./PostboxFolder.module.sass";

const PostboxFolder = () => {
  const { __ } = useLocales();
  const { postboxId, folderName } = useParams();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const viewType = params.get(MAIL_PARAMS.SPLIT);
  const unseen = params.get(MAIL_PARAMS.UNREAD);
  const favorite = params.get(MAIL_PARAMS.FAVORITE);
  const { fetchPostboxMails, onClearLetters, onNullifyAllLetters } = useActions();
  const { postbox } = useFindPostbox();
  const { letters, loader, pickedLetters } = usePostboxSelectors();
  const isFolder = useIsPostboxFolder();

  useEffect(() => {
    if (!isFolder) {
      navigate(`/${ROUTES.MAIL}`, { replace: true });
      return;
    }
    const controller = new AbortController();
    if (pickedLetters.length > 0) {
      onNullifyAllLetters();
    }
    onClearLetters();
    const payload: IGetPostboxMailsPayload = {
      user: postbox.email,
      pass: postbox.pass,
      folder: folderName,
      limit: LETTERS_LIMIT,
      start: 0,
      isSchedule: folderName === POSTBOX_SYSTEM_FOLDER.SCHEDULED,
      is_favorite: favorite ? "1" : undefined,
      is_unseen: unseen ? "1" : undefined
    };
    fetchPostboxMails(payload, controller);

    return () => controller.abort();
  }, [postboxId, folderName, unseen, favorite]); //eslint-disable-line

  return (
    <div className={styles.wrap}>
      {letters.length > 0 ? (
        <>
          <LetterList />
          {viewType && <LetterViewList />}
        </>
      ) : loader ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <Clue text={__("Здесь пока пусто")} icon="neutral" />
      )}
      {pickedLetters.length > 0 && <PostboxBottomPanel />}
    </div>
  );
};

export default PostboxFolder;
