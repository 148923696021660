import { UserFiltersActions } from "models/store/userFilters/userFiltersActions";
import { initialUserFilters, IUserFiltersState } from "models/store/userFilters/usersFilters";
import { UserFilters } from "Store/types/usersFilters";

const INITIAL_STATE = initialUserFilters();

export const UserFiltersReducer = (state = INITIAL_STATE, action: UserFiltersActions): IUserFiltersState => {
  switch (action.type) {
    case UserFilters.GET_FILTERS:
      return { ...state, ...action.payload };

    case UserFilters.NULLIFY_FILTERS:
      return INITIAL_STATE;

    default:
      return { ...state };
  }
};
