import api from "api";
import { ReactComponent as AnswerIcon } from "assets/icons/answer.svg";
import { ReactComponent as SendIcon } from "assets/icons/arrow-send.svg";
import { ReactComponent as ForwardIcon } from "assets/icons/forward.svg";
import { ReactComponent as DraftIcon } from "assets/icons/template.svg";
import TinyMCE, { TinyFile } from "containers/Postbox/TinyMCE/TinyMCE";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useFindPostbox, usePostboxFolder } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import html2canvas from "html2canvas";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import { ILetterInfo } from "models/store/postbox/postbox";
import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { Editor as TinyMCEEditor } from "tinymce";
import { renderHeader, renderSubject } from "utils/mail";
import { v4 as uuid } from "uuid";

import DialogActions from "../DialogActions/DialogActions";
import LetterViewItem from "../LetterViewItem/LetterViewItem";
import ScheduleView from "../ScheduleView/ScheduleView";
import styles from "./LetterViewList.module.sass";

const LetterViewList: FC = () => {
  const { __ } = useLocales();
  const { folderName } = useParams();
  const { formatDate } = useDateFormat();

  const { onMailNewMessageModal, onSendEmailDelay, onSendEmail, onScheduleEdit, onDeleteLetter, onLetterToDraft } =
    useActions();
  const { uid } = useUserSelectors();
  const { searchLetter } = usePostboxSelectors();
  const { postbox } = useFindPostbox();
  const { _isSchedule } = usePostboxFolder();

  const [letters, setLetters] = useState<ILetterInfo[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const editorRef = useRef<TinyMCEEditor | null>(null);
  const submitButton = useRef<HTMLButtonElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const filesRef = useRef<HTMLInputElement>(null);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [dateValue, setDateValue] = useState<string>("");
  const [isAnswer, setIsAnswer] = useState<boolean>(false);

  const ref = useRef<HTMLUListElement>(null);
  const iframeRef = useRef<any>(null);

  useEffect(() => {
    const controller = new AbortController();
    if (searchLetter) {
      if (_isSchedule) return;
      setLetters([]);
      onSearchLetter(controller);
      setIsAnswer(false);
    }
    return () => controller.abort();
  }, [searchLetter]); // eslint-disable-line

  const onSearchLetter = async (controller: AbortController): Promise<void> => {
    try {
      setLoader(true);
      const params = {
        uid,
        user: postbox.email,
        pass: postbox.pass,
        folder: folderName,
        subject: searchLetter.subject,
        from: searchLetter.from,
        reverse: 1,
        to: searchLetter.to[0].address
      };

      const { data } = await api.get("/ajax/email_messages_search.php", { params, signal: controller.signal });
      checkResponseStatus(data.ok);
      setLetters(data.data.messages.length > 0 ? data.data.messages : [searchLetter]);
    } catch {
      setLetters([searchLetter]);
    } finally {
      setLoader(false);
    }
  };

  const onChangeFilesHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    setUploadFiles((prevState) =>
      prevState ? [...prevState, ...Array.from(e.target.files)] : [...Array.from(e.target.files)]
    );
  };

  const getAnswerText = (): string => {
    const time = formatDate(new Date(), `fullDay`);
    return `
      <div>${editorRef.current.getContent()}</div><br>      
      <div dir="ltr">
        ${time}, &lt;<a href="mailto:${letters[letters.length - 1].from}">${
      letters[letters.length - 1].from
    }</a>&gt; ${__("Пишет")}:<br><br>          
        <blockquote class="gmail_quote" style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">
          ${letters[letters.length - 1].html}
        </blockquote>
      </div>\r\n`;
  };

  const addSend = (letter: ILetterInfo) => {
    setLetters((prev) => [...prev, letter]);
    setIsAnswer(false);
  };

  const sendMail = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("uid", uid);
    formData.append("user", postbox.email);
    formData.append("pass", postbox.pass);
    formData.append("server", postbox.server);
    formData.append("from_name", postbox.name || "");
    formData.append("from", postbox.email);
    formData.append("subject", `Re: ${searchLetter?.subject}`);
    formData.append("to", searchLetter?.from);
    formData.append("html", getAnswerText());
    uploadFiles.forEach((file) => formData.append("files[]", file));

    if (dateValue) {
      formData.append("date", dateValue);
      onSendEmailDelay(formData, _isSchedule, {
        messages: {
          success: __("Вы запланировали отправку письма"),
          error: __("Ошибка планирования отправки письма")
        }
      });
    } else {
      onSendEmail(formData, {
        cb: addSend,
        messages: {
          success: __("Письмо отправленно"),
          error: __("Ошибка отправки")
        }
      });
    }
  };

  const closeAnswer = () => setIsAnswer(false);

  const toDraft = () => {
    const formData = new FormData();
    uploadFiles.forEach((file) => formData.append("files[]", file));
    formData.append("user", postbox?.email);
    formData.append("pass", postbox?.pass);
    formData.append("server", postbox?.server);
    formData.append("from_name", postbox?.name || "");
    formData.append("from", postbox?.email);
    formData.append("subject", searchLetter.subject);
    formData.append("to", searchLetter?.from);
    formData.append("template", getAnswerText());
    formData.append("folder", POSTBOX_SYSTEM_FOLDER.Drafts);

    const messages = {
      error: __("Не удалось сохранить письмо в черновиках. Попробуйте еще раз!"),
      success: __("Письмо сохранено в черновиках!")
    };
    onLetterToDraft(formData, messages, closeAnswer);
  };

  const sendNow = () => {
    const messages = {
      error: __("Не удалось отправить письмо"),
      success: __("Письмо отправлено")
    };
    const formData = new FormData();
    formData.append("time", formatDate(new Date(Date.now() - 1000), "full"));
    formData.append("id_items[]", searchLetter.id);
    formData.append("user", postbox?.email);

    onScheduleEdit(formData, messages);
    onDeleteLetter([+searchLetter.id]);
  };

  const onPrint = async (): Promise<void> => {
    try {
      let block = ref.current;
      const canvas = await html2canvas(block);
      const dataUrl = canvas.toDataURL("image/png");
      if (iframeRef.current) {
        let isPrinted = false;
        const img = new Image();
        img.width = 750;
        img.onload = () => {
          if (!isPrinted) {
            const iframeWindow = iframeRef.current.contentWindow || iframeRef.current;
            iframeWindow.document.body.appendChild(img);
            iframeWindow.focus();
            iframeWindow.print();
            iframeWindow.document.body.removeChild(img);
            isPrinted = true;
          }
        };
        img.src = dataUrl;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPrint = () => {
    return `
    <div style="font-size: 16px; font-family: sans-serif; max-width: 750px">  
      ${renderHeader(postbox)}
      ${renderSubject(letters[letters.length - 1]?.subject || __("без темы"))}   
     </div>`;
  };
  const getUploadFiles = (): TinyFile[] => {
    const upload: TinyFile[] = uploadFiles.map((file: File) => ({
      filename: file.name,
      mime: file.type,
      variant: "upload"
    }));
    return upload;
  };

  const onRemoveFiles = (file: TinyFile): void => {
    if (file.variant === "upload") {
      setUploadFiles((prev) => prev.filter((item) => item.name !== file.filename));
    }
  };

  const onHideLetter = (item: ILetterInfo): void => {
    setLetters((prev) => prev.filter((letter) => letter.message_id !== item.message_id));
  };

  if (loader) {
    return (
      <div className={styles.wrap}>
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      </div>
    );
  }

  if (!searchLetter || !letters) {
    return (
      <div className={styles.wrap}>
        <Clue text={__("Выберите письмо для начала работы ")} icon="happy" />
      </div>
    );
  }

  return (
    <div className={styles.wrap}>
      <iframe ref={iframeRef} style={{ display: "none" }} title="mailFrame" srcDoc={getPrint()} />

      <div className={styles.header}>
        <span className={styles.subject}>{searchLetter.subject || __("(без темы)")}</span>
        {!_isSchedule && <DialogActions letters={letters} postbox={postbox} onPrint={onPrint} />}
      </div>
      {_isSchedule ? (
        <ScheduleView letter={searchLetter} postbox={postbox} />
      ) : (
        <ul ref={ref}>
          {letters.map((letter) => (
            <LetterViewItem key={uuid()} letter={letter} postbox={postbox} onHideLetter={onHideLetter} />
          ))}
        </ul>
      )}
      {isAnswer ? (
        <>
          <div className={styles.answer}>
            <Avatar name={postbox.name || postbox.email} />

            <form className={styles.form} ref={formRef} onSubmit={sendMail}>
              <TinyMCE
                refFiles={filesRef}
                refEditor={editorRef}
                refSubmit={submitButton}
                setDateValue={setDateValue}
                dateValue={dateValue}
                files={getUploadFiles()}
                onRemoveFiles={onRemoveFiles}
              />
              <button ref={submitButton} type="submit" style={{ display: "none" }}></button>
              <input
                style={{ display: "none" }}
                name="files[]"
                type="file"
                multiple
                onChange={onChangeFilesHandler}
                ref={filesRef}
              />
            </form>
          </div>
          <Button
            onClick={toDraft}
            variant={ButtonVariantType.LIGHT}
            size={ButtonSizeType.MEDIUM}
            iconL={<DraftIcon />}
            text={__("В черновик")}
          />
        </>
      ) : (
        <div className={styles.btns}>
          {!_isSchedule && (
            <Button
              onClick={() => setIsAnswer(true)}
              variant={ButtonVariantType.LIGHT}
              size={ButtonSizeType.MEDIUM}
              iconL={<AnswerIcon />}
              text={__("Ответить")}
            />
          )}
          <Button
            onClick={() =>
              onMailNewMessageModal({
                open: true,
                letter: { letter: searchLetter, type: "forward" }
              })
            }
            variant={ButtonVariantType.LIGHT}
            size={ButtonSizeType.MEDIUM}
            iconL={<ForwardIcon />}
            text={__("Переслать")}
          />
          {_isSchedule && (
            <Button
              onClick={sendNow}
              variant={ButtonVariantType.LIGHT}
              size={ButtonSizeType.MEDIUM}
              iconL={<SendIcon />}
              text={__("Отправить сейчас")}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LetterViewList;
