import api from "api";
import { ReactComponent as LetterdIcon } from "assets/icons/letter.svg";
import { ReactComponent as LockIcon } from "assets/icons/sign_in_lock.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import Button from "containers/Authentication/Button/Button";
import Input from "containers/Authentication/Input/Input";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import moment from "moment-timezone";
import { useState } from "react";
import { useLocales } from "react-localized";
import { isEmail, isPhoneNumber } from "utils/validation";

import styles from "./ThirdStep.module.sass";

const ThirdStep = ({
  handleNextStep,
  login,
  setLogin,
  isSolo,
  setUid
}: {
  handleNextStep: () => void;
  login: string;
  setLogin: React.Dispatch<React.SetStateAction<string>>;
  isSolo: boolean;
  setUid: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  const { __ } = useLocales();
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fetchError, setFetchError] = useState(false);
  const [errors, setErrors] = useState({
    fullName: "",
    login: "",
    password: ""
  });

  const handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFullName(value);
    setErrors({ ...errors, fullName: "" });
    setFetchError(false);
  };

  const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLogin(value);
    setErrors({ ...errors, login: "" });
    setFetchError(false);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
    setErrors({ ...errors, password: "" });
    setFetchError(false);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setConfirmPassword(value);
    setFetchError(false);
  };

  const validateLogin = (login: string): boolean => {
    return isEmail(login) || isPhoneNumber(login);
  };

  const handleNextStepClick = async () => {
    const newErrors = {
      fullName: fullName.length > 1 ? "" : __("Полное имя должно содержать не менее 1 символов"),
      login: validateLogin(login) ? "" : __("Введите правильный адрес электронной почты или номер телефона."),
      password:
        password.length > 1
          ? password === confirmPassword
            ? ""
            : __("Пароли не совпадают")
          : __("Пароль должен быть длиной не менее 1 символов.")
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => !!error);

    if (!hasErrors) {
      try {
        const params = {
          name: fullName,
          company: isSolo ? undefined : `&company=${fullName}`,
          pass: password,
          email: isEmail(login) ? login : null,
          tel: isPhoneNumber(login) ? login : null,
          gmt: moment().utcOffset() / 60
        };
        const { data } = await api.post("/ajax/user_reg.php", null, { params });
        checkResponseStatus(data.ok);
        setUid(data.uid);
        handleNextStep();
      } catch (error) {
        console.error(error);
        setFetchError(true);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4>{__("Регистрируйтесь в 4HUB")}</h4>
        <p>{__("Начните работать в интеллектуальных рабочих пространствах")}</p>
        <div className={styles.inputs_wrapper}>
          <Input
            value={fullName}
            onChange={handleFullNameChange}
            placeholder={__("Полное имя")}
            icon={<UserIcon />}
            error={errors.fullName}
          />
          <Input
            value={login}
            onChange={handleLoginChange}
            type="email"
            placeholder={__("Адрес электронной почты/телефон")}
            icon={<LetterdIcon />}
            error={errors.login}
          />
          <Input
            value={password}
            onChange={handlePasswordChange}
            placeholder={__("Пароль")}
            icon={<LockIcon />}
            type="password"
            error={errors.password}
          />
          <Input
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            placeholder={__("Подтвердите пароль")}
            icon={<LockIcon />}
            type="password"
          />
          {fetchError && <span className={styles.error}>{__("Упс!!! Что-то пошло не так!")}</span>}
        </div>
      </div>
      <Button
        disabled={!fullName || !login || !password}
        className={styles.confirm_button}
        onClick={handleNextStepClick}
        animationClassName={styles.animation}
      >
        {__("Продолжить")}
      </Button>
    </div>
  );
};

export default ThirdStep;
