import { ReactComponent as PersonIcon } from "assets/icons/person_4.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { PROJECT_PATH } from "generalComponents/variables/routing";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { FC } from "react";
import { useLocation } from "react-router";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./TeamButtons.module.sass";

interface IProps {
  onSelectTeam?: (val: string) => void;
  selectedTeam: string;
}

const TeamButtons: FC<IProps> = ({ onSelectTeam, selectedTeam }) => {
  const { project } = useJoinProjectsSelectors();
  const location = useLocation();
  const { userInfo } = useUserSelectors();
  const isMyTasksPage = location.pathname.includes(PROJECT_PATH.MY_TASKS);

  const filteredTeams = isMyTasksPage
    ? project.teams.filter((team) => team.users.some((user) => user === userInfo.id))
    : project.teams;

  return (
    <ul className={classNames(styles.list, "scrollbar-thin-blue")}>
      {filteredTeams.map((team) => (
        <li className={classNames(styles.team, { [styles.active]: selectedTeam === team.id })} key={team.id}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            iconL={<PersonIcon width={20} height={20} />}
            text={team.name}
            onClick={() => onSelectTeam(team.id)}
            className={classNames({ [styles.disabledBtn]: selectedTeam && selectedTeam !== team.id })}
          />
        </li>
      ))}
    </ul>
  );
};

export default TeamButtons;
