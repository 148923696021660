import { ArchiveElementsList } from "containers/Archive/ArchiveElementsList/ArchiveElementsList";
import { useSetOpenFolders } from "generalComponents/Hooks/Folders/useSetOpenFolders";
import { useSetFolderData } from "generalComponents/Hooks/useSetSelectFolder";
import { useAllFolders } from "generalComponents/Services/folderServices/folderServices";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams, useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { IFolder } from "models/store/folders/foldersStore";
import { initialFolderState } from "models/store/folders/foldersStore";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ArchivedFiles = () => {
  const { state, search } = useLocation();
  const navigate = useNavigate();

  const { archivedDirId } = useParams();

  const { onGetArchivedFolderFiles, onGetMyFolders, onNullifyArchivedFiles, onSetFolderPassModal, onSendFolderPath } =
    useActions();

  const {
    objSearchParams: { group, sort, sort_reverse }
  } = useSelectedObjSearchParams();

  const { color, emo, tags } = useAllSelectedSearchParams();

  const foldersAll = useAllFolders();

  const { onSetOpenFolders } = useSetOpenFolders();
  const { setFolderData, setSelectFolder } = useSetFolderData();

  useEffect(() => {
    const controllerFiles = new AbortController();
    const payload = {
      id_dir: archivedDirId
    };

    if (archivedDirId) {
      onNullifyArchivedFiles();
      onSendFolderPath([]);
      setSelectFolder(initialFolderState());

      const folder: IFolder = foldersAll.find((el) => el.id_dir === archivedDirId);

      if (!folder) return;

      if ("pass" in folder && folder.pass !== NO_VALUE) {
        onSetFolderPassModal({
          open: true,
          type: "enterPassword",
          folder,
          callbackOk: (decryptedPass) => {
            onSetOpenFolders(folder.id_parent);
            setFolderData(folder);
            onGetArchivedFolderFiles({ ...payload, pass: decryptedPass }, controllerFiles);
          },
          callbackCancel: () => {
            const path = state?.from || `/${ROUTES.ARCHIVE}${search}`;
            navigate(path);
          }
        });
        return;
      }
      onSetOpenFolders(folder.id_parent);
      setFolderData(folder);
      onGetArchivedFolderFiles(payload, controllerFiles);
    }

    return () => {
      controllerFiles.abort();
    };
  }, [archivedDirId, sort, group, sort_reverse, color.selectedValue, emo.selectedValue, tags.selectedValue?.length]); //eslint-disable-line

  useEffect(() => {
    const controllerFolders = new AbortController();
    onGetMyFolders(controllerFolders);

    return () => {
      onNullifyArchivedFiles();
      controllerFolders.abort();
    };
  }, []); //eslint-disable-line

  return <ArchiveElementsList />;
};

export default ArchivedFiles;
