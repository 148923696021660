import styles from "./Price.module.sass";

interface TariffPriceProps {
  value: string | number;
  discount: string | number | null;
}

const Price = ({ value, discount }: TariffPriceProps) => {
  return (
    <div className={styles.container}>
      {discount ? (
        <>
          <span className={styles.discount}>
            <del>{value}</del>
            <sup>$</sup>
          </span>
          <div>
            {discount}
            <sup>$</sup>
          </div>
        </>
      ) : (
        <div>
          {value}
          <sup>$</sup>
        </div>
      )}
    </div>
  );
};

export default Price;
