import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classNames from "classnames";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useTaskType } from "hooks/joinProjectHooks";
import { TASK_TYPE } from "models/store/joinProjects/joinProgects";
import { FC, MouseEvent, useState } from "react";

import styles from "./TaskType.module.sass";

interface IProps {
  type: TASK_TYPE;
  setType: (val: TASK_TYPE) => void;
  readOnly?: boolean;
}

const TaskType: FC<IProps> = ({ setType, type, readOnly = false }) => {
  const [show, setShow] = useState<boolean>(false);
  const { taskType } = useTaskType();

  const handleChange = (value: TASK_TYPE): void => {
    setType(value);
    setShow(false);
  };

  const renderOverlay = () => {
    return (
      <ul>
        <li className={styles.item} onClick={() => handleChange(TASK_TYPE.TASK)}>
          {taskType[TASK_TYPE.TASK]}
        </li>
        <li className={styles.item} onClick={() => handleChange(TASK_TYPE.EPIC)}>
          {taskType[TASK_TYPE.EPIC]}
        </li>
      </ul>
    );
  };

  return (
    <PopoverOverlay show={show} overlayContent={renderOverlay()} setShow={setShow} placement="bottom" btn="extra">
      <div
        className={classNames(styles.label, { [styles.active]: show })}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          !readOnly && setShow(true);
        }}
      >
        <span>{taskType[type]}</span>
        <ArrowIcon className={classNames(styles.arrow, { [styles.arrowOpen]: show })} />
      </div>
    </PopoverOverlay>
  );
};

export default TaskType;
