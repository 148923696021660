import { useListMemoryTools } from "collections/profile";
import { FC } from "react";
import { useLocales } from "react-localized";

import { ListMemoryTools } from "../../../ListMemoryTools/ListMemoryTools";
import { PanelBox } from "../PanelBox/PanelBox";

export const PanelTools: FC = (): JSX.Element => {
  const { __ } = useLocales();

  const listMemoryTools = useListMemoryTools();

  return (
    <PanelBox title={__("Инструменты")}>
      <ListMemoryTools tools={Object.values(listMemoryTools)} />
    </PanelBox>
  );
};
