import classNames from "classnames";
import Avatar from "generalComponents/Avatar/Avatar";
import { calculateSize } from "generalComponents/Services/helpers";
import { SEARCH_PARAMS, TYPE_TOOLS } from "generalComponents/variables/global";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { IEmploeesMemory } from "models/store/memory/memory";
import { IUserStorage } from "models/store/user/user";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./ListEmployees.module.sass";

interface IListEmployeesProps {
  employees: IEmploeesMemory[] | IUserStorage[];
  variant?: "row" | "column";
}

export const ListEmployees: FC<IListEmployeesProps> = ({ employees, variant = "column" }): JSX.Element => {
  const [chapterParams] = useSearchParams();

  const chapter = chapterParams.get(SEARCH_PARAMS.CHAPTER);

  const { theme } = useUserSelectors();

  return (
    !!employees?.length && (
      <ul
        className={classNames(styles.list, `scrollbar-medium-${theme}`, {
          [styles[variant]]: variant
        })}
      >
        {employees.map(({ id_user, fname, sname, icon, storage, total }) => {
          const nameEmployee = `${fname} ${sname}`;

          const size = storage && storage[chapter as TYPE_TOOLS];

          return (
            <li
              key={id_user}
              className={classNames(styles.list__item, {
                [styles[variant]]: variant
              })}
            >
              <span className={styles.list__itemInfo}>
                <Avatar name={nameEmployee} size={variant === "row" ? 48 : 24} imgSrc={`${projectSrc}${icon}`} />
                {variant === "column" && <span className={styles.list__itemName}>{nameEmployee}</span>}
              </span>
              <span className={styles.list__itemSize}>{calculateSize(size ?? total ?? 0)}</span>
            </li>
          );
        })}
      </ul>
    )
  );
};
