import classNames from "classnames";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { ROUTES } from "generalComponents/variables/routing";
import { SAFE_MODALS } from "generalComponents/variables/safe";
import { useSafeFind } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { EditItemsTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";

import styles from "./BottomMenuOption.module.sass";

const EditBtn: FC = () => {
  const { __ } = useLocales();
  const {
    onSetCreateFolderModal,
    onSetCustomizeFileModal,
    onSetSafeModal,
    onSetEditItemsModal,
    onEditFolders,
    onSetUpdateFiles,
    onSetUpdateNextFiles
  } = useActions();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const safe = useSafeFind();
  const {
    pickedItems: { items }
  } = useFilesSelectors();

  const { isLinesPreviewView } = useGetItemView();

  const { folders } = useFoldersSelectors();

  const onEdit = (): void => {
    if (items.length === 1) {
      if (items.some((item) => item.is_dir === 1)) {
        if (isSafePage) {
          onSetSafeModal({ open: true, type: SAFE_MODALS.RENAME_FOLDER, folder: items[0] as IFolder, safe });
          return;
        }

        onSetCreateFolderModal({
          open: true,
          type: "edit",
          folder: items[0] as IFolder,
          folders,
          onEditFolder: onEditFolders,
          onSetUpdate: (folder: IFolder) => {
            if (isLinesPreviewView) {
              onSetUpdateNextFiles([folder]);
            } else {
              onSetUpdateFiles([folder]);
            }
          }
        });
      } else {
        onSetCustomizeFileModal({ open: true, params: items[0] as IFile });
      }
    } else {
      if (items.every((item) => item.is_dir === 1)) {
        onSetEditItemsModal({
          open: true,
          type: EditItemsTypes.EDIT_FOLDERS,
          params: {
            items
          }
        });
      } else {
        onSetEditItemsModal({
          open: true,
          type: EditItemsTypes.EDIT_FILES,
          params: {
            items
          }
        });
      }
    }
  };

  return (
    <button
      onClick={onEdit}
      className={classNames({
        [styles.disabled]: !items.every((item) => item.is_dir === 1) && !items.every((item) => item.is_dir === 0)
      })}
      type="button"
      disabled={!items.every((item) => item.is_dir === 1) && !items.every((item) => item.is_dir === 0)}
    >
      {items.length > 1 ? __("Редактировать") : __("Переименовать")}
    </button>
  );
};

export default EditBtn;
