import api from "api";
import { ReactComponent as BlockIcon } from "assets/icons/block.svg";
import { ReactComponent as CrossIcon } from "assets/PrivateCabinet/cross.svg";
import classNames from "classnames";
import { useListEmployees } from "collections/profile";
import Button from "generalComponents/Button/Button";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { IDeviceInfo, IIP_Info } from "models/deviceInfo/deviceInfo";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./ProfileSessions.module.sass";

export interface IDevice {
  agent: string;
  date: string;
  email: string;
  id: string;
  id_user: string;
  device_info: IDeviceInfo;
  ip: string;
  is_block: number;
  is_my: number;
  ip_info: IIP_Info;
}

const ProfileSessions = () => {
  const { __ } = useLocales();
  const { userInfo, uid } = useUserSelectors();
  const [params] = useSearchParams();
  const paramsUserId = params.get(SEARCH_PARAMS.USER_ID) || userInfo?.id;
  const [devices, setDevices] = useState<IDevice[]>([]);
  const options = useListEmployees();
  const selectedDefault = options.find((el) => el.value === paramsUserId);

  useEffect(() => {
    const onFetchDevices = async () => {
      const { data } = await api.get(`/ajax/agents_get.php?uid=${uid}&filter_user=${paramsUserId}`);
      setDevices(data);
    };
    onFetchDevices();
  }, [paramsUserId]); //eslint-disable-line

  const onToggleBlockAgent = async (item: IDevice) => {
    const params = {
      uid,
      id_item: item.id,
      is_block: item.is_block ? 0 : 1,
      id_user_to: paramsUserId
    };
    const { data } = await api.get("/ajax/agents_edit.php", { params });
    setDevices(data.data.agents);
  };

  const onCloseAgent = async (id_item: string) => {
    const params = {
      uid,
      id_item: id_item,
      is_logoff: 1,
      id_user_to: paramsUserId
    };
    const { data } = await api.get("/ajax/agents_edit.php", { params });
    setDevices(data.data.agents);
  };

  return (
    <WorkAreaWrap>
      {userInfo.is_admin && (
        <ToolsPanel size="large">
          <div className={styles.selectBox}>
            <SelectCustom
              width={200}
              options={options}
              selectedDefault={selectedDefault}
              search
              variantHeight="small"
            />
          </div>
        </ToolsPanel>
      )}
      <div className={styles.devices}>
        <ul className={styles.list}>
          {devices.length > 0 &&
            devices.map((item) => (
              <li className={classNames(styles.item, { [styles.start]: item.is_my })} key={item.id}>
                <div className={styles.info}>
                  <p className={styles.browser}>
                    {item.device_info.device_type} {item.device_info.platform} ({item.device_info.browser})
                  </p>
                  <p className={styles.ip}>
                    {item.ip_info?.city}, {item.ip_info?.country} ({item.ip_info?.query})
                  </p>
                </div>
                {item.is_block ? (
                  <Button
                    type="button"
                    onClick={() => onToggleBlockAgent(item)}
                    variant={ButtonVariantType.EXRTA_LIGHT}
                    size={ButtonSizeType.LARGE}
                    text={__("Разблокировать")}
                  />
                ) : (
                  <>
                    {item.is_my ? (
                      <p className={styles.text}>{__("Это устройство")}</p>
                    ) : (
                      <div className={styles.btns}>
                        <Button
                          type="button"
                          onClick={() => onToggleBlockAgent(item)}
                          variant={ButtonVariantType.OPACITY}
                          size={ButtonSizeType.MEDIUM}
                          iconL={<BlockIcon />}
                          isSquare
                          title={__("Завершить сессию и заблокировать устройство ")}
                        />
                        <Button
                          type="button"
                          onClick={() => onCloseAgent(item.id)}
                          variant={ButtonVariantType.OPACITY}
                          size={ButtonSizeType.MEDIUM}
                          iconL={<CrossIcon className={styles.crossIcon} />}
                          isSquare
                          title={__("Завершить сессию")}
                        />
                      </div>
                    )}
                  </>
                )}
              </li>
            ))}
        </ul>
      </div>
    </WorkAreaWrap>
  );
};

export default ProfileSessions;
