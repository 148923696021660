import { ReactComponent as BrushIcon } from "assets/icons/brush.svg";
import { ReactComponent as CloseIcon } from "assets/PrivateCabinet/closeModal.svg";
import { ReactComponent as ImageIcon } from "assets/PrivateCabinet/image.svg";
import classNames from "classnames";
import AudioPlayer from "generalComponents/AudioPlayer/AudioPlayer";
import Loader from "generalComponents/Loaders/4HUB";
import PopUp from "generalComponents/PopUp/PopUp";
import { isSafari } from "generalComponents/Services/browserServices";
import { useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { getMedia } from "generalComponents/Services/mediaServices";
import { Times } from "generalComponents/Times/Times";
import { ROUTES } from "generalComponents/variables/routing";
import VideoPlayer from "generalComponents/VideoPlayer/VideoPlayer";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { LoaderTypes } from "models/generalComponents/loader";
import PaintToolbar from "Pages/Cabinet/Components/WorkElements/MiniToolBar/PaintToolbar";
import React, { useEffect, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { PdfView } from "../PdfView/PdfView";
import styles from "./FileView.module.sass";

const FileView = (): JSX.Element => {
  const { __ } = useLocales();
  const { onFileView, onChangeRecentFile } = useActions();
  const {
    fileView: { params, callback }
  } = useGlobalModalsSelectors();
  const { uid } = useUserSelectors();
  const { isShared } = useIsShared();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const safe = useAuthorizedSafe();
  const [audio, setAudio] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [naturalWidth, setNaturalWidth] = useState<number>(0);
  const [naturalHeight, setNaturalHeight] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const widthRef = useRef<number>(0);
  const heightRef = useRef<number>(0);

  const onClose = (): void => {
    onFileView({ open: false, params: null });
    if (isShared && params.my === 0) return;

    callback && callback();

    onChangeRecentFile(params);
  };

  const FILE_PREVIEW = isSafePage
    ? `https://fs2.mh.net.ua/ajax/safe_file_preview.php?uid=${uid}&fid=${params.fid}&code=${safe.code}&pass=${
        safe.pass
      }&id_safe=${safe.id_safe}&${new Date()}`
    : `${params?.preview}?${new Date()}`;

  const onEdit = (): void => {
    if (isEdit) return;
    setIsLoad(true);
    const img = new Image();
    img.src = FILE_PREVIEW;
    img.onload = () => {
      const cr = canvasRef;
      if (cr?.current) {
        const canvas = cr.current.getContext("2d");
        cr.current.width = widthRef.current;
        cr.current.height = heightRef.current;
        cr.current.style.width = widthRef.current + "px";
        cr.current.style.height = heightRef.current + "px";
        canvas && canvas.clearRect(0, 0, widthRef.current, heightRef.current);
        canvas && canvas.drawImage(img, 0, 0, widthRef.current, heightRef.current);
        setIsLoad(false);
      }
    };
    setIsEdit(true);
  };

  const onLoadImage = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    widthRef.current = e.currentTarget.clientWidth;
    heightRef.current = e.currentTarget.clientHeight;
    setNaturalWidth(e.currentTarget.naturalWidth);
    setNaturalHeight(e.currentTarget.naturalHeight);
  };

  const renderFile = (): JSX.Element => {
    if (params.mime_type && params.mime_type.startsWith("application/pdf")) {
      return (
        <div className={styles.pdfContainer}>
          <PdfView src={FILE_PREVIEW} />
        </div>
      );
    }

    if (params.mime_type && params.mime_type.startsWith("image")) {
      return (
        <>
          <div className={styles.header}>
            <span>{params.name}</span>
            <div className={styles.btns}>
              <button type="button" onClick={onEdit} className={styles.close}>
                <BrushIcon width={18} height={18} />
              </button>
              <button type="button" onClick={onClose} className={styles.close}>
                <CloseIcon width={18} height={18} />
              </button>
            </div>
          </div>
          {isEdit ? (
            <div
              style={{ width: widthRef.current, height: heightRef.current + 48, position: "relative", minWidth: 800 }}
            >
              {isLoad ? (
                <Loader
                  type={LoaderTypes.BOUNCING_DOTS}
                  position="absolute"
                  background="white"
                  zIndex={5}
                  width="100px"
                  height="100px"
                  containerType="bounceDots"
                />
              ) : (
                <PaintToolbar
                  canvasRef={canvasRef}
                  file={params}
                  onCancel={() => setIsEdit(false)}
                  naturalHeight={naturalHeight}
                  naturalWidth={naturalWidth}
                  setIsLoad={(value) => setIsLoad(value)}
                />
              )}
              <canvas ref={canvasRef} className={styles.canvas} />
            </div>
          ) : (
            <div
              className={styles.imageBlock}
              style={{
                width: widthRef.current ? widthRef.current : "",
                height: heightRef.current ? heightRef.current : ""
              }}
            >
              <img src={FILE_PREVIEW} alt="filePrieview" className={styles.image} onLoad={onLoadImage} />
            </div>
          )}
        </>
      );
    }
    if (params.edit_url) {
      return (
        <div className={styles.editFile}>
          <iframe title={params.name} src={params.edit_url}></iframe>
          <Times handleClick={onClose} />
        </div>
      );
    }
    return (
      <>
        <div className={styles.header}>
          <span>{params.name}</span>
          <div className={styles.btns}>
            <button type="button" onClick={onClose} className={styles.close}>
              <CloseIcon width={18} height={18} />
            </button>
          </div>
        </div>
        <div className={styles.noformat}>
          <div>
            <ImageIcon width={293} height={213} fill="#9FAEAB" />
          </div>
          <p className={styles.text}>{__("Файл недоступен к просмотру")}</p>
        </div>
      </>
    );
  };

  const collapseToggle = (): void => setCollapse((prev) => !prev);

  useEffect(() => {
    if (params) {
      if (params?.mime_type && params.mime_type.includes("audio") && params.is_preview) {
        isSafari() ? getMedia(FILE_PREVIEW, params.mime_type, setAudio) : setAudio(FILE_PREVIEW);
      }
      if (params?.mime_type && params.mime_type.includes("video") && params.is_preview) {
        isSafari() ? getMedia(FILE_PREVIEW, params.mime_type, setVideo) : setVideo(FILE_PREVIEW);
      }
    }
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  if (params.mime_type && params.mime_type.startsWith("audio")) {
    return (
      <div className={classNames(styles.audiopopup, { [styles.collapse]: collapse })}>
        <div className={styles.wrap}>
          {!collapse && (
            <div className={styles.header}>
              <span>{params.name}</span>
              <button type="button" onClick={onClose} className={styles.close}>
                <CloseIcon width={18} height={18} />
              </button>
            </div>
          )}
          {audio && <AudioPlayer collapse={collapse} setCollapse={collapseToggle} onClose={onClose} audio={audio} />}
        </div>
      </div>
    );
  }

  if (params.mime_type && params.mime_type.startsWith("video")) {
    return (
      <div className={classNames(styles.videopopup, { [styles.collapse]: collapse })}>
        <div className={styles.wrap}>
          {!collapse && (
            <div className={styles.header}>
              <span>{params.name}</span>
              <button type="button" onClick={onClose} className={styles.close}>
                <CloseIcon width={18} height={18} />
              </button>
            </div>
          )}
          {video && <VideoPlayer collapse={collapse} setCollapse={collapseToggle} onClose={onClose} video={video} />}
        </div>
      </div>
    );
  }

  return (
    <PopUp set={onClose} background={"rgba(0,0,0, 0.7"}>
      {renderFile()}
    </PopUp>
  );
};

export default FileView;
