import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import SelectChosen from "generalComponents/SelectChosen/SelectChosen";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import UserBlock from "Pages/Cabinet/Components/Project/Components/UserBlock/UserBlock";
import { useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { onAppointRoles, onEditUser } from "Store/actions/ProjectActions";
import { projectUserProps } from "types/Project";

import styles from "./AppointUsers.module.sass";

const AppointUsers = ({ onClose, params, type }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const theme = useSelector((s) => s.user.userInfo.theme);
  const project = useSelector((s) => s.Projects.project);
  const [users, setUsers] = useState(params);
  const MSG = useProjectMsg();

  const onSelectRole = (user, id_role) => {
    setUsers((prev) =>
      prev.reduce((acc, item) => {
        if (item.id_user === user.id_user) {
          acc.push({ ...user, id_role: id_role });
        } else {
          acc.push(item);
        }
        return acc;
      }, [])
    );
  };
  const getRoleName = (user) => project.roles.find((el) => el.id === user.id_role)?.name;

  const onSubmit = () => {
    type === PROJECT_MODALS.APPOINT_ROLES
      ? dispatch(onAppointRoles(users, MSG.ERROR))
      : dispatch(onEditUser(users, { error: MSG.ERROR }, PROJECT_MODALS.ADDED_USERS));
  };

  return (
    <div className={styles.wrap}>
      <p className={styles.text}>{__("Шагом ранее Вы выбрали сотрудников, сейчас Вы можете назначить им роль")}</p>
      <div className={classNames(styles.userList, `scrollbar-medium-${theme}`, { [styles.scroll]: users.length > 6 })}>
        {users.map((user) => (
          <div key={user.id_user} className={styles.userBlock}>
            <UserBlock user={user} />
            <div className={styles.roles}>
              <SelectChosen value={getRoleName(user)} placeholder={__("Выберите роль")} variant="low">
                <div className={styles.rolesList}>
                  {project.roles.map((role) => (
                    <div key={role.id} className={styles.item} onClick={() => onSelectRole(user, role.id)}>
                      {role.name}
                    </div>
                  ))}
                </div>
              </SelectChosen>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.btns}>
        <Button type="button" variant="cancel" text={__("Отмена")} onClick={onClose} />
        <Button type="button" variant="ok" text={__("Назначить роль")} onClick={onSubmit} />
      </div>
    </div>
  );
};

export default AppointUsers;

AppointUsers.propTypes = {
  onClose: PropTypes.func,
  params: PropTypes.arrayOf(projectUserProps),
  type: PropTypes.string
};
