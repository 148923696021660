import { ReactComponent as ArrowLeft } from "assets/PrivateCabinet/mail/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/PrivateCabinet/mail/arrow-right.svg";
import { DefaultButtonIcon } from "generalComponents/DefaultButtonIcon/DefaultButtonIcon";
import { useActions } from "hooks/useActions";
import React from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router-dom";

import styles from "./NavigationOption.module.sass";

const NavigationOption = (): JSX.Element => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const { onSetCurrentItem } = useActions();

  const onClickPrevNavButton = (): void => {
    onSetCurrentItem(null);
    navigate(-1);
  };
  const onClickNextNavButton = (): void => {
    onSetCurrentItem(null);
    navigate(1);
  };

  return (
    <div className={styles.root}>
      <button onClick={onClickPrevNavButton} className={styles.navigationButton} type="button" title={__("Назад")}>
        <DefaultButtonIcon className={styles.defaultButtonIcon}>
          <ArrowLeft className={styles.icon} />
        </DefaultButtonIcon>
      </button>
      <button onClick={onClickNextNavButton} className={styles.navigationButton} type="button" title={__("Вперед")}>
        <DefaultButtonIcon className={styles.defaultButtonIcon}>
          <ArrowRight className={styles.icon} />
        </DefaultButtonIcon>
      </button>
    </div>
  );
};

export default NavigationOption;
