import { LibraryElementsList } from "containers/Library/LibraryElementsList/LibraryElementsList";
import { LibraryRubricsList } from "containers/Library/LibraryRubricsList/LibraryRubricsList";
import { TABS } from "generalComponents/variables/library";
import { useActions } from "hooks/useActions";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

const LibrarySettingsPage = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    objSearchParams: { tab, chapter, id_dep, year, month }
  } = useSelectedObjSearchParams();

  const { rights } = useUserSelectors();

  const { onGetLibraryElements, onResetLibraryFolders, onGetAllLibraryRubrics } = useActions();

  useEffect(() => {
    if (tab === TABS.OBJECTS) {
      const controllerFolders = new AbortController();

      onResetLibraryFolders();
      onGetLibraryElements(controllerFolders);

      return () => {
        controllerFolders.abort();
      };
    }

    if (tab === TABS.RUBRICS) {
      const controllerRubrics = new AbortController();

      onGetAllLibraryRubrics(controllerRubrics);

      return () => {
        controllerRubrics.abort();
      };
    }
  }, [tab, chapter, id_dep, year, month]); //eslint-disable-line

  useEffect(() => {
    if (!rights.IS_LIBRARY) {
      navigate("/", { replace: true });
      return;
    }

    return () => {
      onResetLibraryFolders();
    };
  }, []); //eslint-disable-line

  if (tab === TABS.OBJECTS) {
    return <LibraryElementsList isLinePreview />;
  }

  if (tab === TABS.RUBRICS) {
    return <LibraryRubricsList />;
  }

  return null;
};

export default LibrarySettingsPage;
