import { ViewType } from "generalComponents/variables/global";
import { IFile } from "models/store/files/files";
import { SizeTypes } from "models/store/files/sort/sortPayloads";

export enum SORTING_TYPE {
  NAME = "byName",
  DATE_CREATED = "byDateCreated",
  DATE_CHANGED = "byDateChanged",
  SIZE = "bySize",
  TYPE = "byType"
}

export interface IFileCriterionState {
  sort: SORTING_TYPE;
  group: string;
  sort_reverse: string;
}

export interface ISortState {
  dragged: IFile;
  size: SizeTypes;
  view: ViewType;
}

export function initialFileCriterionState(): IFileCriterionState {
  return {
    sort: SORTING_TYPE.DATE_CREATED,
    group: "ctime",
    sort_reverse: "1"
  };
}

export function initialSortState(): ISortState {
  return {
    dragged: null,
    size: SizeTypes.SMALL,
    view: ViewType.BARS
  };
}
