import Loader from "generalComponents/Loaders/4HUB";
import { useIsFilesPage } from "generalComponents/Services/fileServices/fileServices";
import { useIsFavorite, useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { ROUTES, SHARED_PARAMS } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { LoaderTypes } from "models/generalComponents/loader";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useLocales } from "react-localized";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { onGetSafeFiles } from "Store/actions/SafeActions";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useSafeSelectors } from "Store/selectors/safeSelectors";

import styles from "./FilesObserver.module.sass";

const FilesObserver: React.FC = (): JSX.Element => {
  const { __ } = useLocales();
  const { loader, page, total } = useFilesSelectors();
  const { authorized } = useSafeSelectors();
  const { onGetMyFiles, onSetPage, onLoadSharedFiles, onGetAllFiles, onGetFilesSharedFolder } = useActions();
  const [serchParams] = useSearchParams();
  const { ref, inView } = useInView({
    threshold: 0.5
  });
  const { dirId, safeId, did } = useParams();
  const { isShared } = useIsShared();
  const { isFavorite } = useIsFavorite();
  const dirShared = serchParams.get(SHARED_PARAMS.DIR);
  const { isFilesPage } = useIsFilesPage();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const isShareFolderPage = useCurrentPathStarstWith(ROUTES.SHARE_FOLDER);

  useEffect(() => {
    const controller = new AbortController();
    if (Math.ceil(total / PER_PAGE) <= page) return;

    if (inView) {
      onSetPage(page + 1);
      if (dirShared) {
        onLoadSharedFiles(dirShared, { error: __("Не удалось получить список файлов, попробуйте еще раз") });
        return;
      }
      if (isShared) {
        onLoadSharedFiles(undefined, { error: __("Не удалось получить список файлов, попробуйте еще раз") });
        return;
      }
      if (dirId || isFavorite) {
        onGetMyFiles({ isReset: false });
      }
      if (isFilesPage) {
        onGetAllFiles(controller);
      }
      if (isSafePage) {
        const authorizeddSafe = authorized.find((el) => el.id_safe === safeId);
        const payload = {
          id_safe: safeId,
          code: authorizeddSafe.code
        };
        onGetSafeFiles(payload);
      }
      if (isShareFolderPage) {
        const params = {
          did,
          id_dir: dirId
        };
        onGetFilesSharedFolder(params);
      }
    }
  }, [inView]); //eslint-disable-line

  return loader ? (
    <div className={styles.loaderBox}>
      <Loader
        type={LoaderTypes.BOUNCING_DOTS}
        position="absolute"
        background="white"
        zIndex={5}
        width="100px"
        height="100px"
        containerType="bounceDots"
      />
    </div>
  ) : (
    <div ref={ref} className={styles.observer} />
  );
};

export default FilesObserver;
