import { getTime, startOfDay } from "date-fns";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { useActions } from "hooks/useActions";
import React, { createContext, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import ProjectRoles from "../Components/ProjectRoles/ProjectRoles";
import { useProjectMsg } from "../helpers";
import BacklogServicePanel from "./BacklogServicePanel/BacklogServicePanel";
import BacklogSpace from "./BacklogSpace/BacklogSpace";
import BacklogTask from "./BacklogTask/BacklogTask";
import styles from "./ProjectBacklog.module.sass";

export const BacklogProvider = createContext(null);

const ProjectBacklog = () => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const { onMoveToTasks, onMoveTrashTask } = useActions();
  const MSG = useProjectMsg();
  const { project } = useProjectsSelectors();
  const [searchValue, setSearchValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [isSelect, setIsSelect] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [isEdit, setIsEdit] = useState("");
  const [isOpenAll, setIsOpenAll] = useState(false);

  const handleChange = (value) => setSearchValue(value);

  const backlogTasks = useMemo(() => project.tasks.filter((t) => +t.is_backlog >= 0), [project.tasks]);

  const filtredValue = useMemo(
    () => backlogTasks.filter((t) => t.name.toLowerCase().includes(searchValue.toLowerCase())),
    [backlogTasks, searchValue]
  );

  const filtredDate = useMemo(
    () =>
      filtredValue.filter((t) => {
        const time = getTime(
          startOfDay(new Date(dateValue.slice(6), dateValue.slice(3, 5) - 1, dateValue.slice(0, 2)))
        );
        const timeStart = getTime(startOfDay(new Date(t.ut)));
        if (dateValue) {
          return timeStart === time;
        }
        return t;
      }),
    [dateValue, filtredValue]
  );

  const handleSelect = () => {
    setIsSelect((prev) => !prev);
    setSelectedTasks([]);
  };

  const handleSelected = (task) => {
    if (!isSelect) {
      return;
    }
    setSelectedTasks((prev) => {
      return prev.some((t) => t.id === task.id) ? prev.filter((t) => t.id !== task.id) : [...prev, task];
    });
  };

  const handleDelete = (id) => {
    if (selectedTasks.length === 0) {
      return;
    }
    const messages = {
      error: MSG.ERROR,
      success: MSG[PROJECT_MODALS.DEL_TASK]
    };

    const payload = {
      id_task: typeof id === "string" ? [id] : selectedTasks.map((t) => t.id),
      id_project: project.id
    };
    dispatch(onMoveTrashTask(payload, messages));
  };

  const handleMoveToTask = (role) => {
    if (selectedTasks.length === 0) {
      return;
    }
    const is_users = selectedTasks.filter((t) => t.id_executor.length > 0);

    if (is_users.length > 0 && role !== -1) {
      dispatch(
        onSetModals(MODALS.PROJECT, {
          type: PROJECT_MODALS.WARNING,
          params: {
            text: (
              <p className={styles.text}>
                {__("Вы не можете переместить задачи: ")}
                <ul>
                  {is_users.map((t, i) => (
                    <li key={i}>{t.name}</li>
                  ))}
                </ul>
                {__("в выбранную роль")}
              </p>
            )
          }
        })
      );
      return;
    }
    const tasks = selectedTasks.map((t) => t.id);
    dispatch(onMoveToTasks(tasks, role, MSG.ERROR));
  };

  return (
    <BacklogProvider.Provider value={{ isEdit, setIsEdit, isOpenAll, setIsOpenAll, isSelect }}>
      <ProjectRoles />
      <BacklogServicePanel
        searchValue={searchValue}
        handleChange={handleChange}
        setDateValue={setDateValue}
        dateValue={dateValue}
        isSelect={isSelect}
        handleSelect={handleSelect}
        handleDelete={handleDelete}
        handleMoveToTask={handleMoveToTask}
        isOpenAll={isOpenAll}
        setIsOpenAll={setIsOpenAll}
      />
      <div className={styles.wrap}>
        {searchValue || dateValue ? (
          <div className={styles.tasks}>
            {filtredDate.map((task) => (
              <BacklogTask key={task.id} task={task} handleSelected={handleSelected} selectedTasks={selectedTasks} />
            ))}
          </div>
        ) : (
          <BacklogSpace backlogTasks={backlogTasks} handleSelected={handleSelected} selectedTasks={selectedTasks} />
        )}
      </div>
    </BacklogProvider.Provider>
  );
};

export default ProjectBacklog;
