import { Image } from "generalComponents/Image/Image";
// import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { splitFilename } from "generalComponents/Services/helpers";
import { FC, useMemo, useState } from "react";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

interface IPreviewUploadImgPdfProps {
  file: File;
}

export const PreviewUploadImgVideo: FC<IPreviewUploadImgPdfProps> = ({ file }): JSX.Element => {
  const [imageUrl, setImageUrl] = useState<string>("");

  const fileName = splitFilename(file.name).name;

  const snapImage = (video: HTMLVideoElement, url: string) => {
    const canvas = document.createElement("canvas");
    canvas.width = 112;
    canvas.height = 78;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
    const image = canvas.toDataURL("image/jpg");
    const success = image.length > 100;

    if (success) {
      setImageUrl(image);
      URL.revokeObjectURL(url);
    }

    return success;
  };

  const createVideo = (): void => {
    const fileReader = new FileReader();

    fileReader.onload = function () {
      const blob = new Blob([fileReader.result], { type: file.type });
      const url = URL.createObjectURL(blob);
      const video = document.createElement("video");

      const timeupdate = function () {
        if (snapImage(video, url)) {
          video.removeEventListener("timeupdate", timeupdate);
          video.pause();
        }
      };

      video.addEventListener("loadeddata", function () {
        if (snapImage(video, url)) {
          video.removeEventListener("timeupdate", timeupdate);
        }
      });

      video.addEventListener("timeupdate", timeupdate);
      video.preload = "metadata";
      video.src = url;
      video.muted = true;
      video.playsInline = true;
      video.play();
    };

    fileReader.readAsArrayBuffer(file);
  };
  useMemo(() => {
    createVideo();
  }, [file]); //eslint-disable-line

  return imageUrl && <Image src={imageUrl} alt={fileName} />;
};
