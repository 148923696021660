import { ReactComponent as SortIcon } from "assets/icons/sort.svg";
import classNames from "classnames";
import { useContextMenuFilters } from "generalComponents/collections/folders";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { ISortingFiles } from "models/generalComponents/collections/folders";
import { ISortCriteriaState } from "models/store/sortCriteria/sortCriteria";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useSortCriteriaSelector } from "Store/selectors/sortCriteriaSelectors";

import styles from "./TempSort.module.sass";

const TempSort = (): JSX.Element => {
  const { __ } = useLocales();

  const contextMenuFilters = useContextMenuFilters();

  const { onAddSortCriteria } = useActions();

  const { selectFile } = useGlobalModalsSelectors();

  const {
    sortCriteria: { group, sort, sort_reverse }
  } = useSortCriteriaSelector();

  const { objSearchParams, onSetObjSearchParams } = useSelectedObjSearchParams();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const selectedItem = (sortItem: string, groupItem: string, reverseItem: string): boolean => {
    if (selectFile.open) {
      return sortItem === sort && +groupItem === group && reverseItem === sort_reverse;
    }

    return (
      sortItem === objSearchParams.sort &&
      groupItem === objSearchParams.group &&
      reverseItem === objSearchParams.sort_reverse
    );
  };

  const handleClick = (el: ISortingFiles): void => {
    if (selectedItem(el.sort, el.group, el.revers)) {
      setIsOpen(false);
      return;
    }

    if (selectFile.open) {
      const sorting: ISortCriteriaState = {
        sort: el.sort,
        group: +el.group,
        sort_reverse: el.revers
      };

      onAddSortCriteria(sorting);
    } else {
      onSetObjSearchParams({
        [SEARCH_PARAMS.SORT]: el.sort,
        [SEARCH_PARAMS.GROUP]: el.group,
        [SEARCH_PARAMS.SORT_REVERS]: el.revers
      });
    }

    setIsOpen(false);
  };

  const renderSorting = (): JSX.Element => {
    return (
      <ul className={styles.list}>
        {contextMenuFilters.map((el, i) => (
          <li
            key={i}
            className={classNames(styles.item, {
              [styles.active]: selectedItem(el.sort, el.group, el.revers)
            })}
            onClick={() => handleClick(el)}
          >
            {el.name}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <PopoverOverlay overlayContent={renderSorting()} title={__("Сортировка")} show={isOpen} setShow={setIsOpen}>
      <span className={classNames(styles.button, { [styles.open]: isOpen })}>
        <SortIcon className={styles.menuIcon} />
      </span>
    </PopoverOverlay>
  );
};

export default TempSort;
