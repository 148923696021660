import { EMOJIS, SIGNS } from "generalComponents/variables/elements";

export interface IUserFiltersState {
  emojis: EMOJIS[];
  tags: string[];
  colors: string[];
  figures: SIGNS[];
}

export function initialUserFilters(): IUserFiltersState {
  return { emojis: [], tags: [], colors: [], figures: [] };
}
