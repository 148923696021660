import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down.svg";
import classNames from "classnames";
import { IFaqCategory, IFaqItem, useFaq } from "collections/faq";
import { ROUTES } from "generalComponents/variables/routing";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import styles from "./FaqSubcategories.module.sass";

interface FaqSubcategoriesProps {
  data: IFaqCategory;
  active: IFaqItem | IFaqCategory;
  setActive: React.Dispatch<React.SetStateAction<IFaqItem | IFaqCategory>>;
  previousRoute?: string;
}

const FaqSubcategories: React.FC<FaqSubcategoriesProps> = ({ data, active, setActive, previousRoute }) => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const faq = useFaq();

  useEffect(() => {
    // Функция для обхода данных FAQ и поиска активной категории
    const traverseFaqData = (
      categories: Array<IFaqItem | IFaqCategory>,
      pathParts: string[]
    ): IFaqItem | IFaqCategory | undefined => {
      const currentPart = pathParts[0];
      let activeItem = categories.find((category) => category.route === currentPart);
      if (!activeItem || !Array.isArray(activeItem.content)) {
        return undefined;
      } else {
        for (let index = 1; index < pathParts.length; index++) {
          if (Array.isArray(activeItem.content)) {
            const newItem = activeItem.content.find((item) => item.route === pathParts[index]);
            if (newItem) {
              activeItem = newItem;
            } else {
              activeItem = undefined;
              break;
            }
          } else {
            activeItem = undefined;
            break;
          }
        }
      }
      // Обновление активной категории, если она найдена
      if (activeItem && activeItem?.title !== active?.title) {
        setActive(activeItem);
      }
    };

    // Разбиение текущего пути на части и запуск обхода данных
    const pathParts = location.pathname.replace(`/${ROUTES.FAQDETAILS}/`, "").split("/");
    traverseFaqData(faq, pathParts);

    // открываем категорию если она есть в роуте
    const categoryInPath = pathParts.includes(data.route);
    setExpanded(categoryInPath);
  }, [location.pathname, faq, active]); //eslint-disable-line

  const renderSubcategory = (value: IFaqItem | IFaqCategory, prevRoute: string) => {
    if (Array.isArray(value.content)) {
      return value.content.map((item, i) => (
        <FaqSubcategories key={i} data={item} active={active} setActive={setActive} previousRoute={prevRoute} />
      ));
    }
    return null;
  };

  return (
    <li className={classNames(styles.item, expanded && styles.expanded)}>
      <NavLink
        className={({ isActive }) => (isActive ? styles.active : "")}
        to={previousRoute ? `${previousRoute}/${data.route}` : data.route}
        end
      >
        {Array.isArray(data.content) && <ArrowIcon className={expanded ? styles.active : ""} />}
        {data.title}
      </NavLink>
      {expanded && <ul>{renderSubcategory(data, previousRoute ? `${previousRoute}/${data.route}` : data.route)}</ul>}
    </li>
  );
};

export default FaqSubcategories;
