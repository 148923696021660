import { ILibraryRubric } from "models/store/library/library";

export interface ICreateRubricLibraryModalState {
  open: boolean;
  titleHead?: string;
  btnLabel?: string;
  callbak?: () => void | undefined;
  rubric?: ILibraryRubric;
}

export const initialCreateRubricLibraryModalState = (): ICreateRubricLibraryModalState => {
  return { open: false };
};
