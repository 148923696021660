import { FC } from "react";

import { H } from "../GantSpace/GantSpace";

interface IProps {
  y: number;
  id: string;
  isHover: boolean;
  setHoverRow: (v: string) => void;
}

export const RowTask: FC<IProps> = ({ y, id, isHover, setHoverRow }) => {
  return (
    <rect
      x="0"
      y={y}
      width="100%"
      height={H}
      fill={isHover ? "rgba(100, 181, 246, 0.2)" : "transparent"}
      onMouseEnter={() => setHoverRow(id)}
    />
  );
};
