import classNames from "classnames";
import EmployeeItem from "containers/Dashboard/Employees/EmployeeItem/EmployeeItem";
import Empty from "containers/Dashboard/Empty/Empty";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./Employees.module.sass";

const Employees = ({ type }: { type: string }) => {
  const { __ } = useLocales();
  const { employees } = useUserSelectors();

  return (
    <div className={classNames(styles.container, styles[`container_${type}`])}>
      <div className={styles.title}>{__("Коллеги")}</div>
      {employees.length === 0 ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={classNames(styles.list, "scrollbar-thin-blue")}>
          {employees.map((item, i) => (
            <EmployeeItem item={item} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Employees;
