//set image to requested size with maxWidth && maxHeight params
import axios from "axios";
import html2canvas from "html2canvas";

import api from "../../api";
import { imageSrc } from "../variables/globalVariables";

export function imageToRatio(width: number, height: number, maxWidth = 100, maxHeight = 100) {
  let ratio = 0;

  if (width > maxWidth) {
    ratio = maxWidth / width; // get ratio for scaling image
    height = height * ratio; // Reset height to match scaled image
    width = width * ratio; // Reset width to match scaled image
  }

  // Check if current height is larger than max
  if (height > maxHeight) {
    ratio = maxHeight / height; // get ratio for scaling image
    width = width * ratio; // Reset width to match scaled image
    height = height * ratio; // Reset height to match scaled image
  }

  return { height, width };
}

// currently unused !!!!
//make a printScreen of page with navigator
export const handlePrintScreen = async (ref: HTMLElement, set: (it: string) => void) => {
  const video = document.createElement("video");
  const options = { video: true, audio: false };
  const width = window.innerWidth - 200;
  const height = window.innerHeight - 200;
  video.width = width;
  video.height = height;

  await navigator.mediaDevices
    .getDisplayMedia(options)
    .then((stream) => {
      video.srcObject = stream;
      video.play();

      video.addEventListener("canplay", async () => {
        setTimeout(() => {
          video.pause();
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          if (ctx) {
            ctx.drawImage(video, 0, 0, width, height);
          }
          const data = canvas.toDataURL("image/png");
          ref.setAttribute("src", data);
          if (set) set("block");
        }, 500);
      });
    })
    .catch((err) => {
      console.log("An error occurred: " + err);
    });
};

const CancelToken = axios.CancelToken;

//loading media to play (after problems with Safari)
export const getMedia = (
  url: string,
  type: string,
  set = (it: string) => {},
  setLoading = (it: boolean) => {},
  setError = (it: string) => {}
) => {
  setLoading(true);
  const cancelLoadMedia = CancelToken.source();
  window.cancellationTokens = { cancelLoadMedia };
  api
    .get(url, {
      responseType: "blob",
      cancelToken: cancelLoadMedia.token
    })
    .then((res) => {
      const blob = new Blob([res.data], { type });
      let objectURL = URL.createObjectURL(blob);
      set(objectURL);
    })
    .catch(() => setError("Failed to load media"))
    .finally(() => {
      setLoading(false);
    });
};

//HEX to RGBA color - full(e.g. - rgba(1, 1, 1, 1) ) or short - e.g. 255, 255, 255
export function hexToRgbA(hex: string, type?: string) {
  //TODO - mkortelov - find right types for c
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return type === "full"
      ? "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
      : `${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")}`;
  }
  throw new Error("Bad Hex");
}

//Transforming dataURL canvas into Blob
export function dataURLintoBlobImage(dataURL: string): Blob {
  const blobBin = window.atob(dataURL.split(",")[1]);
  let array = [];
  for (let i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/png" });
}

export const htmlToCanvas = async (
  element: HTMLElement,
  ref: HTMLElement,
  set: (displayType: string, dataUrl: string) => void
): Promise<void> => {
  await html2canvas(element, { allowTaint: true, proxy: imageSrc })
    .then((canvas) => {
      const data = canvas.toDataURL("image/png");
      ref.setAttribute("src", data);
      if (set) set("block", data);
    })
    .catch((e) => console.log(e));
};

export function createWhiteSheetDataUrl(width = 1920, height = 1080): Promise<string | Error> {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.fillStyle = "#FFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    return Promise.resolve(canvas.toDataURL());
  }
  return Promise.reject("Context error");
}
