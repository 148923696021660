import { ReactComponent as WarningInfoIcon } from "assets/PrivateCabinet/warning-info.svg";
import PopUp from "generalComponents/PopUp/PopUp";
import TextButton from "generalComponents/TextButton";
import { Times } from "generalComponents/Times/Times";
import { useActions } from "hooks/useActions";
import { ShareContextTypes } from "models/store/Cabinet/modals/modals";
import React from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./AccessIsLimitedModal.module.sass";

const AccessIsLimitedModal = (): JSX.Element => {
  const { __ } = useLocales();
  const { onSetAccessIsLimitedModal } = useActions();
  const { type } = useGlobalModalsSelectors().accessIsLimitedModal;

  const closeModal = (): void => {
    onSetAccessIsLimitedModal({
      open: false,
      type: ShareContextTypes.NO_TYPE,
      params: null
    });
  };

  const renderTextOption = (): string => {
    switch (type) {
      case ShareContextTypes.EXPAND_WITHOUT_FILES:
        return __(
          "В данной папке имеються файлы с ограниченным доступом для скачивание, для изменения статуса файла обратитесь к  правообладателю или расшарить папку без файлов «проект файлообменник» «дизайн»?"
        );

      case ShareContextTypes.ACCESS_IS_LIMITED:
        return __(
          "Данная папка имеют ограниченный доступ для скачивания, для изменения статуса папки обратитесь к правобладателю"
        );
    }
  };

  const renderButtonVariant = (): JSX.Element => {
    switch (type) {
      case ShareContextTypes.EXPAND_WITHOUT_FILES:
        return (
          <>
            <TextButton text={__("Отмена")} type="cancel" callback={closeModal} style={{ width: 140 }} />
            <TextButton text={__("Расшарить")} type="ok" callback={() => {}} style={{ width: 140 }} />
          </>
        );

      case ShareContextTypes.ACCESS_IS_LIMITED:
        return <TextButton text={__("Отмена")} type="cancel" callback={closeModal} style={{ width: 140 }} />;
    }
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.root}>
        <Times handleClick={closeModal} />
        <div className={styles.content}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{__("Доступ ограничен")}</div>
          </div>
          <div className={styles.iconWrapper}>
            <WarningInfoIcon className={styles.icon} />
          </div>
          <p className={styles.text}>{renderTextOption()}</p>
          <div className={styles.buttonsWrapper}>{renderButtonVariant()}</div>
        </div>
      </div>
    </PopUp>
  );
};

export default AccessIsLimitedModal;
