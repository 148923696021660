import api from "api";
import Button from "containers/Authentication/Button/Button";
import Input from "containers/Authentication/Input/Input";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { ROUTES } from "generalComponents/variables/routing";
import { IAnswer } from "Pages/Questionnaire/Questionnaire";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router-dom";
import { isEmail } from "utils/validation";

import styles from "./FourthStep.module.sass";

interface IFourthStep {
  login: string;
  uid: string;
  answers: IAnswer[];
}

const FourthStep = ({ login, uid, answers }: IFourthStep): JSX.Element => {
  const { __ } = useLocales();
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
    setError("");
  };

  const handleSubmit = async () => {
    if (code.length !== 4) {
      setError(__("Код проверки должен состоять из 4 символов."));
    } else {
      try {
        const params = { uid, code, opros: answers };
        const { data } = await api.post("/ajax/user_confirm.php", null, { params });
        checkResponseStatus(data.ok);
        navigate(`/${ROUTES.SIGN_IN}`);
      } catch (error) {
        console.log(error);
        setError("");
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4>{__("Уважаемый пользователь")}</h4>
        {isEmail(login) ? (
          <p>
            {__("Код был отправлен на вашу электронную почту") +
              login +
              __(", проверьте свою электронную почту и введите код, пожалуйста.")}
          </p>
        ) : (
          <p>
            {__("Код был отправлен на ваш телефон ") +
              login +
              __(", проверьте свой телефон и введите код, пожалуйста.")}
          </p>
        )}
        <div className={styles.input}>
          <Input value={code} onChange={handleCodeChange} error={error} />
        </div>
      </div>

      <Button className={styles.confirm_button} onClick={handleSubmit}>
        {__("Подтвердить")}
      </Button>
    </div>
  );
};

export default FourthStep;
