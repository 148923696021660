import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export const useChatSelectors = () => {
  const chat = useTypeSelector((s) => s.Cabinet.chat);
  const groupsList = chat.groupsList;
  const recentGroupsMessages = chat.recentGroupsMessages;
  const notificationsCounter = chat.notificationsCounter;
  const recentChatsList = chat.recentChatsList;
  const secretChatsList = chat.secretChatsList;
  const selectedContact = chat.selectedContact;
  const userId = chat.userId;
  const files = chat.files;
  const messages = chat.messages;
  const messageLifeTime = chat.messageLifeTime;
  const insertEmoji = chat.insertEmoji;
  const theme = chat.theme;
  const callRoom = chat.callRoom;

  return {
    groupsList,
    recentGroupsMessages,
    notificationsCounter,
    recentChatsList,
    secretChatsList,
    selectedContact,
    userId,
    files,
    messages,
    messageLifeTime,
    insertEmoji,
    theme,
    callRoom
  };
};
