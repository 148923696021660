import { ReactComponent as FavoriteIcon } from "assets/PrivateCabinet/folderFavorite.svg";
import { ReactComponent as SharedIcon } from "assets/PrivateCabinet/folderShared.svg";
import classNames from "classnames";
import { useSystemFolder } from "generalComponents/collections/folder";
import { useSetOpenFolders } from "generalComponents/Hooks/Folders/useSetOpenFolders";
import { useSetFolderData } from "generalComponents/Hooks/useSetSelectFolder";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { useAllFolders, useIsFavorite, useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { FOLDER_ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { DEFAULT_FOLDERS } from "models/generalComponents/folders";
import { IFolder, initialFolderState } from "models/store/folders/foldersStore";
import React, { useEffect } from "react";
import { useLocales } from "react-localized";
import { useLocation, useNavigate, useParams } from "react-router";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./FolderBreadcrumbs.module.sass";

const FolderBreadcrumbs = (): JSX.Element => {
  const { __ } = useLocales();
  const SYSTEM_FOLDERS = useSystemFolder();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { folderPath } = useFoldersSelectors();
  const { isShared } = useIsShared();
  const { isFavorite } = useIsFavorite();
  const { onSetCurrentItem, onSendFolderPath } = useActions();
  const { onSetOpenFolders } = useSetOpenFolders();
  const { setFolderData, setSelectFolder } = useSetFolderData();
  const { dirId } = useParams();

  const folders = useAllFolders();

  const {
    userInfo: { theme }
  } = useUserSelectors();

  const handleSelectFolder = (e: React.MouseEvent<HTMLButtonElement>, folder: IFolder): void => {
    e.stopPropagation();
    onSetCurrentItem(null);

    navigate(`${folder.id_dir}${search}`, { state: { from: pathname } });
  };

  const renderIcon = (folder: IFolder): JSX.Element => {
    if (folder.is_system === "1") {
      return SYSTEM_FOLDERS[folder.name as DEFAULT_FOLDERS].icon;
    }

    return <ItemIcon color={folder.color} passIcon={folder.is_pass === 1} isFolder />;
  };

  const renderFolderName = (folder: IFolder): string => {
    if (folder.is_system === "1") {
      return SYSTEM_FOLDERS[folder.name as DEFAULT_FOLDERS].nameRu;
    }

    return folder.name;
  };

  useEffect(() => {
    if (!dirId) return;
    onSendFolderPath([]);
    setSelectFolder(initialFolderState());
    const folder = folders?.find((el) => el.id_dir === dirId);
    if (!folder) return;

    setFolderData(folder);
    onSetOpenFolders(folder.id_parent);
  }, [folders]); //eslint-disable-line

  if (isFavorite) {
    return (
      <div className={styles.root}>
        <div className={styles.button}>
          <FavoriteIcon />
          <span>{__("Избранное")}</span>
        </div>
      </div>
    );
  }

  if (isShared) {
    return (
      <div className={styles.root}>
        <button className={styles.button} onClick={() => navigate(`${FOLDER_ROUTES.SHARED}${search}`)} type="button">
          <SharedIcon />
          <span>{__("Предоставленный доступ")}</span>
        </button>
        <span>/</span>
        {folderPath.map((folder) => (
          <li key={folder.id_dir} className={styles.item}>
            <div className={styles.button}>
              <div className={styles.iconWrapper}>{renderIcon(folder)}</div>
              <div className={styles.folderName}>{`${renderFolderName(folder)}`}</div>
            </div>
          </li>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <ul className={classNames(styles.list, `scrollbar-thin-${theme}`)}>
        {folderPath.map((folder) => (
          <li key={folder.id_dir} className={styles.item}>
            <button onClick={(e) => handleSelectFolder(e, folder)} className={styles.button} type="button">
              <div className={styles.iconWrapper}>{renderIcon(folder)}</div>
              <div className={styles.folderName}>{`${renderFolderName(folder)}`}</div>
              <span>/</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FolderBreadcrumbs;
