import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as ExitIcon } from "assets/icons/exit.svg";
import { ReactComponent as PassChangeIcon } from "assets/icons/pass-change.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import { POSTBOX_MODALS } from "generalComponents/variables/mail";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IPostbox } from "models/store/postbox/postbox";
import React from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";

import { Agents } from "../PostboxSettings";
import styles from "./Accounts.module.sass";

interface IAccountsProps {
  agents: Agents;
}

const Accounts: React.FC<IAccountsProps> = ({ agents }) => {
  const { __ } = useLocales();
  const { postboxId } = useParams();
  const navigate = useNavigate();
  const { onSetPostboxModal, onExitPostbox, onSetApproveModal, onClearLetters } = useActions();
  const { postboxes } = usePostboxSelectors();

  const createAccountHandler = (): void => {
    onSetPostboxModal({ open: true, type: POSTBOX_MODALS.CREATE_ACCOUNT });
  };

  const connectAccountHandler = (): void => {
    onSetPostboxModal({ open: true, type: POSTBOX_MODALS.CONNECT_ACCOUNT });
  };

  const onRestorePassword = (): void => {
    onSetPostboxModal({ open: true, type: POSTBOX_MODALS.PASSWORD_RESTORE });
  };

  const onChangePass = (postbox: IPostbox): void => {
    onSetPostboxModal({ open: true, type: POSTBOX_MODALS.PASSWORD_CHANGE, postbox });
  };

  const onEdit = (postbox: IPostbox) => {
    onSetPostboxModal({ open: true, type: POSTBOX_MODALS.NAME_CHANGE, postbox });
  };

  const onExit = (acc: IPostbox) => {
    if (!agents) return;
    const idAgent = agents[acc.email].find((el) => el.is_my)?.id;
    const payload = {
      id: acc.id,
      email: acc.email,
      pass: acc.pass,
      id_agent: idAgent
    };
    const messages = {
      success: `${__("Вы вышли из аккаунта")} ${acc.email}`,
      error: __("Что-то пошло не так. Попробуйте еще раз")
    };
    if (postboxId === acc.id) {
      navigate(`/${ROUTES.MAIL}`, { replace: true });
      onClearLetters();
    }
    onExitPostbox(payload, messages);
  };

  const onDelete = (acc: IPostbox) => {
    const params = {
      title: __("Удалить"),
      text: __("Вы уверенны что хотите удалить почтовый ящик?"),
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.OK_RED,
      callback: () => onSetPostboxModal({ open: true, type: POSTBOX_MODALS.CHECK_PASS, postbox: acc })
    };

    onSetApproveModal({ open: true, params });
  };

  return (
    <>
      <div className={styles.buttonsWrapper}>
        <Button
          type="button"
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.MEDIUM}
          text={__("Создать Email аккаунт 4Hub?")}
          onClick={createAccountHandler}
        />
        <Button
          type="button"
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.MEDIUM}
          text={__("Забыли пароль?")}
          onClick={onRestorePassword}
        />
        <div className={styles.delimiter} />
        <Button
          type="button"
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.MEDIUM}
          text={__("Подключить Аккаунт")}
          onClick={connectAccountHandler}
        />
      </div>
      <ul className={styles.list}>
        {postboxes.length > 0 ? (
          postboxes.map((item) => (
            <li key={item.id} className={styles.account}>
              <span>{item.email}</span>
              <div className={styles.btns}>
                <Button
                  type="button"
                  onClick={() => onExit(item)}
                  variant={ButtonVariantType.OPACITY}
                  size={ButtonSizeType.MEDIUM}
                  iconL={<ExitIcon />}
                  isSquare
                  title={__("Выйти")}
                />
                <Button
                  type="button"
                  onClick={() => onChangePass(item)}
                  variant={ButtonVariantType.OPACITY}
                  size={ButtonSizeType.MEDIUM}
                  iconL={<PassChangeIcon />}
                  isSquare
                  title={__("Сменить пароль")}
                />
                <Button
                  type="button"
                  onClick={() => onEdit(item)}
                  variant={ButtonVariantType.OPACITY}
                  size={ButtonSizeType.MEDIUM}
                  iconL={<EditIcon />}
                  isSquare
                  title={__("Изменить")}
                />
                <Button
                  type="button"
                  onClick={() => onDelete(item)}
                  variant={ButtonVariantType.OPACITY}
                  size={ButtonSizeType.MEDIUM}
                  iconL={<TrashIcon />}
                  isSquare
                  title={__("Удалить")}
                />
              </div>
            </li>
          ))
        ) : (
          <Clue text={__("Здесь пока пусто")} icon="neutral" />
        )}
      </ul>
    </>
  );
};

export default Accounts;
