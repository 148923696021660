import { ReactComponent as Safeicon } from "assets/icons/safe.svg";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { ISafe } from "models/store/safe/safe";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router";
import { useSafeSelectors } from "Store/selectors/safeSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { dateISO } from "utils/dateToISO";

import styles from "./SafeIcon.module.sass";

const SafeIcon = ({ item }: { item: ISafe }) => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const navigate = useNavigate();

  const { userInfo } = useUserSelectors();
  const { onSetSafeModal, onSetTopMessageModal } = useActions();

  const { safeList, authorized } = useSafeSelectors();

  const open = () => {
    if (!userInfo.tel) {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR, message: __("Подтвердите основной телефон") });
      return;
    }
    const isAuthorized = authorized.find((el) => el.id_safe === item.id);
    if (isAuthorized) {
      navigate(item.id);
    } else {
      const safe = safeList.find((safe) => safe.id === item.id);
      if (safe.time_blocked && new Date(dateISO(safe.time_blocked)).getTime() > Date.now()) {
        onSetSafeModal({
          open: true,
          type: SAFE_MODALS.SAFE_BLOKED,
          safe
        });
        return;
      }
      onSetSafeModal({
        open: true,
        type: SAFE_MODALS.ENTER_SAFE_PASSWORD,
        safe,
        callbackType: SAFE_CALLBACK_MODAL.FETCH_FILES,
        alert: safe.is_system ? __("Введите пароль который используется для доступа к 4HUB") : undefined
      });
    }
  };

  return (
    <div className={styles.container} onClick={open}>
      <div className={styles.iconWrapper}>
        <Safeicon fill={item.is_system ? "#DF9A14" : "#5379FF"} width={34} height={34} />
      </div>
      <div className={styles.text}>
        <div>{item.name}</div>
        <div>{formatDate(item.date_open, "fullDay")}</div>
      </div>
    </div>
  );
};

export default SafeIcon;
