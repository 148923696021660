import api from "api";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { ContactsSelect } from "generalComponents/ContactsSelect/ContactsSelect";
import ItemInfo from "generalComponents/ItemInfo/ItemInfo";
import Loader from "generalComponents/Loaders/4HUB";
import PopUp from "generalComponents/PopUp/PopUp";
import { useGetFileAccessInfo } from "generalComponents/Services/fileServices/fileServicesApi";
import { useGetFolderAccessInfo } from "generalComponents/Services/folderServices/folderServicesApi";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { IContactShort, useContactsList } from "hooks/useContactsList";
import { AccessTypes } from "models/contextMenuComponents/generalContextMenuComponents/share";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { ISharedUser } from "models/modals/sharedUser";
import { initialShareState, ITopMessageTypes, ShareActionTypes } from "models/store/Cabinet/modals/modals";
import { IFile, initialPickedItems } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useCallback, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import SharedUser from "../SharedUser/SharedUser";
import styles from "./Share.module.sass";

const Share = (): JSX.Element => {
  const { __ } = useLocales();
  const { share } = useGlobalModalsSelectors();
  const { onGetFileAccessInfo } = useGetFileAccessInfo();
  const { onGetFolderAccessInfo } = useGetFolderAccessInfo();
  const { employeesList } = useContactsList();

  const { uid, theme } = useUserSelectors();
  const {
    onShareFiles,
    onShareFolders,
    onSetPickedItems,
    onSetCopyLinkModal,
    onSetShareModal,
    onSetTopMessageModal,
    onSetLoaderModal
  } = useActions();

  const [sharedUsers, setSharedUsers] = useState<ISharedUser[]>([]);
  const [selectedItems] = useState<(IFolder | IFile)[]>([...share.folders, ...share.files]);
  const [selectedItem] = useState<IFolder | IFile>(share.folders.length > 0 ? share.folders[0] : share.files[0]);
  const [loading, setLoading] = useState<boolean>(false);

  const _isShareOneFile = share.files.length === 1 && share.folders.length === 0;
  const _isShareOneFolder = share.files.length === 0 && share.folders.length === 1;

  const onAdd = (el: any): void => {
    setSharedUsers(
      el.map((i: any) => ({
        ...i,
        name: i.user_name,
        email: [i.email],
        accessType: i.is_read ? AccessTypes.VIEWING : AccessTypes.EDITING,
        id_real_user: i.id_user_to,
        id: i.id_user_to,
        deadline: i.deadline
      }))
    );
  };

  const onLoad = (bool: boolean): void => {
    setLoading(bool);
  };

  const getProperties = useCallback((): void => {
    if (selectedItem.is_dir) {
      const id_dir = (selectedItem as IFolder).id_dir;
      onGetFolderAccessInfo(id_dir, onAdd, onLoad);
    } else {
      const fid = (selectedItem as IFile).fid;
      onGetFileAccessInfo(fid, onAdd, onLoad);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_isShareOneFile || _isShareOneFolder) {
      setLoading(true);
      getProperties();
    }
  }, [_isShareOneFile, _isShareOneFolder, getProperties]);

  const closeModal = (): void => {
    onSetShareModal(initialShareState());
  };

  const onAddRecipientContact = (contact: IContactShort): void => {
    const user = {
      ...contact,
      deadline: "",
      accessType: AccessTypes.VIEWING
    };
    setSharedUsers((prev) => [...prev, user]);
  };

  const onCopyLink = (item: IFolder | IFile): void => {
    if (item.is_dir) {
      onSetCopyLinkModal({
        open: true,
        params: (item as IFolder).link_share_to_user
      });
    } else {
      onSetCopyLinkModal({
        open: true,
        params: (item as IFile).file_link
      });
    }
  };

  const onChangeUserAccess = (id: string, type: AccessTypes): void => {
    setSharedUsers((prev) => {
      return prev.map((u) => (u.id === id ? { ...u, accessType: type } : u));
    });
  };
  const onChangeUserTime = (id: string, time: string): void => {
    setSharedUsers((prev) => {
      return prev.map((u) => (u.id === id ? { ...u, deadline: time } : u));
    });
  };
  const onRemoveUser = (id: string): void => {
    setSharedUsers((prev) => prev.filter((u) => u.id !== id));
  };

  const onClickSendButton = async (): Promise<void> => {
    const _users = sharedUsers.map((u) => ({
      id_user: u.id,
      deadline: u.deadline,
      is_write: u.accessType === AccessTypes.EDITING ? 1 : 0,
      is_download: u.accessType === AccessTypes.EDITING ? 1 : 0
    }));

    if (share.actionType === ShareActionTypes.DIR_ACCESS_ADD) {
      const payload = {
        usersTo: JSON.stringify(_users),
        idDirs: share.folders.map((folder) => folder.id_dir)
      };

      onShareFolders(payload);
      return;
    }

    if (share.actionType === ShareActionTypes.FILE_SHARE) {
      const payload = {
        usersTo: JSON.stringify(_users),
        fids: share.files.map((file) => file.fid)
      };
      onShareFiles(payload);
    }

    if (share.actionType === ShareActionTypes.MIXED) {
      const foldersParams = {
        uid,
        users_to: JSON.stringify(_users),
        id_dirs: share.folders.map((folder) => folder.id_dir)
      };
      const filesParams = {
        uid,
        users_to: JSON.stringify(_users),
        fids: share.files.map((file) => file.fid)
      };

      try {
        onSetLoaderModal(true);
        const [foldersResponse, filesResponse] = await Promise.all([
          api.get("/ajax/dir_access_add.php", { params: foldersParams }),
          api.get("/ajax/file_share.php", { params: filesParams })
        ]);

        if (foldersResponse.data.ok && filesResponse.data.ok) {
          closeModal();
          onSetPickedItems(initialPickedItems());
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.SUCCESS,
            variantMessage: EVENT_TYPE.SHARED
          });
        } else {
          throw new Error();
        }
      } catch {
        onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
      } finally {
        onSetLoaderModal(false);
      }
    }
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.root}>
        <HeaderModal title={__("Предоставленный доступ")} onClose={closeModal} />
        <div className={styles.mainContent}>
          <ItemInfo items={selectedItems} item={selectedItem} />
          <ContactsSelect
            addContact={onAddRecipientContact}
            isSend={_isShareOneFile || _isShareOneFolder}
            item={share.files.length === 1 ? share.files[0] : share.folders[0]}
            contacts={employeesList}
          />
          <div className={classNames(styles.users, `scrollbar-thin-${theme}`)}>
            {loading ? (
              <Loader
                type={LoaderTypes.BOUNCING_DOTS}
                position="absolute"
                background="white"
                zIndex={5}
                width="100px"
                height="100px"
                containerType="bounceDots"
              />
            ) : (
              <ul>
                {sharedUsers.map((user) => (
                  <SharedUser
                    key={user.id}
                    user={user as ISharedUser}
                    onChangeUserAccess={onChangeUserAccess}
                    onChangeUserTime={onChangeUserTime}
                    onRemoveUser={onRemoveUser}
                  />
                ))}
              </ul>
            )}
          </div>
          <div className={styles.buttonsWrapper}>
            {_isShareOneFile && (
              <Button
                type="button"
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.LARGE}
                iconL={<LinkIcon />}
                text={__("Копировать ссылку")}
                onClick={() => onCopyLink(share.files[0])}
              />
            )}
            {_isShareOneFolder && (
              <Button
                type="button"
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.LARGE}
                iconL={<LinkIcon />}
                text={__("Копировать ссылку")}
                onClick={() => onCopyLink(share.folders[0])}
              />
            )}
            <div className={styles.rightBtns}>
              <Button
                type="button"
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.LARGE}
                text={__("Отмена")}
                onClick={closeModal}
              />
              <Button
                type="button"
                variant={ButtonVariantType.BLUE}
                size={ButtonSizeType.LARGE}
                text={__("Применить")}
                onClick={onClickSendButton}
              />
            </div>
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default Share;
