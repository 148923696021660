import OpenTask from "generalComponents/Modals/Components/ProjectModals/OpenTask/OpenTask";
import PopUp from "generalComponents/PopUp/PopUp";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import ProjectRoles from "../Components/ProjectRoles/ProjectRoles";
import { useProjectTasks } from "../helpers";
import ProjectUsers from "./ProjectUsers/ProjectUsers";
import Tasks from "./Tasks/Tasks";

const ProjectTasks = () => {
  const project = useSelector((s) => s.Projects.project);
  const location = useLocation();
  const history = useNavigate();
  const { allTasks } = useProjectTasks();

  const getTask = () => allTasks.find((task) => task.id === location.state.idTask);

  const onClose = () =>
    history.replace({
      pathname: `/project/${project.id}/tasks`,
      state: { openModal: false, idTask: "" }
    });

  return (
    <>
      {project && (
        <>
          <ProjectRoles />
          <ProjectUsers />
          <Tasks />
          {location?.state?.openModal && getTask() && (
            <PopUp set={onClose}>
              <OpenTask params={getTask()} />
            </PopUp>
          )}
        </>
      )}
    </>
  );
};

export default ProjectTasks;
