import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as DragIcon } from "assets/icons/drag.svg";
import { ReactComponent as InfoIcon } from "assets/icons/exclamation_mark.svg";
import classNames from "classnames";
import Empty from "containers/Dashboard/Empty/Empty";
import { ExtendedLayout } from "containers/JoinProject/JointProjectsTasks/JointProjectsTasks";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { initialTasksQueueState } from "models/store/Cabinet/modals/tasksQueue";
import { IProjectTask } from "models/store/joinProjects/joinProgects";
import { useEffect, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./TasksQueueModal.module.sass";

const ReactGridLayout = WidthProvider(RGL);

const TasksQueueModal = () => {
  const { project } = useJoinProjectsSelectors();
  const { __ } = useLocales();
  const {
    onSetTasksQueueModal,
    onFetchAllTaskProject,
    onSetTasksProject,
    onEditJointProjectTasks,
    onResetAllTasksProject
  } = useActions();
  const [tasks, setTasks] = useState<ExtendedLayout[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const id_sprint = searchParams.get("sprint");
  const {
    tasksQueue: { userId }
  } = useGlobalModalsSelectors();

  const closeModal = () => {
    onSetTasksQueueModal(initialTasksQueueState());
    onResetAllTasksProject();
  };

  useEffect(() => {
    const updatedTasks = project.allTasks.map((task) => {
      return { ...task, x: 0, y: task.orders?.[id_sprint] || 0, i: task.id, w: 1, h: 1, isResizable: false };
    });
    setTasks(updatedTasks);
  }, [project.allTasks]); //eslint-disable-line

  useEffect(() => {
    if (!id_sprint) return;
    setIsLoading(true);
    const controller = new AbortController();
    onFetchAllTaskProject({ id_sprint, controller, selectedParticipants: userId, callBack: () => setIsLoading(false) });
    return () => {
      onSetTasksProject([]);
      controller.abort();
    };
  }, [id_sprint]); //eslint-disable-line

  const onLayoutChange = (layout: ExtendedLayout[]) => {
    setTasks((prev) => {
      return prev.map((item) => {
        const layoutItem = layout.find((lItem: RGL.Layout) => lItem.i === item.i);
        if (layoutItem) {
          return { ...item, x: layoutItem.x, y: layoutItem.y };
        }
        return item;
      });
    });
  };

  const toggleUp = (clickedItem: ExtendedLayout) => {
    setTasks((prev) => {
      const updatedTasks = prev.map((item) => {
        if (item.i === clickedItem.i) {
          return { ...item, y: item.y - 1.5 };
        }
        return item;
      });
      return updatedTasks;
    });
  };

  const toggleDown = (clickedItem: ExtendedLayout) => {
    setTasks((prev) => {
      const updatedTasks = prev.map((item) => {
        if (item.i === clickedItem.i) {
          return { ...item, y: item.y + 1.5 };
        }
        return item;
      });

      return updatedTasks;
    });
  };

  const closeCallback = () => {
    setIsLoading(false);
    closeModal();
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const controller = new AbortController();
    const changedItemsMap = tasks.reduce((result, currentItem) => {
      result[currentItem.id] = {
        orders: { [id_sprint]: currentItem.y + 1 }
      };

      return result;
    }, {} as Record<string, Partial<IProjectTask>>);
    onEditJointProjectTasks(changedItemsMap, id_sprint, controller, closeCallback);
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.header}>
        <InfoIcon /> {__("Перетащите карточки вверх или вниз для изменения очереди выполнения")}
      </div>
      {isLoading ? (
        <div className={styles.empty}>
          <Loader position="relative" type={LoaderTypes.BOUNCING_DOTS} containerType="bounceDots" />
        </div>
      ) : tasks.length > 0 ? (
        <>
          <div className={classNames(styles.container, "scrollbar-thin-blue")}>
            <ReactGridLayout
              cols={1}
              rowHeight={34}
              onLayoutChange={(layout) => onLayoutChange(layout as ExtendedLayout[])}
            >
              {tasks.map((item) => (
                <div key={item.id} className={styles.item} data-grid={item}>
                  <div className={styles.number}>{item.y + 1}</div>
                  <div className={styles.content}>
                    <div className={styles.num}>{item.num}</div>
                    <div className={styles.title}>{item.name}</div>
                  </div>
                  <div className={styles.buttons}>
                    <Button
                      isSquare
                      className={styles.up}
                      variant={ButtonVariantType.OPACITY}
                      size={ButtonSizeType.EXTRA_SMALL}
                      onClick={() => toggleUp(item)}
                      iconL={<ArrowIcon />}
                      onMouseDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      isSquare
                      className={styles.down}
                      variant={ButtonVariantType.OPACITY}
                      size={ButtonSizeType.EXTRA_SMALL}
                      iconL={<ArrowIcon />}
                      onClick={() => toggleDown(item)}
                      onMouseDown={(e) => e.stopPropagation()}
                    />
                    <DragIcon className={styles.dragIcon} />
                  </div>
                </div>
              ))}
            </ReactGridLayout>
          </div>
          <div className={styles.footer}>
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.MEDIUM}
              text={__("Готово")}
              onClick={handleSubmit}
            />
          </div>
        </>
      ) : (
        <div className={styles.empty}>
          <Empty />
        </div>
      )}
    </PopUp>
  );
};

export default TasksQueueModal;
