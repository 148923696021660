import PropTypes from "prop-types";
import React from "react";
import { useLocales } from "react-localized";

import ProjectTask from "../../Components/ProjectTask/ProjectTask";
import styles from "./HistoryTasks.module.sass";

const HistoryTasks = ({ tasks }) => {
  const { __ } = useLocales();
  const renderTasks = () => {
    return Object.entries(tasks).map(([data, values], i) => (
      <div key={i}>
        <p className={styles.date}>
          {__("Спринт с")}&nbsp;{data}
        </p>

        {values.map((task, i) => (
          <div key={i} className={styles.taskBox}>
            <ProjectTask task={task} variant="row" isMenu={false} changeStatus={false} />
          </div>
        ))}
      </div>
    ));
  };

  return <div className={styles.wrap}>{renderTasks()}</div>;
};

export default HistoryTasks;

HistoryTasks.propTypes = {
  tasks: PropTypes.string
};
