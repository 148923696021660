import { ReactComponent as HeartIcon } from "assets/PrivateCabinet/heart.svg";
import { ReactComponent as MoreIcon } from "assets/PrivateCabinet/more.svg";
import { useActions } from "hooks/useActions";
import { IProgram } from "models/store/programs/programs";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./ProgramItem.module.sass";

interface Props {
  program: IProgram;
}

const ProgramItem: React.FC<Props> = ({ program }) => {
  const { __ } = useLocales();
  const { onChangeFavoriteProgram, setFaforiteProgram } = useActions();

  const onSetFavourite = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    onChangeFavoriteProgram({ ...program, is_favorite: program.is_favorite ? 0 : 1 });
    const payload = {
      program,
      isFavorite: program.is_favorite ? "0" : "1"
    };
    const messageError = program.is_favorite
      ? __("Не удалось убрать с избранных")
      : __("Не удалось добывить в избранные");
    setFaforiteProgram(payload, messageError);
  };
  return (
    <a href={program.link} target="_blank" rel="noopener noreferrer" className={styles.itemWrap}>
      <div className={styles.iconBox}>{program.icon ? <img src={program.icon} alt="ico" /> : <MoreIcon />}</div>
      <div className={styles.nameBlock}>
        <div className={styles.programName}>{program?.name}</div>
        <div className={styles.programLink}>{program?.linkLabel}</div>
      </div>
      <button className={styles.btn} onClick={onSetFavourite}>
        <HeartIcon fill={program.is_favorite ? "#FFB800" : "#B6C2BF"} />
      </button>
    </a>
  );
};

export default ProgramItem;
