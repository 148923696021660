import classNames from "classnames";
import { format, startOfWeek } from "date-fns";
import { useDaysOfWeeks } from "generalComponents/Calendars/CalendarHelper";
import { useActions } from "hooks/useActions";
import { TaskFilters } from "models/store/tasks/tasks";
import React from "react";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";

import styles from "./DaysOfWeeks.module.sass";

const DaysOfWeeks = (): JSX.Element => {
  const daysOfWeeks = useDaysOfWeeks();
  const {
    filters: { date, type }
  } = useTasksSelectors();
  const { onSelectFilterType, onSelectFilterDate } = useActions();

  const startDay = new Date(startOfWeek(new Date(date.year, date.month, date.day), { weekStartsOn: 1 })).getDate();

  const selectDay = (i: number): void => {
    if (type === TaskFilters.BY_WEEK || type === TaskFilters.BY_DAY) {
      onSelectFilterDate({ ...date, day: startDay + i });
      onSelectFilterType(TaskFilters.BY_DAY);
    }
  };

  const isCurrentWeekDay = (i: number): boolean => {
    const current = new Date().getDay();

    if (type === TaskFilters.BY_WEEK) {
      return (
        date.year === new Date().getFullYear() &&
        date.month === new Date().getMonth() &&
        startDay + i === new Date().getDate()
      );
    }
    if (type === TaskFilters.BY_DAY) {
      return startDay + i === date.day;
    }
    if (type === TaskFilters.BY_MONTH) {
      if (i === 6) {
        return current === 0;
      }
      return i + 1 === current && date.year === new Date().getFullYear() && date.month === new Date().getMonth();
    }
  };

  return (
    <div className={styles.weekDays}>
      {daysOfWeeks.short.map((el, i) => (
        <div
          key={el}
          className={classNames(styles.weekDay, {
            [styles.currentWeekDay]: isCurrentWeekDay(i),
            [styles.select]: type === TaskFilters.BY_WEEK || type === TaskFilters.BY_DAY
          })}
          onClick={() => selectDay(i)}
        >
          {el}
          {(type === TaskFilters.BY_WEEK || type === TaskFilters.BY_DAY) && (
            <>,&nbsp;{format(new Date(date.year, date.month, startDay + i), "dd")}</>
          )}
        </div>
      ))}
    </div>
  );
};

export default DaysOfWeeks;
