import { useTaskStatuses } from "collections/tasks";
import { colorsCategory } from "generalComponents/collections/elements";
import { ITaskStatus } from "models/generalComponents/collections/tasks";
import { IAnaliticsPanelProps } from "models/tasks/myTasks";
import React from "react";

import styles from "./AnaliticsPanel.module.sass";

const AnaliticsPanel: React.FC<IAnaliticsPanelProps> = ({ tasks }): JSX.Element => {
  const allTasks = Object.values(tasks).flat();
  const { myTasks } = useTaskStatuses();

  const renderItem = (status: ITaskStatus): JSX.Element => {
    const amount = allTasks.filter((t) => t.id_status === status.status).length;
    const width = amount ? `${(amount / allTasks.length) * 100}%` : "20px";
    const statusColor = colorsCategory.find((item) => item.name === status.color).dark;

    return (
      <div className={styles.category} key={status.status} style={{ width: width, color: statusColor }}>
        <p>{amount}</p>
        <div
          className={styles.line}
          style={{ background: `repeating-linear-gradient(-60deg, #fff 0 0.5px, ${statusColor} 0 5px)` }}
        />
      </div>
    );
  };
  return <div className={styles.wrap}>{myTasks.map((status) => renderItem(status))}</div>;
};

export default AnaliticsPanel;
