import { ReactComponent as DotsIcon } from "assets/PrivateCabinet/dots-vertical-round.svg";
import { useContextProjecFile } from "collections/joinProject";
import { isIFile } from "containers/Dashboard/DashboardItem/DashboardItem";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { PROJECT_PATH, ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./JointProjectFile.module.sass";

export const JointProjectFile = ({ item }: { item: IFile | IFolder }) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const onHover = (): void => setIsHover(true);
  const outHover = (): void => {
    setIsHover(false);
  };

  const { id } = useParams();

  const navigate = useNavigate();

  const { contextMenuFileCallback, contextMenuFolderCallback } = useContextProjecFile();

  const { onFileView } = useActions();

  const renderContextMenu = () => {
    if (item?.is_dir === 1) {
      return contextMenuFolderCallback(item as IFolder);
    } else {
      return contextMenuFileCallback(item as IFile);
    }
  };

  const onClickJointProjectsFile = () => {
    if (!item?.is_dir) {
      return onFileView({ open: true, params: item as IFile });
    } else {
      navigate(`/${ROUTES.PROJECT}/${id}/${PROJECT_PATH.FILES}/${item.id_dir}`);
    }
  };

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={onHover}
      onMouseLeave={outHover}
      onDoubleClick={onClickJointProjectsFile}
    >
      <div className={styles.content}>
        <ItemIcon
          width={isIFile(item) ? 44 : 54}
          height={54}
          color={item?.color}
          ext={isIFile(item) ? item?.ext : undefined}
          isFolder={!isIFile(item)}
        />
        <PopoverContextMenu data={renderContextMenu() || []} iconVariant="hidden" visible={isHover}>
          <DotsIcon fill="#E7EBEA" />
        </PopoverContextMenu>
      </div>
      <div className={styles.title}>{item.name}</div>
    </div>
  );
};
