import { ReactComponent as LabelIcon } from "assets/icons/label.svg";
import classNames from "classnames";
import { colors, emojis } from "collections/markers";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import TagSelect from "generalComponents/TagSelect/TagSelect";
import { ButtonSizeType } from "models/generalComponents/button";
import { IProjectTask } from "models/store/joinProjects/joinProgects";
import { FC, useState } from "react";
import { useLocales } from "react-localized";

import styles from "./Tags.module.sass";

interface IProps {
  task: IProjectTask;
  setTags: (v: string[]) => void;
  color: string;
  setColor: (v: string) => void;
  emoji: string;
  setEmoji: (v: string) => void;
  disabled?: boolean;
}

const Tags: FC<IProps> = ({ task, setTags, color, setColor, emoji, setEmoji, disabled }) => {
  const { __ } = useLocales();
  const [show, setShow] = useState(false);

  const onSelectColor = (v: string) => {
    setColor(v === color ? "" : v);
    setShow(false);
  };

  const onSelectEmoji = (v: string) => {
    setEmoji(v === emoji ? "" : v);
    setShow(false);
  };

  const renderContent = () => {
    return (
      <div>
        <div className={styles.block}>
          <TagSelect item={task} onChange={setTags} />
        </div>
        <div className={styles.block}>
          <div className={styles.list}>
            {colors.map((el, i) => (
              <div
                key={i}
                className={classNames(styles.element, { [styles.active]: el === color })}
                onClick={() => onSelectColor(el)}
              >
                <div className={styles.color} style={{ background: el }} />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.list}>
            {emojis.map((el, i) => (
              <div
                key={i}
                className={classNames(styles.element, { [styles.active]: el === emoji })}
                onClick={() => onSelectEmoji(el)}
              >
                <div className={styles.emoji} dangerouslySetInnerHTML={{ __html: el }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <PopoverOverlay
      overlayContent={renderContent()}
      classes={{ popoverBody: styles.nopadding }}
      show={show}
      setShow={setShow}
      btn="custom"
      iconLBtn={<LabelIcon className={styles.icon} />}
      title={__("Тэги")}
      sizeBtn={ButtonSizeType.EXTRA_SMALL}
      visibleHeader={false}
      width={350}
      disabled={disabled}
    />
  );
};

export default Tags;
