import PropTypes from "prop-types";
import React from "react";

const Arrow = ({ color, id }) => {
  return (
    <svg width="100%" height={22}>
      <defs>
        <marker id={`arrowhead${id}`} markerWidth="8" markerHeight="6" refX="2" refY="3" orient="auto">
          <polygon points="0 0, 4 3, 0 6" fill={color} />
        </marker>
      </defs>
      <line
        x1="50%"
        y1="3"
        x2="50%"
        y2="20"
        markerEnd={`url(#arrowhead${id})`}
        stroke={color}
        fill="transparent"
        strokeWidth={1}
      />
    </svg>
  );
};

export default Arrow;

Arrow.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string
};
