import { IFilterCriteriaState, initialFilterCriteriaState } from "models/store/filterCriteria/filterCriteria";
import { FilterCriteriaActions } from "models/store/filterCriteria/filterCriteriaActions";
import { FilterCriteriaTypes } from "Store/types/filterCriteria";

const INITIAL_STATE = initialFilterCriteriaState();

export const FilterCriteriaReducer = (state = INITIAL_STATE, action: FilterCriteriaActions): IFilterCriteriaState => {
  switch (action.type) {
    case FilterCriteriaTypes.ADD_FILTER:
      return { ...state, ...action.payload };

    case FilterCriteriaTypes.NULLIFY_FILTERS_CRITERIA:
      return INITIAL_STATE;

    default:
      return state;
  }
};
