import { ReactComponent as ViewIcon } from "assets/icons/view.svg";
import classNames from "classnames";
import { useActionsJournal } from "collections/journal";
import Button from "generalComponents/Button/Button";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import styles from "./TopMessage.module.sass";

const TopMessage = (): JSX.Element => {
  const { __ } = useLocales();
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState(null);
  const { topMessage } = useGlobalModalsSelectors();
  const { onSetTopMessageModal } = useActions();
  const navigate = useNavigate();

  const actionMessage = useActionsJournal();

  const closeModal = (): void => {
    onSetTopMessageModal({
      open: false,
      type: ITopMessageTypes.MESSAGE,
      message: ""
    });
  };

  const clearStoreMessage = (): void => {
    onSetTopMessageModal({
      open: true,
      type: ITopMessageTypes.MESSAGE,
      message: ""
    });
  };

  const checkNewMessage = (): void => {
    if (messages.length > 0) {
      setCurrentMessage(messages[0]);
      setMessages((s) => s.filter((_, i) => i !== 0));
    } else {
      if (currentMessage !== null) {
        closeModal();
      }
    }
  };

  useEffect(() => {
    if (!topMessage.message && topMessage.type === ITopMessageTypes.ERROR) {
      setMessages((s) => [
        ...s,
        {
          type: topMessage.type,
          message: __("Упс, что-то пошло не так, повторите запрос позже. Мы уже работаем над устранением проблемы"),
          callback: topMessage?.callback
            ? () => {
                topMessage.callback();

                if (topMessage.newPath) {
                  navigate(topMessage.newPath);
                }
              }
            : undefined
        }
      ]);
    }
    if (!topMessage.message && topMessage.type === ITopMessageTypes.SUCCESS && topMessage?.variantMessage) {
      setMessages((s) => [
        ...s,
        {
          type: topMessage.type,
          message: actionMessage[topMessage.variantMessage],
          callback: topMessage?.callback
            ? () => {
                topMessage.callback();

                if (topMessage.newPath) {
                  navigate(topMessage.newPath);
                }
              }
            : undefined
        }
      ]);
    }
    if (topMessage.message?.length > 0) {
      setMessages((s) => [
        ...s,
        {
          type: topMessage.type,
          message: topMessage.message || actionMessage[topMessage.variantMessage],
          callback: topMessage?.callback
            ? () => {
                topMessage.callback();

                if (topMessage.newPath) {
                  navigate(topMessage.newPath);
                }
              }
            : undefined
        }
      ]);
      clearStoreMessage();
    }
  }, [topMessage.message]); //eslint-disable-line

  // Should work only on first render after messages was updated
  useEffect(() => {
    if (currentMessage === null) {
      checkNewMessage();
    }
  }, [messages]); //eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      checkNewMessage();
    }, 3000);
  }, [currentMessage]); //eslint-disable-line

  return (
    <>
      {currentMessage !== null ? (
        <div className={styles.wrap}>
          <div
            className={classNames(styles[currentMessage.type], {
              [styles.row]: currentMessage.callback
            })}
          >
            <p>{currentMessage.message}</p>
            {currentMessage.callback && (
              <Button
                onClick={currentMessage.callback}
                variant={ButtonVariantType.EXRTA_LIGHT}
                iconL={<ViewIcon />}
                size={ButtonSizeType.SMALL}
              />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TopMessage;
