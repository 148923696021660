import { initialSortCriterionState, ISortCriteriaState } from "models/store/sortCriteria/sortCriteria";
import { SortCriteriaActions } from "models/store/sortCriteria/sortCriteriaActions";
import { SortCriteriaTypes } from "Store/types/sortCriteria";

const INITIAL_STATE = initialSortCriterionState();

export const SortCriteriaReducer = (state = INITIAL_STATE, action: SortCriteriaActions): ISortCriteriaState => {
  switch (action.type) {
    case SortCriteriaTypes.ADD_SORT:
      return { ...state, ...action.payload };

    case SortCriteriaTypes.NULLIFY_SORTS_CRITERIA:
      return INITIAL_STATE;

    default:
      return state;
  }
};
