import { IExtendedIJointProjectStatus } from "generalComponents/Modals/Components/JointProject/StatusModal/StatusModal";

export interface IChangeStatusModalState {
  open: boolean;
  setStatuses: React.Dispatch<React.SetStateAction<IExtendedIJointProjectStatus[]>>;
  item?: IExtendedIJointProjectStatus;
}

export function initialChangeStatusModalState(): IChangeStatusModalState {
  return { open: false, setStatuses: () => {}, item: undefined };
}
