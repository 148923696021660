import { TYPE_ELEMENTS } from "generalComponents/variables/global";
import { TASK_TYPES } from "generalComponents/variables/tasks";

export const getEventGroup = (eventGroup: TYPE_ELEMENTS, typeTask?: TASK_TYPES) => {
  const isFile: boolean = eventGroup === TYPE_ELEMENTS.FILES;
  const isFolder: boolean = eventGroup === TYPE_ELEMENTS.FOLDERS;
  const isTasks: boolean = eventGroup === TYPE_ELEMENTS.TASKS;
  const isTask: boolean = typeTask === TASK_TYPES.TASK;
  const isMeetings: boolean = typeTask === TASK_TYPES.MEETINGS;
  const isOnlineMeeting: boolean = typeTask === TASK_TYPES.ONLINE_MEETING;
  const isOfflineMeeting: boolean = typeTask === TASK_TYPES.OFFLINE_MEETING;
  const isCalls: boolean = typeTask === TASK_TYPES.CALLS;
  const isMails: boolean = typeTask === TASK_TYPES.MAILS;
  const isReminder: boolean = typeTask === TASK_TYPES.REMINDER;
  const isNotes: boolean = typeTask === TASK_TYPES.NOTES;
  const isEmails: boolean = eventGroup === TYPE_ELEMENTS.EMAILS;
  const isSoft: boolean = eventGroup === TYPE_ELEMENTS.SOFT;
  const isMyData: boolean = eventGroup === TYPE_ELEMENTS.USERS;
  const isEmployees: boolean = eventGroup === TYPE_ELEMENTS.ORG_USERS;
  const isLibrary: boolean = eventGroup === TYPE_ELEMENTS.LIBRARY;
  const isDeps: boolean = eventGroup === TYPE_ELEMENTS.DEPS;
  const isSessionEmployee: boolean = eventGroup === TYPE_ELEMENTS.SESSION_USERS;

  return {
    isFile,
    isFolder,
    isTasks,
    isTask,
    isMeetings,
    isOnlineMeeting,
    isOfflineMeeting,
    isCalls,
    isMails,
    isReminder,
    isNotes,
    isEmails,
    isSoft,
    isMyData,
    isEmployees,
    isLibrary,
    isDeps,
    isSessionEmployee
  };
};
