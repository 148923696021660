import { ReactComponent as NoteIcon } from "assets/icons/note.svg";
import { ReactComponent as EmployeIcon } from "assets/icons/person_2.svg";
import { ReactComponent as SafeIcon } from "assets/icons/safe.svg";
import { ReactComponent as BellIcon } from "assets/PrivateCabinet/bell.svg";
import { ReactComponent as TaskIcon } from "assets/PrivateCabinet/calendar-4.svg";
import { ReactComponent as FolderIcon } from "assets/PrivateCabinet/folder.svg";
import { ReactComponent as MailIcon } from "assets/PrivateCabinet/mail/unread.svg";
import { ReactComponent as MeetingIcon } from "assets/PrivateCabinet/meeting.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-5.svg";
import { ReactComponent as GroupProjectIcon } from "assets/PrivateCabinet/sideMenu/project.svg";
import { SEARCH_PARAMS } from "generalComponents/variables/global";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { TypeElements } from "models/typeElements/typeElements";
import { useLocales } from "react-localized";

export function useMenuFilterTypeElements(): ISelectCustom[] {
  const { __ } = useLocales();
  const { onSetObjSearchParams } = useSelectedObjSearchParams();

  return [
    {
      name: __(`Все объекты`),
      value: "",
      callback: () => {
        onSetObjSearchParams({
          [SEARCH_PARAMS.TABLE]: "",
          [SEARCH_PARAMS.ID_TYPE]: ""
        });
      }
    },
    {
      name: __(`Файлы и Папки`),
      value: `${TypeElements.FILES},${TypeElements.FOLDERS},${TypeElements.SAFE_FILES}`,
      callback: () => {
        onSetObjSearchParams({
          [SEARCH_PARAMS.TABLE]: `${TypeElements.FILES},${TypeElements.FOLDERS},${TypeElements.SAFE_FILES}`,
          [SEARCH_PARAMS.ID_TYPE]: ""
        });
      },
      icon: <FolderIcon width={16} height={16} />
    },
    {
      name: __(`Совместный проект`),
      value: TypeElements.PROJECT,
      callback: () => {
        onSetObjSearchParams({
          [SEARCH_PARAMS.TABLE]: TypeElements.PROJECT,
          [SEARCH_PARAMS.ID_TYPE]: ""
        });
      },
      icon: <GroupProjectIcon width={16} height={16} fill="#56716B" />
    },
    {
      name: __("Сотрудники"),
      value: TypeElements.USERS,
      callback: () => {
        onSetObjSearchParams({
          [SEARCH_PARAMS.TABLE]: TypeElements.USERS,
          [SEARCH_PARAMS.ID_TYPE]: ""
        });
      },
      icon: <EmployeIcon width={16} height={16} fill="#56716B" />
    },
    {
      name: __(`Сейф`),
      value: TypeElements.SAFE,
      callback: () => {
        onSetObjSearchParams({
          [SEARCH_PARAMS.TABLE]: TypeElements.SAFE,
          [SEARCH_PARAMS.ID_TYPE]: ""
        });
      },
      icon: <SafeIcon width={16} height={16} />
    },
    {
      name: "",
      label: __(`Задачи`)
    },
    {
      name: __(`Задача`),
      value: TASK_TYPES.TASK,
      callback: () => {
        onSetObjSearchParams({
          [SEARCH_PARAMS.TABLE]: TypeElements.TASKS,
          [SEARCH_PARAMS.ID_TYPE]: TASK_TYPES.TASK
        });
      },
      icon: <TaskIcon width={16} height={16} fill="#4086F1" />
    },
    {
      name: __(`Встречи`),
      value: `${TASK_TYPES.ONLINE_MEETING},${TASK_TYPES.OFFLINE_MEETING}`,
      callback: () => {
        onSetObjSearchParams({
          [SEARCH_PARAMS.TABLE]: TypeElements.TASKS,
          [SEARCH_PARAMS.ID_TYPE]: `${TASK_TYPES.ONLINE_MEETING},${TASK_TYPES.OFFLINE_MEETING}`
        });
      },
      icon: <MeetingIcon width={16} height={16} />
    },
    {
      name: __(`Звонки`),
      value: TASK_TYPES.CALLS,
      callback: () => {
        onSetObjSearchParams({
          [SEARCH_PARAMS.TABLE]: TypeElements.TASKS,
          [SEARCH_PARAMS.ID_TYPE]: TASK_TYPES.CALLS
        });
      },
      icon: <PhoneIcon width={16} height={16} fill="#2ABD27" />
    },
    {
      name: __(`Письма`),
      value: TASK_TYPES.MAILS,
      callback: () => {
        onSetObjSearchParams({
          [SEARCH_PARAMS.TABLE]: TypeElements.TASKS,
          [SEARCH_PARAMS.ID_TYPE]: TASK_TYPES.MAILS
        });
      },
      icon: <MailIcon width={16} height={16} fill="#F0BA2E" />
    },
    {
      name: __(`Напоминание`),
      value: TASK_TYPES.REMINDER,
      callback: () => {
        onSetObjSearchParams({
          [SEARCH_PARAMS.TABLE]: TypeElements.TASKS,
          [SEARCH_PARAMS.ID_TYPE]: TASK_TYPES.REMINDER
        });
      },
      icon: <BellIcon width={16} height={16} fill="#26c0ca" />
    },
    {
      name: __(`Заметка`),
      value: TASK_TYPES.NOTES,
      callback: () => {
        onSetObjSearchParams({
          [SEARCH_PARAMS.TABLE]: TypeElements.TASKS,
          [SEARCH_PARAMS.ID_TYPE]: TASK_TYPES.NOTES
        });
      },
      icon: <NoteIcon />
    }
  ];
}
