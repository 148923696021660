import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";

export enum AccessTypes {
  VIEWING = "VIEWING",
  EDITING = "EDITING"
}

export enum AccessPeriodTypes {
  DAY = "DAY",
  THREE = "THREE",
  WEEK = "WEEK",
  MONTH = "MONTH",
  EMPTY = "EMPTY"
}

export type TSelectedItems = (IFolder | IFile)[];

export interface IRecipient {
  email: string;
  idUser?: string;
}

export interface IAccessSelectList {
  name: string;
  type: AccessTypes;
}

export interface IUseAccessSelectList {
  accessSelectList: IAccessSelectList[];
}

export interface IAccessPeriod {
  name: string;
  type: AccessPeriodTypes;
}
