import { IFilterLibraryState, initialFilterLibraryState } from "models/store/filterLibrary/filterLibrary";
import { FilterLibraryActions } from "models/store/filterLibrary/filterLibraryActions";
import { FilterLibraryTypes } from "Store/types/filterLibrary";

const INITIAL_STATE = initialFilterLibraryState();

export const FilterLibraryReducer = (state = INITIAL_STATE, action: FilterLibraryActions): IFilterLibraryState => {
  switch (action.type) {
    case FilterLibraryTypes.ADD_CATEGORY_FILTER_LIBRARY:
      return { ...state, category: action.payload };

    case FilterLibraryTypes.ADD_RUBRIC_FILTER_LIBRARY:
      return { ...state, idRubric: action.payload };

    case FilterLibraryTypes.ADD_SEARCH_FILTER_LIBRARY:
      return { ...state, search: action.payload };

    case FilterLibraryTypes.NULLIFY_FILTER:
      return INITIAL_STATE;

    default:
      return state;
  }
};
