import { IUserInfo } from "models/store/user/user";

export enum PROFILE_MODALS {
  NO_MODAL = "",
  ADD_EMPLOYEE = "ADD_EMPLOYEE",
  EDIT_EMPLOYEE = "EDIT_EMPLOYEE",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CHANGE_STATUS = "CHANGE_STATUS",
  EMPLOYEE_SIGNIN = "EMPLOYEE_SIGNIN"
}

export interface IProfileModalState {
  open: boolean;
  type: PROFILE_MODALS;
  employee?: IUserInfo;
}

export function initialProfileModalState(): IProfileModalState {
  return { open: false, type: PROFILE_MODALS.NO_MODAL, employee: null };
}
