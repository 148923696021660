import api from "api";
import avatar from "assets/PrivateCabinet/avatar2.svg";
import calendar, { ReactComponent as CalendarIcon } from "assets/PrivateCabinet/calendar-5.svg";
import { ReactComponent as EditIcon } from "assets/PrivateCabinet/edit.svg";
import { ReactComponent as ForwardIcon } from "assets/PrivateCabinet/forward.svg";
import mail, { ReactComponent as MailIcon } from "assets/PrivateCabinet/mail.svg";
import phone, { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-4.svg";
import { ReactComponent as PrintIcon } from "assets/PrivateCabinet/print.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { imageSrc } from "generalComponents/variables/globalVariables";
import UserAvatar from "Pages/Cabinet/Components/Project/Components/UserAvatar/UserAvatar";
import PropTypes from "prop-types";
import React, { useLayoutEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { getContacts } from "Store/actions/userAction";
import { ProjectTypes } from "Store/types/projectTypes";
import { projectUserProps } from "types/Project";

import styles from "./UserInfo.module.sass";

const UserInfo = ({ params, onClose }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const contactList = useSelector((s) => s.Cabinet.contactList);
  const user = useSelector((s) => s.user);
  const [contact, setContact] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [sname, setSName] = useState("");
  const [loading, setloading] = useState(false);
  const [_, setIsSend] = useState(false);

  useLayoutEffect(() => {
    setloading(true);
    contactList ? onFindUser() : dispatch(getContacts());
  }, [contactList]); // eslint-disable-line

  const onFindUser = () => {
    const isUser = contactList.find((u) => u.id_real_user === params.id_user);
    if (!isUser && user.userInfo.id_user === params.id_user) {
      const userMe = {
        name: user.userInfo.name,
        sname: user.userInfo.sname ? user.userInfo.sname : "",
        email: user.userInfo.email ? [user.userInfo.email] : [],
        tel: user.userInfo.tel ? [user.userInfo.tel] : []
      };
      setContact(userMe);
    } else {
      setContact(isUser);
    }
    setloading(false);
  };

  const renderBtns = () => {
    if (contact) {
      if (isEdit) {
        return <Button type="button" variant="ok" text={__("Сохранить")} onClick={onSubmit} />;
      } else {
        return <Button type="button" variant="ok" text={__("Закрыть")} onClick={onClose} />;
      }
    }
    if (!contact) {
      return (
        <>
          <Button type="button" variant="ok" text={__("Закрыть")} onClick={onClose} />
          <Button type="button" variant="ok" text={__("Добавить контакт")} onClick={onSubmit} />
        </>
      );
    }
  };

  const onEdit = () => {
    setName(contact ? contact.name : params.name);
    setSName(contact ? contact.sname : params.sname || "");
    setIsEdit((prev) => !prev);
  };

  const changeName = ({ target }) => setName(target.value);

  const changeSName = ({ target }) => setSName(target.value);

  const onSubmit = async () => {
    try {
      let apiUrl = contact ? "contacts_edit.php" : "contacts_add.php";
      const email = contact ? contact.email : [params.email];
      const payload = new FormData();
      const addName = name || params.name;
      const addSname = sname || params.sname || "";
      const contactId = contact?.id || "";

      payload.append("email", JSON.stringify(email));
      payload.append("uid", user.uid);
      payload.append("id", contactId);
      payload.append("name", addName);
      payload.append("sname", addSname);
      const { data } = await api.post(`/ajax/${apiUrl}`, payload);
      checkResponseStatus(data.ok);
      dispatch(getContacts());

      dispatch({ type: ProjectTypes.EDIT_USER, payload: [{ ...params, name, sname }] });
    } catch (error) {
      console.log(error);
    } finally {
      setIsEdit(false);
    }
  };

  const sendToggle = () => setIsSend((prev) => !prev);

  const printIframe = (id) => {
    const iframe = document.frames ? document.frames[id] : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;
    iframe.focus();
    iframeWindow.print();
    return false;
  };

  const renderName = () => {
    const imgSrc = `"${imageSrc}${params?.icon?.[0]}"`;
    return `
      <div style="display: flex; gap:10px; align-items: center; margin-bottom: 15px">   
        <img src=${
          params?.icon?.[0] ? imgSrc : avatar
        } alt="avatar" style="width:40px; height: 40px; border-radius: 50%; object-fit: cover" />
        <span>${params.name}</span>
        <span>${params.sname || ""}</span>
    </div>`;
  };

  const renderEmail = () => {
    if (contact) {
      return `
      <div style="display: flex; gap:10px; align-items: center; margin-bottom: 15px"> 
        <img src=${mail} alt="svg" style="margin-left: 7px" />
        ${contact.email?.map((email) => `<span>${email}</span>`)}
      </div>`;
    } else if (params.email) {
      return `
      <div style="display: flex; gap:10px; align-items: center; margin-bottom: 15px"> 
        <img src=${mail} alt="svg" style="margin-left: 7px" />
        <span>${params.email}</span>
      </div>`;
    } else {
      return "";
    }
  };
  const renderTel = () => {
    return contact?.tel?.length > 0
      ? `<div style="display: flex; gap:10px; align-items: center; margin-bottom: 15px" >
      <img src=${phone} alt="svg" style="margin-left: 7px" />
        <div>
          ${contact.tel.map((tel) => `<span key={i}>&nbsp;${tel}</span>`)}
        </div>
      </div>`
      : "";
  };

  const renderBdate = () => {
    return contact?.bdate
      ? `<div style="display: flex; gap:10px; align-items: center; margin-bottom: 15px">
          <img src=${calendar} alt="svg" style="margin-left: 7px" />
          <span>${contact.bdate}</span>
        </div>`
      : "";
  };

  const getPrint = () => {
    return `
    <div style="font-size: 16px; font-family: sans-serif">       
      ${renderName()}     
      ${renderEmail()}       
      ${renderTel()}
      ${renderBdate()}
     </div>`;
  };

  return (
    <div className={styles.wrap}>
      <iframe id="contact" srcDoc={getPrint()} style={{ display: "none" }} title="contact" />
      {loading ? (
        <Loader
          type="bounceDots"
          position="relative"
          background="transparent"
          zIndex={5}
          width="100px"
          height="106px"
          containerType="bounceDots"
        />
      ) : (
        <div>
          {!contact && (
            <p className={styles.subTitle}>
              {__(
                "Данный пользователь не находиться в вашем списке контактов Вы можете его добавить при помощи кнопки добавить контакт"
              )}
            </p>
          )}
          <div className={classNames(styles.block, styles.ml)}>
            <UserAvatar icon={params.icon} diameter={36} />

            <div className={styles.inputBox}>
              <label className={classNames(styles.label, { [styles.hiddenLabel]: !isEdit })}>{__("имя")}</label>
              {!isEdit ? (
                <span className={styles.name}>{name ? name : contact?.name ? contact.name : params?.name}</span>
              ) : (
                <input
                  className={classNames(styles.input, { [styles.editInput]: isEdit })}
                  type="text"
                  value={name}
                  onChange={changeName}
                />
              )}
            </div>

            <div className={styles.inputBox}>
              <label className={classNames(styles.label, { [styles.hiddenLabel]: !isEdit })}>{__("фамилия")}</label>
              {!isEdit ? (
                <span className={styles.name}>{sname ? sname : contact?.sname ? contact.sname : params?.sname}</span>
              ) : (
                <input
                  className={classNames(styles.input, { [styles.editInput]: isEdit })}
                  type="text"
                  value={sname}
                  onChange={changeSName}
                />
              )}
            </div>
          </div>
          {(contact?.email.length > 0 || params.email) && (
            <div className={styles.block}>
              <MailIcon />
              <div>{contact ? contact.email?.map((email, i) => <div key={i}>{email}</div>) : params.email}</div>
            </div>
          )}
          {contact?.tel.length > 0 && (
            <div className={styles.block}>
              <PhoneIcon />
              <div>
                {contact.tel.map((tel, i) => (
                  <div key={i}>{tel}</div>
                ))}
              </div>
            </div>
          )}
          {contact?.bdate && (
            <div className={styles.block}>
              <CalendarIcon /> <span>{contact.bdate}</span>
            </div>
          )}
        </div>
      )}

      <div className={styles.service}>
        {contact && <ForwardIcon className={classNames(styles.icon, styles.forward)} onClick={sendToggle} />}
        {user.userInfo.id_user !== params.id_user && (
          <EditIcon className={classNames(styles.icon, { [styles.edit]: isEdit })} onClick={onEdit} />
        )}
        <PrintIcon className={classNames(styles.icon, styles.print)} onClick={() => printIframe("contact")} />
      </div>
      <div className={styles.btns}>{renderBtns()}</div>
    </div>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  params: projectUserProps,
  onClose: PropTypes.func
};
