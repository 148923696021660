import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { useActions } from "hooks/useActions";
import md5 from "md5";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ISafe } from "models/store/safe/safe";
import React from "react";
import { useLocales } from "react-localized";

import AlertModal from "../../AlertModal/AlertModal";
import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./ChangeSafePassword.module.sass";

interface IChangeSafePasswordProps {
  onClose: () => void;
  safe: ISafe;
}

const ChangeSafePassword: React.FC<IChangeSafePasswordProps> = ({ onClose, safe }) => {
  const { __ } = useLocales();
  const { onChangeSafePassword } = useActions();

  const { value: pass, onChange: onChangePass, error: passError, onError: onPassError } = useInputValue();
  const { value: newPass, onChange: onChangeNewPass, error: newPassError, onError: onNewPassError } = useInputValue();
  const { value: checkPass, onChange: onChangeCheckPass, error: checkError, onError: onCheckError } = useInputValue();

  const onCheckPass = () => {
    if (md5(pass) !== safe.pass) {
      onPassError(__("Неверный пароль"));
    }
  };

  const onValidPass = (): void => {
    if (newPass.length < 8) {
      onNewPassError(__("Пароль должен быть не менее 8-ми символов"));
    }
  };

  const onValidCheckPass = (): void => {
    if (newPass !== checkPass) {
      onCheckError(__("Пароли не совпадают"));
    }
  };

  const isDisabled = (): boolean => {
    return !pass || md5(pass) !== safe.pass || !newPass || !checkPass || newPass !== checkPass || newPass.length < 8;
  };

  const onSubmit = () => {
    const messages = {
      success: __("Пароль к сейфу изменен!"),
      error: __("Не удалось изменить пароль. Попробуйте еще раз")
    };
    const payload = {
      id: safe.id,
      pass: safe.pass,
      pass_new: md5(newPass)
    };
    onChangeSafePassword(payload, messages);
  };

  return (
    <div className={styles.wrap}>
      <HeaderModal title={__("Сменить пароль")} onClose={onClose} />
      <AlertModal
        text={__(
          "Пароль к сейфу используется для шифрования данных. Потеря пароля приведет к невозможности восстановления доступа к зашифрованным данным."
        )}
      />
      <div className={styles.body}>
        <div className={styles.inputBox}>
          <Input
            value={pass}
            onChange={onChangePass}
            isPass
            error={Boolean(passError)}
            errorText={passError}
            onBlur={onCheckPass}
            label={__("Пароль")}
          />
        </div>
        <div className={styles.inputBox}>
          <Input
            value={newPass}
            onChange={onChangeNewPass}
            isPass
            error={Boolean(newPassError)}
            errorText={newPassError}
            onBlur={onValidPass}
            label={__("Новый пароль")}
          />
        </div>
        <div className={styles.inputBox}>
          <Input
            value={checkPass}
            onChange={onChangeCheckPass}
            isPass
            error={Boolean(checkError)}
            errorText={checkError}
            onBlur={onValidCheckPass}
            label={__("Повторите пароль еще раз")}
          />
        </div>
      </div>
      <div className={styles.btns}>
        <Button
          onClick={onClose}
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.LARGE}
          text={__("Отменить")}
          type="button"
        />
        <Button
          onClick={onSubmit}
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.LARGE}
          text={__("Применить")}
          type="button"
          disabled={isDisabled()}
        />
      </div>
    </div>
  );
};

export default ChangeSafePassword;
