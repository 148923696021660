import { useListMemoryTools } from "collections/profile";
import Button from "generalComponents/Button/Button";
import { PROFILE_ROUTES, ROUTES } from "generalComponents/variables/routing";
import { useCurrentPath } from "hooks/useCurrentPath";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { ListEmployees } from "../../ListEmployees/ListEmployees";
import { ListMemoryTools } from "../../ListMemoryTools/ListMemoryTools";
import { StorageDiagram } from "../../StorageDiagram/StorageDiagram";
import { StorageHead } from "../../StorageHead/StorageHead";
import styles from "./StorageContent.module.sass";

interface IStorageContentProps {
  onClose: () => void;
}

export const StorageContent: FC<IStorageContentProps> = ({ onClose }) => {
  const { __ } = useLocales();

  const { users_storage } = useUserSelectors();

  const navigate = useNavigate();

  const isMemoryPage = useCurrentPath(`/${ROUTES.PROFILE}/${PROFILE_ROUTES.MEMORY}`);

  const goToMemory = () => {
    navigate(`/${ROUTES.PROFILE}/${PROFILE_ROUTES.MEMORY}`);
    onClose();
  };

  const listMemoryTools = Object.values(useListMemoryTools())?.map(({ name, color, size }) => ({ name, color, size }));

  return (
    <div className={styles.box}>
      <div>
        <StorageHead />
        <StorageDiagram />
      </div>
      <ListMemoryTools tools={listMemoryTools} />
      <ListEmployees variant="row" employees={users_storage} />
      {!isMemoryPage && (
        <Button
          type="button"
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.SMALL}
          text={__("Подробно")}
          onClick={goToMemory}
          className={styles.btn}
        />
      )}
    </div>
  );
};
