import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload_3.svg";
import Button from "generalComponents/Button/Button";
import { useCurrentFolder } from "generalComponents/Services/folderServices/folderServices";
import { ShellContext } from "generalComponents/Shell/Shell";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { useProjectMainAccess } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useContext } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectFilesPanel.module.sass";

export const JointProjectFilesPanel = () => {
  const { __ } = useLocales();

  const { fileSelect } = useContext(ShellContext);

  const { onSetCreateFolderModal, onCreateProjectFolder, onSetSelectedUploadReplacementFile } = useActions();

  const navigate = useNavigate();

  const currentFolder = useCurrentFolder();

  const { project } = useJoinProjectsSelectors();

  const isMainAccess = useProjectMainAccess();

  return (
    <ToolsPanel size="large">
      <div className={styles.buttons}>
        <Button
          variant={ButtonVariantType.EXRTA_LIGHT}
          onClick={() => navigate(-1)}
          iconL={<ArrowLeftIcon />}
          size={ButtonSizeType.SMALL}
        />
      </div>
      {(isMainAccess || project?.accesses?.MGR_FILES) && (
        <div className={styles.buttons}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            onClick={() => {
              onSetSelectedUploadReplacementFile(null);
              fileSelect();
            }}
            iconL={<UploadIcon />}
            size={ButtonSizeType.SMALL}
            text={__("Загрузить")}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            onClick={() => {
              onSetCreateFolderModal({
                open: true,
                folder: currentFolder,
                type: "add",
                onCreateFolder: onCreateProjectFolder
              });
            }}
            size={ButtonSizeType.SMALL}
            text={`+ ${__("Новая папка")}`}
          />
        </div>
      )}
    </ToolsPanel>
  );
};
