import { ReactComponent as CalendarIcon } from "assets/PrivateCabinet/calendar-6.svg";
import { ReactComponent as PrintIcon } from "assets/PrivateCabinet/print.svg";
import { ReactComponent as UploadIcon } from "assets/PrivateCabinet/share.svg";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import Calendar from "generalComponents/Calendars/Calendar/Calendar";
import PopUp from "generalComponents/PopUp/PopUp";
import PropTypes from "prop-types";
import React, { useState } from "react";

import ProjectUsers from "../../Components/ProjectUsers/ProjectUsers";
import styles from "./ServicePanel.module.sass";

const ServicePanel = ({ date, setDate, getSnapshot }) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const onSetDate = (value) => {
    if (value) {
      setDate(value);
      setShowCalendar(false);
      return;
    }
    setDate(null);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.usersBlock}>
        <ProjectUsers />
      </div>
      <div className={styles.serviceBlock}>
        <ButtonIcon handleClick={() => {}} icon={<UploadIcon />} variant="grey" />
        <ButtonIcon handleClick={getSnapshot} icon={<PrintIcon />} variant="grey" />
        <ButtonIcon handleClick={() => setShowCalendar(true)} icon={<CalendarIcon />} variant="grey" isActive={date} />
      </div>
      {showCalendar && (
        <PopUp set={() => setShowCalendar(false)}>
          <Calendar setDateValue={onSetDate} selectRange />
        </PopUp>
      )}
    </div>
  );
};

export default ServicePanel;

ServicePanel.propTypes = {
  date: PropTypes.exact({ start: PropTypes.string, end: PropTypes.string }),
  setDate: PropTypes.func,
  getSnapshot: PropTypes.func
};
