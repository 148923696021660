import { TASK_DEPENDENCIES, TaskDependenciesType } from "models/store/joinProjects/joinProgects";

import styles from "./TaskDependencies.module.sass";
import TaskDependencyItem from "./TaskDependencyItem/TaskDependencyItem";

const TaskDependencies = ({ dependencies }: { dependencies: TaskDependenciesType }) => {
  return (
    <div className={styles.container}>
      {Object.entries(dependencies).map(([type, tasks]) => (
        <div key={type}>
          {tasks.map((t) => (
            <TaskDependencyItem key={t.id} task={t} type={type as TASK_DEPENDENCIES} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default TaskDependencies;
