import api from "api";
import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { useActions } from "hooks/useActions";
import md5 from "md5";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { STATUS_COLOR, USER_STATUS } from "models/store/user/user";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import AlertModal from "../../AlertModal/AlertModal";
import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./ChangePassword.module.sass";

interface IProps {
  closeModal: () => void;
}

const ChangePassword: FC<IProps> = ({ closeModal }) => {
  const { __ } = useLocales();
  const { userInfo } = useUserSelectors();
  const { onSetTopMessageModal, onLog, onChangeUserSettings } = useActions();
  const { value: password, onChange: setPassword, error: passwordError, onError: onPasswordError } = useInputValue();
  const {
    value: newPassword,
    onChange: setNewPassword,
    error: newPasswordError,
    onError: onNewPasswordError
  } = useInputValue();

  const {
    value: passwordRepeat,
    onChange: setPasswordRepeat,
    error: repeatError,
    onError: onRepeatError
  } = useInputValue();

  const onPasswordBlur = (): void => {
    if (md5(password) !== userInfo.pass) onPasswordError(__("Неверный текущий пароль"));
  };

  const onNewPasswordBlur = (): void => {
    if (newPassword.length < 4) {
      onNewPasswordError(__("Пароль должен быть не менее 4 символов"));
    }
    if (md5(newPassword) === userInfo.pass) {
      onNewPasswordError(__("Новый пароль не может совпадать со старым"));
    }
  };

  const onRepeatBlur = (): void => {
    if (newPassword !== passwordRepeat) {
      onRepeatError(__("Введенные новые пароли не совпадают"));
      return;
    }
  };

  const onSubmit = async () => {
    onRepeatBlur();
    try {
      const params = new FormData();

      params.append("uid", userInfo.uid);
      params.append("pass", newPassword);

      const { data } = await api.post("/ajax/user_edit2.php?", params);
      if (data.ok) {
        onChangeUserSettings({ status: USER_STATUS.OFFLINE, status_color: STATUS_COLOR.OFFLINE });
        onLog("");
        closeModal();
      }
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  return (
    <div className={styles.wrap}>
      <HeaderModal title={__("Изменить пароль")} onClose={closeModal} />
      {passwordError && <AlertModal text={passwordError} variant="red" />}
      {newPasswordError && <AlertModal text={newPasswordError} variant="red" />}
      {repeatError && <AlertModal text={repeatError} variant="red" />}
      <div className={styles.body}>
        <Input
          className={styles.input}
          value={password}
          onChange={setPassword}
          label={__("Пароль")}
          placeholder={__("Введите")}
          isPass
          error={Boolean(passwordError)}
          onBlur={onPasswordBlur}
          require
        />
        <Input
          className={styles.input}
          value={newPassword}
          onChange={setNewPassword}
          label={__("Новый пароль")}
          placeholder={__("Введите")}
          isPass
          error={Boolean(newPasswordError)}
          onBlur={onNewPasswordBlur}
          require
        />
        <Input
          className={styles.input}
          value={passwordRepeat}
          onChange={setPasswordRepeat}
          onBlur={onRepeatBlur}
          label={__("Повторите пароль")}
          placeholder={__("Введите")}
          error={Boolean(repeatError)}
          isPass
          require
        />
      </div>
      <div className={styles.footer}>
        <Button
          onClick={closeModal}
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.MEDIUM}
          text={__("Отменить")}
          type="button"
        />
        <Button
          onClick={onSubmit}
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.MEDIUM}
          text={__("Изменить")}
          disabled={
            Boolean(passwordError) ||
            Boolean(passwordError) ||
            Boolean(repeatError) ||
            !password ||
            !newPassword ||
            !passwordRepeat
          }
        />
      </div>
    </div>
  );
};

export default ChangePassword;
