import JointProjectsTaskCard from "containers/JoinProject/JointProjectsTasks/components/JointProjectsTaskCard/JointProjectsTaskCard";
import { IProjectTask } from "models/store/joinProjects/joinProgects";
import { FC } from "react";

import styles from "./JointProjectsHistoryGrid.module.sass";

interface IJointProjectsHistoryGridProps {
  tasks: IProjectTask[];
}

export const JointProjectsHistoryGrid: FC<IJointProjectsHistoryGridProps> = ({ tasks }) => (
  <div className={styles.container}>
    {tasks?.length > 0 &&
      tasks.map((item) => (
        <div key={item.id}>
          <JointProjectsTaskCard item={item} />
        </div>
      ))}
  </div>
);
