import classNames from "classnames";
import { noteTitles, useNotesTitles } from "collections/notes";
import { convertToRaw, EditorState } from "draft-js";
import Button from "generalComponents/Button/Button";
import NoteEditor from "generalComponents/NotesEditor/NoteEditor";
import NoteSidebarItem from "generalComponents/NotesSidebar/components/NoteSidebarItem/NoteSidebarItem";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialNotesSidebarState } from "models/store/Cabinet/modals/notesSidebar";
import { INote } from "models/store/notes/notes";
import { useEffect, useRef, useState } from "react";
import { EditorState as IEditorState } from "react-draft-wysiwyg";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useNotesSelectors } from "Store/selectors/notesSelectors";

import styles from "./NotesSidebar.module.sass";

const NotesSidebar = () => {
  const { __ } = useLocales();
  const { onSetNotesSidebar, onGetAllNotes, onAddNewNote } = useActions();
  const { notes } = useNotesSelectors();
  const [sortedNotes, setSortedNotes] = useState<{ [date: string]: INote[] }>({});
  const [editorState, setEditorState] = useState<IEditorState>(EditorState.createEmpty());
  const [password, setPassword] = useState<string>();
  const [emo, setEmo] = useState<string>();
  const [color, setColor] = useState<string>();
  const [tags, setTags] = useState<string[]>([]);
  const titles = useNotesTitles();
  const navigate = useNavigate();
  const {
    notesSidebar: { open }
  } = useGlobalModalsSelectors();

  const sidebarRef = useRef(null);

  const closeSidebar = () => {
    onSetNotesSidebar(initialNotesSidebarState());
  };

  useEffect(() => {
    onGetAllNotes();
  }, []); //eslint-disable-line

  useEffect(() => {
    sortNotesByDate(notes);
  }, [notes]); //eslint-disable-line

  const sortNotesByDate = (notes: INote[]) => {
    const sorted: { [date: string]: INote[] } = {};
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    notes?.forEach((note) => {
      const noteDate = new Date(note.ut);
      const todayMidnight = new Date(today);
      todayMidnight.setHours(0, 0, 0, 0);
      const yesterdayMidnight = new Date(yesterday);
      yesterdayMidnight.setHours(0, 0, 0, 0);

      let category = "";
      if (noteDate >= todayMidnight) {
        category = noteTitles.TODAY;
      } else if (noteDate >= yesterdayMidnight) {
        category = noteTitles.YESTERDAY;
      } else if (noteDate >= lastWeek) {
        category = noteTitles.LAST_7_DAYS;
      } else {
        category = noteTitles.LAST_30_DAYS;
      }

      // Instead of push, use unshift to add notes at the beginning
      if (!sorted[category]) {
        sorted[category] = [];
      }
      sorted[category].unshift(note);
    });

    for (const key in sorted) {
      sorted[key].sort((a, b) => {
        return new Date(b.ut).getTime() - new Date(a.ut).getTime();
      });
    }

    setSortedNotes(sorted);
  };

  const addNote = () => {
    const text = convertToRaw(editorState.getCurrentContent());
    onAddNewNote({ prim: JSON.stringify(text), pass: password, emo, tags, color });
    setColor("");
    setEmo("");
    setTags([]);
    setEditorState(EditorState.createEmpty());
  };

  return (
    <div className={classNames(styles.wrapper, open && styles.open)} onClick={closeSidebar}>
      <div ref={sidebarRef} className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.editor}>
          <NoteEditor
            editorState={editorState}
            setEditorState={setEditorState}
            height="200px"
            setPassword={setPassword}
            password={password}
            emo={emo}
            setEmo={setEmo}
            color={color}
            setColor={setColor}
            tags={tags}
            setTags={setTags}
          />
          <div className={styles.buttons}>
            <Button
              variant={ButtonVariantType.LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Все заметки")}
              onClick={() => {
                closeSidebar();
                navigate(ROUTES.NOTES);
              }}
            />
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={__("Создать")}
              onClick={() => addNote()}
            />
          </div>
        </div>
        <div className={styles.content}>
          {Object.keys(noteTitles).map(
            (category) =>
              sortedNotes[category] &&
              sortedNotes[category].length > 0 && (
                <div key={category}>
                  <div className={styles.header}>{titles[category]}</div>
                  <div className={styles.notes}>
                    {sortedNotes[category].map((item) => (
                      <NoteSidebarItem key={item.id} item={item} />
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default NotesSidebar;
