import { DropArea } from "generalComponents/DropArea/DropArea";
import Loader from "generalComponents/Loaders/4HUB";
import { Observer } from "generalComponents/Observer/Observer";
import UploadArea from "generalComponents/UploadArea/UploadArea";
import { PAGE_DEPTH } from "generalComponents/variables/global";
import { WorkSpaceWrap } from "generalComponents/WorkSpaceWrap/WorkSpaceWrap";
import { useActions } from "hooks/useActions";
import { useDragDropUpload } from "hooks/useDragDropUpload";
import { LoaderTypes } from "models/generalComponents/loader";
import { TProjectFilesArray } from "models/store/joinProjects/joinProgects";
import { useParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import { JointProjectFilesGrid } from "./components/JointProjectFilesGrid/JointProjectFilesGrid";

const JoinProjectElementsList = (): JSX.Element => {
  const { loader, project, page, total } = useJoinProjectsSelectors();
  const { onGetProjectFiles, onSetProjectPage, onSetProjectSelectedFiles, onSetProjectFiles } = useActions();

  const { dirId } = useParams();

  const projectFiles = project?.files;

  const { handleDrop, dragStartHandler, dragEndHandler, isDrag } = useDragDropUpload(onSetProjectSelectedFiles);

  const loadMore = (): void => {
    const controller = new AbortController();
    onSetProjectPage(page + 1);

    if (dirId || projectFiles?.length) {
      const payload = {
        id_dir: dirId || projectFiles[0]?.id_dir,
        dep: PAGE_DEPTH.PROJECT_FILES,
        callback: (files: TProjectFilesArray) => onSetProjectFiles(files)
      };

      onGetProjectFiles(payload, controller);
    }
  };

  const renderMyFiles = (): JSX.Element => {
    if (projectFiles !== null) {
      if (total === 0) {
        return <UploadArea />;
      }

      return <JointProjectFilesGrid />;
    }
  };

  return (
    <WorkSpaceWrap onDragStart={dragStartHandler} onDragOver={dragStartHandler} isDrag={isDrag}>
      {loader && page === 1 ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <>
          {renderMyFiles()}
          {<Observer loader={loader} page={page} total={total} callBack={loadMore} />}
        </>
      )}
      {isDrag && (
        <DropArea
          onDragStart={dragStartHandler}
          onDragLeave={dragEndHandler}
          onDragOver={dragStartHandler}
          onDrop={handleDrop}
        />
      )}
    </WorkSpaceWrap>
  );
};

export { JoinProjectElementsList };
