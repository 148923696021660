import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import CombinedElements from "generalComponents/Elements/CombinedElements";
import { createMarkup } from "generalComponents/Services/browserServices";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IMyTask } from "models/store/tasks/tasks";
import React from "react";
import { useLocales } from "react-localized";

import styles from "./OpenNote.module.sass";

interface IOpenNoteProps {
  params: IMyTask;
  closeModal?: () => void;
}

const OpenNote: React.FC<IOpenNoteProps> = ({ params, closeModal }) => {
  const { __ } = useLocales();

  return (
    <div className={styles.wrap}>
      <h3 className={styles.title}>{params.name}</h3>
      <div className={classNames("htmlContent", styles.note)} dangerouslySetInnerHTML={createMarkup(params.prim)} />
      <CombinedElements color={params.color} emo={params.emo} tags={params.tags} disabled />
      <div className={styles.btns}>
        <Button
          onClick={closeModal}
          variant={ButtonVariantType.LIGHT}
          size={ButtonSizeType.LARGE}
          text={__("Закрыть")}
          type="button"
        />
      </div>
    </div>
  );
};

export default OpenNote;
