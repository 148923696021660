import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import { onSetModals } from "../../../../../Store/actions/ModalActions";
import styles from "./ProjectSpace.module.sass";

const ProjectSpace: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { project } = useProjectsSelectors();

  useEffect(() => {
    if (project && Number(project?.show_message) > 0 && project?.show_message === project.is_read) {
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.MESSAGE_ADMIN, params: project.show_message }));
    }
  }, [project]); //eslint-disable-line

  return <div className={styles.wrap}>{project.id && <Outlet />}</div>;
};

export default ProjectSpace;
