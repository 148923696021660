import classNames from "classnames";
import CalenderItem from "containers/Dashboard/Calendar/CalenderItem/CalenderItem";
import Empty from "containers/Dashboard/Empty/Empty";
import { STATUS_TYPES } from "generalComponents/variables/tasks";
import { useDateFormat } from "hooks/useDateFormat";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";
import { groupAllTasksByDate } from "utils/tasks";

import styles from "./Calendar.module.sass";

const Calendar = ({ type }: { type: string }) => {
  const { myTasks } = useTasksSelectors();
  const { formatDate } = useDateFormat();

  const futureTasks = myTasks.filter((task) => {
    const taskEndDate = new Date(task.date_end);
    const taskStartDate = new Date(task.date_start);
    const now = new Date();

    taskEndDate.setHours(0, 0, 0, 0);
    taskStartDate.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    return (
      (taskEndDate.getTime() >= now.getTime() || taskStartDate.getTime() >= now.getTime()) &&
      task.id_status !== STATUS_TYPES.DONE
    );
  });

  const groupedTasks = groupAllTasksByDate(futureTasks);

  for (const tasksOfDay in groupedTasks) {
    groupedTasks[tasksOfDay].sort(
      (a, b) => new Date(b.date_end || b.date_start).getTime() - new Date(a.date_end || a.date_start).getTime()
    );
  }

  const dates = Object.keys(groupedTasks).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  return (
    <div className={classNames(styles.container, styles[`container_${type}`])}>
      {dates.length === 0 ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={classNames(styles.scrollWrapper, "scrollbar-thin-blue")}>
          {dates.map((date) => (
            <div key={date} className={styles.dateBlock}>
              <div className={styles.date}>{formatDate(date, "fullDayShortMonth")}</div>
              <div className={styles.list}>
                {groupedTasks[date].map((item, i) => (
                  <CalenderItem task={item} key={i} />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Calendar;
