export const SIZE_SMALL = "small";
export const AUDIO_MESSAGE = "audio_message";
export const VIDEO_MESSAGE = "video_message";
export const PHOTO = "image";
export const FILES = "files";
export const VOICE_MESSAGES = "voiceMessages";
export const MUSIC = "music";
export const LINKS = "links";
export const MEDIA = "media";
export const VIDEO = "video";
export const AUDIO = "audio";
export const GIF = "gif";
export const DOCS = "application";
export const DARK = "dark";
export const MAIN = "main";
export const DELETE_CHAT_GROUP = "deleteChatGroup";
export const LEAVE_FROM_CHAT_GROUP = "leaveFromChatGroup";
export const CHECKBOX = "checkBox";
export const CONTEXT_MENU = "contextMenu";
export const LOCAL_CLIENT = "LOCAL_CLIENT";
export const LOCAL_SCREEN_CLIENT = "LOCAL_SCREEN_CLIENT";
export enum CHAT_CALLROOM {
  // OUTGOING_CALL = "OUTGOING_CALL", // ->
  // OUTGOING_VIDEO_CALL = "OUTGOING_VIDEO_CALL", // ->
  INCOMING_CALL = "INCOMING_CALL", // <-

  NO_CALL = "NO_CALL",
  VOICE_CALL = "VOICE_CALL",
  VIDEO_CALL = "VIDEO_CALL",
  JOINED = "JOINED",
  ACCEPTED = "ACCEPTED"
}

export enum ICE_CONNECTION_STATE {
  CHECKING = "checking",
  CONNECTED = "connected",
  DISCONNECTED = "disconnected"
}

export const CHAT_CALLROOM_SOCKET_ACTION = "call_room";

export enum CHAT_CALLROOM_ACTIONS { // if messages grouped, -> means send message, <- receive message accordingly
  OUTGOING_CALL = "OUTGOING_CALL", // ->
  OUTGOING_VIDEO_CALL = "OUTGOING_VIDEO_CALL", // ->
  INCOMING_CALL = "INCOMING_CALL", // <-

  ACCEPT_CALL = "ACCEPT_CALL", // ->
  ADD_PEER = "ADD_PEER", // <-

  RELAY_ICE = "RELAY_ICE", // ->
  ICE_CANDIDATE = "ICE_CANDIDATE", // <-

  RELAY_SDP = "RELAY_SDP", // ->
  SESSION_DESCRIPTION = "SESSION_DESCRIPTION", // <-

  REQUEST_MEDIA_STREAM = "REQUEST_MEDIA_STREAM", // -> as a response receiver should add track to pc

  CALLROOM_CHECK_CONNECTION = "CALLROOM_CHECK_CONNECTION", // <- ->
  CALLROOM_CHECK_CONNECTION_REPLY = "CALLROOM_CHECK_CONNECTION_REPLY", // <-

  // Not grouped
  ASK_TO_CONNECT = "ASK_TO_CONNECT",
  LEAVE = "LEAVE",
  REMOVE_PEER = "REMOVE_PEER",
  STOP_CALL = "STOP_CALL",
  SWITCH_VIDEO = "SWITCH_VIDEO",
  SCREEN_SHARE = "SCREEN_SHARE",
  CLOSE_ROOM = "CLOSE_ROOM",
  INVITE_CALLROOM = "INVITE_CALLROOM",
  RISE_HAND = "RISE_HAND",
  OPEN_PAINT_ROOM = "OPEN_PAINT_ROOM",
  CLOSE_PAINT_ROOM = "CLOSE_PAINT_ROOM",
  CHECK_CONNECTED_USERS = "CHECK_CONNECTED_USERS",
  ASK_RECONNECTION = "ASK_RECONNECTION",
  RECONNECT_USER = "RECONNECT_USER",
  NO_ACTION = "NO_ACTION",
  DRAW_CANVAS = "DRAW_CANVAS" // <- ->
}

export enum CHAT_ACTIONS {
  UPDATE_MESSAGE = "chat_message_update",
  UPDATE_GROUP_CHAT = "/ajax/chat_group_message_update.php"
}

export enum MEDIA_TRACK_KIND {
  AUDIO = "audio",
  VIDEO = "video",
  SCREEN = "screen"
}

export enum CHAT_MODALS {
  ADD_USER = "ADD_USER",
  ADD_TASK = "ADD_TASK_FROM_CHAT",
  ADD_QUICK_TASK = "ADD_QUICK_TASK_FROM_CHAT"
}

export enum CHAT_TASK {
  BACKLOG = "backlog",
  SPRINT = "sprint"
}

export enum CHAT_MESSAGE_ACTION {
  ADD_TASK = "ADD_TASK",
  EDIT_TASK = "EDIT_TASK"
}
