import api from "api";
import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { useActions } from "hooks/useActions";
import md5 from "md5";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { ISafe } from "models/store/safe/safe";
import { FC, useState } from "react";
import { useLocales } from "react-localized";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import AlertModal from "../../AlertModal/AlertModal";
import HeaderModal from "../../HeaderModal/HeaderModal";
import styles from "./EnterSafePassword.module.sass";

interface IEnterSafePasswordProps {
  onClose: () => void;
  safe: ISafe;
  callbackType: SAFE_CALLBACK_MODAL;
  alert?: string;
}

const EnterSafePassword: FC<IEnterSafePasswordProps> = ({ onClose, safe, callbackType, alert }) => {
  const { __ } = useLocales();
  const { safeId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { onEditSafe, onSetApproveModal, onSetSafeModal, onSetLoaderModal, onSetTopMessageModal } = useActions();
  const { uid } = useUserSelectors();

  const { value, onChange, error, onError } = useInputValue();
  const [errorCounter, setErrorCounter] = useState<number>(0);
  const [prim, setPrim] = useState<string>("");

  const onDelete = (): void => {
    const payload = { id: safe.id, pass: md5(value), is_del: "1" };
    const params = {
      titleHead: __("Удалить"),
      title: `${__("Вы действительно хотите удалить")} <span>${safe.name}</span>?`,
      text: `${__(`Вы сможете восстановить ${safe.name} с корзины`)}`,
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.OK_RED,
      callback: (): void => {
        onEditSafe(payload, EVENT_TYPE.IS_DEL);
      }
    };
    onSetApproveModal({
      open: true,
      params
    });
    onClose();
  };

  const onGetAccess = async () => {
    try {
      onSetLoaderModal(true);
      const params = {
        uid,
        id_safe: safe.id,
        pass: md5(value),
        verify_only: 0
      };
      const { data } = await api.get("/ajax/safe_get_access.php?", { params });
      if (data.ok) {
        onSetSafeModal({ open: true, type: SAFE_MODALS.ENTER_SAFE_CODE, safe });
      } else {
        if (data.pass_error >= 5 && data.prim) {
          setPrim(data.prim);
        }
        if (+data.pass_error >= 10) {
          onSetSafeModal({
            open: true,
            type: SAFE_MODALS.SAFE_BLOKED,
            safe: { ...safe, time_blocked: data.time_blocked }
          });
        }
        onError(__("Неверный пароль"));
        setErrorCounter(data.pass_error);
      }
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR
      });
    } finally {
      onSetLoaderModal(false);
    }
  };

  const handleClose = (): void => {
    if (safe.id === safeId) {
      navigate(`/${ROUTES.SAFE}${search}`, { replace: true });
    }
    onClose();
  };

  const onSubmit = () => {
    switch (callbackType) {
      case SAFE_CALLBACK_MODAL.DELETE: {
        onDelete();
        return;
      }
      case SAFE_CALLBACK_MODAL.FETCH_FILES: {
        onGetAccess();
        return;
      }
      default:
        break;
    }
  };

  return (
    <div className={styles.wrap}>
      <HeaderModal title={__("Доступ Ограничен")} onClose={onClose} />
      {alert && <AlertModal text={alert} variant="green" />}
      <div className={styles.body}>
        <Input value={value} onChange={onChange} isPass label={__("Пароль")} error={Boolean(error)} errorText={error} />
        {+errorCounter >= 5 && <span className={styles.helperText}>{prim}</span>}
      </div>
      <div className={styles.btns}>
        <Button
          onClick={handleClose}
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.LARGE}
          text={__("Отменить")}
          type="button"
        />
        <Button
          onClick={onSubmit}
          variant={ButtonVariantType.BLUE}
          size={ButtonSizeType.LARGE}
          text={__("Открыть доступ ")}
          type="button"
          disabled={!value || (md5(value) !== safe.pass && callbackType === SAFE_CALLBACK_MODAL.DELETE)}
        />
      </div>
    </div>
  );
};

export default EnterSafePassword;
