import { IFaqCategory, IFaqItem } from "collections/faq";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router";

import styles from "./FaqDetails.module.sass";

const FaqDetails = ({ data }: { data: IFaqCategory | IFaqItem }) => {
  const { __ } = useLocales();
  const [anchorLinks, setAnchorLinks] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const container = document.getElementById("text");

    if (container) {
      const ids = Array.from(container.querySelectorAll("[id]")).map((element) => element.id);
      setAnchorLinks(ids);
    }
  }, [data]);

  useEffect(() => {
    const currentHash = window.location.hash.substr(1);
    const isHashInAnchorLinks = anchorLinks.includes(decodeURIComponent(currentHash));
    if (isHashInAnchorLinks) {
      handleLinkClick(decodeURIComponent(currentHash));
    }
  }, [anchorLinks]);

  const handleLinkClick = (id: string) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
      window.history.pushState(null, "", `#${id}`);
    }
  };

  const handleButtonClick = (route: string) => {
    const currentPath = window.location.pathname;
    navigate(currentPath + `/${route}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div id="text" className={styles.text}>
          {typeof data.content === "function" ? (
            data.content()
          ) : (
            <div className={styles.navigate}>
              {data.content.map((item, i) => (
                <button key={i} className={styles.item} onClick={() => handleButtonClick(item.route)}>
                  {item.title}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      {anchorLinks.length > 0 && (
        <div className={styles.sidebar}>
          <div className={styles.title}>{__("Содержание")}</div>
          <ul>
            {anchorLinks.map((link) => (
              <li key={link}>
                <button onClick={() => handleLinkClick(link)}>{link}</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FaqDetails;
