/* eslint-disable react/display-name */
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/closeModal.svg";
import Button from "generalComponents/Button/Button";
import { PreviewFileLibrary } from "generalComponents/PreviewFileLibrary/PreviewFileLibrary";
import { PreviewUploadFileLibrary } from "generalComponents/PreviewUploadFileLibrary/PreviewUploadFileLibrary";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IFile } from "models/store/files/files";
import { memo } from "react";
import { Dispatch, SetStateAction } from "react";
import { v4 as uuid } from "uuid";

import styles from "./PreviewUploadFiles.module.sass";

interface IPreviewUploadFilesProps {
  files: File[];
  className?: string;
  removeFile: (id: number) => void;
  folderFiles: IFile[];
  onSetFilesFolder: Dispatch<SetStateAction<IFile[]>>;
  onSetRemovedFilesFolder: Dispatch<SetStateAction<IFile[]>>;
}

export const PreviewUploadFiles = memo<IPreviewUploadFilesProps>(
  ({
    files,
    folderFiles,
    removeFile,
    onSetFilesFolder,
    onSetRemovedFilesFolder
  }: IPreviewUploadFilesProps): JSX.Element => {
    const filesUploadReverse = [...files].reverse();

    const removeFileFolder = (file: IFile) => {
      onSetFilesFolder((prev: IFile[]) => [...prev].filter(({ fid }) => fid !== file.fid));
      onSetRemovedFilesFolder((prev: IFile[]) => [...prev, file]);
    };

    return (
      (!!filesUploadReverse?.length || !!folderFiles?.length) && (
        <div className={styles.previewFiles}>
          {!!filesUploadReverse?.length &&
            filesUploadReverse.map((file, index) => (
              <div key={uuid()} className={styles.previewFile}>
                <Button
                  variant={ButtonVariantType.DARK}
                  size={ButtonSizeType.EXTRA_SMALL}
                  onClick={() => removeFile(index)}
                  iconL={<TrashIcon width={9} height={9} />}
                  className={styles.btnRemove}
                  isSquare
                />
                <PreviewUploadFileLibrary file={file} />
              </div>
            ))}
          {!!folderFiles?.length &&
            folderFiles.map((file) => (
              <div key={file.fid} className={styles.previewFile}>
                <Button
                  variant={ButtonVariantType.DARK}
                  size={ButtonSizeType.SMALL}
                  onClick={() => removeFileFolder(file)}
                  iconL={<TrashIcon width={9} height={9} />}
                  className={styles.btnRemove}
                  isSquare
                />
                <PreviewFileLibrary file={file} clickable={false} />
              </div>
            ))}
        </div>
      )
    );
  }
);
