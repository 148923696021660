import Clue from "generalComponents/Clue/Clue";
import { FoldersLibrary } from "generalComponents/FoldersLibrary/FoldersLibrary";
import Loader from "generalComponents/Loaders/4HUB";
import { Observer } from "generalComponents/Observer/Observer";
import { WorkSpaceWrap } from "generalComponents/WorkSpaceWrap/WorkSpaceWrap";
import { useActions } from "hooks/useActions";
import { useIsFiltersLibrary } from "hooks/useIsFilters";
import { LoaderTypes } from "models/generalComponents/loader";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useLibrarySelectors } from "Store/selectors/librarySelectors";

import { LibraryFolderInfo } from "../LibraryFolderInfo/LibraryFolderInfo";

interface ILibraryElementsListProps {
  isLinePreview?: boolean;
}

export const LibraryElementsList: FC<ILibraryElementsListProps> = ({ isLinePreview = false }): JSX.Element => {
  const { __ } = useLocales();

  const { onGetLibraryElements, onSetLibraryPage } = useActions();

  const { loader, folderInfo, foldersLibrary, page, total } = useLibrarySelectors();

  const { libraryDirId } = useParams();

  const isFiltersLibrary = useIsFiltersLibrary();

  const onLoadMore = (): void => {
    const controller = new AbortController();

    onSetLibraryPage(page + 1);

    onGetLibraryElements(controller, libraryDirId ? { id_dir: libraryDirId } : null);
  };

  const renderMyFiles = (): JSX.Element => {
    if (foldersLibrary !== null) {
      if (total === 0) {
        if (isFiltersLibrary) {
          return <Clue icon="neutral" text={__("Нет файлов соответствующих параметрам фильтрации")} />;
        }

        return <Clue icon="sad" text={__("Здесь пока пусто")} />;
      }
      return (
        <>
          {libraryDirId && libraryDirId === folderInfo?.id_dir && (
            <LibraryFolderInfo prim={folderInfo.prim} ctime={folderInfo.ctime} />
          )}
          <FoldersLibrary folders={foldersLibrary} isLinePreview={isLinePreview} />
        </>
      );
    }
  };

  return (
    <WorkSpaceWrap>
      {loader && page === 1 ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <>
          {renderMyFiles()}
          {<Observer loader={loader} page={page} total={total} callBack={onLoadMore} />}
        </>
      )}
    </WorkSpaceWrap>
  );
};
