import SoundRecord from "containers/SoundRecord/SoundRecord";
import VideoRecord from "containers/VideoRecord/VideoRecord";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { initialRecordModalState, IRecordType } from "models/store/Cabinet/modals/record";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

const RecordModal = () => {
  const { onSetRecordModal } = useActions();
  const {
    record: { type }
  } = useGlobalModalsSelectors();

  const onClose = () => {
    onSetRecordModal(initialRecordModalState());
  };

  return (
    <PopUp position={"right-top"} zIndex={20}>
      {type === IRecordType.VIDEO && <HeaderModal title="Запись видео" onClose={onClose} />}
      {type === IRecordType.VIDEO ? <VideoRecord /> : <SoundRecord />}
    </PopUp>
  );
};

export default RecordModal;
