export enum CategoriesPropertiesTypes {
  NO_TYPE = "",
  GENERAL = "general",
  ACCESSES = "accesses",
  VERSIONS = "versions"
}

export enum PropertyParameterTypes {
  SIZE = "size",
  NUMBER_OF_FILES = "numberOfFiles",
  DATE_CREATION = "dateCreation",
  DATE_CHANGE = "dateChange"
}

export interface ICategoryProperties {
  name: string;
  type: CategoriesPropertiesTypes;
}

export interface IPropertySettings {
  name: string;
  type: PropertyParameterTypes;
}

export interface IUseCategoryPropertiesList {
  categoriesProperties: ICategoryProperties[];
}

export interface IUsePropertySettingsList {
  propertySettingsFolder: IPropertySettings[];
  propertySettingsFile: IPropertySettings[];
}
