import { ReactComponent as FileIcon } from "assets/icons/file_3.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder_1.svg";
import { ReactComponent as ArrowDownIcon } from "assets/PrivateCabinet/arrow_down.svg";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { ShellContext } from "generalComponents/Shell/Shell";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { useContext, useState } from "react";
import { useLocales } from "react-localized";

import styles from "./TempDownload.module.sass";

const TempDownload = (): JSX.Element => {
  const { __ } = useLocales();
  const { fileSelect, folderFileSelect } = useContext(ShellContext);

  const [show, setShow] = useState<boolean>(false);

  const isFolderPage = useCurrentPathStarstWith(ROUTES.FOLDERS);

  const { onSetSelectedUploadReplacementFile } = useActions();

  const renderMenu = (): JSX.Element => {
    return (
      <ul className={styles.list}>
        <li className={styles.list__item}>
          <label
            onClick={() => {
              onSetSelectedUploadReplacementFile(null);
              fileSelect();
              setShow(false);
            }}
            className={styles.list__label}
          >
            <FileIcon />
            <span>{__("Файл")}</span>
          </label>
        </li>
        <li className={styles.list__item}>
          <label
            onClick={() => {
              onSetSelectedUploadReplacementFile(null);
              folderFileSelect();
              setShow(false);
            }}
            className={styles.list__label}
          >
            <FolderIcon />
            <span>{__("Папку")}</span>
          </label>
        </li>
      </ul>
    );
  };

  return isFolderPage ? (
    <PopoverOverlay
      width={131}
      overlayContent={renderMenu()}
      placement="bottom"
      classes={{ popoverBody: styles.nopadding }}
      show={show}
      setShow={setShow}
    >
      <span className={styles.button}>
        <span className={styles.text}>{__("Загрузить")}</span>
        <ArrowDownIcon className={styles.icon} />
      </span>
    </PopoverOverlay>
  ) : (
    <label
      onClick={() => {
        onSetSelectedUploadReplacementFile(null);
        fileSelect();
      }}
      className={styles.button}
    >
      {__("Загрузить")}
    </label>
  );
};

export default TempDownload;
