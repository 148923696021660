import { addHours, format } from "date-fns";
import { ICalendarDay, IDateObject, IDaysOfWeeks, IMonth, TYPE_DAY } from "models/generalComponents/calendars";
import { useLocales } from "react-localized";

import { typeCheck } from "../Services/browserServices";
import { TYPES } from "../variables/global";

const d: Date = new Date();

export const MS_OF_DAY: number = 86400000;
export const MS_Of_MINUTE = 60000;
export const MS_OF_WEEK = MS_OF_DAY * 7;

export const dateToMs = (d: string): number => new Date(d).getTime();

export const useMonths = (): ((year?: number) => IMonth[]) => {
  const { __ } = useLocales();
  return (year: number): IMonth[] => [
    {
      id: 0,
      name: __("Январь"),
      declensionName: __("Января"),
      code: 1,
      days: 31
    },
    {
      id: 1,
      name: __("Февраль"),
      declensionName: __("Февраля"),
      code: 4,
      days: year % 4 === 0 ? 29 : 28
    },
    { id: 2, name: __("Март"), declensionName: __("Марта"), code: 4, days: 31 },
    {
      id: 3,
      name: __("Апрель"),
      declensionName: __("Апреля"),
      code: 0,
      days: 30
    },
    { id: 4, name: __("Май"), declensionName: __("Мая"), code: 2, days: 31 },
    { id: 5, name: __("Июнь"), declensionName: __("Июня"), code: 5, days: 30 },
    { id: 6, name: __("Июль"), declensionName: __("Июля"), code: 0, days: 31 },
    {
      id: 7,
      name: __("Август"),
      declensionName: __("Августа"),
      code: 3,
      days: 31
    },
    {
      id: 8,
      name: __("Сентябрь"),
      declensionName: __("Сентября"),
      code: 6,
      days: 30
    },
    {
      id: 9,
      name: __("Октябрь"),
      declensionName: __("Октября"),
      code: 1,
      days: 31
    },
    {
      id: 10,
      name: __("Ноябрь"),
      declensionName: __("Ноября"),
      code: 4,
      days: 30
    },
    {
      id: 11,
      name: __("Декабрь"),
      declensionName: __("Декабря"),
      code: 6,
      days: 31
    }
  ];
};

export const useGetMonthByIndex = (): ((index?: number) => string) => {
  const months = useMonths();
  return (index: number): string => {
    const findMonth = months().find((item) => item.id === index);
    return findMonth.name;
  };
};

export const useDaysOfWeeks = (): IDaysOfWeeks => {
  const { __ } = useLocales();
  return {
    short: [__("Пн"), __("Вт"), __("Ср"), __("Чт"), __("Пт"), __("Сб"), __("Вс")],
    code: [2, 3, 4, 5, 6, 0, 1]
  };
};

export const useWeeksDays = (): IDaysOfWeeks => {
  const { __ } = useLocales();
  return {
    short: [__("Пн"), __("Вт"), __("Ср"), __("Чт"), __("Пт"), __("Сб"), __("Вс")],
    code: [2, 3, 4, 5, 6, 0, 1]
  };
};

const centuryCode = (year: number): number => {
  const century = Number(String(year).slice(0, 2));
  if (century % 4 === 0) return 6;
  if (century % 4 === 1) return 4;
  if (century % 4 === 2) return 2;
  if (century % 4 === 3) return 0;
};

const yearCode = (year: number): number => {
  const short = Number(String(year).slice(2));
  return (centuryCode(year) + short + Math.floor(short / 4)) % 7;
};

export const getToday = (): IDateObject => {
  return {
    day: d.getDate(),
    month: d.getMonth(),
    year: d.getFullYear()
  };
};

export const useRenderDaysInMonth = () => {
  const months = useMonths();
  return (month: number, year: number): number[] => {
    let days = months(year)[month].days;
    let arr = [];
    for (let i = 1; i <= days; i++) {
      arr.push(i);
    }
    return arr;
  };
};

const useDayInWeek = () => {
  const months = useMonths();
  return (obj: IDateObject) => {
    if (obj.year % 4 === 0 && obj.month <= 1) {
      return {
        firstDay: ((1 + months()[obj.month].code + yearCode(obj.year)) % 7) - 1,
        today: ((obj.day + months()[obj.month].code + yearCode(obj.year)) % 7) - 1,
        lastDay: ((months()[obj.month].days + months()[obj.month].code + yearCode(obj.year)) % 7) - 1
      };
    } else {
      return {
        firstDay: (1 + months()[obj.month].code + yearCode(obj.year)) % 7,
        today: (obj.day + months()[obj.month].code + yearCode(obj.year)) % 7,
        lastDay: (months()[obj.month].days + months()[obj.month].code + yearCode(obj.year)) % 7
      };
    }
  };
};

const useRenderBeforeDays = () => {
  const months = useMonths();
  return (month: number, i: number): number[] => {
    const arr = [];
    for (let j = 0; j < i; j++) {
      arr.unshift(months()[month].days - j);
    }
    return arr;
  };
};

const renderAfterDays = (last: number): number[] => {
  let arr = [];
  for (let i = 1; i <= 6 - last; i++) {
    arr.push(i);
  }
  return arr;
};

export const useGenerateCalendar = () => {
  const renderDaysInMonth = useRenderDaysInMonth();
  const dayInWeek = useDayInWeek();
  const renderBeforeDays = useRenderBeforeDays();
  const daysOfWeeks = useDaysOfWeeks();
  return (strings: number, obj: IDateObject): number[][] => {
    const arr = [];
    obj.month === 0
      ? arr.push(renderBeforeDays(11, daysOfWeeks.code.indexOf(dayInWeek(obj).firstDay)))
      : arr.push(renderBeforeDays(obj.month - 1, daysOfWeeks.code.indexOf(dayInWeek(obj).firstDay)));

    arr.push(renderDaysInMonth(obj.month, obj.year));
    arr.push(renderAfterDays(daysOfWeeks.code.indexOf(dayInWeek(obj).lastDay)));
    if (arr[0].length === 0) {
      arr.splice(0, 1, obj.month === 0 ? renderBeforeDays(11, 7) : renderBeforeDays(obj.month - 1, 7));
    }
    if (arr[2].length === 0) {
      arr.splice(2, 1, renderAfterDays(-1));
    }
    if (strings * 7 > arr.flat().length) {
      let number = arr[2][arr[2].length - 1] + 1;
      const delta = strings * 7 - arr.flat().length;
      for (let i = 0; i < delta; i++) {
        arr[2].push(number);
        number++;
      }
    }
    return arr;
  };
};

export const useGenerateCalendarWeeks = () => {
  const renderDaysInMonth = useRenderDaysInMonth();
  const dayInWeek = useDayInWeek();
  const renderBeforeDays = useRenderBeforeDays();
  const daysOfWeeks = useDaysOfWeeks();
  return (obj: IDateObject): ICalendarDay[][] => {
    const amountBefore = daysOfWeeks.code.indexOf(dayInWeek(obj).firstDay);
    const beforeDays =
      obj.month === 0
        ? renderBeforeDays(11, amountBefore).map((d) => ({
            value: d,
            typeDay: TYPE_DAY.PREV
          }))
        : renderBeforeDays(obj.month - 1, amountBefore).map((d) => ({
            value: d,
            typeDay: TYPE_DAY.PREV
          }));
    const currentDays = renderDaysInMonth(obj.month, obj.year).map((d) => ({ value: d, typeDay: TYPE_DAY.CURRENT }));
    const nextDays = renderAfterDays(daysOfWeeks.code.indexOf(dayInWeek(obj).lastDay)).map((d) => ({
      value: d,
      typeDay: TYPE_DAY.NEXT
    }));
    const renderedDays = [...beforeDays, ...currentDays, ...nextDays];
    const weeks = [];
    for (let i = 0; i < renderedDays.length; i += 7) {
      weeks.push(renderedDays.slice(i, i + 7));
    }

    return weeks;
  };
};

export const areEqual = (a: IDateObject, b: IDateObject): boolean => {
  if (!a || !b) return false;
  return a.day === b.day && a.month === b.month && a.year === b.year;
};

export const useDateToString = () => {
  const { __ } = useLocales();
  const months = useMonths();
  return (date: string): string => {
    if (date === "today") return __("Сегодня");
    if (date === "yesterday") return __("Вчера");
    const arr = date.split("-").reverse();
    const day = arr[0];
    const month = months()[+arr[1] - 1].declensionName;
    const year = d.getFullYear() === +arr[2] ? "" : arr[2];
    return `${+day} ${month} ${year}`;
  };
};

export function formatDateStandard(date: Date) {
  if (typeCheck(date) === TYPES.DATE) {
    return format(date, "yyyy-MM-dd HH:mm:ss");
  } else {
    throw new Error("Type of the incoming parameter must be date");
  }
}

export function parseCalendarDateToDate(date: string): Date {
  if (/^\d{2}\.\d{2}\.\d{4}$/.test(date)) {
    const d = date.split(".");
    return new Date(`${d[2]}-${d[1]}-${d[0]}`);
  } else {
    throw new Error("Date does not correspond to format - dd.MM.yyyy");
  }
}

// {year, month, day} => DD.MM.YYYY
export const dateToString = (date: IDateObject): string => {
  if (date) {
    const d = { ...date, day: `0${date.day}`.slice(-2), month: `0${date.month + 1}`.slice(-2) };
    return `${d.day}.${d.month}.${d.year}`;
  }
  return "";
};

// YYYY-MM-DD => DD.MM.YYYY
export const dateToCalendarString = (value: string): string => {
  const str = value.slice(0, 10);
  return str.slice(0, 10).split("-").reverse().join(".");
};

export const createArrayOfHours = (beginFrom: Date, interval: number): string[] => {
  if (typeCheck(beginFrom) === TYPES.DATE) {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(format(addHours(beginFrom, i * interval), "HH:mm"));
    }
    return hours;
  } else {
    throw new Error("Type of the incoming parameter must be date");
  }
};

export const createArrayOfMinutes = (interval: number = 1): string[] => {
  const minutes: string[] = [];
  for (let i = 0; i < 60; i += interval) {
    minutes.push(`0${i}`.slice(-2));
  }
  return minutes;
};

export const msToDateObject = (value: number | string): IDateObject => {
  return {
    year: new Date(value).getFullYear(),
    month: new Date(value).getMonth(),
    day: new Date(value).getDate()
  };
};
