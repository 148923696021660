import { initialTasksState, ITasksState } from "models/store/tasks/tasks";
import { TasksActions } from "models/store/tasks/tasksActions";
import { TasksTypes } from "Store/types/tasksTypes";

const INITIAL_STATE: ITasksState = initialTasksState();

export const TasksReducer = (state = INITIAL_STATE, action: TasksActions): ITasksState => {
  switch (action.type) {
    case TasksTypes.FETCH_TASKS:
      return { ...state, myTasks: action.payload };

    case TasksTypes.ADD_TASK:
      return { ...state, myTasks: [action.payload, ...state.myTasks] };

    case TasksTypes.EDIT_TASK: {
      const newTasks = [...state.myTasks];
      const index = newTasks.findIndex((item) => item.id === action.payload.id);
      newTasks.splice(index, 1, action.payload);
      return { ...state, myTasks: newTasks };
    }

    case TasksTypes.DELETE_TASK:
      return { ...state, myTasks: state.myTasks.filter((item) => item.id !== action.payload) };

    case TasksTypes.SELECT_TASK:
      return { ...state, chosenTask: action.payload };

    case TasksTypes.ADD_TASK_COMMENT: {
      const newTasks = [...state.myTasks];
      newTasks.forEach((item) => (item.id === action.payload.id_task ? item.comments.push(action.payload) : null));
      return { ...state, myTasks: newTasks };
    }

    case TasksTypes.SELECT_DATE_FILTER:
      return { ...state, filters: { type: state.filters.type, date: action.payload } };

    case TasksTypes.SELECT_TYPE_FILTER:
      return { ...state, filters: { type: action.payload, date: state.filters.date } };

    default:
      return state;
  }
};
