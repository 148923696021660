import ContactItem from "containers/Profile/ContactsSidebar/ContactItem/ContactItem";
import Clue from "generalComponents/Clue/Clue";
import { useActions } from "hooks/useActions";
import { IContact } from "models/store/user/user";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useMemo } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./ContactsSidebar.module.sass";

interface IContactsSidebar {
  isFavorites: boolean;
  search: string;
  setActiveContact: Dispatch<SetStateAction<IContact>>;
  activeContact: IContact;
}

const ContactsSidebar = ({ isFavorites, search, setActiveContact, activeContact }: IContactsSidebar) => {
  const { contacts } = useUserSelectors();
  const { getContacts } = useActions();
  const { __ } = useLocales();

  useEffect(() => {
    getContacts();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!activeContact?.id) return;
    const foundContact = contacts.find((contact) => contact.id === activeContact.id);
    if (foundContact) {
      setActiveContact(foundContact);
    }
  }, [contacts]); // eslint-disable-line

  useEffect(() => {
    setActiveContact(contacts[0]);
  }, [contacts.length]); // eslint-disable-line

  const filteredContacts = useMemo(() => {
    const filtered = contacts.filter((contact: IContact) => {
      const nameMatches = contact.name.toLowerCase().includes(search.toLowerCase());
      const snameMatches = contact.sname.toLowerCase().includes(search.toLowerCase());
      const telMatches = contact.tel.some((tel) => tel.toLowerCase().includes(search.toLowerCase()));
      const emailMatches = contact.email.some((email) => email.toLowerCase().includes(search.toLowerCase()));
      return nameMatches || snameMatches || telMatches || emailMatches;
    });

    return filtered;
  }, [contacts, search]);

  const groupContactsByFirstLetter = (contacts: IContact[]) => {
    const groupedContacts: { [letter: string]: IContact[] } = {};
    for (const contact of contacts) {
      if (isFavorites && !contact.is_fav) {
        continue;
      }
      const firstLetter = contact.name.charAt(0).toUpperCase();
      if (!groupedContacts[firstLetter]) {
        groupedContacts[firstLetter] = [];
      }
      groupedContacts[firstLetter].push(contact);
    }
    return groupedContacts;
  };

  const groupedContacts = useMemo(() => groupContactsByFirstLetter(filteredContacts), [filteredContacts, isFavorites]); // eslint-disable-line

  return (
    <div className={styles.container}>
      {!Object.keys(groupedContacts)?.length && (
        <Clue icon="search" text={__(`Нет результатов для <strong>"${search}"</strong>`)} />
      )}
      {Object.keys(groupedContacts).map((letter) => (
        <div key={letter}>
          <span className={styles.title}>{letter}</span>
          <ul>
            {groupedContacts[letter].map((contact) => (
              <ContactItem
                data={contact}
                key={contact.id}
                activeContact={activeContact}
                setActiveContact={setActiveContact}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ContactsSidebar;
