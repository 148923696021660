import Button from "generalComponents/Button/Button";
import PopUp from "generalComponents/PopUp/PopUp";
import { createMarkup } from "generalComponents/Services/browserServices";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialApproveModalState } from "models/store/Cabinet/modals/modals";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { v4 as uuid } from "uuid";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./ApproveModal.module.sass";

const ApproveModal = (): JSX.Element => {
  const { __ } = useLocales();
  const { onSetApproveModal } = useActions();
  const {
    approveModal: { params }
  } = useGlobalModalsSelectors();

  const closeModal = (): void => {
    if (params.closeCallback) {
      params.closeCallback();
    }
    onSetApproveModal(initialApproveModalState());
  };

  const onlyCloseModal = (): void => {
    if (params?.onlyCloseCallback) {
      params.onlyCloseCallback();
    }

    closeModal();
  };

  const onUpdate = (): void => {
    params.updateCallback();
    onSetApproveModal(initialApproveModalState());
  };

  const onSubmit = (): void => {
    params.callback();
    closeModal();
  };

  return (
    <PopUp set={onlyCloseModal}>
      <HeaderModal onClose={onlyCloseModal} title={params?.titleHead} />
      <div className={styles.wrap}>
        <div className={styles.contentWrap}>
          <div className={styles.topWrap}>
            {params?.title && <h2 dangerouslySetInnerHTML={createMarkup(params?.title)} className={styles.title} />}
            {params?.text && <p className={styles.text}>{params?.text}</p>}
            {/* <div dangerouslySetInnerHTML={createMarkup(params?.text)} /> */}
            {!!params?.list && (
              <ul className={styles.list}>
                {params.list.map((item) => (
                  <li key={uuid()} className={styles.list__item}>
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className={styles.buttonsWrap}>
            <Button
              type="button"
              variant={ButtonVariantType.LIGHT}
              size={ButtonSizeType.SMALL}
              text={params.closeBtn || __("Отменить")}
              onClick={onlyCloseModal}
            />
            {params?.updateCallback && (
              <Button
                type="button"
                variant={ButtonVariantType.LIGHT}
                size={ButtonSizeType.SMALL}
                text={params?.updateBtn || __("Обновить")}
                onClick={onUpdate}
              />
            )}
            <Button
              type="button"
              variant={params?.approveBtnVariant ?? ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={params.approveBtn}
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default ApproveModal;
