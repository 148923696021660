import { useProjectMsg } from "generalComponents/Services/projectServices";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { IPressedButton } from "models/generalComponents/customEditorToolbar";
import { PROJECT_DOCS_VIEW } from "models/project/projectDocs";
import { IDocsProject } from "models/store/projects/projectStore";
import React, { useEffect, useState } from "react";

import ServicePanel from "./ServicePanel/ServicePanel";
import TabsPanel from "./TabsPanel/TabsPanel";
import CreateDoc from "./WorkSpace/CreateDoc";
import CreateTemplate from "./WorkSpace/CreateTemplate";
import Docs from "./WorkSpace/Docs";
import Template from "./WorkSpace/Template";

const ProjectDoc = () => {
  const { onFetchDocs } = useActions();
  const MSG = useProjectMsg();

  const [viewType, setViewType] = useState<PROJECT_DOCS_VIEW>(PROJECT_DOCS_VIEW.DOCS);
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isPrint, setIsPrint] = useState<boolean>(false);

  const [pressedButton, setPressedButton] = useState<IPressedButton>({ change: true, type: NO_VALUE });
  const [pushoutButton, setPushoutButton] = useState<IPressedButton>({ change: true, type: NO_VALUE });
  const [currentDocs, setCurrentDocs] = useState<IDocsProject[]>([]);
  const [currentEditor, setCurrentEditor] = useState<string>(NO_VALUE);

  useEffect(() => {
    onFetchDocs(MSG.ERROR);
  }, []); // eslint-disable-line

  useEffect(() => {
    clearCurrentDocs();
  }, [viewType]); // eslint-disable-line

  const toolbarClick = (name: string): void => setPressedButton((prev) => ({ change: !prev.change, type: name }));

  const changeToolbar = (name: string) => {
    setPushoutButton((prev) => ({ change: !prev.change, type: name }));
  };

  const selectDoc = (doc: IDocsProject): void => {
    if (isSelect) {
      setCurrentDocs((prev) => {
        const isInCurrent = prev.some((item) => item.id === doc.id);
        return isInCurrent ? prev.filter((item) => item.id !== doc.id) : [...prev, doc];
      });
    } else {
      setCurrentDocs([doc]);
    }
  };

  const handleSelect = (): void => {
    setIsSelect((prev) => !prev);
    clearCurrentDocs();
    setIsEdit(false);
  };

  const handleEdit = (): void => setIsEdit((prev) => !prev);

  const clearCurrentDocs = (): void => {
    setIsEdit(false);
    setCurrentDocs([]);
  };

  const onPrint = (): void => setIsPrint((prev) => !prev);

  return (
    <>
      <TabsPanel viewType={viewType} setTypes={setViewType} />
      <ServicePanel
        toolbarClick={toolbarClick}
        pushoutButton={pushoutButton}
        viewType={viewType}
        currentDocs={currentDocs}
        clearCurrentDocs={clearCurrentDocs}
        handleSelect={handleSelect}
        isSelect={isSelect}
        handleEdit={handleEdit}
        isEdit={isEdit}
        currentEditor={currentEditor}
        onPrint={onPrint}
      />
      {viewType === PROJECT_DOCS_VIEW.DOCS && (
        <Docs
          currentDocs={currentDocs}
          selectDoc={selectDoc}
          isEdit={isEdit}
          pressedButton={pressedButton}
          changeToolbar={changeToolbar}
          handleEdit={handleEdit}
          currentEditor={currentEditor}
          setCurrentEditor={setCurrentEditor}
          setCurrentDocs={setCurrentDocs}
          isPrint={isPrint}
          setIsPrint={setIsPrint}
        />
      )}
      {viewType === PROJECT_DOCS_VIEW.TEMPLATE && (
        <Template
          currentDocs={currentDocs}
          selectDoc={selectDoc}
          isEdit={isEdit}
          pressedButton={pressedButton}
          changeToolbar={changeToolbar}
          handleEdit={handleEdit}
          setTypes={setViewType}
          currentEditor={currentEditor}
          setCurrentEditor={setCurrentEditor}
          isPrint={isPrint}
          setIsPrint={setIsPrint}
        />
      )}
      {viewType === PROJECT_DOCS_VIEW.CREATE_DOC && (
        <CreateDoc pressedButton={pressedButton} changeToolbar={changeToolbar} setTypes={setViewType} />
      )}
      {viewType === PROJECT_DOCS_VIEW.CREATE_TEMPLATE && <CreateTemplate setTypes={setViewType} />}
    </>
  );
};

export default ProjectDoc;
