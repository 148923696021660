import api from "api";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useActions } from "hooks/useActions";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IAccessInfo } from "models/store/folders/foldersPayloads";
import { IFolder } from "models/store/folders/foldersStore";
import { useUserSelectors } from "Store/selectors/userSelectors";

export const useGetFolderInfo = () => {
  const { onSetTopMessageModal } = useActions();
  const { uid } = useUserSelectors();

  const onGetFolderInfo = async (
    payload: { id_dir?: string; did?: string },
    setData: (el: IFolder) => void,
    setLoading?: (el: boolean) => void
  ) => {
    const params = {
      uid: payload.did ? undefined : uid,
      id_dir: payload.id_dir,
      did: payload.did
    };
    try {
      setLoading(true);
      const { data } = await api.get<IFolder>("/ajax/dir_info.php", { params });
      setData(data);
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    } finally {
      setLoading(false);
    }
  };

  return {
    onGetFolderInfo
  };
};

export const useGetFolderAccessInfo = () => {
  const { onSetTopMessageModal } = useActions();
  const { uid } = useUserSelectors();

  const onGetFolderAccessInfo = async (
    id_dir: string,
    setData: (el: IAccessInfo[]) => void,
    setLoading: (el: boolean) => void
  ) => {
    const params = { uid, id_dir };

    try {
      setLoading(true);
      const { data } = await api.get("/ajax/dir_access_info.php", { params });
      checkResponseStatus(data.ok);
      setData(data.access);
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    } finally {
      setLoading(false);
    }
  };

  return {
    onGetFolderAccessInfo
  };
};
