import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./TabsPicker.module.sass";

function TabsPicker({ ELEMENTS, onClick, selected }) {
  const getImage = (Element) => <Element className={styles.icon} />;

  return (
    <div className={styles.wrap}>
      {ELEMENTS.map((el) => (
        <button
          key={el.id}
          onClick={() => onClick(el.id)}
          className={classNames(styles.box, {
            [styles.iconViewChosen]: selected === el.id
          })}
          type="button"
        >
          {getImage(el.img)}
        </button>
      ))}
    </div>
  );
}

export default TabsPicker;

TabsPicker.defaultProps = {
  ELEMENTS: [],
  selected: -1,
  onClick: () => {}
};

TabsPicker.propTypes = {
  ELEMENTS: PropTypes.arrayOf(PropTypes.exact({ id: PropTypes.string, img: PropTypes.elementType })).isRequired,
  selected: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
