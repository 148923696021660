import { useActions } from "hooks/useActions";
import { useEffect } from "react";
import { ReadyState } from "react-use-websocket";

import {
  IAddPeerSocketMessage,
  IIceCandidateSocketMessage,
  IRemovePeerSocketMessage,
  IRequestMediaStreamSocketMessage,
  IScreenShareSocketMessage,
  ISessionDescriptionSocketMessage,
  IUseWebRTCSocketMessages,
  ReceivedSocketMessages
} from "../../../models/generalComponents/hooks/WebRTC/useWebRTCSocketMessages";
import { initialCallRoomState } from "../../../models/store/Cabinet/chat/chat";
import { useChatSelectors } from "../../../Store/selectors/chatSelectors";
import { useUserSelectors } from "../../../Store/selectors/userSelectors";
import { CHAT_CALLROOM_ACTIONS } from "../../variables/chat";
import { useWebSocketContext } from "../../WebSocketsProvider/WebSocketsProvider";
import { useWebRTCCheckServices } from "./useWebRTCCheckServices";
import { useWebRTCSetConnectionServices } from "./useWebRTCSetConnectionServices";
import { useWebRTCContext } from "./WebRTCProvider";

// Хук, который принимает все входящие сокет сообщения от сервера, относительно голосовой комнаты
export function useWebRTCSocketMessages(): IUseWebRTCSocketMessages {
  const { socket: _, lastMessage, readyState } = useWebSocketContext();
  const { checkCameraPermissions } = useWebRTCCheckServices();
  const { userInfo } = useUserSelectors();
  const { callRoom } = useChatSelectors();
  const {
    handleNewPeer,
    setRemoteMedia,
    handleRemovePeer,
    openPaintRoom,
    reconnectUsers,
    reconnectPeer,
    reloadMediaStream
  } = useWebRTCSetConnectionServices();
  const {
    peerConnections,
    localMediaStream,
    sendSocket,
    peerScreenElements,
    screenStreams,
    setHasCameraPermission,
    remoteStreams,
    fixedReconnectionUsers,
    setFixedReconnectionUsers,
    setIsLoadingUsers
  } = useWebRTCContext();
  const { setCallRoom, setCallRoomChangeConnectedUsers, changeCallRoomConnectedUsers, setCallRoomConnectedUsersHand } =
    useActions();

  useEffect(() => {
    async function handleMessage(message: ReceivedSocketMessages) {
      switch (message.action) {
        // Используется в Chat/WorkSpace/index.js - где добавляется информация в стор о входящем звонке. В этом месте сообщение игнорируется
        // Возможно использовать проверки при этом хуке на права доступа к медиаустройствам
        case CHAT_CALLROOM_ACTIONS.INCOMING_CALL: {
          checkCameraPermissions().then(() => {
            setHasCameraPermission(true);
          });
          break; //used in WorkSpace component
        }
        // Добавление нового клиента для обмена sessionDescription и perCandidates
        case CHAT_CALLROOM_ACTIONS.ADD_PEER: {
          const msg = message as IAddPeerSocketMessage;
          if (msg.createOffer) {
            handleNewPeer({ peerID: msg.from.id_user, createOffer: msg.createOffer });
          } else {
            msg.connected_users
              .filter((it) => it !== userInfo.id)
              .forEach((peerID) => handleNewPeer({ peerID, createOffer: msg.createOffer }));
          }
          if (window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL]) {
            clearTimeout(window[CHAT_CALLROOM_ACTIONS.OUTGOING_CALL]);
          }
          break;
        }
        // обмен sessionDescription
        case CHAT_CALLROOM_ACTIONS.SESSION_DESCRIPTION: {
          const msg = message as ISessionDescriptionSocketMessage;
          await setRemoteMedia({
            peerID: msg.from.id_user,
            sessionDescription: msg.sessionDescription,
            peer: msg.from,
            initialConnection: true
          });
          remoteStreams.current[msg.from.id_user] = null;
          break;
        }
        // обмен ice candidates
        case CHAT_CALLROOM_ACTIONS.ICE_CANDIDATE: {
          const msg = message as IIceCandidateSocketMessage;
          await peerConnections.current[msg.from.id_user]?.addIceCandidate(new RTCIceCandidate(msg.iceCandidate));
          break;
        }
        // Используется для первого рендера входящего звонка в компоненте Chat/WorkSpace/index.js
        case CHAT_CALLROOM_ACTIONS.ASK_TO_CONNECT: {
          break; //used in WorkSpace component
        }
        // Удаление клиента или полный разрыв соединения, если в комнате осталось два пользователя
        case CHAT_CALLROOM_ACTIONS.REMOVE_PEER: {
          const msg = message as IRemovePeerSocketMessage;

          handleRemovePeer({ peerID: msg?.from?.id_user });
          Object.keys(peerConnections.current).forEach((key) => reloadMediaStream(key));
          if (msg?.from?.id_user === userInfo.id) {
            setCallRoom(initialCallRoomState());
          }
          if (msg?.connected_users?.length === 1) {
            sendSocket({ action: CHAT_CALLROOM_ACTIONS.CLOSE_ROOM });
            localMediaStream.current.getTracks().forEach((track) => track.stop());
            setCallRoom(initialCallRoomState());
            handleRemovePeer({ peerID: userInfo.id });
          }
          if (Object.keys(peerConnections.current).length === 0 && callRoom.room_id === msg?.room_id) {
            setCallRoom(initialCallRoomState());
          }
          break;
        }
        // Полное завершение вызова
        case CHAT_CALLROOM_ACTIONS.STOP_CALL: {
          if (callRoom.state === CHAT_CALLROOM_ACTIONS.INCOMING_CALL && callRoom.room_id === message.room_id) {
            localMediaStream.current.getTracks().forEach((track) => track.stop());
            setCallRoom(initialCallRoomState());
          }
          break;
        }
        // включение/выключение аудио/видео
        case CHAT_CALLROOM_ACTIONS.SWITCH_VIDEO: {
          setCallRoomChangeConnectedUsers(message.from);
          break;
        }
        // Расшаривание экрана
        case CHAT_CALLROOM_ACTIONS.SCREEN_SHARE: {
          const msg = message as IScreenShareSocketMessage;
          //check setRemoteDescription error
          await setRemoteMedia({
            peerID: msg.from.id_user,
            sessionDescription: msg.sessionDescription,
            peer: msg.from,
            initialConnection: false
          });
          if (!msg.from.media.screen && userInfo.id !== msg.from.id_user) {
            peerScreenElements.current[msg.from.id_user].srcObject = null;
            delete screenStreams.current[msg.from.id_user];
          }
          changeCallRoomConnectedUsers(msg.from);
          break;
        }
        // Проверка подключение пользователя к комнате (данные о комнате серверу НЕ известны)
        case CHAT_CALLROOM_ACTIONS.CALLROOM_CHECK_CONNECTION: {
          sendSocket({ action: CHAT_CALLROOM_ACTIONS.CALLROOM_CHECK_CONNECTION });
          // checkConnectionStatus(message);
          console.log("peerConnections", peerConnections.current);
          console.log("remoteStreams", remoteStreams.current);
          Object.entries(remoteStreams.current).forEach(
            ([id, stream]) => stream && stream.getTracks().forEach((track) => console.log(id, track))
          );
          break;
        }
        // Проверка подключение пользователя к комнате (данные о комнате серверу известны)
        case CHAT_CALLROOM_ACTIONS.CALLROOM_CHECK_CONNECTION_REPLY: {
          setFixedReconnectionUsers((s) => ({ ...s, checkCounts: s.checkCounts + 1 }));

          if (!fixedReconnectionUsers.isFixed && fixedReconnectionUsers.checkCounts === 1) {
            // Тестовое переподключения воизбежании ошибок передачи медиа данных
            message.connected_users
              .filter((it) => it !== userInfo.id)
              .forEach((peerID, i, arr) => {
                setTimeout(() => {
                  reconnectPeer(peerID);
                  if (i === arr.length - 1) {
                    setIsLoadingUsers(false);
                  }
                }, 200 * i);
              });
          }
          break;
        }
        // Поднять/опустить руку
        case CHAT_CALLROOM_ACTIONS.RISE_HAND: {
          setCallRoomConnectedUsersHand(message);
          break;
        }
        // Открытие комнаты рисования
        case CHAT_CALLROOM_ACTIONS.OPEN_PAINT_ROOM: {
          openPaintRoom();
          break;
        }
        // Запрос на список пользователей, подключенный к комнате
        case CHAT_CALLROOM_ACTIONS.CHECK_CONNECTED_USERS: {
          const users = message.connected_users.filter((it) => it !== userInfo.id);
          reconnectUsers(users);
          break;
        }
        // RECONNECT_USER - unavailable on server
        case CHAT_CALLROOM_ACTIONS.RECONNECT_USER: {
          handleRemovePeer({ peerID: message.from.id_user });
          handleNewPeer({ peerID: message.from.id_user, createOffer: true });
          break;
        }
        case CHAT_CALLROOM_ACTIONS.REQUEST_MEDIA_STREAM: {
          // TODO - reconnect needs to be switched to RECONNECT_USER action
          const msg = message as IRequestMediaStreamSocketMessage;
          if (msg.isReconnect) {
            handleRemovePeer({ peerID: message.from.id_user });
            setTimeout(() => {
              handleNewPeer({ peerID: message.from.id_user, createOffer: true });
            }, 100);
          }
          // else {
          // localMediaStream.current.getTracks().forEach((track) => {
          //   peerConnections.current[msg.from.id_user].addTrack(track, localMediaStream.current);
          // });
          // }

          break;
        }
        default: {
          break;
        }
      }
    }

    if (lastMessage && lastMessage.data && readyState === ReadyState.OPEN) {
      const message: ReceivedSocketMessages = JSON.parse(lastMessage.data);
      handleMessage(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  return {};
}
