import Button from "containers/Authentication/Button/Button";
import { useLocales } from "react-localized";

import styles from "./FirstStep.module.sass";

const FirstStep = ({
  isSolo,
  setIsSolo,
  handleNextStep
}: {
  isSolo: boolean | undefined;
  setIsSolo: React.Dispatch<React.SetStateAction<boolean>>;
  handleNextStep: () => void;
}): JSX.Element => {
  const { __ } = useLocales();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4>{__("Добро пожаловать в 4HUB")}</h4>
        <p>{__("Выберите свой рабочий план. Вы можете изменить свой выбор в любое время.")}</p>
        <Button isActive={isSolo} className={styles.button} onClick={() => setIsSolo(true)}>
          <img src="assets/Authentication/Slider/solo.png" alt="solo" />
          {__("Для индивидуальных работников")}
        </Button>
        <Button isActive={isSolo === false} className={styles.button} onClick={() => setIsSolo(false)}>
          <img src="assets/Authentication/Slider/team.png" alt="team" />
          {__("Для бизнеса и команд")}
        </Button>
      </div>
      <Button
        disabled={isSolo === undefined}
        className={styles.confirm_button}
        onClick={handleNextStep}
        animationClassName={styles.animation}
      >
        {__("Продолжить")}
      </Button>
    </div>
  );
};

export default FirstStep;
