import { ReactComponent as SearchIcon } from "assets/icons/search2.svg";
import { ReactComponent as LogoIcon } from "assets/PrivateCabinet/sideMenu/logo.svg";
import { ReactComponent as LogoTextIcon } from "assets/PrivateCabinet/sideMenu/logo-text.svg";
import classNames from "classnames";
import { IFaqCategory, IFaqItem, useFaq } from "collections/faq";
import Input from "generalComponents/Input/Input";
import { ROUTES } from "generalComponents/variables/routing";
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useLocales } from "react-localized";
import { Link, useLocation } from "react-router-dom";

import styles from "./FaqHeader.module.sass";

const FaqHeader = () => {
  const { __ } = useLocales();
  const [search, setSearch] = useState("");
  const [filteredFaq, setFilteredFaq] = useState<Array<IFaqItem | IFaqCategory>>([]);
  const [dataArray, setDataArray] = useState<Array<IFaqItem | IFaqCategory>>([]);
  const faqData = useFaq();

  const location = useLocation();
  const isFaqDetailsPage = location.pathname.includes("faqdetails");

  useEffect(() => {
    const flattenArray = (arr: Array<IFaqItem | IFaqCategory>, parentRoute = "") => {
      let result: Array<IFaqItem | IFaqCategory> = [];
      // Вспомогательная рекурсивная функция flatten обрабатывает каждый элемент массива.
      // Присваивает полный маршрут элементу и добавляет его в результат.
      // Рекурсивно обрабатывает вложенные элементы, удаляя свойство content после обработки.
      const flatten = (obj: IFaqItem | IFaqCategory, parentRoute: string) => {
        let fullRoute = parentRoute ? `${parentRoute}/${obj.route}` : obj.route;
        obj.route = fullRoute;
        result.push(obj);
        // Если у объекта есть свойство content и это массив, обрабатываем его дочерние элементы.
        if (obj.content && Array.isArray(obj.content)) {
          obj.content.forEach((childObj) => flatten(childObj, fullRoute));
          delete obj.content; // Удаляем свойство content после обработки, чтобы избежать дублирования.
        }
      };
      // Обходим каждый элемент в исходном массиве и вызываем функцию flatten для каждого из них.
      arr.forEach((obj) => flatten(obj, parentRoute));
      return result; // Возвращаем плоский массив.
    };
    // Преобразование исходного faqData в плоский массив с помощью функции flattenArray.
    const flattenedArray = flattenArray(faqData);
    // Если новый плоский массив не пустой и отличается от текущего dataArray, обновляем dataArray.
    if (flattenedArray.length > 0 && flattenedArray.length !== dataArray.length) {
      setDataArray(flattenedArray);
    }
  }, [faqData]); //eslint-disable-line

  useEffect(() => {
    const searchResult = dataArray.filter((item) => {
      const itemName = item.title.toLowerCase();
      let itemContent = null;
      if (typeof item.content === "function") {
        // Вызываем функцию для получения контента
        let content = item.content();
        // Проверяем, является ли контент React-элементом
        if (React.isValidElement(content)) {
          // Рендерим React-элемент в строку
          content = ReactDOMServer.renderToStaticMarkup(content);
          // Удаляем HTML-теги из строки контента
          itemContent = stripHtmlTags(content as string);
        } else if (typeof content === "string") {
          // Если контент уже является строкой, используем его
          itemContent = content;
        }
      }
      // Проверяем тип search и itemContent перед использованием
      return (
        itemName.includes(search.toLowerCase()) ||
        (itemContent && typeof itemContent === "string" && itemContent.includes(search.toLowerCase()))
      );
    });
    setFilteredFaq(searchResult);
  }, [search, dataArray, setFilteredFaq]);

  const stripHtmlTags = (html: string): string => {
    return html.replace(/<[^>]*>/g, " ").replace(/\n/g, " ");
  };

  const highlightSearchText = (
    content: React.ReactNode | string | (() => React.ReactNode),
    search: string,
    maxLength = 20
  ): string => {
    // Если контент - функция, вызываем её, чтобы получить React-элемент или строку.
    if (typeof content === "function") {
      content = content();
    }

    // Если контент является React-элементом, преобразуем его в статичный HTML-код.
    if (React.isValidElement(content)) {
      content = ReactDOMServer.renderToStaticMarkup(content);
    }

    // Если контент не является строкой, возвращаем пустую строку.
    if (typeof content !== "string") {
      return "";
    }

    // Удаляем HTML-теги из текста контента.
    const cleanText = stripHtmlTags(content);

    // Ищем индекс первого вхождения искомого текста (без учёта регистра).
    const searchIndex = cleanText.toLowerCase().indexOf(search.toLowerCase());

    // Если искомый текст найден:
    if (searchIndex !== -1) {
      // Определяем начальный и конечный индексы для выделенного фрагмента текста.
      const start = Math.max(0, searchIndex - maxLength / 2);
      const end = Math.min(cleanText.length, start + maxLength);

      // Выделяем и подсвечиваем искомый текст внутри фрагмента.
      const highlightedText = cleanText
        .substring(start, end)
        .replace(
          new RegExp(search, "gi"),
          (match) => `<span style="background-color:#1565C0; color: white;">${match}</span>`
        );

      // Возвращаем выделенный фрагмент с добавлением многоточия в начале и конце, если текст обрезан.
      return start > 0 ? `...${highlightedText}...` : highlightedText + "...";
    } else {
      // Если искомый текст не найден, возвращаем часть текста до maxLength с добавлением многоточия, если текст обрезан.
      return cleanText.length > maxLength ? `${cleanText.substring(0, maxLength)}...` : cleanText;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <div className={classNames(styles.container, isFaqDetailsPage && styles.details)}>
      <div className={styles.content}>
        <Link to="/" className={styles.logo}>
          <LogoIcon />
          <LogoTextIcon />
          <span className={styles.title}>FAQ</span>
        </Link>
        <div className={styles.changeLang}>RU</div>
      </div>
      <div className={styles.search}>
        {!isFaqDetailsPage && <h2>{__("Что ищите?")}</h2>}
        <div className={styles.input}>
          <Input value={search} onChange={handleInputChange} placeholder={__("Введите")} rightIcon={<SearchIcon />} />
          {search.length > 0 && (
            <div className={styles.searchResult}>
              {filteredFaq.length > 0 ? (
                filteredFaq.map((item, i) => (
                  <Link key={i} to={`/${ROUTES.FAQDETAILS}/${item.route}`} onClick={() => setSearch("")}>
                    <strong>{item.title}</strong>
                    {!Array.isArray(item.content) && (
                      <span dangerouslySetInnerHTML={{ __html: highlightSearchText(item.content, search) }} />
                    )}
                  </Link>
                ))
              ) : (
                <div className={styles.empty}>{__("Ничего не найдено")}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqHeader;
