import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import Button from "generalComponents/Button/Button";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IContact } from "models/store/user/user";

import styles from "./SocialBlock.module.sass";

interface ISocialBlock {
  title: string;
  data: IContact["soc"] | IContact["mes"];
  icons: Record<string, JSX.Element>;
  emptyIcons: Record<string, JSX.Element>;
  updateData: (updateData: IContact["mes"] | IContact["soc"]) => void;
}

const SocialBlock = ({ title, data, icons, emptyIcons, updateData }: ISocialBlock) => {
  const { onSetSocialModal } = useActions();
  const presentTypes = new Set(
    data
      .map((item) => {
        if (item.link) {
          return item.type;
        }
        return null;
      })
      .filter((type) => type !== null)
  );

  const openSocialModal = () => {
    onSetSocialModal({ open: true, data, title, icons, updateData });
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.title}>{title}</div>
        <ul className={styles.list}>
          {Array.from(presentTypes).map((type, index) => (
            <li key={index} className={styles.item}>
              {icons[type]}
            </li>
          ))}
          {Object.keys(emptyIcons).map(
            (type, index) =>
              !presentTypes.has(type) && (
                <li key={index} className={styles.item}>
                  {emptyIcons[type]}
                </li>
              )
          )}
        </ul>
      </div>
      <Button
        iconL={<EditIcon />}
        variant={ButtonVariantType.EXRTA_LIGHT}
        size={ButtonSizeType.SMALL}
        isSquare
        onClick={openSocialModal}
      />
    </div>
  );
};

export default SocialBlock;
