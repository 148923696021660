import { CustomMediaStreamTrack } from "models/generalComponents/hooks/WebRTC/useWebRTCHelpers";
import { IGenericWebRTCSocketSendMessage } from "models/generalComponents/hooks/WebRTC/useWebRTCSocketMessages";
import { useCallback } from "react";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { LOCAL_CLIENT } from "../../variables/chat";
import { useWebRTCContext } from "./WebRTCProvider";

export function useWebRTCHelpers() {
  const { userInfo } = useUserSelectors();

  const {
    peerConnections,
    localMediaStream,
    localScreenStream,
    peerScreenElements,
    minimizedMediaElement,
    screenStreams,
    media
  } = useWebRTCContext();

  // включение/выключение экрана пользователя
  async function switchScreen(): Promise<void> {
    if (media.screen) {
      // отключение видеоэлемента в случае прекращение расшаривания экрана
      Object.values(peerConnections.current).forEach((pc) => {
        pc.getSenders().forEach((sender) => {
          if ((sender?.track as CustomMediaStreamTrack).info === "SCREEN") {
            pc.removeTrack(sender);
          }
        });
      });
      localScreenStream.current.getTracks().forEach((track) => track.stop());
      peerScreenElements.current[LOCAL_CLIENT].srcObject = null;
      delete screenStreams.current[LOCAL_CLIENT];
      minimizedMediaElement.current.srcObject = localMediaStream.current;
      localScreenStream.current = null;
    } else {
      // расашривание экрана
      localScreenStream.current = await navigator.mediaDevices.getDisplayMedia({
        video: { width: 1920, height: 1080 }
      });

      localScreenStream.current.getTracks().forEach((track) => {
        (track as CustomMediaStreamTrack).info = "SCREEN";
        Object.values(peerConnections.current).forEach((pc) => pc.addTrack(track, localScreenStream.current));
      });

      const localScreenElement = peerScreenElements.current[LOCAL_CLIENT];
      if (localScreenElement) {
        localScreenElement.srcObject = localScreenStream.current;
        minimizedMediaElement.current.srcObject = localScreenStream.current;
      }

      // отключение видеоэлемента в случае прекращение расшаривания экрана при нажатии на системную копку
      localScreenStream.current.getVideoTracks()[0].onended = () => {
        Object.values(peerConnections.current).forEach((pc) => {
          pc.getSenders().forEach((sender) => {
            if ((sender?.track as CustomMediaStreamTrack).info === "SCREEN") {
              pc.removeTrack(sender);
            }
          });
        });
        localScreenStream.current.getTracks().forEach((track) => track.stop());
        peerScreenElements.current[LOCAL_CLIENT].srcObject = null;
        delete screenStreams.current[LOCAL_CLIENT];
        minimizedMediaElement.current.srcObject = localMediaStream.current;
        localScreenStream.current = null;
      };
    }
  }

  // Включение/выключение аудио/выидео
  const switchMedia = useCallback(
    (user: IGenericWebRTCSocketSendMessage["from"]) => {
      if (user.id_user === userInfo.id) {
        const content = Object.entries(user.media);
        localMediaStream.current.getTracks().forEach((track) => {
          content.forEach((it) => (it[0] === track.kind ? (track.enabled = it[1]) : track));
        });
      }
    },
    [userInfo.id, localMediaStream]
  );

  return {
    switchMedia,
    switchScreen
  };
}
