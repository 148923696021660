import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fileChatProps } from "types/File";

import { CHAT_CALLROOM, CHAT_CALLROOM_ACTIONS } from "../../../../../generalComponents/variables/chat";
import { useWebSocketContext } from "../../../../../generalComponents/WebSocketsProvider/WebSocketsProvider";
import {
  addNewChatMessage,
  onDeleteChatMessage,
  onEditChatMessage,
  onReadMessages,
  onSetSelectedContact,
  setCallRoom
} from "../../../../../Store/actions/ChatActions";
import { onFetchProjectsList } from "../../../../../Store/actions/ProjectActions";
import { actionProps } from "../../../../../types/Action";
import ChatBoard from "../ChatBoard";
import { constructChatMessage } from "../chatService";
import CreateCameraMedia from "../CreateCameraMedia";
import CreateChat from "../CreateChat";
import styles from "./WorkSpace.module.sass";

const WorkSpace = ({
  sideMenuCollapsed,
  boardOption,
  setShowSuccessPopup,
  nullifyAction,
  action,
  currentDate,
  setAction,
  setMouseParams,
  file,
  setFile,
  showSettings
}) => {
  const { uid } = useSelector((state) => state.user);
  const userId = useSelector((state) => state.Cabinet.chat.userId);
  const callRoom = useSelector((state) => state.Cabinet.chat.callRoom);
  const id_company = useSelector((state) => state.user.id_company);
  const endMessagesRef = useRef();
  const dispatch = useDispatch();
  const chatTheme = useSelector((state) => state.Cabinet.chat.theme);
  const [attachedFiles, setAttachedFiles] = useState(null);
  const selectedContact = useSelector((state) => state.Cabinet.chat.selectedContact);
  const messageLifeTime = useSelector((state) => state.Cabinet.chat.messageLifeTime);
  const { socket, lastMessage } = useWebSocketContext();

  const scrollToBottom = () => {
    endMessagesRef?.current?.scrollIntoView();
  };

  const onWebSocketsMessage = (lastMessage) => {
    const data = JSON.parse(lastMessage.data);

    const isForGroups = data.is_group && selectedContact?.isGroup;
    const isForSecretChat = data.is_secret_chat && selectedContact?.is_secret_chat;
    const isForChats = !data.is_group && !selectedContact?.isGroup;
    const isForSelectedGroup = isForGroups && data.id_group === selectedContact?.id;
    const isForSelectedChat = isForChats && (data.id_contact === selectedContact?.id || data.id_user_to === userId);
    const isForSelectedSecretChat = isForSecretChat && data.id_group === selectedContact?.id;

    // PrivateMessage - direct message; PublicMessage- message from group
    if (data.action === "PrivateMessage" || data.action === "PublicMessage") {
      const newMsg = constructChatMessage(data);

      // Renew project list to show created task in chat
      if (newMsg.type && newMsg.type.startsWith("task")) {
        dispatch(onFetchProjectsList(""));
        if (newMsg.type.startsWith("task_edit")) {
          return;
        }
      }
      if (isForGroups) {
        dispatch({
          type: "NEW_LAST_GROUP_MESSAGE",
          payload: { id_group: data.id_group, text: data.text }
        });
      }
      if (isForSelectedGroup || isForSelectedChat || isForSelectedSecretChat) {
        dispatch(addNewChatMessage(newMsg));
      } else {
        console.log("new message from dont selectedContact");

        if (data.id_group && !isForSelectedGroup) {
          dispatch({
            type: "INCREASE_NOTIFICATION_COUNTER",
            payload: `group_${data.id_group}`
          });
        }
        if (!data.id_group && !isForSelectedChat) {
          dispatch({
            type: "INCREASE_NOTIFICATION_COUNTER",
            payload: `chat_${data.api.id_user}`
          });
        }
      }
    }
    if (
      (data.action === "chat_group_message_edit" || data.action === "chat_message_edit") &&
      (isForSelectedGroup || isForSelectedSecretChat || isForSelectedChat)
    ) {
      dispatch(
        onEditChatMessage({ attachment: data.attachment, text: data.text }, { id: data.id_message, day: data.day })
      );
    }
    if (
      (data.action === "chat_group_message_del" || data.action === "chat_message_del") &&
      (isForSelectedChat || isForSelectedGroup || isForSelectedSecretChat)
    ) {
      dispatch(onDeleteChatMessage({ id: data.id_message, day: data.day }));
    }
    if (data.action === "chat_message_update") {
      dispatch(onReadMessages(data.id_messages));
    }
    if (data.action === "chat_message_del_all") {
      dispatch(onSetSelectedContact(selectedContact));
    }

    if (data.action === CHAT_CALLROOM_ACTIONS.INCOMING_CALL && callRoom.state === CHAT_CALLROOM.NO_CALL) {
      dispatch(
        setCallRoom({
          ...callRoom,
          state: CHAT_CALLROOM_ACTIONS.INCOMING_CALL,
          id_group: data.id_group,
          callType: data.media.video ? CHAT_CALLROOM.VIDEO_CALL : CHAT_CALLROOM.VOICE_CALL,
          contacts: [...data.users_to.filter((it) => it !== userId), data.from.id_user],
          user_id: data.from.id_user,
          icon: data.from.icon,
          room_id: data.room_id,
          connectedUsers: [...callRoom.connectedUsers, { ...data.from, media: data.media }],
          initialCallType: data.media.video ? CHAT_CALLROOM.VIDEO_CALL : CHAT_CALLROOM.VOICE_CALL
        })
      );
    }
  };

  useEffect(() => {
    if (lastMessage) {
      onWebSocketsMessage(lastMessage);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const addMessage = (text, file) => {
    if ((text || file || attachedFiles?.length) && socket) {
      const attachment = file ? [file] : attachedFiles?.length ? [...attachedFiles] : undefined;
      const sendMessage = (params) => {
        socket().send(JSON.stringify({ ...params, uid, id_company, text, attachment }));
      };
      if (attachedFiles?.length) setAttachedFiles(null);
      sendMessage(
        selectedContact?.isGroup
          ? {
              action: "chat_group_message_add",
              id_group: selectedContact?.id_group,
              is_group: true
            }
          : selectedContact.is_secret_chat
          ? {
              action: "chat_group_message_add",
              id_group: selectedContact?.id_group,
              is_secret_chat: true,
              deadline: messageLifeTime
            }
          : {
              action: "chat_message_send",
              id_user_to: selectedContact?.id_real_user,
              id_contact: selectedContact?.id
            }
      );
    }
  };

  const editMessage = (message, newText) => {
    // TODO: add attachment deleting
    if (newText && newText !== action.message.text) {
      const sendSocketMessage = (params) => {
        socket().send(
          JSON.stringify({
            ...params,
            attachment: message.attachment,
            uid,
            id_message: message.id,
            text: newText,
            day: message.day
          })
        );
      };

      sendSocketMessage(
        message.id_group
          ? {
              action: "chat_group_message_edit",
              id_group: message.id_group,
              is_group: true,
              is_secret_chat: !!selectedContact.is_secret_chat
            }
          : {
              action: "chat_message_edit",
              id_user_to: selectedContact?.id_real_user,
              id_contact: selectedContact?.id
            }
      );
    }
  };

  const setIsReadMessage = (params) => {
    socket().send(
      JSON.stringify({
        ...params,
        uid
      })
    );
  };

  const renderCreateCameraMedia = useCallback(
    () => (
      <CreateCameraMedia
        nullifyAction={nullifyAction}
        addMessage={addMessage}
        socket={socket}
        scrollToBottom={scrollToBottom}
      />
    ),
    [action] // eslint-disable-line
  );

  useEffect(() => {
    //TODO: move to Store
    if (selectedContact)
      dispatch({
        type: "SET_NOTIFICATION_COUNTER",
        payload: {
          id:
            selectedContact?.isGroup || selectedContact?.is_secret_chat
              ? `group_${selectedContact?.id_group}`
              : `chat_${selectedContact?.id_real_user}`,
          value: 0
        }
      });

    // if (socket()) {
    //   socket().addEventListener("message", onWebSocketsMessage);
    // }
    // return () => {
    //   socket().removeEventListener("message", onWebSocketsMessage);
    // };
  }, [socket, selectedContact]); //eslint-disable-line

  return (
    <div
      className={classNames({
        [styles.chatWorkSpaceWrap]: true,
        [styles.darkTheme]: chatTheme.name === "dark"
      })}
    >
      <div className={styles.main} style={showSettings ? { height: "calc(100% - 179px - 60px)" } : {}}>
        {selectedContact && action.type !== "addChat" && action.type !== "editChatGroup" ? (
          <ChatBoard
            sideMenuCollapsed={sideMenuCollapsed}
            boardOption={boardOption}
            setShowSuccessPopup={setShowSuccessPopup}
            action={action}
            setAction={setAction}
            setMouseParams={setMouseParams}
            currentDate={currentDate}
            addMessage={addMessage}
            nullifyAction={nullifyAction}
            file={file}
            setFile={setFile}
            socket={socket}
            endMessagesRef={endMessagesRef}
            scrollToBottom={scrollToBottom}
            editMessage={editMessage}
            showSettings={showSettings}
            attachedFiles={attachedFiles}
            setAttachedFiles={setAttachedFiles}
            setIsReadMessage={setIsReadMessage}
          />
        ) : (
          ""
        )}
        {action.type === "addChat" ? (
          <CreateChat
            title={action.name}
            maxCountUsers={action?.chatsType === "groups" ? 200 : 1}
            nullifyAction={nullifyAction}
            setShowSuccessPopup={setShowSuccessPopup}
            componentType={"add"}
            currentDate={currentDate}
            initialUser={action.initialUser}
          />
        ) : (
          ""
        )}
        {action.type === "editChatGroup" ? (
          <CreateChat
            title={action.name}
            maxCountUsers={200}
            nullifyAction={nullifyAction}
            setShowSuccessPopup={setShowSuccessPopup}
            selectedContact={selectedContact}
            componentType={"edit"}
            currentDate={currentDate}
          />
        ) : (
          ""
        )}
      </div>
      {action?.type === "createMediaFromCamera" ? renderCreateCameraMedia() : ""}
    </div>
  );
};

export default WorkSpace;

WorkSpace.propTypes = {
  sideMenuCollapsed: PropTypes.bool,
  boardOption: PropTypes.string,
  setShowSuccessPopup: PropTypes.func.isRequired,
  nullifyAction: PropTypes.func.isRequired,
  action: actionProps,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  setAction: PropTypes.func.isRequired,
  setMouseParams: PropTypes.func.isRequired,
  file: fileChatProps,
  setFile: PropTypes.func.isRequired,
  showSettings: PropTypes.bool,
  setShowSettings: PropTypes.func.isRequired
};
