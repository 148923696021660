import { ICombinedElementsProps } from "models/generalComponents/elements";
import React from "react";

import Colors from "./Colors";
import styles from "./CombinedElements.module.sass";
import Emoji from "./Emoji";
import { TagPicker } from "./TagPicker";

const CombinedElements: React.FC<ICombinedElementsProps> = ({
  tags,
  onSelectTags,
  color,
  setColor,
  emo,
  setEmo,
  disabled
}): JSX.Element => {
  return (
    <div className={styles.wrap}>
      <TagPicker tags={tags} onSelectTags={onSelectTags} disabled={disabled} />
      <Colors color={color} setColor={setColor} disabled={disabled} />
      <Emoji emo={emo} setEmo={setEmo} disabled={disabled} />
    </div>
  );
};

export default CombinedElements;
