import { ReactComponent as PlayGreyIcon } from "assets/PrivateCabinet/play-grey.svg";
import classNames from "classnames";
import React from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./List.module.sass";

interface IListProps {
  title: string;
  tooltipBtn?: string;
  setListCollapsed?: (cb: (el: boolean) => boolean) => void;
  listCollapsed?: boolean;
  children: React.ReactNode;
  onCreate?: (el: boolean) => void;
  icon?: JSX.Element;
  leftIcon?: JSX.Element;
  childrenBackground?: string;
  collapsedSize?: "small" | "medium" | "large";
}

const List: React.FC<IListProps> = ({
  title,
  tooltipBtn,
  setListCollapsed,
  listCollapsed,
  children,
  onCreate,
  icon = false,
  leftIcon,
  childrenBackground,
  collapsedSize = "medium"
}): JSX.Element => {
  const { __ } = useLocales();
  const {
    userInfo: { theme }
  } = useUserSelectors();

  const onClickCreateIcon = (): void => onCreate(true);
  const toggleListCollapsed = (): void => setListCollapsed((prev) => !prev);

  return (
    <div
      className={classNames(styles.listWrap, {
        [styles.collapsed]: Boolean(listCollapsed),
        [styles.small]: collapsedSize === "small",
        [styles.medium]: collapsedSize === "medium",
        [styles.large]: collapsedSize === "large"
      })}
    >
      <div className={styles.header} title={listCollapsed ? title : ""}>
        <div className={styles.leftWrapper}>
          {Boolean(leftIcon) && (
            <button onClick={onClickCreateIcon} className={styles.icon} type="button">
              {leftIcon}
            </button>
          )}
          {!listCollapsed && <span className={styles.title}>{title}</span>}
        </div>
        {listCollapsed !== undefined && (
          <div className={styles.imgWrap}>
            <button
              onClick={toggleListCollapsed}
              className={classNames(styles.icon, {
                [styles.reverse]: listCollapsed
              })}
              type="button"
            >
              <PlayGreyIcon title={listCollapsed ? __("Развернуть") : __("Свернуть")} />
            </button>
            {Boolean(icon) && (
              <button onClick={onClickCreateIcon} className={styles.icon} type="button" title={tooltipBtn}>
                {icon}
              </button>
            )}
          </div>
        )}
      </div>
      <div
        style={{ background: childrenBackground }}
        className={classNames(styles.children, `scrollbar-thin-${theme}`)}
      >
        {children}
      </div>
    </div>
  );
};

export default List;
