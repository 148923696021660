import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import classNames from "classnames";
import { useTaskDependencies } from "collections/joinProject";
import Button from "generalComponents/Button/Button";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { PROJECT_PATH, ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IProjectTask, TASK_DEPENDENCIES } from "models/store/joinProjects/joinProgects";
import { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { getDependencyType2 } from "utils/jointPtoject";

import styles from "./TaskDependencyItem.module.sass";

interface IProps {
  task: IProjectTask;
  type: TASK_DEPENDENCIES;
}

const TaskDependencyItem: FC<IProps> = ({ task, type }) => {
  const { taskDpendencies } = useTaskDependencies();
  const { onDeleteTaskDependency, onEditTaskDependency, onSetProjectTaskInfoModal } = useActions();
  const { id, id_task } = useParams();
  const [show, setShow] = useState<boolean>(false);
  const { currentTask } = useJoinProjectsSelectors();

  const onEditDependence = (type1_new: TASK_DEPENDENCIES) => {
    const payload = {
      id_task1: currentTask.id,
      id_task2: task.id,
      type1: type,
      type2: getDependencyType2(type),
      type1_new,
      type2_new: getDependencyType2(type1_new)
    };
    onEditTaskDependency(payload);
  };

  const onDeleteDependence = (type1: TASK_DEPENDENCIES) => {
    const payload = { id_task1: currentTask.id, id_task2: task.id, type1, type2: getDependencyType2(type1) };
    onDeleteTaskDependency(payload);
  };

  const renderContent = () => (
    <ul>
      <li
        className={classNames(styles.item, styles.border)}
        onClick={() => onEditDependence(TASK_DEPENDENCIES.RELATION)}
      >
        {taskDpendencies[TASK_DEPENDENCIES.RELATION]}
      </li>
      <li className={styles.item} onClick={() => onEditDependence(TASK_DEPENDENCIES.REQUIRED_FOR)}>
        {taskDpendencies[TASK_DEPENDENCIES.REQUIRED_FOR]}
      </li>
      <li
        className={classNames(styles.item, styles.border)}
        onClick={() => onEditDependence(TASK_DEPENDENCIES.DEPENDS_ON)}
      >
        {taskDpendencies[TASK_DEPENDENCIES.DEPENDS_ON]}
      </li>
      <li className={styles.item} onClick={() => onEditDependence(TASK_DEPENDENCIES.PARENT_FOR)}>
        {taskDpendencies[TASK_DEPENDENCIES.PARENT_FOR]}
      </li>
      <li className={styles.item} onClick={() => onEditDependence(TASK_DEPENDENCIES.CHILD_FOR)}>
        {taskDpendencies[TASK_DEPENDENCIES.CHILD_FOR]}
      </li>
    </ul>
  );

  const getSprint = (task: IProjectTask): string => {
    if (task.id_sprints.length === 0) return "";
    return `?sprint=${task.id_sprints[0]}`;
  };

  const changeTask = () => onSetProjectTaskInfoModal({ open: true, task });

  return (
    <div className={styles.dependence}>
      <PopoverOverlay
        overlayContent={renderContent()}
        classes={{ popoverBody: styles.nopadding }}
        show={show}
        setShow={setShow}
        btn="custom"
        iconRBtn={<ArrowIcon className={styles.icon} />}
        title={taskDpendencies[type]}
        sizeBtn={ButtonSizeType.EXTRA_SMALL}
        variantBtn={ButtonVariantType.BLUE}
        visibleHeader={false}
        width={200}
        placement="bottom"
      />
      {id_task ? (
        <Link to={`/${ROUTES.PROJECT}/${id}/${PROJECT_PATH.TASK}/${task.id}${getSprint(task)}`} className={styles.name}>
          <span className={styles.num}>{task.num}</span> {task.name}
        </Link>
      ) : (
        <div className={styles.name} onClick={changeTask}>
          <span className={styles.num}>{task.num}</span> {task.name}
        </div>
      )}
      <Button
        variant={ButtonVariantType.EXRTA_LIGHT}
        size={ButtonSizeType.EXTRA_SMALL}
        iconL={<CrossIcon />}
        isSquare
        className={styles.btn}
        onClick={() => onDeleteDependence(type)}
      />
    </div>
  );
};

export default TaskDependencyItem;
