import { ITopMessages } from "models/common/common";

import { ILetterInfo } from "./postbox";

export interface IFetchFoldersPayload {
  email: string;
}

export interface IOnAddNewPostboxPayload {
  name: string;
  email: string;
  password: string;
  message: {
    title: string;
    success: string;
    error_email_name_exists: string;
    error_email_unknown_domain: string;
    error_unknown_error: string;
  };
}

export interface IOnEnterPostbox {
  email: string;
  pass: string;
  server?: string;
  name: string;
  messages: {
    succes: string;
    error: string;
    errorLogin: string;
    errorBlocked: string;
    errorExist: string;
  };
}

export interface IOnExitPostboxPayload {
  email: string;
  pass: string;
  id: string;
  id_agent?: string;
}

export interface IOnEditPostboxFolderPayload {
  email: string;
  folder: string;
  pass: string;
  folder_new?: string;
}

export enum OrderByList {
  SORT_DATE = "SORTDATE",
  SORT_ARRIVAL = "SORTARRIVAL",
  SORT_FROM = "SORTFROM",
  SORT_SUBJECT = "SORTSUBJECT",
  SORT_TO = "SORTTO",
  SORT_CC = "SORTCC",
  SORT_SIZE = "SORTSIZE"
}

export interface IGetPostboxMailsPayload {
  user: string;
  pass: string;
  folder: string;
  limit: number;
  start: number;
  orderBy?: OrderByList;
  isSchedule?: boolean;
  is_unseen?: string;
  is_favorite?: string;
  messages?: {
    error: string;
  };
}
export interface IUpdateInboxPayload {
  user: string;
  pass: string;
  limit: number;
  start: number;
  userId: string;
  is_unseen?: string;
  is_favorite?: string;
}

export interface IOnSendEmailPayload {
  user?: string;
  folder?: string;
  newFolder?: string;
  id?: string;
  messages?: ITopMessages;
  cb?: (letter: ILetterInfo) => void;
}

export interface IChangeFlagMail {
  data: {
    user: string;
    pass: string;
    server: string;
    data: string;
  };
  value: {
    flag: string;
    val: string;
    folder?: string;
    unreadAmount?: number;
  };
}
export interface IMoveLetter {
  user: string;
  pass: string;
  server: string;
  data: string;
}

export interface IRemoveLetter {
  user: string;
  pass: string;
  server: string;
  data: string;
}
