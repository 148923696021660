import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import { Observer } from "generalComponents/Observer/Observer";
import { WorkSpaceWrap } from "generalComponents/WorkSpaceWrap/WorkSpaceWrap";
import { useActions } from "hooks/useActions";
import { LoaderTypes } from "models/generalComponents/loader";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import { JointProjectTrashElementsGrid } from "./components/JointProjectTrashElementsGrid/JointProjectTrashElementsGrid";

interface IJoinProjectTrashContentProps {
  selectedTeam: string;
  selectedParticipants: string[];
  searchQuery: string;
}

export const JoinProjectTrashContent: FC<IJoinProjectTrashContentProps> = ({
  selectedTeam,
  selectedParticipants,
  searchQuery
}): JSX.Element => {
  const { __ } = useLocales();

  const { loader, project, page, total } = useJoinProjectsSelectors();
  const { onGetProjectTrashFiles, onSetProjectPage } = useActions();

  const projectTrashElements = project?.trash;

  const loadMore = (): void => {
    const controller = new AbortController();
    onSetProjectPage(page + 1);

    const payload = {
      id_team: selectedTeam,
      id_executor: selectedParticipants.join(","),
      search: searchQuery
    };

    onGetProjectTrashFiles(payload, controller);
  };

  const renderMyFiles = (): JSX.Element => {
    if (projectTrashElements !== null) {
      if (total === 0) {
        if (selectedTeam || selectedParticipants || searchQuery) {
          return <Clue icon="neutral" text={__("Нет файлов соответствующих параметрам фильтрации")} />;
        }
        return <Clue icon="sad" text={__("Здесь пока пусто")} />;
      }

      return <JointProjectTrashElementsGrid />;
    }
  };

  return (
    <WorkSpaceWrap>
      {loader && page === 1 ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <>
          {renderMyFiles()}
          {<Observer loader={loader} page={page} total={total} callBack={loadMore} />}
        </>
      )}
    </WorkSpaceWrap>
  );
};
