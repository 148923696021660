import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import SearchField from "generalComponents/SearchField/SearchField";
import { useFindSprint } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ISelectCustom } from "models/generalComponents/selectCustom/selectCustom";
import { useEffect, useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import { Options } from "./Options/Options";
import styles from "./SelectSprint.module.sass";

const SelectSprint = (): JSX.Element => {
  const { __ } = useLocales();

  const findSprint = useFindSprint();
  const { project } = useJoinProjectsSelectors();
  const { onSetCreateSprintModal } = useActions();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<ISelectCustom>({ name: __("Выберите"), value: "" });
  const [searchValue, setSearchValue] = useState<string>("");

  const { id_sprint } = useAllSelectedSearchParams();

  useEffect(() => {
    if (id_sprint.selectedValue) {
      const _sprint = findSprint(id_sprint.selectedValue);
      _sprint && setSelected({ name: _sprint.name.trim(), value: _sprint.id });
    }
  }, [id_sprint.selectedValue]); // eslint-disable-line

  const handleClick = (el: ISelectCustom): void => {
    if (el.id !== id_sprint.selectedValue) {
      id_sprint.onSelectValue(el.id);
    }

    setIsOpen(false);
  };

  const filtredSprints = useMemo(() => {
    return project.sprints.filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [project.sprints, searchValue]);

  return (
    <PopoverOverlay
      width={280}
      placement="bottom"
      overlayContent={
        <div className={styles.content}>
          <div className={styles.content__header}>
            <SearchField
              variant="notOutline"
              placeholder={__("Введите")}
              value={searchValue}
              setValue={setSearchValue}
            />
          </div>
          {filtredSprints?.length ? (
            <Options
              options={filtredSprints}
              handleClick={handleClick}
              selected={selected}
              onHidden={() => setIsOpen(false)}
            />
          ) : (
            <div className={styles.content__body}>
              <Clue icon="sad" text={__("Ничего не найдено")} variant="column" />
            </div>
          )}
          <div className={styles.content__footer}>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              onClick={() => {
                setIsOpen(false);
                onSetCreateSprintModal({ open: true });
              }}
              size={ButtonSizeType.SMALL}
              text={`+ ${__("Новый спринт")}`}
            />
          </div>
        </div>
      }
      show={isOpen}
      setShow={setIsOpen}
      placeholder={selected.name}
    >
      <span
        className={classNames(styles.btnSelected, {
          [styles.open]: isOpen,
          [styles.selected]: Boolean(selected.value)
        })}
      >
        {selected?.name}
      </span>
    </PopoverOverlay>
  );
};

export { SelectSprint };
