import Button from "generalComponents/Button/Button";
import FolderPath from "generalComponents/FolderPath/FolderPath";
import PopUp from "generalComponents/PopUp/PopUp";
import { useCurrentFolder, useFolderPath } from "generalComponents/Services/folderServices/folderServices";
import { EVENT_TYPE, PAGE_DEPTH } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialMoveElementsModalState, MOVE_ELEMENTS_TYPE } from "models/store/Cabinet/modals/moveElements";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./MoveElements.module.sass";

const MoveElements = () => {
  const { __ } = useLocales();

  const { onSetMoveElementsModal, onSetCreateFolderModal } = useActions();
  const getFolderPath = useFolderPath();
  const {
    moveElements: {
      elements,
      folders,
      type,
      actionsCallback: {
        onCreateFolder,
        onEditFolders,
        onCopyFolders,
        onDelCopyFile,
        onEditFiles,
        onCopyFiles,
        onSetUpdate
      }
    }
  } = useGlobalModalsSelectors();

  const [folderPath, setFolderPath] = useState<IFolder[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<IFolder>();

  const closeModal = () => onSetMoveElementsModal(initialMoveElementsModalState());
  const getTitle = (): string => {
    switch (type) {
      case MOVE_ELEMENTS_TYPE.MOVE_FILE:
      case MOVE_ELEMENTS_TYPE.MOVE_FOLDER:
        return __("Переместить");

      case MOVE_ELEMENTS_TYPE.COPY_FILE:
      case MOVE_ELEMENTS_TYPE.COPY_FOLDER:
        return __("Скопировать");

      default:
        break;
    }
  };

  const onSelectFolder = (e: React.MouseEvent<HTMLElement>, folder: IFolder): void => {
    e.stopPropagation();
    setFolderPath(getFolderPath(folder));
    setSelectedFolder(folder);
  };

  const onMoveFolder = () => {
    const payload = {
      id_dirs: [(elements as IFolder).id_dir],
      id_parent: selectedFolder.id_dir,
      id_parent_dir_moved: (elements as IFolder).id_parent
    };
    onEditFolders(payload, EVENT_TYPE.MOVE, (data?: any) => {
      closeModal();
      onSetUpdate(data);
    });
  };

  const onMoveFile = () => {
    const payload = {
      id_dir: selectedFolder?.id_dir,
      fids: [(elements as IFile).fid]
    };

    onEditFiles(payload, EVENT_TYPE.MOVE, null, (data?: any) => {
      closeModal();
      onSetUpdate(data);
    });
  };

  const onCopyFile = () => {
    const payload = {
      id_dir: selectedFolder?.id_dir,
      fids: [(elements as IFile).fid],
      dep: selectedFolder?.dep
    };

    onCopyFiles(payload, onDelCopyFile);
  };

  const onCopyFolder = () => {
    const payload = {
      id_parent: selectedFolder?.id_dir,
      id_dirs: [(elements as IFolder).id_dir],
      dep: selectedFolder?.dep
    };

    onCopyFolders(payload);
  };

  const onSubmit = () => {
    switch (type) {
      case MOVE_ELEMENTS_TYPE.MOVE_FOLDER:
        onMoveFolder();
        break;
      case MOVE_ELEMENTS_TYPE.MOVE_FILE:
        onMoveFile();
        break;
      case MOVE_ELEMENTS_TYPE.COPY_FILE:
        onCopyFile();
        break;
      case MOVE_ELEMENTS_TYPE.COPY_FOLDER:
        onCopyFolder();
        break;
      default:
        break;
    }
  };

  const renderCreateFolders = () => {
    if (folders.some(({ dep }) => dep === PAGE_DEPTH.PROJECT_DOC)) {
      return folders.filter(({ dep }) => dep === PAGE_DEPTH.PROJECT_DOC);
    } else {
      return folders;
    }
  };

  const currentFolder = useCurrentFolder();

  return (
    <PopUp set={closeModal}>
      <div className={styles.wrap}>
        <HeaderModal onClose={closeModal} title={getTitle()} />
        <div className={styles.body}>
          <div className={styles.folders}>
            <FolderPath
              folders={folders}
              folderPath={folderPath}
              onSelect={onSelectFolder}
              currentFolder={elements.is_dir ? (elements as IFolder) : undefined}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            onClick={() =>
              onSetCreateFolderModal({
                open: true,
                type: "add",
                folder: currentFolder ?? null,
                folders: renderCreateFolders(),
                onCreateFolder
              })
            }
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("Создать папку")}
          />
          <div className={styles.btns}>
            <Button
              onClick={closeModal}
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Отменить")}
            />
            <Button
              onClick={onSubmit}
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={getTitle()}
              disabled={!selectedFolder}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default MoveElements;
