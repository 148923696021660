import { STATUS_TYPES, TASK_TYPES, URGENCY_TYPES } from "generalComponents/variables/tasks";
import { IDateObject } from "models/generalComponents/calendars";
import { TypeElements } from "models/typeElements/typeElements";

export enum TaskFilters {
  BY_DAY = "BY_DAY",
  BY_WEEK = "BY_WEEK",
  BY_MONTH = "BY_MONTH",
  BY_YEAR = "BY_YEAR"
}

export enum TASK_DEP {
  WORK = "work",
  PERSONAL = "personal",
  NO_DEP = ""
}

export interface ITaskComment {
  id?: string;
  id_task: string;
  text: string;
}

export interface IMyTask {
  comments: ITaskComment[];
  date_end: string;
  date_start: string;
  emails: string;
  id: string;
  id_act: URGENCY_TYPES;
  id_dep: TASK_DEP;
  id_status: STATUS_TYPES;
  id_type: TASK_TYPES;
  is_del: number;
  name: string;
  num: string;
  pass: string;
  prim: string;
  is_archive: number;
  tags: string[];
  ut: string;
  emo?: string;
  color?: string;
  showNote?: boolean;
  atime?: string;
  cdate?: string;
  ctime?: string;
  mtime?: string;
  dtime?: string;
  table?: TypeElements;
}
export interface ITaskFilters {
  type: TaskFilters;
  date: IDateObject;
}
export interface ITasksState {
  myTasks: IMyTask[];
  chosenTask: IMyTask;
  filters: ITaskFilters;
}

export function initialTasksState(): ITasksState {
  return {
    myTasks: [],
    chosenTask: {
      comments: [],
      date_end: "",
      date_start: "",
      emails: "",
      id: "",
      id_act: URGENCY_TYPES.PLANNED,
      id_dep: TASK_DEP.NO_DEP,
      id_status: STATUS_TYPES.IN_PROGRESS,
      id_type: TASK_TYPES.TASK,
      is_del: 0,
      name: "",
      num: "",
      pass: "",
      prim: "",
      is_archive: 0,
      tags: [],
      ut: ""
    },
    filters: {
      type: TaskFilters.BY_DAY,
      date: { year: new Date().getFullYear(), month: new Date().getMonth(), day: new Date().getDate() }
    }
  };
}
