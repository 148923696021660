import React from "react";
import { useLocales } from "react-localized";

import styles from "./BottomMenuOption.module.sass";

interface ICancelBtnProps {
  onCancel: () => void;
}

const CancelBtn: React.FC<ICancelBtnProps> = ({ onCancel }) => {
  const { __ } = useLocales();
  return (
    <button onClick={onCancel} className={styles.cancel} type="button">
      {__("Отмена")}
    </button>
  );
};

export default CancelBtn;
