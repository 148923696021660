import { typeCheck } from "generalComponents/Services/browserServices";
import { useFiltredRole } from "generalComponents/Services/projectServices";
import { TYPES } from "generalComponents/variables/global";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { taskTypes } from "types/Project";

import BacklogRole from "../BacklogRole/BacklogRole";

const BacklogSpace = ({ backlogTasks, handleSelected, selectedTasks }) => {
  const filtredRoles = useFiltredRole();
  const project = useSelector((s) => s.Projects.project);
  const selectRole = useSelector((s) => s.Projects.selectRole);
  const usersRole = project.users.reduce((acc, u) => {
    acc[u.id_user] = Number(u.id_role);
    return acc;
  }, {});

  const backlogTasksWithoutRole = backlogTasks.filter(
    (t) => t.is_backlog === 0 && (t.id_executor.length === 0 || t.id_executor.some((i) => usersRole[i] === 0))
  );

  const backlogGroup = useMemo(
    () =>
      filtredRoles.reduce((acc, r) => {
        acc[r.id] = backlogTasks.filter((t) => {
          if (typeCheck(t.id_executor) === TYPES.ARRAY && t.id_executor.length > 0) {
            return t.id_executor.some((i) => project.users.some((u) => u.id_user === i && u.id_role === r.id));
          } else {
            return Number(t.is_backlog) === Number(r.id);
          }
        });
        if (backlogTasksWithoutRole.length > 0 && selectRole === "0") {
          acc["0"] = backlogTasksWithoutRole;
        }
        return acc;
      }, {}),
    [backlogTasks, backlogTasksWithoutRole, filtredRoles, project.users, selectRole]
  );

  return (
    <>
      {Object.entries(backlogGroup).map(([roleId, tasks], i) => (
        <BacklogRole
          key={i}
          roleId={roleId}
          tasks={tasks}
          handleSelected={handleSelected}
          selectedTasks={selectedTasks}
        />
      ))}
    </>
  );
};

export default BacklogSpace;

BacklogSpace.propTypes = {
  backlogTasks: PropTypes.arrayOf(taskTypes),
  handleSelected: PropTypes.func,
  selectedTasks: PropTypes.arrayOf(taskTypes)
};
