import api from "api";
import { ReactComponent as PlusIcon } from "assets/icons/person_add.svg";
import { useEmployeeRights, useTransferAccess } from "collections/profile";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import PopUp from "generalComponents/PopUp/PopUp";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import Switcher from "generalComponents/Switcher/Switcher";
import { TYPE_TABLE_ACCESS } from "generalComponents/variables/global";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import useBeforeUnloadWarning from "hooks/useBoforeUnloadWarning";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { PROFILE_MODALS } from "models/store/Cabinet/modals/profileModals";
import { initialTransferDutiesModalState } from "models/store/Cabinet/modals/transferDuties";
import { EMPLOYEE_RIGHTS, IUserInfo } from "models/store/user/user";
import { useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import AlertModal from "../AlertModal/AlertModal";
import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./TransferDuties.module.sass";

const mgrRightsArray = [
  EMPLOYEE_RIGHTS.IS_MGR_ORG,
  EMPLOYEE_RIGHTS.IS_MGR_EMPLOYEE,
  EMPLOYEE_RIGHTS.IS_MGR_FINANCE,
  EMPLOYEE_RIGHTS.IS_MGR_LIBRARY,
  EMPLOYEE_RIGHTS.IS_MGR_PROJECT
];

const InitialTransferAccess = {
  IS_FILES: true,
  IS_TASKS: true,
  IS_PROJECT: true,
  IS_CALENDAR: true,
  IS_CHAT: true,
  IS_MAIL: true
};

const getdata = (rights: Record<EMPLOYEE_RIGHTS, boolean>): Record<string, boolean> => {
  const result = mgrRightsArray.reduce((acc, el) => ({ ...acc, [el]: rights[el] }), {});
  return result;
};

const TransferDuties = () => {
  const { __ } = useLocales();

  const { onSetTransferDutiesModal, onSetProfileModal, onSetTopMessageModal } = useActions();
  const {
    transferDuties: { employee }
  } = useGlobalModalsSelectors();
  const { userInfo, employees, uid } = useUserSelectors();
  const RIGHTS = useEmployeeRights();
  const TRANSFER_ACCESS = useTransferAccess();

  const [selectedUser, setSelectedUser] = useState<IUserInfo>(null);
  const [mgrRights, setMgrRights] = useState<Record<string, boolean>>(getdata(employee.rights));
  const [transferAccess, setTransferAccess] = useState<Record<string, boolean>>(InitialTransferAccess);
  const [isCopying, setIsCopying] = useState<boolean>(false);
  useBeforeUnloadWarning(isCopying);

  const closeModal = () => onSetTransferDutiesModal(initialTransferDutiesModalState());

  const onAddEmlpoee = () => onSetProfileModal({ open: true, type: PROFILE_MODALS.ADD_EMPLOYEE, employee: null });

  const changeMgrRight = (field: string) => setMgrRights((prev) => ({ ...prev, [field]: !prev[field] }));

  const changeTransferAccess = (field: string) => setTransferAccess((prev) => ({ ...prev, [field]: !prev[field] }));

  const optionsEmployees = useMemo(
    () => [
      {
        name: `${userInfo.fname} ${userInfo.sname}`,
        value: userInfo.uid,
        icon: (
          <Avatar name={`${userInfo.fname} ${userInfo.sname}`} size={24} imgSrc={`${projectSrc}${userInfo.icon}`} />
        ),
        callback: () => setSelectedUser(userInfo)
      },

      ...employees.map((item) => ({
        name: `${item.fname} ${item.sname}`.trim() || item.email,
        value: item.uid,
        icon: (
          <Avatar
            name={`${item.fname} ${item.sname}`.trim() || item.email}
            size={24}
            imgSrc={`${projectSrc}${item.icon}`}
          />
        ),
        callback: () => setSelectedUser(item)
      }))
    ],
    [employees, userInfo]
  );

  const onSubmit = async () => {
    setIsCopying(true);
    const rights: Record<string, boolean> = { ...selectedUser.rights };
    for (let key in mgrRights) {
      if (mgrRights[key] === true) {
        rights[key] = mgrRights[key];
      }
    }

    try {
      const params = {
        uid,
        id_user_from: employee.id,
        id_user_to: selectedUser.id,
        rights: JSON.stringify(rights)
      };

      if (transferAccess["IS_FILES"] === true) {
        const { data } = await api.get("/ajax/user_copy.php", {
          params: { ...params, table: TYPE_TABLE_ACCESS.FOLDERS }
        });
        checkResponseStatus(data.ok);
      }
      if (transferAccess["IS_FILES"] === true) {
        const { data } = await api.get("/ajax/user_copy.php", {
          params: { ...params, table: TYPE_TABLE_ACCESS.FILES }
        });
        checkResponseStatus(data.ok);
      }
      if (transferAccess["IS_TASKS"] === true) {
        const { data } = await api.get("/ajax/user_copy.php", {
          params: { ...params, table: TYPE_TABLE_ACCESS.TASKS }
        });
        checkResponseStatus(data.ok);
      }
      if (transferAccess["IS_CALENDAR"] === true) {
        const { data } = await api.get("/ajax/user_copy.php", {
          params: { ...params, table: TYPE_TABLE_ACCESS.CALENDARS }
        });
        checkResponseStatus(data.ok);
      }
      if (transferAccess["IS_MAIL"] === true) {
        const { data } = await api.get("/ajax/user_copy.php", {
          params: { ...params, table: TYPE_TABLE_ACCESS.EMAILS }
        });
        checkResponseStatus(data.ok);
      }
      // TODO -mk-
      // if (transferAccess["IS_PROJECT"] === true) {
      //   const { data } = await api.get("/ajax/user_copy.php", { params: { ...params, table: TYPE_TABLE_ACCESS.PROJECTS } });
      //   checkResponseStatus(data.ok);
      // }
      // if (transferAccess["IS_CHAT"] === true) {
      //   const { data } = await api.get("/ajax/user_copy.php", { params: { ...params, table: TYPE_TABLE_ACCESS.CHAT } });
      //   checkResponseStatus(data.ok);
      // }
      setIsCopying(false);

      closeModal();
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  return (
    <PopUp set={closeModal} position={isCopying ? "right-bottom" : "center"}>
      <div className={styles.wrap}>
        <HeaderModal title={__("Передача обязанностей")} onClose={isCopying ? null : closeModal} />
        {!isCopying ? (
          <div className={styles.body}>
            <div className={styles.top}>
              <SelectCustom
                selectedDefault={{ name: "", value: "" }}
                name="searchEmployees"
                options={optionsEmployees}
                variantHeight="small"
                width={300}
                search
              />
              <div className={styles.delimiter} />
              <Button
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.SMALL}
                onClick={onAddEmlpoee}
                text={__("Добавить")}
                iconL={<PlusIcon width={10} height={10} />}
              />
            </div>
            <AlertModal
              text={__("Следующие данные и привилегии будут перенесены в аккаунт сотрудника")}
              variant="yellow"
            />
            <div className={styles.block}>
              <span className={styles.title}>{__("Привелегии")}</span>
              <ul className={styles.list}>
                {Object.entries(mgrRights).map(([field, value], i) => (
                  <li key={i} className={styles.item}>
                    <Switcher checked={value as boolean} onClickCheckBox={() => changeMgrRight(field)} />
                    <span className={styles.name}>{RIGHTS[field as EMPLOYEE_RIGHTS]}</span>
                  </li>
                ))}
              </ul>
              <span className={styles.title}>{__("Инструменты")}</span>

              <ul className={styles.list}>
                {Object.entries(transferAccess).map(([field, value], i) => (
                  <li key={i} className={styles.item}>
                    <Switcher checked={value as boolean} onClickCheckBox={() => changeTransferAccess(field)} />
                    <span className={styles.name}>{TRANSFER_ACCESS[field]}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.btns}>
              <Button
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.MEDIUM}
                onClick={closeModal}
                text={__("Отменить")}
              />
              <Button
                variant={ButtonVariantType.BLUE}
                size={ButtonSizeType.MEDIUM}
                onClick={onSubmit}
                text={__("Сохранить")}
                disabled={Boolean(!selectedUser)}
              />
            </div>
          </div>
        ) : (
          <div className={styles.loaderWrap}>
            <div className={styles.loaderBox}>
              <Loader
                type={LoaderTypes.BOUNCING_DOTS}
                position="absolute"
                background="white"
                zIndex={5}
                width="150px"
                height="150px"
                containerType="bounceDots"
              />
            </div>
            <p>{__("Это может занять некоторое время. Не закрывайте это окно")}</p>
          </div>
        )}
      </div>
    </PopUp>
  );
};

export default TransferDuties;
