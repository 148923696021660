import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import classNames from "classnames";
import EmployeeItem from "containers/Dashboard/Employees/EmployeeItem/EmployeeItem";
import Empty from "containers/Dashboard/Empty/Empty";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { IJoinProject, IParticipant, IProjectTeam } from "models/store/joinProjects/joinProgects";
import { ILayuotWidget } from "Pages/DashboardPage/DashboardPage";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JointProjectTeams.module.sass";

const JointProjectTeams = ({
  type,
  projectId,
  teamId,
  setBoard,
  i,
  handleConfirm
}: {
  type: string;
  projectId: string;
  teamId: string;
  setBoard?: React.Dispatch<React.SetStateAction<ILayuotWidget[]>>;
  i: string;
  handleConfirm: () => void;
}) => {
  const { __ } = useLocales();
  const { projectList } = useJoinProjectsSelectors();
  const [boardUpdated, setBoardUpdated] = useState(false);
  const [users, setUsers] = useState<IParticipant[]>([]);
  const [project, setProject] = useState<IJoinProject>();
  const [team, setTeam] = useState<IProjectTeam>();
  const [loading, setLoading] = useState(true);
  const { onFetchProjectlist, onSetDashboardJointProjectTeamModal } = useActions();

  useEffect(() => {
    const controller = new AbortController();
    onFetchProjectlist(controller, setLoading);
    return () => controller.abort();
  }, []); // eslint-disable-line

  useEffect(() => {
    const selectedProject = projectList.find((project) => project.id === projectId);
    if (selectedProject) {
      setProject(selectedProject);
      const selectedTeam = selectedProject.teams.find((team) => team.id === teamId);
      if (selectedTeam) {
        setTeam(selectedTeam);
        const userIds = selectedTeam.users;
        const selectedUsers = selectedProject.participants.filter((participant) => userIds.includes(participant.id));
        setUsers(selectedUsers);
      } else {
        setTeam(undefined);
        setUsers(selectedProject.participants);
      }
    }
  }, [projectList, projectId, teamId]);

  const updateJointProjectIds = (projectId: string, teamId: string) => {
    setBoardUpdated(false); // Сбрасываем флаг перед обновлением состояния
    // Используйте функцию обновления состояния для setBoard
    setBoard((prev) => {
      // Ваш код обновления состояния
      const updatedBoard = prev.map((item) => {
        if (item.i === i) {
          return { ...item, projectId: projectId, teamId: teamId };
        }
        return item;
      });
      // Устанавливаем флаг после успешного обновления состояния
      setBoardUpdated(true);

      return updatedBoard;
    });
  };

  useEffect(() => {
    // Вызываем handleConfirm только если setBoard был вызван
    if (boardUpdated) {
      handleConfirm();
      setBoardUpdated(false);
    }
  }, [boardUpdated]); // eslint-disable-line

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <div className={styles.title}>{project && `${project.name} - ` + (team ? team.name : __("Все команды"))}</div>
        </div>
        <Button
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.EXTRA_SMALL}
          isSquare
          iconL={<SettingsIcon />}
          onClick={() =>
            onSetDashboardJointProjectTeamModal({
              open: true,
              projectId,
              teamId,
              callBack: updateJointProjectIds
            })
          }
        />
      </div>
      {loading ? (
        <Loader
          position="absolute"
          containerType="bounceDots"
          width={"100px"}
          height={"100px"}
          type={LoaderTypes.BOUNCING_DOTS}
          background="white"
          animation={false}
        />
      ) : users.length === 0 ? (
        <div className={styles.emptyWrapper}>
          <Empty />
        </div>
      ) : (
        <div className={classNames(styles.content, styles[`content_${type}`])}>
          <div className={classNames(styles.list, "scrollbar-thin-blue")}>
            {users.map((item) => (
              <EmployeeItem item={item} key={item.id} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default JointProjectTeams;
