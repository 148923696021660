import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { getParentTask } from "../../helpers";
import styles from "./TaskNum.module.sass";

const TaskNum = ({ num, color, epicId }) => {
  const theme = useSelector((s) => s.user.userInfo.theme);
  const [showEpic, setShowEpic] = useState(false);
  const project = useSelector((s) => s.Projects.project);

  if (num === "0" || !num) return null;

  return (
    <div
      className={classNames(styles.num, { [styles[theme]]: !color && num })}
      style={{ backgroundColor: num ? color : "" }}
      onMouseEnter={() => setShowEpic(true)}
      onMouseLeave={() => setShowEpic(false)}
    >
      {num}
      {epicId && (
        <div className={classNames(styles.epicInfo, { [styles.hidden]: !showEpic })}>
          {getParentTask(project.tasks, epicId)?.name}
        </div>
      )}
    </div>
  );
};

export default TaskNum;
TaskNum.defaultProps = {
  num: ""
};

TaskNum.propTypes = {
  num: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  epicId: PropTypes.string
};
