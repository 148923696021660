import JoinProjectMainMenu from "containers/JoinProject/JoinProjectMainMenu/JoinProjectMainMenu";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import List from "generalComponents/List/List";
import Loader from "generalComponents/Loaders/4HUB";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useProjectMainAccess } from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { Outlet, useParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./JoinProjectPage.module.sass";

const JoinProjectPage = () => {
  const { __ } = useLocales();
  const { id } = useParams();
  const { projectList } = useJoinProjectsSelectors();
  const [listCollapsed, setListCollapsed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { onSetCreateProjectModal, onFetchProjectlist } = useActions();
  const isMainAccess = useProjectMainAccess();
  const addProject = () => onSetCreateProjectModal({ open: true });

  const renderClue = (): string => {
    if (isMainAccess) {
      return __("У Вас пока нет ни одно проекта. Хотите создать?");
    }
    return __("У Вас пока нет ни одно проекта.");
  };

  useEffect(() => {
    const controller = new AbortController();
    onFetchProjectlist(controller, setLoading);

    return () => controller.abort();
  }, []); // eslint-disable-line

  if (loading) {
    return (
      <WorkAreaWrap variant="row">
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      </WorkAreaWrap>
    );
  }

  if (projectList.length === 0) {
    return (
      <WorkAreaWrap variant="row">
        <div className={styles.start}>
          <Clue text={renderClue()} icon="neutral" />
          {isMainAccess && (
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.MEDIUM}
              text={__("Создать проект")}
              onClick={addProject}
            />
          )}
        </div>
      </WorkAreaWrap>
    );
  }

  return (
    <WorkAreaWrap variant="row">
      <List
        title={__("Совместный проект")}
        setListCollapsed={setListCollapsed}
        listCollapsed={listCollapsed}
        collapsedSize="small"
      >
        <JoinProjectMainMenu collapsed={listCollapsed} />
      </List>
      {id ? <Outlet /> : <Clue text={__("Выберите проект")} icon="happy" />}
    </WorkAreaWrap>
  );
};

export default JoinProjectPage;
