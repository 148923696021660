import classNames from "classnames";
import { FC } from "react";

import styles from "./Image.module.sass";

interface IImageProps {
  src: string;
  srcSet?: string;
  alt: string;
  className?: string;
}

export const Image: FC<IImageProps> = ({ src, srcSet, alt, className }) => (
  <img src={src} srcSet={srcSet} alt={alt} className={classNames(styles.img, className)} />
);
