import { ReactComponent as BellIcon } from "assets/PrivateCabinet/bell.svg";
import { ReactComponent as TaskIcon } from "assets/PrivateCabinet/calendar-4.svg";
import { ReactComponent as CommentIcon } from "assets/PrivateCabinet/comm.svg";
import { ReactComponent as FireIcon } from "assets/PrivateCabinet/fire.svg";
import classNames from "classnames";
import { useContextMenuTask } from "collections/tasks";
import FileTags from "generalComponents/FileTags/FileTags";
import { PopoverContextMenu } from "generalComponents/Popover/PopoverContextMenu/PopoverContextMenu";
import { STATUS_TYPES, TASK_MODALS, TASK_TYPES, URGENCY_TYPES } from "generalComponents/variables/tasks";
import { useDepName, useTaskSatus } from "hooks/tasksHooks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ITaskLineProps } from "models/tasks/myTasks";
import React, { useEffect, useRef } from "react";
import { useLocales } from "react-localized";

import ContextButtons from "../ContextButtons/ContextButtons";
import StatusesButtons from "../StatusesButtons/StatusesButtons";
import TaskStatusSelect from "../TaskStatusSelect/TaskStatusSelect";
import styles from "./TaskLine.module.sass";

const TaskLine: React.FC<ITaskLineProps> = ({ task, num, isFull = true, dayView = false, isIcon = false }) => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const status = useTaskSatus(TASK_TYPES.TASK);
  const contextMenu = useContextMenuTask();
  const departmentName = useDepName();

  const { onSetTaskModal } = useActions();

  const refContainer = useRef<HTMLDivElement>(null);
  const refStatuses = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refContainer && refStatuses) {
      const toLeft = refStatuses.current.clientWidth + 5;
      refContainer.current.scrollLeft = toLeft;
    }
  }, []);

  const onAddComment = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.ADD_COMMENT_TASK,
      params: {
        ...task
      }
    });
  };

  const onOpen = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.OPEN_TASK,
      params: task
    });
  };

  return (
    <div className={classNames(styles.container, { [styles.dayView]: dayView })} ref={refContainer}>
      <div className={styles.additions} ref={refStatuses}>
        <StatusesButtons task={task} />
      </div>
      <div className={styles.taskBox} onClick={onOpen}>
        {num && (
          <div className={classNames(styles.num, { [styles.through]: task.id_status === STATUS_TYPES.DONE })}>
            {num}
          </div>
        )}
        {isIcon && (
          <>
            {dayView && task.id_type === TASK_TYPES.REMINDER ? (
              <BellIcon className={styles.bellIcon} width={9} height={11} />
            ) : (
              <TaskIcon className={styles.taskIcon} width={9} height={11} />
            )}
          </>
        )}
        <div className={classNames(styles.name, { [styles.through]: task.id_status === STATUS_TYPES.DONE })}>
          {task.id_act === URGENCY_TYPES.URGENT && (
            <>
              <FireIcon />
              &nbsp;
            </>
          )}
          {task.name}
        </div>
        {dayView && task.id_type === TASK_TYPES.TASK && (
          <>
            <div className={styles.block}>
              {formatDate(task.date_start)}
              {task.date_end && ` - ${formatDate(task.date_end)}`}
            </div>
            {departmentName(task.id_dep) && (
              <div className={styles.block}>
                {__("Тип:")}&nbsp;{departmentName(task.id_dep)}
              </div>
            )}
          </>
        )}
        {isFull && task.tags.length > 0 && <FileTags item={task} />}
        {task.color && <div className={styles.color} style={{ background: task.color }} />}
        {task.emo && <span className={styles.emo} dangerouslySetInnerHTML={{ __html: task.emo }} />}

        <div
          className={classNames(styles.commentBox, { [styles.isComment]: task.comments?.length > 0 })}
          onClick={onAddComment}
        >
          <CommentIcon />
        </div>
        {isFull && <TaskStatusSelect task={task} />}
        <PopoverContextMenu data={contextMenu(task)} title={__("Действия")} />
        <div className={styles.iconStatus}>{status(task.id_status)?.img}</div>
      </div>
      <div className={styles.additions}>
        <ContextButtons task={task} />
      </div>
    </div>
  );
};

export default TaskLine;
