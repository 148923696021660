import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import Input, { InputVariantType } from "generalComponents/Input/Input";
import { VariantSize } from "generalComponents/variables/size";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IPopoverOverlayProps } from "models/generalComponents/popover/popoverOverlay";
import { FC, MouseEvent, useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";

import styles from "./PopoverOverlay.module.sass";

export const PopoverOverlay: FC<IPopoverOverlayProps> = ({
  classes = {},
  title = "",
  onEnter = () => {},
  onExit = () => {},
  placement = "auto",
  overlayContent,
  rootClose = true,
  children,
  width = 250,
  show,
  setShow,
  isCloseDelay,
  disabled,
  value,
  onChange,
  name,
  placeholder,
  search = false,
  btn = "default",
  variantBtn = ButtonVariantType.EXRTA_LIGHT,
  sizeBtn = ButtonSizeType.SMALL,
  iconLBtn,
  iconRBtn,
  onMouseDown,
  visibleHeader = true
}): JSX.Element => {
  const targetBtn = useRef<HTMLButtonElement>(null);
  const targetDiv = useRef<HTMLDivElement>(null);

  const targetWrappInput = useRef<HTMLDivElement>(null);
  const targetOverlay = useRef<HTMLButtonElement>(null);

  useOutsideClick(targetOverlay, () => {
    isCloseDelay ? setTimeout(() => setShow(false), 100) : setShow(false);
  });

  return (
    <>
      {btn === "extra" && <div ref={targetDiv}>{children}</div>}
      {!search && btn === "default" && (
        <button
          type="button"
          ref={targetBtn}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            setShow(true);
          }}
          title={title}
          disabled={disabled}
          className={styles.btn}
          onMouseDown={onMouseDown}
        >
          {children}
        </button>
      )}
      {!search && btn === "custom" && (
        <Button
          ref={targetBtn}
          onClick={() => setShow(true)}
          variant={variantBtn}
          iconL={iconLBtn}
          iconR={iconRBtn}
          size={sizeBtn}
          text={title}
          title={title}
          onMouseDown={onMouseDown}
          disabled={disabled}
        />
      )}
      {search && (
        <Input
          value={value}
          ref={targetWrappInput}
          onClick={(e: MouseEvent<HTMLInputElement>) => {
            e.stopPropagation();
            setShow(true);
          }}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          variant={InputVariantType.SELECTED}
          size={VariantSize.MEDIUM}
          open={show}
        />
      )}
      <Overlay
        onEnter={onEnter}
        onExit={onExit}
        placement={placement}
        rootClose={rootClose}
        show={show}
        flip
        target={targetBtn?.current || targetWrappInput?.current || targetDiv.current}
        ref={targetOverlay}
      >
        <Popover
          className={classNames(classes?.popoverRoot, styles.popoverRoot)}
          style={{ maxWidth: width }}
          id="popover-context-menu"
        >
          {title && visibleHeader && (
            <Popover.Header className={classNames(classes?.popoverHeader, styles.popoverHeader)}>
              {title}
            </Popover.Header>
          )}
          <Popover.Body className={classNames(classes?.popoverBody, styles.popoverBody)}>{overlayContent}</Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};
