import classNames from "classnames";
import Clue from "generalComponents/Clue/Clue";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { useSetFolderData } from "generalComponents/Hooks/useSetSelectFolder";
import BarsPreviewView from "generalComponents/ItemsViewTypeVariants/BarsPreviewView/BarsPreviewView";
import BarsView from "generalComponents/ItemsViewTypeVariants/BarsView/BarsView";
import LinesPreviewView from "generalComponents/ItemsViewTypeVariants/LinesPreviewView/LinesPreviewView";
import LinesView from "generalComponents/ItemsViewTypeVariants/LinesView/LinesView";
import Loader from "generalComponents/Loaders/4HUB";
import { useIsFilters, useIsShared } from "generalComponents/Services/folderServices/folderServices";
import { ViewType } from "generalComponents/variables/global";
import { SHARED_PARAMS } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useIsFilterByDateCreated } from "hooks/useIsFilters";
import { useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { LoaderTypes } from "models/generalComponents/loader";
import { FilesNextArrayType } from "models/store/files/files";
import { initialFolderState } from "models/store/folders/foldersStore";
import { useEffect, useMemo } from "react";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useFilterCriteriaSelector } from "Store/selectors/filterCriteriaSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import FilesObserver from "../../../generalComponents/FilesObserver/FilesObserver";
import ItemsDate from "../../Cabinet/Components/WorkElements/ItemsDate/ItemsDate";
import styles from "./SharedList.module.sass";

const SharedList = (): JSX.Element => {
  const { __ } = useLocales();
  const [searchParams] = useSearchParams();
  const dir = searchParams.get(SHARED_PARAMS.DIR);
  const { onResetFilesList, onSendFolderPath, onLoadSharedFiles } = useActions();
  const { view, loader, page, files } = useFilesSelectors();
  const {
    objSearchParams: { group, sort, sort_reverse }
  } = useSelectedObjSearchParams();
  const {
    filterCriteria: { color, emo, figure, tags }
  } = useFilterCriteriaSelector();

  const isFilterByDateCreated = useIsFilterByDateCreated();

  const { isBarsView, isLinesView, isLinesPreviewView, isBarsPreviewView } = useGetItemView();
  const { theme } = useUserSelectors();
  const { isSharedByMe, isSharedToMe } = useIsShared();
  const { setSelectFolder } = useSetFolderData();
  const isFilters = useIsFilters();

  const filtredItems: FilesNextArrayType = useMemo(() => {
    return files?.filter((f) => {
      if (isSharedByMe) {
        return f.my === 1;
      }
      if (isSharedToMe) {
        return f.my === 0;
      }
      return f;
    });
  }, [files, isSharedByMe, isSharedToMe]);

  useEffect(() => {
    if (dir) {
      onResetFilesList();
      onLoadSharedFiles(dir, { error: __("Не удалось получить список файлов, попробуйте еще раз") });
      return;
    }
    onResetFilesList();
    onSendFolderPath([]);
    setSelectFolder(initialFolderState());

    onLoadSharedFiles(undefined, { error: __("Не удалось получить список файлов, попробуйте еще раз") });
  }, [dir, group, sort, sort_reverse, color, emo, figure, tags.length]); //eslint-disable-line

  const renderItemsViewType = (files: FilesNextArrayType): JSX.Element => {
    switch (view) {
      case ViewType.BARS:
        return !isFilterByDateCreated ? <BarsView items={files} /> : <ItemsDate files={files} />;

      case ViewType.LINES:
        return !isFilterByDateCreated ? <LinesView items={files} /> : <ItemsDate files={files} />;

      case ViewType.BARS_PREVIEW:
        return <BarsPreviewView items={files} />;
    }
  };

  const renderMyFiles = (): JSX.Element => {
    if (isLinesPreviewView) {
      return <LinesPreviewView />;
    }

    if (Object.values(filtredItems).flat(1).length === 0) {
      return isFilters ? (
        <Clue icon="sad" text={__("Нет файлов соответствующих параметрам фильтрации")} />
      ) : (
        <Clue icon="neutral" text={__("Тут пока пусто")} />
      );
    } else {
      return renderItemsViewType(filtredItems);
    }
  };

  return (
    <div
      className={classNames(styles.root, `scrollbar-medium-${theme}`, {
        [styles.marginTop]: isBarsView || isLinesView
      })}
    >
      {loader && page === 1 && !isLinesPreviewView ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <>
          {renderMyFiles()}
          {!isLinesPreviewView && !isBarsPreviewView && <FilesObserver />}
        </>
      )}
    </div>
  );
};

export default SharedList;
