import { LibraryMainWorkSpace } from "containers/Library/LibraryMainWorkSpace/LibraryMainWorkSpace";
import { LibraryServicePanel } from "containers/Library/LibraryServicePanel/LibraryServicePanel";
import { ROUTES } from "generalComponents/variables/routing";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useCurrentPath } from "hooks/useCurrentPath";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

export const LibraryPage = () => {
  const navigate = useNavigate();
  const { rights } = useUserSelectors();

  const isLibrary = useCurrentPath(ROUTES.LIBRARY);

  useEffect(() => {
    if (!rights.IS_LIBRARY) {
      navigate("/", { replace: true });
      return;
    }
  }, []); //eslint-disable-line

  return (
    <WorkAreaWrap>
      <LibraryServicePanel />
      {!isLibrary ? <Outlet /> : <LibraryMainWorkSpace />}
    </WorkAreaWrap>
  );
};
