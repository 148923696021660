import { PanelPersonalization } from "containers/Profile/Settings/components/PanelPersonalization/PanelPersonalization";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { WorkSpaceWrap } from "generalComponents/WorkSpaceWrap/WorkSpaceWrap";

import styles from "./SettingsPage.module.sass";

export const SettingsPage = () => {
  return (
    <WorkAreaWrap className={styles.workArea}>
      <ToolsPanel size="large" />
      <WorkSpaceWrap>
        <PanelPersonalization />
      </WorkSpaceWrap>
    </WorkAreaWrap>
  );
};
