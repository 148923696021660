import api from "api";
import { ReactComponent as AnswerIcon } from "assets/icons/answer.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import classNames from "classnames";
import TaskFile from "containers/JoinProject/TaskFile/TaskFile";
import TaskUploadFile from "containers/JoinProject/TaskUploadFile/TaskUploadFile";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { ITaskComment } from "models/store/joinProjects/joinProgects";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import TaskCommentEditor from "../TaskCommentEditor/TaskCommentEditor";
import styles from "./CommentItem.module.sass";

interface IProps {
  comment: ITaskComment;
  setComments: Dispatch<SetStateAction<ITaskComment[]>>;
  editItem: string;
  setEditItem: (v: string) => void;
  taskId: string;
  setAnswerUser: (v: string) => void;
}

const CommentItem: FC<IProps> = ({ comment, setComments, editItem, setEditItem, taskId, setAnswerUser }) => {
  const { __ } = useLocales();
  const { id } = useParams();
  const { onSetTopMessageModal, onSetApproveModal } = useActions();

  const { employees, userInfo, theme, uid } = useUserSelectors();
  const { project } = useJoinProjectsSelectors();
  const [uploadFiles, setUploadFiles] = useState<(IFile | { file: File; fid: string })[]>([]);
  const [files, setFiles] = useState<IFile[]>(comment.files);
  const [files4hub, setFiles4hub] = useState<IFile[]>(comment.files_4hub);
  const [loading, setIsLoading] = useState<boolean>(false);

  const author = [...employees, userInfo].find((u) => u.id === comment.id_user);
  const { formatDate } = useDateFormat();
  const { fetchFileZipLink } = useActions();

  const findUsersIds = (text: string) => {
    const valuesArray: string[] = text.match(/(?:\s|>)@(\S+?)(?=\s|<|$)/g) || [];
    const values = valuesArray.map((v) => v.slice(2));
    const ids = project.participants.filter((el) => {
      const search = el.fname && el.sname ? `${el.fname}_${el.sname}` : `${el.fname}` || `${el.sname}`;
      return values.includes(search);
    });
    return ids;
  };

  const onUpdate = async (text: string) => {
    console.log(findUsersIds(text)); // массив участков о которых есть упоминания

    try {
      setIsLoading(true);
      const newFids = uploadFiles.filter((f) => (f as IFile)?.id_dir).map((f) => f.fid);
      const prevFids = files4hub.map((f) => f.fid);
      const fids = [...newFids, ...prevFids];
      const myFiles = uploadFiles.filter((f) => !(f as IFile)?.id_dir).map((f) => f.file);
      const removedFiles: string[] = comment.files.reduce((acc, el) => {
        const serch = files.find((f) => f.fid === el.fid);
        return !serch ? [...acc, el.fid] : acc;
      }, []);
      if (removedFiles.length > 0) {
        await Promise.all(
          removedFiles.map(async (fid) => {
            const params = { uid, id_com: comment.id, fid };
            await api.get(`/ajax/task_com_file_del.php`, { params });
          })
        );
      }
      const formData = new FormData();
      formData.append("uid", uid);
      formData.append("id_item", comment.id);
      formData.append("id_task", taskId);
      formData.append("text", text);
      formData.append("id_project", id);
      fids.length && formData.append("fids", JSON.stringify(fids));
      myFiles.length && myFiles.forEach((file) => formData.append("myfile[]", file));

      const { data } = await api.post(`/ajax/task_com_edit.php`, formData);
      if (data.ok) {
        setEditItem("");
        setUploadFiles([]);
        setComments((prev) => prev.map((el) => (el.id === comment.id ? data.comment : el)));
        onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: __("Сохранено") });
      }
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      const params = { uid, id_item: comment.id, id_task: taskId };
      const { data } = await api.get(`/ajax/task_com_del.php`, { params });
      if (data.ok) {
        setComments((comments) => comments.filter((i) => i.id !== comment.id));
        onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: __("Удалено") });
      }
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  const onApproveDel = () => {
    const params = {
      titleHead: __(__("Удалить")),
      title: `${__("Вы действительно хотите удалить комментарий")} `,
      approveBtn: __("Удалить"),
      approveBtnVariant: ButtonVariantType.BLUE,
      callback: () => onDelete()
    };
    onSetApproveModal({ open: true, params });
  };

  const deleteFile = async (fid: string) => setFiles((prev) => prev.filter((item) => item.fid !== fid));
  const deleteFile4Hub = (fid: string) => setFiles4hub((prev) => prev.filter((f) => f.fid !== fid));
  const deleteUploadFile = (fid: string) => setUploadFiles((prev) => prev.filter((item) => item.fid !== fid));

  if (editItem === comment.id) {
    return (
      <div className={styles.block}>
        <Avatar name={author.sname + " " + author.sname} imgSrc={projectSrc + author.icon} size={32} />
        <div className={styles.comment}>
          <TaskCommentEditor
            prim={comment.text}
            setUploadFiles={setUploadFiles}
            onSend={onUpdate}
            setEditItem={setEditItem}
          />
          <ul className={classNames(styles.fileList, `scrollbar-thin-${theme}`)}>
            {files.map((file) => (
              <TaskFile key={file.fid} file={file} deleteFile={deleteFile} />
            ))}
            {files4hub.map((file) => (
              <TaskFile key={file.fid} file={file} deleteFile={deleteFile4Hub} />
            ))}
            {uploadFiles.length > 0 &&
              uploadFiles.map((file) =>
                "id_dir" in file ? (
                  <TaskFile key={file.fid} file={file} deleteFile={deleteUploadFile} />
                ) : (
                  <TaskUploadFile key={file.fid} file={file} deleteFile={deleteUploadFile} />
                )
              )}
          </ul>
          {loading && (
            <div className={styles.loaderBox}>
              <Loader
                type={LoaderTypes.BOUNCING_DOTS}
                position="absolute"
                background="transparent"
                zIndex={10}
                width="200px"
                height="200px"
                containerType="bounceDots"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.block}>
      <Avatar name={author.fname + " " + author.sname} imgSrc={projectSrc + author.icon} size={32} />
      <div className={styles.comment}>
        <div className={styles.title}>
          {author.fname + " " + author.sname}&nbsp;
          <span className={styles.date}>{formatDate(comment.date, "full")}</span>
        </div>
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: comment.text }} />

        <ul>
          <ul className={classNames(styles.fileList, `scrollbar-thin-${theme}`)}>
            {comment?.files?.map((file) => (
              <TaskFile key={file.fid} file={file} downloadFile={fetchFileZipLink} />
            ))}
            {comment?.files_4hub?.map((file) => (
              <TaskFile key={file.fid} file={file} downloadFile={fetchFileZipLink} />
            ))}
          </ul>
        </ul>
        <div className={styles.actions}>
          <Button
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.EXTRA_SMALL}
            iconL={<AnswerIcon />}
            onClick={() => {
              setAnswerUser(`@${author.fname + "_" + author.sname}`);
              setEditItem("0");
            }}
            isSquare
          />
          <Button
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.EXTRA_SMALL}
            iconL={<EditIcon />}
            onClick={() => setEditItem(comment.id)}
            isSquare
          />
          <Button
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.EXTRA_SMALL}
            iconL={<TrashIcon />}
            onClick={onApproveDel}
            isSquare
          />
        </div>
      </div>
    </div>
  );
};

export default CommentItem;
