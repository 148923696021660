import { SIGNS } from "generalComponents/variables/elements";

export interface IFilterCriteriaState {
  color: string;
  emo: string;
  figure: SIGNS;
  tags: string[];
}

export function initialFilterCriteriaState(): IFilterCriteriaState {
  return {
    color: "",
    emo: "",
    figure: SIGNS.NO_SIGN,
    tags: []
  };
}
