import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export const useArchivedFilesSelectors = () => {
  const ArchivedFiles = useTypeSelector((s) => s.Archive);
  const elements = ArchivedFiles.elements;
  const total = ArchivedFiles.total;
  const page = ArchivedFiles.page;
  const loader = ArchivedFiles.loader;

  return {
    elements,
    total,
    page,
    loader
  };
};
