import { ReactComponent as AddIcon } from "assets/PrivateCabinet/add-2.svg";
import { ReactComponent as TaskIcon } from "assets/PrivateCabinet/calendar-4.svg";
import { ReactComponent as CollapseIcon } from "assets/PrivateCabinet/collapse.svg";
import { ReactComponent as ExpandIcon } from "assets/PrivateCabinet/frame.svg";
import { ELEMENTS } from "collections/tasks";
import OverdueTasks from "generalComponents/OverdueTasks/OverdueTasks";
import TabsPicker from "generalComponents/TabsPicker/TabsPicker";
import {
  STATUS_TYPES,
  TASK_MODALS,
  TASK_TYPES,
  TASK_VIEW,
  TaskFilters,
  TASKS_SCHEMA
} from "generalComponents/variables/tasks";
import { useOverdueTasks, useTasks } from "hooks/tasksHooks";
import { useActions } from "hooks/useActions";
import { IMyTask, TASK_DEP } from "models/store/tasks/tasks";
import TaskLine from "Pages/Cabinet/Components/Tasks/Components/TaskLine/TaskLine";
import React, { useContext, useState } from "react";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";
import { groupByDay, groupByMonth, groupByWeek, groupByYear, INITIAL_ADD_TASK } from "utils/tasks";

import AnaliticsPanel from "../../../Pages/Cabinet/Components/Tasks/Components/AnaliticsPanel/AnaliticsPanel";
import { MyTasksProvider } from "../GridBoard/GridBoard";
import TasksKanban from "../TasksKanban/TasksKanban";
import TasksLine from "../TasksLine/TasksLine";
import styles from "./MyTasks.module.sass";

const MyTasks: React.FC = (): JSX.Element => {
  const { __ } = useLocales();
  const { schema, setSchema } = useContext(MyTasksProvider);
  const [searchParams] = useSearchParams();
  const { overdueTasks } = useOverdueTasks();
  const isOverdue = searchParams.get("overdue");
  const { onSetTaskModal } = useActions();
  const { tasks } = useTasks();
  const { filters } = useTasksSelectors();
  const [tabSelect, setTabSelect] = useState<TASK_VIEW>(TASK_VIEW.LINE);

  const onExpand = (): void => {
    setSchema((prev) =>
      prev === TASKS_SCHEMA.EXPANDED_TASKS_BOARD ? TASKS_SCHEMA.GRID_BAR : TASKS_SCHEMA.EXPANDED_TASKS_BOARD
    );
  };

  const getGroup = (): Record<string, IMyTask[]> => {
    switch (filters.type) {
      case TaskFilters.BY_DAY:
        return groupByDay(tasks, filters.date);
      case TaskFilters.BY_WEEK:
        return groupByWeek(tasks, filters.date);
      case TaskFilters.BY_MONTH:
        return groupByMonth(tasks, filters.date);
      case TaskFilters.BY_YEAR:
        return groupByYear(tasks, filters.date);

      default:
        break;
    }
  };

  const onAddTask = (): void => {
    onSetTaskModal({
      type: TASK_MODALS.ADD_TASK,
      params: { ...INITIAL_ADD_TASK, id_type: TASK_TYPES.TASK, id_dep: TASK_DEP.NO_DEP, id_status: STATUS_TYPES.QUEUE }
    });
  };

  return (
    <div className={styles.myTasksWrap}>
      <div className={styles.panel}>
        <div className={styles.block}>
          <AddIcon onClick={onAddTask} />
          <span>{__("Задачи")}</span>
          <TaskIcon />
        </div>
        <div className={styles.block}>
          <OverdueTasks />
          <TabsPicker ELEMENTS={ELEMENTS} selected={tabSelect} onClick={setTabSelect} />
          <button className={styles.expandIcon} onClick={onExpand}>
            {schema === TASKS_SCHEMA.EXPANDED_TASKS_BOARD ? <CollapseIcon /> : <ExpandIcon />}
          </button>
        </div>
      </div>
      {!isOverdue && <AnaliticsPanel tasks={getGroup()} />}
      {isOverdue ? (
        overdueTasks.map((t, i) => <TaskLine key={t.id} task={t} num={i + 1} />)
      ) : (
        // <></>
        <>
          {tabSelect === TASK_VIEW.LINE && <TasksLine tasks={getGroup()} />}
          {tabSelect === TASK_VIEW.KANBAN && <TasksKanban tasks={getGroup()} />}
        </>
      )}
    </div>
  );
};

export default MyTasks;
