import { JointProjectLinksGrid } from "containers/JoinProject/JointProjectLinks/JointProjectLinksIGrid/JointProjectLinksGrid";
import { JointProjectLinksPanel } from "containers/JoinProject/JointProjectLinks/JointProjectLinksPanel/JointProjectLinksPanel";
import { WrapperPage } from "containers/JoinProject/WrapperPage/WrapperPage";

const ProjectLinksPage = () => {
  return (
    <WrapperPage>
      <JointProjectLinksPanel />
      <JointProjectLinksGrid />
    </WrapperPage>
  );
};

export default ProjectLinksPage;
