import Button from "generalComponents/Button/Button";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import PopUp from "generalComponents/PopUp/PopUp";
import SelectChosen from "generalComponents/SelectChosen/SelectChosen";
import { SAFE_CALLBACK_MODAL, SAFE_MODALS } from "generalComponents/variables/safe";
import { useActions } from "hooks/useActions";
import md5 from "md5";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialMoveToSafeModalState } from "models/store/Cabinet/modals/modals";
import { ISafe } from "models/store/safe/safe";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useSafeSelectors } from "Store/selectors/safeSelectors";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./MoveToSafe.module.sass";

const MoveToSafe = () => {
  const { __ } = useLocales();
  const { onSetMoveToSafeModal, onMoveToSafeFile, onSetSafeModal } = useActions();
  const { value, onChange } = useInputValue();
  const {
    moveToSafe: { file, safe }
  } = useGlobalModalsSelectors();
  const { safeList } = useSafeSelectors();
  const [selectedSafe, setSelectedSafe] = useState<ISafe>(safe ?? safeList[0]);

  const closeModal = (): void => {
    onSetMoveToSafeModal(initialMoveToSafeModalState());
  };

  const onSelect = (value: any) => {
    setSelectedSafe(value);
  };
  const onCreateSafe = (): void => {
    onSetSafeModal({ open: true, type: SAFE_MODALS.ADD_SAFE, callbackType: SAFE_CALLBACK_MODAL.MOVE_TO_SAFE });
    onSetMoveToSafeModal({ open: false, file });
  };
  const getDisabled = (): boolean => {
    return !selectedSafe || selectedSafe.pass !== md5(value);
  };
  const onSubmit = (): void => {
    const options = {
      tags: file.tags,
      color: file.color,
      emo: file.emo
    };
    onMoveToSafeFile(file.fid, options, selectedSafe, file);
  };

  return (
    <PopUp set={closeModal}>
      <div className={styles.wrap}>
        <HeaderModal onClose={closeModal} title={__("Переместить в Сейф")} />
        <div className={styles.body}>
          <SelectChosen value={selectedSafe.name}>
            <ul className={styles.list}>
              {safeList.map((el) => (
                <li className={styles.item} key={el.id} onClick={() => onSelect(el)}>
                  {el.name}
                </li>
              ))}
            </ul>
          </SelectChosen>
          <Input value={value} onChange={onChange} label={__("Пароль")} isPass />
        </div>
        <div className={styles.btns}>
          <Button
            onClick={onCreateSafe}
            variant={ButtonVariantType.EXRTA_LIGHT}
            text={__("Создать Сейф")}
            size={ButtonSizeType.LARGE}
          />
          <div>
            <Button
              onClick={closeModal}
              variant={ButtonVariantType.EXRTA_LIGHT}
              text={__("Отменить")}
              size={ButtonSizeType.LARGE}
            />
            <Button
              onClick={onSubmit}
              variant={ButtonVariantType.BLUE}
              text={__("Переместить")}
              size={ButtonSizeType.LARGE}
              disabled={getDisabled()}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default MoveToSafe;
