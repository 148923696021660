import { ReactComponent as PhotoIcon } from "assets/PrivateCabinet/photo-camera.svg";
import Button from "generalComponents/Button/Button";
import Emoji from "generalComponents/Elements/Emoji";
import Signs from "generalComponents/Elements/Signs";
import InputField from "generalComponents/InputField";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { useProjectMsg } from "Pages/Cabinet/Components/Project/helpers";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch } from "react-redux";
import { onEditProject } from "Store/actions/JoinProjectActions";
import { onSetModals } from "Store/actions/ModalActions";
import { onAddProject } from "Store/actions/ProjectActions";
import { projectProps } from "types/Project";
import { dataURLToFile } from "utils/filesConvert";

import styles from "./AddProject.module.sass";

const AddProject = ({ onClose, params, type }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const MSG = useProjectMsg();
  const [name, setName] = useState(params.name ?? "");
  const [tags] = useState(params.tags ?? "");
  const [id_fig, setId_fig] = useState(params.id_fig ?? "");
  const [id_emo, setId_emo] = useState(params.id_emo ?? "");

  const addAvatar = () =>
    dispatch(
      onSetModals(MODALS.PROJECT, {
        type: PROJECT_MODALS.AVATAR_WAY,
        callback: type,
        params: { ...params, name, tags, id_fig, id_emo }
      })
    );

  const addProject = () => {
    const icon = params.project_icon
      ? params.project_icon.startsWith("/upload")
        ? params.project_icon
        : dataURLToFile(params.project_icon)
      : "";
    const payload = {
      name,
      tags: tags ?? "",
      id_fig: id_fig ?? "",
      id_emo: id_emo ?? "",
      icon: icon,
      id: params.id,
      roles: params.roles ? params.roles : "",
      tasks_category_sort: params.tasks_category_sort
    };

    const messages = {
      error: MSG.ERROR,
      success: type === PROJECT_MODALS.ADD_PROJECT ? MSG[PROJECT_MODALS.ADD_PROJECT] : MSG[PROJECT_MODALS.EDIT_PROJECT]
    };
    type === PROJECT_MODALS.ADD_PROJECT
      ? dispatch(onAddProject(payload, messages))
      : dispatch(onEditProject(payload, messages, PROJECT_MODALS.PROJECT_SUCCESS));
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.avatarBox}>
        <div className={styles.avatar}>
          {params.project_icon ? <img src={params.project_icon} alt="logo" className={styles.image} /> : <PhotoIcon />}
        </div>
        <p className={styles.paceholder}>{__("Добавьте аватар проекта")}</p>
        <Button type="button" variant="add" text={__("Загрузить")} onClick={addAvatar} />
      </div>
      <div className={styles.fields}>
        <InputField placeholder={__("Имя проекта")} value={name} set={setName} />
        <Signs sign={id_fig} title={__("Добавьте значок проекта")} setSign={setId_fig} />
        <Emoji emoji={id_emo} setEmoji={setId_emo} />
      </div>
      <div className={styles.btns}>
        <Button type="button" variant="cancel" text={__("Отмена")} onClick={onClose} />
        <Button
          type="button"
          variant="ok"
          text={type === PROJECT_MODALS.ADD_PROJECT ? __("Добавить") : __("Сохранить")}
          onClick={addProject}
          disabled={name.trim().length === 0}
        />
      </div>
    </div>
  );
};

export default AddProject;

AddProject.propTypes = {
  onClose: PropTypes.func,
  params: projectProps,
  type: PropTypes.string
};
