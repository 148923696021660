import { FC } from "react";

import styles from "./PdfView.module.sass";

interface IPdfViewProprs {
  src: string;
}

export const PdfView: FC<IPdfViewProprs> = ({ src }) => (
  <div className={styles.pdfContainer}>
    <embed className={styles.embed} src={src}></embed>
  </div>
);
