import Button from "generalComponents/Button/Button";
import { ChooseFolder } from "generalComponents/ChooseFolder/ChooseFolder";
import CombinedElements from "generalComponents/Elements/CombinedElements";
import Input from "generalComponents/Input/Input";
import { ItemInfoDynamic } from "generalComponents/ItemInfoDynamic/ItemInfoDynamic";
import PopUp from "generalComponents/PopUp/PopUp";
import { useCurrentFolder } from "generalComponents/Services/folderServices/folderServices";
import { calculateSize, splitFilename } from "generalComponents/Services/helpers";
import { Times } from "generalComponents/Times/Times";
import { PAGE_DEPTH } from "generalComponents/variables/global";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { PROJECT_PATH, ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ButtonVariantType } from "models/generalComponents/button";
import { DEFAULT_FOLDERS } from "models/generalComponents/folders";
import { IFolder } from "models/store/folders/foldersStore";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { v4 as uuid } from "uuid";

import styles from "./UploadFiles.module.sass";

const UploadFiles: React.FC = (): JSX.Element => {
  const { __ } = useLocales();

  const { id } = useParams();

  const {
    uploadFiles: { selectedFiles }
  } = useFilesSelectors();
  const { project } = useJoinProjectsSelectors();
  const projectSelectedFiles = project?.uploadFiles?.selectedFiles;

  const currentFolder = useCurrentFolder();
  const { onLoadingFiles, onSelectedFiles, onSetProjectLoadingDocFiles, onSetProjectSelectedFiles } = useActions();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const isProject = useCurrentPathStarstWith(ROUTES.PROJECT);
  const isProjectDoc = useCurrentPathStarstWith(`${ROUTES.PROJECT}/${id}/${PROJECT_PATH.DOC}`);
  const isProjectFiles = useCurrentPathStarstWith(`${ROUTES.PROJECT}/${id}/${PROJECT_PATH.FILES}`);
  const { safeDir } = useParams();

  const { folders } = useFoldersSelectors();
  const defaultFolder = folders.find((el) => el.name === DEFAULT_FOLDERS.ALL);

  const [name, setName] = useState<string>(
    selectedFiles.length === 1
      ? splitFilename(selectedFiles[0].file.name).name
      : projectSelectedFiles?.length === 1
      ? splitFilename(projectSelectedFiles[0].file.name).name
      : NO_VALUE
  );
  const [tags, setTags] = useState<string[]>([]);
  const [emo, setEmo] = useState<string>("");
  const [color, setColor] = useState<string>();
  const [folder, setFolder] = useState<IFolder>(currentFolder ?? defaultFolder);

  const onCloseTab = (): void => {
    if (isProject) {
      onSetProjectSelectedFiles([]);
    } else {
      onSelectedFiles([]);
    }
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => setName(e.target.value);
  const totalSize = (): number =>
    isProject
      ? projectSelectedFiles.reduce((acc, file) => acc + file.file.size, 0)
      : selectedFiles.reduce((acc, file) => acc + file.file.size, 0);

  const getDisabled = (): boolean => {
    if (isSafePage) {
      return selectedFiles.length === 1 ? !name : false;
    }

    if (isProject) {
      return Boolean(!folder?.id_dir) || projectSelectedFiles?.length === 1 ? !name : false;
    }

    return Boolean(!folder?.id_dir) || selectedFiles.length === 1 ? !name : false;
  };

  const onAddFile = (): void => {
    const safe_dir = safeDir ?? "0";
    if (isProject) {
      !!projectSelectedFiles?.length &&
        projectSelectedFiles.forEach((file) => {
          onSetProjectLoadingDocFiles({
            ...file,
            options: {
              name: name ? name : file.file.name,
              tags,
              emo,
              color,
              id_dir: folder?.id_dir,
              ext: splitFilename(file.file.name).format,
              dep: isProjectDoc ? PAGE_DEPTH.PROJECT_DOC : PAGE_DEPTH.PROJECT_FILES,
              chapter: folder?.chapter ? folder?.chapter : undefined
            },
            time: 0,
            percent: 0,
            id: uuid(),
            done: false,
            create: false,
            replace: false
          });
        });
    } else {
      selectedFiles.forEach((file) => {
        onLoadingFiles({
          ...file,
          options: {
            name: name ? name : file.file.name,
            tags,
            emo,
            color,
            id_dir: isSafePage ? safe_dir : folder?.id_dir,
            ext: splitFilename(file.file.name).format
          },
          time: 0,
          percent: 0,
          id: uuid(),
          done: false,
          create: false,
          replace: false
        });
      });
    }
    onCloseTab();
  };

  return (
    <PopUp set={onCloseTab}>
      <div className={styles.createFolderWrap}>
        <Times handleClick={onCloseTab} />
        <span className={styles.title}>{__("Добавление файлов")}</span>
        <ItemInfoDynamic
          setColor={setColor}
          setEmo={setEmo}
          color={color}
          passIcon={false}
          ext={
            selectedFiles.length === 1
              ? splitFilename(selectedFiles[0].file.name).format
              : projectSelectedFiles?.length === 1
              ? splitFilename(projectSelectedFiles[0].file.name).format
              : "FILES"
          }
          name={name}
          size={calculateSize(totalSize())}
          tags={tags}
          emo={emo}
          isFolder={false}
          itemsLength={selectedFiles.length || projectSelectedFiles?.length}
        />

        <div className={styles.inputFieldsWrap}>
          {(selectedFiles.length === 1 || projectSelectedFiles?.length === 1) && (
            <Input type="text" placeholder={__("Имя файла")} value={name} onChange={onChangeName} />
          )}
          {!isSafePage && !isProjectFiles && (
            <ChooseFolder
              onSelect={(folder) => setFolder(folder)}
              folder={folder}
              folders={isProjectDoc ? project?.folders_doc : folders}
            />
          )}
          {!isProject && (
            <CombinedElements
              color={color}
              setColor={setColor}
              emo={emo}
              setEmo={setEmo}
              tags={tags}
              onSelectTags={setTags}
            />
          )}
        </div>
        <div className={styles.buttonsWrap}>
          <Button type="button" variant={ButtonVariantType.CANCEL} text={__("Отмена")} onClick={onCloseTab} />
          <Button
            type="button"
            variant={ButtonVariantType.OK}
            text={__("Добавить")}
            onClick={onAddFile}
            disabled={getDisabled()}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default UploadFiles;
