import classNames from "classnames";
import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import { LoaderTypes } from "models/generalComponents/loader";
import React from "react";
import { useLocales } from "react-localized";

import { Agents, IAccount } from "../PostboxSettings";
import SessionsItem from "../SessionsItem/SessionsItem";
import styles from "./Sessions.module.sass";

interface ISessionsProps {
  agents: Agents;
  loading: boolean;
  onRemoveItem: (email: string, id: string, val: number) => void;
  blocked: boolean;
  error: string;
}

const Sessions: React.FC<ISessionsProps> = ({ agents, loading, onRemoveItem, blocked, error }) => {
  const { __ } = useLocales();
  const getFiltredItems = (items: IAccount[]) => {
    return items.filter((el) => (blocked ? el.is_block : !el.is_block));
  };

  const isEmptyList = (): boolean => {
    if (!agents) return true;
    const arr = Object.values(agents).flat();
    return getFiltredItems(arr).length === 0;
  };

  if (loading) {
    return (
      <div className={styles.box}>
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      </div>
    );
  }

  if (isEmptyList()) {
    return (
      <div className={styles.box}>
        <Clue text={__("Список пуст")} icon="neutral" />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.box}>
        <Clue text={error} icon="sad" />
      </div>
    );
  }

  return (
    <ul className={classNames(styles.listAccount, "scrollbar-thin-blue")}>
      {agents &&
        Object.entries(agents).map(
          ([email, items]) =>
            getFiltredItems(items).length > 0 && (
              <li key={email}>
                <p className={styles.email}>{email}</p>
                <ul>
                  {getFiltredItems(items).map((item) => (
                    <SessionsItem key={item.id} item={item} onRemoveItem={onRemoveItem} blocked={blocked} />
                  ))}
                </ul>
              </li>
            )
        )}
    </ul>
  );
};

export default Sessions;
