import Button from "generalComponents/Button/Button";
import { usePostboxFolder } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import { ILetterDraft, initialMailNewMessageModalState } from "models/store/Cabinet/modals/postboxModal";
import { IPostbox } from "models/store/postbox/postbox";
import React, { useState } from "react";
import { useLocales } from "react-localized";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";

import HeaderModal from "../../HeaderModal/HeaderModal";
import SelectMail from "../MailNewMessage/SelectMail/SelectMail";
import styles from "./Draft.module.sass";

interface IDraftProps {
  closeModal: () => void;
  draft: ILetterDraft;
}

const Draft: React.FC<IDraftProps> = ({ closeModal, draft }) => {
  const { __ } = useLocales();
  const { onMailNewMessageModal, onLetterToDraft } = useActions();
  const { postboxes } = usePostboxSelectors();
  const { _isDraft } = usePostboxFolder();

  const [from, setFrom] = useState<IPostbox>(draft.from ? draft.from : postboxes.length === 1 ? postboxes[0] : null);

  const onClose = () => {
    closeModal();
    onMailNewMessageModal(initialMailNewMessageModalState());
  };

  const onSave = async (): Promise<void> => {
    const formData = new FormData();
    draft.files.forEach((file) => formData.append("files[]", file));
    if (draft.prevFiles) {
      draft.prevFiles.forEach((file) => formData.append("email_files[]", file.filename));
    }
    formData.append("user", from?.email);
    formData.append("pass", from?.pass);
    formData.append("server", from?.server);
    formData.append("from_name", from?.name || "");
    formData.append("from", from?.email);
    formData.append("subject", draft.subject);
    formData.append("to", draft.to);
    formData.append("template", draft.html);
    formData.append("folder", POSTBOX_SYSTEM_FOLDER.Drafts);
    if (draft.uid) {
      formData.append("email_uid", String(draft.uid));
    }
    const messages = {
      error: __("Не удалось сохранить письмо в черновиках. Попробуйте еще раз!"),
      success: __("Письмо сохранено в черновиках!")
    };
    const isUpdate = _isDraft;
    onLetterToDraft(formData, messages, onClose, isUpdate);
  };

  return (
    <div className={styles.wrap}>
      <HeaderModal onClose={onClose} title={__("Закрыть")} />
      <div className={styles.body}>
        <p className={styles.text}>
          {draft.from
            ? __("Cохранения в черновик?")
            : __("Для сохранения в черновик выберите почтовый аккаунт отправителя")}
        </p>
        {!draft.from && (
          <div className={styles.select}>
            <SelectMail value={from} onSelect={setFrom} />
          </div>
        )}
        <div className={styles.btns}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("Не сохранять")}
            onClick={onClose}
          />
          <div>
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Отмена")}
              onClick={closeModal}
            />
            <Button
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.SMALL}
              text={__("В черновик")}
              onClick={onSave}
              disabled={!from}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Draft;
