import { IFile } from "models/store/files/files";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import { JointProjectsFile } from "./components/JointProjectsFile/JointProjectsFile";
import styles from "./JointProjectDocumentationGrid.module.sass";

export const JointProjectDocumentationGrid = () => {
  const { project } = useJoinProjectsSelectors();
  const projectDocFiles = project.documentation?.files;

  return (
    <div className={styles.content}>
      {!!projectDocFiles?.length &&
        projectDocFiles.map((item) => <JointProjectsFile key={(item as IFile)?.fid || item?.id_dir} item={item} />)}
    </div>
  );
};
