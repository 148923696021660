import "pdfjs-dist/build/pdf.worker.entry";

import { Image } from "generalComponents/Image/Image";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { FC, useMemo, useState } from "react";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface IPreviewUploadImgPdfProps {
  pdfUrl: string;
  fileName: string;
}

export const PreviewUploadImgPdf: FC<IPreviewUploadImgPdfProps> = ({ pdfUrl, fileName }): JSX.Element => {
  const [error, setError] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  const urlUploader = (url: string): void => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = window.atob(e.target.result.toString().replace(/.*base64,/, ""));
          renderPage(data);
        };
        reader.readAsDataURL(blob);
      });
    });
  };

  useMemo(() => {
    urlUploader(pdfUrl);
  }, []); //eslint-disable-line

  const renderPage = async (data: string): Promise<any> => {
    const canvas = document.createElement("canvas");

    try {
      const pdf = await pdfjs.getDocument({ data }).promise;

      const page = await pdf.getPage(1);

      const viewport = page.getViewport({ scale: 1 });

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport
      };

      await page.render(render_context).promise;

      const img = canvas.toDataURL("image/jpg");

      setImageUrl(img);
    } catch {
      setError(true);
    }
  };

  if (error) {
    <ItemIcon isFolder={false} width={33} height={44} color={"#E5252A"} ext={"PDF"} />;
  }

  return !error && imageUrl && <Image src={imageUrl} alt={fileName} />;
};
