import { EVENT_TYPE } from "generalComponents/variables/global";
import { FilesActions } from "models/store/files/filesActions";
import { FoldersAction } from "models/store/folders/foldersActions";
import { ICreateFolderPayload, IEditFolderPayload } from "models/store/folders/foldersPayloads";
import { IFolder } from "models/store/folders/foldersStore";
import { JoinProjectActions } from "models/store/joinProjects/joinProjectsActions";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";

import { CabinetActions } from "../Cabinet";

type ThunkActionType = ThunkAction<
  void,
  RootState,
  unknown,
  FoldersAction | FilesActions | CabinetActions | JoinProjectActions
>;

export interface ICreateFolderModal {
  open: boolean;
  type: "" | "add" | "edit";
  folder?: IFolder;
  folders?: IFolder[];
  onCreateFolder?: (payload: ICreateFolderPayload) => ThunkActionType;
  onEditFolder?: (
    payload: IEditFolderPayload,
    typeMessage: EVENT_TYPE,
    callback?: (folder?: IFolder) => void
  ) => ThunkActionType;
  onSetUpdate?: (folder?: IFolder) => void;
}

export function initialCreateFolderState(): ICreateFolderModal {
  return { open: false, type: "", folder: null, folders: null, onCreateFolder: null, onEditFolder: null };
}
