import { EMOJIS, SIGNS } from "generalComponents/variables/elements";
import { PAGE_DEPTH, ViewType } from "generalComponents/variables/global";
import { initialSortState } from "models/store/files/sort/sort";
import { SizeTypes } from "models/store/files/sort/sortPayloads";
import { IFolder } from "models/store/folders/foldersStore";
import { TypeElements } from "models/typeElements/typeElements";

import { IInitialsUploadFiles, initialUploadFiles } from "./uploadFilesModals/uploadFiles";

export interface IFile {
  is_dir: number;
  id_dir: string;
  id_safe?: string;
  file: string;
  nameindb: string;
  name: string;
  tag: string;
  tag2: string;
  fid: string;
  is_del: string | number;
  date: number;
  adate: boolean;
  otime: string;
  deadline: number;
  deny_edit: string;
  size: number;
  ext: string;
  fname: string;
  atime?: string;
  cdate?: string;
  ctime: string;
  dtime?: string;
  mtime: string;
  date_archive: string;
  mtime_now: string;
  size_now: string;
  file_link: string;
  mime_type: string;
  is_preview: number;
  preview: string;
  gdir: string;
  fdir: string;
  is_archive: number;
  color: string;
  emo: EMOJIS;
  fig: SIGNS;
  tags?: string[];
  is_pass: number;
  edit_url: string;
  edit_url2: string;
  is_favorite: number;
  my?: number;
  preview_image?: string;
  user_name?: string;
  user_sname?: string;
  user_icon?: string[];
  dep?: PAGE_DEPTH;
  table?: "" | TypeElements;
  chapter?: string;
  id_project?: string;
}

export interface IRecentFile {
  file: string;
  name: string;
  tag: string;
  fid: string;
  date: number;
  deadline: number;
  deny_edit: string;
  size: number;
  ext: string;
  fname: string;
  atime?: string;
  ctime: string;
  dtime?: string;
  mtime: string;
  size_now: string;
  mime_type: string;
  is_preview: number;
  preview: string;
  id_color: string;
  id_emo: string;
  id_fig: string;
  emo: EMOJIS;
  fig: SIGNS;
  color: boolean;
  edit_url: string;
  edit_url2: string;
}

export type FilesNextArrayType = (IFile | IFolder)[];
export type FilesNextObjectType = Record<number, FilesNextArrayType>;
export type FilesNextType = FilesNextObjectType | FilesNextArrayType;
export interface FilesNext {
  files: FilesNextArrayType;
  total: number;
}
export type FilesLinePreview = Record<string, FilesNext>;

export interface IPickedItems {
  open: boolean;
  items: (IFolder | IFile)[];
  isRightSide?: boolean;
  isLeftSide?: boolean;
}

export interface IFilesState {
  files: FilesNextArrayType;
  filesNext: FilesLinePreview;
  pickedItems: IPickedItems;
  currentItem: IFile | IFolder;
  dragged: IFile;
  size: SizeTypes;
  view: ViewType;
  total: number;
  page: number;
  loader: boolean;
  uploadFiles: IInitialsUploadFiles;
}

export function initialPickedItems(): IPickedItems {
  return {
    open: false,
    items: [],
    isRightSide: false,
    isLeftSide: false
  };
}

export function initialFileState(): IFile {
  return {
    is_dir: 0,
    id_dir: "",
    file: "",
    nameindb: "",
    name: "",
    tag: "",
    tag2: "",
    fid: "",
    is_del: "",
    date: 0,
    adate: false,
    otime: "",
    deadline: 0,
    deny_edit: "",
    size: 0,
    ext: "",
    fname: "",
    ctime: "",
    date_archive: "",
    mtime: "",
    mtime_now: "",
    size_now: "",
    file_link: "",
    mime_type: "",
    is_preview: 0,
    preview: "",
    gdir: "",
    fdir: "",
    is_archive: 0,
    color: "",
    emo: EMOJIS.NO_EMOJI,
    fig: SIGNS.NO_SIGN,
    is_pass: 0,
    edit_url: "",
    edit_url2: "",
    is_favorite: 0
  };
}

export function initialFilesState(): IFilesState {
  return {
    files: null,
    filesNext: null,
    pickedItems: initialPickedItems(),
    currentItem: null,
    total: 0,
    page: 1,
    loader: false,
    uploadFiles: initialUploadFiles(),
    // SORT && FILTER
    ...initialSortState()
  };
}
