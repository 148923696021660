import classNames from "classnames";
import FilesObserver from "generalComponents/FilesObserver/FilesObserver";
import { useIsFilterByDateCreated } from "hooks/useIsFilters";
import { IElementsItemProps } from "models/myFolders/workElements";
import ItemsDate from "Pages/Cabinet/Components/WorkElements/ItemsDate/ItemsDate";
import React from "react";
import { useUserSelectors } from "Store/selectors/userSelectors";

import BarsPreviewList from "./BarsPreviewList/BarsPreviewList";
import styles from "./BarsPreviewView.module.sass";
import PreviewItem from "./PreviewItem/PreviewItem";

const BarsPreviewView: React.FC<IElementsItemProps> = ({ items }): JSX.Element => {
  const { theme } = useUserSelectors();
  const isFilterByDateCreated = useIsFilterByDateCreated();

  return (
    <div className={styles.wrap}>
      <PreviewItem />

      <div className={classNames(styles.caruselContainer, `scrollbar-medium-${theme}`)}>
        <div className={styles.carusel}>
          {!isFilterByDateCreated ? <BarsPreviewList items={items} /> : <ItemsDate files={items} />}
          <FilesObserver />
        </div>
      </div>
    </div>
  );
};

export default BarsPreviewView;
