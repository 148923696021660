export enum ModalTypes {
  SET_MODALS = "SET_MODALS",
  SET_ERROR_MODAL = "SET_ERROR_MODAL",
  SET_SUCCESS_MODAL = "SET_SUCCESS_MODAL",
  SET_NEXT_ACTION_MODAL = "SET_NEXT_ACTION_MODAL",
  SET_TOP_MESSAGE_MODAL = "SET_TOP_MESSAGE_MODAL",
  SET_LOADER_MODAL = "SET_LOADER_MODAL",
  SET_SHARE_MODAL = "SET_SHARE_MODAL",
  SET_PREVIEW_WITH_COMMENTS_MODAL = "SET_PREVIEW_WITH_COMMENTS_MODAL",
  SET_PRINT_SCREEN_MODAL = "SET_PRINT_SCREEN_MODAL",
  SET_PREVIEW_FILE_MODAL = "SET_PREVIEW_FILE_MODAL",
  SET_FILE_ACCESS_RIGHTS_MODAL = "SET_FILE_ACCESS_RIGHTS_MODAL",
  SET_CONTEXT_MENU_MODAL = "SET_CONTEXT_MENU_MODAL",
  SET_TASK_MODAL = "SET_TASK_MODAL",
  SET_LIBRARY_MODAL = "SET_LIBRARY_MODAL",
  SET_CALENDAR_MODAL = "SET_CALENDAR_MODAL",
  SET_PROJECT_MODAL = "SET_PROJECT_MODAL",
  SET_CHAT_MODAL = "SET_CHAT_MODAL",
  SET_CREATE_FOLDER_MODAL = "SET_CREATE_FOLDER_MODAL",
  SET_CREATE_FILE_MODAL = "SET_CREATE_FILE_MODAL",
  SET_APPROVE_MODAL = "SET_APPROVE_MODAL",
  SET_ACCESS_IS_LIMITED_MODAL = "SET_ACCESS_IS_LIMITED_MODAL",
  SET_PROPERTIES_MODAL = "SET_PROPERTIES_MODAL",
  SET_COPY_LINK_MODAL = "SET_COPY_LINK_MODAL",
  SET_EDIT_ITEMS_MODAL = "SET_EDIT_ITEMS_MODAL",
  SET_CUSTOMIZE_FILE_MODAL = "SET_CUSTOMIZE_FILE_MODAL",
  SET_CREATE_ZIP_MODAL = "SET_CREATE_ZIP_MODAL",
  DOWNLOAD_LINK = "DOWNLOAD_LINK",
  FILE_VIEW = "FILE_VIEW",
  FOLDER_NAVIGATION_MODAL = "FOLDER_NAVIGATION_MODAL",
  FILE_PRINT = "FILE_PRINT",
  SHARE_TO_MESSENGERS_MODAL = "SHARE_TO_MESSENGERS_MODAL",
  MAIL_SETTINGS_MODAL = "MAIL_SETTINGS_MODAL",
  MAIL_NEW_MESSAGE_MODAL = "MAIL_NEW_MESSAGE_MODAL",
  SET_SAFE_MODALS = "SET_SAFE_MODALS",
  MOVE_TO_SAFE = "MOVE_TO_SAFE",
  SET_POSTBOX_MODALS = "SET_POSTBOX_MODALS",
  SET_PROPERTIES_ELEMENT_MODALS = "SET_PROPERTIES_ELEMENT_MODALS",
  SET_RESTORE_FILE_MODALS = "SET_RESTORE_FILE_MODALS",
  SET_PROFILE_MODALS = "SET_PROFILE_MODALS",
  SET_CREATE_OBJECT_LIBRARY_MODALS = "SET_CREATE_OBJECT_LIBRARY_MODALS",
  SET_MOVE_ELEMENTS_MODAL = "SET_MOVE_ELEMENTS_MODAL",
  ON_AVATAR_CROP = "ON_AVATAR_CROP",
  CONFIRM_CODE = "CONFIRM_CODE",
  TARIFF_MODAL = "TARIFF_MODAL",
  SET_ALL_FILE_VIEW_MODAL = "SET_ALL_FILE_VIEW_MODAL",
  CONTACTS_MODAL = "CONTACTS_MODAL",
  SOCIAL_MODAL = "SOCIAL_MODAL",
  SET_CREATE_RUBRIC_LIBRARY_MODALS = "SET_CREATE_RUBRIC_LIBRARY_MODALS",
  SET_REMOVE_NON_EMPTY_RUBRIC_LIBRARY_MODAL = "SET_REMOVE_NON_EMPTY_RUBRIC_LIBRARY_MODAL",
  FOLDER_PASSWORD = "FOLDER_PASSWORD",
  MAIL_DASHBOARD_MODAL = "MAIL_DASHBOARD_MODAL",
  TRANSFER_DUTIES = "TRANSFER_DUTIES",
  STATUS_MODAL = "STATUS_MODAL",
  CREATE_PROJECT = "CREATE_PROJECT",
  CHANGE_STATUS_MODAL = "CHANGE_STATUS_MODAL",
  CREATE_PROJECT_ROLE = "CREATE_PROJECT_ROLE",
  CREATE_PROJECT_TEAM = "CREATE_PROJECT_TEAM",
  CREATE_PROJECT_TASK = "CREATE_PROJECT_TASK",
  ACCOUNT_DELETING_MODAL = "ACCOUNT_DELETING_MODAL",
  SET_CREATE_SPRINT_MODAL = "SET_CREATE_SPRINT_MODAL",
  LINKS_MODAL = "LINKS_MODAL",
  LINKS_CATEGORY_MODAL = "LINKS_CATEGORY_MODAL",
  SELECT_FILE = "SELECT_FILE",
  TASKS_QUEUE_MODAL = "TASKS_QUEUE_MODAL",
  TASKS_CONNECTION_MODAL = "TASKS_CONNECTION_MODAL",
  PROJECT_TASK_INFO = "PROJECT_TASK_INFO",
  DASHBOARD_JOINT_PROJECT_STATISTIC = "DASHBOARD_JOINT_PROJECT_STATISTIC",
  DASHBOARD_JOINT_PROJECT_TEAM = "DASHBOARD_JOINT_PROJECT_TEAM",
  TASK_OVERDUE = "TASK_OVERDUE",
  TAG_EDIT = "TAG_EDIT",
  USER_DELETE = "USER_DELETE",
  NOTE_EDIT = "NOTE_EDIT",
  RECORD_MODAL = "RECORD_MODAL",
  NOTES_SIDEBAR = "NOTES_SIDEBAR",
  PASSWORD_MODAL = "PASSWORD_MODAL",
  NOTES_CATEGORIES = "NOTES_CATEGORIES",
  NOTES_FULL_CATEGORY = "NOTES_FULL_CATEGORY"
}
