import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow-down.svg";
import { ReactComponent as TrashIcon } from "assets/PrivateCabinet/garbage.svg";
import { ReactComponent as OpenIcon } from "assets/PrivateCabinet/open-all.svg";
import { ReactComponent as SubstractIcon } from "assets/PrivateCabinet/subtract.svg";
import classNames from "classnames";
import ButtonIcon from "generalComponents/ButtonIcon/ButtonIcon";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useProjectsSelectors } from "Store/selectors/projectsSelectors";

import Role from "../../Components/Role/Role";
import TasksSearch from "../../Components/TasksSearch/TasksSearch";
import styles from "./BacklogServicePanel.module.sass";

const BacklogServicePanel = ({
  searchValue,
  handleChange,
  dateValue,
  setDateValue,
  isSelect,
  handleSelect,
  handleDelete,
  handleMoveToTask,
  isOpenAll,
  setIsOpenAll
}) => {
  const { __ } = useLocales();
  const { project } = useProjectsSelectors();

  const [isOpenMove, setisOpenMove] = useState(false);
  const moveRef = useRef(null);
  useOutsideClick(moveRef, () => setisOpenMove(false));

  const onOpenAll = () => setIsOpenAll((prev) => !prev);

  const toggleOpenMove = () => {
    setisOpenMove((open) => !open);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.filter}>
        <TasksSearch
          searchValue={searchValue}
          handleChange={handleChange}
          dateValue={dateValue}
          setDateValue={setDateValue}
        />
        <ButtonIcon handleClick={onOpenAll} icon={<OpenIcon />} variant="grey" isActive={isOpenAll} />
      </div>
      <div className={styles.rightGroup}>
        <div className={classNames(styles.selectBtn, { [styles.active]: isSelect })} onClick={handleSelect}>
          {__("Выбрать")}
        </div>
        <div className={styles.icons}>
          <div className={styles.roleSelectBlock}>
            <ButtonIcon
              handleClick={toggleOpenMove}
              icon={<ArrowIcon />}
              variant="grey"
              isActive={isOpenMove}
              disabled={!isSelect}
            />
            {isOpenMove && (
              <div ref={moveRef} className={styles.roleSelect}>
                {project.roles.map((r) => (
                  <div key={r.id} onClick={() => handleMoveToTask(r.id)}>
                    <Role role={r} />
                  </div>
                ))}
              </div>
            )}
          </div>
          <ButtonIcon
            handleClick={() => handleMoveToTask(-1)}
            variant="grey"
            icon={<SubstractIcon />}
            disabled={!isSelect}
          />
          <ButtonIcon handleClick={handleDelete} variant="grey" notHover disabled={!isSelect}>
            <TrashIcon width={15} height={15} className={styles.icon} />
          </ButtonIcon>
        </div>
      </div>
    </div>
  );
};

export default BacklogServicePanel;

BacklogServicePanel.propTypes = {
  searchValue: PropTypes.string,
  handleChange: PropTypes.func,
  setDateValue: PropTypes.func,
  dateValue: PropTypes.string,
  isSelect: PropTypes.bool,
  handleSelect: PropTypes.func,
  handleDelete: PropTypes.func,
  handleMoveToTask: PropTypes.func,
  isOpenAll: PropTypes.bool,
  setIsOpenAll: PropTypes.func
};
