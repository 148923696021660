import api from "api";
import { ReactComponent as SendIcon } from "assets/icons/arrow-send.svg";
import classNames from "classnames";
import TaskFile from "containers/JoinProject/TaskFile/TaskFile";
import TaskUploadFile from "containers/JoinProject/TaskUploadFile/TaskUploadFile";
import Avatar from "generalComponents/Avatar/Avatar";
import Button from "generalComponents/Button/Button";
import Loader from "generalComponents/Loaders/4HUB";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { projectSrc } from "generalComponents/variables/globalVariables";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IProjectTask, ITaskComment } from "models/store/joinProjects/joinProgects";
import React, { FC, useState } from "react";
import { useLocales } from "react-localized";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import CommentItem from "../CommentItem/CommentItem";
import TaskCommentEditor from "../TaskCommentEditor/TaskCommentEditor";
import styles from "./TaskComments.module.sass";

interface IProps {
  task: IProjectTask;
}
type IUploadFiles = IFile | { file: File; fid: string };

const TaskComments: FC<IProps> = ({ task }) => {
  const { __ } = useLocales();

  const { userInfo, theme, uid } = useUserSelectors();
  const { project } = useJoinProjectsSelectors();

  const { onSetTopMessageModal } = useActions();

  const [editItem, setEditItem] = useState<string>("");
  const [comments, setComments] = useState<ITaskComment[]>(task.comments);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<IUploadFiles[]>([]);
  const [answerUser, setAnswerUser] = useState<string>("");

  const deleteUploadFile = (fid: string) => setUploadFiles((prev) => prev.filter((item) => item.fid !== fid));

  const findUsersIds = (text: string) => {
    const valuesArray: string[] = text.match(/(?:\s|>)@(\S+?)(?=\s|<|$)/g) || [];
    const values = valuesArray.map((v) => v.slice(2));
    const ids = project.participants.filter((el) => {
      const search = el.fname && el.sname ? `${el.fname}_${el.sname}` : `${el.fname}` || `${el.sname}`;
      return values.includes(search);
    });
    return ids;
  };

  const onSend = async (text: string) => {
    console.log(findUsersIds(text)); // массив участков о которых есть упоминания
    try {
      setIsLoading(true);
      const fids = uploadFiles.filter((f) => (f as IFile)?.id_dir).map((f) => f.fid);
      const myFiles = uploadFiles.filter((f) => !(f as IFile)?.id_dir).map((f) => f.file);
      const formData = new FormData();
      formData.append("uid", uid);
      formData.append("id_task", task.id);
      formData.append("text", text);
      formData.append("id_project", task.id_project);
      fids.length && formData.append("fids", JSON.stringify(fids));
      myFiles.length && myFiles.forEach((file) => formData.append("myfile[]", file));
      console.log("text", text);
      const { data } = await api.post(`/ajax/task_com_add.php`, formData);
      checkResponseStatus(data.ok);
      setEditItem("");
      setUploadFiles([]);
      setComments((prev) => [data.comment, ...prev]);
      setAnswerUser("");
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.block}>
        <Avatar name={userInfo.fname + " " + userInfo.sname} imgSrc={projectSrc + userInfo.icon} size={32} />
        {(!editItem || +editItem > 0) && (
          <div className={styles.emptyArea} onClick={() => setEditItem("0")}>
            {__("Введите")}
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.EXTRA_SMALL}
              className={styles.sendBtn}
              iconL={<SendIcon />}
            />
          </div>
        )}
        {editItem === "0" && (
          <div className={styles.editorContainer}>
            <TaskCommentEditor
              prim={answerUser}
              setUploadFiles={setUploadFiles}
              onSend={onSend}
              setEditItem={setEditItem}
            />
            <ul className={classNames(styles.fileList, `scrollbar-thin-${theme}`)}>
              {uploadFiles.length > 0 &&
                uploadFiles.map((file) =>
                  "id_dir" in file ? (
                    <TaskFile key={file.fid} file={file} deleteFile={deleteUploadFile} />
                  ) : (
                    <TaskUploadFile key={file.fid} file={file} deleteFile={deleteUploadFile} />
                  )
                )}
            </ul>
            {loading && (
              <div className={styles.loaderBox}>
                <Loader
                  type={LoaderTypes.BOUNCING_DOTS}
                  position="absolute"
                  background="transparent"
                  zIndex={10}
                  width="200px"
                  height="200px"
                  containerType="bounceDots"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div>
        {comments.map((item) => (
          <CommentItem
            key={item.id}
            comment={item}
            setComments={setComments}
            editItem={editItem}
            setEditItem={setEditItem}
            taskId={task.id}
            setAnswerUser={setAnswerUser}
          />
        ))}
      </div>
    </div>
  );
};

export default TaskComments;
