import { ReactComponent as ArrowDownIcon } from "assets/PrivateCabinet/arrow_down.svg";
import { ReactComponent as FolderIcon } from "assets/PrivateCabinet/folder-3.svg";
import { useContextMenuCreateFile } from "generalComponents/collections/folders";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useCurrentFolder } from "generalComponents/Services/folderServices/folderServices";
import { PAGE_DEPTH } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { SAFE_MODALS } from "generalComponents/variables/safe";
import { useSafeFind } from "hooks/safeHooks";
import { useActions } from "hooks/useActions";
import { useCurrentPath, useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";

import styles from "./TempCreate.module.sass";

const TempCreate = (): JSX.Element => {
  const { __ } = useLocales();
  const { onSetCreateFileModal, onSetCreateFolderModal, onSetSafeModal, onCreateFolder, onLoadingFiles } = useActions();

  const { folders } = useFoldersSelectors();

  const currentFolder = useCurrentFolder();
  const menu = useContextMenuCreateFile();
  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const currentSafe = useSafeFind();
  const isFilesPage = useCurrentPath(ROUTES.FILES);
  const [show, setShow] = useState(false);

  const onSelect = (ext: string): void => {
    if (!ext) return;
    onSetCreateFileModal({
      open: true,
      dep: PAGE_DEPTH.FILES,
      ext,
      folders,
      onLoading: (payload) => onLoadingFiles(payload)
    });
    setShow(false);
  };

  const onCreateFolderCallback = (): void => {
    if (isSafePage) {
      onSetSafeModal({ open: true, type: SAFE_MODALS.CREATE_FOLDER, safe: currentSafe });
      setShow(false);
      return;
    }
    onSetCreateFolderModal({ open: true, type: "add", folder: currentFolder ?? null, folders, onCreateFolder });
    setShow(false);
  };

  const renderMenu = (): JSX.Element => {
    return (
      <>
        {!isSafePage && (
          <ul className={styles.list}>
            {!isFilesPage && (
              <li className={styles.item} onClick={onCreateFolderCallback}>
                <FolderIcon />
                <span>{__("Папку")}</span>
              </li>
            )}
          </ul>
        )}
        <ul className={styles.list}>
          {menu.office.map((el, i) => (
            <li key={i} className={styles.item} onClick={() => onSelect(el.ext)}>
              {el.img}
              <span>{el.name}</span>
            </li>
          ))}
        </ul>
        <ul className={styles.list}>
          {menu.microsoft.map((el, i) => (
            <li key={i} className={styles.item} onClick={() => onSelect(el.ext)}>
              {el.img}
              <span>{el.name}</span>
            </li>
          ))}
        </ul>
        <ul className={styles.list}>
          {menu.google.map((el, i) => (
            <li key={i} className={styles.item} onClick={() => onSelect(el.ext)}>
              {el.img}
              <span>{el.name}</span>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <PopoverOverlay
      overlayContent={renderMenu()}
      placement="bottom"
      classes={{ popoverBody: styles.nopadding }}
      show={show}
      setShow={setShow}
    >
      <span className={styles.button}>
        <span className={styles.text}>{__("Создать")}</span>
        <ArrowDownIcon className={styles.icon} />
      </span>
    </PopoverOverlay>
  );
};

export default TempCreate;
