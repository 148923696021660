import api from "api";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import Loader from "generalComponents/Loaders/4HUB";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { LETTERS_LIMIT } from "generalComponents/variables/globalVariables";
import { useFindPostbox } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { POSTBOX_SYSTEM_FOLDER } from "models/postbox";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import React, { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./BlockedUsers.module.sass";

interface IBlockedUser {
  id: string;
  email: string;
}

const BlockedUsers = () => {
  const { __ } = useLocales();
  const { onSetTopMessageModal, fetchPostboxMails } = useActions();
  const { uid } = useUserSelectors();
  const [users, setUsers] = useState<IBlockedUser[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const { postbox } = useFindPostbox();
  const { folderName } = useParams();

  useEffect(() => {
    const controller = new AbortController();
    fetchUsers(controller);
    return () => controller.abort();
  }, []); //eslint-disable-line

  const updateLetters = () => {
    const payload = {
      user: postbox.email,
      pass: postbox.pass,
      folder: folderName,
      limit: LETTERS_LIMIT,
      start: 0
    };
    fetchPostboxMails(payload);
  };

  const isUpdateLetters = (): boolean => {
    switch (folderName) {
      case POSTBOX_SYSTEM_FOLDER.Sent:
        return false;

      default:
        return true;
    }
  };

  const fetchUsers = async (controller: AbortController): Promise<void> => {
    try {
      const { data } = await api.get("/ajax/email_spam_list.php", { params: { uid }, signal: controller.signal });
      checkResponseStatus(data.ok);
      setUsers(data.email_spam);
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR,
        message: __("Что-то пошло не так. Попробуйте позже!")
      });
    } finally {
      setLoader(false);
    }
  };
  const onRemoveBlocked = async (emails: string): Promise<void> => {
    try {
      const { data } = await api.get("/ajax/email_spam_del.php", {
        params: { uid, emails }
      });
      checkResponseStatus(data.ok);
      setUsers((prev) => prev.filter((el) => el.email !== emails));
      if (isUpdateLetters()) updateLetters();
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR,
        message: __("Что-то пошло не так. Попробуйте позже!")
      });
    } finally {
      setLoader(false);
    }
  };

  if (loader) {
    return (
      <div className={styles.box}>
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      </div>
    );
  }

  if (users.length === 0) {
    return (
      <div className={styles.box}>
        <Clue text={__("Список пуст")} icon="neutral" />
      </div>
    );
  }

  return (
    <ul className={styles.box}>
      {users.map((user) => (
        <li key={user.id} className={styles.user}>
          <p className={styles.name}>{user.email}</p>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.EXTRA_SMALL}
            text={__("Разблокировать")}
            onClick={() => onRemoveBlocked(user.email)}
          />
        </li>
      ))}
    </ul>
  );
};

export default BlockedUsers;
