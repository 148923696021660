import { useFiltredTrashTask, useGetRolesGroup, useProjectMsg } from "generalComponents/Services/projectServices";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { TRolesGroup } from "models/generalComponents/services/projectServices";
import { ITaskProject } from "models/store/projects/projectStore";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import ProjectUsers from "../../Components/ProjectUsers/ProjectUsers";
import Roles from "../../Components/Roles/Roles";
import TrashRoles from "../TrashRoles/TrashRoles";
import TrashServicePanelTask from "../TrashServicePanel/TrashServicePanelTask";
import styles from "./TrashTasks.module.sass";

const TrashTasks: React.FC = (): JSX.Element => {
  const { onFetchTrashTasks } = useActions();
  const MSG = useProjectMsg();
  const tasks = useFiltredTrashTask();
  const getRolesGroup = useGetRolesGroup();
  const [chosenTasks, setChosenTasks] = useState<ITaskProject[]>([]);
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const { formatDate } = useDateFormat();

  useEffect(() => {
    onFetchTrashTasks(MSG.ERROR);
  }, []); //eslint-disable-line

  const trashTasks = (): Record<string, TRolesGroup> => {
    const dates = tasks.reduce((acc: any, item: ITaskProject) => {
      const key: string = item.del_date.slice(0, 10);
      return { ...acc, [key]: [...(acc[key] || []), item] };
    }, {});

    const obj = { ...dates };
    for (let key in obj) {
      obj[key] = getRolesGroup([...obj[key]]);
    }
    return obj;
  };

  const toggleSelect = (): void => {
    setChosenTasks([]);
    setIsSelect((isSelect) => !isSelect);
  };

  const toggleCurrentTask = (task: ITaskProject): void => {
    chosenTasks.some((t) => t.id === task.id)
      ? setChosenTasks((prev) => prev.filter((t) => t.id !== task.id))
      : setChosenTasks((prev) => [...prev, task]);
  };

  return (
    <>
      <div className={styles.rolesWrap}>
        <Roles />
      </div>
      <ProjectUsers />
      <TrashServicePanelTask chosenTasks={chosenTasks} toggleSelect={toggleSelect} isSelect={isSelect} />
      <div className={styles.wrap}>
        {Object.entries(trashTasks()).map(([date, group]) => (
          <div key={uuid()} className={styles.block}>
            <span className={styles.date}>{formatDate(date)}</span>
            {Object.entries(group).map(([role, tasks]) => (
              <>
                {tasks.length > 0 && (
                  <TrashRoles
                    key={uuid()}
                    role={role}
                    tasks={tasks}
                    isSelect={isSelect}
                    toggleCurrentTask={toggleCurrentTask}
                    chosenTasks={chosenTasks}
                  />
                )}
              </>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default TrashTasks;
