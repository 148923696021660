import classNames from "classnames";
import { useProjectModalTitles } from "generalComponents/collections/project";
import PopUp from "generalComponents/PopUp/PopUp";
import { Times } from "generalComponents/Times/Times";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onSetModals } from "Store/actions/ModalActions";

import AddCategory from "./AddCategory/AddCategory";
import AddLink from "./AddLink/AddLink";
import AddImage from "./AddProject/AddImage/AddImage";
import AddProject from "./AddProject/AddProject";
import AvatarCrop from "./AddProject/AvatarCrop/AvatarCrop";
import AddedRoles from "./AddRoles/AddedRoles/AddedRoles";
import AddRoles from "./AddRoles/AddRoles";
import CreateRole from "./AddRoles/CreateRole/CreateRole";
import AddSection from "./AddSection/AddSection";
import AddTask from "./AddTask/AddTask";
import AddedUsers from "./AddUsers/AddedUsers/AddedUsers";
import AddExecutors from "./AddUsers/AddExecutors/AddExecutors";
import AddUsers from "./AddUsers/AddUsers/AddUsers";
import AppointUsers from "./AddUsers/AppointUsers/AppointUsers";
import ChooseAdmin from "./AddUsers/ChooseAdmin/ChooseAdmin";
import AppointAdmin from "./AppointAdmin/AppointAdmin";
import ChooseFile from "./ChooseFile/ChooseFile";
import DeleteConfirm from "./DeleteConfirm/DeleteConfirm";
import ExtendPeriod from "./ExtendPeriod/ExtendPeriod";
import AddedAdmin from "./LeaveProject/AddedAdmin/AddedAdmin";
import LeaveProject from "./LeaveProject/LeaveProject";
import MessageAdmin from "./MessageAdmin/MessageAdmin";
import OpenTask from "./OpenTask/OpenTask";
import styles from "./ProjectModals.module.sass";
import ProjectSuccess from "./ProjectSuccess/ProjectSuccess";
import TaskHistory from "./TaskHistory/TaskHistory";
import UserInfo from "./UserInfo/UserInfo";
import WarningModal from "./WarningModal/WarningModal";

const ProjectModals = () => {
  const dispatch = useDispatch();
  const { type, callback, params } = useSelector((s) => s.Cabinet.modals.projectModals);
  const TITLES = useProjectModalTitles();
  const closeModal = () => {
    callback
      ? dispatch(onSetModals(MODALS.PROJECT, { type: callback, params }))
      : dispatch(onSetModals(MODALS.PROJECT, { type: MODALS.NO_MODAL, params: null }));
  };
  const getTitles = () => {
    return TITLES[type];
  };

  return (
    <PopUp set={closeModal}>
      <div className={classNames(styles.form, { [styles.noPadding]: type === PROJECT_MODALS.OPEN_TASK })}>
        {type !== PROJECT_MODALS.ADDED_ADMIN && <Times handleClick={closeModal} />}
        <header>{getTitles()}</header>
        {type === PROJECT_MODALS.PROJECT_SUCCESS && <ProjectSuccess onClose={closeModal} params={params} />}
        {(type === PROJECT_MODALS.TASK_TO_TRASH ||
          type === PROJECT_MODALS.DEL_TASK ||
          type === PROJECT_MODALS.DEL_PROJECT ||
          type === PROJECT_MODALS.DEL_CATEGORY ||
          type === PROJECT_MODALS.CLEAR_TRASH) && <DeleteConfirm onClose={closeModal} params={params} type={type} />}

        {(type === PROJECT_MODALS.ADD_PROJECT || type === PROJECT_MODALS.EDIT_PROJECT) && (
          <AddProject onClose={closeModal} params={params} type={type} />
        )}
        {type === PROJECT_MODALS.AVATAR_WAY && <AddImage onClose={closeModal} params={params} callback={callback} />}
        {type === PROJECT_MODALS.AVATAR_CROP && <AvatarCrop onClose={closeModal} params={params} callback={callback} />}
        {type === PROJECT_MODALS.CHOOSE_AVATAR && (
          <ChooseFile onClose={closeModal} params={params} callback={callback} type={type} />
        )}
        {type === PROJECT_MODALS.ADD_ROLES && <AddRoles onClose={closeModal} params={params} />}
        {(type === PROJECT_MODALS.CREATE_ROLE || type === PROJECT_MODALS.EDIT_ROLE) && (
          <CreateRole params={params} type={type} />
        )}
        {type === PROJECT_MODALS.ADDED_ROLES && <AddedRoles onClose={closeModal} params={params} />}

        {type === PROJECT_MODALS.ADD_USERS && <AddUsers onClose={closeModal} params={params} />}
        {type === PROJECT_MODALS.ADDED_USERS && <AddedUsers onClose={closeModal} params={params} />}
        {(type === PROJECT_MODALS.APPOINT_ROLES || type === PROJECT_MODALS.EDIT_USER) && (
          <AppointUsers onClose={closeModal} params={params} type={type} />
        )}

        {(type === PROJECT_MODALS.ADD_TASK || type === PROJECT_MODALS.EDIT_TASK) && (
          <AddTask onClose={closeModal} params={params} type={type} />
        )}
        {type === PROJECT_MODALS.OPEN_TASK && <OpenTask params={params} />}

        {(type === PROJECT_MODALS.ADD_CATEGORY || type === PROJECT_MODALS.EDIT_CATEGORY) && (
          <AddCategory onClose={closeModal} params={params} type={type} />
        )}

        {type === PROJECT_MODALS.ADD_EXECUTORS && <AddExecutors onClose={closeModal} params={params} />}

        {type === PROJECT_MODALS.LEAVE_PROJECT && <LeaveProject />}
        {(type === PROJECT_MODALS.CHOOSE_ADMIN || type === PROJECT_MODALS.CHOOSE_SUBADMIN) && (
          <ChooseAdmin onClose={closeModal} type={type} />
        )}
        {type === PROJECT_MODALS.APPOINT_SUBADMIN && <AppointAdmin />}
        {type === PROJECT_MODALS.ADDED_ADMIN && <AddedAdmin params={params} onClose={closeModal} />}
        {type === PROJECT_MODALS.MESSAGE_ADMIN && <MessageAdmin params={params} onClose={closeModal} />}
        {type === PROJECT_MODALS.EXTEND_PERIOD && <ExtendPeriod params={params} onClose={closeModal} />}
        {type === PROJECT_MODALS.TASK_HISTORY && <TaskHistory params={params} onClose={closeModal} />}
        {type === PROJECT_MODALS.USER_INFO && <UserInfo params={params} onClose={closeModal} />}
        {type !== PROJECT_MODALS.ADDED_ADMIN && <Times handleClick={closeModal} />}
        {(type === PROJECT_MODALS.ADD_LINK || type === PROJECT_MODALS.EDIT_LINK) && (
          <AddLink onClose={closeModal} type={type} params={params} />
        )}
        {type === PROJECT_MODALS.WARNING && <WarningModal onClose={closeModal} params={params} />}
        {(type === PROJECT_MODALS.ADD_SECTION || type === PROJECT_MODALS.EDIT_SECTION) && (
          <AddSection onClose={closeModal} params={params} type={type} />
        )}
      </div>
    </PopUp>
  );
};

export default ProjectModals;
