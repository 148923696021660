import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as CloudIcon } from "assets/icons/cloud.svg";
import { ReactComponent as CollapsIcon } from "assets/icons/collapsed_1.svg";
import { ReactComponent as OpenIcon } from "assets/icons/open.svg";
import classNames from "classnames";
import { convertToRaw, EditorState } from "draft-js";
import Button from "generalComponents/Button/Button";
import NoteEditor from "generalComponents/NotesEditor/NoteEditor";
import { createEditorStateFromJson } from "generalComponents/NotesSidebar/components/NoteSidebarItem/NoteSidebarItem";
import PopUp from "generalComponents/PopUp/PopUp";
import { useActions } from "hooks/useActions";
import { useDebounce } from "hooks/useDebounce";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialNoteEditState } from "models/store/Cabinet/modals/noteEdit";
import { useEffect, useState } from "react";
import { EditorState as IEditorState } from "react-draft-wysiwyg";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useNotesSelectors } from "Store/selectors/notesSelectors";

import styles from "./NoteEditModal.module.sass";

const NoteEditModal = () => {
  const { onSetNoteEditModal, onAddNewNote, onEditNote, onSetActiveNote } = useActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    noteEdit: { isFull: full, category_id }
  } = useGlobalModalsSelectors();

  const [isFull, setIsFull] = useState<boolean>(full ?? false);

  const controller = new AbortController();
  const { activeNote } = useNotesSelectors();

  const [editorState, setEditorState] = useState<IEditorState>(
    activeNote ? createEditorStateFromJson(activeNote.prim) : EditorState.createEmpty()
  );
  const [password, setPassword] = useState<string>(activeNote?.pass);
  const [emo, setEmo] = useState<string>(activeNote?.emo);
  const [color, setColor] = useState<string>(activeNote?.color);
  const [tags, setTags] = useState<string[]>(activeNote?.tags || []);

  const closeModal = () => {
    onSetActiveNote(null);
    onSetNoteEditModal(initialNoteEditState());
  };

  const addNote = () => {
    const text = convertToRaw(editorState.getCurrentContent());
    if (activeNote) {
      if (
        JSON.stringify(text) === activeNote.prim &&
        JSON.stringify(tags) === JSON.stringify(activeNote.tags) &&
        color === activeNote.color &&
        password === activeNote.pass
      )
        return;
      onEditNote(
        {
          id_item: activeNote.id,
          prim: JSON.stringify(text),
          pass: password,
          emo,
          tags,
          color
        },
        setIsLoading
      );
    } else {
      onAddNewNote({ prim: JSON.stringify(text), pass: password, emo, tags, color, id_dep: category_id }, setIsLoading);
    }
  };

  const debounceCallback = useDebounce(addNote, 1500);

  useEffect(() => {
    const hasTextChanged = editorState.getCurrentContent().hasText();
    if (!hasTextChanged) return;
    debounceCallback();
    return () => {
      controller.abort();
    };
  }, [editorState, tags, color, emo, password]); //eslint-disable-line

  return (
    <PopUp set={closeModal} position={isFull ? "center" : "right-bottom"} zIndex={12}>
      <div className={classNames(styles.container, isFull && styles.full)}>
        <div className={styles.header}>
          {isLoading && <CloudIcon className={styles.cloudIcon} fill="#34C6A2" />}
          <Button
            isSquare={true}
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.SMALL}
            iconL={isFull ? <CollapsIcon /> : <OpenIcon />}
            onClick={() => setIsFull((prev) => !prev)}
          />
          <Button
            isSquare={true}
            variant={ButtonVariantType.OPACITY}
            size={ButtonSizeType.SMALL}
            iconL={<CloseIcon />}
            onClick={closeModal}
          />
        </div>
        <NoteEditor
          editorState={editorState}
          setEditorState={setEditorState}
          setPassword={setPassword}
          password={password}
          emo={emo}
          setEmo={setEmo}
          color={color}
          setColor={setColor}
          tags={tags}
          setTags={setTags}
        />
      </div>
    </PopUp>
  );
};

export default NoteEditModal;
