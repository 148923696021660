import { useFolderPath } from "generalComponents/Services/folderServices/folderServices";
import { useActions } from "hooks/useActions";
import { IFolder } from "models/store/folders/foldersStore";

export const useSetFolderData = () => {
  const getFolderPath = useFolderPath();
  const { onSendFolderPath, onSelectFolder } = useActions();

  const setSelectFolder = (folder: IFolder): void => {
    onSelectFolder(folder);
  };

  const setFolderPath = (folder: IFolder): void => {
    onSendFolderPath(getFolderPath(folder));
  };

  const setFolderData = (folder: IFolder): void => {
    setSelectFolder(folder);
    setFolderPath(folder);
  };

  return {
    setSelectFolder,
    setFolderPath,
    setFolderData
  };
};
