import { TrashElementsList } from "containers/Trash/TrashElementsList/TrashElementsList";
import { useSetOpenFolders } from "generalComponents/Hooks/Folders/useSetOpenFolders";
import { useSetFolderData } from "generalComponents/Hooks/useSetSelectFolder";
import { useAllFolders } from "generalComponents/Services/folderServices/folderServices";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { ROUTES } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { useAllSelectedSearchParams, useSelectedObjSearchParams } from "hooks/useSelectedSearchParams";
import { IFolder } from "models/store/folders/foldersStore";
import { initialFolderState } from "models/store/folders/foldersStore";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const TrashFiles = () => {
  const { state, search } = useLocation();
  const navigate = useNavigate();

  const { trashDirId } = useParams();

  const { onGetTrashFolderFiles, onGetMyFolders, onNullifyTrashElements, onSetFolderPassModal, onSendFolderPath } =
    useActions();

  const {
    objSearchParams: { group, sort, sort_reverse }
  } = useSelectedObjSearchParams();
  const { color, emo, tags } = useAllSelectedSearchParams();

  const foldersAll = useAllFolders();
  const { onSetOpenFolders } = useSetOpenFolders();
  const { setFolderData, setSelectFolder } = useSetFolderData();

  useEffect(() => {
    const controllerFiles = new AbortController();
    const payload = {
      id_dir: trashDirId
    };

    if (trashDirId) {
      onNullifyTrashElements();
      onSendFolderPath([]);
      setSelectFolder(initialFolderState());

      const folder: IFolder = foldersAll.find((el) => el.id_dir === trashDirId);

      if (!folder) return;

      if ("pass" in folder && folder.pass !== NO_VALUE) {
        onSetFolderPassModal({
          open: true,
          type: "enterPassword",
          folder,
          callbackOk: (decryptedPass) => {
            setFolderData(folder);
            onSetOpenFolders(folder.id_parent);
            onGetTrashFolderFiles({ ...payload, pass: decryptedPass }, controllerFiles);
          },
          callbackCancel: () => {
            const path = state?.from || `/${ROUTES.TRASH}${search}`;
            navigate(path);
          }
        });
        return;
      }

      setFolderData(folder);
      onSetOpenFolders(folder.id_parent);
      onGetTrashFolderFiles(payload, controllerFiles);
    }

    return () => {
      controllerFiles.abort();
    };
  }, [trashDirId, group, sort, sort_reverse, color.selectedValue, emo.selectedValue, tags.selectedValue?.length]); //eslint-disable-line

  useEffect(() => {
    const controllerFolders = new AbortController();

    onGetMyFolders(controllerFolders);

    return () => {
      onNullifyTrashElements();
      controllerFolders.abort();
    };
  }, []); //eslint-disable-line

  return <TrashElementsList />;
};

export default TrashFiles;
