import Button from "generalComponents/Button/Button";
import Emoji from "generalComponents/Elements/Emoji";
import Input from "generalComponents/Input/Input";
import Loader from "generalComponents/Loaders/4HUB";
import HeaderModal from "generalComponents/Modals/Components/HeaderModal/HeaderModal";
import PopUp from "generalComponents/PopUp/PopUp";
import { SelectCustom } from "generalComponents/SelectCustom/SelectCustom";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { initialLinksModalState } from "models/store/Cabinet/modals/linksModal";
import { IJoinProjectLink } from "models/store/joinProjects/joinProgects";
import { ChangeEvent, MouseEvent, useState } from "react";
import { useLocales } from "react-localized";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";

import styles from "./LinksModal.module.sass";

const LinksModal = () => {
  const { project } = useJoinProjectsSelectors();
  const {
    linksModal: { link }
  } = useGlobalModalsSelectors();

  const { __ } = useLocales();
  const { onSetLinksModal, onAddJointProjectLink, onEditJointProjectLink } = useActions();
  const [isLoading, setIsLoading] = useState(false);
  const [emo, setEmo] = useState(link?.emo || "");

  const [linkObj, setLinkObj] = useState<IJoinProjectLink>(
    link || {
      id: null,
      id_project: project.id,
      name: "",
      link: "",
      id_dep: "",
      emo: emo,
      icon: undefined,
      date: undefined
    }
  );

  const closeModal = (): void => {
    onSetLinksModal(initialLinksModalState());
  };

  const handleCategoryChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | MouseEvent<HTMLButtonElement>
  ): void => {
    const { value } = e.currentTarget;
    setLinkObj((prevLinkObj) => ({ ...prevLinkObj, id_dep: value }));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setLinkObj((prevLinkObj) => ({ ...prevLinkObj, name: newName }));
  };

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLink = e.target.value;
    setLinkObj((prevLinkObj) => ({ ...prevLinkObj, link: newLink }));
  };

  const handleConfirm = () => {
    if (!link) {
      onAddJointProjectLink({ item: { ...linkObj, emo: emo || "" }, setIsLoading });
    } else {
      onEditJointProjectLink({ item: { ...linkObj, emo: emo || "" }, setIsLoading });
    }
    closeModal();
  };

  const isAddButtonDisabled = !linkObj.name || !linkObj.link || !linkObj.id_dep;

  return (
    <PopUp set={closeModal} position="top">
      <HeaderModal onClose={closeModal} title={link ? __("Редактировать ссылку") : __("Добавить ссылку")} />
      <div className={styles.content}>
        {isLoading && (
          <Loader
            position="absolute"
            type={LoaderTypes.BOUNCING_DOTS}
            background="rgba(0, 0, 0, 0.35)"
            containerType="bounceDots"
          />
        )}
        <SelectCustom
          width={400}
          variantHeight="medium"
          options={project.linksCategories?.map((category) => ({ name: category.name, value: category.id })) || []}
          label={__("Категория")}
          selectedDefault={
            linkObj.id_dep ? project.linksCategories.find((category) => category.id === linkObj.id_dep) : null
          }
          onSelected={handleCategoryChange}
        />
        <Input className={styles.input} label={__("Название")} value={linkObj.name} onChange={handleNameChange} />
        <Input className={styles.input} label={__("Url")} value={linkObj.link} onChange={handleLinkChange} />
        <div className={styles.emoji}>
          <Emoji emo={emo} setEmo={setEmo} />
        </div>
        <div className={styles.buttons}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("Отменить")}
            onClick={closeModal}
          />
          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={link ? __("Редактировать") : __("Добавить")}
            disabled={isAddButtonDisabled}
            onClick={handleConfirm}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default LinksModal;
