import classNames from "classnames";
import Clue from "generalComponents/Clue/Clue";
import { DropArea } from "generalComponents/DropArea/DropArea";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import BarsPreviewView from "generalComponents/ItemsViewTypeVariants/BarsPreviewView/BarsPreviewView";
import BarsView from "generalComponents/ItemsViewTypeVariants/BarsView/BarsView";
import LinesPreviewView from "generalComponents/ItemsViewTypeVariants/LinesPreviewView/LinesPreviewView";
import LinesView from "generalComponents/ItemsViewTypeVariants/LinesView/LinesView";
import Loader from "generalComponents/Loaders/4HUB";
import UploadArea from "generalComponents/UploadArea/UploadArea";
import { HOT_KEYS, ViewType } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { useDragDropUpload } from "hooks/useDragDropUpload";
import { useIsFilterByDateCreated, useIsFiltersMarkers, useIsFilterSort } from "hooks/useIsFilters";
import { useKeysHotPress } from "hooks/useKeysHotPress";
import { LoaderTypes } from "models/generalComponents/loader";
import { FilesNextArrayType, initialPickedItems } from "models/store/files/files";
import { ISelectedFiles } from "models/store/files/uploadFilesModals/uploadFiles";
import { useRef } from "react";
import { useLocales } from "react-localized";
import { useFilesSelectors } from "Store/selectors/filesSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import ItemsDate from "../../Pages/Cabinet/Components/WorkElements/ItemsDate/ItemsDate";
import FilesObserver from "../FilesObserver/FilesObserver";
import styles from "./ElementsList.module.sass";

const ElementsList = (): JSX.Element => {
  const { __ } = useLocales();
  const { onSelectedFiles, onSetPickedItems } = useActions();
  const {
    view,
    loader,
    page,
    files,
    total,
    pickedItems: { isLeftSide, isRightSide }
  } = useFilesSelectors();

  const { isBarsView, isLinesView, isBarsPreviewView, isLinesPreviewView } = useGetItemView();
  const { theme } = useUserSelectors();

  const onSelectedFilesCallback = (files: ISelectedFiles[]) => {
    onSelectedFiles(files);
  };

  const rootRef = useRef();

  const isFilterByDateCreated = useIsFilterByDateCreated();
  const isFilterSort = useIsFilterSort();
  const isFiltersMarkers = useIsFiltersMarkers();

  const { handleDrop, dragStartHandler, dragEndHandler, isDrag } = useDragDropUpload(onSelectedFilesCallback);

  const onSetAllSelectedFiles = () => {
    onSetPickedItems({
      open: true,
      items: files,
      isRightSide,
      isLeftSide
    });
  };

  useKeysHotPress(rootRef, HOT_KEYS.KEY_A, HOT_KEYS.META, onSetAllSelectedFiles);
  useKeysHotPress(rootRef, HOT_KEYS.KEY_A, HOT_KEYS.ALT, () => {
    onSetPickedItems(initialPickedItems());
  });

  const renderItemsViewType = (files: FilesNextArrayType): JSX.Element => {
    switch (view) {
      case ViewType.BARS:
        return !isFilterByDateCreated ? <BarsView items={files} /> : <ItemsDate files={files} />;

      case ViewType.LINES:
        return !isFilterByDateCreated ? <LinesView items={files} /> : <ItemsDate files={files} />;

      case ViewType.BARS_PREVIEW:
        return <BarsPreviewView items={files} />;
    }
  };

  const renderMyFiles = (): JSX.Element => {
    if (isLinesPreviewView) {
      return <LinesPreviewView />;
    }
    if (files !== null) {
      if (total === 0) {
        return isFilterSort && isFiltersMarkers ? (
          <Clue icon="sad" text={__("Нет файлов соответствующих параметрам фильтрации")} />
        ) : (
          <UploadArea />
        );
      }
      return renderItemsViewType(files);
    }
  };

  return (
    <div
      className={classNames(styles.root, `scrollbar-medium-${theme}`, {
        [styles.marginTop]: isBarsView || isLinesView,
        [styles.bgnd]: isDrag
      })}
      onDragStart={dragStartHandler}
      onDragOver={dragStartHandler}
      ref={rootRef}
      tabIndex={-1}
    >
      {loader && page === 1 && !isLinesPreviewView ? (
        <Loader
          type={LoaderTypes.BOUNCING_DOTS}
          position="absolute"
          background="white"
          zIndex={5}
          width="100px"
          height="100px"
          containerType="bounceDots"
        />
      ) : (
        <>
          {renderMyFiles()}
          {!isLinesPreviewView && !isBarsPreviewView && <FilesObserver />}
        </>
      )}
      {isDrag && (
        <DropArea
          onDragStart={dragStartHandler}
          onDragLeave={dragEndHandler}
          onDragOver={dragStartHandler}
          onDrop={handleDrop}
        />
      )}
    </div>
  );
};

export default ElementsList;
