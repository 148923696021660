import { ReactComponent as ArrowDonwIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as SessionIcon } from "assets/icons/devices.svg";
import { ReactComponent as NotesIcon } from "assets/icons/note.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as EmployeIcon } from "assets/icons/person_2.svg";
import { ReactComponent as SafeIcon } from "assets/icons/safe.svg";
import { ReactComponent as BellIcon } from "assets/PrivateCabinet/bell.svg";
import { ReactComponent as MailIcon } from "assets/PrivateCabinet/mail/unread.svg";
import { ReactComponent as MeetingIcon } from "assets/PrivateCabinet/meeting.svg";
import { ReactComponent as PhoneIcon } from "assets/PrivateCabinet/phone-5.svg";
import { ReactComponent as LibraryIcon } from "assets/PrivateCabinet/sideMenu/library.svg";
import { ReactComponent as ProgramsIcon } from "assets/PrivateCabinet/sideMenu/programs.svg";
import { ReactComponent as TaskIcon } from "assets/PrivateCabinet/sideMenu/tasks.svg";
import classNames from "classnames";
import { useActionsJournal, useEventGroupElement } from "collections/journal";
import { useListEmployees } from "collections/profile";
import { useSystemFolder } from "generalComponents/collections/folder";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { TYPE_ELEMENTS } from "generalComponents/variables/global";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType } from "models/generalComponents/button";
import { DEFAULT_FOLDERS } from "models/generalComponents/folders";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IEvent } from "models/store/journal/journal";
import { IPostbox } from "models/store/postbox/postbox";
import { FC, useState } from "react";

import { ContextInfoModal } from "./components/ContextInfoModal/ContextInfoModal";
import styles from "./JournalEvent.module.sass";

export interface IJournalEventProps {
  event: IEvent;
}

export const JournalEvent: FC<IJournalEventProps> = ({ event }): JSX.Element => {
  const dataEventGroup = useEventGroupElement();
  const actionsJournal = useActionsJournal();
  const SYSTEM_FOLDERS = useSystemFolder();

  const [isOpenModal, setOpenModal] = useState(false);

  const { formatDate } = useDateFormat();

  const eventGroup = event.event_group;
  const objName = event?.object?.name;
  const typeTask = event?.object?.id_type;
  const folderObj = event?.object as IFolder;

  const userName = useListEmployees().find(({ value }) => value === event?.object?.id)?.name;

  const renderName = (): string => {
    if (Boolean(folderObj?.is_dir) && folderObj?.is_system === "1") {
      return SYSTEM_FOLDERS[folderObj?.name as DEFAULT_FOLDERS]?.nameRu;
    }

    return objName || userName || (event?.data?.old as IPostbox)?.email || event?.data?.old?.name;
  };

  const renderIcon = (): JSX.Element => {
    switch (eventGroup) {
      case TYPE_ELEMENTS.FOLDERS:
        return <ItemIcon isFolder={Boolean(folderObj?.is_dir)} width={16} height={16} />;

      case TYPE_ELEMENTS.FILES:
        return <ItemIcon isFolder={false} width={16} height={16} ext={(event.object as IFile)?.ext} />;

      case TYPE_ELEMENTS.LIBRARY:
        return (
          <ItemIcon
            isFolder={Boolean((event.object as IFolder).is_dir)}
            ext={(event.object as IFile)?.ext}
            width={16}
            height={16}
          />
        );

      case TYPE_ELEMENTS.SAFES:
        return <SafeIcon width={16} height={16} fill="#1565C0" />;

      case TYPE_ELEMENTS.EMAILS:
        return <MailIcon width={16} height={16} fill="#1565C0" />;

      case TYPE_ELEMENTS.SOFT:
        return <ProgramsIcon width={16} height={16} fill="#1565C0" />;

      case TYPE_ELEMENTS.USERS:
        return <PersonIcon width={16} height={16} />;

      case TYPE_ELEMENTS.ORG_USERS:
        return <EmployeIcon />;

      case TYPE_ELEMENTS.SESSION_USERS:
        return <SessionIcon width={16} height={16} />;

      case TYPE_ELEMENTS.DEPS:
        return <LibraryIcon width={16} height={16} fill="#4D35BE" />;

      case TYPE_ELEMENTS.TASKS:
        switch (typeTask) {
          case TASK_TYPES.MAILS:
            return <MailIcon width={16} height={16} fill="#F0BA2E" />;

          case TASK_TYPES.CALLS:
            return <PhoneIcon width={16} height={16} fill="#2ABD27" />;

          case TASK_TYPES.TASK:
            return <TaskIcon width={16} height={16} fill="#4086F1" />;

          case TASK_TYPES.ONLINE_MEETING:
          case TASK_TYPES.OFFLINE_MEETING:
            return <MeetingIcon width={16} height={16} />;

          case TASK_TYPES.REMINDER:
            return <BellIcon width={16} height={16} fill="#26c0ca" />;

          case TASK_TYPES.NOTES:
            return <NotesIcon />;

          default:
            return <TaskIcon width={16} height={16} fill="#4086F1" />;
        }

      default:
        <></>;
    }
  };

  return (
    <div className={styles.item}>
      <div>{dataEventGroup[eventGroup]}</div>
      <div className={styles.item__info}>
        {renderIcon()}
        <span className={styles.item__name}>{renderName()}</span>
      </div>
      <div className={styles.item__date}>{formatDate(event.date, "full")}</div>
      <div>{actionsJournal[event.event_type]}</div>
      <div className={styles.item__btns}>
        <PopoverOverlay
          overlayContent={<ContextInfoModal event={event} setShow={setOpenModal} />}
          width={440}
          show={isOpenModal}
          setShow={setOpenModal}
          btn="custom"
          iconLBtn={
            <ArrowDonwIcon
              width={16}
              height={16}
              className={classNames(styles.item__btnIcon, {
                [styles.active]: isOpenModal
              })}
            />
          }
          sizeBtn={ButtonSizeType.EXTRA_SMALL}
        />
      </div>
    </div>
  );
};
