import { ReactComponent as InfinIcon } from "assets/PrivateCabinet/infin.svg";
import { endOfDay, getTime, startOfDay, startOfWeek } from "date-fns";
import { defaultRows, useTasksCalendarPeriod } from "generalComponents/Services/calendarPageServices";
import { TASK_TYPES } from "generalComponents/variables/tasks";
import { IMyTask } from "models/store/tasks/tasks";
import React, { useMemo } from "react";
import { useTasksSelectors } from "Store/selectors/tasksSelectors";
import { taskOfPeriod } from "utils/tasks";

import AllDayTasks from "../AllDayTasks/AllDayTasks";
import DaysOfWeeks from "../DaysOfWeeks/DaysOfWeeks";
import WeekDay from "../WeekDay/WeekDay";
import styles from "./WeekView.module.sass";

const WeekView = (): JSX.Element => {
  const tasks = useTasksCalendarPeriod();
  const {
    filters: { date }
  } = useTasksSelectors();

  const taskTimes: Record<string, IMyTask[]> = useMemo(
    () =>
      tasks.reduce((acc: Record<string, IMyTask[]>, t) => {
        const time: string = t.date_start.split("T")[1].slice(0, 5);
        acc[time] ? acc[time].push(t) : (acc[time] = [t]);
        return acc;
      }, {}),
    [tasks]
  );

  const timeRows: Record<string, IMyTask[]> = { ...defaultRows(), ...taskTimes };

  const timeArray: string[] = Object.keys(timeRows).sort();

  const startDay = new Date(startOfWeek(new Date(date.year, date.month, date.day), { weekStartsOn: 1 })).getDate();

  const weekDates: number[] = useMemo(() => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(new Date(date.year, date.month, startDay + i).getTime());
    }
    return dates;
  }, [date.month, date.year, startDay]);

  const renderRow = (time: string): JSX.Element[] => {
    return weekDates.map((day, i) => {
      return <WeekDay key={i} day={day} timeRows={timeRows[time]} />;
    });
  };

  const renderAllDay = (): JSX.Element[] => {
    return weekDates.map((day, i) => {
      const periodStart: number = getTime(startOfDay(new Date(day)));
      const periodEnd: number = getTime(endOfDay(new Date(day)));
      const tasksDay: IMyTask[] = taskOfPeriod(tasks, periodStart, periodEnd);

      const filtredTasks: IMyTask[] = tasksDay.filter(
        (t) => t.id_type === TASK_TYPES.TASK && periodStart >= new Date(t.date_start).getTime()
      );

      return (
        <React.Fragment key={i}>
          <AllDayTasks day={day} timeRows={filtredTasks} />
        </React.Fragment>
      );
    });
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.weekBox}>
        <DaysOfWeeks />
      </div>
      <div className={styles.timeRow}>
        <div className={styles.infin}>
          <InfinIcon />
        </div>
        <div className={styles.days}>{renderAllDay()}</div>
      </div>

      <div className={styles.scroll}>
        {timeArray.map((time) => (
          <div key={time} className={styles.timeRow}>
            <div className={styles.time}>{time}</div>
            <div className={styles.days}>{renderRow(time)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeekView;
