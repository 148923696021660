import { ChartData, ChartOptions } from "chart.js";
import { FC } from "react";
import { Bar } from "react-chartjs-2";
import { useLocales } from "react-localized";

interface IProps {
  dataChart: Record<string, { amountTasks: number; time: number }>;
  tab: "time" | "amount";
}
const TasksOverdueChart: FC<IProps> = ({ dataChart, tab }) => {
  const { __ } = useLocales();

  const getAlign = () => {
    const arr = Object.values(dataChart).map((el) => (tab === "time" ? el.time : el.amountTasks));
    const max = Math.max(...arr);
    return arr.map((el) => {
      const perstange = (el / max) * 100;
      return perstange >= 8 ? "center" : "end";
    });
  };

  const options: ChartOptions<"line" | "bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: true,
        color: "#494949",
        align: getAlign()
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: tab === "time" ? __("часы") : __("задачи")
        },
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  const dataBar: ChartData<"bar", number[], string> = {
    labels: Object.keys(dataChart),
    datasets: [
      {
        data:
          tab === "time"
            ? Object.values(dataChart).map((el) => el.time)
            : Object.values(dataChart).map((el) => el.amountTasks),
        type: "bar",
        label: __("Просроченных задач"),
        backgroundColor: "#CC4036",
        borderRadius: 4
      }
    ]
  };

  return <Bar data={dataBar} options={options} />;
};

export default TasksOverdueChart;
