import Button from "generalComponents/Button/Button";
import { PROJECT_MODALS } from "generalComponents/variables/project";
import { ButtonVariantType } from "models/generalComponents/button";
import { IAddSectionModalProps } from "models/modals/projectModals";
import React, { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./AddSection.module.sass";

const AddSection: React.FC<IAddSectionModalProps> = ({ onClose, params, type }): JSX.Element => {
  const { __ } = useLocales();
  const [name, setName] = useState<string>(params.name ?? "");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => setName(e.target.value);

  const onAdd = (): void => {
    params.callback(name);
    onClose();
  };

  return (
    <div className={styles.wrap}>
      <input className={styles.inputSection} value={name} onChange={handleChange} />
      <div className={styles.btns}>
        <Button type="button" variant={ButtonVariantType.CANCEL} onClick={onClose} text={__("Отмена")} />
        <Button
          type="button"
          variant={ButtonVariantType.OK}
          onClick={onAdd}
          text={type === PROJECT_MODALS.ADD_SECTION ? __("Добавить") : __("Сохранить")}
          disabled={!name}
        />
      </div>
    </div>
  );
};

export default AddSection;
