import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classNames from "classnames";
import { colors } from "collections/markers";
import { isIFile } from "containers/Dashboard/DashboardItem/DashboardItem";
import Button from "generalComponents/Button/Button";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { ROUTES } from "generalComponents/variables/routing";
import { useAuthorizedSafe } from "hooks/safeHooks";
import { useCurrentPathStarstWith } from "hooks/useCurrentPath";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { useState } from "react";
import { v4 as uuid } from "uuid";

import styles from "./ColorPicker.module.sass";

const ColorPicker = ({ item, onColorClick }: { item: IFile | IFolder; onColorClick: Function }) => {
  const [activeColor, setActiveColor] = useState<string>(item?.color && item.color);
  const [isOpen, setIsOpen] = useState(false);

  const isSafePage = useCurrentPathStarstWith(ROUTES.SAFE);
  const safe = useAuthorizedSafe();

  const handleClick = async (active: string) => {
    const newActiveColor = activeColor === active ? "" : active;
    setActiveColor(newActiveColor);

    if (isIFile(item)) {
      onColorClick({ fids: [item.fid], color: newActiveColor }, isSafePage ? safe : null);
    } else {
      onColorClick({ id_dirs: [item.id_dir], color: newActiveColor }, EVENT_TYPE.EDIT);
    }
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.colors, isOpen && styles.open)}>
        {colors.map((current) => (
          <div
            key={uuid()}
            className={classNames(styles.color, activeColor === current && styles.active)}
            onClick={() => handleClick(current)}
          >
            <span style={{ background: current }} />
          </div>
        ))}
      </div>
      <Button
        className={styles.button}
        isSquare
        variant={ButtonVariantType.ADD_GREY}
        size={ButtonSizeType.EXTRA_SMALL}
        iconL={<ArrowIcon className={classNames(styles.arrow, isOpen && styles.open)} />}
        onClick={() => setIsOpen((prev) => !prev)}
      />
    </div>
  );
};

export default ColorPicker;
