import Button from "containers/Authentication/Button/Button";
import { sendCode } from "containers/Authentication/ForgotPasswordSteps/FirstStep/FirstStep";
import Input from "containers/Authentication/Input/Input";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useActions } from "hooks/useActions";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { Dispatch, SetStateAction, useState } from "react";
import { useLocales } from "react-localized";

import styles from "./SecondStep.module.sass";

const SecondStep = ({
  handleNextStep,
  login,
  code,
  setCode
}: {
  handleNextStep: () => void;
  login: string;
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
}): JSX.Element => {
  const { __ } = useLocales();
  const [error, setError] = useState("");
  const { onSetTopMessageModal } = useActions();

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
    setError("");
  };

  const handleSubmit = () => {
    if (code.length !== 4) {
      setError(__("Код проверки должен состоять из 4 символов."));
    } else {
      handleNextStep();
    }
  };

  const resendCode = async () => {
    try {
      const data = await sendCode(login);
      checkResponseStatus(data.ok);
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: __("Код был отправлен повторно") });
    } catch {
      setError(__("Упс!!! Что-то пошло не так!"));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4>{__("Забыли пароль?")}</h4>
        <p>{__("Код был отправлен на ") + login + __(", проверьте и введите код, пожалуйста")}</p>
        <div className={styles.input}>
          <Input value={code} onChange={handleCodeChange} error={error} />
        </div>
        <span className={styles.info}>
          {__("Код не пришел?")}
          <button className={styles.resendButton} onClick={resendCode}>
            {" "}
            {__("Отправить повторно")}
          </button>
        </span>
      </div>
      <Button
        disabled={!code}
        animationClassName={styles.animation}
        className={styles.confirm_button}
        onClick={handleSubmit}
      >
        {__("Продолжить")}
      </Button>
    </div>
  );
};

export default SecondStep;
