import React, { useEffect, useState } from "react";

import styles from "./DeadlineTimer.module.sass";

interface IDeadlineTimerProps {
  deadline: number; // ms unix time
  onComplete?: (v: boolean) => void;
}

const DeadlineTimer: React.FC<IDeadlineTimerProps> = ({ deadline, onComplete }): JSX.Element => {
  const [timeLeft, setTimeLeft] = useState<number>(Math.floor((deadline - Date.now()) / 1000));
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  useEffect(() => {
    if (timeLeft <= 0) {
      onComplete(false);
    } else {
      const timerId = setTimeout(() => {
        const deltaTime = deadline - Date.now();
        const seconds = Math.floor(deltaTime / 1000);
        setTimeLeft(seconds);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft, onComplete]); // eslint-disable-line

  return (
    <p className={styles.time}>
      {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
    </p>
  );
};

export default DeadlineTimer;
