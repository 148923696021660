import { ITag } from "models/store/user/user";

export interface ITagEditState {
  open: boolean;
  tag?: ITag;
}

export function initialTagEditState(): ITagEditState {
  return { open: false, tag: null };
}
