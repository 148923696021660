import { SizeTypes } from "models/store/files/sort/sortPayloads";
import { useFilesSelectors } from "Store/selectors/filesSelectors";

export const useGetItemSize = () => {
  const { size } = useFilesSelectors();

  return {
    isSmallSize: size === SizeTypes.SMALL,
    isMediumSize: size === SizeTypes.MEDIUM,
    isBigSize: size === SizeTypes.BIG
  };
};
