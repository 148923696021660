import PropTypes from "prop-types";
import React from "react";

import TasksSearch from "../../Components/TasksSearch/TasksSearch";
import styles from "./HistoryServicePanel.module.sass";

const HistoryServicePanel = ({ searchValue, handleChange, dateValue, setDateValue }) => {
  return (
    <div className={styles.wrap}>
      <TasksSearch
        searchValue={searchValue}
        handleChange={handleChange}
        dateValue={dateValue}
        setDateValue={setDateValue}
      />
    </div>
  );
};

export default HistoryServicePanel;
HistoryServicePanel.propTypes = {
  searchValue: PropTypes.string,
  handleChange: PropTypes.func,
  setDateValue: PropTypes.func,
  dateValue: PropTypes.string
};
