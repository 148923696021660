import MailAccountList from "containers/Postbox/MailAccountList/MailAccountList";
import MailServicePanel from "containers/Postbox/MailServicePanel/MailServicePanel";
import Button from "generalComponents/Button/Button";
import Clue from "generalComponents/Clue/Clue";
import List from "generalComponents/List/List";
import { POSTBOX_MODALS } from "generalComponents/variables/mail";
import { MAIL_PARAMS } from "generalComponents/variables/routing";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useFindPostbox } from "hooks/postboxHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IUpdateInboxPayload } from "models/store/postbox/postboxPayloads";
import { useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./MailPage.module.sass";

const MailPage = () => {
  const { __ } = useLocales();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { onFetchPostboxes, onSetPostboxModal, onUpdateInbox } = useActions();
  const [listCollapsed, setListCollapsed] = useState<boolean>(false);
  const { postboxes } = usePostboxSelectors();
  const { postbox } = useFindPostbox();
  const { rights } = useUserSelectors();
  const favorite = params.get(MAIL_PARAMS.FAVORITE);
  const unseen = params.get(MAIL_PARAMS.UNREAD);

  const getNewLettersInfo = () => {
    postboxes.forEach((postbox) => {
      const payload: IUpdateInboxPayload = {
        user: postbox.email,
        pass: postbox.pass,
        limit: 20,
        start: 0,
        userId: postbox.id,
        is_unseen: unseen ? "1" : undefined,
        is_favorite: favorite ? "1" : undefined
      };
      onUpdateInbox(payload);
    });
  };

  useEffect(() => {
    if (!rights.IS_MAIL) {
      navigate("/", { replace: true });
      return;
    }
    const controller = new AbortController();
    const messageError = __("Ошибка получения списка почтовых ящиков");
    onFetchPostboxes(messageError, controller);

    return () => {
      controller.abort();
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    // Запрос за входящими раз в минуту
    if (postboxes.length < 1) return;
    const timer = setInterval(getNewLettersInfo, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [postboxes]); //eslint-disable-line

  const onAddAccount = () => onSetPostboxModal({ open: true, type: POSTBOX_MODALS.CONNECT_ACCOUNT });
  const onCreateAccount = () => onSetPostboxModal({ open: true, type: POSTBOX_MODALS.CREATE_ACCOUNT });

  if (postboxes.length === 0) {
    return (
      <WorkAreaWrap>
        <MailServicePanel />
        <div className={styles.emptyAccount}>
          <div>
            <Clue text={__("Для использования функционала Почта, необходимо:")} icon="happy" />
          </div>
          <div className={styles.btns}>
            <Button
              onClick={onCreateAccount}
              variant={ButtonVariantType.BLUE}
              size={ButtonSizeType.LARGE}
              text={__("Созадть Email аккаунт 4Hub?")}
            />
            <Button
              onClick={onAddAccount}
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.LARGE}
              text={__("Подключить Аккаунт")}
            />
          </div>
        </div>
      </WorkAreaWrap>
    );
  }

  return (
    <div className={styles.wrap}>
      <List
        childrenBackground="#E7EBEA"
        title={__("Почта")}
        setListCollapsed={setListCollapsed}
        listCollapsed={listCollapsed}
        collapsedSize="small"
      >
        <MailAccountList listCollapsed={listCollapsed} />
      </List>
      <WorkAreaWrap>
        <MailServicePanel />
        {postbox ? <Outlet /> : <Clue text={__("Выберите аккаунт для просмотра")} icon="happy" />}
      </WorkAreaWrap>
    </div>
  );
};

export default MailPage;
