import api from "api";
import { EventAPI } from "api/EventAddAPI";
import Button from "generalComponents/Button/Button";
import { useGetItemView } from "generalComponents/Hooks/Files/useGetItemView";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import PopUp from "generalComponents/PopUp/PopUp";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { EVENT_TYPE, TYPE_ELEMENTS } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import md5 from "md5";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { initialFolderPassState } from "models/store/Cabinet/modals/folderPass";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFolder } from "models/store/folders/foldersStore";
import { useLocales } from "react-localized";
import { useParams } from "react-router-dom";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import AlertModal from "../AlertModal/AlertModal";
import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./FolderPassword.module.sass";

const FolderPassword = () => {
  const { __ } = useLocales();
  const { dirId } = useParams();
  const {
    folderPassModal: { type, folder, callbackOk, callbackCancel, code }
  } = useGlobalModalsSelectors();
  const { isLinesPreviewView } = useGetItemView();
  const { userInfo, uid } = useUserSelectors();
  const {
    onSetFolderPassModal,
    onEditFolders,
    onSetTopMessageModal,
    onSetConfirmCodeModal,
    onSetNewFolderPass,
    onSetUpdateFiles,
    onSetUpdateNextFiles
  } = useActions();
  const {
    value: password,
    onChange: onChangePassword,
    error: errorPassword,
    onError: onErrorPassword
  } = useInputValue();
  const {
    value: newPassword,
    onChange: onChangeNewPassword,
    error: errorNewPassword,
    onError: onErrorNewPassword
  } = useInputValue();
  const {
    value: checkPassword,
    onChange: onChangeCheckPassword,
    error: errorCheckPassword,
    onError: onErrorCheckPassword
  } = useInputValue();

  const closeModal = () => {
    onSetFolderPassModal(initialFolderPassState());
  };

  const updateFolder = (folder: IFolder): void => {
    if (isLinesPreviewView) {
      onSetUpdateNextFiles([folder]);
    } else {
      onSetUpdateFiles([folder]);
    }
    closeModal();
  };

  const onCancel = () => {
    callbackCancel && callbackCancel();
    closeModal();
  };

  const handleBlurNewPassword = (): void => {
    if (newPassword.length < 8) onErrorNewPassword(__("Пароль должен быть не менее 8-ми символов"));
  };
  const handleBlurCheckPassword = (): void => {
    if (newPassword !== checkPassword) onErrorCheckPassword(__("Пароли не совпадают"));
  };

  const getTitle = (): string => {
    switch (type) {
      case "addPassword":
        return __("Защита паролем");
      case "removePassword":
        return __("Снять защиту");
      case "enterPassword":
        return __("Папка защищена паролем");
      case "restorePassword":
        return __("Восстановление пароля");

      default:
        return "";
    }
  };
  const getBtnText = (): string => {
    switch (type) {
      case "addPassword":
        return __("Установить пароль");
      case "removePassword":
        return __("Снять защиту");
      case "enterPassword":
        return __("Войти");
      case "restorePassword":
        return __("Установить пароль");

      default:
        return "";
    }
  };
  const getDisabled = (): boolean => {
    switch (type) {
      case "addPassword":
      case "restorePassword":
        return (
          newPassword.length === 0 ||
          checkPassword.length === 0 ||
          Boolean(errorNewPassword) ||
          Boolean(errorCheckPassword)
        );
      case "removePassword":
      case "enterPassword":
        return password.length === 0 || Boolean(errorPassword);

      default:
        return true;
    }
  };

  const onForgotPassword = async () => {
    try {
      const params = {
        uid,
        id_dir: folder.id_dir,
        to: "tel"
      };
      const { data } = await api.get("/ajax/dir_pass_restore.php", { params });
      checkResponseStatus(data.ok);
      onSetConfirmCodeModal({
        open: true,
        type: "CHANGE_FOLDER_PASS",
        callbackSuccess: (code) =>
          onSetFolderPassModal({ open: true, type: "restorePassword", code, folder, callbackOk, callbackCancel }),
        callbackRepeat: () => onForgotPassword(),
        info: {
          uid,
          tel: userInfo.tel,
          email: userInfo.email
        }
      });
      onSetFolderPassModal({ open: false, type: "restorePassword", folder });
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  const onAddPassWord = () => {
    if (newPassword !== checkPassword) {
      onErrorCheckPassword(__("Пароли не совпадают"));
      return;
    }
    const params = {
      pass: newPassword,
      id_dirs: [folder.id_dir]
    };

    onEditFolders(params, EVENT_TYPE.PASS_ADD, updateFolder);
  };

  const onRemovePassword = async () => {
    if (md5(password) !== folder.pass) {
      onErrorPassword(__("Неверный пароль"));
      return;
    }
    const params = {
      pass: "",
      id_dirs: [folder.id_dir]
    };

    onEditFolders(params, EVENT_TYPE.PASS_DEL, updateFolder);
  };

  const onEnterPassword = async () => {
    if (md5(password) !== folder.pass) {
      const params = {
        uid,
        id_user_event: userInfo.id,
        event_type: EVENT_TYPE.GET_NO_ACCES,
        event_group: TYPE_ELEMENTS.FOLDERS,
        event_object: folder.id_dir
      };

      await EventAPI.add(params);

      return onErrorPassword(__("Неверный пароль"));
    }

    callbackOk(password);
    closeModal();
  };

  const onSetNewPassword = () => {
    const params = {
      id_dir: folder.id_dir,
      pass: newPassword,
      code: code
    };
    const messages = { success: __("Пароль изменен") };
    const onSuccess = (folder: IFolder) => {
      if (folder.id_dir === dirId) callbackOk && callbackOk(newPassword);
      closeModal();
      onSetUpdateFiles([folder]);
    };
    onSetNewFolderPass(params, messages, onSuccess);
  };

  const onSubmit = (): void => {
    switch (type) {
      case "addPassword": {
        onAddPassWord();
        break;
      }
      case "removePassword": {
        onRemovePassword();
        break;
      }
      case "enterPassword": {
        onEnterPassword();
        break;
      }
      case "restorePassword": {
        onSetNewPassword();
        break;
      }

      default:
        break;
    }
  };
  return (
    <PopUp set={onCancel} position="top" top={300} zIndex={105}>
      <div className={styles.wrap}>
        <HeaderModal title={getTitle()} onClose={closeModal} />
        {type === "enterPassword" && <AlertModal text={__(`Введите пароль к папке ${folder.name}`)} variant="green" />}

        <div className={styles.body}>
          <div className={styles.inputs}>
            {(type === "removePassword" || type === "enterPassword") && (
              <Input
                value={password}
                onChange={onChangePassword}
                error={Boolean(errorPassword)}
                errorText={errorPassword}
                isPass
                label={__("Пароль")}
              />
            )}
            {(type === "addPassword" || type === "restorePassword") && (
              <>
                <Input
                  value={newPassword}
                  onChange={onChangeNewPassword}
                  onBlur={handleBlurNewPassword}
                  error={Boolean(errorNewPassword)}
                  errorText={errorNewPassword}
                  isPass
                  label={__("Пароль")}
                />
                <Input
                  value={checkPassword}
                  onChange={onChangeCheckPassword}
                  onBlur={handleBlurCheckPassword}
                  error={Boolean(errorCheckPassword)}
                  errorText={errorCheckPassword}
                  isPass
                  label={__("Повторите пароль")}
                />
              </>
            )}
          </div>
          <div className={styles.footer}>
            {(type === "removePassword" || type === "enterPassword") && (
              <Button
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.SMALL}
                text={__("Забыли пароль?")}
                onClick={onForgotPassword}
              />
            )}
            <div className={styles.btns}>
              <Button
                variant={ButtonVariantType.EXRTA_LIGHT}
                size={ButtonSizeType.SMALL}
                text={__("Отмена")}
                onClick={onCancel}
              />
              <Button
                variant={ButtonVariantType.BLUE}
                size={ButtonSizeType.SMALL}
                text={getBtnText()}
                onClick={onSubmit}
                disabled={getDisabled()}
              />
            </div>
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default FolderPassword;
