import classNames from "classnames";
import { colorsCategory } from "generalComponents/collections/elements";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { useActions } from "hooks/useActions";
import { ITaskStatus } from "models/generalComponents/collections/tasks";
import { IMyTask } from "models/store/tasks/tasks";
import { IStatusKanbanProps } from "models/tasks/myTasks";
import React from "react";
import { taskOfStatus } from "utils/tasks";

import TaskDate from "../TaskDate/TaskDate";
import TaskKanban from "../TaskKanban/TaskKanban";
import styles from "./StatusKanban.module.sass";

const StatusKanban: React.FC<IStatusKanbanProps> = ({
  status,
  tasks,
  currentTask,
  setCurrentTask,
  currentStatus,
  setCurrentStatus
}): JSX.Element => {
  const { onEditTask, onUpdateTask } = useActions();
  const color = colorsCategory.find((color) => color.name === status.color);

  const dragStart = (task: IMyTask): void => {
    setCurrentTask(task);
  };

  const taskDragEnd = (): void => {
    setCurrentStatus(null);
    setCurrentTask(null);
  };

  const statusDragOver = (status: ITaskStatus): void => {
    setCurrentStatus(status);
  };

  const statusDragLeave = (): void => {
    setCurrentStatus(null);
  };
  const statusOnDrop = (e: React.DragEvent<HTMLDivElement>, status: ITaskStatus): void => {
    e.preventDefault();

    if (currentTask.id_status === status.status) {
      return;
    }

    const params = { id_task: currentTask.id, id_status: status.status };
    onUpdateTask({ ...currentTask, id_status: status.status });
    onEditTask(params, EVENT_TYPE.ADD, false, false);
    setCurrentTask(null);
    setCurrentStatus(null);
  };

  const renderTasks = (): JSX.Element[] => {
    return Object.entries(tasks).map(([key, values], i) => {
      const statusesTasks = taskOfStatus(status.status, values);
      return (
        <div key={key}>
          <TaskDate amountWeeks={Object.keys(tasks).length} idx={i + 1} time={key} values={statusesTasks} />
          <div className={styles.tasks}>
            {statusesTasks.map((task, i) => (
              <div
                key={task.id}
                draggable={true}
                onDragStart={() => dragStart(task)}
                onDragEnd={taskDragEnd}
                className={classNames(styles.taskBox, {
                  [styles.isDraggable]: task.id === currentTask?.id
                })}
              >
                <TaskKanban task={task} color={color} num={i + 1} />
              </div>
            ))}
          </div>
        </div>
      );
    });
  };
  return (
    <div
      className={classNames(styles.container, {
        [styles.isOverStatus]: status.status === currentStatus?.status
      })}
      onDrop={(e) => statusOnDrop(e, status)}
      onDragOver={() => statusDragOver(status)}
      onDragLeave={statusDragLeave}
    >
      <div className={styles.title}>
        <div className={styles.color} style={{ background: color.dark }} />
        <div className={styles.name}> {status.shortNameTask}</div>
      </div>
      <div className={styles.body}>{renderTasks()}</div>
    </div>
  );
};

export default StatusKanban;
