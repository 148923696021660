import { FC, MouseEvent } from "react";

import { W } from "../../GantSpace/GantSpace";
import { ITaskData, ITaskGantProps } from "../../models";
import styles from "./TaskGant.module.sass";

const TaskGant: FC<ITaskGantProps> = ({
  t,
  isMoving,
  isDrawLine,
  hoverRow,
  activeParentTask,
  setChildHover,
  activeChildTask,
  setParentHover,
  setTooltipe,
  setIsMoving,
  setMouseOffset,
  setActiveTask,
  setIsResizingLeft,
  setIsResizingRight,
  setIsDrawLine,
  setStartPosition,
  setEndPosition,
  setActiveChaildTask,
  setActiveParentTask
}) => {
  const showTaskName = (t: ITaskData) => setTooltipe({ x: t.x + t.widthTotal + 30, y: t.y + 16, text: t.title });
  const onHideTaskName = () => setTooltipe(null);

  const onMovedTask = (e: MouseEvent<SVGGElement>) => {
    const { offsetX } = e.nativeEvent;
    setMouseOffset(offsetX - t.x);
    setIsMoving(true);
    setActiveTask(t);
  };

  const onResizeLeft = (e: MouseEvent<SVGRectElement>) => {
    const { offsetX } = e.nativeEvent;
    setMouseOffset(offsetX - t.x);
    setIsResizingLeft(true);
    setActiveTask(t);
  };

  const onResizeRight = (e: MouseEvent<SVGRectElement>) => {
    const { offsetX } = e.nativeEvent;
    setMouseOffset(t.xEnd - offsetX);
    setIsResizingRight(true);
    setActiveTask(t);
  };

  const handleMouseDownChaild = () => {
    setIsDrawLine(true);
    setStartPosition({ x: t.x, y: t.y + 12 });
    setEndPosition({ x: t.x, y: t.y + 12 });
    setActiveChaildTask(t);
  };

  const handleMouseDownParent = () => {
    setIsDrawLine(true);
    setStartPosition({ x: t.xEnd, y: t.y + 12 });
    setEndPosition({ x: t.xEnd, y: t.y + 12 });
    setActiveParentTask(t);
  };

  return t.isTask ? (
    <g
      onMouseOver={() => !t.isText && showTaskName(t)}
      onMouseLeave={() => !t.isText && onHideTaskName()}
      className={styles.taskBlock}
    >
      <defs>
        <linearGradient id={`${t.id}`} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: t.statusColor, stopOpacity: 1 }} />
          <stop offset={`${t.xGradient}%`} style={{ stopColor: t.statusColor, stopOpacity: 1 }} />
          <stop offset={`${t.xGradient}%`} style={{ stopColor: "#FF0000", stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: "#FF0000", stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <g onMouseDown={onMovedTask} style={{ userSelect: "none", cursor: isMoving ? "grabbing" : "grab" }}>
        <rect
          x={t.x}
          y={t.y}
          width={t.widthPrev + t.widthOverdue}
          height="24"
          rx="4"
          ry="4"
          fill={t.isOverdue ? `url(#${t.id})` : t.statusColor}
        />

        {t.isText && (
          <text x={t.x + 10} y={t.y + 16} textAnchor="start" fontSize={12}>
            {t.title}
          </text>
        )}
      </g>

      {t.widthTotal >= W && (
        <g style={{ cursor: "ew-resize" }}>
          <rect x={t.x} y={t.y} height="24" width={W / 2} fill="transparent" onMouseDown={onResizeLeft} />
          <rect x={t.xEnd - 15} y={t.y} height="24" fill="transparent" width={W / 2} onMouseDown={onResizeRight} />
        </g>
      )}
      {!isDrawLine && (
        <g>
          <rect x={t.x - 24} y={t.y} height="24" width="24" fill="transparent" />
          <circle
            cx={t.x - 14}
            cy={t.y + 12}
            r="8"
            fill="#fff"
            stroke="#6E8680"
            strokeWidth="2"
            className={styles.point}
            onMouseDown={handleMouseDownChaild}
          />
        </g>
      )}
      {isDrawLine && hoverRow === t.id && activeParentTask && activeParentTask?.id !== t.id && (
        <circle
          cx={t.x - 14}
          cy={t.y + 12}
          className={styles.pointChild}
          r="8"
          fill="#fff"
          stroke="#6E8680"
          strokeWidth="2"
          onMouseEnter={() => setChildHover(t)}
          onMouseLeave={() => setChildHover(null)}
        />
      )}

      {!isDrawLine && (
        <g>
          <rect x={t.x + t.widthTotal} y={t.y} height="24" fill="transparent" width="24" />
          <circle
            cx={t.x + t.widthTotal + 14}
            cy={t.y + 12}
            r="8"
            fill="#fff"
            stroke="#6E8680"
            strokeWidth="2"
            className={styles.point}
            onMouseDown={handleMouseDownParent}
          />
        </g>
      )}
      {isDrawLine && hoverRow === t.id && activeChildTask && activeChildTask?.id !== t.id && (
        <circle
          cx={t.xEnd + 14}
          cy={t.y + 12}
          className={styles.pointChild}
          r="8"
          fill="#fff"
          stroke="#6E8680"
          strokeWidth="2"
          onMouseEnter={() => setParentHover(t)}
          onMouseLeave={() => setParentHover(null)}
        />
      )}
    </g>
  ) : (
    <path
      d={`
          M${t.x},${t.y - 2} L${t.x + W / 2},${t.y + 12} L${t.x},${t.y + 26} L${t.x - W / 2},${t.y + 12}Z
          `}
      fill="#EED328"
      onMouseOver={() => showTaskName(t)}
      onMouseLeave={() => onHideTaskName()}
      onMouseDown={(e) => onMovedTask(e)}
    />
  );
};

export default TaskGant;
