import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export const useFoldersSelectors = () => {
  const Folders = useTypeSelector((s) => s.Folders);
  const folders = Folders.folders;
  const currentFolder = Folders.currentFolder;
  const openFolder = Folders.openFolder;
  const folderPath = Folders.folderPath;

  return {
    folders,
    currentFolder,
    openFolder,
    folderPath
  };
};
