import { IFilterTypeElementsState } from "models/store/filterTypeElements/filterTypeElements";
import { IAddFilterTypeElements } from "models/store/filterTypeElements/filterTypeElementsActions";
import { INullifyFilterTypeElements } from "models/store/filterTypeElements/filterTypeElementsActions";
import { FilterTypeElementsTypes } from "Store/types/filterTypeElements";

export const onAddFilterTypeElements = (payload: IFilterTypeElementsState): IAddFilterTypeElements => ({
  type: FilterTypeElementsTypes.ADD_FILTER,
  payload
});

export const onNullifyFiltersTypeElements = (): INullifyFilterTypeElements => ({
  type: FilterTypeElementsTypes.NULLIFY_FILTERS
});
