export const getTheRestorePath = ({
  path,
  id_dir,
  id_parent,
  search
}: {
  path: string;
  id_dir: string;
  id_parent: string;
  search?: string;
}) => {
  const searchParams: string = `${search ? search : ""}`;

  if (path && id_dir && id_parent) {
    return `/${path}/${id_parent === "0" ? id_dir : id_parent}${searchParams}`;
  }

  if (path && !id_dir && id_parent) {
    return `/${path}/${id_parent}${searchParams}`;
  }

  if (path && id_dir && !id_parent) {
    return `/${path}/${id_dir}${searchParams}`;
  }

  if (path && !id_dir && !id_parent) {
    return `/${path}${searchParams}`;
  }

  return "/";
};
