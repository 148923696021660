import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import api from "api";
import classNames from "classnames";
import TaskFile from "containers/JoinProject/TaskFile/TaskFile";
import TaskPrimEditor from "containers/JoinProject/TaskPrimEditor/TaskPrimEditor";
import TaskSidebar from "containers/JoinProject/TaskSidebar/TaskSidebar";
import TaskUploadFile from "containers/JoinProject/TaskUploadFile/TaskUploadFile";
import { startOfHour } from "date-fns";
import Button from "generalComponents/Button/Button";
import Input from "generalComponents/Input/Input";
import Loader from "generalComponents/Loaders/4HUB";
import PopUp from "generalComponents/PopUp/PopUp";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { PROJECT_PATH, ROUTES } from "generalComponents/variables/routing";
import { useWebSocketContext } from "generalComponents/WebSocketsProvider/WebSocketsProvider";
import {
  useFindCurrentSprint,
  useFindSprint,
  useGetDuration,
  useGetTaskEnd,
  useSortedStatuses,
  useUsersToProject
} from "hooks/joinProjectHooks";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { LoaderTypes } from "models/generalComponents/loader";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IFile } from "models/store/files/files";
import { IProjectTask, STATUSES, TASK_PRIORITY, TASK_TYPE } from "models/store/joinProjects/joinProgects";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { dateISO } from "utils/dateToISO";

import HeaderModal from "../HeaderModal/HeaderModal";
import styles from "./CreateProjectTask.module.sass";

const CreateProjectTask = () => {
  const { __ } = useLocales();
  const navigate = useNavigate();
  const { onSetCreateProjectTaskModal, onSetTopMessageModal, onUpdateTaskProject, onSetTaskOverdueModal } =
    useActions();
  const { sortedStatuses } = useSortedStatuses();
  const { id } = useParams();
  const getDateEnd = useGetTaskEnd();
  const getDuration = useGetDuration();
  const findSprint = useFindSprint();
  const currentSprint = useFindCurrentSprint();

  const { sendMessage } = useWebSocketContext();
  const users_to = useUsersToProject();

  const { createProjectTask } = useGlobalModalsSelectors();
  const { task } = createProjectTask;
  const _isAdd = createProjectTask.type === "add";
  const { userInfo, uid, theme } = useUserSelectors();
  const { project } = useJoinProjectsSelectors();
  const _oneWorkDay = project.work_hours.length * 60;

  const [name, setName] = useState<string>(task.name);
  const [priority, setPriority] = useState<TASK_PRIORITY>(task.priority);
  const [type, setType] = useState<TASK_TYPE>(task.id_type);
  const [status, setStatus] = useState<string>(task.id_status || sortedStatuses[0].id);
  const [team, setTeam] = useState<string>(task.id_team);
  const [author, _] = useState<string>(_isAdd ? userInfo.id : task.author);
  const [executor, setExecutor] = useState<string>(task.id_executor);
  const [sprints, setSprints] = useState<string[]>(task.id_sprints);

  const [duration, setDuration] = useState<number>(_isAdd ? _oneWorkDay : task.duration);
  const [dateStart, setDateStart] = useState<Date>(initialDateStart());
  const [dateEnd, setDateEnd] = useState<Date>(initialDateEnd());

  const [prim, setPrim] = useState<string>(task.prim);
  const [uploadFiles, setUploadFiles] = useState<(IFile | { file: File; fid: string })[]>([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [files4hub, setFiles4hub] = useState<IFile[]>([]);

  function initialDateStart() {
    if (task.date_start) {
      return new Date(task.date_start);
    }
    if (sprints.length) {
      const _sprint = findSprint(sprints[0]);
      let d = null;
      if (currentSprint?.id === _sprint?.id) {
        d = startOfHour(new Date()).setHours(project.work_hours[0]);
      } else {
        d = new Date(_sprint?.date_start).setHours(project.work_hours[0]);
      }

      return new Date(d);
    }
    return null;
  }

  function initialDateEnd() {
    if (task.date_end) {
      return new Date(task.date_end);
    }
    if (sprints.length) {
      const d = new Date(findSprint(sprints[0])?.date_start).setHours(project.work_hours[0]);
      return getDateEnd(new Date(d), duration);
    }
    return null;
  }

  const fetchFiles = async () => {
    try {
      const params = { uid, id_task: task.id };
      const { data } = await api.get(`/ajax/task_file_list.php`, { params });
      checkResponseStatus(data.ok);
      setFiles(data.files);
      setFiles4hub(data.files_4hub);
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    }
  };

  useEffect(() => {
    if (task.id) fetchFiles();
  }, []); // eslint-disable-line

  const closeModal = () => onSetCreateProjectTaskModal({ open: false, task: null, type: "add" });

  const changeName = (e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);

  const deleteUploadFile = (fid: string) => setUploadFiles((prev) => prev.filter((item) => item.fid !== fid));

  const deleteFile = async (fid: string) => {
    setFiles((prev) => prev.filter((item) => item.fid !== fid));
    const params = { uid, id_task: task.id, fid };
    await api.get(`/ajax/task_file_del.php`, { params });
  };

  const deleteFile4Hub = (fid: string) => {
    const formData = new FormData();
    const fids = files4hub.filter((f) => f.fid !== fid).map((f) => f.fid);
    formData.append("id_project", id);
    formData.append("id_task", task.id);
    formData.append("fids", JSON.stringify(fids));
    onUpdateTaskProject({ data: formData });
    setFiles4hub((prev) => prev.filter((f) => f.fid !== fid));
  };

  const sendTaskWS = (task: IProjectTask) => {
    sendMessage(
      JSON.stringify({
        action: "task_info",
        task,
        uid,
        users_to,
        actionType: _isAdd ? "add" : "edit"
      })
    );
  };

  const changeType = (type: TASK_TYPE) => {
    if (type === TASK_TYPE.EPIC) {
      setDateEnd(null);
      setDuration(0);
    }
    if (type === TASK_TYPE.TASK) {
      setDateEnd(getDateEnd(dateStart, _oneWorkDay));
      setDuration(_oneWorkDay);
    }
    setType(type);
  };

  const changeSprints = (arr: string[]) => {
    if (arr.length === 0) {
      setDateStart(null);
      setDateEnd(null);
      setDuration(_oneWorkDay);
    }
    if (arr.length === 1) {
      const startSprint = new Date(findSprint(arr[0])?.date_start);
      const d = startSprint.setHours(project.work_hours[0]);
      setDateStart(new Date(d));
      setDateEnd(getDateEnd(new Date(d), _oneWorkDay));
      setDuration(_oneWorkDay);
    }
    if (arr.length > 1) {
      const _sprints = project.sprints
        .filter((el) => arr.includes(el.id))
        .sort((a, b) => new Date(a.date_start).getTime() - new Date(b.date_start).getTime());
      const firstSprint = _sprints[0];
      const lastSprint = _sprints[_sprints.length - 1];
      const dStart = new Date(firstSprint?.date_start).setHours(project.work_hours[0]);
      const dEndSprint = new Date(lastSprint?.date_start).setHours(project.work_hours[0]);
      setDateStart(new Date(dStart));
      const dEnd = getDateEnd(new Date(dEndSprint), _oneWorkDay);
      setDateEnd(dEnd);
      const duration = getDuration(new Date(dStart), dEnd);
      setDuration(duration);
    }

    setSprints(arr);
  };

  const changeDuration = (duration: number) => {
    setDuration(duration);
    setDateEnd(getDateEnd(dateStart, duration));
  };

  const changeDateStart = (dateStart: Date) => {
    const dateEnd = getDateEnd(dateStart, duration);
    setDateStart(dateStart);
    setDateEnd(dateEnd);
  };

  const changeDateEnd = (dateEnd: Date) => {
    const duration = getDuration(dateStart, dateEnd);
    setDateEnd(dateEnd);
    setDuration(duration);
  };

  const getSprint = (task: IProjectTask): string => {
    if (task.id_sprints.length === 0) return "";
    return `?sprint=${task.id_sprints[0]}`;
  };

  const onCreate = async (isOpen: boolean) => {
    const fids = uploadFiles.filter((f) => (f as IFile)?.id_dir).map((f) => f.fid);
    const myFiles = uploadFiles.filter((f) => !(f as IFile)?.id_dir).map((f) => f.file);

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("priority", priority);
      formData.append("id_type", type);
      formData.append("id_status", status);
      formData.append("id_team", team);
      formData.append("author", author);
      formData.append("id_executor", executor);
      formData.append("id_sprints", JSON.stringify(sprints));
      formData.append("duration", String(duration));
      formData.append("date_start", dateISO(dateStart));
      formData.append("date_end", dateEnd ? dateISO(dateEnd) : "");
      formData.append("prim", prim);
      formData.append("id_project", id);
      formData.append("uid", uid);
      fids.length && formData.append("fids", JSON.stringify(fids));
      myFiles.length && myFiles.forEach((file) => formData.append("myfile[]", file));

      const { data } = await api.post(`/ajax/task_add.php`, formData);
      checkResponseStatus(data.ok);
      sendTaskWS(data.task);
      closeModal();
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: __("Создано") });
      isOpen && navigate(`/${ROUTES.PROJECT}/${id}/${PROJECT_PATH.TASK}/${data.task.id}${getSprint(data.task)}`);
    } catch {
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.ERROR });
    } finally {
      setLoading(false);
    }
  };

  const onPrevUpdate = () => {
    const targetStatusDone = project.statuses.find((el) => el.id === status)?.system_name === STATUSES.DONE;
    const prevStatusIsOverdue =
      project.statuses.find((el) => el.id === task.id_status)?.system_name === STATUSES.OVERDUE;
    const dateOverdue = task.date_long || dateEnd;
    const isOverdue = task.date_long;

    if (
      task.id_status !== status &&
      prevStatusIsOverdue &&
      !targetStatusDone &&
      dateOverdue &&
      new Date(dateOverdue) < new Date()
    ) {
      onSetTaskOverdueModal({
        open: true,
        task,
        callbackOk: () => {
          onUpdate();
        },
        callbackCancel: () => {}
      });
      return;
    }

    if (task.id_status !== status && isOverdue && targetStatusDone) {
      const time_long = getDuration(new Date(task.date_end), new Date());
      const date_long = getDateEnd(new Date(task.date_end), time_long);
      onUpdate(time_long, dateISO(date_long));
      return;
    }
    onUpdate();
  };

  const onUpdate = (time_long: number = 0, date_long: string = "") => {
    setLoading(true);

    const fids = uploadFiles.filter((f) => (f as IFile)?.id_dir).map((f) => f.fid);
    const myFiles = uploadFiles.filter((f) => !(f as IFile)?.id_dir).map((f) => f.file);

    const formData = new FormData();
    formData.append("id_project", id);
    formData.append("id_task", task.id);
    formData.append("name", name);
    task.priority !== priority && formData.append("priority", priority);
    task.id_type !== type && formData.append("id_type", type);
    task.id_status !== status && formData.append("id_status", status);
    task.id_team !== team && formData.append("id_team", team);
    task.id_executor !== executor && formData.append("id_executor", executor);
    task.duration !== +duration && formData.append("duration", String(duration));
    new Date(task.date_start) !== dateStart && formData.append("date_start", dateISO(dateStart));
    new Date(task.date_end) !== dateEnd && formData.append("date_end", dateEnd ? dateISO(dateEnd) : "");
    time_long && formData.append("time_long", String(time_long));
    date_long && formData.append("date_long", date_long);

    formData.append("id_sprints", JSON.stringify(sprints));

    task.prim !== prim && formData.append("prim", prim);
    fids.length && formData.append("fids", JSON.stringify(fids));
    myFiles.length && myFiles.forEach((file) => formData.append("myfile[]", file));

    const updateTask = (task: IProjectTask) => {
      sendTaskWS(task);
      setLoading(false);
      closeModal();
    };

    onUpdateTaskProject({ data: formData, message: __("Сохранено"), cb: updateTask });
  };

  return (
    <PopUp set={closeModal} position="top" top={100}>
      <div className={styles.wrap}>
        <HeaderModal title={_isAdd ? __("Добавить задачу") : __("Редактировать")} onClose={closeModal} />
        <div className={styles.body}>
          <div className={styles.main}>
            <Input value={name} onChange={changeName} label={__("Краткое содержание")} />
            <div className={styles.editorWrapper}>
              <TaskPrimEditor prim={prim} setPrim={setPrim} setUploadFiles={setUploadFiles} />
              <span className={styles.label}>{__("Описание")}</span>
            </div>

            <ul className={classNames(styles.fileList, `scrollbar-thin-${theme}`)}>
              {files.map((file) => (
                <TaskFile key={file.fid} file={file} deleteFile={deleteFile} />
              ))}
              {files4hub.map((file) => (
                <TaskFile key={file.fid} file={file} deleteFile={deleteFile4Hub} />
              ))}
              {uploadFiles.length > 0 &&
                uploadFiles.map((file) =>
                  "id_dir" in file ? (
                    <TaskFile key={file.fid} file={file} deleteFile={deleteUploadFile} />
                  ) : (
                    <TaskUploadFile key={file.fid} file={file} deleteFile={deleteUploadFile} />
                  )
                )}
            </ul>
          </div>
          <TaskSidebar
            priority={priority}
            setPriority={setPriority}
            type={type}
            setType={changeType}
            status={status}
            setStatus={setStatus}
            team={team}
            setTeam={setTeam}
            author={author}
            executor={executor}
            setExecutor={setExecutor}
            sprints={sprints}
            setSprints={changeSprints}
            duration={duration}
            setDuration={changeDuration}
            dateStart={dateStart}
            setDateStart={changeDateStart}
            dateEnd={dateEnd}
            setDateEnd={changeDateEnd}
          />
        </div>
        <div className={styles.btns}>
          <Button
            variant={ButtonVariantType.EXRTA_LIGHT}
            size={ButtonSizeType.SMALL}
            text={__("Отмена")}
            onClick={closeModal}
          />
          {_isAdd && (
            <Button
              variant={ButtonVariantType.EXRTA_LIGHT}
              size={ButtonSizeType.SMALL}
              text={__("Добавить и открыть")}
              onClick={() => onCreate(true)}
              disabled={!name}
            />
          )}
          <Button
            variant={ButtonVariantType.BLUE}
            size={ButtonSizeType.SMALL}
            text={_isAdd ? __("Добавить") : __("Сохранить")}
            onClick={_isAdd ? () => onCreate(false) : onPrevUpdate}
            disabled={!name}
          />
        </div>
        {loading && (
          <div className={styles.loader}>
            <Loader
              type={LoaderTypes.BOUNCING_DOTS}
              position="absolute"
              background="none"
              zIndex={5}
              width="200px"
              height="200px"
              containerType="bounceDots"
            />
          </div>
        )}
      </div>
    </PopUp>
  );
};

export default CreateProjectTask;
