import classNames from "classnames";
import Avatar from "generalComponents/Avatar/Avatar";
import { useOutsideClick } from "generalComponents/Hooks/useOutsideClick";
import { IContactShort } from "hooks/useContactsList";
import { ChangeEvent, FC, useMemo, useRef, useState } from "react";

import styles from "./InputMail.module.sass";

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  name?: string;
  options?: IContactShort[];
}

// const filtredContacts: IContactShort[] = useMemo(() => {
//   return combineContacts.filter((item: IContactShort) => {
//     console.log("item", item.email.toLowerCase().includes(to.toLowerCase()), item.email, to);
//     const includesName = item.name.toLowerCase().includes(to.toLowerCase());
//     const includesSurname = item.sname.toLowerCase().includes(to.toLowerCase());
//     const includesEmail = item.email && item.email.toLowerCase().includes(to.toLowerCase());
//     if (to) {
//       return includesName || includesSurname || includesEmail;
//     }
//     return item;
//   });
// }, [combineContacts, to]);
const InputMail: FC<Props> = ({ value, onChange, placeholder, name, options }) => {
  const placeHolderRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => setIsOpen(false));
  const searchContacts = useMemo(() => {
    if (!options) return [];
    if (value.length) {
      return options.filter((option) => {
        const includesName = option.name && option.name.toLowerCase().includes(value.toLowerCase());
        const includesSurname = option.name && option.sname.toLowerCase().includes(value.toLowerCase());
        const includesEmail = option.email && option.email.toLowerCase().includes(value.toLowerCase());
        return includesName || includesSurname || includesEmail;
      });
    } else {
      return options.filter((option) => option.email.length > 0);
    }
  }, [value, options]);

  const onFocusHandler = () => {
    setIsOpen((prev) => !prev);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const chooseContactHandler = (contact: IContactShort) => {
    onChange(contact.email[0]);
    setIsOpen(false);
  };

  return (
    <div className={styles.inputMailWrapper} ref={ref}>
      <div className={styles.box}>
        <label htmlFor={name} ref={placeHolderRef} className={styles.inputMailPlaceholder}>
          {placeholder}
        </label>
        <input
          value={value}
          name={name}
          id={name}
          autoComplete={searchContacts.length === 0 ? "on" : "off"}
          onChange={onChangeHandler}
          className={styles.inputMail}
          onFocus={onFocusHandler}
        />
      </div>
      {Boolean(searchContacts.length > 0) && isOpen && (
        <div className={classNames("scrollbar-thin-blue", styles.optionsWrapper)}>
          {searchContacts.map((option) => (
            <div className={styles.option} key={option.email} onClick={() => chooseContactHandler(option)}>
              <div className={styles.optionLeft}>
                <Avatar name={`${option.name} ${option.sname}`} />
              </div>
              <div className={styles.optionRight}>
                <div className={styles.name}>{option.name}</div>
                <div className={styles.email}>{option.email}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InputMail;
