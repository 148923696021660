export enum PostboxTypes {
  SET_POSTBOXES = "SET_POSTBOXES",
  UPDATE_POSTBOX_FOLDERS = "UPDATE_POSTBOX_FOLDERS",
  ADD_POSTBOX = " ADD_POSTBOX",
  REMOVE_POSTBOX = "REMOVE_POSTBOX",
  SET_LOADER = "SET_LOADER",
  SET_LETTERS = "SET_LETTERS",
  CLEAR_LETTERS = "CLEAR_LETTERS",
  CHANGE_FLAG = "CHANGE_FLAG",
  DELETE_LETTER = "DELETE_LETTER",
  SET_CURRENT_LETTER = "SET_CURRENT_LETTER",
  CHOOSE_LETTER = "CHOOSE_LETTER",
  CANCEL_CHOOSE_LETTER = "CANCEL_CHOOSE_LETTER",
  CANCEL_CHOOSE_SCHEDULE_LETTER = "CANCEL_CHOOSE_SCHEDULE_LETTER",
  SELECT_ALL = "SELECT_ALL",
  NULLIFY_SELECTED = "NULLIFY_SELECTED",
  CHANGE_PASSWORD_POSTBOX = "CHANGE_PASSWORD_POSTBOX",
  INCREASE_UNREAD = "INCREASE_UNREAD",
  DECREASE_UNREAD = "DECREASE_UNREAD",
  UPDATE_UNREAD = "UPDATE_UNREAD",
  ADD_NEW_LETTERS = "ADD_NEW_LETTERS",
  SET_SEARCH_LETTER = "SET_SEARCH_LETTER",
  CHANGE_NAME_POSTBOX = "CHANGE_NAME_POSTBOX"
}
