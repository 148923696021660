import { noteTitles, useNotesTitles } from "collections/notes";
import NoteItem from "containers/Notes/NotesCategories/components/NoteItem/NoteItem";
import { useAllSelectedSearchParams } from "hooks/useSelectedSearchParams";
import { INote } from "models/store/notes/notes";
import { useEffect, useMemo, useState } from "react";
import { useNotesSelectors } from "Store/selectors/notesSelectors";

import styles from "./NotesUncategories.module.sass";

const NotesUncategories = ({ search }: { search: string }) => {
  const { notes } = useNotesSelectors();
  const [sortedNotes, setSortedNotes] = useState<{ [date: string]: INote[] }>({});
  const titles = useNotesTitles();
  const { color, emo, tags } = useAllSelectedSearchParams();

  const sortNotesByDate = (notes: INote[]) => {
    const sorted: { [date: string]: INote[] } = {};
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    notes
      ?.filter((item) => !item.id_dep)
      .forEach((note) => {
        const noteDate = new Date(note.ut);
        const todayMidnight = new Date(today);
        todayMidnight.setHours(0, 0, 0, 0);
        const yesterdayMidnight = new Date(yesterday);
        yesterdayMidnight.setHours(0, 0, 0, 0);

        let category = "";
        if (noteDate >= todayMidnight) {
          category = noteTitles.TODAY;
        } else if (noteDate >= yesterdayMidnight) {
          category = noteTitles.YESTERDAY;
        } else if (noteDate >= lastWeek) {
          category = noteTitles.LAST_7_DAYS;
        } else {
          category = noteTitles.LAST_30_DAYS;
        }

        // Instead of push, use unshift to add notes at the beginning
        if (!sorted[category]) {
          sorted[category] = [];
        }
        sorted[category].unshift(note);
      });

    for (const key in sorted) {
      sorted[key].sort((a, b) => {
        return new Date(b.ut).getTime() - new Date(a.ut).getTime();
      });
    }

    return sorted; // Возвращаем отсортированные заметки
  };

  const filteredNotes = useMemo(() => {
    return notes.filter((item) => {
      const containsSearchText = JSON.parse(item.prim).blocks.some((block: any) => block.text.includes(search));

      return (
        !item.id_dep &&
        (!color.selectedValue || item.color === color.selectedValue) &&
        (!emo.selectedValue || item.emo === emo.selectedValue) &&
        (!tags.selectedValue || tags.selectedValue?.some((tag) => item.tags?.includes(tag))) &&
        (!search || containsSearchText)
      );
    });
  }, [notes, color.selectedValue, emo.selectedValue, tags.selectedValue, search]);

  useEffect(() => {
    const sorted = sortNotesByDate(filteredNotes);
    setSortedNotes(sorted);
  }, [filteredNotes]);

  return (
    <>
      {Object.keys(noteTitles).map(
        (category) =>
          sortedNotes[category] &&
          sortedNotes[category].length > 0 && (
            <div key={category}>
              <div className={styles.header}>{titles[category]}</div>
              <div className={styles.notes}>
                {sortedNotes[category].map((item) => (
                  <NoteItem key={item.id} item={item} />
                ))}
              </div>
            </div>
          )
      )}
    </>
  );
};

export default NotesUncategories;
