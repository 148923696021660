import api from "api";
import { ReactComponent as BlockIcon } from "assets/icons/block.svg";
import { ReactComponent as CrossIcon } from "assets/PrivateCabinet/cross.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import React from "react";
import { useLocales } from "react-localized";
import { usePostboxSelectors } from "Store/selectors/postboxSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { IAccount } from "../PostboxSettings";
import styles from "./SessionsItem.module.sass";

interface ISessionsItemProps {
  item: IAccount;
  onRemoveItem: (email: string, id: string, val: number) => void;
  blocked: boolean;
}
const SessionsItem: React.FC<ISessionsItemProps> = ({ item, onRemoveItem, blocked }) => {
  const { __ } = useLocales();
  const { onSetTopMessageModal } = useActions();
  const { uid } = useUserSelectors();
  const { postboxes } = usePostboxSelectors();

  const onBlockAgent = async (): Promise<void> => {
    try {
      const params = {
        uid,
        email: item.email,
        id_agent: item.id
      };
      const { data } = await api.get("/ajax/email_agent_block.php", { params });
      checkResponseStatus(data.ok);
      onRemoveItem(item.email, item.id, 1);
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: __("Пользователь заблокирован") });
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR,
        message: __("Что-то пошло не так. Попробуйте еще раз")
      });
    }
  };

  const onUnblockAgent = async (): Promise<void> => {
    try {
      const params = {
        uid,
        email: item.email,
        id_agent: item.id
      };
      const { data } = await api.get("/ajax/email_agent_unblock.php", { params });
      checkResponseStatus(data.ok);
      onRemoveItem(item.email, item.id, 0);
      onSetTopMessageModal({ open: true, type: ITopMessageTypes.SUCCESS, message: __("Пользователь разблокирован") });
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR,
        message: __("Что-то пошло не так. Попробуйте еще раз")
      });
    }
  };

  const onCloseAgent = async () => {
    const pass = postboxes.find((el) => el.email === item.email)?.pass;
    try {
      const params = {
        uid,
        email: item.email,
        id_agent: item.id,
        pass
      };
      const { data } = await api.get("ajax/email_close.php", { params });
      checkResponseStatus(data.ok);
      onRemoveItem(item.email, item.id, 1);
    } catch {
      onSetTopMessageModal({
        open: true,
        type: ITopMessageTypes.ERROR,
        message: __("Что-то пошло не так. Попробуйте еще раз")
      });
    }
  };

  return (
    <li className={classNames(styles.item, { [styles.start]: item.is_my })}>
      <div className={styles.info}>
        {item.info && (
          <p className={styles.browser}>
            {item.info?.device_type} {item.info?.platform} ({item.info?.browser})
          </p>
        )}
        <p className={styles.ip}>
          {item.geo_info.city}, {item.geo_info.country} ({item.ip})
        </p>
      </div>
      {blocked ? (
        <Button
          type="button"
          onClick={onUnblockAgent}
          variant={ButtonVariantType.EXRTA_LIGHT}
          size={ButtonSizeType.LARGE}
          text={__("Разблокировать")}
        />
      ) : (
        <>
          {item.is_my ? (
            <p className={styles.text}>{__("Это устройство")}</p>
          ) : (
            <div className={styles.btns}>
              <Button
                type="button"
                onClick={onBlockAgent}
                variant={ButtonVariantType.OPACITY}
                size={ButtonSizeType.MEDIUM}
                iconL={<BlockIcon />}
                isSquare
                title={__("Завершить сессию и заблокировать устройство ")}
              />
              <Button
                type="button"
                onClick={onCloseAgent}
                variant={ButtonVariantType.OPACITY}
                size={ButtonSizeType.MEDIUM}
                iconL={<CrossIcon className={styles.crossIcon} />}
                isSquare
                title={__("Завершить сессию")}
              />
            </div>
          )}
        </>
      )}
    </li>
  );
};

export default SessionsItem;
