import { ReactComponent as UserIcon } from "assets/PrivateCabinet/user-setting.svg";
import classNames from "classnames";
import ContextMenu from "generalComponents/ContextMenu";
import ContextMenuItem from "generalComponents/ContextMenu/ContextMenuItem";
import ThreeDots from "generalComponents/ThreeDots/ThreeDots";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_ADMIN_ROLES, PROJECT_CONTEXT_MENU, PROJECT_MODALS } from "generalComponents/variables/project";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { onEditProject } from "Store/actions/JoinProjectActions";
import { onDeleteUser, onEditUser } from "Store/actions/ProjectActions";
import { ProjectTypes } from "Store/types/projectTypes";
import { selectedItemProps } from "types/Contacts";
import { projectUserProps } from "types/Project";

import { onSetModals } from "../../../../../../../Store/actions/ModalActions";
import UserBlock from "../../../Components/UserBlock/UserBlock";
import { getNames, useContextProjectUser, useProjectMsg } from "../../../helpers";
import styles from "./UserProject.module.sass";

const UserProject = ({ user }) => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const contextMenu = useContextProjectUser();
  const project = useSelector((s) => s.Projects.project);
  const selectUser = useSelector((s) => s.Projects.selectUser);
  const isMyTasks = useMatch("/project/:id/myTask");
  const isTeamTasks = useMatch("/project/:id/team");

  const userInfo = useSelector((s) => s.user.userInfo);
  const MSG = useProjectMsg();
  const [mouseParams, setMouseParams] = useState(null);

  const filtredContextMenu = useMemo(
    () =>
      contextMenu.filter((item) => {
        if (project.is_read === PROJECT_ADMIN_ROLES.ADMIN) {
          if (user.id_user === userInfo.id_user) {
            return (
              item.type !== PROJECT_CONTEXT_MENU.DEL_USER &&
              item.type !== PROJECT_CONTEXT_MENU.DEL_SUBADMIN &&
              item.type !== PROJECT_CONTEXT_MENU.APPOINT_SUBADMIN
            );
          } else {
            if (user.is_read === PROJECT_ADMIN_ROLES.SUBADMIN) {
              return (
                item.type !== PROJECT_CONTEXT_MENU.LEAVE_PROJECT &&
                item.type !== PROJECT_CONTEXT_MENU.APPOINT_OF_ADMIN &&
                item.type !== PROJECT_CONTEXT_MENU.ADD_USER &&
                item.type !== PROJECT_CONTEXT_MENU.APPOINT_SUBADMIN
              );
            }
            return (
              item.type === PROJECT_CONTEXT_MENU.ADD_ROLE ||
              item.type === PROJECT_CONTEXT_MENU.DEL_USER ||
              item.type === PROJECT_CONTEXT_MENU.ADD_TASK ||
              item.type === PROJECT_CONTEXT_MENU.APPOINT_SUBADMIN ||
              item.type === PROJECT_CONTEXT_MENU.USER_INFO
            );
          }
        }
        if (project.is_read === PROJECT_ADMIN_ROLES.SUBADMIN) {
          return (
            item.type === PROJECT_CONTEXT_MENU.ADD_ROLE ||
            item.type === PROJECT_CONTEXT_MENU.ADD_TASK ||
            item.type === PROJECT_CONTEXT_MENU.ADD_USER
          );
        }

        return item.type === PROJECT_CONTEXT_MENU.USER_INFO || item.type === PROJECT_CONTEXT_MENU.ADD_TASK;
      }),
    [contextMenu, project.is_read, user.id_user, user.is_read, userInfo.id_user]
  );

  const onClickDots = (e) =>
    setMouseParams({
      x: e.clientX,
      y: e.clientY,
      width: 240,
      height: 25
    });
  const closeContextMenu = () => setMouseParams(null);

  const roleHandler = () => {
    project.roles.length > 0
      ? dispatch(
          onSetModals(MODALS.PROJECT, {
            type: PROJECT_MODALS.EDIT_USER,
            params: [{ ...user }]
          })
        )
      : dispatch(
          onSetModals(MODALS.PROJECT, {
            type: PROJECT_MODALS.ADD_ROLES,
            params: {}
          })
        );
  };

  const onDeleteSubAdmin = () => {
    const params = [
      {
        ...user,
        is_read: 0,
        id_project: project.id
      }
    ];
    const messages = {
      error: MSG.ERROR,
      success: {
        title: __("Заместитель администратора успешно удален"),
        text: `${__("Участник ")}${getNames(params)}${__(
          " больше не является заместителем администратора в проекте "
        )}“${project.name}”`
      }
    };
    dispatch(onEditUser(params, messages, PROJECT_MODALS.PROJECT_SUCCESS));
  };

  const callbacks = {
    [PROJECT_CONTEXT_MENU.ADD_ROLE]: () => roleHandler(),
    [PROJECT_CONTEXT_MENU.USER_INFO]: () =>
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.USER_INFO, params: { ...user } })),

    [PROJECT_CONTEXT_MENU.ADD_USER]: () =>
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.ADD_USERS, params: { ...project } })),
    [PROJECT_CONTEXT_MENU.APPOINT_OF_ADMIN]: () =>
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.APPOINT_SUBADMIN })),
    [PROJECT_CONTEXT_MENU.DEL_SUBADMIN]: () => onDeleteSubAdmin(),
    [PROJECT_CONTEXT_MENU.ADD_TASK]: () =>
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.ADD_TASK, params: { id_executor: [user.id_user] } })),
    [PROJECT_CONTEXT_MENU.DEL_USER]: () => {
      const params = {
        id_user: user.id_user,
        id_project: project.id
      };
      const messages = {
        error: MSG.ERROR,
        success: MSG.DEL_USER
      };
      dispatch(onDeleteUser(params, messages));
    },
    [PROJECT_CONTEXT_MENU.LEAVE_PROJECT]: () => {
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.LEAVE_PROJECT }));
    },
    [PROJECT_CONTEXT_MENU.APPOINT_SUBADMIN]: () => {
      const params = [
        {
          ...user,
          id_project: project.id,
          is_read: PROJECT_ADMIN_ROLES.SUBADMIN
        }
      ];
      const messages = {
        error: MSG.ERROR,
        success: {
          title: __("Заместитель администратора успешно добавлен"),
          text: `${__("Вы добавили администратора проекта ")} “${project.name}”.
            ${__("Права доступа предоставлены ")} “${user.name} ${user.sname}”`
        }
      };
      dispatch(onEditUser(params, messages, PROJECT_MODALS.PROJECT_SUCCESS));
      dispatch(onEditProject({ ...project, show_message: PROJECT_ADMIN_ROLES.SUBADMIN }, { error: MSG.ERROR }));
    }
  };

  const handleClick = () => {
    if (isMyTasks) {
      return;
    }
    const payload = selectUser === user.id_user ? "0" : user.id_user;
    dispatch({ type: ProjectTypes.SELECT_USER, payload });
  };

  return (
    <div
      className={classNames(styles.user, {
        [styles.select]: selectUser === user.id_user || (selectUser === "0" && !isTeamTasks)
      })}
    >
      <div className={styles.info} onClick={handleClick}>
        <UserBlock user={user} variant="light" />
        {Number(user.is_read) > 0 && (
          <UserIcon
            className={classNames(styles.icon, { [styles.admin]: user.is_read === PROJECT_ADMIN_ROLES.ADMIN })}
          />
        )}
      </div>
      <ThreeDots onClick={onClickDots} />

      {mouseParams !== null && (
        <ContextMenu params={mouseParams} setParams={closeContextMenu} tooltip={true}>
          <div className={styles.mainMenuItems}>
            {filtredContextMenu.map((item, i) => (
              <ContextMenuItem
                key={i}
                width={mouseParams.width}
                height={mouseParams.height}
                text={item.name}
                callback={callbacks[item.type]}
                icon={item.img}
              />
            ))}
          </div>
        </ContextMenu>
      )}
    </div>
  );
};

export default UserProject;

UserProject.propTypes = {
  isChosen: PropTypes.bool,
  context: PropTypes.bool,
  user: PropTypes.oneOfType([selectedItemProps, projectUserProps]),
  variant: PropTypes.string
};
