import { IFolder } from "models/store/folders/foldersStore";

import { IPopoverData } from "./popover/popoverOverlay";

export enum FolderInfoClassNameTypes {
  NO_BORDER = "noBorder"
}

export interface IFolderInfoProps {
  className?: FolderInfoClassNameTypes;
  folder: IFolder;
  indent: number;
  isChosen: boolean;
  listCollapsed?: boolean;
  contextMenu?: IPopoverData[];
}
