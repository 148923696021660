import { getTime } from "date-fns";
import { MS_Of_MINUTE } from "generalComponents/Calendars/CalendarHelper";
import { PROJECT_PATH } from "generalComponents/variables/routing";
import {
  IParticipant,
  IProjectTask,
  ROLE_ACCESSES,
  STATUSES,
  SYSTEM_ROLE,
  TASK_PRIORITY,
  TASK_TYPE
} from "models/store/joinProjects/joinProgects";
import { IUserInfo } from "models/store/user/user";
import { useEffect, useMemo } from "react";
import { useLocales } from "react-localized";
import { useLocation, useSearchParams } from "react-router-dom";
import { useJoinProjectsSelectors } from "Store/selectors/joinProjectsSelectors";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { useAllSelectedSearchParams } from "./useSelectedSearchParams";

export const useProjectMainAccess = ({ or = true }: { or?: boolean } = {}): boolean => {
  const { userInfo } = useUserSelectors();
  if (or) {
    return Boolean(userInfo.is_admin) || userInfo.rights.IS_MGR_PROJECT;
  } else {
    return Boolean(userInfo.is_admin) && userInfo.rights.IS_MGR_PROJECT;
  }
};

interface IDefaultRole {
  [SYSTEM_ROLE.ADMIN]: { title: string; prim: string };
  [SYSTEM_ROLE.DEVELOPER]: { title: string; prim: string };
  [SYSTEM_ROLE.APPLICANT]: { title: string; prim: string };
  [SYSTEM_ROLE.WATCHER]: { title: string; prim: string };
}
export const useDefaultProjectRole = (): IDefaultRole => {
  const { __ } = useLocales();
  return {
    [SYSTEM_ROLE.ADMIN]: {
      title: __("Администратор"),
      prim: __("Управление задачами, управление командой и ролями, управление проектом")
    },
    [SYSTEM_ROLE.DEVELOPER]: {
      title: __("Разработчик"),
      prim: __("Создание и изменение задач, назначение исполнителей")
    },
    [SYSTEM_ROLE.APPLICANT]: {
      title: __("Заявитель"),
      prim: __("Создание и изменение задач, назначение исполнителей")
    },
    [SYSTEM_ROLE.WATCHER]: { title: __("Наблюдатель"), prim: __("Просмотр задач и информации о проекте") }
  };
};

interface IAccessesRole {
  [ROLE_ACCESSES.ANALITYCS]: { title: string; prim: string };
  [ROLE_ACCESSES.MGR_TEAMS]: { title: string; prim: string };
  [ROLE_ACCESSES.MGR_LINKS]: { title: string; prim: string };
  [ROLE_ACCESSES.MGR_LIBRARY]: { title: string; prim: string };
  [ROLE_ACCESSES.MGR_FILES]: { title: string; prim: string };
  [ROLE_ACCESSES.MGR_DOCS]: { title: string; prim: string };
  [ROLE_ACCESSES.SETTINGS]: { title: string; prim: string };
}
export const useAccessesProjectRole = (): IAccessesRole => {
  const { __ } = useLocales();
  return {
    [ROLE_ACCESSES.ANALITYCS]: { title: __("Доступ к аналитике"), prim: __("Возможность просматривать аналитику") },
    [ROLE_ACCESSES.MGR_TEAMS]: {
      title: __("Управление Командами"),
      prim: __("Создание, удаление и изменение команд и их состава, доступ к задачам закрытых команд")
    },
    [ROLE_ACCESSES.MGR_LINKS]: {
      title: __("Управление Ссылками"),
      prim: __("Добавление, изменение и удаление ссылок проекта")
    },
    [ROLE_ACCESSES.MGR_FILES]: {
      title: __("Управление Файлами проетка"),
      prim: __("Добавление и удаление файлов проекта ")
    },
    [ROLE_ACCESSES.MGR_LIBRARY]: {
      title: __("Управление Библиотекой проетка"),
      prim: __("Добавление и удаление материалов ")
    },
    [ROLE_ACCESSES.MGR_DOCS]: {
      title: __("Управление Документацией"),
      prim: __("Добавление и удаление документации проекта")
    },
    [ROLE_ACCESSES.SETTINGS]: {
      title: __("Доступ к Настройки"),
      prim: __(
        "Возможность изменять общую информацию проекта, управлять командами и их составом, управлять ролями, управлять участниками и их ролями, управлять Расширениями"
      )
    }
  };
};

interface IDefaultStatuses {
  [STATUSES.PENDING]: string;
  [STATUSES.IN_WORK]: string;
  [STATUSES.DONE]: string;
  [STATUSES.OVERDUE]: string;
}

export const useDefaultProjectStatuses = (): IDefaultStatuses => {
  const { __ } = useLocales();
  return {
    [STATUSES.PENDING]: __("В очереди"),
    [STATUSES.IN_WORK]: __("В работе"),
    [STATUSES.DONE]: __("Готово"),
    [STATUSES.OVERDUE]: __("Просрочено")
  };
};
export const useTaskPriority = () => {
  const { __ } = useLocales();

  const taskPriority = {
    [TASK_PRIORITY.MINOR]: __("Незначительная"),
    [TASK_PRIORITY.NORMAL]: __("Обычная"),
    [TASK_PRIORITY.URGENT]: __("Срочная")
  };
  return { taskPriority };
};

export const useTaskType = () => {
  const { __ } = useLocales();

  const taskType = {
    [TASK_TYPE.EPIC]: __("Epic (Глобальная задача)"),
    [TASK_TYPE.TASK]: __("Задача")
  };
  return { taskType };
};

export const useFindParticipant = () => {
  const {
    project: { participants }
  } = useJoinProjectsSelectors();
  return (id: string) => !!participants?.length && participants.find((item) => item?.id === id);
};
export const useSortedStatuses = () => {
  const { project } = useJoinProjectsSelectors();
  const sortedStatuses = project.statuses.filter((item) => item.is_visible).sort((a, b) => a.y - b.y);
  return { sortedStatuses };
};

export const useFindStatus = () => {
  const { project } = useJoinProjectsSelectors();
  return (val: string) => project.statuses.find((item) => item.id === val);
};

export const useFindTeam = () => {
  const { project } = useJoinProjectsSelectors();
  return (val: string) => project.teams.find((item) => item.id === val);
};

export const useFindProjectUser = (id: string): IUserInfo | IParticipant => {
  const { project } = useJoinProjectsSelectors();
  const { employees, userInfo } = useUserSelectors();
  const author = () => {
    if (!id) return null;
    const participant = project.participants.find((item) => item.id === id);
    if (participant) {
      return participant;
    }
    const employee = employees.find((item) => item.id === id);
    if (employee) {
      return employee;
    }
    return userInfo;
  };

  return author();
};

export const useProjectParticipants = () => {
  const { project } = useJoinProjectsSelectors();
  const usersId = project.teams.map((item) => item.users).flat();
  const projectParticipant = project.participants.filter((u) => usersId.includes(u.id));
  return { projectParticipant };
};

export const useFindSprint = () => {
  const { project } = useJoinProjectsSelectors();
  return (val: string) => project.sprints.find((item) => item.id === val);
};

export const useFindCurrentSprint = () => {
  const { project } = useJoinProjectsSelectors();
  const currentSprint = project.sprints.find(
    ({ date_start, date_end }) => new Date() >= new Date(date_start) && new Date() <= new Date(date_end)
  );
  return currentSprint;
};

export const useUsersToProject = (): string[] => {
  const { project } = useJoinProjectsSelectors();
  const { employees, userInfo } = useUserSelectors();
  const idParticipants = project.participants.map((i) => i.id);
  const idEmployees = employees.reduce((acc: string[], i) => (i.rights.IS_MGR_PROJECT ? [...acc, i.id] : acc), []);
  const combinedSet = new Set([...idParticipants, ...idEmployees, userInfo.id]);
  const uniqueArray = [...combinedSet];
  return uniqueArray;
};

/**
 * @deprecated use useSelectedTeam from JoinProject
 */

export const useSelectedTeam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTeam = searchParams.get("team");
  const onSelectTeam = (id: string) => {
    if (selectedTeam === id) {
      searchParams.delete("team");
      searchParams.delete("participants");
      setSearchParams(searchParams);
      return;
    }
    searchParams.set("team", id);
    searchParams.delete("participants");
    setSearchParams(searchParams);
  };
  return { selectedTeam, onSelectTeam };
};

/**
 * @deprecated use useSelectedParticipant from JoinProject
 */

export const useSelectedParticipant = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedParticipants = searchParams.get("participants");

  const onSelectParticipants = (id: string) => {
    const participantsArray = selectedParticipants ? selectedParticipants.split(",") : [];

    if (participantsArray.includes(id)) {
      const updatedParticipants = participantsArray.filter((participantId) => participantId !== id);

      if (updatedParticipants.length > 0) {
        searchParams.set("participants", updatedParticipants.join(","));
      } else {
        searchParams.has("participants") && searchParams.delete("participants");
      }
    } else {
      participantsArray.push(id);
      searchParams.set("participants", participantsArray.join(","));
    }

    setSearchParams(searchParams);
  };
  return { onSelectParticipants, selectedParticipants };
};

export const useFilteredTasks = (projectTasks: IProjectTask[]): { filteredTasks: IProjectTask[] } => {
  const location = useLocation();
  const { userInfo } = useUserSelectors();

  const { color, tags, emo, search, priority, team, participants } = useAllSelectedSearchParams();

  const isMyTasksPage = location.pathname.includes(PROJECT_PATH.MY_TASKS);

  const filteredTasks = useMemo(() => {
    if (!projectTasks) return;
    let tasks = projectTasks;

    if (isMyTasksPage) tasks = tasks.filter((item) => item.id_executor === userInfo.id);

    if (team.selectedValue) tasks = tasks.filter((item) => item.id_team === team.selectedValue);

    if (participants.selectedValue)
      tasks = tasks.filter((item) => participants.selectedValue.includes(item.id_executor));

    if (color.selectedValue) tasks = tasks.filter((item) => item.color === color.selectedValue);

    if (tags.selectedValue) tasks = tasks.filter((item) => item.tags.some((tag) => tags.selectedValue.includes(tag)));

    if (emo.selectedValue) tasks = tasks.filter((item) => item.emo === emo.selectedValue);

    if (priority.selectedValue) tasks = tasks.filter((item) => item.priority === priority.selectedValue);

    if (search.selectedValue)
      tasks = tasks.filter(
        (item) =>
          item.name.toLocaleLowerCase().includes(search.selectedValue.toLocaleLowerCase()) ||
          item.prim.toLocaleLowerCase().includes(search.selectedValue.toLocaleLowerCase()) ||
          item.num.toString().includes(search.selectedValue)
      );

    return tasks;
  }, [
    projectTasks,
    isMyTasksPage,
    team.selectedValue,
    participants.selectedValue,
    color.selectedValue,
    tags.selectedValue,
    emo.selectedValue,
    priority.selectedValue,
    search.selectedValue,
    userInfo.id
  ]); //eslint-disable-line

  return { filteredTasks };
};

export const useFilterSelectedParticipants = (
  selectedTeam: string,
  selectedParticipiants: string[],
  setSelectedParticipiants: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const { project } = useJoinProjectsSelectors();

  useEffect(() => {
    if (selectedTeam) {
      const selectedTeamInfo = project.teams.find((team) => team.id === selectedTeam);
      if (selectedTeamInfo) {
        const filteredUsers = selectedParticipiants.filter((participant) =>
          selectedTeamInfo.users.includes(participant)
        );
        setSelectedParticipiants(filteredUsers);
      }
    }
  }, [selectedTeam]); //eslint-disable-line
};

export const useIsSystemWatherRole = (): boolean => {
  const { project } = useJoinProjectsSelectors();

  const { userInfo } = useUserSelectors();

  const currentParticipant = project?.participants?.find(({ id }) => id === userInfo.id);

  const isSystemWatherRole = project?.roles?.some(
    ({ id, is_system, name }) => currentParticipant?.idRole === id && name === SYSTEM_ROLE.WATCHER && is_system
  );

  return isSystemWatherRole;
};

export const useGetTaskEnd = () => {
  const {
    project: { work_days, work_hours }
  } = useJoinProjectsSelectors();

  return (dateStart: Date, duration: number) => {
    if (dateStart) {
      let end = dateStart.getTime();
      let d = duration;
      while (d > 0) {
        if (
          work_hours.includes(new Date(end + MS_Of_MINUTE - 1).getHours()) &&
          work_days.includes(new Date(end).getDay())
        ) {
          d = d - 1;
        }
        end += MS_Of_MINUTE;
      }
      return new Date(end);
    }
    return null;
  };
};

export const useGetDuration = () => {
  const {
    project: { work_days, work_hours }
  } = useJoinProjectsSelectors();
  return (start: Date, end: Date) => {
    let endTime = getTime(end.setSeconds(0));
    let duration = 0;

    while (Math.floor(endTime / 1000) > Math.floor(start.getTime() / 1000)) {
      if (
        work_hours.includes(new Date(endTime - MS_Of_MINUTE).getHours()) &&
        work_days.includes(new Date(endTime).getDay())
      ) {
        duration += 1;
      }
      endTime = endTime - MS_Of_MINUTE;
    }
    return duration;
  };
};

export const useDurationToString = (): ((v: number) => string) => {
  const { __ } = useLocales();

  return (duration: number) => {
    const h = Math.floor(duration / 60); // Получаем целое количество часов
    const m = Math.round(duration % 60); // Получаем остаток минут
    const hv = `${h}${__("ч")}`;
    const mv = m >= 1 ? `${m}${__("м")}` : "";
    const str = hv + " " + mv;
    return str;
  };
};
