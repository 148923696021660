import { PanelsMemory } from "containers/Profile/Memory/PanelsMemory/PanelsMemory";
import Clue from "generalComponents/Clue/Clue";
import ToolsPanel from "generalComponents/ToolsPanel/ToolsPanel";
import { SEARCH_PARAMS, TYPE_TOOLS } from "generalComponents/variables/global";
import { WorkAreaWrap } from "generalComponents/WorkAreaWrap/WorkAreaWrap";
import { useActions } from "hooks/useActions";
import { useEffect } from "react";
import { useLocales } from "react-localized";
import { useSearchParams } from "react-router-dom";
import { useUserSelectors } from "Store/selectors/userSelectors";

import styles from "./MemoryPage.module.sass";

export const MemoryPage = () => {
  const { __ } = useLocales();

  const { userInfo } = useUserSelectors();

  const [chapterParams, setChapterParams] = useSearchParams();
  const chapter = chapterParams.get(SEARCH_PARAMS.CHAPTER);

  const { onGetEmploeesMemory, onNullifyMemory } = useActions();

  const message = {
    success: "",
    error: __("Не удалось получить список сотрудников, попробуйте еще раз")
  };

  useEffect(() => {
    const controller = new AbortController();

    onGetEmploeesMemory(message, controller);

    return () => {
      controller.abort();
      onNullifyMemory();
    };
  }, [chapter]); //eslint-disable-line

  useEffect(() => {
    if (!userInfo?.is_admin) {
      return;
    }

    if (!chapter) {
      setChapterParams((params) => {
        params.set(SEARCH_PARAMS.CHAPTER, TYPE_TOOLS.FILES);

        return params;
      });
    }
  }, []); //eslint-disable-line

  return (
    <WorkAreaWrap className={styles.workArea}>
      <ToolsPanel size="large" />
      {userInfo?.is_admin ? <PanelsMemory /> : <Clue icon="sad" text={__("Это страница вам недоступна")} />}
    </WorkAreaWrap>
  );
};
