import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { ILibraryState, initialLibraryState } from "models/store/library/library";
import { FoldersNextArrayType, FoldersNextObjectType } from "models/store/library/library";
import { LibraryActions } from "models/store/library/libraryActions";
import { initialUploadLibraryFiles } from "models/store/library/uploadFilesModals/uploadLibraryFiles";
import { LibraryTypes } from "Store/types/libraryTypes";
import { filtersLibraryFolder } from "Store/utils/filtersLibraryFolder";
import { getLocationSearchParams } from "utils/getLocationSearchParams";
import { getObjDate } from "utils/getObjDate";

const INITIAL_STATE: ILibraryState = initialLibraryState();

export const LibraryReducer = (state = INITIAL_STATE, action: LibraryActions): ILibraryState => {
  switch (action.type) {
    case LibraryTypes.SET_LIBRARY_LOADER:
      return { ...state, loader: action.payload };

    case LibraryTypes.SET_LIBRARY_RUBRICS:
      return { ...state, rubrics: action.payload };

    case LibraryTypes.SET_ADD_LIBRARY_RUBRIC:
      return { ...state, rubrics: [action.payload, ...state.rubrics] };

    case LibraryTypes.SET_UPDATE_LIBRARY_RUBRIC:
      return {
        ...state,
        rubrics: state.rubrics.map((rubric) =>
          rubric.id === action.payload.id ? { ...action.payload } : { ...rubric }
        )
      };

    case LibraryTypes.SET_DEL_LIBRARY_RUBRIC:
      return {
        ...state,
        rubrics: state.rubrics.filter(({ id }) => id !== action.payload.id)
      };

    case LibraryTypes.SET_LIBRARY_FOLDERS:
      if (state.folders) {
        return {
          ...state,
          folders: Array.isArray(action.payload)
            ? [...(state.folders as FoldersNextArrayType), ...action.payload]
            : Object.keys(action.payload).reduce((acc: any, el: string) => {
                return {
                  ...acc,
                  [el]: (state.folders as FoldersNextObjectType)[el]
                    ? [
                        ...(state.folders as FoldersNextObjectType)[el],
                        ...(action.payload as FoldersNextObjectType)[el]
                      ]
                    : (action.payload as FoldersNextObjectType)[el]
                };
              }, {})
        };
      } else {
        return {
          ...state,
          folders: action.payload
        };
      }

    case LibraryTypes.SET_ADD_LIBRARY_FOLDER: {
      const { chapter } = getLocationSearchParams();

      const { y, m } = getObjDate(action.payload.cdate);

      const key = chapter ? `${y}.${m}` : action.payload.chapter;

      if (!Array.isArray(state.folders)) {
        const updateFolders = state.folders?.length
          ? Object.entries(state.folders).reduce(
              (acc, [k, item]) => {
                if (k === key) {
                  const updateKey = [action.payload, ...item] as IFolder[];
                  const filterArr = filtersLibraryFolder(updateKey);

                  return {
                    ...acc,
                    [key]: filterArr
                  };
                }

                if (Object.keys(state.folders).every((v) => key !== v)) {
                  const updateKey = [action.payload] as IFolder[];
                  const filterArr = filtersLibraryFolder(updateKey);

                  return {
                    ...acc,
                    [key]: filterArr
                  };
                }

                return {
                  ...acc
                };
              },
              { ...state.folders }
            )
          : {
              [key]: filtersLibraryFolder([action.payload])
            };

        return {
          ...state,
          folders: updateFolders,
          total: ++state.total
        };
      } else {
        return state;
      }
    }

    case LibraryTypes.SET_UPDATE_LIBRARY_FOLDER: {
      const { chapter, id_dep } = getLocationSearchParams();

      const { y, m } = getObjDate(action.payload.cdate);

      const key = chapter ? `${y}.${m}` : action.payload.chapter;

      if (!Array.isArray(state.folders)) {
        const updateFolders = Object.entries(state.folders).reduce(
          (acc, [k, item]) => {
            // перемещаем папку в новый раздел, если он пустой

            if (Object.keys(state.folders).every((v) => key !== v)) {
              const updateKey = [action.payload] as IFolder[];

              const filterArr = filtersLibraryFolder(updateKey);

              if (filterArr.length) {
                acc[key] = filterArr;
              } else {
                delete acc[key];
              }
            }

            // удаляем папку, если папку переместили в другой раздел

            if (
              item.some(
                (folder) => folder.chapter !== action.payload.chapter && folder.id_dir === action.payload.id_dir
              )
            ) {
              const updateKey = item.filter((folder) => folder.id_dir !== action.payload.id_dir) as IFolder[];

              const filterArr = filtersLibraryFolder(updateKey);

              if (filterArr.length) {
                acc[k] = filterArr;
              } else {
                delete acc[k];
              }
            }

            // перемещаем папку в новый раздел, если он не пустой

            if (
              item.every(
                (folder) => folder.chapter === action.payload.chapter && folder.id_dir !== action.payload.id_dir
              )
            ) {
              const updateKey = [action.payload, ...item] as IFolder[];

              const filterArr = filtersLibraryFolder(updateKey);

              if (filterArr.length) {
                acc[k] = filterArr;
              } else {
                delete acc[k];
              }
            }

            // обновляем папку

            if (k === key && item.some(({ id_dir }) => id_dir === action.payload.id_dir)) {
              const updateKey = item?.map((folder) =>
                folder.id_dir === action.payload.id_dir ? { ...action.payload } : { ...folder }
              ) as IFolder[];

              const filterArr = filtersLibraryFolder(updateKey);

              if (filterArr.length) {
                acc[key] = filterArr;
              } else {
                delete acc[key];
              }
            }

            return acc;
          },
          { ...state.folders }
        );

        const isFilterEmpty =
          (chapter || id_dep) &&
          Object.entries(updateFolders).every(
            ([k, folders]) => k !== key && folders.every((folder) => (folder as IFolder).id_dep !== id_dep)
          );

        return {
          ...state,
          folders: isFilterEmpty ? {} : updateFolders,
          total: isFilterEmpty || !Object.values(updateFolders).length ? 0 : state.total
        };
      } else {
        return state;
      }
    }

    case LibraryTypes.SET_DEL_LIBRARY_FOLDER: {
      const { chapter } = getLocationSearchParams();

      const { y, m } = getObjDate(action.payload.cdate);

      const key = chapter ? `${y}.${m}` : action.payload.chapter;

      if (!Array.isArray(state.folders)) {
        const updateFolders = Object.entries(state.folders).reduce(
          (acc, [k, item]) => {
            if (k === key) {
              const updateKey = item.filter((folder) => folder.id_dir !== action.payload.id_dir);

              if (updateKey.length) {
                return {
                  ...acc,
                  [key]: updateKey
                };
              } else {
                delete acc[k];
              }
            }

            return {
              ...acc
            };
          },
          { ...state.folders }
        );

        return {
          ...state,
          folders: updateFolders,
          total: --state.total
        };
      } else {
        return state;
      }
    }

    case LibraryTypes.SET_DEL_LIBRARY_FILES: {
      const { chapter } = getLocationSearchParams();

      const updateFolders = Object.entries(state.folders).reduce(
        (acc, [k, item]) => {
          const currentFolder = item.find((folder: IFolder) => folder.id_dir === action.payload[0].id_dir);

          if (currentFolder) {
            const { y, m } = getObjDate(currentFolder.cdate);

            const key = chapter ? `${y}.${m}` : currentFolder.chapter;

            if (k === key) {
              return {
                ...acc,
                [key]: item.map((folder: IFolder) => {
                  if (folder.id_dir === currentFolder.id_dir) {
                    return {
                      ...currentFolder,
                      files: currentFolder.files.filter(
                        (file: IFile) => !action.payload.some(({ fid }) => fid === file.fid)
                      )
                    };
                  }

                  return {
                    ...folder
                  };
                })
              };
            }
          }

          return {
            ...acc
          };
        },
        { ...state.folders }
      );

      return {
        ...state,
        folders: updateFolders
      };
    }

    case LibraryTypes.CHANGE_LIBRARY_LOADING_FILES:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          loadingFiles: action.payload
        }
      };

    case LibraryTypes.RESET_LIBRARY_LOADING_FILES:
      return {
        ...state,
        uploadFiles: initialUploadLibraryFiles()
      };

    case LibraryTypes.RESET_LIBRARY_FOLDERS:
      return { ...state, folders: null, folderInfo: null, page: 1, total: 0 };

    case LibraryTypes.SET_LIBRARY_FOLDER_INFO:
      return { ...state, folderInfo: action.payload };

    case LibraryTypes.SET_LIBRARY_PAGE:
      return { ...state, page: action.payload };

    case LibraryTypes.SET_LIBRARY_TOTAL_ITEMS:
      return { ...state, total: action.payload };

    default:
      return state;
  }
};
