import { ReactComponent as ReturnIcon } from "assets/icons/restore_2.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash_2.svg";
import Button from "generalComponents/Button/Button";
import { calculateSize } from "generalComponents/Services/helpers";
import { splitFilename } from "generalComponents/Services/helpers";
import { ROUTES } from "generalComponents/variables/routing";
import { useDepName } from "hooks/tasksHooks";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { ArchivedElementsType } from "models/store/archive/archivedFiles";
import { IFile } from "models/store/files/files";
import { IFolder } from "models/store/folders/foldersStore";
import { IMyTask } from "models/store/tasks/tasks";
import { AllElementsType } from "models/store/typeElements";
import { IUserInfo } from "models/store/user/user";
import { TypeElements } from "models/typeElements/typeElements";
import { FC } from "react";
import { useLocales } from "react-localized";
import { useLocation } from "react-router";
import { useUserSelectors } from "Store/selectors/userSelectors";
import { getConvertFormatDateTime } from "utils/getConvertDateTime";

import { renderType } from "./data/renderType";
import styles from "./PropertiesElemenModalBody.module.sass";

interface IPropertiesElemenModalBodyProps {
  element: AllElementsType;
  onTrash: () => void;
  onRestore: () => void;
}

export const PropertiesElemenModalBody: FC<IPropertiesElemenModalBodyProps> = ({ element, onRestore, onTrash }) => {
  const { __ } = useLocales();
  const { pathname } = useLocation();
  const { formatDate } = useDateFormat();

  const isArchive = pathname.startsWith(`/${ROUTES.ARCHIVE}`);

  const {
    user: {
      userInfo: { lang }
    }
  } = useUserSelectors();

  const departmentName = useDepName();

  const task = element as IMyTask;
  const user = element as IUserInfo;
  const folder = element as IFolder;

  const dateCreated = getConvertFormatDateTime({
    dateStart: isArchive ? (element as ArchivedElementsType).ctime : (element as ArchivedElementsType).cdate,
    locale: lang
  });

  const dateEdit = getConvertFormatDateTime({
    dateStart: isArchive ? (element as ArchivedElementsType).mtime : element.dtime,
    locale: lang
  });

  return (
    <div className={styles.body}>
      <div className={styles.info}>
        <span className={styles.info__name}>{isArchive ? __("Заархивирован") : __("Удален")}</span>
        <p className={styles.info__text}>
          {formatDate(isArchive ? (element as ArchivedElementsType).atime : element.dtime, "full")}
        </p>
      </div>
      <div className={styles.info}>
        <span className={styles.info__name}>{__("Тип")}</span>
        <p className={styles.info__text}>{__(renderType(element))}</p>
      </div>
      <div className={styles.info}>
        <span className={styles.info__name}>{__("Имя")}</span>
        <p className={styles.info__text}>
          {TypeElements.FILES === element.table ||
          TypeElements.SAFE_FILES === element.table ||
          (element as IFile).is_dir === 0
            ? splitFilename(element.name).name
            : TypeElements.USERS === element.table
            ? `${user.fname} ${user.sname}`
            : element.name}
        </p>
      </div>
      {TypeElements.USERS && user?.email && (
        <div className={styles.info}>
          <span className={styles.info__name}>{__("Основной Email")}</span>
          <p className={styles.info__text}>{__(user.email)}</p>
        </div>
      )}
      {(TypeElements.FILES === element.table ||
        TypeElements.SAFE_FILES === element.table ||
        (element as IFolder).is_dir === 1) && (
        <>
          <div className={styles.info}>
            <span className={styles.info__name}>{__("Размер")}</span>
            <p className={styles.info__text}>{calculateSize(Number((element as IFile | IFolder).size))}</p>
          </div>
          {(element as IFolder).is_dir === 1 && (
            <div className={styles.info}>
              <span className={styles.info__name}>{__("Кол-во файлов")}</span>
              <p className={styles.info__text}>{folder.col_files}</p>
            </div>
          )}
          {dateCreated?.date && dateCreated?.time && (
            <div className={styles.info}>
              <span className={styles.info__name}>{__("Дата создания")}</span>
              <p className={styles.info__text}>{`${dateCreated.date}, ${dateCreated.time}`}</p>
            </div>
          )}
          {dateEdit?.date && dateEdit?.time && (
            <div className={styles.info}>
              <span className={styles.info__name}>{__("Дата изменения")}</span>
              <p className={styles.info__text}>{`${dateEdit.date}, ${dateEdit.time}`}</p>
            </div>
          )}
        </>
      )}
      {TypeElements.TASKS === element.table && (
        <>
          {task?.date_start && task?.date_end && (
            <>
              <div className={styles.info}>
                <span className={styles.info__name}>{__("Дата")}</span>
                <p className={styles.info__text}>
                  {formatDate(task.date_start)} {task.date_end && <>- {formatDate(task.date_end)}</>}
                </p>
              </div>

              <div className={styles.info}>
                <span className={styles.info__name}>{__("Время")}</span>
                <p className={styles.info__text}>{formatDate(task.date_start, "time")}</p>
              </div>
            </>
          )}
          {task.id_dep && (
            <div className={styles.info}>
              <span className={styles.info__name}>{__("Группа")}</span>
              <p className={styles.info__text}>{departmentName(task.id_dep)}</p>
            </div>
          )}
          {folder?.prim && (
            <div className={styles.info}>
              <span className={styles.info__name}>{__("Описание")}</span>
              <div className={styles.info__text} dangerouslySetInnerHTML={{ __html: folder.prim }} />
            </div>
          )}
        </>
      )}
      <div className={styles.btns}>
        <Button
          onClick={onRestore}
          variant={ButtonVariantType.EXRTA_LIGHT}
          iconL={<ReturnIcon width={16} height={16} />}
          size={ButtonSizeType.SMALL}
        />
        <Button
          onClick={onTrash}
          variant={ButtonVariantType.EXRTA_LIGHT}
          iconL={<DeleteIcon width={16} height={16} />}
          size={ButtonSizeType.SMALL}
        />
      </div>
    </div>
  );
};
