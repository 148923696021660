import { imageSrc } from "generalComponents/variables/globalVariables";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { getInitials, getUser } from "../../helpers";
import styles from "./UserInitials.module.sass";

const UserInitials = ({ id }) => {
  const project = useSelector((s) => s.Projects.project);
  const getTitle = useMemo(() => {
    const user = getUser(project.users, id);
    return `${user?.name} ${user?.sname ? user.sname : ""}`;
  }, [id, project.users]);

  return (
    <>
      {getUser(project.users, id) && (
        <>
          {getUser(project.users, id).icon[0] ? (
            <img
              src={`${imageSrc}${getUser(project.users, id).icon[0]}`}
              alt="avatar"
              className={styles.userAvatar}
              title={getTitle}
            />
          ) : (
            <span className={styles.userText} title={getTitle}>
              {getInitials(project.users, id)}
            </span>
          )}
        </>
      )}
    </>
  );
};

export default UserInitials;

UserInitials.propTypes = {
  id: PropTypes.string
};
