import { UserAPI } from "api/UserAPI";
import { checkResponseStatus } from "generalComponents/Services/requestServices";
import { SEARCH_PARAMS, TYPE_TOOLS } from "generalComponents/variables/global";
import { PER_PAGE } from "generalComponents/variables/globalVariables";
import { ITopMessages } from "models/common/common";
import { CabinetActions } from "models/store/Cabinet/Cabinet";
import { ITopMessageTypes } from "models/store/Cabinet/modals/modals";
import { IEmploeesMemory } from "models/store/memory/memory";
import {
  INullifyMemory,
  IResetEmployeesMemory,
  ISetEmployeesMemory,
  ISetEmployeesMemoryPage,
  ISetMemoryLoader,
  ISetTotalEmploeesMemory,
  MemoryActions
} from "models/store/memory/memoryActions";
import { ThunkAction } from "redux-thunk";
import { RootState } from "Store/reducers";
import { MemoryTypes } from "Store/types/memoryTypes";

import { onSetTopMessageModal } from "./ModalActions";

export const onSetMemorylLoader = (payload: boolean): ISetMemoryLoader => ({
  type: MemoryTypes.SET_MEMORY_LOADER,
  payload
});

export const onSetEmployeesMemory = (payload: IEmploeesMemory[]): ISetEmployeesMemory => ({
  type: MemoryTypes.SET_EMPLOYEES_MEMORY,
  payload
});

export const onSetEmployeesMemoryPage = (payload: number): ISetEmployeesMemoryPage => ({
  type: MemoryTypes.SET_EMPLOYEES_MEMORY_PAGE,
  payload
});

export const onSetTotalEmploeesMemory = (payload: number): ISetTotalEmploeesMemory => ({
  type: MemoryTypes.SET_TOTAL_EMPLOYEES_MEMORY,
  payload
});

export const onResetJournalElements = (): IResetEmployeesMemory => ({
  type: MemoryTypes.RESET_EMPLOYEES_MEMORY
});

export const onNullifyMemory = (): INullifyMemory => ({
  type: MemoryTypes.NULLIFY_MEMORY
});

export const onGetEmploeesMemory =
  (
    messages?: ITopMessages,
    controller?: AbortController
  ): ThunkAction<void, RootState, unknown, MemoryActions | CabinetActions> =>
  async (dispatch, getState) => {
    const { searchParams } = new URL(window?.location?.href);
    const chapter = searchParams.get(SEARCH_PARAMS.CHAPTER);

    const params = {
      uid: getState().user.uid,
      per_page: PER_PAGE,
      page: getState()?.Journal?.page,
      filter_chapter: chapter as TYPE_TOOLS
    };

    try {
      dispatch(onSetMemorylLoader(true));
      const { data } = await UserAPI.getEmployeesStorage(params, controller?.signal);
      checkResponseStatus(data?.ok);
      dispatch(onSetEmployeesMemory(data?.users));
      dispatch(onSetTotalEmploeesMemory(data?.total));
    } catch {
      if (!controller?.signal?.aborted) {
        dispatch(
          onSetTopMessageModal({
            open: true,
            type: ITopMessageTypes.ERROR,
            message: messages?.error
          })
        );
      }
    } finally {
      dispatch(onSetMemorylLoader(false));
    }
  };
