export enum ArchivedFilesTypes {
  SET_ARCHIVED_FILES = "SET_ARCHIVED_FILES",
  NULLIFY_ARCHIVED_FILES = "NULLIFY_ARCHIVED_FILES",
  SET_TOTAL_ARCHIVED_ITEMS = "SET_TOTAL_ARCHIVED_ITEMS",
  SET_ARCHIVE_PAGE = "SET_ARCHIVE_PAGE",
  RESET_ARCHIVED_FILES_LIST = "RESET_ARCHIVED_FILES_LIST",
  ARCHIVED_FILES_LOADER = "ARCHIVED_FILES_LOADER",
  //Update
  SET_DEL_ARCHIVED_ELEMENT = "SET_DEL_ARCHIVED_ELEMENT"
}
