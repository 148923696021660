import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CKEDITOR_TOOLBAR_OPTIONS } from "generalComponents/variables/ckeditor";
import PropTypes from "prop-types";
import React from "react";

import styles from "./TextEditorNote.module.sass";
const hiddenEvent = () => null;

const TextEditorNote = ({ content, setContent, placeholder }) => {
  const editor = null;
  const handlerChange = (event, editor) => {
    hiddenEvent(event);
    const text = editor.getData();
    setContent(text);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.wrapEditor}>
        <CKEditor
          onReady={(editor) => {
            editor.ui
              .getEditableElement()
              .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
          }}
          onError={({ willEditorRestart }) => {
            if (willEditorRestart) {
              editor.ui.view.toolbar.element.remove();
            }
          }}
          onChange={handlerChange}
          editor={DecoupledEditor}
          data={content}
          config={{
            toolbar: CKEDITOR_TOOLBAR_OPTIONS,
            list: {
              properties: {
                styles: false,
                startIndex: true,
                reversed: true
              }
            },
            placeholder: placeholder
          }}
        />
      </div>
    </div>
  );
};

export default TextEditorNote;

TextEditorNote.propTypes = {
  content: PropTypes.string,
  setContent: PropTypes.func,

  placeholder: PropTypes.string
};
