import { ReactComponent as CloudIcon } from "assets/icons/cloud_2.svg";
import classNames from "classnames";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { calculateSize } from "generalComponents/Services/helpers";
import { useState } from "react";
import { useLocales } from "react-localized";
import { useUserSelectors } from "Store/selectors/userSelectors";

import { StorageContent } from "./components/StorageContent";
import styles from "./StorageSize.module.sass";

export const StorageSize = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { __ } = useLocales();

  const {
    userInfo: { used, total }
  } = useUserSelectors();

  const progressPercent = +((used * 100) / total).toFixed(2);

  const width = used ? `${progressPercent}%` : "0%";

  return (
    <PopoverOverlay
      overlayContent={<StorageContent onClose={() => setIsOpen(false)} />}
      show={isOpen}
      setShow={setIsOpen}
      width={320}
    >
      <span
        className={classNames(styles.btnBox, {
          [styles.open]: isOpen
        })}
      >
        <CloudIcon width={24} height={24} />
        <span className={styles.btnBox__info}>
          <span
            className={classNames(styles.btnBox__head, {
              [styles.open]: isOpen
            })}
          >
            {calculateSize(used ?? 0)} / {calculateSize(total ?? 0)}
          </span>
          <span className={styles.btnBox__progress}>
            <span
              title={`${width} - ${__("Занято")}`}
              className={classNames(styles.btnBox__progressBusy, {
                [styles.small]: progressPercent < 80,
                [styles.medium]: progressPercent >= 80 && progressPercent < 90,
                [styles.big]: progressPercent >= 90
              })}
              style={{ width }}
            />
          </span>
        </span>
      </span>
    </PopoverOverlay>
  );
};
