import Button from "generalComponents/Button/Button";
import { ChooseFolder } from "generalComponents/ChooseFolder/ChooseFolder";
import CombinedElements from "generalComponents/Elements/CombinedElements";
import { useInputValue } from "generalComponents/Hooks/useInputValue";
import Input from "generalComponents/Input/Input";
import { ItemInfoDynamic } from "generalComponents/ItemInfoDynamic/ItemInfoDynamic";
import PopUp from "generalComponents/PopUp/PopUp";
import { SetPassword } from "generalComponents/SetPassword/SetPassword";
import { Times } from "generalComponents/Times/Times";
import { NO_VALUE } from "generalComponents/variables/globalVariables";
import { ROUTES, ROUTES_SEARCH_PARAMS } from "generalComponents/variables/routing";
import { useActions } from "hooks/useActions";
import { ButtonVariantType } from "models/generalComponents/button";
import { IFile } from "models/store/files/files";
import { IFolder, initialFolderState } from "models/store/folders/foldersStore";
import { ChangeEvent, useState } from "react";
import { useLocales } from "react-localized";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import { useFoldersSelectors } from "Store/selectors/foldersSelectors";
import { useGlobalModalsSelectors } from "Store/selectors/globalModalsSelectors";
import { getTheRestorePath } from "utils/getTheRestorePath";

import styles from "./RestoreFileModal.module.sass";

export const RestoreFileModal = (): JSX.Element => {
  const { __ } = useLocales();

  const navigate = useNavigate();

  const {
    onSetRestoreFileModal,
    onRestoreArchivedFiles,
    onRestoreArchivedFolder,
    onRestoreTrashFiles,
    onRestoreTrashFolder
  } = useActions();

  const {
    restoreFileModals: { element }
  } = useGlobalModalsSelectors();

  const { folders } = useFoldersSelectors();

  const asFolder = element as IFolder;
  const asFile = element as IFile;

  const [_folder, _setFolder] = useState<IFolder>({ ...initialFolderState(), id_parent: "0" });
  const { value: password, onChange: setPassword, error: passwordError, onError: onPasswordError } = useInputValue();
  const {
    value: passwordRepeat,
    onChange: setPasswordRepeat,
    error: repeatError,
    onError: onRepeatError
  } = useInputValue();
  const [isPasswordSet, setIsPasswordSet] = useState<boolean>(true);
  const [name, setName] = useState<string>(NO_VALUE);
  const [emo, setEmo] = useState<string>("");
  const [tagOption, setTagOption] = useState<string[]>([]);
  const [color, setColor] = useState<string>();

  const { pathname } = useLocation();
  const isArchive = pathname.startsWith(`/${ROUTES.ARCHIVE}`);
  const isTrash = pathname.startsWith(`/${ROUTES.TRASH}`);

  const closeModal = (): void => {
    onSetRestoreFileModal({
      open: false,
      element: null
    });
  };

  const onClickSetButton = (): void => {
    setIsPasswordSet((prev) => !prev);
    setPassword("");
    setPasswordRepeat("");
  };

  const onSelect = (folder: IFolder): void => _setFolder(folder);
  const onAddName = (e: ChangeEvent<HTMLInputElement>): void => setName(e.target.value);

  const getPayload = () => {
    const payload = {
      add_tags: tagOption || undefined,
      add_color: color,
      add_emo: emo || undefined,
      add_dir_name: name || undefined,
      add_pass: password || undefined,
      add_id_parent: name && !_folder.id_dir ? _folder.id_parent : name && _folder.id_dir ? _folder.id_dir : undefined,
      id_parent: !name ? _folder.id_dir : undefined,
      id_dirs: asFolder.is_dir ? [asFolder.id_dir] : undefined,
      id_dir: !name && asFile.is_dir === 0 && _folder.id_dir ? _folder.id_dir : undefined,
      current_id_parent: asFolder.id_parent
    };

    if (asFolder.is_dir) {
      return {
        ...payload,
        id_dirs: [asFolder.id_dir],
        id_parent: !name ? _folder.id_dir : undefined
      };
    } else {
      return {
        ...element,
        ...payload,
        new_id_dir: !name ? _folder.id_dir : undefined
      };
    }
  };

  const onSubmit = (): void => {
    const callback = () => {
      if (!name) {
        navigate(
          getTheRestorePath({
            path: ROUTES.FOLDERS,
            id_dir: _folder.id_dir,
            id_parent: _folder.id_parent,
            search: `${ROUTES_SEARCH_PARAMS.byDateChanged}`
          })
        );
      }
    };

    if (asFolder.is_dir) {
      if (isArchive) {
        onRestoreArchivedFolder(asFolder, getPayload(), callback);
      }

      if (isTrash) {
        onRestoreTrashFolder(asFolder, getPayload(), callback);
      }
    } else {
      if (isArchive) {
        onRestoreArchivedFiles(asFile, getPayload(), callback);
      }

      if (isTrash) {
        onRestoreTrashFiles(asFile, getPayload(), callback);
      }
    }

    closeModal();
  };

  const disableButton = (): boolean => !name && !_folder.id_dir;

  return (
    <PopUp set={closeModal}>
      <div className={styles.createFolderWrap}>
        <Times handleClick={closeModal} />
        <h2 className={styles.title}>{__("Восстановить в")}</h2>
        <ItemInfoDynamic
          setColor={setColor}
          setEmo={setEmo}
          color={color}
          passIcon={false}
          name={name}
          tags={tagOption}
          emo={emo}
          isFolder
        />
        <div className={styles.inputFieldsWrap}>
          <Input value={name} onChange={onAddName} label={__("Имя папки")} placeholder={__("Введите имя папки")} />
          <ChooseFolder onSelect={onSelect} folder={_folder} folders={folders} />
          <SetPassword
            password={password}
            setPassword={setPassword}
            passwordRepeat={passwordRepeat}
            setPasswordRepeat={setPasswordRepeat}
            passwordError={passwordError}
            onPasswordError={onPasswordError}
            repeatError={repeatError}
            onRepeatError={onRepeatError}
            isPasswordSet={isPasswordSet}
            onClickSetButton={onClickSetButton}
          />
          <CombinedElements
            tags={tagOption}
            onSelectTags={setTagOption}
            color={color}
            setColor={setColor}
            emo={emo}
            setEmo={setEmo}
          />
        </div>
        <div className={styles.buttonsWrap}>
          <Button type="button" variant={ButtonVariantType.CANCEL} text={__("Отмена")} onClick={closeModal} />
          <Button
            type="button"
            variant={ButtonVariantType.BLUE}
            text={__("Восстановить")}
            onClick={onSubmit}
            disabled={disableButton()}
          />
        </div>
      </div>
    </PopUp>
  );
};
