import { IParticipant } from "models/store/joinProjects/joinProgects";

export interface IUserDeleteState {
  open: boolean;
  user: IParticipant;
}

export function initialUserDeleteState(): IUserDeleteState {
  return { open: false, user: undefined };
}
