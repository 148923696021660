import { ReactComponent as ArrowIcon } from "assets/PrivateCabinet/arrow-2.svg";
import { ReactComponent as PlusIcon } from "assets/PrivateCabinet/plus-3.svg";
import classNames from "classnames";
import { startOfDay } from "date-fns";
import { dateToMs, MS_OF_DAY } from "generalComponents/Calendars/CalendarHelper";
import ContextMenu from "generalComponents/ContextMenu";
import ContextMenuItem from "generalComponents/ContextMenu/ContextMenuItem";
import { useFiltredTask } from "generalComponents/Services/projectServices";
import { MODALS } from "generalComponents/variables/global";
import { PROJECT_CONTEXT_MENU, PROJECT_MODALS } from "generalComponents/variables/project";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocales } from "react-localized";
import { useDispatch, useSelector } from "react-redux";

import { onSetModals } from "../../../../../../../Store/actions/ModalActions";
import { minmaxDate, useContextProjectTask, useProjectTasks } from "../../../helpers";
import Diagram from "./Diagram";
import TaskRow from "./TaskRow";
import styles from "./TasksGant.module.sass";

const today = startOfDay(new Date()).getTime();
const W = 22;

const TasksGant = () => {
  const { __ } = useLocales();
  const dispatch = useDispatch();
  const contextMenu = useContextProjectTask();
  const tasks = useSelector((s) => s.Projects.project.tasks);
  const { epicTasks } = useProjectTasks();
  const filtredTasks = useFiltredTask().sort((a, b) => dateToMs(a.ut) - dateToMs(b.ut));
  const [collapsed, setCollapsed] = useState(true);
  const [showChildren, setShowChildren] = useState([]);
  const [mouseParams, setMouseParams] = useState(null);
  const [chosenTask, setchosenTask] = useState(null);
  const linkRef = useRef(null);
  const closeContextMenu = () => setMouseParams(null);
  const ref = useRef();

  const filtredGantTasks = filtredTasks.filter((t) => t.is_backlog < 0);

  const filtredContext = useMemo(() => {
    return contextMenu.filter(
      (item) =>
        item.type === PROJECT_CONTEXT_MENU.OPEN_TASK ||
        item.type === PROJECT_CONTEXT_MENU.EDIT_TASK ||
        item.type === PROJECT_CONTEXT_MENU.COPY_LINK ||
        item.type === PROJECT_CONTEXT_MENU.TASK_TO_TRASH
    );
  }, [contextMenu]);

  const withoutEpic = useMemo(() => filtredGantTasks.filter((t) => !t.id_epic && !t.is_epic), [filtredGantTasks]);

  const minmax = minmaxDate(tasks);
  const minDate = minmax.min;
  const maxDate = minmax.maxLong;

  const handleCollapsed = () => {
    setCollapsed((open) => !open);
  };

  const onAddTask = () => dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.ADD_TASK, params: {} }));

  const callbacks = {
    [PROJECT_CONTEXT_MENU.OPEN_TASK]: () =>
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.OPEN_TASK, params: chosenTask })),

    [PROJECT_CONTEXT_MENU.EDIT_TASK]: () =>
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.EDIT_TASK, params: chosenTask })),

    [PROJECT_CONTEXT_MENU.COPY_LINK]: () => {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(chosenTask.link);
      } else {
        linkRef.current.focus();
        linkRef.current.select();
        document.execCommand("copy");
      }
      dispatch(
        onSetModals("topMessage", {
          open: true,
          type: "message",
          message: __("Ссылка скопирована")
        })
      );
    },

    [PROJECT_CONTEXT_MENU.TASK_TO_TRASH]: () =>
      dispatch(onSetModals(MODALS.PROJECT, { type: PROJECT_MODALS.TASK_TO_TRASH, params: chosenTask }))
  };

  const data = {
    startYear: new Date(minDate).getFullYear(),
    startMonth: new Date(minDate).getMonth(),
    endYear: new Date(maxDate).getFullYear(),
    endMonth: new Date(maxDate).getMonth()
  };
  const startCalendar = useMemo(
    () => new Date(data.startYear, data.startMonth).getTime(),
    [data.startMonth, data.startYear]
  );

  useEffect(() => {
    if (ref) {
      const toLeft = Math.round((today - startCalendar) / MS_OF_DAY) * W - W * 5;
      ref.current.scrollLeft = toLeft;
    }
  }, [startCalendar]);

  return (
    <>
      {tasks.length > 0 && (
        <div className={styles.wrap} ref={ref}>
          <div className={classNames(styles.tasks)}>
            <div className={styles.head}>
              <div className={styles.name}>{__("Задачи")}</div>
              <div className={classNames(styles.visible, { [styles.hidden]: collapsed })}>
                {collapsed ? "" : __("Исполнитель")}
              </div>
              <div className={classNames(styles.visible, { [styles.hidden]: collapsed })}>
                {collapsed ? "" : __("Статус")}
              </div>
              <div className={classNames(styles.visible, { [styles.hidden]: collapsed })}>
                {collapsed ? "" : __("Тег")}
              </div>
              <div className={styles.plusBox}>
                <PlusIcon width={26} height={26} className={styles.plusIcon} onClick={onAddTask} />
              </div>
            </div>
            <div className={classNames(styles.body, { [styles.tableTop]: tasks.length > 12 })}>
              <div
                className={classNames(styles.openBtn, { [styles.openBtnStyky]: tasks.length > 12 })}
                onClick={handleCollapsed}
              >
                <ArrowIcon
                  className={classNames(styles.arrowIcon, { [styles.arrowIconOpen]: collapsed })}
                  width={6}
                  height={10}
                />
              </div>
              {epicTasks.map((task) => (
                <TaskRow
                  key={task.id}
                  task={task}
                  collapsed={collapsed}
                  isEpic={!!task.is_epic}
                  setShowChildren={setShowChildren}
                  setMouseParams={setMouseParams}
                  setchosenTask={setchosenTask}
                />
              ))}
              {withoutEpic.length > 0 && epicTasks.length > 0 && <div className={styles.indent} />}
              {withoutEpic.map((task) => (
                <TaskRow
                  key={task.id}
                  task={task}
                  collapsed={collapsed}
                  isEpic={!!task.is_epic}
                  setShowChildren={setShowChildren}
                  setMouseParams={setMouseParams}
                  setchosenTask={setchosenTask}
                />
              ))}
            </div>
            <input value={chosenTask?.link} readOnly className={styles.hiddenInput} ref={linkRef} />
          </div>

          <Diagram
            data={data}
            epics={epicTasks}
            withoutEpic={withoutEpic}
            showChildren={showChildren}
            filtredTasks={filtredGantTasks}
          />

          {mouseParams && (
            <ContextMenu params={mouseParams} setParams={closeContextMenu} tooltip={true}>
              <>
                {filtredContext.map((item, i) => (
                  <div key={i}>
                    <ContextMenuItem
                      width={mouseParams.width}
                      height={mouseParams.height}
                      text={item.name}
                      icon={item.img}
                      callback={callbacks[item.type]}
                    />
                  </div>
                ))}
              </>
            </ContextMenu>
          )}
        </div>
      )}
    </>
  );
};

export default TasksGant;
