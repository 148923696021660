import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as NoteIcon } from "assets/icons/note_1.svg";
import { useNotesButtons } from "collections/notes";
import Button from "generalComponents/Button/Button";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { useActions } from "hooks/useActions";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./NotesButtons.module.sass";

const NotesButtons = () => {
  const { __ } = useLocales();
  const { onSetNotesSidebar } = useActions();

  const [showOverlay, setShowOverlay] = useState(false);
  const notes = useNotesButtons();

  return (
    <div className={styles.container}>
      <Button
        className={styles.leftButton}
        isSquare
        variant={ButtonVariantType.ACCORDION}
        size={ButtonSizeType.SMALL}
        iconL={<NoteIcon />}
        onClick={() => onSetNotesSidebar({ open: true })}
      />
      <PopoverOverlay
        classes={{ popoverBody: styles.tooltip }}
        width={210}
        overlayContent={
          <div className={styles.content}>
            <div className={styles.title}>{__("Быстрая заметка")}</div>
            {notes.map((note, i) => (
              <div
                key={i}
                className={styles.item}
                onClick={() => {
                  note.onClick();
                  setShowOverlay(false);
                }}
              >
                {note.icon}
                {note.title}
              </div>
            ))}
          </div>
        }
        show={showOverlay}
        setShow={setShowOverlay}
      >
        <div className={styles.rightButton}>
          <ArrowDown />
        </div>
      </PopoverOverlay>
    </div>
  );
};

export default NotesButtons;
