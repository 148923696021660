import { AllElementsType } from "models/store/typeElements";

export interface IPropertiesElementModalState {
  open: boolean;
  element: AllElementsType;
  onTrash?: () => void;
  onRestore?: () => void;
}

export const initialPropertiesElementModalState = (): IPropertiesElementModalState => {
  return { open: false, element: null };
};
