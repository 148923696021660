import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import Button from "generalComponents/Button/Button";
import { ItemIcon } from "generalComponents/ItemIcon/ItemIcon";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { FC, memo, useEffect, useState } from "react";

import styles from "./TaskUploadFile.module.sass";

interface IProps {
  file: { file: File; fid: string };
  deleteFile?: (id: string) => void;
}
const TaskUploadFile: FC<IProps> = ({ file, deleteFile }) => {
  const [imageSrc, setImageSrc] = useState<string>("");

  const handleFileRead = (e: ProgressEvent<FileReader>) => {
    if (e.target?.result) {
      setImageSrc(e.target.result.toString());
    }
  };

  useEffect(() => {
    if (!(file as { file: File; fid: string }).file.type.startsWith("image")) return;
    const readFile = (file: File | null) => {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = handleFileRead;
        reader.readAsDataURL(file);
      }
    };

    readFile((file as { file: File; fid: string }).file);
  }, [file]);

  const getExt = (file: File) => {
    const arr = file.name.split(".");
    return arr[arr.length - 1];
  };

  return (
    <div className={styles.taskBox}>
      <div className={styles.preview}>
        {imageSrc ? (
          <img src={imageSrc} alt="#" style={{ maxWidth: "100%", maxHeight: "300px" }} />
        ) : (
          <ItemIcon isFolder={false} width={33} height={44} ext={getExt((file as { file: File; fid: string }).file)} />
        )}
        <div className={styles.btn}>
          <Button
            variant={ButtonVariantType.LIGHT}
            size={ButtonSizeType.EXTRA_SMALL}
            isSquare
            iconL={<CrossIcon />}
            onClick={() => deleteFile(file.fid)}
          />
        </div>
      </div>
      <p className={styles.name}>{file.file.name}</p>
    </div>
  );
};
const MemoTaskUploadFile = memo(TaskUploadFile);
export default MemoTaskUploadFile;
