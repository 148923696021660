export enum CheckBoxVariantTypes {
  NO_TYPE = "",
  DEFAULT = "default",
  HOVER = "hover",
  CHECKED = "checked",
  ADDICTION = "addiction",
  SIGNIN_DEFAULT = "signin_default",
  SIGNIN_CHECKED = "signin_checked"
}

export interface ICheckBoxProps {
  variant: CheckBoxVariantTypes;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  width?: number;
  height?: number;
  tooltip?: string;
  increaseArea?: boolean;
}
