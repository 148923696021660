import { ReactComponent as CalendarIcon } from "assets/PrivateCabinet/calendar-4.svg";
import { ReactComponent as CheckIcon } from "assets/PrivateCabinet/check.svg";
import classNames from "classnames";
import Button from "generalComponents/Button/Button";
import { PopoverOverlay } from "generalComponents/Popover/PopoverOverlay/PopoverOverlay";
import { EVENT_TYPE } from "generalComponents/variables/global";
import { STATUS_TYPES, TASK_MODALS } from "generalComponents/variables/tasks";
import { useActions } from "hooks/useActions";
import { useDateFormat } from "hooks/useDateFormat";
import { ButtonSizeType, ButtonVariantType } from "models/generalComponents/button";
import { IMyTask } from "models/store/tasks/tasks";
import { useState } from "react";
import { useLocales } from "react-localized";

import styles from "./TaskItem.module.sass";

const TaskItem = ({ task }: { task: IMyTask }) => {
  const { __ } = useLocales();
  const { formatDate } = useDateFormat();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { onEditTask, onSetTaskModal } = useActions();

  const removeHtmlTags = (html: string) => {
    return html.replace(/<[^>]*>?/gm, "");
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case STATUS_TYPES.IN_PROGRESS:
        return __("В работе");
      case STATUS_TYPES.QUEUE:
        return __("В очереди");
      case STATUS_TYPES.DONE:
        return __("Готово");
    }
  };

  const statusButtons = [
    { type: STATUS_TYPES.QUEUE, variant: ButtonVariantType.LIGHT, text: "В очереди" },
    { type: STATUS_TYPES.IN_PROGRESS, variant: ButtonVariantType.DARK_GREEN, text: "В работе" },
    { type: STATUS_TYPES.DONE, variant: ButtonVariantType.GREEN, text: "Готово" }
  ];

  const editStatus = (e: React.MouseEvent<HTMLButtonElement>, status: STATUS_TYPES): void => {
    e.stopPropagation();
    const params = { id_task: task.id, id_status: status };
    onEditTask(params, EVENT_TYPE.EDIT);
    setShowTooltip(false);
  };

  const isStatusMatch = (status: STATUS_TYPES) => task.id_status === status;

  return (
    <div
      className={styles.container}
      onClick={() => {
        onSetTaskModal({
          type: TASK_MODALS.OPEN_TASK,
          params: task
        });
      }}
    >
      <div className={styles.titleWrapper}>
        <div>
          <CalendarIcon />
        </div>
        <span className={styles.title}>{task.name}</span>
      </div>
      <div className={styles.desc}>
        <div className={styles.date}>{formatDate(task.date_end || task.date_start, "dateMonth")}</div>
        {task.prim && <span className={styles.info} dangerouslySetInnerHTML={{ __html: removeHtmlTags(task.prim) }} />}
      </div>
      <div
        className={classNames(styles.tip, styles[`tip_${task.id_status}`])}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <PopoverOverlay
          classes={{ popoverBody: styles.tooltip }}
          width={120}
          overlayContent={
            <>
              {statusButtons.map(({ type, variant, text }) => (
                <div key={type}>
                  <Button
                    variant={variant}
                    size={ButtonSizeType.EXTRA_SMALL}
                    text={text}
                    onClick={(e) => editStatus(e, type)}
                  />
                  {isStatusMatch(type) && <CheckIcon />}
                </div>
              ))}
            </>
          }
          show={showTooltip}
          setShow={setShowTooltip}
        >
          <span className={classNames(styles.tip, styles[`tip_${task.id_status}`])}>
            {getStatusText(task.id_status)}
          </span>
        </PopoverOverlay>
      </div>
    </div>
  );
};

export default TaskItem;
