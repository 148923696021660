import { useTypeSelector } from "generalComponents/Hooks/useTypedSelector";

export const usePaintSelectors = () => {
  const paint = useTypeSelector((s) => s.Cabinet.paint);
  const tool = paint.tool;
  const color = paint.color;
  const mutualEdit = paint.mutualEdit;
  const size = paint.size;

  return {
    paint,
    tool,
    color,
    mutualEdit,
    size
  };
};
